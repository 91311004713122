<!-- begin main content -->
<div class="container " role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <div class="row">
        <div [ngClass]="{ 'col-md-9': !isTitleVI, 'col-md-8': isTitleVI } ">
          <h1>Dashboard</h1>
        </div>
        <!-- .end col-md-6 -->
        <div [ngClass]="{ 'col-md-3': !isTitleVI, 'col-md-4': isTitleVI }">
          <!-- .begin year picker dropdown -->
          <fiscalYearDropdown #ffyDropdown></fiscalYearDropdown>
          <!-- .end year picker dropdown -->
        </div>
        <!-- .end col-md-3 -->
      </div>
      <loading #loadingSpinner></loading>
      <div *ngIf="!_claimService.isDeactivated && dashInitialized">
        <!-- return comments modal -->
        <div id="returnCommentsModal" class="modal fade">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title">Return comments</h1>
              </div>
              <div class="modal-body">
                <p>Returned on: {{returnCommentModel.created | date:'MM/dd/yyyy'}}</p>
                <p class="mb-2">Returned by: {{returnCommentModel.createdBy}}</p>
                <p class="background-gray-lighter p-1">{{returnCommentModel.comments}}</p>
              </div>
              <div class="modal-footer">
                <button (click)="hideReturnedComments()" type="button" class="btn btn-secondary btn-small"
                  data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <!-- .end return comments modal -->
        <!-- tvi dashboard -->
        <div class="col-md-12 pt-1 pb-1" *ngIf="_claimService.currentTitle === 'VI'">
          <acl-tvidashboard [dashboardModel]="tviDashboardModel" [regionalContact]="contact"
            (regionChanged)="setRegion($event)"></acl-tvidashboard>
        </div>
        <!-- .end tvi dashboard -->
        <div class="col-md-12 pt-1 pb-1" *ngIf="_claimService.currentTitle !== 'VI'">
          <div *ngIf="!_claimService.isAAAUser && _claimService.isNavStateSubmission">
            <div class="pb-1">
              <div class="mb-2">
                <h2 *ngIf="_claimService.currentTitle == 'III'">{{_claimService.titleIIIStateOfficeName}}</h2>
                <h2 *ngIf="_claimService.currentTitle == 'VII'">{{stateNameLegible}} Office of the State Long-Term Care
                  Ombudsman</h2>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <table class="table">
                    <tbody>
                    <tr class="background-gray-lighter">
                      <td *ngIf="_claimService.currentTitle == 'III'"></td>
                      <th scope="col">FFY {{_claimService.currentYear}} Reporting Status</th>
                      <th scope="col">Last Update</th>
                    </tr>
                    <tr>
                      <th scope="row" *ngIf="_claimService.currentTitle == 'III'">SPR</th>
                      <td *ngIf="_claimService.currentTitle == 'III'">
                        <span id="titleIIISPRStatus" [ngClass]="_commonService.setColor(overviewModelIII.sprStatus)"
                          [innerHTML]="_commonService.overallStatusHtml(overviewModelIII.sprStatus, overviewModelIII.sprIsRequired)"></span>&nbsp;
                        <a class="btn-sm btn-primary" *ngIf="overviewModelIII.sprStatus == statusEnum.Returned"
                          (click)="isSPRReturnComments = true; showReturnedComments()" id="viewReturnCommentsBtn">
                          View return comments
                        </a>
                      </td>
                      <td *ngIf="_claimService.currentTitle == 'III'">
                        <span
                          [innerHTML]="_commonService.updatedByReader(overviewModelIII.sprLastUpdatedTime, overviewModelIII.sprLastUpdatedBy, overviewModelIII.sprIsRequired)"></span>
                      </td>
                      <td *ngIf="_claimService.currentTitle == 'VII'">
                        <span id="titleVIIStatus" [ngClass]="_commonService.setColor(overviewModelVII.overviewStatus)"
                          [innerHTML]="_commonService.overallStatusHtml(overviewModelVII.overviewStatus)"></span>
                      </td>
                      <td
                        *ngIf="_claimService.currentTitle == 'VII' && overviewModelVII.lastUpdatedTime != '0001-01-01T00:00:00Z'">
                        <span *ngIf="!!overviewModelVII.lastUpdatedBy">
                          {{overviewModelVII.lastUpdatedTime | amLocale:'en' | amDateFormat:'MM/DD/YYYY [at] h:mm A'}}
                          by
                          <br />{{overviewModelVII.lastUpdatedBy}}
                        </span>
                      </td>
                      <td
                        *ngIf="_claimService.currentTitle == 'VII' && overviewModelVII.lastUpdatedTime == '0001-01-01T00:00:00Z'">
                        <span *ngIf="!!overviewModelVII.lastUpdatedBy">By {{overviewModelVII.lastUpdatedBy}}</span>
                      </td>
                    </tr>
                    <tr *ngIf="_claimService.currentTitle == 'III'">
                      <th scope="row">NSIP</th>
                      <td>
                        <span id="titleIIISPRNSIP" [ngClass]="_commonService.setColor(overviewModelIII.nsipStatus)"
                          [innerHTML]="_commonService.overallStatusHtml(overviewModelIII.nsipStatus, overviewModelIII.nsipIsRequired)"></span>&nbsp;
                        <a class="btn-sm btn-primary" *ngIf="overviewModelIII.nsipStatus == statusEnum.Returned"
                          (click)="isSPRReturnComments = false; showReturnedComments()">View return comments</a>
                      </td>
                      <td>
                        <span
                          [innerHTML]="_commonService.updatedByReader(overviewModelIII.nsipLastUpdatedTime, overviewModelIII.nsipLastUpdatedBy, overviewModelIII.nsipIsRequired)"></span>
                      </td>
                    </tr>
                  </tbody>
                  </table>
                  <div *ngIf="_claimService.currentTitle == 'VII'">
                    <a id="viewVIIStatusHistoryLnk" [routerLink]="['/dashboard/titleVIISubmissionLog']">View status
                      history</a>
                  </div>
                  <div *ngIf="_claimService.currentTitle == 'III'">
                    <a id="viewIIIStatusHistoryLnk"
                      [routerLink]="['/data-submissions/titleIIIStatusLogSrpComponent']">View
                      status history</a>
                  </div>
                </div>
                <!-- end col-md-7 -->
                <div class="col-md-3">
                  <img src="/assets/images/flags/{{stateNameFormatted}}-flag.png"
                    alt="Flag of {{stateNameLegible}}" class="flags img-responsive t3">
                </div>
                <!-- end col-md-3 -->
              </div>
              <!-- end row -->
            </div>
            <!-- end row -->
          </div>
          <div
            *ngIf="!_claimService.noRoles && ((!_claimService.isNavStateSubmission && !_claimService.isAAAUser) || _claimService.isAAAEnabledStateUser())">
            <div class="col-md-12">
              <!--<a href="#" class="pull-right pt-1">view all submissions</a>-->
              <div class="row form-inline">
                <h2 class="col-md-10 pl-0">
                  FFY {{_claimService.currentYear}} {{_claimService.isAAAEnabledStateUser() ? 'AAA' : 'State'}}
                  Submission Statuses
                </h2>
                <div class="col-md-2 p-0">
                  <div
                    *ngIf="(!_claimService.isNavStateSubmission && !_claimService.isAAAUser)"
                    class="pull-right acl-select">
                    <label class="sr-only" for="regionDropdown">Filter by region:</label>
                    <select id="regionDropdown" (change)="filterRegions()" [(ngModel)]="selectedRegion"
                      aria-label="Filter by region">
                      <option
                        *ngIf="titleVIIUserDefaultStates.defaultStates.length > 0 || titleIIIUserDefaultStates.defaultStates.length > 0"
                        value="myStates">
                        My states
                      </option>
                      <option value="">All Regions</option>
                      <option *ngFor="let region of regions" [ngValue]="region">Region {{region}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid row table-responsive">
              <table class="table  text-center">
                <tbody>
                <tr class="background-gray-lighter">
                  <td *ngIf="_claimService.currentTitle == 'III'"></td>
                  <th scope="col">Not Started</th>
                  <th scope="col">In Progress</th>
                  <th scope="col" *ngIf="_claimService.currentTitle == 'VII'">Submitted</th>
                  <th scope="col" *ngIf="_claimService.currentTitle == 'III'">Submitted - Pending Explanations</th>
                  <th scope="col" *ngIf="_claimService.currentTitle == 'III'">Submitted - Explanations Provided</th>
                  <th scope="col">In Review</th>
                  <th scope="col">Returned</th>
                  <th scope="col">Approved</th>
                  <th scope="col">Locked</th>
                  <th *ngIf="_claimService.isAAAEnabledStateUser()" scope="col">N/A</th>
                  <th scope="col">Total</th>
                </tr>
                <tr>
                  <th *ngIf="_claimService.currentTitle == 'III'" scope="row">SPR</th>
                  <td id="notStartedStatus">{{notStartedCountDisplay}}</td>
                  <td id="inProgressStatus">{{inProgressCountDisplay}}</td>
                  <td *ngIf="_claimService.currentTitle == 'III'">{{withoutExCount}}</td>
                  <td id="submittedStatus">{{submittedCountDisplay}}</td>
                  <td id="reviewStatus">{{reivewCountDisplay}}</td>
                  <td id="returnedStatus">{{returnedCountDisplay}}</td>
                  <td id="approvedStatus">{{approvedCountDisplay}}</td>
                  <td id="lockedStatus">{{lockedCountDisplay}}</td>
                  <td *ngIf="_claimService.isAAAEnabledStateUser()">{{naCountDisplay}}</td>
                  <td id="totalStatus">{{totalCountDisplay}}</td>
                </tr>
                <tr *ngIf="_claimService.currentTitle == 'III'">
                  <th scope="row">NSIP</th>
                  <td id="notStartedNsipStatus">{{notStartedCountNsipDisplay}}</td>
                  <td id="inProgressStatus">{{inProgressCountNsipDisplay}}</td>
                  <td id="withoutExNsipStatus">{{withoutExCountNsipDisplay}}</td>
                  <td id="submittedNsipStatus">{{submittedCountNsipDisplay}}</td>
                  <td id="reviewNsipStatus">{{reivewCountNsipDisplay}}</td>
                  <td id="returnedNsipStatus">{{returnedCountNsipDisplay}}</td>
                  <td id="approvedNsipStatus">{{approvedCountNsipDisplay}}</td>
                  <td id="lockedNsipStatus">{{lockedCountNsipDisplay}}</td>
                  <td *ngIf="_claimService.isAAAEnabledStateUser()">{{naCountNsipDisplay}}</td>
                  <td id="totalNsipStatus">{{totalCountNsipDisplay}}</td>
                </tr>
              </tbody>
              </table>
            </div>
            <!--  end row   -->
            <!-- end if (not state) -->
          </div>
          <!-- .end ngIf -->
          <div>
            <div class="pb-1" *ngIf="_claimService.isAAAUser">
              <h2>{{_claimService.aaaName}}</h2>
              <div class="row">
                <div class="col-md-9">
                  <table class="table">
                    <tbody>
                    <tr class="background-gray-lighter">
                      <td></td>
                      <th scope="col">FFY {{_claimService.currentYear}} Reporting Status</th>
                      <th scope="col">Last Update</th>
                    </tr>
                    <tr>
                      <th scope="row">SPR</th>
                      <td>
                        <span id="titleIIISPRStatusDos" [ngClass]="_commonService.setColor(overviewModelIII.sprStatus)"
                          [innerHTML]="_commonService.overallStatusHtml(overviewModelIII.sprStatus, overviewModelIII.sprIsRequired)"></span>&nbsp;
                        <a class="btn-sm btn-primary" *ngIf="overviewModelIII.sprStatus == statusEnum.Returned"
                          (click)="isSPRReturnComments = true; showReturnedComments()" id="viewSPRReturnCommentsBtn">
                          View return comments
                        </a>
                      </td>
                      <td>
                        <span
                          [innerHTML]="_commonService.updatedByReader(overviewModelIII.sprLastUpdatedTime, overviewModelIII.sprLastUpdatedBy, overviewModelIII.sprIsRequired)"></span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">NSIP</th>
                      <td>
                        <span id="titleIIINSIPStatusDos"
                          [ngClass]="_commonService.setColor(overviewModelIII.nsipStatus)"
                          [innerHTML]="_commonService.overallStatusHtml(overviewModelIII.nsipStatus, overviewModelIII.nsipIsRequired)"></span>&nbsp;
                        <a class="btn-sm btn-primary" *ngIf="overviewModelIII.nsipStatus == statusEnum.Returned"
                          (click)="isSPRReturnComments = false; showReturnedComments()" id="viewNsipReturnCommentsBtn">
                          View return comments
                        </a>
                      </td>
                      <td>
                        <span
                          [innerHTML]="_commonService.updatedByReader(overviewModelIII.nsipLastUpdatedTime, overviewModelIII.nsipLastUpdatedBy, overviewModelIII.nsipIsRequired)"></span>
                      </td>
                    </tr>
                  </tbody>
                  </table>
                  <div>
                    <a id="viewAAAStatusHistoryLnk"
                      [routerLink]="['../data-submissions/titleIIIStatusLogSrpComponent']">View
                      status history</a>
                    <div *ngIf="_claimService.isAAAUser" class="pull-right">
                      <a [routerLink]="['../data-submissions/dataSubmissionResponsibility']">
                        View data submission responsibility
                      </a>
                    </div>
                  </div>
                </div>
                <!-- end col-md-7 -->
                <div class="col-md-3">
                  <img src="/assets/images/flags/{{stateNameFormatted}}-flag.png"
                    alt="Flag of {{stateNameLegible}}" class="flags img-responsive t3">
                </div>
                <!-- end col-md-3 -->
              </div>
              <!-- end row -->
            </div>
            <!-- three panels -->
            <div class="flex-list negSevenM mt-8">
              <div class="flex-list-item">
                <div class="gray-dash flex-list-content">
                  <span class="btn-floating btn-large">
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                  </span>
                  <h3>Recent System Updates</h3>
                  <ul class="list-unstyled">
                    <li *ngFor="let rp of systemUpdates;">
                      <p>
                        <strong>
                          {{rp.shortText}}
                        </strong>
                      </p>
                      <p>Date: {{rp.reportingDate | amLocale:'en' | amDateFormat:'MM/DD/YYYY'}}</p>
                      <p>{{rp.longText}}</p>
                    </li>
                  </ul>
                  <a [routerLink]="['../systemUpdates']" class="btn btn-default">See all updates</a>
                </div>
              </div>
              <div class="flex-list-item">
                <div class="gray-dash flex-list-content">
                  <span class="btn-floating btn-large">
                    <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                  </span>
                  <h3>Reporting Calendar</h3>
                  <ul class="list-unstyled">
                    <li *ngFor="let rp of reportingDates;">
                      <p>
                        <strong>
                          {{rp.shortText}}
                        </strong>
                      </p>
                      <p>Date: {{rp.reportingDate | amLocale:'en' | amDateFormat:'MM/DD/YYYY'}}</p>
                      <p>{{rp.longText}}</p>
                    </li>
                  </ul>
                  <a [routerLink]="['../Resources/reporting-calendar/reporting-calendar']" class="btn btn-default">
                    See reporting calendar
                  </a>
                </div>
              </div>
              <div class="flex-list-item">
                <div class="gray-dash flex-list-content">
                  <span class="btn-floating btn-large">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </span>
                  <h3>Upcoming Events</h3>
                  <ul class="list-unstyled">
                    <li *ngFor="let rp of upcomingEvents;">
                      <p>
                        <strong>
                          {{rp.shortText}}
                        </strong>
                      </p>
                      <p>Date: {{rp.reportingDate | amLocale:'en' | amDateFormat:'MM/DD/YYYY'}}</p>
                      <p>{{rp.longText}}</p>
                    </li>
                  </ul>
                  <a [routerLink]="['../Resources/events']" class="btn btn-default">See all events</a>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
          <!-- .end ngIf -->
        </div>
        <!-- .end .col-md-12 -->
      </div>
      <div style="height: 800px;" *ngIf="!dashInitialized">
        Loading...
      </div>
      <!-- .begin deactivated user dashboard-->
      <div *ngIf="_claimService.isDeactivated" class="col-md-12 pt-1 pb-1">
        <div class="col-md-12 pt-1 pb-1">
          <h1>Dashboard</h1>
          <h2>Your account has been deactivated.</h2>
          <br />
          <p>Your account was deactivated by a user administrator.</p>
          <p>If you need to have your account reactivated, please contact your organization's user administrator.</p>
        </div>
      </div>
      <!-- .end deactivated user dashboard-->
    </div>
    <!-- .end col-md-12 -->
  </div>
  <!-- .end row -->
</div>
<!-- .end container -->
