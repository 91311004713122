import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { TruncateModule } from 'ng2-truncate';

import { TitleVIIDataEntryService } from './titleVIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { CommonService } from '../shared/common.service';
import { YearApprovalStatus } from '../shared/yearApprovalStatus.model';
import { FormGroup } from '@angular/forms';
import { ToastrService } from '../shared/toastr.service';
import { LoadingComponent } from '../shared/loading.component';
import { QuestionStatusEnum } from '../shared/dataElement';
import { DestructibleComponent } from '../destructible.component';
import { AclHeader } from '../shared/AclReviewHeader/aclHeader';

declare var jQuery: any;

@Component({
    templateUrl: 'titleVIISubmissionLog.component.html',
    styles: [
        `.modal-header > h1 { text-transform: none; color: black; font-size: 20px;
            font-weight: bold; border-bottom-width: 0px; padding-bottom: 0px; }`,
        '.modal-body > .table { margin-bottom: 0px; }',
        '.modal-body > .table > tbody > tr > td { border-top: 0px; width: 70%; }',
        '.modal-body > .table > tbody > tr > th { border-top: 0px; width: 30% }'
    ]
})

export class TitleVIISubmissionLogComponent extends DestructibleComponent implements OnInit, OnDestroy {

    constructor(public route: ActivatedRoute,
        public router: Router,
        public _titleService: Title,
        public _service: TitleVIIDataEntryService,
        public _claimService: ClaimService,
        public _router: Router,
        public _commonService: CommonService,
        public toastr: ToastrService,
        public truncateModule: TruncateModule
    ) { super(); }

    public resolutionStrategiesForm: FormGroup;

    _sectionModel: YearApprovalStatus;
    _submissionLog: Array<YearApprovalStatus> = [];
    disabledFlag: boolean = false;
    customResolutionStrategies: string[] = [];
    sitOtherDescriptionError: boolean = false;
    state: string;
    ffy: string;
    logCreatedBy: string;
    logCreatedDate: string;
    comments: string;
    status: string;
    isPrint: boolean = false;
    rowsOnPage: number = 10;
    sortBy: string = 'created';
    sortOrder: string = 'desc';
    role: string;
    aclHeader: AclHeader = new AclHeader();

    // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;

    public _currentRoute: string = this.route.toString();

    ngOnInit(): void {
        // this.loadingComponent.setLoadingInProgress(5);
        this._titleService.setTitle('State Submission Log');
        this.state = this._claimService.currentOrg;
        this.ffy = this._claimService.currentYear;
        this.getsubmissionlogs();
        this.setRole();
    }

    ngOnDestroy(): void {
        super.destroy();
    }

    getsubmissionlogs(): void {
        super.registerSubscription(this._service.getYearApprovalStatusByFfyAndState(this.ffy, this.state)
            .subscribe(data => {
                this.buildAclHeader();
                if (data.length > 0) {
                    this._submissionLog = data;
                }
            }));
    }

    showSubmissionLogCommentModal(status: QuestionStatusEnum, createdBy: string, created: string, comment: string): void {
        this.status = QuestionStatusEnum[status];
        this.logCreatedBy = createdBy;
        this.logCreatedDate = created;
        this.comments = comment;
        jQuery('#submissionLogComment').modal('show');
    }

    dismissComments(): void {
        jQuery('#submissionLogComment').modal('hide');
    }

    print(): void {
        this.isPrint = true;
        this.rowsOnPage = 100000;
        setTimeout(() => { window.print(); this.rowsOnPage = 10; }, 2000);
    }

    formatData(data: YearApprovalStatus): void {
        this._sectionModel.id = data.id;
        this._sectionModel.upn = data.upn;
    }

    setFocus() {
        jQuery('#customResolutionStrategyDiv' + (this.customResolutionStrategies.length - 2)).show();
        jQuery('#id' + (this.customResolutionStrategies.length - 2)).focus();
    }

    setRole() {
        if (!this._claimService.isNavStateSubmission) {
            this.role = 'ACL';
        }
        if (this._claimService.isNavStateSubmission) {
            this.role = 'DataEntry';
        }
    }


    setErrorDisplayType(status: number): string {
        if (status === 1 || status === 2) {
            return 'alert-danger';
        }
        if (status === 4) {
            return 'alert-warning';
        } else { return ''; }
    }

    calculateOutlineColor(status: number): string {
        if (status === 1 || status === 2) {
            return 'has-error';
        }
        if (status === 4) {
            return 'has-warning';
        } else { return ''; }
    }

    private buildAclHeader(): void {
        let title, orgLabel, orgName, returnLink, returnText, returnParams = <any>{};
        title = this._claimService.currentTitle;
        orgLabel = 'State';
        orgName = this._commonService.stateAbbrvToFullName(this._claimService.currentOrg);
        returnText = 'Back to state submission review';
        returnLink = '/state-submissions/titleVIISSReview';

        this.aclHeader = {
            title,
            orgLabel,
            orgName,
            returnLink,
            returnText,
            returnParams,
            fiscalYear: this._claimService.currentYear,
            shouldRender: this._claimService.isACLUser()
        };
    }

}

