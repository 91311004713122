import { Component, Input } from '@angular/core';
import { AclHeader } from './aclHeader';

@Component({
    selector: 'acl-review-header',
    templateUrl: 'aclReviewHeader.component.html',
    styles: ['div { font-size: 19px; }', '.row { margin-right: 0; margin-left: 0; }']
})
export class AclReviewHeaderComponent {

    @Input() model: AclHeader = new AclHeader();

    constructor() {}

}
