import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class ANNetworkFundingSection extends Section {

    noFederalFunds: IDataElement;
    medicaid: IDataElement;
    medicaidWaiver: IDataElement;
    socialServicesBlockGrant: IDataElement;
    communityServicesBlockGrant: IDataElement;
    unitedStatesDepartmentOfAgriculture: IDataElement;
    departmentOfTransportation: IDataElement;
    stateLegalAssistanceAndDeveloperPrograms: IDataElement;
    centersForIndependentLiving: IDataElement;
    otherFederalFunds: IDataElement;
    comments: IDataElement;


    constructor() {
        super();
        this.noFederalFunds = new DataElement();
        this.medicaid = new DataElement();
        this.medicaidWaiver = new DataElement();
        this.socialServicesBlockGrant = new DataElement();
        this.communityServicesBlockGrant = new DataElement();
        this.unitedStatesDepartmentOfAgriculture = new DataElement();
        this.departmentOfTransportation = new DataElement();
        this.otherFederalFunds = new DataElement();
        this.comments = new DataElement();
    }
}
