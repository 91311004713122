import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';
import { VarianceExplanation } from '../shared/varianceExplanation.model';
import { VarianceExplanationDto } from './Dto/VarianceExplanationDto';


export class NsipSection extends Section {

    hdmC: IDataElement;
    hdmE: IDataElement;
    hdmTotal: IDataElement;
    congregateC: IDataElement;
    congregateE: IDataElement;
    congregateTotal: IDataElement;
    hdmCompare: IDataElement;
    congregateCompare: IDataElement;
    varianceExplanation: VarianceExplanation;
    aaaVarianceExplanations: VarianceExplanationDto[];

    constructor() {
        super();

        this.hdmC = new DataElement();
        this.hdmE = new DataElement();
        this.hdmTotal = new DataElement();
        this.congregateC = new DataElement();
        this.congregateE = new DataElement();
        this.congregateTotal = new DataElement();
        this.hdmCompare = new DataElement();
        this.congregateCompare = new DataElement();
    }
}
