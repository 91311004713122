<div class="acl-container">
  <div class="container">
    <div class="col-md-9" style="min-height: 600px;">
      <h1>Recent System Updates</h1>
      <div *ngFor="let rp of systemUpdates;" class="border-bottom-gray-lighter pb-1 mb-3">
        <h2>{{rp.shortText}}</h2>
        <p class="mb-2">Date:
          <em>{{rp.reportDate | amLocale:'en' | amDateFormat:'MM/DD/YYYY'}}</em>
        </p>
        <p class="mb-2">{{rp.longText}} </p>
        <p>
          <strong>Update Details</strong>
        </p>
        <p [innerHTML]="rp.description">{{rp.description}}</p>
      </div>
      <div *ngIf="!systemUpdates.length">
        <h2>Coming soon</h2>
      </div>
    </div>
    <!-- end col-md-9 -->
  </div>
  <!-- end container -->
</div>
<!-- end acl-container -->
