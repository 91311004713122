import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class CGRespiteSection extends Section {

    inhomeTotalUnduplicated: IDataElement;
    outDayTotalUnduplicated: IDataElement;
    outNightTotalUnduplicated: IDataElement;
    unknownRespiteTotalUnduplicated: IDataElement;
    totalRespiteTotalUnduplicated: IDataElement;

    inhomeTotalTotalService: IDataElement;
    outDayTotalService: IDataElement;
    outNightTotalService: IDataElement;
    unknownRespiteTotalService: IDataElement;
    totalRespiteTotalService: IDataElement;

    title3InHome: IDataElement;
    title3OutDay: IDataElement;
    title3OutNight: IDataElement;
    title3Unknown: IDataElement;
    title3Total: IDataElement;
    otherStateInHome: IDataElement;
    otherStateOutDay: IDataElement;
    otherStateOutNight: IDataElement;
    otherStateUnknown: IDataElement;
    otherStateTotal: IDataElement;
    otherNonStateInHome: IDataElement;
    otherNonStateOutDay: IDataElement;
    otherNonStateOutNight: IDataElement;
    otherNonStateUnknown: IDataElement;
    otherNonStateTotal: IDataElement;
    programInHome: IDataElement;
    programOutDay: IDataElement;
    programOutNight: IDataElement;
    programUnknown: IDataElement;
    programTotal: IDataElement;
    totalExInHome: IDataElement;
    totalExOutDay: IDataElement;
    totalExOutNight: IDataElement;
    totalExUnknown: IDataElement;
    totalExTotal: IDataElement;
    perPersonInHome: IDataElement;
    perPersonOutDay: IDataElement;
    perPersonOutNight: IDataElement;
    perPersonUnknown: IDataElement;
    perPersonTotal: IDataElement;

    constructor() {
        super();

        this.inhomeTotalUnduplicated = new DataElement();
        this.outDayTotalUnduplicated = new DataElement();
        this.outNightTotalUnduplicated = new DataElement();
        this.unknownRespiteTotalUnduplicated = new DataElement();
        this.totalRespiteTotalUnduplicated = new DataElement();

        this.inhomeTotalTotalService = new DataElement();
        this.outDayTotalService = new DataElement();
        this.outNightTotalService = new DataElement();
        this.unknownRespiteTotalService = new DataElement();
        this.totalRespiteTotalService = new DataElement();

        this.title3InHome = new DataElement();
        this.title3OutDay = new DataElement();
        this.title3OutNight = new DataElement();
        this.title3Unknown = new DataElement();
        this.title3Total = new DataElement();
        this.otherStateInHome = new DataElement();
        this.otherStateOutDay = new DataElement();
        this.otherStateOutNight = new DataElement();
        this.otherStateUnknown = new DataElement();
        this.otherStateTotal = new DataElement();
        this.otherNonStateInHome = new DataElement();
        this.otherNonStateOutDay = new DataElement();
        this.otherNonStateOutNight = new DataElement();
        this.otherNonStateUnknown = new DataElement();
        this.otherNonStateTotal = new DataElement();
        this.programInHome = new DataElement();
        this.programOutDay = new DataElement();
        this.programOutNight = new DataElement();
        this.programUnknown = new DataElement();
        this.programTotal = new DataElement();
        this.totalExInHome = new DataElement();
        this.totalExOutDay = new DataElement();
        this.totalExOutNight = new DataElement();
        this.totalExUnknown = new DataElement();
        this.totalExTotal = new DataElement();
        this.perPersonInHome = new DataElement();
        this.perPersonOutDay = new DataElement();
        this.perPersonOutNight = new DataElement();
        this.perPersonUnknown = new DataElement();
        this.perPersonTotal = new DataElement();
    }
}
