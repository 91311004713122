import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ReportingCalendar } from '../resources/resources.model';
import * as systemUpdatesData from '../../assets/json/systemUpdates.json';
import * as reportingDatesData from '../../assets/json/reportingPeriod.json';
import * as eventsData from '../../assets/json/events.json';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'welcome.component.html',
    selector: 'welcome'
})

export class WelcomeComponent implements OnInit {

    systemUpdates: ReportingCalendar[];
    reportingDates: ReportingCalendar[];
    upcomingEvents: ReportingCalendar[];

    constructor(public router: Router,
      public _titleService: Title) { }

    public ngOnInit(): void {
        this._titleService.setTitle('ACL OAAPS');
        this.systemUpdates = (<any>systemUpdatesData).default;
        this.reportingDates = (<any>reportingDatesData).default;
        this.upcomingEvents = (<any>eventsData).default;

        this.getLatestDates();
    }

    private getLatestDates() {
        this.systemUpdates = this.systemUpdates.filter(x => new Date(x.reportingDate) <= new Date())
            .sort((left, right) => left.reportingDate < right.reportingDate ? 1 : -1).slice(0, 2); // top 2

        this.reportingDates = this.reportingDates.filter(x => new Date(x.reportingDate) >= new Date())
          .sort((left, right) => left.order > right.order ? 1 : -1).slice(0, 3); // the upcoming 3

        this.upcomingEvents = this.upcomingEvents.filter(x => new Date(x.reportingDate) >= new Date())
          .sort((left, right) => left.reportingDate > right.reportingDate ? 1 : -1).slice(0, 3); // top 3
    }
}
