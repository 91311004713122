import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { YearState, YearStateRegionReport, YearStateRegion } from './report.model';
import { EmbedConfig } from './ad-hoc-report/embed-config';
import { ITransaction, ITransactionCreatedResponse } from '../shared/Sync/distributedTransaction';
import { RollupTypes } from '../app.constants';
import { ClaimService } from '../shared/claim.service';
import { YearApprovalStatus } from '../shared/yearApprovalStatus.model';

export interface IReportSettings {
    title: string;
    reportName: string;
    modelType: string;
    numberOfYears: number;
    yearRange: number[];
    showStateSelect: boolean;
    showRegionSelect: boolean;
    showPsaSelect: boolean;
    showGranteeSelect: boolean;
    showSingleStateSelect: boolean;
    showSingleGranteeSelect: boolean;
    role?: string;
    generate: (model, loadingComponent?) => void;
    description: string;
}

/**
 * Service for uploads
 */
@Injectable()
export class ReportService {

    public readonly _getTableA1Report: string = '/api/titlevii/report/generateTableA1Report';
    public readonly _getActivityAnalysisReport: string = '/api/titlevii/report/getactivityanalysisreport';
    public readonly _getAnnualRollupReport: string = '/api/titlevii/report/generateAnnualRollupReport';
    public readonly _getComplaintCodeAnalysisReport: string = '/api/titlevii/report/generateComplaintCodeAnalysisReport';
    public readonly _getComplainantCodeAnalysisReport: string = '/api/titlevii/report/generateComplainantCodeAnalysisReport';
    public readonly _getCasesClosedComplaintsChart: string = '/api/titlevii/report/generateCasesClosedComplaintsChart';
    public readonly _get2yrReport: string = '/api/titlevii/report/Generate2yrReport';
    public readonly _get5yrReport: string = '/api/titlevii/report/Generate5yrReport';
    public readonly _getLegacyReport: string = '/api/titlevii/report/GenerateLegacyReport';
    public readonly _exportTitleVIIData = '/api/titlevii/report/{ffy}/{state}';
    public readonly _getLegacyDataCutoffYear = 'api/titlevii/report/legacy-cutoff';
    public _getEmbedConfig = '/api/titlevii/report/getembedconfig';
    public _getHasData = 'api/titlevii/report/hasData';

    public readonly _getTIIIServiceUnitExpReport: string = 'api/titleiii/report/serviceUnitExpReport';
    public readonly _getTIIIPersonsServedServiceUnitsReport: string = 'api/titleiii/report/personsServedServiceUnitsReport';
    public readonly _getTIIINsipReport: string = 'api/titleiii/report/nsipReport';
    public readonly _getTIIIeReport: string = 'api/titleiii/report/eReport';
    public readonly _getSubmissionHistoryReport: string = 'api/titleiii/report/submissionHistoryReport';
    public readonly _getMissingDataReport: string = 'api/titleiii/report/missingDataReport';
    public readonly _getClientCharacteristicsReport: string = 'api/titleiii/report/clientCharacteristics';
    public readonly _getTitleVIIExpenditureReport: string = 'api/titleiii/report/titleVIIExpenditureReport';
    public readonly _getMissingDataByStateReport: string = 'api/titleiii/report/missingDataByStateReport';
    public readonly _getLegacyOtherServicesReport: string = 'api/titleiii/report/legacyOtherServicesReport';
    public readonly _getLegacyPersonsServedUnderOAAReport: string = 'api/titleiii/report/personsServedUnderOAAReport';
    public readonly _getLegacyServiceUnitsForSelectedCluster3Report: string = 'api/titleiii/report/legacyServiceUnitsForSelectedCluster3';
    public readonly _getLegacyNFCSPReport: string = 'api/titleiii/report/legacyNationalFamilyCaregiverSupportProgram';

    public readonly _getLegacyTwoYearReport: string = 'api/titleiii/report/legacyTwoYear';
    public readonly _get2yrTitleIIIReport: string = 'api/titleiii/report/two-year';
    //public readonly _getNationalRollupExport: string = 'api/titleiii/export';
    public readonly _getTIIILegacyReport: string = 'api/titleiii/report/legacyReport';
    public readonly _getTIIILegacyDataCutoffYear = 'api/titleiii/report/legacy-cutoff';
    public readonly _exportTitleIIIData = '/api/titleiii/report/export';
    public readonly _get5yrLegacyReport: string = 'api/titleiii/report/legacyFiveYear';

    // TITLE 6 REPORTS
    // Two Year Variance Report with Explanations
    public readonly _tviTwoYearVarianceReport: string = 'api/titlevi/report/two-year-variance';
    // Two-Year Comparison Overview Report Across Grantees
    public readonly _tviTwoYearComparisonReport: string = 'api/titlevi/report/two-year-comparison';
    // Multi-Year Trend Report
    public readonly _tviMultiYearTrendReport: string = 'api/titlevi/report/multi-year-trend';
    // Multi-Year Trend Overview Report Across Grantees
    public readonly _tviMultiYearTrendOverviewReport: string = 'api/titlevi/report/multi-year-trend-across-grantees';
    // NSIP Variance Report Across Grantees
    public readonly _tviNsipVarianceReport: string = 'api/titlevi/report/nsip-variance-across-grantees';
    // Multi-Grantee Data Export
    public readonly _tviMultiGranteeDataExport: string = 'api/titlevi/report/multi-grantee-data-export';
    // PPR Submission Status Report
    public readonly _tviPprSubmissionStatusReport: string = 'api/titlevi/report/ppr-submission-status';
    // Legacy Report
    public readonly _tviLegacyReport: string = '/api/titlevi/report/legacy-report';
    public readonly _tviExportData = '/api/titlevi/report/{ffy}/{granteeId}';
    public readonly _dataExport: string = '/api/report/ad-hoc';
    public readonly _clearData: string = '/api/report/clear-data';
    public readonly _uploadDataExport: string = '/api/report/export';
    public readonly _getTVILegacyDataCutoffYear = 'api/titlevi/report/legacy-cutoff';
    public readonly _downloadFile: string = '/api/report/downloadfile';

    public reportSettings: IReportSettings = null; // Set this to use in report-settings component

    constructor(public _http: HttpClient,
        public _claimService: ClaimService) {
    }

    public generateNationalRollupExport(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
        return this._http.post<ITransactionCreatedResponse>(this._exportTitleIIIData, reportDetails, {
            withCredentials: true
        });
    }

    public generateTableA1Report(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
        return this._http.post<ITransactionCreatedResponse>(this._getTableA1Report, reportDetails, {
            withCredentials: true
        });
    }

    public generateActivityAnalysisReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getActivityAnalysisReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    exporttitleviidata(ffy: string, state: string): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', this._exportTitleVIIData.replace('{ffy}', ffy).replace('{state}', state), true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                        console.log('error');
                    }
                }
            };
            xhr.send();
        });
        return this._claimService.injectToken(req);
    }

    public generateAnnualRollupReport(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
        return this._http.post<ITransactionCreatedResponse>(this._getAnnualRollupReport, reportDetails, {
            withCredentials: true
        });
    }

    public generateComplaintCodeAnalysisReport(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
        return this._http.post<ITransactionCreatedResponse>(this._getComplaintCodeAnalysisReport, reportDetails, {
            withCredentials: true
        });
    }

    public generateComplainantCodeAnalysisReport(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
        return this._http.post<ITransactionCreatedResponse>(this._getComplainantCodeAnalysisReport, reportDetails, {
            withCredentials: true
        });
    }

    public generateTIIILegacyReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getTIIILegacyReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.status);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public generateCasesClosedComplaintsChart(years: YearState[]): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getCasesClosedComplaintsChart, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(years));
        });
        return this._claimService.injectToken(req);
    }

    public generate2yrReport(reportDetails: YearStateRegionReport, titleNumber: string): Observable<ITransactionCreatedResponse> {
        if (titleNumber !== 'T3') {
            return this._http.post<ITransactionCreatedResponse>(this._get2yrReport, reportDetails, {
                withCredentials: true
            });
        } else {
            const req = (token) => new Observable<any>(observer => {
                const xhr = new XMLHttpRequest();
                let url = this._get2yrReport;
                if (titleNumber === 'T3') {
                    url = this._get2yrTitleIIIReport;
                }
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Content-type', 'application/json');
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                xhr.responseType = 'blob';

                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                            const blob = new Blob([xhr.response], { type: contentType });
                            observer.next(blob);
                            observer.complete();
                        } else {
                            observer.error(xhr.status);
                        }
                    }
                };
                xhr.send(JSON.stringify(reportDetails));
            });
            return this._claimService.injectToken(req);
        }
    }

    public generate5yrReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._get5yrReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public generateLegacyReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getLegacyReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public getLegacyDataCutoffYear(title: string): Observable<string> {
        let url = this._getLegacyDataCutoffYear;
        if(title === 'III')
            url = this._getTIIILegacyDataCutoffYear;
        else if(title === 'VI')
            url = this._getTVILegacyDataCutoffYear;
        return this._http.get<string>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<string>())
        );
    }

    public getEmbedConfig(): Observable<EmbedConfig> {
        return this._http.get<EmbedConfig>(this._getEmbedConfig, { withCredentials: true }).pipe(
            catchError(this.handleError<EmbedConfig>())
        );
    }

    public generateTIIIServiceUnitExpReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getTIIIServiceUnitExpReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public generateTIIIPersonsServedServiceUnitsReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getTIIIPersonsServedServiceUnitsReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public generateTIIINsipReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getTIIINsipReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public generateTIIIeReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getTIIIeReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public generateSubmissionHistoryReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getSubmissionHistoryReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public generateClientCharacteristicsReport(reportDetails: YearStateRegionReport): Observable<object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getClientCharacteristicsReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public generateMissingDataReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getMissingDataReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }


    exporttitleiiidata(ffy: string, state: string, psa: string, rollupType: RollupTypes): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
          const xhr = new XMLHttpRequest();
         const reportDetails: Array<YearStateRegion> = [];
         reportDetails.push(new YearStateRegion(ffy, state, psa, null, rollupType === RollupTypes.StateRollup));
         const report: YearStateRegionReport = new YearStateRegionReport(reportDetails);
         report.reportName = "export";
         report.createdBy = this._claimService.userID;
         const url = this._exportTitleIIIData;
          xhr.open('POST', url, true);
          xhr.setRequestHeader('Content-type', 'application/json');
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          xhr.responseType = 'blob';
          xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const blob = new Blob([xhr.response], { type: contentType });
                observer.next(blob);
                observer.complete();
              } else {
                observer.error(xhr.response);
                console.log('error');
              }
            }
          };
          xhr.send(JSON.stringify(report));
        });
        return this._claimService.injectToken(req);
      }

      exportUploaddata(reportDetails: YearStateRegionReport): Observable<{ tid: string }> {
        const title = this._claimService.currentTitle;
         const url = `${this._uploadDataExport}/${title}`;

        return this._http.post<{ tid: string }>(url, reportDetails, {
            withCredentials: true
        });
    }

    public generateReportHasData(reportDetails: YearStateRegionReport): Observable<string> {
        return this._http.post<string>(this._getHasData, reportDetails, { withCredentials: true }).pipe(
            catchError(this.handleError<string>())
        );
    }
    public generateTitleVIIExpenditureReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getTitleVIIExpenditureReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public generateMissingDataByStateReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._getMissingDataByStateReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public generateTiiiLegacyOtherServicesReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
      return this.postSyncReportRequest(this._getLegacyOtherServicesReport, reportDetails);
    }

    public generateTiiiLegacyPersonsServedUnderOAAReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        return this.postSyncReportRequest(this._getLegacyPersonsServedUnderOAAReport, reportDetails);
    }

    public generateTiiiLegacyServiceUnitsForSelectedCluster3Report(reportDetails: YearStateRegionReport): Observable<Object[]> {
        return this.postSyncReportRequest(this._getLegacyServiceUnitsForSelectedCluster3Report, reportDetails);
    }

    public generateLegacyNFCSPReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        return this.postSyncReportRequest(this._getLegacyNFCSPReport, reportDetails);
    }

    public generateLegacyTwoYearReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        return this.postSyncReportRequest(this._getLegacyTwoYearReport, reportDetails);
    }

    public generate5yrLegacyReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
      return this.postSyncReportRequest(this._get5yrLegacyReport, reportDetails);
    }
      // TITLE 6 REPORTS

    // Two Year Variance Report with Explanations
    public tviTwoYearVarianceReport(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
      return this._http.post<ITransactionCreatedResponse>(this._tviTwoYearVarianceReport, reportDetails, {
        withCredentials: true
      });
    }

    // Two-Year Comparison Overview Report Across Grantees
    public tviTwoYearComparisonReport(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
      return this._http.post<ITransactionCreatedResponse>(this._tviTwoYearComparisonReport, reportDetails, {
        withCredentials: true
      });
    }

    // Multi-Year Trend Report
    public tviMultiYearTrendReport(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
      return this._http.post<ITransactionCreatedResponse>(this._tviMultiYearTrendReport, reportDetails, {
        withCredentials: true
      });
    }

    // Multi-Year Trend Overview Report Across Grantees
    public tviMultiYearTrendOverviewReport(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
      return this._http.post<ITransactionCreatedResponse>(this._tviMultiYearTrendOverviewReport, reportDetails, {
        withCredentials: true
      });
    }

    // NSIP Variance Report Across Grantees
    public tviNsipVarianceReport(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
      return this._http.post<ITransactionCreatedResponse>(this._tviNsipVarianceReport, reportDetails, {
        withCredentials: true
      });
    }

    // Multi-Grantee Data Export
    public tviMultiGranteeDataExport(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
      return this._http.post<ITransactionCreatedResponse>(this._tviMultiGranteeDataExport, reportDetails, {
        withCredentials: true
      });
    }

    // PPR Submission Status Report
    public tviPprSubmissionStatusReport(reportDetails: YearStateRegionReport): Observable<ITransactionCreatedResponse> {
      return this._http.post<ITransactionCreatedResponse>(this._tviPprSubmissionStatusReport, reportDetails, {
        withCredentials: true
      });
    }

    // Legacy Report
    public tviLegacyReport(reportDetails: YearStateRegionReport): Observable<any> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._tviLegacyReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const fileName = xhr.getResponseHeader('Content-Disposition').split("filename=")[1].split(".xlsx; filename")[0];
                        const blob = xhr.response;//new Blob([xhr.response], { type: contentType });
                        observer.next({blob: blob, fileName: fileName});
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    exporttitlevidata(ffy: string, org: string): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', this._tviExportData.replace('{ffy}', ffy).replace('{granteeId}', org), true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                        console.log('success');
                    } else {
                        observer.error(xhr.response);
                        console.log('error');
                    }
                }
            };
            xhr.send();
        });
        return this._claimService.injectToken(req);
    }
    public generateDataExport(reportDetails: YearStateRegionReport, title: string): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${this._dataExport}/${title}`, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });
        return this._claimService.injectToken(req);
    }

    public clearTIIIStateData(ffy:string, org:string): Observable<string> {
        return this._http.get<string>(`${this._clearData}/iii/${ffy}/${org}`, { withCredentials: true }).pipe(
            catchError(this.handleError<string>())
        );
    }

    public downloadFile(transactionId: string): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${this._downloadFile}/${transactionId}`, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send();
        });
        return this._claimService.injectToken(req);
    }

    public getTransactionDetails(route: string, tid: string): Observable<ITransaction> {
        return this._http.get<ITransaction>(route, {
            withCredentials: true,
            params: {
                tid: tid
            }
        })
    }

    private postSyncReportRequest(endpoint: string, reportDetails: YearStateRegionReport) {
      const req = (token) => new Observable<any>(observer => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', endpoint, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.responseType = 'blob';
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    const blob = new Blob([xhr.response], { type: contentType });
                    observer.next(blob);
                    observer.complete();
                } else {
                    observer.error(xhr.response);
                }
            }
        };
        xhr.send(JSON.stringify(reportDetails));
      });
      return this._claimService.injectToken(req);
    }

    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
