<div class="flex-list-item">
  <div class="panel panel-primary-inverted flex-list-content" [ngClass]="{ active: active }">
    <div class="panel-body">
      <p>
        <strong>{{title}}</strong>
      </p>
      <hr />
      <small>{{description}}</small>
    </div>
  </div>
</div>