<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <h2>Title III Service Expenditures - Older Relative Caregivers Expenditures</h2>
        <p class="info mt-1">All fields are required</p>
        <div class="col-md-12 background-gray-lighter mb-1">
          <h2>Total Older Relative Caregivers Expenditures</h2>
        </div>
        <div class="col-md-9">
          <label for="expend" class="normal">Title III-E expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" id="expend" value="{{t3ExpendTotal}}" readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label for="expendOtherState" class="normal">Other: State expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" id="expendOtherState" value="{{otherStateTotal}}" readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label for="expendOtherNonState" class="normal">Other: Non-state expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" id="expendOtherNonState" value="{{nonOtherStateTotal}}" readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label for="programIncome" class="normal">Program income expended</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" id="programIncome" value="{{programIncomeTotal}}" readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label for="expendTotal" class="normal">Total service expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" id="expendTotal" value="{{serviceExpendTotal}}" readonly>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-1">
          <!-- error -->
          <i *ngIf="hasError(registeredCluster)" aria-hidden="true" class="fa fa-exclamation-circle text-danger">
            <span class="sr-only">Error:</span>
          </i>
          <!-- warning -->
          <i *ngIf="hasWarning(registeredCluster)" aria-hidden="true" class="fa fa-exclamation-triangle text-warning">
            <span class="sr-only">Warning:</span>
          </i>
          <a id="registeredServicesLnk" class="btn btn-link" data-target="#myModal" data-toggle="modal"
            tabindex="0" href="#">View /
            Edit Utilization and Expenditure for Older Relative Caregivers Registered Services</a>
        </div>

        <div class="col-md-12 mb-1">
          <!-- error -->
          <i *ngIf="hasError(respiteCluster)" aria-hidden="true" class="fa fa-exclamation-circle text-danger">
            <span class="sr-only">Error:</span>
          </i>
          <!-- warning -->
          <i *ngIf="hasWarning(respiteCluster)" aria-hidden="true" class="fa fa-exclamation-triangle text-warning">
            <span class="sr-only">Warning:</span>
          </i>
          <a id="respiteServicesLnk" class="btn btn-link" data-target="#myModal2" data-toggle="modal" tabindex="0" href="#">View
            / Edit Utilization and Expenditure for Respite Services</a>
        </div>

        <div class="col-md-12 mb-1">
          <!-- error -->
          <i *ngIf="hasError(unregisteredCluster)" aria-hidden="true" class="fa fa-exclamation-circle text-danger">
            <span class="sr-only">Error:</span>
          </i>
          <!-- warning -->
          <i *ngIf="hasWarning(unregisteredCluster)" aria-hidden="true" class="fa fa-exclamation-triangle text-warning">
            <span class="sr-only">Warning:</span>
          </i>
          <a id="unregisteredServicesLnk" class="btn btn-link" data-target="#myModal3" data-toggle="modal"
            tabindex="0" href="#">View
            / Edit Utilization and Expenditure for Older Relative Caregivers Unregistered Services</a>
        </div>

        <!-- Button trigger modal -->
        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div class="modal-dialog modal-xlg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title" id="myModalLabel">Utilization and Expenditure for Older Relative Caregivers Registered Services</h4>
              </div>
              <div class="modal-body">

                <table *ngIf="registeredErrors().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-error">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Error(s):
                        </span>
                        <p *ngFor="let registeredErrorItem of registeredErrors()" class="help-block">
                          {{registeredErrorItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table *ngIf="registeredWarnings().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-warning">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):
                        </span>
                        <p *ngFor="let registeredWarnItem of registeredWarnings()" class="help-block">
                          {{registeredWarnItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="table-responsive">
                  <table class="table table-no-border">
                    <caption class="sr-only">Older Relative Caregivers Registered Services</caption>
                    <thead>
                      <tr class="background-gray-lighter">
                        <td width="25%"></td>
                        <th width="15%" id="counseling" scope="col">
                          <a id="titleIIIORCCounselingLnk" [routerLink]="['../titleIIIORCCounseling']" data-dismiss="modal">Counseling</a>
                        </th>
                        <th width="15%" id="training" scope="col">
                          <a id="titleIIIORCTrainingLnk" [routerLink]="['../titleIIIORCTraining']" data-dismiss="modal">Training</a>
                        </th>
                        <th width="15%" id="supplementalServices" scope="col">
                          <a id="titleIIIORCSuppProvidedLnk" [routerLink]="['../titleIIIORCSuppProvided']" data-dismiss="modal">Supplemental
                            Services</a>
                        </th>
                        <th width="15%" id="caseManagementAssistance" scope="col">
                          <a id="titleIIIORCCaseMgmtLnk" [routerLink]="['../titleIIIORCCaseMgmt']" data-dismiss="modal">Case Management
                            Assistance</a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="normal" id="totalupserved" scope="row" width="40%">Total
                          unduplicated
                          persons served</th>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_counselingSectionModel.totalPersonsServed.questionStatus)">
                            <input
                              (change)="unsavedChanges = true; _counselingSectionModel.totalPersonsServed = validate(_counselingSectionModel.totalPersonsServed); checkValidForm('registered'); _counselingSectionModel.totalPersonsServed.elementValue = addComma(_counselingSectionModel.totalPersonsServed.elementValue)"
                              [(ngModel)]="_counselingSectionModel.totalPersonsServed.elementValue"
                              [disabled]="!_counselingSectionModel.totalPersonsServed.isRequired" type="text"
                              class="form-control" id="counselingUnduplicated"
                              aria-labelledby="counseling totalupserved">
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_trainingSectionModel.totalPersonsServed.questionStatus)">
                            <input
                              (change)="unsavedChanges = true; _trainingSectionModel.totalPersonsServed = validate(_trainingSectionModel.totalPersonsServed); checkValidForm('registered'); _trainingSectionModel.totalPersonsServed.elementValue = addComma(_trainingSectionModel.totalPersonsServed.elementValue)"
                              [(ngModel)]="_trainingSectionModel.totalPersonsServed.elementValue"
                              [disabled]="!_trainingSectionModel.totalPersonsServed.isRequired" type="text"
                              class="form-control" id="trainingUnduplicated" aria-labelledby="training totalupserved">
                          </div>
                        </td>
                        <td class="background-gray-lighter"
                          [ngClass]="calculateOutlineColor(_otherServicesSectionModel.totalServiceUnits.questionStatus)">
                          <input
                            (change)="unsavedChanges = true; updatePerUnit(); _otherServicesSectionModel.totalServiceUnits = validate(_otherServicesSectionModel.totalServiceUnits); checkValidForm('registered'); _otherServicesSectionModel.totalServiceUnits.elementValue = addComma(_otherServicesSectionModel.totalServiceUnits.elementValue)"
                            [(ngModel)]="_otherServicesSectionModel.totalServiceUnits.elementValue" type="text"
                            class="form-control" id="suppUnduplicated"
                            aria-labelledby="supplementalServices serviceUnits">
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_caseMgmtSectionModel.totalPersonsServed.questionStatus)">
                            <input
                              (change)="unsavedChanges = true; _caseMgmtSectionModel.totalPersonsServed = validate(_caseMgmtSectionModel.totalPersonsServed); checkValidForm('registered'); _caseMgmtSectionModel.totalPersonsServed.elementValue = addComma(_caseMgmtSectionModel.totalPersonsServed.elementValue)"
                              [(ngModel)]="_caseMgmtSectionModel.totalPersonsServed.elementValue"
                              [disabled]="!_caseMgmtSectionModel.totalPersonsServed.isRequired" type="text"
                              class="form-control" id="caseUnduplicated"
                              aria-labelledby="caseManagementAssistance totalupserved">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="serviceUnits" scope="row">Services units</th>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_counselingSectionModel.totalServiceUnits.questionStatus)">
                            <input [(ngModel)]="_counselingSectionModel.totalServiceUnits.elementValue"
                              [disabled]="!_counselingSectionModel.totalServiceUnits.isRequired" type="text"
                              class="form-control" id="counselingUnits" aria-labelledby="counseling serviceUnits"
                              (change)="unsavedChanges = true; updatePerUnit(); _counselingSectionModel.totalServiceUnits = validate(_counselingSectionModel.totalServiceUnits); checkValidForm('registered'); _counselingSectionModel.totalServiceUnits.elementValue = addComma(_counselingSectionModel.totalServiceUnits.elementValue)">
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_trainingSectionModel.totalServiceUnits.questionStatus)">
                            <input [(ngModel)]="_trainingSectionModel.totalServiceUnits.elementValue"
                              [disabled]="!_trainingSectionModel.totalServiceUnits.isRequired" type="text"
                              class="form-control" id="trainingUnits" aria-labelledby="training serviceUnits"
                              (change)="unsavedChanges = true; updatePerUnit(); _trainingSectionModel.totalServiceUnits = validate(_trainingSectionModel.totalServiceUnits); checkValidForm('registered'); _trainingSectionModel.totalServiceUnits.elementValue = addComma(_trainingSectionModel.totalServiceUnits.elementValue)">
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <!-- supplemental service units -->
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_caseMgmtSectionModel.totalServiceUnits.questionStatus)">
                            <input [(ngModel)]="_caseMgmtSectionModel.totalServiceUnits.elementValue"
                              [disabled]="!_caseMgmtSectionModel.totalServiceUnits.isRequired" type="text"
                              class="form-control" id="caseUnits"
                              aria-labelledby="caseManagementAssistance serviceUnits"
                              (change)="unsavedChanges = true; updatePerUnit(); _caseMgmtSectionModel.totalServiceUnits = validate(_caseMgmtSectionModel.totalServiceUnits); checkValidForm('registered'); _caseMgmtSectionModel.totalServiceUnits.elementValue = addComma(_caseMgmtSectionModel.totalServiceUnits.elementValue)">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="titleIIIExpenditure1" scope="row">Title III-E
                          expenditures</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_counselingSectionModel.expend.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_counselingSectionModel.expend.elementValue"
                                [disabled]="!_counselingSectionModel.expend.isRequired" type="text" class="form-control"
                                id="couselingExp" aria-labelledby="counseling titleIIIExpenditure1"
                                (change)="unsavedChanges = true;
                                                        _counselingSectionModel.expendTotal.elementValue = updateTotal(counselingArr);
                                                        updatePerUnit(); _counselingSectionModel.expend = validate(_counselingSectionModel.expend); checkValidForm('registered')">
                            </div>
                          </div>

                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_trainingSectionModel.expend.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_trainingSectionModel.expend.elementValue"
                                [disabled]="!_trainingSectionModel.expend.isRequired" type="text" class="form-control"
                                id="trainingExp" aria-labelledby="training titleIIIExpenditure1"
                                (change)="unsavedChanges = true;
                                                        _trainingSectionModel.expendTotal.elementValue = updateTotal(trainingArr);
                                                        updatePerUnit(); _trainingSectionModel.expend = validate(_trainingSectionModel.expend); checkValidForm('registered')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_otherServicesSectionModel.expend.elementValue" type="text"
                              class="form-control" id="suppExp"
                              aria-labelledby="supplementalServices titleIIIExpenditure1" readonly>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_caseMgmtSectionModel.expend.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_caseMgmtSectionModel.expend.elementValue"
                                [disabled]="!_caseMgmtSectionModel.expend.isRequired" type="text" class="form-control"
                                id="caseExp" aria-labelledby="caseManagementAssistance titleIIIExpenditure1"
                                (change)="unsavedChanges = true;
                                                        _caseMgmtSectionModel.expendTotal.elementValue = updateTotal(caseMgmtArr);
                                                        updatePerUnit(); _caseMgmtSectionModel.expend = validate(_caseMgmtSectionModel.expend); checkValidForm('registered')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="otherStateExpenditure1" scope="row">
                          Other: State expenditure
                        </th>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_counselingSectionModel.expendOtherState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_counselingSectionModel.expendOtherState.elementValue"
                                [disabled]="!_counselingSectionModel.expendOtherState.isRequired" type="text"
                                class="form-control" id="couselingOtherState"
                                aria-labelledby="counseling otherStateExpenditure1"
                                (change)="unsavedChanges = true; _counselingSectionModel.expendTotal.elementValue = updateTotal(counselingArr); updatePerUnit(); _counselingSectionModel.expendOtherState = validate(_counselingSectionModel.expendOtherState); checkValidForm('registered')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_trainingSectionModel.expendOtherState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_trainingSectionModel.expendOtherState.elementValue"
                                [disabled]="!_trainingSectionModel.expendOtherState.isRequired" type="text"
                                class="form-control" id="trainingOtherState"
                                aria-labelledby="training otherStateExpenditure1"
                                (change)="unsavedChanges = true; _trainingSectionModel.expendTotal.elementValue = updateTotal(trainingArr); updatePerUnit(); _trainingSectionModel.expendOtherState = validate(_trainingSectionModel.expendOtherState); checkValidForm('registered')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_otherServicesSectionModel.expendOtherState.elementValue" type="text"
                              class="form-control" id="suppOtherState"
                              aria-labelledby="supplementalServices otherStateExpenditure1" readonly>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_caseMgmtSectionModel.expendOtherState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_caseMgmtSectionModel.expendOtherState.elementValue"
                                [disabled]="!_caseMgmtSectionModel.expendOtherState.isRequired" type="text"
                                class="form-control" id="caseOtherState"
                                aria-labelledby="caseManagementAssistance otherStateExpenditure1"
                                (change)="unsavedChanges = true; _caseMgmtSectionModel.expendTotal.elementValue = updateTotal(caseMgmtArr); updatePerUnit(); _caseMgmtSectionModel.expendOtherState = validate(_caseMgmtSectionModel.expendOtherState); checkValidForm('registered')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="otherNonStateExpenditure1" scope="row">Other:
                          Non-state expenditure</th>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_counselingSectionModel.expendOtherNonState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_counselingSectionModel.expendOtherNonState.elementValue"
                                [disabled]="!_counselingSectionModel.expendOtherNonState.isRequired" type="text"
                                class="form-control" id="couselingOtherNon"
                                aria-labelledby="counseling otherNonStateExpenditure1"
                                (change)="unsavedChanges = true; _counselingSectionModel.expendTotal.elementValue = updateTotal(counselingArr); updatePerUnit(); _counselingSectionModel.expendOtherNonState = validate(_counselingSectionModel.expendOtherNonState); checkValidForm('registered')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_trainingSectionModel.expendOtherNonState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_trainingSectionModel.expendOtherNonState.elementValue"
                                [disabled]="!_trainingSectionModel.expendOtherNonState.isRequired" type="text"
                                class="form-control" id="trainingOtherNon"
                                aria-labelledby="training otherNonStateExpenditure1"
                                (change)="unsavedChanges = true; _trainingSectionModel.expendTotal.elementValue = updateTotal(trainingArr); updatePerUnit(); _trainingSectionModel.expendOtherNonState = validate(_trainingSectionModel.expendOtherNonState); checkValidForm('registered')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_otherServicesSectionModel.expendOtherNonState.elementValue" type="text"
                              class="form-control" id="suppOtherNon"
                              aria-labelledby="supplementalServices otherNonStateExpenditure1" readonly>
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_caseMgmtSectionModel.expendOtherNonState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_caseMgmtSectionModel.expendOtherNonState.elementValue"
                                [disabled]="!_caseMgmtSectionModel.expendOtherNonState.isRequired" type="text"
                                class="form-control" id="caseOtherNon"
                                aria-labelledby="caseManagementAssistance otherNonStateExpenditure1"
                                (change)="unsavedChanges = true; _caseMgmtSectionModel.expendTotal.elementValue = updateTotal(caseMgmtArr); updatePerUnit(); _caseMgmtSectionModel.expendOtherNonState = validate(_caseMgmtSectionModel.expendOtherNonState); checkValidForm('registered')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="programIncomeExpended" scope="row">Program
                          income expended</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_counselingSectionModel.programIncome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_counselingSectionModel.programIncome.elementValue"
                                [disabled]="!_counselingSectionModel.programIncome.isRequired" type="text"
                                class="form-control" id="counselingProgram"
                                aria-labelledby="counseling programIncomeExpended"
                                (change)="unsavedChanges = true; _counselingSectionModel.expendTotal.elementValue = updateTotal(counselingArr); updatePerUnit(); _counselingSectionModel.programIncome = validate(_counselingSectionModel.programIncome); checkValidForm('registered')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_trainingSectionModel.programIncome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_trainingSectionModel.programIncome.elementValue"
                                [disabled]="!_trainingSectionModel.programIncome.isRequired" type="text"
                                class="form-control" id="trainingProgram"
                                aria-labelledby="training programIncomeExpended"
                                (change)="unsavedChanges = true; _trainingSectionModel.expendTotal.elementValue = updateTotal(trainingArr); updatePerUnit(); _trainingSectionModel.programIncome = validate(_trainingSectionModel.programIncome); checkValidForm('registered')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_otherServicesSectionModel.programIncome.elementValue" type="text"
                              class="form-control" id="suppProgram"
                              aria-labelledby="supplementalServices programIncomeExpended" readonly>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_caseMgmtSectionModel.programIncome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_caseMgmtSectionModel.programIncome.elementValue"
                                [disabled]="!_caseMgmtSectionModel.programIncome.isRequired" type="text"
                                class="form-control" id="caseProgram"
                                aria-labelledby="caseManagementAssistance programIncomeExpended"
                                (change)="unsavedChanges = true; _caseMgmtSectionModel.expendTotal.elementValue = updateTotal(caseMgmtArr); updatePerUnit(); _caseMgmtSectionModel.programIncome = validate(_caseMgmtSectionModel.programIncome); checkValidForm('registered')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th id="totalExpenditure" scope="row">Total expenditures</th>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_counselingSectionModel.expendTotal.elementValue"
                              [disabled]="!_counselingSectionModel.expendTotal.isRequired" type="text"
                              class="form-control" id="counselingTotal" aria-labelledby="counseling totalExpenditure"
                              readonly>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_trainingSectionModel.expendTotal.elementValue"
                              [disabled]="!_trainingSectionModel.expendTotal.isRequired" type="text"
                              class="form-control" id="trainingTotal" aria-labelledby="training totalExpenditure"
                              readonly>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_otherServicesSectionModel.expendTotal.elementValue" type="text"
                              class="form-control" id="suppTotal"
                              aria-labelledby="supplementalServices totalExpenditure" readonly>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_caseMgmtSectionModel.expendTotal.elementValue"
                              [disabled]="!_caseMgmtSectionModel.expendTotal.isRequired" type="text"
                              class="form-control" id="caseTotal"
                              aria-labelledby="caseManagementAssistance totalExpenditure" readonly>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th id="totalExpendituresPerPersonHours1" scope="row">Total
                          expenditures per unit</th>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <input [(ngModel)]="_counselingSectionModel.totalExpendPerUnit.elementValue"
                              [disabled]="!_counselingSectionModel.totalExpendPerUnit.isRequired" type="text"
                              class="form-control" id="counselingPerUnit"
                              aria-labelledby="counseling totalExpendituresPerPersonHours1" readonly>
                            <div class="input-group-addon">/hour</div>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <input [(ngModel)]="_trainingSectionModel.totalExpendPerUnit.elementValue"
                              [disabled]="!_trainingSectionModel.totalExpendPerUnit.isRequired" type="text"
                              class="form-control" id="trainingPerUnit"
                              aria-labelledby="training totalExpendituresPerPersonHours1" readonly>
                            <div class="input-group-addon">/hour</div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <input [(ngModel)]="_otherServicesSectionModel.totalExpendPerUnit.elementValue" type="text"
                              class="form-control" id="suppPerUnit"
                              aria-labelledby="supplementalServices totalExpendituresPerPersonHours1" readonly>
                            <div class="input-group-addon">/person</div>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_caseMgmtSectionModel.totalExpendPerUnit.elementValue"
                              [disabled]="!_caseMgmtSectionModel.totalExpendPerUnit.isRequired" type="text"
                              class="form-control" id="casePerUnit"
                              aria-labelledby="caseManagementAssistance totalExpendituresPerPersonHours1" readonly>
                            <div class="input-group-addon">/hour</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--  end table responsive  -->
              </div>
              <div class="modal-footer">
                <button id="orcSaveAllBtn1" type="button" *ngIf="validForm" class="btn btn-primary" data-dismiss="modal"
                  (click)="saveAllSections()">Save changes</button>
                <button id="orcSaveAllBtnDisabled1" type="button" *ngIf="!validForm" class="btn btn-primary"
                  data-dismiss="modal" disabled>Save changes</button>
                <button id="orcCloseBtn1" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Button trigger modal -->
        <div class="modal fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModal2Label">
          <div class="modal-dialog modal-xlg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title" id="myModal2Label">Utilization and Expenditure for Respite
                  Services</h4>
              </div>
              <div class="modal-body">

                <table *ngIf="respiteErrors().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-error">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Error(s):
                        </span>
                        <p *ngFor="let respiteErrorItem of respiteErrors()" class="help-block">{{respiteErrorItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table *ngIf="respiteWarnings().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-warning">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):
                        </span>
                        <p *ngFor="let respiteWarnItem of respiteWarnings()" class="help-block">{{respiteWarnItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="table-responsive">
                  <table class="table table-no-border">
                    <caption class="sr-only">Respite Expenditures</caption>
                    <thead>
                      <tr class="background-gray-lighter">
                        <td></td>
                        <th id="inHomeRespite">In-Home Respite</th>
                        <th id="ouOfHomeRespite">Out-of-Home Respite (day)</th>
                        <th id="outOfHomeRespitOvernight">Out-of-Home Respite (overnight)</th>
                        <th id="otherRespite">Other Respite</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th id="unduplicatedNoPerServedClusterOne" class="normal" width="40%">Total
                          unduplicated persons served</th>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.inhomeTotalUnduplicated.questionStatus)">
                            <input
                              (change)="unsavedChanges = true; _respiteSectionModel.inhomeTotalUnduplicated.elementValue = addComma(_respiteSectionModel.inhomeTotalUnduplicated.elementValue); _respiteSectionModel.inhomeTotalUnduplicated = validate(_respiteSectionModel.inhomeTotalUnduplicated); checkValidForm('respite')"
                              [(ngModel)]="_respiteSectionModel.inhomeTotalUnduplicated.elementValue"
                              [disabled]="!_respiteSectionModel.inhomeTotalUnduplicated.isRequired" type="text"
                              class="form-control" id="inhomeTotalUnduplicated"
                              aria-labelledby="inHomeRespite unduplicatedNoPerServedClusterOne">
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.outDayTotalUnduplicated.questionStatus)">
                            <input
                              (change)="unsavedChanges = true; _respiteSectionModel.outDayTotalUnduplicated.elementValue = addComma(_respiteSectionModel.outDayTotalUnduplicated.elementValue); _respiteSectionModel.outDayTotalUnduplicated = validate(_respiteSectionModel.outDayTotalUnduplicated); checkValidForm('respite')"
                              [(ngModel)]="_respiteSectionModel.outDayTotalUnduplicated.elementValue"
                              [disabled]="!_respiteSectionModel.outDayTotalUnduplicated.isRequired" type="text"
                              class="form-control" id="outDayTotalUnduplicated"
                              aria-labelledby="ouOfHomeRespite unduplicatedNoPerServedClusterOne">
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.outNightTotalUnduplicated.questionStatus)">
                            <input
                              (change)="unsavedChanges = true; _respiteSectionModel.outNightTotalUnduplicated.elementValue = addComma(_respiteSectionModel.outNightTotalUnduplicated.elementValue); _respiteSectionModel.outNightTotalUnduplicated = validate(_respiteSectionModel.outNightTotalUnduplicated); checkValidForm('respite')"
                              [(ngModel)]="_respiteSectionModel.outNightTotalUnduplicated.elementValue"
                              [disabled]="!_respiteSectionModel.outNightTotalUnduplicated.isRequired" type="text"
                              class="form-control" id="outNightTotalUnduplicated"
                              aria-labelledby="outOfHomeRespitOvernight unduplicatedNoPerServedClusterOne">
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.unknownRespiteTotalUnduplicated.questionStatus)">
                            <input
                              (change)="unsavedChanges = true; _respiteSectionModel.unknownRespiteTotalUnduplicated.elementValue = addComma(_respiteSectionModel.unknownRespiteTotalUnduplicated.elementValue); _respiteSectionModel.unknownRespiteTotalUnduplicated = validate(_respiteSectionModel.unknownRespiteTotalUnduplicated); checkValidForm('respite')"
                              [(ngModel)]="_respiteSectionModel.unknownRespiteTotalUnduplicated.elementValue"
                              [disabled]="!_respiteSectionModel.unknownRespiteTotalUnduplicated.isRequired" type="text"
                              class="form-control" id="unknownRespiteTotalUnduplicated"
                              aria-labelledby="otherRespite unduplicatedNoPerServedClusterOne">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th id="totalServiceUnitsHours" class="normal">Total service units
                          (Hours)</th>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.inhomeTotalTotalService.questionStatus)">
                            <input [(ngModel)]="_respiteSectionModel.inhomeTotalTotalService.elementValue"
                              [disabled]="!_respiteSectionModel.inhomeTotalTotalService.isRequired" type="text"
                              class="form-control" id="inhomeTotalTotalService"
                              aria-labelledby="inHomeRespite totalServiceUnitsHours"
                              (change)="unsavedChanges = true; _respiteSectionModel.totalRespiteTotalService.elementValue = updateTotals(serviceUnitsArr, false); _respiteSectionModel.inhomeTotalTotalService.elementValue = addComma(_respiteSectionModel.inhomeTotalTotalService.elementValue); _respiteSectionModel.inhomeTotalTotalService = validate(_respiteSectionModel.inhomeTotalTotalService); checkValidForm('respite')">
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.outDayTotalService.questionStatus)">
                            <input [(ngModel)]="_respiteSectionModel.outDayTotalService.elementValue"
                              [disabled]="!_respiteSectionModel.outDayTotalService.isRequired" type="text"
                              class="form-control" id="outDayTotalService"
                              aria-labelledby="ouOfHomeRespite totalServiceUnitsHours"
                              (change)="unsavedChanges = true; _respiteSectionModel.totalRespiteTotalService.elementValue = updateTotals(serviceUnitsArr, false); _respiteSectionModel.outDayTotalService.elementValue = addComma(_respiteSectionModel.outDayTotalService.elementValue); _respiteSectionModel.outDayTotalService = validate(_respiteSectionModel.outDayTotalService); checkValidForm('respite')">
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.outNightTotalService.questionStatus)">
                            <input [(ngModel)]="_respiteSectionModel.outNightTotalService.elementValue"
                              [disabled]="!_respiteSectionModel.outNightTotalService.isRequired" type="text"
                              class="form-control" id="outNightTotalService"
                              aria-labelledby="outOfHomeRespitOvernight totalServiceUnitsHours"
                              (change)="unsavedChanges = true; _respiteSectionModel.totalRespiteTotalService.elementValue = updateTotals(serviceUnitsArr, false); _respiteSectionModel.outNightTotalService.elementValue = addComma(_respiteSectionModel.outNightTotalService.elementValue); _respiteSectionModel.outNightTotalService = validate(_respiteSectionModel.outNightTotalService); checkValidForm('respite')">
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.unknownRespiteTotalService.questionStatus)">
                            <input [(ngModel)]="_respiteSectionModel.unknownRespiteTotalService.elementValue"
                              [disabled]="!_respiteSectionModel.unknownRespiteTotalService.isRequired" type="text"
                              class="form-control" id="unknownRespiteTotalService"
                              aria-labelledby="otherRespite totalServiceUnitsHours"
                              (change)="unsavedChanges = true; _respiteSectionModel.totalRespiteTotalService.elementValue = updateTotals(serviceUnitsArr, false); _respiteSectionModel.unknownRespiteTotalService.elementValue = addComma(_respiteSectionModel.unknownRespiteTotalService.elementValue); _respiteSectionModel.unknownRespiteTotalService = validate(_respiteSectionModel.unknownRespiteTotalService); checkValidForm('respite')">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="titleIIIExpenditure">Title III expenditure</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_respiteSectionModel.title3InHome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.title3InHome.elementValue"
                                [disabled]="!_respiteSectionModel.title3InHome.isRequired" type="text"
                                class="form-control" id="title3InHome"
                                aria-labelledby="inHomeRespite titleIIIExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExInHome.elementValue = updateTotals(inHomeArr); _respiteSectionModel.title3Total.elementValue = updateTotals(title3Arr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.title3InHome.elementValue = addDecimals(_respiteSectionModel.title3InHome.elementValue); _respiteSectionModel.title3InHome = validate(_respiteSectionModel.title3InHome); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_respiteSectionModel.title3OutDay.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.title3OutDay.elementValue"
                                [disabled]="!_respiteSectionModel.title3OutDay.isRequired" type="text"
                                class="form-control" id="title3OutDay"
                                aria-labelledby="ouOfHomeRespite titleIIIExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExOutDay.elementValue = updateTotals(outDayArr); _respiteSectionModel.title3Total.elementValue = updateTotals(title3Arr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.title3OutDay.elementValue = addDecimals(_respiteSectionModel.title3OutDay.elementValue); _respiteSectionModel.title3OutDay = validate(_respiteSectionModel.title3OutDay); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_respiteSectionModel.title3OutNight.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.title3OutNight.elementValue"
                                [disabled]="!_respiteSectionModel.title3OutNight.isRequired" type="text"
                                class="form-control" id="title3OutNight"
                                aria-labelledby="outOfHomeRespitOvernight titleIIIExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExOutNight.elementValue = updateTotals(outNightArr); _respiteSectionModel.title3Total.elementValue = updateTotals(title3Arr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.title3OutNight.elementValue = addDecimals(_respiteSectionModel.title3OutNight.elementValue); _respiteSectionModel.title3OutNight = validate(_respiteSectionModel.title3OutNight); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_respiteSectionModel.title3Unknown.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.title3Unknown.elementValue"
                                [disabled]="!_respiteSectionModel.title3Unknown.isRequired" type="text"
                                class="form-control" id="title3Unknown"
                                aria-labelledby="otherRespite titleIIIExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExUnknown.elementValue = updateTotals(unknownArr); _respiteSectionModel.title3Total.elementValue = updateTotals(title3Arr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.title3Unknown.elementValue = addDecimals(_respiteSectionModel.title3Unknown.elementValue); _respiteSectionModel.title3Unknown = validate(_respiteSectionModel.title3Unknown); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="otherStateExpenditure">Other: State expenditure</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_respiteSectionModel.otherStateInHome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.otherStateInHome.elementValue"
                                [disabled]="!_respiteSectionModel.otherStateInHome.isRequired" type="text"
                                class="form-control" id="otherStateInHome"
                                aria-labelledby="inHomeRespite otherStateExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExInHome.elementValue = updateTotals(inHomeArr); _respiteSectionModel.otherStateTotal.elementValue = updateTotals(otherStateArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.otherStateInHome.elementValue = addDecimals(_respiteSectionModel.otherStateInHome.elementValue); _respiteSectionModel.otherStateInHome = validate(_respiteSectionModel.otherStateInHome); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_respiteSectionModel.otherStateOutDay.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.otherStateOutDay.elementValue"
                                [disabled]="!_respiteSectionModel.otherStateOutDay.isRequired" type="text"
                                class="form-control" id="otherStateOutDay"
                                aria-labelledby="ouOfHomeRespite otherStateExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExOutDay.elementValue = updateTotals(outDayArr); _respiteSectionModel.otherStateTotal.elementValue = updateTotals(otherStateArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.otherStateOutDay.elementValue = addDecimals(_respiteSectionModel.otherStateOutDay.elementValue); _respiteSectionModel.otherStateOutDay = validate(_respiteSectionModel.otherStateOutDay); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.otherStateOutNight.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.otherStateOutNight.elementValue"
                                [disabled]="!_respiteSectionModel.otherStateOutNight.isRequired" type="text"
                                class="form-control" id="otherStateOutNight"
                                aria-labelledby="outOfHomeRespitOvernight otherStateExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExOutNight.elementValue = updateTotals(outNightArr); _respiteSectionModel.otherStateTotal.elementValue = updateTotals(otherStateArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.otherStateOutNight.elementValue = addDecimals(_respiteSectionModel.otherStateOutNight.elementValue); _respiteSectionModel.otherStateOutNight = validate(_respiteSectionModel.otherStateOutNight); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_respiteSectionModel.otherStateUnknown.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.otherStateUnknown.elementValue"
                                [disabled]="!_respiteSectionModel.otherStateUnknown.isRequired" type="text"
                                class="form-control" id="otherStateUnknown"
                                aria-labelledby="otherRespite otherStateExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExUnknown.elementValue = updateTotals(unknownArr); _respiteSectionModel.otherStateTotal.elementValue = updateTotals(otherStateArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.otherStateUnknown.elementValue = addDecimals(_respiteSectionModel.otherStateUnknown.elementValue); _respiteSectionModel.otherStateUnknown = validate(_respiteSectionModel.otherStateUnknown); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="otherNonStateExpenditure">Other: Non-state
                          expenditure</th>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.otherNonStateInHome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.otherNonStateInHome.elementValue"
                                [disabled]="!_respiteSectionModel.otherNonStateInHome.isRequired" type="text"
                                class="form-control" id="otherNonStateInHome"
                                aria-labelledby="inHomeRespite otherNonStateExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExInHome.elementValue = updateTotals(inHomeArr); _respiteSectionModel.otherNonStateTotal.elementValue = updateTotals(otherNonStateArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.otherNonStateInHome.elementValue = addDecimals(_respiteSectionModel.otherNonStateInHome.elementValue); _respiteSectionModel.otherNonStateInHome = validate(_respiteSectionModel.otherNonStateInHome); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.otherNonStateOutDay.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.otherNonStateOutDay.elementValue"
                                [disabled]="!_respiteSectionModel.otherNonStateOutDay.isRequired" type="text"
                                class="form-control" id="otherNonStateOutDay"
                                aria-labelledby="ouOfHomeRespite otherNonStateExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExOutDay.elementValue = updateTotals(outDayArr); _respiteSectionModel.otherNonStateTotal.elementValue = updateTotals(otherNonStateArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.otherNonStateOutDay.elementValue = addDecimals(_respiteSectionModel.otherNonStateOutDay.elementValue); _respiteSectionModel.otherNonStateOutDay = validate(_respiteSectionModel.otherNonStateOutDay); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.otherNonStateOutNight.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.otherNonStateOutNight.elementValue"
                                [disabled]="!_respiteSectionModel.otherNonStateOutNight.isRequired" type="text"
                                class="form-control" id="otherNonStateOutNight"
                                aria-labelledby="outOfHomeRespitOvernight otherNonStateExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExOutNight.elementValue = updateTotals(outNightArr); _respiteSectionModel.otherNonStateTotal.elementValue = updateTotals(otherNonStateArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.otherNonStateOutNight.elementValue = addDecimals(_respiteSectionModel.otherNonStateOutNight.elementValue); _respiteSectionModel.otherNonStateOutNight = validate(_respiteSectionModel.otherNonStateOutNight); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_respiteSectionModel.otherNonStateUnknown.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.otherNonStateUnknown.elementValue"
                                [disabled]="!_respiteSectionModel.otherNonStateUnknown.isRequired" type="text"
                                class="form-control" id="otherNonStateUnknown"
                                aria-labelledby="otherRespite otherNonStateExpenditure"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExUnknown.elementValue = updateTotals(unknownArr); _respiteSectionModel.otherNonStateTotal.elementValue = updateTotals(otherNonStateArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.otherNonStateUnknown.elementValue = addDecimals(_respiteSectionModel.otherNonStateUnknown.elementValue); _respiteSectionModel.otherNonStateUnknown = validate(_respiteSectionModel.otherNonStateUnknown); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="programIncome">Program income expended</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_respiteSectionModel.programInHome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.programInHome.elementValue"
                                [disabled]="!_respiteSectionModel.programInHome.isRequired" type="text"
                                class="form-control" id="programInHome" aria-labelledby="inHomeRespite programIncome"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExInHome.elementValue = updateTotals(inHomeArr); _respiteSectionModel.programTotal.elementValue = updateTotals(programArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.programInHome.elementValue = addDecimals(_respiteSectionModel.programInHome.elementValue); _respiteSectionModel.programInHome = validate(_respiteSectionModel.programInHome); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_respiteSectionModel.programOutDay.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.programOutDay.elementValue"
                                [disabled]="!_respiteSectionModel.programOutDay.isRequired" type="text"
                                class="form-control" id="programOutDay" aria-labelledby="ouOfHomeRespite programIncome"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExOutDay.elementValue = updateTotals(outDayArr); _respiteSectionModel.programTotal.elementValue = updateTotals(programArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.programOutDay.elementValue = addDecimals(_respiteSectionModel.programOutDay.elementValue); _respiteSectionModel.programOutDay = validate(_respiteSectionModel.programOutDay); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_respiteSectionModel.programOutNight.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.programOutNight.elementValue"
                                [disabled]="!_respiteSectionModel.programOutNight.isRequired" type="text"
                                class="form-control" id="programOutNight"
                                aria-labelledby="outOfHomeRespitOvernight programIncome"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExOutNight.elementValue = updateTotals(outNightArr); _respiteSectionModel.programTotal.elementValue = updateTotals(programArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.programOutNight.elementValue = addDecimals(_respiteSectionModel.programOutNight.elementValue); _respiteSectionModel.programOutNight = validate(_respiteSectionModel.programOutNight); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_respiteSectionModel.programUnknown.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_respiteSectionModel.programUnknown.elementValue"
                                [disabled]="!_respiteSectionModel.programUnknown.isRequired" type="text"
                                class="form-control" id="programUnknown" aria-labelledby="otherRespite programIncome"
                                (change)="unsavedChanges = true; _respiteSectionModel.totalExUnknown.elementValue = updateTotals(unknownArr); _respiteSectionModel.programTotal.elementValue = updateTotals(programArr); _respiteSectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _respiteSectionModel.programUnknown.elementValue = addDecimals(_respiteSectionModel.programUnknown.elementValue); _respiteSectionModel.programUnknown = validate(_respiteSectionModel.programUnknown); checkValidForm('respite')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th id="totalExpenditures">Total expenditures</th>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_respiteSectionModel.totalExInHome.elementValue"
                              [disabled]="!_respiteSectionModel.totalExInHome.isRequired" type="text"
                              class="form-control" id="totalExInHome" aria-labelledby="inHomeRespite totalExpenditures"
                              readonly>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_respiteSectionModel.totalExOutDay.elementValue"
                              [disabled]="!_respiteSectionModel.totalExOutDay.isRequired" type="text"
                              class="form-control" id="totalExOutDay"
                              aria-labelledby="ouOfHomeRespite totalExpenditures" readonly>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_respiteSectionModel.totalExOutNight.elementValue"
                              [disabled]="!_respiteSectionModel.totalExOutNight.isRequired" type="text"
                              class="form-control" id="totalExOutNight"
                              aria-labelledby="outOfHomeRespitOvernight totalExpenditures" readonly>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_respiteSectionModel.totalExUnknown.elementValue"
                              [disabled]="!_respiteSectionModel.totalExUnknown.isRequired" type="text"
                              class="form-control" id="totalExUnknown" aria-labelledby="otherRespite totalExpenditures"
                              readonly>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="col-gap"></td>
                        <td class="background-gray-lighter"></td>
                        <td></td>
                        <td class="background-gray-lighter"></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th id="totalExpendituresPerPersonHours">Total expenditure per hour</th>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_respiteSectionModel.perPersonInHome.elementValue"
                              [disabled]="!_respiteSectionModel.perPersonInHome.isRequired" type="text"
                              class="form-control" id="perPersonInHome"
                              aria-labelledby="inHomeRespite totalExpendituresPerPersonHours" readonly>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_respiteSectionModel.perPersonOutDay.elementValue"
                              [disabled]="!_respiteSectionModel.perPersonOutDay.isRequired" type="text"
                              class="form-control" id="perPersonOutDay"
                              aria-labelledby="ouOfHomeRespite totalExpendituresPerPersonHours" readonly>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_respiteSectionModel.perPersonOutNight.elementValue"
                              [disabled]="!_respiteSectionModel.perPersonOutNight.isRequired" type="text"
                              class="form-control" id="perPersonOutNight"
                              aria-labelledby="outOfHomeRespitOvernight totalExpendituresPerPersonHours" readonly>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_respiteSectionModel.perPersonUnknown.elementValue"
                              [disabled]="!_respiteSectionModel.perPersonUnknown.isRequired" type="text"
                              class="form-control" id="perPersonUnknown"
                              aria-labelledby="otherRespite totalExpendituresPerPersonHours" readonly>
                          </div>
                        </td>
                      </tr>
                    </tbody>

                  </table>

                </div>
                <!-- ./end table-responsive -->
              </div>
              <div class="modal-footer">
                <button id="orcSaveAllBtn2" type="button" *ngIf="validForm" class="btn btn-primary" data-dismiss="modal"
                  (click)="saveAllSections()">Save changes</button>
                <button id="orcSaveAllBtnDisabled2" type="button" *ngIf="!validForm" class="btn btn-primary"
                  data-dismiss="modal" disabled>Save changes</button>
                <button id="orcCloseBtn2" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="myModal3Label">
          <div class="modal-dialog modal-xlg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title" id="myModal3Label">Utilization and Expenditure for Older Relative Caregivers Unregistered Services</h4>
              </div>
              <div class="modal-body">

                <table *ngIf="unregisteredErrors().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-error">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Error(s):
                        </span>
                        <p *ngFor="let unregisteredErrorItem of unregisteredErrors()" class="help-block">
                          {{unregisteredErrorItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table *ngIf="unregisteredWarnings().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-warning">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):
                        </span>
                        <p *ngFor="let unregisteredWarnItem of unregisteredWarnings()" class="help-block">
                          {{unregisteredWarnItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="table-responsive">
                  <table class="table table-no-border">
                    <caption class="sr-only">Older Relative Caregivers Unregistered Services</caption>
                    <thead>
                      <tr class="background-gray-lighter">
                        <td width="25%"></td>
                        <th width="15%" id="orcSupportGroups" scope="col">
                          <a [routerLink]="['../titleIIIORCSupport']" data-dismiss="modal">Support Groups</a>
                        </th>
                        <th width="15%" id="orcInfoAssis" scope="col">
                          <a [routerLink]="['../titleIIIORCInfoAndAssistance']" data-dismiss="modal">Information
                            and Assistance</a>
                        </th>
                        <th width="15%" id="orcPubInfoServices" scope="col">
                          <a [routerLink]="['../titleIIIORCInfoServices']" data-dismiss="modal">Public Information
                            Services</a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr>
                                                <th class="normal" id="totalEstserved" scope="row">Total estimated
                                                    persons served</th>
                                                <td class="background-gray-lighter">
                                                </td>
                                                <td>
                                                </td>
                                                <td class="background-gray-lighter">
                                                </td>
                                            </tr> -->
                      <tr>
                        <th class="normal" id="totalEstSize" scope="row" width="50%">Total
                          estimated
                          audience size</th>
                        <td class="background-gray-lighter">
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_infoAssistSectionModel.totalPersonsServed.questionStatus)">
                            <input [(ngModel)]="_infoAssistSectionModel.totalPersonsServed.elementValue"
                              (change)="unsavedChanges = true; _infoAssistSectionModel.totalPersonsServed = validate(_infoAssistSectionModel.totalPersonsServed); checkValidForm('unregistered'); _infoAssistSectionModel.totalPersonsServed.elementValue = addComma(_infoAssistSectionModel.totalPersonsServed.elementValue)"
                              [disabled]="!_infoAssistSectionModel.totalPersonsServed.isRequired" type="text"
                              class="form-control" id="orcInfoAndAssistSize"
                              aria-labelledby="orcInfoAssis totalEstSize">
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_infoServiceSectionModel.totalPersonsServed.questionStatus)">
                            <input [(ngModel)]="_infoServiceSectionModel.totalPersonsServed.elementValue"
                              (change)="unsavedChanges = true; _infoServiceSectionModel.totalPersonsServed = validate(_infoServiceSectionModel.totalPersonsServed); checkValidForm('unregistered'); _infoServiceSectionModel.totalPersonsServed.elementValue = addComma(_infoServiceSectionModel.totalPersonsServed.elementValue)"
                              [disabled]="!_infoServiceSectionModel.totalPersonsServed.isRequired" type="text"
                              class="form-control" id="orcPublicInfoSize"
                              aria-labelledby="orcPubInfoServices totalEstSize">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="servUnits" scope="row">Service units</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_supportSectionModel.totalServiceUnits.questionStatus)">
                            <div class="input-group">
                              <input [(ngModel)]="_supportSectionModel.totalServiceUnits.elementValue"
                                [disabled]="!_supportSectionModel.totalServiceUnits.isRequired" type="text"
                                class="form-control" id="supportUnits" aria-labelledby="orcSupportGroups servUnits"
                                (change)="unsavedChanges = true; updatePerUnit(); _supportSectionModel.totalServiceUnits = validate(_supportSectionModel.totalServiceUnits); checkValidForm('unregistered'); _supportSectionModel.totalServiceUnits.elementValue = addComma(_supportSectionModel.totalServiceUnits.elementValue)">
                              <div class="input-group-addon">Sessions</div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_infoAssistSectionModel.totalServiceUnits.questionStatus)">
                            <div class="input-group">
                              <input [(ngModel)]="_infoAssistSectionModel.totalServiceUnits.elementValue"
                                [disabled]="!_infoAssistSectionModel.totalServiceUnits.isRequired" type="text"
                                class="form-control" id="iaUnits" aria-labelledby="orcInfoAssis servUnits"
                                (change)="unsavedChanges = true; updatePerUnit(); _infoAssistSectionModel.totalServiceUnits = validate(_infoAssistSectionModel.totalServiceUnits); checkValidForm('unregistered'); _infoAssistSectionModel.totalServiceUnits.elementValue = addComma(_infoAssistSectionModel.totalServiceUnits.elementValue)">
                              <div class="input-group-addon">Contact</div>
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_infoServiceSectionModel.totalServiceUnits.questionStatus)">
                            <div class="input-group">
                              <input [(ngModel)]="_infoServiceSectionModel.totalServiceUnits.elementValue"
                                [disabled]="!_infoServiceSectionModel.totalServiceUnits.isRequired" type="text"
                                class="form-control" id="isUnits" aria-labelledby="orcPubInfoServices servUnits"
                                (change)="unsavedChanges = true; updatePerUnit(); _infoServiceSectionModel.totalServiceUnits = validate(_infoServiceSectionModel.totalServiceUnits); checkValidForm('unregistered'); _infoServiceSectionModel.totalServiceUnits.elementValue = addComma(_infoServiceSectionModel.totalServiceUnits.elementValue)">
                              <div class="input-group-addon">Activity</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="titleIIIExpenditure3" scope="row">Title III-E
                          expenditures</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_supportSectionModel.expend.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_supportSectionModel.expend.elementValue"
                                [disabled]="!_supportSectionModel.expend.isRequired" type="text" class="form-control"
                                id="supportExp" aria-labelledby="orcSupportGroups titleIIIExpenditure3"
                                (change)="unsavedChanges = true; _supportSectionModel.expendTotal.elementValue = updateTotal(supportArr); updatePerUnit(); _supportSectionModel.expend = validate(_supportSectionModel.expend); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_infoAssistSectionModel.expend.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_infoAssistSectionModel.expend.elementValue"
                                [disabled]="!_infoAssistSectionModel.expend.isRequired" type="text" class="form-control"
                                id="iaExp" aria-labelledby="orcInfoAssis titleIIIExpenditure3"
                                (change)="unsavedChanges = true; _infoAssistSectionModel.expendTotal.elementValue = updateTotal(infoAssistArr); updatePerUnit(); _infoAssistSectionModel.expend = validate(_infoAssistSectionModel.expend); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_infoServiceSectionModel.expend.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_infoServiceSectionModel.expend.elementValue"
                                [disabled]="!_infoServiceSectionModel.expend.isRequired" type="text"
                                class="form-control" id="isExp"
                                aria-labelledby="orcPubInfoServices titleIIIExpenditure3"
                                (change)="unsavedChanges = true; _infoServiceSectionModel.expendTotal.elementValue = updateTotal(infoServiceArr); updatePerUnit(); _infoServiceSectionModel.expend = validate(_infoServiceSectionModel.expend); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="otherStateExpenditure3" scope="row">Other: State
                          expenditure</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_supportSectionModel.expendOtherState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_supportSectionModel.expendOtherState.elementValue"
                                [disabled]="!_supportSectionModel.expendOtherState.isRequired" type="text"
                                class="form-control" id="supportOtherState"
                                aria-labelledby="orcSupportGroups otherStateExpenditure3"
                                (change)="unsavedChanges = true; _supportSectionModel.expendTotal.elementValue = updateTotal(supportArr); updatePerUnit(); _supportSectionModel.expendOtherState = validate(_supportSectionModel.expendOtherState); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_infoAssistSectionModel.expendOtherState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_infoAssistSectionModel.expendOtherState.elementValue"
                                [disabled]="!_infoAssistSectionModel.expendOtherState.isRequired" type="text"
                                class="form-control" id="iaOtherState"
                                aria-labelledby="orcInfoAssis otherStateExpenditure3"
                                (change)="unsavedChanges = true; _infoAssistSectionModel.expendTotal.elementValue = updateTotal(infoAssistArr); updatePerUnit(); _infoAssistSectionModel.expendOtherState = validate(_infoAssistSectionModel.expendOtherState); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_infoServiceSectionModel.expendOtherState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_infoServiceSectionModel.expendOtherState.elementValue"
                                [disabled]="!_infoServiceSectionModel.expendOtherState.isRequired" type="text"
                                class="form-control" id="isOtherState"
                                aria-labelledby="orcPubInfoServices otherStateExpenditure3"
                                (change)="unsavedChanges = true; _infoServiceSectionModel.expendTotal.elementValue = updateTotal(infoServiceArr); updatePerUnit(); _infoServiceSectionModel.expendOtherState = validate(_infoServiceSectionModel.expendOtherState); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="otherNonStateExpenditure3" scope="row">Other:
                          Non-state expenditure</th>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_supportSectionModel.expendOtherNonState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_supportSectionModel.expendOtherNonState.elementValue"
                                [disabled]="!_supportSectionModel.expendOtherNonState.isRequired" type="text"
                                class="form-control" id="supportOtherNon"
                                aria-labelledby="orcSupportGroups otherNonStateExpenditure3"
                                (change)="unsavedChanges = true; _supportSectionModel.expendTotal.elementValue = updateTotal(supportArr); updatePerUnit(); _supportSectionModel.expendOtherNonState = validate(_supportSectionModel.expendOtherNonState); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            [ngClass]="calculateOutlineColor(_infoAssistSectionModel.expendOtherNonState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_infoAssistSectionModel.expendOtherNonState.elementValue"
                                [disabled]="!_infoAssistSectionModel.expendOtherNonState.isRequired" type="text"
                                class="form-control" id="iaOtherNon"
                                aria-labelledby="orcInfoAssis otherNonStateExpenditure3"
                                (change)="unsavedChanges = true; _infoAssistSectionModel.expendTotal.elementValue = updateTotal(infoAssistArr); updatePerUnit(); _infoAssistSectionModel.expendOtherNonState = validate(_infoAssistSectionModel.expendOtherNonState); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div
                            [ngClass]="calculateOutlineColor(_infoServiceSectionModel.expendOtherNonState.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_infoServiceSectionModel.expendOtherNonState.elementValue"
                                [disabled]="!_infoServiceSectionModel.expendOtherNonState.isRequired" type="text"
                                class="form-control" id="isOtherNon"
                                aria-labelledby="orcPubInfoServices otherNonStateExpenditure3"
                                (change)="unsavedChanges = true; _infoServiceSectionModel.expendTotal.elementValue = updateTotal(infoServiceArr); updatePerUnit(); _infoServiceSectionModel.expendOtherNonState = validate(_infoServiceSectionModel.expendOtherNonState); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" scope="row" id="orcProgramIncomeExpended">Program
                          income expended</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_supportSectionModel.programIncome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_supportSectionModel.programIncome.elementValue"
                                [disabled]="!_supportSectionModel.programIncome.isRequired" type="text"
                                class="form-control" id="supportProgram"
                                aria-labelledby="orcSupportGroups orcProgramIncomeExpended"
                                (change)="unsavedChanges = true; _supportSectionModel.expendTotal.elementValue = updateTotal(supportArr); updatePerUnit(); _supportSectionModel.programIncome = validate(_supportSectionModel.programIncome); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_infoAssistSectionModel.programIncome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_infoAssistSectionModel.programIncome.elementValue"
                                [disabled]="!_infoAssistSectionModel.programIncome.isRequired" type="text"
                                class="form-control" id="iaProgram"
                                aria-labelledby="orcInfoAssis orcProgramIncomeExpended"
                                (change)="unsavedChanges = true; _infoAssistSectionModel.expendTotal.elementValue = updateTotal(infoAssistArr); updatePerUnit(); _infoAssistSectionModel.programIncome = validate(_infoAssistSectionModel.programIncome); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_infoServiceSectionModel.programIncome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_infoServiceSectionModel.programIncome.elementValue"
                                [disabled]="!_infoServiceSectionModel.programIncome.isRequired" type="text"
                                class="form-control" id="isProgram"
                                aria-labelledby="orcPubInfoServices orcProgramIncomeExpended"
                                (change)="unsavedChanges = true; _infoServiceSectionModel.expendTotal.elementValue = updateTotal(infoServiceArr); updatePerUnit(); _infoServiceSectionModel.programIncome = validate(_infoServiceSectionModel.programIncome); checkValidForm('unregistered')">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" id="orcTotalExpenditures">Total expenditures</th>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_supportSectionModel.expendTotal.elementValue"
                              [disabled]="!_supportSectionModel.expendTotal.isRequired" type="text" class="form-control"
                              id="supportTotal" aria-labelledby="orcSupportGroups orcTotalExpenditures" readonly>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_infoAssistSectionModel.expendTotal.elementValue"
                              [disabled]="!_infoAssistSectionModel.expendTotal.isRequired" type="text"
                              class="form-control" id="iaTotal" aria-labelledby="orcInfoAssis orcTotalExpenditures"
                              readonly>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_infoServiceSectionModel.expendTotal.elementValue"
                              [disabled]="!_infoServiceSectionModel.expendTotal.isRequired" type="text"
                              class="form-control" id="isTotal"
                              aria-labelledby="orcPubInfoServices orcTotalExpenditures" readonly>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th id="orcTotalExpendituresPerPersonUnit" scope="row">Expenditure per
                          unit</th>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <input [(ngModel)]="_supportSectionModel.totalExpendPerUnit.elementValue"
                              [disabled]="!_supportSectionModel.totalExpendPerUnit.isRequired" type="text"
                              class="form-control" id="supportPerUnit"
                              aria-labelledby="orcSupportGroups orcTotalExpendituresPerPersonUnit" readonly>
                            <div class="input-group-addon">/Hour</div>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <input [(ngModel)]="_infoAssistSectionModel.totalExpendPerUnit.elementValue"
                              [disabled]="!_infoAssistSectionModel.totalExpendPerUnit.isRequired" type="text"
                              class="form-control" id="iaPerUnit"
                              aria-labelledby="orcInfoAssis orcTotalExpendituresPerPersonUnit" readonly>
                            <div class="input-group-addon">/Contact</div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <input [(ngModel)]="_infoServiceSectionModel.totalExpendPerUnit.elementValue"
                              [disabled]="!_infoServiceSectionModel.totalExpendPerUnit.isRequired" type="text"
                              class="form-control" id="isPerUnit"
                              aria-labelledby="orcPubInfoServices orcTotalExpendituresPerPersonUnit" readonly>
                            <div class="input-group-addon">/Activity</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--  end table responsive  -->
              </div>
              <div class="modal-footer">
                <button id="orcSaveAllBtn3" type="button" *ngIf="validForm" class="btn btn-primary" data-dismiss="modal"
                  (click)="saveAllSections()">Save changes</button>
                <button id="orcSaveAllBtnDisabled3" type="button" *ngIf="!validForm" class="btn btn-primary"
                  data-dismiss="modal" disabled>Save changes</button>
                <button id="orcCloseBtn3" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 row">
          <div *ngIf="!validForm" class="has-error">
            <p class="help-block">There are errors on the page. You must fix the errors or clear the data
              in those fields in order
              to Save this form.</p>
          </div>
          <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal"
            class="btn btn-primary mr-1">Next</button>
          <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>
          <button class="btn btn-default mr-1" (click)="saveAllSections()" [disabled]="!validForm">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return
            to Overview</a>
        </div>
        <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1"
          style="display: none;">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
                <h3 class="modal-title" id="nextModalLabel">Recent changes on this page will not be
                  saved</h3>
              </div>
              <div class="modal-body">
                <p>
                  There are errors on the page. You must fix the errors or clear the data in those
                  fields in order to save this form.
                  <br />
                  <br /> You may select "Go back to form" to remove the errors or you may select
                  "Proceed to
                  next section" and all of new the data entered in the current section will be lost.
                </p>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go
                  back to form</button>
                <button class="btn btn-primary pull-right" (click)="unsavedChanges = false;" data-dismiss="modal"
                  type="button" [routerLink]="['/data-submissions/titleVIIExpenditures']">Proceed
                  to next section</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
