import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


import { TruncateModule } from 'ng2-truncate';

import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { CommonService } from '../shared/common.service';
import { YearApprovalStatus } from '../shared/yearApprovalStatus.model';
import { FormGroup } from '@angular/forms';
import { ToastrService } from '../shared/toastr.service';
import { LoadingComponent } from '../shared/loading.component';
import { QuestionStatusEnum } from '../shared/dataElement';
import { DestructibleComponent } from '../destructible.component';
import { AclHeader } from '../shared/AclReviewHeader/aclHeader';
import { OrgLevelEnum } from '../UserManagement/userManagerObjects';

declare var jQuery: any;

@Component({
    templateUrl: 'titleIIIstatuslogsrp.component.html',
    styles: [
        `.modal-header > h1 { text-transform: none; color: black; font-size: 20px;
            font-weight: bold; border-bottom-width: 0px; padding-bottom: 0px; }`,
        '.modal-body > .table { margin-bottom: 0px; }',
        '.modal-body > .table > tbody > tr > td { border-top: 0px; width: 70%; }',
        '.modal-body > .table > tbody > tr > th { border-top: 0px; width: 30% }'
    ]
})
export class TitleIIIStatusLogSrpComponent extends DestructibleComponent implements OnInit, OnDestroy {

    constructor(public route: ActivatedRoute,
        public router: Router,
        public _titleService: Title,
        public _service: TitleIIIDataEntryService,
        public _claimService: ClaimService,
        public _router: Router,
        public _commonService: CommonService,
        public toastr: ToastrService,
        public truncateModule: TruncateModule
    ) { super(); }

    public resolutionStrategiesForm: FormGroup;

    _sectionModel: YearApprovalStatus;
    _submissionLog: Array<YearApprovalStatus> = [];
    disabledFlag: boolean = false;
    customResolutionStrategies: string[] = [];
    sitOtherDescriptionError: boolean = false;
    state: string;
    ffy: string;
    logCreatedBy: string;
    logCreatedDate: string;
    comments: string;
    status: string;
    isPrint: boolean = false;
    rowsOnPage: number = 10;
    sortBy: string = 'created';
    sortOrder: string = 'desc';
    role: string;
    flowType: string = 'SPR';
    aclHeader: AclHeader = new AclHeader();

    // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;

    public _currentRoute: string = this.route.toString();

    ngOnInit(): void {
        // this.loadingComponent.setLoadingInProgress(5);
        super.registerSubscription(this.route.queryParams.subscribe(
            qs => {
                this.flowType = qs['flowType'] != null ? qs['flowType'] : 'SPR';
            }));
        this._titleService.setTitle('State Submission Log');
        this.state = this._claimService.currentOrg;
        this.ffy = this._claimService.currentYear;
        this.getsubmissionlogs();
        this.setRole();
    }

    ngOnDestroy(): void {
        super.destroy();
    }

    getsubmissionlogs(): void {
        super.registerSubscription(this._service.getYearApprovalStatusByFfyAndState(this.ffy, this.state, this._claimService.currentSubOrg,
            (this._claimService.isAAAEnabledStateUser() || this._claimService.isRollupOverview)
            && !this._claimService.currentSubOrg, this.flowType)
            .subscribe(data => {
                this._submissionLog = data ? data : [];
                this.buildAclHeader();
            },
                (error)=> console.log(error),
                ()=> {}
            ));
    }

    showSubmissionLogCommentModal(status: QuestionStatusEnum, createdBy: string, created: string, comment: string): void {
        this.status = QuestionStatusEnum[status];
        this.logCreatedBy = createdBy;
        this.logCreatedDate = created;
        this.comments = comment;
        jQuery('#submissionLogComment').modal('show');
    }

    updateSubmissionLogs(flowType: string): void {
        // this.loadingComponent.setLoadingInProgress(5);
        this.flowType = flowType;
        this.getsubmissionlogs();
    }

    dismissComments(): void {
        jQuery('#submissionLogComment').modal('hide');
    }

    print(): void {
        this.isPrint = true;
        this.rowsOnPage = 100000;
        setTimeout(() => { window.print(); this.rowsOnPage = 10; }, 2000);
    }

    formatData(data: YearApprovalStatus): void {
        this._sectionModel.id = data.id;
        this._sectionModel.upn = data.upn;
    }

    setFocus() {
        jQuery('#customResolutionStrategyDiv' + (this.customResolutionStrategies.length - 2)).show();
        jQuery('#id' + (this.customResolutionStrategies.length - 2)).focus();
    }

    setRole() {
        if (!this._claimService.isNavStateSubmission) {
            this.role = 'ACL';
        }
        if (this._claimService.isNavStateSubmission) {
            this.role = 'DataEntry';
            if (this._claimService.currentSubOrg != null) {
                this.role = 'StateWithAAA';
            }
        }
    }

    private buildAclHeader(): void {
        const isAAAStateUser = this._claimService.isAAAState
            && this._claimService.currentOrgLevel === OrgLevelEnum[OrgLevelEnum.State];
        let title, orgLabel, orgName, returnLink, returnText, returnParams = <any>{};
        title = this._claimService.currentTitle;
        if (isAAAStateUser) {
            orgLabel = 'AAA';
            orgName = this._claimService.aaaName;
            returnText = 'Back to AAA submission review';
        } else {
            orgLabel = 'State';
            orgName = this._commonService.stateAbbrvToFullName(this._claimService.currentOrg);
            returnText = 'Back to state submission review';
        }
        returnLink = '../../data-submissions/titleIIISSReview';
        this.aclHeader = {
            title,
            orgLabel,
            orgName,
            returnLink,
            returnText,
            returnParams,
            fiscalYear: this._claimService.currentYear,
            shouldRender: this._claimService.isACLUser()
                || (!this._commonService.isNullOrEmpty(this._claimService.currentSubOrg) && isAAAStateUser)
        };
    }
}
