import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Section } from './section';
import { QuestionStatusEnum, DataElement, ValidationTypes } from './dataElement';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { VarianceExplanation } from './varianceExplanation.model';
import { ClaimService } from './claim.service';
import { AllStates } from './states';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import { IsoDateTimeRegex, EST_GMT_OFFSET, Constants } from '../app.constants';
import { Router } from '@angular/router';
declare var jQuery: any;


@Injectable()
export class CommonService {

  public agingNetworkLockedNav: boolean = false;
  public oaLockedNav: boolean = false;
  public coaLockedNav: boolean = false;
  public orcLockedNav: boolean = false;
  public eoLockedNav: boolean = false;
  public veLockedNav: boolean = false;

  private readonly alphaNumArray = []; // For generating random strings

  constructor(
    public http: HttpClient,
    public _sanitizer: DomSanitizer,
    public _claimService: ClaimService) {

  }

  // WARNING: this method is dangerous, only use it for static strings. No user input or data from the db!
  public renderHtml(html: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  public toUpperSafe(str: string) {
    if (str) {
      return str.toUpperCase();
    }
    return null;
  }

  public sanitizeUserFinished(event, varianceExplanation: VarianceExplanation) {
    if (this.isNullOrEmpty(event.target.value)) {
      varianceExplanation.isUserFinished = false;
    }
  }

  public disableFormElements(section: Section, disableOverride: boolean = false): void {
    if (section.sectionStatus > QuestionStatusEnum.Returned
      || this._claimService.isViewOnly
      || (this._claimService.currentTitle === 'III' && !section.isRequired)
      //|| section.isRollup
      || disableOverride) {
      jQuery('button:contains("Save and next")').html('Next');
      jQuery('button:contains("Save")').remove();
      jQuery('div.acl-container :input,textarea').each((i, e) => {
        if (jQuery(e).html() !== 'Next'
          && jQuery(e).html() !== 'Close'
          && jQuery(e).attr('id') !== 'reportingPeriod'
          && (jQuery(e).attr('class') && !jQuery(e).attr('class').includes('close')) // 4-12-19 bug when class is undefined
          && !jQuery(e).html().includes('Expand all')
          && !jQuery(e).html().includes('Collapse all')
          && jQuery(e).text() !== 'Print') {
          jQuery(e).attr('disabled', 'disabled');
        }
      });
    }
  }

  public disableVarianceFormElements(): void {
    jQuery('button:contains("Save and next")').html('Next');
    jQuery('button:contains("Save")').remove();
    jQuery('div.acl-container :input,textarea').each((i, e) => {
      if (jQuery(e).html() !== 'Next'
        && jQuery(e).html() !== 'Close'
        && jQuery(e).attr('id') !== 'reportingPeriod'
        && (jQuery(e).attr('class') && !jQuery(e).attr('class').includes('close')) // 4-12-19 bug when class is undefined
        && !jQuery(e).html().includes('Expand all')
        && !jQuery(e).html().includes('Collapse all')
        && jQuery(e).text() !== 'Print') {
        jQuery(e).attr('disabled', 'disabled');
      }
    });
  }

  public isNullOrEmpty(value: string): boolean {
    if (!value || value.trim() === '') {
      return true;
    }
    return false;
  }

  public includesAny(target: string, ...includesValues: string[]) {
    if (target && includesValues) {
      return includesValues.some(item => target.includes(item));
    }
    return false;
  }

  public isValidDateString(date: string) {
    const timestamp = Date.parse(date);
    return !isNaN(timestamp);
  }

  public isAfter(date1: Date, date2: Date) {
    return date1.getTime() > date2.getTime();
  }

  public isNotAfter(date1: Date, date2: Date) {
    return !this.isAfter(date1, date2);
  }

  public yearsSince(startYear: number) {
    return _.range(startYear, new Date().getFullYear());
  }

  handleError(err): void {
    const payload = JSON.parse(err._body);
    switch (payload.code) {
      case (3000):
        let msg = 'We\'re sorry a validation error occurred while saving your data.\n\n\Please ';
        msg += 'ensure all required fields are filled and try again.';
        alert(msg);
        break;
      default:
        console.log(err);
        break;
    }
  }

  overallStatusHtmlWhite(statusCode: number, isRequired?: boolean): SafeHtml {
    return this.statusReaderWhite(this.overallStatus(statusCode), isRequired);
  }

  overallStatusHtml(statusCode: number, isRequired?: boolean): SafeHtml {
    return this.statusReader(this.overallStatus(statusCode), isRequired);
  }

  // ACL InProgress rolls up several different state statuses
  isAclInProgress(statusCode: number): boolean {
    return _.inRange(statusCode, QuestionStatusEnum.L1InProgressError, QuestionStatusEnum.Returned);
  }

  aclInProgressStatuses(): Array<number> {
    return _.range(QuestionStatusEnum.L1InProgressError, QuestionStatusEnum.Returned);
  }

  overallStatus(statusCode: number): number {
    if (_.inRange(statusCode, QuestionStatusEnum.L1InProgressError, QuestionStatusEnum.Returned)) {
      return QuestionStatusEnum.L1InProgress;
    } else {
      return statusCode;
    }
  }

  // Used for section level status - (section level status wording is different
  // from overall status wording, see status spreadsheet for correct mapping)
  statusReader(statusCode: number, isRequired?: boolean): SafeHtml {
    if (isRequired != null && !isRequired) {
      return this._sanitizer.bypassSecurityTrustHtml('<span class="fontWhite text-center">N/A</span>');
    } else {
      switch (statusCode) {
        case QuestionStatusEnum.NotStarted: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="not-started"><i class="fa fa-power-off" aria-hidden="true"></i> Not Started</span>');
        }
        case QuestionStatusEnum.L1InProgressError: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class=\'in-progress-error\'><i class=\'fa fa-times\' aria-hidden=\'true\'></i> In Progress with Errors</span>');
        }
        case QuestionStatusEnum.L2InProgressError: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class=\'in-progress-error\'><i class=\'fa fa-times\' aria-hidden=\'true\'></i> In Progress with Errors</span>');
        }
        case QuestionStatusEnum.L1InProgress: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="in-progress"><i class="fa fa-cogs" aria-hidden="true"></i> In Progress</span>');
        }
        case QuestionStatusEnum.L2ValidatedWarning: {
          let txt = '<span class="validated-w-warnings"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ';
          txt += 'Validated with Warnings</span>';
          return this._sanitizer.bypassSecurityTrustHtml(txt);
        }
        case QuestionStatusEnum.L2Validated: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="validated"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Validated</span>');
        }
        case QuestionStatusEnum.L3NoExplanation: {
          let txt = '<span class="variance-required"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> ';
          txt += 'Variance Explanations Required</span>';
          return this._sanitizer.bypassSecurityTrustHtml(txt);
        }
        case QuestionStatusEnum.L3Explanation: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="variance-provided"><i class="fa fa-check-circle" aria-hidden="true"></i> Variance Explanations Provided</span>');
        }
        case QuestionStatusEnum.NoSignificantVariance: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="variance-provided"><i class="fa fa-check-circle" aria-hidden="true"></i> No Significant Variances</span>');
        }
        case QuestionStatusEnum.Returned: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="returned"><i class="fa fa-undo" aria-hidden="true"></i> Returned</span>');
        }
        case QuestionStatusEnum.SubmittedNoExplanation: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="variance-required"><i class="fa fa-upload" aria-hidden="true"></i> Submitted - Pending Explanations</span>');
        }
        case QuestionStatusEnum.Submitted: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="submitted"><i class="fa fa-upload" aria-hidden="true"></i> Submitted</span>');
        }
        case QuestionStatusEnum.InReview: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="submitted"><i class="fa fa-file-text-o" aria-hidden="true"></i> In Review</span>');
        }
        case QuestionStatusEnum.Approved: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="approved"><i class="fa fa-thumbs-up" aria-hidden="true"></i> Approved</span>');
        }
        case QuestionStatusEnum.Locked: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="locked"><i class="fa fa-lock" aria-hidden="true"></i> Locked</span>');
        }
        case Constants.CODES.reportingNotPermitted: {
          return `
            <span class="locked">
              <i class="fa fa-lock" aria-hidden="true"></i> Data reporting is not permitted for this fiscal year.
            </span>
          `;
        }
        default: {
          return this._sanitizer.bypassSecurityTrustHtml('<span>Loading...</span>');
        }
      }
    }
  }

  // Used for overall status - (overall status wording is different from section
  // level status wording, see status spreadsheet for correct mapping)
  statusReaderWhite(statusCode: number, isRequired?: boolean): SafeHtml {
    if (isRequired != null && !isRequired) {
      return this._sanitizer.bypassSecurityTrustHtml('<span class="fontWhite text-center">N/A</span>');
    } else {
      switch (statusCode) {
        case QuestionStatusEnum.NotStarted: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-power-off" aria-hidden="true"></i> Not Started</span>');
        }
        case QuestionStatusEnum.L1InProgressError: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class=\'fontWhite\'><i class=\'fa fa-times\' aria-hidden=\'true\'></i> In Progress</span>');
        }
        case QuestionStatusEnum.L2InProgressError: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class=\'fontWhite\'><i class=\'fa fa-times\' aria-hidden=\'true\'></i> In Progress</span>');
        }
        case QuestionStatusEnum.L1InProgress: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-cogs" aria-hidden="true"></i> In Progress</span>');
        }
        case QuestionStatusEnum.L2ValidatedWarning: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> In Progress</span>');
        }
        case QuestionStatusEnum.L2Validated: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-check-circle-o" aria-hidden="true"></i> In Progress</span>');
        }
        case QuestionStatusEnum.L3NoExplanation: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> In Progress</span>');
        }
        case QuestionStatusEnum.L3Explanation: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-check-circle" aria-hidden="true"></i> In Progress</span>');
        }
        case QuestionStatusEnum.NoSignificantVariance: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-check-circle" aria-hidden="true"></i> In Progress</span>');
        }
        case QuestionStatusEnum.Returned: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-undo" aria-hidden="true"></i> Returned</span>');
        }
        case QuestionStatusEnum.SubmittedNoExplanation: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-upload" aria-hidden="true"></i> Submitted - Pending Explanations</span>');
        }
        case QuestionStatusEnum.Submitted: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-upload" aria-hidden="true"></i> Submitted - Explanations Provided</span>');
        }
        case QuestionStatusEnum.InReview: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-file-text-o" aria-hidden="true"></i> In Review</span>');
        }
        case QuestionStatusEnum.Approved: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-thumbs-up" aria-hidden="true"></i> Approved</span>');
        }
        case QuestionStatusEnum.Locked: {
          return this._sanitizer.bypassSecurityTrustHtml(
            '<span class="fontWhite"><i class="fa fa-lock" aria-hidden="true"></i> Locked</span>');
        }
        default: {
          return this._sanitizer.bypassSecurityTrustHtml('<span>Loading...</span>');
        }
      }
    }
  }

  setColor(status: number): string {
    if (status === 0) {
      return 'whiteFont';
    }
    if (status === 1
      || status === 2
      || status === 6) {
      return 'redFont';
    }
    if (status === 4) {
      return 'orangeFont';
    }
    if (status === 5
      || status === 7
      || status === QuestionStatusEnum.NoSignificantVariance) {
      return 'greenFont';
    } else { return ''; }
  }

  updatedByReader(lastUpdatedTime: string, lastUpdatedBy: string, isRequired?: boolean): SafeHtml {
    if (isRequired != null && !isRequired) {
      return '';
    }
    if (lastUpdatedTime !== '0001-01-01T00:00:00Z' && lastUpdatedBy !== 'SYSTEM') {
      const momentDate = moment(Date.parse(lastUpdatedTime));
      if (!!lastUpdatedBy) {
        return this._sanitizer.bypassSecurityTrustHtml(momentDate.format('MM/DD/YYYY') + ' at ' + momentDate.format('h:mm A')
          + ' by <br/> ' + lastUpdatedBy);
      }
    }
    return '';
  }

  /* Methods for accordions */

  expandAccordions(): void {
    jQuery('.panel-collapse').each((i, e) => jQuery(e).collapse('show'));
    this.setAccordionAccessibilityListener();
  }

  collapseAccordions(): void {
    jQuery('.panel-collapse').each((i, e) => jQuery(e).collapse('hide'));
    this.setAccordionAccessibilityListener();
  }

  setAccordionAccessibilityListener(): void {
    jQuery('.clickable button').click(() => {
      this.setAccordionAccessibilityLabel();
    });
  }

  setAccordionAccessibilityLabel(): void {
    if (jQuery(this).closest('tr.clickable').attr('aria-expanded') === 'true') {
      jQuery(this).closest('tr.clickable').attr('aria-expanded', 'false');
      jQuery(this).attr('aria-expanded', 'false').attr('aria-label', 'closed');
    } else {
      jQuery(this).closest('tr.clickable').attr('aria-expanded', 'true');
      jQuery(this).attr('aria-expanded', 'true').attr('aria-label', 'open');
    }
  }

  accordionStatus(veo: VarianceExplanation): SafeHtml {
    if (!veo) {
      return this._sanitizer.bypassSecurityTrustHtml(
        '<span class="variance- required darkRedFont"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Loading...</span>');
    }
    if (!veo.isRequired) {
      let txt = '<span class="variance- provided darkGreenFont"><i class="fa fa-check-circle" aria-hidden="true"></i> ';
      txt += 'No Significant Variances</span>';
      return this._sanitizer.bypassSecurityTrustHtml(txt);
    }
    if (veo.isRequirementsSatisfied) {
      let txt = '<span class="variance- provided darkGreenFont"><i class="fa fa-check-circle" aria-hidden="true"></i> ';
      txt += 'Variance Explanations Provided</span>';
      return this._sanitizer.bypassSecurityTrustHtml(txt);
    } else {
      let txt = '<span class="variance- required darkRedFont"><i class="fa fa-exclamation-circle" aria-hidden="true"></i> ';
      txt += 'Variance Explanations Required</span>';
      return this._sanitizer.bypassSecurityTrustHtml(txt);
    }
  }

  areArraysEqual(a: Array<any>, b: Array<any>) {
    if (a === b) { return true; }
    if (a == null || b == null) { return false; }
    if (a.length !== b.length) { return false; }

    a = a.sort();
    b = b.sort();

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) { return false; }
    }
    return true;
  }

  showModal(modalId: string) {
    jQuery('#' + modalId).modal('show');
  }

  hideModal(modalId: string) {
    jQuery('#' + modalId).modal('hide');
  }

  sanitizeDataElement(de: DataElement): DataElement {
    de.businessErrorMessage = null;
    de.dataEntryErrorMessage = null;
    de.elementValue = null;
    de.explanation = null;
    de.nationalValue = null;
    de.nationalVariance = null;
    de.isNotSigificantVariance = false;
    de.isUserNotProvidedSigificantVariance = false;
    de.isValid = false;
    de.questionStatus = QuestionStatusEnum.NotStarted;

    // clone
    return { ...de };
  }

  stateAbbrvToFullName(stateAbbrv: string): string {
    let stateFullName: string = stateAbbrv;
    AllStates.forEach(state => {
      if (state.abbreviation === stateAbbrv) {
        stateFullName = state.name;
      }
    });
    return stateFullName;
  }

  // Returns true if token is equal to 'true' ignoring case
  parseBoolean(token: string) {
    let parsed = false;
    if (token && token.toLowerCase() === 'true') {
      parsed = true;
    }
    return parsed;
  }

  private buildAlphabet() {
    // Letters
    for (let i = 'A'.charCodeAt(0); i <= 'Z'.charCodeAt(0); i++) {
      this.alphaNumArray.push(String.fromCharCode(i));
    }
    // Numbers
    for (let i = '0'.charCodeAt(0); i <= '9'.charCodeAt(0); i++) {
      this.alphaNumArray.push(String.fromCharCode(i));
    }
  }

  public generateRandomString(len: number): string {
    if (len > 1000) {
      throw new Error('Max length 1000');
    }

    if (this.alphaNumArray.length <= 0) {
      this.buildAlphabet();
    }
    let randomString = '';
    while (len-- > 0) {
      randomString = randomString.concat(this.alphaNumArray[Math.floor(Math.random() * this.alphaNumArray.length)]);
    }
    return randomString;
  }

  public fileFormatIcon(fileFormat: string): string {
    if (fileFormat === 'xlsx' || fileFormat === 'xls') {
      return 'fa fa-file-excel-o';
    }
    if (fileFormat === 'docx' || fileFormat === 'doc') {
      return 'fa fa-file-word-o';
    } else { return 'fa fa-file'; }
  }

  public typeguard(o: any, properties: string[]) {
    return o && typeof o === 'object' && properties.every(key => key in o);
  }

  public copyMetadata(from: Section, to: Section): void {
    Object.keys(from).forEach(key => {
      if (key !== 'subSection' && this.typeguard(from[key], DataElement.getProperties())) {
        const fromDe: DataElement = from[key];
        const toDe: DataElement = to[key] || (to[key] = new DataElement());
        to[key] = { ...fromDe, elementValue: toDe.elementValue };
      }
    });
  }

  public copyMetadataIf(from: Section, to: Section, condition: boolean): void {
    if (condition) {
      this.copyMetadata(from, to);
    }
  }

  public addCommas(de: DataElement): void {
    if (de && !this.isNullOrEmpty(de.elementValue)) {
      de.elementValue = de.elementValue.replace(/,/g, '');
      const num = parseInt(de.elementValue, 10);
      if (!isNaN(num) && !de.elementValue.includes('.')) {
        de.elementValue = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
  }

  public addDecimals(de: DataElement): void {
    if (de && !this.isNullOrEmpty(de.elementValue)) {
      de.elementValue = de.elementValue.replace(/,/g, '');
      const num = parseFloat(de.elementValue);
      if (!isNaN(num)) {
        de.elementValue = num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      }
    }
  }

  private isNaNOrInfinity(value: number): boolean {
    return isNaN(value) || value === Infinity;
  }

  public calculatePerUnit(unitValue: number, ...parts: number[]): string {
    let perUnit = 'N/A';
    if (!this.isNaNOrInfinity(unitValue) && !parts.some(x => this.isNaNOrInfinity(x))) {
      const calculated = parts.reduce((prev, curr) => prev + curr, 0) / unitValue;
      if (!this.isNaNOrInfinity(calculated)) {
        perUnit = calculated.toString();
      }
    }
    return perUnit;
  }

  public upnToUserName(upn: string): string {
    let extTenantEmail = "";
    const azureADExternalTenantSpliter = "#EXT#@";
    const azureADLiveTenantSpliter = "live.com#";

    if (!this.isNullOrEmpty(upn) && upn.includes(azureADExternalTenantSpliter)) {
      extTenantEmail = upn.split('#')[0].replace("_", "@");
      return extTenantEmail;
    }
    if (!this.isNullOrEmpty(upn) && upn.includes(azureADLiveTenantSpliter)) {
      extTenantEmail = upn.split('#')[1];
      return extTenantEmail;
    }
    return upn;
  }

  /**
   * Converts Data Elements with the validation type BOOLEAN to string values
   */
  public convertBooleanDataElements<T extends Section>(section: T): T {
    Object.keys(section).forEach(k => {
      const property = section[k];
      if (this.isValidationType(property, ValidationTypes.Boolean)) {
        if (typeof property.elementValue === 'boolean') {
          property.elementValue = property.elementValue
            ? 'True' : 'False';
        }
      }
    });
    return section;
  }

  private isValidationType(obj: any, validationType: ValidationTypes): boolean {
    return obj
      && obj.validationType
      && obj.validationType === validationType;
  }

  /**
   * Returns year in format of YEAR-YEAR+1
   * @param ffy the start year
   */
  getYearDisplayText(ffy: string): string {
    let displayText = 'Loading';
    if (!isNaN(parseInt(ffy, 10))) {
      const yrFrom = parseInt(ffy, 10);
      const yrTo = yrFrom + 1;
      displayText = `${yrFrom}-${yrTo}`;
    }
    return displayText;
  }

  /**
   * Returns true if section can be saved false otherwise
   * @param s A section
   */
  public shouldSave(s: Section, isAAAEnabledStateUser: boolean, aaaStateEditMode: boolean): boolean {
    return s.isRequired
    && s.sectionStatus <= QuestionStatusEnum.Returned
    && (!isAAAEnabledStateUser || this.isStateLevelData(s) || (isAAAEnabledStateUser && (aaaStateEditMode || s.isRollup)));
  }

  private isStateLevelData(s: Section): boolean {
    return !s.isRollup && !s.psa;
  }

  /**
   * Takes a variance value and converts it for display
   * @param value string value representing variance
   * @param isDecimal flag for indicating the format of the percent value
   * @returns formatted percentage or 'N/A' if parsing fails
   */
  public formatVariance(value: string, isDecimal = true): string {
    if (isNaN(parseFloat(value))) {
      return 'N/A';
    } else {
      value = (Number(value) * (isDecimal ? 100 : 1)).toFixed(1).toString();
      return this.formatNumber(value) + '%';
    }
  }

  /**
   * Takes a number value and formats it for display
   * @param value string value representing a number
   * @returns formatted number or 'N/A' if parsing fails
   */
  formatNumber(value: string): string {
    if (value == null || value === '') {
      return 'N/A';
    }
    value = value.toString().replace(/,/g, '');
    if (!isNaN(parseInt(value))) {
      return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return 'N/A';
    }
  }

  /**
   * Find elements matching the predicate starting with the supplied root node and apply the given action
   * @param rootNode the root node who's children will be searched
   * @param predicate the test for each child node
   * @param action the action to be performed on matching nodes
   */
  public findElementsAndApply(rootNode: Element,
    predicate: (node: Element) => boolean = () => false,
    action: (node: Element) => void = () => null): void {
    for (let i = 0; rootNode && i < rootNode.children.length; i++) {
      const childNode = rootNode.children[i];
      if (predicate(childNode)) {
        action(childNode);
      }
    }
  }

  public setSubOrg(router: Router) {
    const psa = router.parseUrl(router.url).queryParamMap.get('psa');
    if (psa) {
      this._claimService.currentSubOrg = psa;
    }
  }

  /**
   * Used to get the end of day in Eastern time represented by a UTC Date
   * @param dateTime a string representing a date time
   * @returns a new Date representing the end of day for the given Date in Eastern time
   */
  getEndOfDay(dateTime: string|Date): Date {
    if (dateTime) {
      if (typeof dateTime === 'string' && !IsoDateTimeRegex.test(dateTime)) {
        throw new Error('Invalid format see https://www.iso.org/iso-8601-date-and-time-format.html');
      }

      let date = new Date(dateTime);
      const timezoneOffset = this.isDST(date) ? EST_GMT_OFFSET - 1 : EST_GMT_OFFSET;
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23 + timezoneOffset, 59, 59, 999));
    }
    return null;
  }

  /**
   * Used to get the beginning of day in Eastern time represented by a UTC Date
   * @param dateTime a string representing a date time
   * @returns a new Date representing the end of day for the given Date in Eastern time
   */
  getBeginningOfDay(dateTime: string|Date): Date {
    if (dateTime) {
      if (typeof dateTime === 'string' && !IsoDateTimeRegex.test(dateTime)) {
        throw new Error('Invalid format see https://www.iso.org/iso-8601-date-and-time-format.html');
      }

      let date = new Date(dateTime);
      const timezoneOffset = this.isDST(date) ? EST_GMT_OFFSET - 1 : EST_GMT_OFFSET;
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), timezoneOffset, 0, 0, 0));
    }
    return null;
  }

  getDaysRemaining(d0: Date, d1: Date): number {
    const dayInMs = 1000 * 60 * 60 * 24;
    let remainingTimeInDays = (d0.valueOf() - d1.valueOf()) / dayInMs;
    return remainingTimeInDays > 0
        ? Math.ceil(remainingTimeInDays)
        : Math.floor(remainingTimeInDays);
}

  private isDST(date: Date): boolean {
    const dstStart = new Date(Date.UTC(date.getFullYear(), 2, 7, 2 + EST_GMT_OFFSET));
    dstStart.setDate(7 + (7 - dstStart.getDay()));
    const dstEnd = new Date(Date.UTC(date.getFullYear(), 10, 1, 2 + EST_GMT_OFFSET - 1));
    dstEnd.setDate(dstEnd.getDate() + (7 - dstEnd.getDay()));
    return date >= dstStart && date <= dstEnd;
  }
}
