<span class="btn-floating btn-large">
    <i class="fa {{newsModel.listContentClass}}" aria-hidden="true"></i>
</span>
<h3>{{newsModel.headerText}}</h3>
<ul class="list-unstyled">
    <li *ngFor="let news of newsModel.newsList;">
        <p>
            <strong>
                {{news.shortText}}
            </strong>
        </p>
        <p>Date: {{news.reportingDate | amLocale:'en' | amDateFormat:'MM/DD/YYYY'}}</p>
        <p>{{news.longText}}</p>
    </li>
</ul>
<a [routerLink]="newsModel.url" class="btn btn-default">{{newsModel.moreLinkText}}</a>