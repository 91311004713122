<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <div class="col-md-12">
        <h2>Grantee Attachments</h2>

        <!-- uploads -->
        <div *ngIf="_attachments?.length > 0">
          <div *ngIf="currentTitle === 'VI'" class="col-md-12 table-responsive">
            <div class="mt-2 mb-1">
              <acl-dt-header [mfTable]="mf"></acl-dt-header>
            </div>
            <table class="table table-striped" [mfData]="_attachments" [mfRowsOnPage]="rowsOnPage" [(mfSortBy)]="sortBy"
            [(mfSortOrder)]="sortOrder" #mf="mfDataTable">
              <thead>
                <tr class="background-gray-lighter">
                  <th scope="col"><mfDataTableSorter by="granteeName">Grantee</mfDataTableSorter></th>
                  <th scope="col"><mfDataTableSorter by="fiscalYear">Fiscal Year</mfDataTableSorter></th>
                  <th scope="col"><mfDataTableSorter by="region">Region</mfDataTableSorter></th>
                  <th scope="col"><mfDataTableSorter by="state">State</mfDataTableSorter></th>
                  <th scope="col"><mfDataTableSorter by="fileName">File Name</mfDataTableSorter></th>
                  <th scope="col"><mfDataTableSorter by="categoryType">Category</mfDataTableSorter></th>
                  <th scope="col"><mfDataTableSorter by="created">Date Uploaded</mfDataTableSorter></th>
                  <th scope="col">Comments</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let attachment of mf.data">
                  <th>{{attachment.granteeName}}</th>
                  <td>{{attachment.fiscalYear}}</td>
                  <td>{{attachment.region}}</td>
                  <td>{{attachment.state}}</td>
                  <td><a [href]="attachment.azureUrl">{{attachment.fileName}}</a></td>
                  <td>{{attachment.categoryType}}</td>
                  <td> {{attachment.created | date : 'shortDate'}} at {{attachment.created | date : 'shortTime'}}</td>
                  <td>{{attachment.comments | truncate : 100 }}
                    <a *ngIf="attachment.comments.length > 100" tabindex="0">more</a>
                  </td>
                </tr>
              </tbody>
              <tfoot class="no-print" *ngIf="_attachments.length > rowsOnPage">
                <tr>
                  <td colspan="5">
                    <mfBootstrapPaginator></mfBootstrapPaginator>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div *ngIf="currentTitle !== 'VI'" class="col-md-12 table-responsive">
            <div class="mt-2 mb-1">
              <acl-dt-header [mfTable]="mf"></acl-dt-header>
            </div>
            <table class="table table-striped" [mfData]="_attachments" [mfRowsOnPage]="rowsOnPage" [(mfSortBy)]="sortBy"
            [(mfSortOrder)]="sortOrder" #mf="mfDataTable">
              <thead>
                <tr class="background-gray-lighter">
                  <th scope="col"><mfDataTableSorter by="org">State</mfDataTableSorter></th>
                  <th scope="col"><mfDataTableSorter by="fiscalYear">Ffy</mfDataTableSorter></th>
                  <th scope="col"><mfDataTableSorter by="fileName">File Name</mfDataTableSorter></th>
                  <th scope="col"><mfDataTableSorter by="categoryType">Category</mfDataTableSorter></th>
                  <th scope="col"><mfDataTableSorter by="created">Date Uploaded</mfDataTableSorter></th>
                  <th scope="col">Comments</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let attachment of mf.data">
                  <td>{{attachment.org}}</td>
                  <td>{{attachment.fiscalYear}}</td>
                  <td><a [href]="attachment.azureUrl">{{attachment.fileName}}</a></td>
                  <td>{{attachment.categoryType}}</td>
                  <td> {{attachment.created | date : 'shortDate'}} at {{attachment.created | date : 'shortTime'}}</td>
                  <td>{{attachment.comments | truncate : 100 }}
                    <a *ngIf="attachment.comments.length > 100" tabindex="0">more</a>
                  </td>
                </tr>
              </tbody>
              <tfoot class="no-print" *ngIf="_attachments.length > rowsOnPage">
                <tr>
                  <td colspan="5">
                    <mfBootstrapPaginator></mfBootstrapPaginator>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div *ngIf="_attachments?.length == 0" class="col-md-12">
          No attachments uploaded.
        </div>
        <!-- .end uploads -->

        <div class="m-1">
          <div class="row col-md-12">
            <a *ngIf="currentTitle === 'VI'" class="btn btn-link" [routerLink]="['/titlevi/acl-ppr-submissions-list']">
              Back to grantee listing
            </a>
            <a *ngIf="currentTitle === 'VII'" class="btn btn-link" [routerLink]="['/state-submissions/titleVIIDataSubmissions']">
              Back to state submissions
            </a>
            <a *ngIf="currentTitle === 'III'" class="btn btn-link" [routerLink]="['/data-submissions/titleIIIDataSubmissions']">
              Back to state submissions
            </a>
          </div>
        </div>

        <!-- old functionality that allows merging of sections, not deleting in case we want to re-enable it -->
        <!-- <div><button [disabled]="_sectionsToMerge.length <= 0 && _batchSectionsToUpdate.length <= 0" (click)="merge()" class="btn btn-primary" id="mergeData">Merge data</button></div> -->
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
