import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    template: '<div>Please wait...</div>'
})

export class OAuthCallbackComponent implements OnInit {
    constructor(public router: Router) { }

    ngOnInit() {
        // The normal flow should never lead to this component,
        // But if you do end up here e.g. URL entry, then route to welcome
        this.router.navigate(['/welcome']);
    }

}
