export abstract class GenericModel {
    public id: string;
    public type: string;
    public isDeleted: boolean;
    public created: Date;
    public createdBy: string;
    public updatedBy: string;
    public lastModified: Date;
}

export interface IGenericFfyStateModel {
    id: string;
    fiscalYear: string;
    state: string;
    type: string;
    isDeleted: boolean;
    createdBy: string;
    updatedBy: string;
    created: string;
    lastModified: string;
}

export class GenericFfyStateModel implements IGenericFfyStateModel {
    id: string;
    fiscalYear: string;
    state: string;
    type: string;
    isDeleted: boolean;
    createdBy: string;
    updatedBy: string;
    created: string;
    lastModified: string;
    constructor(fiscalYear: string, state: string) {
        this.fiscalYear = fiscalYear;
        this.state = state;
    }
}
export class FfyStateDto {
    ffy: string;
    state: string;
    psa: string;
    isRollup: boolean;
    createdBy: string;
    lastModified: string;
    batchId: string;
    title: string;
    overwrite: boolean;
    sectionName: string;
    constructor() { }
}
