import { Component, OnInit, NgZone, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { ClaimService } from '../shared/claim.service';
import { Constants } from '../app.constants';
import { ReportService } from '../reports/reports.service';
import { YearStateRegion, YearStateRegionReport } from '../reports/report.model';
import { LoadingComponent } from '../shared/loading.component';
import { PageViewComponent } from '../shared/PageView/page-view.component';
import { QuestionStatusEnum } from '../shared/dataElement';
import { YearApprovalStatus, ApprovalDecision } from '../shared/yearApprovalStatus.model';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { CommonService } from '../shared/common.service';
import { CommentTypeEnum, RollupTypes, OrgLevelEnum } from '../app.constants';
import { Comment } from '../shared/Comment/comment';
import { CommentService } from '../shared/Comment/comment.service';
import { DestructibleComponent } from '../destructible.component';
import { ProgramViewConstraints } from './ProgramViewContraints';
import { AclHeader } from '../shared/AclReviewHeader/aclHeader';
declare var jQuery: any;

@Component({
    templateUrl: 'titleIIISSReview.component.html',
})

export class TitleIIISSReviewComponent extends DestructibleComponent implements OnInit, OnDestroy {

    // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;
    @ViewChild('pvc', { static: true }) pageViewComponent: PageViewComponent;

    public _sprModel: YearApprovalStatus;
    public _nsipModel: YearApprovalStatus;
    public _nsipComment: Comment;
    public _sprComment: Comment;
    public _submissionLog: Array<YearApprovalStatus> = [];
    public confirmationFlow: string;
    public confirmationAction: string;
    public statusEnum = QuestionStatusEnum;
    public decisionEnum = ApprovalDecision;
    public viewConstraints = new ProgramViewConstraints();
    public aclHeader: AclHeader = new AclHeader();
    public loaded = false;
    public psa: string;

    constructor(public zone: NgZone,
        public route: ActivatedRoute,
        public router: Router,
        public _titleService: Title,
        public _service: TitleIIIDataEntryService,
        public _claimService: ClaimService,
        public _reportService: ReportService,
        public _commonService: CommonService,
        public _commentService: CommentService
    ) { super(); }
    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.psa = params.psa;

            this._claimService.currentSubOrg = this.psa;

            this._titleService.setTitle('Data Submissions - ACL OAAPS');
            this._claimService.isViewOnly = false;
            this._sprModel = new YearApprovalStatus();
            this._nsipModel = new YearApprovalStatus();
            this._sprComment = new Comment();
            this._nsipComment = new Comment();
            super.registerSubscription(this._claimService.init$.subscribe(isInit => {
                if (isInit) {
                    if (!this.pageViewComponent.pageData || Object.keys(this.pageViewComponent.pageData).length <= 0) {
                        let o$;
                        if (this._claimService.isRollupOverview) {
                            // Rollup overview
                            o$ = this._service.getRollupOverviewByFfyAndState(this._claimService.currentYear, this._claimService.currentOrg);
                        } else {
                            // State/AAA overview
                            o$ = this._service.getOverviewByFfyAndState(this._claimService.currentYear, this._claimService.currentOrg,
                                this._claimService.currentSubOrg);
                        }

                        super.registerSubscription(o$
                            .subscribe(data => {
                                this.pageViewComponent.pageData = data;
                                this.viewConstraints = new ProgramViewConstraints(this._claimService, data);
                                this.buildAclHeader();
                                this.setSectionDecision();
                                this.fetchPersistentComment();
                                this.loaded = true;
                            }));

                    } else {
                        this.fetchPersistentComment();
                    }

                }
            }));


        });
        this._service.aaaStateEditMode = false;
    }

    ngOnDestroy(): void {
        super.destroy();
    }

    fetchPersistentComment() {
        if (this.checkForPersistentSprComment() || this.checkForPersistentNsipComment()) {
            // this.loadingComponent.setLoadingInProgress(30);
            super.registerSubscription(this._commentService.getTIIICommentsByFfyStateTypes(this.pageViewComponent.pageData.ffy,
                this.pageViewComponent.pageData.state, this.pageViewComponent.pageData.psa,
                [CommentTypeEnum.IIITempReviewCommentNsip, CommentTypeEnum.IIITempReviewCommentSpr])
                .subscribe(data => {
                    if (data) {
                        data.forEach(comment => this.setPersistentComment(comment));
                    }
                }, null));
        }
    }

    // Comments
    getModel(type: CommentTypeEnum) {
        if (type === CommentTypeEnum.IIITempReviewCommentNsip) {
            return this._nsipModel;
        } else if (type === CommentTypeEnum.IIITempReviewCommentSpr) {
            return this._sprModel;
        }
    }
    setComment(comment: Comment) {
        if (comment.commentType === CommentTypeEnum.IIITempReviewCommentNsip) {
            this._nsipComment = comment;
        } else if (comment.commentType === CommentTypeEnum.IIITempReviewCommentSpr) {
            this._sprComment = comment;
        }
    }
    setPersistentComment(comment: Comment) {
        this.setComment(comment);
        const model = this.getModel(comment.commentType);
        model.comments = comment.value;
        if (comment.extraProps &&
            this.isDecisionSelected(parseInt(comment.extraProps['decision'], 10))) {
            model.decision = parseInt(comment.extraProps['decision'], 10);
        }
    }
    checkForPersistentSprComment(): boolean {
        return this.pageViewComponent.pageData.sprStatus >= QuestionStatusEnum.Returned
            && this.pageViewComponent.pageData.sprStatus <= QuestionStatusEnum.Approved;
    }

    checkForPersistentNsipComment(): boolean {
        return this.pageViewComponent.pageData.nsipStatus >= QuestionStatusEnum.Returned
            && this.pageViewComponent.pageData.nsipStatus <= QuestionStatusEnum.Approved;
    }

    isDecisionSelected(decision) {
        return decision === ApprovalDecision.Returned
            || decision === ApprovalDecision.Approved;
    }

    saveTempSprComments(renderLoader: boolean = true) {
        this._sprComment.state = this._claimService.currentOrg;
        this._sprComment.fiscalYear = this._claimService.currentYear;
        this._sprComment.psa = this._claimService.currentSubOrg;
        this.saveTempComments(this._sprModel, this._sprComment, CommentTypeEnum.IIITempReviewCommentSpr, renderLoader);
    }

    saveTempNsipComments(renderLoader: boolean = true) {
        this._nsipComment.state = this._claimService.currentOrg;
        this._nsipComment.fiscalYear = this._claimService.currentYear;
        this._nsipComment.psa = this._claimService.currentSubOrg;
        this.saveTempComments(this._nsipModel, this._nsipComment, CommentTypeEnum.IIITempReviewCommentNsip, renderLoader);
    }

    // Allows user to save a comment to the that will be copied over to the final decision
    saveTempComments(model: YearApprovalStatus, comment: Comment, commentType: CommentTypeEnum, renderLoader: boolean = true) {
        if (model.comments) {
            if (renderLoader) {
                // this.loadingComponent.setLoadingInProgress(15);
            }
            comment.value = model.comments;
            if (this.isDecisionSelected(model.decision)) {
                comment.extraProps = { decision: model.decision };
            }
            comment.commentType = commentType;
            comment.fiscalYear = this.pageViewComponent.pageData.ffy;
            comment.state = this.pageViewComponent.pageData.state;
            comment.psa = this.pageViewComponent.pageData.psa;
            super.registerSubscription(this._commentService.saveSingleTIIIComment(comment)
                .subscribe(data => { if (renderLoader) {  } },
                    err => {}));
        }
    }

    sanitizeSprModel(): void {
        this._sprModel.state = this._sprModel.psa = this._sprModel.fiscalYear = this._sprModel.comments = '';
        this._sprModel.decision = 0;
    }

    sanitizeNsipModel(): void {
        this._nsipModel.state = this._nsipModel.psa = this._nsipModel.fiscalYear = this._nsipModel.comments = '';
        this._nsipModel.decision = 0;
    }

    beginReview(flowType: string): void {
        // Update local model, no sense to make another network request
        if (flowType === 'SPR') {
            this.pageViewComponent.pageData.sprStatus = QuestionStatusEnum.InReview;
        } else if (flowType === 'NSIP') {
            this.pageViewComponent.pageData.nsipStatus = QuestionStatusEnum.InReview;
        }
        // this.loadingComponent.setLoadingInProgress(60);
        super.registerSubscription(this._service.review(this.pageViewComponent.pageData.ffy, this.pageViewComponent.pageData.state,
            this.pageViewComponent.pageData.psa, this.pageViewComponent.pageData.isRollup, flowType)
            .subscribe(data => {

            }));
    }
    showConfirmationPopup(flowType: string, action: string) {
        this.confirmationFlow = flowType;
        this.confirmationAction = action;
        jQuery('#confirmationPopup').modal('show');
    }
    dismissConfirmationPopup(): void {
        this.confirmationFlow = '';
        this.confirmationAction = '';
        jQuery('#confirmationPopup').modal('hide');
    }
    lockUnlockData() {
        if (this.confirmationAction === 'lock') {
            this.lockData(this.confirmationFlow);
        } else if (this.confirmationAction === 'unlock') {
            this.unlockData(this.confirmationFlow);
        }
    }

    lockData(flowType: string): void {
        // this.loadingComponent.setLoadingInProgress(50);
        if (flowType === 'NSIP' && this.pageViewComponent.pageData.nsipStatus === QuestionStatusEnum.Approved) {
            this.pageViewComponent.pageData.nsipStatus = QuestionStatusEnum.Locked;
        } else if (flowType === 'SPR' && this.pageViewComponent.pageData.sprStatus === QuestionStatusEnum.Approved) {
            this.pageViewComponent.pageData.sprStatus = QuestionStatusEnum.Locked;
        }
        this.setSectionDecision();
        super.registerSubscription(this._service.lock(this.pageViewComponent.pageData.ffy, this.pageViewComponent.pageData.state,
            this.pageViewComponent.pageData.isRollup, flowType)
            .subscribe(data => {

            }));
    }

    unlockData(flowType: string): void {
        // this.loadingComponent.setLoadingInProgress(50);
        if (flowType === 'NSIP' && this.pageViewComponent.pageData.nsipStatus === QuestionStatusEnum.Locked) {
            this.pageViewComponent.pageData.nsipStatus = QuestionStatusEnum.Approved;
        }
        if (flowType === 'SPR' && this.pageViewComponent.pageData.sprStatus === QuestionStatusEnum.Locked) {
            this.pageViewComponent.pageData.sprStatus = QuestionStatusEnum.Approved;
        }
        super.registerSubscription(this._service.unlock(this.pageViewComponent.pageData.ffy, this.pageViewComponent.pageData.state,
            this.pageViewComponent.pageData.isRollup, flowType)
            .subscribe(data => {

            }));
    }

    isSubmittedOrInReview(flowType: string) {
        if (flowType === 'SPR') {
            return this.pageViewComponent.pageData.sprStatus === QuestionStatusEnum.Submitted
                || this.pageViewComponent.pageData.sprStatus === QuestionStatusEnum.SubmittedNoExplanation
                || this.pageViewComponent.pageData.sprStatus === QuestionStatusEnum.InReview;
        } else if (flowType === 'NSIP') {
            return this.pageViewComponent.pageData.nsipStatus === QuestionStatusEnum.Submitted
                || this.pageViewComponent.pageData.nsipStatus === QuestionStatusEnum.SubmittedNoExplanation
                || this.pageViewComponent.pageData.nsipStatus === QuestionStatusEnum.InReview;
        }
    }

    saveReviewDecision(flowType: string): void {
        // this.loadingComponent.setLoadingInProgress(30);
        // Update local model, no sense to make another network request
        if (flowType === 'SPR') {
            this.saveTempSprComments(false);
            this.pageViewComponent.pageData.sprStatus = this._sprModel.decision === 1 ?
                QuestionStatusEnum.Returned : QuestionStatusEnum.Approved;
            this._sprModel.state = this.pageViewComponent.pageData.state;
            this._sprModel.psa = this.pageViewComponent.pageData.psa;
            this._sprModel.isRollup = this.pageViewComponent.pageData.isRollup;
            this._sprModel.fiscalYear = this.pageViewComponent.pageData.ffy;
            this._sprModel.titleIIISectionType = flowType;
            this._sprModel.status = this._sprModel.decision === 1 ? QuestionStatusEnum.Returned : QuestionStatusEnum.Approved;
            super.registerSubscription(this._service.approve(this._sprModel)
                .subscribe(data => {
                    this.viewConstraints.isSprViewable = this.viewConstraints.isSprViewable
                        && this._sprModel.decision !== ApprovalDecision.Returned;

                }));
            // commentType = CommentTypeEnum.IIITempReviewCommentSpr;
        } else if (flowType === 'NSIP') {
            this.saveTempNsipComments(false);
            this.pageViewComponent.pageData.nsipStatus = this._nsipModel.decision === 1 ?
                QuestionStatusEnum.Returned : QuestionStatusEnum.Approved;
            this._nsipModel.state = this.pageViewComponent.pageData.state;
            this._nsipModel.psa = this.pageViewComponent.pageData.psa;
            this._nsipModel.isRollup = this.pageViewComponent.pageData.isRollup;
            this._nsipModel.fiscalYear = this.pageViewComponent.pageData.ffy;
            this._nsipModel.titleIIISectionType = flowType;
            this._nsipModel.status = this._nsipModel.decision === 1 ? QuestionStatusEnum.Returned : QuestionStatusEnum.Approved;
            super.registerSubscription(this._service.approve(this._nsipModel)
                .subscribe(data => {
                    this.viewConstraints.isNsipViewable = this.viewConstraints.isNsipViewable
                        && this._nsipModel.decision !== ApprovalDecision.Returned;

                }));
        }
    }

    deleteTempComment(type: CommentTypeEnum) {
        super.registerSubscription(this._commentService.deleteTIIICommentsByFfyStateType(this.pageViewComponent.pageData.ffy,
            this.pageViewComponent.pageData.state, this.pageViewComponent.pageData.psa, type)
            .subscribe(data => {}));
    }

    dismiss(): void {
        jQuery('#submissionLog').modal('hide');
    }

    goToNsipData(): void {
        this._claimService.isViewOnly = true;
        this._claimService.currentSubOrg = this.pageViewComponent.pageData.psa;
        this._claimService.currentOrg = this.pageViewComponent.pageData.state;
        this.router.navigate(['/data-submissions/titleIIInsip']);
    }

    goToSprData(): void {
        this._claimService.isViewOnly = true;
        this._claimService.currentOrg = this.pageViewComponent.pageData.state;
        this._claimService.currentSubOrg = this.pageViewComponent.pageData.psa;
        this.router.navigate(['/data-submissions/titleIIIOverview'], {
            queryParams: {
                psa: this.pageViewComponent.pageData.psa                                                                                                                                                                                        }
        });
    }

    goToNsipVarianceExplanations(): void {
        this._claimService.isViewOnly = true;
        this._claimService.currentSubOrg = this.pageViewComponent.pageData.psa;
        this._claimService.currentOrg = this.pageViewComponent.pageData.state;
        this.router.navigate(['/data-submissions/titleIIIVarianceExplanationsNSIP']);
    }

    goToSprVarianceExplanations(): void {
        this._claimService.isViewOnly = true;
        this._claimService.currentSubOrg = this.pageViewComponent.pageData.psa;
        this._claimService.currentOrg = this.pageViewComponent.pageData.state;
        this.router.navigate(['/data-submissions/titleIIIVarianceExplanationsOA']);
    }

    export(): void {
        const rollupType = this._claimService.isRollupOverview ? RollupTypes.StateRollup : RollupTypes.None;
        // this.loadingComponent.setLoadingInProgress(180);
        super.registerSubscription(this._reportService.exporttitleiiidata(this._claimService.currentYear,
            this._claimService.currentOrg, this._claimService.currentSubOrg, rollupType).subscribe(data => {
                const downloadUrl = URL.createObjectURL(data as any);
                const a = window.document.createElement('a');
                a.href = downloadUrl;
                a.download = 'TitleIIIData.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);


            }));
    }

    public generateTwoYearReport(): void {

        // this.loadingComponent.setLoadingInProgress(35);
        const currentYear = parseInt(this._claimService.currentYear);
        const reportDetails: Array<YearStateRegion> = [];
        reportDetails.push(new YearStateRegion((currentYear - 1).toString(),
            this.pageViewComponent.pageData.state, this.pageViewComponent.pageData.psa, null, this.pageViewComponent.pageData.isRollup));
        reportDetails.push(new YearStateRegion((currentYear).toString(),
            this.pageViewComponent.pageData.state, this.pageViewComponent.pageData.psa, null, this.pageViewComponent.pageData.isRollup));
        const report: YearStateRegionReport = new YearStateRegionReport(reportDetails);
        report.userRole = 'ACL';
        super.registerSubscription(this._reportService.generate2yrReport(report, 'T3').subscribe(data => {
            const downloadUrl = URL.createObjectURL(data as any);
            const fileName =
                'SPR_TwoYrComparisonFFY' +
                (currentYear - 1) +
                '_' +
                currentYear +
                '.xlsx';
                const a = window.document.createElement('a');
                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

        }));
    }

    public generateTIIIPersonsServedServiceUnitsReport(): void {

        // this.loadingComponent.setLoadingInProgress(35);
        const currentYear = parseInt(this._claimService.currentYear);
        const reportDetails: Array<YearStateRegion> = [];
        reportDetails.push(new YearStateRegion((currentYear).toString(),
            this.pageViewComponent.pageData.state, this.pageViewComponent.pageData.psa, null, this.pageViewComponent.pageData.isRollup));
        const report: YearStateRegionReport = new YearStateRegionReport(reportDetails);
        report.userRole = 'ACL';
        super.registerSubscription(this._reportService.generateTIIIPersonsServedServiceUnitsReport(report).subscribe(data => {
            const downloadUrl = URL.createObjectURL(data as any);
            const fileName =
                'SPR_PersonsServed_ServiceUnits_FFY' +
                currentYear +
                '.xlsx';
                const a = window.document.createElement('a');
                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
        }));
    }

    public generateMissingDataReport(): void {

        // this.loadingComponent.setLoadingInProgress(35);
        const currentYear = parseInt(this._claimService.currentYear);
        const reportDetails: Array<YearStateRegion> = [];
        reportDetails.push(new YearStateRegion((currentYear).toString(),
            this.pageViewComponent.pageData.state, this.pageViewComponent.pageData.psa,
            Constants.STATES.find(x => x.abbr == this.pageViewComponent.pageData.state).region
            , this.pageViewComponent.pageData.isRollup));
        const report: YearStateRegionReport = new YearStateRegionReport(reportDetails);
        report.userRole = 'ACL';
        super.registerSubscription(this._reportService.generateMissingDataReport(report).subscribe(data => {
            const downloadUrl = URL.createObjectURL(data as any);
            const fileName =
                'Missing_Data_Report_FFY' +
                currentYear +
                '.xlsx';
                const a = window.document.createElement('a');
                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

        }));
    }


    public generateClientCharacteristicsReport(): void {
        // this.loadingComponent.setLoadingInProgress(35);
        const currentYear = parseInt(this._claimService.currentYear);
        const reportDetails: Array<YearStateRegion> = [];
        reportDetails.push(new YearStateRegion((currentYear).toString(),
            this.pageViewComponent.pageData.state, this.pageViewComponent.pageData.psa, null, this.pageViewComponent.pageData.isRollup));
        const report: YearStateRegionReport = new YearStateRegionReport(reportDetails);
        report.userRole = 'ACL';
        super.registerSubscription(this._reportService.generateClientCharacteristicsReport(report).subscribe(data => {
            const downloadUrl = URL.createObjectURL(data as any);
            const fileName =
                'Client_Characteristics_Report' +
                currentYear +
                '.xlsx';
                const a = window.document.createElement('a');
                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

        }));
    }

    submissionLogs(flowType: string): void {
        this._claimService.isViewOnly = true;
        this.router.navigate(['/data-submissions/titleIIIStatusLogSrpComponent'], {
            queryParams: {
                flowType: flowType
            }
        });
    }

    setSectionDecision() {
        if (this.pageViewComponent.pageData.sprStatus === QuestionStatusEnum.SubmittedNoExplanation
            || this.pageViewComponent.pageData.sprStatus === QuestionStatusEnum.Submitted) {
            this._sprModel.decision = null;
            // this.deleteTempComment(CommentTypeEnum.IIITempReviewCommentSpr);
        } else if (this.pageViewComponent.pageData.sprStatus === QuestionStatusEnum.Returned) {
            this._sprModel.decision = ApprovalDecision.Returned;
        } else if (this.pageViewComponent.pageData.sprStatus >= QuestionStatusEnum.Approved) {
            this._sprModel.decision = ApprovalDecision.Approved;
        }
        if (this.pageViewComponent.pageData.nsipStatus === QuestionStatusEnum.SubmittedNoExplanation
            || this.pageViewComponent.pageData.nsipStatus === QuestionStatusEnum.Submitted) {
            this._nsipModel.decision = null;
            // this.deleteTempComment(CommentTypeEnum.IIITempReviewCommentNsip);
        } else if (this.pageViewComponent.pageData.nsipStatus === QuestionStatusEnum.Returned) {
            this._nsipModel.decision = ApprovalDecision.Returned;
        } else if (this.pageViewComponent.pageData.nsipStatus >= QuestionStatusEnum.Approved) {
            this._nsipModel.decision = ApprovalDecision.Approved;
        }
    }

    isFormValid(model: YearApprovalStatus): boolean {
        // Invalid conditions: No decision, comments are empty for Returned decision, or comments are over 3400 chars.
        return ((model.decision === ApprovalDecision.Returned)
            && ((!this._commonService.isNullOrEmpty(model.comments) && model.comments.length <= 3400))
            || (model.decision === ApprovalDecision.Approved)
            && ((this._commonService.isNullOrEmpty(model.comments) || model.comments.length <= 3400)));
    }

    public get viewLevel(): string {
        let level = '';
        if (!this._commonService.isNullOrEmpty(this._claimService.currentSubOrg)) {
            level = 'AAA';
        } else {
            level = 'State';
        }
        return level;
    }

    goToReport() {
        this.router.navigate(['/reports/report']);
    }

    private buildAclHeader(): void {
        const isAAAStateUser = this._claimService.isAAAState;
        let title, orgLabel, orgName, returnLink, returnText, returnParams = <any>{};
        title = this._claimService.currentTitle;
        if (isAAAStateUser) {
            orgLabel = 'AAA';
            orgName = this._claimService.aaaName;
            returnText = 'Back to AAA listing';
            returnLink = '/data-submissions/aaaDataSubmissions';
        } else {
            orgLabel = 'State';
            orgName = this._commonService.stateAbbrvToFullName(this._claimService.currentOrg);
            returnText = 'Back to state listing';
            returnLink = '/data-submissions/titleIIIDataSubmissions';
        }
        this.aclHeader = {
            title,
            orgLabel,
            orgName,
            returnLink,
            returnText,
            returnParams,
            fiscalYear: this._claimService.currentYear,
            shouldRender: true
        };
    }

    public get isApprover(): boolean {
        return this._claimService.isApprover || this._claimService.currentOrgLevel === OrgLevelEnum[OrgLevelEnum.State];
    }

    public get isLocker(): boolean {
        return this._claimService.isNavLocker;
    }
}
