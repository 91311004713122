<button *ngIf="buttonText" data-toggle="modal" [attr.data-target]="'#' + id" [ngClass]="buttonClass">{{buttonText}}</button>

<div [attr.aria-labelledby]="id + 'Label'" class="modal fade" [id]="id" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" [ngStyle]="{ 'width': width }">
            <div *ngIf="headerText" class="modal-header">
                <h2 class="modal-title">{{headerText}}</h2>
            </div>
            <div class="modal-body">
                <ng-content></ng-content>
            </div>
            <div class="modal-footer">
                <button *ngFor="let button of buttons" tabindex="0" type="button" data-dismiss="modal"
                    [ngClass]="button.buttonClass" (keydown.enter)="button.action()"
                    (click)="button.action()">{{button.text}}</button>
            </div>
        </div>
    </div>
</div>