import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class NutritionCounselingUnitsSection extends Section {

    totalServiceUnits: IDataElement;
    expendC1: IDataElement;
    expendC2: IDataElement;
    partB: IDataElement;
    expendC: IDataElement;
    expendOtherState: IDataElement;
    expendOtherNonState: IDataElement;
    programIncome: IDataElement;
    expendTotal: IDataElement;
    totalExpendPerUnit: IDataElement;

    constructor() {
        super();

        this.totalServiceUnits = new DataElement();
        this.expendC1 = new DataElement();
        this.expendC2 = new DataElement();
        this.partB = new DataElement();
        this.expendC = new DataElement();
        this.expendOtherState = new DataElement();
        this.expendOtherNonState = new DataElement();
        this.programIncome = new DataElement();
        this.expendTotal = new DataElement();
        this.totalExpendPerUnit = new DataElement();

    }
}
