<loading #loadingSpinner></loading>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <acl-modal #confirmModal [headerText]="'Confirm Submission'" [id]="'submissionConfirmModal'" [buttons]="modalButtons">
    You have just completed the last of the required explanations of variances for SPR. If you chose to continue, your
    explanations of variances will be submitted to ACL for review.
    <br>
    <br>
    Once submitted, you will no longer be able to update the explanations of variances unless the complete submission is
    Pulled back for corrections. You can do so from the Overview page until ACL begins their review.
    <br>
    <br>
    If you are not ready to submit the explanations of variances, you can cancel and return to the Overview page.
  </acl-modal>
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9" *ngIf="currentFfyCGServiceAndExpenditureSummaryDto?.isRequired">
        <h2>Explanations of Variances for Caregivers of Older Adults<span
            class="print-only line">&nbsp;for&nbsp;{{_claimService.currentOrg}}</span></h2>
        <span class="block print-only">
          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
          <span class="sr-only">Variance threshold exceeded</span>
          = Variance threshold exceeded
        </span>
        <div class="row mb-1">
          <div class="col-md-12">
            <p class="pull-left pt-1">Enter explanations for each noted variance below. Once complete, mark each explanation as complete.</p>
            <button class="btn btn-default pull-right no-print" (click)='print()'>Print</button>
          </div>
        </div>
        <div class="row mb-1 no-print">
          <div class="text-right">
            <a class="btn btn-link" (click)="_commonService.expandAccordions()" (keyup.enter)="_commonService.expandAccordions()" tabindex="0">Expand all </a> |
            <a class="btn btn-link" (click)="_commonService.collapseAccordions()" (keyup.enter)="_commonService.collapseAccordions()" tabindex="0"> Collapse all</a>
          </div>
        </div>
        <div class="panel-group accordionGroup" id="CGAccordion" role="tablist" aria-multiselectable="true">
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="consumerOverviewHeading" data-toggle="collapse"
              data-target="#consumerOverviewBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#consumerOverviewBody"
                  aria-expanded="true" aria-controls="consumerOverviewBody">
                  <span class="ico"></span>
                  <b>Consumer Overview</b>
                  <span class="badge pull-right"
                    [innerHTML]="_commonService.accordionStatus(currentFfyCGServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="consumerOverviewBody" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="consumerOverviewHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Total registered Caregivers of Older Adults served</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRegisteredClients?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRegisteredClients?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRegisteredClients?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRegisteredClients?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRegisteredClients?.variance)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive"
                  [explanations]="currentFfyCGServiceAndExpenditureSummaryDto.consumerSummaryTotal?.rollupExplanations">
                </acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance
                      Explanation</strong></span>{{currentFfyCGServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.varianceExplanation?.explanation}}
                </p>
                <span class="clearfix no-print"
                  *ngIf="currentFfyCGServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.varianceExplanation">
                  <label [for]="consumerOverviewTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div
                    [ngClass]="calculateOutlineColor(currentFfyCGServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation?.explanation?.length)">
                    <textarea
                      (change)="unsavedChanges = true; currentFfyCGServiceAndExpenditureSummaryDto.consumerSummaryTotal.varianceExplanation.explanation = currentFfyCGServiceAndExpenditureSummaryDto.consumerSummaryTotal.varianceExplanation.explanation.trim();"
                      [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyCGServiceAndExpenditureSummaryDto.consumerSummaryTotal.varianceExplanation.isUserFinished || !currentFfyCGServiceAndExpenditureSummaryDto.consumerSummaryTotal.varianceExplanation.isRequired"
                      class="verticalResizeOnly form-control"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.explanation"
                      rows="4" id="consumerOverviewTextbox1" (blur)="checkValid()"></textarea>
                  </div>
                  <label class="pull-left" for="consumerSummaryTotalUserFinishedCheckbox">
                    <input (change)="unsavedChanges = true;" id="consumerSummaryTotalUserFinishedCheckbox"
                      type="checkbox"
                      [disabled]="markAsCompleteCheckboxDisabled(currentFfyCGServiceAndExpenditureSummaryDto.consumerSummaryTotal.varianceExplanation)"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.consumerSummaryTotal.varianceExplanation.isUserFinished" />
                    &nbsp;Mark as complete
                  </label>
                  <small class="form-text help-block pull-right">{{currentFfyCGServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                  <div
                    *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation?.explanation?.length > 3400"
                    class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="counselingHeading" data-toggle="collapse"
              data-target="#counselingBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#counselingBody" aria-expanded="true"
                  aria-controls="counselingBody">
                  <span class="ico"></span>
                  <b>Counseling</b>
                  <span class="badge pull-right"
                    [innerHTML]="_commonService.accordionStatus(currentFfyCGServiceAndExpenditureSummaryDto?.counseling?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="counselingBody" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="counselingHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.counseling?.personsServed?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.counseling?.personsServed?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.counseling.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.counseling.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.counseling.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.counseling?.serviceUnits?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.counseling?.serviceUnits?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.counseling.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.counseling.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.counseling.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.counseling?.totalExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.counseling?.totalExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.counseling.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.counseling.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.counseling.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.counseling?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.counseling?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.counseling.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.counseling.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.counseling.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.counseling?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.counseling?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.counseling.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.counseling.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.counseling.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.counseling?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.counseling?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.counseling.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.counseling.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.counseling.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.counseling?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.counseling?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.counseling.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.counseling.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.counseling.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.counseling?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.counseling?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.counseling.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.counseling.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.counseling.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.counseling?.totalExpenditurePerUnit?.nationalValue)}}
                      </td>
                      <td>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto?.counseling?.totalExpenditurePerUnit?.nationalVariance, false)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive"
                  [explanations]="currentFfyCGServiceAndExpenditureSummaryDto.counseling?.rollupExplanations">
                </acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance
                      Explanation</strong></span>{{currentFfyCGServiceAndExpenditureSummaryDto.counseling?.varianceExplanation?.explanation}}
                </p>
                <span class="clearfix no-print"
                  *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.counseling?.varianceExplanation">
                  <label [for]="counselingTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div
                    [ngClass]="calculateOutlineColor(currentFfyCGServiceAndExpenditureSummaryDto.counseling?.varianceExplanation?.explanation?.length)">
                    <textarea
                      (change)="unsavedChanges = true; currentFfyCGServiceAndExpenditureSummaryDto.counseling?.varianceExplanation.explanation = currentFfyCGServiceAndExpenditureSummaryDto.counseling?.varianceExplanation.explanation.trim()"
                      [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyCGServiceAndExpenditureSummaryDto.counseling.varianceExplanation.isUserFinished || !currentFfyCGServiceAndExpenditureSummaryDto.counseling.varianceExplanation.isRequired"
                      class="verticalResizeOnly form-control"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.counseling?.varianceExplanation.explanation"
                      rows="4" id="counselingTextbox1" (blur)="checkValid()"></textarea>
                  </div>
                  <label class="pull-left" for="counselingUserFinishedCheckbox">
                    <input (change)="unsavedChanges = true;" id="counselingUserFinishedCheckbox" type="checkbox"
                      [disabled]="markAsCompleteCheckboxDisabled(currentFfyCGServiceAndExpenditureSummaryDto.counseling.varianceExplanation)"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.counseling.varianceExplanation.isUserFinished" />
                    &nbsp;Mark as complete
                  </label>
                  <small class="form-text help-block pull-right">{{currentFfyCGServiceAndExpenditureSummaryDto.counseling?.varianceExplanation.explanation?.length
                    || 0}}
                    of 3400 characters</small>
                  <div
                    *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.counseling?.varianceExplanation?.explanation?.length > 3400"
                    class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="trainingHeading" data-toggle="collapse"
              data-target="#trainingBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#trainingBody" aria-expanded="true"
                  aria-controls="trainingBody">
                  <span class="ico"></span>
                  <b>Training</b>
                  <span class="badge pull-right"
                    [innerHTML]="_commonService.accordionStatus(currentFfyCGServiceAndExpenditureSummaryDto?.training?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="trainingBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="trainingHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.training?.personsServed?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.training?.personsServed?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.training.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.training.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.training.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.training?.serviceUnits?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.training?.serviceUnits?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.training.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.training.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.training.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.training?.totalExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.training?.totalExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.training.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.training.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.training.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.training?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.training?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.training.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.training.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.training.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.training?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.training?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.training.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.training.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.training.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.training?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.training?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.training.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.training.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.training.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.training?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.training?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.training.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.training.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.training.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.training?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.training?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.training.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.training.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.training.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.training?.totalExpenditurePerUnit?.nationalValue)}}
                      </td>
                      <td>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto?.training?.totalExpenditurePerUnit?.nationalVariance, false)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive"
                  [explanations]="currentFfyCGServiceAndExpenditureSummaryDto.training?.rollupExplanations">
                </acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance
                      Explanation</strong></span>{{currentFfyCGServiceAndExpenditureSummaryDto.training?.varianceExplanation?.explanation}}
                </p>
                <span class="clearfix no-print"
                  *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.training?.varianceExplanation">
                  <label [for]="trainingTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div
                    [ngClass]="calculateOutlineColor(currentFfyCGServiceAndExpenditureSummaryDto.training?.varianceExplanation?.explanation?.length)">
                    <textarea
                      (change)="unsavedChanges = true; currentFfyCGServiceAndExpenditureSummaryDto.training?.varianceExplanation.explanation = currentFfyCGServiceAndExpenditureSummaryDto.training?.varianceExplanation.explanation.trim()"
                      [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyCGServiceAndExpenditureSummaryDto.training.varianceExplanation.isUserFinished || !currentFfyCGServiceAndExpenditureSummaryDto.training.varianceExplanation.isRequired"
                      class="verticalResizeOnly form-control"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.training?.varianceExplanation.explanation"
                      rows="4" id="trainingTextbox1" (blur)="checkValid()"></textarea>
                  </div>
                  <label class="pull-left" for="trainingUserFinishedCheckbox">
                    <input (change)="unsavedChanges = true;" id="trainingUserFinishedCheckbox" type="checkbox"
                      [disabled]="markAsCompleteCheckboxDisabled(currentFfyCGServiceAndExpenditureSummaryDto.training.varianceExplanation)"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.training.varianceExplanation.isUserFinished" />
                    &nbsp;Mark as complete
                  </label>
                  <small class="form-text help-block pull-right">{{currentFfyCGServiceAndExpenditureSummaryDto.training?.varianceExplanation.explanation?.length
                    || 0}}
                    of 3400 characters</small>
                  <div
                    *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.training?.varianceExplanation?.explanation?.length > 3400"
                    class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="totalRespiteHeading" data-toggle="collapse"
              data-target="#totalRespiteBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#totalRespiteBody" aria-expanded="true"
                  aria-controls="totalRespiteBody">
                  <span class="ico"></span>
                  <b>Total Respite</b>
                  <span class="badge pull-right"
                    [innerHTML]="_commonService.accordionStatus(currentFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="totalRespiteBody" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="totalRespiteHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.personsServed?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.personsServed?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.serviceUnits?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.serviceUnits?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditurePerUnit?.nationalValue)}}
                      </td>
                      <td>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditurePerUnit?.nationalVariance, false)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive"
                  [explanations]="currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.rollupExplanations">
                </acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance
                      Explanation</strong></span>{{currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation?.explanation}}
                </p>
                <span class="clearfix no-print"
                  *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation">
                  <label [for]="totalRespiteTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div
                    [ngClass]="calculateOutlineColor(currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation?.explanation?.length)">
                    <textarea
                      (change)="unsavedChanges = true; currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation.explanation = currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation.explanation.trim()"
                      [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyCGServiceAndExpenditureSummaryDto.totalRespite.varianceExplanation.isUserFinished || !currentFfyCGServiceAndExpenditureSummaryDto.totalRespite.varianceExplanation.isRequired"
                      class="verticalResizeOnly form-control"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation.explanation"
                      rows="4" id="totalRespiteTextbox1" (blur)="checkValid()"></textarea>
                  </div>
                  <label class="pull-left" for="totalRespiteUserFinishedCheckbox">
                    <input (change)="unsavedChanges = true;" id="totalRespiteUserFinishedCheckbox" type="checkbox"
                      [disabled]="markAsCompleteCheckboxDisabled(currentFfyCGServiceAndExpenditureSummaryDto.totalRespite.varianceExplanation)"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.totalRespite.varianceExplanation.isUserFinished" />
                    &nbsp;Mark as complete
                  </label>
                  <small class="form-text help-block pull-right">{{currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                  <div
                    *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation?.explanation?.length > 3400"
                    class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="supplementalServicesHeading" data-toggle="collapse"
              data-target="#supplementalServicesBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#supplementalServicesBody"
                  aria-expanded="true" aria-controls="supplementalServicesBody">
                  <span class="ico"></span>
                  <b>Supplemental Services</b>
                  <span class="badge pull-right"
                    [innerHTML]="_commonService.accordionStatus(currentFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="supplementalServicesBody" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="supplementalServicesHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.personsServed?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.personsServed?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <!-- <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr> -->
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per person</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditurePerUnit?.nationalValue)}}
                      </td>
                      <td>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditurePerUnit?.nationalVariance, false)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive"
                  [explanations]="currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices?.rollupExplanations">
                </acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance
                      Explanation</strong></span>{{currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation?.explanation}}
                </p>
                <span class="clearfix no-print"
                  *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation">
                  <label [for]="supplementalServicesTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div
                    [ngClass]="calculateOutlineColor(currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation?.explanation?.length)">
                    <textarea
                      (change)="unsavedChanges = true; currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation.explanation = currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation.explanation.trim()"
                      [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.varianceExplanation.isUserFinished || !currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.varianceExplanation.isRequired"
                      class="verticalResizeOnly form-control"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation.explanation"
                      rows="4" id="supplementalServicesTextbox1" (blur)="checkValid()"></textarea>
                  </div>
                  <label class="pull-left" for="supplementalServicesUserFinishedCheckbox">
                    <input (change)="unsavedChanges = true;" id="supplementalServicesUserFinishedCheckbox"
                      type="checkbox"
                      [disabled]="markAsCompleteCheckboxDisabled(currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.varianceExplanation)"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices.varianceExplanation.isUserFinished" />
                    &nbsp;Mark as complete
                  </label>
                  <small class="form-text help-block pull-right">{{currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                  <div
                    *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation?.explanation?.length > 3400"
                    class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="caseManagementHeading" data-toggle="collapse"
              data-target="#caseManagementBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#caseManagementBody"
                  aria-expanded="true" aria-controls="caseManagementBody">
                  <span class="ico"></span>
                  <b>Case Management</b>
                  <span class="badge pull-right"
                    [innerHTML]="_commonService.accordionStatus(currentFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="caseManagementBody" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="caseManagementHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.personsServed?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.personsServed?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.serviceUnits?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.serviceUnits?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.nationalValue)}}
                      </td>
                      <td>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.nationalVariance, false)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive"
                  [explanations]="currentFfyCGServiceAndExpenditureSummaryDto.caseManagement?.rollupExplanations">
                </acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance
                      Explanation</strong></span>{{currentFfyCGServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation?.explanation}}
                </p>
                <span class="clearfix no-print"
                  *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation">
                  <label [for]="caseManagementTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div
                    [ngClass]="calculateOutlineColor(currentFfyCGServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation?.explanation?.length)">
                    <textarea
                      (change)="unsavedChanges = true; currentFfyCGServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation = currentFfyCGServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation.trim()"
                      [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.varianceExplanation.isUserFinished || !currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.varianceExplanation.isRequired"
                      class="verticalResizeOnly form-control"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation"
                      rows="4" id="caseManagementTextbox1" (blur)="checkValid()"></textarea>
                  </div>
                  <label class="pull-left" for="caseManagementUserFinishedCheckbox">
                    <input (change)="unsavedChanges = true;" id="caseManagementUserFinishedCheckbox" type="checkbox"
                      [disabled]="markAsCompleteCheckboxDisabled(currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.varianceExplanation)"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.caseManagement.varianceExplanation.isUserFinished" />
                    &nbsp;Mark as complete
                  </label>
                  <small class="form-text help-block pull-right">{{currentFfyCGServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                  <div
                    *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation?.explanation?.length > 3400"
                    class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="supportGroupsHeading" data-toggle="collapse"
              data-target="#supportGroupsBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#supportGroupsBody" aria-expanded="true"
                  aria-controls="supportGroupsBody">
                  <span class="ico"></span>
                  <b>Support Groups</b>
                  <span class="badge pull-right"
                    [innerHTML]="_commonService.accordionStatus(currentFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="supportGroupsBody" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="supportGroupsHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.serviceUnits?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.serviceUnits?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditurePerUnit?.nationalValue)}}
                      </td>
                      <td>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditurePerUnit?.nationalVariance, false)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive"
                  [explanations]="currentFfyCGServiceAndExpenditureSummaryDto.supportGroups?.rollupExplanations">
                </acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance
                      Explanation</strong></span>{{currentFfyCGServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation?.explanation}}
                </p>
                <span class="clearfix no-print"
                  *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation">
                  <label [for]="supportGroupsTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div
                    [ngClass]="calculateOutlineColor(currentFfyCGServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation?.explanation?.length)">
                    <textarea
                      (change)="unsavedChanges = true; currentFfyCGServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation.explanation = currentFfyCGServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation.explanation.trim()"
                      [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.varianceExplanation.isUserFinished || !currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.varianceExplanation.isRequired"
                      class="verticalResizeOnly form-control"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation.explanation"
                      rows="4" id="supportGroupsTextbox1" (blur)="checkValid()"></textarea>
                  </div>
                  <label class="pull-left" for="supportGroupsUserFinishedCheckbox">
                    <input (change)="unsavedChanges = true;" id="supportGroupsUserFinishedCheckbox" type="checkbox"
                      [disabled]="markAsCompleteCheckboxDisabled(currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.varianceExplanation)"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.supportGroups.varianceExplanation.isUserFinished" />
                    &nbsp;Mark as complete
                  </label>
                  <small class="form-text help-block pull-right">{{currentFfyCGServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                  <div
                    *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation?.explanation?.length > 3400"
                    class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="infoAndAssitanceHeading" data-toggle="collapse"
              data-target="#infoAndAssitanceBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#infoAndAssitanceBody"
                  aria-expanded="true" aria-controls="infoAndAssitanceBody">
                  <span class="ico"></span>
                  <b>Information and Assistance</b>
                  <span class="badge pull-right"
                    [innerHTML]="_commonService.accordionStatus(currentFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="infoAndAssitanceBody" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="infoAndAssitanceHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.personsServed?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.personsServed?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.serviceUnits?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.serviceUnits?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditurePerUnit?.nationalValue)}}
                      </td>
                      <td>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditurePerUnit?.nationalVariance, false)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive"
                  [explanations]="currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance?.rollupExplanations">
                </acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance
                      Explanation</strong></span>{{currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation?.explanation}}
                </p>
                <span class="clearfix no-print"
                  *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation">
                  <label [for]="infoAndAssitanceTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div
                    [ngClass]="calculateOutlineColor(currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation?.explanation?.length)">
                    <textarea
                      (change)="unsavedChanges = true; currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation.explanation = currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation.explanation.trim()"
                      [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.varianceExplanation.isUserFinished || !currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.varianceExplanation.isRequired"
                      class="verticalResizeOnly form-control"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation.explanation"
                      rows="4" id="infoAndAssitanceTextbox1" (blur)="checkValid()"></textarea>
                  </div>
                  <label class="pull-left" for="infoAndAssitanceUserFinishedCheckbox">
                    <input (change)="unsavedChanges = true;" id="infoAndAssitanceUserFinishedCheckbox" type="checkbox"
                      [disabled]="markAsCompleteCheckboxDisabled(currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.varianceExplanation)"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance.varianceExplanation.isUserFinished" />
                    &nbsp;Mark as complete
                  </label>
                  <small class="form-text help-block pull-right">{{currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                  <div
                    *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation?.explanation?.length > 3400"
                    class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="infoServicesHeading" data-toggle="collapse"
              data-target="#infoServicesBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#infoServicesBody" aria-expanded="true"
                  aria-controls="infoServicesBody">
                  <span class="ico"></span>
                  <b>Information Services</b>
                  <span class="badge pull-right"
                    [innerHTML]="_commonService.accordionStatus(currentFfyCGServiceAndExpenditureSummaryDto?.infoServices?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="infoServicesBody" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="infoServicesHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.infoServices?.personsServed?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.infoServices?.personsServed?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoServices.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>
                        {{formatNumber(previousFfyCGServiceAndExpenditureSummaryDto?.infoServices?.serviceUnits?.elementValue)}}
                      </td>
                      <td>
                        {{formatNumber(currentFfyCGServiceAndExpenditureSummaryDto?.infoServices?.serviceUnits?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoServices.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoServices.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoServices?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoServices?.titleIIIExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoServices.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoServices?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoServices?.otherStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoServices.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoServices?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoServices?.otherNonStateExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoServices.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoServices?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoServices?.programIncomeExpenditure?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoServices.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>
                        {{formatCurrency(previousFfyCGServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditurePerUnit?.elementValue)}}
                      </td>
                      <td
                        [ngClass]="(!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span
                          *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto.infoServices.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto.infoServices.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>
                        {{formatCurrency(currentFfyCGServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditurePerUnit?.nationalValue)}}
                      </td>
                      <td>
                        {{formatVariance(currentFfyCGServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditurePerUnit?.nationalVariance, false)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive"
                  [explanations]="currentFfyCGServiceAndExpenditureSummaryDto.infoServices?.rollupExplanations">
                </acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance
                      Explanation</strong></span>{{currentFfyCGServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation?.explanation}}
                </p>
                <span class="clearfix no-print"
                  *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation">
                  <label [for]="infoServicesTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div
                    [ngClass]="calculateOutlineColor(currentFfyCGServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation?.explanation?.length)">
                    <textarea
                      (change)="unsavedChanges = true; currentFfyCGServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation.explanation = currentFfyCGServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation.explanation.trim()"
                      [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyCGServiceAndExpenditureSummaryDto.infoServices.varianceExplanation.isUserFinished || !currentFfyCGServiceAndExpenditureSummaryDto.infoServices.varianceExplanation.isRequired"
                      class="verticalResizeOnly form-control"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation.explanation"
                      rows="4" id="infoServicesTextbox1" (blur)="checkValid()"></textarea>
                  </div>
                  <label class="pull-left" for="infoServicesUserFinishedCheckbox">
                    <input (change)="unsavedChanges = true;" id="infoServicesUserFinishedCheckbox" type="checkbox"
                      [disabled]="markAsCompleteCheckboxDisabled(currentFfyCGServiceAndExpenditureSummaryDto.infoServices.varianceExplanation)"
                      [(ngModel)]="currentFfyCGServiceAndExpenditureSummaryDto.infoServices.varianceExplanation.isUserFinished" />
                    &nbsp;Mark as complete
                  </label>
                  <small class="form-text help-block pull-right">{{currentFfyCGServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                  <div
                    *ngIf="currentFfyCGServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation?.explanation?.length > 3400"
                    class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row col-md-12 no-print">
          <button [disabled]="!isValid" class="btn btn-primary mr-1" (click)="saveAndNext()">Save
            and
            next</button>
          <button [disabled]="!isValid" class="btn btn-default" (click)="save()">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to
            Overview</a>
        </div>
      </div>
      <!-- .end col-md-9 -->
      <div class="col-md-9" *ngIf="!currentFfyCGServiceAndExpenditureSummaryDto?.isRequired">
        <h2>Explanations of Variances for Caregivers of Older Adults</h2>
        <span>Caregivers of Older Adults Explanations are not required</span>
      </div>
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
