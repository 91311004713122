<!-- begin main content -->
<div class="container " role="main" id="main-content">
  <loading #loadingSpinner></loading>
  <div class="row">
    <div class="col-md-12 acl-container">
      <loading #loadingSpinner></loading>
      <div class="col-md-12">
        <div class="row border-bottom-gray-light">
          <div class="col-md-6 pl-0">
            <h1 class="border-none">Data Submissions</h1>
          </div>
          <!-- .end col-md-6 -->
          <div class="col-md-3 pr-0 col-md-push-3">
            <!-- .begin year picker dropdown -->
            <fiscalYearDropdown></fiscalYearDropdown>
            <!-- .end year picker dropdown -->
          </div>
          <!-- .end col-md-3 -->
        </div>
        <!-- .end row -->
      </div>


      <div class="row">
        <div class="col-md-12">
          <div class="flex-list panel-group">
            <!-- card -->
            <a tabindex="0" class="flex-list-item" id="responsibility" [routerLink]="['../dataSubmissionResponsibility']" routerLinkActive="router-link-active">
              <div class="panel panel-primary-inverted flex-list-content">
                <div class="panel-body">
                  <p>
                    <strong>Data Submissions Responsibility</strong>
                  </p>
                  <hr />
                  <small>Identify the data submission responsibility for the year between AAAs and the state. </small>
                </div>
              </div>
            </a>
            <!-- end card -->
            <!-- card -->
            <a tabindex="0" class="flex-list-item" id="statedata" (click)="navActionState()" routerLinkActive="router-link-active">
              <div class="panel panel-primary-inverted flex-list-content">
                <div class="panel-body">
                  <p>
                    <strong>State-Provided Data</strong>
                  </p>
                  <hr />
                  <small>Enter data for sections responsible by the state.</small>
                </div>
              </div>
            </a>
            <!-- end card -->
            <!-- card -->
            <a tabindex="0" class="flex-list-item" id="aaasubmissions" (click)="navActionAAA()" routerLinkActive="router-link-active">
              <div class="panel panel-primary-inverted flex-list-content">
                <div class="panel-body">
                  <p>
                    <strong>AAA Submissions</strong>
                  </p>
                  <hr />
                  <small>Check AAA data entry status and review AAA data submissions</small>
                </div>
              </div>
            </a>
            <!-- end card -->
          </div>
          <!-- end flex-list panel-group -->
          <acl-state-rollup 
            [canGenerateRollup]="canGenerateRollup"
            [rollup]="rollup" 
            (uploadStateRollup)="uploadStateRollup()" 
            (navAction)="navActionRollup()" 
            (rollupModal)="showRollupOptions()">
          </acl-state-rollup>
        </div>
        <!-- end col-md-12 -->
      </div>
      <!-- end row -->
    </div>
    <!--  end col-md-12 acl-container -->
  </div>
  <!-- end row -->
</div>
<!-- end container -->

<div class="modal fade in" tabindex="-1" id="rollupModal">
  <div class="container" role="main" id="main-content">
    <div class="row">
        <div class="col-md-12 acl-container">
            <div>
                <h2>State and AAA Selection</h2>
                <div class="mb-2">
                  <acl-rollup-multiselect 
                    [grantees]="availableGrantees" 
                    [(selected)]="selectedGrantees"
                    (selectedChange)="selectedChange($event)">
                  </acl-rollup-multiselect>
                  <hr />
                </div>
                <div class="container-fluid col-md-8 mt-1">
                    <button class="btn btn-primary" (click)="generateStateRollup()" data-dismiss="modal" [disabled]="disableSave">Generate roll-up</button>
                    <button type="button" class="btn btn-link" data-dismiss="modal">Cancel</button>
                </div>
                <!-- .end container-fluid .end col-md-8 -->
            </div>
            <!-- .end col-md-9 -->
        </div>
        <!-- .end acl-container (col-md-12) -->
    </div>
  </div>  
</div>

<!-- <div class="modal fade in" tabindex="-1" id="rollupModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2>State and AAA Selection</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <acl-rollup-multiselect [grantees]="availableGrantees" [(selected)]="selectedGrantees"></acl-rollup-multiselect>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> -->
