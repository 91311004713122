<div *ngIf="bannerMessage?.isActive" #banner class="banner" [ngClass]="bannerStyle">
  <div class="content">
    <div class="desc">
      <p><strong>{{bannerMessage.headerText}}</strong></p>
      <p [innerHtml]="bannerMessage.descriptionHtml"></p>
    </div>
    <div>
      <button class="pull-right btn btn-secondary btn-small" (click)="bannerMessage.isActive = false">Close</button>
    </div>
  </div>
</div>