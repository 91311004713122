import { VarianceExplanation } from '../shared/varianceExplanation.model';
import { DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';
import { ServiceExpenditure } from '../shared/serviceExpenditure';
import { VarianceExplanationDto } from './Dto/VarianceExplanationDto';


export class CGConsumerSummaryTotal extends Section {
    public totalClients: DataElement;
    public totalRegisteredClients: DataElement;
    public totalUnregisteredClients: DataElement;
    public varianceExplanation: VarianceExplanation;
    public rollupExplanations: VarianceExplanationDto[];


    constructor() {
        super();
        this.totalClients = new DataElement();
        this.totalRegisteredClients = new DataElement();
        this.totalUnregisteredClients = new DataElement();
    }
}

export class CGServiceAndExpenditureSummaryDto extends Section {
    public ffy: string;
    public state: string;
    public consumerSummaryTotal: CGConsumerSummaryTotal;
    public counseling: ServiceExpenditure;
    public training: ServiceExpenditure;
    public supplementalServices: ServiceExpenditure;
    public caseManagement: ServiceExpenditure;
    public totalRespite: ServiceExpenditure;
    public inHomeRespite: ServiceExpenditure;
    public outOfHomeDayRespite: ServiceExpenditure;
    public outOfHomeNightRespite: ServiceExpenditure;
    public unknownRespite: ServiceExpenditure;
    public supportGroups: ServiceExpenditure;
    public infoAndAssitance: ServiceExpenditure;
    public infoServices: ServiceExpenditure;
    public total: ServiceExpenditure;
    public shouldSubmit: boolean;
    public confirmationPrompt: boolean;

    constructor() {
        super();
        this.ffy = '';
        this.state = '';
        this.consumerSummaryTotal = new CGConsumerSummaryTotal();
        this.counseling = new ServiceExpenditure();
        this.training = new ServiceExpenditure();
        this.supplementalServices = new ServiceExpenditure();
        this.caseManagement = new ServiceExpenditure();
        this.inHomeRespite = new ServiceExpenditure();
        this.outOfHomeDayRespite = new ServiceExpenditure();
        this.outOfHomeNightRespite = new ServiceExpenditure();
        this.unknownRespite = new ServiceExpenditure();
        this.supportGroups = new ServiceExpenditure();
        this.infoAndAssitance = new ServiceExpenditure();
        this.infoServices = new ServiceExpenditure();
        this.total = new ServiceExpenditure();
    }
}
