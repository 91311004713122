import { Component, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { ORCConsumerSummarySection } from './ORCConsumerSummarySection';
import { CommonService } from '../shared/common.service';
import { ToastrService } from '../shared/toastr.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { ValidationService } from '../shared/validation.service';
import { TitleIIISectionComponent } from './title-iii-section.component';

@Component({
  templateUrl: 'titleIIIORCConsumerSummary.component.html',
})


export class TitleIIIORCConsumerSummaryComponent extends TitleIIISectionComponent<ORCConsumerSummarySection> implements OnInit, OnDestroy {

  _sectionModel: ORCConsumerSummarySection;
  _uploadModels: Array<ORCConsumerSummarySection>;
  _batchIds: Array<string>;
  allAgeTotalArr: Array<DataElement>;
  bpAgeTotalArr: Array<DataElement>;
  allGenderTotalArr: Array<DataElement>;
  bpGenderTotalArr: Array<DataElement>;
  allGeoTotalArr: Array<DataElement>;
  bpGeoTotalArr: Array<DataElement>;
  allPovertyTotalArr: Array<DataElement>;
  allEthnicityTotalArr: Array<DataElement>;
  bpEthnicityTotalArr: Array<DataElement>;
  allMinorityTotalArr: Array<DataElement>;
  bpMinorityTotalArr: Array<DataElement>;
  allRelationshipTotalArr: Array<DataElement>;
  bpRelationshipTotalArr: Array<DataElement>;
  pageName: string;
  statusEnum = QuestionStatusEnum;

  // age
  totalRange1Text: string = 'Total 55-59';
  bpRange1Text: string = '50-59 at or below poverty';
  totalRange2Text: string = 'Total 60-64';
  bpRange2Text: string = '60-64 at or below poverty';
  totalRange3Text: string = 'Total 65-74';
  bpRange3Text: string = '65-74 at or below poverty';
  totalRange4Text: string = 'Total 75-84';
  bpRange4Text: string = '75-84 at or below poverty';
  totalRange5Text: string = 'Total 85 and above';
  bpRange5Text: string = '85 and above at or below poverty';
  totalAgeMissingText: string = 'Total Age missing';
  bpAgeMissingText: string = 'Age missing at or below poverty';
  allAgeTotalText: string = 'Total for Total Persons Served in the Age section';
  bpAgeTotalText: string = 'Total Persons Served at or below poverty in the Age section';

  // gender
  totalFemaleText: string = 'Total Female';
  bpFemaleText: string = 'Female at or below poverty';
  totalMaleText: string = 'Total Male';
  bpMaleText: string = 'Male at or below poverty';
  totalOtherText: string = 'Total Other';
  bpOtherText: string = 'Other at or below poverty';
  totalGenderMissingText: string = 'Total Gender missing';
  bpGenderMissingText: string = 'Gender missing at or below poverty';
  allGenderTotalText: string = 'Total for Total Persons Served in the Gender section';
  bpGenderTotalText: string = 'Total Persons Served at or below poverty in the Gender section';

  // geographic
  totalRuralText: string = 'Rural Total';
  bpRuralText: string = 'Rural at or below poverty';
  totalNonRuralText: string = 'Total Non-rural';
  bpNonRuralText: string = 'Non-rural at or below poverty';
  totalGeoMissingText: string = 'Total Geographic distribution missing';
  bpGeoMissingText: string = 'Geographic distribution missing at or below poverty';
  allGeoTotalText: string = 'Total for Total Persons Served in the Geographic section';
  bpGeoTotalText: string = 'Total Persons Served at or below poverty in the Geographic section';

  // poverty
  totalBelowPovertyText: string = 'At or below poverty Total';
  totalAbovePovertyText: string = 'Total Above poverty';
  totalMissingPovertyText: string = 'Total Poverty status missing';
  allPovertyTotalText: string = 'Total for Total Persons Served in the Poverty section';

  // ethnicity
  totalHispanicText: string = 'Hispanic or Latino Total';
  bpHispanicText: string = 'Hispanic or Latino at or below poverty';
  totalNonHispanicText: string = 'Total Not Hispanic or Latino';
  bpNonHispanicText: string = 'Not Hispanic or Latino at or below poverty';
  totalEthnicityMissingText: string = 'Total Ethnicity missing';
  bpEthnicityMissingText: string = 'Ethnicity missing at or below poverty';
  allEthnicityTotalText: string = 'Total for Total Persons Served in Ethnicity section';
  bpEthnicityTotalText: string = 'Total Persons Served at or below poverty in Ethnicity section';

  // race
  totalAmericanIndianText: string = 'American Indian or Alaska Native Total';
  bpAmericanIndianText: string = 'American Indian or Alaska Native at or below poverty';
  totalAsianText: string = 'Total Asian or Asian American';
  bpAsianText: string = 'Asian or Asian American at or below poverty';
  totalBlackText: string = 'Total Black or African American';
  bpBlackText: string = 'Black or African Americanat or below poverty';
  totalIslanderText: string = 'Native Hawaiian or  Pacific Islander Total';
  bpIslanderText: string = 'Native Hawaiian or  Pacific Islander at or below poverty';
  totalWhiteText: string = 'Total White';
  bpWhiteText: string = 'White at or below poverty';
  totalRaceOtherText: string = 'Total other race';
  bpRaceOtherText: string = 'Other race at or below poverty';
  totalRaceMultipleText: string = 'Total multiple race';
  bpRaceMultipleText: string = 'Multiple race at or below poverty';
  totalRaceMissingText: string = 'Total Race missing';
  bpRaceMissingText: string = 'Race missing at or below poverty';

  // minority
  totalMinorityText: string = 'Total Minority';
  bpMinorityText: string = 'Minority at or below poverty';
  totalNotMinorityText: string = 'Total Not minority';
  bpNotMinorityText: string = 'Not minority at or below poverty';
  totalMinorityMissingText: string = 'Total Not minority';
  bpMinorityMissingText: string = 'Not minority at or below poverty';
  allMinorityTotalText: string = 'Total for Total Persons Served in the Minority section';
  bpMinorityTotalText: string = 'Total Persons Served at or below poverty in the Minority section';

  // Relationship Distribution
  totalGrandparentsText: string = 'Grandparents total';
  bpGrandparentsText: string = 'Grandparents at or below poverty';
  totalParentsText: string = 'Parents total';
  bpParentsText: string = 'Parents at or below poverty';
  totalOtherRelText: string = 'Other relative total';
  bpOtherRelText: string = 'Other relative at or below poverty';
  totalNonText: string = 'Non-relative total';
  bpNonText: string = 'Non-relative at or below poverty';
  totalRelMissingText: string = 'Relative missing total';
  bpRelMissingText: string = 'Relative missing';
  allRelationshipTotalText: string = 'Total for Total Persons Served in the Relationship section';
  bpRelationshipTotalText: string = 'Total Persons Served at or below poverty in the Relationship section';

  unsavedChanges: boolean = false;

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _commonService: CommonService,
    public _validation: ValidationService,
    public toastr: ToastrService
  ) {
      super(new ORCConsumerSummarySection(), _commonService, _validation, router, _claimService, _service);
    }

  ngOnInit(): void {
    this._titleService.setTitle('ORC - Consumer Summary - Persons Receiving Registered Services - Data Submissions - ACL OAAPS');
    this._sectionModel = new ORCConsumerSummarySection();
    this.getorcconsumersummarybyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    super.registerSubscription(this._service.saveorcconsumersummary(this._sectionModel)
      .subscribe(data => this.zone.run(() => {
        this._sectionModel = data;
        this.initArrays();
      }),
      (error)=> console.log(error),
      ()=>{
        callback();

        this.toastr.success('Saved!');
      }
      ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIIORCCounseling']);
    if (!this._claimService.isViewOnly &&
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  next(): void {
    this.router.navigate(['/data-submissions/titleIIIORCCounseling']);
}

  getorcconsumersummarybyffyandstate(): void {
    super.registerSubscription(this._service.get<ORCConsumerSummarySection[]>(this._claimService,
      this._service._getorcconsumersummarybyffyandstate, this.resolveSectionName())
      .subscribe(data => this.zone.run(() => {
        if (data.length > 0) {
          // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
          let tempSectionModel: ORCConsumerSummarySection = new ORCConsumerSummarySection();
          tempSectionModel = data.filter(element => element.batchId === '00000000-0000-0000-0000-000000000000')[0];
          if (tempSectionModel) {
            this._sectionModel = tempSectionModel;
            this.initArrays();
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
            // initialize arrays for summing
            this.initArrays();
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
        } else {
          this.initArrays();
        }

      })));
  }

  initArrays(): void {
    this.allAgeTotalArr = [this._sectionModel.totalRange1,
    this._sectionModel.totalRange2,
    this._sectionModel.totalRange3,
    this._sectionModel.totalRange4,
    this._sectionModel.totalRange5,
    this._sectionModel.totalAgeMissing];

    this.bpAgeTotalArr = [this._sectionModel.bpRange1,
    this._sectionModel.bpRange2,
    this._sectionModel.bpRange3,
    this._sectionModel.bpRange4,
    this._sectionModel.bpRange5,
    this._sectionModel.bpAgeMissing];

    this.allGenderTotalArr = [this._sectionModel.totalFemale,
    this._sectionModel.totalMale,
    this._sectionModel.totalOther,
    this._sectionModel.totalGenderMissing];

    this.bpGenderTotalArr = [this._sectionModel.bpFemale,
    this._sectionModel.bpMale,
    this._sectionModel.bpOther,
    this._sectionModel.bpGenderMissing];

    this.allGeoTotalArr = [this._sectionModel.totalRural,
    this._sectionModel.totalNonRural,
    this._sectionModel.totalGeoMissing];

    this.bpGeoTotalArr = [this._sectionModel.bpRural,
    this._sectionModel.bpNonRural,
    this._sectionModel.bpGeoMissing];

    this.allPovertyTotalArr = [this._sectionModel.totalBelowPoverty,
    this._sectionModel.totalAbovePoverty,
    this._sectionModel.totalMissingPoverty];

    this.allEthnicityTotalArr = [this._sectionModel.totalHispanic,
    this._sectionModel.totalNonHispanic,
    this._sectionModel.totalEthnicityMissing];

    this.bpEthnicityTotalArr = [this._sectionModel.bpHispanic,
    this._sectionModel.bpNonHispanic,
    this._sectionModel.bpEthnicityMissing];

    this.allMinorityTotalArr = [this._sectionModel.totalMinority,
    this._sectionModel.totalNotMinority,
    this._sectionModel.totalMinorityMissing];

    this.bpMinorityTotalArr = [this._sectionModel.bpMinority,
    this._sectionModel.bpNotMinority,
    this._sectionModel.bpMinorityMissing];

    this.allRelationshipTotalArr = [this._sectionModel.totalGrandparents,
    this._sectionModel.totalParents,
    this._sectionModel.totalOtherRel,
    this._sectionModel.totalNon,
    this._sectionModel.totalRelMissing];

    this.bpRelationshipTotalArr = [this._sectionModel.bpGrandparents,
    this._sectionModel.bpParents,
    this._sectionModel.bpOtherRel,
    this._sectionModel.bpNon,
    this._sectionModel.bpRelMissing];
  }


  updateTotal(totalsArray: Array<DataElement>): string {
    let total: number = 0;

    for (let i = 0; i < totalsArray.length; i++) {
      // if not null, remove commas
      if (totalsArray[i].elementValue) {
        totalsArray[i].elementValue = totalsArray[i].elementValue.replace(/,/g, '');
      }
      // if is a number, sum it
      if (!isNaN(parseInt(totalsArray[i].elementValue))) {
        total += parseInt(totalsArray[i].elementValue);
      }
      // replace commas when done adding
      totalsArray[i].elementValue = this.addComma(totalsArray[i].elementValue);
    }

    // add comma and return
    return this.addComma(total.toString());
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.totalPersonsServed.elementValue = this.addComma(this._sectionModel.totalPersonsServed.elementValue);
    this._sectionModel.totalRange1.elementValue = this.addComma(this._sectionModel.totalRange1.elementValue);
    this._sectionModel.bpRange1.elementValue = this.addComma(this._sectionModel.bpRange1.elementValue);
    this._sectionModel.totalRange2.elementValue = this.addComma(this._sectionModel.totalRange2.elementValue);
    this._sectionModel.bpRange2.elementValue = this.addComma(this._sectionModel.bpRange2.elementValue);
    this._sectionModel.totalRange3.elementValue = this.addComma(this._sectionModel.totalRange3.elementValue);
    this._sectionModel.bpRange3.elementValue = this.addComma(this._sectionModel.bpRange3.elementValue);
    this._sectionModel.totalRange4.elementValue = this.addComma(this._sectionModel.totalRange4.elementValue);
    this._sectionModel.bpRange4.elementValue = this.addComma(this._sectionModel.bpRange4.elementValue);
    this._sectionModel.totalRange5.elementValue = this.addComma(this._sectionModel.totalRange5.elementValue);
    this._sectionModel.bpRange5.elementValue = this.addComma(this._sectionModel.bpRange5.elementValue);
    this._sectionModel.totalAgeMissing.elementValue = this.addComma(this._sectionModel.totalAgeMissing.elementValue);
    this._sectionModel.bpAgeMissing.elementValue = this.addComma(this._sectionModel.bpAgeMissing.elementValue);
    this._sectionModel.allAgeTotal.elementValue = this.addComma(this._sectionModel.allAgeTotal.elementValue);
    this._sectionModel.bpAgeTotal.elementValue = this.addComma(this._sectionModel.bpAgeTotal.elementValue);
    this._sectionModel.totalFemale.elementValue = this.addComma(this._sectionModel.totalFemale.elementValue);
    this._sectionModel.bpFemale.elementValue = this.addComma(this._sectionModel.bpFemale.elementValue);
    this._sectionModel.totalMale.elementValue = this.addComma(this._sectionModel.totalMale.elementValue);
    this._sectionModel.bpMale.elementValue = this.addComma(this._sectionModel.bpMale.elementValue);
    this._sectionModel.totalOther.elementValue = this.addComma(this._sectionModel.totalOther.elementValue);
    this._sectionModel.bpOther.elementValue = this.addComma(this._sectionModel.bpOther.elementValue);
    this._sectionModel.totalGenderMissing.elementValue = this.addComma(this._sectionModel.totalGenderMissing.elementValue);
    this._sectionModel.bpGenderMissing.elementValue = this.addComma(this._sectionModel.bpGenderMissing.elementValue);
    this._sectionModel.allGenderTotal.elementValue = this.addComma(this._sectionModel.allGenderTotal.elementValue);
    this._sectionModel.bpGenderTotal.elementValue = this.addComma(this._sectionModel.bpGenderTotal.elementValue);
    this._sectionModel.totalRural.elementValue = this.addComma(this._sectionModel.totalRural.elementValue);
    this._sectionModel.bpRural.elementValue = this.addComma(this._sectionModel.bpRural.elementValue);
    this._sectionModel.totalNonRural.elementValue = this.addComma(this._sectionModel.totalNonRural.elementValue);
    this._sectionModel.bpNonRural.elementValue = this.addComma(this._sectionModel.bpNonRural.elementValue);
    this._sectionModel.totalGeoMissing.elementValue = this.addComma(this._sectionModel.totalGeoMissing.elementValue);
    this._sectionModel.bpGeoMissing.elementValue = this.addComma(this._sectionModel.bpGeoMissing.elementValue);
    this._sectionModel.allGeoTotal.elementValue = this.addComma(this._sectionModel.allGeoTotal.elementValue);
    this._sectionModel.bpGeoTotal.elementValue = this.addComma(this._sectionModel.bpGeoTotal.elementValue);
    this._sectionModel.totalBelowPoverty.elementValue = this.addComma(this._sectionModel.totalBelowPoverty.elementValue);
    this._sectionModel.totalAbovePoverty.elementValue = this.addComma(this._sectionModel.totalAbovePoverty.elementValue);
    this._sectionModel.totalMissingPoverty.elementValue = this.addComma(this._sectionModel.totalMissingPoverty.elementValue);
    this._sectionModel.allPovertyTotal.elementValue = this.addComma(this._sectionModel.allPovertyTotal.elementValue);
    this._sectionModel.totalHispanic.elementValue = this.addComma(this._sectionModel.totalHispanic.elementValue);
    this._sectionModel.bpHispanic.elementValue = this.addComma(this._sectionModel.bpHispanic.elementValue);
    this._sectionModel.totalNonHispanic.elementValue = this.addComma(this._sectionModel.totalNonHispanic.elementValue);
    this._sectionModel.bpNonHispanic.elementValue = this.addComma(this._sectionModel.bpNonHispanic.elementValue);
    this._sectionModel.totalEthnicityMissing.elementValue = this.addComma(this._sectionModel.totalEthnicityMissing.elementValue);
    this._sectionModel.bpEthnicityMissing.elementValue = this.addComma(this._sectionModel.bpEthnicityMissing.elementValue);
    this._sectionModel.allEthnicityTotal.elementValue = this.addComma(this._sectionModel.allEthnicityTotal.elementValue);
    this._sectionModel.bpEthnicityTotal.elementValue = this.addComma(this._sectionModel.bpEthnicityTotal.elementValue);
    this._sectionModel.totalAmericanIndian.elementValue = this.addComma(this._sectionModel.totalAmericanIndian.elementValue);
    this._sectionModel.bpAmericanIndian.elementValue = this.addComma(this._sectionModel.bpAmericanIndian.elementValue);
    this._sectionModel.totalAsian.elementValue = this.addComma(this._sectionModel.totalAsian.elementValue);
    this._sectionModel.bpAsian.elementValue = this.addComma(this._sectionModel.bpAsian.elementValue);
    this._sectionModel.totalBlack.elementValue = this.addComma(this._sectionModel.totalBlack.elementValue);
    this._sectionModel.bpBlack.elementValue = this.addComma(this._sectionModel.bpBlack.elementValue);
    this._sectionModel.totalIslander.elementValue = this.addComma(this._sectionModel.totalIslander.elementValue);
    this._sectionModel.bpIslander.elementValue = this.addComma(this._sectionModel.bpIslander.elementValue);
    this._sectionModel.totalWhite.elementValue = this.addComma(this._sectionModel.totalWhite.elementValue);
    this._sectionModel.bpWhite.elementValue = this.addComma(this._sectionModel.bpWhite.elementValue);
    this._sectionModel.totalRaceOther.elementValue = this.addComma(this._sectionModel.totalRaceOther.elementValue);
    this._sectionModel.bpRaceOther.elementValue = this.addComma(this._sectionModel.bpRaceOther.elementValue);
    this._sectionModel.totalRaceMultiple.elementValue = this.addComma(this._sectionModel.totalRaceMultiple.elementValue);
    this._sectionModel.bpRaceMultiple.elementValue = this.addComma(this._sectionModel.bpRaceMultiple.elementValue);
    this._sectionModel.totalRaceMissing.elementValue = this.addComma(this._sectionModel.totalRaceMissing.elementValue);
    this._sectionModel.bpRaceMissing.elementValue = this.addComma(this._sectionModel.bpRaceMissing.elementValue);
    this._sectionModel.totalMinority.elementValue = this.addComma(this._sectionModel.totalMinority.elementValue);
    this._sectionModel.bpMinority.elementValue = this.addComma(this._sectionModel.bpMinority.elementValue);
    this._sectionModel.totalNotMinority.elementValue = this.addComma(this._sectionModel.totalNotMinority.elementValue);
    this._sectionModel.bpNotMinority.elementValue = this.addComma(this._sectionModel.bpNotMinority.elementValue);
    this._sectionModel.totalMinorityMissing.elementValue = this.addComma(this._sectionModel.totalMinorityMissing.elementValue);
    this._sectionModel.bpMinorityMissing.elementValue = this.addComma(this._sectionModel.bpMinorityMissing.elementValue);
    this._sectionModel.allMinorityTotal.elementValue = this.addComma(this._sectionModel.allMinorityTotal.elementValue);
    this._sectionModel.bpMinorityTotal.elementValue = this.addComma(this._sectionModel.bpMinorityTotal.elementValue);
    this._sectionModel.totalGrandparents.elementValue = this.addComma(this._sectionModel.totalGrandparents.elementValue);
    this._sectionModel.bpGrandparents.elementValue = this.addComma(this._sectionModel.bpGrandparents.elementValue);
    this._sectionModel.totalParents.elementValue = this.addComma(this._sectionModel.totalParents.elementValue);
    this._sectionModel.bpParents.elementValue = this.addComma(this._sectionModel.bpParents.elementValue);
    this._sectionModel.totalOtherRel.elementValue = this.addComma(this._sectionModel.totalOtherRel.elementValue);
    this._sectionModel.bpOtherRel.elementValue = this.addComma(this._sectionModel.bpOtherRel.elementValue);
    this._sectionModel.totalNon.elementValue = this.addComma(this._sectionModel.totalNon.elementValue);
    this._sectionModel.bpNon.elementValue = this.addComma(this._sectionModel.bpNon.elementValue);
    this._sectionModel.totalRelMissing.elementValue = this.addComma(this._sectionModel.totalRelMissing.elementValue);
    this._sectionModel.bpRelMissing.elementValue = this.addComma(this._sectionModel.bpRelMissing.elementValue);
    this._sectionModel.allRelationshipTotal.elementValue = this.addComma(this._sectionModel.allRelationshipTotal.elementValue);
    this._sectionModel.bpRelationshipTotal.elementValue = this.addComma(this._sectionModel.bpRelationshipTotal.elementValue);
    this._sectionModel.totalChildren.elementValue = this.addComma(this._sectionModel.totalChildren.elementValue);
    this._sectionModel.totalDisabled.elementValue = this.addComma(this._sectionModel.totalDisabled.elementValue);
  }

  ageError(): boolean {
    if (this._sectionModel.totalRange1.dataEntryErrorMessage ||
      this._sectionModel.bpRange1.dataEntryErrorMessage ||
      this._sectionModel.totalRange2.dataEntryErrorMessage ||
      this._sectionModel.bpRange2.dataEntryErrorMessage ||
      this._sectionModel.totalRange3.dataEntryErrorMessage ||
      this._sectionModel.bpRange3.dataEntryErrorMessage ||
      this._sectionModel.totalRange4.dataEntryErrorMessage ||
      this._sectionModel.bpRange4.dataEntryErrorMessage ||
      this._sectionModel.totalRange5.dataEntryErrorMessage ||
      this._sectionModel.bpRange5.dataEntryErrorMessage ||
      this._sectionModel.totalAgeMissing.dataEntryErrorMessage ||
      this._sectionModel.bpAgeMissing.dataEntryErrorMessage ||
      this._sectionModel.allAgeTotal.dataEntryErrorMessage ||
      this._sectionModel.bpAgeTotal.dataEntryErrorMessage ||
      this._sectionModel.totalRange1.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange1.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange2.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange2.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange3.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange3.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange4.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange4.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange5.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange5.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalAgeMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAgeMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allAgeTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAgeTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  genderError(): boolean {
    if (this._sectionModel.totalFemale.dataEntryErrorMessage ||
      this._sectionModel.bpFemale.dataEntryErrorMessage ||
      this._sectionModel.totalMale.dataEntryErrorMessage ||
      this._sectionModel.bpMale.dataEntryErrorMessage ||
      this._sectionModel.totalOther.dataEntryErrorMessage ||
      this._sectionModel.bpOther.dataEntryErrorMessage ||
      this._sectionModel.totalGenderMissing.dataEntryErrorMessage ||
      this._sectionModel.bpGenderMissing.dataEntryErrorMessage ||
      this._sectionModel.allGenderTotal.dataEntryErrorMessage ||
      this._sectionModel.bpGenderTotal.dataEntryErrorMessage ||
      this._sectionModel.totalFemale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpFemale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalMale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalGenderMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGenderMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allGenderTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGenderTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  geoError(): boolean {
    if (this._sectionModel.totalRural.dataEntryErrorMessage ||
      this._sectionModel.bpRural.dataEntryErrorMessage ||
      this._sectionModel.totalNonRural.dataEntryErrorMessage ||
      this._sectionModel.bpNonRural.dataEntryErrorMessage ||
      this._sectionModel.totalGeoMissing.dataEntryErrorMessage ||
      this._sectionModel.bpGeoMissing.dataEntryErrorMessage ||
      this._sectionModel.allGeoTotal.dataEntryErrorMessage ||
      this._sectionModel.bpGeoTotal.dataEntryErrorMessage ||
      this._sectionModel.totalRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalNonRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNonRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalGeoMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGeoMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allGeoTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGeoTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  povertyError(): boolean {
    if (this._sectionModel.totalBelowPoverty.dataEntryErrorMessage ||
      this._sectionModel.totalAbovePoverty.dataEntryErrorMessage ||
      this._sectionModel.totalMissingPoverty.dataEntryErrorMessage ||
      this._sectionModel.allPovertyTotal.dataEntryErrorMessage ||
      this._sectionModel.totalBelowPoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalAbovePoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalMissingPoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allPovertyTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  ethnicityError(): boolean {
    if (this._sectionModel.totalHispanic.dataEntryErrorMessage ||
      this._sectionModel.bpHispanic.dataEntryErrorMessage ||
      this._sectionModel.totalNonHispanic.dataEntryErrorMessage ||
      this._sectionModel.bpNonHispanic.dataEntryErrorMessage ||
      this._sectionModel.totalEthnicityMissing.dataEntryErrorMessage ||
      this._sectionModel.bpEthnicityMissing.dataEntryErrorMessage ||
      this._sectionModel.allEthnicityTotal.dataEntryErrorMessage ||
      this._sectionModel.bpEthnicityTotal.dataEntryErrorMessage ||
      this._sectionModel.totalHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalNonHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNonHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalEthnicityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpEthnicityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allEthnicityTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpEthnicityTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  raceError(): boolean {
    if (
      this._sectionModel.totalAmericanIndian.dataEntryErrorMessage ||
      this._sectionModel.bpAmericanIndian.dataEntryErrorMessage ||
      this._sectionModel.totalAsian.dataEntryErrorMessage ||
      this._sectionModel.bpAsian.dataEntryErrorMessage ||
      this._sectionModel.totalBlack.dataEntryErrorMessage ||
      this._sectionModel.bpBlack.dataEntryErrorMessage ||
      this._sectionModel.totalIslander.dataEntryErrorMessage ||
      this._sectionModel.bpIslander.dataEntryErrorMessage ||
      this._sectionModel.totalWhite.dataEntryErrorMessage ||
      this._sectionModel.bpWhite.dataEntryErrorMessage ||
      this._sectionModel.totalRaceOther.dataEntryErrorMessage ||
      this._sectionModel.bpRaceOther.dataEntryErrorMessage ||
      this._sectionModel.totalRaceMultiple.dataEntryErrorMessage ||
      this._sectionModel.bpRaceMultiple.dataEntryErrorMessage ||
      this._sectionModel.totalRaceMissing.dataEntryErrorMessage ||
      this._sectionModel.bpRaceMissing.dataEntryErrorMessage ||
      this._sectionModel.totalAmericanIndian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAmericanIndian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalAsian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAsian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalBlack.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpBlack.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalIslander.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpIslander.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalWhite.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpWhite.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRaceOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRaceOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRaceMultiple.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRaceMultiple.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRaceMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRaceMissing.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  minorityError(): boolean {
    if (this._sectionModel.totalMinority.dataEntryErrorMessage ||
      this._sectionModel.bpMinority.dataEntryErrorMessage ||
      this._sectionModel.totalNotMinority.dataEntryErrorMessage ||
      this._sectionModel.bpNotMinority.dataEntryErrorMessage ||
      this._sectionModel.totalMinorityMissing.dataEntryErrorMessage ||
      this._sectionModel.bpMinorityMissing.dataEntryErrorMessage ||
      this._sectionModel.allMinorityTotal.dataEntryErrorMessage ||
      this._sectionModel.bpMinorityTotal.dataEntryErrorMessage ||
      this._sectionModel.totalMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalNotMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNotMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalMinorityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMinorityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allMinorityTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMinorityTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  relationshipError(): boolean {
    if (this._sectionModel.totalGrandparents.dataEntryErrorMessage ||
      this._sectionModel.totalParents.dataEntryErrorMessage ||
      this._sectionModel.totalOtherRel.dataEntryErrorMessage ||
      this._sectionModel.totalNon.dataEntryErrorMessage ||
      this._sectionModel.totalRelMissing.dataEntryErrorMessage ||
      this._sectionModel.bpGrandparents.dataEntryErrorMessage ||
      this._sectionModel.bpParents.dataEntryErrorMessage ||
      this._sectionModel.bpOtherRel.dataEntryErrorMessage ||
      this._sectionModel.bpNon.dataEntryErrorMessage ||
      this._sectionModel.bpRelMissing.dataEntryErrorMessage ||
      this._sectionModel.totalGrandparents.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalParents.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalOtherRel.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalNon.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRelMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allRelationshipTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGrandparents.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpParents.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpOtherRel.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNon.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRelMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRelationshipTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  ageWarning(): boolean {
    if (this._sectionModel.totalRange1.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange1.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange3.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange3.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange5.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange5.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalAgeMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAgeMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allAgeTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAgeTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  genderWarning(): boolean {
    if (this._sectionModel.totalFemale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpFemale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalMale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalGenderMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGenderMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allGenderTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGenderTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }
  geoWarning(): boolean {
    if (this._sectionModel.totalRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalNonRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNonRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalGeoMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGeoMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allGeoTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGeoTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  povertyWarning(): boolean {
    if (this._sectionModel.totalBelowPoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalAbovePoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalMissingPoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allPovertyTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  ethnicityWarning(): boolean {
    if (this._sectionModel.totalHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalNonHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNonHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalEthnicityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpEthnicityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allEthnicityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpEthnicityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  raceWarning(): boolean {
    if (this._sectionModel.totalAmericanIndian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAmericanIndian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalAsian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAsian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalBlack.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpBlack.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalIslander.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpIslander.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalWhite.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpWhite.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRaceOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRaceOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRaceMultiple.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRaceMultiple.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRaceMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRaceMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  minorityWarning(): boolean {
    if (this._sectionModel.totalMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalNotMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNotMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalMinorityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMinorityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allMinorityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMinorityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  relationshipWarning(): boolean {
    if (this._sectionModel.totalGrandparents.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalParents.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalOtherRel.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalNon.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRelMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGrandparents.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpParents.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpOtherRel.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNon.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRelMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.orcLockedNav = false;
      return true;
    } else {
      this._commonService.orcLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      let msg = 'You have unsaved changes. Press OK to proceed without saving these changes, ';
      msg += 'or press Cancel to go back and save these changes.';
      $event.returnValue = msg;
    }
  }

  validate(data: DataElement): DataElement {
    if (data.elementValue != null && data.elementValue !== '') {
      if (this._validation.validate(data.validationType, data.elementValue)) {
        if (!this._validation.validateMaxLength(data.elementValue, data.maxLength)) {
          data.dataEntryErrorMessage = 'Value cannot be greater than ' + data.maxLength + ' digits.';
          data.questionStatus = this.statusEnum.L1InProgressError;
        } else {
          data.dataEntryErrorMessage = '';
          data.questionStatus = this.statusEnum.L1InProgress;
        }
      } else {
        data.dataEntryErrorMessage = this._validation.getErrorMessage(data.validationType);
        data.questionStatus = this.statusEnum.L1InProgressError;
      }
    } else {
      data.dataEntryErrorMessage = '';
      data.questionStatus = this.statusEnum.NotStarted;
    }

    return data;
  }
}
