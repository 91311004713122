import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ILink } from '../../shared/Model/link';
import { ISection } from '../../shared/section';
import { SectionLink } from '../../shared/Model/sectionLink';
import { QuestionStatusEnum } from '../../shared/dataElement';
import { routeLookup } from '../../DataSubmissions/title-iii.routing';
import { Subscription } from 'rxjs';
import { CacheService } from '../../shared/cache-service';
import { CommonService } from '../../shared/common.service';

class ServiceCard {
  constructor(public title: string, public links: ILink[], public sortOrder: number) { }
}

@Component({
  styleUrls: ['./landing.component.scss'],
  template: `
  <header> </header>
  <!-- begin main content -->
  <div class="container" role="main" id="main-content">
    <div class="row">
      <div class="col-md-12 acl-container">
        <!-- left navigation -->
        <sideNav></sideNav>
        <!-- .end left navigation -->
        <div class="col-md-9">
          <h2>{{pageName}}</h2>
          <div class="flex-list panel-group thirty">
            <acl-multi-link-card *ngFor="let c of cardData" class="flex-list-item"
              [errorFn]="isSectionInError" [warningFn]="isSectionInWaring" [title]="c.title" [links]="c.links"></acl-multi-link-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  `
})
export class LandingComponent implements OnInit, OnDestroy {

  private _serviceSections: ISection;
  private _serviceType: string;
  private _module: string;
  private _cacheUpdatedSub: Subscription;
  public cardData: SectionLink[];


  constructor(private service: CacheService
    , private commonService: CommonService
    , private router: Router
    , private route: ActivatedRoute) { }

  ngOnInit() {
    const urlParts = this.router.url.split('/');
    this._serviceType = urlParts[urlParts.length - 1].replace(/\?.*$/, '');
    this.loadServiceSections();
    this.route.queryParams.subscribe(
      qs => {
        this._module = qs['module'];
        if (this.commonService.isNullOrEmpty(this._module)) {
          this.routeForError();
        }
        this.loadCardData();
      }, err => this.routeForError());
    this._cacheUpdatedSub = this.service.cacheUpdated$
      .subscribe(next => { if (next) { this.loadServiceSections(); this.loadCardData(); } });
  }

  ngOnDestroy(): void {
    if (this._cacheUpdatedSub) {
      this._cacheUpdatedSub.unsubscribe();
    }
  }

  private loadServiceSections(): void {
    this._serviceSections = this.service.getCacheItem(this._serviceType);
    // Error occurred
    if (!this._serviceType || !this._serviceSections) {
      this.routeForError();
    }
  }
  public isSectionInWaring(link: SectionLink): boolean {
    return link.status === QuestionStatusEnum.L2ValidatedWarning;
  }

  public isSectionInError(link: SectionLink): boolean {
    return link.status === QuestionStatusEnum.L1InProgressError
      || link.status === QuestionStatusEnum.L2InProgressError;
  }

  public get pageName(): string {
    return this._serviceSections ? this._serviceSections.groupTitle : '';
  }

  // This algorithm works for a tree with a height of 3 only
  // Adjust if hierarchy gets deeper
  public loadCardData(): void {
    if (this._serviceSections && this._serviceSections.childSection) {
      const data = [];
      this._serviceSections.childSection.forEach(x => {
        data.push(new ServiceCard(x.groupTitle, this.createSectionLinks(x), x.sortOrder));
      });
      this.cardData = data.sort((left, right) => left.sortOrder > right.sortOrder ? 1 : -1);
    } else {
      // Error occurred
      this.routeForError();
    }
  }

  // Flattens tree into array of links
  private createSectionLinks(section: ISection): SectionLink[] {
    let links: SectionLink[] = [];
    if (section.childSection) {
      section.childSection.forEach(x => links = links.concat(this.createSectionLinks(x)));
    } else {
      links.push(new SectionLink(section.sectionName,
        section.sectionStatus,
        section.sectionTitle,
        `${this._module}/${routeLookup.lookupPath(section.sectionName)}`,  // TODO add logic for other titles if required
        section.sortOrder
      ));
    }
    return links;
  }

  private routeForError(): void {
    this.router.navigate(['/dashboard']);
  }


}
