////////////////////////////////////
// Ng libraries
////////////////////////////////////
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

////////////////////////////////////
// Ng supporting cast
////////////////////////////////////
import { MomentModule } from 'ngx-moment';
import { TruncateModule } from 'ng2-truncate';

////////////////////////////////////
// App components, modules, pipes
////////////////////////////////////
// Components
import { LoadingComponent } from './loading.component';
import { PageViewModule } from './PageView/page-view.module';
import { FiscalYearDropdownComponent } from '../StateSubmissions/fiscalYearDropdown.component';
import { TitleVIISubmissionLogComponent } from '../StateSubmissions/titleVIISubmissionLog.component';
import { TVIVarianceSideNavComponent } from './Navigation/tviVarianceSideNav.component';


// Pipes
import { SplitPipe } from './Pipe/split.pipe';
import { DataFilterPipe } from './Pipe/dataFilter.pipe';
import { JoinPipe } from './Pipe/join.pipe';
import { PhonePipe } from './Pipe/phone.pipe';
import { CapitalizeFirstPipe } from './Pipe/capitalizeFirst.pipe';
import { StateSelectComponent } from './StateSelect/state-select.component';
import { YearSelectComponent } from './YearSelect/year-select.component';
import { RegionSelectComponent } from './RegionSelect/region-select.component';
import { PsaSelectComponent } from './StateSelect/psa-select.component';
import { StateSingleSelectComponent } from './StateSelect/state-singleSelect.component';
import { GranteeSingleSelectComponent } from './granteeSelect/grantee-singleSelect.component';

// Lib Ext
import { CardComponent } from './Card/card/card.component';
import { ButtonCardComponent } from './Card/button-card/button-card.component';
import { LinkCardComponent } from './Card/link-card/link-card.component';
import { MultiLinkCardComponent } from './Card/multi-link-card/multi-link-card.component';
import { InputErrorComponent } from './InputError/inputError.component';
import { NewsComponent } from './NewsPanel/news.component';
import { TabContainerComponent } from './TabContainer/tabContainer.component';
import { OverviewButtonSetComponent } from './OverviewButtonSet/overviewButtonSet.component';
import { OaapsGranteeMultiselectComponent } from '../UserManagement/granteeMultiselect/oaapsGranteeMultiselect.component';
import { InputTextComponent } from './InputText/inputText.component';
import { NewlinePipe } from './Pipe/newline.pipe';
import { AclReviewHeaderComponent } from './AclReviewHeader/aclReviewHeader.component';
import { ReturnCommentsComponent } from './ReturnComments/returnComments.component';
import { DataTableModule } from '../DataTable/DataTableModule';
import { ModalComponent } from './Modal/modal.component';
import { InputTextAreaComponent } from './input-text-area/input-text-area.component';

@NgModule({
    imports: [
        CommonModule,
        MomentModule,
        FormsModule,
        RouterModule,
        TruncateModule,
        DataTableModule
    ],
    declarations: [
        LoadingComponent,
        FiscalYearDropdownComponent,
        StateSelectComponent,
        PsaSelectComponent,
        RegionSelectComponent,
        StateSingleSelectComponent,
        GranteeSingleSelectComponent,
        YearSelectComponent,
        SplitPipe,
        DataFilterPipe,
        JoinPipe,
        PhonePipe,
        CapitalizeFirstPipe,
        TitleVIISubmissionLogComponent,
        TVIVarianceSideNavComponent,
        ButtonCardComponent,
        LinkCardComponent,
        CardComponent,
        MultiLinkCardComponent,
        InputErrorComponent,
        NewsComponent,
        TabContainerComponent,
        OverviewButtonSetComponent,
        OaapsGranteeMultiselectComponent,
        InputTextComponent,
        NewlinePipe,
        AclReviewHeaderComponent,
        ReturnCommentsComponent,
        ModalComponent,
        InputTextAreaComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingComponent,
        MomentModule,
        DataTableModule,
        PageViewModule,
        FiscalYearDropdownComponent,
        StateSelectComponent,
        PsaSelectComponent,
        RegionSelectComponent,
        StateSingleSelectComponent,
        GranteeSingleSelectComponent,
        YearSelectComponent,
        SplitPipe,
        DataFilterPipe,
        PhonePipe,
        CapitalizeFirstPipe,
        TVIVarianceSideNavComponent,
        CardComponent,
        LinkCardComponent,
        ButtonCardComponent,
        MultiLinkCardComponent,
        InputErrorComponent,
        NewsComponent,
        TabContainerComponent,
        OverviewButtonSetComponent,
        OaapsGranteeMultiselectComponent,
        InputTextComponent,
        NewlinePipe,
        AclReviewHeaderComponent,
        ReturnCommentsComponent,
        ModalComponent,
        InputTextAreaComponent,
    ]
})

export class SharedModule { }

