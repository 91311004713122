import { QuestionStatusEnum } from './dataElement';
import { ITVIIOverview } from './section';

export class TVIIStatuses {
    public tVIIOverallStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public caseAndComplaintSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public staffAndVolunteerSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public fundsExpendedSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public programActivitiesSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public orgStructureSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public conflictOfInterestSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public complaintExamplesParentSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public nursingfacilitySectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public rccSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public optionalExampleSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public systemIssuesParentSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public systemsIssue1SectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public systemsIssue2SectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public optionalSystemIssueSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public facilityParentSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public numbersAndCapacitySectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public rccInformationSectionStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;

    public setTitleVIIStatuses(data: ITVIIOverview): void {
        this.caseAndComplaintSectionStatus = 0;
        this.staffAndVolunteerSectionStatus = 0;
        this.fundsExpendedSectionStatus = 0;
        this.programActivitiesSectionStatus = 0;
        this.orgStructureSectionStatus = 0;
        this.complaintExamplesParentSectionStatus = 0;
        this.nursingfacilitySectionStatus = 0;
        this.rccSectionStatus = 0;
        this.optionalExampleSectionStatus = 0;
        this.systemIssuesParentSectionStatus = 0;
        this.systemsIssue1SectionStatus = 0;
        this.systemsIssue2SectionStatus = 0;
        this.optionalSystemIssueSectionStatus = 0;
        this.facilityParentSectionStatus = 0;
        this.numbersAndCapacitySectionStatus = 0;
        this.rccInformationSectionStatus = 0;
        if (data) {
            for (const section of data.sections) {
                switch (section.type) {
                    case 'CaseAndComplaintAggregate': {
                        this.caseAndComplaintSectionStatus = section.sectionStatus;
                        break;
                    }
                    case 'OrgStructureSection': {
                        this.orgStructureSectionStatus = section.sectionStatus;
                        break;
                    }
                    case 'StaffAndVolunteerSection': {
                        this.staffAndVolunteerSectionStatus = section.sectionStatus;
                        break;
                    }
                    case 'FundsExpendedSection': {
                        this.fundsExpendedSectionStatus = section.sectionStatus;
                        break;
                    }
                    case 'ProgramActivitiesSection': {
                        this.programActivitiesSectionStatus = section.sectionStatus;
                        break;
                    }
                    case 'ConflictOfInterestSection': {
                        this.conflictOfInterestSectionStatus = section.sectionStatus;
                        break;
                    }
                    case 'ComplaintExampleSection': {
                        this.complaintExamplesParentSectionStatus = section.sectionStatus;
                        section.childSection.forEach(child => {
                            if (child.type === 'titleVIIComplaintNursing') {
                                this.nursingfacilitySectionStatus = child.sectionStatus;
                            }
                            if (child.type === 'titleVIIComplaintRcc') {
                                this.rccSectionStatus = child.sectionStatus;
                            }
                            if (child.type === 'titleVIIComplaintOptional') {
                                this.optionalExampleSectionStatus = child.sectionStatus;
                            }
                        });
                        break;
                    }
                    case 'SystemIssueSection': {
                        this.systemIssuesParentSectionStatus = section.sectionStatus;
                        section.childSection.forEach(child => {
                            if (child.type === 'titleVIISystemIssue1') {
                                this.systemsIssue1SectionStatus = child.sectionStatus;
                            }
                            if (child.type === 'titleVIISystemIssue2') {
                                this.systemsIssue2SectionStatus = child.sectionStatus;
                            }
                            if (child.type === 'titleVIISystemIssue3') {
                                this.optionalSystemIssueSectionStatus = child.sectionStatus;
                            }
                        });
                        break;
                    }
                    case 'FacilitySection': {
                        this.facilityParentSectionStatus = section.sectionStatus;
                        section.childSection.forEach(child => {
                            if (child.type === 'NumberAndCapacitySection') {
                                this.numbersAndCapacitySectionStatus = child.sectionStatus;
                            }
                            if (child.type === 'RccInformationSection') {
                                this.rccInformationSectionStatus = child.sectionStatus;
                            }
                        });
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
            // Set overall status
            this.tVIIOverallStatus = data.overviewStatus;
        }
    }

    public firstAvilableT7VESection(): string {
        if (this.caseAndComplaintSectionStatus > QuestionStatusEnum.L2Validated) {
            return 'titleVIICaseAndComplaintsExplanations';
        } else if (this.orgStructureSectionStatus > QuestionStatusEnum.L2Validated) {
            return 'titleVIIOrganizationalStructureExplanations';
        } else if (this.staffAndVolunteerSectionStatus > QuestionStatusEnum.L2Validated) {
            return 'titleVIIStaffAndVolunteersExplanations';
        } else if (this.fundsExpendedSectionStatus > QuestionStatusEnum.L2Validated) {
            return 'titleVIIFundsExpendedExplanations';
        } else if (this.facilityParentSectionStatus > QuestionStatusEnum.L2Validated) {
            return 'titleVIIFacilityExplanations';
        } else if (this.programActivitiesSectionStatus > QuestionStatusEnum.L2Validated) {
            return 'titleVIIProgramActivitiesExplanations';
        } else {
            return 'titleVIIOverview';
        }
    }
}
