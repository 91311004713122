export interface ILink {
    title: string;
    path: string;
}

export class Link implements ILink {
    constructor(public title: string,
        public path: string
    ) { }
}
