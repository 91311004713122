import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';

export class CGSuppExpendSection extends Section {

    totalServiceUnits: IDataElement;
    expend: IDataElement;
    expendOtherState: IDataElement;
    expendOtherNonState: IDataElement;
    programIncome: IDataElement;
    expendTotal: IDataElement;
    totalExpendPerUnit: IDataElement;

    constructor() {
        super();

        this.totalServiceUnits = new DataElement();
        this.expend = new DataElement();
        this.expendOtherState = new DataElement();
        this.expendOtherNonState = new DataElement();
        this.programIncome = new DataElement();
        this.expendTotal = new DataElement();
        this.totalExpendPerUnit = new DataElement();

    }
}

export class TitleIIIOtherExpend extends CGSuppExpendSection {

    expendC1 = new DataElement();
    expendC2 = new DataElement();
    partB = new DataElement();

    constructor() {
        super();

        this.expendC1 = new DataElement();
        this.expendC2 = new DataElement();
        this.partB = new DataElement();

    }
}