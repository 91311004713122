import { OnInit, Component, Input } from '@angular/core';
import { IStatusHistoryDto } from '../Dto/statusHistoryDto';

@Component({
    templateUrl: 'returnComments.component.html',
    selector: 'acl-return-comments'
})
export class ReturnCommentsComponent implements OnInit {

    @Input() model: IStatusHistoryDto;

    constructor() { }

    ngOnInit(): void {

    }
}
