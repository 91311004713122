import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare let toastr: any;

@Injectable()
export class ToastrService {

  private standardOptions = { positionClass: 'toast-top-full-width', timeOut: '8000', extendedTimeOut: '5000' };
  private e2eOptions = { timeOut: '500', extendedTimeOut: '500' };

  constructor() {

    toastr.options = environment.disableAdalNonceCheck ?
      this.e2eOptions :
      this.standardOptions;
  }

  success(message: string, title?: string) {
    toastr.success('<span role="alert">' + message + '</span>', title);
  }
  info(message: string, title?: string) {
    toastr.info('<span role="alert">' + message + '</span>', title);
  }
  warning(message: string, title?: string) {
    toastr.warning('<span role="alert">' + message + '</span>', title);
  }
  error(message: string, title?: string) {
    toastr.error('<span role="alert">' + message + '</span>', title);
  }

  successWithOptions(message: string, options: any, title?: string) {
    toastr.success(message, title, options);
  }
  infoWithOptions(message: string, options: any, title?: string) {
    toastr.info(message, title, options);
  }
  warningWithOptions(message: string, options: any, title?: string) {
    toastr.warning(message, title, options);
  }
  errorWithOptions(message: string, options: any, title?: string) {
    toastr.error(message, title, options);
  }
}
