import { Component, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { MultipleUnitsSection } from './MultipleUnitsSection';
import { HomeDeliveredMealsUnitsSection } from './HomeDeliveredMealsUnitsSection';
import { AssistedTransportationUnitsSection } from './AssistedTransportationUnitsSection';
import { CongregateMealsUnitsSection } from './CongregateMealsUnitsSection';
import { NutritionCounselingUnitsSection } from './NutritionCounselingUnitsSection';
import { InfoAndAssistanceSection } from './InfoAndAssistanceSection';
import { HealthPromoEvidenceSection } from './HealthPromoEvidenceSection';
import { HealthPromoNonEvidenceSection } from './HealthPromoNonEvidenceSection';
import { TitleIIIOtherExpend } from './CGSuppExpendSection';
import { AssistedTransportationCharsSection } from './AssistedTransportationCharsSection';
import { HomeDeliveredMealsCharsSection } from './HomeDeliveredMealsCharsSection';
import { TwoColCharsSection } from './TwoColCharsSection';
import { FourColNoNutritionCharsSection } from './FourColNoNutritionCharsSection';
import { CommonService } from '../shared/common.service';
import { ValidationService } from '../shared/validation.service';
import * as _ from 'lodash-es';
import { ComponentCanDeactivate } from '../shared/pending-changes.guard';
import { ToastrService } from '../shared/toastr.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { OAExpenditureModelDto } from './OAExpenditureModelDto';
import { Section } from '../shared/section';
import { DestructibleComponent } from '../destructible.component';
import { map } from 'rxjs/operators';
import { NutritionEduInfoSection } from './NutritionEduInfoSection';

@Component({
  templateUrl: 'titleIIIOAExpenditures.component.html',
})

export class TitleIIIOAExpendituresComponent extends DestructibleComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  // chars pages
  _personalCareCharsModel: FourColNoNutritionCharsSection;
  _homemakerCharsModel: FourColNoNutritionCharsSection;
  _choreCharsModel: FourColNoNutritionCharsSection;
  _homeDeliveredMealsCharsModel: HomeDeliveredMealsCharsSection;
  _adultDayCareCharsModel: FourColNoNutritionCharsSection;
  _assistedTranspCharsModel: AssistedTransportationCharsSection;
  _caseMgmtCharsModel: FourColNoNutritionCharsSection;
  _congregateMealsCharsModel: TwoColCharsSection;
  _nutritionCounselingCharsModel: TwoColCharsSection;
  _legalCharsModel: AssistedTransportationCharsSection;
  // units
  _personalCareSectionModel: MultipleUnitsSection;
  _homemakerSectionModel: MultipleUnitsSection;
  _choreSectionModel: MultipleUnitsSection;
  _homeDeliveredMealsSectionModel: HomeDeliveredMealsUnitsSection;
  _adultDayCareSectionModel: MultipleUnitsSection;
  _caseMgmtSectionModel: MultipleUnitsSection;
  _assistedTranspSectionModel: AssistedTransportationUnitsSection;
  _congregateMealsSectionModel: CongregateMealsUnitsSection;
  _nutritionCounselingSectionModel: NutritionCounselingUnitsSection;
  _legalSectionModel: AssistedTransportationUnitsSection;
  _transportationSectionModel: InfoAndAssistanceSection;
  _nutritionEduSectionModel: NutritionEduInfoSection;
  _infoAndAssistanceSectionModel: InfoAndAssistanceSection;
  _healthPromoEvidenceSectionModel: HealthPromoEvidenceSection;
  _healthPromoNonEvidenceSectionModel: HealthPromoNonEvidenceSection;
  _otherServicesSectionModel: TitleIIIOtherExpend;
  _oaExpenditureModelDto: OAExpenditureModelDto;
  personalCareArr: Array<DataElement>;
  homemakerArr: Array<DataElement>;
  choreArr: Array<DataElement>;
  homeDeliveredArr: Array<DataElement>;
  adultDayCareArr: Array<DataElement>;
  caseMgmtArr: Array<DataElement>;
  asssitedTranspArr: Array<DataElement>;
  congMealsArr: Array<DataElement>;
  nutritionCounsArr: Array<DataElement>;
  legalArr: Array<DataElement>;
  transpArr: Array<DataElement>;
  nutritionEduArr: Array<DataElement>;
  infoArr: Array<DataElement>;
  healthPromoArr: Array<DataElement>;
  healthPromoNonArr: Array<DataElement>;
  otherServicesArr: Array<DataElement>;
  questionStatusEnum = QuestionStatusEnum;
  unsavedChanges: boolean = false;
  t3ExpendTotal: any = 0.00;
  t3aExpendTotal: any = 0.00;
  otherStateTotal: any = 0.00;
  nonOtherStateTotal: any = 0.00;
  programIncomeTotal: any = 0.00;
  serviceExpendTotal: any = 0.00;
  validForm: boolean = true;

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public _commonService: CommonService,
    public _dataService: TitleIIIDataEntryService,
    public toastr: ToastrService
  ) { super(); }

  ngOnInit(): void {
    this._titleService.setTitle('Expenditures - Title III Service Expenditures');

    // initialize models
    this._personalCareSectionModel = new MultipleUnitsSection();
    this._homemakerSectionModel = new MultipleUnitsSection();
    this._choreSectionModel = new MultipleUnitsSection();
    this._homeDeliveredMealsSectionModel = new HomeDeliveredMealsUnitsSection();
    this._adultDayCareSectionModel = new MultipleUnitsSection();
    this._caseMgmtSectionModel = new MultipleUnitsSection();
    this._assistedTranspSectionModel = new AssistedTransportationUnitsSection();
    this._congregateMealsSectionModel = new CongregateMealsUnitsSection();
    this._nutritionCounselingSectionModel = new NutritionCounselingUnitsSection();
    this._legalSectionModel = new AssistedTransportationUnitsSection();
    this._transportationSectionModel = new InfoAndAssistanceSection();
    this._nutritionEduSectionModel = new NutritionEduInfoSection();
    this._infoAndAssistanceSectionModel = new InfoAndAssistanceSection();
    this._healthPromoEvidenceSectionModel = new HealthPromoEvidenceSection();
    this._healthPromoNonEvidenceSectionModel = new HealthPromoNonEvidenceSection();
    this._otherServicesSectionModel = new TitleIIIOtherExpend();

    this._personalCareCharsModel = new FourColNoNutritionCharsSection();
    this._homemakerCharsModel = new FourColNoNutritionCharsSection();
    this._choreCharsModel = new FourColNoNutritionCharsSection();
    this._homeDeliveredMealsCharsModel = new HomeDeliveredMealsCharsSection();
    this._adultDayCareCharsModel = new FourColNoNutritionCharsSection();
    this._assistedTranspCharsModel = new AssistedTransportationCharsSection();
    this._caseMgmtCharsModel = new FourColNoNutritionCharsSection();
    this._congregateMealsCharsModel = new TwoColCharsSection();
    this._nutritionCounselingCharsModel = new TwoColCharsSection();
    this._legalCharsModel = new AssistedTransportationCharsSection();

    this.fillModels();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  saveAllSections(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    const save$ = [];
    // TODO could use refactor for performance, bulk update

    this._oaExpenditureModelDto = new OAExpenditureModelDto();
    // assign state, year, and (where required) sectionName then Save
    this._personalCareCharsModel.state = this._claimService.currentOrg;
    this._personalCareCharsModel.fiscalYear = this._claimService.currentYear;
    this._personalCareCharsModel.sectionName = 'titleIIIPersonalCareChars';
    this._oaExpenditureModelDto.personalCareChars = this._personalCareCharsModel;
    // save$.push(this._service.savefourcolnonutritionchars(this._personalCareCharsModel));

    this._personalCareSectionModel.state = this._claimService.currentOrg;
    this._personalCareSectionModel.fiscalYear = this._claimService.currentYear;
    this._personalCareSectionModel.sectionName = 'titleIIIPersonalCareUnits';
    this._oaExpenditureModelDto.personalCareUnits = this._personalCareSectionModel;
    // save$.push(this._service.savemultipleunits(this._personalCareSectionModel));

    this._homemakerCharsModel.state = this._claimService.currentOrg;
    this._homemakerCharsModel.fiscalYear = this._claimService.currentYear;
    this._homemakerCharsModel.sectionName = 'titleIIIHomemakerChars';
    this._oaExpenditureModelDto.homemakerChars = this._homemakerCharsModel;
    // save$.push(this._service.savefourcolnonutritionchars(this._homemakerCharsModel));

    this._homemakerSectionModel.state = this._claimService.currentOrg;
    this._homemakerSectionModel.fiscalYear = this._claimService.currentYear;
    this._homemakerSectionModel.sectionName = 'titleIIIHomemakerUnits';
    this._oaExpenditureModelDto.homemakerUnits = this._homemakerSectionModel;
    // save$.push(this._service.savemultipleunits(this._homemakerSectionModel));

    this._choreCharsModel.state = this._claimService.currentOrg;
    this._choreCharsModel.fiscalYear = this._claimService.currentYear;
    this._choreCharsModel.sectionName = 'titleIIIChoreChars';
    this._oaExpenditureModelDto.choreChars = this._choreCharsModel;
    // save$.push(this._service.savefourcolnonutritionchars(this._choreCharsModel));

    this._choreSectionModel.state = this._claimService.currentOrg;
    this._choreSectionModel.fiscalYear = this._claimService.currentYear;
    this._choreSectionModel.sectionName = 'titleIIIChoreUnits';
    this._oaExpenditureModelDto.choreUnits = this._choreSectionModel;
    // save$.push(this._service.savemultipleunits(this._choreSectionModel));

    this._homeDeliveredMealsCharsModel.state = this._claimService.currentOrg;
    this._homeDeliveredMealsCharsModel.fiscalYear = this._claimService.currentYear;
    this._homeDeliveredMealsCharsModel.sectionName = 'titleIIIHomeDeliveredMealsChars';
    this._oaExpenditureModelDto.homeDeliveredMealsChars = this._homeDeliveredMealsCharsModel;
    // save$.push(this._service.savehomedeliveredmealschars(this._homeDeliveredMealsCharsModel));

    this._homeDeliveredMealsSectionModel.state = this._claimService.currentOrg;
    this._homeDeliveredMealsSectionModel.fiscalYear = this._claimService.currentYear;
    this._homeDeliveredMealsSectionModel.sectionName = 'titleIIIHomeDeliveredMealsUnits';
    this._oaExpenditureModelDto.homeDeliveredMealsUnits = this._homeDeliveredMealsSectionModel;
    // save$.push(this._service.savehomedeliveredmealsunits(this._homeDeliveredMealsSectionModel));

    this._adultDayCareCharsModel.state = this._claimService.currentOrg;
    this._adultDayCareCharsModel.fiscalYear = this._claimService.currentYear;
    this._adultDayCareCharsModel.sectionName = 'titleIIIAdultDayCareChars';
    this._oaExpenditureModelDto.adultDayCareChars = this._adultDayCareCharsModel;
    // save$.push(this._service.savefourcolnonutritionchars(this._adultDayCareCharsModel));

    this._adultDayCareSectionModel.state = this._claimService.currentOrg;
    this._adultDayCareSectionModel.fiscalYear = this._claimService.currentYear;
    this._adultDayCareSectionModel.sectionName = 'titleIIIAdultDayCareUnits';
    this._oaExpenditureModelDto.adultDayCareUnits = this._adultDayCareSectionModel;
    // save$.push(this._service.savemultipleunits(this._adultDayCareSectionModel));

    this._caseMgmtCharsModel.state = this._claimService.currentOrg;
    this._caseMgmtCharsModel.fiscalYear = this._claimService.currentYear;
    this._caseMgmtCharsModel.sectionName = 'titleIIICaseMgmtChars';
    this._oaExpenditureModelDto.caseMgmtChars = this._caseMgmtCharsModel;
    // save$.push(this._service.savefourcolnonutritionchars(this._caseMgmtCharsModel));

    this._caseMgmtSectionModel.state = this._claimService.currentOrg;
    this._caseMgmtSectionModel.fiscalYear = this._claimService.currentYear;
    this._caseMgmtSectionModel.sectionName = 'titleIIICaseMgmtUnits';
    this._oaExpenditureModelDto.caseMgmtUnits = this._caseMgmtSectionModel;
    // save$.push(this._service.savemultipleunits(this._caseMgmtSectionModel));

    this._assistedTranspCharsModel.state = this._claimService.currentOrg;
    this._assistedTranspCharsModel.fiscalYear = this._claimService.currentYear;
    this._assistedTranspCharsModel.sectionName = 'titleIIIAssistedTransportationChars';
    this._oaExpenditureModelDto.assistedTransportationChars = this._assistedTranspCharsModel;
    // save$.push(this._service.saveassistedtransportationchars(this._assistedTranspCharsModel));

    this._assistedTranspSectionModel.state = this._claimService.currentOrg;
    this._assistedTranspSectionModel.fiscalYear = this._claimService.currentYear;
    this._assistedTranspSectionModel.sectionName = 'titleIIIAssistedTransportationUnits';
    this._oaExpenditureModelDto.assistedTransportationUnits = this._assistedTranspSectionModel;
    // save$.push(this._service.saveassistedtransportationunits(this._assistedTranspSectionModel));

    this._congregateMealsCharsModel.state = this._claimService.currentOrg;
    this._congregateMealsCharsModel.fiscalYear = this._claimService.currentYear;
    this._congregateMealsCharsModel.sectionName = 'titleIIICongregateMealsChars';
    this._oaExpenditureModelDto.congregateMealsChars = this._congregateMealsCharsModel;
    // save$.push(this._service.savetwocolchars(this._congregateMealsCharsModel));

    this._congregateMealsSectionModel.state = this._claimService.currentOrg;
    this._congregateMealsSectionModel.fiscalYear = this._claimService.currentYear;
    this._congregateMealsSectionModel.sectionName = 'titleIIICongregateMealsUnits';
    this._oaExpenditureModelDto.congregateMealsUnits = this._congregateMealsSectionModel;
    // save$.push(this._service.savecongregatemealsunits(this._congregateMealsSectionModel));

    this._nutritionCounselingCharsModel.state = this._claimService.currentOrg;
    this._nutritionCounselingCharsModel.fiscalYear = this._claimService.currentYear;
    this._nutritionCounselingCharsModel.sectionName = 'titleIIINutritionCounselingChars';
    this._oaExpenditureModelDto.nutritionCounselingChars = this._nutritionCounselingCharsModel;
    // save$.push(this._service.savetwocolchars(this._nutritionCounselingCharsModel));

    this._nutritionCounselingSectionModel.state = this._claimService.currentOrg;
    this._nutritionCounselingSectionModel.fiscalYear = this._claimService.currentYear;
    this._nutritionCounselingSectionModel.sectionName = 'titleIIINutritionCounselingUnits';
    this._oaExpenditureModelDto.nutritionCounselingUnits = this._nutritionCounselingSectionModel;
    // save$.push(this._service.savenutritioncounselingunits(this._nutritionCounselingSectionModel));

    this._legalCharsModel.state = this._claimService.currentOrg;
    this._legalCharsModel.fiscalYear = this._claimService.currentYear;
    this._legalCharsModel.sectionName = 'titleIIILegalChars';
    this._oaExpenditureModelDto.legalChars = this._legalCharsModel;
    // save$.push(this._service.saveassistedtransportationchars(this._legalCharsModel));

    this._legalSectionModel.state = this._claimService.currentOrg;
    this._legalSectionModel.fiscalYear = this._claimService.currentYear;
    this._legalSectionModel.sectionName = 'titleIIILegalUnits';
    this._oaExpenditureModelDto.legalUnits = this._legalSectionModel;
    // save$.push(this._service.saveassistedtransportationunits(this._legalSectionModel));

    this._transportationSectionModel.state = this._claimService.currentOrg;
    this._transportationSectionModel.fiscalYear = this._claimService.currentYear;
    this._transportationSectionModel.sectionName = 'titleIIITransportation';
    this._oaExpenditureModelDto.transportation = this._transportationSectionModel;
    // save$.push(this._service.saveinfoandassistance(this._transportationSectionModel));

    this._nutritionEduSectionModel.state = this._claimService.currentOrg;
    this._nutritionEduSectionModel.fiscalYear = this._claimService.currentYear;
    this._nutritionEduSectionModel.sectionName = 'titleIIINutritionEducation';
    this._oaExpenditureModelDto.nutritionEdu = this._nutritionEduSectionModel;
    // save$.push(this._service.saveinfoandassistance(this._nutritionEduSectionModel));

    this._infoAndAssistanceSectionModel.state = this._claimService.currentOrg;
    this._infoAndAssistanceSectionModel.fiscalYear = this._claimService.currentYear;
    this._infoAndAssistanceSectionModel.sectionName = 'titleIIIInfoAndAssistance';
    this._oaExpenditureModelDto.infoAndAssist = this._infoAndAssistanceSectionModel;
    // save$.push(this._service.saveinfoandassistance(this._infoAndAssistanceSectionModel));

    this._healthPromoEvidenceSectionModel.state = this._claimService.currentOrg;
    this._healthPromoEvidenceSectionModel.fiscalYear = this._claimService.currentYear;
    this._healthPromoEvidenceSectionModel.sectionName = 'healthPromoEvidence';
    this._oaExpenditureModelDto.healthPromo = this._healthPromoEvidenceSectionModel;
    // save$.push(this._service.savehealthpromoevidence(this._healthPromoEvidenceSectionModel));

    this._healthPromoNonEvidenceSectionModel.state = this._claimService.currentOrg;
    this._healthPromoNonEvidenceSectionModel.fiscalYear = this._claimService.currentYear;
    this._healthPromoNonEvidenceSectionModel.sectionName = 'healthPromoNonEvidence';
    this._oaExpenditureModelDto.healthNonPromo = this._healthPromoNonEvidenceSectionModel;
    // save$.push(this._service.savehealthpromononevidence(this._healthPromoNonEvidenceSectionModel));

    this._otherServicesSectionModel.state = this._claimService.currentOrg;
    this._otherServicesSectionModel.fiscalYear = this._claimService.currentYear;
    this._otherServicesSectionModel.sectionName = 'titleIIIOtherExpend';
    this._oaExpenditureModelDto.otherServices = this._otherServicesSectionModel;
    // save$.push(this._service.savecgsuppexpend(this._otherServicesSectionModel));

    super.registerSubscription(this._service.saveoaexpendituresdto(this._oaExpenditureModelDto)
      .subscribe(data => this.zone.run(() => {
        this._oaExpenditureModelDto = data;
        this.fillModels()
      }),
      (error)=> console.log(error),
      ()=>{
        callback();

        this.toastr.success('Saved!');
      }
      ));
    /*
    super.registerSubscription(
      forkJoin(save$)
        .finally(() => {
          this.initArrays();

          this.toastr.success('Saved!');
          callback();
        }).subscribe(data => {
          let i = 0;
          this._personalCareCharsModel = <any>data[i++];
          this._personalCareSectionModel = <any>data[i++];
          this._homemakerCharsModel = <any>data[i++];
          this._homemakerSectionModel = <any>data[i++];
          this._choreCharsModel = <any>data[i++];
          this._choreSectionModel = <any>data[i++];
          this._homeDeliveredMealsCharsModel = <any>data[i++];
          this._homeDeliveredMealsSectionModel = <any>data[i++];
          this._adultDayCareCharsModel = <any>data[i++];
          this._adultDayCareSectionModel = <any>data[i++];
          this._caseMgmtCharsModel = <any>data[i++];
          this._caseMgmtSectionModel = <any>data[i++];
          this._assistedTranspCharsModel = <any>data[i++];
          this._assistedTranspSectionModel = <any>data[i++];
          this._congregateMealsCharsModel = <any>data[i++];
          this._congregateMealsSectionModel = <any>data[i++];
          this._nutritionCounselingCharsModel = <any>data[i++];
          this._nutritionCounselingSectionModel = <any>data[i++];
          this._legalCharsModel = <any>data[i++];
          this._legalSectionModel = <any>data[i++];
          this._transportationSectionModel = <any>data[i++];
          this._nutritionEduSectionModel = <any>data[i++];
          this._infoAndAssistanceSectionModel = <any>data[i++];
          this._healthPromoEvidenceSectionModel = <any>data[i++];
          this._healthPromoNonEvidenceSectionModel = <any>data[i++];
          this._otherServicesSectionModel = <any>data[i++];
        })); */
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIICOAExpenditures']);
    if (!this._claimService.isViewOnly && this._otherServicesSectionModel.isRequired
      && !this._claimService.isRollupOverview
      && this._otherServicesSectionModel.sectionStatus <= QuestionStatusEnum.Returned) {
      this.saveAllSections(navAction);
    } else {
      navAction();
    }
  }

  fillModels(): void {

    super.registerSubscription(this._service.get<OAExpenditureModelDto>(
      this._claimService, this._service._getOAExpenditureDto, null)
      .subscribe(data => {

        // units sections
        this._personalCareSectionModel = (data.personalCareUnits == null) ? new MultipleUnitsSection() : data.personalCareUnits;
        this._homemakerSectionModel = (data.homemakerUnits == null) ? new MultipleUnitsSection() : data.homemakerUnits;
        this._choreSectionModel = (data.choreUnits == null) ? new MultipleUnitsSection() : data.choreUnits;
        this._adultDayCareSectionModel = (data.adultDayCareUnits == null) ? new MultipleUnitsSection() : data.adultDayCareUnits;
        this._caseMgmtSectionModel = (data.caseMgmtUnits == null) ? new MultipleUnitsSection() : data.caseMgmtUnits;
        this._homeDeliveredMealsSectionModel = (data.homeDeliveredMealsUnits == null) ?
          new HomeDeliveredMealsUnitsSection() : data.homeDeliveredMealsUnits;
        this._assistedTranspSectionModel = (data.assistedTransportationUnits == null) ?
          new AssistedTransportationUnitsSection() : data.assistedTransportationUnits;
        this._legalSectionModel = (data.legalUnits == null) ? new AssistedTransportationUnitsSection() : data.legalUnits;
        this._infoAndAssistanceSectionModel = (data.infoAndAssist == null) ? new InfoAndAssistanceSection() : data.infoAndAssist;
        this._transportationSectionModel = (data.transportation == null) ? new InfoAndAssistanceSection() : data.transportation;
        this._nutritionEduSectionModel = (data.nutritionEdu == null) ? new NutritionEduInfoSection() : data.nutritionEdu;
        this._congregateMealsSectionModel = (data.congregateMealsUnits == null) ?
          new CongregateMealsUnitsSection() : data.congregateMealsUnits;
        this._nutritionCounselingSectionModel = (data.nutritionCounselingUnits == null) ?
          new NutritionCounselingUnitsSection() : data.nutritionCounselingUnits;
        this._healthPromoEvidenceSectionModel = (data.healthPromo == null) ? new HealthPromoEvidenceSection() : data.healthPromo;
        this._healthPromoNonEvidenceSectionModel = (data.healthNonPromo == null) ?
          new HealthPromoNonEvidenceSection() : data.healthNonPromo;
        this._otherServicesSectionModel = (data.otherServices == null) ? new TitleIIIOtherExpend() : data.otherServices;

        // chars sections
        this._personalCareCharsModel = (data.personalCareChars == null) ? new FourColNoNutritionCharsSection() : data.personalCareChars;
        this._homemakerCharsModel = (data.homemakerChars == null) ? new FourColNoNutritionCharsSection() : data.homemakerChars;
        this._choreCharsModel = (data.choreChars == null) ? new FourColNoNutritionCharsSection() : data.choreChars;
        this._adultDayCareCharsModel = (data.adultDayCareChars == null) ? new FourColNoNutritionCharsSection() : data.adultDayCareChars;
        this._caseMgmtCharsModel = (data.caseMgmtChars == null) ? new FourColNoNutritionCharsSection() : data.caseMgmtChars;
        this._homeDeliveredMealsCharsModel = (data.homeDeliveredMealsChars == null) ?
          new HomeDeliveredMealsCharsSection() : data.homeDeliveredMealsChars;
        this._assistedTranspCharsModel = (data.assistedTransportationChars == null) ?
          new AssistedTransportationCharsSection() : data.assistedTransportationChars;
        this._legalCharsModel = (data.legalChars == null) ? new AssistedTransportationCharsSection() : data.legalChars;
        this._congregateMealsCharsModel = (data.congregateMealsChars == null) ? new TwoColCharsSection() : data.congregateMealsChars;
        this._nutritionCounselingCharsModel = (data.nutritionCounselingChars == null) ?
          new TwoColCharsSection() : data.nutritionCounselingChars;

        // Decimal and comma format for all expediture values
        this.initArrays();


        // Cluster 1
        this._personalCareCharsModel.totalPersonsServed.elementValue = this.addComma(
          this._personalCareCharsModel.totalPersonsServed.elementValue);
        this._homemakerCharsModel.totalPersonsServed.elementValue = this.addComma(
          this._homemakerCharsModel.totalPersonsServed.elementValue);
        this._choreCharsModel.totalPersonsServed.elementValue = this.addComma(
          this._choreCharsModel.totalPersonsServed.elementValue);
        this._homeDeliveredMealsCharsModel.totalPersonsServed.elementValue = this.addComma(
          this._homeDeliveredMealsCharsModel.totalPersonsServed.elementValue);
        this._adultDayCareCharsModel.totalPersonsServed.elementValue = this.addComma(
          this._adultDayCareCharsModel.totalPersonsServed.elementValue);
        this._caseMgmtCharsModel.totalPersonsServed.elementValue = this.addComma(
          this._caseMgmtCharsModel.totalPersonsServed.elementValue);

        this._personalCareSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._personalCareSectionModel.totalServiceUnits.elementValue);
        this._personalCareSectionModel.expendC.elementValue = this.addDecimalAndComma(
          this._personalCareSectionModel.expendC.elementValue);
        this._personalCareSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._personalCareSectionModel.expendOtherState.elementValue);
        this._personalCareSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._personalCareSectionModel.expendOtherNonState.elementValue);
        this._personalCareSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._personalCareSectionModel.programIncome.elementValue);
        this._personalCareSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._personalCareSectionModel.expendTotal.elementValue);
        this._personalCareSectionModel.totalExpendPerUnit.elementValue = this.addDecimalAndComma(
          this._personalCareSectionModel.totalExpendPerUnit.elementValue);

        this._homemakerSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._homemakerSectionModel.totalServiceUnits.elementValue);
        this._homemakerSectionModel.expendC.elementValue = this.addDecimalAndComma(
          this._homemakerSectionModel.expendC.elementValue);
        this._homemakerSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._homemakerSectionModel.expendOtherState.elementValue);
        this._homemakerSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._homemakerSectionModel.expendOtherNonState.elementValue);
        this._homemakerSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._homemakerSectionModel.programIncome.elementValue);
        this._homemakerSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._homemakerSectionModel.expendTotal.elementValue);
        this._homemakerSectionModel.totalExpendPerUnit.elementValue = this.addDecimalAndComma(
          this._homemakerSectionModel.totalExpendPerUnit.elementValue);

        this._choreSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._choreSectionModel.totalServiceUnits.elementValue);
        this._choreSectionModel.expendC.elementValue = this.addDecimalAndComma(
          this._choreSectionModel.expendC.elementValue);
        this._choreSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._choreSectionModel.expendOtherState.elementValue);
        this._choreSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._choreSectionModel.expendOtherNonState.elementValue);
        this._choreSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._choreSectionModel.programIncome.elementValue);
        this._choreSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._choreSectionModel.expendTotal.elementValue);
        this._choreSectionModel.totalExpendPerUnit.elementValue = this.addDecimalAndComma(
          this._choreSectionModel.totalExpendPerUnit.elementValue);

        this._homeDeliveredMealsSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._homeDeliveredMealsSectionModel.totalServiceUnits.elementValue);
        this._homeDeliveredMealsSectionModel.expendA.elementValue = this.addDecimalAndComma(
          this._homeDeliveredMealsSectionModel.expendA.elementValue);
        this._homeDeliveredMealsSectionModel.expendC.elementValue = this.addDecimalAndComma(
          this._homeDeliveredMealsSectionModel.expendC.elementValue);
        this._homeDeliveredMealsSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._homeDeliveredMealsSectionModel.expendOtherState.elementValue);
        this._homeDeliveredMealsSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._homeDeliveredMealsSectionModel.expendOtherNonState.elementValue);
        this._homeDeliveredMealsSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._homeDeliveredMealsSectionModel.programIncome.elementValue);
        this._homeDeliveredMealsSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._homeDeliveredMealsSectionModel.expendTotal.elementValue);
        this._homeDeliveredMealsSectionModel.totalExpendPerUnit.elementValue = this.addDecimalAndComma(
          this._homeDeliveredMealsSectionModel.totalExpendPerUnit.elementValue);

        this._adultDayCareSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._adultDayCareSectionModel.totalServiceUnits.elementValue);
        this._adultDayCareSectionModel.expendC.elementValue = this.addDecimalAndComma(
          this._adultDayCareSectionModel.expendC.elementValue);
        this._adultDayCareSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._adultDayCareSectionModel.expendOtherState.elementValue);
        this._adultDayCareSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._adultDayCareSectionModel.expendOtherNonState.elementValue);
        this._adultDayCareSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._adultDayCareSectionModel.programIncome.elementValue);
        this._adultDayCareSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._adultDayCareSectionModel.expendTotal.elementValue);
        this._adultDayCareSectionModel.totalExpendPerUnit.elementValue = this.addDecimalAndComma(
          this._adultDayCareSectionModel.totalExpendPerUnit.elementValue);

        this._caseMgmtSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._caseMgmtSectionModel.totalServiceUnits.elementValue);
        this._caseMgmtSectionModel.expendC.elementValue = this.addDecimalAndComma(
          this._caseMgmtSectionModel.expendC.elementValue);
        this._caseMgmtSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._caseMgmtSectionModel.expendOtherState.elementValue);
        this._caseMgmtSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._caseMgmtSectionModel.expendOtherNonState.elementValue);
        this._caseMgmtSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._caseMgmtSectionModel.programIncome.elementValue);
        this._caseMgmtSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._caseMgmtSectionModel.expendTotal.elementValue);
        this._caseMgmtSectionModel.totalExpendPerUnit.elementValue = this.addDecimalAndComma(
          this._caseMgmtSectionModel.totalExpendPerUnit.elementValue);

        // Cluster 2
        this._assistedTranspCharsModel.totalPersonsServed.elementValue = this.addComma(
          this._assistedTranspCharsModel.totalPersonsServed.elementValue);
        this._assistedTranspSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._assistedTranspSectionModel.totalServiceUnits.elementValue);

        this._congregateMealsSectionModel.expendA.elementValue = this.addDecimalAndComma(
          this._congregateMealsSectionModel.expendA.elementValue);
        this._congregateMealsSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._congregateMealsSectionModel.totalServiceUnits.elementValue);

        this._congregateMealsCharsModel.totalPersonsServed.elementValue = this.addComma(
          this._congregateMealsCharsModel.totalPersonsServed.elementValue);

        this._nutritionCounselingCharsModel.totalPersonsServed.elementValue = this.addComma(
          this._nutritionCounselingCharsModel.totalPersonsServed.elementValue);
        this._nutritionCounselingSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._nutritionCounselingSectionModel.totalServiceUnits.elementValue);

        this._legalCharsModel.totalPersonsServed.elementValue = this.addComma(
          this._legalCharsModel.totalPersonsServed.elementValue);
        this._legalSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._legalSectionModel.totalServiceUnits.elementValue);

        this._assistedTranspSectionModel.expendC.elementValue = this.addDecimalAndComma(
          this._assistedTranspSectionModel.expendC.elementValue);
        this._congregateMealsSectionModel.expendC.elementValue = this.addDecimalAndComma(
          this._congregateMealsSectionModel.expendC.elementValue);
        this._nutritionCounselingSectionModel.expendC.elementValue = this.addDecimalAndComma(
          this._nutritionCounselingSectionModel.expendC.elementValue);
        this._legalSectionModel.expendC.elementValue = this.addDecimalAndComma(
          this._legalSectionModel.expendC.elementValue);

        this._nutritionCounselingSectionModel.partB.elementValue = this.addDecimalAndComma(
            this._nutritionCounselingSectionModel.partB.elementValue);

        this._assistedTranspSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._assistedTranspSectionModel.expendOtherState.elementValue);
        this._congregateMealsSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._congregateMealsSectionModel.expendOtherState.elementValue);
        this._nutritionCounselingSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._nutritionCounselingSectionModel.expendOtherState.elementValue);
        this._legalSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._legalSectionModel.expendOtherState.elementValue);

        this._assistedTranspSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._assistedTranspSectionModel.expendOtherNonState.elementValue);
        this._congregateMealsSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._congregateMealsSectionModel.expendOtherNonState.elementValue);
        this._nutritionCounselingSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._nutritionCounselingSectionModel.expendOtherNonState.elementValue);
        this._legalSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._legalSectionModel.expendOtherNonState.elementValue);

        this._assistedTranspSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._assistedTranspSectionModel.programIncome.elementValue);
        this._congregateMealsSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._congregateMealsSectionModel.programIncome.elementValue);
        this._nutritionCounselingSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._nutritionCounselingSectionModel.programIncome.elementValue);
        this._legalSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._legalSectionModel.programIncome.elementValue);

        this._assistedTranspSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._assistedTranspSectionModel.expendTotal.elementValue);
        this._congregateMealsSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._congregateMealsSectionModel.expendTotal.elementValue);
        this._nutritionCounselingSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._nutritionCounselingSectionModel.expendTotal.elementValue);
        this._legalSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._legalSectionModel.expendTotal.elementValue);

        // Cluster 3
        this._healthPromoEvidenceSectionModel.peopleServed.elementValue = this.addComma(
          this._healthPromoEvidenceSectionModel.peopleServed.elementValue);
        this._healthPromoNonEvidenceSectionModel.peopleServed.elementValue = this.addComma(
          this._healthPromoNonEvidenceSectionModel.peopleServed.elementValue);
        this._otherServicesSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._otherServicesSectionModel.totalServiceUnits.elementValue);
        this._nutritionEduSectionModel.estimatedAudienceSize.elementValue = this.addComma(
          this._nutritionEduSectionModel.estimatedAudienceSize.elementValue);
        this._transportationSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._transportationSectionModel.totalServiceUnits.elementValue);
        this._nutritionEduSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._nutritionEduSectionModel.totalServiceUnits.elementValue);
        this._infoAndAssistanceSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._infoAndAssistanceSectionModel.totalServiceUnits.elementValue);

        this._nutritionEduSectionModel.expendC1.elementValue = this.addComma(
          this._nutritionEduSectionModel.expendC1.elementValue);
        this._nutritionEduSectionModel.expendC2.elementValue = this.addComma(
          this._nutritionEduSectionModel.expendC2.elementValue);
        this._otherServicesSectionModel.expendC1.elementValue = this.addComma(
          this._otherServicesSectionModel.expendC1.elementValue);
        this._otherServicesSectionModel.expendC2.elementValue = this.addComma(
          this._otherServicesSectionModel.expendC2.elementValue);
            

        this._nutritionEduSectionModel.partB.elementValue = this.addComma(
          this._nutritionEduSectionModel.partB.elementValue);
         this._healthPromoEvidenceSectionModel.partB.elementValue = this.addDecimalAndComma(
          this._healthPromoEvidenceSectionModel.partB.elementValue);
        this._otherServicesSectionModel.partB.elementValue = this.addDecimalAndComma(
          this._otherServicesSectionModel.partB.elementValue);
  
        this._healthPromoEvidenceSectionModel.partD.elementValue = this.addDecimalAndComma(
          this._healthPromoEvidenceSectionModel.partD.elementValue);

        this._transportationSectionModel.expend.elementValue = this.addDecimalAndComma(
          this._transportationSectionModel.expend.elementValue);
        this._nutritionEduSectionModel.expend.elementValue = this.addDecimalAndComma(
          this._nutritionEduSectionModel.expend.elementValue);
        this._infoAndAssistanceSectionModel.expend.elementValue = this.addDecimalAndComma(
          this._infoAndAssistanceSectionModel.expend.elementValue);
        this._healthPromoNonEvidenceSectionModel.title3Expend.elementValue = this.addDecimalAndComma(
          this._healthPromoNonEvidenceSectionModel.title3Expend.elementValue);
        this._otherServicesSectionModel.expend.elementValue = this.addDecimalAndComma(
          this._otherServicesSectionModel.expend.elementValue);

        this._transportationSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._transportationSectionModel.expendOtherNonState.elementValue);
        this._nutritionEduSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._nutritionEduSectionModel.expendOtherNonState.elementValue);
        this._infoAndAssistanceSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._infoAndAssistanceSectionModel.expendOtherNonState.elementValue);
        this._healthPromoEvidenceSectionModel.otherStateExpend.elementValue = this.addDecimalAndComma(
          this._healthPromoEvidenceSectionModel.otherStateExpend.elementValue);
        this._infoAndAssistanceSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._infoAndAssistanceSectionModel.expendOtherNonState.elementValue);
        this._healthPromoNonEvidenceSectionModel.nonStateExpend.elementValue = this.addDecimalAndComma(
          this._healthPromoNonEvidenceSectionModel.nonStateExpend.elementValue);
        this._otherServicesSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._otherServicesSectionModel.expendOtherState.elementValue);

        this._transportationSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._transportationSectionModel.expendOtherState.elementValue);
        this._nutritionEduSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._nutritionEduSectionModel.expendOtherState.elementValue);
        this._infoAndAssistanceSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._infoAndAssistanceSectionModel.expendOtherState.elementValue);
        this._healthPromoEvidenceSectionModel.nonStateExpend.elementValue = this.addDecimalAndComma(
          this._healthPromoEvidenceSectionModel.nonStateExpend.elementValue);
        this._infoAndAssistanceSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._infoAndAssistanceSectionModel.expendOtherState.elementValue);
        this._healthPromoNonEvidenceSectionModel.otherStateExpend.elementValue = this.addDecimalAndComma(
          this._healthPromoNonEvidenceSectionModel.otherStateExpend.elementValue);
        this._otherServicesSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._otherServicesSectionModel.expendOtherNonState.elementValue);

        this._transportationSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._transportationSectionModel.programIncome.elementValue);
        this._nutritionEduSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._nutritionEduSectionModel.programIncome.elementValue);
        this._infoAndAssistanceSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._infoAndAssistanceSectionModel.programIncome.elementValue);
        this._healthPromoEvidenceSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._healthPromoEvidenceSectionModel.programIncome.elementValue);
        this._infoAndAssistanceSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._infoAndAssistanceSectionModel.programIncome.elementValue);
        this._healthPromoNonEvidenceSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._healthPromoNonEvidenceSectionModel.programIncome.elementValue);
        this._otherServicesSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._otherServicesSectionModel.programIncome.elementValue);

        this._transportationSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._transportationSectionModel.expendTotal.elementValue);
        this._nutritionEduSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._nutritionEduSectionModel.expendTotal.elementValue);
        this._infoAndAssistanceSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._infoAndAssistanceSectionModel.expendTotal.elementValue);
        this._healthPromoEvidenceSectionModel.totalExpend.elementValue = this.addDecimalAndComma(
          this._healthPromoEvidenceSectionModel.totalExpend.elementValue);
        this._infoAndAssistanceSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._infoAndAssistanceSectionModel.expendTotal.elementValue);
        this._healthPromoNonEvidenceSectionModel.totalExpend.elementValue = this.addDecimalAndComma(
          this._healthPromoNonEvidenceSectionModel.totalExpend.elementValue);
        this._otherServicesSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._otherServicesSectionModel.expendTotal.elementValue);

        this.calcSums();

      }, err => console.log(err),
        () => {
          this._commonService.disableFormElements(_.minBy([this._personalCareSectionModel,
        this._homemakerSectionModel,
        this._choreSectionModel,
        this._homeDeliveredMealsSectionModel,
        this._adultDayCareSectionModel,
        this._caseMgmtSectionModel,
        this._assistedTranspSectionModel,
        this._congregateMealsSectionModel,
        this._nutritionCounselingSectionModel,
        this._legalSectionModel,
        this._transportationSectionModel,
        this._nutritionEduSectionModel,
        this._infoAndAssistanceSectionModel,
        this._healthPromoEvidenceSectionModel,
        this._healthPromoNonEvidenceSectionModel,
        this._otherServicesSectionModel,
        this._personalCareCharsModel,
        this._homemakerCharsModel,
        this._choreCharsModel,
        this._homeDeliveredMealsCharsModel,
        this._adultDayCareCharsModel,
        this._assistedTranspCharsModel,
        this._caseMgmtCharsModel,
        this._congregateMealsCharsModel,
        this._nutritionCounselingCharsModel,
        this._legalCharsModel], item => item.sectionStatus));

        this.processSectionStatus();
      }));

  }

  // using chore section for the next 2 methods, chose it randomly. If conditions are true for any section, then its true for expenditures
  public processSectionStatus(disableOverride: boolean = false): void {
    if(this.disabledForAAAStateUser()){
      this._commonService.disableFormElements(this._choreSectionModel, true);
    }
  }

  private disabledForAAAStateUser(): boolean {
    const aaaStateUser = this._claimService.isAAAEnabledStateUser();
    const editModeDisabled = !this._dataService._aaaStateEditMode;
    const viewingStateData = !this._choreSectionModel.psa;
      //&& !this._choreSectionModel.isRollup;

      return aaaStateUser && editModeDisabled && !viewingStateData;
  }

  initArrays(): void {
    this.personalCareArr = [this._personalCareSectionModel.expendC,
    this._personalCareSectionModel.expendOtherState,
    this._personalCareSectionModel.expendOtherNonState,
    this._personalCareSectionModel.programIncome];

    this.homemakerArr = [this._homemakerSectionModel.expendC,
    this._homemakerSectionModel.expendOtherState,
    this._homemakerSectionModel.expendOtherNonState,
    this._homemakerSectionModel.programIncome];

    this.choreArr = [this._choreSectionModel.expendC,
    this._choreSectionModel.expendOtherState,
    this._choreSectionModel.expendOtherNonState,
    this._choreSectionModel.programIncome];

    this.homeDeliveredArr = [this._homeDeliveredMealsSectionModel.expendC,
    this._homeDeliveredMealsSectionModel.expendA,
    this._homeDeliveredMealsSectionModel.expendOtherState,
    this._homeDeliveredMealsSectionModel.expendOtherNonState,
    this._homeDeliveredMealsSectionModel.programIncome];

    this.adultDayCareArr = [this._adultDayCareSectionModel.expendC,
    this._adultDayCareSectionModel.expendOtherState,
    this._adultDayCareSectionModel.expendOtherNonState,
    this._adultDayCareSectionModel.programIncome];

    this.caseMgmtArr = [this._caseMgmtSectionModel.expendC,
    this._caseMgmtSectionModel.expendOtherState,
    this._caseMgmtSectionModel.expendOtherNonState,
    this._caseMgmtSectionModel.programIncome];

    this.asssitedTranspArr = [this._assistedTranspSectionModel.expendC,
    this._assistedTranspSectionModel.expendOtherState,
    this._assistedTranspSectionModel.expendOtherNonState,
    this._assistedTranspSectionModel.programIncome];

    this.congMealsArr = [this._congregateMealsSectionModel.expendC,
    this._congregateMealsSectionModel.expendA,
    this._congregateMealsSectionModel.expendOtherState,
    this._congregateMealsSectionModel.expendOtherNonState,
    this._congregateMealsSectionModel.programIncome];

    this.nutritionCounsArr = [this._nutritionCounselingSectionModel.expendC1,
    this._nutritionCounselingSectionModel.expendC2,
    this._nutritionCounselingSectionModel.partB,
    this._nutritionCounselingSectionModel.expendOtherState,
    this._nutritionCounselingSectionModel.expendOtherNonState,
    this._nutritionCounselingSectionModel.programIncome];

    this.legalArr = [this._legalSectionModel.expendC,
    this._legalSectionModel.expendOtherState,
    this._legalSectionModel.expendOtherNonState,
    this._legalSectionModel.programIncome];

    this.transpArr = [this._transportationSectionModel.expend,
    this._transportationSectionModel.expendOtherState,
    this._transportationSectionModel.expendOtherNonState,
    this._transportationSectionModel.programIncome];

    this.nutritionEduArr = [this._nutritionEduSectionModel.expendC1,
    this._nutritionEduSectionModel.expendC2,
    this._nutritionEduSectionModel.partB,
    this._nutritionEduSectionModel.expendOtherState,
    this._nutritionEduSectionModel.expendOtherNonState,
    this._nutritionEduSectionModel.programIncome];

    this.infoArr = [this._infoAndAssistanceSectionModel.expend,
    this._infoAndAssistanceSectionModel.expendOtherState,
    this._infoAndAssistanceSectionModel.expendOtherNonState,
    this._infoAndAssistanceSectionModel.programIncome];

    this.healthPromoArr = [this._healthPromoEvidenceSectionModel.partB,
    this._healthPromoEvidenceSectionModel.partD,
    this._healthPromoEvidenceSectionModel.otherStateExpend,
    this._healthPromoEvidenceSectionModel.nonStateExpend,
    this._healthPromoEvidenceSectionModel.programIncome];

    this.healthPromoNonArr = [this._healthPromoNonEvidenceSectionModel.title3Expend,
    this._healthPromoNonEvidenceSectionModel.otherStateExpend,
    this._healthPromoNonEvidenceSectionModel.nonStateExpend,
    this._healthPromoNonEvidenceSectionModel.programIncome];
  }

  updateTotal(totalsArray: Array<DataElement>): string {
    let total: number = 0;

    for (let i = 0; i < totalsArray.length; i++) {
      // if not null, remove commas
      if (totalsArray[i].elementValue) {
        totalsArray[i].elementValue = totalsArray[i].elementValue.replace(/,/g, '');
      }
      // if is a number, sum it
      if (!isNaN(parseFloat(totalsArray[i].elementValue))) {
        total += parseFloat(totalsArray[i].elementValue);
      }
      // replace commas when done adding
      totalsArray[i].elementValue = this.addDecimals(totalsArray[i].elementValue);
    }
    this.calcSums();
    return this.addDecimals(total.toString());
  }

  updateHealthPromoEvidenceBothParts(): void {
    let grandTotal = 0;
    if (this._healthPromoEvidenceSectionModel.partB.isRequired && this._healthPromoEvidenceSectionModel.partD.isRequired) {
      const partB = parseFloat(this.removeCommas(this._healthPromoEvidenceSectionModel.partB.elementValue));
      const partD = parseFloat(this.removeCommas(this._healthPromoEvidenceSectionModel.partD.elementValue));
      if (!isNaN(partB)) { grandTotal += partB; }
      if (!isNaN(partD)) { grandTotal += partD; }
      this._healthPromoEvidenceSectionModel.bothParts.elementValue = this.addDecimals(grandTotal.toString());
    } else {
      this._healthPromoEvidenceSectionModel.bothParts.elementValue = 'N/A';
    }
  }

  updateNutritionCounselingExpendC(): void {
    let grandTotal = 0;
    if (this._nutritionCounselingSectionModel.expendC1.isRequired && this._nutritionCounselingSectionModel.expendC2.isRequired && this._nutritionCounselingSectionModel.partB.isRequired) {
      const expendC1 = parseFloat(this.removeCommas(this._nutritionCounselingSectionModel.expendC1.elementValue));
      const expendC2 = parseFloat(this.removeCommas(this._nutritionCounselingSectionModel.expendC2.elementValue));
      const partB = parseFloat(this.removeCommas(this._nutritionCounselingSectionModel.partB.elementValue));
      if (!isNaN(expendC1)) { grandTotal += expendC1; }
      if (!isNaN(expendC2)) { grandTotal += expendC2; }
      if (!isNaN(partB)) { grandTotal += partB; }
      this._nutritionCounselingSectionModel.expendC.elementValue = this.addDecimals(grandTotal.toString());
    } else {
      this._nutritionCounselingSectionModel.expendC.elementValue = 'N/A';
    }
  }

  updateNutritionEducationExpend()
  {
    let grandTotal = 0;
    if (this._nutritionEduSectionModel.expendC1.isRequired && this._nutritionEduSectionModel.expendC2.isRequired && this._nutritionEduSectionModel.partB.isRequired) {
      const expendC1 = parseFloat(this.removeCommas(this._nutritionEduSectionModel.expendC1.elementValue));
      const expendC2 = parseFloat(this.removeCommas(this._nutritionEduSectionModel.expendC2.elementValue));
      const partB = parseFloat(this.removeCommas(this._nutritionEduSectionModel.partB.elementValue));
      if (!isNaN(expendC1)) { grandTotal += expendC1; }
      if (!isNaN(expendC2)) { grandTotal += expendC2; }
      if (!isNaN(partB)) { grandTotal += partB; }
      this._nutritionEduSectionModel.expend.elementValue = this.addDecimals(grandTotal.toString());
    } else {
      this._nutritionEduSectionModel.expend.elementValue = 'N/A';
    }
  }

  // updates Total Expenditure row
  updatePerUnit(): void {
    const personalUnits = parseFloat(this.removeCommas(this._personalCareSectionModel.totalServiceUnits.elementValue));
    const homemakerUnits = parseFloat(this.removeCommas(this._homemakerSectionModel.totalServiceUnits.elementValue));
    const choreUnits = parseFloat(this.removeCommas(this._choreSectionModel.totalServiceUnits.elementValue));
    const hdmUnits = parseFloat(this.removeCommas(this._homeDeliveredMealsSectionModel.totalServiceUnits.elementValue));
    const adcUnits = parseFloat(this.removeCommas(this._adultDayCareSectionModel.totalServiceUnits.elementValue));
    const caseUnits = parseFloat(this.removeCommas(this._caseMgmtSectionModel.totalServiceUnits.elementValue));
    const atUnits = parseFloat(this.removeCommas(this._assistedTranspSectionModel.totalServiceUnits.elementValue));
    const congUnits = parseFloat(this.removeCommas(this._congregateMealsSectionModel.totalServiceUnits.elementValue));
    const ncUnits = parseFloat(this.removeCommas(this._nutritionCounselingSectionModel.totalServiceUnits.elementValue));
    const legalUnits = parseFloat(this.removeCommas(this._legalSectionModel.totalServiceUnits.elementValue));
    const transpUnits = parseFloat(this.removeCommas(this._transportationSectionModel.totalServiceUnits.elementValue));
    const neUnits = parseFloat(this.removeCommas(this._nutritionEduSectionModel.totalServiceUnits.elementValue));
    const infoUnits = parseFloat(this.removeCommas(this._infoAndAssistanceSectionModel.totalServiceUnits.elementValue));
    const healthUnits = parseFloat(this.removeCommas(this._healthPromoEvidenceSectionModel.peopleServed.elementValue));
    const healthNonUnits = parseFloat(this.removeCommas(this._healthPromoNonEvidenceSectionModel.peopleServed.elementValue));
    const otherUnits = parseFloat(this.removeCommas(this._otherServicesSectionModel.totalServiceUnits.elementValue));

    const personalExpend = parseFloat(this.removeCommas(this._personalCareSectionModel.expendTotal.elementValue));
    const homemakerExpend = parseFloat(this.removeCommas(this._homemakerSectionModel.expendTotal.elementValue));
    const choreExpend = parseFloat(this.removeCommas(this._choreSectionModel.expendTotal.elementValue));
    const hdmExpend = parseFloat(this.removeCommas(this._homeDeliveredMealsSectionModel.expendTotal.elementValue));
    const adcExpend = parseFloat(this.removeCommas(this._adultDayCareSectionModel.expendTotal.elementValue));
    const caseExpend = parseFloat(this.removeCommas(this._caseMgmtSectionModel.expendTotal.elementValue));
    const atExpend = parseFloat(this.removeCommas(this._assistedTranspSectionModel.expendTotal.elementValue));
    const congExpend = parseFloat(this.removeCommas(this._congregateMealsSectionModel.expendTotal.elementValue));
    const ncExpend = parseFloat(this.removeCommas(this._nutritionCounselingSectionModel.expendTotal.elementValue));
    const legalExpend = parseFloat(this.removeCommas(this._legalSectionModel.expendTotal.elementValue));
    const transpExpend = parseFloat(this.removeCommas(this._transportationSectionModel.expendTotal.elementValue));
    const neExpend = parseFloat(this.removeCommas(this._nutritionEduSectionModel.expendTotal.elementValue));
    const infoExpend = parseFloat(this.removeCommas(this._infoAndAssistanceSectionModel.expendTotal.elementValue));
    const healthExpend = parseFloat(this.removeCommas(this._healthPromoEvidenceSectionModel.totalExpend.elementValue));
    const healthNonExpend = parseFloat(this.removeCommas(this._healthPromoNonEvidenceSectionModel.totalExpend.elementValue));
    const otherExpend = parseFloat(this.removeCommas(this._otherServicesSectionModel.expendTotal.elementValue));

    this._personalCareSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(personalExpend, personalUnits);
    this._homemakerSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(homemakerExpend, homemakerUnits);
    this._choreSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(choreExpend, choreUnits);
    this._homeDeliveredMealsSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(hdmExpend, hdmUnits);
    this._adultDayCareSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(adcExpend, adcUnits);
    this._caseMgmtSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(caseExpend, caseUnits);
    this._assistedTranspSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(atExpend, atUnits);
    this._congregateMealsSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(congExpend, congUnits);
    this._nutritionCounselingSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(ncExpend, ncUnits);
    this._legalSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(legalExpend, legalUnits);
    this._transportationSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(transpExpend, transpUnits);
    this._nutritionEduSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(neExpend, neUnits);
    this._infoAndAssistanceSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(infoExpend, infoUnits);
    this._healthPromoEvidenceSectionModel.expendPerUnit.elementValue = this.expendPerUnit(healthExpend, healthUnits);
    this._healthPromoNonEvidenceSectionModel.expendPerUnit.elementValue = this.expendPerUnit(healthNonExpend, healthNonUnits);

    if (!isNaN(otherUnits) && !isNaN(otherExpend)) {
      this._otherServicesSectionModel.totalExpendPerUnit.elementValue = this.addDecimals(
        this._commonService.calculatePerUnit(otherUnits, otherExpend));
    }

    this.calcSums();
  }

  expendPerUnit(expendValue: number, unitValue: number): string {
    if (!isNaN(expendValue) && !isNaN(unitValue) && unitValue !== 0) {
        return this.addDecimals((expendValue / unitValue).toString());
    }
    else {
        return 'N/A';
    }
  }

  removeCommas(value: string): string {
    if (value) {
      value = value.replace(/,/g, '');
    }
    return value;
  }

  addDecimals(currentValue: string): string {
    const originalvalue = currentValue;
    if (currentValue == null || currentValue === '') {
      return '';
    }
    currentValue = currentValue.replace(/,/g, '');
    const num = parseFloat(currentValue);
    if (!isNaN(num)) {
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    } else {
      return originalvalue;
    }
  }

  // adds commas to value after every 3 digits
  addComma(currentValue: string): string {
    const originalvalue = currentValue;

    if (currentValue == null || currentValue === '') {
      return '';
    }
    currentValue = currentValue.replace(/,/g, '');
    const num = parseInt(currentValue);
    if (!isNaN(num) && !currentValue.includes('.')) {
      return currentValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return originalvalue;
    }
  }

  addDecimalAndComma(currentValue: string): string {
    return this.addDecimals(this.addComma(currentValue));
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.eoLockedNav = false;
      return true;
    } else {
      this._commonService.eoLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

  calcSums(): void {

    this.t3ExpendTotal = (Number(this.removeCommas(this._personalCareSectionModel.expendC.elementValue)) || 0) +
      (Number(this.removeCommas(this._homemakerSectionModel.expendC.elementValue)) || 0) +
      (Number(this.removeCommas(this._choreSectionModel.expendC.elementValue)) || 0) +
      (Number(this.removeCommas(this._homeDeliveredMealsSectionModel.expendC.elementValue)) || 0) +
      (Number(this.removeCommas(this._adultDayCareSectionModel.expendC.elementValue)) || 0) +
      (Number(this.removeCommas(this._caseMgmtSectionModel.expendC.elementValue)) || 0) +
      (Number(this.removeCommas(this._assistedTranspSectionModel.expendC.elementValue)) || 0) +
      (Number(this.removeCommas(this._congregateMealsSectionModel.expendC.elementValue)) || 0) +
      (Number(this.removeCommas(this._nutritionCounselingSectionModel.expendC.elementValue)) || 0) +
      (Number(this.removeCommas(this._legalSectionModel.expendC.elementValue)) || 0) +
      (Number(this.removeCommas(this._transportationSectionModel.expend.elementValue)) || 0) +
      (Number(this.removeCommas(this._nutritionEduSectionModel.expend.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoAndAssistanceSectionModel.expend.elementValue)) || 0) +
      (Number(this.removeCommas(this._healthPromoEvidenceSectionModel.bothParts.elementValue)) || 0) +
      (Number(this.removeCommas(this._healthPromoNonEvidenceSectionModel.title3Expend.elementValue)) || 0) +
      (Number(this.removeCommas(this._otherServicesSectionModel.expend.elementValue)) || 0);

    this.t3aExpendTotal = (Number(this.removeCommas(this._homeDeliveredMealsSectionModel.expendA.elementValue)) || 0) +
      (Number(this.removeCommas(this._congregateMealsSectionModel.expendA.elementValue)) || 0);

    this.otherStateTotal = (Number(this.removeCommas(this._personalCareSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._homemakerSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._choreSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._homeDeliveredMealsSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._adultDayCareSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._caseMgmtSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._assistedTranspSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._congregateMealsSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._nutritionCounselingSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._legalSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._transportationSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._nutritionEduSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoAndAssistanceSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._healthPromoEvidenceSectionModel.otherStateExpend.elementValue)) || 0) +
      (Number(this.removeCommas(this._healthPromoNonEvidenceSectionModel.otherStateExpend.elementValue)) || 0) +
      (Number(this.removeCommas(this._otherServicesSectionModel.expendOtherState.elementValue)) || 0);

    this.nonOtherStateTotal = (Number(this.removeCommas(this._personalCareSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._homemakerSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._choreSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._homeDeliveredMealsSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._adultDayCareSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._caseMgmtSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._assistedTranspSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._congregateMealsSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._nutritionCounselingSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._legalSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._transportationSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._nutritionEduSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoAndAssistanceSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._healthPromoEvidenceSectionModel.nonStateExpend.elementValue)) || 0) +
      (Number(this.removeCommas(this._healthPromoNonEvidenceSectionModel.nonStateExpend.elementValue)) || 0) +
      (Number(this.removeCommas(this._otherServicesSectionModel.expendOtherNonState.elementValue)) || 0);

    this.programIncomeTotal = (Number(this.removeCommas(this._personalCareSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._homemakerSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._choreSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._homeDeliveredMealsSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._adultDayCareSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._caseMgmtSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._assistedTranspSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._congregateMealsSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._nutritionCounselingSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._legalSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._transportationSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._nutritionEduSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoAndAssistanceSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._healthPromoEvidenceSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._healthPromoNonEvidenceSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._otherServicesSectionModel.programIncome.elementValue)) || 0);

    this.serviceExpendTotal = (Number(this.removeCommas(this._personalCareSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._homemakerSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._choreSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._homeDeliveredMealsSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._adultDayCareSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._caseMgmtSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._assistedTranspSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._congregateMealsSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._nutritionCounselingSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._legalSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._transportationSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._nutritionEduSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoAndAssistanceSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._healthPromoEvidenceSectionModel.totalExpend.elementValue)) || 0) +
      (Number(this.removeCommas(this._healthPromoNonEvidenceSectionModel.totalExpend.elementValue)) || 0) +
      (Number(this.removeCommas(this._otherServicesSectionModel.expendTotal.elementValue)) || 0);

    this.t3ExpendTotal = this.addDecimals(this.t3ExpendTotal.toString());
    this.t3aExpendTotal = this.addDecimals(this.t3aExpendTotal.toString());
    this.otherStateTotal = this.addDecimals(this.otherStateTotal.toString());
    this.nonOtherStateTotal = this.addDecimals(this.nonOtherStateTotal.toString());
    this.programIncomeTotal = this.addDecimals(this.programIncomeTotal.toString());
    this.serviceExpendTotal = this.addDecimals(this.serviceExpendTotal.toString());
  }

  cluster1Errors(): Array<string> {
    const errors: Array<string> = [];
    this.getCluster1DataElements().forEach((de) => {
      this.getError(de, errors);
    });

    return errors;
  }

  cluster2Errors(): Array<string> {
    const errors: Array<string> = [];
    this.getCluster2DataElements().forEach((de) => {
      this.getError(de, errors);
    });

    return errors;
  }

  cluster3Errors(): Array<string> {
    const errors: Array<string> = [];
    this.getCluster3DataElements().forEach((de) => {
      this.getError(de, errors);
    });

    return errors;
  }

  getError(dataElement: DataElement, errors: Array<string>){
    if (dataElement.dataEntryErrorMessage) {
      errors.push(this._personalCareSectionModel.expendC.label + ': ' + dataElement.dataEntryErrorMessage);
    } else if (!dataElement.dataEntryErrorMessage &&
      dataElement.questionStatus === QuestionStatusEnum.L2InProgressError) {
        errors.push(this._personalCareSectionModel.expendC.label + ': ' + dataElement.businessErrorMessage);
    }
  }

  getWarning(dataElement: DataElement, warnings: Array<string>){
    if (dataElement.businessErrorMessage && dataElement.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      warnings.push(this._personalCareSectionModel.expendC.label + ': ' + dataElement.businessErrorMessage);
    }
  }

  cluster1Warnings(): Array<string> {
    const warnings: Array<string> = [];

    this.getCluster1DataElements().forEach((de) => {
      this.getWarning(de, warnings);
    });

    return warnings;
  }

  cluster2Warnings(): Array<string> {
    const warnings: Array<string> = [];

    this.getCluster2DataElements().forEach((de) => {
      this.getWarning(de, warnings);
    });

    return warnings;
  }

  cluster3Warnings(): Array<string> {
    const warnings: Array<string> = [];

    this.getCluster3DataElements().forEach((de) => {
      this.getWarning(de, warnings);
    });

    return warnings;
  }

  getCluster1DataElements(): Array<DataElement> {
    const cluster1DataElements: Array<DataElement> = [
      this._personalCareSectionModel.expendC,
      this._personalCareSectionModel.expendOtherState,
      this._personalCareSectionModel.expendOtherNonState,
      this._personalCareSectionModel.programIncome,
      this._personalCareSectionModel.totalServiceUnits,
      this._personalCareCharsModel.totalPersonsServed,
      this._homemakerSectionModel.expendC,
      this._homemakerSectionModel.expendOtherState,
      this._homemakerSectionModel.expendOtherNonState,
      this._homemakerSectionModel.programIncome,
      this._homemakerSectionModel.totalServiceUnits,
      this._homemakerCharsModel.totalPersonsServed,
      this._choreSectionModel.expendC,
      this._choreSectionModel.expendOtherState,
      this._choreSectionModel.expendOtherNonState,
      this._choreSectionModel.programIncome,
      this._choreSectionModel.totalServiceUnits,
      this._choreCharsModel.totalPersonsServed,
      this._homeDeliveredMealsSectionModel.expendC,
      this._homeDeliveredMealsSectionModel.expendA,
      this._homeDeliveredMealsSectionModel.expendOtherState,
      this._homeDeliveredMealsSectionModel.expendOtherNonState,
      this._homeDeliveredMealsSectionModel.programIncome,
      this._homeDeliveredMealsSectionModel.totalServiceUnits,
      this._homeDeliveredMealsCharsModel.totalPersonsServed,
      this._adultDayCareSectionModel.expendC,
      this._adultDayCareSectionModel.expendOtherState,
      this._adultDayCareSectionModel.expendOtherNonState,
      this._adultDayCareSectionModel.programIncome,
      this._adultDayCareSectionModel.totalServiceUnits,
      this._adultDayCareCharsModel.totalPersonsServed,
      this._caseMgmtSectionModel.expendC,
      this._caseMgmtSectionModel.expendOtherState,
      this._caseMgmtSectionModel.expendOtherNonState,
      this._caseMgmtSectionModel.programIncome,
      this._caseMgmtSectionModel.totalServiceUnits,
      this._caseMgmtCharsModel.totalPersonsServed
    ];

    return cluster1DataElements;
  }

  getCluster2DataElements(): Array<DataElement> {
    const cluster2DataElements: Array<DataElement> = [
      this._assistedTranspSectionModel.expendC,
      this._assistedTranspSectionModel.expendOtherState,
      this._assistedTranspSectionModel.expendOtherNonState,
      this._assistedTranspSectionModel.programIncome,
      this._assistedTranspSectionModel.totalServiceUnits,
      this._assistedTranspCharsModel.totalPersonsServed,
      this._congregateMealsSectionModel.expendC,
      this._congregateMealsSectionModel.expendA,
      this._congregateMealsSectionModel.expendOtherState,
      this._congregateMealsSectionModel.expendOtherNonState,
      this._congregateMealsSectionModel.programIncome,
      this._congregateMealsSectionModel.totalServiceUnits,
      this._congregateMealsCharsModel.totalPersonsServed,
      this._nutritionCounselingSectionModel.expendC,
      this._nutritionCounselingSectionModel.expendOtherState,
      this._nutritionCounselingSectionModel.expendOtherNonState,
      this._nutritionCounselingSectionModel.programIncome,
      this._nutritionCounselingSectionModel.totalServiceUnits,
      this._nutritionCounselingCharsModel.totalPersonsServed,
      this._legalSectionModel.expendC,
      this._legalSectionModel.expendOtherState,
      this._legalSectionModel.expendOtherNonState,
      this._legalSectionModel.programIncome,
      this._legalSectionModel.totalServiceUnits,
      this._legalCharsModel.totalPersonsServed
    ];

    return cluster2DataElements;
  }

  getCluster3DataElements(): Array<DataElement> {
    const cluster3DataElements: Array<DataElement> = [
      this._transportationSectionModel.expend,
      this._transportationSectionModel.expendOtherState,
      this._transportationSectionModel.expendOtherNonState,
      this._transportationSectionModel.programIncome,
      this._transportationSectionModel.totalServiceUnits,
      this._nutritionEduSectionModel.expendC1,
      this._nutritionEduSectionModel.expendC2,
      this._nutritionEduSectionModel.partB,
      this._nutritionEduSectionModel.expend,
      this._nutritionEduSectionModel.expendOtherState,
      this._nutritionEduSectionModel.expendOtherNonState,
      this._nutritionEduSectionModel.programIncome,
      this._nutritionEduSectionModel.totalServiceUnits,
      this._nutritionEduSectionModel.estimatedAudienceSize,
      this._infoAndAssistanceSectionModel.expend,
      this._infoAndAssistanceSectionModel.expendOtherState,
      this._infoAndAssistanceSectionModel.expendOtherNonState,
      this._infoAndAssistanceSectionModel.programIncome,
      this._infoAndAssistanceSectionModel.totalServiceUnits,
      this._healthPromoEvidenceSectionModel.partB,
      this._healthPromoEvidenceSectionModel.partD,
      this._healthPromoEvidenceSectionModel.otherStateExpend,
      this._healthPromoEvidenceSectionModel.nonStateExpend,
      this._healthPromoEvidenceSectionModel.programIncome,
      this._healthPromoEvidenceSectionModel.peopleServed,
      this._healthPromoNonEvidenceSectionModel.title3Expend,
      this._healthPromoNonEvidenceSectionModel.otherStateExpend,
      this._healthPromoNonEvidenceSectionModel.nonStateExpend,
      this._healthPromoNonEvidenceSectionModel.programIncome,
      this._healthPromoNonEvidenceSectionModel.peopleServed,
      this._otherServicesSectionModel.totalServiceUnits
    ];

    return cluster3DataElements;
  }

  validate(data: DataElement): DataElement {
    if (data.elementValue != null && data.elementValue !== '') {
      if (this._validation.validate(data.validationType, data.elementValue)) {
        if (!this._validation.validateMaxLength(data.elementValue, data.maxLength)) {
          data.dataEntryErrorMessage = 'Maximum allowed digits is ' + data.maxLength;
          data.questionStatus = 1;
        } else {
          data.dataEntryErrorMessage = '';
          data.questionStatus = 3;
        }
      } else {
        data.dataEntryErrorMessage = this._validation.getErrorMessage(data.validationType);
        data.questionStatus = 1;
      }
    } else {
      data.dataEntryErrorMessage = '';
      data.questionStatus = 0;
    }

    return data;
  }

  public get clusterOneSections(): Array<Section> {
    return [
      this._personalCareSectionModel,
      this._personalCareCharsModel,
      this._homemakerCharsModel,
      this._homemakerSectionModel,
      this._choreCharsModel,
      this._choreSectionModel,
      this._homeDeliveredMealsCharsModel,
      this._homeDeliveredMealsSectionModel,
      this._adultDayCareSectionModel,
      this._adultDayCareCharsModel,
      this._caseMgmtSectionModel,
      this._caseMgmtCharsModel
    ];
  }

  public get clusterTwoSections(): Array<Section> {
    return [
      this._assistedTranspSectionModel,
      this._assistedTranspCharsModel,
      this._congregateMealsSectionModel,
      this._congregateMealsCharsModel,
      this._nutritionCounselingSectionModel,
      this._nutritionCounselingCharsModel,
      this._legalSectionModel,
      this._legalCharsModel
    ];
  }

  public get clusterThreeSections(): Array<Section> {
    return [
      this._transportationSectionModel,
      this._nutritionEduSectionModel,
      this._infoAndAssistanceSectionModel,
      this._healthPromoEvidenceSectionModel,
      this._healthPromoNonEvidenceSectionModel
    ];
  }


  checkValidForm(cluster: string): void {
    let valid = true;
    let clusterSections: Array<Section>;

    switch (cluster) {
      case 'cluster1': {
        clusterSections = [this._personalCareCharsModel,
        this._homemakerSectionModel, this._homemakerCharsModel,
        this._choreSectionModel, this._choreSectionModel,
        this._homeDeliveredMealsSectionModel, this._homeDeliveredMealsCharsModel,
        this._adultDayCareSectionModel, this._adultDayCareCharsModel,
        this._caseMgmtSectionModel, this._caseMgmtCharsModel];

        clusterSections.forEach(function (currSection) {
          Object.keys(currSection).forEach(function (key) {
            if (currSection[key] != null && currSection[key].questionStatus === 1) {
              valid = false;
            }
          });
        });
        break;
      }
      case 'cluster2': {
        clusterSections = [this._assistedTranspSectionModel, this._assistedTranspCharsModel,
        this._congregateMealsSectionModel, this._congregateMealsCharsModel,
        this._nutritionCounselingSectionModel, this._nutritionCounselingCharsModel,
        this._legalSectionModel, this._legalCharsModel];

        clusterSections.forEach(function (currSection) {
          Object.keys(currSection).forEach(function (key) {
            if (currSection[key] != null && currSection[key].questionStatus === 1) {
              valid = false;
            }
          });
        });
        break;
      }
      case 'cluster3': {
        clusterSections = [this._transportationSectionModel, this._nutritionEduSectionModel,
        this._infoAndAssistanceSectionModel, this._healthPromoEvidenceSectionModel,
        this._healthPromoNonEvidenceSectionModel, this._otherServicesSectionModel];

        clusterSections.forEach(function (currSection) {
          Object.keys(currSection).forEach(function (key) {
            if (currSection[key] != null && currSection[key].questionStatus === 1) {
              valid = false;
            }
          });
        });
        break;
      }
    }

    this.validForm = valid;
  }

  setErrorDisplayType(status: number): string {
    if (status === QuestionStatusEnum.L1InProgressError || status === QuestionStatusEnum.L2InProgressError) {
      return 'alert alert-danger';
    }
    if (status === QuestionStatusEnum.L2ValidatedWarning) {
      return 'alert alert-warning';
    } else { return ''; }
  }

  calculateOutlineColor(status: number): string {
    if (status === QuestionStatusEnum.L1InProgressError || status === QuestionStatusEnum.L2InProgressError) {
      return 'has-error';
    }
    if (status === QuestionStatusEnum.L2ValidatedWarning) {
      return 'has-warning';
    } else { return ''; }
  }


  public hasError(cluster: Array<Section>): boolean {
    if (cluster) {
      return cluster.some(x => x.sectionStatus === QuestionStatusEnum.L1InProgressError
        || x.sectionStatus === QuestionStatusEnum.L2InProgressError);
    } else {
      return false;
    }
  }

  public hasWarning(cluster: Array<Section>): boolean {
    if (cluster && !this.hasError(cluster)) {
      return cluster.some(x => x.sectionStatus === QuestionStatusEnum.L2ValidatedWarning);
    } else {
      return false;
    }
  }
}
