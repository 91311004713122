import { IOverviewDetails } from "../shared/Overview/overviewDetails";
import { TIIIOverview } from "../shared/section";

export class TitleIIIOverviewConstants {
    public static readonly baseRoute: string = '/data-submissions';
    public static readonly errorsUrl: string = '/errors';
    public static readonly uploadUrl: string = `${TitleIIIOverviewConstants.baseRoute}/titleIIIManageUploads`;

    public static readonly AN: IOverviewDetails = {
        name: TIIIOverview.ANSectionServiceName,
        overviewName: TIIIOverview.ANSectionServiceName,
        dataEntryUrl: [`${TitleIIIOverviewConstants.baseRoute}/titleIIIANNetworkFunding`],
        varianceUrl: ['NO-VE'],
        description: TIIIOverview.ANSectionServiceDescription
    };
    public static readonly OA: IOverviewDetails = {
        name: TIIIOverview.OASectionServiceName,
        overviewName: TIIIOverview.OASectionServiceName,
        dataEntryUrl: [`${TitleIIIOverviewConstants.baseRoute}/OAConsumerTps`],
        varianceUrl: [`${TitleIIIOverviewConstants.baseRoute}/titleIIIVarianceExplanationsOA`],
        description: TIIIOverview.OASectionServiceDescription
    };
    public static readonly COA: IOverviewDetails = {
        name: TIIIOverview.COASectionServiceName,
        overviewName: TIIIOverview.COASectionServiceName,
        dataEntryUrl: [`${TitleIIIOverviewConstants.baseRoute}/titleIIICOAConsumerSummary`],
        varianceUrl: [`${TitleIIIOverviewConstants.baseRoute}/titleIIIVarianceExplanationsCG`],
        description: TIIIOverview.COASectionServiceDescription
    };
    public static readonly ORC: IOverviewDetails = {
        name: TIIIOverview.ORCSectionServiceName,
        overviewName: TIIIOverview.ORCSectionServiceName,
        dataEntryUrl: [`${TitleIIIOverviewConstants.baseRoute}/titleIIIORCConsumerSummary`],
        varianceUrl: [`${TitleIIIOverviewConstants.baseRoute}/titleIIIVarianceExplanationsOR`],
        description: TIIIOverview.ORCSectionServiceDescription
    };
    public static readonly Expenditures: IOverviewDetails = {
        name: TIIIOverview.ExpendituresSectionServiceName,
        overviewName: TIIIOverview.ExpendituresSectionServiceName,
        dataEntryUrl: [`${TitleIIIOverviewConstants.baseRoute}/titleIIIOAExpenditures`],
        varianceUrl: [`${TitleIIIOverviewConstants.baseRoute}/titleIIIVarianceExplanationsOA`],
        description: TIIIOverview.ExpendituresSectionServiceDescription
    };
    public static readonly NSIP: IOverviewDetails = {
        name: TIIIOverview.nsipSectionServiceName,
        overviewName: TIIIOverview.nsipSectionServiceName,
        dataEntryUrl: [`${TitleIIIOverviewConstants.baseRoute}/titleIIInsip`],
        varianceUrl: [`${TitleIIIOverviewConstants.baseRoute}/titleIIIVarianceExplanationsNSIP`],
        description: TIIIOverview.nsipSectionServiceDescription
    };

}
