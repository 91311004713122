<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- merge section warning -->
      <div id="mergeConfirmationModal" class="modal fade" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <strong class="modal-title">Update Existing Data</strong>
              <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <p>The file you are attempting to upload includes data for fields that already contain data in the
                report.
              </p>
              <p>You must choose one of the following options:</p>
              <ol>
                <li>
                  <strong>Merge data</strong> with what is already populated in the report. When data is merged:
                  <ol>
                    <li>Numeric data in overlapping fields will be aggregated</li>
                    <li>Narrative fields will be appended within the character limits</li>
                    <li>Data selections from lists will show the selections from the newly uploaded file</li>
                  </ol>
                  <strong class="row bold mt-1 mb-1">OR</strong>
                </li>
                <li>
                  <strong>Overwrite data </strong> already populated in the report. When data is overwritten, data
                  already in the system will be replaced by the data in the newly uploaded file.</li>
              </ol>
            </div>
            <div class="modal-footer">
              <button (click)="merge()" type="button" class="btn btn-primary btn-small" id="mergedata">Yes, merge data</button>
              <button (click)="overwrite()" type="button" class="btn btn-primary btn-small" id='overwritedat'>No,
                overwrite data</button>
              <button type="button" class="btn btn-secondary btn-small" data-dismiss="modal" id='cancelupload'>Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <!-- .end merge section warning -->
      <!-- cc overwrite warning -->
      <div id="ccOverwriteConfirmationModal" class="modal fade">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <strong class="modal-title">Replace Previously Uploaded Cases and Complaints Data</strong>
              <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <p>A file containing cases and complaints data has already been uploaded.</p>
              <p>
                If you proceed to upload a new file containing cases and complaints data, previously uploaded data will
                be replaced by data
                in the new file. Cases and complaints data will reflect only data from the latest cases and
                complaints file uploaded.
              </p>
              <p>
                You can download previously uploaded versions of the cases and complaints data files from the Manage
                Uploads page at any
                time.
              </p>
            </div>
            <div class="modal-footer">
              <button (click)="overwriteCaseAndComplaints()" type="button" class="btn btn-primary btn-small"
                data-dismiss="modal" id="replace">Continue to replace previous data</button>
              <button type="button" class="btn btn-secondary btn-small" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <!-- invalid file upload modal -->
      <div id="incorrectUploadFileModal" class="modal fade">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <strong class="modal-title">Invalid File Format Detected</strong>
              <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <p>An invalid file format was detected and file upload terminated.</p>
              <p>To review your file, click cancel then open the file in your preferred editor.</p>
              <p>To review OAAPS upload templates and samples, click the sample files button to proceed to the technical documents section.</p>
            </div>
            <div class="modal-footer">
              <button (click)="sampleFile()" type="button" class="btn btn-primary btn-small"
                data-dismiss="modal" id="replace">Sample files</button>
              <button type="button" class="btn btn-secondary btn-small" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <!-- .end cc overwrite warning -->
      <div id="whatHappensModal" class="modal fade" tabindex="-1">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                <span aria-hidden="true">×</span>
              </button>
              <h3 class="modal-title">What Happens During Upload?</h3>
            </div>
            <div class="modal-body">
              <p>Once you select a file to upload, the system will verify the following:</p>
              <ul class="ptb-1">
                <li>
                  <strong>Correct template:</strong> if you attempt to upload a file that does not match the required
                  file type or include the proper headers, you will be prompted to upload a new file. Valid file
                  formats are: .csv, .xls, .xlsx and .xml
                </li>
                <li>
                  <strong>Format validation:</strong> if you attempt to upload any data that does not match the data
                  format expected for a given field, you will be presented with a list of errors. You will be asked to
                  fix the errors in the file and attempt the upload again. (For example, currency values must be
                  numeric)
                </li>
                <ng-container *ngIf="_claimService.currentTitle === 'VII'">
                  <li>
                    <strong>Business rule validation:</strong> if you attempt to upload case and complaint data that
                    does not pass business rule validations, you will be presented with a list of errors.
                    You will be asked to fix the errors in the file and attempt the upload again. (For example,
                    the case opened date must be before the case closed date)
                  </li>
                  <li>
                    <strong>Subsequent case and complaint data:</strong> Any subsequent uploads of case and complaint
                    data will replace previously uploaded data.
                  </li>
                  <li>
                    <strong>Duplicate data for non-case and complaint data:</strong> if you attempt to upload data
                    for fields already populated in the report, you will be asked to either merge the data
                    (add to existing data in the system) or overwrite the data (replace existing data in
                    the system with what is included in the new file being uploaded).
                  </li>
                </ng-container>
                <ng-container *ngIf="_claimService.currentTitle === 'III' || _claimService.currentTitle === 'VI'">
                  <li>
                    <strong>Duplicate data: </strong> if you attempt to upload data for fields already populated in the
                    report, you will be asked to either merge the data (add to existing data in the system) or
                    overwrite the data (replace existing data in the system with what is included in the new file being
                    uploaded).
                  </li>
                  <li *ngIf="_claimService.currentTitle === 'III'">
                    <strong>Data Responsibility:</strong> if you attempt to upload data for fields identified as not
                    your organization's responsibility, the system will discard data in those fields and upload the
                    rest.
                  </li>
                </ng-container>
              </ul>
            </div>
            <div class="text-center pt-2 pb-2 modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <!-- .end whatHappensModal -->
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <loading #loadingSpinner></loading>
        <div class="col-md-9">
          <h2>
            <i class="fa fa-upload" aria-hidden="true"></i> Upload a New File</h2>
          <p class="mt-1 help-block">All fields are required unless indicated as optional</p>
          <div class="background-gray-lighter p-1">
            <div class="acl-container mb-1 p-1">
              <div class="form-group">
                <p class="bold text-secondary">Select file for upload</p>
                <div class="input-group">
                  <label id="uploadFileLabel" class="input-group-btn" aria-label="browse for file" for="uploadFileButton">
                    <span (keydown.enter)="uploadFileClicked()" class="btn btn-primary uploader" tabindex="0">
                      Browse&hellip;
                      <input #inputUpload id="uploadFileButton" type="file" style="display: none;" (change)="fileChangeEvent($event)"
                        accept=".csv,.xls,.xlsx,.xml" multiple>
                    </span>
                  </label>
                  <!-- PLEASE SEE ACLFUNCTIONS.JS FILE FOR FUNCTIONALITY -->
                  <input id="inputFilePath" type="text" class="form-control" readonly aria-label="browse for file extended control"
                    value="{{fileName}}">
                </div>
              </div>

              <!-- title vii flow -->
              <div *ngIf="_claimService.currentTitle === 'VII'">
                <p class="bold text-secondary"> Select sections included in this upload</p>
                <div class="radio">
                  <label>
                    <input type="radio" [(ngModel)]="uploadSectionRadio" name="uploadSectionRadio" id="cc" value="casecomplaints">
                    Cases and Complaints
                  </label>
                </div>
                <div class="radio">
                  <label>
                    <input type="radio" [(ngModel)]="uploadSectionRadio" name="uploadSectionRadio" id="allsections"
                      value="allsections"> All other sections
                  </label>
                </div>
              </div>
              <!--title iii flow -->
              <!--<div *ngIf="_claimService.currentTitle == 'III'">
                                <div class="radio">
                                    <label>
                                        <input type="radio" checked="checked" [(ngModel)]="uploadSectionRadio" name="uploadSectionRadio" id="allsections" value="allsections">
                                        All sections
                                    </label>
                                </div>
                            </div>-->
              <div>
                <label for="comments">
                  Upload comments (optional)
                </label>
                <textarea [(ngModel)]="comment" class="form-control" id="comments"></textarea>
                <p class="mt-1">
                  <a tabindex="0" data-toggle="modal" data-target="#whatHappensModal" href="#" >What happens during upload?</a>
                </p>

              </div>
              <button class="btn btn-primary mt-1 mb-1 mr-1" id="uploadFile" [disabled]="_claimService.isViewOnly || !isReadyForUpload
              || (_claimService.currentTitle === _titleEnum[_titleEnum.VII] && (this.uploadSectionRadio === '' || _isUploadDisabled))"
                (click)="upload()">
                Upload file
              </button>
              <button class="btn btn-link mt-1 mb-1" id="cancel" (click)="navCancel()">
                Cancel
              </button>

            </div>
            <!-- .end acl-container -->
          </div>
        </div>
        <!-- .end col-md-8 -->

      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
