import { Component, OnInit, Input } from '@angular/core';
import { NewsModel } from './news.model';

@Component({
    selector: 'acl-news',
    templateUrl: './news.component.html'
})

export class NewsComponent implements OnInit {
    @Input() newsModel: NewsModel;
    ngOnInit() {
    }
}
