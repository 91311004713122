import { NgModule } from '@angular/core';
import { OAuthCallbackComponent } from './oauthCallback.component';
import { OAuthCallbackHandler } from './oauthCallback.guard';

@NgModule({
    imports: [],
    declarations: [OAuthCallbackComponent],
    providers: [OAuthCallbackHandler]
})
export class OAuthHandshakeModule { }
