import { TitleRoute } from '../Model/titleRoute';

/**
 * This class provides a lookup for route paths by sectionNames
 */
export class RouteLookup {

    /**
     * @param routes The module routes
     */
    constructor(public routes: TitleRoute[]) { }

    // Lookup path by sectionName
    private _sectionNameToPathMap = null;
    public lookupPath(sectionName: string) {
        if (!this._sectionNameToPathMap) {
            this._sectionNameToPathMap = this.routes.reduce((acc, i) => {
                acc[i.sectionName] = i.path;
                return acc;
            }, {});
        }
        let path = '';
        if (this._sectionNameToPathMap[sectionName]) {
            path = this._sectionNameToPathMap[sectionName];
        }
        return path;
    }
}
