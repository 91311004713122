import { QuestionStatusEnum } from '../dataElement';

export interface IStatusHistoryDto {
        status: QuestionStatusEnum;
        created: string;
        createdBy: string;
        comments: string;
}

export class StatusHistoryDto implements IStatusHistoryDto {
        status: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
        created: string = '';
        createdBy: string = '';
        comments: string = '';

        public static loading(): IStatusHistoryDto {
                return {
                        comments: 'loading...',
                        createdBy: 'loading...',
                        created: new Date().toISOString(),
                        status: QuestionStatusEnum.Returned
                };
        }
}
