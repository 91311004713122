<div *ngIf="yearArrayBasic.length > 0 && (_claimService.isNavStateSubmission || _claimService.isAAAUser || _claimService.isNavApprover || _claimService.currentOrgLevel === userManagementStrings.orgLevelGrantee)"
  class="acl-select pull-right">
  <label class="sr-only" for="reportingPeriod">Reporting Period:</label>
  <select *ngIf="_claimService.currentTitle !== 'VI'" class="form-control primary" (change)="switchYear($event.target.value)"
    id="reportingPeriod">
    <option disabled selected value="">Reporting Period: FFY {{_claimService.currentYear}}</option>
    <ng-container *ngFor="let year of yearArrayBasic">
      <option *ngIf="year != _claimService.currentYear" value="{{year}}">Reporting Period: FFY {{year}}</option>
    </ng-container>
  </select>

  <select *ngIf="reportingPeriods && _claimService.currentTitle === 'VI'" class="form-control primary" (change)="switchYear($event.target.value)"
    id="reportingPeriod">
    <!-- <option disabled selected value="">Reporting Period: {{_claimService.currentYear}}</option> -->
    <ng-container *ngFor="let rp of reportingPeriods">
      <option *ngIf="rp.fiscalYear === _claimService.currentYear" disabled selected value="">Reporting Period:
        {{rp.fiscalYear }} ({{rp.startDate | amDateFormat: 'MMM YYYY'}} -
        {{ rp.endDate | amDateFormat: 'MMM YYYY'}})</option>
      <option *ngIf="rp.fiscalYear !== _claimService.currentYear" value="{{rp.fiscalYear}}">Reporting Period:
        {{rp.fiscalYear }} ({{rp.startDate | amDateFormat: 'MMM YYYY'}} -
        {{ rp.endDate | amDateFormat: 'MMM YYYY'}})</option>
    </ng-container>
  </select>
</div>
