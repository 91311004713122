import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ITitleState } from '../iTitleState';

import * as _ from 'lodash-es';
import { Disabled } from '../../reports/report-settings/report-settings.component';


export class ITitleStateSelection implements ITitleState {
  id?: string;
  isAAAState?: boolean;
  name?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state: string;
  zip?: string;
  region?: string;
  type?: string;
  links?: string[];

  isSelected?: boolean;
}

@Component({
  selector: 'acl-state-select',
  templateUrl: './state-select.component.html',
  styles: [
    'label { display: block; }',
    'hr { margin-top: 20px; margin-bottom: 20px; border-top: 2px solid #eeeeee; }'
  ]
})
export class StateSelectComponent implements OnInit, OnDestroy {

  @Input() states: Array<ITitleState>;

  @Output() selectedStates: EventEmitter<ITitleState[]> = new EventEmitter<ITitleState[]>();

  @Output() disabledFlag: EventEmitter<Disabled> = new EventEmitter<Disabled>();

  statesByRegion: _.Dictionary<ITitleStateSelection[]>;

  constructor() { }

  ngOnInit() {
    if (this.states) {
      this.statesByRegion = _.groupBy(_.sortBy(this.states.map<ITitleStateSelection>
        (q => ({ isSelected: false, ...q })), 'state'), r => r.region);
    } else {
      throw new Error('Attribute states is required');
    }
    this.disabledFlag.emit({ componentName: 'StateSelectComponent', isDisabled: true });
  }


  ngOnDestroy(): void {
    this.disabledFlag.emit({ componentName: 'StateSelectComponent', isDisabled: false });
  }

  toggleRegion(region: string, e) {
    if (e.target.checked) {
      this.statesByRegion[region].forEach(state => state.isSelected = true);
    } else {
      this.statesByRegion[region].forEach(state => state.isSelected = false);
    }
    const selectedStates = this.selected;
    this.disabledFlag.emit({ componentName: 'StateSelectComponent', isDisabled: selectedStates.length <= 0 });
    this.selectedStates.emit(selectedStates);
  }

  areAllStatesChecked(region: string) {
    return this.statesByRegion[region].reduce<boolean>((a, n) => a && n.isSelected, true);
  }

  modelChange(state: ITitleStateSelection) {
    state.isSelected = !state.isSelected;
    const selectedStates = this.selected;
    this.disabledFlag.emit({ componentName: 'StateSelectComponent', isDisabled: selectedStates.length <= 0 });
    this.selectedStates.emit(selectedStates);
  }

  get selected() {
    const selected = [];
    if (this.statesByRegion) {
      Object.keys(this.statesByRegion).forEach(key =>
        selected.push(...this.statesByRegion[key].filter(s => s.isSelected)));
    }
    return selected;
  }
}
