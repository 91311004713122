import { Component, OnInit, Input, NgZone, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { FileService } from '../files/file.service';
import { IFile } from '../files/file';
import { ClaimService } from '../shared/claim.service';
import { YearApprovalStatus } from '../shared/yearApprovalStatus.model';
import { TitleVIIDataEntryService } from '../StateSubmissions/titleVIIDataEntry.service';
import { TitleIIIDataEntryService } from '../DataSubmissions/titleIIIDataEntry.service';
import { TitleVIDataEntryService } from '../TitleVI/titleVIDataEntry.service';
import { CommonService } from '../shared/common.service';
import { LoadingComponent } from '../shared/loading.component';
import {
    ITVIIOverview, TVIIOverview,
    ITIIIOverview, TIIIOverview
} from '../shared/section';
import { QuestionStatusEnum } from '../shared/dataElement';
import { Constants, AclTitleEnum } from '../app.constants';
import { AllStates } from '../shared/states';
import { UserDefaultStates } from '../shared/userDefaultStates';
import * as systemUpdatesData from '../../assets/json/systemUpdates.json';
import * as reportingDatesData from '../../assets/json/reportingPeriod.json';
import * as eventsData from '../../assets/json/events.json';
import { ApprovalDecision } from '../shared/yearApprovalStatus.model';
import { LookupService } from '../lookup.service';
import { TitleIIIState } from '../DataSubmissions/titleIIIState';
import { UserManagementStrings } from '../UserManagement/userManagerObjects';

import * as _ from 'lodash-es';
declare var jQuery: any;


import { ErrorLogger } from '../shared/error-logger.service';
import { TviDashboardModel } from '../TitleVI/dashboard/tvidashboard.model';
import { RegionEnum } from '../UserManagement/userManagerObjects';
import { UserService } from '../UserManagement/user.service';
import { ITVIOverviewDto } from '../TitleVI/model/Overview';
import { GranteeService } from '../TitleVI/granteeManagement/grantee.service';
import { TVIRegionalContactDto } from '../TitleVI/model/IGrantee';
import { IStatusHistoryDto, StatusHistoryDto } from '../shared/Dto/statusHistoryDto';
import { DestructibleComponent } from '../destructible.component';
import { ReportingCalendar } from '../resources/resources.model';
import { Title } from '@angular/platform-browser';
import { FiscalYearDropdownComponent } from '../StateSubmissions/fiscalYearDropdown.component';
import { switchMap } from 'rxjs/operators';

@Component({
    templateUrl: 'dashboard.component.html',
    styles: [
        `
        .white-circle {
            border-radius: 60px;
            background: #FFFFFF;
            padding: 15px;
        }
    `
    ],
    selector: 'dashboard'
})

export class DashboardComponent extends DestructibleComponent implements OnInit, OnDestroy {

    // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;
    @ViewChild('ffyDropdown', { static: true }) ffyDropdownComponent: FiscalYearDropdownComponent;

    statusTxt: string;
    previousStatusTxt: string;
    enterViewDataTxt: string;
    returnCommentModel: YearApprovalStatus = new YearApprovalStatus();
    overviewModelVII: ITVIIOverview = new TVIIOverview();
    overviewModelIII: ITIIIOverview = new TIIIOverview();
    overviewModelVIIArray: Array<ITVIIOverview> = [];
    overviewModelIIIArray: Array<ITIIIOverview> = [];

    notStartedStates: Array<string>;
    inProgressStates: Array<string>;
    withoutExStates: Array<string>;
    submittedStates: Array<string>;
    reviewStates: Array<string>;
    returnedStates: Array<string>;
    approvedStates: Array<string>;
    lockedStates: Array<string>;
    notStartedStatesNsip: Array<string>;
    inProgressStatesNsip: Array<string>;
    withoutExStatesNsip: Array<string>;
    submittedStatesNsip: Array<string>;
    reviewStatesNsip: Array<string>;
    returnedStatesNsip: Array<string>;
    approvedStatesNsip: Array<string>;
    lockedStatesNsip: Array<string>;

    notStartedCount: number = 0;
    inProgressCount: number = 0;
    withoutExCount: number = 0;
    submittedCount: number = 0;
    reivewCount: number = 0;
    returnedCount: number = 0;
    approvedCount: number = 0;
    lockedCount: number = 0;
    naCount: number = 0;
    notStartedCountNsip: number = 0;
    inProgressCountNsip: number = 0;
    withoutExCountNsip: number = 0;
    submittedCountNsip: number = 0;
    reivewCountNsip: number = 0;
    returnedCountNsip: number = 0;
    approvedCountNsip: number = 0;
    lockedCountNsip: number = 0;
    naCountNsip: number = 0;

    notStartedCountDisplay: number = 0;
    inProgressCountDisplay: number = 0;
    withoutExCountDisplay: number = 0;
    submittedCountDisplay: number = 0;
    reivewCountDisplay: number = 0;
    returnedCountDisplay: number = 0;
    approvedCountDisplay: number = 0;
    lockedCountDisplay: number = 0;
    naCountDisplay: number = 0;
    totalCountDisplay: number = 0;
    notStartedCountNsipDisplay: number = 0;
    inProgressCountNsipDisplay: number = 0;
    withoutExCountNsipDisplay: number = 0;
    submittedCountNsipDisplay: number = 0;
    reivewCountNsipDisplay: number = 0;
    returnedCountNsipDisplay: number = 0;
    approvedCountNsipDisplay: number = 0;
    lockedCountNsipDisplay: number = 0;
    naCountNsipDisplay: number = 0;
    totalCountNsipDisplay: number = 0;

    files: IFile[];
    @Input() currentFile: IFile;
    previousFile: IFile;
    errorMessage: string;
    regions: string[] = _.uniq(Constants.STATES.map(item => item.region));
    selectedRegion: string = '';
    dashInitialized: boolean = false;
    stateNameLegible: string = '';
    stateNameFormatted: string = '';
    role: string;
    titleVIIUserDefaultStates: UserDefaultStates = new UserDefaultStates();
    titleIIIUserDefaultStates: UserDefaultStates = new UserDefaultStates();
    myStates: string[] = [];
    yearArrayBasic: Array<string> = [];

    systemUpdates: ReportingCalendar[] = [];
    reportingDates: ReportingCalendar[] = [];
    upcomingEvents: ReportingCalendar[] = [];

    contact: TVIRegionalContactDto;
    email: string = '';
    phone: string = '';
    location: string = '';
    name: string = '';

    titleEnum = AclTitleEnum;
    statusEnum = QuestionStatusEnum;
    isSPRReturnComments: boolean;
    titleIIIState: TitleIIIState;
    public granteeIds: string[];
    public filteredGranteeIds: string[];
    public myGranteeDefined: boolean = false;
    public overviewDtos: Array<ITVIOverviewDto> = [];
    public filteredOverviewDtos: Array<ITVIOverviewDto> = [];
    public myGranteesOverviewDtos: Array<ITVIOverviewDto> = [];
    public statusHistory: IStatusHistoryDto = StatusHistoryDto.loading();

    constructor(public zone: NgZone,
        public route: ActivatedRoute,
        public router: Router,
        public _titleService: Title,
        //public _fileService: FileService,
        public _claimService: ClaimService,
        public _lookupService: LookupService,
        public _titleVIIservice: TitleVIIDataEntryService,
        public _titleIIIservice: TitleIIIDataEntryService,
        public _commonService: CommonService,
        public _errorLogger: ErrorLogger,
        public _userService: UserService,
        public _titleVIservice: TitleVIDataEntryService,
        private _granteeService: GranteeService
    ) { super(); }

    ngOnInit(): void {
        this._titleService.setTitle('Dashboard - ACL OAAPS');
        this.initializeValues();
    }

    ngOnDestroy(): void {
        super.destroy();
    }

    initializeValues(): boolean {
        super.registerSubscription(this._claimService.init$.subscribe(isInit => {
            if (isInit) {
                if (!this.dashInitialized && this._claimService.roles.length > 0) {
                    this.setRole();
                    if (this._claimService.currentTitle === 'VII' && this._claimService.currentOrg) {
                        // this.loadingComponent.setLoadingInProgress(60);
                        super.registerSubscription(
                            this._titleVIIservice.getOverviewbyffyandstate(this._claimService.currentYear, this._claimService.currentOrg)
                                .subscribe(data => {
                                    this.overviewModelVII = data;
                                    
                                }));
                    } else if (this._claimService.currentTitle === 'III' && this._claimService.currentOrg) {
                        // this.loadingComponent.setLoadingInProgress(60);
                        this.clearAAADataForState();
                        if (this._claimService.isAAAEnabledStateUser()) {
                            super.registerSubscription(
                                this._titleIIIservice.getRollupOverviewByFfyAndState(
                                    this._claimService.currentYear, this._claimService.currentOrg)
                                    .subscribe(data => {
                                        this.overviewModelIII = data;
                                        // 
                                    }));
                        } else {
                            super.registerSubscription(
                                this._titleIIIservice.getOverviewByFfyAndState(
                                    this._claimService.currentYear, this._claimService.currentOrg, this._claimService.currentSubOrg)
                                    .subscribe(data => {
                                        this.overviewModelIII = data;
                                        
                                    }));
                        }
                    } else if (this._claimService.currentTitle === 'VI' && this._claimService.currentOrgLevel) {
                        // this.loadingComponent.setLoadingInProgress(60);
                        if ((this._claimService.currentOrgLevel === UserManagementStrings.orgLevelACLCO)
                            || (this._claimService.currentOrgLevel === UserManagementStrings.orgLevelACLRO)) {
                            if (this._claimService.currentOrgLevel === UserManagementStrings.orgLevelACLRO) {
                                this.granteeIds = this._claimService.user.groupedRoles
                                    .filter(x => x.title === 'VI' && x.org)
                                    .map(y => y.org);
                                this.regions = _.uniq(this._claimService.user.groupedRoles
                                    .filter(x => x.title === 'VI' && x.region && x.region > 0)
                                    .map(y => y.region.toString()));
                                super.registerSubscription(
                                    this._titleVIservice.getoverviewbyffy(this._claimService.currentYear, this.granteeIds)
                                        .subscribe(
                                            overviews => {
                                                this.filteredOverviewDtos = this.overviewDtos = overviews;
                                                this.filteredGranteeIds = overviews.map(x => x.granteeId);
                                                super.registerSubscription(this._titleVIservice.getUserDefaultGrantees().subscribe(
                                                    data => {
                                                        if (data.length && data[0].defaultGrantees !== null &&
                                                            data[0].defaultGrantees.length) {
                                                            this.filteredOverviewDtos = this.overviewDtos
                                                                .filter(
                                                                    x => x.granteeId && data[0].defaultGrantees &&
                                                                        data[0].defaultGrantees.includes(x.granteeId));
                                                            this.filteredGranteeIds = this.filteredOverviewDtos.map(x => x.granteeId);
                                                            this.myGranteesOverviewDtos = this.filteredOverviewDtos;
                                                            this.myGranteeDefined = true;
                                                        }
                                                        
                                                    }
                                                ));
                                                
                                            }
                                        ));
                            } else {
                                super.registerSubscription(this._granteeService.getGranteeDisplayItems(null).
                                    subscribe(
                                        results => {
                                            this.granteeIds = results.map(x => x.granteeId);
                                            super.registerSubscription(this._titleVIservice.getoverviewbyffy(
                                                this._claimService.currentYear, this.granteeIds)
                                                .subscribe(
                                                    overviews => {
                                                        this.filteredOverviewDtos = this.overviewDtos = overviews;
                                                        this.filteredGranteeIds = overviews.map(x => x.granteeId);
                                                        super.registerSubscription(this._titleVIservice.getUserDefaultGrantees().subscribe(
                                                            data => {
                                                                if (data.length && data[0].defaultGrantees !== null &&
                                                                    data[0].defaultGrantees.length) {
                                                                    this.filteredOverviewDtos = this.overviewDtos
                                                                        .filter(x => x.granteeId && data[0].defaultGrantees &&
                                                                            data[0].defaultGrantees.includes(x.granteeId));
                                                                    this.filteredGranteeIds = this.filteredOverviewDtos.map(
                                                                        x => x.granteeId);
                                                                    this.myGranteesOverviewDtos = this.filteredOverviewDtos;
                                                                    this.myGranteeDefined = true;
                                                                }
                                                                
                                                            }));
                                                        
                                                    }));
                                            
                                        }));
                            }
                        } else if (this._claimService.currentOrgLevel === UserManagementStrings.orgLevelGrantee) {
                            super.registerSubscription(
                                this.ffyDropdownComponent.yearChange.pipe(
                                    switchMap(() =>
                                        this._titleVIservice.getoverviewbyffyandorg(
                                            this._claimService.currentYear, this._claimService.currentOrg)
                                    )).subscribe(
                                        overview => {
                                            if (overview) {
                                                this.filteredOverviewDtos[0] = this.overviewDtos[0] = overview;
                                            }
                                            super.registerSubscription(
                                                this._claimService._lookupService.getRegionalContact(this._claimService.currentOrg)
                                                    .subscribe(
                                                        contact => {
                                                            this.contact = contact;
                                                        }
                                                    ));
                                            if (overview && overview.overview.sectionStatus === QuestionStatusEnum.Returned) {
                                                super.registerSubscription(this._titleVIservice.getLatestStatusHistory(
                                                    this._claimService.currentYear,
                                                    this._claimService.currentOrg,
                                                    QuestionStatusEnum.Returned
                                                ).subscribe(data => { if (data) { this.statusHistory = data; } }));
                                            }
                                            
                                        }
                                    )
                                );
                        }
                        
                    }
                    if (this._claimService.currentRegion !== RegionEnum.CO.toString()) {
                        this.selectedRegion = this._claimService.currentRegion;
                    }
                    if (this._claimService.isNavApprover) {
                        switch (this._claimService.currentTitle) {
                            case 'III': {
                                this.getTitleIIIUserDefaultStatesPreferences();
                                break;
                            }
                            case 'VII': {
                                this.getTitleVIIUserDefaultStatesPreferences();
                                break;
                            }
                        }
                    }
                    // populate the counts
                    if (this._claimService.isACLUser() || this._claimService.isAAAEnabledStateUser()) {
                        this.getStatusCounts();
                    }
                    this.stateName();
                    this.dashInitialized = true;
                    // this.setFiscalYears();
                    this.getLatestDates();
                }
            }
        }));
        return this.dashInitialized;
    }

    private getLatestDates() {

        this.systemUpdates = (<any>systemUpdatesData).default;
        this.reportingDates = (<any>reportingDatesData).default;
        this.upcomingEvents = (<any>eventsData).default;

        this.systemUpdates = this.systemUpdates.filter(x => x.title === this._claimService.currentTitle)
            .sort((left, right) => left.reportingDate < right.reportingDate ? 1 : -1).slice(0, 3); // top 3

        this.reportingDates = this.reportingDates.filter(x => new Date(x.reportingDate) >= new Date()
            && x.title === this._claimService.currentTitle)
            .sort((left, right) => left.order > right.order ? 1 : -1).slice(0, 3); // the upcoming 3

        this.upcomingEvents = this.upcomingEvents.filter(x => new Date(x.reportingDate) >= new Date()
            && x.title === this._claimService.currentTitle)
            .sort((left, right) => left.reportingDate > right.reportingDate ? 1 : -1).slice(0, 3); // top 3
    }
    getStatusCounts(): void {
        // start loading spinner (ends when last state is counted)
        // this.loadingComponent.setLoadingInProgress(180);
        if (this._claimService.currentTitle === 'VII') {
            super.registerSubscription(this._titleVIIservice.getOverviewByFfy(this._claimService.currentYear)
                .subscribe(data => {
                    data.forEach(item => this.incrementT7StatusCounts(item));
                    this.filterRegions();
                    
                }));
        } else if (this._claimService.currentTitle === 'III') {
            if (this._claimService.isAAAEnabledStateUser()) {
                super.registerSubscription(this._titleIIIservice.getAAAOverviewByFfyAndState(
                    this._claimService.currentYear, this._claimService.currentOrg)
                    .subscribe(data => {
                        this.incrementT3StatusCounts(data);
                        this.filterRegions();
                        
                    }));

            } else {
                super.registerSubscription(this._titleIIIservice.getOverviewByFfy(this._claimService.currentYear)
                    .subscribe(data => {
                        this.incrementT3StatusCounts(data);
                        this.filterRegions();
                        
                    }));
            }
        }
    }

    incrementT7StatusCounts(overview: ITVIIOverview): void {
        this.overviewModelVIIArray.push(overview);
        switch (overview.overviewStatus) {
            case QuestionStatusEnum.NotStarted:
                this.notStartedCount++;
                break;
            case QuestionStatusEnum.L1InProgress:
                this.inProgressCount++;
                break;
            case QuestionStatusEnum.SubmittedNoExplanation:
                this.withoutExCount++;
                break;
            case QuestionStatusEnum.Submitted:
                this.submittedCount++;
                break;
            case QuestionStatusEnum.InReview:
                this.reivewCount++;
                break;
            case QuestionStatusEnum.Returned:
                this.returnedCount++;
                break;
            case QuestionStatusEnum.Approved:
                this.approvedCount++;
                break;
            case QuestionStatusEnum.Locked:
                this.lockedCount++;
                break;
            default:
                this.inProgressCount++;
        }
    }

    incrementT3StatusCounts(data: Array<ITIIIOverview>): void {        
        if(data){
            this.overviewModelIIIArray = data;
            data.forEach(overview => {
                switch (overview.sprStatus) {
                    case QuestionStatusEnum.NotStarted:
                        this.notStartedCount++;
                        break;
                    case QuestionStatusEnum.L1InProgress:
                        this.inProgressCount++;
                        break;
                    case QuestionStatusEnum.SubmittedNoExplanation:
                        this.withoutExCount++;
                        break;
                    case QuestionStatusEnum.Submitted:
                        this.submittedCount++;
                        break;
                    case QuestionStatusEnum.InReview:
                        this.reivewCount++;
                        break;
                    case QuestionStatusEnum.Returned:
                        this.returnedCount++;
                        break;
                    case QuestionStatusEnum.Approved:
                        this.approvedCount++;
                        break;
                    case QuestionStatusEnum.Locked:
                        this.lockedCount++;
                        break;
                    default:
                        this.inProgressCount++;
                }
                switch (overview.nsipStatus) {
                    case QuestionStatusEnum.NotStarted:
                        this.notStartedCountNsip++;
                        break;
                    case QuestionStatusEnum.L1InProgress:
                        this.inProgressCountNsip++;
                        break;
                    case QuestionStatusEnum.SubmittedNoExplanation:
                        this.withoutExCountNsip++;
                        break;
                    case QuestionStatusEnum.Submitted:
                        this.submittedCountNsip++;
                        break;
                    case QuestionStatusEnum.InReview:
                        this.reivewCountNsip++;
                        break;
                    case QuestionStatusEnum.Returned:
                        this.returnedCountNsip++;
                        break;
                    case QuestionStatusEnum.Approved:
                        this.approvedCountNsip++;
                        break;
                    case QuestionStatusEnum.Locked:
                        this.lockedCountNsip++;
                        break;
                    default:
                        this.inProgressCountNsip++;
                }
            });
        this.filterRegions();
    }
        
    }

    initializeCounts(): void {
        // t7 & t3 spr
        this.notStartedCount = 0;
        this.inProgressCount = 0;
        this.withoutExCount = 0;
        this.submittedCount = 0;
        this.reivewCount = 0;
        this.returnedCount = 0;
        this.approvedCount = 0;
        this.lockedCount = 0;
        this.inProgressCount = 0;
        this.naCount = 0;
        // t3 nsip
        this.notStartedCountNsip = 0;
        this.inProgressCountNsip = 0;
        this.withoutExCountNsip = 0;
        this.submittedCountNsip = 0;
        this.reivewCountNsip = 0;
        this.returnedCountNsip = 0;
        this.approvedCountNsip = 0;
        this.lockedCountNsip = 0;
        this.inProgressCountNsip = 0;
        this.naCountNsip = 0;
    }

    filterRegions(): void {
        // this.loadingComponent.setLoadingInProgress(60);
        this.initializeCounts();
        const stateList: string[] = [];
        let stateListLength: number = 0;
        // t7
        if (this._claimService.currentTitle === 'VII') {
            Constants.T7STATES.forEach(State => {
                // "" is national so add all states, otherwise add states by selected region
                if (this.selectedRegion === '' || State.region === this.selectedRegion) {
                    stateList.push(State.abbr);
                    stateListLength = stateList.length;
                }
                if (this.selectedRegion === 'myStates' && this.myStates.includes(State.abbr)) {
                    stateList.push(State.abbr);
                    stateListLength = stateList.length;
                }
            });
        }
        // t3
        if (this._claimService.currentTitle === 'III') {
            // AAA state user
            if (!this._claimService.isNavApprover && this._claimService.titleIIIState) {
                this._claimService.titleIIIState.aaa.forEach(element => {
                    stateList.push(element.psa);
                    stateListLength = stateList.length;
                });
            }
            // ACL user
            if (this._claimService.isNavApprover) {
                Constants.STATES.forEach(State => {
                    // "" is national so add all states, otherwise add states by selected region
                    if (this.selectedRegion === '' || State.region === this.selectedRegion) {
                        stateList.push(State.abbr);
                        stateListLength = stateList.length;
                    }
                    if (this.selectedRegion === 'myStates' && this.myStates.includes(State.abbr)) {
                        stateList.push(State.abbr);
                        stateListLength = stateList.length;
                    }
                });
            }
            stateList.forEach(state => {
                stateListLength--;
                let tempOverviewModelIII = new TIIIOverview;
                if (this._claimService.isNavApprover) {
                    tempOverviewModelIII = this.overviewModelIIIArray.filter(model => model.state === state)[0];
                } else {
                    tempOverviewModelIII = this.overviewModelIIIArray.filter(model => model.psa === state)[0];
                }
                if (tempOverviewModelIII.sprIsRequired) {
                    switch (tempOverviewModelIII.sprStatus) {
                        case QuestionStatusEnum.NotStarted:
                            this.notStartedCount++;
                            break;
                        case QuestionStatusEnum.L1InProgress:
                            this.inProgressCount++;
                            break;
                        case QuestionStatusEnum.SubmittedNoExplanation:
                            this.withoutExCount++;
                            break;
                        case QuestionStatusEnum.Submitted:
                            this.submittedCount++;
                            break;
                        case QuestionStatusEnum.InReview:
                            this.reivewCount++;
                            break;
                        case QuestionStatusEnum.Returned:
                            this.returnedCount++;
                            break;
                        case QuestionStatusEnum.Approved:
                            this.approvedCount++;
                            break;
                        case QuestionStatusEnum.Locked:
                            this.lockedCount++;
                            break;
                        default:
                            this.inProgressCount++;
                    }
                } else {
                    this.naCount++;
                }
                if (tempOverviewModelIII.nsipIsRequired) {
                    switch (tempOverviewModelIII.nsipStatus) {
                        case QuestionStatusEnum.NotStarted:
                            this.notStartedCountNsip++;
                            break;
                        case QuestionStatusEnum.L1InProgress:
                            this.inProgressCountNsip++;
                            break;
                        case QuestionStatusEnum.SubmittedNoExplanation:
                            this.withoutExCountNsip++;
                            break;
                        case QuestionStatusEnum.Submitted:
                            this.submittedCountNsip++;
                            break;
                        case QuestionStatusEnum.InReview:
                            this.reivewCountNsip++;
                            break;
                        case QuestionStatusEnum.Returned:
                            this.returnedCountNsip++;
                            break;
                        case QuestionStatusEnum.Approved:
                            this.approvedCountNsip++;
                            break;
                        case QuestionStatusEnum.Locked:
                            this.lockedCountNsip++;
                            break;
                        default:
                            this.inProgressCountNsip++;
                    }
                } else {
                    this.naCountNsip++;
                }
                // end loading on last state
                if (stateListLength === 0) {
                    this.notStartedCountDisplay = this.notStartedCount;
                    this.inProgressCountDisplay = this.inProgressCount;
                    this.withoutExCountDisplay = this.withoutExCount;
                    this.submittedCountDisplay = this.submittedCount;
                    this.reivewCountDisplay = this.reivewCount;
                    this.returnedCountDisplay = this.returnedCount;
                    this.approvedCountDisplay = this.approvedCount;
                    this.lockedCountDisplay = this.lockedCount;
                    this.naCountDisplay = this.naCount;
                    this.notStartedCountNsipDisplay = this.notStartedCountNsip;
                    this.inProgressCountNsipDisplay = this.inProgressCountNsip;
                    this.withoutExCountNsipDisplay = this.withoutExCountNsip;
                    this.submittedCountNsipDisplay = this.submittedCountNsip;
                    this.reivewCountNsipDisplay = this.reivewCountNsip;
                    this.returnedCountNsipDisplay = this.returnedCountNsip;
                    this.approvedCountNsipDisplay = this.approvedCountNsip;
                    this.lockedCountNsipDisplay = this.lockedCountNsip;
                    this.naCountNsipDisplay = this.naCountNsip;
                    // get totals
                    this.totalCountDisplay = (this.notStartedCountDisplay
                        + this.inProgressCountDisplay
                        + this.withoutExCountDisplay
                        + this.submittedCountDisplay
                        + this.reivewCountDisplay
                        + this.returnedCountDisplay
                        + this.approvedCountDisplay
                        + this.lockedCountDisplay
                        + this.naCountDisplay);
                    this.totalCountNsipDisplay = (this.notStartedCountNsipDisplay
                        + this.inProgressCountNsipDisplay
                        + this.withoutExCountNsipDisplay
                        + this.submittedCountNsipDisplay
                        + this.reivewCountNsipDisplay
                        + this.returnedCountNsipDisplay
                        + this.approvedCountNsipDisplay
                        + this.lockedCountNsipDisplay
                        + this.naCountNsipDisplay);
                    
                }
            });
        }
        // t7
        if (this._claimService.currentTitle === 'VII') {
            stateList.forEach(state => {
                const tempOverviewModelVII = this.overviewModelVIIArray.filter(model => model.state === state)[0];
                stateListLength--;

                switch (tempOverviewModelVII.overviewStatus) {
                    case QuestionStatusEnum.NotStarted:
                        this.notStartedCount++;
                        break;
                    case QuestionStatusEnum.L1InProgress:
                        this.inProgressCount++;
                        break;
                    case QuestionStatusEnum.SubmittedNoExplanation:
                        this.withoutExCount++;
                        break;
                    case QuestionStatusEnum.Submitted:
                        this.submittedCount++;
                        break;
                    case QuestionStatusEnum.InReview:
                        this.reivewCount++;
                        break;
                    case QuestionStatusEnum.Returned:
                        this.returnedCount++;
                        break;
                    case QuestionStatusEnum.Approved:
                        this.approvedCount++;
                        break;
                    case QuestionStatusEnum.Locked:
                        this.lockedCount++;
                        break;
                    default:
                        this.inProgressCount++;
                }

                // end loading on last state
                if (stateListLength === 0) {
                    this.notStartedCountDisplay = this.notStartedCount;
                    this.inProgressCountDisplay = this.inProgressCount;
                    this.withoutExCountDisplay = this.withoutExCount;
                    this.submittedCountDisplay = this.submittedCount;
                    this.reivewCountDisplay = this.reivewCount;
                    this.returnedCountDisplay = this.returnedCount;
                    this.approvedCountDisplay = this.approvedCount;
                    this.lockedCountDisplay = this.lockedCount;
                    // get total
                    this.totalCountDisplay = (this.notStartedCountDisplay
                        + this.inProgressCountDisplay
                        + this.withoutExCountDisplay
                        + this.submittedCountDisplay
                        + this.reivewCountDisplay
                        + this.returnedCountDisplay
                        + this.approvedCountDisplay
                        + this.lockedCountDisplay);
                    
                }
            });
        }
        // Title VI
        if (this._claimService.currentTitle === 'VI') {
            if (this.selectedRegion === 'all') {
                this.filteredOverviewDtos = this.overviewDtos;
                this.filteredGranteeIds = this.filteredOverviewDtos.map(x => x.granteeId);
            } else if (this.selectedRegion === 'myGrantees') {
                this.filteredOverviewDtos = this.myGranteesOverviewDtos;
                this.filteredGranteeIds = this.filteredOverviewDtos.map(x => x.granteeId);
            } else {
                this.filteredOverviewDtos = this.overviewDtos.filter(x => x.region === this.selectedRegion);
                this.filteredGranteeIds = this.filteredOverviewDtos.map(x => x.granteeId);
            }
            
        }
    }

    /**
     * MVP leaving as example
     * @deprecated
     * @param result
     */
    fileHandler(result: IFile): void {
        this.currentFile = result;

        switch (result.Status) {
            case 0:
                this.statusTxt = 'Not Started';
                this.enterViewDataTxt = 'Enter data manually';
                break;
            case 1:
                this.statusTxt = 'In Progress';
                this.enterViewDataTxt = 'Enter data manually';
                break;
            case 2:
                this.statusTxt = 'Submitted';
                this.enterViewDataTxt = 'View data';
                break;
            case 3:
                this.statusTxt = 'Returned In Progress';
                this.enterViewDataTxt = 'Enter data manually';
                break;
            case 4:
                this.statusTxt = 'Approved';
                this.enterViewDataTxt = 'View data';
                break;
            case 5:
                this.statusTxt = 'Locked';
                this.enterViewDataTxt = 'View data';
                break;
            default:
                this.statusTxt = 'Unknown';
                this.enterViewDataTxt = 'Enter data manually';
        }
    }
    /**
     * MVP leaving as example
     * @deprecated
     * @param result
     */
    previousFileHandler(result: IFile): void {
        this.previousFile = result;

        switch (result.Status) {
            case 0:
                this.previousStatusTxt = 'Not Started';
                break;
            case 1:
                this.previousStatusTxt = 'In Progress';
                break;
            case 2:
                this.previousStatusTxt = 'Submitted';
                break;
            case 3:
                this.previousStatusTxt = 'Returned In Progress';
                break;
            case 4:
                this.previousStatusTxt = 'Approved';
                break;
            case 5:
                this.previousStatusTxt = 'Locked';
                break;
            default:
                this.previousStatusTxt = 'Unknown';
        }
    }

    // Orphan
    // Security Scan remediation (remove DELETE HTTP verb)
    // deleteFile(): void {
    //     const confirmDelete = confirm('Are you sure you wish to delete this file?');
    //     if (confirmDelete) {
    //         this._fileService.deleteFile(this.currentFile.Id)
    //             .then(result => this.checkDeleted(result.toString()));
    //     }
    // }

    // Re-getting the file to see if it exists due to promise sometimes returning deleted
    // when server actually didn't complete the delete
    // checkDeleted(result: string): void {
    //     super.registerSubscription(this._fileService.getFileByYearAndState(this._claimService.currentYear, this._claimService.currentOrg)
    //         .subscribe(file => this.zone.run(() => this.showDeleteMessages(file))));
    // }

    showDeleteMessages(file: IFile): void {
        this.currentFile = file;

        if (this.currentFile.Id == null) {
            this.statusTxt = 'Not Started';
        }
    }

    onUpdate(): void {
        this.router.navigate(['/file/create']);
    }

    setRole(): void {
        if (this._claimService.isNavStateSubmission && this._claimService.currentTitle === 'III') {
            this.role = 'T3DataEntry';
        }
        if (this._claimService.isNavStateSubmission && this._claimService.currentTitle === 'VII') {
            this.role = 'T7DataEntry';
        }
        if (!this._claimService.isNavStateSubmission && this._claimService.currentTitle === 'III') {
            this.role = 'T3ACL';
        }
        if (!this._claimService.isNavStateSubmission && this._claimService.currentTitle === 'VII') {
            this.role = 'T7ACL';
        }
    }

    showReturnedComments(): void {
        if (this.isSPRReturnComments) {
            super.registerSubscription(this._titleIIIservice.getYearApprovalStatusByFfyAndState(
                this._claimService.currentYear, this._claimService.currentOrg, this._claimService.currentSubOrg,
                this._claimService.isAAAEnabledStateUser(), 'SPR').subscribe(data => {
                    if (data) {
                        this.returnCommentModel = data.sort((a, b) => a.lastModified.localeCompare(b.lastModified)).reverse()
                            .find(x => x.decision === ApprovalDecision.Returned);
                    }
                }));
        } else {
            super.registerSubscription(this._titleIIIservice.getYearApprovalStatusByFfyAndState(
                this._claimService.currentYear, this._claimService.currentOrg, this._claimService.currentSubOrg,
                this._claimService.isAAAEnabledStateUser(), 'NSIP').subscribe(data => {
                    if (data) {
                        this.returnCommentModel = data.sort((a, b) => a.lastModified.localeCompare(b.lastModified)).reverse()
                            .find(x => x.decision === ApprovalDecision.Returned);
                    }
                }));
        }
        jQuery('#returnCommentsModal').modal('show');
    }

    hideReturnedComments(): void {
        jQuery('#returnCommentsModal').modal('hide');
    }

    stateName(): void {
        AllStates.forEach(state => {
            if (state.abbreviation === this._claimService.currentOrg) {
                this.stateNameLegible = state.name;
                this.stateNameFormatted = state.name.toLowerCase().replace(/ /g, '-');
            }
        });
    }

    getTitleVIIUserDefaultStatesPreferences(): void {
        super.registerSubscription(this._titleVIIservice.getUserDefaultStates()
            .subscribe(data => {
                if (data.length > 0) {
                    this.titleVIIUserDefaultStates.id = data[0].id;
                    this.titleVIIUserDefaultStates.upn = data[0].upn;
                    this.titleVIIUserDefaultStates.defaultStates = data[0].defaultStates;
                    if (this.titleVIIUserDefaultStates.defaultStates.length > 0) {
                        this.selectedRegion = 'myStates';
                        this.loadMyT7States(this.titleVIIUserDefaultStates.defaultStates);
                    }
                }
            }));
    }

    loadMyT7States(states: string[]): void {
        // national
        if (states.includes('national')) {
            this.myStates = Constants.T7STATES.map(item => item.abbr);
        }
        // regional
        if (states.toString().search('region') !== -1) {
            for (const region of states) {
                const regionNumber = region.replace('region ', '');
                Constants.T7STATES.forEach(State => {
                    if (State.region === regionNumber) {
                        this.myStates.push(State.abbr);
                    }
                });
            }
        }
        // state
        if (states.length > 0 && !states.includes('national') && states.toString().search('region') === -1) {
            Constants.T7STATES.forEach(State => {
                if (states.includes(State.abbr)) {
                    this.myStates.push(State.abbr);
                }
            });
        }
    }

    getTitleIIIUserDefaultStatesPreferences(): void {
        super.registerSubscription(this._titleIIIservice.getUserDefaultStates()
            .subscribe(data => {
                if (data.length > 0) {
                    this.titleIIIUserDefaultStates.id = data[0].id;
                    this.titleIIIUserDefaultStates.upn = data[0].upn;
                    this.titleIIIUserDefaultStates.defaultStates = data[0].defaultStates;
                    if (this.titleIIIUserDefaultStates.defaultStates.length > 0) {
                        this.selectedRegion = 'myStates';
                        this.loadMyT3States(this.titleIIIUserDefaultStates.defaultStates);
                    }
                }
            }));
    }

    loadMyT3States(states: string[]): void {
        // national
        if (states.includes('national')) {
            this.myStates = Constants.STATES.map(item => item.abbr);
        }
        // regional
        if (states.toString().search('region') !== -1) {
            for (const region of states) {
                const regionNumber = region.replace('region ', '');
                Constants.STATES.forEach(State => {
                    if (State.region === regionNumber) {
                        this.myStates.push(State.abbr);
                    }
                });
            }
        }
        // state
        if (states.length > 0 && !states.includes('national') && states.toString().search('region') === -1) {
            Constants.STATES.forEach(State => {
                if (states.includes(State.abbr)) {
                    this.myStates.push(State.abbr);
                }
            });
        }
    }
    getTitleIIIState(state: string) {
        super.registerSubscription(this._lookupService.getTitleIIIState(state).subscribe(data => {
            this.titleIIIState = data;
        }));
    }
    goToResource(resource: string) {
        if (this._claimService.resourceTitle === '') {
            this._claimService.resourceTitle = localStorage.getItem('title');
        }
        if (resource === 'reportingPeriod') {
            this.router.navigate(['/Resources/reporting-calendar/reporting-calendar']);
        } else if (resource === 'events') {
            this.router.navigate(['/Resources/events']);
        }
    }

    clearAAADataForState() {
        if (this.role === 'T3DataEntry' && !this._claimService.isAAAUser) {
            this._claimService.resetAAAProperties();
        }
    }

    public setRegion(region: string, shouldFilter: boolean = true) {
        this.selectedRegion = region;
        if (shouldFilter) {
            this.filterRegions();
        }
    }

    public titleVIpartABC(overviewDtos: ITVIOverviewDto[]) {
        if (overviewDtos.length) {
            const part = 'Part ';
            const partabc = [];
            for (let i = 0; i < overviewDtos.length; i++) {
                if (overviewDtos[i].overview.children.find(x => x.sectionName === 'ABOverview' && x.isRequired)) {
                    partabc.push('A', 'B');
                    break;
                }
            }
            for (let i = 0; i < overviewDtos.length; i++) {
                if (overviewDtos[i].overview.children.find(x => x.sectionName === 'COverview' && x.isRequired)) {
                    partabc.push('C');
                    break;
                }
            }
            return part + partabc.join('/');
        } else {
            return 'N/A';
        }
    }

    public titleVIGranteeReportingStatus(overviewDtos: ITVIOverviewDto[]) {
        if (this._claimService.currentOrgLevel === UserManagementStrings.orgLevelGrantee && overviewDtos.length) {
            return this._commonService.overallStatus(overviewDtos[0].overview.sectionStatus);
        }
        return Constants.CODES.reportingNotPermitted;
    }

    public titleVIGranteeLastModified(overviewDtos: ITVIOverviewDto[]) {
        if (this._claimService.currentOrgLevel === UserManagementStrings.orgLevelGrantee && overviewDtos.length) {
            return overviewDtos[0].overview.lastModified;
        }
    }
    public titleVIGranteeUpdatedBy(overviewDtos: ITVIOverviewDto[]) {
        if (this._claimService.currentOrgLevel === UserManagementStrings.orgLevelGrantee && overviewDtos.length) {
            return overviewDtos[0].overview.updatedBy;
        }
    }

    public titleVIAclUserStatusCount(overviewDtos: ITVIOverviewDto[]) {
        const statusCount = [];
        if ((this._claimService.currentOrgLevel === UserManagementStrings.orgLevelACLCO ||
            this._claimService.currentOrgLevel === UserManagementStrings.orgLevelACLRO)) {
            for (let i = 0; i < overviewDtos.length; i++) {
                overviewDtos[i].overview.sectionStatus = this.resolveACLStatus(overviewDtos[i].overview.sectionStatus);
            }
            statusCount.push(overviewDtos.filter(x => x.overview.sectionStatus === QuestionStatusEnum.NotStarted).length);
            statusCount.push(overviewDtos.filter(x => x.overview.sectionStatus === QuestionStatusEnum.L1InProgress).length);
            statusCount.push(overviewDtos.filter(x => x.overview.sectionStatus === QuestionStatusEnum.Submitted).length);
            statusCount.push(overviewDtos.filter(x => x.overview.sectionStatus === QuestionStatusEnum.InReview).length);
            statusCount.push(overviewDtos.filter(x => x.overview.sectionStatus === QuestionStatusEnum.Returned).length);
            statusCount.push(overviewDtos.filter(x => x.overview.sectionStatus === QuestionStatusEnum.Approved).length);
            statusCount.push(overviewDtos.filter(x => x.overview.sectionStatus === QuestionStatusEnum.Locked).length);
            statusCount.push(overviewDtos.length);
        }
        return statusCount;
    }

    resolveACLStatus(status: number): number {
        if (status > QuestionStatusEnum.NotStarted && status < QuestionStatusEnum.Returned) {
            return QuestionStatusEnum.L1InProgress;
        } else if (status > QuestionStatusEnum.Returned && status < QuestionStatusEnum.InReview) {
            return QuestionStatusEnum.Submitted;
        } else {
            return status;
        }
    }

    public get tviDashboardModel(): TviDashboardModel {
        return {
            fullName: this._claimService.currentTitleOrg.fullName,
            currentYear: this._claimService.currentYear,
            currentOrgLevel: this._claimService.currentOrgLevel,
            regions: this.regions, systemUpdates: this.systemUpdates,
            reportingDates: this.reportingDates,
            upcomingEvents: this.upcomingEvents,
            pprStatusCount: this.titleVIAclUserStatusCount(this.filteredOverviewDtos),
            part: this.titleVIpartABC(this.filteredOverviewDtos),
            granteeReportingStatus: this.titleVIGranteeReportingStatus(this.filteredOverviewDtos),
            granteeLastModified: this.titleVIGranteeLastModified(this.filteredOverviewDtos),
            granteeUpdatedBy: this.titleVIGranteeUpdatedBy(this.filteredOverviewDtos),
            statusHistory: this.statusHistory,
            granteeIds: this.filteredGranteeIds,
            myGranteeDefined: this.myGranteeDefined,
        };
    }

    public get isTitleVI(): boolean {
        return this._claimService.currentTitle === this.titleEnum[this.titleEnum.VI];
    }

}
