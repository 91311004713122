import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { TitleIIIAAA, TitleIIIState } from './DataSubmissions/titleIIIState';
import { TitleVIIState } from './StateSubmissions/titleVIIState';
import { ITitleState } from './shared/iTitleState';
import { ITitleOrg } from './UserManagement/userManagerObjects';
import { IGrantee, GranteeDetailsDto, TVIRegionalContactDto, IGranteeState } from './TitleVI/model/IGrantee';
import { CacheService } from './shared/cache-service';

@Injectable()
export class LookupService {
    readonly ResourceGetTitleIIIState = 'api/titleiii/state/{abbr}'; // state/{abbr}
    readonly ResourceGetEnabledTitleIIIState = 'api/titleiii/state/enabled';
    readonly ResourceGetAllTitleIIIState = 'api/titleiii/state';
    readonly ResourceGetTitleIIIStateAbbrs = 'api/titleiii/state/abbrs';
    readonly ResourceGetTitleIIIStateDisplay = 'api/titleiii/state/display';

    readonly ResourceGetTitleVIIState = 'api/titlevii/state'; // state/{abbr}
    readonly ResourceGetEnabledTitleVIIState = 'api/titlevii/state/enabled';
    readonly ResourceGetAllTitleVIIState = 'api/titlevii/state';
    readonly ResourceGetTitleVIIStateAbbrs = 'api/titlevii/state/abbrs';
    readonly ResourceGetTitleVIIStateDisplay = 'api/titlevii/state/display';

    readonly ResourceGetGranteeDisplayNames = 'api/v1/titlevi/TitleVIGrantee/display';
    readonly GranteeApiRoot = 'api/v1/titlevi/TitleVIGrantee';

    constructor(private _http: HttpClient, private cacheService: CacheService) {
    }

    // Title III

    getTitleIIIState(abbr: string): Observable<TitleIIIState> {
        const url = this.ResourceGetTitleIIIState.replace('{abbr}', abbr);
        const cacheKey =`${this.cacheService.cacheIIIStatePre}${abbr}`;
        let state = this.cacheService.getCacheItem(cacheKey) as TitleIIIState;
        return state
            ? of(state)
            : this._http.get<TitleIIIState>(url, { withCredentials: true }).pipe(
                tap(s => this.cacheService.putCacheItem(cacheKey, s)),
                catchError(this.handleError<TitleIIIState>()
            ));
    }

    getTitleIIIAAA(abbr: string, psa: string): Observable<TitleIIIAAA> {
        return this.getTitleIIIState(abbr).pipe(
            map(state => state.aaa.find(a => a.psa === psa))
        );
    }

    getEnabledTitleIIIStates(): Observable<Array<string>> {
        const url = this.ResourceGetEnabledTitleIIIState;
        return this._http.get<Array<string>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<string>>())
        );
    }

    getAllTitleIIIStates(): Observable<Array<TitleIIIState>> {
        const url = this.ResourceGetAllTitleIIIState;
        return this._http.get<Array<TitleIIIState>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<TitleIIIState>>())
        );
    }

    getTitleIIIStateAbbrs(): Observable<Array<string>> {
        const url = this.ResourceGetTitleIIIStateAbbrs;
        return this._http.get<Array<string>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<string>>())
        );
    }

    getTitleIIIStateDisplayItems(onlyEnabled: boolean): Observable<Array<ITitleOrg>> {
        const url = this.ResourceGetTitleIIIStateDisplay;
        const options = { withCredentials: true, params: { onlyEnabled: onlyEnabled.toString() } };
        return this._http.get<Array<ITitleOrg>>(url, options).pipe(
            map(arr => arr.sort((a, b) => a.displayName > b.displayName ? 1 : -1)),
            catchError(this.handleError<Array<ITitleOrg>>())
        );
    }

    getTitleVIIState(abbr: string): Observable<TitleVIIState> {
        const url = this.ResourceGetTitleVIIState;
        const options = { withCredentials: true, search: 'abbr='.concat(abbr) };
        return this._http.get<TitleVIIState>(url, options).pipe(
            catchError(this.handleError<TitleVIIState>())
        );
    }

    getEnabledTitleVIIStates(): Observable<Array<string>> {
        const url = this.ResourceGetEnabledTitleVIIState;
        return this._http.get<Array<string>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<string>>())
        );
    }

    getAllTitleVIIStates(): Observable<Array<TitleVIIState>> {
        const url = this.ResourceGetAllTitleVIIState;
        return this._http.get<Array<TitleVIIState>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<TitleVIIState>>())
        );
    }

    getTitleVIIStateAbbrs(): Observable<Array<string>> {
        const url = this.ResourceGetTitleVIIStateAbbrs;
        return this._http.get<Array<string>>(url, { withCredentials: true }).pipe(
            map(arr => arr.sort()),
            catchError(this.handleError<Array<string>>()
            ));
    }

    getTitleVIIStateDisplayItems(): Observable<Array<ITitleOrg>> {
        const url = this.ResourceGetTitleVIIStateDisplay;
        return this._http.get<Array<ITitleOrg>>(url, { withCredentials: true }).pipe(
            map(arr => arr.sort((a, b) => a.displayName > b.displayName ? 1 : -1)),
            catchError(this.handleError<Array<ITitleOrg>>())
        );
    }

    // Title VI
    getGranteeDisplayItems(ids: string[]): Observable<Array<ITitleOrg>> {
        const url = this.ResourceGetGranteeDisplayNames;
        return this._http.post<Array<ITitleOrg>>(url, ids, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<ITitleOrg>>())
        );
    }

    getGrantee(id: string): Observable<IGrantee> {
        const url = `${this.GranteeApiRoot}/${id}`;
        return this._http.get<IGrantee>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<IGrantee>())
        );
    }

    getGranteeStates(id: string): Observable<Array<IGranteeState>> {
        const url = `${this.GranteeApiRoot}/grantee-states`;

        return this._http.get<Array<IGranteeState>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<IGranteeState>>())
        );
    }

    getGranteeDetails(ffy: string, id: string): Observable<GranteeDetailsDto> {
        const url = `${this.GranteeApiRoot}/details/${ffy}/${id}`;
        return this._http.get<GranteeDetailsDto>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<GranteeDetailsDto>())
        );
    }

    getRegionalContact(id: string): Observable<TVIRegionalContactDto> {
        const url = `${this.GranteeApiRoot}/regional-contact/${id}`;
        return this._http.get<TVIRegionalContactDto>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<TVIRegionalContactDto>())
        );
    }

    // Helper

    getTitleStates(title: string): Observable<Array<ITitleState>> {
        switch (title) {
            case ('VII'):
                return this.getAllTitleVIIStates();
            case ('III'):
                return this.getAllTitleIIIStates();
            case ('VI'):
                return this.getAllTitleIIIStates();
        }
    }

    // Global
    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
