import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class InfoAndAssistanceSection extends Section {

    estimatedAudienceSize: IDataElement;
    totalServiceUnits: IDataElement;
    expend: IDataElement;
    expendOtherState: IDataElement;
    expendOtherNonState: IDataElement;
    programIncome: IDataElement;
    expendTotal: IDataElement;
    totalExpendPerUnit: IDataElement;

    constructor() {
        super();

        this.estimatedAudienceSize = new DataElement();
        this.totalServiceUnits = new DataElement();
        this.expend = new DataElement();
        this.expendOtherState = new DataElement();
        this.expendOtherNonState = new DataElement();
        this.programIncome = new DataElement();
        this.expendTotal = new DataElement();
        this.totalExpendPerUnit = new DataElement();

    }
}
