import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TitleIIIDataEntryService } from '../DataSubmissions/titleIIIDataEntry.service';
import { TitleVIIDataEntryService } from '../StateSubmissions/titleVIIDataEntry.service';
import { TitleVIDataEntryService } from '../TitleVI/titleVIDataEntry.service';
import { ClaimService } from './claim.service';
import { TIIIStatuses } from './tiiiStatuses';

@Injectable({
  providedIn: 'root'
})
export class StatusLoaderService {

  constructor(
    private claimService: ClaimService,
    private iiiDataService: TitleIIIDataEntryService,
    private viDataService: TitleVIDataEntryService,
    private viiDataService: TitleVIIDataEntryService
    ) { }

    public statusInitialized$(): Observable<boolean> {
      return this.claimService
        .init$.pipe(
          switchMap(() => {
            if (this.claimService.statusesLoaded) {
              return of(true);
            } else {
              if (this.claimService.currentTitle === 'III') {
                return this.iiiDataService.getOverviewByFfyAndState(
                  this.claimService.currentYear, this.claimService.currentOrg, this.claimService.currentSubOrg)
                  .pipe(
                    switchMap(data => {
                      this.claimService.tiiiStatuses = new TIIIStatuses();
                      this.claimService.tiiiStatuses.sprStatus = data.sprStatus;
                      this.claimService.tiiiStatuses.nsipStatus = data.nsipStatus;
                      this.claimService.tiiiStatuses.setTitleIIIStatuses(data);
                      return of(true);
                    })
                  );
              } else if (this.claimService.currentTitle === 'VII') {
                return this.viiDataService.getOverviewbyffyandstate(this.claimService.currentYear, this.claimService.currentOrg)
                  .pipe(
                    switchMap(data => {
                      this.claimService.tviiStatuses.setTitleVIIStatuses(data);
                      return of(true);
                    })
                  );
              } else {
                return of(false);
              }
            }
          }),
          catchError(() => of(false))
        )
    }
}
