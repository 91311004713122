import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { OAuthCallbackComponent } from './auth/login-callback/oauthCallback.component';
import { OAuthCallbackHandler } from './auth/login-callback/oauthCallback.guard';
import { ModuleWithProviders } from '@angular/core';

import { LogoutComponent } from './auth/login/logout.component';
import { AuthenticationGuard } from './auth/service/authenticated.guard';
import { DashboardComponent } from './home/dashboard.component';
import { WelcomeComponent } from './home/welcome.component';
import { AboutComponent } from './home/about/about.component';
import { SystemUpdatesComponent } from './home/about/systemUpdates.component';
import { TitleVIISubmissionLogComponent } from './StateSubmissions/titleVIISubmissionLog.component';
import { ValidationErrorsComponent } from './shared/Errors/validationErrors.component';

const appRoutes: Routes = [
    { path: 'jwt', component: OAuthCallbackComponent, canActivate: [OAuthCallbackHandler] },
    { path: 'welcome', component: WelcomeComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard] },
    { path: 'dashboard/titleVIISubmissionLog', component: TitleVIISubmissionLogComponent },
    { path: 'state-submissions', loadChildren: () => import('./StateSubmissions/title-vii.module').then(m => m.TitleViiModule) },
    { path: 'data-submissions', loadChildren: () => import('./DataSubmissions/title-iii.module').then(m => m.TitleIiiModule) },
    {
        path: 'UserManagement', loadChildren:
         () => import('./UserManagement/oaaps-user-management.module').then(m => m.OaapsUserManagementModule) 
    },
    {
        path: 'granteeManagement', loadChildren:
            () => import('./TitleVI/granteeManagement/oaaps-grantee-management.module').then(m => m.OaapsGranteeManagementModule)
    },
    { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
    { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
    { path: 'Resources', loadChildren: () => import('./Resources/resources.module').then(m => m.ResourcesModule) },
    { path: 'logout', component: LogoutComponent },
    { path: 'about', component: AboutComponent },
    { path: 'systemUpdates', component: SystemUpdatesComponent },
    { path: 'errors', component: ValidationErrorsComponent },
    { path: 'errors/:id', component: ValidationErrorsComponent },
    { path: 'errors/:id/:filter', component: ValidationErrorsComponent },
    { path: 'landing', loadChildren: () => import('./navigation/landing/landing.module').then(m => m.LandingModule) },
    { path: 'titlevi', loadChildren: () => import('./TitleVI/titlevi.module').then(m => m.TitleVIModule) },
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: '**', redirectTo: 'welcome', pathMatch: 'full' },
    // { path: '**', redirectTo: '/dashboard' },
];

// export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes);
// Pre-Load modules
export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {
    useHash: Boolean(history.pushState) === false,
    preloadingStrategy: NoPreloading
});


