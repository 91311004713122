import { Component, OnInit, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HelpService } from '../help.service';
import { DestructibleComponent } from '../../destructible.component';
import { BannerMessage, BannerType } from './bannerMessage';

@Component({
  selector: 'oaaps-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent extends DestructibleComponent implements OnInit, OnDestroy {

  @ViewChild('banner')
  private banner: ElementRef;

  public bannerMessage: BannerMessage = new BannerMessage();

  constructor(private helpService: HelpService) { super(); }

  ngOnInit() {
    super.registerSubscription(this.helpService.getBannerMessage().subscribe(data => {
      if (data) {
        this.bannerMessage = data;
      }
    }))

    // unsupported browser check
    if(navigator.userAgent.indexOf("Chrome") == -1 && navigator.userAgent.indexOf("Firefox") == -1){
      super.registerSubscription(this.helpService.getUnsupportedBrowserBannerMessage().subscribe(data => {
        if (data) {
          this.bannerMessage = data;
        }
      }))
    }
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  @HostListener('window:scroll', ['$event']) scrollHandler($event) {
    if (this.banner) {
      const bannerElement = this.banner.nativeElement;
      if (window.pageYOffset > bannerElement.offsetHeight) {
        bannerElement.classList.add('sticky');
      } else {
        bannerElement.classList.remove('sticky');
      }
    }
  }

  public get bannerStyle(): string {
    switch (this.bannerMessage.bannerType) {
      case(BannerType.Success):
        return 'banner-success';
      case(BannerType.Info):
        return 'banner-info';
      case(BannerType.Warning):
        return 'banner-warning';
      case(BannerType.Error):
        return 'banner-error';
      default:
        return 'banner-success';
    }
  }

}
