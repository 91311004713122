export interface IDataElement {
    label: string;
    elementValue: string;
    variance: string;
    explanation: string;
    dataEntryErrorMessage: string;
    businessErrorMessage: string;
    isValid: boolean;
    mergeType: number;
    validationType: number;
    isNotSigificantVariance: boolean;
    isUserNotProvidedSigificantVariance: boolean;
    nationalValue: string;
    nationalVariance: string;
    varianceThreshold: number;
    questionStatus: number;
    maxLength: number;
    isRequired: boolean;
}

export class DataElement implements IDataElement {
    label: string;
    elementValue: string;
    variance: string;
    explanation: string;
    dataEntryErrorMessage: string;
    businessErrorMessage: string;
    isValid: boolean;
    mergeType: number;
    validationType: number;
    isNotSigificantVariance: boolean;
    isUserNotProvidedSigificantVariance: boolean;
    nationalValue: string;
    nationalVariance: string;
    varianceThreshold: number;
    questionStatus: QuestionStatusEnum;
    maxLength: number;
    isRequired: boolean = true;

    constructor() {
        this.elementValue = '';
        this.isValid = true;
    }

    public static getProperties(): string[] {
        return [
            'label',
            'elementValue',
            'variance',
            'explanation',
            'dataEntryErrorMessage',
            'businessErrorMessage',
            'mergeType',
            'validationType',
            'isNotSigificantVariance',
            'isUserNotProvidedSigificantVariance',
            'nationalValue',
            'nationalVariance',
            'varianceThreshold',
            'questionStatus',
            'maxLength',
            'isRequired'
        ];
    }
}

export enum QuestionStatusEnum {
    NotStarted,
    L1InProgressError,
    L2InProgressError,
    L1InProgress,
    L2ValidatedWarning,
    L2Validated,
    L3NoExplanation,
    L3Explanation,
    NoSignificantVariance,
    Returned,
    SubmittedNoExplanation,
    Submitted,
    InReview,
    Approved,
    Locked
}

export enum ValidationTypes {
    None = 0,
    Number = 1,
    Decimal2 = 2,
    NonZero = 4,
    AlphaNum = 8,
    Date = 16,
    String = 32,
    NotNullOrEmpty = 64,
    NonZeroInt = 128,
    CharMax3400 = 256,
    CharMax100 = 512,
    Boolean = 1024
}
