<div class="gray-dash">
  <div>
    <p>
      <strong>{{title}}</strong>
    </p>
    <hr align="left" />
  </div>
  <p>
    <small class="gray-lighter">{{description}}</small>
  </p>
  <!-- flex list -->
  <div class="flex-list panel-group">
    <div class="flex-list-item">
      <acl-button-card [description]="cards[0].description" id="generatebtn" [buttonText]="cards[0].buttonText" [disabled]="cards[0].disabled"
        [link]="cards[0].link" [width]="100" (action)="cards[0].action()"></acl-button-card>
    </div>
    <div class="flex-list-item">
      <acl-button-card [description]="cards[1].description" id="viewbtn" [buttonText]="cards[1].buttonText" [disabled]="cards[1].disabled"
        [link]="cards[1].link" [width]="100" (action)="cards[1].action()"></acl-button-card>
    </div>
    <div class="flex-list-item">
      <acl-button-card [description]="cards[2].description" id="viewbtn" [buttonText]="cards[2].buttonText" [disabled]="cards[2].disabled"
        [link]="cards[2].link" [width]="100" (action)="cards[2].action()"></acl-button-card>
    </div>
  </div>
  <!-- .end flex list -->
</div>
