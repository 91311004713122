/*
 * Contains array utils
 */


export function remove<T>(arr: Array<T>, element: T, selector?: string) {
    if (!arr) {
        throw new Error('Invalid arguments supplied.');
    }
    let index = -1;
    if (selector) {
        index = arr.findIndex(x => x[selector] === element[selector]);
    } else {
        index = arr.indexOf(element);
    }
    if (index !== -1) {
        arr.splice(index, 1);
    }
}

export function removeAll<T>(arr: Array<T>, elements: T[], selector?: string) {
    if (!arr || !elements) {
        throw new Error('Invalid arguments supplied.');
    }
    elements.forEach(e => {
        let index = -1;
        if (selector) {
            index = arr.findIndex(x => x[selector] === e[selector]);
        } else {
            index = arr.indexOf(e);
        }
        if (index !== -1) {
            arr.splice(index, 1);
        }
    });
}

