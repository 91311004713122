<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav #sideNav></sideNav>
      <!-- .end left navigation -->

      <div class="col-md-9">
        <h2>Manage Attachments</h2>
        <!-- <div class="col-md-6" id="uploadFileContainer"> -->
          <button (click)="navAttachFile()" [disabled]="_isUploadDisabled || _claimService.isViewOnly"
            class="btn btn-primary pull-right" id="uploadbtn">Upload a new attachment</button>
        <!-- uploads -->
        <div *ngIf="_attachments?.length > 0">
          <div class="col-md-12 table-responsive">
            <table class="table">
              <caption>Upload Log</caption>
              <thead>
                <tr class="background-gray-lighter">
                  <th width="30%" scope="col">File Name</th>
                  <th width="30%" scope="col">Category</th>
                  <th width="30%" scope="col">Comments</th>
                  <th width="10%" scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let attachment of _attachments">
                  <td><a [href]="attachment.azureUrl">{{attachment.fileName}}</a></td>
                  <td>{{attachment.categoryType}}</td>
                  <td>{{attachment.comments | truncate : 100 }}
                    <a *ngIf="attachment.comments.length > 100" tabindex="0">more</a>
                  </td>
                  <td><button [disabled]="_isUploadDisabled || _claimService.isViewOnly" (click)="deleteAttachment(attachment.id)" class="btn btn-danger" id="uniqueID0">Delete</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="_attachments?.length == 0" class="col-md-12">
          No attachments found.
        </div>
        <!-- .end uploads -->

        <div class="m-1">
          <div class="row col-md-12">
            <a *ngIf="getCurrentTitle() == 'III'" class="btn btn-link" [routerLink]="['/data-submissions/titleIIIOverview']">
              Return to Overview
            </a>
            <a *ngIf="getCurrentTitle() == 'VI'" class="btn btn-link" [routerLink]="['/titlevi/titleVIPPROverview']">
              Return to Overview
            </a>
            <a *ngIf="getCurrentTitle() == 'VII'" class="btn btn-link" [routerLink]="['/state-submissions/titleVIIOverview']">
              Return to Overview
            </a>
          </div>
        </div>

        <!-- old functionality that allows merging of sections, not deleting in case we want to re-enable it -->
        <!-- <div><button [disabled]="_sectionsToMerge.length <= 0 && _batchSectionsToUpdate.length <= 0" (click)="merge()" class="btn btn-primary" id="mergeData">Merge data</button></div> -->
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
