<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <loading #loadingSpinner></loading>
      <div class="col-md-6 pl-0">
        <h1>Data Submissions</h1>
      </div>
      <div class="col-md-3  col-md-push-3">
        <!-- .begin year picker dropdown -->
        <fiscalYearDropdown></fiscalYearDropdown>
        <!-- .end year picker dropdown -->
      </div>
      <!-- .end col-md-12 -->
      <div class="col-md-12">
        <div class="form-group">
          <label for="action">Actions on multiple AAA's</label>
          <select id="action" name="action" style="width: 21%;" class="form-control" (change)="showSubmittedAAAs()"
            [(ngModel)]="action">
            <option [ngValue]="0">-Select-</option>
            <option [ngValue]="11">Change status to In Review</option>
            <option *ngIf="isUserLocker" [ngValue]="13">Lock data</option>
            <option *ngIf="isUserLocker" [ngValue]="14">Unlock data</option>
          </select>
        </div>
      </div>

      <div id="submittedAAAs" class="modal fade">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title"> {{action === statusEnum.Submitted ? "Change multiple AAA statuses to In
                Review" : action === statusEnum.Approved
                ? "Lock multiple AAA submissions" : action === statusEnum.Locked ? "Unlock multiple AAA
                submissions"
                : ""}}</h2>
            </div>
            <div class="modal-body">
              <p *ngIf="action === statusEnum.Submitted">Only AAA's that are in a Submitted status can be changed to
                In Review. Once set to In Review, AAA's can no
                longer Unsubmit their reports to make corrections. ACL must then return the report to the AAA's for
                correction.
                Select the AAA's you would like to set to In Review below.
              </p>
              <p *ngIf="action === statusEnum.Approved">Only AAA's submissions that are in an Approved status can be
                Locked. Once Locked, ACL Approvers can no longer
                change the review decision for that AAA. A user with Locking capabilities must then Unlock the AAA
                to
                change the review decision.
                <br /> Select the AAA's you would like to set to Locked below.</p>
              <p *ngIf="action === statusEnum.Locked">Only AAA's that are in a Locked status can be Unlocked. Once
                Unlocked, the AAA's status would revert to
                Approved.</p>
              <br />
              <div *ngIf="submittedAAAOverviews.length > 0" class="table-responsive">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <th scope="col">AAA</th>
                    <th scope="col">
                      <input (change)="selectAllSprAAAs($event)" id="selectSprAAAs" name="selectSprAAAs" type="checkbox"
                        value="">
                    </th>
                    <th scope="col">SPR Status</th>
                    <th scope="col">
                      <input (change)="selectAllNsipAAAs($event)" id="selectNsipAAAs" name="selectNsipAAAs" type="checkbox"
                        value="">
                    </th>
                    <th scope="col">NSIP Status</th>
                  </tr>
                  <tr *ngFor="let overview of submittedAAAOverviews; let i = index">
                    <td>{{overview.psaName}}</td>
                    <td>
                      <input *ngIf="action === overview.sprStatus" (change)="handleSprCheckEvent($event)" [checked]="sprAAAsSelected" [hidden]="!overview.sprIsRequired"
                        name="sprValue[{{i}}]" type="checkbox" value="{{overview.psaName}}">
                    </td>
                    <td [ngClass]="_commonService.setColor(resolveACLStatus(overview.sprStatus))" [innerHTML]="_commonService.statusReader(_commonService.overallStatus(overview.sprStatus), overview.sprIsRequired)"></td>
                    <td>
                      <input *ngIf="action === overview.nsipStatus" (change)="handleNsipCheckEvent($event)" [checked]="nsipAAAsSelected" [hidden]="!overview.nsipIsRequired"
                        name="nsipValue[{{i}}]" type="checkbox" value="{{overview.psaName}}">
                    </td>
                    <td [ngClass]="_commonService.setColor(resolveACLStatus(overview.nsipStatus))" [innerHTML]="_commonService.statusReader(_commonService.overallStatus(overview.nsipStatus), overview.nsipIsRequired)"></td>
                  </tr>
                </tbody>
                </table>
              </div>
              <div *ngIf="submittedAAAOverviews.length == 0">
                <p>
                  <strong>{{action === statusEnum.Submitted ? "There are currently no AAA's that are in a submitted status for the selected reporting period." : action === statusEnum.Approved
                    ? "There are currently no AAA's that are in a approved status for the selected reporting period." : action === statusEnum.Locked ? "There are currently no AAA's that are in a locked status for the selected reporting period."
                    : ""}}</strong>
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <button *ngIf="submittedAAAOverviews.length > 0" (click)="saveStatus()" type="button" class="btn btn-primary btn-small"
                data-dismiss="modal">Save</button>
              <button (click)="dismissSubmittedAAAModal()" type="button" class="btn btn-secondary btn-small"
                data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <!-- .end submitted modal -->

      <div class="col-md-12 mt-2">
        <h2>AAA Data Submissions</h2>
        <div class="table-responsive">
          <table class="table" [mfData]="overviews" #mf="mfDataTable" [mfRowsOnPage]="rowsOnPage" [(mfSortBy)]="sortBy"
            [(mfSortOrder)]="sortOrder">
            <tbody>
            <tr class="background-gray-lighter">
              <th scope="col">
                <mfDataTableSorter by="psaName">Area Agency</mfDataTableSorter>
              </th>
              <th scope="col">
                <mfDataTableSorter by="sprStatus">SPR Status</mfDataTableSorter>
              </th>
              <th scope="col">
                <mfDataTableSorter by="sprLastUpdatedTime">SPR Last Update</mfDataTableSorter>
              </th>
              <th scope="col">
                <mfDataTableSorter by="nsipStatus">NSIP Status</mfDataTableSorter>
              </th>
              <th scope="col">
                <mfDataTableSorter by="nsipLastUpdatedTime">NSIP Last Update</mfDataTableSorter>
              </th>
              <th scope="col" tabindex="0">Actions</th>
            </tr>
            <tr *ngFor="let overview of mf.data">
              <td>{{overview.psaName}}</td>
              <td [ngClass]="_commonService.setColor(resolveACLStatus(overview.sprStatus))" [innerHTML]="_commonService.statusReader(_commonService.overallStatus(overview.sprStatus), overview.sprIsRequired)"></td>
              <td>
                <span [innerHTML]="_commonService.updatedByReader(overview.sprLastUpdatedTime, overview.sprLastUpdatedBy, overview.sprIsRequired)"></span>
              </td>
              <td [ngClass]="_commonService.setColor(resolveACLStatus(overview.nsipStatus))" [innerHTML]="_commonService.statusReader(_commonService.overallStatus(overview.nsipStatus), overview.nsipIsRequired)"></td>
              <td>
                <span [innerHTML]="_commonService.updatedByReader(overview.nsipLastUpdatedTime, overview.nsipLastUpdatedBy, overview.nsipIsRequired)"></span>
              </td>
              <td>
                <button *ngIf="(overview.nsipIsRequired) || (overview.sprIsRequired)"
                  class="btn btn-primary" (click)="getAAAData(overview.psa, overview.psaName)">View</button>
              </td>
            </tr>
          </tbody>
            <tfoot class="no-print">
              <tr>
                <td colspan="5">
                  <mfBootstrapPaginator></mfBootstrapPaginator>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- end .table-responsive -->
      </div>
      <!-- .end acl-container (col-md-6) -->
    </div>
    <!-- .end row -->
  </div>
  <!-- ./container -->
