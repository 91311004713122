import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { FourColNoNutritionCharsSection } from './FourColNoNutritionCharsSection';
import { ValidationService } from '../shared/validation.service';
import { ToastrService } from '../shared/toastr.service';
import { CommonService } from '../shared/common.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIISectionComponent } from './title-iii-section.component';

@Component({
  templateUrl: 'titleIIIFourColNoNutritionChars.component.html',
})

export class TitleIIIFourColNoNutritionCharsComponent extends TitleIIISectionComponent<FourColNoNutritionCharsSection> implements OnInit, OnDestroy {

  _sectionModel: FourColNoNutritionCharsSection;
  _uploadModels: Array<FourColNoNutritionCharsSection>;
  _batchIds: Array<string>;
  pageName: string;
  unsavedChanges: boolean = false;
  allAgeTotalArr: Array<DataElement>;
  bpAgeTotalArr: Array<DataElement>;
  adlAgeTotalArr: Array<DataElement>;
  iadlAgeTotalArr: Array<DataElement>;
  allGenderTotalArr: Array<DataElement>;
  bpGenderTotalArr: Array<DataElement>;
  adlGenderTotalArr: Array<DataElement>;
  iadlGenderTotalArr: Array<DataElement>;
  allGeoTotalArr: Array<DataElement>;
  bpGeoTotalArr: Array<DataElement>;
  adlGeoTotalArr: Array<DataElement>;
  iadlGeoTotalArr: Array<DataElement>;
  allPovertyTotalArr: Array<DataElement>;
  adlPovertyTotalArr: Array<DataElement>;
  iadlPovertyTotalArr: Array<DataElement>;
  allHouseholdTotalArr: Array<DataElement>;
  bpHouseholdTotalArr: Array<DataElement>;
  adlHouseholdTotalArr: Array<DataElement>;
  iadlHouseholdTotalArr: Array<DataElement>;
  allEthnicityTotalArr: Array<DataElement>;
  bpEthnicityTotalArr: Array<DataElement>;
  adlEthnicityTotalArr: Array<DataElement>;
  iadlEthnicityTotalArr: Array<DataElement>;
  allMinorityTotalArr: Array<DataElement>;
  bpMinorityTotalArr: Array<DataElement>;
  adlMinorityTotalArr: Array<DataElement>;
  iadlMinorityTotalArr: Array<DataElement>;
  allLimitTotalAdlArr: Array<DataElement>;
  bpLimitTotalAdlArr: Array<DataElement>;
  allLimitTotalIadlArr: Array<DataElement>;
  bpLimitTotalIadlArr: Array<DataElement>;

  statusEnum = QuestionStatusEnum;

  // age
  totalBelow60Text: string = 'Total Below 60';
  bpBelow60Text: string = 'Below 60 at or below poverty';
  adlBelow60Text: string = 'Below 60 ADL 3+';
  iadlBelow60Text: string = 'Below 60 IADL 3+';
  totalRange1Text: string = 'Total 60-64';
  bpRange1Text: string = '60-64 at or below poverty';
  adlRange1Text: string = '60-64 ADL 3+';
  iadlRange1Text: string = '60-64 IADL 3+';
  totalRange2Text: string = 'Total 65-74';
  bpRange2Text: string = '65-74 at or below poverty';
  adlRange2Text: string = '65-74 ADL 3+';
  iadlRange2Text: string = '65-74 IADL 3+';
  totalRange3Text: string = 'Total 75-84';
  bpRange3Text: string = '75-84 at or below poverty';
  adlRange3Text: string = '75-84 ADL 3+';
  iadlRange3Text: string = '75-84 IADL 3+';
  totalRange4Text: string = 'Total 85 and above';
  bpRange4Text: string = '85 and above at or below poverty';
  adlRange4Text: string = '85 and above ADL 3+';
  iadlRange4Text: string = '85 and above IADL 3+';
  totalAgeMissingText: string = 'Total age missing';
  bpAgeMissingText: string = 'Age missing at or below poverty';
  adlAgeMissingText: string = 'Age missing ADL 3+';
  iadlAgeMissingText: string = 'Age missing IADL 3+';
  allAgeTotalText: string = 'Total for Total Persons Served in the Age section';
  bpAgeTotalText: string = 'Total Persons Served at or below poverty in the Age section';
  adlAgeTotalText: string = 'Total Persons Served ADL 3+ in the Age section';
  iadlAgeTotalText: string = 'Total Persons Served IADL 3+ in the Age section';

  // gender
  totalFemaleText: string = 'Total Female';
  bpFemaleText: string = 'Female at or below poverty';
  adlFemaleText: string = 'Female ADL 3+';
  iadlFemaleText: string = 'Female IADL 3+';
  totalMaleText: string = 'Total Male';
  bpMaleText: string = 'Male at or below poverty';
  adlMaleText: string = 'Male ADL 3+';
  iadlMaleText: string = 'Male IADL 3+';
  totalOtherText: string = 'Total Other';
  bpOtherText: string = 'Other at or below poverty';
  adlOtherText: string = 'Other ADL 3+';
  iadlOtherText: string = 'Other IADL 3+';
  totalGenderMissingText: string = 'Total Gender missing';
  bpGenderMissingText: string = 'Gender missing at or below poverty';
  adlGenderMissingText: string = 'Gender missing ADL 3+';
  iadlGenderMissingText: string = 'Gender missing IADL 3+';
  allGenderTotalText: string = 'Total for Total Persons Served in the Gender section';
  bpGenderTotalText: string = 'Total Persons Served at or below poverty in the Gender section';
  adlGenderTotalText: string = 'Total Persons Served ADL 3+ in the Gender section';
  iadlGenderTotalText: string = 'Total Persons Served IADL 3+ in the Gender section';

  // geographic
  totalRuralText: string = 'Rural Total';
  bpRuralText: string = 'Rural at or below poverty';
  adlRuralText: string = 'Rural ADL 3+';
  iadlRuralText: string = 'Rural IADL 3+';
  totalNonRuralText: string = 'Total Non-rural';
  bpNonRuralText: string = 'Non-rural at or below poverty';
  adlNonRuralText: string = 'Non-rural ADL 3+';
  iadlNonRuralText: string = 'Non-rural IADL 3+';
  totalGeoMissingText: string = 'Total Geographic distribution missing';
  bpGeoMissingText: string = 'Geographic distribution missing at or below poverty';
  adlGeoMissingText: string = 'Geographic distribution missing ADL 3+';
  iadlGeoMissingText: string = 'Geographic distribution missing IADL 3+';
  allGeoTotalText: string = 'Total for Total Persons Served in the Geographic section';
  bpGeoTotalText: string = 'Total Persons Served at or below poverty in the Geographic section';
  adlGeoTotalText: string = 'Total Persons Served ADL 3+ in the Geographic section';
  iadlGeoTotalText: string = 'Total Persons Served IADL 3+ in the Geographic section';

  // poverty
  totalBelowPovertyText: string = 'At or below poverty Total';
  adlBelowPovertyText: string = 'At or below poverty ADL 3+';
  iadlBelowPovertyText: string = 'At or below poverty IADL 3+';
  totalAbovePovertyText: string = 'Total Above poverty';
  adlAbovePovertyText: string = 'Above poverty ADL 3+';
  iadlAbovePovertyText: string = 'Above poverty IADL 3+';
  totalMissingPovertyText: string = 'Total Poverty status missing';
  adlMissingPovertyText: string = 'Poverty status missing ADL 3+';
  iadlMissingPovertyText: string = 'Poverty status missing IADL 3+';
  allPovertyTotalText: string = 'Total for Total Persons Served in the Poverty section';
  adlPovertyTotalText: string = 'Total Persons Served ADL 3+ in the Poverty section';
  iadlPovertyTotalText: string = 'Total Persons Served IADL 3+ in the Poverty section';

  // household
  totalAloneText: string = 'Lives alone Total';
  bpAloneText: string = 'Lives alone at or below poverty';
  adlAloneText: string = 'Lives alone ADL 3+';
  iadlAloneText: string = 'Lives alone IADL 3+';
  totalWithOthersText: string = 'Total Lives with others';
  bpWithOthersText: string = 'Lives with others at or below poverty';
  adlWithOthersText: string = 'Lives with others ADL 3+';
  iadlWithOthersText: string = 'Lives with others IADL 3+';
  totalHouseholdMissingText: string = 'Total Household status missing';
  bpHouseholdMissingText: string = 'Household status missing at or below poverty';
  adlHouseholdMissingText: string = 'Household status missing ADL 3+';
  iadlHouseholdMissingText: string = 'Household status missing IADL 3+';
  allHouseholdTotalText: string = 'Total for Total Persons Served in the Household section';
  bpHouseholdTotalText: string = 'Total Persons Served at or below poverty in the Household section';
  adlHouseholdTotalText: string = 'Total Persons Served ADL 3+ in the Household section';
  iadlHouseholdTotalText: string = 'Total Persons Served IADL 3+ in the Household section';

  // ethnicity
  totalHispanicText: string = 'Hispanic or Latino Total';
  bpHispanicText: string = 'Hispanic or Latino at or below poverty';
  adlHispanicText: string = 'Hispanic or Latino ADL 3+';
  iadlHispanicText: string = 'Hispanic or Latino IADL 3+';
  totalNonHispanicText: string = 'Total Not Hispanic or Latino';
  bpNonHispanicText: string = 'Not Hispanic or Latino at or below poverty';
  adlNonHispanicText: string = 'Not Hispanic or Latino ADL 3+';
  iadlNonHispanicText: string = 'Not Hispanic or Latino IADL 3+';
  totalEthnicityMissingText: string = 'Total Ethnicity missing';
  bpEthnicityMissingText: string = 'Ethnicity missing at or below poverty';
  adlEthnicityMissingText: string = 'Ethnicity missing ADL 3+';
  iadlEthnicityMissingText: string = 'Ethnicity missing IADL 3+';
  allEthnicityTotalText: string = 'Total for Total Persons Served in Ethnicity section';
  bpEthnicityTotalText: string = 'Total Persons Served at or below poverty in Ethnicity section';
  adlEthnicityTotalText: string = 'Total Persons Served ADL 3+ in Ethnicity section';
  iadlEthnicityTotalText: string = 'Total Persons Served IADL 3+ in Ethnicity section';

  // race
  totalAmericanIndianText: string = 'American Indian or Alaska Native Total';
  bpAmericanIndianText: string = 'American Indian or Alaska Native at or below poverty';
  adlAmericanIndianText: string = 'American Indian or Alaska Native ADL 3+';
  iadlAmericanIndianText: string = 'American Indian or Alaska Native IADL 3+';
  totalAsianText: string = 'Total Asian or Asian American';
  bpAsianText: string = 'Asian or Asian American at or below poverty';
  adlAsianText: string = 'Asian or Asian American ADL 3+';
  iadlAsianText: string = 'Asian or Asian American IADL 3+';
  totalBlackText: string = 'Total Black or African American';
  bpBlackText: string = 'Black or African Americanat or below poverty';
  adlBlackText: string = 'Black or African American ADL 3+';
  iadlBlackText: string = 'Black or African American IADL 3+';
  totalIslanderText: string = 'Native Hawaiian or  Pacific Islander Total';
  bpIslanderText: string = 'Native Hawaiian or  Pacific Islander at or below poverty';
  adlIslanderText: string = 'Native Hawaiian or  Pacific Islander ADL 3+';
  iadlIslanderText: string = 'Native Hawaiian or  Pacific Islander IADL 3+';
  totalWhiteText: string = 'Total White';
  bpWhiteText: string = 'White at or below poverty';
  adlWhiteText: string = 'White ADL 3+';
  iadlWhiteText: string = 'White IADL 3+';
  totalRaceOtherText: string = 'Total other race';
  bpRaceOtherText: string = 'Other race at or below poverty';
  adlRaceOtherText: string = 'Other race ADL 3+';
  iadlRaceOtherText: string = 'Other race IADL 3+';
  totalRaceMultipleText: string = 'Total multiple race';
  bpRaceMultipleText: string = 'Multiple race at or below poverty';
  adlRaceMultipleText: string = 'Multiple race ADL 3+';
  iadlRaceMultipleText: string = 'Multiple race IADL 3+';
  totalRaceMissingText: string = 'Total Race missing';
  bpRaceMissingText: string = 'Race missing at or below poverty';
  adlRaceMissingText: string = 'Race missing ADL 3+';
  iadlRaceMissingText: string = 'Race missing IADL 3+';

  // minority
  totalMinorityText: string = 'Total Minority';
  bpMinorityText: string = 'Minority at or below poverty';
  adlMinorityText: string = 'Minority ADL 3+';
  iadlMinorityText: string = 'Minority IADL 3+';
  totalNotMinorityText: string = 'Total not minority';
  bpNotMinorityText: string = 'Not minority at or below poverty';
  adlNotMinorityText: string = 'Not minority ADL 3+';
  iadlNotMinorityText: string = 'Not minority IADL 3+';
  totalMinorityMissingText: string = 'Total not minority';
  bpMinorityMissingText: string = 'Not minority at or below poverty';
  adlMinorityMissingText: string = 'Not minority ADL 3+';
  iadlMinorityMissingText: string = 'Not minority IADL 3+';
  allMinorityTotalText: string = 'Total for Total Persons Served in the Minority section';
  bpMinorityTotalText: string = 'Total Persons Served at or below poverty in the Minority section';
  adlMinorityTotalText: string = 'Total Persons Served ADL 3+ in the Minority section';
  iadlMinorityTotalText: string = 'Total Persons Served IADL 3+ in the Minority section';

  // adl limits
  totalZeroLimitAdlText: string = 'ADL limitations Total for 0 - 1';
  bpZeroAdlText: string = 'ADL limitations 0 - 1 at or below poverty';
  totalTwoLimitAdlText: string = 'ADL limitations Total 2';
  bpTwoLimitAdlText: string = 'ADL limitations 2 at or below poverty';
  totalHigherLimitAdlText: string = 'ADL limitations Total 3+';
  bpHigherLimitAdlText: string = 'ADL limitations 3+ at or below poverty';
  totalLimitMissingAdlText: string = 'Total ADL limitations missing';
  bpLimitMissingAdlText: string = 'ADL limitations missing at or below poverty';
  allLimitTotalAdlText: string = 'Total for Total Persons Served in the ADL Limitations section';
  bpLimitTotalAdlText: string = 'Total Persons Served at or below poverty in the ADL Limitations section';

  // iadl limits
  totalZeroLimitIadlText: string = 'IADL limitations Total for 0 - 1';
  bpZeroIadlText: string = 'IADL limitations 0 - 1 at or below poverty';
  totalTwoLimitIadlText: string = 'IADL limitations Total 2';
  bpTwoLimitIadlText: string = 'IADL limitations 2 at or below poverty';
  totalHigherLimitIadlText: string = 'IADL limitations Total 3+';
  bpHigherLimitIadlText: string = 'IADL limitations 3+ at or below poverty';
  totalLimitMissingIadlText: string = 'Total IADL limitations missing';
  bpLimitMissingIadlText: string = 'IADL limitations missing at or below poverty';
  allLimitTotalIadlText: string = 'Total for Total Persons Served in the IADL Limitations section';
  bpLimitTotalIadlText: string = 'Total Persons Served at or below poverty in the IADL Limitations section';

  constructor(public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public toastr: ToastrService,
    public _commonService: CommonService
  ) { super(new FourColNoNutritionCharsSection(), _commonService, _validation, router, _claimService, _service); }

  ngOnInit(): void {
    this._sectionModel = new FourColNoNutritionCharsSection();
    const setSectionNameCallback = () => {
      this.setSectionName();

      switch (this._sectionModel.sectionName) {
        case 'OAConsumerOne': {
          this.pageName = 'Consumer Summary -  Persons Receiving Registered Services (Clusters 1)';
          break;
        }
        case 'titleIIIPersonalCareChars': {
          this.pageName = 'Older Adults - Personal Care - Consumer Characteristics ';
          break;
        }
        case 'titleIIIHomemakerChars': {
          this.pageName = 'Older Adults - Homemaker - Consumer Characteristics ';
          break;
        }
        case 'titleIIIChoreChars': {
          this.pageName = 'Older Adults - Chore - Consumer Characteristics ';
          break;
        }
        case 'titleIIIAdultDayCareChars': {
          this.pageName = 'Older Adults - Adult Day Care/Health - Consumer Characteristics ';
          break;
        }
        case 'titleIIICaseMgmtChars': {
          this.pageName = 'Older Adults - Case Management - Consumer Characteristics ';
          break;
        }
        default: {
          this.pageName = 'Error';
          break;
        }
      }
      this._titleService.setTitle(this.pageName.concat(' - Data Submissions - ACL OAAPS'));
    };
    this.getfourcolnonutritioncharsbyffyandstate(setSectionNameCallback);
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    this.setSectionName();
    super.registerSubscription(this._service.savefourcolnonutritionchars(this._sectionModel)
      .subscribe(data => {
        this._sectionModel = data;
        this.initArrays();

      },
        (error) => console.log(error),
        () => {
          callback();
          this.toastr.success('Saved!')
      }));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => {
      switch (this._sectionModel.sectionName) {
        case 'OAConsumerOne': {
          this.router.navigate(['/data-submissions/titleIIIPersonalCareChars']);
          break;
        }
        case 'titleIIIPersonalCareChars': {
          this.router.navigate(['/data-submissions/titleIIIPersonalCareUnits']);
          break;
        }
        case 'titleIIIHomemakerChars': {
          this.router.navigate(['/data-submissions/titleIIIHomemakerUnits']);
          break;
        }
        case 'titleIIIChoreChars': {
          this.router.navigate(['/data-submissions/titleIIIChoreUnits']);
          break;
        }
        case 'titleIIIAdultDayCareChars': {
          this.router.navigate(['/data-submissions/titleIIIAdultDayCareUnits']);
          break;
        }
        case 'titleIIICaseMgmtChars': {
          this.router.navigate(['/data-submissions/titleIIICaseMgmtUnits']);
          break;
        }
        default: {
          this.router.navigate(['/dashboard']);
          break;
        }
      }
    };
    if (!this._claimService.isViewOnly &&
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  next(): void {
    switch (this._sectionModel.sectionName) {
      case 'OAConsumerOne': {
        this.router.navigate(['/data-submissions/titleIIIPersonalCareChars']);
        break;
      }
      case 'titleIIIPersonalCareChars': {
        this.router.navigate(['/data-submissions/titleIIIPersonalCareUnits']);
        break;
      }
      case 'titleIIIHomemakerChars': {
        this.router.navigate(['/data-submissions/titleIIIHomemakerUnits']);
        break;
      }
      case 'titleIIIChoreChars': {
        this.router.navigate(['/data-submissions/titleIIIChoreUnits']);
        break;
      }
      case 'titleIIIAdultDayCareChars': {
        this.router.navigate(['/data-submissions/titleIIIAdultDayCareUnits']);
        break;
      }
      case 'titleIIICaseMgmtChars': {
        this.router.navigate(['/data-submissions/titleIIICaseMgmtUnits']);
        break;
      }
      default: {
        this.router.navigate(['/dashboard']);
        break;
      }
    }
  }

  getfourcolnonutritioncharsbyffyandstate(onComplete: () => void): void {
    super.registerSubscription(this._service.get<FourColNoNutritionCharsSection[]>(this._claimService,
      this._service._getfourcolnonutritioncharsbyffyandstate, this.resolveSectionName())
      .subscribe(data => {
        if (data) {
          const sectionData = data.find(item => this.resolveSectionName().includes(item.sectionName));
          if (sectionData) {
            this._sectionModel = sectionData;
            this.initArrays();
          } else {
            // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
            const tempSectionModel: FourColNoNutritionCharsSection = new FourColNoNutritionCharsSection();
            this._sectionModel = tempSectionModel;
            this.initArrays();
          }
          this._uploadModels = data.filter(element => element.batchId != '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
            // initialize arrays for summing
            this.initArrays();
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
        } else {
          this.initArrays();
        }

      }, err => console.log(err), onComplete));
  }

  initArrays(): void {
    this.allAgeTotalArr = [this._sectionModel.totalBelow60,
    this._sectionModel.totalRange1,
    this._sectionModel.totalRange2,
    this._sectionModel.totalRange3,
    this._sectionModel.totalRange4,
    this._sectionModel.totalAgeMissing];

    this.bpAgeTotalArr = [this._sectionModel.bpBelow60,
    this._sectionModel.bpRange1,
    this._sectionModel.bpRange2,
    this._sectionModel.bpRange3,
    this._sectionModel.bpRange4,
    this._sectionModel.bpAgeMissing];

    this.adlAgeTotalArr = [this._sectionModel.adlBelow60,
    this._sectionModel.adlRange1,
    this._sectionModel.adlRange2,
    this._sectionModel.adlRange3,
    this._sectionModel.adlRange4,
    this._sectionModel.adlAgeMissing];

    this.iadlAgeTotalArr = [this._sectionModel.iadlBelow60,
    this._sectionModel.iadlRange1,
    this._sectionModel.iadlRange2,
    this._sectionModel.iadlRange3,
    this._sectionModel.iadlRange4,
    this._sectionModel.iadlAgeMissing];

    this.allGenderTotalArr = [this._sectionModel.totalFemale,
    this._sectionModel.totalMale,
    this._sectionModel.totalOther,
    this._sectionModel.totalGenderMissing];

    this.bpGenderTotalArr = [this._sectionModel.bpFemale,
    this._sectionModel.bpMale,
    this._sectionModel.bpOther,
    this._sectionModel.bpGenderMissing];

    this.adlGenderTotalArr = [this._sectionModel.adlFemale,
    this._sectionModel.adlMale,
    this._sectionModel.adlOther,
    this._sectionModel.adlGenderMissing];

    this.iadlGenderTotalArr = [this._sectionModel.iadlFemale,
    this._sectionModel.iadlMale,
    this._sectionModel.iadlOther,
    this._sectionModel.iadlGenderMissing];

    this.allGeoTotalArr = [this._sectionModel.totalRural,
    this._sectionModel.totalNonRural,
    this._sectionModel.totalGeoMissing];

    this.bpGeoTotalArr = [this._sectionModel.bpRural,
    this._sectionModel.bpNonRural,
    this._sectionModel.bpGeoMissing];

    this.adlGeoTotalArr = [this._sectionModel.adlRural,
    this._sectionModel.adlNonRural,
    this._sectionModel.adlGeoMissing];

    this.iadlGeoTotalArr = [this._sectionModel.iadlRural,
    this._sectionModel.iadlNonRural,
    this._sectionModel.iadlGeoMissing];

    this.allPovertyTotalArr = [this._sectionModel.totalBelowPoverty,
    this._sectionModel.totalAbovePoverty,
    this._sectionModel.totalMissingPoverty];

    this.adlPovertyTotalArr = [this._sectionModel.adlBelowPoverty,
    this._sectionModel.adlAbovePoverty,
    this._sectionModel.adlMissingPoverty];

    this.iadlPovertyTotalArr = [this._sectionModel.iadlBelowPoverty,
    this._sectionModel.iadlAbovePoverty,
    this._sectionModel.iadlMissingPoverty];

    this.allHouseholdTotalArr = [this._sectionModel.totalAlone,
    this._sectionModel.totalWithOthers,
    this._sectionModel.totalHouseholdMissing];

    this.bpHouseholdTotalArr = [this._sectionModel.bpAlone,
    this._sectionModel.bpWithOthers,
    this._sectionModel.bpHouseholdMissing];

    this.adlHouseholdTotalArr = [this._sectionModel.adlAlone,
    this._sectionModel.adlWithOthers,
    this._sectionModel.adlHouseholdMissing];

    this.iadlHouseholdTotalArr = [this._sectionModel.iadlAlone,
    this._sectionModel.iadlWithOthers,
    this._sectionModel.iadlHouseholdMissing];

    this.allEthnicityTotalArr = [this._sectionModel.totalHispanic,
    this._sectionModel.totalNonHispanic,
    this._sectionModel.totalEthnicityMissing];

    this.bpEthnicityTotalArr = [this._sectionModel.bpHispanic,
    this._sectionModel.bpNonHispanic,
    this._sectionModel.bpEthnicityMissing];

    this.adlEthnicityTotalArr = [this._sectionModel.adlHispanic,
    this._sectionModel.adlNonHispanic,
    this._sectionModel.adlEthnicityMissing];

    this.iadlEthnicityTotalArr = [this._sectionModel.iadlHispanic,
    this._sectionModel.iadlNonHispanic,
    this._sectionModel.iadlEthnicityMissing];

    this.allMinorityTotalArr = [this._sectionModel.totalMinority,
    this._sectionModel.totalNotMinority,
    this._sectionModel.totalMinorityMissing];

    this.bpMinorityTotalArr = [this._sectionModel.bpMinority,
    this._sectionModel.bpNotMinority,
    this._sectionModel.bpMinorityMissing];

    this.adlMinorityTotalArr = [this._sectionModel.adlMinority,
    this._sectionModel.adlNotMinority,
    this._sectionModel.adlMinorityMissing];

    this.iadlMinorityTotalArr = [this._sectionModel.iadlMinority,
    this._sectionModel.iadlNotMinority,
    this._sectionModel.iadlMinorityMissing];

    this.allLimitTotalAdlArr = [this._sectionModel.totalZeroLimitAdl,
    this._sectionModel.totalTwoLimitAdl,
    this._sectionModel.totalHigherLimitAdl,
    this._sectionModel.totalLimitMissingAdl];

    this.bpLimitTotalAdlArr = [this._sectionModel.bpZeroAdl,
    this._sectionModel.bpTwoLimitAdl,
    this._sectionModel.adlBelowPoverty, // this._sectionModel.bpHigherLimitAdl,
    this._sectionModel.bpLimitMissingAdl];

    this.allLimitTotalIadlArr = [this._sectionModel.totalZeroLimitIadl,
    this._sectionModel.totalTwoLimitIadl,
    this._sectionModel.totalHigherLimitIadl,
    this._sectionModel.totalLimitMissingIadl];

    this.bpLimitTotalIadlArr = [this._sectionModel.bpZeroIadl,
    this._sectionModel.bpTwoLimitIadl,
    this._sectionModel.iadlBelowPoverty, // this._sectionModel.bpHigherLimitIadl,
    this._sectionModel.bpLimitMissingIadl];
  }

  updateTotal(totalsArray: Array<DataElement>): string {
    let total: number = 0;

    for (let i = 0; i < totalsArray.length; i++) {
      // if not null, remove commas
      if (totalsArray[i].elementValue) {
        totalsArray[i].elementValue = totalsArray[i].elementValue.replace(/,/g, '');
      }
      // if is a number, sum it
      if (!isNaN(parseInt(totalsArray[i].elementValue))) {
        total += parseInt(totalsArray[i].elementValue);
      }
      // replace commas when done adding
      totalsArray[i].elementValue = this.addComma(totalsArray[i].elementValue);
    }

    // add comma and return
    return this.addComma(total.toString());
  }


  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.totalPersonsServed.elementValue = this.addComma(this._sectionModel.totalPersonsServed.elementValue);
    this._sectionModel.totalBelow60.elementValue = this.addComma(this._sectionModel.totalBelow60.elementValue);
    this._sectionModel.bpBelow60.elementValue = this.addComma(this._sectionModel.bpBelow60.elementValue);
    this._sectionModel.adlBelow60.elementValue = this.addComma(this._sectionModel.adlBelow60.elementValue);
    this._sectionModel.iadlBelow60.elementValue = this.addComma(this._sectionModel.iadlBelow60.elementValue);
    this._sectionModel.totalRange1.elementValue = this.addComma(this._sectionModel.totalRange1.elementValue);
    this._sectionModel.bpRange1.elementValue = this.addComma(this._sectionModel.bpRange1.elementValue);
    this._sectionModel.adlRange1.elementValue = this.addComma(this._sectionModel.adlRange1.elementValue);
    this._sectionModel.iadlRange1.elementValue = this.addComma(this._sectionModel.iadlRange1.elementValue);
    this._sectionModel.totalRange2.elementValue = this.addComma(this._sectionModel.totalRange2.elementValue);
    this._sectionModel.bpRange2.elementValue = this.addComma(this._sectionModel.bpRange2.elementValue);
    this._sectionModel.adlRange2.elementValue = this.addComma(this._sectionModel.adlRange2.elementValue);
    this._sectionModel.iadlRange2.elementValue = this.addComma(this._sectionModel.iadlRange2.elementValue);
    this._sectionModel.totalRange3.elementValue = this.addComma(this._sectionModel.totalRange3.elementValue);
    this._sectionModel.bpRange3.elementValue = this.addComma(this._sectionModel.bpRange3.elementValue);
    this._sectionModel.adlRange3.elementValue = this.addComma(this._sectionModel.adlRange3.elementValue);
    this._sectionModel.iadlRange3.elementValue = this.addComma(this._sectionModel.iadlRange3.elementValue);
    this._sectionModel.totalRange4.elementValue = this.addComma(this._sectionModel.totalRange4.elementValue);
    this._sectionModel.bpRange4.elementValue = this.addComma(this._sectionModel.bpRange4.elementValue);
    this._sectionModel.adlRange4.elementValue = this.addComma(this._sectionModel.adlRange4.elementValue);
    this._sectionModel.iadlRange4.elementValue = this.addComma(this._sectionModel.iadlRange4.elementValue);
    this._sectionModel.totalAgeMissing.elementValue = this.addComma(this._sectionModel.totalAgeMissing.elementValue);
    this._sectionModel.bpAgeMissing.elementValue = this.addComma(this._sectionModel.bpAgeMissing.elementValue);
    this._sectionModel.adlAgeMissing.elementValue = this.addComma(this._sectionModel.adlAgeMissing.elementValue);
    this._sectionModel.iadlAgeMissing.elementValue = this.addComma(this._sectionModel.iadlAgeMissing.elementValue);
    this._sectionModel.allAgeTotal.elementValue = this.addComma(this._sectionModel.allAgeTotal.elementValue);
    this._sectionModel.bpAgeTotal.elementValue = this.addComma(this._sectionModel.bpAgeTotal.elementValue);
    this._sectionModel.adlAgeTotal.elementValue = this.addComma(this._sectionModel.adlAgeTotal.elementValue);
    this._sectionModel.iadlAgeTotal.elementValue = this.addComma(this._sectionModel.iadlAgeTotal.elementValue);
    this._sectionModel.totalFemale.elementValue = this.addComma(this._sectionModel.totalFemale.elementValue);
    this._sectionModel.bpFemale.elementValue = this.addComma(this._sectionModel.bpFemale.elementValue);
    this._sectionModel.adlFemale.elementValue = this.addComma(this._sectionModel.adlFemale.elementValue);
    this._sectionModel.iadlFemale.elementValue = this.addComma(this._sectionModel.iadlFemale.elementValue);
    this._sectionModel.totalMale.elementValue = this.addComma(this._sectionModel.totalMale.elementValue);
    this._sectionModel.bpMale.elementValue = this.addComma(this._sectionModel.bpMale.elementValue);
    this._sectionModel.adlMale.elementValue = this.addComma(this._sectionModel.adlMale.elementValue);
    this._sectionModel.iadlMale.elementValue = this.addComma(this._sectionModel.iadlMale.elementValue);
    this._sectionModel.totalOther.elementValue = this.addComma(this._sectionModel.totalOther.elementValue);
    this._sectionModel.bpOther.elementValue = this.addComma(this._sectionModel.bpOther.elementValue);
    this._sectionModel.adlOther.elementValue = this.addComma(this._sectionModel.adlOther.elementValue);
    this._sectionModel.iadlOther.elementValue = this.addComma(this._sectionModel.iadlOther.elementValue);
    this._sectionModel.totalGenderMissing.elementValue = this.addComma(this._sectionModel.totalGenderMissing.elementValue);
    this._sectionModel.bpGenderMissing.elementValue = this.addComma(this._sectionModel.bpGenderMissing.elementValue);
    this._sectionModel.adlGenderMissing.elementValue = this.addComma(this._sectionModel.adlGenderMissing.elementValue);
    this._sectionModel.iadlGenderMissing.elementValue = this.addComma(this._sectionModel.iadlGenderMissing.elementValue);
    this._sectionModel.allGenderTotal.elementValue = this.addComma(this._sectionModel.allGenderTotal.elementValue);
    this._sectionModel.bpGenderTotal.elementValue = this.addComma(this._sectionModel.bpGenderTotal.elementValue);
    this._sectionModel.adlGenderTotal.elementValue = this.addComma(this._sectionModel.adlGenderTotal.elementValue);
    this._sectionModel.iadlGenderTotal.elementValue = this.addComma(this._sectionModel.iadlGenderTotal.elementValue);
    this._sectionModel.totalRural.elementValue = this.addComma(this._sectionModel.totalRural.elementValue);
    this._sectionModel.bpRural.elementValue = this.addComma(this._sectionModel.bpRural.elementValue);
    this._sectionModel.adlRural.elementValue = this.addComma(this._sectionModel.adlRural.elementValue);
    this._sectionModel.iadlRural.elementValue = this.addComma(this._sectionModel.iadlRural.elementValue);
    this._sectionModel.totalNonRural.elementValue = this.addComma(this._sectionModel.totalNonRural.elementValue);
    this._sectionModel.bpNonRural.elementValue = this.addComma(this._sectionModel.bpNonRural.elementValue);
    this._sectionModel.adlNonRural.elementValue = this.addComma(this._sectionModel.adlNonRural.elementValue);
    this._sectionModel.iadlNonRural.elementValue = this.addComma(this._sectionModel.iadlNonRural.elementValue);
    this._sectionModel.totalGeoMissing.elementValue = this.addComma(this._sectionModel.totalGeoMissing.elementValue);
    this._sectionModel.bpGeoMissing.elementValue = this.addComma(this._sectionModel.bpGeoMissing.elementValue);
    this._sectionModel.adlGeoMissing.elementValue = this.addComma(this._sectionModel.adlGeoMissing.elementValue);
    this._sectionModel.iadlGeoMissing.elementValue = this.addComma(this._sectionModel.iadlGeoMissing.elementValue);
    this._sectionModel.allGeoTotal.elementValue = this.addComma(this._sectionModel.allGeoTotal.elementValue);
    this._sectionModel.bpGeoTotal.elementValue = this.addComma(this._sectionModel.bpGeoTotal.elementValue);
    this._sectionModel.adlGeoTotal.elementValue = this.addComma(this._sectionModel.adlGeoTotal.elementValue);
    this._sectionModel.iadlGeoTotal.elementValue = this.addComma(this._sectionModel.iadlGeoTotal.elementValue);
    this._sectionModel.totalBelowPoverty.elementValue = this.addComma(this._sectionModel.totalBelowPoverty.elementValue);
    this._sectionModel.adlBelowPoverty.elementValue = this.addComma(this._sectionModel.adlBelowPoverty.elementValue);
    this._sectionModel.iadlBelowPoverty.elementValue = this.addComma(this._sectionModel.iadlBelowPoverty.elementValue);
    this._sectionModel.totalAbovePoverty.elementValue = this.addComma(this._sectionModel.totalAbovePoverty.elementValue);
    this._sectionModel.adlAbovePoverty.elementValue = this.addComma(this._sectionModel.adlAbovePoverty.elementValue);
    this._sectionModel.iadlAbovePoverty.elementValue = this.addComma(this._sectionModel.iadlAbovePoverty.elementValue);
    this._sectionModel.totalMissingPoverty.elementValue = this.addComma(this._sectionModel.totalMissingPoverty.elementValue);
    this._sectionModel.adlMissingPoverty.elementValue = this.addComma(this._sectionModel.adlMissingPoverty.elementValue);
    this._sectionModel.iadlMissingPoverty.elementValue = this.addComma(this._sectionModel.iadlMissingPoverty.elementValue);
    this._sectionModel.allPovertyTotal.elementValue = this.addComma(this._sectionModel.allPovertyTotal.elementValue);
    this._sectionModel.adlPovertyTotal.elementValue = this.addComma(this._sectionModel.adlPovertyTotal.elementValue);
    this._sectionModel.iadlPovertyTotal.elementValue = this.addComma(this._sectionModel.iadlPovertyTotal.elementValue);
    this._sectionModel.totalAlone.elementValue = this.addComma(this._sectionModel.totalAlone.elementValue);
    this._sectionModel.bpAlone.elementValue = this.addComma(this._sectionModel.bpAlone.elementValue);
    this._sectionModel.adlAlone.elementValue = this.addComma(this._sectionModel.adlAlone.elementValue);
    this._sectionModel.iadlAlone.elementValue = this.addComma(this._sectionModel.iadlAlone.elementValue);
    this._sectionModel.totalWithOthers.elementValue = this.addComma(this._sectionModel.totalWithOthers.elementValue);
    this._sectionModel.bpWithOthers.elementValue = this.addComma(this._sectionModel.bpWithOthers.elementValue);
    this._sectionModel.adlWithOthers.elementValue = this.addComma(this._sectionModel.adlWithOthers.elementValue);
    this._sectionModel.iadlWithOthers.elementValue = this.addComma(this._sectionModel.iadlWithOthers.elementValue);
    this._sectionModel.totalHouseholdMissing.elementValue = this.addComma(this._sectionModel.totalHouseholdMissing.elementValue);
    this._sectionModel.bpHouseholdMissing.elementValue = this.addComma(this._sectionModel.bpHouseholdMissing.elementValue);
    this._sectionModel.adlHouseholdMissing.elementValue = this.addComma(this._sectionModel.adlHouseholdMissing.elementValue);
    this._sectionModel.iadlHouseholdMissing.elementValue = this.addComma(this._sectionModel.iadlHouseholdMissing.elementValue);
    this._sectionModel.allHouseholdTotal.elementValue = this.addComma(this._sectionModel.allHouseholdTotal.elementValue);
    this._sectionModel.bpHouseholdTotal.elementValue = this.addComma(this._sectionModel.bpHouseholdTotal.elementValue);
    this._sectionModel.adlHouseholdTotal.elementValue = this.addComma(this._sectionModel.adlHouseholdTotal.elementValue);
    this._sectionModel.iadlHouseholdTotal.elementValue = this.addComma(this._sectionModel.iadlHouseholdTotal.elementValue);
    this._sectionModel.totalHispanic.elementValue = this.addComma(this._sectionModel.totalHispanic.elementValue);
    this._sectionModel.bpHispanic.elementValue = this.addComma(this._sectionModel.bpHispanic.elementValue);
    this._sectionModel.adlHispanic.elementValue = this.addComma(this._sectionModel.adlHispanic.elementValue);
    this._sectionModel.iadlHispanic.elementValue = this.addComma(this._sectionModel.iadlHispanic.elementValue);
    this._sectionModel.totalNonHispanic.elementValue = this.addComma(this._sectionModel.totalNonHispanic.elementValue);
    this._sectionModel.bpNonHispanic.elementValue = this.addComma(this._sectionModel.bpNonHispanic.elementValue);
    this._sectionModel.adlNonHispanic.elementValue = this.addComma(this._sectionModel.adlNonHispanic.elementValue);
    this._sectionModel.iadlNonHispanic.elementValue = this.addComma(this._sectionModel.iadlNonHispanic.elementValue);
    this._sectionModel.totalEthnicityMissing.elementValue = this.addComma(this._sectionModel.totalEthnicityMissing.elementValue);
    this._sectionModel.bpEthnicityMissing.elementValue = this.addComma(this._sectionModel.bpEthnicityMissing.elementValue);
    this._sectionModel.adlEthnicityMissing.elementValue = this.addComma(this._sectionModel.adlEthnicityMissing.elementValue);
    this._sectionModel.iadlEthnicityMissing.elementValue = this.addComma(this._sectionModel.iadlEthnicityMissing.elementValue);
    this._sectionModel.allEthnicityTotal.elementValue = this.addComma(this._sectionModel.allEthnicityTotal.elementValue);
    this._sectionModel.bpEthnicityTotal.elementValue = this.addComma(this._sectionModel.bpEthnicityTotal.elementValue);
    this._sectionModel.adlEthnicityTotal.elementValue = this.addComma(this._sectionModel.adlEthnicityTotal.elementValue);
    this._sectionModel.iadlEthnicityTotal.elementValue = this.addComma(this._sectionModel.iadlEthnicityTotal.elementValue);
    this._sectionModel.totalAmericanIndian.elementValue = this.addComma(this._sectionModel.totalAmericanIndian.elementValue);
    this._sectionModel.bpAmericanIndian.elementValue = this.addComma(this._sectionModel.bpAmericanIndian.elementValue);
    this._sectionModel.adlAmericanIndian.elementValue = this.addComma(this._sectionModel.adlAmericanIndian.elementValue);
    this._sectionModel.iadlAmericanIndian.elementValue = this.addComma(this._sectionModel.iadlAmericanIndian.elementValue);
    this._sectionModel.totalAsian.elementValue = this.addComma(this._sectionModel.totalAsian.elementValue);
    this._sectionModel.bpAsian.elementValue = this.addComma(this._sectionModel.bpAsian.elementValue);
    this._sectionModel.adlAsian.elementValue = this.addComma(this._sectionModel.adlAsian.elementValue);
    this._sectionModel.iadlAsian.elementValue = this.addComma(this._sectionModel.iadlAsian.elementValue);
    this._sectionModel.totalBlack.elementValue = this.addComma(this._sectionModel.totalBlack.elementValue);
    this._sectionModel.bpBlack.elementValue = this.addComma(this._sectionModel.bpBlack.elementValue);
    this._sectionModel.adlBlack.elementValue = this.addComma(this._sectionModel.adlBlack.elementValue);
    this._sectionModel.iadlBlack.elementValue = this.addComma(this._sectionModel.iadlBlack.elementValue);
    this._sectionModel.totalIslander.elementValue = this.addComma(this._sectionModel.totalIslander.elementValue);
    this._sectionModel.bpIslander.elementValue = this.addComma(this._sectionModel.bpIslander.elementValue);
    this._sectionModel.adlIslander.elementValue = this.addComma(this._sectionModel.adlIslander.elementValue);
    this._sectionModel.iadlIslander.elementValue = this.addComma(this._sectionModel.iadlIslander.elementValue);
    this._sectionModel.totalWhite.elementValue = this.addComma(this._sectionModel.totalWhite.elementValue);
    this._sectionModel.bpWhite.elementValue = this.addComma(this._sectionModel.bpWhite.elementValue);
    this._sectionModel.adlWhite.elementValue = this.addComma(this._sectionModel.adlWhite.elementValue);
    this._sectionModel.iadlWhite.elementValue = this.addComma(this._sectionModel.iadlWhite.elementValue);
    this._sectionModel.totalRaceOther.elementValue = this.addComma(this._sectionModel.totalRaceOther.elementValue);
    this._sectionModel.bpRaceOther.elementValue = this.addComma(this._sectionModel.bpRaceOther.elementValue);
    this._sectionModel.adlRaceOther.elementValue = this.addComma(this._sectionModel.adlRaceOther.elementValue);
    this._sectionModel.iadlRaceOther.elementValue = this.addComma(this._sectionModel.iadlRaceOther.elementValue);
    this._sectionModel.totalRaceMultiple.elementValue = this.addComma(this._sectionModel.totalRaceMultiple.elementValue);
    this._sectionModel.bpRaceMultiple.elementValue = this.addComma(this._sectionModel.bpRaceMultiple.elementValue);
    this._sectionModel.adlRaceMultiple.elementValue = this.addComma(this._sectionModel.adlRaceMultiple.elementValue);
    this._sectionModel.iadlRaceMultiple.elementValue = this.addComma(this._sectionModel.iadlRaceMultiple.elementValue);
    this._sectionModel.totalRaceMissing.elementValue = this.addComma(this._sectionModel.totalRaceMissing.elementValue);
    this._sectionModel.bpRaceMissing.elementValue = this.addComma(this._sectionModel.bpRaceMissing.elementValue);
    this._sectionModel.adlRaceMissing.elementValue = this.addComma(this._sectionModel.adlRaceMissing.elementValue);
    this._sectionModel.iadlRaceMissing.elementValue = this.addComma(this._sectionModel.iadlRaceMissing.elementValue);
    this._sectionModel.totalMinority.elementValue = this.addComma(this._sectionModel.totalMinority.elementValue);
    this._sectionModel.bpMinority.elementValue = this.addComma(this._sectionModel.bpMinority.elementValue);
    this._sectionModel.adlMinority.elementValue = this.addComma(this._sectionModel.adlMinority.elementValue);
    this._sectionModel.iadlMinority.elementValue = this.addComma(this._sectionModel.iadlMinority.elementValue);
    this._sectionModel.totalNotMinority.elementValue = this.addComma(this._sectionModel.totalNotMinority.elementValue);
    this._sectionModel.bpNotMinority.elementValue = this.addComma(this._sectionModel.bpNotMinority.elementValue);
    this._sectionModel.adlNotMinority.elementValue = this.addComma(this._sectionModel.adlNotMinority.elementValue);
    this._sectionModel.iadlNotMinority.elementValue = this.addComma(this._sectionModel.iadlNotMinority.elementValue);
    this._sectionModel.totalMinorityMissing.elementValue = this.addComma(this._sectionModel.totalMinorityMissing.elementValue);
    this._sectionModel.bpMinorityMissing.elementValue = this.addComma(this._sectionModel.bpMinorityMissing.elementValue);
    this._sectionModel.adlMinorityMissing.elementValue = this.addComma(this._sectionModel.adlMinorityMissing.elementValue);
    this._sectionModel.iadlMinorityMissing.elementValue = this.addComma(this._sectionModel.iadlMinorityMissing.elementValue);
    this._sectionModel.allMinorityTotal.elementValue = this.addComma(this._sectionModel.allMinorityTotal.elementValue);
    this._sectionModel.bpMinorityTotal.elementValue = this.addComma(this._sectionModel.bpMinorityTotal.elementValue);
    this._sectionModel.adlMinorityTotal.elementValue = this.addComma(this._sectionModel.adlMinorityTotal.elementValue);
    this._sectionModel.iadlMinorityTotal.elementValue = this.addComma(this._sectionModel.iadlMinorityTotal.elementValue);
    this._sectionModel.totalZeroLimitAdl.elementValue = this.addComma(this._sectionModel.totalZeroLimitAdl.elementValue);
    this._sectionModel.bpZeroAdl.elementValue = this.addComma(this._sectionModel.bpZeroAdl.elementValue);
    this._sectionModel.totalTwoLimitAdl.elementValue = this.addComma(this._sectionModel.totalTwoLimitAdl.elementValue);
    this._sectionModel.bpTwoLimitAdl.elementValue = this.addComma(this._sectionModel.bpTwoLimitAdl.elementValue);
    this._sectionModel.totalHigherLimitAdl.elementValue = this.addComma(this._sectionModel.totalHigherLimitAdl.elementValue);
    this._sectionModel.bpHigherLimitAdl.elementValue = this.addComma(this._sectionModel.bpHigherLimitAdl.elementValue);
    this._sectionModel.totalLimitMissingAdl.elementValue = this.addComma(this._sectionModel.totalLimitMissingAdl.elementValue);
    this._sectionModel.bpLimitMissingAdl.elementValue = this.addComma(this._sectionModel.bpLimitMissingAdl.elementValue);
    this._sectionModel.allLimitTotalAdl.elementValue = this.addComma(this._sectionModel.allLimitTotalAdl.elementValue);
    this._sectionModel.bpLimitTotalAdl.elementValue = this.addComma(this._sectionModel.bpLimitTotalAdl.elementValue);
    this._sectionModel.totalZeroLimitIadl.elementValue = this.addComma(this._sectionModel.totalZeroLimitIadl.elementValue);
    this._sectionModel.bpZeroIadl.elementValue = this.addComma(this._sectionModel.bpZeroIadl.elementValue);
    this._sectionModel.totalTwoLimitIadl.elementValue = this.addComma(this._sectionModel.totalTwoLimitIadl.elementValue);
    this._sectionModel.bpTwoLimitIadl.elementValue = this.addComma(this._sectionModel.bpTwoLimitIadl.elementValue);
    this._sectionModel.totalHigherLimitIadl.elementValue = this.addComma(this._sectionModel.totalHigherLimitIadl.elementValue);
    this._sectionModel.bpHigherLimitIadl.elementValue = this.addComma(this._sectionModel.bpHigherLimitIadl.elementValue);
    this._sectionModel.totalLimitMissingIadl.elementValue = this.addComma(this._sectionModel.totalLimitMissingIadl.elementValue);
    this._sectionModel.bpLimitMissingIadl.elementValue = this.addComma(this._sectionModel.bpLimitMissingIadl.elementValue);
    this._sectionModel.allLimitTotalIadl.elementValue = this.addComma(this._sectionModel.allLimitTotalIadl.elementValue);
    this._sectionModel.bpLimitTotalIadl.elementValue = this.addComma(this._sectionModel.bpLimitTotalIadl.elementValue);

  }

  ageError(): boolean {
    if (this._sectionModel.totalBelow60.dataEntryErrorMessage ||
      this._sectionModel.bpBelow60.dataEntryErrorMessage ||
      this._sectionModel.adlBelow60.dataEntryErrorMessage ||
      this._sectionModel.iadlBelow60.dataEntryErrorMessage ||
      this._sectionModel.totalRange1.dataEntryErrorMessage ||
      this._sectionModel.bpRange1.dataEntryErrorMessage ||
      this._sectionModel.adlRange1.dataEntryErrorMessage ||
      this._sectionModel.iadlRange1.dataEntryErrorMessage ||
      this._sectionModel.totalRange2.dataEntryErrorMessage ||
      this._sectionModel.bpRange2.dataEntryErrorMessage ||
      this._sectionModel.adlRange2.dataEntryErrorMessage ||
      this._sectionModel.iadlRange2.dataEntryErrorMessage ||
      this._sectionModel.totalRange3.dataEntryErrorMessage ||
      this._sectionModel.bpRange3.dataEntryErrorMessage ||
      this._sectionModel.adlRange3.dataEntryErrorMessage ||
      this._sectionModel.iadlRange3.dataEntryErrorMessage ||
      this._sectionModel.totalRange4.dataEntryErrorMessage ||
      this._sectionModel.bpRange4.dataEntryErrorMessage ||
      this._sectionModel.adlRange4.dataEntryErrorMessage ||
      this._sectionModel.iadlRange4.dataEntryErrorMessage ||
      this._sectionModel.totalAgeMissing.dataEntryErrorMessage ||
      this._sectionModel.bpAgeMissing.dataEntryErrorMessage ||
      this._sectionModel.adlAgeMissing.dataEntryErrorMessage ||
      this._sectionModel.iadlAgeMissing.dataEntryErrorMessage ||
      this._sectionModel.allAgeTotal.dataEntryErrorMessage ||
      this._sectionModel.bpAgeTotal.dataEntryErrorMessage ||
      this._sectionModel.adlAgeTotal.dataEntryErrorMessage ||
      this._sectionModel.iadlAgeTotal.dataEntryErrorMessage ||
      this._sectionModel.totalBelow60.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpBelow60.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlBelow60.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlBelow60.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange1.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange1.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlRange1.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlRange1.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange2.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange2.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlRange2.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlRange2.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange3.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange3.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlRange3.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlRange3.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange4.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange4.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlRange4.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlRange4.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalAgeMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAgeMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlAgeMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlAgeMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allAgeTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAgeTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlAgeTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlAgeTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  genderError(): boolean {
    if (this._sectionModel.totalFemale.dataEntryErrorMessage ||
      this._sectionModel.bpFemale.dataEntryErrorMessage ||
      this._sectionModel.adlFemale.dataEntryErrorMessage ||
      this._sectionModel.iadlFemale.dataEntryErrorMessage ||
      this._sectionModel.totalMale.dataEntryErrorMessage ||
      this._sectionModel.bpMale.dataEntryErrorMessage ||
      this._sectionModel.adlMale.dataEntryErrorMessage ||
      this._sectionModel.iadlMale.dataEntryErrorMessage ||
      this._sectionModel.totalOther.dataEntryErrorMessage ||
      this._sectionModel.bpOther.dataEntryErrorMessage ||
      this._sectionModel.adlOther.dataEntryErrorMessage ||
      this._sectionModel.iadlOther.dataEntryErrorMessage ||
      this._sectionModel.totalGenderMissing.dataEntryErrorMessage ||
      this._sectionModel.bpGenderMissing.dataEntryErrorMessage ||
      this._sectionModel.adlGenderMissing.dataEntryErrorMessage ||
      this._sectionModel.iadlGenderMissing.dataEntryErrorMessage ||
      this._sectionModel.allGenderTotal.dataEntryErrorMessage ||
      this._sectionModel.bpGenderTotal.dataEntryErrorMessage ||
      this._sectionModel.adlGenderTotal.dataEntryErrorMessage ||
      this._sectionModel.iadlGenderTotal.dataEntryErrorMessage ||
      this._sectionModel.totalFemale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpFemale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlFemale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlFemale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalMale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlMale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlMale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalGenderMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGenderMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlGenderMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlGenderMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allGenderTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGenderTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlGenderTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlGenderTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  geoError(): boolean {
    if (this._sectionModel.totalRural.dataEntryErrorMessage ||
      this._sectionModel.bpRural.dataEntryErrorMessage ||
      this._sectionModel.adlRural.dataEntryErrorMessage ||
      this._sectionModel.iadlRural.dataEntryErrorMessage ||
      this._sectionModel.totalNonRural.dataEntryErrorMessage ||
      this._sectionModel.bpNonRural.dataEntryErrorMessage ||
      this._sectionModel.adlNonRural.dataEntryErrorMessage ||
      this._sectionModel.iadlNonRural.dataEntryErrorMessage ||
      this._sectionModel.totalGeoMissing.dataEntryErrorMessage ||
      this._sectionModel.bpGeoMissing.dataEntryErrorMessage ||
      this._sectionModel.adlGeoMissing.dataEntryErrorMessage ||
      this._sectionModel.iadlGeoMissing.dataEntryErrorMessage ||
      this._sectionModel.allGeoTotal.dataEntryErrorMessage ||
      this._sectionModel.bpGeoTotal.dataEntryErrorMessage ||
      this._sectionModel.adlGeoTotal.dataEntryErrorMessage ||
      this._sectionModel.iadlGeoTotal.dataEntryErrorMessage ||
      this._sectionModel.totalRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalNonRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNonRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlNonRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlNonRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalGeoMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGeoMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlGeoMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlGeoMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allGeoTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGeoTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlGeoTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlGeoTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  povertyError(): boolean {
    if (this._sectionModel.totalBelowPoverty.dataEntryErrorMessage ||
      this._sectionModel.adlBelowPoverty.dataEntryErrorMessage ||
      this._sectionModel.iadlBelowPoverty.dataEntryErrorMessage ||
      this._sectionModel.totalAbovePoverty.dataEntryErrorMessage ||
      this._sectionModel.adlAbovePoverty.dataEntryErrorMessage ||
      this._sectionModel.iadlAbovePoverty.dataEntryErrorMessage ||
      this._sectionModel.totalMissingPoverty.dataEntryErrorMessage ||
      this._sectionModel.adlMissingPoverty.dataEntryErrorMessage ||
      this._sectionModel.iadlMissingPoverty.dataEntryErrorMessage ||
      this._sectionModel.allPovertyTotal.dataEntryErrorMessage ||
      this._sectionModel.adlPovertyTotal.dataEntryErrorMessage ||
      this._sectionModel.iadlPovertyTotal.dataEntryErrorMessage ||
      this._sectionModel.totalBelowPoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlBelowPoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlBelowPoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalAbovePoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlAbovePoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlAbovePoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalMissingPoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlMissingPoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlMissingPoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allPovertyTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlPovertyTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlPovertyTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  householdError(): boolean {
    if (this._sectionModel.totalAlone.dataEntryErrorMessage ||
      this._sectionModel.bpAlone.dataEntryErrorMessage ||
      this._sectionModel.adlAlone.dataEntryErrorMessage ||
      this._sectionModel.iadlAlone.dataEntryErrorMessage ||
      this._sectionModel.totalWithOthers.dataEntryErrorMessage ||
      this._sectionModel.bpWithOthers.dataEntryErrorMessage ||
      this._sectionModel.adlWithOthers.dataEntryErrorMessage ||
      this._sectionModel.iadlWithOthers.dataEntryErrorMessage ||
      this._sectionModel.totalHouseholdMissing.dataEntryErrorMessage ||
      this._sectionModel.bpHouseholdMissing.dataEntryErrorMessage ||
      this._sectionModel.adlHouseholdMissing.dataEntryErrorMessage ||
      this._sectionModel.iadlHouseholdMissing.dataEntryErrorMessage ||
      this._sectionModel.allHouseholdTotal.dataEntryErrorMessage ||
      this._sectionModel.bpHouseholdTotal.dataEntryErrorMessage ||
      this._sectionModel.adlHouseholdTotal.dataEntryErrorMessage ||
      this._sectionModel.iadlHouseholdTotal.dataEntryErrorMessage ||
      this._sectionModel.totalAlone.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAlone.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlAlone.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlAlone.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalWithOthers.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpWithOthers.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlWithOthers.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlWithOthers.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalHouseholdMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpHouseholdMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlHouseholdMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlHouseholdMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allHouseholdTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpHouseholdTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlHouseholdTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlHouseholdTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  ethnicityError(): boolean {
    if (this._sectionModel.totalHispanic.dataEntryErrorMessage ||
      this._sectionModel.bpHispanic.dataEntryErrorMessage ||
      this._sectionModel.adlHispanic.dataEntryErrorMessage ||
      this._sectionModel.iadlHispanic.dataEntryErrorMessage ||
      this._sectionModel.totalNonHispanic.dataEntryErrorMessage ||
      this._sectionModel.bpNonHispanic.dataEntryErrorMessage ||
      this._sectionModel.adlNonHispanic.dataEntryErrorMessage ||
      this._sectionModel.iadlNonHispanic.dataEntryErrorMessage ||
      this._sectionModel.totalEthnicityMissing.dataEntryErrorMessage ||
      this._sectionModel.bpEthnicityMissing.dataEntryErrorMessage ||
      this._sectionModel.adlEthnicityMissing.dataEntryErrorMessage ||
      this._sectionModel.iadlEthnicityMissing.dataEntryErrorMessage ||
      this._sectionModel.allEthnicityTotal.dataEntryErrorMessage ||
      this._sectionModel.bpEthnicityTotal.dataEntryErrorMessage ||
      this._sectionModel.adlEthnicityTotal.dataEntryErrorMessage ||
      this._sectionModel.iadlEthnicityTotal.dataEntryErrorMessage ||
      this._sectionModel.totalHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalNonHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNonHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlNonHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlNonHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalEthnicityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpEthnicityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlEthnicityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlEthnicityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allEthnicityTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpEthnicityTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlEthnicityTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlEthnicityTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  raceError(): boolean {
    if (
      this._sectionModel.totalAmericanIndian.dataEntryErrorMessage ||
      this._sectionModel.bpAmericanIndian.dataEntryErrorMessage ||
      this._sectionModel.adlAmericanIndian.dataEntryErrorMessage ||
      this._sectionModel.iadlAmericanIndian.dataEntryErrorMessage ||
      this._sectionModel.totalAsian.dataEntryErrorMessage ||
      this._sectionModel.bpAsian.dataEntryErrorMessage ||
      this._sectionModel.adlAsian.dataEntryErrorMessage ||
      this._sectionModel.iadlAsian.dataEntryErrorMessage ||
      this._sectionModel.totalBlack.dataEntryErrorMessage ||
      this._sectionModel.bpBlack.dataEntryErrorMessage ||
      this._sectionModel.adlBlack.dataEntryErrorMessage ||
      this._sectionModel.iadlBlack.dataEntryErrorMessage ||
      this._sectionModel.totalIslander.dataEntryErrorMessage ||
      this._sectionModel.bpIslander.dataEntryErrorMessage ||
      this._sectionModel.adlIslander.dataEntryErrorMessage ||
      this._sectionModel.iadlIslander.dataEntryErrorMessage ||
      this._sectionModel.totalWhite.dataEntryErrorMessage ||
      this._sectionModel.bpWhite.dataEntryErrorMessage ||
      this._sectionModel.adlWhite.dataEntryErrorMessage ||
      this._sectionModel.iadlWhite.dataEntryErrorMessage ||
      this._sectionModel.totalRaceOther.dataEntryErrorMessage ||
      this._sectionModel.bpRaceOther.dataEntryErrorMessage ||
      this._sectionModel.adlRaceOther.dataEntryErrorMessage ||
      this._sectionModel.iadlRaceOther.dataEntryErrorMessage ||
      this._sectionModel.totalRaceMultiple.dataEntryErrorMessage ||
      this._sectionModel.bpRaceMultiple.dataEntryErrorMessage ||
      this._sectionModel.adlRaceMultiple.dataEntryErrorMessage ||
      this._sectionModel.iadlRaceMultiple.dataEntryErrorMessage ||
      this._sectionModel.totalRaceMissing.dataEntryErrorMessage ||
      this._sectionModel.bpRaceMissing.dataEntryErrorMessage ||
      this._sectionModel.adlRaceMissing.dataEntryErrorMessage ||
      this._sectionModel.iadlRaceMissing.dataEntryErrorMessage ||
      this._sectionModel.totalAmericanIndian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAmericanIndian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlAmericanIndian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlAmericanIndian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalAsian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAsian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlAsian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlAsian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalBlack.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpBlack.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlBlack.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlBlack.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalIslander.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpIslander.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlIslander.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlIslander.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalWhite.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpWhite.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlWhite.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlWhite.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRaceOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRaceOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlRaceOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlRaceOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRaceMultiple.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRaceMultiple.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlRaceMultiple.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlRaceMultiple.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRaceMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRaceMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlRaceMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlRaceMissing.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  minorityError(): boolean {
    if (this._sectionModel.totalMinority.dataEntryErrorMessage ||
      this._sectionModel.bpMinority.dataEntryErrorMessage ||
      this._sectionModel.adlMinority.dataEntryErrorMessage ||
      this._sectionModel.iadlMinority.dataEntryErrorMessage ||
      this._sectionModel.totalNotMinority.dataEntryErrorMessage ||
      this._sectionModel.bpNotMinority.dataEntryErrorMessage ||
      this._sectionModel.adlNotMinority.dataEntryErrorMessage ||
      this._sectionModel.iadlNotMinority.dataEntryErrorMessage ||
      this._sectionModel.totalMinorityMissing.dataEntryErrorMessage ||
      this._sectionModel.bpMinorityMissing.dataEntryErrorMessage ||
      this._sectionModel.adlMinorityMissing.dataEntryErrorMessage ||
      this._sectionModel.iadlMinorityMissing.dataEntryErrorMessage ||
      this._sectionModel.allMinorityTotal.dataEntryErrorMessage ||
      this._sectionModel.bpMinorityTotal.dataEntryErrorMessage ||
      this._sectionModel.adlMinorityTotal.dataEntryErrorMessage ||
      this._sectionModel.iadlMinorityTotal.dataEntryErrorMessage ||
      this._sectionModel.totalMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalNotMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNotMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlNotMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlNotMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalMinorityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMinorityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlMinorityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlMinorityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allMinorityTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMinorityTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.adlMinorityTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.iadlMinorityTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  adlLimitError(): boolean {
    if (this._sectionModel.totalZeroLimitAdl.dataEntryErrorMessage ||
      this._sectionModel.bpZeroAdl.dataEntryErrorMessage ||
      this._sectionModel.totalTwoLimitAdl.dataEntryErrorMessage ||
      this._sectionModel.bpTwoLimitAdl.dataEntryErrorMessage ||
      this._sectionModel.totalHigherLimitAdl.dataEntryErrorMessage ||
      this._sectionModel.bpHigherLimitAdl.dataEntryErrorMessage ||
      this._sectionModel.totalLimitMissingAdl.dataEntryErrorMessage ||
      this._sectionModel.bpLimitMissingAdl.dataEntryErrorMessage ||
      this._sectionModel.allLimitTotalAdl.dataEntryErrorMessage ||
      this._sectionModel.bpLimitTotalAdl.dataEntryErrorMessage ||
      this._sectionModel.totalZeroLimitAdl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpZeroAdl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalTwoLimitAdl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpTwoLimitAdl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalHigherLimitAdl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpHigherLimitAdl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalLimitMissingAdl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpLimitMissingAdl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allLimitTotalAdl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpLimitTotalAdl.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  iadlLimitError(): boolean {
    if (this._sectionModel.totalZeroLimitIadl.dataEntryErrorMessage ||
      this._sectionModel.bpZeroIadl.dataEntryErrorMessage ||
      this._sectionModel.totalTwoLimitIadl.dataEntryErrorMessage ||
      this._sectionModel.bpTwoLimitIadl.dataEntryErrorMessage ||
      this._sectionModel.totalHigherLimitIadl.dataEntryErrorMessage ||
      this._sectionModel.bpHigherLimitIadl.dataEntryErrorMessage ||
      this._sectionModel.totalLimitMissingIadl.dataEntryErrorMessage ||
      this._sectionModel.bpLimitMissingIadl.dataEntryErrorMessage ||
      this._sectionModel.allLimitTotalIadl.dataEntryErrorMessage ||
      this._sectionModel.bpLimitTotalIadl.dataEntryErrorMessage ||
      this._sectionModel.totalZeroLimitIadl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpZeroIadl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalTwoLimitIadl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpTwoLimitIadl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalHigherLimitIadl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpHigherLimitIadl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalLimitMissingIadl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpLimitMissingIadl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allLimitTotalIadl.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpLimitTotalIadl.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  ageWarning(): boolean {
    if (this._sectionModel.totalBelow60.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpBelow60.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlBelow60.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlBelow60.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange1.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange1.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlRange1.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlRange1.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange3.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange3.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlRange3.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlRange3.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalAgeMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAgeMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlAgeMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlAgeMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allAgeTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAgeTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlAgeTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlAgeTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  genderWarning(): boolean {
    if (this._sectionModel.totalFemale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpFemale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlFemale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlFemale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalMale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlMale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlMale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalGenderMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGenderMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlGenderMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlGenderMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allGenderTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGenderTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlGenderTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlGenderTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }
  geoWarning(): boolean {
    if (this._sectionModel.totalRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalNonRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNonRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlNonRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlNonRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalGeoMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGeoMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlGeoMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlGeoMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allGeoTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGeoTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlGeoTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlGeoTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  povertyWarning(): boolean {
    if (this._sectionModel.totalBelowPoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlBelowPoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlBelowPoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalAbovePoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlAbovePoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlAbovePoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalMissingPoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlMissingPoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlMissingPoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allPovertyTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlPovertyTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlPovertyTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  householdWarning(): boolean {
    if (this._sectionModel.totalAlone.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAlone.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlAlone.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlAlone.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalWithOthers.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpWithOthers.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlWithOthers.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlWithOthers.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalHouseholdMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpHouseholdMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlHouseholdMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlHouseholdMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allHouseholdTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpHouseholdTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlHouseholdTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlHouseholdTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  ethnicityWarning(): boolean {
    if (this._sectionModel.totalHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalNonHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNonHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlNonHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlNonHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalEthnicityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpEthnicityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlEthnicityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlEthnicityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allEthnicityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpEthnicityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlEthnicityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlEthnicityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  raceWarning(): boolean {
    if (this._sectionModel.totalAmericanIndian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAmericanIndian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlAmericanIndian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlAmericanIndian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalAsian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAsian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlAsian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlAsian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalBlack.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpBlack.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlBlack.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlBlack.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalIslander.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpIslander.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlIslander.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlIslander.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalWhite.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpWhite.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlWhite.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlWhite.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRaceOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRaceOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlRaceOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlRaceOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRaceMultiple.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRaceMultiple.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlRaceMultiple.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlRaceMultiple.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRaceMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRaceMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlRaceMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlRaceMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  minorityWarning(): boolean {
    if (this._sectionModel.totalMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalNotMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNotMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlNotMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlNotMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalMinorityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMinorityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlMinorityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlMinorityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allMinorityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMinorityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.adlMinorityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.iadlMinorityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  adlLimitWarning(): boolean {
    if (this._sectionModel.totalZeroLimitAdl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpZeroAdl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalTwoLimitAdl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpTwoLimitAdl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalHigherLimitAdl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpHigherLimitAdl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalLimitMissingAdl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpLimitMissingAdl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allLimitTotalAdl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpLimitTotalAdl.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  iadlLimitWarning(): boolean {
    if (this._sectionModel.totalZeroLimitIadl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpZeroIadl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalTwoLimitIadl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpTwoLimitIadl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalHigherLimitIadl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpHigherLimitIadl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalLimitMissingIadl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpLimitMissingIadl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allLimitTotalIadl.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpLimitTotalIadl.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  validate(data: DataElement): DataElement {
    if (data.elementValue != null && data.elementValue !== '') {
      if (this._validation.validate(data.validationType, data.elementValue)) {
        if (!this._validation.validateMaxLength(data.elementValue, data.maxLength)) {
          data.dataEntryErrorMessage = 'Value cannot be greater than ' + data.maxLength + ' digits.';
          data.questionStatus = this.statusEnum.L1InProgressError;
        } else {
          data.dataEntryErrorMessage = '';
          data.questionStatus = this.statusEnum.L1InProgress;
        }
      } else {
        data.dataEntryErrorMessage = this._validation.getErrorMessage(data.validationType);
        data.questionStatus = this.statusEnum.L1InProgressError;
      }
    } else {
      data.dataEntryErrorMessage = '';
      data.questionStatus = this.statusEnum.NotStarted;
    }
    return data;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.oaLockedNav = false;
      return true;
    } else {
      this._commonService.oaLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

}
