export class AddOnOptions {
    text: string;
    width: number;
    isPrefix?: boolean = false;
}

export const AddOnSmall = 50;
export const AddOnMedium = 100;
export const AddOnLarge = 125;
export const AddOnXLarge = 150;
