import { Component, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { HealthPromoNonEvidenceSection } from './HealthPromoNonEvidenceSection';
import { SectionLookup } from '../shared/sectionLookup';
import { CommonService } from '../shared/common.service';
import { ToastrService } from '../shared/toastr.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { ValidationService } from '../shared/validation.service';
import { TitleIIISectionComponent } from './title-iii-section.component';
import { AddOnOptions } from '../shared/Model/addOnOptions';

@Component({
  templateUrl: 'titleIIIHealthPromoNonEvidence.component.html',
})

export class TitleIIIHealthPromoNonEvidenceComponent extends TitleIIISectionComponent<HealthPromoNonEvidenceSection> implements OnInit, OnDestroy {

  _sectionModel: HealthPromoNonEvidenceSection;
  _uploadModels: Array<HealthPromoNonEvidenceSection>;
  _batchIds: Array<string>;
  unsavedChanges: boolean = false;
  questionStatusEnum = QuestionStatusEnum;

  public get addOnOption(): AddOnOptions {
    return {
      text: '$',
      width: 20,
      isPrefix: true
    };
  }

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public _commonService: CommonService,
    public toastr: ToastrService
  ) {
    super(new HealthPromoNonEvidenceSection(), _commonService, _validation, router, _claimService, _service);
   }

  ngOnInit(): void {
    this._titleService.setTitle('Older Adults - Health Promotion: Non Evidence-based - Data Submissions - ACL OAAPS');
    this._sectionModel = new HealthPromoNonEvidenceSection();
    this.gethealthpromononevidencebyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    super.registerSubscription(this._service.savehealthpromononevidence(this._sectionModel)
      .subscribe(data => this.zone.run(() => {
        this._sectionModel = data;
      }),
      (error)=> console.log(error),
      ()=>{
        callback();

        this.toastr.success('Saved!');
      }
      ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIIOtherServices']);
    if (!this._claimService.isViewOnly && 
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  next(): void {
    this.router.navigate(['/data-submissions/titleIIIOtherServices']);
}

  gethealthpromononevidencebyffyandstate(): void {
    super.registerSubscription(this._service.get<HealthPromoNonEvidenceSection[]>(this._claimService,
      this._service._gethealthpromononevidencebyffyandstate, this.resolveSectionName())
      .subscribe(data => this.zone.run(() => {
        if (data.length > 0) {
          // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
          let tempSectionModel: HealthPromoNonEvidenceSection = new HealthPromoNonEvidenceSection();
          tempSectionModel = data.filter(element => element.batchId === '00000000-0000-0000-0000-000000000000')[0];
          if (tempSectionModel) {
            this._sectionModel = tempSectionModel;
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
        }

      })));
  }

  checkUserResponsibility() {
    let calculateTotals = false;
    if (this._sectionModel.peopleServed.isRequired &&
      this.checkUserExpenditureResponsibility()) {
      calculateTotals = true; // User is responsible for everything
    } else {
      this._sectionModel.expendPerUnit.elementValue = 'N/A';
    }
    return calculateTotals;
  }

  checkUserExpenditureResponsibility() {
    return this._sectionModel.title3Expend.isRequired &&
      this._sectionModel.otherStateExpend.isRequired &&
      this._sectionModel.nonStateExpend.isRequired &&
      this._sectionModel.programIncome.isRequired;
  }


  updateTotals(): void {
    let grandTotal = 0;
    const peopleServed = parseInt(this.removeCommas(this._sectionModel.peopleServed.elementValue));
    if (this.checkUserExpenditureResponsibility()) {
      const title3Expend = parseFloat(this.removeCommas(this._sectionModel.title3Expend.elementValue));
      const otherStateExpend = parseFloat(this.removeCommas(this._sectionModel.otherStateExpend.elementValue));
      const nonStateExpend = parseFloat(this.removeCommas(this._sectionModel.nonStateExpend.elementValue));
      const programIncome = parseFloat(this.removeCommas(this._sectionModel.programIncome.elementValue));
      if (!isNaN(title3Expend)) { grandTotal += title3Expend; }
      if (!isNaN(otherStateExpend)) { grandTotal += otherStateExpend; }
      if (!isNaN(nonStateExpend)) { grandTotal += nonStateExpend; }
      if (!isNaN(programIncome)) { grandTotal += programIncome; }
      this._sectionModel.totalExpend.elementValue = this.addDecimals(grandTotal.toString());
    } else {
      this._sectionModel.totalExpend.elementValue = 'N/A';
    }
    if (this.checkUserResponsibility()) {
      this._sectionModel.expendPerUnit.elementValue = this.expendPerUnit(grandTotal, peopleServed);
      this._sectionModel.expendPerUnit.questionStatus = this.questionStatusEnum.L1InProgress;
    }
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.peopleServed.elementValue = this.addComma(this._sectionModel.peopleServed.elementValue);
    this._sectionModel.title3Expend.elementValue = this.addDecimals(this._sectionModel.title3Expend.elementValue);
    this._sectionModel.otherStateExpend.elementValue = this.addDecimals(this._sectionModel.otherStateExpend.elementValue);
    this._sectionModel.nonStateExpend.elementValue = this.addDecimals(this._sectionModel.nonStateExpend.elementValue);
    this._sectionModel.programIncome.elementValue = this.addDecimals(this._sectionModel.programIncome.elementValue);
    this._sectionModel.totalExpend.elementValue = this.addDecimals(this._sectionModel.totalExpend.elementValue);
    this._sectionModel.expendPerUnit.elementValue = this.addDecimals(this._sectionModel.expendPerUnit.elementValue);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.oaLockedNav = false;
      return true;
    } else {
      this._commonService.oaLockedNav = true;
      return false;
    }
  }

  validate(data: DataElement): DataElement {
    if (data.elementValue != null && data.elementValue !== '') {
      if (this._validation.validate(data.validationType, data.elementValue)) {
        data.dataEntryErrorMessage = '';
        data.questionStatus = this.questionStatusEnum.L1InProgress;
      } else {
        data.dataEntryErrorMessage = this._validation.getErrorMessage(data.validationType);
        data.questionStatus = this.questionStatusEnum.L1InProgressError;
      }
    } else {
      data.dataEntryErrorMessage = '';
      data.questionStatus = this.questionStatusEnum.NotStarted;
    }
    return data;
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

}
