import { QuestionStatusEnum } from '../dataElement';
import { Link } from './link';

export class SectionLink extends Link {
    constructor(public sectionName: string,
        public status: QuestionStatusEnum,
        public title: string,
        public path: string,
        public sortOrder: number,
    ) {
        super(title, path);
    }
}
