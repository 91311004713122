import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { HttpClientModule } from '@angular/common/http';
import { HelpService } from './help.service';
import { EmailComponent } from './email/email.component';
import { BannerComponent } from './banner/banner.component';

@NgModule({
  declarations: [EmailComponent, BannerComponent],
  imports: [
    CommonModule,
    // HttpClientModule
  ],
  providers: [
    HelpService
  ],
  exports: [EmailComponent, BannerComponent]
})
export class HelpModule { }
