import * as _ from 'lodash-es';
import { Pipe, PipeTransform } from '@angular/core';
import { DataFilter } from './dataFilter';

@Pipe({
  name: 'dataFilter',
  pure: false
})
export class DataFilterPipe implements PipeTransform {
  // Only return rows that START with the given filter phrase. By doing it at the start,
  // this avoids subsets (i.e. "Older Adults" filter phrase returning results from "Caregivers of Older Adults")
  transform(array: any[], filters: DataFilter): any {
    let ret = array;
    if (filters && filters.query) {
      ret = _.filter(ret, row => row.componentFriendlyName.indexOf(filters.query) == 0);
    }
    if (filters && filters.errorType) {
      if (filters.errorType == 'warning') {
        ret = _.filter(ret, row => row.status == 4);
      } else {
        ret = _.filter(ret, row => (row.status == 1 || row.status == 2));
      }
    }
    if (filters && filters.sectionName) {
      if (filters.sectionName == 'SupportiveServicesOverview') {
        ret = _.filter(ret, row => (
          row.sectionName.indexOf('accessServices') == 0
          || row.sectionName.indexOf('inHomeServices') == 0
          || row.sectionName.indexOf('otherServices') == 0
        ));
      } else if (filters.sectionName == 'CaregiverSupportServicesOverview') {
        ret = _.filter(ret, row => (
          row.sectionName.indexOf('caregiverServices') == 0
          || row.sectionName.indexOf('suppServices') == 0
          || row.sectionName.indexOf('respite') == 0
        ));
      } else {
        ret = _.filter(ret, row => row.sectionName.indexOf(filters.sectionName) == 0);
      }
    }
    return ret;
  }
}
