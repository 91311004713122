<!-- begin main content -->
<div class="container" role="main" id="main-content">
    <div class="row">
      <div class="col-md-12 acl-container">
        <!-- left navigation -->
        <sideNav #sideNav></sideNav>
        <!-- .end left navigation -->
        <div class="col-md-9">
          <div class="col-md-9">
            <h2>
              <i class="fa fa-upload" aria-hidden="true"></i>Upload an Attachment</h2>
            <p class="mt-1 help-block">All fields are required unless indicated as optional</p>
            <div class="background-gray-lighter p-1">
              <div class="acl-container mb-1 p-1">
                  
                <form [formGroup]="form">
                    <div class="form-group">
                      <p class="bold text-secondary">Select file to attach</p>
                      <div class="input-group">
                          <label id="uploadFileLabel" class="input-group-btn" aria-label="browse for file" for="uploadFileButton">
                          <span (keydown.enter)="uploadFileClicked()" class="btn btn-primary uploader" tabindex="0">
                              Browse&hellip;
                              <input id="uploadFileButton" type="file" style="display: none;" (change)="fileChangeEvent($event)">
                          </span>
                          </label>
                          <!-- PLEASE SEE ACLFUNCTIONS.JS FILE FOR FUNCTIONALITY -->
                          <input id="inputFilePath" type="text" class="form-control" readonly aria-label="browse for file extended control"
                          value="{{fileName}}">                       
                      </div>
                      Accepted file types: .docx, .pdf, .csv, .xls, .xlsx, .xml
                    </div>
                    <div>
                        <label for="categoryType">Category Type</label>
                        <select id="categoryType" formControlName="categoryType" class="form-control" (change)="categoryChangeEvent()"> 
                            <option value="Upload File">Upload File</option>
                            <option value="Supplemental Funding">Supplemental Funding</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div>
                    <label for="comments">
                        Comments (optional)
                    </label>
                    <textarea formControlName="comments" class="form-control" id="comments"></textarea>                    
                    </div>
                    <button class="btn btn-primary mt-1 mb-1 mr-1" id="uploadFile" [disabled]="_claimService.isViewOnly || !isReadyForUpload"
                    (click)="upload()">
                    Upload file
                    </button>
                    <button class="btn btn-link mt-1 mb-1" id="cancel" (click)="navCancel()">
                    Cancel
                    </button>  
                </form>
              </div>
              <!-- .end acl-container -->
            </div>
          </div>
          <!-- .end col-md-8 -->
  
        </div>
        <!-- .end col-md-9 -->
      </div>
      <!-- .end acl-container (col-md-12) -->
    </div>
    <!-- .end row -->
  </div>
  <!-- ./container -->
  