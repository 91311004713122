<loading #loadingSpinner></loading>
<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
    <div class="row">
        <div class="col-md-12 acl-container">
            <!-- left navigation -->
            <sideNav></sideNav>
            <!-- .end left navigation -->
            <div class="col-md-9">
                <h2>Title VII Expenditures</h2>
                <p class="info mt-1">All fields are required</p>
                <div class="col-md-12 background-gray-lighter mb-1">
                    <h2>Title VII Chapter 3 - Elder Abuse Prevention</h2>
                </div>
                <!--THIS FIELD IS NOT APPLICABLE TO SINGLE PSA STATES-->
                <div class="col-md-9">
                    <label for="suaExpend" class="normal">Funds expended by State Unit on Aging (SUA)</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [addOnOptions]="addOnOption" [id]="'suaExpend'" [de]="_sectionModel.suaExpend"
                    (change)="updateTotals(); onChange()">
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.suaExpend"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="aaaExpend" class="normal">Funds expended by Area Agency on Aging (AAA)</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [addOnOptions]="addOnOption" [id]="'aaaExpend'" [de]="_sectionModel.aaaExpend"
                    (change)="updateTotals(); onChange()">
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.aaaExpend"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="apsExpend" class="normal">Funds expended by Abuse Prevention Services (APS)</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [addOnOptions]="addOnOption" [id]="'apsExpend'" [de]="_sectionModel.apsExpend"
                    (change)="updateTotals(); onChange()">
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.apsExpend"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="otherEntitiesExpend" class="normal">Funds expended by other entities</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [addOnOptions]="addOnOption" [id]="'otherEntitiesExpend'" [de]="_sectionModel.otherEntitiesExpend"
                    (change)="updateTotals(); onChange()">
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.otherEntitiesExpend"></acl-input-error>
                </div>
                <div class="col-md-9"></div>
                <div class="col-md-3 mb-1"></div>
                <div class="col-md-9"></div>
                <div class="col-md-3 mb-1"></div>
                <div class="col-md-9">
                    <label for="vii3Total" class="normal">Total Title VII-3 expenditure
                  <br />
                  <strong>(Total of SUA, AAA, APS and other entities from above)</strong>
                  </label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [addOnOptions]="addOnOption" [id]="'vii3Total'" [de]="_sectionModel.vii3Total"
                    [disabled]=true>
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.vii3Total"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="otherStateExpend" class="normal">Other: State expenditure</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [addOnOptions]="addOnOption" [id]="'otherStateExpend'" [de]="_sectionModel.otherStateExpend"
                    (change)="updateTotals(); onChange()">
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.otherStateExpend"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="nonStateExpend" class="normal">Other: Non-state expenditure</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [addOnOptions]="addOnOption" [id]="'nonStateExpend'" [de]="_sectionModel.nonStateExpend"
                    (change)="updateTotals(); onChange()">
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.nonStateExpend"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="chapter3Total">Total expenditures</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [addOnOptions]="addOnOption" [id]="'chapter3Total'" [de]="_sectionModel.chapter3Total"
                    [disabled]=true>
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.chapter3Total"></acl-input-error>
                </div>


                <div class="col-md-12 background-gray-lighter mb-1">
                    <h2>Title VII Chapter 4 - Legal Assistance Development</h2>
                </div>
                <div class="col-md-9">
                    <label for="chapter4Total" class="normal">Title VII Chapter 4 expenditures</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [addOnOptions]="addOnOption" [id]="'chapter4Total'" [de]="_sectionModel.chapter4Total"
                    (change)="updateTotals(); onChange()">
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.chapter4Total"></acl-input-error>
                </div>

                <div class="col-md-12 row">
                    <div *ngIf="!validForm" class="has-error">
                        <p class="help-block">There are errors on the page. You must fix the errors or clear the data
                            in those fields in order
                            to Save this form.</p>
                    </div>
                    <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
                    <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>
                    <button class="btn btn-default mr-1" (click)="save()" [disabled]="!validForm || !_sectionModel.isRequired">Save</button>
                    <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to
                        Overview</a>
                </div>
                <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1"
                    style="display: none;">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h3 class="modal-title" id="nextModalLabel">Recent changes on this page will not be
                                    saved</h3>
                            </div>
                            <div class="modal-body">
                                <p>
                                    There are errors on the page. You must fix the errors or clear the data in those
                                    fields in order to save this form.
                                    <br />
                                    <br /> You may select "Go back to form" to remove the errors or you may select
                                    "Proceed to
                                    next section" and all of new the data entered in the current section will be lost.
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go
                                    back to form</button>
                                <button class="btn btn-primary pull-right" (click)="unsavedChanges = false;"
                                    data-dismiss="modal" type="button" [routerLink]="['/data-submissions/titleIIInsip']">Proceed
                                    to next section</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- .end col-md-9 -->
        </div>
        <!-- .end acl-container (col-md-12) -->
    </div>
    <!-- .end row -->
</div>
<!-- ./container -->
