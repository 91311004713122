<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
    <div class="row">
        <div class="col-md-12 acl-container">
            <!-- left navigation -->
            <sideNav></sideNav>
            <!-- .end left navigation -->
            <div class="col-md-9">
              <h2>Older Adults - Legal Assistance - Levels of Service and Types of Cases</h2>
              <p class="info mt-1">All fields are required</p>
              <div class="col-md-12 background-gray-lighter mb-1">
                <h2>Total number of cases</h2>
              </div>
              <div class="col-md-9">
                <label for="openCases" class="normal">Total number of open cases</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputopenCases'" [de]="_sectionModel.openCases"
                 (change)="onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.openCases"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="closedCases" class="normal">Total number of closed cases</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputclosedCases'" [de]="_sectionModel.closedCases"
                 (change)="onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.closedCases"></acl-input-error>
              </div>

              <div class="col-md-12 background-gray-lighter mb-1">
                <h2>Number of closed cases by level of service</h2>
              </div>
              <div class="col-md-9">
                <label for="advice" class="normal">Advice</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputadvice'" [de]="_sectionModel.advice"
                 (change)="_sectionModel.serviceClosed.elementValue = updateTotal(serviceArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.advice"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="limited" class="normal">Limited representation</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputlimited'" [de]="_sectionModel.limited"
                 (change)="_sectionModel.serviceClosed.elementValue = updateTotal(serviceArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.limited"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="rep" class="normal">Representation</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputrep'" [de]="_sectionModel.rep"
                 (change)="_sectionModel.serviceClosed.elementValue = updateTotal(serviceArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.rep"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="serviceClosed">Total number of closed cases</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputserviceClosed'" [de]="_sectionModel.serviceClosed"
                 [disabled]=true></acl-data-element-text>
                <acl-input-error [de]="_sectionModel.serviceClosed"></acl-input-error>
              </div>

              <div class="col-md-12 background-gray-lighter mb-1">
                <h2>Number of closed cases by type of case</h2>
              </div>
              <div class="col-md-9">
                <label for="income" class="normal">Income</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputincome'" [de]="_sectionModel.income"
                 (change)="_sectionModel.typeClosed.elementValue = updateTotal(typeArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.income"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="healthCare" class="normal">Health care</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputhealthCare'" [de]="_sectionModel.healthCare"
                 (change)="_sectionModel.typeClosed.elementValue = updateTotal(typeArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.healthCare"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="longTerm" class="normal">Long-term care</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputlongTerm'" [de]="_sectionModel.longTerm"
                 (change)="_sectionModel.typeClosed.elementValue = updateTotal(typeArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.longTerm"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="nutrition" class="normal">Nutrition</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputnutrition'" [de]="_sectionModel.nutrition"
                 (change)="_sectionModel.typeClosed.elementValue = updateTotal(typeArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.nutrition"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="housing" class="normal">Housing</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputhousing'" [de]="_sectionModel.housing"
                 (change)="_sectionModel.typeClosed.elementValue = updateTotal(typeArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.housing"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="utilities" class="normal">Utilities</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inpututilities'" [de]="_sectionModel.utilities"
                 (change)="_sectionModel.typeClosed.elementValue = updateTotal(typeArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.utilities"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="abuseNeglect" class="normal">Abuse/Neglect</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputabuseNeglect'" [de]="_sectionModel.abuseNeglect"
                 (change)="_sectionModel.typeClosed.elementValue = updateTotal(typeArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.abuseNeglect"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="defenseGuardianship" class="normal">Defense of guardianship or protective services</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputdefenseGuardianship'" [de]="_sectionModel.defenseGuardianship"
                 (change)="_sectionModel.typeClosed.elementValue = updateTotal(typeArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.defenseGuardianship"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="ageDiscrimination" class="normal">Age discrimination</label>
              </div>
              <!-- <div class="col-md-3 mb-1">
                <label for="defenseGuardianship" class="normal">Defense of guardianship or protective services</label>
              </div> -->
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputageDiscrimination'" [de]="_sectionModel.ageDiscrimination"
                 (change)="_sectionModel.typeClosed.elementValue = updateTotal(typeArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.ageDiscrimination"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="other" class="normal">Other/Miscellaneous</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputother'" [de]="_sectionModel.other"
                 (change)="_sectionModel.typeClosed.elementValue = updateTotal(typeArr); onChange()">
                </acl-data-element-text>
                <acl-input-error [de]="_sectionModel.other"></acl-input-error>
              </div>
              <div class="col-md-9">
                <label for="typeClosed">Total number of closed cases</label>
              </div>
              <div class="col-md-3 mb-1">
                <acl-data-element-text [id]="'inputtypeClosed'" [de]="_sectionModel.typeClosed"
                 [disabled]=true></acl-data-element-text>
                <acl-input-error [de]="_sectionModel.other"></acl-input-error>
              </div>
              <div class="col-md-12 row">
                <div *ngIf="!validForm" class="has-error">
                  <p class="help-block">
                    There are errors on the page. You must fix the errors or clear the data in those fields in order to Save this form.
                  </p>
                </div>
                <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
                <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>
                <button class="btn btn-default mr-1" (click)="save()" [disabled]="!validForm || !_sectionModel.isRequired">Save</button>
                <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
              </div>
              <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1"
                   style="display: none;">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                        <span aria-hidden="true">×</span>
                      </button>
                      <h3 class="modal-title" id="nextModalLabel">
                        Recent changes on this page will not be saved
                      </h3>
                    </div>
                    <div class="modal-body">
                      <p>
                        There are errors on the page. You must fix the errors or clear the data in those fields in order to save this form.
                        <br />
                        <br />
                        You may select "Go back to form" to remove the errors or you may select "Proceed to next section" and all of new the data entered in the current section will be lost.
                      </p>
                    </div>
                    <div class="modal-footer">
                      <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go back to form</button>
                      <button class="btn btn-primary pull-right" data-dismiss="modal" type="button" (click)="next()">Proceed to next section</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- .end col-md-9 -->
        </div>
        <!-- .end acl-container (col-md-12) -->
    </div>
    <!-- .end row -->
</div>
<!-- ./container -->
