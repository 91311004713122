<div id="main-container">

  <!-- Main jumbotron for a primary marketing message or call to action -->
  <div class="jumbotron jumbotron-fluid home">
    <div class="bg">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-7 mt-3">
            <h1>Welcome to OAAPS</h1>
          </div>
        </div>
        <!-- .end row -->
        <div class="row">
          <div class="col-sm-12 col-md-4 mb-2">
            <p class="lead">Access to this site is restricted to authorized Older Americans Act (OAA) Title III, VI, and VII grantees and sub-grantees. Final datasets can be viewed at:
              <a href="https://agid.acl.gov/" target="_blank">AGing, Independence, and Disability (AGID) Program Data Portal</a>.
            </p>
          </div>
        </div>
        <!-- .end row -->
      </div>
      <!-- .end container -->
    </div>
    <!-- .end bg -->
  </div>
  <!-- .end jumbotron col 12 -->




  <div class="plain pt-2 pb-2 mb-3">
    <div class="container pb-3">
      <div class="mb-2">
        <div class="flex flex-between">
          <div class="panel panel-default p-1 flex-fifty mr-1">
            <h3>About the Older Americans Act (OAA)</h3>
            <p>The OAA authorizes an array of services through a national network of state and local agencies on aging, and American Indian, Alaskan Native and Native Hawaiian organizations. 
              OAA programs help older adults remain at home for as long as possible, promote the rights of older individuals, and advocate for individuals who live in long-term care facilities.</p>
          </div>

          <!-- end col-md-6 -->
          <div class="panel panel-default p-1 flex-fifty">
            <h3>About the Administration for Community Living (ACL)</h3>
            <p>ACL’s programs support people with disabilities, older adults, families and caregivers to live independently, with equal opportunities to earn a living, go to school, choose where to live, 
              and make decisions about their lives. In addition, ACL invests in research, innovation and education to improve the quality and availability of these services, and advocates for older adults 
              and people with disabilities. More information is available at:
              <a href="http://www.acl.gov" target="_blank">ACL.GOV</a>.
            </p>
          </div>
          <!-- end col-md-6 -->
        </div>
        <!-- end flex -->
      </div>
      <!-- end row -->
      <div class="flex-list negSevenM">
        <div class="flex-list-item">
          <div class="gray-dash flex-list-content">
            <span class="btn-floating btn-large">
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </span>
            <h3>Recent System Updates</h3>
            <ul class="list-unstyled">
              <li>Coming soon.</li>
              <!--li *ngFor="let rp of systemUpdates;">
                <p>
                  <strong>
                    <a href="">{{rp.shortText}}</a>
                  </strong>
                </p>
                <p>Date: {{rp.reportingDate | amLocale:'en' | amDateFormat:'MM/DD/YYYY'}}</p>
                <p>{{rp.longText}}</p>
              </li-->
            </ul>
            <a [routerLink]="['../systemUpdates']" class="btn btn-default">See all updates</a>
          </div>
        </div>

        <div class="flex-list-item">
          <div class="gray-dash flex-list-content">
            <span class="btn-floating btn-large">
              <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
            </span>
            <h3>Reporting Calendar</h3>
            <ul class="list-unstyled">
              <li *ngFor="let rp of reportingDates;">
                <p>
                  <strong>
                    {{rp.shortText}}
                  </strong>
                </p>
                <p>Date: {{rp.reportingDate | amLocale:'en' | amDateFormat:'MM/DD/YYYY'}}</p>
                <p>{{rp.longText}}</p>
              </li>
            </ul>
            <a [routerLink]="['/Resources/landingResources', 'calendar']" class="btn btn-default">See reporting calendar</a>
          </div>
        </div>

        <div class="flex-list-item">
          <div class="gray-dash flex-list-content">
            <span class="btn-floating btn-large">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </span>
            <h3>Upcoming Events</h3>
            <ul class="list-unstyled">
              <li *ngFor="let rp of upcomingEvents;">
                <p>
                  <strong>
                    {{rp.shortText}}
                  </strong>
                </p>
                <p>Date: {{rp.reportingDate | amLocale:'en' | amDateFormat:'MM/DD/YYYY'}}</p>
                <p>{{rp.longText}}</p>
              </li>
            </ul>
            <a [routerLink]="['/Resources/landingResources', 'events']" class="btn btn-default">See all events</a>
          </div>
        </div>
      </div>
      <!-- end flex-list -->
    </div>
    <!-- end container -->
  </div>
  <!-- end plain -->
</div>
<!-- end main-container -->
