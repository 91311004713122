import { QuestionStatusEnum } from '../shared/dataElement';
import { TIIITopSectionTypeEnum } from '../shared/section';
import { YearStateRegionReport } from '../reports/report.model';

export class YearApprovalStatus {
    public id: string;
    public fiscalYear: string;
    public state: string;
    public psa: string;
    public isRollup: boolean;
    public isACLRollup: boolean;
    public isSubmissionPullback: boolean;
    public titleIIISectionType: string;
    public decision: ApprovalDecision;
    public comments: string;
    public createdBy: string;
    public created: string;
    public updatedBy: string;
    public lastModified: string;
    public type: string;
    public isDeleted: boolean;
    public upn: string;
    public status: QuestionStatusEnum;
    // public isActiveState: boolean;
    public serviceId: TIIITopSectionTypeEnum;
    public granteeId: string;
    public reportSettings: YearStateRegionReport;
}

export enum ApprovalDecision {
    Pending,
    Returned,
    Approved
}
