import { Component } from '@angular/core';
import { DefaultSorter } from './DefaultSorter';
import { DataTable } from './DataTable';

@Component({
  selector: 'mfDataTableSorter',
  template: `
        <a style="cursor: pointer" (click)="sort()" (keydown.enter)="sort()" class="text-nowrap" tabindex="0">
            <ng-content></ng-content>
            <span *ngIf="!isSortedByMeAsc && !isSortedByMeDesc" class="glyphicon glyphicon-sort"></span>
            <span *ngIf="isSortedByMeAsc" class="glyphicon glyphicon-triangle-top" aria-hidden="true" aria-label="ascending"></span>
            <span *ngIf="isSortedByMeDesc" class="glyphicon glyphicon-triangle-bottom" aria-hidden="true" aria-label="descending"></span>
        </a>`
})
export class DataTableSorter extends DefaultSorter {

  public constructor(mfTable: DataTable) {
    super(mfTable);
  }


}
