import { QuestionStatusEnum } from './dataElement';
import { ITIIIOverview } from './section';

export class TIIIStatuses {
    public sprStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public nsipStatus: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    // Aging Network
    public anSection: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIANNetworkFunding: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIANStaffAndVolunteers: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIANServiceProviders: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIANSeniorCenters: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIANSelfDirection: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIANRespite: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    // Older Adults
    public OAParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OAConsumerParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OAConsumerTps: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OAConsumerBoth: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OAConsumerOne: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OAPersonalCareParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIPersonalCareChars: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIPersonalCareUnits: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OAHomemakerParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIHomemakerChars: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIHomemakerUnits: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OAChoreParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIChoreChars: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIChoreUnits: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OAHomeDeliveredMealsParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIHomeDeliveredMealsChars: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIHomeDeliveredMealsUnits: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OAAdultDayCareParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIAdultDayCareChars: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIAdultDayCareUnits: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OACaseMgmtCharsParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICaseMgmtChars: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICaseMgmtUnits: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OAAssistedTransportationParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIAssistedTransportationChars: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIAssistedTransportationUnits: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OACongregateMealsParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICongregateMealsChars: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICongregateMealsUnits: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OANutritionCounselingParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIINutritionCounselingChars: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIINutritionCounselingUnits: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OALegalParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIILegalChars: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIILegalUnits: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OALegalCases: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIITransportation: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIINutritionEducation: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIInfoAndAssistance: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public healthPromoEvidence: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public healthPromoNonEvidence: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIOtherServices: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIOtherExpend: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    // Caregivers of Older Adults
    public COAParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public ConsumerSummaryParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public COAConsumerTps: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICOAConsumerSummary: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICOACounseling: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICOARespite: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICOATraining: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICOASuppProvided: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICOASuppExpend: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICOACaseMgmt: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICOASupport: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICOAInfoAndAssistance: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIICOAInfoServices: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    // Older Relative Caregivers
    public ORCParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public ORCConsumerSummaryParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public ORCConsumerTps: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIORCConsumerSummary: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIORCCounseling: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIORCTraining: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIORCRespite: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIORCSuppProvided: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIORCSuppExpend: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIORCCaseMgmt: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIORCSupport: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIORCInfoAndAssistance: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIIORCInfoServices: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    // Expenditure
    public ExpendituresSectionParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public ServiceExpendituresParent: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public OAExpendituresChildren: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public COAExpendituresChildren: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public ORCExpendituresChildren: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleVIIExpenditures: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public titleIIInsip: QuestionStatusEnum = QuestionStatusEnum.NotStarted;

    // IsRequiredFlags for Variances
    public oaIsRequired: boolean = true;
    public coaIsRequired: boolean = true;
    public orcIsRequired: boolean = true;
    public nsipIsRequired: boolean = true;


    public setTitleIIIStatuses(data: ITIIIOverview): void {
        this.anSection = 0;
        this.titleIIIANNetworkFunding = 0;
        this.titleIIIANStaffAndVolunteers = 0;
        this.titleIIIANServiceProviders = 0;
        this.titleIIIANSeniorCenters = 0;
        this.titleIIIANSelfDirection = 0;
        this.titleIIIANRespite = 0;
        this.OAConsumerTps = 0;
        this.OAConsumerBoth = 0;
        this.OAConsumerBoth = 0;
        this.titleIIIPersonalCareChars = 0;
        this.titleIIIPersonalCareUnits = 0;
        this.titleIIIHomemakerChars = 0;
        this.titleIIIHomemakerUnits = 0;
        this.titleIIIChoreChars = 0;
        this.titleIIIChoreUnits = 0;
        this.titleIIIHomeDeliveredMealsChars = 0;
        this.titleIIIHomeDeliveredMealsUnits = 0;
        this.titleIIIAdultDayCareChars = 0;
        this.titleIIIAdultDayCareUnits = 0;
        this.titleIIICaseMgmtChars = 0;
        this.titleIIICaseMgmtUnits = 0;
        this.titleIIIAssistedTransportationChars = 0;
        this.titleIIIAssistedTransportationUnits = 0;
        this.titleIIICongregateMealsChars = 0;
        this.titleIIICongregateMealsUnits = 0;
        this.titleIIINutritionCounselingChars = 0;
        this.titleIIINutritionCounselingUnits = 0;
        this.titleIIILegalChars = 0;
        this.titleIIILegalUnits = 0;
        this.OALegalCases = 0;
        this.titleIIIOtherServices = 0;
        this.titleIIIOtherExpend = 0;
        this.titleIIITransportation = 0;
        this.titleIIINutritionEducation = 0;
        this.titleIIIInfoAndAssistance = 0;
        this.healthPromoEvidence = 0;
        this.healthPromoNonEvidence = 0;
        this.COAParent = 0;
        this.ConsumerSummaryParent = 0;
        this.COAConsumerTps = 0;
        this.titleIIICOAConsumerSummary = 0;
        this.titleIIICOASuppProvided = 0;
        this.titleIIICOASuppExpend = 0;
        this.titleIIICOACounseling = 0;
        this.titleIIICOARespite = 0;
        this.titleIIICOATraining = 0;
        this.titleIIICOACaseMgmt = 0;
        this.titleIIICOASupport = 0;
        this.titleIIICOAInfoAndAssistance = 0;
        this.titleIIICOAInfoServices = 0;
        this.ORCConsumerTps = 0;
        this.titleIIIORCConsumerSummary = 0;
        this.titleIIIORCSuppProvided = 0;
        this.titleIIIORCSuppExpend = 0;
        this.titleIIIORCCounseling = 0;
        this.titleIIIORCTraining = 0;
        this.titleIIIORCRespite = 0;
        this.titleIIIORCCaseMgmt = 0;
        this.titleIIIORCSupport = 0;
        this.titleIIIORCInfoAndAssistance = 0;
        this.titleIIIORCInfoServices = 0;
        this.titleIIInsip = 0;
        this.OAParent = 0;
        this.OAConsumerParent = QuestionStatusEnum.NotStarted;
        this.OAPersonalCareParent = QuestionStatusEnum.NotStarted;
        this.OAHomemakerParent = QuestionStatusEnum.NotStarted;
        this.OAChoreParent = 0;
        this.OAHomeDeliveredMealsParent = 0;
        this.OAAdultDayCareParent = 0;
        this.OACaseMgmtCharsParent = 0;
        this.OAAssistedTransportationParent = 0;
        this.OACongregateMealsParent = 0;
        this.OANutritionCounselingParent = 0;
        this.OALegalParent = 0;
        this.ORCParent = 0;
        this.ORCConsumerSummaryParent = 0;
        this.ExpendituresSectionParent = 0;
        this.ServiceExpendituresParent = 0;
        this.OAExpendituresChildren = 0;
        this.COAExpendituresChildren = 0;
        this.ORCExpendituresChildren = 0;
        this.titleVIIExpenditures = 0;

        if (data) {
            // this.nsipIsRequired = data.nsipIsRequired;
            for (const section of data.sections) {
                switch (section.sectionName) {
                    case 'ANSection': {
                        this.anSection = section.sectionStatus;
                        section.childSection.forEach(child => {
                            if (child.sectionName === 'titleIIIANNetworkFunding') {
                                this.titleIIIANNetworkFunding = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIANStaffAndVolunteers') {
                                this.titleIIIANStaffAndVolunteers = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIANServiceProviders') {
                                this.titleIIIANServiceProviders = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIANSeniorCenters') {
                                this.titleIIIANSeniorCenters = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIANSelfDirection') {
                                this.titleIIIANSelfDirection = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIANRespite') {
                                this.titleIIIANRespite = child.sectionStatus;
                            }
                        });
                        break;
                    }
                    case 'OASection': {
                        section.childSection.forEach(child => {
                            this.OAParent = section.sectionStatus;
                            if (child.childSection != null) {
                                child.childSection.forEach(c => {
                                    if (child.sectionName === 'Consumer') {
                                        this.OAConsumerParent = child.sectionStatus;
                                        if (c.sectionName === 'OAConsumerTps') {
                                            this.OAConsumerTps = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'OAConsumerBoth') {
                                            this.OAConsumerBoth = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'OAConsumerOne') {
                                            this.OAConsumerOne = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'PersonalCare') {
                                        this.OAPersonalCareParent = child.sectionStatus;
                                        if (c.sectionName === 'titleIIIPersonalCareChars') {
                                            this.titleIIIPersonalCareChars = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIIPersonalCareUnits') {
                                            this.titleIIIPersonalCareUnits = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'Homemaker') {
                                        this.OAHomemakerParent = child.sectionStatus;
                                        if (c.sectionName === 'titleIIIHomemakerChars') {
                                            this.titleIIIHomemakerChars = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIIHomemakerUnits') {
                                            this.titleIIIHomemakerUnits = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'Chore') {
                                        this.OAChoreParent = child.sectionStatus;
                                        if (c.sectionName === 'titleIIIChoreChars') {
                                            this.titleIIIChoreChars = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIIChoreUnits') {
                                            this.titleIIIChoreUnits = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'HomeDeliveredMeals') {
                                        this.OAHomeDeliveredMealsParent = child.sectionStatus;
                                        if (c.sectionName === 'titleIIIHomeDeliveredMealsChars') {
                                            this.titleIIIHomeDeliveredMealsChars = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIIHomeDeliveredMealsUnits') {
                                            this.titleIIIHomeDeliveredMealsUnits = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'AdultDayCare') {
                                        this.OAAdultDayCareParent = child.sectionStatus;
                                        if (c.sectionName === 'titleIIIAdultDayCareChars') {
                                            this.titleIIIAdultDayCareChars = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIIAdultDayCareUnits') {
                                            this.titleIIIAdultDayCareUnits = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'CaseMgmtChars') {
                                        this.OACaseMgmtCharsParent = child.sectionStatus;
                                        if (c.sectionName === 'titleIIICaseMgmtChars') {
                                            this.titleIIICaseMgmtChars = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIICaseMgmtUnits') {
                                            this.titleIIICaseMgmtUnits = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'AssistedTransportation') {
                                        this.OAAssistedTransportationParent = child.sectionStatus;
                                        if (c.sectionName === 'titleIIIAssistedTransportationChars') {
                                            this.titleIIIAssistedTransportationChars = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIIAssistedTransportationUnits') {
                                            this.titleIIIAssistedTransportationUnits = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'CongregateMeals') {
                                        this.OACongregateMealsParent = child.sectionStatus;
                                        if (c.sectionName === 'titleIIICongregateMealsChars') {
                                            this.titleIIICongregateMealsChars = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIICongregateMealsUnits') {
                                            this.titleIIICongregateMealsUnits = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'NutritionCounseling') {
                                        this.OANutritionCounselingParent = child.sectionStatus;
                                        if (c.sectionName === 'titleIIINutritionCounselingChars') {
                                            this.titleIIINutritionCounselingChars = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIINutritionCounselingUnits') {
                                            this.titleIIINutritionCounselingUnits = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'Legal') {
                                        this.OALegalParent = child.sectionStatus;
                                        if (c.sectionName === 'titleIIILegalChars') {
                                            this.titleIIILegalChars = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIILegalUnits') {
                                            this.titleIIILegalUnits = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'OALegalCases') {
                                            this.OALegalCases = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'titleIIIOtherServices') {
                                        if (c.sectionName === 'titleIIIOtherServices') {
                                            this.titleIIIOtherServices = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIIOtherExpend') {
                                            this.titleIIIOtherExpend = c.sectionStatus;
                                        }
                                    }
                                });
                            }
                            if (child.sectionName === 'titleIIITransportation') {
                                this.titleIIITransportation = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIINutritionEducation') {
                                this.titleIIINutritionEducation = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIInfoAndAssistance') {
                                this.titleIIIInfoAndAssistance = child.sectionStatus;
                            }
                            if (child.sectionName === 'healthPromoEvidence') {
                                this.healthPromoEvidence = child.sectionStatus;
                            }
                            if (child.sectionName === 'healthPromoNonEvidence') {
                                this.healthPromoNonEvidence = child.sectionStatus;
                            }
                        });
                        this.oaIsRequired = section.isRequired;
                        break;
                    }
                    case 'COASection': {
                        section.childSection.forEach(child => {
                            this.COAParent = section.sectionStatus;
                            if (child.childSection != null) {
                                child.childSection.forEach(c => {
                                    if (child.sectionName === 'COAConsumer') {
                                        this.ConsumerSummaryParent = child.sectionStatus;
                                        if (c.sectionName === 'COAConsumerTps') {
                                            this.COAConsumerTps = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIICOAConsumerSummary') {
                                            this.titleIIICOAConsumerSummary = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'COASupp') {
                                        if (c.sectionName === 'titleIIICOASuppProvided') {
                                            this.titleIIICOASuppProvided = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIICOASuppExpend') {
                                            this.titleIIICOASuppExpend = c.sectionStatus;
                                        }
                                    }
                                });
                            }
                            if (child.sectionName === 'titleIIICOACounseling') {
                                this.titleIIICOACounseling = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIICOARespite') {
                                this.titleIIICOARespite = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIICOATraining') {
                                this.titleIIICOATraining = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIICOACaseMgmt') {
                                this.titleIIICOACaseMgmt = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIICOASupport') {
                                this.titleIIICOASupport = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIICOAInfoAndAssistance') {
                                this.titleIIICOAInfoAndAssistance = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIICOAInfoServices') {
                                this.titleIIICOAInfoServices = child.sectionStatus;
                            }
                        });
                        this.coaIsRequired = section.isRequired;
                        break;
                    }
                    case 'ORCSection': {
                        section.childSection.forEach(child => {
                            this.ORCParent = section.sectionStatus;
                            if (child.childSection != null) {
                                child.childSection.forEach(c => {
                                    if (child.sectionName === 'ORCConsumer') {
                                        this.ORCConsumerSummaryParent = child.sectionStatus;
                                        if (c.sectionName === 'ORCConsumerTps') {
                                            this.ORCConsumerTps = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIIORCConsumerSummary') {
                                            this.titleIIIORCConsumerSummary = c.sectionStatus;
                                        }
                                    }
                                    if (child.sectionName === 'ORCSupp') {
                                        if (c.sectionName === 'titleIIIORCSuppProvided') {
                                            this.titleIIIORCSuppProvided = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'titleIIIORCSuppExpend') {
                                            this.titleIIIORCSuppExpend = c.sectionStatus;
                                        }
                                    }
                                });
                            }
                            if (child.sectionName === 'titleIIIORCCounseling') {
                                this.titleIIIORCCounseling = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIORCTraining') {
                                this.titleIIIORCTraining = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIORCRespite') {
                                this.titleIIIORCRespite = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIORCCaseMgmt') {
                                this.titleIIIORCCaseMgmt = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIORCSupport') {
                                this.titleIIIORCSupport = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIORCInfoAndAssistance') {
                                this.titleIIIORCInfoAndAssistance = child.sectionStatus;
                            }
                            if (child.sectionName === 'titleIIIORCInfoServices') {
                                this.titleIIIORCInfoServices = child.sectionStatus;
                            }
                        });
                        this.orcIsRequired = section.isRequired;
                        break;
                    }
                    case 'ExpendituresSection': {
                        section.childSection.forEach(child => {
                            this.ExpendituresSectionParent = section.sectionStatus;
                            if (child.childSection != null) {
                                child.childSection.forEach(c => {
                                    if (child.sectionName === 'ServiceExpenditures') {
                                        this.ServiceExpendituresParent = child.sectionStatus;
                                        if (c.sectionName === 'OAExpendituresChildren') {
                                            this.OAExpendituresChildren = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'COAExpendituresChildren') {
                                            this.COAExpendituresChildren = c.sectionStatus;
                                        }
                                        if (c.sectionName === 'ORCExpendituresChildren') {
                                            this.ORCExpendituresChildren = c.sectionStatus;
                                        }
                                    }
                                });
                            }
                            if (child.sectionName === 'titleVIIExpenditures') {
                                this.titleVIIExpenditures = child.sectionStatus;
                            }
                        });
                        break;
                    }
                    case 'titleIIInsip': {
                        this.titleIIInsip = section.sectionStatus;
                        this.nsipIsRequired = section.isRequired;
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        }
    }
}
