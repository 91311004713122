import { Component, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { TwoColCharsSection } from './TwoColCharsSection';
import { CommonService } from '../shared/common.service';
import { ToastrService } from '../shared/toastr.service';
import { ValidationService } from '../shared/validation.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIISectionComponent } from './title-iii-section.component';


@Component({
  templateUrl: 'titleIIITwoColChars.component.html',
})

export class TitleIIITwoColCharsComponent extends TitleIIISectionComponent<TwoColCharsSection> implements OnInit, OnDestroy {

  _sectionModel: TwoColCharsSection;
  _uploadModels: Array<TwoColCharsSection>;
  _batchIds: Array<string>;
  pageName: string;
  unsavedChanges: boolean = false;
  allAgeTotalArr: Array<DataElement>;
  bpAgeTotalArr: Array<DataElement>;
  allGenderTotalArr: Array<DataElement>;
  bpGenderTotalArr: Array<DataElement>;
  allGeoTotalArr: Array<DataElement>;
  bpGeoTotalArr: Array<DataElement>;
  allPovertyTotalArr: Array<DataElement>;
  allHouseholdTotalArr: Array<DataElement>;
  bpHouseholdTotalArr: Array<DataElement>;
  allEthnicityTotalArr: Array<DataElement>;
  bpEthnicityTotalArr: Array<DataElement>;
  allMinorityTotalArr: Array<DataElement>;
  bpMinorityTotalArr: Array<DataElement>;
  allNutritionTotalArr: Array<DataElement>;
  bpNutritionTotalArr: Array<DataElement>;

  statusEnum = QuestionStatusEnum;

  // age
  totalBelow60Text: string = 'Total Below 60';
  bpBelow60Text: string = 'Below 60 at or below poverty';
  totalRange1Text: string = 'Total 60-64';
  bpRange1Text: string = '60-64 at or below poverty';
  totalRange2Text: string = 'Total 65-74';
  bpRange2Text: string = '65-74 at or below poverty';
  totalRange3Text: string = 'Total 75-84';
  bpRange3Text: string = '75-84 at or below poverty';
  totalRange4Text: string = 'Total 85 and above';
  bpRange4Text: string = '85 and above at or below poverty';
  totalAgeMissingText: string = 'Total Age missing';
  bpAgeMissingText: string = 'Age missing at or below poverty';
  allAgeTotalText: string = 'Total for Total Persons Served in the Age section';
  bpAgeTotalText: string = 'Total Persons Served at or below poverty in the Age section';

  // gender
  totalFemaleText: string = 'Total Female';
  bpFemaleText: string = 'Female at or below poverty';
  totalMaleText: string = 'Total Male';
  bpMaleText: string = 'Male at or below poverty';
  totalOtherText: string = 'Total Other';
  bpOtherText: string = 'Other at or below poverty';
  totalGenderMissingText: string = 'Total Gender missing';
  bpGenderMissingText: string = 'Gender missing at or below poverty';
  allGenderTotalText: string = 'Total for Total Persons Served in the Gender section';
  bpGenderTotalText: string = 'Total Persons Served at or below poverty in the Gender section';

  // geographic
  totalRuralText: string = 'Rural Total';
  bpRuralText: string = 'Rural at or below poverty';
  totalNonRuralText: string = 'Total Non-rural';
  bpNonRuralText: string = 'Non-rural at or below poverty';
  totalGeoMissingText: string = 'Total Geographic distribution missing';
  bpGeoMissingText: string = 'Geographic distribution missing at or below poverty';
  allGeoTotalText: string = 'Total for Total Persons Served in the Geographic section';
  bpGeoTotalText: string = 'Total Persons Served at or below poverty in the Geographic section';

  // poverty
  totalBelowPovertyText: string = 'At or below poverty Total';
  totalAbovePovertyText: string = 'Total Above poverty';
  totalMissingPovertyText: string = 'Total Poverty status missing';
  allPovertyTotalText: string = 'Total for Total Persons Served in the Poverty section';

  // household
  totalAloneText: string = 'Lives alone Total';
  bpAloneText: string = 'Lives alone at or below poverty';
  totalWithOthersText: string = 'Total Lives with others';
  bpWithOthersText: string = 'Lives with others at or below poverty';
  totalHouseholdMissingText: string = 'Total Household status missing';
  bpHouseholdMissingText: string = 'Household status missing at or below poverty';
  allHouseholdTotalText: string = 'Total for Total Persons Served in the Household section';
  bpHouseholdTotalText: string = 'Total Persons Served at or below poverty in the Household section';

  // ethnicity
  totalHispanicText: string = 'Hispanic or Latino Total';
  bpHispanicText: string = 'Hispanic or Latino at or below poverty';
  totalNonHispanicText: string = 'Total Not Hispanic or Latino';
  bpNonHispanicText: string = 'Not Hispanic or Latino at or below poverty';
  totalEthnicityMissingText: string = 'Total Ethnicity missing';
  bpEthnicityMissingText: string = 'Ethnicity missing at or below poverty';
  allEthnicityTotalText: string = 'Total for Total Persons Served in Ethnicity section';
  bpEthnicityTotalText: string = 'Total Persons Served at or below poverty in Ethnicity section';

  // race
  totalAmericanIndianText: string = 'American Indian or Alaska Native Total';
  bpAmericanIndianText: string = 'American Indian or Alaska Native at or below poverty';
  totalAsianText: string = 'Total Asian or Asian American';
  bpAsianText: string = 'Asian or Asian American at or below poverty';
  totalBlackText: string = 'Total Black or African American';
  bpBlackText: string = 'Black or African Americanat or below poverty';
  totalIslanderText: string = 'Native Hawaiian or  Pacific Islander Total';
  bpIslanderText: string = 'Native Hawaiian or  Pacific Islander at or below poverty';
  totalWhiteText: string = 'Total White';
  bpWhiteText: string = 'White at or below poverty';
  totalRaceOtherText: string = 'Total other race';
  bpRaceOtherText: string = 'Other race at or below poverty';
  totalRaceMultipleText: string = 'Total multiple race';
  bpRaceMultipleText: string = 'Multiple race at or below poverty';
  totalRaceMissingText: string = 'Total Race missing';
  bpRaceMissingText: string = 'Race missing at or below poverty';

  // minority
  totalMinorityText: string = 'Total Minority';
  bpMinorityText: string = 'Minority at or below poverty';
  totalNotMinorityText: string = 'Total Not minority';
  bpNotMinorityText: string = 'Not minority at or below poverty';
  totalMinorityMissingText: string = 'Total Not minority';
  bpMinorityMissingText: string = 'Not minority at or below poverty';
  allMinorityTotalText: string = 'Total for Total Persons Served in the Minority section';
  bpMinorityTotalText: string = 'Total Persons Served at or below poverty in the Minority section';

  // nutrition
  totalLowNutritionText: string = 'Nutrition risk score 0-5 Total';
  bpLowNutritionText: string = 'Nutrition risk score 0-5 at or below poverty';
  totalHighNutritionText: string = 'Nutrition risk score Total 6+';
  bpHighNutritionText: string = 'Nutrition risk score 6+ at or below poverty';
  totalNutritionMissingText: string = 'Total Nutrition risk score missing';
  bpNutritionMissingText: string = 'Nutrition risk score missing at or below poverty';
  allNutritionTotalText: string = 'Total for Total Persons Served in the Nutrition Risk section';
  bpNutritionTotalText: string = 'Total Persons Served at or below poverty in the Nutrition Risk section';

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _commonService: CommonService,
    public toastr: ToastrService,
    public _validation: ValidationService
  ) { super(new TwoColCharsSection(), _commonService, _validation, router, _claimService, _service); }

  ngOnInit(): void {
    this._sectionModel = new TwoColCharsSection();
    this.setSectionName();

    switch (this._sectionModel.sectionName) {
      case 'OAConsumerBoth': {
        this.pageName = 'Older Adults - Consumer Summary - Persons Receiving Registered Services (Clusters 1 & 2)';
        break;
      }
      case 'titleIIICongregateMealsChars': {
        this.pageName = 'Older Adults - Congregate Nutrition - Consumer Characteristics';
        break;
      }
      case 'titleIIINutritionCounselingChars': {
        this.pageName = 'Older Adults - Nutrition Counseling - Consumer Characteristics';
        break;
      }
      default: {
        this.pageName = 'Error';
        break;
      }
    }
    this._titleService.setTitle(this.pageName.concat(' - Data Submissions - ACL OAAPS'));
    this.gettwocolcharsbyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    this.setSectionName();
    super.registerSubscription(this._service.savetwocolchars(this._sectionModel)
      .subscribe(data => this.zone.run(() => {
        this.toastr.success('Saved!');
        this._sectionModel = data;
        this.initArrays();

      }),
      (error)=>console.log(error),
      ()=>{
        callback();

        this.toastr.success('Saved!');
      }
      ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => {
      switch (this._sectionModel.sectionName) {
        case 'OAConsumerBoth': {
          this.router.navigate(['/data-submissions/OAConsumerOne']);
          break;
        }
        case 'titleIIICongregateMealsChars': {
          this.router.navigate(['/data-submissions/titleIIICongregateMealsUnits']);
          break;
        }
        case 'titleIIINutritionCounselingChars': {
          this.router.navigate(['/data-submissions/titleIIINutritionCounselingUnits']);
          break;
        }
        default: {
          this.router.navigate(['/dashboard']);
          break;
        }
      }
    };
    if (!this._claimService.isViewOnly &&
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  next(): void {
    switch (this._sectionModel.sectionName) {
      case 'OAConsumerBoth': {
        this.router.navigate(['/data-submissions/OAConsumerOne']);
        break;
      }
      case 'titleIIICongregateMealsChars': {
        this.router.navigate(['/data-submissions/titleIIICongregateMealsUnits']);
        break;
      }
      case 'titleIIINutritionCounselingChars': {
        this.router.navigate(['/data-submissions/titleIIINutritionCounselingUnits']);
        break;
      }
      default: {
        this.router.navigate(['/dashboard']);
        break;
      }
    }
  }

  gettwocolcharsbyffyandstate(): void {
    super.registerSubscription(this._service.get<TwoColCharsSection[]>(this._claimService,
      this._service._gettwocolcharsbyffyandstate, this.resolveSectionName())
      .subscribe(data => this.zone.run(() => {
        if (data.length > 0) {
          // Filter for sectionName
          const sectionData = data.find(item => this.resolveSectionName().includes(item.sectionName));
          // If no model with section name exists see if there is a model with null section name (init model from mvc)
          if (sectionData) {
            this._sectionModel = sectionData;
            this.initArrays();
          } else {
            // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
            const tempSectionModel: TwoColCharsSection = new TwoColCharsSection();
            this._sectionModel = tempSectionModel;
            this.initArrays();
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
            // initialize arrays for summing
            this.initArrays();
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
        } else {
          this.initArrays();
        }

      })));
  }

  initArrays(): void {
    this.allAgeTotalArr = [this._sectionModel.totalBelow60,
    this._sectionModel.totalRange1,
    this._sectionModel.totalRange2,
    this._sectionModel.totalRange3,
    this._sectionModel.totalRange4,
    this._sectionModel.totalAgeMissing];

    this.bpAgeTotalArr = [this._sectionModel.bpBelow60,
    this._sectionModel.bpRange1,
    this._sectionModel.bpRange2,
    this._sectionModel.bpRange3,
    this._sectionModel.bpRange4,
    this._sectionModel.bpAgeMissing];

    this.allGenderTotalArr = [this._sectionModel.totalFemale,
    this._sectionModel.totalMale,
    this._sectionModel.totalOther,
    this._sectionModel.totalGenderMissing];

    this.bpGenderTotalArr = [this._sectionModel.bpFemale,
    this._sectionModel.bpMale,
    this._sectionModel.bpOther,
    this._sectionModel.bpGenderMissing];

    this.allGeoTotalArr = [this._sectionModel.totalRural,
    this._sectionModel.totalNonRural,
    this._sectionModel.totalGeoMissing];

    this.bpGeoTotalArr = [this._sectionModel.bpRural,
    this._sectionModel.bpNonRural,
    this._sectionModel.bpGeoMissing];

    this.allPovertyTotalArr = [this._sectionModel.totalBelowPoverty,
    this._sectionModel.totalAbovePoverty,
    this._sectionModel.totalMissingPoverty];

    this.allHouseholdTotalArr = [this._sectionModel.totalAlone,
    this._sectionModel.totalWithOthers,
    this._sectionModel.totalHouseholdMissing];

    this.bpHouseholdTotalArr = [this._sectionModel.bpAlone,
    this._sectionModel.bpWithOthers,
    this._sectionModel.bpHouseholdMissing];

    this.allEthnicityTotalArr = [this._sectionModel.totalHispanic,
    this._sectionModel.totalNonHispanic,
    this._sectionModel.totalEthnicityMissing];

    this.bpEthnicityTotalArr = [this._sectionModel.bpHispanic,
    this._sectionModel.bpNonHispanic,
    this._sectionModel.bpEthnicityMissing];

    this.allMinorityTotalArr = [this._sectionModel.totalMinority,
    this._sectionModel.totalNotMinority,
    this._sectionModel.totalMinorityMissing];

    this.bpMinorityTotalArr = [this._sectionModel.bpMinority,
    this._sectionModel.bpNotMinority,
    this._sectionModel.bpMinorityMissing];

    this.allNutritionTotalArr = [this._sectionModel.totalLowNutrition,
    this._sectionModel.totalHighNutrition,
    this._sectionModel.totalNutritionMissing];

    this.bpNutritionTotalArr = [this._sectionModel.bpLowNutrition,
    this._sectionModel.bpHighNutrition,
    this._sectionModel.bpNutritionMissing];
  }

  updateTotal(totalsArray: Array<DataElement>): string {
    let total: number = 0;

    for (let i = 0; i < totalsArray.length; i++) {
      // if not null, remove commas
      if (totalsArray[i].elementValue) {
        totalsArray[i].elementValue = totalsArray[i].elementValue.replace(/,/g, '');
      }
      // if is a number, sum it
      if (!isNaN(parseInt(totalsArray[i].elementValue))) {
        total += parseInt(totalsArray[i].elementValue);
      }
      // replace commas when done adding
      totalsArray[i].elementValue = this.addComma(totalsArray[i].elementValue);
    }

    // add comma and return
    return this.addComma(total.toString());
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.totalPersonsServed.elementValue = this.addComma(this._sectionModel.totalPersonsServed.elementValue);
    this._sectionModel.totalBelow60.elementValue = this.addComma(this._sectionModel.totalBelow60.elementValue);
    this._sectionModel.bpBelow60.elementValue = this.addComma(this._sectionModel.bpBelow60.elementValue);
    this._sectionModel.totalRange1.elementValue = this.addComma(this._sectionModel.totalRange1.elementValue);
    this._sectionModel.bpRange1.elementValue = this.addComma(this._sectionModel.bpRange1.elementValue);
    this._sectionModel.totalRange2.elementValue = this.addComma(this._sectionModel.totalRange2.elementValue);
    this._sectionModel.bpRange2.elementValue = this.addComma(this._sectionModel.bpRange2.elementValue);
    this._sectionModel.totalRange3.elementValue = this.addComma(this._sectionModel.totalRange3.elementValue);
    this._sectionModel.bpRange3.elementValue = this.addComma(this._sectionModel.bpRange3.elementValue);
    this._sectionModel.totalRange4.elementValue = this.addComma(this._sectionModel.totalRange4.elementValue);
    this._sectionModel.bpRange4.elementValue = this.addComma(this._sectionModel.bpRange4.elementValue);
    this._sectionModel.totalAgeMissing.elementValue = this.addComma(this._sectionModel.totalAgeMissing.elementValue);
    this._sectionModel.bpAgeMissing.elementValue = this.addComma(this._sectionModel.bpAgeMissing.elementValue);
    this._sectionModel.allAgeTotal.elementValue = this.addComma(this._sectionModel.allAgeTotal.elementValue);
    this._sectionModel.bpAgeTotal.elementValue = this.addComma(this._sectionModel.bpAgeTotal.elementValue);
    this._sectionModel.totalFemale.elementValue = this.addComma(this._sectionModel.totalFemale.elementValue);
    this._sectionModel.bpFemale.elementValue = this.addComma(this._sectionModel.bpFemale.elementValue);
    this._sectionModel.totalOther.elementValue = this.addComma(this._sectionModel.totalOther.elementValue);
    this._sectionModel.bpOther.elementValue = this.addComma(this._sectionModel.bpOther.elementValue);
    this._sectionModel.totalMale.elementValue = this.addComma(this._sectionModel.totalMale.elementValue);
    this._sectionModel.bpMale.elementValue = this.addComma(this._sectionModel.bpMale.elementValue);
    this._sectionModel.totalGenderMissing.elementValue = this.addComma(this._sectionModel.totalGenderMissing.elementValue);
    this._sectionModel.bpGenderMissing.elementValue = this.addComma(this._sectionModel.bpGenderMissing.elementValue);
    this._sectionModel.allGenderTotal.elementValue = this.addComma(this._sectionModel.allGenderTotal.elementValue);
    this._sectionModel.bpGenderTotal.elementValue = this.addComma(this._sectionModel.bpGenderTotal.elementValue);
    this._sectionModel.totalRural.elementValue = this.addComma(this._sectionModel.totalRural.elementValue);
    this._sectionModel.bpRural.elementValue = this.addComma(this._sectionModel.bpRural.elementValue);
    this._sectionModel.totalNonRural.elementValue = this.addComma(this._sectionModel.totalNonRural.elementValue);
    this._sectionModel.bpNonRural.elementValue = this.addComma(this._sectionModel.bpNonRural.elementValue);
    this._sectionModel.totalGeoMissing.elementValue = this.addComma(this._sectionModel.totalGeoMissing.elementValue);
    this._sectionModel.bpGeoMissing.elementValue = this.addComma(this._sectionModel.bpGeoMissing.elementValue);
    this._sectionModel.allGeoTotal.elementValue = this.addComma(this._sectionModel.allGeoTotal.elementValue);
    this._sectionModel.bpGeoTotal.elementValue = this.addComma(this._sectionModel.bpGeoTotal.elementValue);
    this._sectionModel.totalBelowPoverty.elementValue = this.addComma(this._sectionModel.totalBelowPoverty.elementValue);
    this._sectionModel.totalAbovePoverty.elementValue = this.addComma(this._sectionModel.totalAbovePoverty.elementValue);
    this._sectionModel.totalMissingPoverty.elementValue = this.addComma(this._sectionModel.totalMissingPoverty.elementValue);
    this._sectionModel.allPovertyTotal.elementValue = this.addComma(this._sectionModel.allPovertyTotal.elementValue);
    this._sectionModel.totalAlone.elementValue = this.addComma(this._sectionModel.totalAlone.elementValue);
    this._sectionModel.bpAlone.elementValue = this.addComma(this._sectionModel.bpAlone.elementValue);
    this._sectionModel.totalWithOthers.elementValue = this.addComma(this._sectionModel.totalWithOthers.elementValue);
    this._sectionModel.bpWithOthers.elementValue = this.addComma(this._sectionModel.bpWithOthers.elementValue);
    this._sectionModel.totalHouseholdMissing.elementValue = this.addComma(this._sectionModel.totalHouseholdMissing.elementValue);
    this._sectionModel.bpHouseholdMissing.elementValue = this.addComma(this._sectionModel.bpHouseholdMissing.elementValue);
    this._sectionModel.allHouseholdTotal.elementValue = this.addComma(this._sectionModel.allHouseholdTotal.elementValue);
    this._sectionModel.bpHouseholdTotal.elementValue = this.addComma(this._sectionModel.bpHouseholdTotal.elementValue);
    this._sectionModel.totalHispanic.elementValue = this.addComma(this._sectionModel.totalHispanic.elementValue);
    this._sectionModel.bpHispanic.elementValue = this.addComma(this._sectionModel.bpHispanic.elementValue);
    this._sectionModel.totalNonHispanic.elementValue = this.addComma(this._sectionModel.totalNonHispanic.elementValue);
    this._sectionModel.bpNonHispanic.elementValue = this.addComma(this._sectionModel.bpNonHispanic.elementValue);
    this._sectionModel.totalEthnicityMissing.elementValue = this.addComma(this._sectionModel.totalEthnicityMissing.elementValue);
    this._sectionModel.bpEthnicityMissing.elementValue = this.addComma(this._sectionModel.bpEthnicityMissing.elementValue);
    this._sectionModel.allEthnicityTotal.elementValue = this.addComma(this._sectionModel.allEthnicityTotal.elementValue);
    this._sectionModel.bpEthnicityTotal.elementValue = this.addComma(this._sectionModel.bpEthnicityTotal.elementValue);
    this._sectionModel.totalAmericanIndian.elementValue = this.addComma(this._sectionModel.totalAmericanIndian.elementValue);
    this._sectionModel.bpAmericanIndian.elementValue = this.addComma(this._sectionModel.bpAmericanIndian.elementValue);
    this._sectionModel.totalAsian.elementValue = this.addComma(this._sectionModel.totalAsian.elementValue);
    this._sectionModel.bpAsian.elementValue = this.addComma(this._sectionModel.bpAsian.elementValue);
    this._sectionModel.totalBlack.elementValue = this.addComma(this._sectionModel.totalBlack.elementValue);
    this._sectionModel.bpBlack.elementValue = this.addComma(this._sectionModel.bpBlack.elementValue);
    this._sectionModel.totalIslander.elementValue = this.addComma(this._sectionModel.totalIslander.elementValue);
    this._sectionModel.bpIslander.elementValue = this.addComma(this._sectionModel.bpIslander.elementValue);
    this._sectionModel.totalWhite.elementValue = this.addComma(this._sectionModel.totalWhite.elementValue);
    this._sectionModel.bpWhite.elementValue = this.addComma(this._sectionModel.bpWhite.elementValue);
    this._sectionModel.totalRaceOther.elementValue = this.addComma(this._sectionModel.totalRaceOther.elementValue);
    this._sectionModel.bpRaceOther.elementValue = this.addComma(this._sectionModel.bpRaceOther.elementValue);
    this._sectionModel.totalRaceMultiple.elementValue = this.addComma(this._sectionModel.totalRaceMultiple.elementValue);
    this._sectionModel.bpRaceMultiple.elementValue = this.addComma(this._sectionModel.bpRaceMultiple.elementValue);
    this._sectionModel.totalRaceMissing.elementValue = this.addComma(this._sectionModel.totalRaceMissing.elementValue);
    this._sectionModel.bpRaceMissing.elementValue = this.addComma(this._sectionModel.bpRaceMissing.elementValue);
    this._sectionModel.totalMinority.elementValue = this.addComma(this._sectionModel.totalMinority.elementValue);
    this._sectionModel.bpMinority.elementValue = this.addComma(this._sectionModel.bpMinority.elementValue);
    this._sectionModel.totalNotMinority.elementValue = this.addComma(this._sectionModel.totalNotMinority.elementValue);
    this._sectionModel.bpNotMinority.elementValue = this.addComma(this._sectionModel.bpNotMinority.elementValue);
    this._sectionModel.totalMinorityMissing.elementValue = this.addComma(this._sectionModel.totalMinorityMissing.elementValue);
    this._sectionModel.bpMinorityMissing.elementValue = this.addComma(this._sectionModel.bpMinorityMissing.elementValue);
    this._sectionModel.allMinorityTotal.elementValue = this.addComma(this._sectionModel.allMinorityTotal.elementValue);
    this._sectionModel.bpMinorityTotal.elementValue = this.addComma(this._sectionModel.bpMinorityTotal.elementValue);
    this._sectionModel.totalLowNutrition.elementValue = this.addComma(this._sectionModel.totalLowNutrition.elementValue);
    this._sectionModel.bpLowNutrition.elementValue = this.addComma(this._sectionModel.bpLowNutrition.elementValue);
    this._sectionModel.totalHighNutrition.elementValue = this.addComma(this._sectionModel.totalHighNutrition.elementValue);
    this._sectionModel.bpHighNutrition.elementValue = this.addComma(this._sectionModel.bpHighNutrition.elementValue);
    this._sectionModel.totalNutritionMissing.elementValue = this.addComma(this._sectionModel.totalNutritionMissing.elementValue);
    this._sectionModel.bpNutritionMissing.elementValue = this.addComma(this._sectionModel.bpNutritionMissing.elementValue);
    this._sectionModel.allNutritionTotal.elementValue = this.addComma(this._sectionModel.allNutritionTotal.elementValue);
    this._sectionModel.bpNutritionTotal.elementValue = this.addComma(this._sectionModel.bpNutritionTotal.elementValue);
  }


  ageError(): boolean {
    if (this._sectionModel.totalBelow60.dataEntryErrorMessage ||
      this._sectionModel.bpBelow60.dataEntryErrorMessage ||
      this._sectionModel.totalRange1.dataEntryErrorMessage ||
      this._sectionModel.bpRange1.dataEntryErrorMessage ||
      this._sectionModel.totalRange2.dataEntryErrorMessage ||
      this._sectionModel.bpRange2.dataEntryErrorMessage ||
      this._sectionModel.totalRange3.dataEntryErrorMessage ||
      this._sectionModel.bpRange3.dataEntryErrorMessage ||
      this._sectionModel.totalRange4.dataEntryErrorMessage ||
      this._sectionModel.bpRange4.dataEntryErrorMessage ||
      this._sectionModel.totalAgeMissing.dataEntryErrorMessage ||
      this._sectionModel.bpAgeMissing.dataEntryErrorMessage ||
      this._sectionModel.allAgeTotal.dataEntryErrorMessage ||
      this._sectionModel.bpAgeTotal.dataEntryErrorMessage ||
      this._sectionModel.totalBelow60.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpBelow60.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange1.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange1.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange2.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange2.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange3.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange3.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRange4.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRange4.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalAgeMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAgeMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allAgeTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAgeTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  genderError(): boolean {
    if (this._sectionModel.totalFemale.dataEntryErrorMessage ||
      this._sectionModel.bpFemale.dataEntryErrorMessage ||
      this._sectionModel.totalMale.dataEntryErrorMessage ||
      this._sectionModel.bpMale.dataEntryErrorMessage ||
      this._sectionModel.totalOther.dataEntryErrorMessage ||
      this._sectionModel.bpOther.dataEntryErrorMessage ||
      this._sectionModel.totalGenderMissing.dataEntryErrorMessage ||
      this._sectionModel.bpGenderMissing.dataEntryErrorMessage ||
      this._sectionModel.allGenderTotal.dataEntryErrorMessage ||
      this._sectionModel.bpGenderTotal.dataEntryErrorMessage ||
      this._sectionModel.totalFemale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpFemale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalMale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMale.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalGenderMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGenderMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allGenderTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGenderTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  geoError(): boolean {
    if (this._sectionModel.totalRural.dataEntryErrorMessage ||
      this._sectionModel.bpRural.dataEntryErrorMessage ||
      this._sectionModel.totalNonRural.dataEntryErrorMessage ||
      this._sectionModel.bpNonRural.dataEntryErrorMessage ||
      this._sectionModel.totalGeoMissing.dataEntryErrorMessage ||
      this._sectionModel.bpGeoMissing.dataEntryErrorMessage ||
      this._sectionModel.allGeoTotal.dataEntryErrorMessage ||
      this._sectionModel.bpGeoTotal.dataEntryErrorMessage ||
      this._sectionModel.totalRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalNonRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNonRural.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalGeoMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGeoMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allGeoTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpGeoTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  povertyError(): boolean {
    if (this._sectionModel.totalBelowPoverty.dataEntryErrorMessage ||
      this._sectionModel.totalAbovePoverty.dataEntryErrorMessage ||
      this._sectionModel.totalMissingPoverty.dataEntryErrorMessage ||
      this._sectionModel.allPovertyTotal.dataEntryErrorMessage ||
      this._sectionModel.totalBelowPoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalAbovePoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalMissingPoverty.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allPovertyTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  householdError(): boolean {
    if (this._sectionModel.totalAlone.dataEntryErrorMessage ||
      this._sectionModel.bpAlone.dataEntryErrorMessage ||
      this._sectionModel.totalWithOthers.dataEntryErrorMessage ||
      this._sectionModel.bpWithOthers.dataEntryErrorMessage ||
      this._sectionModel.totalHouseholdMissing.dataEntryErrorMessage ||
      this._sectionModel.bpHouseholdMissing.dataEntryErrorMessage ||
      this._sectionModel.allHouseholdTotal.dataEntryErrorMessage ||
      this._sectionModel.bpHouseholdTotal.dataEntryErrorMessage ||
      this._sectionModel.totalAlone.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAlone.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalWithOthers.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpWithOthers.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalHouseholdMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpHouseholdMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allHouseholdTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpHouseholdTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  ethnicityError(): boolean {
    if (this._sectionModel.totalHispanic.dataEntryErrorMessage ||
      this._sectionModel.bpHispanic.dataEntryErrorMessage ||
      this._sectionModel.totalNonHispanic.dataEntryErrorMessage ||
      this._sectionModel.bpNonHispanic.dataEntryErrorMessage ||
      this._sectionModel.totalEthnicityMissing.dataEntryErrorMessage ||
      this._sectionModel.bpEthnicityMissing.dataEntryErrorMessage ||
      this._sectionModel.allEthnicityTotal.dataEntryErrorMessage ||
      this._sectionModel.bpEthnicityTotal.dataEntryErrorMessage ||
      this._sectionModel.totalHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalNonHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNonHispanic.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalEthnicityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpEthnicityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allEthnicityTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpEthnicityTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  raceError(): boolean {
    if (
      this._sectionModel.totalAmericanIndian.dataEntryErrorMessage ||
      this._sectionModel.bpAmericanIndian.dataEntryErrorMessage ||
      this._sectionModel.totalAsian.dataEntryErrorMessage ||
      this._sectionModel.bpAsian.dataEntryErrorMessage ||
      this._sectionModel.totalBlack.dataEntryErrorMessage ||
      this._sectionModel.bpBlack.dataEntryErrorMessage ||
      this._sectionModel.totalIslander.dataEntryErrorMessage ||
      this._sectionModel.bpIslander.dataEntryErrorMessage ||
      this._sectionModel.totalWhite.dataEntryErrorMessage ||
      this._sectionModel.bpWhite.dataEntryErrorMessage ||
      this._sectionModel.totalRaceOther.dataEntryErrorMessage ||
      this._sectionModel.bpRaceOther.dataEntryErrorMessage ||
      this._sectionModel.totalRaceMultiple.dataEntryErrorMessage ||
      this._sectionModel.bpRaceMultiple.dataEntryErrorMessage ||
      this._sectionModel.totalRaceMissing.dataEntryErrorMessage ||
      this._sectionModel.bpRaceMissing.dataEntryErrorMessage ||
      this._sectionModel.totalAmericanIndian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAmericanIndian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalAsian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpAsian.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalBlack.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpBlack.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalIslander.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpIslander.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalWhite.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpWhite.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRaceOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRaceOther.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRaceMultiple.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRaceMultiple.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalRaceMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpRaceMissing.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  minorityError(): boolean {
    if (this._sectionModel.totalMinority.dataEntryErrorMessage ||
      this._sectionModel.bpMinority.dataEntryErrorMessage ||
      this._sectionModel.totalNotMinority.dataEntryErrorMessage ||
      this._sectionModel.bpNotMinority.dataEntryErrorMessage ||
      this._sectionModel.totalMinorityMissing.dataEntryErrorMessage ||
      this._sectionModel.bpMinorityMissing.dataEntryErrorMessage ||
      this._sectionModel.allMinorityTotal.dataEntryErrorMessage ||
      this._sectionModel.bpMinorityTotal.dataEntryErrorMessage ||
      this._sectionModel.totalMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalNotMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNotMinority.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalMinorityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMinorityMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allMinorityTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpMinorityTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  nutritionError(): boolean {
    if (this._sectionModel.totalLowNutrition.dataEntryErrorMessage ||
      this._sectionModel.bpLowNutrition.dataEntryErrorMessage ||
      this._sectionModel.totalHighNutrition.dataEntryErrorMessage ||
      this._sectionModel.bpHighNutrition.dataEntryErrorMessage ||
      this._sectionModel.totalNutritionMissing.dataEntryErrorMessage ||
      this._sectionModel.bpNutritionMissing.dataEntryErrorMessage ||
      this._sectionModel.allNutritionTotal.dataEntryErrorMessage ||
      this._sectionModel.bpNutritionTotal.dataEntryErrorMessage ||
      this._sectionModel.allNutritionTotal.dataEntryErrorMessage ||
      this._sectionModel.bpNutritionTotal.dataEntryErrorMessage ||
      this._sectionModel.totalLowNutrition.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpLowNutrition.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalHighNutrition.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpHighNutrition.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalNutritionMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNutritionMissing.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allNutritionTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNutritionTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.allNutritionTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.bpNutritionTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }



  ageWarning(): boolean {
    if (this._sectionModel.totalBelow60.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpBelow60.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange1.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange1.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange3.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange3.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRange4.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalAgeMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAgeMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allAgeTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAgeTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  genderWarning(): boolean {
    if (this._sectionModel.totalFemale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpFemale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalMale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMale.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalGenderMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGenderMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allGenderTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGenderTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }
  geoWarning(): boolean {
    if (this._sectionModel.totalRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalNonRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNonRural.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalGeoMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGeoMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allGeoTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpGeoTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  povertyWarning(): boolean {
    if (this._sectionModel.totalBelowPoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalAbovePoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalMissingPoverty.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allPovertyTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  householdWarning(): boolean {
    if (this._sectionModel.totalAlone.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAlone.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalWithOthers.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpWithOthers.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalHouseholdMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpHouseholdMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allHouseholdTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpHouseholdTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  ethnicityWarning(): boolean {
    if (this._sectionModel.totalHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalNonHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNonHispanic.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalEthnicityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpEthnicityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allEthnicityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpEthnicityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  raceWarning(): boolean {
    if (this._sectionModel.totalAmericanIndian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAmericanIndian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalAsian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpAsian.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalBlack.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpBlack.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalIslander.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpIslander.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalWhite.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpWhite.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRaceOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRaceOther.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRaceMultiple.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRaceMultiple.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalRaceMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpRaceMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  minorityWarning(): boolean {
    if (this._sectionModel.totalMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalNotMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNotMinority.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalMinorityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMinorityMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allMinorityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpMinorityTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  nutritionWarning(): boolean {
    if (this._sectionModel.totalLowNutrition.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpLowNutrition.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalHighNutrition.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpHighNutrition.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalNutritionMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNutritionMissing.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allNutritionTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNutritionTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.allNutritionTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.bpNutritionTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.oaLockedNav = false;
      return true;
    } else {
      this._commonService.oaLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

  validate(data: DataElement): DataElement {
    if (data.elementValue != null && data.elementValue !== '') {
      if (this._validation.validate(data.validationType, data.elementValue)) {
        if (!this._validation.validateMaxLength(data.elementValue, data.maxLength)) {
          data.dataEntryErrorMessage = 'Value cannot be greater than ' + data.maxLength + ' digits.';
          data.questionStatus = this.statusEnum.L1InProgressError;
        } else {
          data.dataEntryErrorMessage = '';
          data.questionStatus = this.statusEnum.L1InProgress;
        }
      } else {
        data.dataEntryErrorMessage = this._validation.getErrorMessage(data.validationType);
        data.questionStatus = this.statusEnum.L1InProgressError;
      }
    } else {
      data.dataEntryErrorMessage = '';
      data.questionStatus = this.statusEnum.NotStarted;
    }

    return data;
  }

}
