<!-- regions 1-5 -->
<div class="row mb-2">
  <div class="col-md-2 mr-2">
    <label for="cbRegionOne">
      <input type="checkbox" id="cbRegionOne" name="cbRegionOne" [checked]="areAllStatesChecked('1')" (click)="toggleRegion('1', $event)">&nbsp;Region 1
    </label>
    <hr />
    <label class="text-normal" *ngFor="let titleState of statesByRegion[1]" for="{{'cb'+titleState.state}}">
      <input type="checkbox" name="{{'cb'+titleState.state}}" id="{{'cb'+titleState.state}}" [ngModel]="titleState.isSelected"
        (ngModelChange)="modelChange(titleState)">&nbsp;{{titleState.state}}
    </label>
  </div>
  <div class="col-md-2 mr-2">
    <label for="cbRegionTwo">
      <input type="checkbox" id="cbRegionTwo" name="cbRegionTwo" [checked]="areAllStatesChecked('2')" (click)="toggleRegion('2', $event)">&nbsp;Region 2
    </label>
    <hr />
    <label class="text-normal" *ngFor="let titleState of statesByRegion[2]" for="{{'cb'+titleState.state}}">
      <input type="checkbox" name="{{'cb'+titleState.state}}" id="{{'cb'+titleState.state}}" [ngModel]="titleState.isSelected"
        (ngModelChange)="modelChange(titleState)">&nbsp;{{titleState.state}}
    </label>
  </div>
  <div class="col-md-2 mr-2">
    <label for="cbRegionThree">
      <input type="checkbox" id="cbRegionThree" name="cbRegionThree" [checked]="areAllStatesChecked('3')" (click)="toggleRegion('3', $event)">&nbsp;Region 3
    </label>
    <hr />
    <label class="text-normal" *ngFor="let titleState of statesByRegion[3]" for="{{'cb'+titleState.state}}">
      <input type="checkbox" name="{{'cb'+titleState.state}}" id="{{'cb'+titleState.state}}" [ngModel]="titleState.isSelected"
        (ngModelChange)="modelChange(titleState)">&nbsp;{{titleState.state}}
    </label>
  </div>
  <div class="col-md-2 mr-2">
    <label for="cbRegionFour">
      <input type="checkbox" id="cbRegionFour" name="cbRegionFour" [checked]="areAllStatesChecked('4')" (click)="toggleRegion('4', $event)">&nbsp;Region 4
    </label>
    <hr />
    <label class="text-normal" *ngFor="let titleState of statesByRegion[4]" for="{{'cb'+titleState.state}}">
      <input type="checkbox" name="{{'cb'+titleState.state}}" id="{{'cb'+titleState.state}}" [ngModel]="titleState.isSelected"
        (ngModelChange)="modelChange(titleState)">&nbsp;{{titleState.state}}
    </label>
  </div>
  <div class="col-md-2 mr-2">
    <label for="cbRegionFive">
      <input type="checkbox" id="cbRegionFive" name="cbRegionFive" [checked]="areAllStatesChecked('5')" (click)="toggleRegion('5', $event)">&nbsp;Region 5
    </label>
    <hr />
    <label class="text-normal" *ngFor="let titleState of statesByRegion[5]" for="{{'cb'+titleState.state}}">
      <input type="checkbox" name="{{'cb'+titleState.state}}" id="{{'cb'+titleState.state}}" [ngModel]="titleState.isSelected"
        (ngModelChange)="modelChange(titleState)">&nbsp;{{titleState.state}}
    </label>
  </div>
</div>
<!-- regions 6-10 -->
<div class="row">
  <div class="col-md-2 mr-2">
    <label for="cbRegionSix">
      <input type="checkbox" id="cbRegionSix" name="cbRegionSix" [checked]="areAllStatesChecked('6')" (click)="toggleRegion('6', $event)">&nbsp;Region 6
    </label>
    <hr />
    <label class="text-normal" *ngFor="let titleState of statesByRegion[6]" for="{{'cb'+titleState.state}}">
      <input type="checkbox" name="{{'cb'+titleState.state}}" id="{{'cb'+titleState.state}}" [ngModel]="titleState.isSelected"
        (ngModelChange)="modelChange(titleState)">&nbsp;{{titleState.state}}
    </label>
  </div>
  <div class="col-md-2 mr-2">
    <label for="cbRegionSeven">
      <input type="checkbox" id="cbRegionSeven" name="cbRegionSeven" [checked]="areAllStatesChecked('7')" (click)="toggleRegion('7', $event)">&nbsp;Region 7
    </label>
    <hr />
    <label class="text-normal" *ngFor="let titleState of statesByRegion[7]" for="{{'cb'+titleState.state}}">
      <input type="checkbox" name="{{'cb'+titleState.state}}" id="{{'cb'+titleState.state}}" [ngModel]="titleState.isSelected"
        (ngModelChange)="modelChange(titleState)">&nbsp;{{titleState.state}}
    </label>
  </div>
  <div class="col-md-2 mr-2">
    <label for="cbRegionEight">
      <input type="checkbox" id="cbRegionEight" name="cbRegionEight" [checked]="areAllStatesChecked('8')" (click)="toggleRegion('8', $event)">&nbsp;Region 8
    </label>
    <hr />
    <label class="text-normal" *ngFor="let titleState of statesByRegion[8]" for="{{'cb'+titleState.state}}">
      <input type="checkbox" name="{{'cb'+titleState.state}}" id="{{'cb'+titleState.state}}" [ngModel]="titleState.isSelected"
        (ngModelChange)="modelChange(titleState)">&nbsp;{{titleState.state}}
    </label>
  </div>
  <div class="col-md-2 mr-2">
    <label for="cbRegionNine">
      <input type="checkbox" id="cbRegionNine" name="cbRegionNine" [checked]="areAllStatesChecked('9')" (click)="toggleRegion('9', $event)">&nbsp;Region 9
    </label>
    <hr />
    <label class="text-normal" *ngFor="let titleState of statesByRegion[9]" for="{{'cb'+titleState.state}}">
      <input type="checkbox" name="{{'cb'+titleState.state}}" id="{{'cb'+titleState.state}}" [ngModel]="titleState.isSelected"
        (ngModelChange)="modelChange(titleState)">&nbsp;{{titleState.state}}
    </label>
  </div>
  <div class="col-md-2 mr-2">
    <label for="cbRegionTen">
      <input type="checkbox" id="cbRegionTen" name="cbRegionTen" [checked]="areAllStatesChecked('10')" (click)="toggleRegion('10', $event)">&nbsp;Region 10
    </label>
    <hr />
    <label class="text-normal" *ngFor="let titleState of statesByRegion[10]" for="{{'cb'+titleState.state}}">
      <input type="checkbox" name="{{'cb'+titleState.state}}" id="{{'cb'+titleState.state}}" [ngModel]="titleState.isSelected"
        (ngModelChange)="modelChange(titleState)">&nbsp;{{titleState.state}}
    </label>
  </div>
</div>