<ng-container *ngIf="_claimService.currentTitle === 'VI'">
  <ul>
    <li *ngIf="disableNavT6VE()">
      <a id="titleVIVariancedDisabledLnk" class="disabled">
        <strong>Explanation of Variances</strong>
      </a>
    </li>
    <li>
      <a *ngIf="!disableNavT6VE() && !router.url.includes('/titlevi/tviVarianceExplanationsAB') && !router.url.includes('/titlevi/tviVarianceExplanationsC')"
         [routerLink]="['/titlevi/' + firstAvilableSection()]" [routerLinkActive]="['active']" id="firstAvilableSectionLnk">
        <p>
          <strong>Explanation of Variances</strong>
        </p>
      </a>
    </li>
    <li *ngIf="!disableNavT6VE() && (router.url.includes('/titlevi/tviVarianceExplanationsAB') || router.url.includes('/titlevi/tviVarianceExplanationsC'))"
        class="leadNav subActive">
      <strong>Explanation of Variances</strong>
      <ul>
        <!--Part A/B VE-->
        <li *ngIf="!partABVEDisabled()"
            [ngClass]="(router.url.includes('/titlevi/tviVarianceExplanationsAB'))?'active':''">
          <a id="partABLnk"
             [routerLink]="['/titlevi/tviVarianceExplanationsAB']"
             [routerLinkActive]="['active']">
            <p>Part A/B</p>
            <span *ngIf="router.url.includes('/titlevi/tviVarianceExplanationsAB')">
              <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
            </span>
          </a>
        </li>
        <li *ngIf="partABVEDisabled()">
          <a id="partABDisabledLnk" class="disabled">
            <p>Part A/B</p>
          </a>
        </li>
        <!--Part C VE-->
        <li *ngIf="titleViIsPartCRequired() && !partCVEDisabled()"
            [ngClass]="(router.url.includes('/titlevi/tviVarianceExplanationsC'))?'active':''">
          <a id="partCLnk"
             [routerLink]="['/titlevi/tviVarianceExplanationsC']"
             [routerLinkActive]="['active']">
            <p>Part C</p>
            <span *ngIf="router.url.includes('/titlevi/tviVarianceExplanationsC')">
              <i class="fa fa-chevron-circle-right fa-2x" aria-hidden="true"></i>
            </span>
          </a>
        </li>
        <li *ngIf="titleViIsPartCRequired() && partCVEDisabled()">
          <a id="partCDisabledLnk" class="disabled">
            <p>Part C</p>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</ng-container>
