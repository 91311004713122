import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class TitleVIIExpendituresSection extends Section {

    suaExpend: IDataElement;
    aaaExpend: IDataElement;
    apsExpend: IDataElement;
    otherEntitiesExpend: IDataElement;
    vii3Total: IDataElement;
    otherStateExpend: IDataElement;
    nonStateExpend: IDataElement;
    chapter3Total: IDataElement;
    chapter4Total: IDataElement;
    singlePSA: boolean = true;

    constructor() {
        super();

        this.suaExpend = new DataElement();
        this.aaaExpend = new DataElement();
        this.apsExpend = new DataElement();
        this.otherEntitiesExpend = new DataElement();
        this.vii3Total = new DataElement();
        this.otherStateExpend = new DataElement();
        this.nonStateExpend = new DataElement();
        this.chapter3Total = new DataElement();
        this.chapter4Total = new DataElement();
        this.singlePSA = true;
    }
}
