import { QuestionStatusEnum } from '../shared/dataElement';
import { UserManagementStrings } from '../UserManagement/userManagerObjects';

export class ReportModel {

    public createdBy: string;
    public userRole: string;
    public reportName: string;
    constructor() { }
}

export class YearState {

    constructor(public year: string, public state: string) { }
}

export class YearStateRegion {
    // constructor(public year: string, public state: string, public region: string) { }
    constructor(public year: string, public state: string, public psa: string, public region: string, public isRollup: boolean) { }
}

export enum YearStatusFilterOption {
    LastYear,           // Filters states for the report based on the last year's statuses. e.g. (was 52) 30, (was 52) 30, 30
    EachYear,           // Filters states for the report for each year's statuses. e.g. 52, 52, 30
    IntersectYears     // Filters states for the report based on the intersect of
    // each year filtered by status, e.g. (was 52) 15, 15, (was 52) 15
}

export class YearStateRegionReport extends ReportModel {

    constructor(public yearStateRegionList: Array<YearStateRegion>
        , public filterStateOption?: YearStatusFilterOption
        , public filterStateStatus?: QuestionStatusEnum
        , public userStateFilterStatus?: QuestionStatusEnum
        , public userState?: string) { super(); }

    public setStandardFilters(orgLevel: string, filterStateOption: YearStatusFilterOption, userState?: string) {
        this.filterStateOption = filterStateOption;
        if (orgLevel === UserManagementStrings.orgLevelState) {
            this.filterStateStatus = QuestionStatusEnum.Locked;
            this.userStateFilterStatus = QuestionStatusEnum.L1InProgress;
            this.userState = userState;
        } else {
            this.filterStateStatus = QuestionStatusEnum.Submitted;
        }
    }
}

// export class TwoYearReport extends ReportModel {

//     constructor(public yearOne: YearState, public yearTwo: YearState) { super(); }
// }

// export class FiveYearReport extends ReportModel {

//     constructor(
//         public yearOne: YearState,
//         public yearTwo: YearState,
//         public yearThree: YearState,
//         public yearFour: YearState,
//         public yearFive: YearState
//     ) { super(); }
// }

// export class AnnualRollupReport extends ReportModel {
//     constructor(
//         public yearStateRegionList: Array<YearStateRegion>
//     ) { super(); }
// }
