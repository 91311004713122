export enum RollupStatus { NotStarted, PartialRollup, FullRollup, FinishedRollup }

export namespace RollupStatus {
    export function getFriendlyName(rollupStatus: RollupStatus): string {
        switch (rollupStatus) {
            case (RollupStatus.NotStarted):
                return 'Not started';
            case (RollupStatus.PartialRollup):
                return 'Partial';
            case (RollupStatus.FullRollup):
                return 'Full';
            case (RollupStatus.FinishedRollup):
                return 'Finished';
        }
    }
}

export class Rollup {

    constructor(public id?: string,
        public fiscalYear?: string,
        public state?: string,
        public type?: string,
        public status: RollupStatus = RollupStatus.NotStarted,
        public canGenerateRollup: boolean = false) { }

}
