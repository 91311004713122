import { QuestionStatusEnum } from '../../shared/dataElement';
import { IOverview } from '../../shared/Overview/overview';

export interface ITVIOverviewDto {
    ffy: string;
    granteeId: string;
    granteeName: string;
    region: string;
    state: string;

    overview: ITVIOverview;
}

export class TVIOverviewDto implements ITVIOverviewDto {
    ffy: string;
    granteeId: string;
    granteeName: string;
    region: string;
    state: string;

    // tslint:disable-next-line: no-use-before-declare
    overview: ITVIOverview = new TVIOverview();
}

export interface ITVIOverview extends IOverview {
    org: string;
    sectionStatus: QuestionStatusEnum;
    minStatus: QuestionStatusEnum;
    sectionName: string;
    sectionTitle: string;
    sortOrder: number;
    isRequired: boolean;
    isOptional: boolean;
    children: ITVIOverview[];
    lastModified: string;
    updatedBy: string;
}

export class TVIOverview implements ITVIOverview {
    public static readonly ABOverview = 'ABOverview';
    public static readonly COverview = 'COverview';
    org: string;
    sectionStatus: QuestionStatusEnum;
    minStatus: QuestionStatusEnum;
    sectionName: string;
    sectionTitle: string;
    sortOrder: number;
    isRequired: boolean;
    isOptional: boolean;
    children: ITVIOverview[] = [];
    lastModified: string;
    updatedBy: string;
}
