<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <h2>Aging Network Profile - Respite Voucher</h2>
        <p class="info mt-1">All fields are required</p>
        <div class="col-md-12 background-gray-lighter mb-1">
          <h2>Caregivers of Older Adults Served (Title III-E Funded)</h2>
        </div>
        <div class="col-md-9">
          <label for="coaPersonsServed" class="normal">Total number of persons served</label>
        </div>
        <div class="col-md-3 mb-1">
          <acl-data-element-text [id]="'inputcoaPersonsServed'" [de]="_sectionModel.coaPersonsServed"
          (change)="onChange()">
         </acl-data-element-text>
         <acl-input-error [de]="_sectionModel.coaPersonsServed"></acl-input-error>
        </div>
        <div class="col-md-9"></div>
        <div class="col-md-3 mb-1"></div>
        <div class="col-md-9"></div>
        <div class="col-md-3 mb-1"></div>
        <div class="col-md-12">
          <label>Which of the following funding sources were expended for Respite Vouchers?</label>
        </div>
        <div class="col-md-9">
          <label for="coaExpend" class="normal">Title III expenditure</label>
          <div *ngIf="_sectionModel.coaExpend.dataEntryErrorMessage" class="alert alert-danger" role="alert">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.coaExpend.dataEntryErrorMessage}}
          </div>
          <div *ngIf="!_sectionModel.coaExpend.dataEntryErrorMessage && (_sectionModel.coaExpend.questionStatus == questionStatusEnum.L2InProgressError || _sectionModel.coaExpend.questionStatus == questionStatusEnum.L2ValidatedWarning)"
            [ngClass]="setErrorDisplayType(_sectionModel.coaExpend.questionStatus)" role="alert">
            <i *ngIf="_sectionModel.coaExpend.questionStatus == questionStatusEnum.L2ValidatedWarning" class="fa fa-exclamation-triangle"
              aria-hidden="true">
              <span class="sr-only">Warning:</span>
            </i>
            <i *ngIf="_sectionModel.coaExpend.questionStatus != questionStatusEnum.L2ValidatedWarning" class="fa fa-times"
              aria-hidden="true">
              <span class="sr-only">Error:</span>
            </i>
            &nbsp;{{_sectionModel.coaExpend.businessErrorMessage}}
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <fieldset>
              <label class="radio-inline" for="coaExpendYes">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.coaExpend.elementValue" type="radio"
                  name="coaExpend" id="coaExpendYes" value="Yes"><span class="sr-only">Title III expenditure</span> Yes
              </label>
              <label class="radio-inline" for="coaExpendNo">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.coaExpend.elementValue" type="radio"
                  name="coaExpend" id="coaExpendNo" value="No"><span class="sr-only">Title III expenditure</span> No
              </label>
            </fieldset>
          </div>
        </div>
        <div class="col-md-9">
          <label for="coaState" class="normal">Other: State expenditure</label>
          <div *ngIf="_sectionModel.coaState.dataEntryErrorMessage" class="alert alert-danger" role="alert">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.coaState.dataEntryErrorMessage}}
          </div>
          <div *ngIf="!_sectionModel.coaState.dataEntryErrorMessage && (_sectionModel.coaState.questionStatus == questionStatusEnum.L2InProgressError || _sectionModel.coaState.questionStatus == questionStatusEnum.L2ValidatedWarning)"
            [ngClass]="setErrorDisplayType(_sectionModel.coaState.questionStatus)" role="alert">
            <i *ngIf="_sectionModel.coaState.questionStatus == questionStatusEnum.L2ValidatedWarning" class="fa fa-exclamation-triangle"
              aria-hidden="true">
              <span class="sr-only">Warning:</span>
            </i>
            <i *ngIf="_sectionModel.coaState.questionStatus != questionStatusEnum.L2ValidatedWarning" class="fa fa-times"
              aria-hidden="true">
              <span class="sr-only">Error:</span>
            </i>
            &nbsp;{{_sectionModel.coaState.businessErrorMessage}}
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <fieldset>
              <label class="radio-inline" for="coaStateYes">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.coaState.elementValue" type="radio"
                  name="coaState" id="coaStateYes" value="Yes"><span class="sr-only">Other: State expenditure</span> Yes
              </label>
              <label class="radio-inline" for="coaStateNo">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.coaState.elementValue" type="radio"
                  name="coaState" id="coaStateNo" value="No"><span class="sr-only">Other: State expenditure</span> No
              </label>
            </fieldset>
          </div>
        </div>
        <div class="col-md-9">
          <label for="coaNonState" class="normal">Other: Non-state expenditure</label>
          <div *ngIf="_sectionModel.coaNonState.dataEntryErrorMessage" class="alert alert-danger" role="alert">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.coaNonState.dataEntryErrorMessage}}
          </div>
          <div *ngIf="!_sectionModel.coaNonState.dataEntryErrorMessage && (_sectionModel.coaNonState.questionStatus == questionStatusEnum.L2InProgressError || _sectionModel.coaNonState.questionStatus == questionStatusEnum.L2ValidatedWarning)"
            [ngClass]="setErrorDisplayType(_sectionModel.coaNonState.questionStatus)" role="alert">
            <i *ngIf="_sectionModel.coaNonState.questionStatus == questionStatusEnum.L2ValidatedWarning" class="fa fa-exclamation-triangle"
              aria-hidden="true">
              <span class="sr-only">Warning:</span>
            </i>
            <i *ngIf="_sectionModel.coaNonState.questionStatus != questionStatusEnum.L2ValidatedWarning" class="fa fa-times"
              aria-hidden="true">
              <span class="sr-only">Error:</span>
            </i>
            &nbsp;{{_sectionModel.coaNonState.businessErrorMessage}}
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <fieldset>
              <label class="radio-inline" for="coaNonStateYes">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.coaNonState.elementValue" type="radio"
                  name="coaNonState" id="coaNonStateYes" value="Yes"><span class="sr-only">Other: Non-state expenditure</span> Yes
              </label>
              <label class="radio-inline" for="coaNonStateNo">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.coaNonState.elementValue" type="radio"
                  name="coaNonState" id="coaNonStateNo" value="No"><span class="sr-only">Other: Non-state expenditure</span> No
              </label>
            </fieldset>
          </div>
        </div>
        <div class="col-md-9">
          <label for="coaProgramIncome" class="normal">Program income expended</label>
          <div *ngIf="_sectionModel.coaProgramIncome.dataEntryErrorMessage" class="alert alert-danger" role="alert">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.coaProgramIncome.dataEntryErrorMessage}}
          </div>
          <div *ngIf="!_sectionModel.coaProgramIncome.dataEntryErrorMessage && (_sectionModel.coaProgramIncome.questionStatus == questionStatusEnum.L2InProgressError || _sectionModel.coaProgramIncome.questionStatus == questionStatusEnum.L2ValidatedWarning)"
            [ngClass]="setErrorDisplayType(_sectionModel.coaProgramIncome.questionStatus)" role="alert">
            <i *ngIf="_sectionModel.coaProgramIncome.questionStatus == questionStatusEnum.L2ValidatedWarning" class="fa fa-exclamation-triangle"
              aria-hidden="true">
              <span class="sr-only">Warning:</span>
            </i>
            <i *ngIf="_sectionModel.coaProgramIncome.questionStatus != questionStatusEnum.L2ValidatedWarning" class="fa fa-times"
              aria-hidden="true">
              <span class="sr-only">Error:</span>
            </i>
            &nbsp;{{_sectionModel.coaProgramIncome.businessErrorMessage}}
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <fieldset>
              <label class="radio-inline" for="coaProgramIncomeYes">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.coaProgramIncome.elementValue" type="radio"
                  name="coaProgramIncome" id="coaProgramIncomeYes" value="Yes"><span class="sr-only">Program income expended</span> Yes
              </label>
              <label class="radio-inline" for="coaProgramIncomeNo">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.coaProgramIncome.elementValue" type="radio"
                  name="coaProgramIncome" id="coaProgramIncomeNo" value="No"><span class="sr-only">Program income expended</span> No
              </label>
            </fieldset>
          </div>
        </div>

        <div class="col-md-12 background-gray-lighter mb-1">
          <h2>Older Relative Caregivers Served (Title III-E Funded)</h2>
        </div>
        <div class="col-md-9">
          <label for="orcPersonsServed" class="normal">Total number of persons served</label>
        </div>
        <div class="col-md-3 mb-1">
          <acl-data-element-text [id]="'inputorcPersonsServed'" [de]="_sectionModel.orcPersonsServed"
          (change)="onChange()">
         </acl-data-element-text>
         <acl-input-error [de]="_sectionModel.orcPersonsServed"></acl-input-error>
        </div>
        <div class="col-md-9"></div>
        <div class="col-md-3 mb-1"></div>
        <div class="col-md-9"></div>
        <div class="col-md-3 mb-1"></div>
        <div class="col-md-12">
          <label>Which of the following funding sources were expended for Respite Vouchers?</label>
        </div>
        <div class="col-md-9">
          <label for="orcExpend" class="normal">Title III expenditure</label>
          <div *ngIf="_sectionModel.orcExpend.dataEntryErrorMessage" class="alert alert-danger" role="alert">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.orcExpend.dataEntryErrorMessage}}
          </div>
          <div *ngIf="!_sectionModel.orcExpend.dataEntryErrorMessage && (_sectionModel.orcExpend.questionStatus == questionStatusEnum.L2InProgressError || _sectionModel.orcExpend.questionStatus == questionStatusEnum.L2ValidatedWarning)"
            [ngClass]="setErrorDisplayType(_sectionModel.orcExpend.questionStatus)" role="alert">
            <i *ngIf="_sectionModel.orcExpend.questionStatus == questionStatusEnum.L2ValidatedWarning" class="fa fa-exclamation-triangle"
              aria-hidden="true">
              <span class="sr-only">Warning:</span>
            </i>
            <i *ngIf="_sectionModel.orcExpend.questionStatus != questionStatusEnum.L2ValidatedWarning" class="fa fa-times"
              aria-hidden="true">
              <span class="sr-only">Error:</span>
            </i>
            &nbsp;{{_sectionModel.orcExpend.businessErrorMessage}}
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <fieldset>
              <label class="radio-inline" for="orcExpendYes">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.orcExpend.elementValue" type="radio"
                  name="orcExpend" id="orcExpendYes" value="Yes"><span class="sr-only">Title III expenditure</span> Yes
              </label>
              <label class="radio-inline" for="orcExpendNo">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.orcExpend.elementValue" type="radio"
                  name="orcExpend" id="orcExpendNo" value="No"><span class="sr-only">Title III expenditure</span> No
              </label>
            </fieldset>
          </div>
        </div>
        <div class="col-md-9">
          <label for="orcState" class="normal">Other: State expenditure</label>
          <div *ngIf="_sectionModel.orcState.dataEntryErrorMessage" class="alert alert-danger" role="alert">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.orcState.dataEntryErrorMessage}}
          </div>
          <div *ngIf="!_sectionModel.orcState.dataEntryErrorMessage && (_sectionModel.orcState.questionStatus == questionStatusEnum.L2InProgressError || _sectionModel.orcState.questionStatus == questionStatusEnum.L2ValidatedWarning)"
            [ngClass]="setErrorDisplayType(_sectionModel.orcState.questionStatus)" role="alert">
            <i *ngIf="_sectionModel.orcState.questionStatus == questionStatusEnum.L2ValidatedWarning" class="fa fa-exclamation-triangle"
              aria-hidden="true">
              <span class="sr-only">Warning:</span>
            </i>
            <i *ngIf="_sectionModel.orcState.questionStatus != questionStatusEnum.L2ValidatedWarning" class="fa fa-times"
              aria-hidden="true">
              <span class="sr-only">Error:</span>
            </i>
            &nbsp;{{_sectionModel.orcState.businessErrorMessage}}
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <fieldset>
              <label class="radio-inline" for="orcStateYes">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.orcState.elementValue" type="radio"
                  name="orcState" id="orcStateYes" value="Yes"><span class="sr-only">Other: State expenditure<</span> Yes
              </label>
              <label class="radio-inline" for="orcStateNo">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.orcState.elementValue" type="radio"
                  name="orcState" id="orcStateNo" value="No"><span class="sr-only">Other: State expenditure<</span> No
              </label>
            </fieldset>
          </div>
        </div>
        <div class="col-md-9">
          <label for="orcNonState" class="normal">Other: Non-state expenditure</label>
          <div *ngIf="_sectionModel.orcNonState.dataEntryErrorMessage" class="alert alert-danger" role="alert">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.orcNonState.dataEntryErrorMessage}}
          </div>
          <div *ngIf="!_sectionModel.orcNonState.dataEntryErrorMessage && (_sectionModel.orcNonState.questionStatus == questionStatusEnum.L2InProgressError || _sectionModel.orcNonState.questionStatus == questionStatusEnum.L2ValidatedWarning)"
            [ngClass]="setErrorDisplayType(_sectionModel.orcNonState.questionStatus)" role="alert">
            <i *ngIf="_sectionModel.orcNonState.questionStatus == questionStatusEnum.L2ValidatedWarning" class="fa fa-exclamation-triangle"
              aria-hidden="true">
              <span class="sr-only">Warning:</span>
            </i>
            <i *ngIf="_sectionModel.orcNonState.questionStatus != questionStatusEnum.L2ValidatedWarning" class="fa fa-times"
              aria-hidden="true">
              <span class="sr-only">Error:</span>
            </i>
            &nbsp;{{_sectionModel.orcNonState.businessErrorMessage}}
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <fieldset>
              <label class="radio-inline" for="orcNonStateYes">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.orcNonState.elementValue" type="radio"
                  name="orcNonState" id="orcNonStateYes" value="Yes"><span class="sr-only">Other: Non-state expenditure</span> Yes
              </label>
              <label class="radio-inline" for="orcNonStateNo">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.orcNonState.elementValue" type="radio"
                  name="orcNonState" id="orcNonStateNo" value="No"><span class="sr-only">Other: Non-state expenditure</span> No
              </label>
            </fieldset>
          </div>
        </div>
        <div class="col-md-9">
          <label for="orcProgramIncome" class="normal">Program income expended</label>
          <div *ngIf="_sectionModel.orcProgramIncome.dataEntryErrorMessage" class="alert alert-danger" role="alert">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.orcProgramIncome.dataEntryErrorMessage}}
          </div>
          <div *ngIf="!_sectionModel.orcProgramIncome.dataEntryErrorMessage && (_sectionModel.orcProgramIncome.questionStatus == questionStatusEnum.L2InProgressError || _sectionModel.orcProgramIncome.questionStatus == questionStatusEnum.L2ValidatedWarning)"
            [ngClass]="setErrorDisplayType(_sectionModel.orcProgramIncome.questionStatus)" role="alert">
            <i *ngIf="_sectionModel.orcProgramIncome.questionStatus == questionStatusEnum.L2ValidatedWarning" class="fa fa-exclamation-triangle"
              aria-hidden="true">
              <span class="sr-only">Warning:</span>
            </i>
            <i *ngIf="_sectionModel.orcProgramIncome.questionStatus != questionStatusEnum.L2ValidatedWarning" class="fa fa-times"
              aria-hidden="true">
              <span class="sr-only">Error:</span>
            </i>
            &nbsp;{{_sectionModel.orcProgramIncome.businessErrorMessage}}
          </div>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <fieldset>
              <label class="radio-inline" for="orcProgramIncomeYes">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.orcProgramIncome.elementValue" type="radio"
                  name="orcProgramIncome" id="orcProgramIncomeYes" value="Yes"><span class="sr-only">Program income expended</span> Yes
              </label>
              <label class="radio-inline" for="orcProgramIncomeNo">
                <input (change)="unsavedChanges = true" [(ngModel)]="_sectionModel.orcProgramIncome.elementValue" type="radio"
                  name="orcProgramIncome" id="orcProgramIncomeNo" value="No"><span class="sr-only">Program income expended</span> No
              </label>
            </fieldset>
          </div>
        </div>

        <div class="col-md-12 row">
          <div *ngIf="!validForm" class="has-error">
            <p class="help-block">
              There are errors on the page. You must fix the errors or clear the data in those fields in order to Save
              this
              form.
            </p>
          </div>
          <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
          <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>
          <button class="btn btn-default mr-1" (click)="save()" [disabled]="!validForm || !_sectionModel.isRequired">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
        </div>
        <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1" style="display: none;">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
                <h3 class="modal-title" id="nextModalLabel">Recent changes on this page will not be saved</h3>
              </div>
              <div class="modal-body">
                <p>
                  There are errors on the page. You must fix the errors or clear the data in those fields in order to
                  save this form.
                  <br />
                  <br /> You may select "Go back to form" to remove the errors or you may select "Proceed to next
                  section" and
                  all of new the data entered in the current section will be lost.
                </p>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" type="button" href="#">Go back to form</button>
                <button class="btn btn-primary pull-right" (click)="unsavedChanges = false;" data-dismiss="modal" type="button"
                  [routerLink]="['/data-submissions/OAConsumerTps']">Proceed to next section</button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
