import { TitleRoute } from '../../shared/Model/titleRoute';
import { LandingComponent } from './landing.component';
import { TIIIOverview } from '../../shared/section';
import { RouteLookup } from '../../shared/Routing/routeLookup';


export const landingRoutes: TitleRoute[] = [
    { path: 'titleIIIANHome', sectionName: TIIIOverview.ANSectionServiceName, component: LandingComponent },
    { path: 'titleIIIOAHome', sectionName: TIIIOverview.OASectionServiceName, component: LandingComponent },
    { path: 'titleIIICOAHome', sectionName: TIIIOverview.COASectionServiceName, component: LandingComponent },
    { path: 'titleIIIORCHome', sectionName: TIIIOverview.ORCSectionServiceName, component: LandingComponent },
    { path: 'titleIIIExpendituresHome', sectionName: TIIIOverview.ExpendituresSectionServiceName, component: LandingComponent },
    { path: 'titleIIIVarianceHome', sectionName: 'titleIIIVarianceHome', component: LandingComponent },
];
export const routeLookup: RouteLookup = new RouteLookup(landingRoutes);
