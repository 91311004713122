import { Component, OnInit, HostListener, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { FileService } from './files/file.service';
import { IFile } from './files/file';
import { ClaimService } from './shared/claim.service';
import { ConfigService } from './auth/service/config.service';
import { ToastrService } from './shared/toastr.service';
import { LookupService } from './lookup.service';
import { AclTitleEnum } from './app.constants';
import { LogoutTypeParam, LogoutTypes } from './auth/login/logout.component';
import { HelpService } from './help/help.service';
import { OktaService } from './auth/service/okta.service';
import { DestructibleComponent } from './destructible.component';
import { environment } from '../environments/environment';
import { LoadingService } from './shared/loading.service';
import { delay } from 'rxjs/operators';

declare var jQuery: any;

@Component({
  selector: 'my-app',
  templateUrl: 'app.component.html',
  providers: [ConfigService]
})

export class AppComponent extends DestructibleComponent implements OnInit {
  errorMessage: string;
  currentFile: IFile;
  dataEntryState: string;
  previousFile: IFile;
  showKeyboardShortcutsMenu: boolean = false;
  userRoleText: string;
  fileIsEditable: boolean;
  isDeactivated: boolean = false;
  titleEnum = AclTitleEnum;
  enabledTitleIIIStates: Array<string> = [];
  private toastrOptions: any = {
    closeOnHover: false, timeOut: '-1', showDuration: '-1',
    closeButton: true, positionClass: 'toast-top-full-width'
  };
  public isLoading: boolean = false;

  constructor(
    public zone: NgZone,
    public route: ActivatedRoute,
    public _router: Router,
    //public _fileService: FileService,
    public _claimService: ClaimService,
    public _configService: ConfigService,
    public _toastr: ToastrService,
    public _oktaService: OktaService,
    public _lookupService: LookupService,
    public _helpService: HelpService,
    private _loading: LoadingService
  ) { super(); }


  ngOnInit(): void {
    this.listenToLoading();

    this._oktaService.isAuthenticatedAsync().then(isAuthenticated => {
      if (isAuthenticated) {
        this._claimService.init$.subscribe(
          () => {
            if (this._claimService.currentTitle.includes('III')) { this.getAAAStates(); }
          }, error => this.errorMessage = <any>error);
      }
    });

    switch (sessionStorage.getItem(LogoutTypeParam)) {
      case (LogoutTypes.Timeout): {
        this._toastr.warningWithOptions(`<span role="alert">Your session has timed out due to inactivity.
        You must sign in again to continue working.</span><button tabindex="1" id="sessionSignoffToastBtn"
        class="btn-sm btn-default col-sm-offset-1" onclick="getElementById(\'signinbtn\').click()">Sign in</button>`, this.toastrOptions);
        sessionStorage.removeItem(LogoutTypeParam);
        break;
      }
      case (LogoutTypes.Signoff): {
        this._toastr.successWithOptions(`<span role="alert">You have successfully signed out of your account.
        You must sign in again to continue working.</span><button tabindex="1" id="sessionSignoffToastBtn"
        class="btn-sm btn-default col-sm-offset-1" onclick="getElementById(\'signinbtn\').click()">Sign in</button>`, this.toastrOptions);
        sessionStorage.removeItem(LogoutTypeParam);
        break;
      }
      case (LogoutTypes.Signedout): {
        this._toastr.warningWithOptions(`<span role="alert">Your session is no longer valid.
        You must sign in again to continue working.</span><button tabindex="1" id="sessionSignoffToastBtn"
        class="btn-sm btn-default col-sm-offset-1" onclick="getElementById(\'signinbtn\').click()">Sign in</button>`, this.toastrOptions);
        sessionStorage.removeItem(LogoutTypeParam);
        break;
      }
    }
    if (document.getElementById('sessionSignoffToastBtn') !== null) {
      if (document.getElementsByClassName('toast-close-button').length > 0) {
        const toastClose = <HTMLElement>document.getElementsByClassName('toast-close-button')[0];
        toastClose.tabIndex = 2;
        toastClose.setAttribute('aria-label', 'Close');
      }
      document.getElementById('sessionSignoffToastBtn').focus();
    }
    this._helpService.getEnvironment().subscribe(env => window['env'] = env);
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }

  // These scripts are executed after a route change navigation
  // NOTE: these will be called after every route change so
  // consider performance before adding scripts here.
  executeRouteChangeScripts() {

  }

  onRouteChange() {
    this.executeRouteChangeScripts();
  }

  getAAAStates() {
    this._lookupService.getEnabledTitleIIIStates().subscribe(
      result => {
        this.enabledTitleIIIStates = result;
      },
      error => this.errorMessage = <any>error);
  }

  public skipThatNav(): void {
    document.getElementById('mainContent').focus();
  }

  // Keyboard events
  @HostListener('window:keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    // Ctrl + Shift + Alt + A -> Open keyboard shortcuts menu
    if ((e.keyCode === 65 && e.ctrlKey === true && e.shiftKey === true && e.altKey === true)) {
      this.showKeyboardShortcutsMenu = !this.showKeyboardShortcutsMenu;
    }
    // Ctrl + Shift + Alt + B -> Go to dashboard
    if ((e.keyCode === 66 && e.ctrlKey === true && e.shiftKey === true && e.altKey === true)) {
      this._router.navigate(['/dashboard']);
    }
    // 508, prevent focus being set on modal window itself, only focus on inputs within it
    // Tab
    if (e.keyCode === 9 && e.shiftKey !== true) {
      if (document.activeElement.innerHTML === 'Cancel') {
        if (document.activeElement.matches('.modal-content ' + document.activeElement.tagName)) {
          e.preventDefault();
          jQuery('.close').focus();
        }
      }
    }
    // Shift + Tab
    if (e.keyCode === 9 && e.shiftKey === true) {
      if (document.activeElement.classList.contains('close')) {
        if (document.activeElement.matches('.modal-content ' + document.activeElement.tagName)) {
          e.preventDefault();
          jQuery('a:contains(\'Cancel\')').focus();
        }
      }
    }
  }

  public login(): void {
    localStorage.removeItem('title');
    sessionStorage.removeItem('fiscalYear');
    sessionStorage.removeItem('sessionExpiredRedirect');
    this._oktaService.login();
  }

  public logout(): void {
    this._oktaService.logout();
  }

  public scrollToTop() {
    document.getElementById('mainContent').focus();
    document.documentElement.scrollTop = 0;
  }

  changePassNav() {
    window.open(environment.oktaConfig.url, '_blank');
  }


}
