import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class TwoColCharsSection extends Section {

    // totals
    totalPersonsServed: IDataElement;

    // age
    totalBelow60: IDataElement;
    bpBelow60: IDataElement;
    totalRange1: IDataElement;
    bpRange1: IDataElement;
    totalRange2: IDataElement;
    bpRange2: IDataElement;
    totalRange3: IDataElement;
    bpRange3: IDataElement;
    totalRange4: IDataElement;
    bpRange4: IDataElement;
    totalAgeMissing: IDataElement;
    bpAgeMissing: IDataElement;
    allAgeTotal: IDataElement;
    bpAgeTotal: IDataElement;

    // gender
    totalFemale: IDataElement;
    bpFemale: IDataElement;
    totalMale: IDataElement;
    bpMale: IDataElement;
    totalOther: IDataElement;
    bpOther: IDataElement;
    totalGenderMissing: IDataElement;
    bpGenderMissing: IDataElement;
    allGenderTotal: IDataElement;
    bpGenderTotal: IDataElement;

    // geographic
    totalRural: IDataElement;
    bpRural: IDataElement;
    totalNonRural: IDataElement;
    bpNonRural: IDataElement;
    totalGeoMissing: IDataElement;
    bpGeoMissing: IDataElement;
    allGeoTotal: IDataElement;
    bpGeoTotal: IDataElement;

    // poverty
    totalBelowPoverty: IDataElement;
    totalAbovePoverty: IDataElement;
    totalMissingPoverty: IDataElement;
    allPovertyTotal: IDataElement;

    // household status
    totalAlone: IDataElement;
    bpAlone: IDataElement;
    totalWithOthers: IDataElement;
    bpWithOthers: IDataElement;
    totalHouseholdMissing: IDataElement;
    bpHouseholdMissing: IDataElement;
    allHouseholdTotal: IDataElement;
    bpHouseholdTotal: IDataElement;

    // ethnicity
    totalHispanic: IDataElement;
    bpHispanic: IDataElement;
    totalNonHispanic: IDataElement;
    bpNonHispanic: IDataElement;
    totalEthnicityMissing: IDataElement;
    bpEthnicityMissing: IDataElement;
    allEthnicityTotal: IDataElement;
    bpEthnicityTotal: IDataElement;

    // race
    totalAmericanIndian: IDataElement;
    bpAmericanIndian: IDataElement;
    totalAsian: IDataElement;
    bpAsian: IDataElement;
    totalBlack: IDataElement;
    bpBlack: IDataElement;
    totalIslander: IDataElement;
    bpIslander: IDataElement;
    totalWhite: IDataElement;
    bpWhite: IDataElement;
    totalRaceOther: IDataElement;
    bpRaceOther: IDataElement;
    totalRaceMultiple: IDataElement;
    bpRaceMultiple: IDataElement;
    totalRaceMissing: IDataElement;
    bpRaceMissing: IDataElement;

    // minority
    totalMinority: IDataElement;
    bpMinority: IDataElement;
    totalNotMinority: IDataElement;
    bpNotMinority: IDataElement;
    totalMinorityMissing: IDataElement;
    bpMinorityMissing: IDataElement;
    allMinorityTotal: IDataElement;
    bpMinorityTotal: IDataElement;

    // nutrition
    totalLowNutrition: IDataElement;
    bpLowNutrition: IDataElement;
    totalHighNutrition: IDataElement;
    bpHighNutrition: IDataElement;
    totalNutritionMissing: IDataElement;
    bpNutritionMissing: IDataElement;
    allNutritionTotal: IDataElement;
    bpNutritionTotal: IDataElement;

    constructor() {
        super();

        // totals
        this.totalPersonsServed = new DataElement();

        // age
        this.totalBelow60 = new DataElement();
        this.bpBelow60 = new DataElement();
        this.totalRange1 = new DataElement();
        this.bpRange1 = new DataElement();
        this.totalRange2 = new DataElement();
        this.bpRange2 = new DataElement();
        this.totalRange3 = new DataElement();
        this.bpRange3 = new DataElement();
        this.totalRange4 = new DataElement();
        this.bpRange4 = new DataElement();
        this.totalAgeMissing = new DataElement();
        this.bpAgeMissing = new DataElement();
        this.allAgeTotal = new DataElement();
        this.bpAgeTotal = new DataElement();

        // gender
        this.totalFemale = new DataElement();
        this.bpFemale = new DataElement();
        this.totalMale = new DataElement();
        this.bpMale = new DataElement();
        this.totalOther = new DataElement();
        this.bpOther = new DataElement();
        this.totalGenderMissing = new DataElement();
        this.bpGenderMissing = new DataElement();
        this.allGenderTotal = new DataElement();
        this.bpGenderTotal = new DataElement();

        // geographic
        this.totalRural = new DataElement();
        this.bpRural = new DataElement();
        this.totalNonRural = new DataElement();
        this.bpNonRural = new DataElement();
        this.totalGeoMissing = new DataElement();
        this.bpGeoMissing = new DataElement();
        this.allGeoTotal = new DataElement();
        this.bpGeoTotal = new DataElement();

        // poverty status
        this.totalBelowPoverty = new DataElement();
        this.totalAbovePoverty = new DataElement();
        this.totalMissingPoverty = new DataElement();
        this.allPovertyTotal = new DataElement();

        // household
        this.totalAlone = new DataElement();
        this.bpAlone = new DataElement();
        this.totalWithOthers = new DataElement();
        this.bpWithOthers = new DataElement();
        this.totalHouseholdMissing = new DataElement();
        this.bpHouseholdMissing = new DataElement();
        this.allHouseholdTotal = new DataElement();
        this.bpHouseholdTotal = new DataElement();

        // ethnicity
        this.totalHispanic = new DataElement();
        this.bpHispanic = new DataElement();
        this.totalNonHispanic = new DataElement();
        this.bpNonHispanic = new DataElement();
        this.totalEthnicityMissing = new DataElement();
        this.bpEthnicityMissing = new DataElement();
        this.allEthnicityTotal = new DataElement();
        this.bpEthnicityTotal = new DataElement();

        // race
        this.totalAmericanIndian = new DataElement();
        this.bpAmericanIndian = new DataElement();
        this.totalAsian = new DataElement();
        this.bpAsian = new DataElement();
        this.totalBlack = new DataElement();
        this.bpBlack = new DataElement();
        this.totalIslander = new DataElement();
        this.bpIslander = new DataElement();
        this.totalWhite = new DataElement();
        this.bpWhite = new DataElement();
        this.totalRaceOther = new DataElement();
        this.bpRaceOther = new DataElement();
        this.totalRaceMultiple = new DataElement();
        this.bpRaceMultiple = new DataElement();
        this.totalRaceMissing = new DataElement();
        this.bpRaceMissing = new DataElement();

        // minority
        this.totalMinority = new DataElement();
        this.bpMinority = new DataElement();
        this.totalNotMinority = new DataElement();
        this.bpNotMinority = new DataElement();
        this.totalMinorityMissing = new DataElement();
        this.bpMinorityMissing = new DataElement();
        this.allMinorityTotal = new DataElement();
        this.bpMinorityTotal = new DataElement();

        // nutrition
        this.totalLowNutrition = new DataElement();
        this.bpLowNutrition = new DataElement();
        this.totalHighNutrition = new DataElement();
        this.bpHighNutrition = new DataElement();
        this.totalNutritionMissing = new DataElement();
        this.bpNutritionMissing = new DataElement();
        this.allNutritionTotal = new DataElement();
        this.bpNutritionTotal = new DataElement();
    }
}
