import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class ANStaffAndVolunteersSection extends Section {

    agingFullTime: IDataElement;
    agingPartTime: IDataElement;
    communityStaff: IDataElement;
    aaaFullTime: IDataElement;
    aaaPartTime: IDataElement;
    volunteers: IDataElement;
    volunteerHours: IDataElement;

    constructor() {
        super();

        this.agingFullTime = new DataElement();
        this.agingPartTime = new DataElement();
        this.communityStaff = new DataElement();
        this.aaaFullTime = new DataElement();
        this.aaaPartTime = new DataElement();
        this.volunteers = new DataElement();
        this.volunteerHours = new DataElement();

    }
}
