import { QuestionStatusEnum } from './shared/dataElement';

// Constant strings

// Generic error message
export const MsgErrorGeneric = 'We\'re sorry an error occurred.';

// User updated
export const MsgMgmtUserUpdated = 'User updated';

// App Constant Values

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';

// Special roles
export const RoleQA = 'SystemRole:QA';

// reCAPTCHA site key
export const reCaptchaSiteKey = '6LfLgdYUAAAAAFofgPQEX2zidOldld9Pev75PYcF';

// export const ProgramStartYear = 2016;
export const LegacyDataStartYear: number = 2009;
export const FiscalYear = () => { const now = new Date(); return new Date(now.setDate(now.getDate() + 92)).getFullYear(); };
export const LegacyYearRange: () => string[] = () => {
    const yearRange = [];
    const fiscalYear = FiscalYear();
    for (let i = LegacyDataStartYear; i <= fiscalYear; i++) {
        yearRange.push(i.toString());
    }
    return yearRange;
};

// Validation regex

// tslint:disable-next-line: max-line-length
export const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const ZipcodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
export const TwoDigitNumberRegex = /^\d{2}$/;
// tslint:disable-next-line: max-line-length
export const IsoDateTimeRegex = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/;
export const EST_GMT_OFFSET = 5;

// Enums
export enum AclTitleEnum {
    All,
    III,
    VI,
    VII
}

export enum UploadTypeEnum {
    NewData,
    Merge,
    Overwrite,
    Pending,
    Success,
    Failed
}

export enum OrgLevelEnum {
    SysAdmin, ACLCO, ACLRO, State, AAA, NoAdmin = 999
}

export enum NgErrorCodeEnum {
    TokenRenewFailed = 1000
}

export enum CommentTypeEnum {
    VIITempReviewComment,
    IIITempReviewCommentSpr,
    IIITempReviewCommentNsip,
    VITempReviewComment
}

export enum RollupTypes {
    None,
    StateRollup,
    ACLRollup
}

// Style

export const getFaCssForStatuses = () => {
    const statusCss = {};
    statusCss[QuestionStatusEnum.NotStarted] = 'fa fa-power-off';
    statusCss[QuestionStatusEnum.L1InProgressError] = 'fa fa-times';
    statusCss[QuestionStatusEnum.L2InProgressError] = 'fa fa-times';
    statusCss[QuestionStatusEnum.L1InProgress] = 'fa fa-cogs';
    statusCss[QuestionStatusEnum.L2ValidatedWarning] = 'fa fa-exclamation-triangle';
    statusCss[QuestionStatusEnum.L2Validated] = 'fa fa-check-circle-o';
    statusCss[QuestionStatusEnum.L3NoExplanation] = 'fa fa-exclamation-circle';
    statusCss[QuestionStatusEnum.L3Explanation] = 'fa fa-check-circle';
    statusCss[QuestionStatusEnum.NoSignificantVariance] = 'fa fa-check-circle';
    statusCss[QuestionStatusEnum.Returned] = 'fa fa-undo';
    statusCss[QuestionStatusEnum.SubmittedNoExplanation] = 'fa fa-upload';
    statusCss[QuestionStatusEnum.Submitted] = 'fa fa-upload';
    statusCss[QuestionStatusEnum.InReview] = 'fa fa-file-text-o';
    statusCss[QuestionStatusEnum.Approved] = 'fa fa-thumbs-up';
    statusCss[QuestionStatusEnum.Locked] = 'fa fa-lock';
    return statusCss;
};

export const getFaCssForStatus = (status) => {
    const css = getFaCssForStatuses();
    return css[status] || css[QuestionStatusEnum.NotStarted];
};


export class State {
    abbr: string;
    region: string;
    constructor(abbr: string, region: string) {
        this.abbr = abbr;
        this.region = region;
    }
}

export class Region {
    number: string;
    displayName: string;
    constructor(number: string, displayName: string) {
        this.number = number;
        this.displayName = displayName;
    }
}

export class StateSelection extends State {
    isSelected?: boolean;
}

export class Constants {
    public static REGIONS: Region[] = [
        new Region('1', 'Region 1'),
        new Region('2', 'Region 2'),
        new Region('3', 'Region 3'),
        new Region('4', 'Region 4'),
        new Region('5', 'Region 5'),
        new Region('6', 'Region 6'),
        new Region('7', 'Region 7'),
        new Region('8', 'Region 8'),
        new Region('9', 'Region 9'),
        new Region('10', 'Region 10')
    ];

    public static STATES: State[] = [
        new State('CT', '1'),
        new State('ME', '1'),
        new State('MA', '1'),
        new State('NH', '1'),
        new State('RI', '1'),
        new State('VT', '1'),
        new State('NJ', '2'),
        new State('NY', '2'),
        new State('PR', '2'),
        new State('VI', '2'),
        new State('DE', '3'),
        new State('DC', '3'),
        new State('MD', '3'),
        new State('PA', '3'),
        new State('VA', '3'),
        new State('WV', '3'),
        new State('AL', '4'),
        new State('FL', '4'),
        new State('GA', '4'),
        new State('KY', '4'),
        new State('MS', '4'),
        new State('NC', '4'),
        new State('SC', '4'),
        new State('TN', '4'),
        new State('IL', '5'),
        new State('IN', '5'),
        new State('MI', '5'),
        new State('MN', '5'),
        new State('OH', '5'),
        new State('WI', '5'),
        new State('AR', '6'),
        new State('LA', '6'),
        new State('NM', '6'),
        new State('OK', '6'),
        new State('TX', '6'),
        new State('IA', '7'),
        new State('KS', '7'),
        new State('MO', '7'),
        new State('NE', '7'),
        new State('CO', '8'),
        new State('MT', '8'),
        new State('ND', '8'),
        new State('SD', '8'),
        new State('UT', '8'),
        new State('WY', '8'),
        new State('AS', '9'),
        new State('AZ', '9'),
        new State('CA', '9'),
        new State('GU', '9'),
        new State('HI', '9'),
        new State('MP', '9'),
        new State('NV', '9'),
        new State('AK', '10'),
        new State('ID', '10'),
        new State('OR', '10'),
        new State('WA', '10')
    ];

    public static T7STATES: State[] = Constants.STATES.filter(state => !['VI', 'AS', 'GU', 'MP'].includes(state.abbr));

    public static CODES = Object.freeze({
        reportingNotPermitted: 100,
    });

    public static MSG = Object.freeze({
        defaultErrorMessage: 'We\'re sorry an error has occurred, please try again later'
    })

}

