import { Component, OnInit } from '@angular/core';


@Component({
    templateUrl: 'footer.component.html',
    selector: 'acl-footer'
})

export class FooterComponent implements OnInit {

    constructor() { }

    public ngOnInit(): void {

    }
}
