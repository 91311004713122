////////////////////////////////////
// Ng libraries
////////////////////////////////////
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { OktaAuth } from '@okta/okta-auth-js';


import { OAuthHandshakeModule } from './auth/login-callback/oauthCallback.module';
import { LoginModule } from './auth/login/login.module';

import { routing } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorLogger } from './shared/error-logger.service';
import { OktaService } from './auth/service/okta.service';
import { environment } from '../environments/environment';
import { ConfigService } from './auth/service/config.service';

// Begin app.module imports
////////////////////////////////////
// Ng libraries
////////////////////////////////////
import { Title } from '@angular/platform-browser';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // keep in app module or else :(
import { SharedModule } from './shared/shared.module';

import { TruncateModule } from 'ng2-truncate';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

////////////////////////////////////
// App components
////////////////////////////////////
// import { ApplicationErrorHandler } from './applicationErrorHandler';
import { AppComponent } from './app.component';
import { DashboardComponent } from './home/dashboard.component';
import { FooterComponent } from './shared/footer/footer.component';
import { WelcomeComponent } from './home/welcome.component';
import { ToastrService } from './shared/toastr.service';

// import { FileModule } from './files/file.module';
// import { ReviewModule } from './review/review.module';
// import { ReviewGuard } from './review/review-service.guard';
// import { ReviewDashboardGuard } from './shared/review-dashboard.guard';

////////////////////////////////////
// App services (injectables):
// Can be added to specific modules but registered here
// since services are globally avaiable regardles of location
////////////////////////////////////
import { ClaimService } from './shared/claim.service';
import { UserService } from './UserManagement/user.service';
import { LookupService } from './lookup.service';
import { ValidationService } from './shared/validation.service';
import { TitleVIIDataEntryService } from './StateSubmissions/titleVIIDataEntry.service';
import { TitleIIIDataEntryService } from './DataSubmissions/titleIIIDataEntry.service';
import { TitleVIDataEntryService } from './TitleVI/titleVIDataEntry.service';
import { UploadService } from './shared/Upload/upload.service';
import { AttachmentService } from './shared/Attachment/attachment.service';
import { CommonService } from './shared/common.service';
import { AppState } from './app.service';
import { CommentService } from './shared/Comment/comment.service';
import { AuthenticationGuard } from './auth/service/authenticated.guard';
import { SessionWarningComponent } from './auth/session/sessionWarning.component';
// import { AuthHttpModule } from './auth/http/authHttp.module';
import { LoadingService } from './shared/loading.service';

import { ReportService } from './reports/reports.service';
import { GranteeService } from './TitleVI/granteeManagement/grantee.service';
import { ValidationErrorsComponent } from './shared/Errors/validationErrors.component';
import { AboutComponent } from './home/about/about.component';
import { SystemUpdatesComponent } from './home/about/systemUpdates.component';
import { SideNavModule } from './navigation/side-nav.module';
import { CommonModule } from '@angular/common';
import { CacheService } from './shared/cache-service';
import { HorizontalNav } from './shared/Navigation/horizontalNav.component';


// import { OaapsUserManagementModule } from './UserManagement/oaaps-user-management.module';
// import { OaapsGranteeManagementModule } from './TitleVI/granteeManagement/oaaps-grantee-management.module';
// import { OaapsUserManagementSideNavComponent } from './UserManagement/sidenav/oaapsUserManagementSideNav.component';

// TitleVI Dashboard Component
import { TitleVIDashboardComponent } from './TitleVI/dashboard/tvidashboard.component';
import { HelpModule } from './help/help.module';
import { httpInterceptorProviders} from './interceptors';

// Good one when ready to refactor jwt authentication i.e. custom authorization interceptor in favor of @auth0/angular-jwt
// Custom interceptor is a stepping stone to creating additional interceptors e.g. http error handler based on status code.
// https://jasonwatmore.com/post/2018/11/16/angular-7-jwt-authentication-example-tutorial
export function jwtOptionsFactory(oktaService: OktaService) {
    return {
        tokenGetter: () => {
            return oktaService.getIdTokenAsync();
        }
    };
}

// End app.module imports


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        OAuthHandshakeModule,
        LoginModule,

        SharedModule,
        // FileModule,
        CommonModule,
        HttpClientModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [OktaService]
            }
        }),
        TruncateModule,
        SideNavModule,
        HelpModule,

        routing // Must be last due to wildcard routes
    ],
    declarations: [
        AppComponent,
        DashboardComponent,
        FooterComponent,
        WelcomeComponent,
        SessionWarningComponent,
        AboutComponent,
        SystemUpdatesComponent,
        ValidationErrorsComponent,
        TitleVIDashboardComponent,
        HorizontalNav
    ],

    providers: [
        {
            provide: OktaService,
            useValue: new OktaService(new OktaAuth(environment.oktaConfig))
        },
        httpInterceptorProviders,
        ConfigService,
        ErrorLogger,
        AuthenticationGuard,
        ClaimService,
        CommentService,
        UserService,
        LookupService,
        ValidationService,
        TitleVIIDataEntryService,
        TitleIIIDataEntryService,
        TitleVIDataEntryService,
        Title,
        UploadService,
        AttachmentService,
        CommonService,
        CacheService,
        AppState,
        ToastrService,
        ReportService,
        GranteeService,
        LoadingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }


