import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { TviDashboardModel } from './tvidashboard.model';
import { NewsModel } from '../../shared/NewsPanel/news.model';
import { UserManagementStrings } from '../../UserManagement/userManagerObjects';
import { CommonService } from '../../shared/common.service';
import { TVIRegionalContactDto } from '../model/IGrantee';
import { QuestionStatusEnum } from '../../shared/dataElement';
import { ClaimService } from '../../shared/claim.service';
import { ReportService } from '../../reports/reports.service';
import { ToastrService } from '../../shared/toastr.service';
import { YearStateRegion, YearStateRegionReport } from '../../reports/report.model';
import { LoadingComponent } from '../../shared/loading.component';
import { DestructibleComponent } from '../../destructible.component';
import { TitleVIDataEntryService } from '../../TitleVI/titleVIDataEntry.service';
import { SimpleChanges } from '@angular/core';
import { Observable, forkJoin, Subscription, interval, of, combineLatest } from 'rxjs';
import { DistributedTransaction, ITransactionCreatedResponse, TransactionStatus } from '../../../app/shared/Sync/distributedTransaction';
import { LoadingService } from '../../../app/shared/loading.service';
import { finalize, switchMap, takeWhile, catchError } from 'rxjs/operators';
import { Constants } from '../../app.constants';
declare var jQuery: any;

@Component({
    selector: 'acl-tvidashboard',
    templateUrl: './tvidashboard.component.html',
    styleUrls: ['tvidashboard.component.scss']
})

export class TitleVIDashboardComponent extends DestructibleComponent implements OnInit, OnChanges {
    userManagementStrings = UserManagementStrings;
    public statusEnum = QuestionStatusEnum;
    reportModel: YearStateRegionReport = new YearStateRegionReport([], 0, 0, 0);
    yearStateRegions: Array<YearStateRegion> = [];
    selectedRegion: string = null;
    @Input() dashboardModel: TviDashboardModel;
    @Input() regionalContact: TVIRegionalContactDto;
    @Output() regionChanged = new EventEmitter<string>();

    @ViewChild('loadingSpinner') loadingComponent: LoadingComponent;

    constructor(
        public _commonService: CommonService,
        private _claimService: ClaimService,
        private _reportService: ReportService,
        public toastr: ToastrService,
        public _titleVIservice: TitleVIDataEntryService,
        private _loadingService: LoadingService,
        ) { super(); }

    ngOnInit() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dashboardModel && !changes.dashboardModel.isFirstChange() && changes.dashboardModel.currentValue.myGranteeDefined &&
            this.selectedRegion === null) {
            jQuery('#regionDropdown').val('myGrantees');
        }
    }

    public get recentSystemUpdates(): NewsModel {
        return {
            listContentClass: 'fa-refresh',
            headerText: 'Recent System Updates',
            newsList: this.dashboardModel.systemUpdates,
            url: '/systemUpdates',
            moreLinkText: 'See all updates'
        };
    }
    public get reportingCalendar(): NewsModel {
        return {
            listContentClass: 'fa-calendar-check-o',
            headerText: 'Reporting Calendar',
            newsList: this.dashboardModel.reportingDates,
            url: '/Resources/reporting-calendar/reporting-calendar',
            moreLinkText: 'See reporting calendar'
        };
    }
    public get upcomingEvents(): NewsModel {
        return {
            listContentClass: 'fa-calendar',
            headerText: 'Upcoming Events',
            newsList: this.dashboardModel.upcomingEvents,
            url: '/Resources/events',
            moreLinkText: 'See all events'
        };
    }

    public regionChangedHandler(region: string) {
        this.selectedRegion = region;
        this.regionChanged.emit(region);
    }

    tviPprSubmissionStatusReport(): void {
        this.reportModel.userRole = 'T6ACL';
        this.reportModel.createdBy = this._claimService.userID;
        this.reportModel.yearStateRegionList = [];
        // tslint:disable-next-line:max-line-length
        this.dashboardModel.granteeIds.forEach(grantee => this.reportModel.yearStateRegionList.push(
            new YearStateRegion(this._claimService.currentYear, grantee, null, this.selectedRegion === 'all' ? 'national' : '', false)));
        this.pollForReportDownload(
            this._reportService.tviPprSubmissionStatusReport(
                this.reportModel), this.getFileName('PprSubmissionStatusReport_FFY', this.reportModel));
    }

    // Poll API until report is ready, then download it
    private pollForReportDownload(transactionResult: Observable<ITransactionCreatedResponse>, fileName: string): Subscription {
        this._loadingService.setManualLoading(true);
        return super.registerSubscription(
        transactionResult
        .pipe(
            switchMap(resp => combineLatest([of(resp.tid), interval(3000)])),
            takeWhile(([_, tick]) => tick < 100),
            switchMap(([tid]) => this._reportService.getTransactionDetails('api/titlevii/report/transaction', tid)),
            takeWhile(t => t.status === TransactionStatus.InProgress, true),
            finalize(() => this._loadingService.setManualLoading(false)),
            catchError(_ => of({ ...new DistributedTransaction(), status: TransactionStatus.Failed }))
        ).subscribe(t => {
            if (t.status === TransactionStatus.Completed) {
            this._reportService
                .downloadFile(t.id).subscribe(data => {
                const downloadUrl = URL.createObjectURL(data as any);
                const a = window.document.createElement('a');
                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
            } else if (t.status === TransactionStatus.NoContent) {
            jQuery('#reportData').modal('show');
            } else if (t.status !== TransactionStatus.InProgress) {
            console.log(t);
            this.toastr.error(Constants.MSG.defaultErrorMessage);
            }
        })
        );
    }

    private getFileName(fileName: string, model: YearStateRegionReport): string {
        const years = this._claimService.distinctArray(
            Array.prototype.map.call(model.yearStateRegionList, function (item) {
                return item.year;
            })
        );
        const yearStr = years.join('_');
        return fileName + yearStr + '.xlsx';
    }
}
