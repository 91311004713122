<loading #loadingSpinner></loading>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <acl-modal #confirmModal [headerText]="'Confirm Submission'" [id]="'submissionConfirmModal'" [buttons]="modalButtons">
    You have just completed the last of the required explanations of variances for SPR. If you chose to continue, your
    explanations of variances will be submitted to ACL for review.
    <br>
    <br>
    Once submitted, you will no longer be able to update the explanations of variances unless the complete submission is
    Pulled back for corrections. You can do so from the Overview page until ACL begins their review.
    <br>
    <br>
    If you are not ready to submit the explanations of variances, you can cancel and return to the Overview page.
  </acl-modal>
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto?.isRequired">
        <h2>Explanations of Variances for Older Adults<span class="print-only line">&nbsp;for&nbsp;{{_claimService.currentOrg}}</span></h2>
        <span class="block print-only">
          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
          <span class="sr-only">Variance threshold exceeded</span>
          = Variance threshold exceeded
        </span>
        <div class="row mb-1">
          <div class="col-md-12">
            <p class="pull-left pt-1">Enter explanations for each noted variance below. Once complete, mark each explanation as complete.</p>
            <button class="btn btn-default pull-right no-print" (click)='print()'>Print</button>
          </div>
        </div>
        <div class="row mb-1 no-print">
          <div class="text-right">
            <a class="btn btn-link" (click)="_commonService.expandAccordions()" (keyup.enter)="_commonService.expandAccordions()" tabindex="0">Expand all </a> |
            <a class="btn btn-link" (click)="_commonService.collapseAccordions()" (keyup.enter)="_commonService.collapseAccordions()" tabindex="0"> Collapse all</a>
          </div>
        </div>
        <div class="panel-group accordionGroup" id="OAAccordion" role="tablist" aria-multiselectable="true">
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="consumerOverviewHeading" data-toggle="collapse"
              data-target="#consumerOverviewBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#consumerOverviewBody"
                  aria-expanded="true" aria-controls="consumerOverviewBody">
                  <span class="ico"></span>
                  <b>Consumer Overview</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="consumerOverviewBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="consumerOverviewHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Unduplicated count of registered persons served (under Cluster 1 &
                        2)</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.registeredClients?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.registeredClients?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.registeredClients?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.registeredClients?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.registeredClients?.variance)}}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total estimated unduplicated count of persons served(registered
                        and unregistered)</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalClients?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalClients?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalClients?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalClients?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalClients?.variance)}}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Minority status - Minority</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalMinorityDistribution?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalMinorityDistribution?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalMinorityDistribution?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalMinorityDistribution?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalMinorityDistribution?.variance)}}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Rural status - Rural</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRural?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRural?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRural?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRural?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRural?.variance)}}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Below poverty</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalBelowPoverty?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalBelowPoverty?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalBelowPoverty?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalBelowPoverty?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalBelowPoverty?.variance)}}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">High nutrition risk</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalNutritionRiskDistribution?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalNutritionRiskDistribution?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalNutritionRiskDistribution?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalNutritionRiskDistribution?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalNutritionRiskDistribution?.variance)}}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">ADL score - 3+</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalADL3?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalADL3?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalADL3?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalADL3?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalADL3?.variance)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.varianceExplanation">
                  <label for="consumerOverviewTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.explanation"
                    rows="4" id="consumerOverviewTextbox1" (blur)="checkValid()"></textarea>
                  </div>
                  <label class="pull-left" for="consumerOverviewUserFinishedCheckbox">
                    <input (change)="unsavedChanges = true;" id="consumerOverviewUserFinishedCheckbox" type="checkbox"
                      [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation)"
                      [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.isUserFinished" />
                    &nbsp;Mark as complete
                  </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                  <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="personalCareHeading" data-toggle="collapse" data-target="#personalCareBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#personalCareBody"
                  aria-expanded="true" aria-controls="personalCareBody">
                  <span class="ico"></span>
                  <b>Personal Care</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.personalCare?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="personalCareBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="personalCareHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.personalCare?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.personalCare?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.personalCare.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.personalCare?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.personalCare?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.personalCare.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.personalCare?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.personalCare?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.personalCare.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.personalCare?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.personalCare?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.personalCare.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.personalCare?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.personalCare?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.personalCare.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.personalCare?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.personalCare?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.personalCare.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.personalCare?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.personalCare?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.personalCare.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.personalCare?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.personalCare?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.personalCare.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.personalCare.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.personalCare?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.personalCare?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation">
                  <label [for]="personalCareTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation.explanation"
                    rows="4" id="personalCareTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="personalCareUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="personalCareUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.personalCare?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="homemakerHeading" data-toggle="collapse" data-target="#homemakerBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#homemakerBody"
                  aria-expanded="true" aria-controls="homemakerBody">
                  <span class="ico"></span>
                  <b>Homemaker</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.homemaker?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="homemakerBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="homemakerHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.homemaker?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.homemaker?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homemaker.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.homemaker?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.homemaker?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homemaker.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homemaker?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homemaker?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homemaker.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homemaker?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homemaker?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homemaker.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homemaker?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homemaker?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homemaker.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homemaker?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homemaker?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homemaker.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homemaker?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homemaker?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homemaker.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homemaker?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homemaker?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homemaker.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homemaker.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homemaker?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.homemaker?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation">
                  <label [for]="homemakerTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation.explanation"
                    rows="4" id="homemakerTextbox1"(blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="homemakerUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="homemakerUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation.explanation?.length
                    || 0}}
                    of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.homemaker?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="choreHeading" data-toggle="collapse" data-target="#choreBody">
              <p class="panel-title">

                <a role="button" class="collapsed" data-toggle="collapse" href="#choreBody"
                  aria-expanded="true" aria-controls="choreBody">
                  <span class="ico"></span>
                  <b>Chore</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.chore?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="choreBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="choreHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.chore?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.chore?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.chore.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.chore.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.chore.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.chore?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.chore?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.chore.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.chore.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.chore.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.chore?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.chore?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.chore.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.chore.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.chore.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.chore?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.chore?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.chore.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.chore.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.chore.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.chore?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.chore?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.chore.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.chore.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.chore.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.chore?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.chore?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.chore.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.chore.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.chore.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.chore?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.chore?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.chore.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.chore.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.chore.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.chore?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.chore?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.chore.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.chore.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.chore.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.chore?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.chore?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.chore?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation">
                  <label [for]="choreTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation.explanation"
                    rows="4" id="choreTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="choreUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="choreUserFinishedCheckbox" type="checkbox" [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation.explanation?.length
                    || 0}} of
                    3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.chore?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="homeDeliveredMealsHeading" data-toggle="collapse"
              data-target="#homeDeliveredMealsBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#homeDeliveredMealsBody"
                  aria-expanded="true" aria-controls="homeDeliveredMealsBody">
                  <span class="ico"></span>
                  <b>Home Delivered Nutrition</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="homeDeliveredMealsBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="homeDeliveredMealsHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.homeDeliveredMeals?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation">
                  <label [for]="homeDeliveredMealsTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation.explanation"
                    rows="4" id="homeDeliveredMealsTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="homeDeliveredMealsUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="homeDeliveredMealsUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="adultDayCareHeading" data-toggle="collapse" data-target="#adultDayCareBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#adultDayCareBody"
                  aria-expanded="true" aria-controls="adultDayCareBody">
                  <span class="ico"></span>
                  <b>Adult Day Care/Health</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="adultDayCareBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="adultDayCareHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.adultDayCare?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation">
                  <label [for]="adultDayCareTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation.explanation"
                    rows="4" id="adultDayCareTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="adultDayCareUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="adultDayCareUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="caseManagementHeading" data-toggle="collapse"
              data-target="#caseManagementBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#caseManagementBody"
                  aria-expanded="true" aria-controls="caseManagementBody">
                  <span class="ico"></span>
                  <b>Case Management services</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="caseManagementBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="caseManagementHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation">
                  <label [for]="caseManagementTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation"
                    rows="4" id="caseManagementTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="caseManagementUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="caseManagementUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="assistedTransportationHeading" data-toggle="collapse"
              data-target="#assistedTransportationBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#assistedTransportationBody"
                  aria-expanded="true" aria-controls="assistedTransportationBody">
                  <span class="ico"></span>
                  <b>Assisted Transportation</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="assistedTransportationBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="assistedTransportationHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.assistedTransportation?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation">
                  <label [for]="assistedTransportationTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation.explanation"
                    rows="4" id="assistedTransportationTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="assistedTransportationUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="assistedTransportationUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="congregateMealsHeading" data-toggle="collapse"
              data-target="#congregateMealsBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#congregateMealsBody"
                  aria-expanded="true" aria-controls="congregateMealsBody">
                  <span class="ico"></span>
                  <strong>Congregate Nutrition</strong>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="congregateMealsBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="congregateMealsHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.congregateMeals?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation">
                  <label [for]="congregateMealsTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation.explanation"
                    rows="4" id="congregateMealsTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="congregateMealsUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="congregateMealsUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="nutritionCounselingHeading" data-toggle="collapse"
              data-target="#nutritionCounselingBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#nutritionCounselingBody"
                  aria-expanded="true" aria-controls="nutritionCounselingBody">
                  <span class="ico"></span>
                  <b>Nutrition Counseling</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="nutritionCounselingBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="nutritionCounselingHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionCounseling?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation">
                  <label [for]="nutritionCounselingTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation.explanation"
                    rows="4" id="nutritionCounselingTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="nutritionCounselingUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="nutritionCounselingUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="legalAssistanceHeading" data-toggle="collapse"
              data-target="#legalAssistanceBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#legalAssistanceBody"
                  aria-expanded="true" aria-controls="legalAssistanceBody">
                  <span class="ico"></span>
                  <b>Legal Assistance</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="legalAssistanceBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="legalAssistanceHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total Number of Open Cases</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.totalOpenCases?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.totalOpenCases?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.totalOpenCases?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.totalOpenCases?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.legalAssistance?.totalOpenCases?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation">
                  <label [for]="legalAssistanceTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation.explanation"
                    rows="4" id="legalAssistanceTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="legalAssistanceUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="legalAssistanceUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="transportationHeading" data-toggle="collapse"
              data-target="#transportationBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#transportationBody"
                  aria-expanded="true" aria-controls="transportationBody">
                  <span class="ico"></span>
                  <b>Transportation</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.transportation?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="transportationBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="transportationHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.transportation?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.transportation?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.transportation.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.transportation.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.transportation.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.transportation?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.transportation?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.transportation.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.transportation.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.transportation.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.transportation?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.transportation?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.transportation.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.transportation.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.transportation.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.transportation?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.transportation?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.transportation.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.transportation.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.transportation.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.transportation?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.transportation?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.transportation.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.transportation.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.transportation.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.transportation?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.transportation?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.transportation.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.transportation.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.transportation.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.transportation?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.transportation?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.transportation.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.transportation.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.transportation.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.transportation?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.transportation?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.transportation?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation">
                  <label [for]="transportationTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation.explanation"
                    rows="4" id="transportationTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="transportationUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="transportationUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.transportation?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="nutritionEducationHeading" data-toggle="collapse"
              data-target="#nutritionEducationBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#nutritionEducationBody"
                  aria-expanded="true" aria-controls="nutritionEducationBody">
                  <span class="ico"></span>
                  <b>Nutrition Education</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="nutritionEducationBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="nutritionEducationHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Audience size</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.estimatedAudienceSize?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.estimatedAudienceSize?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.estimatedAudienceSize?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.estimatedAudienceSize?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.estimatedAudienceSize?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.nutritionEducation?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation">
                  <label [for]="nutritionEducationTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation.explanation"
                    rows="4" id="nutritionEducationTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="nutritionEducationUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="nutritionEducationUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="informationAndAssistanceHeading" data-toggle="collapse"
              data-target="#informationAndAssistanceBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#informationAndAssistanceBody"
                  aria-expanded="true" aria-controls="informationAndAssistanceBody">
                  <span class="ico"></span>
                  <b>Information And Assistance</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="informationAndAssistanceBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="informationAndAssistanceHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15">% Difference from National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td>{{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto?.informationAndAssistance?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation">
                  <label [for]="informationAndAssistanceTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation.explanation"
                    rows="4" id="informationAndAssistanceTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="informationAndAssistanceUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="informationAndAssistanceUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="evidenceBasedHealthHeading" data-toggle="collapse"
              data-target="#evidenceBasedHealthBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#evidenceBasedHealthBody"
                  aria-expanded="true" aria-controls="evidenceBasedHealthBody">
                  <span class="ico"></span>
                  <b>Health Promotion: Evidence-Based</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="evidenceBasedHealthBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="evidenceBasedHealthHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">People served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.personsServed?.variance)}}
                      </td>
                    </tr>
                    <!-- <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.serviceUnits?.variance)}}
                      </td>
                    </tr> -->
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.totalExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.titleIIIExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.otherStateExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.otherNonStateExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.evidenceBasedHealth?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.programIncomeExpenditure?.variance)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation">
                  <label [for]="evidenceBasedHealthTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation.explanation"
                    rows="4" id="evidenceBasedHealthTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="evidenceBasedHealthUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="evidenceBasedHealthUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="nonEvidenceBasedHealthHeading" data-toggle="collapse"
              data-target="#nonEvidenceBasedHealthBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#nonEvidenceBasedHealthBody"
                  aria-expanded="true" aria-controls="nonEvidenceBasedHealthBody">
                  <span class="ico"></span>
                  <b>Health Promotion: Non- Evidence-Based</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="nonEvidenceBasedHealthBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="nonEvidenceBasedHealthHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">People served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.personsServed?.variance)}}
                      </td>
                    </tr>
                    <tr>
                      <!-- <tr>
                        <th scope="row" class="normal">Service units</th>
                        <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.serviceUnits?.elementValue)}}</td>
                        <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.serviceUnits?.elementValue)}}</td>
                        <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                          <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.serviceUnits?.isNotSigificantVariance">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                            <span class="sr-only">Variance threshold exceeded</span>
                          </span>
                          {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.serviceUnits?.variance)}}
                        </td>
                      </tr> -->
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.totalExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.titleIIIExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.otherStateExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.otherNonStateExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.nonEvidenceBasedHealth?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.programIncomeExpenditure?.variance)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation">
                  <label [for]="nonEvidenceBasedHealthTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation.explanation"
                    rows="4" id="nonEvidenceBasedHealthTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="nonEvidenceBasedHealthUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="nonEvidenceBasedHealthUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="otherServicesHeading" data-toggle="collapse"
              data-target="#otherServicesBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" href="#otherServicesBody"
                  aria-expanded="true" aria-controls="otherServicesBody">
                  <span class="ico"></span>
                  <b>Other Services</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyOAServiceAndExpenditureSummaryDto?.otherServices?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="otherServicesBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="otherServicesHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Field</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">People served</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.otherServices?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.otherServices?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.otherServices.personsServed?.variance)}}
                      </td>
                    </tr>
                    <!-- <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyOAServiceAndExpenditureSummaryDto?.otherServices?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyOAServiceAndExpenditureSummaryDto?.otherServices?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.otherServices.serviceUnits?.variance)}}
                      </td>
                    </tr> -->
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.otherServices?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.otherServices?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.otherServices.totalExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.otherServices?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.otherServices?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.otherServices.titleIIIExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: State expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.otherServices?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.otherServices?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.otherServices.otherStateExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other: Non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.otherServices?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.otherServices?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.otherServices.otherNonStateExpenditure?.variance)}}
                      </td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyOAServiceAndExpenditureSummaryDto?.otherServices?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyOAServiceAndExpenditureSummaryDto?.otherServices?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto.otherServices.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyOAServiceAndExpenditureSummaryDto.otherServices.programIncomeExpenditure?.variance)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation">
                  <label [for]="otherServicesTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation.explanation = currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation.isUserFinished || !currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation.explanation"
                    rows="4" id="otherServicesTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="otherServicesUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="otherServicesUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation)"
                        [(ngModel)]="currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                    <div *ngIf="currentFfyOAServiceAndExpenditureSummaryDto.otherServices?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row col-md-12 no-print">
          <button [disabled]="!isValid" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and
            next</button>
          <button [disabled]="!isValid" class="btn btn-default" (click)="save()">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to
            Overview</a>
        </div>
      </div>
      <div class="col-md-9" *ngIf="!currentFfyOAServiceAndExpenditureSummaryDto?.isRequired">
        <h2>Explanations of Variances for Older Adults</h2>
        <span>Older Adults Explanations are not required</span>
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
