import { Component, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';

import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { OAConsumerTpsSection } from './OAConsumerTpsSection';
import { CommonService } from '../shared/common.service';
import { DataElement } from '../shared/dataElement';
import { ValidationService } from '../shared/validation.service';
import { ToastrService } from '../shared/toastr.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { FourColNoNutritionCharsSection } from './FourColNoNutritionCharsSection';
import { TwoColCharsSection } from './TwoColCharsSection';
import { COAConsumerSummarySection } from './COAConsumerSummarySection';
import { ORCConsumerSummarySection } from './ORCConsumerSummarySection';
import { TitleIIISectionComponent } from './title-iii-section.component';

@Component({
  templateUrl: 'titleIIIOAConsumerTps.component.html',
})

export class TitleIIIOAConsumerTpsComponent extends TitleIIISectionComponent<OAConsumerTpsSection> implements OnInit, OnDestroy {

  _sectionModel: OAConsumerTpsSection;
  _uploadModels: Array<OAConsumerTpsSection>;
  _batchIds: Array<string>;
  pageName: string;
  unsavedChanges: boolean = false;
  questionStatusEnum = QuestionStatusEnum;
  oaConsumerOneSectionModel: FourColNoNutritionCharsSection;
  oaConsumerBothSectionModel: TwoColCharsSection;
  coaConsumerSummarySectionModel: COAConsumerSummarySection;
  orcConsumerSummarySectionModel: ORCConsumerSummarySection;
  goToNextSection: boolean = false;

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _commonService: CommonService,
    public _validation: ValidationService,
    public toastr: ToastrService
  ) {
    super(new OAConsumerTpsSection(), _commonService, _validation, router, _claimService, _service);
   }

  ngOnInit(): void {
    this._sectionModel = new OAConsumerTpsSection();
    this.setSectionName();
    switch (this._sectionModel.sectionName) {
      case 'OAConsumerTps': {
        this.pageName = 'Older Adults';
        break;
      }
      case 'COAConsumerTps': {
        this.pageName = 'Caregiver of Older Adults';
        break;
      }
      case 'ORCConsumerTps': {
        this.pageName = 'Older Relative Caregivers';
        break;
      }
      default: {
        this.pageName = 'Error';
        break;
      }
    }
    this._titleService.setTitle(this.pageName + ' - Consumer Summary - Total Persons Served');
    this.getoaconsumertpsbyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    this.setSectionName();
    this._service.saveoaconsumertps(this._sectionModel).subscribe(data => this.zone.run(() => {
      this._sectionModel = data;
      this._claimService.tiiiStatuses.OAConsumerTps = data.sectionStatus;
      this.saveConsumer();
    }));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    this.goToNextSection = true;
    if (!this._claimService.isViewOnly && 
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save();
    } else {
      /*
      Adding the deleted else{} back below, page should not go directly to the next section until the save completes, because
      the next section needs the data from this section. Going directly there causes the next section to show outdated data.
      saveOAConsumer(), saveCOAConsumer(), and saveORCConsumer() are handling the redirect after the save completes. This else{}
      is just for when Save and Next button is only a Next button and saves are disabled.
      */
      switch (this._sectionModel.sectionName) {
        case 'OAConsumerTps': {
          this.router.navigate(['/data-submissions/OAConsumerBoth']);
          break;
        }
        case 'COAConsumerTps': {
          this.router.navigate(['/data-submissions/titleIIICOAConsumerSummary']);
          break;
        }
        case 'ORCConsumerTps': {
          this.router.navigate(['/data-submissions/titleIIIORCConsumerSummary']);
          break;
        }
        default: {
          this.router.navigate(['/data-submissions/titleIIIOverview']);
          break;
        }
      }
    }
  }

  getoaconsumertpsbyffyandstate(): void {
    this._service.get<OAConsumerTpsSection[]>(this._claimService,
      this._service._getoaconsumertpsbyffyandstate, this.resolveSectionName())
      .subscribe(data => {
        if (data.length > 0) {
          const sectionData = data.find(item => this.resolveSectionName().includes(item.sectionName));
          if (sectionData) {
            this._sectionModel = sectionData;
          } else {
            // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
            const tempSectionModel: OAConsumerTpsSection = new OAConsumerTpsSection();
            this._sectionModel = tempSectionModel;
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
        }

      });
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.bothClusterCount.elementValue = this.addComma(this._sectionModel.bothClusterCount.elementValue);
    this._sectionModel.cluster1Count.elementValue = this.addComma(this._sectionModel.cluster1Count.elementValue);
    this._sectionModel.unregisteredCount.elementValue = this.addComma(this._sectionModel.unregisteredCount.elementValue);
    this._sectionModel.totalCount.elementValue = this.addComma(this._sectionModel.totalCount.elementValue);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this._sectionModel.sectionName === 'OAConsumerTps') {
      if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
        this._commonService.oaLockedNav = false;

        return true;
      } else {
        this._commonService.oaLockedNav = true;
        return false;
      }
    } else if (this._sectionModel.sectionName === 'COAConsumerTps') {
      if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
        this._commonService.coaLockedNav = false;

        return true;
      } else {
        this._commonService.coaLockedNav = true;
        return false;
      }
    } else if (this._sectionModel.sectionName === 'ORCConsumerTps') {
      if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
        this._commonService.orcLockedNav = false;

        return true;
      } else {
        this._commonService.orcLockedNav = true;
        return false;
      }
    }

  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      const msg = `You have unsaved changes. Press OK to proceed without saving these changes,
        or press Cancel to go back and save these changes.`;
      $event.returnValue = msg;
    }
  }

  saveConsumer(): void {
    switch (this._sectionModel.sectionName) {
      case 'OAConsumerTps': {
        this.getAndSaveOAConsumer();
        break;
      }
      case 'COAConsumerTps': {
        this.getAndSaveCOAConsumer();
        break;
      }
      case 'ORCConsumerTps': {
        this.getAndSaveORCConsumer();
        break;
      }
    }
  }

  getAndSaveOAConsumer(): void {
    /* ACLTITLES-1244 - Need to get the OAConsumerOne object here so whatever the user enters
    for "Unduplicated count of registered persons served under Cluster 1 & 2" and "Unduplicated
    count of registered persons served under Cluster 1" can also be applied to and saved out
    for OAConsumerOne and OAConsumerBoth which has those felds as readonly now and pulling
    their values from whatever gets entered here. Same done for COA and ORC Consumer Summary
    sections. See C# data model comments marked "ACLTITLES-1244" for more info. */
    const oaConsumerOneUrl = this._service._getfourcolnonutritioncharsbyffyandstate;
    const oaConsumerBothUrl = this._service._gettwocolcharsbyffyandstate;
    forkJoin(
      this._service.get<FourColNoNutritionCharsSection[]>(this._claimService, oaConsumerOneUrl, 'OAConsumerOne'),
      this._service.get<TwoColCharsSection[]>(this._claimService, oaConsumerBothUrl, 'OAConsumerBoth')
    ).subscribe(data => {
      if (data[0].length > 0) {
        this.oaConsumerOneSectionModel = data[0][0];
      } else {
        this.oaConsumerOneSectionModel = new FourColNoNutritionCharsSection();
      }
      if (data[1].length > 0) {
        this.oaConsumerBothSectionModel = data[1][0];
      } else {
        this.oaConsumerBothSectionModel = new TwoColCharsSection();
      }
      this.saveOAConsumer();
    }, err => console.log(err));
  }

  getAndSaveCOAConsumer(): void {
    const url = this._service._getcoaconsumersummarybyffyandstate;
    this._service.get<COAConsumerSummarySection[]>(this._claimService, url, 'titleIIICOAConsumerSummary')
      .subscribe(data => {
        let tempSectionModel: COAConsumerSummarySection = new COAConsumerSummarySection();
        tempSectionModel = data.filter(element => element.batchId === '00000000-0000-0000-0000-000000000000')[0];
        if (tempSectionModel) {
          this.coaConsumerSummarySectionModel = tempSectionModel;
        } else {
          this.coaConsumerSummarySectionModel = new COAConsumerSummarySection();
        }
        this.saveCOAConsumer();
      });
  }

  getAndSaveORCConsumer(): void {
    const url = this._service._getorcconsumersummarybyffyandstate;
    this._service.get<ORCConsumerSummarySection[]>(this._claimService, url, 'titleIIIORCConsumerSummary')
      .subscribe(data => {
        let tempSectionModel: ORCConsumerSummarySection = new ORCConsumerSummarySection();
        tempSectionModel = data.filter(element => element.batchId === '00000000-0000-0000-0000-000000000000')[0];
        if (tempSectionModel) {
          this.orcConsumerSummarySectionModel = tempSectionModel;
        } else {
          this.orcConsumerSummarySectionModel = new ORCConsumerSummarySection();
        }
        this.saveORCConsumer();
      });
  }

  saveOAConsumer(): void {
    this.oaConsumerOneSectionModel.state = this._claimService.currentOrg;
    this.oaConsumerOneSectionModel.fiscalYear = this._claimService.currentYear;
    this.oaConsumerOneSectionModel.sectionName = 'OAConsumerOne';
    this.oaConsumerOneSectionModel.totalPersonsServed.elementValue = this._sectionModel.cluster1Count.elementValue;
    this._service.savefourcolnonutritionchars(this.oaConsumerOneSectionModel).subscribe(() => {
    });
    this.oaConsumerBothSectionModel.state = this._claimService.currentOrg;
    this.oaConsumerBothSectionModel.fiscalYear = this._claimService.currentYear;
    this.oaConsumerBothSectionModel.sectionName = 'OAConsumerBoth';
    this.oaConsumerBothSectionModel.totalPersonsServed.elementValue = this._sectionModel.bothClusterCount.elementValue;
    this._service.savetwocolchars(this.oaConsumerBothSectionModel).subscribe(data => {

      this.toastr.success('Saved!');
      if (this.goToNextSection) {
        this.router.navigate(['/data-submissions/OAConsumerBoth']);
      }
    });
  }

  saveCOAConsumer(): void {
    this.coaConsumerSummarySectionModel.state = this._claimService.currentOrg;
    this.coaConsumerSummarySectionModel.fiscalYear = this._claimService.currentYear;
    this.coaConsumerSummarySectionModel.sectionName = 'titleIIICOAConsumerSummary';
    this.coaConsumerSummarySectionModel.totalPersonsServed.elementValue =
      this._sectionModel.bothClusterCount.elementValue;
    this._service.savecoaconsumersummary(this.coaConsumerSummarySectionModel).subscribe(data => {
      this.coaConsumerSummarySectionModel = data;

      this.toastr.success('Saved!');
      if (this.goToNextSection) {
        this.router.navigate(['/data-submissions/titleIIICOAConsumerSummary']);
      }
    });
  }

  saveORCConsumer(): void {
    this.orcConsumerSummarySectionModel.state = this._claimService.currentOrg;
    this.orcConsumerSummarySectionModel.fiscalYear = this._claimService.currentYear;
    this.orcConsumerSummarySectionModel.sectionName = 'titleIIIORCConsumerSummary';
    this.orcConsumerSummarySectionModel.totalPersonsServed.elementValue =
      this._sectionModel.bothClusterCount.elementValue;
    this._service.saveorcconsumersummary(this.orcConsumerSummarySectionModel).subscribe(data => {
      this.orcConsumerSummarySectionModel = data;

      this.toastr.success('Saved!');
      if (this.goToNextSection) {
        this.router.navigate(['/data-submissions/titleIIIORCConsumerSummary']);
      }
    });
  }

}
