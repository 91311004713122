import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class HomeDeliveredMealsUnitsSection extends Section {

    totalServiceUnits: IDataElement;
    expendC: IDataElement;
    expendA: IDataElement;
    expendOtherState: IDataElement;
    expendOtherNonState: IDataElement;
    programIncome: IDataElement;
    expendTotal: IDataElement;
    totalExpendPerUnit: IDataElement;

    constructor() {
        super();

        this.totalServiceUnits = new DataElement();
        this.expendC = new DataElement();
        this.expendA = new DataElement();
        this.expendOtherState = new DataElement();
        this.expendOtherNonState = new DataElement();
        this.programIncome = new DataElement();
        this.expendTotal = new DataElement();
        this.totalExpendPerUnit = new DataElement();

    }
}
