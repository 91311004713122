<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <loading #loadingSpinner></loading>
  <div class="row">
    <div *ngIf="hasProfile" class="col-md-12 acl-container accordionGroup">
      <!-- return comments modal -->
      <div id="returnCommentsModal" class="modal fade">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 id="return-comments-header" class="modal-title">Return comments</h2>
            </div>
            <div *ngIf="isSPRReturnComments" class="modal-body">
              <p>Returned on: {{sprReturnCommentModel.created | amLocale:'en' | amDateFormat:'MM/DD/YYYY [at] h:mma'}}
              </p>
              <p class="mb-2">Returned by: {{sprReturnCommentModel.createdBy}}</p>
              <p class="background-gray-lighter p-1">{{sprReturnCommentModel.comments}}</p>
            </div>
            <div *ngIf="!isSPRReturnComments" class="modal-body">
              <p>Returned on: {{nsipReturnCommentModel.created | amLocale:'en' | amDateFormat:'MM/DD/YYYY [at] h:mma'}}
              </p>
              <p class="mb-2">Returned by: {{nsipReturnCommentModel.createdBy}}</p>
              <p class="background-gray-lighter p-1">{{nsipReturnCommentModel.comments}}</p>
            </div>
            <div class="modal-footer">
              <button id="hideReturnCommentsBtn" (click)="hideReturnedComments()" type="button"
                class="btn btn-secondary btn-small" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <!-- end return comments modal -->
      <!-- export data modal -->
      <div id="exportDataModal" class="modal fade">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 id="export-data-header" class="modal-title">Export Data</h2>
            </div>
            <div class="modal-body">
              <p>Select an export data format</p>
              <button id="exportBtn" class="btn btn-export" (click)="export()" (keyup.enter)='export()'>
                <i class="fa fa-file-excel-o" aria-hidden="true"></i> Custom Format
              </button>
              <button id="exportUploadBtn" class="btn btn-export ml-2" (click)="exportUploadData()" (keyup.enter)='exportUploadData()'>
                <i class="fa fa-file-excel-o" aria-hidden="true"></i> Upload Template Format
              </button>
            </div>
            <div class="modal-footer">
              <button id="hideExportDataBtn" (click)="hideExportDataModal()" type="button"
                class="btn btn-secondary btn-small" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <!-- end export data modal -->

      <sideNav *ngIf="hasProfile"></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <div class="row mt-1">
          <div class="col-md-8">
            <h2 id="overview-header">Overview</h2>
          </div>
          <!-- export -->
          <div class="col-md-4">
            <div class="mb-1">
              <p class="pull-right">
                <button id="exportBtn" class="btn btn-export" (click)="showExportDataModal()" (keyup.enter)='showExportDataModal()'>
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Export all data
                </button>
              </p>
            </div>
          </div>
          <!-- .end export -->
        </div>
        <div class="row">
          <div [ngClass]="_claimService.isRollupOverview === true ? 'col-md-6': 'hidden'">
            <p [ngClass]="rollupSource.sources.length == 0 ? '' : 'hidden'" class="pt-1">Rollup type: <span data-toggle="tooltip" tabindex="0" data-placement="top"
              title="A rollup with one or more datapoints generated outside the system and therefore does not have an audit trail tracing back to the AAA(s) or State datapoint.">Untraceable</span>
            </p>
            <p [ngClass]="rollupSource.sources.length > 0 ? '' : 'hidden'" class="pt-0">Rollup type:
              <button type="button" class="btn btn-link" data-toggle="modal" data-target="#traceableRollupModal">
                Traceable
              </button>
            </p>
          </div>
          <div [ngClass]="_claimService.isRollupOverview === true ? 'col-md-6': 'col-md-12'">
            <div class="text-right">
              <a class="btn btn-link" (click)="_commonService.expandAccordions()"
                (keyup.enter)='_commonService.expandAccordions()' tabindex="0">Expand all </a>|
              <a class="btn btn-link" (click)="_commonService.collapseAccordions()"
                (keyup.enter)='_commonService.collapseAccordions()' tabindex="0"> Collapse all</a>
            </div>
          </div>
        </div>

    <!-- acl edit alert -->
    <div *ngIf="showAAAStateEditAlert" class="alert icon" [ngClass]="state.aaaState.editAlertStyle" role="alert">
      <div class="flex flex-align-center">
        <div class="flex-item-wrap">
          <i [ngClass]="getFaCssForStatus(_commonService.overallStatus(overviewModel.sprStatus))"
            aria-hidden="true"></i>
        </div>
        <div class="flex-item-wrap flex-1-2">{{state.aaaState.editAlertText}}</div>
        <div class="flex-item-wrap pr-1 pl-1">
          <a tabindex="0" (click)="aclEditAlertAction()" (keydown.enter)="aclEditAlertAction()"
            class='btn-sm btn-action text-center block pull-right' id="aaaAlertEditBtn">
            {{state.aaaState.editButtonText}}
          </a>
        </div>
      </div>
    </div>
    <!-- .end acl edit alert -->
    <!-- acl edit confirmation -->
    <div aria-labelledby="aclEditModalLabel" id="aaaStateEditModal" class="modal fade" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
            <strong class="modal-title">{{state.aaaState.editModalTitleText}}</strong>
          </div>
          <div class="modal-body">
            <p>{{state.aaaState.editModalBodyText}}</p>
          </div>
          <div class="modal-footer">
            <button (keydown.enter)="enableAAAStateEdit()" (click)="enableAAAStateEdit()" type="button"
              class="btn btn-primary btn-small" data-dismiss="modal"
              id="btnEnableAclEdit" tabindex="0">{{state.aaaState.editModalPositiveBtnText}}</button>
            <button type="button" id="btnDismissAaaStateEditModal" class="btn btn-secondary btn-small"
              data-dismiss="modal">{{state.aaaState.editModalNegativeBtnText}}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- .end acl edit confirmation -->

    <div aria-labelledby="traceableRollupModal" id="traceableRollupModal" class="modal fade" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
            <strong class="modal-title">Traceable Rollup</strong>
          </div>
          <div class="modal-body">
            <p>The following State and/or AAA(s) records were used to generate the rollup record.</p>
            <p>State:</p>
            <ul>
              <li *ngFor="let item of rollupState">{{item}}</li>
            </ul>
            <p>AAA(s):</p>
            <ul>
              <li *ngFor="let item of rollupAaa">{{item}}</li>
            </ul>
          </div>
          <div class="modal-footer">
            <button (keydown.enter)="enableAAAStateEdit()" (click)="enableAAAStateEdit()" type="button"
              class="btn btn-primary btn-small" data-dismiss="modal"
              id="btnEnableAclEdit" tabindex="0">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- SPR accordion -->
        <div class="panel-group" id="sprAccordion" role="tablist" aria-multiselectable="true">
          <!-- SPR panel -->
          <div class="panel panel-default border-none">
            <!-- SPR heading -->
            <div class="panel-heading panel-heading-primary collapsed" role="tab" id="sprHeading" data-toggle="collapse"
              data-target="#sprBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" data-parent="#sprAccordion" href="#sprBody"
                  aria-expanded="true" aria-controls="sprBody">
                  <span class="ico"></span>
                  <b> SPR Data</b>
                </a>
                <span class="ml-1">
                  Overall reporting status:
                  <span id="sprOverallStatus" class="ml-1"
                    [innerHTML]="_commonService.overallStatusHtmlWhite(overviewModel.sprStatus, overviewModel.sprIsRequired)"></span>
                </span>
              </p>
            </div>
            <!-- .end SPR heading -->
            <div
              *ngIf="!_claimService.isAAAUser && overviewModel.sprStatus < statusEnum.SubmittedNoExplanation && sprDaysRemaining >= 0"
              class="alert alert-info clock" role="alert">
              <p>
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                <strong>{{sprDaysRemaining}}</strong>
                day(s) left until SPR submission due date: {{sprDueDate | amDateFormat:'L'}}
              </p>
            </div>
            <div
              *ngIf="!_claimService.isAAAUser && overviewModel.sprStatus < statusEnum.SubmittedNoExplanation && sprDaysRemaining < 0"
              class="alert alert-danger" role="alert">
              <p>
                <i class="fa fa-2x fa-exclamation-triangle pull-left" aria-hidden="true"></i>
                <strong>{{0 - sprDaysRemaining}}</strong>
                day(s) past SPR submission due date: {{sprDueDate | amDateFormat:'L'}}
              </p>
            </div>
            <!-- end spr alert -->
            <!-- spr return alert -->
            <div *ngIf="showCheckForSPRAction('RETURNED', 'SPR')" class="alert alert-danger mt-1 mb-0" role="alert">
              <a class="pull-right btn-sm btn-danger" (click)="isSPRReturnComments = true; showReturnedComments()">
                View return comments
              </a>
              <p class="inline-block">Your data has been returned for rework.</p>
              <p class="inline-block">Please make the necessary corrections and resubmit for review and approval.</p>
            </div>
            <!-- end spr return alert -->

            <div
              *ngIf="ANSection.sectionStatus == statusEnum.L2InProgressError || OASection.sectionStatus == statusEnum.L2InProgressError || COASection.sectionStatus == statusEnum.L2InProgressError || ORCSection.sectionStatus == statusEnum.L2InProgressError || ExpendituresSection.sectionStatus == statusEnum.L2InProgressError ||
                 ANSection.sectionStatus == statusEnum.L2ValidatedWarning || OASection.sectionStatus == statusEnum.L2ValidatedWarning || COASection.sectionStatus == statusEnum.L2ValidatedWarning || ORCSection.sectionStatus == statusEnum.L2ValidatedWarning || ExpendituresSection.sectionStatus == statusEnum.L2ValidatedWarning"
              class="row LeftRightMargin0px">
              <div class="alert alert-danger col-md-12" role="alert" style="margin-bottom: 0px;">
                <i class="fa fa-2x fa-exclamation-triangle pull-left" aria-hidden="true">
                  <span class="sr-only">Error:</span>
                </i>
                &nbsp;Validation warnings and/or errors were found in one or more sections.
                <a class='btn-sm btn-danger text-center pull-right' [routerLink]="['/errors', 'T3']">
                  View all validation issues
                </a>
              </div>
            </div>

            <!-- SPR accordion body -->
            <div id="sprBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="sprHeading">
              <!-- body content -->
              <div *ngIf="viewConstraints.isSprViewable" class="panel-body panel-body-plain">
                <!-- retract submission -->
                <div *ngIf="showPullBackSpr()" class="background-gray-lighter p-1 mb-1">
                  <p>
                    <button id="pullBackSPRBtn" class="pull-right btn btn-export"
                      (click)="pullBackForCorrectionsClicked('SPR')"
                      (keyup.enter)="pullBackForCorrectionsClicked('SPR')">
                      Pull back for corrections
                    </button>
                    <ng-container *ngIf="!_commonService.isNullOrEmpty(this._claimService.currentSubOrg)">
                      Your SPR data has been submitted to your state for review.
                      You may pull back your SPR submission for corrections until your state begins their review.
                    </ng-container>
                    <ng-container *ngIf="_commonService.isNullOrEmpty(this._claimService.currentSubOrg)">
                    Your SPR data has been submitted to {{orgText}} for review.
                    You may pull back your SPR submission for corrections until {{orgText}} begins their review.
                    </ng-container>
                  </p>
                </div>
                <!-- .end retract submission -->
                <div *ngIf="overviewModel.sprStatus == statusEnum.L3NoExplanation" class="row LeftRightMargin0px">
                  <div class="alert alert-danger col-md-12 mt-1" role="alert">
                    <i class="fa fa-2x fa-exclamation-triangle pull-left" aria-hidden="true">
                      <span class="sr-only">Error:</span>
                    </i>
                    &nbsp;Variance explanations are required for one or more sections.
                  </div>
                </div>
                <!-- SPR table responsive -->
                <div class="col-md-12 table-responsive">
                  <table class="table mb-4">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col">Section</th>
                        <th scope="col">Data Entry Status</th>
                        <th scope="col">Last Updated</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">
                        <!-- <a [routerLink]="['../titleIIIANNetworkFunding']">Aging Network Profile</a> -->
                        <a (click)="displayAgingNetwork()" (keypress)="displayAgingNetwork()">Aging Network Profile</a>
                      </th>
                      <td id="anStatus" [ngClass]="_commonService.setColor(ANSection.sectionStatus)"
                        [innerHTML]="_commonService.statusReader(ANSection.sectionStatus, ANSection.isRequired)"></td>
                      <td>
                        <span
                          [innerHTML]="_commonService.updatedByReader(ANSection.lastModified, ANSection.updatedBy, ANSection.isRequired)"></span>
                      </td>
                      <td class="text-nowrap">
                        <div *ngIf="ANSection.isRequired">
                          <acl-overview-buttons [options]="buildButtonOptions(overviewConstants.AN)" [state]="state"
                          (validateAction)="validate($event)"></acl-overview-buttons>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">
                        <a (click)="displayOlderAdults()" (keypress)="displayOlderAdults()">Older Adults</a>
                      </th>
                      <td id="oaStatus" [ngClass]="_commonService.setColor(OASection.sectionStatus)"
                        [innerHTML]="_commonService.statusReader(OASection.sectionStatus, OASection.isRequired)"></td>
                      <td>
                        <span
                          [innerHTML]="_commonService.updatedByReader(OASection.lastModified, OASection.updatedBy, OASection.isRequired)"></span>
                      </td>
                      <td class="text-nowrap">
                        <div *ngIf="OASection.isRequired">
                          <acl-overview-buttons [options]="buildButtonOptions(overviewConstants.OA)" [state]="state"
                            (validateAction)="validate($event)"></acl-overview-buttons>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">
                        <a (click)="displayCOA()" (keypress)="displayCOA()">Caregivers of Older Adults</a>
                      </th>
                      <td id="coaStatus" [ngClass]="_commonService.setColor(COASection.sectionStatus)"
                        [innerHTML]="_commonService.statusReader(COASection.sectionStatus, COASection.isRequired)"></td>
                      <td>
                        <span
                          [innerHTML]="_commonService.updatedByReader(COASection.lastModified, COASection.updatedBy, COASection.isRequired)"></span>
                      </td>
                      <td class="text-nowrap">
                        <div *ngIf="COASection.isRequired">
                          <acl-overview-buttons [options]="buildButtonOptions(overviewConstants.COA)" [state]="state"
                            (validateAction)="validate($event)"></acl-overview-buttons>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">
                        <a (click)="displayORC()" (keypress)="displayORC()">Older Relative Caregivers</a>
                      </th>
                      <td id="orcStatus" [ngClass]="_commonService.setColor(ORCSection.sectionStatus)"
                        [innerHTML]="_commonService.statusReader(ORCSection.sectionStatus, ORCSection.isRequired)"></td>
                      <td>
                        <span
                          [innerHTML]="_commonService.updatedByReader(ORCSection.lastModified, ORCSection.updatedBy, ORCSection.isRequired)"></span>
                      </td>
                      <td class="text-nowrap">
                        <div *ngIf="ORCSection.isRequired">
                          <acl-overview-buttons [options]="buildButtonOptions(overviewConstants.ORC)" [state]="state"
                            (validateAction)="validate($event)"></acl-overview-buttons>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">
                        <a (click)="displayExpenditures()" (keypress)="displayExpenditures()">Expenditures Overview</a>
                      </th>
                      <td id="eStatus" [ngClass]="_commonService.setColor(ExpendituresSection.sectionStatus)"
                        [innerHTML]="_commonService.statusReader(ExpendituresSection.sectionStatus, ExpendituresSection.isRequired)">
                      </td>
                      <td>
                        <span
                          [innerHTML]="_commonService.updatedByReader(ExpendituresSection.lastModified, ExpendituresSection.updatedBy, ExpendituresSection.isRequired)"></span>
                      </td>
                      <td class="text-nowrap">
                        <div *ngIf="ExpendituresSection.isRequired">
                          <acl-overview-buttons [options]="buildButtonOptions(overviewConstants.Expenditures)" [state]="state"
                            (validateAction)="validate($event)"></acl-overview-buttons>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <!-- .end SPR table responsive-->
              </div>
              <!-- .end body content -->
              <!-- SPR data is not viewable (either by ACL or AAA state user based on status) -->
              <div *ngIf="!viewConstraints.isSprViewable" class="panel-body p-1">
                <strong>SPR data has not been submitted.</strong>
              </div>
              <!-- wire conditions -->
              <!-- SPR flex -->
              <!-- These conditions are so specific because of the very unusual submission flow functionality chosen -->
              <div *ngIf="isResponsibleSpr && shouldSeeActionsBasedOnViewAndRole" class="flex flex-center">
                <div [ngClass]="getButtonCSS(buttonActions.VALIDATE, submissionTypes.SPR)">
                  <button id="validateSPRSectionsBtn" class="box-selection"
                    (click)='validateSectionClicked(sectionEnum.Spr)'
                    [disabled]="_claimService.isViewOnly || isButtonDisabled(buttonActions.VALIDATE, submissionTypes.SPR)">
                    <span *ngIf="overviewModel.sprStatus < statusEnum.L2Validated" class="btn-floating btn-large">
                      <i>{{!showValidateOnly ? '1' : ''}}</i>
                    </span>
                    <span *ngIf="overviewModel.sprStatus >= statusEnum.L2Validated"
                      class="btn-floating btn-large btn-success">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                    <span class="box-in-box">Validate all SPR data</span>
                    <p>Validate data in all components</p>
                  </button>
                </div>
                <div *ngIf="!showValidateOnly"
                  [ngClass]="getButtonCSS(buttonActions.GEN_VARIANCE, submissionTypes.SPR)">
                  <button id="generateVarianceSPRSectionsBtn" class="box-selection"
                    (click)="generateVarianceReportClicked('SPR')"
                    [disabled]="_claimService.isViewOnly || isButtonDisabled(buttonActions.GEN_VARIANCE, submissionTypes.SPR)">
                    <span
                      *ngIf="overviewModel.sprStatus < statusEnum.L3Explanation || isSubmittedNoExplanation(overviewModel.sprStatus)"
                      class="btn-floating btn-large">
                      <i>2</i>
                    </span>
                    <span
                      *ngIf="overviewModel.sprStatus >= statusEnum.L3Explanation && !isSubmittedNoExplanation(overviewModel.sprStatus)"
                      class="btn-floating btn-large btn-success">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                    <span class="box-in-box">Generate Variances for SPR data</span>
                    <p>Enter explanations for variances where necessary</p>
                  </button>
                </div>
                <!-- add selected class to insider div when selected -->
                <div *ngIf="!showValidateOnly" [ngClass]="getButtonCSS(buttonActions.SUBMIT, submissionTypes.SPR)">
                  <button id="submitForReviewSPRSectionsBtn" class="box-selection"
                    (click)="submitForReviewClicked('SPR')"
                    [disabled]="_claimService.isViewOnly || isButtonDisabled(buttonActions.SUBMIT, submissionTypes.SPR)">
                    <span *ngIf="overviewModel.sprStatus < statusEnum.Submitted" class="btn-floating btn-large">
                      <i>3</i>
                    </span>
                    <span *ngIf="overviewModel.sprStatus >= statusEnum.Submitted"
                      class="btn-floating btn-large btn-success">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                    <span class="box-in-box">Submit SPR data for review</span>
                    <p *ngIf="_claimService.isNavStateSubmission && isSubmitExplanations(submissionTypes.SPR)">
                      Submit explanations
                    </p>
                    <p *ngIf="_claimService.isNavStateSubmission && !isSubmitExplanations(submissionTypes.SPR) 
                      && !(_claimService.isAAAUser || !_commonService.isNullOrEmpty(this._claimService.currentSubOrg))">
                      Submit data to ACL for review
                    </p>
                    <p *ngIf="_claimService.isAAAUser || !_commonService.isNullOrEmpty(this._claimService.currentSubOrg)">
                      Submit data to State for review
                    </p>
                  </button>
                </div>
              </div>
              <!-- .end SPR flex -->
            </div>
            <!--.end SPR accordion body-->
          </div>
          <!--.end SPR accordion -->
        </div>
        <!-- .end SPR accordion -->
        <!-- NSIP accordion -->
        <div class="panel-group" id="nsipAccordion" role="tablist" aria-multiselectable="true">
          <!-- NSIP panel -->
          <div class="panel panel-default border-none">
            <!-- NSIP heading -->
            <div class="panel-heading panel-heading-primary collapsed" role="tab" id="nsipHeading"
              data-toggle="collapse" data-target="#nsipBody">
              <p class="panel-title">
                <a role="button" class="collapsed" data-toggle="collapse" data-parent="#nsipAccordion" href="#nsipBody"
                  aria-expanded="true" aria-controls="nsipBody">
                  <span class="ico"></span>
                  <b> NSIP Data</b>
                </a>
                <span class="ml-1">
                  Overall reporting status:
                  <span id="nsipOverallStatus" class="ml-1"
                    [innerHTML]="_commonService.overallStatusHtmlWhite(overviewModel.nsipStatus, overviewModel.nsipIsRequired)"></span>
                </span>
              </p>
            </div>
            <!-- .end NSIP heading -->
            <div
              *ngIf="!_claimService.isAAAUser && overviewModel.nsipStatus < statusEnum.SubmittedNoExplanation && nsipDaysRemaining >= 0"
              class="alert alert-info clock" role="alert">
              <p>
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                <strong>{{nsipDaysRemaining}}</strong>
                day(s) left until NSIP submission due date: {{nsipDueDate | amDateFormat:'L'}}
              </p>
            </div>
            <div
              *ngIf="!_claimService.isAAAUser && overviewModel.nsipStatus < statusEnum.SubmittedNoExplanation && nsipDaysRemaining < 0"
              class="alert alert-danger" role="alert">
              <p>
                <i class="fa fa-2x fa-exclamation-triangle pull-left" aria-hidden="true"></i>
                <strong>{{0 - nsipDaysRemaining}}</strong>
                day(s) past NSIP submission due date: {{nsipDueDate | amDateFormat:'L'}}
              </p>
            </div>
            <div
              *ngIf="!_claimService.isAAAUser && overviewModel.nsipStatus < statusEnum.L3Explanation && nsipExplanationDaysRemaining >= 0"
              class="alert alert-info clock" role="alert">
              <p>
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                <strong>{{nsipExplanationDaysRemaining}}</strong>
                day(s) left until NSIP variance explanations due date: {{nsipExplanationsDueDate | amDateFormat:'L'}}
              </p>
            </div>
            <div
              *ngIf="!_claimService.isAAAUser && overviewModel.nsipStatus < statusEnum.L3Explanation && nsipExplanationDaysRemaining < 0"
              class="alert alert-danger" role="alert">
              <p>
                <i class="fa fa-2x fa-exclamation-triangle pull-left" aria-hidden="true"></i>
                <strong>{{0 - nsipExplanationDaysRemaining}}</strong>
                day(s) past NSIP variance explanations due date: {{nsipExplanationsDueDate | amDateFormat:'L'}}
              </p>
            </div>
            <!-- end nsip alert -->
            <!-- nsip return alert -->
            <div *ngIf="showCheckForSPRAction('RETURNED', 'NSIP')" class="alert alert-danger mt-1 mb-0" role="alert">
              <a class="pull-right btn-sm btn-danger" (click)="isSPRReturnComments = false; showReturnedComments()">
                View return comments
              </a>
              <p class="inline-block">Your data has been returned for rework.</p>
              <p class="inline-block">Please make the necessary corrections and resubmit for review and approval.</p>
            </div>
            <!-- end nsip return alert -->
            <!-- NSIP accordion body -->
            <div id="nsipBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="nsipHeading">
              <div *ngIf="viewConstraints.isNsipViewable" class="panel-body panel-body-plain">
                <!-- retract submission -->
                <div *ngIf="showPullBackNsip()" class="background-gray-lighter p-1 mb-1">
                  <p>
                    <button id="pullBackNSIPBtn" class="pull-right btn btn-export"
                      (click)="pullBackForCorrectionsClicked('NSIP')"
                      (keyup.enter)="pullBackForCorrectionsClicked('NSIP')">
                      Pull back for corrections
                    </button>
                    <ng-container *ngIf="!_commonService.isNullOrEmpty(this._claimService.currentSubOrg)">
                      Your NSIP data has been submitted to your state for review.
                      <br />You may pull back your NSIP submission for corrections until your state begins their review
                    </ng-container>
                    <ng-container *ngIf="_commonService.isNullOrEmpty(this._claimService.currentSubOrg)">
                      Your NSIP data has been submitted to {{orgText}} for review.
                      <br />You may pull back your NSIP submission for corrections until {{orgText}} begins their review
                    </ng-container>
                  </p>
                </div>
                <!-- .end retract submission -->
                <div *ngIf="overviewModel.nsipStatus == statusEnum.L3NoExplanation" class="row LeftRightMargin0px">
                  <div class="alert alert-danger col-md-12 mt-1" role="alert">
                    <i class="fa fa-2x fa-exclamation-triangle pull-left" aria-hidden="true">
                      <span class="sr-only">Error:</span>
                    </i>
                    &nbsp;Variance explanations are required.
                  </div>
                </div>
                <!-- NSIP table responsive -->
                <div class="col-md-12 table-responsive">
                  <table class="table mb-4">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col">Section</th>
                        <th scope="col">Data Entry Status</th>
                        <th scope="col">Last Update</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">
                        <a [routerLink]="['../titleIIInsip']">NSIP Qualified Meals</a>
                      </th>
                      <td id="nsipStatus" [ngClass]="_commonService.setColor(nsipSection.sectionStatus)"
                        [innerHTML]="_commonService.statusReader(nsipSection.sectionStatus, nsipSection.isRequired)">
                      </td>
                      <td>
                        <span
                          [innerHTML]="_commonService.updatedByReader(nsipSection.lastModified, nsipSection.updatedBy, nsipSection.isRequired)"></span>
                      </td>
                      <td class="text-nowrap">
                        <div *ngIf="nsipSection.isRequired">
                          <acl-overview-buttons [options]="buildButtonOptions(overviewConstants.NSIP)" [state]="state"
                            (validateAction)="validate($event)"></acl-overview-buttons>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <!-- .end NSIP table responsive-->
              </div>
              <!-- NSIP data is not viewable (either by ACL or AAA state user based on status) -->
              <div *ngIf="!viewConstraints.isNsipViewable" class="panel-body p-1">
                <strong>NSIP data has not been submitted.</strong>
              </div>
              <!-- wire conditions -->
              <!-- NSIP flex -->
              <!-- These conditions are so specific because of the very unusual submission flow functionality chosen -->
              <div *ngIf="isResponsibleNsip && shouldSeeActionsBasedOnViewAndRole" class="flex flex-center">
                <div [ngClass]="getButtonCSS(buttonActions.VALIDATE, submissionTypes.NSIP)">
                  <button id="validateNSIPSectionBtn" class="box-selection"
                    (click)='validateSectionClicked(sectionEnum.nsipSection)'
                    [disabled]="_claimService.isViewOnly || isButtonDisabled(buttonActions.VALIDATE, submissionTypes.NSIP)">
                    <span *ngIf="overviewModel.nsipStatus < statusEnum.L2Validated" class="btn-floating btn-large">
                      <i>{{!showValidateOnly ? '1' : ''}}</i>
                    </span>
                    <span *ngIf="overviewModel.nsipStatus >= statusEnum.L2Validated"
                      class="btn-floating btn-large btn-success">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                    <span class="box-in-box">Validate all NSIP data</span>
                    <p>Validate data in all components</p>
                  </button>
                </div>
                <div *ngIf="!showValidateOnly"
                  [ngClass]="getButtonCSS(buttonActions.GEN_VARIANCE, submissionTypes.NSIP)">
                  <button id="generateVarianceNSIPSectionBtn" class="box-selection"
                    (click)="generateVarianceReportClicked('NSIP')"
                    [disabled]="_claimService.isViewOnly || isButtonDisabled(buttonActions.GEN_VARIANCE, submissionTypes.NSIP)">
                    <span
                      *ngIf="overviewModel.nsipStatus < statusEnum.L3Explanation || isSubmittedNoExplanation(overviewModel.nsipStatus)"
                      class="btn-floating btn-large">
                      <i>2</i>
                    </span>
                    <span
                      *ngIf="overviewModel.nsipStatus >= statusEnum.L3Explanation && !isSubmittedNoExplanation(overviewModel.nsipStatus)"
                      class="btn-floating btn-large btn-success">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                    <span class="box-in-box">Generate variances for NSIP Report</span>
                    <p>Enter explanations for variances where necessary</p>
                  </button>
                </div>
                <!-- add selected class to insider div when selected -->
                <div *ngIf="!showValidateOnly" [ngClass]="getButtonCSS(buttonActions.SUBMIT, submissionTypes.NSIP)">

                  <button id="submitForReviewNSIPSectionBtn" class="box-selection"
                    (click)="submitForReviewClicked('NSIP')"
                    [disabled]="_claimService.isViewOnly || isButtonDisabled(buttonActions.SUBMIT, submissionTypes.NSIP)">
                    <span *ngIf="overviewModel.nsipStatus < statusEnum.Submitted" class="btn-floating btn-large">
                      <i>3</i>
                    </span>
                    <span *ngIf="overviewModel.nsipStatus >= statusEnum.Submitted"
                      class="btn-floating btn-large btn-success">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                    <span class="box-in-box">Submit NSIP data for review</span>
                    <p *ngIf="_claimService.isNavStateSubmission && isSubmitExplanations(submissionTypes.NSIP)">
                      Submit explanations
                    </p>
                    <p *ngIf="_claimService.isNavStateSubmission && !isSubmitExplanations(submissionTypes.NSIP) 
                      && !(_claimService.isAAAUser || !_commonService.isNullOrEmpty(this._claimService.currentSubOrg))">
                      Submit data to ACL for review
                    </p>
                    <p *ngIf="_claimService.isAAAUser || !_commonService.isNullOrEmpty(this._claimService.currentSubOrg)">Submit data to State for review</p>
                  </button>
                </div>
              </div>
              <!-- .end NSIP flex -->
            </div>
            <!--.end NSIP accordion body-->
          </div>
          <!--.end NSIP accordion -->
        </div>
        <!-- .end NSIP accordion -->
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
    <!-- todo view for profile not being completed yet for ffy -->
    <div *ngIf="!hasProfile" class="col-md-12 acl-container accordionGroup">
      <div class="col-md-12">
        <div class="row border-bottom-gray-light">
          <div class="col-md-6 pl-0">
            <h1 class="border-none">Data Submissions</h1>
          </div>
          <!-- .end col-md-6 -->
          <div class="col-md-3 col-md-push-3">
            <!-- .begin year picker dropdown -->
            <fiscalYearDropdown></fiscalYearDropdown>
            <!-- .end year picker dropdown -->
          </div>
          <!-- .end col-md-3 -->
        </div>
        <!-- .end row -->
      </div>
      <!-- .end col-md-12  -->
      <p>Your state has not defined the data submission responsibility for the selected year.</p>
      <br />
      <p *ngIf="_claimService.isNavStateSubmission">
        Please identify the responsible party for each data group on the
        Data Submission Responsibility screen.
      </p>
      <p *ngIf="_claimService.isAAAUser">Please contact your State Unit on Aging.</p>
    </div>
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
