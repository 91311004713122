import { VarianceExplanation } from '../shared/varianceExplanation.model';
import { DataElement, QuestionStatusEnum } from '../shared/dataElement';
import { Section } from '../shared/section';
import { ServiceExpenditure } from '../shared/serviceExpenditure';


export class ConsumerSummaryTotal extends Section {
    registeredClients: DataElement;
    totalClients: DataElement;
    totalMinorityDistribution: DataElement;
    totalRural: DataElement;
    totalBelowPoverty: DataElement;
    totalNutritionRiskDistribution: DataElement;
    totalADL3: DataElement;
    varianceExplanation: VarianceExplanation;

    constructor() {
        super();
        this.registeredClients = new DataElement();
        this.totalClients = new DataElement();
        this.totalMinorityDistribution = new DataElement();
        this.totalRural = new DataElement();
        this.totalBelowPoverty = new DataElement();
        this.totalNutritionRiskDistribution = new DataElement();
        this.totalADL3 = new DataElement();
    }
}

export class OAServiceAndExpenditureSummaryDto extends Section {
    public ffy: string;
    public state: string;
    public status: QuestionStatusEnum;
    // public consumerTotalPerson: OAConsumerTpsSection;
    public consumerSummaryTotal: ConsumerSummaryTotal;
    public personalCare: ServiceExpenditure;
    public homemaker: ServiceExpenditure;
    public chore: ServiceExpenditure;
    public homeDeliveredMeals: ServiceExpenditure;
    public adultDayCare: ServiceExpenditure;
    public caseManagement: ServiceExpenditure;
    public assistedTransportation: ServiceExpenditure;
    public congregateMeals: ServiceExpenditure;
    public nutritionCounseling: ServiceExpenditure;
    public legalAssistance: ServiceExpenditure;
    public otherServices: ServiceExpenditure;
    public transportation: ServiceExpenditure;
    public nutritionEducation: ServiceExpenditure;
    public informationAndAssistance: ServiceExpenditure;
    public evidenceBasedHealth: ServiceExpenditure;
    public nonEvidenceBasedHealth: ServiceExpenditure;
    public total: ServiceExpenditure;
    public shouldSubmit: boolean;
    public confirmationPrompt: boolean;
    // public nsipUnit: NSIPSection;

    constructor() {
        super();
        this.ffy = '';
        this.state = '';
        // this.consumerTotalPerson = new OAConsumerTpsSection();
        this.consumerSummaryTotal = new ConsumerSummaryTotal();
        this.personalCare = new ServiceExpenditure();
        this.homemaker = new ServiceExpenditure();
        this.chore = new ServiceExpenditure();
        this.homeDeliveredMeals = new ServiceExpenditure();
        this.adultDayCare = new ServiceExpenditure();
        this.caseManagement = new ServiceExpenditure();
        this.assistedTransportation = new ServiceExpenditure();
        this.congregateMeals = new ServiceExpenditure();
        this.nutritionCounseling = new ServiceExpenditure();
        this.legalAssistance = new ServiceExpenditure();
        this.otherServices = new ServiceExpenditure();
        this.transportation = new ServiceExpenditure();
        this.nutritionEducation = new ServiceExpenditure();
        this.informationAndAssistance = new ServiceExpenditure();
        this.evidenceBasedHealth = new ServiceExpenditure();
        this.nonEvidenceBasedHealth = new ServiceExpenditure();
        this.total = new ServiceExpenditure;
        // this.nsipUnit = new NSIPSection();
    }
}
