import { Router } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { from } from 'rxjs';

import { ClaimService, Title } from '../../shared/claim.service';
import { ITitleOrg } from '../../UserManagement/userManagerObjects';
import { environment } from '../../../environments/environment';
import { OktaService } from '../service/okta.service';
import { DestructibleComponent } from '../../destructible.component';
import { AuthStates } from '../authStates';

const USER_NOT_ASSIGNED_MESSAGE = 'Your account is not assigned to this OAAPS environment. Please contact the technical support team.';

@Component({
    templateUrl: 'login.component.html',
    selector: 'login-header',
    styles: [
        '.vmiddle { vertical-align: middle; }',
        '.vmiddle > a { display: block; line-height: 2.5rem; }'
    ]
})
export class LoginComponent extends DestructibleComponent implements OnInit, OnDestroy {

    @Input() currentTitleOrg: ITitleOrg;

    public isLoggedIn = false;
    public username = '';
    public userProfileUri = '';

    constructor(public router: Router,
        public oktaService: OktaService,
        public claimService: ClaimService) {
            super();
            this.userProfileUri = `${environment.oktaConfig.url}enduser/settings`
         }

    ngOnInit() {
        super.registerSubscription(this.oktaService.stateChange.subscribe((state) => {
            console.log(AuthStates[state]);
            if (state === AuthStates.LOGIN_COMPLETE) {
                this.isLoggedIn = true;
                from(this.oktaService.getUpnAsync())
                    .subscribe(upn => this.username = upn);
            } else {
                this.handleNotAuthState(state);
            }
        }));
    }

    ngOnDestroy(): void {
        super.destroy();
    }

    login() {
        // localStorage.removeItem('title');
        this.claimService.clearLocalStorage();
        sessionStorage.removeItem('fiscalYear');
        sessionStorage.removeItem('sessionExpiredRedirect');
        this.oktaService.login();
    }

    logout() {
        this.router.navigate(['/logout']);
    }

    public get roles() {
        return this.claimService.roles;
    }

    public switchTitle(titleOrg: ITitleOrg): void {
        this.claimService.currentTitle = titleOrg.title as Title;
        this.claimService.currentOrg = titleOrg.org;
        localStorage.setItem('title', titleOrg.title);
        if (titleOrg.org) {
            localStorage.setItem('org', titleOrg.org);
        }
        localStorage.setItem('titleOrg', JSON.stringify(titleOrg));
        if (localStorage.getItem('reportingYears')) {
            const rpYears = JSON.parse(localStorage.getItem('reportingYears'));
            this.claimService.currentYear = rpYears[titleOrg.title];
            sessionStorage.removeItem("fiscalYear");
            sessionStorage.setItem('fiscalYear', rpYears[titleOrg.title]);
        }

        if (this.router.url.includes('/dashboard')) {
            location.reload();
        } else {
            location.href = '/dashboard';
        }
    }

    forgotPassNav() {
        window.open(environment.forgotPwdLink, '_blank');
    }

    private handleNotAuthState(state: AuthStates) {
        if (state === AuthStates.USER_NOT_ASSIGNED) {
            alert(USER_NOT_ASSIGNED_MESSAGE);
        }
        this.isLoggedIn = false;
        this.username = '';
    }
}
