import { IDataElement, DataElement } from './dataElement';
import { Map } from './DataStructure/map';

export class DynamicFormItem {

    public description: IDataElement;
    public value: IDataElement;
    public properties: Map<DataElement>;

    constructor() {
        this.description = new DataElement();
        this.value = new DataElement();
        this.properties = {};
    }
}
