import {
    Component, OnInit, NgZone, ViewChild, OnDestroy
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { LoadingComponent } from '../shared/loading.component';
import { ClaimService } from '../shared/claim.service';
import { CommonService } from '../shared/common.service';
import { TIIIOverview } from '../shared/section';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { TIIIDataSubmissionsFilter, StatusItemCheckbox } from '../filter/dataSubmissionsFilter';
import { UserDefaultStates } from '../shared/userDefaultStates';
import { Constants, State } from '../app.constants';
import * as _ from 'lodash-es';
import { RegionEnum } from '../UserManagement/userManagerObjects';
import { FfyStatesDto, IStateServiceFlags } from './Dto/FfyStatesDto';
import { finalize, exhaustMap } from 'rxjs/operators';
import { DestructibleComponent } from '../destructible.component';

declare var jQuery: any;

@Component({
    templateUrl: 'titleIIIDataSubmissions.component.html',
    animations: [
        trigger('fadeInFlyOut', [
            state('inactive', style({
                display: 'none',
                height: '0px',
                borderWidth: '0px',
                padding: '0px',
                boxShadow: '0px 0px 0px 0px #FFFFFF'
            })),
            state('active', style({
                display: 'block',
                height: '*',
                borderWidth: '1px',
                padding: '1em',
                boxShadow: '1px 1px 5px 0px #444444'
            })),
            transition('inactive => active', animate('300ms ease-in-out')),
            transition('active  => inactive', animate('300ms ease-in-out'))
        ])
    ]
})

export class TitleIIIDataSubmissionsComponent extends DestructibleComponent implements OnInit, OnDestroy {

    constructor(public zone: NgZone,
        public route: ActivatedRoute,
        public router: Router,
        public _titleService: Title,
        public _service: TitleIIIDataEntryService,
        public _claimService: ClaimService,
        public _commonService: CommonService
    ) {
        super();
        this.filterOptions = new TIIIDataSubmissionsFilter();
        this.filterOptions.ffy = this._claimService.currentYear;
    }

    overviews: TIIIOverview[] = [];
    rowsOnPage: number = 10;
    sortBy: string = 'state';
    sortOrder: string = 'asc';
    statusEnum = QuestionStatusEnum;
    years: Array<string> = []; // this._commonService.yearsSince(ProgramStartYear).sort((left, right) => left > right ? -1 : 1);
    stateAbbrs: string[] = Constants.STATES.map(item => item.abbr).sort();
    states = Constants.STATES;
    filteredStates: State[] = this.states;
    regions: string[] = _.uniq(Constants.STATES.map(item => item.region));
    titleIIIUserDefaultStates: UserDefaultStates = new UserDefaultStates();
    myStates: string[] = [];
    submittedStateOverviews: TIIIOverview[] = [];
    action: QuestionStatusEnum;
    sprStatesSelected: boolean = false;
    nsipStatesSelected: boolean = false;
    sprStates: TIIIOverview[] = [];
    nsipStates: TIIIOverview[] = [];
    isUserLocker = false;
    tabIndexFlag:string = '-1';

    // Filters
    filterOptions: TIIIDataSubmissionsFilter;

    // Animation state
    state: string = 'inactive';

    // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;
    sortByNumber = (a: any) => new Function('a', 'return +a.' + a + '.valueOf()');
    ngOnInit(): void {
        this._service.overviews = []; // Clear any previously selected overviews
        // this.loadingComponent.setLoadingInProgress(180);
        super.registerSubscription(this._claimService.init$.subscribe(() => {
            this._service.getOverviewByFfy(this._claimService.currentYear)
                .subscribe(data => { this.overviews = data;  },
                    err => {});
            if (this._claimService.currentRegion !== RegionEnum.CO.toString()) {
                this.filterOptions.region = this._claimService.currentRegion;
            } else {
                this.filterOptions.region = '';
            }
            this.checkAndAssignFilters();
            this.getTitleIIIUserDefaultStatesPreferences();
            this.getTitleIIIReportingPeriods();
            this._titleService.setTitle('Data Submissions - ACL OAAPS');
            this.isUserLocker = this._claimService.isNavLocker;
        }));
    }

    ngOnDestroy() {
        super.destroy();
    }

    // State link clicked
    routeToReviewForState(overview: TIIIOverview) {
        // const stateOverview = this.overviews.find(x => x.state === state);
        this._claimService.currentOrg = overview.state;
        this._claimService.isRollupOverview = overview.isRollup;
        this._claimService.currentSubOrg = overview.psa;
        // this._service.overviews = [];
        // this._service.overviews.push(stateOverview);
        this.router.navigate(['/data-submissions/titleIIISSReview']);
    }
    routeToReviewForStates() {
        this.router.navigate(['/data-submissions/titleIIISSReview']);
    }
    // ACL should only see Not Started, In Progress, or > Returned
    resolveACLStatus(status: number): number {
        if (status > QuestionStatusEnum.NotStarted && status < QuestionStatusEnum.Returned) {
            return QuestionStatusEnum.L1InProgress;
        } else {
            return status;
        }
    }

    selectAllStatusNsip() {
        this.filterOptions.nsipStatus.forEach(item => item.isChecked = true);
    }

    deselectAllStatusNsip() {
        this.filterOptions.nsipStatus.forEach(item => item.isChecked = false);
    }

    selectAllStatusSpr() {
        this.filterOptions.sprStatus.forEach(item => item.isChecked = true);
    }

    deselectAllStatusSpr() {
        this.filterOptions.sprStatus.forEach(item => item.isChecked = false);
    }

    filter() {
        let filtered = this.overviews.slice();
        // Statuses
        // InProgress SPR
        const aclInProgSpr = _.find(this.filterOptions.sprStatus, x => x.key === StatusItemCheckbox.AclInProgress);
        // Other SPR
        const otherStatusSpr = _.difference(this.filterOptions.sprStatus, [aclInProgSpr]);
        // InProgress NSIP
        const aclInProgNsip = _.find(this.filterOptions.nsipStatus, x => x.key === StatusItemCheckbox.AclInProgress);
        // Other NSIP
        const otherStatusNsip = _.difference(this.filterOptions.nsipStatus, [aclInProgNsip]);
        // Region
        if (this.filterOptions.region && this.filterOptions.region.trim() !== '' && this.filterOptions.region.trim() !== 'myStates') {
            filtered = _.filter(filtered, row => row.region === this.filterOptions.region);
            this.filteredStates = this.states
                .filter(s => s.region === this.filterOptions.region.toString())
                .sort((a, b) => (a.abbr > b.abbr) ? 1 : -1);
        }
        // My States
        if (this.filterOptions.region && this.filterOptions.region.trim() !== '' && this.filterOptions.region.trim() === 'myStates') {
            filtered = _.filter(filtered, overview => this.myStates.includes(overview.state));
        }
        // State
        if (this.filterOptions.region.trim() == '') {
            this.filteredStates = this.states
                .sort((a, b) => (a.abbr > b.abbr) ? 1 : -1);
        }
        if (this.filterOptions.state && this.filterOptions.state.trim() !== '') {
            filtered = _.filter(filtered, overview => overview.state === this.filterOptions.state);
        }
        // SPR Status
        let filteredSpr = [];
        let showStatusesSpr = [];
        if (aclInProgSpr.isChecked) {
            showStatusesSpr.push(this._commonService.aclInProgressStatuses());
        }
        // Other statuses
        showStatusesSpr.push(_.filter(otherStatusSpr, x => x.isChecked).map(x => x.key));
        showStatusesSpr = _.flatten(showStatusesSpr);
        filteredSpr = _.filter(filtered, overview => showStatusesSpr.indexOf(overview.sprStatus) !== -1);

        // NSIP Status
        let filteredNsip = [];
        let showStatusesNsip = [];
        if (aclInProgNsip.isChecked) {
            showStatusesNsip.push(this._commonService.aclInProgressStatuses());
        }
        // Other statuses
        showStatusesNsip.push(_.filter(otherStatusNsip, x => x.isChecked).map(x => x.key));
        showStatusesNsip = _.flatten(showStatusesNsip);
        filteredNsip = _.filter(filtered, overview => showStatusesNsip.indexOf(overview.nsipStatus) !== -1);

        filtered = _.union(filteredSpr, filteredNsip);
        // Start date
        if (this._commonService.isValidDateString(this.filterOptions.startDate)) {
            filtered = _.filter(filtered, overview => this._commonService.isAfter(
                new Date(overview.lastUpdatedTime), new Date(this.filterOptions.startDate)));
        }
        // End date
        if (this._commonService.isValidDateString(this.filterOptions.endDate)) {
            filtered = _.filter(filtered, overview => !this._commonService.isAfter(
                new Date(overview.lastUpdatedTime), new Date(this.filterOptions.endDate)));
        }
        return filtered;
    }

    toggleFilters(): void {
        this.state = this.state === 'inactive' ? 'active' : 'inactive';
        if (this.state === 'active') {
            this.tabIndexFlag = '0';
        } else {
            this.tabIndexFlag = '-1';
        }
    }

    clearFilters() {
        this.filterOptions = new TIIIDataSubmissionsFilter();
        this.filterOptions.ffy = this._claimService.currentYear;
    }

    updateFfy() {
        // this.loadingComponent.setLoadingInProgress(60);
        this._service.getOverviewByFfy(this.filterOptions.ffy)
            .subscribe(data => { this.overviews = data; this.filter();  },
                err => {});
        if (this.filterOptions.ffy && this.filterOptions.ffy.trim() !== '') {
            this._claimService.currentYear = this.filterOptions.ffy;
            sessionStorage.setItem('fiscalYear', this.filterOptions.ffy);
        }
    }

    getTitleIIIUserDefaultStatesPreferences(): void {
        this._service.getUserDefaultStates()
            .subscribe(data => {
                if (data.length > 0) {
                    this.titleIIIUserDefaultStates.id = data[0].id;
                    this.titleIIIUserDefaultStates.upn = data[0].upn;
                    this.titleIIIUserDefaultStates.defaultStates = data[0].defaultStates;
                    if (this.titleIIIUserDefaultStates.defaultStates.length > 0) {
                        this.loadMyT3States(this.titleIIIUserDefaultStates.defaultStates);
                    }
                }
                this.checkAndAssignFilters();
            });
    }

    getTitleIIIReportingPeriods(): void {
        this._service.getAllReportingPeriods(this._claimService.isNavApprover ? "true" : "false")
            .subscribe(data => {
                if (data.length > 0) {
                    this.years = data.map(x => x.fiscalYear);
                }
            });
    }

    loadMyT3States(states: string[]): void {
        // national
        if (states.includes('national')) {
            this.myStates = Constants.STATES.map(item => item.abbr);
        }
        // regional
        if (states.toString().search('region') !== -1) {
            for (const region of states) {
                const regionNumber = region.replace('region ', '');
                Constants.STATES.forEach(s => {
                    if (s.region === regionNumber) {
                        this.myStates.push(s.abbr);
                    }
                });
            }
        }
        // state
        if (states.length > 0 && !states.includes('national') && states.toString().search('region') === -1) {
            Constants.STATES.forEach(s => {
                if (states.includes(s.abbr)) {
                    this.myStates.push(s.abbr);
                }
            });
        }
    }

    checkAndAssignFilters(): void {
        // If persistent filters are added to title III
        // Check for persistent filter in AppState
        /*
        const persistentFilterOptions = this._appState[this.appStateKeyTIIIFilter];
        if (persistentFilterOptions) {
            this.filterOptions = persistentFilterOptions;
        } else {*/
        this.filterOptions = new TIIIDataSubmissionsFilter();
        this.filterOptions.ffy = this._claimService.currentYear;
        if (this._claimService.currentRegion !== RegionEnum.CO.toString() && this.myStates.length === 0) {
            this.filterOptions.region = this._claimService.currentRegion;
        } else {
            this.filterOptions.region = '';
        }
        if (this.myStates.length > 0) {
            this.filterOptions.region = 'myStates';
        }
        // }

    }
    handleSprCheckEvent(e) {
        if (e.target.checked) {
            this.sprStates.push(this.submittedStateOverviews.find(x => x.state === e.target.getAttribute('value')));
        } else {
            const index = this.sprStates.findIndex(x => x.state === e.target.getAttribute('value'));
            if (index !== -1) {
                this.sprStates.splice(index, 1);
            }
        }
    }

    handleNsipCheckEvent(e) {
        if (e.target.checked) {
            this.nsipStates.push(this.submittedStateOverviews.find(x => x.state === e.target.getAttribute('value')));
        } else {
            const index = this.nsipStates.findIndex(x => x.state === e.target.getAttribute('value'));
            if (index !== -1) {
                this.nsipStates.splice(index, 1);
            }
        }
    }
    selectAllSprStates(e) {
        if (e.target.checked) {
            this.sprStates = [];
            this.submittedStateOverviews.forEach((item) => {
                if (item.sprStatus === this.action) {
                    this.sprStates.push(Object.assign({}, item));
                }
            });
            this.sprStatesSelected = this.sprStates.length > 0 ? true : false;
        } else {
            this.sprStates = [];
            this.sprStatesSelected = false;
        }
    }

    selectAllNsipStates(e) {
        if (e.target.checked) {
            this.nsipStates = [];
            this.submittedStateOverviews.forEach((item) => {
                if (item.nsipStatus === this.action) {
                    this.nsipStates.push(Object.assign({}, item));
                }
            });
            this.nsipStatesSelected = this.nsipStates.length > 0 ? true : false;
        } else {
            this.nsipStates = [];
            this.nsipStatesSelected = false;
        }
    }

    saveStatus(): void {
        this.sprStatesSelected = false;
        // this.loadingComponent.setLoadingInProgress(30);

        const dataMap = this.sprStates.map(x => ({ type: 'SPR', state: x.state })).concat(
            this.nsipStates.map(x => ({ type: 'NSIP', state: x.state })))
            .reduce((acc, cur) => {
                const stateServiceFlags: IStateServiceFlags = acc.statesServiceFlags[cur.state]
                    || { state: cur.state, spr: false, nsip: false };
                stateServiceFlags.spr = stateServiceFlags.spr || cur.type === 'SPR';
                stateServiceFlags.nsip = stateServiceFlags.nsip || cur.type === 'NSIP';
                acc.statesServiceFlags[cur.state] = stateServiceFlags;
                return acc;
            }, { fiscalYear: this._claimService.currentYear, statesServiceFlags: {} });

        let payload: FfyStatesDto = {
            fiscalYear: dataMap.fiscalYear,
            statesServiceFlags: Object.values(dataMap.statesServiceFlags)
        }

        this._service.batch(this.action, payload)
            .pipe(
                exhaustMap(() => this._service.getOverviewByFfy(this.filterOptions.ffy)),
                finalize(() => {  this.sprStates = []; this.nsipStates = []; })
            )
            .subscribe(data => this.overviews = data);
    }

    showSubmittedStates(): void {
        if (this.action !== 0) {
            this.submittedStateOverviews = this.filter().filter(x => x.sprStatus === this.action || x.nsipStatus === this.action);
            jQuery('#submittedStates').modal('show');
        }
    }

    dismissSubmittedStateModal(): void {
        jQuery('#submittedStates').modal('hide');
    }

    displayAttachments() {
        this.router.navigate(['/data-submissions/aclManageAttachments']);
    }
}
