import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of, interval } from 'rxjs';
import { catchError, tap, exhaustMap, takeWhile, finalize, flatMap } from 'rxjs/operators';

import { BasicErrorDetail } from '../shared/Upload/uploadError';
import { TVIUserNotifications } from './tviUserNotification';
// Shared Models
import { LoadingComponent } from '../shared/loading.component';

// Models
import { ClaimService } from '../shared/claim.service';
import { StaffInfoSection } from './model/staffInfoSection';
import { NutritionServicesSection } from './model/NutritionServicesSection';
import { AccessServicesSection } from './model/AccessServicesSection';
import { InHomeServicesSection } from './model/InHomeServicesSection';
import { OtherServicesSection } from './model/OtherServicesSection';
import { CaregiverCharsSection } from './model/CaregiverCharsSection';
import { CaregiverServicesSection } from './model/CaregiverServicesSection';
import { SuppServicesSection } from './model/SuppServicesSection';
import { RespiteSection } from './model/RespiteSection';
import { RemarksSection } from './model/RemarksSection';
import { FinanceABSection } from './model/FinanceABSection';
import { FinanceCSection } from './model/FinanceCSection';
import { UserDefaultGrantees } from '../shared/userDefaultGrantees';
import { ITVIOverviewDto, TVIOverviewDto } from './model/Overview';
import { StatusHistoryDto } from '../shared/Dto/statusHistoryDto';
import { YearApprovalStatus } from '../shared/yearApprovalStatus.model';
import { FfyOrgsDto } from './model/dto/ffyOrgsDto';
import { QuestionStatusEnum } from '../shared/dataElement';
import { Section } from '../shared/section';

// TODO this service need refactoring, sections are referenced by ffy and org for title vi

@Injectable()
export class TitleVIDataEntryService {

  private httpClient: HttpClient;

  constructor(public _http: HttpClient,
    public _claimService: ClaimService) {
      this.httpClient = _http;
  }

  // Resources

  // Variance
  public _updateVarianceNutritionServices = 'api/titlevi/variance/status/nutritionservices';
  public _updateVarianceAccessServices = 'api/titlevi/variance/status/accessservices';
  public _updateVarianceRespite = 'api/titlevi/variance/status/respite';
  public _updateVarianceCaregiverServices = 'api/titlevi/variance/status/caregiverservices';

  // overrview
  private _canReport = '/api/titlevi/overview/can-report/{ffy}/{org}';
  private _getOverviewByffyandorg = '/api/titlevi/overview/{ffy}/{org}';
  private _getOverviewByffy = '/api/titlevi/overview/{ffy}';
  private _validate = '/api/titlevi/overview/validate/{ffy}/{org}';
  private _variance = '/api/titlevi/overview/variance/{ffy}/{org}';
  private _submit = '/api/titlevi/overview/submit/{ffy}/{org}';
  private _review = '/api/titlevi/overview/review/{ffy}/{org}';
  public _dataEntryErrors = 'api/titlevi/overview/errors';//'/api/titlevi/validation-errors/{ffy}/{id}';
  public _approve = '/api/titlevi/overview/approve';
  public _reviewBatch = '/api/titlevi/overview/review/batch';
  public _lock = '/api/titlevi/overview/lock/{ffy}/{org}';
  public _lockBatch = '/api/titlevi/overview/lock/batch';
  public _approveBatch = '/api/titlevi/overview/approve/batch';
  public _return = '/api/titlevi/overview/return';
  public _batchLock = '/api/titlevi/overview/batch/lock';

  // Staffing Info
  public _getstaffinfobyffyandstate = 'api/titlevi/staffinfo/{ffy}/{state}/{sectionName}';
  public _savestaffinfo = '/api/titlevi/staffinfo';

  // Nutrition Services
  public _getnutritionservicesbyffyandstate = 'api/titlevi/nutritionservices/{ffy}/{state}';
  public _savenutritionservices = '/api/titlevi/nutritionservices';

  // Access Services
  public _getaccessservicesbyffyandstate = 'api/titlevi/accessservices/{ffy}/{state}';
  public _saveaccessservices = '/api/titlevi/accessservices';

  // In-Home Services
  public _getinhomeservicesbyffyandstate = 'api/titlevi/inhomeservices/{ffy}/{state}';
  public _saveinhomeservices = '/api/titlevi/inhomeservices';

  // Other Services
  public _getotherservicesbyffyandstate = 'api/titlevi/otherservices/{ffy}/{state}';
  public _saveotherservices = '/api/titlevi/otherservices';

  // Caregiver Characteristics
  public _getcaregivercharsbyffyandstate = 'api/titlevi/caregiverchars/{ffy}/{state}';
  public _savecaregiverchars = '/api/titlevi/caregiverchars';

  // Caregiver Services Services
  public _getcaregiverservicesbyffyandstate = 'api/titlevi/caregiverservices/{ffy}/{state}';
  public _savecaregiverservices = '/api/titlevi/caregiverservices';

  // Supplemental Services Services
  public _getsuppservicesbyffyandstate = '/api/titlevi/suppservices/{ffy}/{state}';
  public _savesuppservices = '/api/titlevi/suppservices';
  public _deletesuppservices = '/api/titlevi/suppservices/delete/{id}';

  // Respite Services
  public _getrespitebyffyandstate = 'api/titlevi/respite/{ffy}/{state}';
  public _saverespite = '/api/titlevi/respite';

  // Remarks
  public _getremarksbyffyandstate = 'api/titlevi/remarks/{ffy}/{state}';
  public _saveremarks = '/api/titlevi/remarks';

  // Finance Part A/B
  public _getfinanceabbyffyandstate = 'api/titlevi/financeab/{ffy}/{state}';
  public _savefinanceab = '/api/titlevi/financeab';

  // Finance Part C
  public _getfinancecbyffyandstate = 'api/titlevi/financec/{ffy}/{state}';
  public _savefinancec = '/api/titlevi/financec';

  // export
  public _exportTitleVIData = '/api/titlevi/export/{ffy}/{granteeId}';

  // User Default Grantees
  public _titleviUserDefaultGrantees = 'api/titlevi/userdefaultgrantees';

  // reporting periods
  public _getreportingperiods = 'api/titlevi/reportingperiod/all';
  public _getreportingperiodbyffy = 'api/titlevi/reportingperiod/{ffy}';
  public _getActiveYearApprovalStatus = 'api/titlevi/reportingperiod/active/{org}';
  public _getmaxreportingyear = 'api/titleiii/reportingperiod/maxyear';

  // user notification
  public _titleviUserPreferences = 'api/titlevi/usernotification';

  // Status
  public _yearApprovalStatusApiRoot = 'api/titlevi/year-approval-status';


  public _aclEditMode: boolean = false;
  public get aclEditMode(): boolean { return this._aclEditMode; }
  public set aclEditMode(isEditting: boolean) { this._aclEditMode = isEditting; }

  public isSectionEditable(claimService: ClaimService, section: Section): boolean {
    let isEditable = section.sectionStatus <= QuestionStatusEnum.Returned
        && !claimService.isViewOnly
        && (!claimService.isACLUser()
          || (claimService.isACLUser() && this.aclEditMode));
    return isEditable;
  }

  // Staffing Info
  savestaffinfo(model: StaffInfoSection): Observable<StaffInfoSection> {
    return this._http.post<StaffInfoSection>(
      this._savestaffinfo, model, { withCredentials: true }).pipe(
        catchError(this.handleError<StaffInfoSection>()
        ));
  }

  getstaffinfobyffyandstate(ffy: string, state: string):
    Observable<Array<StaffInfoSection>> {
    const url: string = this._getstaffinfobyffyandstate.replace('{ffy}', ffy)
      .replace('{state}', state);
    return this._http.get<Array<StaffInfoSection>>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<StaffInfoSection>>())
    );
  }

  // Nutrition Services
  savenutritionservices(model: NutritionServicesSection):
    Observable<NutritionServicesSection> {
    return this._http.post<NutritionServicesSection>(
      this._savenutritionservices, model, { withCredentials: true }).pipe(
        catchError(this.handleError<NutritionServicesSection>())
      );
  }

  getnutritionservicesbyffyandstate(ffy: string, state: string):
    Observable<Array<NutritionServicesSection>> {
    const url: string = this._getnutritionservicesbyffyandstate
      .replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<Array<NutritionServicesSection>>(
      url, { withCredentials: true }).pipe(
        catchError(this.handleError<Array<NutritionServicesSection>>())
      );
  }

  // Access Services
  saveaccessservices(model: AccessServicesSection): Observable<AccessServicesSection> {
    return this._http.post<AccessServicesSection>(
      this._saveaccessservices, model, { withCredentials: true }).pipe(
        catchError(this.handleError<AccessServicesSection>())
      );
  }

  getaccessservicesbyffyandstate(ffy: string, state: string):
    Observable<Array<AccessServicesSection>> {
    const url: string = this._getaccessservicesbyffyandstate
      .replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<Array<AccessServicesSection>>(
      url, { withCredentials: true }).pipe(
        catchError(this.handleError<Array<AccessServicesSection>>())
      );
  }

  // In-Home Services
  saveinhomeservices(model: InHomeServicesSection): Observable<InHomeServicesSection> {
    return this._http.post<InHomeServicesSection>(
      this._saveinhomeservices, model, { withCredentials: true }).pipe(
        catchError(this.handleError<InHomeServicesSection>())
      );
  }

  getinhomeservicesbyffyandstate(ffy: string, state: string):
    Observable<Array<InHomeServicesSection>> {
    const url: string = this._getinhomeservicesbyffyandstate
      .replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<Array<InHomeServicesSection>>(
      url, { withCredentials: true }).pipe(
        catchError(this.handleError<Array<InHomeServicesSection>>())
      );
  }

  // Other Services
  saveotherservices(model: OtherServicesSection): Observable<OtherServicesSection> {
    return this._http.post<OtherServicesSection>(
      this._saveotherservices, model, { withCredentials: true }).pipe(
        catchError(this.handleError<OtherServicesSection>())
      );
  }

  getotherservicesbyffyandstate(ffy: string, state: string):
    Observable<Array<OtherServicesSection>> {
    const url: string = this._getotherservicesbyffyandstate
      .replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<Array<OtherServicesSection>>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<OtherServicesSection>>())
    );
  }

  // Caregiver Chars
  savecaregiverchars(model: CaregiverCharsSection): Observable<CaregiverCharsSection> {
    return this._http.post<CaregiverCharsSection>(
      this._savecaregiverchars, model, { withCredentials: true }).pipe(
        catchError(this.handleError<CaregiverCharsSection>())
      );
  }

  getcaregivercharsbyffyandstate(ffy: string, state: string):
    Observable<Array<CaregiverCharsSection>> {
    const url: string = this._getcaregivercharsbyffyandstate
      .replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<Array<CaregiverCharsSection>>(
      url, { withCredentials: true }).pipe(
        catchError(this.handleError<Array<CaregiverCharsSection>>())
      );
  }

  // Caregiver Services
  savecaregiverservices(model: CaregiverServicesSection): Observable<CaregiverServicesSection> {
    return this._http.post<CaregiverServicesSection>(
      this._savecaregiverservices, model, { withCredentials: true }).pipe(
        catchError(this.handleError<CaregiverServicesSection>())
      );
  }

  getcaregiverservicesbyffyandstate(ffy: string, state: string):
    Observable<Array<CaregiverServicesSection>> {
    const url: string = this._getcaregiverservicesbyffyandstate
      .replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<Array<CaregiverServicesSection>>(
      url, { withCredentials: true }).pipe(
        catchError(this.handleError<Array<CaregiverServicesSection>>())
      );
  }

  // Supplemental Services
  savesuppservices(model: SuppServicesSection): Observable<SuppServicesSection> {
    return this._http.post<SuppServicesSection>(
      this._savesuppservices, model, { withCredentials: true }).pipe(
        catchError(this.handleError<SuppServicesSection>())
      );
  }

  getsuppservicesbyffyandstate(ffy: string, state: string):
    Observable<Array<SuppServicesSection>> {
    const url: string = this._getsuppservicesbyffyandstate
      .replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<Array<SuppServicesSection>>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<SuppServicesSection>>())
    );
  }

  deletesuppservices(id: string): Observable<any> {
    const url: string = this._deletesuppservices.replace('{id}', id);
    return this._http.get(url, { withCredentials: true }).pipe(
      catchError(this.handleError<any>())
    );
  }

  // Respite
  saverespite(model: RespiteSection): Observable<RespiteSection> {
    return this._http.post<RespiteSection>(
      this._saverespite, model, { withCredentials: true }).pipe(
        catchError(this.handleError<RespiteSection>())
      );
  }

  getrespitebyffyandstate(ffy: string, state: string):
    Observable<Array<RespiteSection>> {
    const url: string = this._getrespitebyffyandstate
      .replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<Array<RespiteSection>>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<RespiteSection>>())
    );
  }

  // Remarks
  saveremarks(model: RemarksSection): Observable<RemarksSection> {
    return this._http.post<RemarksSection>(
      this._saveremarks, model, { withCredentials: true }).pipe(
        catchError(this.handleError<RemarksSection>())
      );
  }

  getremarksbyffyandstate(ffy: string, state: string):
    Observable<Array<RemarksSection>> {
    const url: string = this._getremarksbyffyandstate
      .replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<Array<RemarksSection>>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<RemarksSection>>())
    );
  }

  // Finance Part A/B
  savefinanceab(model: FinanceABSection): Observable<FinanceABSection> {
    return this._http.post<FinanceABSection>(
      this._savefinanceab, model, { withCredentials: true }).pipe(
        catchError(this.handleError<FinanceABSection>())
      );
  }

  getfinanceabbyffyandstate(ffy: string, state: string):
    Observable<Array<FinanceABSection>> {
    const url: string = this._getfinanceabbyffyandstate
      .replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<Array<FinanceABSection>>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<FinanceABSection>>())
    );
  }

  // Finance Part C
  savefinancec(model: FinanceCSection): Observable<FinanceCSection> {
    return this._http.post<FinanceCSection>(
      this._savefinancec, model, { withCredentials: true }).pipe(
        catchError(this.handleError<FinanceCSection>())
      );
  }

  getfinancecbyffyandstate(ffy: string, state: string): Observable<Array<FinanceCSection>> {
    const url: string = this._getfinancecbyffyandstate
      .replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<Array<FinanceCSection>>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<FinanceCSection>>())
    );
  }

  // Variance
  updateVarianceNutritionServices(model: NutritionServicesSection):
    Observable<NutritionServicesSection> {
    return this._http.post<NutritionServicesSection>(
      this._updateVarianceNutritionServices, model, { withCredentials: true }).pipe(
        catchError(this.handleError<NutritionServicesSection>()
        ));
  }
  updateVarianceAccessServices(model: AccessServicesSection): Observable<AccessServicesSection> {
    return this._http.post<AccessServicesSection>(
      this._updateVarianceAccessServices, model, { withCredentials: true }).pipe(
        catchError(this.handleError<AccessServicesSection>())
      );
  }

  updateVarianceRespite(model: RespiteSection): Observable<RespiteSection> {
    return this._http.post<RespiteSection>(
      this._updateVarianceRespite, model, { withCredentials: true }).pipe(
        catchError(this.handleError<RespiteSection>())
      );
  }

  updateVarianceCaregiverServices(model: CaregiverServicesSection):
    Observable<CaregiverServicesSection> {
    return this._http.post<CaregiverServicesSection>(
      this._updateVarianceCaregiverServices, model, { withCredentials: true }).pipe(
        catchError(this.handleError<CaregiverServicesSection>())
      );
  }

  // Overview
  canReport(ffy: string, org: string): Observable<boolean> {
    return this._http.get<boolean>(this._canReport
      .replace('{ffy}', ffy).replace('{org}', org), { withCredentials: true }).pipe(
        catchError(this.handleError<boolean>())
      );
  }
  getoverviewbyffyandorg(ffy: string, org: string): Observable<TVIOverviewDto> {
    return this._http.get<TVIOverviewDto>(
      this._getOverviewByffyandorg.replace('{ffy}', ffy)
        .replace('{org}', org), { withCredentials: true }).pipe(
          catchError(this.handleError<TVIOverviewDto>())
        );
  }

  getoverviewbyffy(ffy: string, ids: string[]): Observable<Array<ITVIOverviewDto>> {
    return this._http.post<Array<ITVIOverviewDto>>(
      this._getOverviewByffy.replace('{ffy}', ffy), ids, { withCredentials: true }).pipe(
        tap(data => data.filter(x => x)),
        catchError(this.handleError<Array<ITVIOverviewDto>>())
      );
  }

  validate(ffy: string, org: string, sectionNames: string[] = []): Observable<ITVIOverviewDto> {
    const options = { withCredentials: true };
    return this._http.post<ITVIOverviewDto>(this._validate
      .replace('{ffy}', ffy).replace('{org}', org), sectionNames || [], options).pipe(
        catchError(this.handleError<TVIOverviewDto>())
      );
  }

  generateVariances(ffy: string, org: string, explanationProvided = false): Observable<ITVIOverviewDto> {
    const url = this._variance.replace('{ffy}', ffy).replace('{org}', org);
    const params: HttpParams = new HttpParams().append('explanationProvided', explanationProvided.toString());
    const options = { params, withCredentials: true };
    return this._http.post<ITVIOverviewDto>(url, null, options).pipe(
      catchError(this.handleError<TVIOverviewDto>())
    );
  }

  submit(ffy: string, org: string): Observable<ITVIOverviewDto> {
    const options = { withCredentials: true };
    const url = this._submit.replace('{ffy}', ffy).replace('{org}', org);
    return this._http.post<ITVIOverviewDto>(url, null, options).pipe(
      catchError(this.handleError<TVIOverviewDto>())
    );
  }

  review(ffy: string, org: string): Observable<TVIOverviewDto> {
    const options = { withCredentials: true };
    const url = this._review.replace('{ffy}', ffy).replace('{org}', org);
    return this._http.post<TVIOverviewDto>(url, null, options).pipe(
      catchError(this.handleError<TVIOverviewDto>())
    );
  }

  approve(model: YearApprovalStatus) {
    return this._http.post(this._approve, model, { withCredentials: true }).pipe(
      catchError(this.handleError())
    );
  }

  reviewBatch(model: FfyOrgsDto) {
    return this._http.post<{id?: string, error?: string}>(this._reviewBatch, model, { withCredentials: true });
  }

  lock(ffy: string, org: string) {
    const url = this._lock.replace('{ffy}', ffy).replace('{org}', org);
    return this._http.post(url, { withCredentials: true }).pipe(
      catchError(this.handleError<any>())
    );
  }

  lockBatch(model: FfyOrgsDto) {
    return this._http.post<{id?: string, error?: string}>(this._lockBatch, model, { withCredentials: true });
  }

  // Unlock
  approveBatch(model: FfyOrgsDto) {
    return this._http.post<{id?: string, error?: string}>(this._approveBatch, model, { withCredentials: true });
  }

  return(model: YearApprovalStatus) {
    return this._http.post(this._return, model, { withCredentials: true }).pipe(
      catchError(this.handleError<any>())
    );
  }

  // user default grantees
  getUserDefaultGrantees(): Observable<any> {
    return this._http.get(this._titleviUserDefaultGrantees, {
      params: { upn: this._claimService.userID }, withCredentials: true }).pipe(
        catchError(this.handleError<any>())
      );
  }

  saveUserDefaultGrantees(model: UserDefaultGrantees): Observable<UserDefaultGrantees> {
    return this._http.post<UserDefaultGrantees>(
      this._titleviUserDefaultGrantees, model, { withCredentials: true }).pipe(
        catchError(this.handleError<UserDefaultGrantees>())
      );
  }

  // reportingPeriods
  getAllReportingPeriods(): Observable<any> {
    return this._http.get(this._getreportingperiods, { withCredentials: true }).pipe(
      catchError(this.handleError())
    );
  }
  getReportingPeriodByFfy(ffy: string): Observable<any> {
    const url = this._getreportingperiodbyffy.replace('{ffy}', ffy);
    return this._http.get(url, { withCredentials: true }).pipe(
      catchError(this.handleError())
    );
  }

  getMaxReportingYear(): Observable<string> {
    return this._http.get<string>(this._getmaxreportingyear, { withCredentials: true }).pipe(
      catchError(this.handleError<string>())
    );
  }

  getActiveYearApprovalStatusByState(state: string): Observable<Array<any>> {
    const url: string = this._getActiveYearApprovalStatus.replace('{org}', state);
    return this._http.get<Array<any>>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<any>>())
    );
  }

  getStatusHistory(ffy: string, org: string): Observable<StatusHistoryDto[]> {
    const url = `${this._yearApprovalStatusApiRoot}/${ffy}/${org}`;
    return this._http.get<StatusHistoryDto[]>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<StatusHistoryDto[]>())
    );
  }

  getLatestStatusHistory(ffy: string, org: string, status: QuestionStatusEnum):
    Observable<StatusHistoryDto> {
    const url = `${this._yearApprovalStatusApiRoot}/latest/${ffy}/${org}/${status}`;
    return this._http.get<StatusHistoryDto>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<StatusHistoryDto>())
    );
  }

  // User Notifications
  findUserNotificationPreferences(): Observable<any> {
    const url = this._titleviUserPreferences + '/upn';
    return this._http.get(url,
      { params: { upn: this._claimService.userID }, withCredentials: true }).pipe(
        catchError(this.handleError<any>())
      );
  }

  saveUserNotifications(model: TVIUserNotifications): Observable<TVIUserNotifications> {
    return this._http.post<TVIUserNotifications>(
      this._titleviUserPreferences, model, { withCredentials: true }).pipe(
        catchError(this.handleError<TVIUserNotifications>())
      );
  }


  // Generic get
  // uses the claim service to determine
  // whether to pull state or AAA record
  getSections<T>(claimService: ClaimService, endpoint: string, sectionName?: string,
    loadingComponent?: LoadingComponent): Observable<T> {
    if (loadingComponent) {
      loadingComponent.setLoadingInProgress(100);
    }
    // return claimService.init$.flatMap(isInit => {
    //   if (isInit) {
    //     let url: string = endpoint.replace('{ffy}', claimService.currentYear).replace('{state}', claimService.currentOrg);
    //     if (sectionName) {
    //       url = url.replace('{sectionName}', sectionName);
    //     }
    //     return this._http.get<T>(url, { withCredentials: true }).pipe(
    //       catchError(this.handleError<T>()
    //       ));
    //   } else {
    //     return Observable.throw('ClaimService failed during initialization');
    //   }
    // });

    // To do: Test this "flatMap" implementation works. This is an RxJs6 update
    return claimService.init$.pipe(
      flatMap(isInit => {
        if (isInit) {
          let url: string = endpoint.replace('{ffy}', claimService.currentYear).replace('{state}', claimService.currentOrg);
          if (sectionName) {
            url = url.replace('{sectionName}', sectionName);
          }
          return this._http.get<T>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<T>()
            ));
        } else {
          return Observable.throw('ClaimService failed during initialization');
        }
      })
    );
  }

  pollBatchLock(id: string, completedCallback: () => void = () => null): Observable<any> {

    return interval(5000).pipe(
      takeWhile(tick => tick <= 84), // Wait for 7 min
      exhaustMap(() => this._http.get(this._batchLock, {
        withCredentials: true,
        params: {
          id: id
        }
      })),
      takeWhile((lock) => lock !== null),
      finalize(() => completedCallback())
    );

  }

  getDataEntryErrors(ffy: string, id: string): Observable<Array<BasicErrorDetail>> {
    return this._http.post<Array<BasicErrorDetail>>(this._dataEntryErrors,
      { 'fiscalYear': ffy, 'granteeId': id }, { withCredentials: true }).pipe(
          catchError(this.handleError<Array<BasicErrorDetail>>())
      );
  }

  // Global
  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
