import { Component, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { CGSuppExpendSection } from './CGSuppExpendSection';
import { CommonService } from '../shared/common.service';
import { ComponentCanDeactivate } from '../shared/pending-changes.guard';
import { ToastrService } from '../shared/toastr.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIISectionComponent } from './title-iii-section.component';
import { ValidationService } from '../shared/validation.service';

@Component({
  templateUrl: 'titleIIICGSuppExpend.component.html',
})

export class TitleIIICGSuppExpendComponent
  extends TitleIIISectionComponent<CGSuppExpendSection> implements OnInit, OnDestroy, ComponentCanDeactivate {

  _sectionModel: CGSuppExpendSection;
  section: string;
  pageName: string;
  unsavedChanges: boolean = false;
  servicesProvidedSubsection: string; // Needed for pulling services provided sections for summing here
  questionStatusEnum = QuestionStatusEnum;

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _commonService: CommonService,
    public toastr: ToastrService,
    public _validation: ValidationService
  ) { super(new CGSuppExpendSection(), _commonService, _validation, router, _claimService, _service); }

  ngOnInit(): void {
    this._sectionModel = new CGSuppExpendSection();
    this.setSectionName();

    if (this._sectionModel.sectionName.includes('ORC')) {
      this.servicesProvidedSubsection = 'titleIIIORCSuppProvided';
      this.section = 'Older Relative Caregivers';
    } else if (this._sectionModel.sectionName.includes('COA')) {
      this.servicesProvidedSubsection = 'titleIIICOASuppProvided';
      this.section = 'Caregivers of Older Adults';
    } else {
      this.section = 'Older Adults';
    }

    if (this._sectionModel.sectionName.includes('Other')) {
      this.pageName = 'Other Services';
      this.servicesProvidedSubsection = 'titleIIIOtherServices';
    } else {
      this.pageName = 'Supplemental Services';
    }

    this._titleService.setTitle(this.section + ' - '
      + this.pageName + ' - Total People Served and Expenditures - Data Submissions - ACL OAAPS');

    this.getcgsuppexpendbyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    this._sectionModel.psa = this._claimService.currentSubOrg;
    this.setSectionName();
    super.registerSubscription(this._service.savecgsuppexpend(this._sectionModel).subscribe(data => {
      if (data) {
        this._sectionModel = data;
      }

      this.toastr.success('Saved!');
      callback();
    }));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    if (!this._claimService.isViewOnly && 
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(this.routeToNextSection.bind(this));
    } else {
      this.routeToNextSection();
    }
  }

  getcgsuppexpendbyffyandstate(): void {
    super.registerSubscription(this._service.get<CGSuppExpendSection[]>(this._claimService,
      this._service._getcgsuppexpendbyffyandstate, this.resolveSectionName())
      .subscribe(data => {
        if (data && data[0]) {
          this._sectionModel = data[0];
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
        }
      },
      (error)=> console.log(error),
      ()=> {
        {}
      }
      ));
  }

  updateTotals(): void {
    let grandTotal = 0;
    const expend = parseFloat(this.removeCommas(this._sectionModel.expend.elementValue));
    const expendOtherState = parseFloat(this.removeCommas(this._sectionModel.expendOtherState.elementValue));
    const expendOtherNonState = parseFloat(this.removeCommas(this._sectionModel.expendOtherNonState.elementValue));
    const programIncome = parseFloat(this.removeCommas(this._sectionModel.programIncome.elementValue));
    const totalServiceUnits = parseFloat(this.removeCommas(this._sectionModel.totalServiceUnits.elementValue));
    if (!isNaN(expend)) { grandTotal += expend; }
    if (!isNaN(expendOtherState)) { grandTotal += expendOtherState; }
    if (!isNaN(expendOtherNonState)) { grandTotal += expendOtherNonState; }
    if (!isNaN(programIncome)) { grandTotal += programIncome; }
    this._sectionModel.expendTotal.elementValue = this.addDecimals(grandTotal.toString());
    this._sectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(grandTotal, totalServiceUnits);
    this._sectionModel.totalExpendPerUnit.questionStatus = QuestionStatusEnum.L1InProgress;
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.totalServiceUnits.elementValue = this.addComma(this._sectionModel.totalServiceUnits.elementValue);
    this._sectionModel.expend.elementValue = this.addDecimals(this._sectionModel.expend.elementValue);
    this._sectionModel.expendOtherState.elementValue = this.addDecimals(this._sectionModel.expendOtherState.elementValue);
    this._sectionModel.expendOtherNonState.elementValue = this.addDecimals(this._sectionModel.expendOtherNonState.elementValue);
    this._sectionModel.programIncome.elementValue = this.addDecimals(this._sectionModel.programIncome.elementValue);
    this._sectionModel.expendTotal.elementValue = this.addDecimals(this._sectionModel.expendTotal.elementValue);
    this._sectionModel.totalExpendPerUnit.elementValue = this.addDecimals(this._sectionModel.totalExpendPerUnit.elementValue);
  }

  private routeToNextSection(): void {
    let routeToNext;
    switch (this._sectionModel.sectionName) {
      case 'titleIIIOtherExpend': {
        this.router.navigate(['/data-submissions/COAConsumerTps']);
        break;
      }
      case 'titleIIICOASuppExpend': {
        this.router.navigate(['/data-submissions/titleIIICOACaseMgmt']);
        break;
      }
      case 'titleIIIORCSuppExpend': {
        this.router.navigate(['/data-submissions/titleIIIORCCaseMgmt']);
        break;
      }
      default: {
        this.router.navigate(['/dashboard']);
        break;
      }
    }
    return routeToNext;
  }

  next(): void {
    switch (this._sectionModel.sectionName) {
      case 'titleIIIOtherExpend': {
        this.router.navigate(['/data-submissions/COAConsumerTps']);
        break;
      }
      case 'titleIIICOASuppExpend': {
        this.router.navigate(['/data-submissions/titleIIICOACaseMgmt']);
        break;
      }
      case 'titleIIIORCSuppExpend': {
        this.router.navigate(['/data-submissions/titleIIIORCCaseMgmt']);
        break;
      }
      default: {
        this.router.navigate(['/dashboard']);
        break;
      }
    }
}

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

}
