<header></header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <div class="col-md-6 pl-0">
        <h1>Data Submissions</h1>
      </div>

      <div class="col-md-3  col-md-push-3">
        <!-- .begin year picker dropdown -->
        <fiscalYearDropdown></fiscalYearDropdown>
        <!-- .end year picker dropdown -->
      </div>
      <!-- .end col-md-3 -->
      <div class="col-md-9">
        <h2>Data Submission Responsibility</h2>

        <p *ngIf="!_claimService.isAAAUser" class="info mt-1">All fields are required</p>
        <loading #loadingSpinner=""></loading>
        <p *ngIf="!_claimService.isAAAUser&& _sectionModel.status == statusEnum.InProgress">Please select who is
          responsible for submission of
          each section.
        </p>
        <p *ngIf="!_claimService.isAAAUser && _sectionModel.status == statusEnum.ReadyForDataEntry">If any changes
          need to be made to the data submission responsibility you must clear selections and make necessary
          updates. <br /> <br />
          <strong>Note: When clearing selections, any AAA or state data reported for the current year will be deleted
          and any data already entered/uploaded for the current year must be re-entered/re-uploaded.</strong>
        </p>
        <p *ngIf="_claimService.isAAAUser">
          The data submission responsibility is defined by the State Unit on Aging. If any changes need to be made,
          please contact your State Unit on Aging representative prior to entering any data for the current reporting
          period.<br /><br />
          All data sections identified as the responsibility of the AAA must be entered, validated and (where
          applicable) explanations provided in order to be able to submit to the state.
        </p>
        <p> Note: Aging Network Profile may be submitted by
          both State and AAAs. All values entered will be merged during
          roll-up.
        </p>
        <div *ngIf="_sectionModel.status == statusEnum.LockedInDelete" class="alert alert-info clock" role="alert">
          <div class="table-cell media-middle">
            <i class="fa fa-clock-o" aria-hidden="true"></i>
          </div>
          <div class="table-cell pt-10px">
            <p>Previously saved Data Submission Responsibility is being cleared. This may take a couple of minutes.</p>
            <p>Please check back or refresh the screen to make new Data Submission Responsibility selections.</p>
          </div>
        </div>
        <div *ngIf="_sectionModel.status == statusEnum.LockedInSave" class="alert alert-info clock" role="alert">
          <p>
            <i class="fa fa-clock-o" aria-hidden="true"></i>Data Submission Responsibility selections are being saved.
            This may take a couple of minutes.
          </p>
        </div>
        <form #form="ngForm">
          <div class="col-md-9">
          <div class="table-responsive centerRadio">
            <table class="table">
              <tr class="background-gray-lighter">
                <th scope="col">Data Group</th>
                <th scope="col">State</th>
                <th scope="col">AAA</th>
                <th scope="col">State & AAA</th>
              </tr>
              <tr>
                <td>Aging Network Profile</td>
                <td>
                  <label class="radio-inline" for="stateAN">
                    <input [(ngModel)]="_sectionModel.agingNetworkResponsibility.userGroup" type="radio" name="agingNetworkResponsibility"
                           id="stateAN" [value]="stateGroup" [disabled]="_sectionModel.status != statusEnum.InProgress || this._claimService.isAAAUser">
                    <span class="sr-only">Aging Network Profile State</span>
                  </label>
                </td>
                <td>
                  <label class="radio-inline" for="aaaAN">
                    <input [(ngModel)]="_sectionModel.agingNetworkResponsibility.userGroup" type="radio" name="agingNetworkResponsibility"
                           id="aaaAN" [value]="aaaGroup" [disabled]="_sectionModel.status != statusEnum.InProgress || this._claimService.isAAAUser">
                    <span class="sr-only">Aging Network Profile AAA</span>
                  </label>
                </td>
                <td>
                  <label class="radio-inline" for="stateAaaAN">
                    <input [(ngModel)]="_sectionModel.agingNetworkResponsibility.userGroup" type="radio" name="agingNetworkResponsibility"
                           id="stateAaaAN" [value]="stateAAAGroup" [disabled]="_sectionModel.status != statusEnum.InProgress || this._claimService.isAAAUser">
                    <span class="sr-only">Aging Network Profile State & AAA</span>
                  </label>
                </td>
              </tr>
              <!-- Service Units -->
              <tr>
                <td>Consumer Characteristics and Service Units (Including NSIP)</td>
                <td>
                  <label class="radio-inline" for="stateServiceUnits">
                    <input [(ngModel)]="_sectionModel.serviceUnitsResponsibility.userGroup" type="radio" name="serviceUnitsResponsibility"
                           id="stateServiceUnits" [value]="stateGroup" [disabled]="_sectionModel.status != statusEnum.InProgress || this._claimService.isAAAUser">
                    <span class="sr-only">Consumer Characteristics and Service Units (Including NSIP) State</span>
                  </label>
                </td>
                <td>
                  <label class="radio-inline" for="aaaServiceUnits">
                    <input [(ngModel)]="_sectionModel.serviceUnitsResponsibility.userGroup" type="radio" name="serviceUnitsResponsibility"
                           id="aaaServiceUnits" [value]="aaaGroup" [disabled]="_sectionModel.status != statusEnum.InProgress || this._claimService.isAAAUser">
                    <span class="sr-only">Consumer Characteristics and Service Units (Including NSIP) AAA</span>
                  </label>
                </td>
                <td></td>
              </tr>
              <!--Expenditures-->
              <tr>
                <td>Service Expenditure</td>
                <td>
                  <label class="radio-inline" for="stateExpenditure">
                    <input [(ngModel)]="_sectionModel.expenditureResponsibility.userGroup" type="radio" name="expenditureResponsibility"
                           id="stateExpenditure" [value]="stateGroup" [disabled]="_sectionModel.status != statusEnum.InProgress || this._claimService.isAAAUser">
                    <span class="sr-only">Service Expenditure State</span>
                  </label>
                </td>
                <td>
                  <label class="radio-inline" for="aaaExpenditure">
                    <input [(ngModel)]="_sectionModel.expenditureResponsibility.userGroup" type="radio" name="expenditureResponsibility"
                           id="aaaExpenditure" [value]="aaaGroup" [disabled]="_sectionModel.status != statusEnum.InProgress || this._claimService.isAAAUser">
                    <span class="sr-only">Service Expenditure AAA</span>
                  </label>
                </td>
                <td></td>
              </tr>

              <!-- Legal Assistance case level data -->
              <tr>
                <td>Legal Assistance: Levels of Service and Types of Cases</td>
                <td>
                  <label class="radio-inline" for="stateLegalAssistance">
                    <input [(ngModel)]="_sectionModel.legalAssistanceResponsibility.userGroup" type="radio" name="legalAssistanceResponsibility"
                           id="stateLegalAssistance" [value]="stateGroup" [disabled]="_sectionModel.status != statusEnum.InProgress || this._claimService.isAAAUser">
                    <span class="sr-only">Legal Assistance: Levels of Service and Types of Cases State</span>
                  </label>
                </td>
                <td>
                  <label class="radio-inline" for="aaaLegalAssistance">
                    <input [(ngModel)]="_sectionModel.legalAssistanceResponsibility.userGroup" type="radio" name="legalAssistanceResponsibility"
                           id="aaaLegalAssistance" [value]="aaaGroup" [disabled]="_sectionModel.status != statusEnum.InProgress || this._claimService.isAAAUser">
                    <span class="sr-only">Legal Assistance: Levels of Service and Types of Cases AAA</span>
                  </label>
                </td>
                <td></td>
              </tr>
              <!-- Supplemental Services for Caregivers of Older Adults -->
              <tr>
                <td>Other Services for Older Adults
                  <i class="fa fa-info-circle blue-tooltip mr-1" data-toggle="tooltip" data-html="true" data-placement="top"
                    [title]="otherServicesTip" tabindex="0">
                    <span class="sr-only">{{otherServicesTip}}</span></i>
                  <br /> and Supplemental Services for Caregiver programs
                  <i class="fa fa-info-circle blue-tooltip mr-1" data-toggle="tooltip" data-html="true" data-placement="top"
                    [title]="supplementalServicesTip" tabindex="0">
                    <span class="sr-only">{{supplementalServicesTip}}</span></i>
                </td>
                <td>
                  <label class="radio-inline" for="stateOCSS">
                    <input [(ngModel)]="_sectionModel.caregiverSupplementalServices.userGroup" type="radio" name="caregiverSupplementalServices"
                           id="stateOCSS" [value]="stateGroup" [disabled]="_sectionModel.status != statusEnum.InProgress || this._claimService.isAAAUser">
                    <span class="sr-only">Other Services for Older Adults and Supplemental Services for Caregiver programs State</span>
                  </label>
                </td>
                <td>
                  <label class="radio-inline" for="aaaOCSS">
                    <input [(ngModel)]="_sectionModel.caregiverSupplementalServices.userGroup" type="radio" name="caregiverSupplementalServices"
                           id="aaaOCSS" [value]="aaaGroup" [disabled]="_sectionModel.status != statusEnum.InProgress || this._claimService.isAAAUser">
                    <span class="sr-only">Other Services for Older Adults and Supplemental Services for Caregiver programs AAA</span>
                  </label>
                </td>
                <td></td>
              </tr>
            </table>
          </div>
          </div>
        </form>
        <div *ngIf="!this._claimService.isAAAUser" class="row col-md-12">
          <button [disabled]="_sectionModel.status != statusEnum.InProgress && _sectionModel.status != statusEnum.LockedInDelete" class="btn btn-primary" (click)="save()">Save</button>
          <button [disabled]="_sectionModel.status != statusEnum.ReadyForDataEntry && _sectionModel.status != statusEnum.LockedInSave" class="btn btn-primary ml-1" (click)="unlock()">Clear
            selections</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIDashboardAAAStates']">Return to Data Submissions</a>
          <!-- <button data-target="#dsrModal" data-toggle="modal" class="btn btn-primary" [disabled]="_sectionModel.status != statusEnum.InProgress && _sectionModel.status != statusEnum.LockedInDelete">Save</button> -->
        </div>
      </div>
      <!-- end col-md-9-->
      <div class="pull-right">
        <a *ngIf="this._claimService.isAAAUser" [routerLink]="['../../dashboard']" class="btn btn-primary">
          Go back to dashboard</a>
      </div>
      <div aria-labelledby="dsrModalLabel" class="modal fade" id="dsrModal" role="dialog" tabindex="-1" style="display: none;">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                <span aria-hidden="true">×</span>
              </button>
              <h3 class="modal-title" id="dsrModalLabel">
                Data Submission Responsibilty settings are final
              </h3>
            </div>
            <div class="modal-body">
              <p>
                After data submission has begun by any state or AAA user, no changes can be made to the Data Submission Responsibility for the remainder of the Reporting Period.
                <br />
                <br />
                Please ensure your Data Submission Responsibility settings are correct before you continue.
              </p>
            </div>
            <div class="modal-footer">
              <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Return to review DSR</button>
              <button class="btn btn-primary pull-right" data-dismiss="modal" type="button" (click)="save()">The chosen settings are correct</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
