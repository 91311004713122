import {
    Component, OnInit, NgZone, ViewChild,
    OnDestroy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { LoadingComponent } from '../shared/loading.component';
import { ClaimService } from '../shared/claim.service';
import { CommonService } from '../shared/common.service';
import { TIIIOverview } from '../shared/section';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { TIIIDataSubmissionsFilter, StatusItemCheckbox } from '../filter/dataSubmissionsFilter';
import * as _ from 'lodash-es';
import { DestructibleComponent } from '../destructible.component';
import { IStateServiceFlags, FfyStatesDto } from './Dto/FfyStatesDto';
import { exhaustMap, finalize } from 'rxjs/operators';

declare var jQuery: any;

@Component({
    templateUrl: 'titleIIIAAADataSubmissions.component.html',
    styles: [
        `.modal-header > h1 { text-transform: none; color: black; font-size: 20px;
            font-weight: bold; border-bottom-width: 0px; padding-bottom: 0px; }`,
        '.modal-body > .table { margin-bottom: 0px; }',
        '.modal-body > .table > tbody > tr > td { border-top: 0px; width: 70%; }',
        '.modal-body > .table > tbody > tr > th { border-top: 0px; width: 30% }'
    ]
})

export class TitleIIIAAADataSubmissionsComponent extends DestructibleComponent implements OnInit, OnDestroy {

    overviews: TIIIOverview[] = [];
    rowsOnPage: number = 10;
    sortBy: string = 'psa';
    sortOrder: string = 'asc';
    statusEnum = QuestionStatusEnum;
    yearArrayBasic: Array<string> = [];
    submittedAAAOverviews: TIIIOverview[] = [];
    action: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    sprAAAsSelected: boolean = false;
    nsipAAAsSelected: boolean = false;
    sprAAAs: TIIIOverview[] = [];
    nsipAAAs: TIIIOverview[] = [];
    isUserLocker = false;

    // Filters
    filterOptions: TIIIDataSubmissionsFilter;

    state: string = 'inactive';

    // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;

    constructor(public zone: NgZone,
        public route: ActivatedRoute,
        public router: Router,
        public _titleService: Title,
        public _service: TitleIIIDataEntryService,
        public _claimService: ClaimService,
        public _commonService: CommonService
    ) {
        super();
        this.filterOptions = new TIIIDataSubmissionsFilter();
        this.filterOptions.ffy = this._claimService.currentYear;
    }
    ngOnInit(): void {
        // this.loadingComponent.setLoadingInProgress(30);
        const org = this._claimService.currentOrg || localStorage.getItem('org'); //claimService is null on refresh but localStorage is not
        super.registerSubscription(this._service.getAAAOverviewByFfyAndState(this._claimService.currentYear, org)
            .subscribe(data => {
                this.overviews = data;
                
            },
                err => {}));
        this._titleService.setTitle('Data Submissions - ACL OAAPS');
        this.setFiscalYears();
        this.isUserLocker = this._claimService.isNavLocker;
    }

    ngOnDestroy(): void {
        super.destroy();
    }
    handleCheckEvent(e) {
        if (e.target.checked) {
            this._service.overviews.push(this.overviews.find(x => x.state === e.target.getAttribute('value')));
        } else {
            const index = this._service.overviews.findIndex(x => x.state === e.target.getAttribute('value'));
            if (index !== -1) {
                this._service.overviews.splice(index, 1);
            }
        }
    }
    // State link clicked
    routeToReviewForState(state: string) {
        const stateOverview = this.overviews.find(x => x.state === state);
        this._service.overviews = [];
        this._service.overviews.push(stateOverview);
        this.router.navigate(['/data-submissions/titleIIISSReview']);
    }
    routeToReviewForStates() {
        this.router.navigate(['/data-submissions/titleIIISSReview']);
    }
    // ACL should only see Not Started, In Progress, or > Returned
    resolveACLStatus(status: number): number {
        if (status > QuestionStatusEnum.NotStarted && status < QuestionStatusEnum.Returned) {
            return QuestionStatusEnum.L1InProgress;
        } else {
            return status;
        }
    }

    public setFiscalYears(): void {
        if (this._claimService.currentTitle === 'III') {
            super.registerSubscription(this._service.getActiveYearApprovalStatusByState(
                this._claimService.currentOrg, this._claimService.currentSubOrg)
                .subscribe(data => {
                    if (data.length > 0) {
                        this.yearArrayBasic = data.map(x => x.fiscalYear);
                    }
                }));
        }
    }
    getAAAData(psa: string, psaName: string) {
        this._claimService.currentSubOrg = psa;
        this._claimService.aaaName = psaName;
        this.router.navigate(['/data-submissions/titleIIISSReview'], {
            queryParams: {
                psa: psa
            }
        });
    }

    selectAllStatusNsip() {
        this.filterOptions.nsipStatus.forEach(item => item.isChecked = true);
    }

    deselectAllStatusNsip() {
        this.filterOptions.nsipStatus.forEach(item => item.isChecked = false);
    }

    selectAllStatusSpr() {
        this.filterOptions.sprStatus.forEach(item => item.isChecked = true);
    }

    deselectAllStatusSpr() {
        this.filterOptions.sprStatus.forEach(item => item.isChecked = false);
    }

    handleSprCheckEvent(e) {
        if (e.target.checked) {
            this.sprAAAs.push(this.submittedAAAOverviews.find(x => x.psaName === e.target.getAttribute('value')));
        } else {
            const index = this.sprAAAs.findIndex(x => x.psaName === e.target.getAttribute('value'));
            if (index !== -1) {
                this.sprAAAs.splice(index, 1);
            }
        }
    }

    handleNsipCheckEvent(e) {
        if (e.target.checked) {
            this.nsipAAAs.push(this.submittedAAAOverviews.find(x => x.psaName === e.target.getAttribute('value')));
        } else {
            const index = this.nsipAAAs.findIndex(x => x.psaName === e.target.getAttribute('value'));
            if (index !== -1) {
                this.nsipAAAs.splice(index, 1);
            }
        }
    }
    selectAllSprAAAs(e) {
        if (e.target.checked) {
            this.sprAAAs = [];
            this.submittedAAAOverviews.forEach((item) => {
                if (item.sprStatus === this.action) {
                    this.sprAAAs.push(Object.assign({}, item));
                }
            });
            this.sprAAAsSelected = this.sprAAAs.length > 0 ? true : false;
        } else {
            this.sprAAAs = [];
            this.sprAAAsSelected = false;
        }
    }

    selectAllNsipAAAs(e) {
        if (e.target.checked) {
            this.nsipAAAs = [];
            this.submittedAAAOverviews.forEach((item) => {
                if (item.nsipStatus === this.action) {
                    this.nsipAAAs.push(Object.assign({}, item));
                }
            });
            this.nsipAAAsSelected = this.nsipAAAs.length > 0 ? true : false;
        } else {
            this.nsipAAAs = [];
            this.nsipAAAsSelected = false;
        }
    }

    saveStatus(): void {
        this.sprAAAsSelected = false;
        // this.loadingComponent.setLoadingInProgress(30);

        const dataMap = this.sprAAAs.map(x => ({type: 'SPR', state: x.state, psa: x.psa})).concat(
            this.nsipAAAs.map(x => ({ type: 'NSIP', state: x.state, psa: x.psa })))
                .reduce((acc, cur) => {
                    const stateServiceFlags: IStateServiceFlags = acc.statesServiceFlags[cur.state+cur.psa]
                        || { state: cur.state, psa: cur.psa, spr: false, nsip: false };
                    stateServiceFlags.spr = stateServiceFlags.spr || cur.type === 'SPR';
                    stateServiceFlags.nsip = stateServiceFlags.nsip || cur.type === 'NSIP';
                    acc.statesServiceFlags[cur.state+cur.psa] = stateServiceFlags;
                    return acc;
                }, { fiscalYear: this._claimService.currentYear, statesServiceFlags: {} });

        let payload: FfyStatesDto = {
            fiscalYear: dataMap.fiscalYear,
            statesServiceFlags: Object.values(dataMap.statesServiceFlags)
        }

        this._service.batch(this.action, payload)
            .pipe(
                exhaustMap(() => this._service.getAAAOverviewByFfyAndState(this._claimService.currentYear, this._claimService.currentOrg)),
                finalize(() => {  this.sprAAAs = []; this.nsipAAAs = []; })
            )
            .subscribe(data => this.overviews = data);
    }

    showSubmittedAAAs(): void {
        if (this.action !== 0) {
            this.submittedAAAOverviews = this.filter().filter(x => x.sprStatus === this.action || x.nsipStatus === this.action);
            jQuery('#submittedAAAs').modal('show');
        }
    }

    dismissSubmittedAAAModal(): void {
        jQuery('#submittedAAAs').modal('hide');
    }

    filter() {
        let filtered = this.overviews.slice();
        // Statuses
        // InProgress SPR
        const aclInProgSpr = _.find(this.filterOptions.sprStatus, x => x.key === StatusItemCheckbox.AclInProgress);
        // Other SPR
        const otherStatusSpr = _.difference(this.filterOptions.sprStatus, [aclInProgSpr]);
        // InProgress NSIP
        const aclInProgNsip = _.find(this.filterOptions.nsipStatus, x => x.key === StatusItemCheckbox.AclInProgress);
        // Other NSIP
        const otherStatusNsip = _.difference(this.filterOptions.nsipStatus, [aclInProgNsip]);
        // SPR Status
        let filteredSpr = [];
        let showStatusesSpr = [];
        if (aclInProgSpr.isChecked) {
            showStatusesSpr.push(this._commonService.aclInProgressStatuses());
        }
        // Other statuses
        showStatusesSpr.push(_.filter(otherStatusSpr, x => x.isChecked).map(x => x.key));
        showStatusesSpr = _.flatten(showStatusesSpr);
        filteredSpr = _.filter(filtered, overview => showStatusesSpr.indexOf(overview.sprStatus) !== -1);

        // NSIP Status
        let filteredNsip = [];
        let showStatusesNsip = [];
        if (aclInProgNsip.isChecked) {
            showStatusesNsip.push(this._commonService.aclInProgressStatuses());
        }
        // Other statuses
        showStatusesNsip.push(_.filter(otherStatusNsip, x => x.isChecked).map(x => x.key));
        showStatusesNsip = _.flatten(showStatusesNsip);
        filteredNsip = _.filter(filtered, overview => showStatusesNsip.indexOf(overview.nsipStatus) !== -1);

        filtered = _.union(filteredSpr, filteredNsip);
        // Start date
        if (this._commonService.isValidDateString(this.filterOptions.startDate)) {
            filtered = _.filter(filtered, overview => {
                this._commonService.isAfter(new Date(overview.lastUpdatedTime), new Date(this.filterOptions.startDate))
            });
        }
        // End date
        if (this._commonService.isValidDateString(this.filterOptions.endDate)) {
            filtered = _.filter(filtered, overview => {
                this._commonService.isNotAfter(new Date(overview.lastUpdatedTime), new Date(this.filterOptions.endDate))
            });
        }
        return filtered;
    }
}
