import { Component, OnInit, NgZone, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Title } from '@angular/platform-browser';
import { ClaimService } from '../shared/claim.service';
import { CommonService } from '../shared/common.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { LoadingComponent } from '../shared/loading.component';
import { OAServiceAndExpenditureSummaryDto } from './OAServiceAndExpenditureSummaryDto';
import { ToastrService } from '../shared/toastr.service';
import { VarianceExplanation } from '../shared/varianceExplanation.model';
import { DestructibleComponent } from '../destructible.component';
import { skip } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash-es';
import { ModalButtonOption } from '../shared/Modal/modalButtonOption';
import { ModalComponent } from '../shared/Modal/modal.component';
declare var jQuery;

@Component({
  templateUrl: 'titleIIIVarianceExplanationsOA.component.html',
})

export class TitleIIIVarianceExplanationsOAComponent extends DestructibleComponent implements OnInit, OnDestroy {

  // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;
  @ViewChild('confirmModal', { static: true }) confirmModal: ModalComponent;
  previousYear: string = '';
  currentYear: string = '';
  loadedSections: number = 0;
  disableUserInputs: boolean = false;
  previousFfyOAServiceAndExpenditureSummaryDto: OAServiceAndExpenditureSummaryDto;
  currentFfyOAServiceAndExpenditureSummaryDto: OAServiceAndExpenditureSummaryDto;
  unsavedChanges: boolean = false;
  modalButtons: Array<ModalButtonOption> = [];
  printHandler: (e) => void;
  private accordionAnimations$: Subject<void> = new Subject<void>();
  lengthExceededArray: Array<string> = [];
  isValid: boolean = true;
  formatVariance: (value: string, isDecimal:  boolean) => string;

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _commonService: CommonService,
    public toastr: ToastrService
  ) { super(); _commonService.setSubOrg(router); }

  ngOnInit(): void {
    // this.loadingComponent.setLoadingInProgress(30);
    this._titleService.setTitle('Older Adults - Explanation of Variances - State Submissions - ACL OAAPS');
    this.buildModalButtons();
    this.currentYear = this._claimService.currentYear;
    this.previousYear = (Number(this._claimService.currentYear) - 1).toString();
    this.previousFfyOAServiceAndExpenditureSummaryDto = new OAServiceAndExpenditureSummaryDto();
    this.currentFfyOAServiceAndExpenditureSummaryDto = new OAServiceAndExpenditureSummaryDto();
    this.getoaserviceandexpendituresummarydtobyffyandstate();

    // Accordion accessibility
    this._commonService.setAccordionAccessibilityListener();

    // Print listener
    // this.printHandler = super.addPrintListener(() => null, this.afterPrint.bind(this));

    this.formatVariance = this._commonService.formatVariance;
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  getoaserviceandexpendituresummarydtobyffyandstate(): void {
    super.registerSubscription(
      this._service.get<OAServiceAndExpenditureSummaryDto[]>(this._claimService, this._service._getoavarianceexplanationsbyffyandstate)
        .subscribe(data => this.zone.run(() => {
          if (data.length > 0) {
            this.previousFfyOAServiceAndExpenditureSummaryDto = data[0];
          }
          if (data.length > 1) {
            this.currentFfyOAServiceAndExpenditureSummaryDto = data[1];
          }
          this.processSectionStatus();
          
        })));
  }

  processSectionStatus(): void {
    this.disableUserInputs = this._claimService.tiiiStatuses.sprStatus >= QuestionStatusEnum.Submitted;
    if (this.disableUserInputs || this._claimService.isViewOnly) {
      this._commonService.disableVarianceFormElements();
    }
  }

  formatNumber(currentValue: string): string {
    if (currentValue == null || currentValue === '') {
      return 'N/A';
    }
    currentValue = currentValue.toString().replace(/,/g, '');
    if (!isNaN(parseInt(currentValue))) {
      return parseInt(currentValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return 'N/A';
    }
  }

  formatCurrency(currentValue: string): string {
    if (currentValue == null || currentValue === '') {
      return 'N/A';
    }
    currentValue = currentValue.toString().replace(/,/g, '');
    if (!isNaN(parseInt(currentValue))) {
      return '$' + parseInt(currentValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return 'N/A';
    }
  }

  nationalVarianceThreshold(value: string): boolean {
    if (value == null || value === '') {
      return false;
    }
    value = value.toString().replace(/,/g, '');
    if (!isNaN(parseInt(value))) {
      if (Math.abs(parseInt(value)) >= 25) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // this.loadingComponent.setLoadingInProgress(180);
    super.registerSubscription(this._service.saveoavarianceexplanations(this.currentFfyOAServiceAndExpenditureSummaryDto)
      .subscribe(data => this.zone.run(() => {
        
        this.currentFfyOAServiceAndExpenditureSummaryDto = data;
        if (this.currentFfyOAServiceAndExpenditureSummaryDto.confirmationPrompt) {
          this.confirmModal.show();
        } else {
          this.toastr.success('Saved!');
        }
      }),
        (error)=>console.log(error),
        ()=>{
          // Suppress callback for confirmationPrompt
          if (!this.currentFfyOAServiceAndExpenditureSummaryDto.confirmationPrompt) {
            callback();
          }
        }
      ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIIVarianceExplanationsCG']);
    if (!this._claimService.isViewOnly
      && this._claimService.tiiiStatuses.sprStatus < QuestionStatusEnum.Submitted) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.veLockedNav = false;
      return true;
    } else {
      this._commonService.veLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

  markAsCompleteCheckboxDisabled(veo: VarianceExplanation): boolean {
    if (this._claimService.isViewOnly || this._claimService.tiiiStatuses.sprStatus >= QuestionStatusEnum.Submitted) {
      return true;
    }
    if (!veo.isRequired) {
      veo.isUserFinished = true;
      return true;
    }
    if (!veo.explanation) {
      veo.isUserFinished = false;
    }
    if (veo.explanation) {
      return false;
    } else {
      return true;
    }
  }

  public get aaaExplanationsActive(): boolean {
    return !this._claimService.isACLUser();
  }

  buildModalButtons(): void {
    this.modalButtons = ModalButtonOption.createActionCancelOption('Continue and submit explanations', () => {
      this.currentFfyOAServiceAndExpenditureSummaryDto.shouldSubmit = true;
      this.save(() => this.router.navigate(['/data-submissions/titleIIIOverview']));
    });
  }


  // Print

  private onAccordionOpen(e: Event): void {
    this.accordionAnimations$.next();
    jQuery(e.target).off('shown.bs.collapse');
  }

  public print(): void {
    let accordions = jQuery('.panel-collapse');
    const openAccordions = jQuery('.panel-collapse.in');
    accordions = jQuery(_.difference(accordions, openAccordions));
    if (accordions.length > 0) {
      const sub = super.registerSubscription(this.accordionAnimations$.pipe(
        skip<void>(accordions.length - 1) // Fire on the last open event
      ).subscribe(() => { window.print(); sub.unsubscribe(); })
      );
      accordions.on('shown.bs.collapse', this.onAccordionOpen.bind(this));
      accordions.collapse('show');
    } else {
      window.print();
    }
  }

  checkValid(): void {
    this.isValid = true;

    this.lengthExceededArray = [
      this.currentFfyOAServiceAndExpenditureSummaryDto.consumerSummaryTotal.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.personalCare.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.homemaker.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.chore.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.homeDeliveredMeals.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.adultDayCare.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.caseManagement.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.assistedTransportation.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.congregateMeals.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.nutritionCounseling.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.legalAssistance.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.otherServices.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.transportation.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.nutritionEducation.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.informationAndAssistance.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.evidenceBasedHealth.varianceExplanation.explanation,
      this.currentFfyOAServiceAndExpenditureSummaryDto.nonEvidenceBasedHealth.varianceExplanation.explanation];

      for(let i of this.lengthExceededArray){
        if (i!=null && i.length > 3400){
          this.isValid = false;
        }
      }
  }

  calculateOutlineColor(length: number): string {
    if (length > 3400) {
      return 'has-error';
    }else {
      return '';
    }
  }

}
