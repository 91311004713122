import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { ANStaffAndVolunteersSection } from './ANStaffAndVolunteersSection';
import { QuestionStatusEnum, ValidationTypes } from '../shared/dataElement';
import { DataElement } from '../shared/dataElement';
import { ValidationService } from '../shared/validation.service';
import { ToastrService } from '../shared/toastr.service';
import { CommonService } from '../shared/common.service';
import { TitleIIISectionComponent } from './title-iii-section.component';
import { AddOnLarge } from '../shared/Model/addOnOptions';

@Component({
  templateUrl: 'titleIIIANStaffAndVolunteers.component.html',
})

export class TitleIIIANStaffAndVolunteersComponent extends TitleIIISectionComponent<ANStaffAndVolunteersSection> implements OnInit, OnDestroy {

  _sectionModel: ANStaffAndVolunteersSection;
  _uploadModels: Array<ANStaffAndVolunteersSection>;
  _batchIds: Array<string>;
  questionStatusEnum = QuestionStatusEnum;
  unsavedChanges: boolean = false;

  autoFillAAAFields: boolean = false;
  public addOnWidth: number = AddOnLarge;

  constructor(public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public toastr: ToastrService,
    public _commonService: CommonService
  ) {
    super(new ANStaffAndVolunteersSection(), _commonService, _validation, router, _claimService, _service);
  }

  ngOnInit(): void {
    this._titleService.setTitle('AN - Staff and Volunteers - Data Submissions - ACL OAAPS');
    this._sectionModel = new ANStaffAndVolunteersSection();
    this.getanstaffandvolunteersbyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    super.registerSubscription(this._service.saveanstaffandvolunteers(this._sectionModel)
      .subscribe(
        data => {
          this._sectionModel = data;
          this._claimService.tiiiStatuses.titleIIIANStaffAndVolunteers = data.sectionStatus;
          this.autoFillAAAFields = this._claimService.isNonAAAState;
          this.autoFillFields();
        },
        (error) => console.log(error),
        () => {
          this.toastr.success('Saved!');
          callback();
        }
      ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIIANServiceProviders']);

    if (!this._claimService.isViewOnly && 
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  getanstaffandvolunteersbyffyandstate(): void {
    super.registerSubscription(this._service.get<ANStaffAndVolunteersSection[]>(this._claimService,
      this._service._getanstaffandvolunteersbyffyandstate, this.resolveSectionName())
      .subscribe(data => {
        if (data.length > 0) {
          // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
          let tempSectionModel: ANStaffAndVolunteersSection = new ANStaffAndVolunteersSection();
          tempSectionModel = data.filter(element => element.batchId === '00000000-0000-0000-0000-000000000000')[0];
          if (tempSectionModel) {
            this._sectionModel = tempSectionModel;
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
      }
        this.autoFillAAAFields = this._claimService.isNonAAAState;
        this.autoFillFields();

      }));
  }

  autoFillFields(): void {
    if (this.autoFillAAAFields) {
      this._sectionModel.aaaFullTime.elementValue = 'N/A';
      this._sectionModel.aaaPartTime.elementValue = 'N/A';
      this._sectionModel.volunteers.elementValue = 'N/A';
      this._sectionModel.volunteerHours.elementValue = 'N/A';
      this._sectionModel.aaaFullTime.validationType = ValidationTypes.None;
      this._sectionModel.aaaPartTime.validationType = ValidationTypes.None;
      this._sectionModel.volunteers.validationType = ValidationTypes.None;
      this._sectionModel.volunteerHours.validationType = ValidationTypes.None;
    }
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.agingFullTime.elementValue = this.addComma(this._sectionModel.agingFullTime.elementValue);
    this._sectionModel.agingPartTime.elementValue = this.addComma(this._sectionModel.agingPartTime.elementValue);
    this._sectionModel.communityStaff.elementValue = this.addComma(this._sectionModel.communityStaff.elementValue);
    this._sectionModel.aaaFullTime.elementValue = this.addComma(this._sectionModel.aaaFullTime.elementValue);
    this._sectionModel.aaaPartTime.elementValue = this.addComma(this._sectionModel.aaaPartTime.elementValue);
    this._sectionModel.volunteers.elementValue = this.addComma(this._sectionModel.volunteers.elementValue);
    this._sectionModel.volunteerHours.elementValue = this.addComma(this._sectionModel.volunteerHours.elementValue);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.agingNetworkLockedNav = false;

      return true;
    } else {
      this._commonService.agingNetworkLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

}
