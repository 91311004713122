import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class CacheService {

    constructor() { }
    // Common cache
    // Consideration: consider moving to an application state pattern
    // Keys
    public readonly cacheGDNPre = 'gdn_';
    public readonly cacheAAAPre = 'aaa_';
    public readonly cacheIIIStatePre = 'iii.state.';
    public readonly cacheUserPre = 'user_';
    public cacheUpdated$: Subject<boolean> = new Subject<boolean>();
    private _cache: any = {};
    public putCacheItem(key: string, item: any): void {
        this._cache[key] = item;
        this.cacheUpdated$.next(true);
    }
    public putCacheItems(items: ICacheItem[]): void {
        if (items && items.length > 0) {
            items.forEach(item => this._cache[item.key] = item.value);
            this.cacheUpdated$.next(true);
        }
    }
    public getCacheItem(key: string): any {
        return this._cache[key];
    }
    public deleteCacheItem(key: string): void {
        this._cache[key] = null;
        this.cacheUpdated$.next(true);
    }
    public clearCache(): void {
        this._cache = {};
        this.cacheUpdated$.next(true);
    }
}

export interface ICacheItem {
    key: string;
    value: string;
}

export class CacheItem implements ICacheItem {
    public key: string;
    public value: string;

    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}
