import { ITitleState } from '../shared/iTitleState';

export class TitleIIIState implements ITitleState {
    
    public static ENABLED_AAA_STATES_CACHE_KEY = `${TitleIIIState.name}_ENABLED_AAA_STATES`;
    
    id: string;
    isAAAState: boolean;
    isEnabled: boolean;
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    region: string;
    type: string;
    links: string[];
    aaa: TitleIIIAAA[] = [];
}
export class TitleIIIAAA {
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    links: string[];
    psa: string;
}
