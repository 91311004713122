<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
    <div class="row">
        <div class="col-md-12 acl-container">
            <!-- left navigation -->
            <sideNav></sideNav>
            <!-- .end left navigation -->
            <div class="col-md-9">
                <h2>Aging Network Profile - Self-Direction</h2>
                <p class="info mt-1">All fields are required</p>
                <div class="col-md-12 background-gray-lighter mb-1">
                    <h2>Older Adults Served (Title III-B Funded)</h2>
                </div>
                <div class="col-md-9">
                    <label for="oaPersonsServed" class="normal">Total number of persons served</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputOaPersonsServed'" [de]="_sectionModel.oaPersonsServed"
                    (change)="onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.oaPersonsServed"></acl-input-error>
                </div>
                <div class="col-md-9"></div>
                <div class="col-md-3 mb-1"></div>
                <div class="col-md-9"></div>
                <div class="col-md-3 mb-1"></div>
                <div class="col-md-9">
                    <label for="oaExpend" class="normal">Total Title III expenditure</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputOaExpend'" [de]="_sectionModel.oaExpend"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.oaTotal.elementValue = updateTotal(oaArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.oaExpend"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="oaState" class="normal">Other: State expenditure</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputOaState'" [de]="_sectionModel.oaState"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.oaTotal.elementValue = updateTotal(oaArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.oaState"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="oaNonState" class="normal">Other: Non-state expenditure</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputOaNonState'" [de]="_sectionModel.oaNonState"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.oaTotal.elementValue = updateTotal(oaArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.oaNonState"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="oaProgramIncome" class="normal">Program income expended</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputOaProgramIncome'" [de]="_sectionModel.oaProgramIncome"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.oaTotal.elementValue = updateTotal(oaArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.oaProgramIncome"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="oaTotal" class="">Total Expenditures</label>
                </div>
                <div class="col-md-3 mb-1">
                    <div class="form-group">
                        <acl-data-element-text [id]="'inputoaTotal'" [de]="_sectionModel.oaTotal"
                        [disabled]=true  [addOnOptions]="addOnOption">
                       </acl-data-element-text>
                       <acl-input-error [de]="_sectionModel.oaTotal"></acl-input-error>
                    </div>
                </div>


                <div class="col-md-12 background-gray-lighter mb-1">
                    <h2>Caregivers of Older Adults (Title III-E Funded)</h2>
                </div>
                <div class="col-md-9">
                    <label for="coaPersonsServed" class="normal">Total number of persons served</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputCoaPersonsServed'" [de]="_sectionModel.coaPersonsServed"
                    (change)="onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.coaPersonsServed"></acl-input-error>
                </div>
                <div class="col-md-9"></div>
                <div class="col-md-3 mb-1"></div>
                <div class="col-md-9"></div>
                <div class="col-md-3 mb-1"></div>
                <div class="col-md-9">
                    <label for="coaExpend" class="normal">Total Title III expenditure</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputCoaExpend'" [de]="_sectionModel.coaExpend"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.coaTotal.elementValue = updateTotal(coaArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.coaExpend"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="coaState" class="normal">Other: State expenditure</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputCoaState'" [de]="_sectionModel.coaState"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.coaTotal.elementValue = updateTotal(coaArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.coaState"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="coaNonState" class="normal">Other: Non-state expenditure</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputCoaNonState'" [de]="_sectionModel.coaNonState"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.coaTotal.elementValue = updateTotal(coaArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.coaNonState"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="coaProgramIncome" class="normal">Program income expended</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputCoaProgramIncome'" [de]="_sectionModel.coaProgramIncome"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.coaTotal.elementValue = updateTotal(coaArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.coaProgramIncome"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="coaTotal" class="">Total Expenditures</label>
                </div>
                <div class="col-md-3 mb-1">
                    <div class="form-group">
                        <acl-data-element-text [id]="'inputCoaTotal'" [de]="_sectionModel.coaTotal"
                        [disabled]=true [addOnOptions]="addOnOption">
                       </acl-data-element-text>
                       <acl-input-error [de]="_sectionModel.coaTotal"></acl-input-error>
                    </div>
                </div>


                <div class="col-md-12 background-gray-lighter mb-1">
                    <h2>Older Relative Caregivers (Title III-E Funded)</h2>
                </div>
                <div class="col-md-9">
                    <label for="orcPersonsServed" class="normal">Total number of persons served</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputOrcPersonsServed'" [de]="_sectionModel.orcPersonsServed"
                    (change)="onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.orcPersonsServed"></acl-input-error>
                </div>
                <div class="col-md-9"></div>
                <div class="col-md-3 mb-1"></div>
                <div class="col-md-9"></div>
                <div class="col-md-3 mb-1"></div>
                <div class="col-md-9">
                    <label for="orcExpend" class="normal">Total Title III expenditure</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputorcExpend'" [de]="_sectionModel.orcExpend"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.orcTotal.elementValue = updateTotal(orcArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.orcExpend"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="orcState" class="normal">Other: State expenditure</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputorcState'" [de]="_sectionModel.orcState"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.orcTotal.elementValue = updateTotal(orcArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.orcState"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="orcNonState" class="normal">Other: Non-state expenditure</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputorcNonState'" [de]="_sectionModel.orcNonState"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.orcTotal.elementValue = updateTotal(orcArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.orcNonState"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="orcProgramIncome" class="normal">Program income expended</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputorcProgramIncome'" [de]="_sectionModel.orcProgramIncome"
                    [addOnOptions]="addOnOption" (change)="_sectionModel.orcTotal.elementValue = updateTotal(orcArr); onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.orcProgramIncome"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="orcTotal" class="">Total Expenditures</label>
                </div>
                <div class="col-md-3 mb-1">
                    <div class="form-group">
                        <acl-data-element-text [id]="'inputorcTotal'" [de]="_sectionModel.orcTotal"
                        [disabled]=true [addOnOptions]="addOnOption">
                       </acl-data-element-text>
                       <acl-input-error [de]="_sectionModel.orcTotal"></acl-input-error>
                    </div>
                </div>

                <div class="col-md-12 row">
                    <div *ngIf="!validForm" class="has-error">
                        <p class="help-block">There are errors on the page. You must fix the errors or clear the data in those fields in order
                            to Save this form.</p>
                    </div>
                    <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
                    <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()" >Save and next</button>
                    <button class="btn btn-default mr-1" (click)="save()" [disabled]="!validForm || !_sectionModel.isRequired">Save</button>
                    <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
                </div>
                <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1" style="display: none;">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h3 class="modal-title" id="nextModalLabel">Recent changes on this page will not be saved</h3>
                            </div>
                            <div class="modal-body">
                                <p>
                                    There are errors on the page. You must fix the errors or clear the data in those fields in order to save this form.
                                    <br />
                                    <br /> You may select "Go back to form" to remove the errors or you may select "Proceed to
                                    next section" and all of new the data entered in the current section will be lost.
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go back to form</button>
                                <button class="btn btn-primary pull-right" (click)="unsavedChanges = false;" data-dismiss="modal" type="button" [routerLink]="['/data-submissions/titleIIIANRespite']">Proceed to next section</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- .end col-md-9 -->
        </div>
        <!-- .end acl-container (col-md-12) -->
    </div>
    <!-- .end row -->
</div>
<!-- ./container -->
