import { QuestionStatusEnum } from "../shared/dataElement";
import { LastUpdated } from "../shared/Model/lastUpdated";
import { IOverview } from "../shared/Overview/overview";
import { IOverviewState } from "../shared/Overview/overviewState";
import { ISection, ITIIIOverview } from "../shared/section";
import { OverviewAclState } from "../TitleVI/overview/titleVIOverviewAclState";


export class TitleIIIOverviewStatePermissions {
    constructor(public isViewOnly: boolean = true
      , public isRollupOverview: boolean = false) { }
}

export class TitleIIIOverviewState implements IOverviewState {

    public get isInit() { return !!(this.overviewModel); }
    public get showUploadButton() { return false; }

    aaaState: OverviewAclState = new OverviewAclState();

    constructor(public permissions: TitleIIIOverviewStatePermissions,
        public overviewModel?: ITIIIOverview) {

        this.updateAAAStateUserStatus()
    }
    updateAAAStateUserStatus() {
        if(this.overviewModel) {
            switch (this.overallReportingStatus) {
                case (QuestionStatusEnum.NotStarted):
                  this.aaaState.editAlertText = 'AAA has not started working on this report yet. '
                    + 'You may enter data on behalf of the AAA.';
                  this.aaaState.editButtonText = 'Enter Data';
                  this.aaaState.editAlertStyle = 'alert-info';
                  this.aaaState.editModalBodyText = 'AAA has not started working on this report yet. '
                    + 'Are you sure you wish to start entering data for this report on behalf of the AAA?';
                  this.aaaState.editModalPositiveBtnText = 'Yes, enter AAA data';
                  break;
                case (QuestionStatusEnum.L1InProgressError):
                case (QuestionStatusEnum.L2InProgressError):
                case (QuestionStatusEnum.L1InProgress):
                case (QuestionStatusEnum.L2ValidatedWarning):
                case (QuestionStatusEnum.L2Validated):
                case (QuestionStatusEnum.L3NoExplanation):
                case (QuestionStatusEnum.L3Explanation):
                case (QuestionStatusEnum.NoSignificantVariance):
                  this.aaaState.editAlertText = 'AAA\'s report is currently in progress. '
                    + 'You may edit this data on behalf of the AAA.';
                  this.aaaState.editButtonText = 'Edit data';
                  this.aaaState.editAlertStyle = 'alert-info';
                  this.aaaState.editModalBodyText = 'This report is currently in progress. '
                    + 'Are you sure you wish to start editing this report on behalf of the AAA?';
                  this.aaaState.editModalPositiveBtnText = 'Yes, edit AAA data';
                  break;
                case (QuestionStatusEnum.Returned):
                  this.aaaState.editAlertText = 'AAA\'s data has been returned for corrections. '
                    + 'You may edit this data on behalf of the AAA.';
                  this.aaaState.editButtonText = 'Edit data';
                  this.aaaState.editAlertStyle = 'alert-danger';
                  this.aaaState.editModalBodyText = 'This report has been returned to the AAA for corrections. '
                    + 'Editing data will change the status of this report from \'Returned\' to \'In progress\'. '
                    + 'Are you sure you wish to start editing this report on behalf of the AAA?';
                  this.aaaState.editModalPositiveBtnText = 'Yes, edit AAA data';
                  break;
                case (QuestionStatusEnum.Submitted):
                  this.aaaState.editAlertText = 'AAA has submitted data for State review. '
                    + 'You may edit this data on behalf of the AAA or return the submission to the AAA for corrections.';
                  this.aaaState.editButtonText = 'Edit data';
                  this.aaaState.editAlertStyle = 'alert-success';
                  this.aaaState.editModalBodyText = 'This report has been submitted for State review. '
                    + 'Editing data will change the status of this report from \'Submitted\' to \'In Progress\'. '
                    + 'Are you sure you wish to start editing this report on behalf of the AAA?';
                  this.aaaState.editModalPositiveBtnText = 'Yes, edit AAA data';
                  break;
                case (QuestionStatusEnum.InReview):
                  this.aaaState.editAlertText = 'AAA data is in review by State. '
                    + 'You may edit this data on behalf of the AAA or return the submission to the AAA for corrections.';
                  this.aaaState.editButtonText = 'Edit data';
                  this.aaaState.editAlertStyle = 'alert-success';
                  this.aaaState.editModalBodyText = 'This report is in review by State. '
                    + 'Editing data will change the status of this report from \'In Review\' to \'In Progress\'. '
                    + 'Are you sure you wish to start editing this report on behalf of the AAA?';
                  this.aaaState.editModalPositiveBtnText = 'Yes, edit AAA data';
                  break;
                case (QuestionStatusEnum.Approved):
                  this.aaaState.editAlertText = 'This report has been approved. '
                    + 'You may change your review decision or update comments on the AAA Review page';
                  this.aaaState.editButtonText = 'Return to AAA Review';
                  this.aaaState.editAlertStyle = 'alert-success';
                  break;
              }
            }
    }

    getLastUpdated(name: string): LastUpdated {
        throw new Error("Method not implemented.");
    }
    getStatus(name: string): QuestionStatusEnum {
        throw new Error("Method not implemented.");
    }
    findOverview(name: string): IOverview {
        throw new Error("Method not implemented.");
    }
    getServiceSection(serviceSectionName: string): ISection {
        let serviceSection = this.overviewModel.sections.find(x => x.sectionName === serviceSectionName);
        if (!serviceSection) {
            // If not found by sectionName property try type property
            serviceSection = this.overviewModel.sections.find(x => x.type === serviceSectionName);
        }
        return serviceSection;
    }
    displayViewButton(serviceSectionName: string): boolean {
        const serviceSection = this.getServiceSection(serviceSectionName);
        if (serviceSection) {
            const shouldDisplay = serviceSection.isRequired
                && (this.permissions.isViewOnly
                    || serviceSection.sectionStatus >= QuestionStatusEnum.SubmittedNoExplanation);
            return shouldDisplay;
        }
        return false;
    }
    displayEnterDataAndUploadButtons(serviceSectionName: string): boolean {
        const serviceSection = this.getServiceSection(serviceSectionName);
        if (serviceSection) {
            const shouldDisplay = serviceSection.isRequired
                && !this.permissions.isViewOnly
                //&& this.permissions.isRollupOverview
                && serviceSection.sectionStatus < QuestionStatusEnum.SubmittedNoExplanation;
            return shouldDisplay;
        }
        return false;
    }
    displayEnterExplanationsButton(serviceSectionName: string, parentOverviewName: string): boolean { //parentOverviewName--required by interface
        const serviceSection = this.getServiceSection(serviceSectionName);
        if (serviceSection) {
            const shouldDisplay = serviceSection.isRequired
                && !this.permissions.isViewOnly
                && (serviceSection.sectionStatus === QuestionStatusEnum.L3NoExplanation
                    || serviceSection.sectionStatus === QuestionStatusEnum.SubmittedNoExplanation);
            return shouldDisplay;
        }
        return false;
    }
    displayUpdateExplanationsButton(serviceSectionName: string): boolean {
        const serviceSection = this.getServiceSection(serviceSectionName);
        if (serviceSection) {
            const shouldDisplay = serviceSection.isRequired
                && !this.permissions.isViewOnly
                && (serviceSection.sectionStatus === QuestionStatusEnum.L3Explanation);
            return shouldDisplay;
        }
        return false;
    }
    displayViewErrorsButton(serviceSectionName: string): boolean {
        const serviceSection = this.getServiceSection(serviceSectionName);
        if (serviceSection) {
            const shouldDisplay = serviceSection.isRequired
                && (serviceSection.sectionStatus === QuestionStatusEnum.L1InProgressError
                    || serviceSection.sectionStatus === QuestionStatusEnum.L2InProgressError);
            return shouldDisplay;
        }
        return false;
    }
    displayViewWarningsButton(serviceSectionName: string): boolean {
        const serviceSection = this.getServiceSection(serviceSectionName);
        if (serviceSection) {
            const shouldDisplay = serviceSection.isRequired
                && serviceSection.sectionStatus === QuestionStatusEnum.L2ValidatedWarning;
            return shouldDisplay;
        }
        return false;
    }
    displayValidateButton(serviceSectionName: string): boolean {
        const serviceSection = this.getServiceSection(serviceSectionName);
        if (serviceSection) {
            const shouldDisplay = serviceSection.isRequired
                && !this.permissions.isViewOnly
                && serviceSection.sectionStatus > QuestionStatusEnum.NotStarted
                && serviceSection.sectionStatus < QuestionStatusEnum.L2Validated
                // && this.canValidateRollup(); //Full rollup condition discontinued
            return shouldDisplay;
        }
        return false;
    }

    getEnterUpdateText(overviewName: string): string {
        const serviceSection = this.getServiceSection(overviewName);
        return serviceSection.sectionStatus > QuestionStatusEnum.NotStarted
          ? 'Update data'
          : 'Enter data';
    }

    public get overallReportingStatus(): QuestionStatusEnum {
      return this.overviewModel ? Math.min(this.overviewModel.sprStatus, this.overviewModel.nsipStatus) : QuestionStatusEnum.NotStarted;
  }
}

