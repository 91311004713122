import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { ANSelfDirectionSection } from './ANSelfDirectionSection';
import { ValidationService } from '../shared/validation.service';
import { ToastrService } from '../shared/toastr.service';
import { CommonService } from '../shared/common.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIISectionComponent } from './title-iii-section.component';
import { AddOnOptions } from '../shared/Model/addOnOptions';

@Component({
  templateUrl: 'titleIIIANSelfDirection.component.html',
})

export class TitleIIIANSelfDirectionComponent extends TitleIIISectionComponent<ANSelfDirectionSection> implements OnInit, OnDestroy {

  _sectionModel: ANSelfDirectionSection;
  _uploadModels: Array<ANSelfDirectionSection>;
  _batchIds: Array<string>;
  oaArr: Array<DataElement>;
  coaArr: Array<DataElement>;
  orcArr: Array<DataElement>;
  unsavedChanges: boolean = false;
  questionStatusEnum = QuestionStatusEnum;

  public get addOnOption(): AddOnOptions {
    return {
      text: '$',
      width: 20,
      isPrefix: true
    };
  }
  constructor(public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public toastr: ToastrService,
    public _commonService: CommonService
  ) {
    super(new ANSelfDirectionSection(), _commonService, _validation, router, _claimService, _service);
   }

  ngOnInit(): void {
    this._titleService.setTitle('AN - Self-Direction - Data Submissions - ACL OAAPS');
    this._sectionModel = new ANSelfDirectionSection();
    this.getanselfdirectionbyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    super.registerSubscription(this._service.saveanselfdirection(this._sectionModel)
      .subscribe(data => {
        this._sectionModel = data;
        this._claimService.tiiiStatuses.titleIIIANSelfDirection = data.sectionStatus;
        this.initArrays();

      },
        (error) => console.log(error),
        () => {
          this.toastr.success('Saved!');
          callback();
        }));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIIANRespite']);
    if (!this._claimService.isViewOnly && 
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  getanselfdirectionbyffyandstate(): void {
    super.registerSubscription(this._service.get<ANSelfDirectionSection[]>(this._claimService,
      this._service._getanselfdirectionbyffyandstate, this.resolveSectionName())
      .subscribe(data => {
        if (data.length > 0) {
          // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
          let tempSectionModel: ANSelfDirectionSection = new ANSelfDirectionSection();
          tempSectionModel = data.filter(element => element.batchId === '00000000-0000-0000-0000-000000000000')[0];
          if (tempSectionModel) {
            this._sectionModel = tempSectionModel;
            // initialize arrays for summing
            this.initArrays();
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
            // initialize arrays for summing
            this.initArrays();
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
      } else {
          this.initArrays();
        }

      }));
  }

  initArrays(): void {
    this.oaArr = [this._sectionModel.oaExpend,
    this._sectionModel.oaState,
    this._sectionModel.oaNonState,
    this._sectionModel.oaProgramIncome];

    this.coaArr = [this._sectionModel.coaExpend,
    this._sectionModel.coaState,
    this._sectionModel.coaNonState,
    this._sectionModel.coaProgramIncome];

    this.orcArr = [this._sectionModel.orcExpend,
    this._sectionModel.orcState,
    this._sectionModel.orcNonState,
    this._sectionModel.orcProgramIncome];
  }

  updateTotal(totalsArray: Array<DataElement>): string {
    let total: number = 0;

    for (let i = 0; i < totalsArray.length; i++) {
      // if not null, remove commas
      if (totalsArray[i].elementValue) {
        totalsArray[i].elementValue = totalsArray[i].elementValue.replace(/,/g, '');
      }
      // if is a number, sum it
      if (!isNaN(parseFloat(totalsArray[i].elementValue))) {
        total += parseFloat(totalsArray[i].elementValue);
      }
      // replace commas when done adding
      totalsArray[i].elementValue = this.addDecimals(totalsArray[i].elementValue);
    }

    // add comma and return
    return this.addDecimals(total.toString());
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.oaPersonsServed.elementValue = this.addComma(this._sectionModel.oaPersonsServed.elementValue);
    this._sectionModel.oaExpend.elementValue = this.addDecimals(this._sectionModel.oaExpend.elementValue);
    this._sectionModel.oaState.elementValue = this.addDecimals(this._sectionModel.oaState.elementValue);
    this._sectionModel.oaNonState.elementValue = this.addDecimals(this._sectionModel.oaNonState.elementValue);
    this._sectionModel.oaProgramIncome.elementValue = this.addDecimals(this._sectionModel.oaProgramIncome.elementValue);
    this._sectionModel.oaTotal.elementValue = this.addDecimals(this._sectionModel.oaTotal.elementValue);

    this._sectionModel.coaPersonsServed.elementValue = this.addComma(this._sectionModel.coaPersonsServed.elementValue);
    this._sectionModel.coaExpend.elementValue = this.addDecimals(this._sectionModel.coaExpend.elementValue);
    this._sectionModel.coaState.elementValue = this.addDecimals(this._sectionModel.coaState.elementValue);
    this._sectionModel.coaNonState.elementValue = this.addDecimals(this._sectionModel.coaNonState.elementValue);
    this._sectionModel.coaProgramIncome.elementValue = this.addDecimals(this._sectionModel.coaProgramIncome.elementValue);
    this._sectionModel.coaTotal.elementValue = this.addDecimals(this._sectionModel.coaTotal.elementValue);

    this._sectionModel.orcPersonsServed.elementValue = this.addComma(this._sectionModel.orcPersonsServed.elementValue);
    this._sectionModel.orcExpend.elementValue = this.addDecimals(this._sectionModel.orcExpend.elementValue);
    this._sectionModel.orcState.elementValue = this.addDecimals(this._sectionModel.orcState.elementValue);
    this._sectionModel.orcNonState.elementValue = this.addDecimals(this._sectionModel.orcNonState.elementValue);
    this._sectionModel.orcProgramIncome.elementValue = this.addDecimals(this._sectionModel.orcProgramIncome.elementValue);
    this._sectionModel.orcTotal.elementValue = this.addDecimals(this._sectionModel.orcTotal.elementValue);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.agingNetworkLockedNav = false;
      return true;
    } else {
      this._commonService.agingNetworkLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

}
