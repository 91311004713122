import { Component, OnInit, NgZone, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClaimService } from '../shared/claim.service';
import { TitleVIIDataEntryService } from './titleVIIDataEntry.service';
import { TitleIIIDataEntryService } from '../DataSubmissions/titleIIIDataEntry.service';
import { ReportingPeriod } from '../shared/reportingPeriod';
import { TitleVIDataEntryService } from '../TitleVI/titleVIDataEntry.service';
import { CommonService } from '../shared/common.service';
import { AclTitleEnum } from '../app.constants';
import { UserManagementStrings } from '../UserManagement/userManagerObjects';
import { DestructibleComponent } from '../destructible.component';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'fiscalYearDropdown',
    templateUrl: 'fiscalYearDropdown.component.html'
})

export class FiscalYearDropdownComponent extends DestructibleComponent implements OnInit, OnDestroy {

    yearArrayBasic: Array<string> = [];
    reportingPeriods: Array<ReportingPeriod> = [];
    userManagementStrings = UserManagementStrings;
    titleEnum = AclTitleEnum;

    @Output() yearChange = new EventEmitter<string>();

    constructor(public zone: NgZone,
        public _claimService: ClaimService,
        public route: ActivatedRoute,
        public router: Router,
        public _titleIIIService: TitleIIIDataEntryService,
        public _titleVIService: TitleVIDataEntryService,
        public _titleVIIService: TitleVIIDataEntryService,
        public _commonService: CommonService,
    ) { super(); }

    ngOnInit(): void {
        super.registerSubscription(this._claimService.init$.subscribe(data => { if (data) { this.init(); } }, err => console.log(err)));
    }

    ngOnDestroy(): void {
        super.destroy();
    }

    init(): void {
        this.setFiscalYears();
    }

    public setFiscalYears(): void {
        if (!this._claimService.isNavApprover) {
            if (this._claimService.currentTitle === this.titleEnum[this.titleEnum.III]) {
                super.registerSubscription(this._titleIIIService.getActiveYearApprovalStatusByState(
                    this._claimService.currentOrg, this._claimService.currentSubOrg)
                    .subscribe(data => {
                        if (data) {
                            this.yearArrayBasic = data.map(x => x.fiscalYear);
                        }
                    }));
            }
            if (this._claimService.currentTitle === this.titleEnum[this.titleEnum.VII]) {
                super.registerSubscription(this._titleVIIService.getActiveYearApprovalStatusByState(this._claimService.currentOrg)
                    .subscribe(data => {
                        if (data) {
                            this.yearArrayBasic = data.map(x => x.fiscalYear);
                        }
                    }));
            }
            if (this._claimService.currentTitle === this.titleEnum[this.titleEnum.VI]) {
                super.registerSubscription(
                    this._titleVIService.getActiveYearApprovalStatusByState(this._claimService.currentOrg)
                    .pipe(
                        finalize(() => {
                            if (!this.yearArrayBasic.includes(this._claimService.currentYear)) {
                                this.setInitialYear();
                            } else {
                                this.switchYearSoft(this._claimService.currentYear);
                            }
                        })
                    ).subscribe(data => {
                        if (data) {
                            this.yearArrayBasic = data.map(x => x.fiscalYear);
                            this.reportingPeriods = data.filter(item => item.type === 'TVIReportingPeriod');
                        }
                    })
                );
            }
        } else {
            if (this._claimService.currentTitle === this.titleEnum[this.titleEnum.III]) {
                super.registerSubscription(this._titleIIIService.getAllReportingPeriods("true")
                    .subscribe(data => {
                        if (data) {
                            this.yearArrayBasic = data.map(x => x.fiscalYear);
                        }
                    }));
            }
            if (this._claimService.currentTitle === this.titleEnum[this.titleEnum.VII]) {
                super.registerSubscription(this._titleVIIService.getAllReportingPeriods("true")
                    .subscribe(data => {
                        if (data) {
                            this.yearArrayBasic = data.map(x => x.fiscalYear);
                        }
                    }));
            }
            if (this._claimService.currentTitle === this.titleEnum[this.titleEnum.VI]) {
                super.registerSubscription(
                    this._titleVIService.getAllReportingPeriods()
                    .pipe(
                        finalize(() => {
                            if (!this.yearArrayBasic.includes(this._claimService.currentYear)) {
                                this.setInitialYear();
                            } else {
                                this.switchYearSoft(this._claimService.currentYear);
                            }
                        })
                    ).subscribe(data => {
                        if (data) {
                            this.yearArrayBasic = data.map(x => x.fiscalYear);
                            this.reportingPeriods = data.filter(item => item.type === 'TVIReportingPeriod');
                        }
                    })
                );
            }
        }
    }

    // No hard refresh
    public switchYearSoft(year: string): void {
        this._claimService.currentYear = year;
        sessionStorage.setItem('fiscalYear', year);
        this.yearChange.emit(year);
    }

    public switchYear(year: string): void {
        this._claimService.currentYear = year;
        sessionStorage.setItem('fiscalYear', year);
        if (this.router.url.includes('/state-submissions/titleVIIOverview')
            || this.router.url.includes('/data-submissions/titleIIIOverview')
            || this.router.url.includes('/titlevi/titleVIPPROverview')
            || this.router.url.includes('/dashboard')
            || this.router.url.includes('/data-submissions/dataSubmissionResponsibility')
            || this.router.url.includes('/data-submissions/titleIIIDashboardAAAStates')
            || this.router.url.includes('/titlevi/acldatasubmissionslist')) {
            location.reload();
        } else {
            if (this._claimService.currentTitle === this.titleEnum[this.titleEnum.VII]) {
                this.router.navigate(['/state-submissions/titleVIIOverview']);
            } else if (this._claimService.currentTitle === this.titleEnum[this.titleEnum.VI]) {
                this.router.navigate(['/titlevi/titleVIPPROverview']);
            } else {
                this.router.navigate(['/data-submissions/titleIIIOverview']);
            }
        }
        this.yearChange.emit(year);
    }

    private setInitialYear(): void {
        if (this.yearArrayBasic.length) {
            this.switchYearSoft(this.yearArrayBasic[0]);
        }
    }
}

