import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class HealthPromoNonEvidenceSection extends Section {

    peopleServed: IDataElement;
    title3Expend: IDataElement;
    otherStateExpend: IDataElement;
    nonStateExpend: IDataElement;
    programIncome: IDataElement;
    totalExpend: IDataElement;
    expendPerUnit: IDataElement;

    constructor() {
        super();

        this.peopleServed = new DataElement();
        this.title3Expend = new DataElement();
        this.otherStateExpend = new DataElement();
        this.nonStateExpend = new DataElement();
        this.programIncome = new DataElement();
        this.totalExpend = new DataElement();
        this.expendPerUnit = new DataElement();

    }
}
