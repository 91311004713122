<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <h2>{{section}} - Respite - Total People Served, Service Units and Expenditures</h2>

        <p class="info mt-1">All fields are required</p>

        <div class="col-md-12 background-gray-lighter mb-1">
          <h2>Total Respite</h2>
        </div>

        <table class="table table-no-border">
          <tbody>
            <tr *ngIf="totalWarning().length > 0">
              <td class="has-warning">
                <span class="help-block">
                  <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):
                </span>
                <p *ngFor="let warningItem of totalWarning()" class="help-block">{{warningItem}}</p>
              </td>
            </tr>
            <tr *ngIf="totalError().length > 0">
              <td class="has-error">
                <span class="help-block">
                  <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):
                </span>
                <p *ngFor="let errorItem of totalError()" class="help-block">{{errorItem}}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="col-md-9">
          <label id="totalRespiteTotalUnduplicatedLabel" for="totalRespiteTotalUnduplicated" class="normal">Total unduplicated persons served</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <input (change)="unsavedChanges = true; _sectionModel.totalRespiteTotalUnduplicated.elementValue = addComma(_sectionModel.totalRespiteTotalUnduplicated.elementValue); _sectionModel.totalRespiteTotalUnduplicated = validate(_sectionModel.totalRespiteTotalUnduplicated); checkValidForm()"
              [(ngModel)]="_sectionModel.totalRespiteTotalUnduplicated.elementValue" [disabled]="!_sectionModel.totalRespiteTotalUnduplicated.isRequired"
              type="text" class="form-control" id="totalRespiteTotalUnduplicated" aria-labelledby="totalRespiteTotalUnduplicatedLabel">
          </div>
        </div>
        <div class="col-md-9">
          <label id="totalRespiteTotalServiceLabel" for="totalRespiteTotalService" class="normal">Total service units (Hours)</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <input [(ngModel)]="_sectionModel.totalRespiteTotalService.elementValue" [disabled]="!_sectionModel.totalRespiteTotalService.isRequired"
                type="text" class="form-control" id="totalRespiteTotalService" aria-labelledby="totalRespiteTotalServiceLabel"
                readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label id="title3TotalLabel" for="title3Total" class="normal">Title III expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input [(ngModel)]="_sectionModel.title3Total.elementValue" [disabled]="!_sectionModel.title3Total.isRequired"
                type="text" class="form-control" id="title3Total" aria-labelledby="title3TotalLabel"
                readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label id="otherStateTotalLabel" for="otherStateTotal" class="normal">Other: State expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input [(ngModel)]="_sectionModel.otherStateTotal.elementValue" [disabled]="!_sectionModel.otherStateTotal.isRequired"
                type="text" class="form-control" id="otherStateTotal" aria-labelledby="otherStateTotalLabel"
                readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label id="otherNonStateTotalLabel" for="otherNonStateTotal" class="normal">Other: Non-state expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input [(ngModel)]="_sectionModel.otherNonStateTotal.elementValue" [disabled]="!_sectionModel.otherNonStateTotal.isRequired"
                type="text" class="form-control" id="otherNonStateTotal" aria-labelledby="otherNonStateTotalLabel"
                readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label id="programTotalLabel" for="programTotal" class="normal">Program income expended</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input [(ngModel)]="_sectionModel.programTotal.elementValue" [disabled]="!_sectionModel.programTotal.isRequired"
                type="text" class="form-control" id="programTotal" aria-labelledby="programTotalLabel"
                readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label id="totalExTotalLabel" for="totalExTotal" class="normal">Total expenditures</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input [(ngModel)]="_sectionModel.totalExTotal.elementValue" [disabled]="!_sectionModel.totalExTotal.isRequired"
                type="text" class="form-control" id="totalExTotal" aria-labelledby="totalExTotalLabel"
                readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label id="perPersonTotalLabel" for="perPersonTotal" class="normal">Total expenditure per hour</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input [(ngModel)]="_sectionModel.perPersonTotal.elementValue" [disabled]="!_sectionModel.perPersonTotal.isRequired"
                type="text" class="form-control" id="perPersonTotal" aria-labelledby="perPersonTotalLabel"
                readonly>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-1">
          <!-- error -->
          <i *ngIf="hasError()" aria-hidden="true" class="fa fa-exclamation-circle text-danger">
            <span class="sr-only">Error:</span>
          </i>
          <!-- warning -->
          <i *ngIf="hasWarning()" aria-hidden="true" class="fa fa-exclamation-triangle text-warning">
            <span class="sr-only">Warning:</span>
          </i>
          <a id="viewEnterExpendituresLnk" class="btn btn-link" data-target="#myModal" data-toggle="modal" href="javascript: void(0)">
            View / Enter Respite Expenditures
          </a>
        </div>

        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div class="modal-dialog modal-xlg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title" id="myModalLabel">Respite Expenditures</h4>
              </div>
              <div class="modal-body">

                <!-- errors -->
                <table *ngIf="expenditureErrors().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-error">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Error(s):
                        </span>
                        <p *ngFor="let ependitureErrorItem of expenditureErrors()" class="help-block">{{ependitureErrorItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- warnings -->
                <table *ngIf="expenditureErrors('warning').length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-warning">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warnings(s):
                        </span>
                        <p *ngFor="let ependitureErrorItem of expenditureErrors('warning')" class="help-block">{{ependitureErrorItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>


                <div class="table-responsive">
                  <table class="table table-no-border">
                    <caption class="sr-only">Respite Expenditures</caption>
                    <thead>
                      <tr class="background-gray-lighter">
                        <td></td>
                        <th id="inHomeRespite">In-Home Respite</th>
                        <th id="outOfHomeRespite">Out-of-Home Respite (day)</th>
                        <th id="outOfHomeRespitOvernight">Out-of-Home Respite (overnight)</th>
                        <th id="otherRespite">Other Respite</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th id="unduplicatedNoPerServedClusterOne" class="normal" width="40%">Total unduplicated
                          persons served</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.inhomeTotalUnduplicated.questionStatus)">
                            <input (change)="unsavedChanges = true; _sectionModel.inhomeTotalUnduplicated.elementValue = addComma(_sectionModel.inhomeTotalUnduplicated.elementValue); _sectionModel.inhomeTotalUnduplicated = validate(_sectionModel.inhomeTotalUnduplicated); checkValidForm()"
                              [(ngModel)]="_sectionModel.inhomeTotalUnduplicated.elementValue" [disabled]="!_sectionModel.inhomeTotalUnduplicated.isRequired"
                              type="text" class="form-control" id="inhomeTotalUnduplicated" aria-labelledby="inHomeRespite unduplicatedNoPerServedClusterOne">
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.outDayTotalUnduplicated.questionStatus)">
                            <input (change)="unsavedChanges = true; _sectionModel.outDayTotalUnduplicated.elementValue = addComma(_sectionModel.outDayTotalUnduplicated.elementValue); _sectionModel.outDayTotalUnduplicated = validate(_sectionModel.outDayTotalUnduplicated); checkValidForm()"
                              [(ngModel)]="_sectionModel.outDayTotalUnduplicated.elementValue" [disabled]="!_sectionModel.outDayTotalUnduplicated.isRequired"
                              type="text" class="form-control" id="outDayTotalUnduplicated" aria-labelledby="outOfHomeRespite unduplicatedNoPerServedClusterOne">
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.outNightTotalUnduplicated.questionStatus)">
                            <input (change)="unsavedChanges = true; _sectionModel.outNightTotalUnduplicated.elementValue = addComma(_sectionModel.outNightTotalUnduplicated.elementValue); _sectionModel.outNightTotalUnduplicated = validate(_sectionModel.outNightTotalUnduplicated); checkValidForm()"
                              [(ngModel)]="_sectionModel.outNightTotalUnduplicated.elementValue" [disabled]="!_sectionModel.outNightTotalUnduplicated.isRequired"
                              type="text" class="form-control" id="outNightTotalUnduplicated" aria-labelledby="outOfHomeRespitOvernight unduplicatedNoPerServedClusterOne">
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.unknownRespiteTotalUnduplicated.questionStatus)">
                            <input (change)="unsavedChanges = true; _sectionModel.unknownRespiteTotalUnduplicated.elementValue = addComma(_sectionModel.unknownRespiteTotalUnduplicated.elementValue); _sectionModel.unknownRespiteTotalUnduplicated = validate(_sectionModel.unknownRespiteTotalUnduplicated); checkValidForm()"
                              [(ngModel)]="_sectionModel.unknownRespiteTotalUnduplicated.elementValue" [disabled]="!_sectionModel.unknownRespiteTotalUnduplicated.isRequired"
                              type="text" class="form-control" id="unknownRespiteTotalUnduplicated" aria-labelledby="otherRespite unduplicatedNoPerServedClusterOne">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th id="totalServiceUnitsHours" class="normal">Total service units (Hours)</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.inhomeTotalTotalService.questionStatus)">
                            <input [(ngModel)]="_sectionModel.inhomeTotalTotalService.elementValue" [disabled]="!_sectionModel.inhomeTotalTotalService.isRequired"
                              type="text" class="form-control" id="inhomeTotalTotalService" aria-labelledby="inHomeRespite totalServiceUnitsHours"
                              (change)="unsavedChanges = true; _sectionModel.totalRespiteTotalService.elementValue = decimalToInt(updateTotals(serviceUnitsArr)); _sectionModel.inhomeTotalTotalService.elementValue = addComma(_sectionModel.inhomeTotalTotalService.elementValue); _sectionModel.inhomeTotalTotalService = validate(_sectionModel.inhomeTotalTotalService); checkValidForm(); updatePerPersonRow();">
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.outDayTotalService.questionStatus)">
                            <input [(ngModel)]="_sectionModel.outDayTotalService.elementValue" [disabled]="!_sectionModel.outDayTotalService.isRequired"
                              type="text" class="form-control" id="outDayTotalService" aria-labelledby="outOfHomeRespite totalServiceUnitsHours"
                              (change)="unsavedChanges = true; _sectionModel.totalRespiteTotalService.elementValue = decimalToInt(updateTotals(serviceUnitsArr)); _sectionModel.outDayTotalService.elementValue = addComma(_sectionModel.outDayTotalService.elementValue); _sectionModel.outDayTotalService = validate(_sectionModel.outDayTotalService); checkValidForm(); updatePerPersonRow();">
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.outNightTotalService.questionStatus)">
                            <input [(ngModel)]="_sectionModel.outNightTotalService.elementValue" [disabled]="!_sectionModel.outNightTotalService.isRequired"
                              type="text" class="form-control" id="outNightTotalService" aria-labelledby="outOfHomeRespitOvernight totalServiceUnitsHours"
                              (change)="unsavedChanges = true; _sectionModel.totalRespiteTotalService.elementValue = decimalToInt(updateTotals(serviceUnitsArr)); _sectionModel.outNightTotalService.elementValue = addComma(_sectionModel.outNightTotalService.elementValue); _sectionModel.outNightTotalService = validate(_sectionModel.outNightTotalService); checkValidForm(); updatePerPersonRow();">
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.unknownRespiteTotalService.questionStatus)">
                            <input [(ngModel)]="_sectionModel.unknownRespiteTotalService.elementValue" [disabled]="!_sectionModel.unknownRespiteTotalService.isRequired"
                              type="text" class="form-control" id="unknownRespiteTotalService" aria-labelledby="otherRespite totalServiceUnitsHours"
                              (change)="unsavedChanges = true; _sectionModel.totalRespiteTotalService.elementValue = decimalToInt(updateTotals(serviceUnitsArr)); _sectionModel.unknownRespiteTotalService.elementValue = addComma(_sectionModel.unknownRespiteTotalService.elementValue); _sectionModel.unknownRespiteTotalService = validate(_sectionModel.unknownRespiteTotalService); checkValidForm(); updatePerPersonRow();">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="titleIIIExpenditure">Title III expenditure</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.title3InHome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.title3InHome.elementValue" [disabled]="!_sectionModel.title3InHome.isRequired"
                                type="text" class="form-control" id="title3InHome" aria-labelledby="inHomeRespite titleIIIExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExInHome.elementValue = updateTotals(inHomeArr); _sectionModel.title3Total.elementValue = updateTotals(title3Arr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.title3InHome.elementValue = addDecimals(_sectionModel.title3InHome.elementValue); _sectionModel.title3InHome = validate(_sectionModel.title3InHome); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.title3OutDay.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.title3OutDay.elementValue" [disabled]="!_sectionModel.title3OutDay.isRequired"
                                type="text" class="form-control" id="title3OutDay" aria-labelledby="outOfHomeRespite titleIIIExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExOutDay.elementValue = updateTotals(outDayArr); _sectionModel.title3Total.elementValue = updateTotals(title3Arr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.title3OutDay.elementValue = addDecimals(_sectionModel.title3OutDay.elementValue); _sectionModel.title3OutDay = validate(_sectionModel.title3OutDay); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.title3OutNight.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.title3OutNight.elementValue" [disabled]="!_sectionModel.title3OutNight.isRequired"
                                type="text" class="form-control" id="title3OutNight" aria-labelledby="outOfHomeRespitOvernight titleIIIExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExOutNight.elementValue = updateTotals(outNightArr); _sectionModel.title3Total.elementValue = updateTotals(title3Arr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.title3OutNight.elementValue = addDecimals(_sectionModel.title3OutNight.elementValue); _sectionModel.title3OutNight = validate(_sectionModel.title3OutNight); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.title3Unknown.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.title3Unknown.elementValue" [disabled]="!_sectionModel.title3Unknown.isRequired"
                                type="text" class="form-control" id="title3Unknown" aria-labelledby="otherRespite titleIIIExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExUnknown.elementValue = updateTotals(unknownArr); _sectionModel.title3Total.elementValue = updateTotals(title3Arr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.title3Unknown.elementValue = addDecimals(_sectionModel.title3Unknown.elementValue); _sectionModel.title3Unknown = validate(_sectionModel.title3Unknown); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="otherStateExpenditure">Other: State expenditure</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.otherStateInHome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.otherStateInHome.elementValue" [disabled]="!_sectionModel.otherStateInHome.isRequired"
                                type="text" class="form-control" id="otherStateInHome" aria-labelledby="inHomeRespite otherStateExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExInHome.elementValue = updateTotals(inHomeArr); _sectionModel.otherStateTotal.elementValue = updateTotals(otherStateArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.otherStateInHome.elementValue = addDecimals(_sectionModel.otherStateInHome.elementValue); _sectionModel.otherStateInHome = validate(_sectionModel.otherStateInHome); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.otherStateOutDay.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.otherStateOutDay.elementValue" [disabled]="!_sectionModel.otherStateOutDay.isRequired"
                                type="text" class="form-control" id="otherStateOutDay" aria-labelledby="outOfHomeRespite otherStateExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExOutDay.elementValue = updateTotals(outDayArr); _sectionModel.otherStateTotal.elementValue = updateTotals(otherStateArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.otherStateOutDay.elementValue = addDecimals(_sectionModel.otherStateOutDay.elementValue); _sectionModel.otherStateOutDay = validate(_sectionModel.otherStateOutDay); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.otherStateOutNight.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.otherStateOutNight.elementValue" [disabled]="!_sectionModel.otherStateOutNight.isRequired"
                                type="text" class="form-control" id="otherStateOutNight" aria-labelledby="outOfHomeRespitOvernight otherStateExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExOutNight.elementValue = updateTotals(outNightArr); _sectionModel.otherStateTotal.elementValue = updateTotals(otherStateArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.otherStateOutNight.elementValue = addDecimals(_sectionModel.otherStateOutNight.elementValue); _sectionModel.otherStateOutNight = validate(_sectionModel.otherStateOutNight); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.otherStateUnknown.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.otherStateUnknown.elementValue" [disabled]="!_sectionModel.otherStateUnknown.isRequired"
                                type="text" class="form-control" id="otherStateUnknown" aria-labelledby="otherRespite otherStateExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExUnknown.elementValue = updateTotals(unknownArr); _sectionModel.otherStateTotal.elementValue = updateTotals(otherStateArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.otherStateUnknown.elementValue = addDecimals(_sectionModel.otherStateUnknown.elementValue); _sectionModel.otherStateUnknown = validate(_sectionModel.otherStateUnknown); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="otherNonStateExpenditure">Other: Non-state expenditure</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.otherNonStateInHome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.otherNonStateInHome.elementValue" [disabled]="!_sectionModel.otherNonStateInHome.isRequired"
                                type="text" class="form-control" id="otherNonStateInHome" aria-labelledby="inHomeRespite otherNonStateExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExInHome.elementValue = updateTotals(inHomeArr); _sectionModel.otherNonStateTotal.elementValue = updateTotals(otherNonStateArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.otherNonStateInHome.elementValue = addDecimals(_sectionModel.otherNonStateInHome.elementValue); _sectionModel.otherNonStateInHome = validate(_sectionModel.otherNonStateInHome); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.otherNonStateOutDay.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.otherNonStateOutDay.elementValue" [disabled]="!_sectionModel.otherNonStateOutDay.isRequired"
                                type="text" class="form-control" id="otherNonStateOutDay" aria-labelledby="outOfHomeRespite otherNonStateExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExOutDay.elementValue = updateTotals(outDayArr); _sectionModel.otherNonStateTotal.elementValue = updateTotals(otherNonStateArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.otherNonStateOutDay.elementValue = addDecimals(_sectionModel.otherNonStateOutDay.elementValue); _sectionModel.otherNonStateOutDay = validate(_sectionModel.otherNonStateOutDay); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.otherNonStateOutNight.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.otherNonStateOutNight.elementValue" [disabled]="!_sectionModel.otherNonStateOutNight.isRequired"
                                type="text" class="form-control" id="otherNonStateOutNight" aria-labelledby="outOfHomeRespitOvernight otherNonStateExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExOutNight.elementValue = updateTotals(outNightArr); _sectionModel.otherNonStateTotal.elementValue = updateTotals(otherNonStateArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.otherNonStateOutNight.elementValue = addDecimals(_sectionModel.otherNonStateOutNight.elementValue); _sectionModel.otherNonStateOutNight = validate(_sectionModel.otherNonStateOutNight); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.otherNonStateUnknown.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.otherNonStateUnknown.elementValue" [disabled]="!_sectionModel.otherNonStateUnknown.isRequired"
                                type="text" class="form-control" id="otherNonStateUnknown" aria-labelledby="otherRespite otherNonStateExpenditure"
                                (change)="unsavedChanges = true; _sectionModel.totalExUnknown.elementValue = updateTotals(unknownArr); _sectionModel.otherNonStateTotal.elementValue = updateTotals(otherNonStateArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.otherNonStateUnknown.elementValue = addDecimals(_sectionModel.otherNonStateUnknown.elementValue); _sectionModel.otherNonStateUnknown = validate(_sectionModel.otherNonStateUnknown); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="normal" id="programIncome">Program income expended</th>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.programInHome.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.programInHome.elementValue" [disabled]="!_sectionModel.programInHome.isRequired"
                                type="text" class="form-control" id="programInHome" aria-labelledby="inHomeRespite programIncome"
                                (change)="unsavedChanges = true; _sectionModel.totalExInHome.elementValue = updateTotals(inHomeArr); _sectionModel.programTotal.elementValue = updateTotals(programArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.programInHome.elementValue = addDecimals(_sectionModel.programInHome.elementValue); _sectionModel.programInHome = validate(_sectionModel.programInHome); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.programOutDay.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.programOutDay.elementValue" [disabled]="!_sectionModel.programOutDay.isRequired"
                                type="text" class="form-control" id="programOutDay" aria-labelledby="outOfHomeRespite programIncome"
                                (change)="unsavedChanges = true; _sectionModel.totalExOutDay.elementValue = updateTotals(outDayArr); _sectionModel.programTotal.elementValue = updateTotals(programArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.programOutDay.elementValue = addDecimals(_sectionModel.programOutDay.elementValue); _sectionModel.programOutDay = validate(_sectionModel.programOutDay); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div [ngClass]="calculateOutlineColor(_sectionModel.programOutNight.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.programOutNight.elementValue" [disabled]="!_sectionModel.programOutNight.isRequired"
                                type="text" class="form-control" id="programOutNight" aria-labelledby="outOfHomeRespitOvernight programIncome"
                                (change)="unsavedChanges = true; _sectionModel.totalExOutNight.elementValue = updateTotals(outNightArr); _sectionModel.programTotal.elementValue = updateTotals(programArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.programOutNight.elementValue = addDecimals(_sectionModel.programOutNight.elementValue); _sectionModel.programOutNight = validate(_sectionModel.programOutNight); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="calculateOutlineColor(_sectionModel.programUnknown.questionStatus)">
                            <div class="input-group">
                              <div class="input-group-addon">$</div>
                              <input [(ngModel)]="_sectionModel.programUnknown.elementValue" [disabled]="!_sectionModel.programUnknown.isRequired"
                                type="text" class="form-control" id="programUnknown" aria-labelledby="otherRespite programIncome"
                                (change)="unsavedChanges = true; _sectionModel.totalExUnknown.elementValue = updateTotals(unknownArr); _sectionModel.programTotal.elementValue = updateTotals(programArr); _sectionModel.totalExTotal.elementValue = updateTotals(respiteTotalsArr); _sectionModel.programUnknown.elementValue = addDecimals(_sectionModel.programUnknown.elementValue); _sectionModel.programUnknown = validate(_sectionModel.programUnknown); checkValidForm(); updatePerPersonRow();">
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th id="totalExpenditures">Total expenditures</th>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_sectionModel.totalExInHome.elementValue" [disabled]="!_sectionModel.totalExInHome.isRequired"
                              type="text" class="form-control" id="totalExInHome" aria-labelledby="inHomeRespite totalExpenditures"
                              readonly>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_sectionModel.totalExOutDay.elementValue" [disabled]="!_sectionModel.totalExOutDay.isRequired"
                              type="text" class="form-control" id="totalExOutDay" aria-labelledby="outOfHomeRespite totalExpenditures"
                              readonly>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_sectionModel.totalExOutNight.elementValue" [disabled]="!_sectionModel.totalExOutNight.isRequired"
                              type="text" class="form-control" id="totalExOutNight" aria-labelledby="outOfHomeRespitOvernight totalExpenditures"
                              readonly>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_sectionModel.totalExUnknown.elementValue" [disabled]="!_sectionModel.totalExUnknown.isRequired"
                              type="text" class="form-control" id="totalExUnknown" aria-labelledby="otherRespite totalExpenditures"
                              readonly>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="col-gap"></td>
                        <td class="background-gray-lighter"></td>
                        <td></td>
                        <td class="background-gray-lighter"></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th id="totalExpendituresPerPersonHours">Total expenditure per hour</th>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_sectionModel.perPersonInHome.elementValue" [disabled]="!_sectionModel.perPersonInHome.isRequired"
                              type="text" class="form-control" id="perPersonInHome" aria-labelledby="inHomeRespite totalExpendituresPerPersonHours"
                              readonly>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_sectionModel.perPersonOutDay.elementValue" [disabled]="!_sectionModel.perPersonOutDay.isRequired"
                              type="text" class="form-control" id="perPersonOutDay" aria-labelledby="outOfHomeRespite totalExpendituresPerPersonHours"
                              readonly>
                          </div>
                        </td>
                        <td class="background-gray-lighter">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_sectionModel.perPersonOutNight.elementValue" [disabled]="!_sectionModel.perPersonOutNight.isRequired"
                              type="text" class="form-control" id="perPersonOutNight" aria-labelledby="outOfHomeRespitOvernight totalExpendituresPerPersonHours"
                              readonly>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_sectionModel.perPersonUnknown.elementValue" [disabled]="!_sectionModel.perPersonUnknown.isRequired"
                              type="text" class="form-control" id="perPersonUnknown" aria-labelledby="otherRespite totalExpendituresPerPersonHours"
                              readonly>
                          </div>
                        </td>
                      </tr>
                    </tbody>

                  </table>

                </div>
                <!-- ./end table-responsive -->
              </div>
              <div class="modal-footer">
                <button id="respiteModalSaveChangesBtn" type="button" *ngIf="validForm" class="btn btn-primary"
                        data-dismiss="modal" (click)="save()">Save changes</button>
                <button id="respiteModalSaveChangesDisabledBtn" type="button" *ngIf="!validForm" class="btn btn-primary"
                        data-dismiss="modal" disabled>Save changes</button>
                <button id="respiteModalCloseBtn" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>


        <!--  end table responsive  -->

        <div class="col-md-12 row">
          <div *ngIf="!validForm" class="has-error">
            <p class="help-block">There are errors on the page. You must fix the errors or clear the data in those
              fields in order
              to Save this form.</p>
          </div>
          <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
          <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>
          <button class="btn btn-default mr-1" (click)="save()" [disabled]="!validForm || !_sectionModel.isRequired">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to
            Overview</a>
        </div>
        <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1" style="display: none;">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
                <h3 class="modal-title" id="nextModalLabel">Recent changes on this page will not be saved</h3>
              </div>
              <div class="modal-body">
                <p>
                  There are errors on the page. You must fix the errors or clear the data in those fields in order to
                  save this form.
                  <br />
                  <br /> You may select "Go back to form" to remove the errors or you may select "Proceed to
                  next section" and all of new the data entered in the current section will be lost.
                </p>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go back to form</button>
                <button class="btn btn-primary pull-right" *ngIf="_sectionModel.sectionName == 'titleIIICOARespite'"
                  (click)="unsavedChanges = false;" data-dismiss="modal" type="button" [routerLink]="['/data-submissions/titleIIICOASuppProvided']">Proceed
                  to next section</button>
                <button class="btn btn-primary pull-right" *ngIf="_sectionModel.sectionName == 'titleIIIORCRespite'"
                  (click)="unsavedChanges = false;" data-dismiss="modal" type="button" [routerLink]="['/data-submissions/titleIIIORCSuppProvided']">Proceed
                  to next section</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
