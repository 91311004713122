import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { OktaService } from '../auth/service/okta.service';

export type HttpError = {
    status: number;
    message: string;
};

@Injectable()
export class Http401Interceptor implements HttpInterceptor {
    constructor(private router: Router, private oktaService: OktaService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {
                // If 401 response, logout & redirect to start page
                this.oktaService.logout();
                this.router.navigate(['/welcome']);
            }
            const message = err.error && err.error.message
                ? err.error.message
                : 'Unknown error occurred';

            const error = {
                status: err.status,
                payload: err.error,
                message
            }
            
            return throwError(error);
        }))
    }
}