import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CardTypes } from '../../shared/Card/card/card.model';
import { ButtonCardModel } from '../../shared/Card/button-card/button-card.model';
import { Rollup, RollupStatus } from './rollup';
import { DestructibleComponent } from '../../destructible.component';

@Component({
  selector: 'acl-state-rollup',
  templateUrl: './state-rollup.component.html',
  styleUrls: ['./state-rollup.component.scss']
})
export class StateRollupComponent extends DestructibleComponent implements OnInit {
  public readonly title: string = 'State-Level Roll-Up';
  public readonly description: string = 'Create/view the state-level roll-up based on AAA data submissions and state entered data.';

  @Input() rollup: Rollup;
  @Input() canGenerateRollup: boolean;
  @Output() uploadStateRollup: EventEmitter<any> = new EventEmitter<any>();
  @Output() rollupModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() navAction: EventEmitter<any> = new EventEmitter<any>();

  constructor() { super(); }

  ngOnInit() {
    if (this.rollup == null) {
      throw new Error('Rollup is required for this component');
    }
  }

  public get cards(): ButtonCardModel[] {

    let desc1 = 'Generate new state-level roll-up that will include latest updates made to AAA or State entered data.';
    desc1 += '<br><strong>Warning: Previously provided variance explanations will be deleted.</strong>';

    let desc2 = 'Use the upload template to upload new state-level roll-up.';
    desc2 += '<br><strong>Warning: Previously provided variance explanations will be deleted.</strong>';

    const card1: ButtonCardModel = {
      title: '',
      description: desc1,
      buttonText: 'Generate new state-level roll-up',
      type: CardTypes.Button,
      disabled: !this.canGenerateRollup,
      action: () => this.rollupModal.emit({})
    };

    const card2: ButtonCardModel = {
      title: '',
      description: desc2,
      buttonText: 'Upload new state-level roll-up',
      type: CardTypes.Button,
      disabled: !this.canGenerateRollup,
      action: () => this.uploadStateRollup.emit({})
    };
    
    const card3: ButtonCardModel = {
      title: '',
      description: 'View or edit the latest generated state-level roll-up.',
      buttonText: 'View state-level roll-up',
      type: CardTypes.Button,
      disabled: this.rollup.status === RollupStatus.NotStarted,
      action: () => this.navAction.emit({})
    };


    return [card1, card2, card3];
  }
}
