<div class="acl-container">
<div class="container">
  <div class="col-md-9">
  <h1>About OAAPS Content</h1>
  <h2>The Older Americans Act Performance System (OAAPS)</h2>
  <p>ACL’s OAA Performance System collects information on Older Americans Act (OAA) Title III, VI, and VII programs. States, Area Agencies on Aging (AAA), and American Indian, Alaskan Native and Native Hawaiian organizations provide detailed information on OAA program participants, services, and expenditures annually by submitting comprehensive program reports. Access to data entry and analysis tools is restricted to authorized users. The information is reported annually and can be viewed by visiting the <a href="https://agid.acl.gov/" target="_blank" aria-label="AGID website open in new window">AGing, Independence, and Disability (AGID) Program Data Portal</a> website.</p>
  <p>For help using the OAAPS please look in the resources tab or contact: <a href="mailto:{{email}}">{{email}}</a></p>
  
  <h2>The Older Americans Act (OAA)</h2>
  <p>The Older Americans Act (OAA) authorizes a wide array of service programs through a national network of state agencies on aging, area agencies on aging, nearly 20,000 service providers, Tribal and Native organizations, and Native Hawaiian organizations. The Administration for Community Living/Administration on Aging’s core programs, authorized under the OAA, help older adults aged 60 and over remain at home for as long as possible, promote the rights of older individuals, and advocate for individuals who live in long-term care facilities (nursing homes, board and care, assisted living, and similar settings).</p>
  
  <h2>The Administration for Community Living (ACL)</h2>
  <p>The Administration for Community Living (ACL) works with states, localities, tribal organizations, nonprofit organizations, businesses, and families to help older adults and people with disabilities live independently, with equal opportunities to earn a living, go to school, choose where to live, and make decisions about their lives.  ACL’s programs provide services that directly support people with disabilities, older adults, families and caregivers to make this possible. In addition, ACL invests in research, innovation and education to improve the quality and availability of these services and advocates for older adults and people with disabilities. More information is available on the ACL website: <a href="http://www.ACL.GOV" target="_blank" aria-label="www.ACL.GOV open in new window">www.ACL.GOV</a>.</p>  
</div>
<!-- end col-md-9 -->
</div>
<!-- end container -->
</div>
<!-- end acl-container -->