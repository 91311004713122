export interface ITransactionCreatedResponse {
    tid: string;
}
export interface ITransaction {
    id: string;
    description: string;
    status: TransactionStatus;
    error: string;
    created: string
    createdBy: string;
    properties: {[key: string]: string};
}

export class DistributedTransaction implements ITransaction {
    id: string;
    description: string;
    status: TransactionStatus;
    error: string;
    created: string
    createdBy: string;
    properties: {[key: string]: string};
}

export enum TransactionStatus {
    Missing,
    InProgress,
    Failed,
    Completed,
    NoContent
}
