import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IGrantee, EnrollmentPeriod, SearchGranteeDto, GranteeDto, Grantee } from '../model/IGrantee';
import { ITitleOrg, TitleOrg } from '../../UserManagement/userManagerObjects';
import { GrantTypes } from '../model/IGrant';
import { TVIGranteeListingFilter } from '../../filter/granteeListingFilter';

@Injectable()
export class GranteeService {
    readonly ResourceGetGranteeDisplayNames = 'api/v1/titlevi/TitleVIGrantee/display';
    readonly GranteeApiRoot = 'api/v1/titlevi/TitleVIGrantee';
    readonly EnrollmentPeriodApiRoot = 'api/v1/titlevi/enrollmentperiod';

    constructor(public _http: HttpClient) {
    }

    private _granteeListingFilter: TVIGranteeListingFilter;
    public get granteeListingFilter(): TVIGranteeListingFilter {
        return this._granteeListingFilter;
    }
    public set granteeListingFilter(filter: TVIGranteeListingFilter) {
        this._granteeListingFilter = filter;
    }

    getGranteeDisplayItems(ids: string[]): Observable<Array<ITitleOrg>> {
        return this._http.post<Array<ITitleOrg>>(
            this.ResourceGetGranteeDisplayNames, ids || [], { withCredentials: true }).pipe(
                catchError(this.handleError<Array<TitleOrg>>())
            );
    }

    getActiveGrantees(ffy: string, ids: string[]): Observable<Array<ITitleOrg>> {
        const url = `${this.GranteeApiRoot}/activeGrantees/${ffy}`;
        return this._http.post<Array<ITitleOrg>>(url, ids || [], { withCredentials: true }).pipe(
            catchError(this.handleError<Array<TitleOrg>>())
        );
    }

    getGrantee(id: string): Observable<IGrantee> {
        const url = `${this.GranteeApiRoot}/${id}`;
        return this._http.get<IGrantee>(url, { withCredentials: true }).pipe(
            tap(this.buildFundingStatusMap),
            catchError(this.handleError<Grantee>())
        );
    }

    add(grantee: IGrantee): Observable<IGrantee> {
        const url = `${this.GranteeApiRoot}/add`;
        return this._http.post<IGrantee>(url, grantee, { withCredentials: true }).pipe(
            tap(this.buildFundingStatusMap),
            catchError(this.handleError<Grantee>())
        );
    }

    update(dto: GranteeDto): Observable<any> {
        const url = `${this.GranteeApiRoot}/update`;
        return this._http.post(url, dto, { withCredentials: true });
    }

    searchGrantee(dto: SearchGranteeDto): Observable<Array<IGrantee>> {
        const url = `${this.GranteeApiRoot}/search`;
        return this._http.post<Array<IGrantee>>(url, dto, { withCredentials: true }).pipe(
            tap(data => data.forEach(this.buildFundingStatusMap)),
            catchError(this.handleError<Array<Grantee>>())
        );
    }

    hasGranteeNumber(model: IGrantee): Observable<Array<IGrantee>> {
        const dto = new SearchGranteeDto();
        dto.number = model.number;
        dto.state = model.address.state;
        const url = `${this.GranteeApiRoot}/search`;
        return this._http.post<Array<IGrantee>>(url, dto, { withCredentials: true }).pipe(
            tap(data => data.forEach(this.buildFundingStatusMap)),
            catchError(this.handleError<Array<Grantee>>())
        );
    }

    // enrollment period

    getCurrentEnrolmentPeriod(): Observable<EnrollmentPeriod> {
        const url = `${this.EnrollmentPeriodApiRoot}/currentPeriod`;
        return this._http.get<EnrollmentPeriod>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<EnrollmentPeriod>())
        );
    }

    getAllEnrolmentPeriods(): Observable<Array<EnrollmentPeriod>> {
        const url = `${this.EnrollmentPeriodApiRoot}/allPeriods`;
        return this._http.get<Array<EnrollmentPeriod>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<EnrollmentPeriod>>())
        );
    }

    getActiveEnrolmentPeriods(): Observable<Array<EnrollmentPeriod>> {
        const url = `${this.EnrollmentPeriodApiRoot}/activePeriods`;
        return this._http.get<Array<EnrollmentPeriod>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<EnrollmentPeriod>>())
        );
    }

    private buildFundingStatusMap(grantee: IGrantee): void {
        if (grantee && grantee.grants) {
            grantee.fundingStatuses = grantee.grants.reduce((acc, cur) => {
                const fundingYear = cur.fiscalYear || cur.enrollmentYear;
                let fundingStatus = acc[fundingYear] || {
                    ffy: fundingYear,
                    enrollmentYear: cur.enrollmentYear,
                    partABFunding: false,
                    partCFunding: false
                };

                if (cur.isActive) {
                    if (cur.type === GrantTypes.AB) {
                        fundingStatus.partABFunding = true;
                    } else if (cur.type === GrantTypes.C) {
                        fundingStatus.partCFunding = true;
                    }
                }

                acc[fundingYear] = fundingStatus;
                return acc;
            }, {});
        } else {
            grantee.fundingStatuses = {};
        }
    }

    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
