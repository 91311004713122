import { TVIOverviewDto, ITVIOverview } from '../model/Overview';
import { QuestionStatusEnum } from '../../shared/dataElement';
import { LastUpdated } from '../../shared/Model/lastUpdated';
import { IOverviewState } from '../../shared/Overview/overviewState';
import { Section } from '../../shared/section';
import { OverviewAclState } from './titleVIOverviewAclState';
import { IOverview } from '../../shared/Overview/overview';

export class TitleVIOverviewStatePermissions {
  constructor(public isViewOnly: boolean = true
    , public isSubmitter: boolean = false
    , public isApprover: boolean = false) { }
}

export class TitleVIOverviewState implements IOverviewState {

  constructor(public permissions: TitleVIOverviewStatePermissions, private overviewDto?: TVIOverviewDto, private openPeriodDate?: Date) {
    if (!permissions.isViewOnly && overviewDto) {

      const minStatus = this.overviewDto.overview.minStatus;
      const sectionStatus = this.overviewDto.overview.sectionStatus;

      // Validate all button states
      this.isValidateEnabled = sectionStatus >= QuestionStatusEnum.L1InProgress
        && minStatus < QuestionStatusEnum.L2Validated;
      this.isValidateCompleted = this.overviewDto.overview.sectionStatus >= QuestionStatusEnum.L2Validated;
      this.isValidateDisabled = !this.isValidateCompleted && !this.isValidateEnabled;

      // Generate variance button states
      this.isVarianceEnabled = minStatus >= QuestionStatusEnum.L2ValidatedWarning
        && minStatus < QuestionStatusEnum.L3NoExplanation;
      this.isVarianceCompleted = minStatus > QuestionStatusEnum.SubmittedNoExplanation
        || minStatus === QuestionStatusEnum.NoSignificantVariance
        || minStatus === QuestionStatusEnum.L3Explanation;
      this.isVarianceDisabled = !this.isVarianceCompleted && !this.isVarianceEnabled;

      // Submit button states
      const today = new Date();
      this.isSubmitEnabled = permissions.isSubmitter
        && minStatus >= QuestionStatusEnum.L3Explanation
        && minStatus < QuestionStatusEnum.Returned
        && today >= (new Date(this.openPeriodDate));
      this.isSubmitCompleted = minStatus >= QuestionStatusEnum.Submitted;
      this.isSubmitDisabled = !permissions.isSubmitter || (!this.isSubmitCompleted && !this.isSubmitEnabled);

      // Review button states
      this.isReviewEnabled = permissions.isApprover
        && minStatus >= QuestionStatusEnum.L3Explanation
        && minStatus < QuestionStatusEnum.InReview
        && today >= (new Date(this.openPeriodDate));
      this.isReviewCompleted = minStatus >= QuestionStatusEnum.InReview;
      this.isReviewDisabled = !permissions.isApprover || (!this.isReviewCompleted && !this.isReviewEnabled);
    } else {
      this.isValidateDisabled = this.isVarianceDisabled = this.isSubmitDisabled = this.isReviewDisabled = true;
    }
    this.updateAclUserState();
  }

  // Is initialized with a dto
  public get isInit() { return !!(this.overviewDto); }

  public get showUploadButton() { return false; }

  /*
   * Top level button states
   */
  public isValidateDisabled: boolean = false;
  public isValidateEnabled: boolean = false;
  public isValidateCompleted: boolean = false;
  public isVarianceDisabled: boolean = false;
  public isVarianceEnabled: boolean = false;
  public isVarianceCompleted: boolean = false;
  public isSubmitDisabled: boolean = false;
  public isSubmitEnabled: boolean = false;
  public isSubmitCompleted: boolean = false;
  public isReviewDisabled: boolean = false;
  public isReviewEnabled: boolean = false;
  public isReviewCompleted: boolean = false;

  /*
   * Acl user details
   */
  public acl: OverviewAclState = new OverviewAclState();


  // Breadth first search
  public findOverview(name: string) {
    if (this.overviewDto) {
      const stack = this.overviewDto.overview.children.slice();
      while (stack.length > 0) {
        const overview = stack.pop();
        if (overview.sectionName === name) {
          return overview;
        } else if (overview.children.length > 0) {
          stack.push(...overview.children);
        }
      }
    }
    return null;
  }

  public getChildNames(overview: ITVIOverview, children: string[] = []): string[] {
    if (overview.children.length > 0) {
      overview.children.forEach(o => this.getChildNames(o, children));
    } else {
      children.push(overview.sectionName);
    }
    return children;
  }

  public getLastUpdated(name: string): LastUpdated {
    const lastUpdate = new LastUpdated();
    const overview = this.findOverview(name);
    if (overview) {
      lastUpdate.lastUpdateBy = overview.updatedBy;
      lastUpdate.lastUpdateTime = new Date(overview.lastModified);
    }
    return lastUpdate;
  }

  public getStatus(name: string): QuestionStatusEnum {
    let status = QuestionStatusEnum.NotStarted;
    const overview = this.findOverview(name);
    if (overview) {
      status = overview.sectionStatus;
    }
    return status;
  }

  public isInError(name: string): boolean {
    let error = false;
    const overview = this.findOverview(name);
    if (overview) {
      error = overview.minStatus === QuestionStatusEnum.L1InProgressError
        || overview.minStatus === QuestionStatusEnum.L2InProgressError;
    }
    return error;
  }

  public isInWarning(name: string): boolean {
    let warning = false;
    const overview = this.findOverview(name);
    if (overview && !this.isInError(name)) {
      warning = overview.minStatus === QuestionStatusEnum.L2ValidatedWarning
        || overview.children.some(o => o.minStatus === QuestionStatusEnum.L2ValidatedWarning);
    }
    return warning;
  }

  public updateOverviewStatus(section: Section) {
    this.findOverview(section.sectionName).sectionStatus = section.sectionStatus;
  }

  public displayViewButton(overviewName: string): boolean {
    const serviceSection = this.findOverview(overviewName);
    if (serviceSection) {
      const shouldDisplay = serviceSection.isRequired
        && (this.permissions.isViewOnly
          || serviceSection.sectionStatus >= QuestionStatusEnum.SubmittedNoExplanation);
      return shouldDisplay;
    }
    return false;
  }

  public displayEnterDataAndUploadButtons(overviewName: string): boolean {
    const serviceSection = this.findOverview(overviewName);
    if (serviceSection) {
      const shouldDisplay = serviceSection.isRequired
        && !this.permissions.isViewOnly
        && serviceSection.sectionStatus < QuestionStatusEnum.SubmittedNoExplanation;
      return shouldDisplay;
    }
    return false;
  }

  public getEnterUpdateText(overviewName: string): string {
    const serviceSection = this.findOverview(overviewName);
    return serviceSection.sectionStatus > QuestionStatusEnum.NotStarted
      ? 'Update data'
      : 'Enter data';
  }

  public displayEnterExplanationsButton(overviewName: string, parentOverviewName: string): boolean {
    const serviceSection = this.findOverview(overviewName);
    const overview = this.findOverview(parentOverviewName);
    if (serviceSection && overview) {
      const shouldDisplay = serviceSection.isRequired
        && !this.permissions.isViewOnly
        && this.shouldDisplayEnterVarianceButton(serviceSection, overview);
      return shouldDisplay;
    }
    return false;
  }

  public displayUpdateExplanationsButton(overviewName: string, parentOverviewName: string): boolean {
    const serviceSection = this.findOverview(overviewName);
    const overview = this.findOverview(parentOverviewName);
    if (serviceSection && overview) {
      const shouldDisplay = serviceSection.isRequired
        && !this.permissions.isViewOnly
        && this.shouldDisplayUpdateVarianceButton(serviceSection, overview);
      return shouldDisplay;
    }
    return false;
  }

  public displayViewErrorsButton(overviewName: string): boolean {
    const serviceSection = this.findOverview(overviewName);
    if (serviceSection) {
      const shouldDisplay = serviceSection.isRequired
        && (serviceSection.sectionStatus === QuestionStatusEnum.L1InProgressError
          || serviceSection.sectionStatus === QuestionStatusEnum.L2InProgressError);
      return shouldDisplay;
    }
    return false;
  }

  public displayViewWarningsButton(overviewName: string): boolean {
    const serviceSection = this.findOverview(overviewName);
    if (serviceSection) {
      const shouldDisplay = serviceSection.isRequired
        && serviceSection.sectionStatus === QuestionStatusEnum.L2ValidatedWarning;
      return shouldDisplay;
    }
    return false;
  }

  public displayValidateButton(overviewName: string): boolean {
    const serviceSection = this.findOverview(overviewName);
    if (serviceSection) {
      const shouldDisplay = serviceSection.isRequired
        && !this.permissions.isViewOnly
        && serviceSection.sectionStatus > QuestionStatusEnum.NotStarted
        && serviceSection.sectionStatus < QuestionStatusEnum.L2Validated;
      return shouldDisplay;
    }
    return false;
  }

  public displayViewAllErrorsAndWarningsBox(children: ITVIOverview[] = []): boolean {
    let shouldDisplay: boolean = false;
    for (const child of children) {
      for (const grandchild of child.children) {
        if (grandchild.sectionStatus === QuestionStatusEnum.L2InProgressError
          || grandchild.sectionStatus === QuestionStatusEnum.L2ValidatedWarning) {
          shouldDisplay = true;
        }
      }
    }
    return shouldDisplay;
  }

  private shouldDisplayEnterVarianceButton(service: IOverview, overview: IOverview): boolean {
    return service.sectionStatus === QuestionStatusEnum.L3NoExplanation &&
      (overview.sectionStatus === QuestionStatusEnum.L3NoExplanation
        || overview.sectionStatus === QuestionStatusEnum.L3Explanation);
  }

  private shouldDisplayUpdateVarianceButton(service: IOverview, overview: IOverview): boolean {
    return service.sectionStatus === QuestionStatusEnum.L3Explanation &&
      (overview.sectionStatus === QuestionStatusEnum.L3NoExplanation
        || overview.sectionStatus === QuestionStatusEnum.L3Explanation);
  }

  // Consideration: perhaps move this content into a json file
  private updateAclUserState() {
    if (this.overviewDto) {
      switch (this.overviewDto.overview.sectionStatus) {
        case (QuestionStatusEnum.NotStarted):
          this.acl.editAlertText = 'Grantee has not started working on this report yet. '
            + 'You may enter data on behalf of the grantee.';
          this.acl.editButtonText = 'Enter Data';
          this.acl.editAlertStyle = 'alert-info';
          this.acl.editModalBodyText = 'Grantee has not started working on this report yet. '
            + 'Are you sure you wish to start entering data for this report on behalf of the grantee?';
          this.acl.editModalPositiveBtnText = 'Yes, enter grantee data';
          break;
        case (QuestionStatusEnum.L1InProgressError):
        case (QuestionStatusEnum.L2InProgressError):
        case (QuestionStatusEnum.L1InProgress):
        case (QuestionStatusEnum.L2ValidatedWarning):
        case (QuestionStatusEnum.L2Validated):
        case (QuestionStatusEnum.L3NoExplanation):
        case (QuestionStatusEnum.L3Explanation):
        case (QuestionStatusEnum.NoSignificantVariance):
          this.acl.editAlertText = 'Grantee\'s report is currently in progress. '
            + 'You may edit this data on behalf of the grantee.';
          this.acl.editButtonText = 'Edit data';
          this.acl.editAlertStyle = 'alert-info';
          this.acl.editModalBodyText = 'This report is currently in progress. '
            + 'Are you sure you wish to start editing this report on behalf of the grantee?';
          this.acl.editModalPositiveBtnText = 'Yes, edit grantee data';
          break;
        case (QuestionStatusEnum.Returned):
          this.acl.editAlertText = 'Grantee\'s data has been returned for corrections. '
            + 'You may edit this data on behalf of the grantee.';
          this.acl.editButtonText = 'Edit data';
          this.acl.editAlertStyle = 'alert-danger';
          this.acl.editModalBodyText = 'This report has been returned to the grantee for corrections. '
            + 'Editing data will change the status of this report from \'Returned\' to \'In progress\'. '
            + 'Are you sure you wish to start editing this report on behalf of the grantee?';
          this.acl.editModalPositiveBtnText = 'Yes, edit grantee data';
          break;
        case (QuestionStatusEnum.Submitted):
          this.acl.editAlertText = 'Grantee has submitted data for ACL review. '
            + 'You may edit this data on behalf of the grantee or return the submission to the grantee for corrections.';
          this.acl.editButtonText = 'Edit data';
          this.acl.editAlertStyle = 'alert-success';
          this.acl.editModalBodyText = 'This report has been submitted for ACL review. '
            + 'Editing data will change the status of this report from \'Submitted\' to \'In Progress\'. '
            + 'Are you sure you wish to start editing this report on behalf of the grantee?';
          this.acl.editModalPositiveBtnText = 'Yes, edit grantee data';
          break;
        case (QuestionStatusEnum.InReview):
          this.acl.editAlertText = 'Grantee data is in review by ACL. '
            + 'You may edit this data on behalf of the grantee or return the submission to the grantee for corrections.';
          this.acl.editButtonText = 'Edit data';
          this.acl.editAlertStyle = 'alert-success';
          this.acl.editModalBodyText = 'This report is in review by ACL. '
            + 'Editing data will change the status of this report from \'In Review\' to \'In Progress\'. '
            + 'Are you sure you wish to start editing this report on behalf of the grantee?';
          this.acl.editModalPositiveBtnText = 'Yes, edit grantee data';
          break;
        case (QuestionStatusEnum.Approved):
          this.acl.editAlertText = 'This report has been approved. '
            + 'You may change your review decision or update comments on the Grantee Review page';
          this.acl.editButtonText = 'Return to Grantee Review';
          this.acl.editAlertStyle = 'alert-success';
          break;
      }
    }
  }

}
