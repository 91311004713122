import { GenericFfyStateModel } from '../../shared/genericModel';

export enum UserGroupEnum {
    NoGroup, State, AAA, StateAAA
}
export enum StateProfileStatus {
    InProgress, LockedInDelete, LockedInSave, ReadyForDataEntry
}
export enum DataGroupEnum {
    NoGroup,
    AgingNetwork,
    ServiceExpenditures,
    ServiceUnits,
    LegalAssistance,
    CaregiverSupplementalServices
}
export class DataSubmissionResponsibility extends GenericFfyStateModel {
    dataGroup: DataGroupEnum;
    userGroup: UserGroupEnum;
    constructor(dataGroup: DataGroupEnum, userGroup: string, fiscalYear: string, state: string) {
        super(fiscalYear, state);
        this.dataGroup = (dataGroup);
        this.userGroup = Number(userGroup);
    }
}
export class StateProfile extends GenericFfyStateModel {
    dataGroups: Array<DataSubmissionResponsibility>;
    status: StateProfileStatus;
    constructor(fiscalYear: string, state: string) {
        super(fiscalYear, state);
    }
}
export class SubmissionResponsibility extends GenericFfyStateModel {
    agingNetworkResponsibility: DataSubmissionResponsibility;
    serviceUnitsResponsibility: DataSubmissionResponsibility;
    expenditureResponsibility: DataSubmissionResponsibility;
    legalAssistanceResponsibility: DataSubmissionResponsibility;
    caregiverSupplementalServices: DataSubmissionResponsibility;
    status: StateProfileStatus;

    constructor(fiscalYear: string, state: string) {
        super(fiscalYear, state);
        this.agingNetworkResponsibility = new DataSubmissionResponsibility(DataGroupEnum.AgingNetwork,
            UserGroupEnum.State.toString(), fiscalYear, state);
        this.serviceUnitsResponsibility = new DataSubmissionResponsibility(DataGroupEnum.ServiceUnits,
            UserGroupEnum.State.toString(), fiscalYear, state);
        this.expenditureResponsibility = new DataSubmissionResponsibility(DataGroupEnum.ServiceExpenditures,
            UserGroupEnum.State.toString(), fiscalYear, state);
        this.legalAssistanceResponsibility = new DataSubmissionResponsibility(DataGroupEnum.LegalAssistance,
            UserGroupEnum.State.toString(), fiscalYear, state);
        this.caregiverSupplementalServices = new DataSubmissionResponsibility(DataGroupEnum.CaregiverSupplementalServices,
            UserGroupEnum.State.toString(), fiscalYear, state);
        this.status = StateProfileStatus.InProgress;
    }
}
