import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';


import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { ANNetworkFundingSection } from './ANNetworkFundingSection';
import { ToastrService } from '../shared/toastr.service';
import { CommonService } from '../shared/common.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { ValidationService } from '../shared/validation.service';
import { TitleIIISectionComponent } from './title-iii-section.component';

@Component({
  templateUrl: 'titleIIIANNetworkFunding.component.html',
})

export class TitleIIIANNetworkFundingComponent extends TitleIIISectionComponent<ANNetworkFundingSection> implements OnInit, OnDestroy {

  _sectionModel: ANNetworkFundingSection;
  _uploadModels: Array<ANNetworkFundingSection>;
  _batchIds: Array<string>;
  noFederalFundsChecked: boolean = false;
  selectAll: boolean = false;
  unsavedChanges: boolean = false;
  questionStatusEnum = QuestionStatusEnum;

  constructor(public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public toastr: ToastrService,
    public _validation: ValidationService,
    public _commonService: CommonService
  ) { super(new ANNetworkFundingSection(), _commonService, _validation, router, _claimService, _service); }

  ngOnInit(): void {
    this._titleService.setTitle('AN - Network Funding - Data Submissions - ACL OAAPS');
    this._sectionModel = new ANNetworkFundingSection();
    this.getannetworkfundingbyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    this._sectionModel.psa = this._claimService.currentSubOrg;
    this.formatData();

    super.registerSubscription(this._service.saveannetworkfunding(this._sectionModel)
      .subscribe(data => {
        this._sectionModel = data;
        this._claimService.tiiiStatuses.titleIIIANNetworkFunding = data.sectionStatus;
        this.setFalseStringsToNull();

      },
        (error) => console.log(error),
        () => {
          callback();
          this.toastr.success('Saved!')}
        ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIIANStaffAndVolunteers']);
    if (!this._claimService.isViewOnly &&
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  formatData(): void {
    this._sectionModel.noFederalFunds.elementValue = this._sectionModel.noFederalFunds.elementValue ? String(this._sectionModel.noFederalFunds.elementValue) : null;
    this._sectionModel.medicaid.elementValue = this._sectionModel.medicaid.elementValue ? String(this._sectionModel.medicaid.elementValue) : null;
    this._sectionModel.medicaidWaiver.elementValue = this._sectionModel.medicaidWaiver.elementValue ? String(this._sectionModel.medicaidWaiver.elementValue) : null;
    this._sectionModel.socialServicesBlockGrant.elementValue = this._sectionModel.socialServicesBlockGrant.elementValue ? String(this._sectionModel.socialServicesBlockGrant.elementValue) : null;
    this._sectionModel.communityServicesBlockGrant.elementValue = this._sectionModel.communityServicesBlockGrant.elementValue ? String(this._sectionModel.communityServicesBlockGrant.elementValue) : null;
    this._sectionModel.unitedStatesDepartmentOfAgriculture.elementValue = this._sectionModel.unitedStatesDepartmentOfAgriculture.elementValue ? String(this._sectionModel.unitedStatesDepartmentOfAgriculture.elementValue) : null;
    this._sectionModel.departmentOfTransportation.elementValue = this._sectionModel.departmentOfTransportation.elementValue ? String(this._sectionModel.departmentOfTransportation.elementValue) : null;
    this._sectionModel.otherFederalFunds.elementValue = this._sectionModel.otherFederalFunds.elementValue ? String(this._sectionModel.otherFederalFunds.elementValue) : null;

  }
  getannetworkfundingbyffyandstate(): void {
    super.registerSubscription(
      this._service.get<ANNetworkFundingSection[]>(
        this._claimService, this._service._getannetworkfundingbyffyandstate, this.resolveSectionName())
        .subscribe(data => {
          if (data.length > 0) {
            this._sectionModel = data[0];
            this.setNoFederalFundsChecked();
            this.setFalseStringsToNull();
            super.processSectionStatus();
          }

        }));
  }

  noFederalFundsSelected(): void {
    if (this.noFederalFundsChecked === false) {
      this._sectionModel.medicaid.elementValue = null;
      this._sectionModel.medicaidWaiver.elementValue = null;
      this._sectionModel.socialServicesBlockGrant.elementValue = null;
      this._sectionModel.communityServicesBlockGrant.elementValue = null;
      this._sectionModel.unitedStatesDepartmentOfAgriculture.elementValue = null;
      this._sectionModel.departmentOfTransportation.elementValue = null;
      this._sectionModel.otherFederalFunds.elementValue = null;
      this._sectionModel.noFederalFunds.elementValue = 'True';
      this.noFederalFundsChecked = true;
    } else {
      this.noFederalFundsChecked = false;
      this._sectionModel.noFederalFunds.elementValue = null;
    }
  }

  setNoFederalFundsChecked(): void {
    this.noFederalFundsChecked = this._commonService.parseBoolean(this._sectionModel.noFederalFunds.elementValue);
  }

  setFalseStringsToNull(): void {

    if (!this._commonService.parseBoolean(this._sectionModel.noFederalFunds.elementValue)) {
      this._sectionModel.noFederalFunds.elementValue = null;
    }
    if (!this._commonService.parseBoolean(this._sectionModel.medicaid.elementValue)) {
      this._sectionModel.medicaid.elementValue = null;
    }
    if (!this._commonService.parseBoolean(this._sectionModel.medicaidWaiver.elementValue)) {
      this._sectionModel.medicaidWaiver.elementValue = null;
    }
    if (!this._commonService.parseBoolean(this._sectionModel.socialServicesBlockGrant.elementValue)) {
      this._sectionModel.socialServicesBlockGrant.elementValue = null;
    }
    if (!this._commonService.parseBoolean(this._sectionModel.communityServicesBlockGrant.elementValue)) {
      this._sectionModel.communityServicesBlockGrant.elementValue = null;
    }
    if (!this._commonService.parseBoolean(this._sectionModel.unitedStatesDepartmentOfAgriculture.elementValue)) {
      this._sectionModel.unitedStatesDepartmentOfAgriculture.elementValue = null;
    }
    if (!this._commonService.parseBoolean(this._sectionModel.departmentOfTransportation.elementValue)) {
      this._sectionModel.departmentOfTransportation.elementValue = null;
    }
    if (!this._commonService.parseBoolean(this._sectionModel.otherFederalFunds.elementValue)) {
      this._sectionModel.otherFederalFunds.elementValue = null;
    }
  }

  selectDeselectAllFunds(): void {
    this.selectAll = !this.selectAll;
    if (!this.noFederalFundsChecked && this.selectAll) {
      this._sectionModel.medicaid.elementValue = 'True';
      this._sectionModel.medicaidWaiver.elementValue = 'True';
      this._sectionModel.socialServicesBlockGrant.elementValue = 'True';
      this._sectionModel.communityServicesBlockGrant.elementValue = 'True';
      this._sectionModel.unitedStatesDepartmentOfAgriculture.elementValue = 'True';
      this._sectionModel.departmentOfTransportation.elementValue = 'True';
      this._sectionModel.otherFederalFunds.elementValue = 'True';
    } else {
      this._sectionModel.medicaid.elementValue = null;
      this._sectionModel.medicaidWaiver.elementValue = null;
      this._sectionModel.socialServicesBlockGrant.elementValue = null;
      this._sectionModel.communityServicesBlockGrant.elementValue = null;
      this._sectionModel.unitedStatesDepartmentOfAgriculture.elementValue = null;
      this._sectionModel.departmentOfTransportation.elementValue = null;
      this._sectionModel.otherFederalFunds.elementValue = null;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.agingNetworkLockedNav = false;
      return true;
    } else {
      this._commonService.agingNetworkLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
      or press Cancel to go back and save these changes.`;
    }
  }

  validate(data: DataElement): DataElement {
    if (data.elementValue != null && data.elementValue !== '') {
      if (this._validation.validate(data.validationType, data.elementValue)) {
        if (data.validationType === 0 && data.maxLength !== 0 && !this._validation.validateCharLimit(data.elementValue, data.maxLength)) {
          data.dataEntryErrorMessage = 'Maximum length of ' + data.maxLength + ' characters with spaces.';
          data.questionStatus = this.questionStatusEnum.L1InProgressError;
        } else {
          data.dataEntryErrorMessage = '';
          data.questionStatus = this.questionStatusEnum.L1InProgress;
        }
      } else {
        data.dataEntryErrorMessage = this._validation.getErrorMessage(data.validationType);
        data.questionStatus = this.questionStatusEnum.L1InProgressError;
      }
    } else {
      data.dataEntryErrorMessage = '';
      data.questionStatus = this.questionStatusEnum.NotStarted;
    }

    return data;
  }

}
