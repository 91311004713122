// Map MVC model names to display names
export class SectionLookup {
    // Title VII
    static readonly StaffAndVolunteerSection: string = 'Staff and Volunteer';
    static readonly CaseAndComplaintSection: string = 'Case and Complaint';
    static readonly FundsExpendedSection: string = 'Funds Expended';
    static readonly NumberAndCapacitySection: string = 'Number and Capacity';
    static readonly RccInformationSection: string = 'RCC Information';
    static readonly OrgStructureSection: string = 'Organizational Structure';
    static readonly ProgramActivitiesSection: string = 'Program Activities';
    // Title III

    // Older adults
    static readonly InfoAndAssistanceSection: string = 'Info and Assistance';
    static readonly HomeDeliveredMealsUnitsSection: string = 'Home Delivered Meals Units';
    static readonly HomeDeliveredMealsCharsSection: string = 'Home Delivered Meals Characteristics';
    static readonly AssistedTransportationUnitsSection: string = 'Assisted Transportation Units';
    static readonly AssistedTransportationCharsSection: string = 'Assisted Transportation Characteristics';
    static readonly PersonalCareUnitsSection: string = 'Personal Care Units';
    static readonly NutritionCounselingUnitsSection: string = 'Nutrition Counseling Units';
    static readonly CongregateMealsUnitsSection: string = 'Congregate Meals Units';
    static readonly HealthPromoNonEvidenceSection: string = 'Health Promotion Non-Evidence';
    static readonly FourColNoNutritionCharsSection: string = 'Consumer Characteristics';
    static readonly OALegalCasesSection: string = 'Legal Cases';
    static readonly OAConsumerTpsSection: string = 'Consumer Summary';
    static readonly HealthPromoEvidenceSection: string = 'Health Promotion Evidence';
    static readonly MultipleUnitsSection: string = 'Multiple Units';
    static readonly TitleVIIExpendituresSection: string = 'Expenditures';
    static readonly nsipSection: string = 'NSIP';
    static readonly NutritionCharsPersonalCareSection: string = 'Nutrition Characteristics - Personal Care';
    static readonly NutritionCharsCaseMgmt: string = 'Nutrition Characteristics - Case Mgmt';
    static readonly NutritionCharsHomemakerSection: string = 'Nutrition Characteristics - Homemaker';
    static readonly NutritionCharsChoreSection: string = 'Nutrition Characteristics - Chore';
    static readonly NutritionCharsAdultDayCare: string = 'Nutrition Characteristics - Adult Day Care';
    static readonly HomemakerUnitsSection: string = 'Homemaker Units';
    static readonly ChoreUnitsSection: string = 'Chore Units';
    static readonly AdultDayCareUnitsSection: string = 'Adult Day Care Units';
    static readonly CaseMgmtUnitsSection: string = 'Case Mgmt Units';
    static readonly NutritionEduInfoSection: string = 'Nutrition Edu Info';
    static readonly TransportationInfoSection: string = 'Transportation Info';



    // Aging network
    static readonly ANStaffAndVolunteersSection: string = 'Staff and Volunteers';
    static readonly ANServiceProvidersSection: string = 'Service Provider';
    static readonly ANSeniorCentersSection: string = 'Senior Centers';
    static readonly ANSelfDirectionSection: string = 'Self Direction';
    static readonly ANRespiteSection: string = 'Respite';
    static readonly ANNetworkFundingSection: string = 'Network Funding';


}
