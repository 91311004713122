import { Component, OnInit, NgZone, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, forkJoin, interval, Observable, of } from "rxjs";
import { Title, SafeHtml, DomSanitizer } from "@angular/platform-browser";

import { ClaimService } from "../shared/claim.service";
import {
  Section,
  ITIIIOverview,
  TIIIOverview,
  TIIITopSectionTypeEnum,
  ISection,
} from "../shared/section";
import { TitleIIIDataEntryService } from "./titleIIIDataEntry.service";
import { CommonService } from "../shared/common.service";
import { YearApprovalStatus } from "../shared/yearApprovalStatus.model";
import { QuestionStatusEnum } from "../shared/dataElement";
import { ToastrService } from "../shared/toastr.service";
import { LookupService } from "../lookup.service";
import { StateProfileStatus } from "./Profile/submissionResponsibility";
import { ApprovalDecision } from "../shared/yearApprovalStatus.model";
import { Rollup, RollupStatus } from "./StateRollup/rollup";
import {
  CommentTypeEnum,
  Constants,
  RollupTypes,
  getFaCssForStatus,
} from "../app.constants";
import { CommentService } from "../shared/Comment/comment.service";
import { NsipSection } from "./NsipSection";
import { ReportService } from "../reports/reports.service";
import { HomeDeliveredMealsUnitsSection } from "./HomeDeliveredMealsUnitsSection";
import { CongregateMealsUnitsSection } from "./CongregateMealsUnitsSection";
import { DestructibleComponent } from "../destructible.component";
import {
  tap,
  map,
  switchMap,
  takeWhile,
  finalize,
  catchError,
} from "rxjs/operators";
import { ProgramViewConstraints } from "./ProgramViewContraints";
import {
  DistributedTransaction,
  TransactionStatus,
} from "../shared/Sync/distributedTransaction";
import {
  YearStateRegion,
  YearStateRegionReport,
} from "../reports/report.model";
import { LoadingService } from "./../shared/loading.service";
import { IOverviewDetails } from "../shared/Overview/overviewDetails";
import { OverviewButtonOptions } from "../shared/OverviewButtonSet/overviewButtonOptions";
import { TitleIIIOverviewConstants } from "./TitleIIIOverviewConstants";
import {
  TitleIIIOverviewState,
  TitleIIIOverviewStatePermissions,
} from "./titleIIIOverviewState";
import {
  IRollupSource,
  RollupSource,
} from "../UserManagement/userManagerObjects";
declare var jQuery: any;

// Enums
enum ButtonActions {
  VALIDATE,
  GEN_VARIANCE,
  SUBMIT,
}
enum SubmissionTypes {
  SPR,
  NSIP,
}
enum ButtonStatuses {
  COMPLETE = 1 << 2,
  ACTIONABLE = 1 << 1,
  DISABLED = 1 << 0,
  NOT_SET = 0,
} // Bit field flags

@Component({
  templateUrl: "titleIIIOverview.component.html",
  styles: [".flex.flex-center { background-color: #FFF }"],
})
export class TitleIIIOverviewComponent
  extends DestructibleComponent
  implements OnInit, OnDestroy
{
  private readonly titleIIIModule = "data-submissions";

  // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;

  public rollup: Rollup = new Rollup();
  public rollupStatus = RollupStatus;

  // Enum refs for template
  buttonActions = ButtonActions;
  submissionTypes = SubmissionTypes;
  currentTitle: string;
  // state: string;
  // ffy: string;
  userID: string;
  sprDaysRemaining: number;
  nsipDaysRemaining: number;
  sprExplanationsDaysRemaining: number;
  nsipExplanationsDaysRemaining: number;
  fFYEndDate: string;
  overviewModel: ITIIIOverview = new TIIIOverview();
  overview = TIIIOverview;
  sectionEnum = TIIITopSectionTypeEnum;
  statusEnum = QuestionStatusEnum;
  sprReturnCommentModel: YearApprovalStatus = new YearApprovalStatus();
  nsipReturnCommentModel: YearApprovalStatus = new YearApprovalStatus();
  isSPRReturnComments: boolean;

  ANSection: Section = new Section();
  OASection: Section = new Section();
  COASection: Section = new Section();
  ORCSection: Section = new Section();
  ExpendituresSection: Section = new Section();
  nsipSection: Section = new Section();

  ANSectionValidateFlag: boolean = false;
  OASectionValidateFlag: boolean = false;
  COASectionValidateFlag: boolean = false;
  ORCSectionValidateFlag: boolean = false;
  ExpendituresSectionValidateFlag: boolean = false;
  nsipSectionValidateFlag: boolean = false;
  sprSectionValidateFlag: boolean = false;

  sprAllowedToSubmit: boolean = false;
  nsipAllowedToSubmit: boolean = false;

  hasProfile: boolean = true;
  sprDueDate: Date;
  nsipDueDate: Date;
  sprExplanationsDueDate: Date;
  nsipExplanationsDueDate: Date;
  openPeriodDate: Date;
  orgText: String = "";

  viewConstraints = new ProgramViewConstraints();
  overviewConstants = TitleIIIOverviewConstants;
  public state: TitleIIIOverviewState = new TitleIIIOverviewState(
    new TitleIIIOverviewStatePermissions()
  );
  showAAAStateEditAlert: boolean;
  getFaCssForStatus = getFaCssForStatus;
  rollupSource: IRollupSource = new RollupSource();

  rollupState: string[] = [];
  rollupAaa: string[] = [];

  constructor(
    public zone: NgZone,
    public _claimService: ClaimService,
    public route: ActivatedRoute,
    public router: Router,
    public _service: TitleIIIDataEntryService,
    public _reportService: ReportService,
    public _titleService: Title,
    public toastr: ToastrService,
    public _commonService: CommonService,
    public _lookupService: LookupService,
    public _sanitizer: DomSanitizer,
    public _commentService: CommentService,
    private _loadingService: LoadingService
  ) {
    super();
  }
  ngOnInit(): void {
    // this.loadingComponent.setLoadingInProgress(300);
    this._claimService.init$.subscribe(() => {
      this._commonService.setSubOrg(this.router);
      this.currentTitle = this._claimService.currentTitle;
      this._titleService.setTitle("Overview - Data Submissions - ACL OAAPS");
      this.userID = this._claimService.userID;
      if (this._claimService.isAAAUser) {
        this.orgText = "your state";
      } else {
        this.orgText = "ACL";
      }
      super.registerSubscription(
        this._service
          .getYearApprovalStatusByFfyAndState(
            this._claimService.currentYear,
            this._claimService.currentOrg,
            this._claimService.currentSubOrg,
            this._claimService.isRollupOverview,
            "SPR"
          )
          .subscribe(
            (data) => {
              if (data) {
                this.sprReturnCommentModel = data
                  .sort((a, b) => a.lastModified.localeCompare(b.lastModified))
                  .reverse()
                  .find((x) => x.decision === ApprovalDecision.Returned);
              }
              if (!this.sprReturnCommentModel) {
                this.sprReturnCommentModel = new YearApprovalStatus();
              }
            },
            (err) => console.log(err)
          )
      );
      super.registerSubscription(
        this._service
          .getYearApprovalStatusByFfyAndState(
            this._claimService.currentYear,
            this._claimService.currentOrg,
            this._claimService.currentSubOrg,
            this._claimService.isRollupOverview,
            "NSIP"
          )
          .subscribe(
            (data) => {
              if (data) {
                this.nsipReturnCommentModel = data
                  .sort((a, b) => a.lastModified.localeCompare(b.lastModified))
                  .reverse()
                  .find((x) => x.decision === ApprovalDecision.Returned);
              }
              if (!this.nsipReturnCommentModel) {
                this.nsipReturnCommentModel = new YearApprovalStatus();
              }
            },
            (err) => console.log(err)
          )
      );
      super.registerSubscription(
        this._lookupService.getEnabledTitleIIIStates().subscribe((result) => {
          if (result && result.includes(this._claimService.currentOrg)) {
            super.registerSubscription(
              this._service
                .getStateProfile(
                  this._claimService.currentYear,
                  this._claimService.currentOrg
                )
                .subscribe((data) => {
                  this.hasProfile =
                    data != null &&
                    data.status === StateProfileStatus.ReadyForDataEntry
                      ? true
                      : false;
                  if (this.hasProfile) {
                    this.getOverview();
                  }
                })
            );
          } else {
            this.getOverview();
          }
        })
      );
      this.getRollupSource();
    });
  }

  getRollupSource() {
    super.registerSubscription(
      this._service
        .getRollupSource(
          this._claimService.currentYear,
          this._claimService.currentOrg
        )
        .subscribe(
          (data) => {
            if (data) {
              this.rollupSource = data;
            }

            this.rollupSource.sources
              .filter((x) => x.region == "State")
              .map((x) => this.rollupState.push(x.displayName));
            if (this.rollupState.length == 0) {
              this.rollupState.push("None");
            }

            this.rollupSource.sources
              .filter((x) => x.region == "AAA")
              .map((x) => this.rollupAaa.push(x.displayName));
            if (this.rollupAaa.length == 0) {
              this.rollupAaa.push("None");
            }
          },
          (err) => console.log(err)
        )
    );
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  getOverview(): any {
    if (this.hasProfile) {
      let o$;
      this.getTitleIIIReportingPeriod();
      if (this._claimService.isRollupOverview) {
        // Rollup overview
        o$ = forkJoin(
          this._service.getRollupOverviewByFfyAndState(
            this._claimService.currentYear,
            this._claimService.currentOrg
          ),
          this._service.get<Rollup>(
            this._claimService,
            this._service._rollupStatusEndpoint
          )
        ).pipe(
          tap((data) => (this.rollup = data[1])),
          map((data) => data[0])
        );
      } else {
        // State/AAA overview
        o$ = this._service.getOverviewByFfyAndState(
          this._claimService.currentYear,
          this._claimService.currentOrg,
          this._claimService.currentSubOrg
        );
      }

      // Get overview
      super.registerSubscription(
        o$.subscribe((data) => {
          this.loadSections(data);
          this.setViewableState();
        })
      );

      // Accordion accessibility
      this._commonService.setAccordionAccessibilityListener();
    }
  }

  aclEditAlertAction() {
    if (this.state.overallReportingStatus < QuestionStatusEnum.Approved) {
      jQuery("#aaaStateEditModal").modal("show");
      jQuery("#aaaStateEditModal")
        .focus()
        .on("shown.bs.modal", () => jQuery("#btnEnableAclEdit").focus());
    } else {
      console.log("To do: implement appropriate route.");
      //   this.router.navigate(['/titlevi/acl-ppr-submissions-review'], { queryParams: { granteeId: this.model.granteeId } });
    }
  }

  showPullBackSpr(): boolean {
    return (
      this.overviewModel.sprStatus >= this.statusEnum.SubmittedNoExplanation &&
      this.overviewModel.sprStatus < this.statusEnum.InReview &&
      (this._claimService.isNavStateSubmission ||
        this._claimService.isAAAUser) &&
      !this._claimService.isViewOnly
    );
  }

  showPullBackNsip(): boolean {
    return (
      this.overviewModel.nsipStatus >= this.statusEnum.SubmittedNoExplanation &&
      this.overviewModel.nsipStatus < this.statusEnum.InReview &&
      (this._claimService.isNavStateSubmission ||
        this._claimService.isAAAUser) &&
      !this._claimService.isViewOnly
    );
  }

  enableAAAStateEdit() {
    let sequence = of(null);
    if (this.overviewModel.sprStatus >= QuestionStatusEnum.Returned) {
      sequence = sequence.pipe(
        switchMap(() => this.calculateVariance$("SPR", true))
      );
    }
    if (this.overviewModel.nsipStatus >= QuestionStatusEnum.Returned) {
      sequence = sequence.pipe(
        switchMap(() => this.calculateVariance$("NSIP", true))
      );
    }
    sequence.subscribe(() => {
      this._service._aaaStateEditMode = true;
      this.updateState(this.overviewModel);
    });
  }

  private isViewOnly(): boolean {
    return (
      this._claimService.isViewOnly ||
      (!this._claimService.isAAAUser &&
        this._claimService.isAAAEnabledState &&
        // Viewing AAA data without edit mode toggled
        this._claimService.currentSubOrg &&
        !this._service._aaaStateEditMode &&
        !this._claimService.isRollupOverview)
    );
  }

  updateState(titleIIIOverview: ITIIIOverview) {
    this.state = new TitleIIIOverviewState(
      {
        isViewOnly: this.isViewOnly(),
        isRollupOverview: this._claimService.isRollupOverview,
      },
      titleIIIOverview
    );
    this.showAAAStateEditAlert =
      this._claimService.isAAAStateUserOnAAARecord() &&
      (titleIIIOverview.sprStatus < QuestionStatusEnum.Locked ||
        titleIIIOverview.nsipStatus < QuestionStatusEnum.Locked) &&
      !this._service._aaaStateEditMode;
  }

  loadSections(data: ITIIIOverview): void {
    if (data != null) {
      this.overviewModel = data;
      // TODO replace with StatusLoaderService
      this._claimService.tiiiStatuses.sprStatus = this.overviewModel.sprStatus;
      this._claimService.tiiiStatuses.nsipStatus =
        this.overviewModel.nsipStatus;
      this._claimService.tiiiStatuses.setTitleIIIStatuses(this.overviewModel);
      this._claimService.statusesLoaded = true;
      this.ANSection = this.overviewModel.sections.find(
        (x) => x.sectionName === this.sectionEnum[this.sectionEnum.ANSection]
      );
      this.OASection = this.overviewModel.sections.find(
        (x) => x.sectionName === this.sectionEnum[this.sectionEnum.OASection]
      );
      this.COASection = this.overviewModel.sections.find(
        (x) => x.sectionName === this.sectionEnum[this.sectionEnum.COASection]
      );
      this.ORCSection = this.overviewModel.sections.find(
        (x) => x.sectionName === this.sectionEnum[this.sectionEnum.ORCSection]
      );
      this.ExpendituresSection = this.overviewModel.sections.find(
        (x) =>
          x.sectionName ===
          this.sectionEnum[this.sectionEnum.ExpendituresSection]
      );
      this.nsipSection = this.overviewModel.sections.find(
        (x) => x.type === this.sectionEnum[this.sectionEnum.nsipSection]
      );

      // Must have data to display 'Validate Business Rules' options
      this.ANSectionValidateFlag =
        this.ANSection.sectionStatus > QuestionStatusEnum.NotStarted &&
        this.ANSection.isRequired;
      this.OASectionValidateFlag =
        this.OASection.sectionStatus > QuestionStatusEnum.NotStarted &&
        this.OASection.isRequired;
      this.COASectionValidateFlag =
        this.COASection.sectionStatus > QuestionStatusEnum.NotStarted &&
        this.COASection.isRequired;
      this.ORCSectionValidateFlag =
        this.ORCSection.sectionStatus > QuestionStatusEnum.NotStarted &&
        this.ORCSection.isRequired;
      this.ExpendituresSectionValidateFlag =
        this.ExpendituresSection.sectionStatus >
          QuestionStatusEnum.NotStarted && this.ExpendituresSection.isRequired;
      this.nsipSectionValidateFlag =
        this.ORCSection.sectionStatus > QuestionStatusEnum.NotStarted &&
        this.nsipSection.isRequired;

      this.sprSectionValidateFlag =
        this.ANSectionValidateFlag &&
        this.OASectionValidateFlag &&
        this.COASectionValidateFlag &&
        this.ORCSectionValidateFlag &&
        this.ExpendituresSectionValidateFlag &&
        this.overviewModel.sprStatus <= QuestionStatusEnum.L1InProgress;

      this.updateState(this.overviewModel);
    }
  }

  refreshOverviewStatusWithCompletionListener(onCompletion: () => void) {
    // this.loadingComponent.setLoadingInProgress(60);
    if (this._claimService.isRollupOverview) {
      super.registerSubscription(
        this._service
          .getRollupOverviewByFfyAndState(
            this._claimService.currentYear,
            this._claimService.currentOrg
          )
          .subscribe(
            (data) => {
              this.loadSections(data);
            },
            (err) => console.log(err),
            onCompletion
          )
      );
    } else {
      super.registerSubscription(
        this._service
          .getOverviewByFfyAndState(
            this._claimService.currentYear,
            this._claimService.currentOrg,
            this._claimService.currentSubOrg
          )
          .subscribe(
            (data) => {
              this.loadSections(data);
            },
            (err) => console.log(err),
            onCompletion
          )
      );
    }
  }

  getRefreshStatusToasterListener(
    sectionId: TIIITopSectionTypeEnum
  ): () => void {
    const lambda: () => void = () => {
      const status = this.getStatusFromId(sectionId);
      if (status === QuestionStatusEnum.L2InProgressError) {
        this.toastr.error("Validation Errors Found!");
      } else if (status === QuestionStatusEnum.L2ValidatedWarning) {
        this.toastr.warning("Validation Warnings Found!");
      } else if (status === QuestionStatusEnum.L2Validated) {
        this.toastr.success("Section Validated!");
      }
    };
    return lambda;
  }

  isSubmittedNoExplanation(status: QuestionStatusEnum) {
    return status === QuestionStatusEnum.SubmittedNoExplanation;
  }

  validate(type: string) {
    this.kickoffValidation(TIIITopSectionTypeEnum[type]);
  }

  validateSectionClicked(sectionId: TIIITopSectionTypeEnum): void {
    // this.loadingComponent.setLoadingInProgress(600);
    // Get the two values needed from SPR that NSIP needs to validate correctly
    if (
      sectionId === TIIITopSectionTypeEnum.nsipSection &&
      !this._claimService.isRollupOverview
    ) {
      super.registerSubscription(
        forkJoin(
          this._service.get<NsipSection[]>(
            this._claimService,
            this._service._getnsipbyffyandstate,
            "titleIIInsip"
          ),
          this._service.get<HomeDeliveredMealsUnitsSection[]>(
            this._claimService,
            this._service._gethomedeliveredmealsunitsbyffyandstate,
            "titleIIIHomeDeliveredMealsUnits"
          ),
          this._service.get<CongregateMealsUnitsSection[]>(
            this._claimService,
            this._service._getcongregatemealsunitsbyffyandstate,
            "titleIIICongregateMealsUnits"
          )
        ).subscribe((data) => {
          // Map properties from SPR to NSIP
          if (data[0] && data[0][0]) {
            if (data[1] && data[1][0]) {
              data[0][0].hdmCompare.elementValue =
                data[1][0].totalServiceUnits.elementValue;
            }
            if (data[2] && data[2][0]) {
              data[0][0].congregateCompare.elementValue =
                data[2][0].totalServiceUnits.elementValue;
            }
            data[0][0].sectionName = "titleIIInsip";
            super.registerSubscription(
              this._service.savensip(data[0][0]).subscribe(() => {
                this.kickoffValidation(sectionId);
              })
            );
          }
        })
      );
    } else {
      this.kickoffValidation(sectionId);
    }
  }

  kickoffValidation(sectionId: TIIITopSectionTypeEnum): void {
    super.registerSubscription(
      this._service
        .validateBusinessRules(
          this._claimService.currentYear,
          this._claimService.currentOrg,
          this._claimService.currentSubOrg,
          this._claimService.isRollupOverview,
          sectionId
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.refreshOverviewStatusWithCompletionListener(
              this.getRefreshStatusToasterListener(sectionId)
            );
          },
          (error) => {
            console.log(error);
          }
        )
    );
  }

  generateVarianceReportClicked(
    flowType: string,
    isSubmissionPullback: boolean = false
  ): void {
    super.registerSubscription(
      this.calculateVariance$(flowType, isSubmissionPullback).subscribe()
    );
  }

  calculateVariance$(
    flowType: string,
    isSubmissionPullback = false
  ): Observable<any> {
    return this._service
      .calculateVariance(
        this._claimService.currentYear,
        this._claimService.currentOrg,
        this._claimService.currentSubOrg,
        this._claimService.isRollupOverview,
        flowType,
        isSubmissionPullback
      )
      .pipe(
        tap(() => {
          this.refreshOverviewStatusWithCompletionListener(
            this.getRefreshStatusToasterListener(
              flowType === "SPR"
                ? TIIITopSectionTypeEnum.Spr
                : TIIITopSectionTypeEnum.nsipSection
            )
          );
        })
      );
  }

  submitForReviewClicked(flowType: string): void {
    // this.loadingComponent.setLoadingInProgress(600);
    super.registerSubscription(
      this._service
        .submitForReview(
          this._claimService.currentYear,
          this._claimService.currentOrg,
          this._claimService.currentSubOrg,
          this._claimService.isRollupOverview,
          flowType
        )
        .subscribe(
          (data) => {
            //
            this.deleteTempComment(
              this._claimService.currentYear,
              this._claimService.currentOrg,
              this._claimService.currentSubOrg,
              flowType === "SPR"
                ? CommentTypeEnum.IIITempReviewCommentSpr
                : CommentTypeEnum.IIITempReviewCommentNsip
            );
            this.refreshOverviewStatusWithCompletionListener(
              this.getRefreshStatusToasterListener(
                flowType === "SPR"
                  ? TIIITopSectionTypeEnum.Spr
                  : TIIITopSectionTypeEnum.nsipSection
              )
            );
          },
          (err) => {
            console.log(err);
          }
        )
    );
  }

  refreshOverviewStatus() {
    super.registerSubscription(
      this._service
        .getOverviewByFfyAndState(
          this._claimService.currentYear,
          this._claimService.currentOrg,
          this._claimService.currentSubOrg
        )
        .subscribe((data) => this.loadSections(data))
    );
  }

  export(): void {
    this._loadingService.setLoading(true, "exportReport");
    const rollupType = this._claimService.isRollupOverview
      ? RollupTypes.StateRollup
      : RollupTypes.None;
    super.registerSubscription(
      this._reportService
        .exporttitleiiidata(
          this._claimService.currentYear,
          this._claimService.currentOrg,
          this._claimService.currentSubOrg,
          rollupType
        )
        .subscribe((data) => {
          const downloadUrl = URL.createObjectURL(data as any);
          this._loadingService.setLoading(false, "exportReport");
          const a = window.document.createElement("a");
          a.href = downloadUrl;
          a.download = "TitleIIIData.xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
    );
    this.hideExportDataModal();
  }

  exportUploadData(): void {
    this._loadingService.setLoading(true, "exportUploadData");
    const rollupType = this._claimService.isRollupOverview
      ? RollupTypes.StateRollup
      : RollupTypes.None;
    const reportDetails: Array<YearStateRegion> = [];
    reportDetails.push(
      new YearStateRegion(
        this._claimService.currentYear,
        this._claimService.currentOrg,
        this._claimService.currentSubOrg,
        null,
        rollupType === RollupTypes.StateRollup
      )
    );
    const report: YearStateRegionReport = new YearStateRegionReport(
      reportDetails
    );
    report.reportName = "export";
    this._reportService
      .exportUploaddata(report)
      .pipe(
        switchMap((resp) => combineLatest(of(resp.tid), interval(3000))),
        takeWhile(([_, tick]) => tick < 100),
        switchMap(([tid]) =>
          this._reportService.getTransactionDetails(
            "api/titleiii/report/transaction",
            tid
          )
        ),
        takeWhile((t) => t.status === TransactionStatus.InProgress, true),
        catchError((_) =>
          of({
            ...new DistributedTransaction(),
            status: TransactionStatus.Failed,
          })
        )
      )
      .subscribe((t) => {
        this._loadingService.setLoading(false, "exportUploadData");
        if (t.status === TransactionStatus.Completed) {
          let uri = t.properties.uri;
          window.open(uri, "_blank");
        } else if (t.status !== TransactionStatus.InProgress) {
          console.log(t);
          this.toastr.error(Constants.MSG.defaultErrorMessage);
        }
      });
    this.hideExportDataModal();
  }

  getMostSevereStatus(sectionsArray: Array<Section>): number {
    if (sectionsArray.length > 0) {
      let mostSevereStatus: number = 9;
      let inProgress: boolean = false;
      for (const section of sectionsArray) {
        if (section.sectionStatus < mostSevereStatus) {
          mostSevereStatus = section.sectionStatus;
        }
        if (section.sectionStatus > this.statusEnum.NotStarted) {
          inProgress = true;
        }
      }
      /*
            do the following so if something isn't started but something else is,
            the catch status the user sees is in progress, which will make
            more sense to them.
            */
      if (mostSevereStatus === 0 && inProgress) {
        mostSevereStatus = 1;
      }
      return mostSevereStatus;
    } else {
      return 0;
    }
  }

  getStatusFromId(sectionId: TIIITopSectionTypeEnum): QuestionStatusEnum {
    switch (sectionId) {
      case 1:
        return this.ANSection.sectionStatus;
      case 2:
        return this.OASection.sectionStatus;
      case 3:
        return this.COASection.sectionStatus;
      case 4:
        return this.ORCSection.sectionStatus;
      case 5:
        return this.ExpendituresSection.sectionStatus;
      case 6:
        return this.nsipSection.sectionStatus;
      case TIIITopSectionTypeEnum.Spr:
        return this.overviewModel.sprStatus;
    }
  }

  getTitleIIIReportingPeriod(): void {
    super.registerSubscription(
      this._service
        .getReportingPeriodByFfy(this._claimService.currentYear)
        .subscribe((data) => {
          if (data && data.length > 0) {
            const reportingPeriod = data.find(
              (item) => item.type === "TIIIReportingPeriod"
            );
            this.sprDueDate = this._commonService.getEndOfDay(
              reportingPeriod.sprDueDate
            );
            this.nsipDueDate = this._commonService.getEndOfDay(
              reportingPeriod.nsipDueDate
            );
            this.sprExplanationsDueDate = this._commonService.getEndOfDay(
              reportingPeriod.sprExplanationsDueDate
            );
            this.nsipExplanationsDueDate = this._commonService.getEndOfDay(
              reportingPeriod.nsipExplanationsDueDate
            );
            this.openPeriodDate = this._commonService.getBeginningOfDay(
              reportingPeriod.openPeriodDate
            );

            const now = new Date();

            this.sprDaysRemaining = this._commonService.getDaysRemaining(
              new Date(this.sprDueDate),
              now
            );
            this.sprExplanationsDaysRemaining =
              this._commonService.getDaysRemaining(
                new Date(this.sprExplanationsDueDate),
                now
              );
            this.nsipDaysRemaining = this._commonService.getDaysRemaining(
              new Date(this.nsipDueDate),
              now
            );
            this.nsipExplanationsDaysRemaining =
              this._commonService.getDaysRemaining(
                new Date(this.nsipExplanationsDueDate),
                now
              );
            this.setSubmitButtonsFlag();
          }
        })
    );
  }

  setSubmitButtonsFlag(): void {
    // For AAAs they should be able to submit even if previous year is not submitted
    if (this._claimService._currentSubOrg) {
      this.sprAllowedToSubmit = this.nsipAllowedToSubmit = true;
    } else {
      super.registerSubscription(
        this._service
          .getPreviousYearApprovalStatusByFfyAndState(
            this._claimService.currentYear,
            this._claimService._currentOrg,
            this._claimService._currentSubOrg,
            this._claimService.isRollupOverview
          )
          .subscribe((data) => {
            if (data) {
              if (
                data.find(
                  (x) =>
                    x.titleIIISectionType === SubmissionTypes[0].toString() &&
                    x.status >= QuestionStatusEnum.Submitted
                )
              ) {
                this.sprAllowedToSubmit =
                  new Date(this.openPeriodDate) <= new Date();
              }
              if (
                data.find(
                  (x) =>
                    x.titleIIISectionType === SubmissionTypes[1].toString() &&
                    x.status >= QuestionStatusEnum.Submitted
                )
              ) {
                this.nsipAllowedToSubmit =
                  new Date(this.openPeriodDate) <= new Date();
              }
            }
          })
      );
    }
  }

  showCheckForSPRAction(actionName: string, type: string) {
    let overallStatus = this.overviewModel.sprStatus;
    if (type === "NSIP") {
      overallStatus = this.overviewModel.nsipStatus;
    }
    switch (actionName) {
      case "VALIDATE":
        return overallStatus >= QuestionStatusEnum.L2Validated;
      case "VARIANCE":
        return overallStatus >= QuestionStatusEnum.L3Explanation;
      case "SUBMIT":
        return overallStatus >= QuestionStatusEnum.Submitted;
      case "RETURNED":
        return overallStatus === QuestionStatusEnum.Returned;
    }
  }

  pullBackForCorrectionsClicked(flowType: string): void {
    // this.loadingComponent.setLoadingInProgress(300);
    this.generateVarianceReportClicked(flowType, true);
  }

  shouldDisplayUploadButton(status: QuestionStatusEnum) {
    return (
      status <= this.statusEnum.Returned &&
      !this._claimService.isViewOnly &&
      !this._claimService.isRollupOverview
    );
  }

  // Buttons
  // Classes based on submissions status:
  // insider-disabled
  // insider-success
  // insider
  // Using bit field flags here

  getButtonCSS(
    action: ButtonActions,
    submissionType: SubmissionTypes
  ): SafeHtml {
    const options = this.getButtonOptions(action, submissionType);

    if (options & ButtonStatuses.COMPLETE) {
      return "insider-success"; // Button is completed
    } else if (!options || options & ButtonStatuses.ACTIONABLE) {
      return "insider"; // Button is actionable
    } else if (options & ButtonStatuses.DISABLED) {
      return "insider-disabled"; // Button is disabled
    } else {
      return "insider-disabled";
    }
  }

  // Visible conditions
  get isResponsibleNsip(): boolean {
    if (!this._claimService.isAAAEnabledState) {
      return true;
    }
    return this.nsipSection.sectionName && this.nsipSection.isRequired;
  }

  get isResponsibleSpr(): boolean {
    if (this._claimService.isAAAEnabledState) {
      const nsipIndex = this.overviewModel.sections.findIndex(
        (x) => x.sectionName === "titleIIInsip"
      );
      if (nsipIndex > -1) {
        const overviewSections = this.overviewModel.sections.slice();
        overviewSections.splice(nsipIndex, 1);
        return (
          overviewSections &&
          overviewSections.length > 0 &&
          overviewSections.some((x) => x.isRequired)
        );
      } else {
        return false; // Not loaded yet
      }
    }
    return true;
  }

  get shouldSeeActionsBasedOnViewAndRole(): boolean {
    let shouldSeeActions: boolean;
    if (this._claimService.isViewOnly) {
      // View only
      shouldSeeActions = false;
    } else if (this._claimService.isACLUser()) {
      // ACL user
      shouldSeeActions = false;
    } else if (
      this._claimService.isNavStateSubmission &&
      !this._commonService.isNullOrEmpty(this._claimService.currentSubOrg) &&
      !this._service._aaaStateEditMode
    ) {
      // State user viewing AAA data, non-edit mode
      shouldSeeActions = false;
    } else {
      shouldSeeActions = true;
    }

    return shouldSeeActions;
  }

  get showValidateOnly(): boolean {
    return (
      this._claimService.isAAAEnabledState &&
      !this._claimService.isRollupOverview &&
      this._claimService.hasStateTitleAccess("III") &&
      this._commonService.isNullOrEmpty(this._claimService.currentSubOrg)
    );
  }

  // NotActionable
  isButtonDisabled(action: ButtonActions, submissionType: SubmissionTypes) {
    const options = this.getButtonOptions(action, submissionType);
    return !(options & ButtonStatuses.ACTIONABLE);
  }

  isSubmitExplanations(submissionType: SubmissionTypes) {
    const status = this.getStatusForFlow(submissionType);
    return status === QuestionStatusEnum.SubmittedNoExplanation;
  }

  getButtonOptions(action: ButtonActions, submissionType: SubmissionTypes) {
    const status = this.getStatusForFlow(submissionType);
    return this.getOptions(action, status, submissionType);
  }

  getOptions(
    action: ButtonActions,
    status: QuestionStatusEnum,
    submissionType: SubmissionTypes
  ) {
    return (
      this.isCompleted(action, status) |
      this.isActionable(action, status, submissionType) |
      this.isDisabled(action, status, submissionType)
    );
  }

  getStatusForFlow(submissionType: SubmissionTypes) {
    if (submissionType === SubmissionTypes.SPR) {
      return this.overviewModel.sprMinStatus;
    } else if (submissionType === SubmissionTypes.NSIP) {
      return this.overviewModel.nsipStatus;
    } else {
      throw new Error("SPR and NSIP are the only valid submission types");
    }
  }

  // 100 flag for completed
  isCompleted(action: ButtonActions, status: QuestionStatusEnum): number {
    switch (action) {
      case ButtonActions.VALIDATE:
        return status > QuestionStatusEnum.L2Validated
          ? ButtonStatuses.COMPLETE
          : ButtonStatuses.NOT_SET;
      case ButtonActions.GEN_VARIANCE:
        return status > QuestionStatusEnum.SubmittedNoExplanation ||
          status === QuestionStatusEnum.NoSignificantVariance ||
          status === QuestionStatusEnum.L3Explanation
          ? ButtonStatuses.COMPLETE
          : ButtonStatuses.NOT_SET;
      case ButtonActions.SUBMIT:
        return status >= QuestionStatusEnum.Submitted
          ? ButtonStatuses.COMPLETE
          : ButtonStatuses.NOT_SET;
    }
  }

  // 010 flag for actionable
  isActionable(
    action: ButtonActions,
    status: QuestionStatusEnum,
    submissionType: SubmissionTypes
  ): number {
    switch (action) {
      case ButtonActions.VALIDATE:
        return status >= QuestionStatusEnum.L1InProgress &&
          status < QuestionStatusEnum.L2Validated
          ? ButtonStatuses.ACTIONABLE
          : ButtonStatuses.NOT_SET;
      case ButtonActions.GEN_VARIANCE:
        return status >= QuestionStatusEnum.L2ValidatedWarning &&
          status < QuestionStatusEnum.L3NoExplanation
          ? ButtonStatuses.ACTIONABLE
          : ButtonStatuses.NOT_SET;
      case ButtonActions.SUBMIT:
        let allowSubmission =
          (submissionType === SubmissionTypes.SPR && this.sprAllowedToSubmit) ||
          (submissionType === this.submissionTypes.NSIP &&
            this.nsipAllowedToSubmit);
        if (this._claimService.isAAAUser) {
          return status >= QuestionStatusEnum.L3Explanation &&
            status < QuestionStatusEnum.Returned &&
            allowSubmission
            ? ButtonStatuses.ACTIONABLE
            : ButtonStatuses.NOT_SET;
        } else {
          return status >= QuestionStatusEnum.L3NoExplanation &&
            status < QuestionStatusEnum.Returned &&
            allowSubmission
            ? ButtonStatuses.ACTIONABLE
            : ButtonStatuses.NOT_SET;
        }
    }
  }

  // 001 flag for disabled
  isDisabled(
    action: ButtonActions,
    status: QuestionStatusEnum,
    submissionType: SubmissionTypes
  ): number {
    switch (action) {
      case ButtonActions.VALIDATE:
        return status < QuestionStatusEnum.L1InProgress
          ? ButtonStatuses.DISABLED
          : ButtonStatuses.NOT_SET;
      case ButtonActions.GEN_VARIANCE:
        return status < QuestionStatusEnum.L2ValidatedWarning
          ? ButtonStatuses.DISABLED
          : ButtonStatuses.NOT_SET;
      case ButtonActions.SUBMIT:
        // add logic here for previous year submission & openPeriodDate
        let allowSubmission =
          (submissionType === SubmissionTypes.SPR && this.sprAllowedToSubmit) ||
          (submissionType === this.submissionTypes.NSIP &&
            this.nsipAllowedToSubmit);
        if (this._claimService.isAAAUser) {
          return status < QuestionStatusEnum.L3Explanation && allowSubmission
            ? ButtonStatuses.NOT_SET
            : ButtonStatuses.DISABLED;
        } else {
          return status < QuestionStatusEnum.L3NoExplanation && allowSubmission
            ? ButtonStatuses.NOT_SET
            : ButtonStatuses.DISABLED;
        }
    }
  }

  showReturnedComments(): void {
    jQuery("#returnCommentsModal").modal("show");
  }

  hideReturnedComments(): void {
    jQuery("#returnCommentsModal").modal("hide");
  }

  showExportDataModal(): void {
    jQuery("#exportDataModal").modal("show");
  }

  hideExportDataModal(): void {
    jQuery("#exportDataModal").modal("hide");
  }

  deleteTempComment(
    ffy: string,
    state: string,
    psa: string,
    type: CommentTypeEnum
  ) {
    super.registerSubscription(
      this._commentService
        .deleteTIIICommentsByFfyStateType(ffy, state, psa, type)
        .subscribe((err) => {})
    );
  }

  // Service button display logic
  getServiceSection(serviceSectionName: string): ISection {
    let serviceSection = this.overviewModel.sections.find(
      (x) => x.sectionName === serviceSectionName
    );
    if (!serviceSection) {
      // If not found by sectionName property try type property
      serviceSection = this.overviewModel.sections.find(
        (x) => x.type === serviceSectionName
      );
    }
    return serviceSection;
  }

  displayViewButton(serviceSectionName: string): boolean {
    const serviceSection = this.getServiceSection(serviceSectionName);
    if (serviceSection) {
      const shouldDisplay =
        serviceSection.isRequired &&
        (this._claimService.isViewOnly ||
          this._claimService.isRollupOverview ||
          serviceSection.sectionStatus >=
            QuestionStatusEnum.SubmittedNoExplanation);
      return shouldDisplay;
    }
    return false;
  }

  displayEnterDataAndUploadButtons(serviceSectionName: string): boolean {
    const serviceSection = this.getServiceSection(serviceSectionName);
    if (serviceSection) {
      const shouldDisplay =
        serviceSection.isRequired &&
        !this._claimService.isViewOnly &&
        !this._claimService.isRollupOverview &&
        serviceSection.sectionStatus <
          QuestionStatusEnum.SubmittedNoExplanation;
      return shouldDisplay;
    }
    return false;
  }

  displayEnterExplanationsButton(serviceSectionName: string): boolean {
    const serviceSection = this.getServiceSection(serviceSectionName);
    if (serviceSection) {
      const shouldDisplay =
        serviceSection.isRequired &&
        !this._claimService.isViewOnly &&
        (serviceSection.sectionStatus === QuestionStatusEnum.L3NoExplanation ||
          serviceSection.sectionStatus ===
            QuestionStatusEnum.SubmittedNoExplanation);
      return shouldDisplay;
    }
    return false;
  }

  displayUpdateExplanationsButton(serviceSectionName: string): boolean {
    const serviceSection = this.getServiceSection(serviceSectionName);
    if (serviceSection) {
      const shouldDisplay =
        serviceSection.isRequired &&
        !this._claimService.isViewOnly &&
        serviceSection.sectionStatus === QuestionStatusEnum.L3Explanation;
      return shouldDisplay;
    }
    return false;
  }

  displayViewErrorsButton(serviceSectionName: string): boolean {
    const serviceSection = this.getServiceSection(serviceSectionName);
    if (serviceSection) {
      const shouldDisplay =
        serviceSection.isRequired &&
        (serviceSection.sectionStatus ===
          QuestionStatusEnum.L1InProgressError ||
          serviceSection.sectionStatus ===
            QuestionStatusEnum.L2InProgressError);
      return shouldDisplay;
    }
    return false;
  }

  displayViewWarningsButton(serviceSectionName: string): boolean {
    const serviceSection = this.getServiceSection(serviceSectionName);
    if (serviceSection) {
      const shouldDisplay =
        serviceSection.isRequired &&
        serviceSection.sectionStatus === QuestionStatusEnum.L2ValidatedWarning;
      return shouldDisplay;
    }
    return false;
  }

  displayValidateButton(serviceSectionName: string): boolean {
    const serviceSection = this.getServiceSection(serviceSectionName);
    if (serviceSection) {
      const shouldDisplay =
        serviceSection.isRequired &&
        !this._claimService.isViewOnly &&
        serviceSection.sectionStatus > QuestionStatusEnum.NotStarted &&
        serviceSection.sectionStatus < QuestionStatusEnum.L2Validated;
      return shouldDisplay;
    }
    return false;
  }

  /////////////////////////////
  /// Begin landing pages nav
  /////////////////////////////
  displayAgingNetwork(): void {
    this.router.navigate(["/landing/titleIIIANHome"], {
      queryParams: { module: this.titleIIIModule },
      queryParamsHandling: "merge",
    });
  }

  displayOlderAdults(): void {
    this.router.navigate(["/landing/titleIIIOAHome"], {
      queryParams: { module: this.titleIIIModule },
      queryParamsHandling: "merge",
    });
  }

  displayCOA(): void {
    this.router.navigate(["/landing/titleIIICOAHome"], {
      queryParams: { module: this.titleIIIModule },
      queryParamsHandling: "merge",
    });
  }

  displayORC(): void {
    this.router.navigate(["/landing/titleIIIORCHome"], {
      queryParams: { module: this.titleIIIModule },
      queryParamsHandling: "merge",
    });
  }

  displayExpenditures(): void {
    this.router.navigate(["/landing/titleIIIExpendituresHome"], {
      queryParams: { module: this.titleIIIModule },
      queryParamsHandling: "merge",
    });
  }
  /////////////////////////////
  /// End landing pages nav
  /////////////////////////////

  private setViewableState(): void {
    this.viewConstraints = new ProgramViewConstraints(
      this._claimService,
      this.overviewModel
    );
  }

  public buildButtonOptions(details: IOverviewDetails): OverviewButtonOptions {
    return {
      ...details,
      errorsUrl: [
        TitleIIIOverviewConstants.errorsUrl,
        "T3",
        details.description,
      ],
      uploadUrl: [TitleIIIOverviewConstants.uploadUrl],
    };
  }
}
