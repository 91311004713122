import { Component } from '@angular/core';

@Component({
    selector: 'session-warning',
    template: `
    `
})

// <loading #loadingSpinner></loading>
// <div id="modal" class="modal fade">
//   <div class="modal-dialog" role="document">
//     <div class="modal-content">
//       <div class="modal-header">
//         <h1 class="modal-title">Session Expiring Soon</h1>
//       </div>
//       <div class="modal-body">
//         <p><strong>Warning!</strong> Your session will expire in {{adalService.timeTillSessionExpires}}</p>
//       </div>
//       <div class="modal-footer">
//         <button id="extend-session" (click)="refreshSession()" type="button" class="btn btn-primary">Extend Session</button>
//         <button id="dismiss-session" (click)="dismissWarning()" type="button"
// class="btn btn-secondary" data-dismiss="modal">Close</button>
//       </div>
//     </div>
//   </div>
// </div>

export class SessionWarningComponent {
    // public static readonly SESSION_WARN_THRESHOLD: number = 600; // Seconds

    // public showWarning: boolean = true;

    // constructor(public configService: ConfigService,
    //     public adalService: AdalService) { }

    // @ViewChild('loadingSpinner') loadingComponent: LoadingComponent;

    // ngOnInit(): void {
    //     this.adalService.startSessionTimer((timeTillSessionExpires: number) => {
    //         if (this.adalService.userInfo
    //             && this.showWarning
    //             && timeTillSessionExpires < SessionWarningComponent.SESSION_WARN_THRESHOLD) {
    //             this.showWarning = false;
    //             jQuery('#modal').modal('show'); // Need to use jQuery here
    //             jQuery('#extend-session').focus();
    //         }
    //     });
    // }

    // refreshSession(): void {
    //     jQuery('#modal').modal('hide');
    //     // this.loadingComponent.setLoadingInProgress(20);
    //     if (this.adalService.userInfo) {
    //         this.adalService.renewToken(() => {  this.showWarning = true; });
    //     }
    // }

    // dismissWarning(): void {
    //     jQuery('#modal').modal('hide');
    // }
}
