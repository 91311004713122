import { Component, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { ANSeniorCentersSection } from './ANSeniorCentersSection';
import { DataElement } from '../shared/dataElement';
import { ValidationService } from '../shared/validation.service';
import { ToastrService } from '../shared/toastr.service';
import { CommonService } from '../shared/common.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIISectionComponent } from './title-iii-section.component';

@Component({
  templateUrl: 'titleIIIANSeniorCenters.component.html',
})

export class TitleIIIANSeniorCentersComponent extends TitleIIISectionComponent<ANSeniorCentersSection> implements OnInit, OnDestroy {

  _sectionModel: ANSeniorCentersSection;
  _uploadModels: Array<ANSeniorCentersSection>;
  _batchIds: Array<string>;
  unsavedChanges: boolean = false;
  questionStatusEnum = QuestionStatusEnum;

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public toastr: ToastrService,
    public _commonService: CommonService
  ) {
    super(new ANSeniorCentersSection(), _commonService, _validation, router, _claimService, _service);
   }

  ngOnInit(): void {
    this._titleService.setTitle('AN - Senior Centers - Data Submissions - ACL OAAPS');
    this._sectionModel = new ANSeniorCentersSection();
    this.getanseniorcentersbyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    super.registerSubscription(this._service.saveanseniorcenters(this._sectionModel)
      .subscribe(data => this.zone.run(() => {
        this._sectionModel = data;
        this._claimService.tiiiStatuses.titleIIIANSeniorCenters = data.sectionStatus;

      }),
        (error) => console.log(error),
        () => {
          this.toastr.success('Saved!');
          callback();
        }
        ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIIANSelfDirection']);
    if (!this._claimService.isViewOnly && 
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  getanseniorcentersbyffyandstate(): void {
    super.registerSubscription(this._service.get<ANSeniorCentersSection[]>(this._claimService,
      this._service._getanseniorcentersbyffyandstate, this.resolveSectionName())
      .subscribe(data => this.zone.run(() => {
        if (data.length > 0) {
          // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
          let tempSectionModel: ANSeniorCentersSection = new ANSeniorCentersSection();
          tempSectionModel = data.filter(element => element.batchId === '00000000-0000-0000-0000-000000000000')[0];
          if (tempSectionModel) {
            this._sectionModel = tempSectionModel;
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
      }

      })));
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.totalCenters.elementValue = this.addComma(this._sectionModel.totalCenters.elementValue);
    this._sectionModel.oaaFunded.elementValue = this.addComma(this._sectionModel.oaaFunded.elementValue);
    this._sectionModel.focalPoints.elementValue = this.addComma(this._sectionModel.focalPoints.elementValue);
    this._sectionModel.focalPointCenters.elementValue = this.addComma(this._sectionModel.focalPointCenters.elementValue);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.agingNetworkLockedNav = false;
      return true;
    } else {
      this._commonService.agingNetworkLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

}
