import { DestructibleComponent } from './destructible.component';
import { CommonService } from './shared/common.service';
import { Section } from './shared/section';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from './shared/pending-changes.guard';
import { HostListener, Directive } from '@angular/core';
import { DataElement, QuestionStatusEnum } from './shared/dataElement';
import { ValidationService } from './shared/validation.service';
import { Router } from '@angular/router';
import { AddOnOptions, AddOnMedium } from './shared/Model/addOnOptions';
import { ClaimService } from './shared/claim.service';
import { SectionLookup } from './shared/sectionLookup';

@Directive()
export abstract class SectionComponent<T extends Section> extends DestructibleComponent implements ComponentCanDeactivate {

    public _sectionModel: T;
    public unsavedChanges: boolean = false;
    public validForm: boolean = true;
    public sectionName: string;
    public addOnWidth: number = AddOnMedium;
    public statusEnum = QuestionStatusEnum;

    constructor(
        // Component model that extends section
        model: T
        // Required services
        , public _commonService: CommonService
        , public _validationService: ValidationService
        , public _router: Router) {
        super();
        this._sectionModel = model;
        this.sectionName = this._router.url.split('/').pop();
    }

    public isEditable(claimService: ClaimService): boolean {
        return !claimService.isViewOnly
            && this._sectionModel.isRequired
            && this._sectionModel.sectionStatus <= QuestionStatusEnum.Returned;
            //&& !this._sectionModel.isRollup;
    }

    /**
     * Determines whether or not to disable page form elements based on section status
     */
    public processSectionStatus(disableOverride: boolean = false): void {
        this._commonService.disableFormElements(this._sectionModel, disableOverride);
    }

    /**
     * Formats decimals using comma style and 2 significant decimal places
     * @param currentValue the input value
     */
    public formatDecimal(currentValue: string): string {
        const originalvalue = currentValue;
        if (currentValue == null || currentValue === '') {
          return '';
        }
        if (currentValue.match(/[a-zA-Z]+/g) != null) {
          return originalvalue;
        }

        currentValue = currentValue.replace(/,/g, '');
        const num = parseFloat(currentValue);
        if (!isNaN(num)) {
          return num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        } else {
          return originalvalue;
        }
      }

    /**
     * Formats numbers using comma style (e.g. 1,000,000)
     * @param currentValue the input value
     */
    public formatWholeNumber(currentValue: string): string {
        const originalValue = currentValue;
        if (currentValue == null || currentValue === '') {
            return '';
        }
        currentValue = currentValue.replace(/,/g, '');
        if (!currentValue.includes('.') && !isNaN(parseInt(currentValue, 10))) {
            return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return originalValue;
        }
    }

    /**
     * Calculates expenditure per units
     * @param expendValue
     * @param unitValue
     * @returns
     */
    public expendPerUnit(expendValue: number, unitValue: number): string {
        if (!isNaN(expendValue) && !isNaN(unitValue) && unitValue !== 0) {
            return this.addDecimals((expendValue / unitValue).toString());
          }
          else {
            return 'N/A';
          }
    }

    /**
     * Adds decimal places
     * @param currentValue
     * @returns
     */
    public addDecimals(currentValue: string): string {
        const originalvalue = currentValue;
        if (currentValue == null || currentValue === '') {
          return '';
        }
        currentValue = currentValue.replace(/,/g, '');
        const num = parseFloat(currentValue);
        if (!isNaN(num)) {
          return num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        } else {
          return originalvalue;
        }
      }

      /**
       * Adds commas to the field value after every 3 digits
       * @param currentValue
       * @returns
       */
      public addComma(currentValue: string): string {
        const originalvalue = currentValue;

        if (currentValue == null || currentValue === '') {
          return '';
        }
        currentValue = currentValue.replace(/,/g, '');
        const num = parseInt(currentValue);
        if (!isNaN(num) && !currentValue.includes('.')) {
          return currentValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          return originalvalue;
        }
      }

    /**
     * Strips commas
     * @param value the value
     */
    public removeCommas(value: string): string {
        if (value) {
          value = value.replace(/,/g, '');
        }
        return value;
      }

    /**
     * Resolves the css class for the alert based on the DataElement's status
     * @param status the DataElement status property
     */
    public setErrorDisplayType(status: number): string {
        if (status === 1 || status === 2) {
            return 'alert-danger';
        }
        if (status === 4) {
            return 'alert-warning';
        } else {
            return '';
        }
    }

    /**
     * Resolves the css class for the input based on the DataElement's status
     * @param status the DataElement status property
     */
    public calculateOutlineColor(status: number): string {
        if (status === 1 || status === 2) {
            return 'has-error';
        }
        if (status === 4) {
            return 'has-warning';
        } else {
            return '';
        }
    }

    /**
     * Checks whether the user can deactivate without a warning
     */
    public canDeactivate(): Observable<boolean> | boolean {
        if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Validates the data element for L1 format rules based on metadata from the API
     * @param data the DataElement to validate
     */
    public validate(data: DataElement): DataElement {
        if (data.elementValue != null && data.elementValue !== '') {
            if (this._validationService.validate(data.validationType, data.elementValue, data.maxLength || 0)) {
                data.dataEntryErrorMessage = '';
                data.questionStatus = QuestionStatusEnum.L1InProgress;
            } else {
                data.dataEntryErrorMessage = this._validationService.getErrorMessage(data.validationType);
                data.questionStatus = QuestionStatusEnum.L1InProgressError;
            }
        } else {
            data.dataEntryErrorMessage = '';
            data.questionStatus = QuestionStatusEnum.NotStarted;
        }
        return data;
    }

    /**
     * Checks all DataElement's for L1 format errors
     */
    public checkValidForm(): void {
        let valid = true;
        const tempModel = this._sectionModel;

        Object.keys(tempModel).forEach(function (key) {
            if (tempModel[key] != null && tempModel[key].questionStatus === QuestionStatusEnum.L1InProgressError) {
                valid = false;
            }
        });
        this.validForm = valid;
    }

    /**
     * Handle changes in form fields
     */
    public onChange(): void {
        this.unsavedChanges = true;
        this.checkValidForm();
    }

    /**
     * Creates options for input add ons
     * @param text the add on text
     * @param width the add on width
     */
    public createAddOnOptions(text: string, width: number): AddOnOptions {
        return {
            text: text,
            width: width
        };
    }

    /**
     *
     * @param sections
     * @returns
     */
    public printSectionDisplay(sections: Array<string>): string {
        if (sections) {
          return sections.map<string>(sectionKey => SectionLookup[sectionKey] || '').join(', ');
        } else {
          return '';
        }
      }

      /**
     * Prompts user to save unsaved changes or continue without saving
     * @param $event the event object
     */
       @HostListener('window:beforeunload', ['$event'])
       public showMsg($event) {
           if (!this.canDeactivate()) {
               $event.returnValue = `You have unsaved changes.
                   Press OK to proceed without saving these changes,
                   or press Cancel to go back and save these changes.`;
           }
       }
}
