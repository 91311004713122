export class BannerMessage {
        public bannerStartTime: string;
        public bannerEndTime: string;
        public bannerType: BannerType;
        public isActive: boolean = false;
        public type: string;
        public headerText: string;
        public descriptionHtml: string;
}

export enum BannerType {
    Success,
    Info,
    Warning,
    Error,
}
