import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { ClaimService } from '../../../shared/claim.service';
import { LookupService } from '../../../lookup.service';
import { of } from 'rxjs';

@Component({
  selector: 'acl-aaastate-review-nav',
  templateUrl: './aaastate-review-nav.component.html',
  styleUrls: ['./aaastate-review-nav.component.scss']
})
export class AaastateReviewNavComponent implements OnInit {

  state = '';
  fiscalYear = '';
  aaaName = '';
  qs: Params = {};
  display = false;

  constructor(route: ActivatedRoute, lookupService: LookupService, claimService: ClaimService) {
    route.queryParams.pipe(
      tap(params => this.qs = params),
      switchMap(() => claimService.init$),
      map(() => {
        this.state = claimService.currentOrg;
        this.fiscalYear = claimService.currentYear;
        const psa = this.qs['psa'];
        this.display = claimService.currentTitle === 'III' && claimService.currentOrgLevel === 'State' && psa;
        return psa;
      }),
      switchMap((psa) =>  psa ? lookupService.getTitleIIIAAA(this.state, psa) : of(null)),
    ).subscribe((a) => {
      if (a) {
        this.aaaName = a.name;
      }
    })
  }

  ngOnInit(): void {
  }

}
