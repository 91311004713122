import { Component, OnInit, NgZone, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';


import { ClaimService } from '../shared/claim.service';
import { CommonService } from '../shared/common.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { LoadingComponent } from '../shared/loading.component';
import { NsipSection } from './NsipSection';
import { ToastrService } from '../shared/toastr.service';
import { ComponentCanDeactivate } from '../shared/pending-changes.guard';
import { VarianceExplanation } from '../shared/varianceExplanation.model';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { DestructibleComponent } from '../destructible.component';
import { ModalButtonOption } from '../shared/Modal/modalButtonOption';
import { ModalComponent } from '../shared/Modal/modal.component';
declare var jQuery;

@Component({
  templateUrl: 'titleIIIVarianceExplanationsNSIP.component.html',
})

export class TitleIIIVarianceExplanationsNSIPComponent extends DestructibleComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;
  @ViewChild('confirmModal', { static: true }) confirmModal: ModalComponent;

  previousYear: string = '';
  currentYear: string = '';
  loadedSections: number = 0;
  disableUserInputs: boolean = false;
  previousFfynsipSection: NsipSection;
  currentFfynsipSection: NsipSection;
  unsavedChanges: boolean = false;
  goToNextSection: boolean = false;
  modalButtons: Array<ModalButtonOption> = [];
  showConfirmationPrompt = false;
  private accordionAnimations$: Subject<void> = new Subject<void>();
  lengthExceeded: boolean = false;
  isValid: boolean = true;
  formatVariance: (value: string, isDecimal:  boolean) => string;



  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _commonService: CommonService,
    public toastr: ToastrService
  ) { super(); _commonService.setSubOrg(router); }

  ngOnInit(): void {
    // this.loadingComponent.setLoadingInProgress(30);
    this.buildModalButtons();
    this._titleService.setTitle('NSIP - Explanation of Variances - State Submissions - ACL OAAPS');
    this.currentYear = this._claimService.currentYear;
    this.previousYear = (Number(this._claimService.currentYear) - 1).toString();
    this.previousFfynsipSection = new NsipSection();
    this.currentFfynsipSection = new NsipSection();
    this.getnsipserviceandexpendituresummarydtobyffyandstate();

    this.formatVariance = this._commonService.formatVariance;

  }

  ngOnDestroy(): void {
    super.destroy();
  }

  getnsipserviceandexpendituresummarydtobyffyandstate(): void {
    super.registerSubscription(this._service.get<NsipSection[]>(this._claimService, this._service._getnsipvarianceexplanationsbyffyandstate)
      .subscribe(data => this.zone.run(() => {
        if (data != null) {
          data = data.filter(element => element.batchId === '00000000-0000-0000-0000-000000000000');
          if (data.length > 0) {
            this.previousFfynsipSection = data[0];
          }
          if (data.length > 1) {
            this.currentFfynsipSection = data[1];
          }
          this.processSectionStatus();
          
        }
      })));
  }

  processSectionStatus(): void {
    this.disableUserInputs = this._claimService.tiiiStatuses.nsipStatus >= QuestionStatusEnum.Submitted;
    if (this.disableUserInputs || this._claimService.isViewOnly) {
      this._commonService.disableVarianceFormElements();
    }
  }

  formatNumber(currentValue: string): string {
    if (currentValue == null || currentValue === '') {
      return 'N/A';
    }
    currentValue = currentValue.toString().replace(/,/g, '');
    if (!isNaN(parseInt(currentValue))) {
      return parseInt(currentValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return 'N/A';
    }
  }

  formatCurrency(currentValue: string): string {
    if (currentValue == null || currentValue === '') {
      return 'N/A';
    }
    currentValue = currentValue.toString().replace(/,/g, '');
    if (!isNaN(parseInt(currentValue))) {
      return '$' + parseInt(currentValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return 'N/A';
    }
  }

  save(callback: () => void = () => null, shouldSubmit: boolean = false): void {
    this.unsavedChanges = false;
    // this.loadingComponent.setLoadingInProgress(180);
    super.registerSubscription(this._service.savensipvarianceexplanations(
      this.currentFfynsipSection, shouldSubmit)
      .subscribe(data => this.zone.run(() => {
        
        this.currentFfynsipSection = data.nsipSection;
        this.showConfirmationPrompt = data.confirmationPrompt;
        if (data.confirmationPrompt) {
          this.confirmModal.show();
        } else {
          this.toastr.success('Saved!');
        }
      }),
        (error)=> console.log(error),
        ()=>{
          // Suppress callback for confirmationPrompt
          if (!this.showConfirmationPrompt) {
            callback();
          }
          this.showConfirmationPrompt = false;
        }
      ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    this.goToNextSection = true;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIIOverview']);
    if (!this._claimService.isViewOnly
      && this._claimService.tiiiStatuses.nsipStatus < QuestionStatusEnum.Submitted) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.veLockedNav = false;
      return true;
    } else {
      this._commonService.veLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

  markAsCompleteCheckboxDisabled(veo: VarianceExplanation): boolean {
    if (this._claimService.isViewOnly || this._claimService.tiiiStatuses.nsipStatus >= QuestionStatusEnum.Submitted) {
      return true;
    }
    if (!veo.isRequired) {
      veo.isUserFinished = true;
      return true;
    }
    if (!veo.explanation) {
      veo.isUserFinished = false;
    }
    if (veo.explanation) {
      return false;
    } else {
      return true;
    }
  }

  public get aaaExplanationsActive(): boolean {
    return !this._claimService.isACLUser();
  }

  buildModalButtons(): void {
    this.modalButtons = ModalButtonOption.createActionCancelOption('Continue and submit explanations', () => {
      this.save(() => this.router.navigate(['/data-submissions/titleIIIOverview']), true);
    });
  }

  // Print

  private onAccordionOpen(e: Event): void {
    this.accordionAnimations$.next();
    jQuery(e.target).off('shown.bs.collapse');
  }

  public print(): void {
    let accordions = jQuery('.panel-collapse');
    const openAccordions = jQuery('.panel-collapse.in');
    accordions = jQuery(_.difference(accordions, openAccordions));
    if (accordions.length > 0) {
      const sub = super.registerSubscription(this.accordionAnimations$.pipe(
        skip<void>(accordions.length - 1) // Fire on the last open event
      ).subscribe(() => { window.print(); sub.unsubscribe(); })
      );
      accordions.on('shown.bs.collapse', this.onAccordionOpen.bind(this));
      accordions.collapse('show');
    } else {
      window.print();
    }
  }

  checkLength(): void {
    this.lengthExceeded = (this.currentFfynsipSection.varianceExplanation.explanation &&
      this.currentFfynsipSection.varianceExplanation.explanation.length > 3400);
    this.isValid = !this.lengthExceeded;
  }

  calculateOutlineColor(exceeded: boolean): string {
    if (exceeded == true) {
      return 'has-error';
    }else {
      return '';
    }
  }

}
