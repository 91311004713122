import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BatchSection } from '../batchSection';
import { UploadError } from './uploadError';
import { ToastrService } from '../toastr.service';
import { UploadTypeEnum, AclTitleEnum } from '../../app.constants';
import { UploadDetails } from './uploadDetails';
import { FfyStateDto } from '../genericModel';
import { exhaustMap, takeWhile } from 'rxjs/operators';
import { Subscription } from 'rxjs';

/**
 * Service for uploads
 */
@Injectable()
export class UploadService {
    // public _getbatch = '/api/v1/upload/getbatch';
    public _getbatchbyid = '/api/titlevii/batch/{id}';
    public _gettiiibatchbyid = 'api/titleiii/batch/{id}';
    public _gettvibatchbyid = 'api/titlevi/batch/{id}';
    // public _getbatchbyffy = '/api/v1/upload/getbatchbyffy';
    public _getbatchsubset = '/api/titlevii/batch/subset';
    // public _getbatchbyffyandstate = '/api/v1/upload/getbatchbyffyandstate';
    public _gettitleviibatchbyffystatetitle = 'api/titlevii/batch/get';
    public _gettitleiiibatchbyffystatetitle = 'api/titleiii/batch/get';
    public _gettitlevibatchbyffystatetitle = 'api/titlevi/batch/get';
    // public _savebatch = '/api/v1/upload/savebatch';
    public _deletetitleiiibatch = '/api/titleiii/batch/delete/{id}';
    public _deletetitleviibatch = '/api/titlevii/batch/delete/{id}';
    public _mergeBatch = 'api/upload/mergebatch';
    // public _mergeSections = '/api/v1/upload/mergesections';
    public _overwriteCasesAndComplaintsData = 'api/upload/overwritecasesandcomplaintsdata/{batchId}/{state}';
    // public _dataEntryErrors = '/api/v1/titleviidataentry/getdataentryerrormessages';

    public observableTimer = timer(0, 15000);

    public uploadError: UploadError;
    public uploadDetails: UploadDetails;

    private toastrOptions: any = { timeOut: '-1', showDuration: '-1', closeButton: true, positionClass: 'toast-top-full-width' };

    constructor(public _http: HttpClient,
        public _router: Router,
        private _toastr: ToastrService,
        private _ngZone: NgZone) {
        // Register routing function for toastr
        (<any>document).routeToErrorsPage = () => this._ngZone.run(() => this._router.navigate(['/state-submissions/errors']));
    }

    public mergeBatch(id: string, title: string, ffyStateDto: FfyStateDto, forceOverwrite: boolean = false): Observable<any> {
        ffyStateDto.title = title;
        ffyStateDto.batchId = id;
        ffyStateDto.overwrite = forceOverwrite;
        return this._http.post(this._mergeBatch, ffyStateDto, { withCredentials: true }).pipe(
            catchError(this.handleError()
            ));
    }

    public deletebatch(id: string, title: string): Observable<any> {
        let url: string = this._deletetitleviibatch;
        if (title === AclTitleEnum.III.toString()) {
            url = this._deletetitleiiibatch;
        }
        return this._http.get<any>(url.replace('{id}', id), { withCredentials: true }).pipe(
            catchError(this.handleError<any>()
            ));
    }

    public getBatchById(id: string): Observable<BatchSection> {
        return this._http.get<BatchSection>(this._getbatchbyid.replace('{id}', id), { withCredentials: true }).pipe(
            catchError(this.handleError<BatchSection>()
            ));
    }

    public getTIIIBatchById(id: string): Observable<BatchSection> {
        return this._http.get<BatchSection>(this._gettiiibatchbyid.replace('{id}', id), { withCredentials: true }).pipe(
            catchError(this.handleError<BatchSection>()
            ));
    }

    public getTVIBatchById(id: string): Observable<BatchSection> {
        return this._http.get<BatchSection>(this._gettvibatchbyid.replace('{id}', id), { withCredentials: true }).pipe(
            catchError(this.handleError<BatchSection>()
            ));
    }

    public getbatchsubset(ids: Array<string>, title: string): Observable<Array<BatchSection>> {
        return this._http.post<Array<BatchSection>>(this._getbatchsubset, ids, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<BatchSection>>()
            ));
    }

    // public getbatchbyffyandstate(ffyStateDto: FfyStateDto): Observable<Array<BatchSection>> {
    //     return this._http.post(this._getbatchbyffyandstate, { 'ffyStateDto': ffyStateDto }, { withCredentials: true })
    //         .map((response: Response) => response.json())
    //         .catch(error => this.handleError(error));
    // }

    public getbatchbyffystatetitle(ffyStateDto: FfyStateDto, title: string): Observable<Array<BatchSection>> {
        let url: string = this._gettitleviibatchbyffystatetitle;
        if (title === AclTitleEnum[AclTitleEnum.III]) {
            url = this._gettitleiiibatchbyffystatetitle;
        } else if (title === AclTitleEnum[AclTitleEnum.VI]) {
            url = this._gettitlevibatchbyffystatetitle;
        }
        return this._http.post<Array<BatchSection>>(url, ffyStateDto, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<BatchSection>>()
            ));
    }

    // public getDataEntryErrors(ffy: string, state: string): Observable<Array<BatchSection>> {
    //     return this._http.post(this._dataEntryErrors, { 'ffy': ffy, 'state': state }, { withCredentials: true })
    //         .map((response: Response) => response.json())
    //         .catch(error => this.handleError(error));
    // }

    public overwriteCCData(batchId: string, state: string): any {
        const url = this._overwriteCasesAndComplaintsData.replace('{batchId}', batchId).replace('{state}', state);
        return this._http.get<any>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<any>()
            ));
    }

    public initBatchSectionPoller(batchId: string): void {
        let jobCompleted = false;
        // Poll every 15 seconds for 5 minutes or job status changes
        // TODO long polling would be better
        const takeWhileLessThanFiveOrJobCompleted = this.observableTimer.pipe(takeWhile(val => val < 20 && !jobCompleted));
        takeWhileLessThanFiveOrJobCompleted.subscribe(ticks => {
            this.getBatchById(batchId).subscribe(batchSection => {
                if (batchSection.uploadType === UploadTypeEnum.Failed) {
                    const parts = batchSection.fileName.split('.');
                    this.uploadError = {
                        code: 1006,
                        description: batchSection.uploadError,
                        formatErrors: batchSection.caseComplaintErrors,
                        fileType: parts[parts.length - 1]
                    };
                    if (!jobCompleted) {
                        this._toastr.errorWithOptions(
                            `<span role="alert">Cases and Complaints data failed due to validation errors.</span>
                                <button tabindex="0" onClick="document.routeToErrorsPage()" class="ml-2 btn-sm btn-danger">
                                View errors</button>`,
                            this.toastrOptions
                        );
                    }
                    jobCompleted = true;
                } else if (batchSection.uploadType !== UploadTypeEnum.Pending) {
                    if (!jobCompleted) {
                        const successMsg = '<span role="alert">Cases and Complaints data was uploaded successfully.</span>';
                        this._toastr.successWithOptions(successMsg, this.toastrOptions);
                    }
                    jobCompleted = true;
                }
            });
        }
        );
    }

    private batchById$(title: AclTitleEnum, batchId: string): Observable<BatchSection> {
        switch (title) {
            case (AclTitleEnum.III):
                return this.getTIIIBatchById(batchId);
            case (AclTitleEnum.VII):
                return this.getBatchById(batchId);
            case (AclTitleEnum.VI):
                return this.getTVIBatchById(batchId);
            default:
                throw new Error('Not implemented');
        }
    }

    public pollForCompletedBatchSection(batchId: string, title: AclTitleEnum, callback: (boolean) => void): Subscription {
        let jobCompleted = false;

        // Poll for 10 minutes every 15 seconds
        const takeWhileLessThanTenOrJobCompleted$ = this.observableTimer.pipe(takeWhile(val => val < 40 && !jobCompleted));
        const getBatchById$ = this.batchById$(title, batchId);
        return takeWhileLessThanTenOrJobCompleted$.pipe(
            exhaustMap(() => getBatchById$)
        ).subscribe(data => {
            if (data) {
                const timeout = new Date(new Date(data.created).getTime() + 10 * 60 * 1000); // 10 min from created

                if (data.uploadType !== UploadTypeEnum.Pending
                    || new Date() > timeout) {
                    jobCompleted = true;
                    callback(false);
                } else {
                    callback(true);
                }
            } else {
                jobCompleted = true;
                callback(false);
            }
        });
    }

    // // Global
    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}

