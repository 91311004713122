<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <loading #loadingSpinner></loading>
      <h1>Data Submissions</h1>
      <!-- status log modal -->
      <div id="submissionLog" class="modal fade">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title">Status Log</h2>
            </div>
            <div class="modal-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Updated by</th>
                    <th scope="col">Updated on</th>
                    <th scope="col">Comments</th>
                  </tr>
                  <tr *ngFor="let logEntry of _submissionLog">
                    <td>{{ logEntry.decision == 1 ? 'Returned' : logEntry.decision == 2 ? 'Approved' : '' }}
                    </td>
                    <td>{{ logEntry.createdBy }}</td>
                    <td>
                      <p *ngIf="logEntry.created != '0001-01-01T00:00:00Z'">{{logEntry.created | date:'MM/dd/yyyy'}}</p>
                    </td>
                    <td>
                      <p style="max-width: 100px; word-wrap: break-word;">{{ logEntry.comments }}</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <button (click)="dismiss()" type="button" class="btn btn-secondary btn-small"
                data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <!-- .end status log modal -->
      <!-- pageview -->
      <page-view #pvc [pvData]="_service.overviews"></page-view>
      <div class="col-md-12 acl-container">
        <acl-review-header [model]="aclHeader"></acl-review-header>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-7">
            <div *ngIf="loaded && pvc.pageData.psa != null">
              <h2>AAA Submission Review</h2>
            </div>
            <div *ngIf="loaded && pvc.pageData.psa == null">
              <h2>State Submission Review</h2>
            </div>
          </div>
          <div class="col-md-5 mb-1">
            <div class="text-right">
              <a class="btn btn-link" (click)="_commonService.expandAccordions()" id="expandAllLnk">Expand all </a>|
              <a class="btn btn-link" (click)="_commonService.collapseAccordions()" id="collapseAllLnk"> Collapse all</a>
            </div>
          </div>
          <div class="col-md-12">
          </div>
        </div>
        <div class="panel-group accordionGroup" id="SprAccordion" role="tablist" aria-multiselectable="true">
          <div class="panel panel-default border-none">
            <div class="panel-heading collapsed" role="tab" id="sprOverviewHeading" data-toggle="collapse"
              data-target="#sprOverviewBody">
              <p class="panel-title">
                <a role="button" data-toggle="collapse" data-parent="#SprAccordion" href="#sprOverviewBody"
                  aria-expanded="true" aria-controls="sprOverviewBody" class="collapsed">
                  <span class="ico"></span>
                  <b>SPR Data</b>
                  <span class="pull-right"
                    [innerHTML]="_commonService.overallStatusHtmlWhite(pvc.pageData.sprStatus, pvc.pageData.sprIsRequired)" id="SPRStatus"></span>
                </a>
              </p>
            </div>
            <!-- end accordion heading -->
            <div id="sprOverviewBody"
              [style.background]="'#FFF'" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="sprOverviewHeading">
              <div class="flex-list panel-group">
                <a class="flex-list-item" (click)="goToSprData()" id="viewSPRDataBtn">
                  <div tabindex="0" (keydown.enter)="goToSprData()" class="panel panel-primary-inverted flex-list-content">
                    <div class="panel-body">
                      <p>
                        <strong>
                          <p>View {{viewLevel}} data</p>
                        </strong>
                      </p>
                      <hr>
                      <p>
                        View submitted {{viewLevel}} data in forms
                      </p>
                    </div>
                  </div>
                </a>
                <a class="flex-list-item" (click)="goToSprVarianceExplanations()" id="viewSPRVarianceExpBtn">
                  <div tabindex="0" (keydown.enter)="goToSprVarianceExplanations()" class="panel panel-primary-inverted flex-list-content">
                    <div class="panel-body">
                      <p>
                        <strong>
                          <p>View explanation of variances</p>
                        </strong>
                      </p>
                      <hr>
                      <p>View submitted explanations of variances</p>
                    </div>
                  </div>
                </a>
                <a class="flex-list-item" (click)="export()">
                  <div tabindex="0" (keydown.enter)="export()" class="panel panel-primary-inverted flex-list-content">
                    <div class="panel-body">
                      <p>
                        <strong>
                          <p>Export {{viewLevel}} submission</p>
                        </strong>
                      </p>
                      <hr>
                      <p>Download {{viewLevel}} data in Excel format</p>
                    </div>
                  </div>
                </a>
                <a class="flex-list-item" (click)="submissionLogs('SPR')" id="viewSPRStatusHistoryBtn">
                  <div tabindex="0" (keydown.enter)="submissionLogs('SPR')" class="panel panel-primary-inverted flex-list-content">
                    <div class="panel-body">
                      <p>
                        <strong>
                          <p>
                            View status history
                          </p>
                        </strong>
                      </p>

                      <hr>
                      <p>View a history of submission statuses</p>
                    </div>
                  </div>
                </a>
              </div>
              <!-- end row -->
              <div class="mb-1">
                <button
                  [disabled]="pvc.pageData.sprStatus !== statusEnum.Submitted || !isApprover"
                  (click)="beginReview('SPR')" class="btn btn-primary" id="beginSPRReviewBtn">Begin review</button>
              </div>
              <!-- end mb-1 -->
              <div class="mt-1 mb-1">
                <input (click)="beginReview('SPR')" type="button" class="mr-1 btn btn-primary"
                  *ngIf="pvc.pageData.sprStatus == statusEnum.Approved && (_claimService.isNavApprover || _claimService.isDataEntry)  && isApprover"
                  value="Change review decision / comments" id="changeReviewDecisionSPR"/>
                <input (click)="showConfirmationPopup('SPR', 'lock')"
                  type="button" class="btn btn-primary"
                  *ngIf="pvc.pageData.sprStatus == statusEnum.Approved && isLocker"
                  value="Lock state submission" id="lockSubmissionSPR"/>
                <input (click)="showConfirmationPopup('SPR', 'unlock')"
                  type="button" class="btn btn-primary"
                  *ngIf="pvc.pageData.sprStatus == statusEnum.Locked && isLocker"
                  value="Unlock state submission" id="unlockSubmissionSPR"/>
              </div>
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="decision">Review Decision</label>
                  <select [(ngModel)]="_sprModel.decision" #sprdecision="ngModel" id="sprdecision" name="sprdecision"
                    class="form-control"
                    [disabled]="!isSubmittedOrInReview('SPR') || !isApprover">
                    <option></option>
                    <option *ngIf="_claimService.isNavApprover || _claimService.isDataEntry" [ngValue]="1">Return
                    </option>
                    <option
                      *ngIf="pvc.pageData.sprStatus == statusEnum.InReview || pvc.pageData.sprStatus >= statusEnum.Approved"
                      [ngValue]="2">Approve</option>
                  </select>
                  <div
                    *ngIf="_sprModel.decision != 1 && _sprModel.decision != 2 && (sprdecision.dirty || sprdecision.touched)"
                    class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true">
                      <span class="sr-only">Error:</span>
                    </i>&nbsp;Decision required.
                  </div>
                </div>
              </div>
              <!-- end form-group -->
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="sprcomments">Review Comments</label>
                  <textarea id="sprComment" #sprcomments="ngModel" [(ngModel)]="_sprModel.comments" name="sprcomments"
                    class="form-control"
                    [disabled]="!isSubmittedOrInReview('SPR') || !isApprover"></textarea>
                  <small class="form-text help-block pull-right">{{_sprModel.comments?.length || 0}} of 3400
                    characters</small>
                  <div
                    *ngIf="(sprcomments.dirty || sprcomments.touched) && (_sprModel.comments?.length > 3400 || (_sprModel.comments?.length < 1 && _sprModel.decision === decisionEnum.Returned))"
                    class="alert alert-danger" role="alert">
                    <span *ngIf="_sprModel.comments?.length > 3400">
                      <i class="fa fa-times" aria-hidden="true">
                        <span class="sr-only">Error:</span>
                      </i>&nbsp;Character limit exceeded.</span>
                    <span *ngIf="(sprcomments.dirty || sprcomments.touched) && _sprModel.comments?.length < 1">
                      <i class="fa fa-times" aria-hidden="true">
                        <span class="sr-only">Error:</span>
                      </i>&nbsp;Review comments required.</span>
                  </div>
                </div>
              </div>
              <!-- end form-group -->
              <input (click)="saveReviewDecision('SPR')" type="button" class="btn btn-primary"
                [disabled]="!isFormValid(_sprModel) || !isSubmittedOrInReview('SPR') || !isApprover"
                value="Submit review decision" id="submitReviewDecisionSPR"/>
              <input *ngIf="checkForPersistentSprComment()" (click)="saveTempSprComments()"
                [disabled]="!_sprModel.comments || _sprModel.comments.length <= 0 || _sprModel.comments.length >= 3400 || !isSubmittedOrInReview('SPR') || !isApprover"
                type="button" class="btn btn-secondary ml-1" value="Save draft review" id="saveDraftReviewSPR"/>
            </div>
            <!-- end accordion body -->
          </div>
        </div>
        <!-- end accordion-->
        <div id="confirmationPopup" class="modal fade">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-title"> {{confirmationAction | titlecase}} State Submission</h2>
              </div>
              <div class="modal-body">
                <p>You are about to {{confirmationAction | titlecase}} the following {{ pvc.pageData.psa != null ?
                  'AAA' : 'state'
                  }} submission:</p>
                <div class='background-gray-lighter'>
                  <p>State:
                    <strong>{{pvc.pageData.state}}</strong>
                  </p>
                  <p>FFY:
                    <strong>{{pvc.pageData.ffy}}</strong>
                  </p>
                </div>
                <p *ngIf="confirmationAction == 'lock'">Once {{confirmationAction | titlecase}}ed ACL Approvers can no
                  longer change the review decision for that
                  state. A user with Locking capabilities must then Unlock the state to change the review decision.
                </p>
                <p *ngIf="confirmationAction == 'unlock'">Once {{confirmationAction | titlecase}}ed the {{
                  pvc.pageData.psa != null ? 'AAA' : 'state' }}'s status would
                  revert to Approved. </p>
                <p>
                  <br />Are you sure you want to continue?</p>
              </div>
              <div class="modal-footer">
                <button (click)="lockUnlockData()" type="button" class="btn btn-primary btn-small" data-dismiss="modal">
                  Yes, {{confirmationAction | titlecase}} {{ pvc.pageData.psa != null ? 'AAA' : 'state' }}
                </button>
                <button (click)="dismissConfirmationPopup()" type="button" class="btn btn-secondary btn-small"
                  data-dismiss="modal">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-group accordionGroup" id="NsipAccordion" role="tablist" aria-multiselectable="true">
          <div class="panel panel-default border-none">
            <div class="panel-heading collapsed" role="tab" id="nsipOverviewHeading" data-toggle="collapse"
              data-target="#nsipOverviewBody">
              <p class="panel-title">
                <a role="button" data-toggle="collapse" data-parent="#NsipAccordion" href="#nsipOverviewBody"
                  aria-expanded="true" aria-controls="nsipOverviewBody" class="collapsed">
                  <span class="ico"></span>
                  <b>NSIP Data</b>
                  <span class="pull-right"
                    [innerHTML]="_commonService.overallStatusHtmlWhite(pvc.pageData.nsipStatus, pvc.pageData.nsipIsRequired)" id="nsipStatus"></span>
                </a>
              </p>
            </div>
            <!-- end accordion heading -->
            <div *ngIf="pvc.pageData.nsipIsRequired && viewConstraints.isNsipViewable" id="nsipOverviewBody"
              [style.background]="'#FFF'" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="nsipOverviewHeading">
              <div class="flex-list panel-group">
                <a class="flex-list-item" (click)="goToNsipData()" id="viewNSIPDataBtn">
                  <div tabindex="0" (keydown.enter)="goToNsipData()" class="panel panel-primary-inverted flex-list-content">
                    <div class="panel-body">
                      <p>
                        <strong>
                          <p>View {{viewLevel}} data</p>
                        </strong>
                      </p>
                      <hr>
                      <p>
                        View submitted {{viewLevel}} data in forms
                      </p>
                    </div>
                  </div>
                </a>
                <a class="flex-list-item" (click)="goToNsipVarianceExplanations()" id="viewNSIPVarianceExpBtn">
                  <div tabindex="0" (keydown.enter)="goToNsipVarianceExplanations()" class="panel panel-primary-inverted flex-list-content">
                    <div class="panel-body">
                      <p>
                        <strong>
                          <p>View explanation of variances</p>
                        </strong>
                      </p>
                      <hr>
                      <p>View explanations of variances submitted</p>
                    </div>
                  </div>
                </a>
                <a class="flex-list-item" (click)="export()">
                  <div tabindex="0" (keydown.enter)="export()" class="panel panel-primary-inverted flex-list-content">
                    <div class="panel-body">
                      <p>
                        <strong>
                          <p>Export {{viewLevel}} submission</p>
                        </strong>
                      </p>
                      <hr>
                      <p>Download {{viewLevel}} data in Excel format</p>
                    </div>
                  </div>
                </a>
                <a class="flex-list-item" (click)="submissionLogs('NSIP')">
                  <div tabindex="0" (keydown.enter)="submissionLogs('NSIP')" class="panel panel-primary-inverted flex-list-content" id="viewNSIPStatusHistoryBtn">
                    <div class="panel-body">
                      <p>
                        <strong>
                          <p>
                            View status history
                          </p>
                        </strong>
                      </p>

                      <hr>
                      <p>View a history of submission statuses</p>
                    </div>
                  </div>
                </a>
              </div>
              <!-- end row -->
              <div class="mb-1">
                <button
                  [disabled]="pvc.pageData.nsipStatus !== statusEnum.Submitted || !isApprover"
                  (click)="beginReview('NSIP')" class="btn btn-primary" id="beginReviewNSIP">Begin review</button>
              </div>
              <!-- end mb-1 -->
              <div class="mt-1 mb-1">
                <input (click)="beginReview('NSIP')" type="button" class="mr-1 btn btn-primary"
                  *ngIf="pvc.pageData.nsipStatus == statusEnum.Approved && (_claimService.isNavApprover || _claimService.isDataEntry)  && isApprover"
                  value="Change review decision / comments" id="changeReviewDecNSIP"/>
                <input (click)="showConfirmationPopup('NSIP', 'lock')"
                  type="button" class="btn btn-primary"
                  *ngIf="pvc.pageData.nsipStatus == statusEnum.Approved && isLocker"
                  value="Lock state submission" id="lockSubmissionNsip"/>
                <input (click)="showConfirmationPopup('NSIP', 'unlock')"
                  type="button" class="btn btn-primary"
                  *ngIf="pvc.pageData.nsipStatus == statusEnum.Locked && isLocker"
                  value="Unlock state submission" id="unlockSubmissionNsip"/>
              </div>
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="decision">Review Decision</label>
                  <select [(ngModel)]="_nsipModel.decision" #decision="ngModel" id="decisionNsip" name="decision"
                    class="form-control"
                    [disabled]="!isSubmittedOrInReview('NSIP') || !isApprover">
                    <option></option>
                    <option *ngIf="_claimService.isNavApprover || _claimService.isDataEntry" [ngValue]="1">Return
                    </option>
                    <option
                      *ngIf="pvc.pageData.nsipStatus == statusEnum.InReview || pvc.pageData.nsipStatus >= statusEnum.Approved"
                      [ngValue]="2">Approve</option>
                  </select>
                  <div
                    *ngIf="_nsipModel.decision != 1 && _nsipModel.decision != 2 && (decision.dirty || decision.touched)"
                    class="alert alert-danger" role="alert">
                    <i class="fa fa-times" aria-hidden="true">
                      <span class="sr-only">Error:</span>
                    </i>&nbsp;Decision required.
                  </div>
                </div>
              </div>
              <!-- end form-group -->
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="commentsNsip">Review Comments</label>
                  <textarea id="nsipComment" #comments="ngModel" [(ngModel)]="_nsipModel.comments" name="comments" class="form-control"
                    [disabled]="!isSubmittedOrInReview('NSIP') || !isApprover"></textarea>
                  <small class="form-text help-block pull-right">{{_nsipModel.comments?.length || 0}} of 3400
                    characters</small>
                  <div
                    *ngIf="(comments.dirty || comments.touched) && (_nsipModel.comments?.length > 3400 || (_nsipModel.comments?.length < 1 && _nsipModel.decision === decisionEnum.Returned))"
                    class="alert alert-danger" role="alert">
                    <span *ngIf="_nsipModel.comments?.length > 3400">
                      <i class="fa fa-times" aria-hidden="true">
                        <span class="sr-only">Error:</span>
                      </i>&nbsp;Character limit exceeded.</span>
                    <span *ngIf="(comments.dirty || comments.touched) && _nsipModel.comments?.length < 1">
                      <i class="fa fa-times" aria-hidden="true">
                        <span class="sr-only">Error:</span>
                      </i>&nbsp;Review comments required.</span>
                  </div>
                </div>
              </div>
              <!-- end form-group -->
              <input (click)="saveReviewDecision('NSIP')" type="button" class="btn btn-primary"
                [disabled]="!isFormValid(_nsipModel) || !isSubmittedOrInReview('NSIP') || !isApprover"
                value="Submit review decision" id="submitReviewDecNsip"/>
              <input *ngIf="checkForPersistentNsipComment()" (click)="saveTempNsipComments()"
                [disabled]="!_nsipModel.comments || _nsipModel.comments.length <= 0 || _nsipModel.comments.length >= 3400 || !isSubmittedOrInReview('NSIP') || !isApprover"
                type="button" class="btn btn-secondary ml-1" value="Save draft review" id="saveDraftReviewNsip"/>
            </div>
            <!-- end accordion body -->
          </div>
        </div>
        <!-- end accordion-->
      </div>
      <!-- .end col-md-9 -->
      <div class="col-md-3">
        <h2 class="mb-1">Reports</h2>
        <ul class="list-unstyled">
          <li>
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
            <a (click)="generateTwoYearReport()" [attr.tabindex]="0">Two-Year Comparison Report</a>
            <p>Compare data reported across the current submission and last year</p>
          </li>
          <li>
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
            <a (click)="generateTIIIPersonsServedServiceUnitsReport()">Persons Served and Service Units Provided Under Older Adult Services by State Report</a>
            <p>Service expenditures per unit cost report for the current submission</p>
          </li>
          <li>
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
            <a (click)="generateMissingDataReport()">Missing Data Report</a>
            <p>Missing data report for the current submission</p>
          </li>
          <li>
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
            <a (click)="generateClientCharacteristicsReport()">Client Characteristics Report</a>
            <p>Client Characteristics Report for the current year</p>
          </li>
        </ul>
        <hr>
        <p>
          <a (click)="goToReport()">Generate other reports</a>
        </p>
      </div>
      <!-- .end col-md-3 -->
      <div class="col-md-12 border-top-gray">

      </div>
      <!-- .end acl-container (col-md-12) -->
    </div>
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
