import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportingCalendar } from '../../resources/resources.model';
import * as systemUpdatesData from '../../../assets/json/systemUpdates.json';

@Component({
    selector: 'systemUpdates',
    templateUrl: 'systemUpdates.component.html'
})
export class SystemUpdatesComponent implements OnInit {

    systemUpdates: ReportingCalendar[];

    constructor(public router: Router,
      public _titleService: Title) { }

    ngOnInit() {
        this._titleService.setTitle('Recent System Updates - ACL OAAPS');
        this.systemUpdates = (<any>systemUpdatesData).default;
        this.systemUpdates = this.systemUpdates.sort((left, right) => left.reportingDate < right.reportingDate ? 1 : -1);
    }

}
