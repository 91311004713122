import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { LoadingService } from '../shared/loading.service';
import { tap } from 'rxjs/operators';

const IGNORE_URLS: Array<RegExp> = [
  /api\/v1\/transaction\/?.*$/
];

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {

  constructor(private _loading: LoadingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.ignoreUrl(req.url)) {
      return next.handle(req);
    } else {
      this._loading.setLoading(true, req.url);
      return new Observable(observer => {
        let isCanceled = true;   // << here
        const sub = next.handle(req)
          .pipe(
            tap(
             (rsp: HttpResponse<any>) => {
                if (rsp instanceof HttpResponse) {
                  isCanceled = false;
                  // your decrement logic here
                  this._loading.setLoading(false, req.url);
                }
              },
              (rspError) => {
                isCanceled = false;
                // your decrement logic here
                this._loading.setLoading(false, req.url);
                throw(rspError); // re-throw same e
              },
            ),
          )
          .subscribe(observer);  // << here
        return () => {
          if (isCanceled) {
            // your decrement logic here
            this._loading.setLoading(false, req.url);
            sub.unsubscribe();
          }
        };
      });
    }
}

  private ignoreUrl(url: string){
    return IGNORE_URLS.some(regex => regex.test(url));
  }

}
