<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <div class="no-print row">
          <div class="col-md-12">
            <h2 *ngIf="id == 'T3' || id == 'T6'" class="no-print">Validation Errors and Warnings</h2>
            <h2 *ngIf="id == 'T7'" class="no-print">Validation Errors</h2>
            <div class="alert alert-danger col-md-12 mt-1" role="alert">
              <i aria-hidden="true" class="fa fa-2x fa-exclamation-triangle pull-left">
                <span class="sr-only">Error:</span>
              </i>
              The following warning(s) or error(s) were found during validation. Please correct all warnings or errors indicated below and try validating the section(s) again.
            </div>
          </div>
        </div>
        <loading #loadingSpinner></loading>
        <div class="col-md-12 table-responsive">
          <!--need to change user_friendly_name property for each section in title 3 in order to get filtering to work. All names need to have top-level section prefix.
                        Ex.
                        "Network Funding" -> "Aging Network - Network Funding"-->
          <div class="row">
            <div *ngIf="id == 'T7'" class="col-md-12 form-inline mb-1">
              <label for="filterT7Section">
                Filter section:
                <select id="filterT7Section" [(ngModel)]="filterQuery.query" class="form-control">
                  <option value="">All Sections</option>
                  <option value="Cases and Complaint">Cases and Complaints</option>
                  <option value="Complaint Example">Complaint Examples</option>
                  <option value="Systems Issue">Systems Issues</option>
                  <option value="Organizational Structure">Organizational Structure</option>
                  <option value="Staff and Volunteer">Staff and Volunteers</option>
                  <option value="Organizational Conflicts of Interest">Organizational Conflict of Interest</option>
                  <option value="Funds Expended">Funds Expended</option>
                  <option value="Facility">Facility</option>
                  <option value="Program Activities">Program Activities</option>
                </select>
              </label>
              <button id="printErrorsBtn" class="btn btn-default p-h no-print pull-right" (click)='print()'>
                <i class="fa fa-print" aria-hidden="true"></i> Print all
              </button>
            </div>
            <div *ngIf="id == 'T3'" class="col-md-12 form-inline mb-1">
              <label for="filterT3Section">
                Filter section:
                <select id="filterT3Section" [(ngModel)]="filterQuery.query" class="form-control">
                  <option [value]="opt.key" *ngFor="let opt of sectionOptions">{{opt.value}}</option>
                </select>
              </label>
              <label for="filterErrorType">
                Issue Type:
                <select id="filterErrorType" [(ngModel)]="filterQuery.errorType" class="form-control">
                  <option value="">All Issues</option>
                  <option value="error">Only Errors</option>
                  <option value="warning">Only Warnings</option>
                </select>
              </label>
              <button id="printErrorsBtn" class="btn btn-default p-h no-print pull-right" (click)='print()'>
                <i class="fa fa-print" aria-hidden="true"></i> Print all
              </button>
            </div>
            <div *ngIf="id == 'T6'" class="col-md-12 form-inline mb-1">
              <label for="filterT6Section">
                Filter section:
                <select id="filterT6Section" [(ngModel)]="filterQuery.sectionName" class="form-control">
                  <option value="">All Sections</option>
                  <option value="staffInfoAB">Part A/B: Staffing Information</option>
                  <option value="nutritionServices">Part A/B: Nutrition Services</option>
                  <option value="SupportiveServicesOverview">Part A/B: Supportive Services</option>
                  <option value="financeAB">Part A/B: Finance</option>
                  <option value="storytelling">Part A/B: Storytelling</option>
                  <option value="staffInfoC">Part C: Staffing Information</option>
                  <option value="caregiverChars">Part C: Total Caregivers Served</option>
                  <option value="CaregiverSupportServicesOverview">Part C: Caregiver Support Services</option>
                  <option value="financeC">Part C: Finance</option>
                </select>
              </label>
              <label for="filterErrorType">
                Issue Type:
                <select id="filterErrorType" [(ngModel)]="filterQuery.errorType" class="form-control">
                  <option value="">All Issues</option>
                  <option value="error">Only Errors</option>
                  <option value="warning">Only Warnings</option>
                </select>
              </label>
              <button id="printErrorsBtn" class="btn btn-default p-h no-print pull-right" (click)='print()'>
                <i class="fa fa-print" aria-hidden="true"></i> Print all
              </button>
            </div>
            <!-- end form-inline -->
          </div>
          <table class="table mb-4 table-striped" [mfData]="formatErrors | dataFilter : filterQuery" #mf="mfDataTable"
            [mfRowsOnPage]="rowsOnPage" [(mfSortBy)]="sortBy" [(mfSortOrder)]="sortOrder">
            <thead>
              <tr class="background-gray-lighter">
                <th *ngIf="id == 'T6'" hidden>
                  <mfDataTableSorter by="sectionName"></mfDataTableSorter>
                </th>
                <th *ngIf="id == 'T6'" width="5%">
                  <mfDataTableSorter by="grantType">Grant Type</mfDataTableSorter>
                </th>
                <th width="10%">
                  <mfDataTableSorter by="componentFriendlyName">Component/Section</mfDataTableSorter>
                </th>
                <th width="10%">
                  <mfDataTableSorter by="fieldFriendlyName">Field</mfDataTableSorter>
                </th>
                <th width="10%">
                  <mfDataTableSorter by="fieldValue">Value</mfDataTableSorter>
                </th>
                <th width="15%">
                  <mfDataTableSorter by="status">Issue Type</mfDataTableSorter>
                </th>
                <th *ngIf="id != 'T6'"  width="45%">
                  <mfDataTableSorter by="errorMessage">Issue Description</mfDataTableSorter>
                </th>
                <th *ngIf="id == 'T6'"  width="40%">
                  <mfDataTableSorter by="errorMessage">Issue Description</mfDataTableSorter>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let error of mf.data; let i = index">
                <td *ngIf="id == 'T6'" width="5%">{{error.grantType}}</td>
                <td [id]="'errorLink' + i" width="10%">
                  <a
                    [routerLink]="calculateRoute(error?.sectionName || error?.componentFriendlyName)">{{error.componentFriendlyName}}</a>
                </td>
                <td width="10%">{{error.fieldFriendlyName}}</td>
                <td width="10%">{{error.fieldValue}}</td>
                <td width="15%">
                  <span *ngIf="error.status == 1 || error.status == 2" class="text-danger">
                    <i class='fa fa-times' aria-hidden='true'></i> Error
                  </span>
                  <span *ngIf="error.status == 4" class="text-warning">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning
                  </span>
                </td>
                <td *ngIf="id != 'T6'"  width="45%">{{error.errorMessage}}</td>
                <td *ngIf="id == 'T6'"  width="40%">{{error.errorMessage}}</td>
              </tr>
            </tbody>
            <tfoot class="no-print">
              <tr>
                <td colspan="5" class="m-0 p-0">
                  <mfBootstrapPaginator [rowsOnPageSet]="[]"></mfBootstrapPaginator>
                </td>
              </tr>
            </tfoot>
            <tfoot class="no-print" *ngIf="mf.data.length == 0">
              <tr>
                <td colspan="5" class="m-0 p-0">
                  No errors to display for this section.
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="no-print">
          <a (click)='returnToOverview()' class="btn">Return to Overview</a>
        </div>
      </div>
      <!-- end table-responsive -->
    </div>
    <!-- .end col-md-9 -->
  </div>
  <!-- .end acl-container (col-md-12) -->
</div>
<!-- ./container -->
