import { Injectable } from '@angular/core';
// import { AuthHttp } from '../../../node_modules/angular2-jwt';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ErrorLogger {
    readonly LogNgErrorEndpoint = '/api/v1/error/trackngerror';

    constructor(private http: HttpClient) { }

    public logError(code: number, message: string) {
        return this.http.post(this.LogNgErrorEndpoint, { code: 'NG' + code, error: message }, { withCredentials: true });
    }

    public handleError(message: string) {
        console.log(message);
    }
}
