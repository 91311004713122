import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, interval } from 'rxjs';
import { catchError, finalize, takeWhile, exhaustMap, switchMap, tap } from 'rxjs/operators';

import { BasicErrorDetail } from '../shared/Upload/uploadError';
import { QuestionStatusEnum } from '../shared/dataElement';
// Models
import { ANStaffAndVolunteersSection } from './ANStaffAndVolunteersSection';
import { ANServiceProvidersSection } from './ANServiceProvidersSection';
import { ANSeniorCentersSection } from './ANSeniorCentersSection';
import { ANSelfDirectionSection } from './ANSelfDirectionSection';
import { ANRespiteSection } from './ANRespiteSection';
import { ANNetworkFundingSection } from './ANNetworkFundingSection';
// import { PersonalCareUnitsSection } from './personalCareUnitsSection';
import { OAConsumerTpsSection } from './OAConsumerTpsSection';
import { ServicesProvidedSection } from './ServicesProvidedSection';
import { HomeDeliveredMealsCharsSection } from './HomeDeliveredMealsCharsSection';
import { HomeDeliveredMealsUnitsSection } from './HomeDeliveredMealsUnitsSection';
import { UserDefaultStates } from '../shared/userDefaultStates';
import { TIIIUserNotifications } from './tIIIUserNotifications';
import { AssistedTransportationCharsSection } from './AssistedTransportationCharsSection';
import { AssistedTransportationUnitsSection } from './AssistedTransportationUnitsSection';
import { CongregateMealsUnitsSection } from './CongregateMealsUnitsSection';
import { NutritionCounselingUnitsSection } from './NutritionCounselingUnitsSection';
import { OALegalCasesSection } from './OALegalCasesSection';
import { InfoAndAssistanceSection } from './InfoAndAssistanceSection';
import { HealthPromoEvidenceSection } from './HealthPromoEvidenceSection';
import { HealthPromoNonEvidenceSection } from './HealthPromoNonEvidenceSection';
import { COAConsumerSummarySection } from './COAConsumerSummarySection';
import { ORCConsumerSummarySection } from './ORCConsumerSummarySection';
import { CaregiverPagesSection } from './CaregiverPagesSection';
import { CGRespiteSection } from './CGRespiteSection';
import { CGSuppExpendSection, TitleIIIOtherExpend } from './CGSuppExpendSection';
import { TitleVIIExpendituresSection } from './TitleVIIExpendituresSection';
import { NsipSection } from './NsipSection';
import { MultipleUnitsSection } from './MultipleUnitsSection';
import { FourColNoNutritionCharsSection } from './FourColNoNutritionCharsSection';
import { TwoColCharsSection } from './TwoColCharsSection';
import { BatchSection } from '../shared/batchSection';
import { ITIIIOverview, TIIIOverview, TIIITopSectionTypeEnum } from '../shared/section';
import { OAServiceAndExpenditureSummaryDto } from './OAServiceAndExpenditureSummaryDto';
import { CGServiceAndExpenditureSummaryDto } from './CGServiceAndExpenditureSummaryDto';
import { YearApprovalStatus } from '../shared/yearApprovalStatus.model';
import { DataSubmissionResponsibility, StateProfile } from './Profile/submissionResponsibility';
import { ClaimService } from '../shared/claim.service';
import { LoadingComponent } from '../shared/loading.component';
import { FfyStateDto } from '../shared/genericModel';
import { ToastrService } from '../shared/toastr.service';
import { FfyStatesDto } from './Dto/FfyStatesDto';
import { OAExpenditureModelDto } from './OAExpenditureModelDto';
import { LoadingService } from './../shared/loading.service';
import { IRollupSource, RollupSource } from '../UserManagement/userManagerObjects';
import { NutritionEduInfoSection } from './NutritionEduInfoSection';

@Injectable()
export class TitleIIIDataEntryService {

  constructor(public _http: HttpClient,
    private _loadingService: LoadingService,
    public _claimService: ClaimService,
    public _toastrService: ToastrService) {
  }

  // AAAState Edit AAA data
  public _aaaStateEditMode: boolean = false;
  public get aaaStateEditMode(): boolean { return this._aaaStateEditMode; }
  public set aaaStateEditMode(isEditting: boolean) { this._aaaStateEditMode = isEditting; }

  // Resources

  // Rollup
  public readonly _rollupStatusEndpoint = 'api/titleiii/rollup/status';
  public readonly _rollupEndpoint = 'api/titleiii/rollup/{ffy}/{state}';
  public readonly _rollupApiRoot = 'api/titleiii/rollup';
  public _rollupLock = '/api/titleiii/rollup/lock';

  // Overview
  public _getOverview = 'api/titleiii/overview/{ffy}/{state}/{psa}';
  public _getRollupOverview = 'api/titleiii/overview/rollup/{ffy}/{state}';
  public _getStatesOverview = 'api/titleiii/overview/{ffy}';
  public _getAAAStateOverview = 'api/titleiii/overview/aaa/{ffy}/{state}';
  public _calculateVariance = 'api/titleiii/overview/variance';
  public _submitForReview = 'api/titleiii/overview/submit';
  public _validateBusinessRules = 'api/titleiii/overview/validate';
  public _dataEntryErrors = 'api/titleiii/overview/errors';
  public _review = 'api/titleiii/overview/review';
  public _approve = 'api/titleiii/overview/approve';
  public _return = 'api/titleiii/overview/return';
  public _lock = 'api/titleiii/overview/lock';
  public _unlock = 'api/titleiii/overview/unlock';

  // AN Staff and Volunteers
  public _getanstaffandvolunteersbyffyandstate = 'api/titleiii/anstaffandvolunteers/get';
  public _saveanstaffandvolunteers = 'api/titleiii/anstaffandvolunteers';

  // AN Service Providers
  public _getanserviceprovidersbyffyandstate = 'api/titleiii/anserviceproviders/get';
  public _saveanserviceproviders = 'api/titleiii/anserviceproviders';
  public _deleteanserviceproviders = 'api/titleiii/anserviceproviders/delete/{id}';

  // AN Senior Centers and Focal Points
  public _getanseniorcentersbyffyandstate = 'api/titleiii/anseniorcenters/get';
  public _saveanseniorcenters = 'api/titleiii/anseniorcenters';

  // AN Self-Direction
  public _getanselfdirectionbyffyandstate = 'api/titleiii/anselfdirection/get';
  public _saveanselfdirection = 'api/titleiii/anselfdirection';

  // AN Respite Voucher
  public _getanrespitebyffyandstate = 'api/titleiii/anrespite/get';
  public _saveanrespite = 'api/titleiii/anrespite';

  // AN Networkork Funding
  public _getannetworkfundingbyffyandstate = 'api/titleiii/annetworkfunding/get';
  public _saveannetworkfunding = 'api/titleiii/annetworkfunding';

  // OA Consumer Summary TPS
  public _getoaconsumertpsbyffyandstate = 'api/titleiii/oaconsumertps/get';
  public _saveoaconsumertps = 'api/titleiii/oaconsumertps';

  // Services Provided
  public _getservicesprovidedbyffyandstate = 'api/titleiii/servicesprovided/get';
  public _saveservicesprovided = 'api/titleiii/servicesprovided';
  public _deleteServicesprovided = 'api/titleiii/servicesprovided/delete/{id}';

  // Home Delivered Meals Chars
  public _gethomedeliveredmealscharsbyffyandstate = 'api/titleiii/homedeliveredmealschars/get';
  public _savehomedeliveredmealschars = 'api/titleiii/homedeliveredmealschars';

  // Home Delivered Meals Units
  public _gethomedeliveredmealsunitsbyffyandstate = 'api/titleiii/homedeliveredmealsunits/get';
  public _savehomedeliveredmealsunits = 'api/titleiii/homedeliveredmealsunits';

  // Assisted Transportation Chars
  public _getassistedtransportationcharsbyffyandstate = 'api/titleiii/assistedtransportationchars/get';
  public _saveassistedtransportationchars = 'api/titleiii/assistedtransportationchars';

  // Assisted Transportation Units
  public _getassistedtransportationunitsbyffyandstate = 'api/titleiii/assistedtransportationunits/get';
  public _saveassistedtransportationunits = 'api/titleiii/assistedtransportationunits';

  // Congregate Meals Units
  public _getcongregatemealsunitsbyffyandstate = 'api/titleiii/congregatemealsunits/get';
  public _savecongregatemealsunits = 'api/titleiii/congregatemealsunits';

  // Nutrition Counseling Units
  public _getnutritioncounselingunitsbyffyandstate = 'api/titleiii/nutritioncounselingunits/get';
  public _savenutritioncounselingunits = 'api/titleiii/nutritioncounselingunits';

  // Legal Cases
  public _getoalegalcasesbyffyandstate = 'api/titleiii/oalegalcases/get';
  public _saveoalegalcases = 'api/titleiii/oalegalcases';

  // Info and Assistance
  public _getinfoandassistancebyffyandstate = 'api/titleiii/infoandassistance/get';
  public _saveinfoandassistance = 'api/titleiii/infoandassistance';

  // Nutrition Education
  public _getnutritioneducationbyffyandstate = 'api/titleiii/nutritioneducation/get';
  public _savenutritioneducation = 'api/titleiii/nutritioneducation';
  
  // Health Promo Evidence Based
  public _gethealthpromoevidencebyffyandstate = 'api/titleiii/healthpromoevidence/get';
  public _savehealthpromoevidence = 'api/titleiii/healthpromoevidence';

  // Health Promo Non Evidence Based
  public _gethealthpromononevidencebyffyandstate = 'api/titleiii/healthpromononevidence/get';
  public _savehealthpromononevidence = 'api/titleiii/healthpromononevidence';

  // COA Consumer summary page
  public _getcoaconsumersummarybyffyandstate = 'api/titleiii/coaconsumersummary/get';
  public _savecoaconsumersummary = 'api/titleiii/coaconsumersummary';

  // ORC Consumer summary page
  public _getorcconsumersummarybyffyandstate = 'api/titleiii/orcconsumersummary/get';
  public _saveorcconsumersummary = 'api/titleiii/orcconsumersummary';

  // Caregiver Expenditure Pages
  public _getcaregiverpagesbyffyandstate = 'api/titleiii/caregiverpages/get';
  public _savecaregiverpages = 'api/titleiii/caregiverpages';

  // Caregiver Respite Pages
  public _getcgrespitebyffyandstate = 'api/titleiii/cgrespite/get';
  public _savecgrespite = 'api/titleiii/cgrespite';
  public _deletecgrespite = 'api/titleiii/cgrespite/delete/{id}';
  public _getcgsuppexpendbyffyandstate = 'api/titleiii/cgsuppexpend/get';
  public _savecgsuppexpend = 'api/titleiii/cgsuppexpend';
  public _deletecgsuppexpend = 'api/titleiii/cgsuppexpend/delete/{id}';

  public _gettitleiiiotherexpendbyffyandstate = 'api/titleiii/titleiiiotherexpend/get';
  public _savetitleiiiotherexpendbyffyandstate = 'api/titleiii/titleiiiotherexpend';

  // title 7 expenditures
  public _gettitleviiexpendituresbyffyandstate = 'api/titleiii/titleviiexpenditures/get';
  public _savetitleviiexpenditures = 'api/titleiii/titleviiexpenditures';
  public _deletetitleviiexpenditures = 'api/titleiii/titleviiexpenditures/delete/{id}';

  // nsip
  public _getnsipbyid = 'api/titleiii/nsip/{id}';
  public _getnsipbyffyandstate = 'api/titleiii/nsip/get';
  public _savensip = 'api/titleiii/nsip';
  public _deletensip = 'api/titleiii/nsip/delete/{id}';

  // Multiple Units
  public _getmultipleunitsbyid = 'api/titleiii/multipleunits/{id}';
  public _getmultipleunitsbyffyandstate = 'api/titleiii/multipleunits/get';
  public _savemultipleunits = 'api/titleiii/multipleunits';
  public _deletemultipleunits = 'api/titleiii/multipleunits/delete/{id}';

  // four columns no nutrition chars pages
  public _getfourcolnonutritioncharsbyid = 'api/titleiii/fourcolnonutritionchars/{id}';
  public _getfourcolnonutritioncharsbyffyandstate = 'api/titleiii/fourcolnonutritionchars/get';
  public _savefourcolnonutritionchars = 'api/titleiii/fourcolnonutritionchars';
  public _deletefourcolnonutritionchars = 'api/titleiii/fourcolnonutritionchars/delete/{id}';

  // two columns
  public _gettwocolcharsbyid = 'api/titleiii/twocolchars/{id}';
  public _gettwocolcharsbyffyandstate = 'api/titleiii/twocolchars/get';
  public _savetwocolchars = 'api/titleiii/twocolchars';
  public _deletetwocolchars = 'api/titleiii/twocolchars/delete/{id}';

  // Batch
  public _getbatch = 'api/titleiii/batch';
  public _getbatchbyid = 'api/titleiii/batch/{id}';
  public _savebatch = 'api/titleiii/batch';
  public _deletebatch = 'api/titleiii/batch/delete/{id}';

  // Export
  // public _exportTitleIIIData = 'api/titleiii/export';

  // Variance
  public _getoavarianceexplanationsbyffyandstate = 'api/titleiii/variance/olderAdults';
  public _saveoavarianceexplanations = 'api/titleiii/variance/status/olderAdults';
  public _getcgvarianceexplanationsbyffyandstate = 'api/titleiii/variance/coa';
  public _savecgvarianceexplanations = 'api/titleiii/variance/status/coa';
  public _getorvarianceexplanationsbyffyandstate = 'api/titleiii/variance/orc';
  public _saveorvarianceexplanations = 'api/titleiii/variance/status/orc';
  public _getnsipvarianceexplanationsbyffyandstate = 'api/titleiii/variance/nsip';
  public _savensipvarianceexplanations = 'api/titleiii/variance/status/nsip';

  // Year Approval Status
  public _getYearApprovalStatus = 'api/titleiiiapproval/get';
  public _getNonPSAActiveYearApprovalStatus = 'api/titleiiiapproval/active/{state}';
  public _getPSAActiveYearApprovalStatus = 'api/titleiiiapproval/active/{state}/{psa}';
  public _getLatestYearApprovalStatus = 'api/titleiiiapproval/latest';
  public _getPreviousYearApprovalStatus = 'api/titleiiiapproval/previous';
  public _postYearApprovalStatus = 'api/titleiiiapproval';

  // user default states
  public _titleiiiUserDefaultStates = 'api/titleiii/userdefaultstates';

  // user notification
  public _gettitleiiiUserPreferences = 'api/titleiii/usernotification/{upn}';
  public _savetitleiiiUserPreferences = 'api/titleiii/usernotification/save';

  // reporting periods
  public _gettitleiiireportingperiods = 'api/titleiii/reportingperiod/all/{isAclUser}';
  public _getreportingperiodbyffy = 'api/titleiii/reportingperiod/{ffy}';
  public _getmaxreportingyear = 'api/titleiii/reportingperiod/maxyear/{isAclUser}';

  // state profile
  _getSubmissionResponsibility = 'api/titleiii/stateprofile/{ffy}/{state}';
  _saveSubmissionResponsibility = 'api/titleiii/stateprofile';
  _deleteSubmissionResponsibility = 'api/titleiii/stateprofile/delete/{ffy}/{state}';

  // expenditures
  _getOAExpenditureDto = 'api/titleiii/expenditure/get';
  _saveOAExpenditureDto = 'api/titleiii/expenditure/save';

  // To hold selections from the data submissions screen
  public _overviews: TIIIOverview[] = [];
  public set overviews(overviews: TIIIOverview[]) {
    this._overviews = overviews;
  }
  public get overviews(): TIIIOverview[] {
    return this._overviews;
  }
  // SSReview Calls

  batch(action: QuestionStatusEnum, payload: FfyStatesDto): Observable<any> {
    // Resolve correct uri for action
    let uri: string;
    switch (action) {
      case QuestionStatusEnum.Submitted:
        uri = this._review;
        break;
      case QuestionStatusEnum.Approved:
        uri = this._lock;
        break;
      case QuestionStatusEnum.Locked:
        uri = this._unlock;
        break;
    }
    uri += '/batch';
    return this._http.post(uri, payload, { withCredentials: true }).pipe(
      catchError(this.handleError()
      ));
  }

  review(ffy: string, state: string, psa: string, isRollup: boolean, flowType: string): Observable<any> {
    const model = new YearApprovalStatus();
    model.fiscalYear = ffy;
    model.state = state;
    model.psa = psa;
    model.isRollup = isRollup;
    model.titleIIISectionType = flowType;
    model.status = QuestionStatusEnum.InReview;
    model.upn = this._claimService.userID;
    return this._http.post(this._review, model, { withCredentials: true }).pipe(
      catchError(this.handleError()
      ));
  }

  approve(model: YearApprovalStatus): Observable<any> {
    let url = this._approve;
    model.upn = this._claimService.userID;
    if(model.status === QuestionStatusEnum.Returned){
      url = this._return;
    }
    return this._http.post(url, model, { withCredentials: true }).pipe(
      catchError(this.handleError()
      ));
  }

  lock(ffy: string, state: string, isRollup: boolean, flowType: string): Observable<any> {
    const model = new YearApprovalStatus();
    model.fiscalYear = ffy;
    model.state = state;
    model.isRollup = isRollup;
    model.status = QuestionStatusEnum.Locked;
    model.titleIIISectionType = flowType;
    model.upn = this._claimService.userID;
    return this._http.post(this._lock, model, { withCredentials: true }).pipe(
      catchError(this.handleError()
      ));
  }

  unlock(ffy: string, state: string, isRollup: boolean, flowType: string): Observable<any> {
    const model = new YearApprovalStatus();
    model.fiscalYear = ffy;
    model.state = state;
    model.isRollup = isRollup;
    model.titleIIISectionType = flowType;
    model.status = QuestionStatusEnum.Approved;
    model.upn = this._claimService.userID;
    return this._http.post(this._unlock, model, { withCredentials: true }).pipe(
      catchError(this.handleError()
      ));
  }

  submitForReview(ffy: string, state: string, psa: string, isRollup: boolean, flowType: string): Observable<any> {
    const model = new YearApprovalStatus();
    model.fiscalYear = ffy;
    model.state = state;
    model.psa = psa;
    model.isRollup = isRollup;
    model.status = QuestionStatusEnum.InReview;
    model.titleIIISectionType = flowType;
    model.upn = this._claimService.userID;
    return this._http.post(this._submitForReview, model, { withCredentials: true }).pipe(
      catchError(this.handleError()
      ));
  }


  getYearApprovalStatusByFfyAndState(ffy: string, state: string, psa: string, isRollup: boolean, flowType: string)
    : Observable<Array<YearApprovalStatus>> {
    const model = new YearApprovalStatus();
    model.fiscalYear = ffy;
    model.state = state;
    model.psa = psa;
    model.isRollup = isRollup;
    model.titleIIISectionType = flowType;
    model.upn = this._claimService.userID;
    return this._http.post<Array<YearApprovalStatus>>(this._getYearApprovalStatus, model, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<YearApprovalStatus>>())
    );
  }

  getActiveYearApprovalStatusByState(state: string, psa: string): Observable<Array<YearApprovalStatus>> {
    let url = this._getNonPSAActiveYearApprovalStatus;
    if (psa !== null) {
      url = this._getPSAActiveYearApprovalStatus.replace('{psa}', psa);
    }
    return this._http.get<Array<YearApprovalStatus>>(url.replace('{state}', state), { withCredentials: true }).pipe(
      catchError(this.handleError<Array<YearApprovalStatus>>())
    );
  }

  saveYearApprovalStatus(model: YearApprovalStatus): Observable<YearApprovalStatus> {
    return this._http.post<YearApprovalStatus>(this._postYearApprovalStatus, model, { withCredentials: true }).pipe(
      catchError(this.handleError<YearApprovalStatus>())
    );
  }

  getPreviousYearApprovalStatusByFfyAndState(ffy: string, state: string, psa: string, isRollup: boolean)
    : Observable<Array<YearApprovalStatus>> {
    const model = new YearApprovalStatus();
    model.fiscalYear = ffy;
    model.state = state;
    model.psa = psa;
    model.isRollup = isRollup;
    return this._http.post<Array<YearApprovalStatus>>(this._getPreviousYearApprovalStatus, model, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<YearApprovalStatus>>())
    );
  }


  getUserDefaultStates(): Observable<any> {
    const url = this._titleiiiUserDefaultStates;
    return this._http.get<any>(url, { params: { upn: this._claimService.userID }, withCredentials: true }).pipe(
      catchError(this.handleError<any>())
    );
  }

  // getRollupSource(ffy: string, org: string): Observable<IRollupSource> {
  //   return this._http.get<IRollupSource>(this._rollupApiRoot + '/rollupsource',
  //     { params: { fiscalYear: ffy, org: org }, withCredentials: true }).pipe(
  //       catchError(this.handleError<any>())
  //     );
  // }

  getRollupSource(ffy: string, org: string): Observable<IRollupSource> {
    const url: string = this._rollupApiRoot + '/source';
    let model: IRollupSource = new RollupSource();
    model.fiscalYear = ffy;
    model.org = org;
    return this._http.post<IRollupSource>(url, model, { withCredentials: true }).pipe(
        catchError(this.handleError<any>())
      );
  }

  saveUserDefaultStates(model: UserDefaultStates): Observable<UserDefaultStates> {
    const url = this._titleiiiUserDefaultStates;
    return this._http.post<UserDefaultStates>(url, model, { withCredentials: true }).pipe(
      catchError(this.handleError<UserDefaultStates>())
    );
  }

  findUserNotificationPreferences(): Observable<any> {
    const url = this._gettitleiiiUserPreferences.replace('{upn}', this._claimService.userID);
    return this._http.get(url,
      { params: { upn: this._claimService.userID }, withCredentials: true }).pipe(
        catchError(this.handleError())
      );
  }

  saveUserNotifications(model: TIIIUserNotifications): Observable<TIIIUserNotifications> {
    const url = this._savetitleiiiUserPreferences;
    return this._http.post<TIIIUserNotifications>(url, model, { withCredentials: true }).pipe(
      catchError(this.handleError<TIIIUserNotifications>())
    );
  }

  // Service calls
  // Overview call
  getOverviewByFfyAndState(ffy: string, state: string, psa: string): Observable<ITIIIOverview> {
    const url = this._getOverview.replace('{ffy}', ffy).replace('{state}', state).replace('{psa}', psa);
    return this._http.get<ITIIIOverview>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<ITIIIOverview>())
    );
  }
  getRollupOverviewByFfyAndState(ffy: string, state: string): Observable<ITIIIOverview> {
    const url = this._getRollupOverview.replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<ITIIIOverview>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<ITIIIOverview>())
    );
  }
  getOverviewByFfy(ffy: string): Observable<Array<ITIIIOverview>> {
    const url = this._getStatesOverview.replace('{ffy}', ffy);
    return this._http.get<Array<ITIIIOverview>>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<ITIIIOverview>>())
    );
  }
  getAAAOverviewByFfyAndState(ffy: string, state: string): Observable<Array<ITIIIOverview>> {
    return this._http.get<Array<ITIIIOverview>>(this._getAAAStateOverview.replace('{ffy}', ffy)
      .replace('{state}', state), { withCredentials: true }).pipe(
        catchError(this.handleError<Array<ITIIIOverview>>())
      );
  }
  calculateVariance(ffy: string, state: string, psa: string, isRollup: boolean,
    flowType: string, isSubmissionPullback: boolean = false): Observable<any> {
    const model = new YearApprovalStatus();
    model.fiscalYear = ffy;
    model.state = state;
    model.psa = psa;
    model.isRollup = isRollup;
    model.titleIIISectionType = flowType;
    model.isSubmissionPullback = isSubmissionPullback;
    model.serviceId = 'NSIP' === flowType ? TIIITopSectionTypeEnum.nsipSection : TIIITopSectionTypeEnum.Spr;
    return this._http.post(this._calculateVariance, model, { withCredentials: true }).pipe(
      catchError(this.handleError())
    );
  }

  validateBusinessRules(ffy: string, state: string, psa: string, isRollup: boolean, sectionId: TIIITopSectionTypeEnum): Observable<any> {
    const model = new YearApprovalStatus();
    model.fiscalYear = ffy;
    model.state = state;
    model.psa = psa;
    model.isRollup = isRollup;
    model.serviceId = sectionId;
    return this._http.post(this._validateBusinessRules, model, { withCredentials: true }).pipe(
      catchError(this.handleError())
    );
  }

  // Staff and Volunteers
  saveanstaffandvolunteers(model: ANStaffAndVolunteersSection): Observable<ANStaffAndVolunteersSection> {
    const url = this._saveanstaffandvolunteers;
    return this._http.post<ANStaffAndVolunteersSection>(url, model, { withCredentials: true }).pipe(
      catchError(this.handleError<ANStaffAndVolunteersSection>())
    );
  }

  // Service Providers
  saveanserviceproviders(model: ANServiceProvidersSection): Observable<ANServiceProvidersSection> {
    const url = this._saveanserviceproviders;
    return this._http.post<ANServiceProvidersSection>(url, model, { withCredentials: true }).pipe(
      catchError(this.handleError<ANServiceProvidersSection>())
    );
  }

  // Senior Centers and Focal Points
  saveanseniorcenters(model: ANSeniorCentersSection): Observable<ANSeniorCentersSection> {
    const url = this._saveanseniorcenters;
    return this._http.post<ANSeniorCentersSection>(url, model, { withCredentials: true }).pipe(
      catchError(this.handleError<ANSeniorCentersSection>())
    );
  }

  // Self-Direction
  saveanselfdirection(model: ANSelfDirectionSection): Observable<ANSelfDirectionSection> {
    const url = this._saveanselfdirection;
    return this._http.post<ANSelfDirectionSection>(url, model, { withCredentials: true }).pipe(
      catchError(this.handleError<ANSelfDirectionSection>())
    );
  }

  // Respite Vouchers
  saveanrespite(model: ANRespiteSection): Observable<ANRespiteSection> {
    return this._http.post<ANRespiteSection>(this._saveanrespite, model, { withCredentials: true }).pipe(
      catchError(this.handleError<ANRespiteSection>())
    );
  }


  // Network Funding
  saveannetworkfunding(model: ANNetworkFundingSection): Observable<ANNetworkFundingSection> {
    return this._http.post<ANNetworkFundingSection>(this._saveannetworkfunding, model, { withCredentials: true }).pipe(
      catchError(this.handleError<ANNetworkFundingSection>())
    );
  }

  // OA Consumer Summary TPS
  saveoaconsumertps(model: OAConsumerTpsSection): Observable<OAConsumerTpsSection> {
    return this._http.post<OAConsumerTpsSection>(this._saveoaconsumertps, model, { withCredentials: true }).pipe(
      catchError(this.handleError<OAConsumerTpsSection>())
    );
  }


  // Services Provided
  saveservicesprovided(model: ServicesProvidedSection): Observable<ServicesProvidedSection> {
    return this._http.post<ServicesProvidedSection>(this._saveservicesprovided, model, { withCredentials: true }).pipe(
      catchError(this.handleError<ServicesProvidedSection>())
    );
  }

  deleteservicesprovided(id: string): Observable<any> {
    return this._http.post(this._deleteServicesprovided + '/' + id, { withCredentials: true }).pipe(
      catchError(this.handleError())
    );
  }

  // Home Delivered Meals Characteristics
  savehomedeliveredmealschars(model: HomeDeliveredMealsCharsSection): Observable<HomeDeliveredMealsCharsSection> {
    const url = this._savehomedeliveredmealschars;
    return this._http.post<HomeDeliveredMealsCharsSection>(url, model, { withCredentials: true }).pipe(
      catchError(this.handleError<HomeDeliveredMealsCharsSection>())
    );
  }

  // Home Delivered Meals Units
  savehomedeliveredmealsunits(model: HomeDeliveredMealsUnitsSection): Observable<HomeDeliveredMealsUnitsSection> {
    return this._http.post<HomeDeliveredMealsUnitsSection>(
      this._savehomedeliveredmealsunits, model, { withCredentials: true }).pipe(
        catchError(this.handleError<HomeDeliveredMealsUnitsSection>())
      );
  }


  // Assisted Transportation Characteristics
  saveassistedtransportationchars(model: AssistedTransportationCharsSection):
    Observable<AssistedTransportationCharsSection> {
    return this._http.post<AssistedTransportationCharsSection>(
      this._saveassistedtransportationchars, model, { withCredentials: true }).pipe(
        catchError(this.handleError<AssistedTransportationCharsSection>())
      );
  }

  // Assisted Transportation Units
  saveassistedtransportationunits(model: AssistedTransportationUnitsSection):
    Observable<AssistedTransportationUnitsSection> {
    return this._http.post<AssistedTransportationUnitsSection>(
      this._saveassistedtransportationunits, model, { withCredentials: true }).pipe(
        catchError(this.handleError<AssistedTransportationUnitsSection>())
      );
  }

  // Congregate Meals Units
  savecongregatemealsunits(model: CongregateMealsUnitsSection): Observable<CongregateMealsUnitsSection> {
    return this._http.post<CongregateMealsUnitsSection>(
      this._savecongregatemealsunits, model, { withCredentials: true }).pipe(
        catchError(this.handleError<CongregateMealsUnitsSection>())
      );
  }

  // Nutrition Counseling Units
  savenutritioncounselingunits(model: NutritionCounselingUnitsSection):
    Observable<NutritionCounselingUnitsSection> {
    return this._http.post<NutritionCounselingUnitsSection>(
      this._savenutritioncounselingunits, model, { withCredentials: true }).pipe(
        catchError(this.handleError<NutritionCounselingUnitsSection>())
      );
  }

  // Legal Cases
  saveoalegalcases(model: OALegalCasesSection): Observable<OALegalCasesSection> {
    return this._http.post<OALegalCasesSection>(
      this._saveoalegalcases, model, { withCredentials: true }).pipe(
        catchError(this.handleError<OALegalCasesSection>())
      );
  }

  // Info And Assistance
  saveinfoandassistance(model: InfoAndAssistanceSection): Observable<InfoAndAssistanceSection> {
    return this._http.post<InfoAndAssistanceSection>(
      this._saveinfoandassistance, model, { withCredentials: true }).pipe(
        catchError(this.handleError<InfoAndAssistanceSection>())
      );
  }

  // Nutrition Education
  savenutritioneducation(model: NutritionEduInfoSection): Observable<NutritionEduInfoSection> {
    return this._http.post<NutritionEduInfoSection>(
      this._savenutritioneducation, model, { withCredentials: true }).pipe(
        catchError(this.handleError<NutritionEduInfoSection>())
      );
  }

  // Health Promo Evidence
  savehealthpromoevidence(model: HealthPromoEvidenceSection): Observable<HealthPromoEvidenceSection> {
    return this._http.post<HealthPromoEvidenceSection>(
      this._savehealthpromoevidence, model, { withCredentials: true }).pipe(
        catchError(this.handleError<HealthPromoEvidenceSection>())
      );
  }

  // Health Promo Non Evidence
  savehealthpromononevidence(model: HealthPromoNonEvidenceSection): Observable<HealthPromoNonEvidenceSection> {
    return this._http.post<HealthPromoNonEvidenceSection>(
      this._savehealthpromononevidence, model, { withCredentials: true }).pipe(
        catchError(this.handleError<HealthPromoNonEvidenceSection>())
      );
  }

  // COA Consumer Summary page
  savecoaconsumersummary(model: COAConsumerSummarySection): Observable<COAConsumerSummarySection> {
    return this._http.post<COAConsumerSummarySection>(
      this._savecoaconsumersummary, model, { withCredentials: true }).pipe(
        catchError(this.handleError<COAConsumerSummarySection>())
      );
  }

  // ORC Consumer Summary page
  saveorcconsumersummary(model: ORCConsumerSummarySection): Observable<ORCConsumerSummarySection> {
    return this._http.post<ORCConsumerSummarySection>(
      this._saveorcconsumersummary, model, { withCredentials: true }).pipe(
        catchError(this.handleError<ORCConsumerSummarySection>())
      );
  }

  // Caregiver Pages
  savecaregiverpages(model: CaregiverPagesSection): Observable<CaregiverPagesSection> {
    return this._http.post<CaregiverPagesSection>(
      this._savecaregiverpages, model, { withCredentials: true }).pipe(
        catchError(this.handleError<CaregiverPagesSection>())
      );
  }

  // Caregiver Respite Pages
  savecgrespite(model: CGRespiteSection): Observable<CGRespiteSection> {
    return this._http.post<CGRespiteSection>(
      this._savecgrespite, model, { withCredentials: true }).pipe(
        catchError(this.handleError<CGRespiteSection>())
      );
  }

  // Caregiver Supplemental Services Expenditure pages
  savecgsuppexpend(model: CGSuppExpendSection): Observable<CGSuppExpendSection> {
    return this._http.post<CGSuppExpendSection>(
      this._savecgsuppexpend, model, { withCredentials: true }).pipe(
        catchError(this.handleError<CGSuppExpendSection>())
      );
  }

  // OA Other Services Summary (Save TPS)
  savetitleiiiotherexpendsummary(model: TitleIIIOtherExpend): Observable<TitleIIIOtherExpend> {
    return this._http.post<TitleIIIOtherExpend>(
      this._savetitleiiiotherexpendbyffyandstate, model, { withCredentials: true }).pipe(
        catchError(this.handleError<TitleIIIOtherExpend>())
      );
  }


  // Title 7 expenditures
  savetitleviiexpenditures(model: TitleVIIExpendituresSection): Observable<TitleVIIExpendituresSection> {
    return this._http.post<TitleVIIExpendituresSection>(
      this._savetitleviiexpenditures, model, { withCredentials: true }).pipe(
        catchError(this.handleError<TitleVIIExpendituresSection>())
      );
  }

  // NSIP
  savensip(model: NsipSection): Observable<NsipSection> {
    return this._http.post<NsipSection>(this._savensip, model, { withCredentials: true }).pipe(
      catchError(this.handleError<NsipSection>())
    );
  }


  // Multiple Units
  savemultipleunits(model: MultipleUnitsSection): Observable<MultipleUnitsSection> {
    return this._http.post<MultipleUnitsSection>(
      this._savemultipleunits, model, { withCredentials: true }).pipe(
        catchError(this.handleError<MultipleUnitsSection>())
      );
  }

  // Four col no nutrition chars pages
  savefourcolnonutritionchars(model: FourColNoNutritionCharsSection):
    Observable<FourColNoNutritionCharsSection> {
    return this._http.post<FourColNoNutritionCharsSection>(
      this._savefourcolnonutritionchars, model, { withCredentials: true }).pipe(
        catchError(this.handleError<FourColNoNutritionCharsSection>())
      );
  }

  // Two col chars
  savetwocolchars(model: TwoColCharsSection): Observable<TwoColCharsSection> {
    return this._http.post<TwoColCharsSection>(
      this._savetwocolchars, model, { withCredentials: true }).pipe(
        catchError(this.handleError<TwoColCharsSection>())
      );
  }

   // OAExpenditures
   saveoaexpendituresdto(model: OAExpenditureModelDto): Observable<OAExpenditureModelDto> {
    return this._http.post<OAExpenditureModelDto>(
      this._saveOAExpenditureDto, model, { withCredentials: true }).pipe(
        catchError(this.handleError<OAExpenditureModelDto>())
      );
  }

  // Business rules errors
  getDataEntryErrors(ffy: string, state: string, psa: string, isRollup: boolean, serviceId?: string):
    Observable<Array<BasicErrorDetail>> {
    const model = new YearApprovalStatus();
    model.fiscalYear = ffy;
    model.state = state;
    model.psa = psa;
    model.isRollup = isRollup;
    model.serviceId = serviceId === null ? TIIITopSectionTypeEnum.All :
      'NSIP' === serviceId ? TIIITopSectionTypeEnum.nsipSection : TIIITopSectionTypeEnum.Spr;
    return this._http.post<Array<BasicErrorDetail>>(this._dataEntryErrors, model, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<BasicErrorDetail>>())
    );
  }


  // Batch
  // KEEP batch functions for now even though unused
  getbatch(): Observable<Array<BatchSection>> {
    return this._http.post<Array<BatchSection>>(this._getbatch, null, { withCredentials: true }).pipe(
      catchError(this.handleError<Array<BatchSection>>())
    );
  }

  getbatchbyid(id: string): Observable<BatchSection> {
    return this._http.post<BatchSection>(this._getbatch, id, { withCredentials: true }).pipe(
      catchError(this.handleError<BatchSection>())
    );
  }


  savebatch(model: BatchSection): Observable<BatchSection> {
    return this._http.post<BatchSection>(this._savebatch, model, { withCredentials: true }).pipe(
      catchError(this.handleError<BatchSection>())
    );
  }

  deletebatch(id: string): Observable<any> {
    return this._http.get(this._deletebatch.replace('id', id), { withCredentials: true }).pipe(
      catchError(this.handleError<any>())
    );
  }

  // Variance
  saveoavarianceexplanations(model: OAServiceAndExpenditureSummaryDto):
    Observable<OAServiceAndExpenditureSummaryDto> {
    return this._http.post<OAServiceAndExpenditureSummaryDto>(
      this._saveoavarianceexplanations, model, { withCredentials: true }).pipe(
        catchError(this.handleError<OAServiceAndExpenditureSummaryDto>())
      );
  }

  savecgvarianceexplanations(model: CGServiceAndExpenditureSummaryDto):
    Observable<CGServiceAndExpenditureSummaryDto> {
    return this._http.post<CGServiceAndExpenditureSummaryDto>(
      this._savecgvarianceexplanations, model, { withCredentials: true }).pipe(
        catchError(this.handleError<CGServiceAndExpenditureSummaryDto>())
      );
  }

  saveorvarianceexplanations(model: CGServiceAndExpenditureSummaryDto):
    Observable<CGServiceAndExpenditureSummaryDto> {
    return this._http.post<CGServiceAndExpenditureSummaryDto>(
      this._saveorvarianceexplanations, model, { withCredentials: true }).pipe(
        catchError(this.handleError<CGServiceAndExpenditureSummaryDto>())
      );
  }

  savensipvarianceexplanations(model: NsipSection, shouldSubmit: boolean):
    Observable<{ nsipSection: NsipSection, confirmationPrompt: boolean }> {
    const requestOptions = {
      withCredentials: true,
      params: {
        shouldSubmit: shouldSubmit.toString()
      }
    };
    if (shouldSubmit) {
      const qsParams: URLSearchParams = new URLSearchParams();
      qsParams.append('shouldSubmit', 'true');
      (<any>requestOptions).search = qsParams;
    }
    return this._http.post<{ nsipSection: NsipSection, confirmationPrompt: boolean }>(
      this._savensipvarianceexplanations, model, requestOptions).pipe(
        catchError(this.handleError<{ nsipSection: NsipSection, confirmationPrompt: boolean }>())
      );
  }

  // reportingPeriods
  getAllReportingPeriods(isAclUser: string): Observable<any> {
    return this._http.get(this._gettitleiiireportingperiods.replace('{isAclUser}', isAclUser), { withCredentials: true }).pipe(
      catchError(this.handleError<any>())
    );
  }

  getMaxReportingYear(isAclUser: string): Observable<string> {
    return this._http.get<string>(this._getmaxreportingyear.replace('{isAclUser}', isAclUser), { withCredentials: true }).pipe(
      catchError(this.handleError<string>())
    );
  }
  getReportingPeriodByFfy(ffy: string): Observable<any> {
    return this._http.get(this._getreportingperiodbyffy.replace('{ffy}', ffy), { withCredentials: true }).pipe(
      catchError(this.handleError())
    );
  }

  // state profiles
  getStateProfile(ffy: string, state: string): Observable<StateProfile> {
    const url = this._getSubmissionResponsibility.replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get<StateProfile>(url, { withCredentials: true }).pipe(
      catchError(this.handleError<StateProfile>())
    );
  }

  saveSubmissionResponsibility(profile: StateProfile): Observable<DataSubmissionResponsibility> {
    return this._http.post<DataSubmissionResponsibility>(
      this._saveSubmissionResponsibility, profile, { withCredentials: true }).pipe(
        catchError(this.handleError<DataSubmissionResponsibility>())
      );
  }

  deleteStateProfile(ffy: string, state: string): Observable<any> {
    const url = this._deleteSubmissionResponsibility.replace('{ffy}', ffy).replace('{state}', state);
    return this._http.get(url, { withCredentials: true });
  }

  // Rollup

  public doRollup(rollupSource: IRollupSource, loadingComponent?: LoadingComponent): Observable<{id?: string, error?: string}> {
    if (loadingComponent) {
      loadingComponent.setLoadingInProgress(600); // 10 mins
    }
    return this._http.post<{id?: string, error?: string}>(this._rollupApiRoot,
      rollupSource, { withCredentials: true }).pipe(
        catchError(this.handleError<{id?: string, error?: string}>())
      );
  }

  pollLock(id: string, completedCallback: () => void = () => null): Observable<any> {
    this._loadingService.setLoading(true, 'PollLock');
    return interval(5000).pipe(
      takeWhile(tick => tick <= 120), // Wait for 10 min
      exhaustMap(() => this._http.get(this._rollupLock, {
        withCredentials: true,
        params: {
          id: id
        }
      })),
      takeWhile((lock) => lock !== null),
      finalize(() => {
      completedCallback();
      this._loadingService.setLoading(false, 'PollLock');
      })
    );

  }

  // Generic get
  // uses the claim service to determine
  // whether to pull state or AAA record
  get<T>(claimService: ClaimService, endpoint: string, sectionName?: string, loadingComponent?: LoadingComponent): Observable<T> {
    // TODO see about waiting for ClaimService to init before firing requests to fix the refresh/newTab window
    if (loadingComponent) {
      loadingComponent.setLoadingInProgress(100);
    }
    return new Observable<T>((observer) => {
      this._claimService.init$.subscribe(() => {
        const dto = new FfyStateDto();
        dto.ffy = claimService.currentYear;
        dto.state = claimService.currentOrg;
        dto.psa = claimService.currentSubOrg;
        dto.isRollup = claimService.isRollupOverview;
        dto.sectionName = sectionName;

        this._http.post<T>(endpoint, dto, { withCredentials: true }).pipe(
          finalize(() => { if (loadingComponent) { loadingComponent.setLoadingComplete(); } }),
          catchError(this.handleError<T>())
        ).subscribe(data => {
          observer.next(data);
          observer.complete();
        })
      })
    });
  }

  // Global
  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
