import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HelpService } from '../help.service';
import { DestructibleComponent } from '../../destructible.component';

@Component({
  selector: 'oaaps-tech-support-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent extends DestructibleComponent implements OnInit, OnDestroy {

  readonly HelpApiRoot = 'api/v1/help';
  email: string;

  constructor(private helpService: HelpService, private router: Router) {
    super();
  }

  public ngOnInit(): void {
    super.registerSubscription(this.helpService.getTechSupportEmail().subscribe(email => this.email = email));
  }

  public ngOnDestroy(): void {
    super.destroy();
  }

  public navSupport() {
    this.router.navigate(['/Resources/technicalAssistance']);
  }

}
