import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class OAConsumerTpsSection extends Section {

    bothClusterCount: IDataElement;
    cluster1Count: IDataElement;
    unregisteredCount: IDataElement;
    totalCount: IDataElement;

    constructor() {
        super();

        this.bothClusterCount = new DataElement();
        this.cluster1Count = new DataElement();
        this.unregisteredCount = new DataElement();
        this.totalCount = new DataElement();
    }
}
