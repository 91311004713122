import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HelpService } from '../../help/help.service';
import { DestructibleComponent } from '../../destructible.component';

@Component({
    selector: 'acl-about',
    templateUrl: './about.component.html'
})
export class AboutComponent extends DestructibleComponent implements OnInit, OnDestroy {
    public email: string;


    constructor(public _titleService: Title, private _helpService: HelpService) { super(); }

    ngOnInit() {
        this._titleService.setTitle('About - ACL OAAPS');
        super.registerSubscription(this._helpService.getTechSupportEmail().subscribe(email => this.email = email))
    }

    ngOnDestroy(): void {
        super.destroy();
    }

}
