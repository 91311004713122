<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <h2>{{pageName}}</h2>
        <div class="flex-list panel-group thirty">
          <div class="flex-list-item">
            <div class="panel panel-primary-inverted flex-list-content">
              <div class="panel-body">
                <p>
                  <strong>Consumer Summary</strong>
                </p>
                <hr />
                <ul class="list-unstyled">
                  <li>
                    <a tabindex="0" [routerLink]="['/data-submissions/ORCConsumerTps']">Total Persons Served</a>
                  </li>
                  <li>
                    <a tabindex="0" [routerLink]="['/data-submissions/titleIIIORCConsumerSummary']">Caregivers Receiving Registered Services</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex-list-item">
            <div class="panel panel-primary-inverted flex-list-content">
              <div class="panel-body">
                <p>
                  <strong>Counseling</strong>
                </p>
                <hr />
                <ul class="list-unstyled">
                  <li>
                    <a tabindex="0" [routerLink]="['/data-submissions/titleIIIORCCounseling']">Total People Served, Service Units and Expenditures</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex-list-item">
            <div class="panel panel-primary-inverted flex-list-content">
              <div class="panel-body">
                <p>
                  <strong>Training</strong>
                </p>
                <hr />
                <ul class="list-unstyled">
                  <li>
                    <a tabindex="0" [routerLink]="['/data-submissions/titleIIIORCTraining']">Total People Served, Service Units and Expenditures</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex-list-item">
            <div class="panel panel-primary-inverted flex-list-content">
              <div class="panel-body">
                <p>
                  <strong>Respite</strong>
                </p>
                <hr />
                <ul class="list-unstyled">
                  <li>
                    <a tabindex="0" [routerLink]="['/data-submissions/titleIIIORCRespite']">Total People Served, Service Units and Expenditures</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex-list-item">
            <div class="panel panel-primary-inverted flex-list-content">
              <div class="panel-body">
                <p>
                  <strong>Supplemental Services</strong>
                </p>
                <hr />
                <ul class="list-unstyled">
                  <li>
                    <a tabindex="0" [routerLink]="['/data-submissions/titleIIIORCSuppProvided']">Services Provided</a>
                  </li>
                  <li>
                    <a tabindex="0" [routerLink]="['/data-submissions/titleIIIORCSuppExpend']">Total Persons Served and Expenditures</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex-list-item">
            <div class="panel panel-primary-inverted flex-list-content">
              <div class="panel-body">
                <p>
                  <strong>Case Management</strong>
                </p>
                <hr />
                <ul class="list-unstyled">
                  <li>
                    <a tabindex="0" [routerLink]="['/data-submissions/titleIIIORCCaseMgmt']">Total People Served, Service Units, and Expenditures</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex-list-item">
            <div class="panel panel-primary-inverted flex-list-content">
              <div class="panel-body">
                <p>
                  <strong>Support Groups</strong>
                </p>
                <hr />
                <ul class="list-unstyled">
                  <li>
                    <a tabindex="0" [routerLink]="['/data-submissions/titleIIIORCSupport']">Service Units, Expenditures and Program Income</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex-list-item">
            <div class="panel panel-primary-inverted flex-list-content">
              <div class="panel-body">
                <p>
                  <strong>Information and Assistance</strong>
                </p>
                <hr />
                <ul class="list-unstyled">
                  <li>
                    <a tabindex="0" [routerLink]="['/data-submissions/titleIIIORCInfoAndAssistance']">Total People Served, Service Units, and Expenditures</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex-list-item">
            <div class="panel panel-primary-inverted flex-list-content">
              <div class="panel-body">
                <p>
                  <strong>Information Services</strong>
                </p>
                <hr />
                <ul class="list-unstyled">
                  <li>
                    <a tabindex="0" [routerLink]="['/data-submissions/titleIIIORCInfoServices']">Total People Served, Service Units, and Expenditures</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
        <!-- .end row -->

      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
