import { Component, OnInit, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { ClaimService } from '../../shared/claim.service';
import { FormBuilder } from '@angular/forms';
import { TitleVIIDataEntryService } from '../../StateSubmissions/titleVIIDataEntry.service';
import { TitleIIIDataEntryService } from '../../DataSubmissions/titleIIIDataEntry.service';
import { TitleVIDataEntryService } from '../../TitleVI/titleVIDataEntry.service';
import { BasicErrorDetail } from '../Upload/uploadError';
import { LoadingComponent } from '../loading.component';
import { DataFilter } from '../Pipe/dataFilter';
import { QuestionStatusEnum } from '../dataElement';

// Same as userManagementObjects.ts IKeyValue, refactor for reuse
interface IKeyValue {
  key: string;
  value: string;
}

const TIIISections: IKeyValue[] = [
  { key: '', value: 'All Sections' },
  { key: 'Aging Network', value: 'Aging Network Profile' },
  { key: 'Older Adults', value: 'Older Adults' },
  { key: 'Caregivers of Older Adults', value: 'Caregivers of Older Adults' },
  { key: 'Older Relative Caregivers', value: 'Older Relative Caregivers' },
  { key: 'Expenditures Overview', value: 'Expenditures Overview' },
  { key: 'NSIP', value: 'NSIP Qualified Meals' }
];

@Component({
  templateUrl: 'validationErrors.component.html',
  styles: [
    `@media print {.container {display: inline; overflow: visible; float: none; position: static;}
    [href]:after {content: none !important;}}`
  ]
})

export class ValidationErrorsComponent implements OnInit, OnDestroy {

  // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;

  ffy: string;
  state: string;
  formatErrors: BasicErrorDetail[] = [];
  filterQuery: DataFilter = new DataFilter();
  rowsOnPage: number = 10;
  sortBy: string = 'email';
  sortOrder: string = 'asc';
  id: string;
  public sub: any;
  sectionOptions: IKeyValue[] = [];

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _claimService: ClaimService,
    public _router: Router,
    public _fb: FormBuilder,
    public _tviiservice: TitleVIIDataEntryService,
    public _tiiiservice: TitleIIIDataEntryService,
    public _tviservice: TitleVIDataEntryService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.filterQuery.query = '';
    // this.loadingComponent.setLoadingInProgress(180);
    this.state = this._claimService.currentOrg;
    this.ffy = this._claimService.currentYear;
    this._titleService.setTitle('Validation Errors - ACL OAAPS');
    this.formatErrors = [];

    this.sub = this.route.params.subscribe(params => {
      if (params['id'] != null) {
        this.id = params['id'];
      }

      if (this.id === 'T7') {
        this._tviiservice.getDataEntryErrors(this.ffy, this.state).subscribe(
          formatErrors => { this.formatErrors = formatErrors; },
          error => console.log(error)
        );
        if (params['filter'] != null) {
          this.filterQuery.query = params['filter'];
          this.location.go(this.router.url.replace('/' + params['filter'], ''));
        }
      }
      if (this.id === 'T3') {
        this._tiiiservice.getDataEntryErrors(this.ffy, this.state, this._claimService.currentSubOrg,
          this._claimService.isRollupOverview, null).subscribe(
            formatErrors => {
              this.formatErrors = formatErrors.filter(x =>
                x.status === QuestionStatusEnum.L1InProgressError
                || x.status === QuestionStatusEnum.L2InProgressError
                || x.status === QuestionStatusEnum.L2ValidatedWarning);
              this.sectionOptions = this.getSectionOptions(this.formatErrors);
              if(this.filterQuery.query === 'Expenditures Overview'){
                this.filterQuery.query = '';
              }
            },
            error => console.log(error)
          );
        if (params['filter'] != null) {
          this.filterQuery.query = params['filter'];
          this.location.go(this.router.url.replace('/' + params['filter'], ''));
        }
      }
      if (this.id === 'T6') {
        this._tviservice.getDataEntryErrors(this.ffy, this.state).subscribe(
          formatErrors => { this.formatErrors = formatErrors; },
          error => console.log(error)
        );
        if (params['filter'] != null) {
          this.filterQuery.sectionName = params['filter'];
          this.location.go(this.router.url.replace('/' + params['filter'], ''));
        }
      }
    });
  }

  print(): void {
    this.rowsOnPage = 100000;
    setTimeout(() => { window.print(); this.rowsOnPage = 10; }, 2000);
  }

  returnToOverview() {
    if (this.id === 'T7') {
      this.router.navigate(['/state-submissions/titleVIIOverview']);
    }
    if (this.id === 'T3') {
      this.router.navigate(['/data-submissions/titleIIIOverview']);
    }
    if (this.id === 'T6') {
      this.router.navigate(['/titlevi/titleVIPPROverview']);
    }
  }

  calculateRoute(sectionName: string): string {
    let route: string = '/';
    if (this.id === 'T7') {
      route = '/state-submissions/titleVII' + sectionName.replace('titleVII', '').replace('Section', '');
    }

    if (this.id === 'T3' && sectionName == 'titleVIIExpenditures')
    {
        route = '/data-submissions/' + sectionName.replace('titleIII', '').replace('Section', '');
    }
    else if (this.id === 'T3')
    {
      route = '/data-submissions/titleIII' + sectionName.replace('titleIII', '').replace('Section', '');
    }

    if (this.id === 'T6') {
      route = '/titlevi/' + sectionName;
    }
    // Adding any known exceptions here where "titleXXX" + SectionName - "Section" != routing path
    if (sectionName.includes('ComplaintExampleSection')) {
      return '/state-submissions/titleVIIComplaintNursing';
    }
    if (sectionName.includes('SystemIssueSection')) {
      return '/state-submissions/titleVIISystemIssue1';
    }
    if (sectionName.includes('NumberAndCapacitySection')) {
      return '/state-submissions/titleVIINumberAndCapacity';
    }
    if (sectionName === 'StaffAndVolunteerSection') {
      route = route + 's';
    }
    if (sectionName.includes('ConsumerTps')
      || sectionName.includes('ConsumerBoth')
      || sectionName.includes('ConsumerOne')
      || sectionName.includes('LegalCases')
      || sectionName.includes('HealthPromo')) {
      route = route.replace('titleIII', '');
      route = route.replace('Health', 'health');
    }
    return route;
  }

  getSectionOptions(basicErrorDetail: BasicErrorDetail[]): IKeyValue[] {
    const fullSectionNames = basicErrorDetail.map(x => x.componentFriendlyName.split(' - ')[0]);
    const uniqueSectionNames = this.uniqueArrayRecords(fullSectionNames);
    return uniqueSectionNames;
  }

  uniqueArrayRecords(input: string[]): IKeyValue[] {
    const rtn: IKeyValue[] = [];
    rtn.push(TIIISections[0]); // All Section
    input.map(x => {
      if (rtn.find(y => y.key === x) === undefined) {
        rtn.push(TIIISections.find(z => z.key === x));
      }
    });
    return rtn;
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
