export class ModalButtonOption {

    constructor(public text: string = ''
        , public buttonClass: string = ''
        , public action: () => void = () => null) { }

    public static createActionCancelOption(actionText: string,
      action: () => void,
      cancelText: string = 'Cancel',
      cancelAction: () => void = () => null) {
        return [
            {
              text: actionText,
              buttonClass: 'btn btn-primary',
              action: action,
            },
            ...this.createCancelOption(cancelText, cancelAction)
          ];
    }

    public static createCancelOption(
      cancelText: string = 'Cancel',
      cancelAction: () => void = () => null) {
        return [{
              text: cancelText,
              buttonClass: 'btn btn-secondary',
              action: cancelAction,
            }]
          ;
    }
}
