<div class="row p-1">
    <div class="col-md-5">
        <label for="availableGrantees">Available State and AAAs</label>
        <select id="availableGrantees" [disabled]="disabled" [(ngModel)]="currentlySelectedAdd" multiple
            (keydown.enter)="addGrantee(currentlySelectedAdd)">
            <optgroup *ngFor="let region of groupedFilteredGrantees" [label]="region.key">
                <option *ngFor="let grantee of region.value" [title]="grantee.displayName" [ngValue]="grantee">{{grantee.displayName}}</option>
            </optgroup>
        </select>
    </div>
    <div class="col-md-2 mb-2 mt-4">
        <div class="form-group">
            <button [disabled]="disabled" class="btn-sm btn-default block mb-1" (click)="addGrantee(currentlySelectedAdd)"
                (keydown.enter)="addGrantee(currentlySelectedAdd)">Add&nbsp;&raquo;</button>
            <button [disabled]="disabled" class="btn-sm btn-default block" (click)="removeGrantee(currentlySelectedRemove)"
                (keydown.enter)="removeGrantee(currentlySelectedRemove)">&laquo;&nbsp;Remove</button>
        </div>
        <div class="form-group">
            <button [disabled]="disabled" class="btn-sm btn-default block mb-1" (click)="addGrantee(filteredGrantees)"
                (keydown.enter)="addGrantee(filteredGrantees)">Add
                all&nbsp;&raquo;</button>
            <button [disabled]="disabled" class="btn-sm btn-default block" (click)="removeGrantee(selectedGrantees)"
                (keydown.enter)="removeGrantee(selectedGrantees)">&laquo;&nbsp;Remove
                all</button>
        </div>
    </div>
    <div class="col-md-5">
        <label for="selectedGrantees">Selected State and AAAs</label>
        <select id="selectedGrantees" [disabled]="disabled" [(ngModel)]="currentlySelectedRemove" multiple
            (keydown.enter)="removeGrantee(currentlySelectedRemove)">
            <optgroup *ngFor="let region of groupedSelectedGrantees" [label]="region.key">
                <option *ngFor="let grantee of region.value" [title]="grantee.displayName" [ngValue]="grantee">{{grantee.displayName}}</option>
            </optgroup>
        </select>
    </div>
</div>
