import { RegionEnum } from '../../UserManagement/userManagerObjects';
import { IAddress } from '../../shared/Model/IAddress';
import { IGrant, GrantTypes } from './IGrant';

export interface IGrantee {
    id: string;
    dunsId: string;
    ein: string;
    name: string;
    number: string;
    alternativeNames: string[];
    region: RegionEnum;
    url: string;
    address: IAddress;
    grants: Array<IGrant>;
    pprContactId: string;
    regionalContactId: string;
    fundingStatuses: { [index: string]: IFundingStatus };
    notes: string;
}

export class Grantee implements IGrantee {
    id: string;
    dunsId: string;
    ein: string;
    name: string;
    number: string;
    alternativeNames: string[];
    region: RegionEnum;
    url: string;
    address: IAddress;
    grants: Array<IGrant>;
    pprContactId: string;
    regionalContactId: string;
    fundingStatuses: { [index: string]: IFundingStatus };
    notes: string;
}

export interface IFundingStatus {
    ffy: string;
    enrollmentYear: string;
    partABFunding: boolean;
    partCFunding: boolean;
}

export class SearchGranteeDto {
    constructor() {
    }
    name: string;
    number: string;
    region: string;
    state: string;
    enrollmentYear: string;
    granteeFunding: GranteeFundingEnum;
}

export class GranteeDto {
    id: string;
    name: string;
    number: string;
    region: string;
    address: IAddress;
    enrollmentYear: string;
    fundingStatuses: Array<IFundingStatus>;
    pprContactId: string;
    regionalContactId: string;
    notes: string;
}

export class GranteeDetailsDto {
    id: string;
    name: string;
    region: string;
    state: string;
    funding: GrantDto[];
}

export class GrantDto {
    fiscalYear: string;
    type: GrantTypes;
    isActive: boolean;
    grantNumber: string;
}

export class TVIRegionalContactDto {
    firstName: string;
    lastName: string;
    email: string;
    granteeAddress: string;
    phoneNumber: string;
}


export class EnrollmentPeriod {
    enrollmentYear: string;
    yearRange: string;
    startDate: Date;
    endDate: Date;
    enrollmentStartDate: Date;
    enrollmentEndDate: Date;
}

export enum GranteeFundingEnum {
    All, ABOnly, ABC, None
}

export interface IGranteeState {
    id: string;
    name: string;
    state: string;
    region: string;
}

export class GranteeState {
    id: string;
    name: string;
    state: string;
}
