<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
    <div class="row">
        <div class="col-md-12 acl-container">
            <!-- left navigation -->
            <sideNav></sideNav>
            <!-- .end left navigation -->
            <div class="col-md-9">
                <h2>Aging Network Profile - Service Providers</h2>
                <p class="info mt-1">All fields are required</p>
                <div class="col-md-12 background-gray-lighter mb-1">
                    <h2>Total Number of Service Providers</h2>
                </div>
                <div class="col-md-9">
                    <label for="bcdServices" class="normal">Unduplicated Title III-B/C/D Service Providers</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputBcdServices'" [de]="_sectionModel.bcdServices"
                    (change)="onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.bcdServices"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="eServices" class="normal">Title III-E Service Providers</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputeServices'" [de]="_sectionModel.eServices"
                    (change)="onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.eServices"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="allServices">Total Unduplicated Title III-B/C/D/E Service Providers</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputAllServices'" [de]="_sectionModel.allServices"
                    (change)="onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.allServices"></acl-input-error>
                </div>

                <div class="col-md-12 background-gray-lighter mb-1">
                    <h2>Number of Providers by Service Type</h2>
                </div>
                <div class="col-md-9">
                    <label for="homeDelivered" class="normal">Providers serving only Home Delivered Nutrition</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputHomeDelivered'" [de]="_sectionModel.homeDelivered"
                    (change)="onChange(); updateTotal()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.homeDelivered"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="congregate" class="normal">Providers serving only Congregate Nutrition</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputeCongregate'" [de]="_sectionModel.congregate"
                    (change)="onChange(); updateTotal()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.congregate"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="bothMeals" class="normal">Providers serving both Home Delivered Nutrition and Congregate Nutrition</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputBothMeals'" [de]="_sectionModel.bothMeals"
                    (change)="onChange(); updateTotal()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.bothMeals"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="totalProviders">Total unduplicated count</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputTotalProviders'" [de]="_sectionModel.totalProviders"
                    [disabled]=true>
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.totalProviders"></acl-input-error>
                </div>
                <div class="col-md-9"></div>
                <div class="col-md-3 mb-1"></div>
                <div class="col-md-9"></div>
                <div class="col-md-3 mb-1"></div>
                <div class="col-md-9"></div>
                <div class="col-md-3 mb-1"></div>
                <div class="col-md-9">
                    <label for="infoAndAssist" class="normal">Information and Assistance Providers</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'infoAndAssist'" [de]="_sectionModel.infoAndAssist"
                    (change)="onChange();">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.infoAndAssist"></acl-input-error>


                   <!-- <div [ngClass]="calculateOutlineColor(_sectionModel.infoAndAssist.questionStatus)">
                        <input [disabled]="!_sectionModel.infoAndAssist.isRequired" [(ngModel)]="_sectionModel.infoAndAssist.elementValue" type="text"
                            class="form-control" id="infoAndAssist" (change)="unsavedChanges = true; _sectionModel.infoAndAssist.elementValue = addComma(_sectionModel.infoAndAssist.elementValue); _sectionModel.infoAndAssist = validate(_sectionModel.infoAndAssist); checkValidForm()">
                    </div>
                    <div *ngIf="_sectionModel.infoAndAssist.dataEntryErrorMessage" class="alert alert-danger" role="alert">
                        <i class="fa fa-times" aria-hidden="true"></i>
                        <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.infoAndAssist.dataEntryErrorMessage}}
                    </div>
                    <div *ngIf="!_sectionModel.infoAndAssist.dataEntryErrorMessage && (_sectionModel.infoAndAssist.questionStatus == questionStatusEnum.L2InProgressError || _sectionModel.infoAndAssist.questionStatus == questionStatusEnum.L2ValidatedWarning)"
                        [ngClass]="setErrorDisplayType(_sectionModel.infoAndAssist.questionStatus)" role="alert">
                        <i *ngIf="_sectionModel.infoAndAssist.questionStatus == questionStatusEnum.L2ValidatedWarning" class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i>
                        <span class="sr-only">Warning:</span>
                        <i *ngIf="_sectionModel.infoAndAssist.questionStatus != questionStatusEnum.L2ValidatedWarning" class="fa fa-times" aria-hidden="true"></i>
                        <span class="sr-only">Error:</span>
                        &nbsp;{{_sectionModel.infoAndAssist.businessErrorMessage}}
                    </div> -->
                </div>


                <div class="col-md-12 row">
                    <div *ngIf="!validForm" class="has-error">
                        <p class="help-block">There are errors on the page. You must fix the errors or clear the data in those fields in order
                            to Save this form.</p>
                    </div>
                    <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
                    <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>
                    <button class="btn btn-default mr-1" (click)="save()" [disabled]="!validForm || !_sectionModel.isRequired">Save</button>
                    <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
                </div>
                <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1" style="display: none;">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h3 class="modal-title" id="nextModalLabel">Recent changes on this page will not be saved</h3>
                            </div>
                            <div class="modal-body">
                                <p>
                                    There are errors on the page. You must fix the errors or clear the data in those fields in order to save this form.
                                    <br />
                                    <br /> You may select "Go back to form" to remove the errors or you may select "Proceed to
                                    next section" and all of new the data entered in the current section will be lost.
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go back to form</button>
                                <button class="btn btn-primary pull-right" (click)="unsavedChanges = false;" data-dismiss="modal" type="button" [routerLink]="['/data-submissions/titleIIIANSeniorCenters']">Proceed to next section</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- .end col-md-9 -->
        </div>
        <!-- .end acl-container (col-md-12) -->
    </div>
    <!-- .end row -->
</div>
<!-- ./container -->
