import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';


import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { ANRespiteSection } from './ANRespiteSection';
import { ValidationService } from '../shared/validation.service';
import { ToastrService } from '../shared/toastr.service';
import { CommonService } from '../shared/common.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIISectionComponent } from './title-iii-section.component';

@Component({
  templateUrl: 'titleIIIANRespite.component.html',
})

export class TitleIIIANRespiteComponent extends TitleIIISectionComponent<ANRespiteSection> implements OnInit, OnDestroy {

  _sectionModel: ANRespiteSection;
  _uploadModels: Array<ANRespiteSection>;
  _batchIds: Array<string>;
  unsavedChanges: boolean = false;
  questionStatusEnum = QuestionStatusEnum;

  constructor(public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public toastr: ToastrService,
    public _commonService: CommonService
  ) {
    super(new ANRespiteSection(), _commonService, _validation, router, _claimService, _service);
   }

  ngOnInit(): void {
    this._titleService.setTitle('AN - Respite Voucher - Data Submissions - ACL OAAPS');
    this._sectionModel = new ANRespiteSection();
    this.getanrespitebyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    super.registerSubscription(this._service.saveanrespite(this._sectionModel)
      .subscribe(data => {
        this._sectionModel = data;
        this._claimService.tiiiStatuses.titleIIIANRespite = data.sectionStatus;
      },
        (error) => console.log(error),
        () => {
          this.toastr.success('Saved!');
          callback();
        }));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/OAConsumerTps']);
    if (!this._claimService.isViewOnly && 
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  getanrespitebyffyandstate(): void {
    super.registerSubscription(this._service.get<ANRespiteSection[]>(this._claimService,
      this._service._getanrespitebyffyandstate, this.resolveSectionName())
      .subscribe(data => {
        if (data.length > 0) {
          // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
          let tempSectionModel: ANRespiteSection = new ANRespiteSection();
          tempSectionModel = data.filter(element => element.batchId === '00000000-0000-0000-0000-000000000000')[0];
          if (tempSectionModel) {
            this._sectionModel = tempSectionModel;
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
          }
          super.processSectionStatus();

        }
      }));
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.coaPersonsServed.elementValue = this.addComma(this._sectionModel.coaPersonsServed.elementValue);
    this._sectionModel.orcPersonsServed.elementValue = this.addComma(this._sectionModel.orcPersonsServed.elementValue);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.agingNetworkLockedNav = false;
      return true;
    } else {
      this._commonService.agingNetworkLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
      or press Cancel to go back and save these changes.`;
    }
  }

}
