import { Component, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { TitleVIIExpendituresSection } from './TitleVIIExpendituresSection';
import { CommonService } from '../shared/common.service';
import { ValidationService } from '../shared/validation.service';
import { ToastrService } from '../shared/toastr.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIISectionComponent } from './title-iii-section.component';
import { AddOnOptions } from '../shared/Model/addOnOptions';
import { now } from 'underscore';

@Component({
  templateUrl: 'titleVIIExpenditures.component.html',
})

export class TitleVIIExpendituresComponent extends TitleIIISectionComponent<TitleVIIExpendituresSection> implements OnInit, OnDestroy {

  _sectionModel: TitleVIIExpendituresSection;
  _uploadModels: Array<TitleVIIExpendituresSection>;
  _batchIds: Array<string>;
  unsavedChanges: boolean = false;
  questionStatusEnum = QuestionStatusEnum;

  autoFillAAAFields: boolean = false;

  public get addOnOption(): AddOnOptions {
    return {
      text: '$',
      width: 20,
      isPrefix: true
    };
  }

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public _commonService: CommonService,
    public toastr: ToastrService
  ) {
    super(new TitleVIIExpendituresSection(), _commonService, _validation, router, _claimService, _service);
   }

  ngOnInit(): void {
    let title = 'Older Adults - Title VII Expenditures - Service Units, Expenditures and Program Income - Data Submissions - ACL OAAPS';
    this._titleService.setTitle(title);
    this._sectionModel = new TitleVIIExpendituresSection();
    this.gettitleviiexpendituresbyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    super.registerSubscription(this._service.savetitleviiexpenditures(this._sectionModel)
      .subscribe(data => this.zone.run(() => {
        this._sectionModel = data;
      }),
      (error)=>console.log(error),
      ()=>{
        callback();

        this.toastr.success('Saved!');
      }
      ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIInsip']);
    if (!this._claimService.isViewOnly && 
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  gettitleviiexpendituresbyffyandstate(): void {
    super.registerSubscription(this._service.get<TitleVIIExpendituresSection[]>(this._claimService,
      this._service._gettitleviiexpendituresbyffyandstate, this.resolveSectionName())
      .subscribe(data => this.zone.run(() => {
        if (data) {
          // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
          let tempSectionModel: TitleVIIExpendituresSection = new TitleVIIExpendituresSection();
          tempSectionModel = data.filter(element => element.batchId === '00000000-0000-0000-0000-000000000000')[0];
          if (tempSectionModel) {
            this._sectionModel = tempSectionModel;
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
        }
        this._sectionModel.singlePSA = false;
        this.autoFillAAAFields = this._claimService.isNonAAAState;
        this.autoFillFields();

      })));
  }

  autoFillFields() {
    if (this.autoFillAAAFields) {
      this._sectionModel.aaaExpend.elementValue = 'N/A';
      this._sectionModel.singlePSA = true;
      this.updateTotals();
    }
  }

  updateTotals(): void {
    let grandTotal = 0;
    const suaExpend = parseFloat(this.removeCommas(this._sectionModel.suaExpend.elementValue));
    const aaaExpend = parseFloat(this.removeCommas(this._sectionModel.aaaExpend.elementValue));
    const apsExpend = parseFloat(this.removeCommas(this._sectionModel.apsExpend.elementValue));
    const otherEntitiesExpend = parseFloat(this.removeCommas(this._sectionModel.otherEntitiesExpend.elementValue));
    const otherStateExpend = parseFloat(this.removeCommas(this._sectionModel.otherStateExpend.elementValue));
    const nonStateExpend = parseFloat(this.removeCommas(this._sectionModel.nonStateExpend.elementValue));
    if (!isNaN(suaExpend)) { grandTotal += suaExpend; }
    if (!isNaN(aaaExpend)) { grandTotal += aaaExpend; }
    if (!isNaN(apsExpend)) { grandTotal += apsExpend; }
    if (!isNaN(otherEntitiesExpend)) { grandTotal += otherEntitiesExpend; }
    this._sectionModel.vii3Total.elementValue = this.addDecimals(grandTotal.toString());
    if (!isNaN(otherStateExpend)) { grandTotal += otherStateExpend; }
    if (!isNaN(nonStateExpend)) { grandTotal += nonStateExpend; }
    this._sectionModel.chapter3Total.elementValue = this.addDecimals(grandTotal.toString());
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.vii3Total.elementValue = this.addDecimals(this._sectionModel.vii3Total.elementValue);
    this._sectionModel.suaExpend.elementValue = this.addDecimals(this._sectionModel.suaExpend.elementValue);
    this._sectionModel.aaaExpend.elementValue = this.addDecimals(this._sectionModel.aaaExpend.elementValue);
    this._sectionModel.apsExpend.elementValue = this.addDecimals(this._sectionModel.apsExpend.elementValue);
    this._sectionModel.otherEntitiesExpend.elementValue = this.addDecimals(this._sectionModel.otherEntitiesExpend.elementValue);
    this._sectionModel.otherStateExpend.elementValue = this.addDecimals(this._sectionModel.otherStateExpend.elementValue);
    this._sectionModel.nonStateExpend.elementValue = this.addDecimals(this._sectionModel.nonStateExpend.elementValue);
    this._sectionModel.chapter3Total.elementValue = this.addDecimals(this._sectionModel.chapter3Total.elementValue);
    this._sectionModel.chapter4Total.elementValue = this.addDecimals(this._sectionModel.chapter4Total.elementValue);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.eoLockedNav = false;
      return true;
    } else {
      this._commonService.eoLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

  // No references, commenting out for now.
  // // special case for AAA field
  // validateAAA(data: DataElement): DataElement {
  //   if (data.elementValue != null && data.elementValue !== '') {

  //     if (data.label.includes('Funds expended by AAA') && this._sectionModel.singlePSA === false) {
  //       // if is aaaExpend and not N/A, then check for decimal val
  //       if (this._validation.validate(2, data.elementValue)) {
  //         if (!this._validation.validateMaxLength(data.elementValue, data.maxLength)) {
  //           data.dataEntryErrorMessage = 'Maximum allowed digits is ' + data.maxLength;
  //           data.questionStatus = 1;
  //         } else {
  //           data.dataEntryErrorMessage = '';
  //           data.questionStatus = 3;
  //         }
  //       } else {
  //         data.dataEntryErrorMessage = this._validation.getErrorMessage(2);
  //         data.questionStatus = 1;
  //       }
  //     } else {
  //       // if singlePSA = true then use string validation
  //       if (this._validation.validate(data.validationType, data.elementValue)) {
  //         if (!this._validation.validateMaxLength(data.elementValue, data.maxLength)) {
  //           data.dataEntryErrorMessage = 'Maximum allowed digits is ' + data.maxLength;
  //           data.questionStatus = 1;
  //         } else {
  //           data.dataEntryErrorMessage = '';
  //           data.questionStatus = 3;
  //         }
  //       } else {
  //         data.dataEntryErrorMessage = this._validation.getErrorMessage(data.validationType);
  //         data.questionStatus = 1;
  //       }
  //     }
  //   } else {
  //     data.dataEntryErrorMessage = '';
  //     data.questionStatus = 0;
  //   }

  //   return data;
  // }

}
