<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <h2>NSIP Qualified Meals</h2>
        <p class="info mt-1">All fields are required</p>
        <div class="table-responsive">
          <div class="background-gray-lighter">
            <h2 colspan="4">NSIP Qualified Meals for Determining Title III A Grant</h2>
          </div>

          <table class="table table-no-border mt-2">
          <tbody>
            <tr *ngIf="hdmWarning() || congWarning()">
              <td class="has-warning" colspan="4">
                <span class="help-block">
                  <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s)
                </span>
                <p *ngIf="!_sectionModel.hdmC.dataEntryErrorMessage && _sectionModel.hdmC.questionStatus == statusEnum.L2ValidatedWarning"
                  class="help-block">Home Delivered Meals Under Title III C: {{_sectionModel.hdmC.businessErrorMessage}} </p>
                <p *ngIf="!_sectionModel.hdmE.dataEntryErrorMessage && _sectionModel.hdmE.questionStatus == statusEnum.L2ValidatedWarning"
                  class="help-block">Home Delivered Meals Under Title III E: {{_sectionModel.hdmE.businessErrorMessage}} </p>
                <p *ngIf="!_sectionModel.congregateC.dataEntryErrorMessage && _sectionModel.congregateC.questionStatus == statusEnum.L2ValidatedWarning"
                  class="help-block">Congregate Meals Under Title III C: {{_sectionModel.congregateC.businessErrorMessage}} </p>
                <p *ngIf="!_sectionModel.congregateE.dataEntryErrorMessage && _sectionModel.congregateE.questionStatus == statusEnum.L2ValidatedWarning"
                  class="help-block">Congregate Meals Under Title III E: {{_sectionModel.congregateE.businessErrorMessage}} </p>
                <p *ngIf="!_sectionModel.hdmTotal.dataEntryErrorMessage && _sectionModel.hdmTotal.questionStatus == statusEnum.L2ValidatedWarning"
                  class="help-block">
                  Home Delivered Meals Total NSIP Qualified: {{_sectionModel.hdmTotal.businessErrorMessage}}
                </p>
                <p *ngIf="!_sectionModel.congregateTotal.dataEntryErrorMessage && _sectionModel.congregateTotal.questionStatus == statusEnum.L2ValidatedWarning"
                  class="help-block">
                  Congregate Meals Total NSIP Qualified Meals: {{_sectionModel.congregateTotal.businessErrorMessage}}
                </p>
              </td>
            </tr>
            <tr *ngIf="hdmError() || congError()">
              <td class="has-error" colspan="4">
                <span class="help-block">
                  <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s)
                </span>
                <p *ngIf="_sectionModel.hdmC.dataEntryErrorMessage" class="help-block">Home Delivered Meals Under Title III C: {{_sectionModel.hdmC.dataEntryErrorMessage}} </p>
                <p *ngIf="_sectionModel.hdmE.dataEntryErrorMessage" class="help-block">Home Delivered Meals Under Title III E: {{_sectionModel.hdmE.dataEntryErrorMessage}} </p>
                <p *ngIf="_sectionModel.congregateC.dataEntryErrorMessage" class="help-block">Congregate Meals Under Title III C: {{_sectionModel.congregateC.dataEntryErrorMessage}} </p>
                <p *ngIf="_sectionModel.congregateE.dataEntryErrorMessage" class="help-block">Congregate Meals Under Title III E: {{_sectionModel.congregateE.dataEntryErrorMessage}} </p>
                <p *ngIf="!_sectionModel.hdmC.dataEntryErrorMessage && _sectionModel.hdmC.questionStatus == statusEnum.L2InProgressError"
                  class="help-block">Home Delivered Meals Under Title III C: {{_sectionModel.hdmC.businessErrorMessage}} </p>
                <p *ngIf="!_sectionModel.hdmE.dataEntryErrorMessage && _sectionModel.hdmE.questionStatus == statusEnum.L2InProgressError"
                  class="help-block">Home Delivered Meals Under Title III E: {{_sectionModel.hdmE.businessErrorMessage}} </p>
                <p *ngIf="!_sectionModel.congregateC.dataEntryErrorMessage && _sectionModel.congregateC.questionStatus == statusEnum.L2InProgressError"
                  class="help-block">Congregate Meals Under Title III C: {{_sectionModel.congregateC.businessErrorMessage}} </p>
                <p *ngIf="!_sectionModel.congregateE.dataEntryErrorMessage && _sectionModel.congregateE.questionStatus == statusEnum.L2InProgressError"
                  class="help-block">Congregate Meals Under Title III E: {{_sectionModel.congregateE.businessErrorMessage}} </p>
                <p *ngIf="!_sectionModel.hdmTotal.dataEntryErrorMessage && _sectionModel.hdmTotal.questionStatus == statusEnum.L2InProgressError"
                  class="help-block">
                  Home Delivered Meals Total NSIP Qualified Meals: {{_sectionModel.hdmTotal.businessErrorMessage}}
                </p>
                <p *ngIf="!_sectionModel.congregateTotal.dataEntryErrorMessage && _sectionModel.congregateTotal.questionStatus == statusEnum.L2InProgressError"
                  class="help-block">
                  Congregate Meals Total NSIP Qualified Meals: {{_sectionModel.congregateTotal.businessErrorMessage}}
                </p>
              </td>
            </tr>
            <tr>
              <td width="25%"></td>
              <td width="25%">Served Under Title III C</td>
              <td width="25%">Served Under Title III E</td>
              <td width="25%">Total NSIP Qualified Meals</td>
            </tr>
            <tr>
              <th class="normal" scope="row">Home delivered meals</th>
              <td>
                <label for="hdmC" class="sr-only">Home Delivered Meals Under Title 3 C</label>
                <div [ngClass]="(_sectionModel.hdmC.dataEntryErrorMessage)?'has-error':''">
                  <input [(ngModel)]="_sectionModel.hdmC.elementValue" [disabled]="!_sectionModel.hdmC.isRequired" type="text" class="form-control"
                    id="hdmC" (change)="unsavedChanges = true;  _sectionModel.hdmTotal.elementValue = updateTotal(hdmTotalArr); _sectionModel.hdmC = validate(_sectionModel.hdmC); checkValidForm()">
                </div>
              </td>
              <td>
                <label for="hdmE" class="sr-only">Home Delivered Meals Under Title 3 E</label>
                <div [ngClass]="(_sectionModel.hdmE.dataEntryErrorMessage)?'has-error':''">
                  <input [(ngModel)]="_sectionModel.hdmE.elementValue" [disabled]="!_sectionModel.hdmE.isRequired" type="text" class="form-control"
                    id="hdmE" (change)="unsavedChanges = true;  _sectionModel.hdmTotal.elementValue = updateTotal(hdmTotalArr); _sectionModel.hdmE = validate(_sectionModel.hdmE); checkValidForm()">
                </div>
              </td>
              <td>
                <label for="hdmTotal" class="sr-only">Total NSIP Qualified Home Delivered Meals</label>
                <input [(ngModel)]="_sectionModel.hdmTotal.elementValue" [disabled]="!_sectionModel.hdmTotal.isRequired" type="text" class="form-control"
                  id="hdmTotal" readonly>
              </td>
            </tr>
            <tr>
              <th class="normal" scope="row">Congregate meals</th>
              <td>
                <label for="congregateC" class="sr-only">Congregate Meals Under Title 3 C</label>
                <div [ngClass]="(_sectionModel.congregateC.dataEntryErrorMessage)?'has-error':''">
                  <input [(ngModel)]="_sectionModel.congregateC.elementValue" [disabled]="!_sectionModel.congregateC.isRequired" type="text"
                    class="form-control" id="congregateC" (change)="unsavedChanges = true;  _sectionModel.congregateTotal.elementValue = updateTotal(congregateTotalArr); _sectionModel.congregateC = validate(_sectionModel.congregateC); checkValidForm()">
                </div>
              </td>
              <td>
                <label for="congregateE" class="sr-only">Congregate Meals Under Title 3 E</label>
                <div [ngClass]="(_sectionModel.congregateE.dataEntryErrorMessage)?'has-error':''">
                  <input [(ngModel)]="_sectionModel.congregateE.elementValue" [disabled]="!_sectionModel.congregateE.isRequired" type="text"
                    class="form-control" id="congregateE" (change)="unsavedChanges = true;  _sectionModel.congregateTotal.elementValue = updateTotal(congregateTotalArr); _sectionModel.congregateE = validate(_sectionModel.congregateE); checkValidForm()">
                </div>
              </td>
              <td>
                <label for="congregateTotal" class="sr-only">Total NSIP Qualified Congregate Meals</label>
                <input [(ngModel)]="_sectionModel.congregateTotal.elementValue" [disabled]="!_sectionModel.congregateTotal.isRequired" type="text"
                  class="form-control" id="congregateTotal" readonly>
              </td>
            </tr>
          </tbody>
          </table>
          <br/>
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>Nutrition Program Meals (Title III C 1 and 2)</h2>
          </div>
          <div class="col-md-9">
            <label for="hdmCompare" class="normal">Home delivered meals</label>
          </div>
          <div class="col-md-3 mb-1">
            <div class="form-group">
              <div>
                <input [(ngModel)]="_sectionModel.hdmCompare.elementValue" [disabled]="!_sectionModel.hdmCompare.isRequired" type="text"
                  class="form-control" id="hdmCompare" readonly>
              </div>
            </div>
          </div>

          <div class="col-md-9">
            <label for="congregateCompare" class="normal">Congregate meals</label>
          </div>
          <div class="col-md-3 mb-1">
            <div class="form-group">
              <div>
                <input [(ngModel)]="_sectionModel.congregateCompare.elementValue" [disabled]="!_sectionModel.congregateCompare.isRequired"
                  type="text" class="form-control" id="congregateCompare" readonly>
              </div>
            </div>
          </div>

          <div class="row col-md-12">
            <button *ngIf="validForm && showSaveAndNext()" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>

            <button *ngIf="validForm" class="btn btn-default" (click)="save()">Save</button>
            <ng-container *ngIf="isACLUser">
              <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIISSReview']">Return to Overview</a>
            </ng-container>
            <ng-container *ngIf="!isACLUser">
              <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
