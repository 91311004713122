import { ClaimService } from '../shared/claim.service';
import { TIIIOverview } from '../shared/section';
import { QuestionStatusEnum } from '../shared/dataElement';
import { OrgLevelEnum } from '../app.constants';

export class ProgramViewConstraints {

    public static CACHE_KEY = 'iii:ProgramViewConstraints';
    public isSprViewable = false;
    public isNsipViewable = false;

    constructor(private claimService?: ClaimService, private overview?: TIIIOverview) {
        this.setViewableState();
    }

    private setViewableState() {
        if (this.claimService && this.overview) {
            // Determine if this is a ACL or AAA state user viewing State/AAA overview
            // const isAAAStateUserViewingAAAOverview = this.claimService.isAAAEnabledState
            //     && this.claimService.currentOrgLevel === OrgLevelEnum[OrgLevelEnum.State]
            //     && this.claimService.currentSubOrg;
            const isAAAStateUserViewingAAAOverview = false;

            const isACLUserViewingStateData = this.claimService.isACLUser();

            const shouldEnforceViewConstraints = isAAAStateUserViewingAAAOverview || isACLUserViewingStateData;

            if (shouldEnforceViewConstraints) {
                this.isSprViewable = this.overview.sprIsRequired
                    && this.overview.sprStatus >= QuestionStatusEnum.NotStarted;
                this.isNsipViewable = this.overview.nsipIsRequired
                    && this.overview.nsipStatus >= QuestionStatusEnum.SubmittedNoExplanation;
            } else {
                this.isNsipViewable = this.isSprViewable = true;
            }
        }
    }
}
