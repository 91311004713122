import { Component, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { NsipSection } from './NsipSection';
import { HomeDeliveredMealsUnitsSection } from './HomeDeliveredMealsUnitsSection';
import { CongregateMealsUnitsSection } from './CongregateMealsUnitsSection';
import { ToastrService } from '../shared/toastr.service';
import { CommonService } from '../shared/common.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { ValidationService } from '../shared/validation.service';
import { TitleIIISectionComponent } from './title-iii-section.component';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { UserManagementStrings } from '../UserManagement/userManagerObjects';

@Component({
  templateUrl: 'titleIIInsip.component.html',
})

export class TitleIIInsipComponent extends TitleIIISectionComponent<NsipSection> implements OnInit, OnDestroy {

  _sectionModel: NsipSection;
  _hdmSectionModel: HomeDeliveredMealsUnitsSection;
  _congSectionModel: CongregateMealsUnitsSection;
  _uploadModels: Array<NsipSection>;
  _batchIds: Array<string>;
  hdmTotalArr: Array<DataElement>;
  congregateTotalArr: Array<DataElement>;
  unsavedChanges: boolean = false;
  goToNextSection: boolean = false;
  statusEnum = QuestionStatusEnum;
  isACLUser: boolean = false;
  userManagementStrings = UserManagementStrings;

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public toastr: ToastrService,
    public _validation: ValidationService,
    public _commonService: CommonService
  ) { super(new NsipSection(), _commonService, _validation, router, _claimService, _service); }

  ngOnInit(): void {
    this._titleService.setTitle('NSIP Qualified Meals - Data Submissions - ACL OAAPS');
    this._sectionModel = new NsipSection();
    this.getnsipbyffyandstate();
    this.isACLUser = this._claimService.currentOrgLevel === this.userManagementStrings.orgLevelACLCO || this._claimService.currentOrgLevel === this.userManagementStrings.orgLevelACLRO;
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    super.registerSubscription(this._service.savensip(this._sectionModel)
      .subscribe(data => {
        this.getnsipbyffyandstate();
      },
        (error) => console.log(error),
        () => {
          callback();
          this.toastr.success('Saved!')
      }));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIIOverview']);
    if (!this._claimService.isViewOnly &&
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  getnsipbyffyandstate(): void {
    super.registerSubscription(
      this._service.get<NsipSection[]>(this._claimService, this._service._getnsipbyffyandstate, this.resolveSectionName())
        .pipe(
          switchMap(data => {
            this._sectionModel = data[0];
            return this._service.get<HomeDeliveredMealsUnitsSection[]>(
              this._claimService, this._service._gethomedeliveredmealsunitsbyffyandstate);
          }),
          switchMap(data => {
            this._hdmSectionModel = data[0];
            return this._service.get<CongregateMealsUnitsSection[]>(
              this._claimService, this._service._getcongregatemealsunitsbyffyandstate);
          }),
          tap(data => {
            this._congSectionModel = data[0];
            this._sectionModel.hdmCompare.elementValue = this._hdmSectionModel.totalServiceUnits.elementValue;
            this._sectionModel.congregateCompare.elementValue = this._congSectionModel.totalServiceUnits.elementValue;
            this.prepareDataModels();
          })
        ).subscribe()
    );
  }

  prepareDataModels() {
    this.initArrays();
    this.formatAllNumbersAlreadySaved();
    super.processSectionStatus();
}

  initArrays(): void {

    this.hdmTotalArr = [this._sectionModel.hdmC,
    this._sectionModel.hdmE];

    this.congregateTotalArr = [this._sectionModel.congregateC,
    this._sectionModel.congregateE];
  }


  updateTotal(totalsArray: Array<DataElement>): string {
    let total: number = 0;

    for (let i = 0; i < totalsArray.length; i++) {
      // if not null, remove commas
      if (totalsArray[i].elementValue) {
        totalsArray[i].elementValue = totalsArray[i].elementValue.replace(/,/g, '');
      }
      // if is a number, sum it
      if (!isNaN(parseInt(totalsArray[i].elementValue))) {
        total += parseInt(totalsArray[i].elementValue);
      }
      // replace commas when done adding
      totalsArray[i].elementValue = this.addComma(totalsArray[i].elementValue);
    }

    // add comma and return
    return this.addComma(total.toString());
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.hdmC.elementValue = this.addComma(this._sectionModel.hdmC.elementValue);
    this._sectionModel.hdmE.elementValue = this.addComma(this._sectionModel.hdmE.elementValue);
    this._sectionModel.hdmTotal.elementValue = this.addComma(this._sectionModel.hdmTotal.elementValue);
    this._sectionModel.congregateC.elementValue = this.addComma(this._sectionModel.congregateC.elementValue);
    this._sectionModel.congregateE.elementValue = this.addComma(this._sectionModel.congregateE.elementValue);
    this._sectionModel.congregateTotal.elementValue = this.addComma(this._sectionModel.congregateTotal.elementValue);
    this._sectionModel.hdmCompare.elementValue = this.addComma(this._sectionModel.hdmCompare.elementValue);
    this._sectionModel.congregateCompare.elementValue = this.addComma(this._sectionModel.congregateCompare.elementValue);
  }

  hdmError(): boolean {
    if (this._sectionModel.hdmC.dataEntryErrorMessage ||
      this._sectionModel.hdmE.dataEntryErrorMessage ||
      this._sectionModel.hdmTotal.dataEntryErrorMessage ||
      this._sectionModel.hdmC.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.hdmE.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.hdmTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  congError(): boolean {
    if (this._sectionModel.congregateC.dataEntryErrorMessage ||
      this._sectionModel.congregateE.dataEntryErrorMessage ||
      this._sectionModel.congregateTotal.dataEntryErrorMessage ||
      this._sectionModel.congregateC.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.congregateE.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.congregateTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  hdmWarning(): boolean {
    if (this._sectionModel.hdmC.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.hdmE.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.hdmTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  congWarning(): boolean {
    if (this._sectionModel.congregateC.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.congregateE.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.congregateTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    return !this.unsavedChanges;
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

  showSaveAndNext() {
    if (!this._claimService.isViewOnly && this._sectionModel.isRequired
      && this._sectionModel.sectionStatus <= QuestionStatusEnum.Returned) {
      return true;
    } else { return false; }
  }

}
