import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

export class LastUpdated {
    constructor(private _lastUpdateTime?: Date, private _lastUpdateBy?: string) {
        if (!_lastUpdateTime) {
            this._lastUpdateTime = new Date('0001-01-01T00:00');
        }
    }

    public get lastUpdateTime(): Date {
        return this._lastUpdateTime;
    }

    public get lastUpdateBy(): string {
        return this._lastUpdateBy;
    }

    public set lastUpdateTime(lastUpdateTime: Date) {
        this._lastUpdateTime = lastUpdateTime;
    }

    public set lastUpdateBy(lastUpdateBy: string) {
        this._lastUpdateBy = lastUpdateBy;
    }

    public getDisplayHtml(sanitizer: DomSanitizer): SafeHtml {
        if (!this.lastUpdateTime.toISOString().startsWith('0001-01-01T00:00') && this.lastUpdateBy) {
            const momentDate = moment(this.lastUpdateTime);
            return sanitizer.bypassSecurityTrustHtml(momentDate.format('MM/DD/YYYY') + ' at ' + momentDate.format('h:mm A')
                + ' by <br/> ' + this.lastUpdateBy);
        }
        return '';
    }
}
