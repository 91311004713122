
import { Section } from '../shared/section';
import { DynamicFormItem } from '../shared/dynamicFormItem';


export class ServicesProvidedSection extends Section {

    public services: Array<DynamicFormItem> = [];

    constructor() {
        super();
    }
}
