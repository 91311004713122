import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OktaService } from '../service/okta.service';

export const LogoutTypeParam: string = 'logoutType';
export const LogoutTypes = {
  Timeout: 'timeout',
  Signoff: 'signoff',
  Signedout: 'signedout',
};

@Component({
  selector: 'app-logout',
  templateUrl: 'logout.component.html'
})
export class LogoutComponent implements OnInit {

  constructor(public oktaService: OktaService,
    private route: ActivatedRoute,
    public router: Router) { }

  ngOnInit() {
    sessionStorage.removeItem('title');
    sessionStorage.removeItem('fiscalYear');
    sessionStorage.removeItem('fiscalYear');
    sessionStorage.removeItem('isViewRollupOverview');
    this.route.queryParams.subscribe((params: Params) => {
      this.setLogoutType(params[LogoutTypeParam]);
      this.logout();
      this.router.navigate(['/welcome']);
    }, err => this.logout());
  }

  logout() {
    this.oktaService.logout();
  }

  setLogoutType(type: string) {
    switch (type) {
      case (LogoutTypes.Timeout): {
        sessionStorage.setItem(LogoutTypeParam, LogoutTypes.Timeout);
        break;
      }
      default: {
        sessionStorage.setItem(LogoutTypeParam, LogoutTypes.Signoff);
        break;
      }
    }
  }

}

