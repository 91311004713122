import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class ANRespiteSection extends Section {

    coaPersonsServed: IDataElement;
    coaExpend: IDataElement;
    coaState: IDataElement;
    coaNonState: IDataElement;
    coaProgramIncome: IDataElement;

    orcPersonsServed: IDataElement;
    orcExpend: IDataElement;
    orcState: IDataElement;
    orcNonState: IDataElement;
    orcProgramIncome: IDataElement;

    constructor() {
        super();

        this.coaPersonsServed = new DataElement();
        this.coaExpend = new DataElement();
        this.coaState = new DataElement();
        this.coaNonState = new DataElement();
        this.coaProgramIncome = new DataElement();

        this.orcPersonsServed = new DataElement();
        this.orcExpend = new DataElement();
        this.orcState = new DataElement();
        this.orcNonState = new DataElement();
        this.orcProgramIncome = new DataElement();

    }
}
