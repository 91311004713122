import { Component, OnInit, Input, Output, EventEmitter, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClaimService } from '../shared/claim.service';
import { ITVIIOverview, TVIIOverview, ITIIIOverview, TIIIOverview, ISection, Section } from '../shared/section';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleVIIDataEntryService } from './titleVIIDataEntry.service';
import { TitleIIIDataEntryService } from '../DataSubmissions/titleIIIDataEntry.service';
import { CommonService } from '../shared/common.service';
import { routeLookup } from '../navigation/landing/landing.routes';
import { CacheService } from '../shared/cache-service';
import { TitleVIDataEntryService } from '../TitleVI/titleVIDataEntry.service';
import { ITVIOverviewDto } from '../TitleVI/model/Overview';
import { TitleVIOverviewState } from '../TitleVI/overview/titleVIOverviewState';
import { IOverview } from '../shared/Overview/overview';
import { AclHeader } from '../shared/AclReviewHeader/aclHeader';
import { AclTitleEnum } from '../app.constants';
import { DestructibleComponent } from '../destructible.component';
import { ProgramViewConstraints } from '../DataSubmissions/ProgramViewContraints';

@Component({
  selector: 'sideNav',
  templateUrl: 'sideNav.component.html'
})

export class SideNavComponent extends DestructibleComponent implements OnInit, OnDestroy {

  @Input() showNavigation: boolean = true;
  @Output() overview: EventEmitter<IOverview> = new EventEmitter<IOverview>();
  private readonly titleIIIModule = 'data-submissions';

  state: string;
  ffy: string;
  currentTitle: string;

  titleEnum = AclTitleEnum;
  staffInfoABStatus: QuestionStatusEnum;
  nutritionServicesStatus: QuestionStatusEnum;
  accessServicesStatus: QuestionStatusEnum;
  inHomeServicesStatus: QuestionStatusEnum;
  otherServicesStatus: QuestionStatusEnum;
  financeABStatus: QuestionStatusEnum;
  storytellingStatus: QuestionStatusEnum;

  staffInfoCStatus: QuestionStatusEnum;
  caregiverCharsStatus: QuestionStatusEnum;
  caregiverServicesStatus: QuestionStatusEnum;
  suppServicesStatus: QuestionStatusEnum;
  respiteStatus: QuestionStatusEnum;
  financeCStatus: QuestionStatusEnum;

  iiiViewConstraints;

  showAgingNetwork: boolean = false;
  agingNetworkRoutes: Array<string> = ['titleIIIANNetworkFunding', 'titleIIIANStaffAndVolunteers',
    'titleIIIANServiceProviders', 'titleIIIANSeniorCenters', 'titleIIIANSelfDirection', 'titleIIIANRespite'];

  showOlderAdults: boolean = false;
  olderAdultsRoutes: Array<string> = [
    'OAConsumerTps', 'OAConsumerBoth', 'OAConsumerOne', 'titleIIIPersonalCareChars',
    'titleIIIPersonalCareUnits', 'titleIIIHomemakerChars', 'titleIIIHomemakerUnits', 'titleIIIChoreChars',
    'titleIIIChoreUnits', 'titleIIIHomeDeliveredMealsChars', 'titleIIIHomeDeliveredMealsUnits',
    'titleIIIAdultDayCareChars', 'titleIIIAdultDayCareUnits', 'titleIIICaseMgmtChars', 'titleIIICaseMgmtUnits',
    'titleIIIAssistedTransportationChars', 'titleIIIAssistedTransportationUnits', 'titleIIICongregateMealsChars',
    'titleIIICongregateMealsUnits', 'titleIIINutritionCounselingChars', 'titleIIINutritionCounselingUnits',
    'titleIIILegalChars', 'titleIIILegalUnits', 'OALegalCases', 'titleIIITransportation', 'titleIIINutritionEducation',
    'titleIIIInfoAndAssistance', 'healthPromoEvidence', 'healthPromoNonEvidence', 'titleIIIOtherServices', 'titleIIIOtherExpend',
    'titleIIIHomeDeliveredMealsUnits'];

  showCOA: boolean = false;
  coaRoutes: Array<string> = [
    'COAConsumerTps', 'titleIIICOAConsumerSummary', 'titleIIICOACounseling', 'titleIIICOATraining', 'titleIIICOARespite',
    'titleIIICOASuppExpend', 'titleIIICOASuppProvided',
    'titleIIICOACaseMgmt', 'titleIIICOASupport', 'titleIIICOAInfoAndAssistance', 'titleIIICOAInfoServices'];

  showORC: boolean = false;
  orcRoutes: Array<string> = [
    'ORCConsumerTps', 'titleIIIORCConsumerSummary', 'titleIIIORCCounseling', 'titleIIIORCTraining', 'titleIIIORCRespite',
    'titleIIIORCSuppExpend', 'titleIIIORCSuppProvided',
    'titleIIIORCCaseMgmt', 'titleIIIORCSupport', 'titleIIIORCInfoAndAssistance', 'titleIIIORCInfoServices'];

  showExpenditures: boolean = false;
  expenditureRoutes: Array<string> = [
    'titleIIIOAExpenditures', 'titleIIICOAExpenditures', 'titleIIIORCExpenditures', 'titleVIIExpenditures'];

  showVarianceExplanations: boolean = false;
  varianceExplanationsRoutes: Array<string> = [
    'titleIIIVarianceExplanationsOA', 'titleIIIVarianceExplanationsCG', 'titleIIIVarianceExplanationsOR',
    'titleIIIVarianceExplanationsNSIP'];

  titleVIIOverviewModel: ITVIIOverview = new TVIIOverview();
  titleIIIOverviewModel: ITIIIOverview = new TIIIOverview();
  yearArrayBasic: Array<string> = [];
  enababledTitleIIIStates: Array<string> = [];
  statusEnum = QuestionStatusEnum;
  overviewDtos: ITVIOverviewDto;
  tviOverviewState: TitleVIOverviewState;

  constructor(public zone: NgZone,
    public _claimService: ClaimService,
    public route: ActivatedRoute,
    public router: Router,
    public _titleIIIService: TitleIIIDataEntryService,
    public _titleVIIService: TitleVIIDataEntryService,
    public _commonService: CommonService,
    public _cacheService: CacheService,
    public _titleVIservice: TitleVIDataEntryService,
  ) { super(); }

  ngOnInit(): void {
    this._commonService.agingNetworkLockedNav = false;
    this._commonService.oaLockedNav = false;
    this._commonService.coaLockedNav = false;
    this._commonService.orcLockedNav = false;
    this._commonService.eoLockedNav = false;
    this._commonService.veLockedNav = false;
    super.registerSubscription(this._claimService.init$.subscribe(data => { if (data) { this.init(); } }, err => console.log(err)));
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  addAccessabilityAttrs(): void {
    setTimeout(() => {
      const activeSubNav = document.querySelector('.subActive');
      // Set aria-label's on subnav items
      const applyAriaLabels = (root) => {
        let navTitle = '';
        // Find subnav title
        this._commonService.findElementsAndApply(root,
          e => e.tagName.toUpperCase() === 'STRONG' || e.tagName.toUpperCase() === 'A',
          strong => navTitle = strong.textContent);
        this._commonService.findElementsAndApply(
          root,
          e => e.tagName.toUpperCase() === 'UL',
          ul => {
            this._commonService.findElementsAndApply(ul, e => e.tagName.toUpperCase() === 'LI',
              li => {
                this._commonService.findElementsAndApply(li, e => e.tagName.toUpperCase() === 'A',
                  a => a.setAttribute('aria-label', `${navTitle.trim()}, sub navigation, ${a.textContent.trim()}`));
                if (li.className.includes('subActive')) {
                  applyAriaLabels(li);
                }
                if (li.className.includes('withSub')) {
                  this._commonService.findElementsAndApply(li.children[0], e => e.tagName.toUpperCase() === 'LI',
                    innerLi =>
                      innerLi.children[0].setAttribute('aria-label', `${navTitle.trim()}, sub navigation, ${innerLi.textContent.trim()}`))
                }
              });
          }
        );
      }
      applyAriaLabels(activeSubNav);
    }, 0);
  }

  init(): void {
    this.state = this._claimService.currentOrg;
    this.ffy = this._claimService.currentYear;
    if (this._claimService.currentTitle === 'III') {
      let o$;
      this.iiiViewConstraints = this._cacheService.getCacheItem(`${ProgramViewConstraints.CACHE_KEY}:${this._claimService.currentOrg}`)
        || new ProgramViewConstraints();
      if (this._claimService.isRollupOverview) {
        // Rollup overview
        o$ = this._titleIIIService.getRollupOverviewByFfyAndState(this._claimService.currentYear,
          this._claimService.currentOrg);
      } else {
        // State/AAA overview
        o$ = this._titleIIIService.getOverviewByFfyAndState(this._claimService.currentYear,
          this._claimService.currentOrg, this._claimService.currentSubOrg);
      }
      super.registerSubscription(o$.subscribe(overview => {
        this.iiiViewConstraints = new ProgramViewConstraints(this._claimService, overview);
        this._cacheService.putCacheItem(`${ProgramViewConstraints.CACHE_KEY}:${this._claimService.currentOrg}`, this.iiiViewConstraints);
        this.setTitleIIIOverviewStatus(overview);
        this._claimService.tiiiStatuses.setTitleIIIStatuses(overview);
        this.hydrateCache();
        this.addAccessabilityAttrs();
      }));
    }
    if (this._claimService.currentTitle === 'VII') {
      if (this._claimService.tviiStatuses.caseAndComplaintSectionStatus == null ||
        this._claimService.tviiStatuses.staffAndVolunteerSectionStatus == null ||
        this._claimService.tviiStatuses.fundsExpendedSectionStatus == null ||
        this._claimService.tviiStatuses.programActivitiesSectionStatus == null ||
        this._claimService.tviiStatuses.orgStructureSectionStatus == null ||
        this._claimService.tviiStatuses.complaintExamplesParentSectionStatus == null ||
        this._claimService.tviiStatuses.nursingfacilitySectionStatus == null ||
        this._claimService.tviiStatuses.rccSectionStatus == null ||
        this._claimService.tviiStatuses.optionalExampleSectionStatus == null ||
        this._claimService.tviiStatuses.systemIssuesParentSectionStatus == null ||
        this._claimService.tviiStatuses.systemsIssue1SectionStatus == null ||
        this._claimService.tviiStatuses.systemsIssue2SectionStatus == null ||
        this._claimService.tviiStatuses.optionalSystemIssueSectionStatus == null ||
        this._claimService.tviiStatuses.facilityParentSectionStatus == null ||
        this._claimService.tviiStatuses.numbersAndCapacitySectionStatus == null ||
        this._claimService.tviiStatuses.rccInformationSectionStatus == null) {
        super.registerSubscription(this._titleVIIService.getOverviewbyffyandstate(this.ffy, this.state)
          .subscribe(data => {
            if (data != null) {
              this._claimService.tviiStatuses.setTitleVIIStatuses(data);
            }
          }));
      }
      this.addAccessabilityAttrs();
    }
    if (this._claimService.currentTitle === 'VI') {
      super.registerSubscription(this._titleVIservice.getoverviewbyffyandorg(this._claimService.currentYear, this._claimService.currentOrg)
        .subscribe(
          overviews => {
            this.overview.emit(overviews.overview);
            this.setTVIOverviewState(overviews);
            this.addAccessabilityAttrs();
          }
        ));
    }

    this.currentTitle = this._claimService.currentTitle;
    // this.setFiscalYears();
    // gets piece of url after the last "/"
    var routeName = this.router.url.split('/').pop();
    var routeArr = routeName.split('?'); // remove query params and return just the route
    routeName = routeArr[0];


    // if the user is on a subpage, then don't auto-hide that section
    if (this.agingNetworkRoutes.includes(routeName)) {
      this.showAgingNetwork = true;
    }

    if (this.olderAdultsRoutes.includes(routeName)) {
      this.showOlderAdults = true;
    }

    if (this.coaRoutes.includes(routeName)) {
      this.showCOA = true;
    }

    if (this.orcRoutes.includes(routeName)) {
      this.showORC = true;
    }

    if (this.expenditureRoutes.includes(routeName)) {
      this.showExpenditures = true;
    }
    if (this.varianceExplanationsRoutes.includes(routeName)) {
      this.showVarianceExplanations = true;
    }
    // end auto-hide checks
  }

  // Hydrates overview tree in cache
  private hydrateCache(): void {
    this._cacheService.putCacheItem(routeLookup.lookupPath(TIIIOverview.ANSectionServiceName),
      this.titleIIIOverviewModel.sections.find(x => x.sectionName === TIIIOverview.ANSectionServiceName));
    this._cacheService.putCacheItem(routeLookup.lookupPath(TIIIOverview.OASectionServiceName),
      this.titleIIIOverviewModel.sections.find(x => x.sectionName === TIIIOverview.OASectionServiceName));
    this._cacheService.putCacheItem(routeLookup.lookupPath(TIIIOverview.COASectionServiceName),
      this.titleIIIOverviewModel.sections.find(x => x.sectionName === TIIIOverview.COASectionServiceName));
    this._cacheService.putCacheItem(routeLookup.lookupPath(TIIIOverview.ORCSectionServiceName),
      this.titleIIIOverviewModel.sections.find(x => x.sectionName === TIIIOverview.ORCSectionServiceName));
    this._cacheService.putCacheItem(routeLookup.lookupPath(TIIIOverview.ExpendituresSectionServiceName),
      this.createExpendituresLanding(
        this.titleIIIOverviewModel.sections.find(x => x.sectionName === TIIIOverview.ExpendituresSectionServiceName)));
    this._cacheService.putCacheItem(routeLookup.lookupPath('titleIIIVarianceHome'),
      this.createVarianceLanding(this.titleIIIOverviewModel.sections, this.titleIIIOverviewModel.sprStatus));

    this._cacheService.cacheUpdated$.next(true);
  }


  // UI model differs from overview, so we have to build this one manually
  // Consideration: might be better off as JSON
  private createExpendituresLanding(parentSection: ISection) {
    const serviceExpenditures = parentSection.childSection.find(x => x.sectionName === 'ServiceExpenditures');
    // Title III Service Expenditures
    const expenditureLanding = {
      groupTitle: 'Expenditures Overview',
      sortOrder: 0,
      childSection: [
        {
          groupTitle: 'Title III Service Expenditures',
          sortOrder: 0,
          childSection: [
            {
              sectionTitle: 'Older Adults Expenditures',
              sectionName: 'titleIIIOAExpenditures',
              sortOrder: 0,
              sectionStatus: serviceExpenditures.childSection.find(x => x.sectionName === 'OAExpendituresChildren').sectionStatus
            },
            {
              sectionTitle: 'Caregivers of Older Adults Expenditures',
              sectionName: 'titleIIICOAExpenditures',
              sortOrder: 1,
              sectionStatus: serviceExpenditures.childSection.find(x => x.sectionName === 'COAExpendituresChildren').sectionStatus
            },
            {
              sectionTitle: 'Older Relative Caregivers Expenditures',
              sectionName: 'titleIIIORCExpenditures',
              sortOrder: 2,
              sectionStatus: serviceExpenditures.childSection.find(x => x.sectionName === 'ORCExpendituresChildren').sectionStatus
            },
          ]
        },
        {
          groupTitle: 'Title VII Expenditures',
          sectionTitle: 'Title VII Expenditures',
          sectionName: 'titleVIIExpenditures',
          sortOrder: 1,
          sectionStatus: parentSection.childSection.find(x => x.sectionName === 'titleVIIExpenditures').sectionStatus
        }
      ]
    };
    return expenditureLanding;
  }

  private createVarianceLanding(section: ISection[], sprStatus: QuestionStatusEnum) {

    const nsipParent = section.find(x => x.type === TIIIOverview.nsipSectionServiceName);

    const varianceLanding = {
      groupTitle: 'Explanation of Variances',
      sortOrder: 0,
      childSection: [
        {
          groupTitle: 'Older Adults',
          sectionTitle: 'Variance Explanations',
          sectionName: 'titleIIIVarianceExplanationsOA',
          sortOrder: 0,
          sectionStatus: sprStatus,
          isRequired: this._claimService.tiiiStatuses.oaIsRequired
        },
        {
          groupTitle: 'Caregivers of Older Adults',
          sectionTitle: 'Variance Explanations',
          sectionName: 'titleIIIVarianceExplanationsCG',
          sortOrder: 1,
          sectionStatus: sprStatus,
          isRequired: this._claimService.tiiiStatuses.coaIsRequired
        },
        {
          groupTitle: 'Older Relative Caregivers',
          sectionTitle: 'Variance Explanations',
          sectionName: 'titleIIIVarianceExplanationsOR',
          sortOrder: 2,
          sectionStatus: sprStatus,
          isRequired: this._claimService.tiiiStatuses.orcIsRequired
        },
        {
          groupTitle: 'NSIP Qualified Meals',
          sectionTitle: 'Variance Explanations',
          sectionName: 'titleIIIVarianceExplanationsNSIP',
          sortOrder: 3,
          sectionStatus: nsipParent !== undefined ? nsipParent.sectionStatus : 0,
          isRequired: this._claimService.tiiiStatuses.nsipIsRequired
        }
      ]
    };
    varianceLanding.childSection = varianceLanding.childSection.filter(
      x => x.sectionStatus >= QuestionStatusEnum.L3NoExplanation && x.isRequired);
    return varianceLanding;
  }

  setTitleIIIOverviewStatus(data: ITIIIOverview): void {
    if (data != null) {
      this.titleIIIOverviewModel = data;
      this._claimService.tiiiStatuses.sprStatus = this.titleIIIOverviewModel.sprStatus;
      this._claimService.tiiiStatuses.nsipStatus = this.titleIIIOverviewModel.nsipStatus;
    } else {
      this._claimService.tiiiStatuses.sprStatus = 0;
      this._claimService.tiiiStatuses.nsipStatus = 0;
    }
  }

  get isANHomeActive(): boolean {
    return this.router.url.includes('/landing/titleIIIANHome');
  }

  displayAgingNetwork(): void {
    this.router.navigate(['/landing/titleIIIANHome'], { queryParams: { module: this.titleIIIModule }, queryParamsHandling: "merge" });
    // Hack for race condition on the this._commonService.anLockedNav
    // queue this code for the the next iteration of the event loop
    // after the property is set correctly
    setTimeout(() => {
      if (!this._commonService.agingNetworkLockedNav) {
        this.showAgingNetwork = !this.showAgingNetwork; // Toggle
        this.addAccessabilityAttrs();
      }
    }, 0);
  }

  get isOAHomeActive(): boolean {
    return this.router.url.includes('/landing/titleIIIOAHome');
  }

  displayOlderAdults(): void {
    this.router.navigate(['/landing/titleIIIOAHome'], { queryParams: { module: this.titleIIIModule }, queryParamsHandling: "merge" });
    setTimeout(() => {
      if (!this._commonService.oaLockedNav) {
        this.showOlderAdults = !this.showOlderAdults;
        this.addAccessabilityAttrs();
      }
    }, 0);
  }

  get isCOAHomeActive(): boolean {
    return this.router.url.includes('/landing/titleIIICOAHome');
  }

  displayCOA(): void {
    this.router.navigate(['/landing/titleIIICOAHome'], { queryParams: { module: this.titleIIIModule }, queryParamsHandling: "merge" });
    setTimeout(() => {
      if (!this._commonService.coaLockedNav) {
        this.showCOA = !this.showCOA;
        this.addAccessabilityAttrs();
      }
    }, 0);
  }


  get isORCHomeActive(): boolean {
    return this.router.url.includes('/landing/titleIIIORCHome');
  }

  displayORC(): void {
    this.router.navigate(['/landing/titleIIIORCHome'], { queryParams: { module: this.titleIIIModule }, queryParamsHandling: "merge" });
    setTimeout(() => {
      if (!this._commonService.orcLockedNav) {
        this.showORC = !this.showORC;
        this.addAccessabilityAttrs();
      }
    }, 0);
  }

  get isExpendituresHomeActive(): boolean {
    return this.router.url.includes('/landing/titleIIIExpendituresHome');
  }

  displayExpenditures(): void {
    this.router.navigate(['/landing/titleIIIExpendituresHome'], { queryParams: { module: this.titleIIIModule }, queryParamsHandling: "merge" });
    setTimeout(() => {
      if (!this._commonService.eoLockedNav) {
        this.showExpenditures = !this.showExpenditures;
        this.addAccessabilityAttrs();

      }
    }, 0);
  }

  get isVarianceHomeActive(): boolean {
    return this.router.url.includes('/landing/titleIIIVarianceHome');
  }

  displayVarianceExplanations(): void {
    this.router.navigate(['/landing/titleIIIVarianceHome'], { queryParams: { module: this.titleIIIModule }, queryParamsHandling: "merge" });
    setTimeout(() => {
      if (!this._commonService.veLockedNav) {
        this.showVarianceExplanations = !this.showVarianceExplanations;
        this.addAccessabilityAttrs();
      }
    }, 0);
  }

  disableT7VENav(): boolean {
    if (this._claimService.tviiStatuses.caseAndComplaintSectionStatus < QuestionStatusEnum.L3NoExplanation
      && this._claimService.tviiStatuses.staffAndVolunteerSectionStatus < QuestionStatusEnum.L3NoExplanation
      && this._claimService.tviiStatuses.fundsExpendedSectionStatus < QuestionStatusEnum.L3NoExplanation
      && this._claimService.tviiStatuses.facilityParentSectionStatus < QuestionStatusEnum.L3NoExplanation
      && this._claimService.tviiStatuses.programActivitiesSectionStatus < QuestionStatusEnum.L3NoExplanation
      && this._claimService.tviiStatuses.orgStructureSectionStatus < QuestionStatusEnum.L3NoExplanation) {
      return true;
    } else {
      return false;
    }
  }

  public getActiveClass(regex: string) {
    if (this.router.url.match(regex)) {
      return 'active';
    }
  }

  public get navHeader(): string {
    let header = '';
    if (this.currentTitle !== 'VI') {
      if (this._claimService.isRollupOverview) {
        header = 'State-Level Roll-up';
      } else if (!this._commonService.isNullOrEmpty(this._claimService.currentSubOrg)) {
        header = 'AAA-Provided Data';
      } else {
        header = 'State-Provided Data';
      }
    } else {
      header = 'Program Performance Report (PPR)';
    }

    return header;
  }

  public buildAclHeader(): AclHeader {
    let title, orgLabel, orgName, returnLink, returnText, returnParams = <any>{};
    title = this._claimService.currentTitle;
    if (this._claimService.currentTitle === 'VI') {
      orgLabel = 'Grantee';
      let granteeNum = sessionStorage.getItem('granteeNum');
      orgName = this._claimService.currentTitleOrg.fullName + ` (${granteeNum})`
        || this._claimService.currentOrg;
      returnLink = '/titlevi/acl-ppr-submissions-review';
      returnText = 'Back to grantee submission review';
      returnParams.granteeId = this._claimService.currentOrg;
    } else {
      orgLabel = 'State';
      orgName = this._commonService.stateAbbrvToFullName(this._claimService.currentOrg);
      returnText = 'Back to state submission review';
      if (this._claimService.currentTitle === 'VII') {
        returnLink = '/state-submissions/titleVIISSReview';
      } else if (this._claimService.currentTitle === 'III') {
        returnLink = '/data-submissions/titleIIISSReview';
      }
    }
    return {
      title,
      orgLabel,
      orgName,
      returnLink,
      returnText,
      returnParams,
      fiscalYear: this._claimService.currentYear,
      shouldRender: this._claimService.isACLUser()
    };
  }

  // TVI logic

  public titleViIsPartCRequired() {
    if (this.overviewDtos != null) {
      return this.overviewDtos.overview.children[1].isRequired;
    }
    return false;
  }

  public setTVIOverviewState(overviewDto: ITVIOverviewDto) {
    this.overviewDtos = overviewDto;
    this.tviOverviewState = new TitleVIOverviewState({ isViewOnly: false, isSubmitter: false, isApprover: false }, this.overviewDtos);
    this.setTVIStatuses();
  }

  public updateTVIOverviewStatus(section: Section) {
    this.tviOverviewState.updateOverviewStatus(section);
    this.setTVIStatuses();
  }

  private setTVIStatuses() {
    this.staffInfoABStatus = this.tviOverviewState.findOverview('staffInfoAB').sectionStatus;
    this.nutritionServicesStatus = this.tviOverviewState.findOverview('nutritionServices').sectionStatus;
    this.accessServicesStatus = this.tviOverviewState.findOverview('accessServices').sectionStatus;
    this.inHomeServicesStatus = this.tviOverviewState.findOverview('inHomeServices').sectionStatus;
    this.otherServicesStatus = this.tviOverviewState.findOverview('otherServices').sectionStatus;
    this.financeABStatus = this.tviOverviewState.findOverview('financeAB').sectionStatus;
    this.storytellingStatus = this.tviOverviewState.findOverview('storytelling').sectionStatus;
    this.staffInfoCStatus = this.tviOverviewState.findOverview('staffInfoC').sectionStatus;
    this.caregiverCharsStatus = this.tviOverviewState.findOverview('caregiverChars').sectionStatus;
    this.caregiverServicesStatus = this.tviOverviewState.findOverview('caregiverServices').sectionStatus;
    this.suppServicesStatus = this.tviOverviewState.findOverview('suppServices').sectionStatus;
    this.respiteStatus = this.tviOverviewState.findOverview('respite').sectionStatus;
    this.financeCStatus = this.tviOverviewState.findOverview('financeC').sectionStatus;
  }

  attachDocument(): void {
    let url = '/titlevi/manageAttachments';
    let status = QuestionStatusEnum.NotStarted;
    if (this._claimService.currentTitle === AclTitleEnum[AclTitleEnum.III]) {
      url='/data-submissions/manageAttachments';
      status = Math.min(this._claimService.tiiiStatuses.sprStatus, this._claimService.tiiiStatuses.nsipStatus);
    }
    else if (this._claimService.currentTitle === AclTitleEnum[AclTitleEnum.VII]) {
      url='/state-submissions/manageAttachments';
      status = this._claimService.tviiStatuses.tVIIOverallStatus;
    }
    this.router.navigate([url], {
      queryParams: {
        status: status
      }
    });
  }

}
