import { Component } from '@angular/core';
import {  Subscription, timer } from 'rxjs';

@Component({
    template: `<div *ngIf="isLoading" class="loading">Loading&#8230;</div>`,
    selector: 'loading'
})

export class LoadingComponent {

    public isLoading: boolean = false;
    public timerSubscription: Subscription;

    constructor() { }

    public setLoadingInProgress(timeout?: number): void {
        this.clearSubscriber();
        this.isLoading = true;
        const number = timer(0, 1000);
        this.timerSubscription = number.subscribe(ticks => {
            if (ticks > (timeout || 30)) {
                this.isLoading = false;
                this.clearSubscriber();
            }
        });
    }

    public setLoadingComplete(): void {
        this.clearSubscriber();
        this.isLoading = false;
    }

    public clearSubscriber(): void {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }
}
