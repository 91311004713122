<acl-return-comments [model]="dashboardModel.statusHistory"></acl-return-comments>
<div class="row" *ngIf="dashboardModel.currentOrgLevel === userManagementStrings.orgLevelGrantee">
  <h2 class="col-md-9 pl-1 mb-2">{{dashboardModel.fullName}}</h2>
  <div class="col-md-9">
    <table class="table">
      <thead>
        <tr class="background-gray-lighter">
          <th scope="col" id="pprReportingtitle">PPR ({{ dashboardModel.part }}) Reporting Status</th>
          <th scope="col" id="pprLastUpdated">Last Update</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div style="margin-top: .5em; display: inline-block;" 
              [ngClass]="_commonService.setColor(dashboardModel.granteeReportingStatus)" [innerHTML]="_commonService.statusReader(dashboardModel.granteeReportingStatus)" id="pprReportingStatus"></div> 
            <button *ngIf="dashboardModel.granteeReportingStatus === statusEnum.Returned" class="pull-right btn-sm btn-action" 
              data-toggle="modal" data-target="#aclReturnCommentsModal">View return corrections</button>
          </td>
          <td>
            <span class="block" *ngIf="dashboardModel.granteeLastModified != null && !dashboardModel.granteeLastModified.startsWith('0001')" id="pprLastUpdated">
              {{dashboardModel.granteeLastModified | date : 'shortDate'}} at {{dashboardModel.granteeLastModified | date : 'shortTime'}}
              by
            </span>
            <span class="block">{{dashboardModel.granteeUpdatedBy}}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <a id="viewStatusHistoryLnk" [routerLink]="'/titlevi/status-log'">View status history</a>
    </div>
  </div>
  <div class="col-md-3">
    <img src="/assets/images/TitleVI-logo.png" alt="Office for American Indian, Alaskan Native, and Native Hawaiian Programs; Older Americans Act Title Six" class="flags img-responsive t6">
  </div>
</div>
<div *ngIf="dashboardModel.currentOrgLevel === userManagementStrings.orgLevelACLCO || dashboardModel.currentOrgLevel === userManagementStrings.orgLevelACLRO">
  <div class="col-md-12 row">
    <h2 class="col-md-10 pl-1 mb-2">{{dashboardModel.currentYear}} Grantee Submission Statuses</h2>
    <div class="col-md-2 p-0 dropdown">
      <div class="pull-right acl-select">
        <label class="sr-only" for="regionDropdown">Filter by region:</label>
        <select id="regionDropdown" (change)="regionChangedHandler($event.target.value)" aria-label="Filter by region">
          <option value="myGrantees" *ngIf="dashboardModel.myGranteeDefined">My Grantees</option>
          <option value="all">All Grantees</option>
          <option *ngFor="let region of dashboardModel.regions" [ngValue]="region" value="{{region}}">Region {{region}}</option>
        </select>
      </div>
    </div>
  </div>
    <div class="col-md-12">
      <table class="table">
        <thead>
          <tr class="background-gray-lighter">
            <td></td>
            <th scope="col">Not Started</th>
            <th scope="col">In Progress</th>
            <th scope="col">Submitted</th>
            <th scope="col">In Review</th>
            <th scope="col">Returned</th>
            <th scope="col">Approved</th>
            <th scope="col">Locked</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">PPR ({{ dashboardModel.part }})</th>
            <td  id="notStartedStatus">
              {{dashboardModel.pprStatusCount[0]}}
            </td>
            <td  id="inProgressStatus">
              {{dashboardModel.pprStatusCount[1]}}
            </td>
            <td  id="submittedStatus">
              {{dashboardModel.pprStatusCount[2]}}
            </td>
            <td  id="inReviewStatus">
              {{dashboardModel.pprStatusCount[3]}}
            </td>
            <td  id="returnedStatus">
              {{dashboardModel.pprStatusCount[4]}}
            </td>
            <td  id="approvedStatus">
              {{dashboardModel.pprStatusCount[5]}}
            </td>
            <td  id="lockedStatus">
              {{dashboardModel.pprStatusCount[6]}}
            </td>
            <td  id="totalStatus">
              {{dashboardModel.pprStatusCount[7]}}
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <a id="pprSubmissionStatusReportLnk" (click)="tviPprSubmissionStatusReport()">PPR Submission Status Report</a>
      </div>
    </div>
</div>
<div class="col-md-12">
<div class="flex-list negSevenM mt-8">
  <div  *ngIf="dashboardModel.currentOrgLevel === userManagementStrings.orgLevelACLCO || dashboardModel.currentOrgLevel === userManagementStrings.orgLevelACLRO" class="flex-list-item">
    <div class="gray-dash flex-list-content">
      <acl-news [newsModel]="recentSystemUpdates"></acl-news>
    </div>
  </div>
  <div class="flex-list-item">
    <div class="gray-dash flex-list-content">
      <acl-news [newsModel]="reportingCalendar"></acl-news>
    </div>
  </div>
  <div class="flex-list-item">
    <div class="gray-dash flex-list-content">
      <acl-news [newsModel]="upcomingEvents"></acl-news>
    </div>
  </div>
  <div *ngIf="dashboardModel.currentOrgLevel === userManagementStrings.orgLevelGrantee" class="flex-list-item">
    <div class="gray-dash flex-list-content">
      <span class="btn-floating btn-large">
        <i class="fa fa-address-card-o" aria-hidden="true"></i>
      </span>
      <h3>Regional Contact</h3>
      <ul class="list-unstyled" *ngIf="regionalContact != null">
        <li>
          <i class="fa fa-envelope-o" aria-hidden="true"></i>
          Name:
          <a href="mailto:{{regionalContact.email}}">{{regionalContact.firstName}} {{regionalContact.lastName}}</a>
        </li>
        <li *ngIf="regionalContact.phoneNumber != null">
          <i class="fa fa-phone" aria-hidden="true"></i>
          Phone: {{regionalContact.phoneNumber | phone}}
        </li>
        <li *ngIf="regionalContact.phoneNumber == null">
          <i class="fa fa-phone" aria-hidden="true"></i>
          Phone: No phone number set
        </li>
        <li>
          <i class="fa fa-map-marker" aria-hidden="true"></i>
          <span>
          Location:
          <a href="http://maps.google.com/maps?q={{regionalContact.granteeAddress}}" target="_blank">{{regionalContact.granteeAddress}}</a>
          </span>
        </li>
      </ul>
      <ul class="list-unstyled" *ngIf="regionalContact == null">
        <li>
          No Regional Contact set
        </li>
      </ul>
    </div>
  </div>
</div>
</div>
