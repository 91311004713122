<loading #loadingSpinner></loading>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
    <acl-modal #confirmModal [headerText]="'Confirm Submission'" [id]="'submissionConfirmModal'" [buttons]="modalButtons">
        You have just completed the last of the required explanations of variances for NSIP. If you chose to continue, your explanations of variances will be submitted to ACL for review.    
        <br>
        <br>
        Once submitted, you will no longer be able to update the explanations of variances unless the complete submission is Pulled back for corrections. You can do so from the Overview page until ACL begins their review.
        <br>
        <br>
        If you are not ready to submit the explanations of variances, you can cancel and return to the Overview page.
      </acl-modal>
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9" *ngIf="currentFfynsipSection?.isRequired">
        <h2>Explanations of Variances for NSIP<span class="print-only line">&nbsp;for&nbsp;{{_claimService.currentOrg}}</span></h2>
        <span class="block print-only">
            <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
            <span class="sr-only">Variance threshold exceeded</span>
            = Variance threshold exceeded
        </span>
        <div class="row mb-1">
            <div class="col-md-12">
              <p class="pull-left pt-1">Enter explanations for each noted variance below. Once complete, mark each explanation as complete.</p>
              <button class="btn btn-default pull-right no-print" (click)='print()'>Print</button>
            </div>
        </div>
        <span [innerHTML]="_commonService.accordionStatus(currentFfynsipSection?.varianceExplanation)"></span>
        <br />
        <br />
        <div class="table-responsive">
          <table class="table  td234-align-right">
            <thead>
              <tr class="background-gray-lighter">
                <th scope="col" width="25%">Data Element</th>
                <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                <th scope="col" width="15%" class="text-right">% Variance</th>
              </tr>
            </thead>
            <tbody>
            <tr>
              <th scope="row" class="normal">NSIP qualified Home delivered meals served under Title III-C</th>
              <td>{{formatNumber(previousFfynsipSection?.hdmC?.elementValue)}}</td>
              <td>{{formatNumber(currentFfynsipSection?.hdmC?.elementValue)}}</td>
              <td [ngClass]="(!currentFfynsipSection?.hdmC?.isNotSigificantVariance)?'background-highlighted':''">
                <span *ngIf="!currentFfynsipSection?.hdmC?.isNotSigificantVariance">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                  <span class="sr-only">Variance threshold exceeded</span>
                </span>
                {{formatVariance(currentFfynsipSection?.hdmC?.variance)}}
              </td>
            </tr>
            <tr>
              <th scope="row" class="normal">NSIP qualified Home delivered meals served under Title III-E</th>
              <td>{{formatNumber(previousFfynsipSection?.hdmE?.elementValue)}}</td>
              <td>{{formatNumber(currentFfynsipSection?.hdmE?.elementValue)}}</td>
              <td [ngClass]="(!currentFfynsipSection?.hdmE?.isNotSigificantVariance)?'background-highlighted':''">
                <span *ngIf="!currentFfynsipSection?.hdmE?.isNotSigificantVariance">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                  <span class="sr-only">Variance threshold exceeded</span>
                </span>
                {{formatVariance(currentFfynsipSection?.hdmE?.variance)}}
              </td>
            </tr>
            <tr>
              <th scope="row" class="normal">
                <b>Total NSIP qualified Home delivered meals</b>
              </th>
              <td>{{formatNumber(previousFfynsipSection?.hdmTotal?.elementValue)}}</td>
              <td>{{formatNumber(currentFfynsipSection?.hdmTotal?.elementValue)}}</td>
              <td [ngClass]="(!currentFfynsipSection?.hdmTotal?.isNotSigificantVariance)?'background-highlighted':''">
                <span *ngIf="!currentFfynsipSection?.hdmTotal?.isNotSigificantVariance">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                  <span class="sr-only">Variance threshold exceeded</span>
                </span>
                {{formatVariance(currentFfynsipSection?.hdmTotal?.variance)}}
              </td>
            </tr>
            <tr>
              <th scope="row" class="normal">NSIP qualified Congregate meals served under Title III-C</th>
              <td>{{formatNumber(previousFfynsipSection?.congregateC?.elementValue)}}</td>
              <td>{{formatNumber(currentFfynsipSection?.congregateC?.elementValue)}}</td>
              <td [ngClass]="(!currentFfynsipSection?.congregateC?.isNotSigificantVariance)?'background-highlighted':''">
                <span *ngIf="!currentFfynsipSection?.congregateC?.isNotSigificantVariance">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                  <span class="sr-only">Variance threshold exceeded</span>
                </span>
                {{formatVariance(currentFfynsipSection?.congregateC?.variance)}}
              </td>
            </tr>
            <tr>
              <th scope="row" class="normal">NSIP qualified Congregate meals served under Title III-E</th>
              <td>{{formatNumber(previousFfynsipSection?.congregateE?.elementValue)}}</td>
              <td>{{formatNumber(currentFfynsipSection?.congregateE?.elementValue)}}</td>
              <td [ngClass]="(!currentFfynsipSection?.congregateE?.isNotSigificantVariance)?'background-highlighted':''">
                <span *ngIf="!currentFfynsipSection?.congregateE?.isNotSigificantVariance">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                  <span class="sr-only">Variance threshold exceeded</span>
                </span>
                {{formatVariance(currentFfynsipSection?.congregateE?.variance)}}
              </td>
            </tr>
            <tr>
              <th scope="row" class="normal">
                <b>Total NSIP qualified Congregate meals</b>
              </th>
              <td>{{formatNumber(previousFfynsipSection?.congregateTotal?.elementValue)}}</td>
              <td>{{formatNumber(currentFfynsipSection?.congregateTotal?.elementValue)}}</td>
              <td [ngClass]="(!currentFfynsipSection?.congregateTotal?.isNotSigificantVariance)?'background-highlighted':''">
                <span *ngIf="!currentFfynsipSection?.congregateTotal?.isNotSigificantVariance">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                  <span class="sr-only">Variance threshold exceeded</span>
                </span>
                {{formatVariance(currentFfynsipSection?.congregateTotal?.variance)}}
              </td>
            </tr>
          </tbody>
          </table>
        </div>
        <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfynsipSection.aaaVarianceExplanations"></acl-rollup-explanation>
        <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfynsipSection?.varianceExplanation?.explanation}}</p>
        <span class="clearfix no-print" *ngIf="currentFfynsipSection?.varianceExplanation">
          <br />
          <br />
          <label [for]="nsipTextbox1">Please provide an explanation for each difference highlighted</label>
          <div [ngClass]="calculateOutlineColor(lengthExceeded)">
            <textarea (change)="unsavedChanges = true; currentFfynsipSection.varianceExplanation.explanation = currentFfynsipSection.varianceExplanation.explanation.trim()" 
              [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfynsipSection.varianceExplanation.isUserFinished || !currentFfynsipSection.varianceExplanation.isRequired"
              class="verticalResizeOnly form-control" [(ngModel)]="currentFfynsipSection.varianceExplanation.explanation"
              rows="4" id="nsipTextbox1" (blur)="checkLength()"></textarea>
          </div>
          <label class="pull-left" for="nsipUserFinishedCheckbox">
            <input (change)="unsavedChanges = true;" id="nsipUserFinishedCheckbox" type="checkbox" [disabled]="markAsCompleteCheckboxDisabled(currentFfynsipSection.varianceExplanation)"
              [(ngModel)]="currentFfynsipSection.varianceExplanation.isUserFinished"/> &nbsp;Mark as complete
          </label>
          <small class="form-text help-block pull-right">{{currentFfynsipSection.varianceExplanation.explanation?.length
            || 0}} of 3400 characters</small>
          <div *ngIf="lengthExceeded" class="alert alert-danger" role="alert">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
          </div>
        </span>
        <div class="row col-md-12 no-print">
          <button [disabled]="!isValid" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and
            next</button>
          <button [disabled]="!isValid" class="btn btn-default" (click)="save()">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to
            Overview</a>
        </div>
      </div>
      <!-- .end col-md-9 -->

      <div class="col-md-9" *ngIf="!currentFfynsipSection?.isRequired">
        <h2>Explanations of Variances for NSIP</h2>
        <span>NSIP Explanations are not required</span>
      </div>
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->