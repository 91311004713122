
<div *ngIf="model.shouldRender" class="background-gray-lighter pt-1 pb-1 row">
    <div class="col-md-6" id="orgName">
        <strong>{{model.orgLabel}}: </strong>{{model.orgName}}
    </div>
    <div class="col-md-3" id="reviewFFY">
        <strong>{{ model.title === 'VI' ? 'Reporting year' : 'FFY'}}: </strong>{{model.fiscalYear}}
    </div>
    <div class="col-md-3">
        <a id="linkAclHeaderReturn" [routerLink]="[model.returnLink]" [queryParams]="model.returnParams"
            class='btn-sm btn-primary pull-right'>
            {{model.returnText}}
        </a>
    </div>
</div>