import { Subscription } from 'rxjs';

/**
 * This is the base class for all route-able components
 */
export class DestructibleComponent {

    protected subscriptions: Subscription[] = [];

    protected registerSubscription(subscription: Subscription): Subscription {
        if (subscription) {
            this.subscriptions.push(subscription);
        }
        return subscription;
    }

    protected destroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    constructor() { }
}
