import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, finalize, first, switchMap, tap } from 'rxjs/operators';

import { ClaimService } from '../../shared/claim.service';
import { LookupService } from '../../lookup.service';
import { AclTitleEnum } from '../../app.constants';
import { OktaService } from '../../auth/service/okta.service';
import { CacheService } from '../cache-service';
import { DestructibleComponent } from '../../destructible.component';
import { TitleIIIState } from '../../DataSubmissions/titleIIIState';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'horizontal-nav',
  templateUrl: 'horizontalNav.component.html'
})

export class HorizontalNav extends DestructibleComponent implements OnInit {

  public titleEnum = AclTitleEnum;
  // TODO not sure this is still needed, never re-assigned
  public isDeactivated = false;

  private componentInitialized = new ReplaySubject<boolean>(1);
  // Title III specific
  private enabledTitleIIIStates: Array<string> = [];

  constructor(
    public route: ActivatedRoute,
    public _router: Router,
    public _claimService: ClaimService,
    public _oktaService: OktaService,
    public _lookupService: LookupService,
    public _cacheService: CacheService,
  ) { super(); this.componentInitialized.next(false); }

    // Changed properties to getter methods to capture change detection in claim service.
    // To do: Might be a better way to handle this?
    public get isNavStateSubmission() : boolean {
      return this._claimService.isNavStateSubmission;
    }

    public get isNavReports() : boolean {
      return this._claimService.isNavReports;
    }

    public get isNavAdmin() : boolean {
      return this._claimService.isNavAdmin;
    }

    public get stateAbbr() : string {
      return this._claimService.currentOrg;
    }

    public get currentTitle() : string {
      return this._claimService._currentTitle;
    }

    public get isNavApprover() : boolean {
      return this._claimService.isNavApprover;
    }

    public get isNavLocker() : boolean {
      return this._claimService.isNavLocker;
    }

    public get isViewOnly() : boolean {
      return this._claimService.isViewOnly;
    }

    public get isAAAUser() : boolean {
      return this._claimService.isAAAUser;
    }

    public get isApprover() : boolean {
      return this._claimService.isApprover;
    }

    public get isDataEntry() : boolean {
      return this._claimService.isDataEntry;
    }

    public get currentOrgLevel() : any {
      return this._claimService.currentOrgLevel;
    }

    public get forgotPasswordUrl(): string {
      return `${environment.forgotPwdLink}/enduser/settings`;
    }


  ngOnInit(): void {
    this.fetchComponentData();
  }

//#region UI
  public getActiveClass(tabName: string) {
    switch (tabName) {
      case ('admin'):
        return this._router.url.includes('UserManagement') ? 'router-link-active' : '';
      case ('data-submissions'):
        return this._router.url.includes('data-submissions') ? 'router-link-active' : '';
      case ('ppr-submissions'):
        return this._router.url.includes('ppr-submissions') || this._router.url.includes('titleVIPPROverview') ||
          (this._router.url.includes('status-log') && (this.isNavApprover || this.isNavLocker)) ? 'router-link-active' : '';
      case ('dashboard'):
        return this._router.url.includes('dashboard') ||
          (this._router.url.includes('status-log') && this._claimService.isGrantee) ? 'router-link-active' : '';
      default:
        return '';
    }
  }

  public navTIIIDataSubmissions() {
    // if logged in, and not AAA user AND logged in user State is a AAA Enabled User State (app.html is controlling AAAuser)
    super.registerSubscription(this.componentInitialized
      .pipe(
        first(initialized => initialized),
        switchMap(() => this._oktaService.isAuthenticatedAsync())
      ).subscribe((authenticated) => {
        if (authenticated
          && this.isAAAStateUser()) {
          this._router.navigate(['/data-submissions/titleIIIDashboardAAAStates']);
        } else {
          this._claimService.isRollupOverview = false;
          this._router.navigate(['/data-submissions']);
        }
      }
      ));
  }

  public inProfileNav(): boolean {
    const url = this._router.url;
    if (url.includes('titleVIIUserNotification')
      || url.includes('titleIIIUserNotification')
      || url.includes('titleVIIUserDefaultStates')
      || url.includes('titleIIIUserDefaultStates')) {
      return true;
    } else {
      return false;
    }
  }

  public get isResourcesActive(): boolean {
    let isActive = false;
    if (this._router.url.indexOf('/Resources/') !== -1) {
      isActive = true;
    }
    return isActive;
  }
//#endregion

  private fetchComponentData() {
    super.registerSubscription(this._claimService
      .init$
      .pipe(
        first(init => init),
        switchMap(this.fetchAAAStatesIfNeeded.bind(this))
      )
      .subscribe((success) => {
        if (!success) {
          // TODO perhaps disable the horizontal nav in this condition
          console.log('Error occurred initializing component');
        }
        this.componentInitialized.next(true);
      }));
  }

  private fetchAAAStatesIfNeeded(): Observable<boolean> {
    if (this._claimService.title === AclTitleEnum.III) {
      let states = this._cacheService
        .getCacheItem(TitleIIIState.ENABLED_AAA_STATES_CACHE_KEY);
      if (!states) {
        return this._lookupService.getEnabledTitleIIIStates()
          .pipe(
            tap(data => states = data),
            switchMap(() => of(true)),
            catchError(() => of(false)),
            finalize(() => this.enabledTitleIIIStates = states || [])
          )
      }
    } else {
      return of(true);
    }
  }

  private isAAAStateUser(): boolean {
    return this._claimService.title === AclTitleEnum.III
      && !this._claimService.isAAAUser
      && this.enabledTitleIIIStates
      && this.enabledTitleIIIStates.includes(this._claimService.currentOrg);
  }

}
