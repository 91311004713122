import { Component, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClaimService } from '../claim.service';
import { QuestionStatusEnum } from '../dataElement';
import { CommonService } from '../common.service';
import { TitleVIDataEntryService } from '../../TitleVI/titleVIDataEntry.service';
import { ITVIOverviewDto, TVIOverview } from '../../TitleVI/model/Overview';
import { IOverviewState } from '../Overview/overviewState';

@Component({
  selector: 'acl-subnav-tvi-variance',
  templateUrl: 'tviVarianceSideNav.component.html'
})

export class TVIVarianceSideNavComponent {

  @Input() overviewState: IOverviewState;
  @Input() overviewDtos: ITVIOverviewDto;

  staffInfoABStatus: QuestionStatusEnum;
  nutritionServicesStatus: QuestionStatusEnum;
  accessServicesStatus: QuestionStatusEnum;
  inHomeServicesStatus: QuestionStatusEnum;
  otherServicesStatus: QuestionStatusEnum;
  financeABStatus: QuestionStatusEnum;
  storytellingStatus: QuestionStatusEnum;
  staffInfoCStatus: QuestionStatusEnum;
  caregiverCharsStatus: QuestionStatusEnum;
  caregiverServicesStatus: QuestionStatusEnum;
  suppServicesStatus: QuestionStatusEnum;
  caregiverSupportServicesOverview: QuestionStatusEnum;
  respiteStatus: QuestionStatusEnum;
  financeCStatus: QuestionStatusEnum;
  statusEnum = QuestionStatusEnum;

  constructor(public zone: NgZone,
    public _claimService: ClaimService,
    public route: ActivatedRoute,
    public router: Router,
    public _commonService: CommonService,
    public _titleVIservice: TitleVIDataEntryService,
  ) { }

  public titleViIsPartCRequired() {
    if (this.overviewDtos != null) {
      return this.overviewDtos.overview.children[1].isRequired;
    }
    return false;
  }

  private setTVIStatuses() {
    if (this.overviewState) {
      this.staffInfoABStatus = this.overviewState.findOverview('staffInfoAB').sectionStatus;
      this.nutritionServicesStatus = this.overviewState.findOverview('nutritionServices').sectionStatus;
      this.accessServicesStatus = this.overviewState.findOverview('accessServices').sectionStatus;
      this.inHomeServicesStatus = this.overviewState.findOverview('inHomeServices').sectionStatus;
      this.otherServicesStatus = this.overviewState.findOverview('otherServices').sectionStatus;
      this.financeABStatus = this.overviewState.findOverview('financeAB').sectionStatus;
      this.storytellingStatus = this.overviewState.findOverview('storytelling').sectionStatus;
      this.staffInfoCStatus = this.overviewState.findOverview('staffInfoC').sectionStatus;
      this.caregiverCharsStatus = this.overviewState.findOverview('caregiverChars').sectionStatus;
      this.caregiverServicesStatus = this.overviewState.findOverview('caregiverServices').sectionStatus;
      this.suppServicesStatus = this.overviewState.findOverview('suppServices').sectionStatus;
      this.respiteStatus = this.overviewState.findOverview('respite').sectionStatus;
      this.financeCStatus = this.overviewState.findOverview('financeC').sectionStatus;
      this.caregiverSupportServicesOverview = this.overviewState.findOverview('CaregiverSupportServicesOverview').sectionStatus;
    }
  }

  firstAvilableSection(): string {
    if (!this.partABVEDisabled()) {
      return 'tviVarianceExplanationsAB';
    } else if (!this.partCVEDisabled()) {
      return 'tviVarianceExplanationsC';
    } else {
      return 'titleVIPPROverview';
    }
  }

  public disableNavT6VE(): boolean {
    if (!this.overviewState) {
      // defaulting to disabled until overviewState is ready
      return true;
    }
    this.setTVIStatuses();
    if (this.partABVEDisabled() && this.partCVEDisabled()) {
      return true;
    } else {
      return false;
    }
  }

  public partABVEDisabled(): boolean {
    return this.overviewState.findOverview(
      TVIOverview.ABOverview).sectionStatus < QuestionStatusEnum.L3NoExplanation;
  }

  public partCVEDisabled(): boolean {
    return this.overviewState.findOverview(
      TVIOverview.COverview).sectionStatus < QuestionStatusEnum.L3NoExplanation;
  }

}
