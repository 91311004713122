<div class="col-md-12" style="font-size: 19px;">
  <div *ngIf="display"
    class="background-gray-lighter p-1">
    <span>
      <strong>AAA:</strong>&nbsp;{{aaaName}}
      &nbsp;&nbsp;&nbsp;&nbsp;
      <strong>FFY:</strong>&nbsp;{{fiscalYear}}
    </span>
    <a id="titleIIISSReviewLnk" [routerLink]="['/data-submissions/titleIIISSReview']" queryParamsHandling="preserve"
      class='btn-sm btn-primary pull-right'>
      Back to AAA submission review
    </a>
  </div>
</div>
