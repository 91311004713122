<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <h2>Older Adults - Home Delivered Nutrition - Consumer Characteristics</h2>
        <p class="info mt-1">All fields are required</p>
        <div class="col-md-9 mb-2 mt-2">
          <label for="totalPersonsServed">Total unduplicated persons served</label>
        </div>
        <div class="col-md-3 mb-2 mt-2">
          <div [ngClass]="calculateOutlineColor(_sectionModel.totalPersonsServed.questionStatus)">
            <input [(ngModel)]="_sectionModel.totalPersonsServed.elementValue" [disabled]="!_sectionModel.totalPersonsServed.isRequired"
              type="text" class="form-control" id="totalPersonsServed" (change)="unsavedChanges = true; _sectionModel.totalPersonsServed.elementValue = addComma(_sectionModel.totalPersonsServed.elementValue); _sectionModel.totalPersonsServed = validate(_sectionModel.totalPersonsServed); checkValidForm()">
          </div>
          <div *ngIf="!!_sectionModel.totalPersonsServed.dataEntryErrorMessage" class="alert alert-danger" role="alert">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.totalPersonsServed.dataEntryErrorMessage}}
          </div>
          <div *ngIf="!_sectionModel.totalPersonsServed.dataEntryErrorMessage && (_sectionModel.totalPersonsServed.questionStatus == statusEnum.L2InProgressError || _sectionModel.totalPersonsServed.questionStatus == statusEnum.L2ValidatedWarning)"
            [ngClass]="setErrorDisplayType(_sectionModel.totalPersonsServed.questionStatus)" role="alert">
            <i *ngIf="_sectionModel.totalPersonsServed.questionStatus == statusEnum.L2ValidatedWarning" class="fa fa-exclamation-triangle"
              aria-hidden="true"></i>
            <span class="sr-only">Warning:</span>
            <i *ngIf="_sectionModel.totalPersonsServed.questionStatus != statusEnum.L2ValidatedWarning" class="fa fa-times"
              aria-hidden="true"></i>
            <span class="sr-only">Error:</span>
            &nbsp;{{_sectionModel.totalPersonsServed.businessErrorMessage}}
          </div>
        </div>
        <div class="pull-left table-responsive">
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>Age Distribution</h2>
          </div>
          <table class="table table-no-border">

            <tbody>
              <tr *ngIf="ageWarning()">
                <td class="has-warning" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                  <p *ngIf="!_sectionModel.totalBelow60.dataEntryErrorMessage && _sectionModel.totalBelow60.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalBelow60Text}}: {{_sectionModel.totalBelow60.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpBelow60.dataEntryErrorMessage && _sectionModel.bpBelow60.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpBelow60Text}}: {{_sectionModel.bpBelow60.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlBelow60.dataEntryErrorMessage && _sectionModel.adlBelow60.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlBelow60Text}}: {{_sectionModel.adlBelow60.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlBelow60.dataEntryErrorMessage && _sectionModel.iadlBelow60.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlBelow60Text}}: {{_sectionModel.iadlBelow60.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalRange1.dataEntryErrorMessage && _sectionModel.totalRange1.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalRange1Text}}: {{_sectionModel.totalRange1.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRange1.dataEntryErrorMessage && _sectionModel.bpRange1.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpRange1Text}}: {{_sectionModel.bpRange1.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRange1.dataEntryErrorMessage && _sectionModel.adlRange1.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlRange1Text}}: {{_sectionModel.adlRange1.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlRange1.dataEntryErrorMessage && _sectionModel.iadlRange1.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlRange1Text}}: {{_sectionModel.iadlRange1.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalRange2.dataEntryErrorMessage && _sectionModel.totalRange2.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalRange2Text}}: {{_sectionModel.totalRange2.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRange2.dataEntryErrorMessage && _sectionModel.bpRange2.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpRange2Text}}: {{_sectionModel.bpRange2.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRange2.dataEntryErrorMessage && _sectionModel.adlRange2.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlRange2Text}}: {{_sectionModel.adlRange2.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlRange2.dataEntryErrorMessage && _sectionModel.iadlRange2.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlRange2Text}}: {{_sectionModel.iadlRange2.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalRange3.dataEntryErrorMessage && _sectionModel.totalRange3.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalRange3Text}}: {{_sectionModel.totalRange3.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRange3.dataEntryErrorMessage && _sectionModel.bpRange3.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpRange3Text}}: {{_sectionModel.bpRange3.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRange3.dataEntryErrorMessage && _sectionModel.adlRange3.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlRange3Text}}: {{_sectionModel.adlRange3.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlRange3.dataEntryErrorMessage && _sectionModel.iadlRange3.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlRange3Text}}: {{_sectionModel.iadlRange3.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalRange4.dataEntryErrorMessage && _sectionModel.totalRange4.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalRange4Text}}: {{_sectionModel.totalRange4.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRange4.dataEntryErrorMessage && _sectionModel.bpRange4.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpRange4Text}}: {{_sectionModel.bpRange4.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRange4.dataEntryErrorMessage && _sectionModel.adlRange4.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlRange4Text}}: {{_sectionModel.adlRange4.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlRange4.dataEntryErrorMessage && _sectionModel.iadlRange4.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlRange4Text}}: {{_sectionModel.iadlRange4.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalAgeMissing.dataEntryErrorMessage && _sectionModel.totalAgeMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalAgeMissingText}}: {{_sectionModel.totalAgeMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpAgeMissing.dataEntryErrorMessage && _sectionModel.bpAgeMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpAgeMissingText}}: {{_sectionModel.bpAgeMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAgeMissing.dataEntryErrorMessage && _sectionModel.adlAgeMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlAgeMissingText}}: {{_sectionModel.adlAgeMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlAgeMissing.dataEntryErrorMessage && _sectionModel.iadlAgeMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlAgeMissingText}}: {{_sectionModel.iadlAgeMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allAgeTotal.dataEntryErrorMessage && _sectionModel.allAgeTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{allAgeTotalText}}: {{_sectionModel.allAgeTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpAgeTotal.dataEntryErrorMessage && _sectionModel.bpAgeTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpAgeTotalText}}: {{_sectionModel.bpAgeTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAgeTotal.dataEntryErrorMessage && _sectionModel.adlAgeTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlAgeTotalText}}: {{_sectionModel.adlAgeTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlAgeTotal.dataEntryErrorMessage && _sectionModel.iadlAgeTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlAgeTotalText}}: {{_sectionModel.iadlAgeTotal.businessErrorMessage}} </p>
                </td>
              </tr>
              <tr *ngIf="ageError()">
                <td class="has-error" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                  <p *ngIf="_sectionModel.totalBelow60.dataEntryErrorMessage" class="help-block">{{totalBelow60Text}}:
                    {{_sectionModel.totalBelow60.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpBelow60.dataEntryErrorMessage" class="help-block">{{bpBelow60Text}}:
                    {{_sectionModel.bpBelow60.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlBelow60.dataEntryErrorMessage" class="help-block">{{adlBelow60Text}}:
                    {{_sectionModel.adlBelow60.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlBelow60.dataEntryErrorMessage" class="help-block">{{iadlBelow60Text}}:
                    {{_sectionModel.iadlBelow60.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalRange1.dataEntryErrorMessage" class="help-block">{{totalRange1Text}}:
                    {{_sectionModel.totalRange1.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpRange1.dataEntryErrorMessage" class="help-block">{{bpRange1Text}}:
                    {{_sectionModel.bpRange1.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlRange1.dataEntryErrorMessage" class="help-block">{{adlRange1Text}}:
                    {{_sectionModel.adlRange1.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlRange1.dataEntryErrorMessage" class="help-block">{{iadlRange1Text}}:
                    {{_sectionModel.iadlRange1.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalRange2.dataEntryErrorMessage" class="help-block">{{totalRange2Text}}:
                    {{_sectionModel.totalRange2.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpRange2.dataEntryErrorMessage" class="help-block">{{bpRange2Text}}:
                    {{_sectionModel.bpRange2.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlRange2.dataEntryErrorMessage" class="help-block">{{adlRange2Text}}:
                    {{_sectionModel.adlRange2.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlRange2.dataEntryErrorMessage" class="help-block">{{iadlRange2Text}}:
                    {{_sectionModel.iadlRange2.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalRange3.dataEntryErrorMessage" class="help-block">{{totalRange3Text}}:
                    {{_sectionModel.totalRange3.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpRange3.dataEntryErrorMessage" class="help-block">{{bpRange3Text}}:
                    {{_sectionModel.bpRange3.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlRange3.dataEntryErrorMessage" class="help-block">{{adlRange3Text}}:
                    {{_sectionModel.adlRange3.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlRange3.dataEntryErrorMessage" class="help-block">{{iadlRange3Text}}:
                    {{_sectionModel.iadlRange3.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalRange4.dataEntryErrorMessage" class="help-block">{{totalRange4Text}}:
                    {{_sectionModel.totalRange4.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpRange4.dataEntryErrorMessage" class="help-block">{{bpRange4Text}}:
                    {{_sectionModel.bpRange4.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlRange4.dataEntryErrorMessage" class="help-block">{{adlRange4Text}}:
                    {{_sectionModel.adlRange4.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlRange4.dataEntryErrorMessage" class="help-block">{{iadlRange4Text}}:
                    {{_sectionModel.iadlRange4.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalAgeMissing.dataEntryErrorMessage" class="help-block">{{totalAgeMissingText}}:
                    {{_sectionModel.totalAgeMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpAgeMissing.dataEntryErrorMessage" class="help-block">{{bpAgeMissingText}}:
                    {{_sectionModel.bpAgeMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlAgeMissing.dataEntryErrorMessage" class="help-block">{{adlAgeMissingText}}:
                    {{_sectionModel.adlAgeMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlAgeMissing.dataEntryErrorMessage" class="help-block">{{iadlAgeMissingText}}:
                    {{_sectionModel.iadlAgeMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalBelow60.dataEntryErrorMessage && _sectionModel.totalBelow60.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalBelow60Text}}: {{_sectionModel.totalBelow60.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpBelow60.dataEntryErrorMessage && _sectionModel.bpBelow60.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpBelow60Text}}: {{_sectionModel.bpBelow60.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlBelow60.dataEntryErrorMessage && _sectionModel.adlBelow60.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlBelow60Text}}: {{_sectionModel.adlBelow60.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlBelow60.dataEntryErrorMessage && _sectionModel.iadlBelow60.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlBelow60Text}}: {{_sectionModel.iadlBelow60.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalRange1.dataEntryErrorMessage && _sectionModel.totalRange1.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalRange1Text}}: {{_sectionModel.totalRange1.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRange1.dataEntryErrorMessage && _sectionModel.bpRange1.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpRange1Text}}: {{_sectionModel.bpRange1.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRange1.dataEntryErrorMessage && _sectionModel.adlRange1.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlRange1Text}}: {{_sectionModel.adlRange1.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlRange1.dataEntryErrorMessage && _sectionModel.iadlRange1.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlRange1Text}}: {{_sectionModel.iadlRange1.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalRange2.dataEntryErrorMessage && _sectionModel.totalRange2.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalRange2Text}}: {{_sectionModel.totalRange2.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRange2.dataEntryErrorMessage && _sectionModel.bpRange2.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpRange2Text}}: {{_sectionModel.bpRange2.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRange2.dataEntryErrorMessage && _sectionModel.adlRange2.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlRange2Text}}: {{_sectionModel.adlRange2.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlRange2.dataEntryErrorMessage && _sectionModel.iadlRange2.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlRange2Text}}: {{_sectionModel.iadlRange2.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalRange3.dataEntryErrorMessage && _sectionModel.totalRange3.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalRange3Text}}: {{_sectionModel.totalRange3.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRange3.dataEntryErrorMessage && _sectionModel.bpRange3.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpRange3Text}}: {{_sectionModel.bpRange3.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRange3.dataEntryErrorMessage && _sectionModel.adlRange3.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlRange3Text}}: {{_sectionModel.adlRange3.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlRange3.dataEntryErrorMessage && _sectionModel.iadlRange3.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlRange3Text}}: {{_sectionModel.iadlRange3.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalRange4.dataEntryErrorMessage && _sectionModel.totalRange4.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalRange4Text}}: {{_sectionModel.totalRange4.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRange4.dataEntryErrorMessage && _sectionModel.bpRange4.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpRange4Text}}: {{_sectionModel.bpRange4.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRange4.dataEntryErrorMessage && _sectionModel.adlRange4.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlRange4Text}}: {{_sectionModel.adlRange4.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlRange4.dataEntryErrorMessage && _sectionModel.iadlRange4.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlRange4Text}}: {{_sectionModel.iadlRange4.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalAgeMissing.dataEntryErrorMessage && _sectionModel.totalAgeMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalAgeMissingText}}: {{_sectionModel.totalAgeMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpAgeMissing.dataEntryErrorMessage && _sectionModel.bpAgeMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpAgeMissingText}}: {{_sectionModel.bpAgeMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAgeMissing.dataEntryErrorMessage && _sectionModel.adlAgeMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlAgeMissingText}}: {{_sectionModel.adlAgeMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlAgeMissing.dataEntryErrorMessage && _sectionModel.iadlAgeMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlAgeMissingText}}: {{_sectionModel.iadlAgeMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allAgeTotal.dataEntryErrorMessage && _sectionModel.allAgeTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{allAgeTotalText}}: {{_sectionModel.allAgeTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpAgeTotal.dataEntryErrorMessage && _sectionModel.bpAgeTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpAgeTotalText}}: {{_sectionModel.bpAgeTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAgeTotal.dataEntryErrorMessage && _sectionModel.adlAgeTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlAgeTotalText}}: {{_sectionModel.adlAgeTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlAgeTotal.dataEntryErrorMessage && _sectionModel.iadlAgeTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlAgeTotalText}}: {{_sectionModel.iadlAgeTotal.businessErrorMessage}} </p>
                </td>
              </tr>
              <tr>
                <td width="20%"></td>
                <th width="20%" scope="col">Total</th>
                <th width="20%" scope="col">At or Below Poverty</th>
                <th width="20%" scope="col">ADL 3+</th>
                <th width="20%" scope="col">IADL 3+</th>
              </tr>
              <tr>
                <th class="normal" scope="row">Below 60</th>
                <td>
                  <label for="totalBelow60" class="sr-only">{{totalBelow60Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.totalBelow60.questionStatus)">
                    <input [(ngModel)]="_sectionModel.totalBelow60.elementValue" [disabled]="!_sectionModel.totalBelow60.isRequired"
                      type="text" class="form-control" id="totalBelow60" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalBelow60 = validate(_sectionModel.totalBelow60); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpBelow60" class="sr-only">{{bpBelow60Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.bpBelow60.questionStatus)">
                    <input [(ngModel)]="_sectionModel.bpBelow60.elementValue" [disabled]="!_sectionModel.bpBelow60.isRequired"
                      type="text" class="form-control" id="bpBelow60" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpBelow60 = validate(_sectionModel.bpBelow60); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlBelow60" class="sr-only">{{adlBelow60Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.adlBelow60.questionStatus)">
                    <input [(ngModel)]="_sectionModel.adlBelow60.elementValue" [disabled]="!_sectionModel.adlBelow60.isRequired"
                      type="text" class="form-control" id="adlBelow60" (change)="unsavedChanges = true; _sectionModel.adlAgeTotal.elementValue = updateTotal(adlAgeTotalArr); _sectionModel.adlBelow60 = validate(_sectionModel.adlBelow60); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlBelow60" class="sr-only">{{iadlBelow60Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.iadlBelow60.questionStatus)">
                    <input [(ngModel)]="_sectionModel.iadlBelow60.elementValue" [disabled]="!_sectionModel.iadlBelow60.isRequired"
                      type="text" class="form-control" id="iadlBelow60" (change)="unsavedChanges = true; _sectionModel.iadlAgeTotal.elementValue = updateTotal(iadlAgeTotalArr); _sectionModel.iadlBelow60 = validate(_sectionModel.iadlBelow60); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">60-64</th>
                <td>
                  <label for="totalRange1" class="sr-only">{{totalRange1Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.totalRange1.questionStatus)">
                    <input [(ngModel)]="_sectionModel.totalRange1.elementValue" [disabled]="!_sectionModel.totalRange1.isRequired"
                      type="text" class="form-control" id="totalRange1" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalRange1 = validate(_sectionModel.totalRange1); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpRange1" class="sr-only">{{bpRange1Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.bpRange1.questionStatus)">
                    <input [(ngModel)]="_sectionModel.bpRange1.elementValue" [disabled]="!_sectionModel.bpRange1.isRequired"
                      type="text" class="form-control" id="bpRange1" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpRange1 = validate(_sectionModel.bpRange1); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlRange1" class="sr-only">{{adlRange1Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.adlRange1.questionStatus)">
                    <input [(ngModel)]="_sectionModel.adlRange1.elementValue" [disabled]="!_sectionModel.adlRange1.isRequired"
                      type="text" class="form-control" id="adlRange1" (change)="unsavedChanges = true; _sectionModel.adlAgeTotal.elementValue = updateTotal(adlAgeTotalArr); _sectionModel.adlRange1 = validate(_sectionModel.adlRange1); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlRange1" class="sr-only">{{iadlRange1Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.iadlRange1.questionStatus)">
                    <input [(ngModel)]="_sectionModel.iadlRange1.elementValue" [disabled]="!_sectionModel.iadlRange1.isRequired"
                      type="text" class="form-control" id="iadlRange1" (change)="unsavedChanges = true; _sectionModel.iadlAgeTotal.elementValue = updateTotal(iadlAgeTotalArr); _sectionModel.iadlRange1 = validate(_sectionModel.iadlRange1); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">65-74</th>
                <td>
                  <label for="totalRange2" class="sr-only">{{totalRange2Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.totalRange2.questionStatus)">
                    <input [(ngModel)]="_sectionModel.totalRange2.elementValue" [disabled]="!_sectionModel.totalRange2.isRequired"
                      type="text" class="form-control" id="totalRange2" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalRange2 = validate(_sectionModel.totalRange2); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpRange2" class="sr-only">{{bpRange2Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.bpRange2.questionStatus)">
                    <input [(ngModel)]="_sectionModel.bpRange2.elementValue" [disabled]="!_sectionModel.bpRange2.isRequired"
                      type="text" class="form-control" id="bpRange2" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpRange2 = validate(_sectionModel.bpRange2); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlRange2" class="sr-only">{{adlRange2Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.adlRange2.questionStatus)">
                    <input [(ngModel)]="_sectionModel.adlRange2.elementValue" [disabled]="!_sectionModel.adlRange2.isRequired"
                      type="text" class="form-control" id="adlRange2" (change)="unsavedChanges = true; _sectionModel.adlAgeTotal.elementValue = updateTotal(adlAgeTotalArr); _sectionModel.adlRange2 = validate(_sectionModel.adlRange2); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlRange2" class="sr-only">{{iadlRange2Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.iadlRange2.questionStatus)">
                    <input [(ngModel)]="_sectionModel.iadlRange2.elementValue" [disabled]="!_sectionModel.iadlRange2.isRequired"
                      type="text" class="form-control" id="iadlRange2" (change)="unsavedChanges = true; _sectionModel.iadlAgeTotal.elementValue = updateTotal(iadlAgeTotalArr); _sectionModel.iadlRange2 = validate(_sectionModel.iadlRange2); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">75-84</th>
                <td>
                  <label for="totalRange3" class="sr-only">{{totalRange3Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.totalRange3.questionStatus)">
                    <input [(ngModel)]="_sectionModel.totalRange3.elementValue" [disabled]="!_sectionModel.totalRange3.isRequired"
                      type="text" class="form-control" id="totalRange3" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalRange3 = validate(_sectionModel.totalRange3); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpRange3" class="sr-only">{{bpRange3Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.bpRange3.questionStatus)">
                    <input [(ngModel)]="_sectionModel.bpRange3.elementValue" [disabled]="!_sectionModel.bpRange3.isRequired"
                      type="text" class="form-control" id="bpRange3" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpRange3 = validate(_sectionModel.bpRange3); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlRange3" class="sr-only">{{adlRange3Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.adlRange3.questionStatus)">
                    <input [(ngModel)]="_sectionModel.adlRange3.elementValue" [disabled]="!_sectionModel.adlRange3.isRequired"
                      type="text" class="form-control" id="adlRange3" (change)="unsavedChanges = true; _sectionModel.adlAgeTotal.elementValue = updateTotal(adlAgeTotalArr); _sectionModel.adlRange3 = validate(_sectionModel.adlRange3); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlRange3" class="sr-only">{{iadlRange3Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.iadlRange3.questionStatus)">
                    <input [(ngModel)]="_sectionModel.iadlRange3.elementValue" [disabled]="!_sectionModel.iadlRange3.isRequired"
                      type="text" class="form-control" id="iadlRange3" (change)="unsavedChanges = true; _sectionModel.iadlAgeTotal.elementValue = updateTotal(iadlAgeTotalArr); _sectionModel.iadlRange3 = validate(_sectionModel.iadlRange3); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">85 and above</th>
                <td>
                  <label for="totalRange4" class="sr-only">{{totalRange4Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.totalRange4.questionStatus)">
                    <input [(ngModel)]="_sectionModel.totalRange4.elementValue" [disabled]="!_sectionModel.totalRange4.isRequired"
                      type="text" class="form-control" id="totalRange4" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalRange4 = validate(_sectionModel.totalRange4); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpRange4" class="sr-only">{{bpRange4Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.bpRange4.questionStatus)">
                    <input [(ngModel)]="_sectionModel.bpRange4.elementValue" [disabled]="!_sectionModel.bpRange4.isRequired"
                      type="text" class="form-control" id="bpRange4" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpRange4 = validate(_sectionModel.bpRange4); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlRange4" class="sr-only">{{adlRange4Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.adlRange4.questionStatus)">
                    <input [(ngModel)]="_sectionModel.adlRange4.elementValue" [disabled]="!_sectionModel.adlRange4.isRequired"
                      type="text" class="form-control" id="adlRange4" (change)="unsavedChanges = true; _sectionModel.adlAgeTotal.elementValue = updateTotal(adlAgeTotalArr); _sectionModel.adlRange4 = validate(_sectionModel.adlRange4); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlRange4" class="sr-only">{{iadlRange4Text}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.iadlRange4.questionStatus)">
                    <input [(ngModel)]="_sectionModel.iadlRange4.elementValue" [disabled]="!_sectionModel.iadlRange4.isRequired"
                      type="text" class="form-control" id="iadlRange4" (change)="unsavedChanges = true; _sectionModel.iadlAgeTotal.elementValue = updateTotal(iadlAgeTotalArr); _sectionModel.iadlRange4 = validate(_sectionModel.iadlRange4); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Age missing</th>
                <td>
                  <label for="totalAgeMissing" class="sr-only">{{totalAgeMissingText}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.totalAgeMissing.questionStatus)">
                    <input [(ngModel)]="_sectionModel.totalAgeMissing.elementValue" [disabled]="!_sectionModel.totalAgeMissing.isRequired"
                      type="text" class="form-control" id="totalAgeMissing" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalAgeMissing = validate(_sectionModel.totalAgeMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpAgeMissing" class="sr-only">{{bpAgeMissingText}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.bpAgeMissing.questionStatus)">
                    <input [(ngModel)]="_sectionModel.bpAgeMissing.elementValue" [disabled]="!_sectionModel.bpAgeMissing.isRequired"
                      type="text" class="form-control" id="bpAgeMissing" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpAgeMissing = validate(_sectionModel.bpAgeMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlAgeMissing" class="sr-only">{{adlAgeMissingText}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.adlAgeMissing.questionStatus)">
                    <input [(ngModel)]="_sectionModel.adlAgeMissing.elementValue" [disabled]="!_sectionModel.adlAgeMissing.isRequired"
                      type="text" class="form-control" id="adlAgeMissing" (change)="unsavedChanges = true; _sectionModel.adlAgeTotal.elementValue = updateTotal(adlAgeTotalArr); _sectionModel.adlAgeMissing = validate(_sectionModel.adlAgeMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlAgeMissing" class="sr-only">{{iadlAgeMissingText}}</label>
                  <div [ngClass]="calculateOutlineColor(_sectionModel.iadlAgeMissing.questionStatus)">
                    <input [(ngModel)]="_sectionModel.iadlAgeMissing.elementValue" [disabled]="!_sectionModel.iadlAgeMissing.isRequired"
                      type="text" class="form-control" id="iadlAgeMissing" (change)="unsavedChanges = true; _sectionModel.iadlAgeTotal.elementValue = updateTotal(iadlAgeTotalArr); _sectionModel.iadlAgeMissing = validate(_sectionModel.iadlAgeMissing); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">Total Persons Served</th>
                <td>
                  <label for="allAgeTotal" class="sr-only">{{allAgeTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.allAgeTotal.elementValue" [disabled]="!_sectionModel.allAgeTotal.isRequired"
                    type="text" class="form-control" id="allAgeTotal" readonly>
                </td>
                <td>
                  <label for="bpAgeTotal" class="sr-only">{{bpAgeTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.bpAgeTotal.elementValue" [disabled]="!_sectionModel.bpAgeTotal.isRequired"
                    type="text" class="form-control" id="bpAgeTotal" readonly>
                </td>
                <td>
                  <label for="adlAgeTotal" class="sr-only">{{adlAgeTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.adlAgeTotal.elementValue" [disabled]="!_sectionModel.adlAgeTotal.isRequired"
                    type="text" class="form-control" id="adlAgeTotal" readonly>
                </td>
                <td>
                  <label for="iadlAgeTotal" class="sr-only">{{iadlAgeTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.iadlAgeTotal.elementValue" [disabled]="!_sectionModel.iadlAgeTotal.isRequired"
                    type="text" class="form-control" id="iadlAgeTotal" readonly>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- end table-responsive -->
        <div class="pull-left table-responsive">
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>Gender Distribution</h2>
          </div>
          <table class="table table-no-border">
            <tbody>
              <tr *ngIf="genderWarning()">
                <td class="has-warning" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                  <p *ngIf="!_sectionModel.totalFemale.dataEntryErrorMessage && _sectionModel.totalFemale.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalFemaleText}}: {{_sectionModel.totalFemale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpFemale.dataEntryErrorMessage && _sectionModel.bpFemale.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpFemaleText}}: {{_sectionModel.bpFemale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlFemale.dataEntryErrorMessage && _sectionModel.adlFemale.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlFemaleText}}: {{_sectionModel.adlFemale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlFemale.dataEntryErrorMessage && _sectionModel.iadlFemale.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlFemaleText}}: {{_sectionModel.iadlFemale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalMale.dataEntryErrorMessage && _sectionModel.totalMale.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalMaleText}}: {{_sectionModel.totalMale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpMale.dataEntryErrorMessage && _sectionModel.bpMale.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpMaleText}}: {{_sectionModel.bpMale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlMale.dataEntryErrorMessage && _sectionModel.adlMale.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlMaleText}}: {{_sectionModel.adlMale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlMale.dataEntryErrorMessage && _sectionModel.iadlMale.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlMaleText}}: {{_sectionModel.iadlMale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalOther.dataEntryErrorMessage && _sectionModel.totalOther.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalOtherText}}: {{_sectionModel.totalOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpOther.dataEntryErrorMessage && _sectionModel.bpOther.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpOtherText}}: {{_sectionModel.bpOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlOther.dataEntryErrorMessage && _sectionModel.adlOther.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlOtherText}}: {{_sectionModel.adlOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlOther.dataEntryErrorMessage && _sectionModel.iadlOther.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlOtherText}}: {{_sectionModel.iadlOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalGenderMissing.dataEntryErrorMessage && _sectionModel.totalGenderMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalGenderMissingText}}:
                    {{_sectionModel.totalGenderMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpGenderMissing.dataEntryErrorMessage && _sectionModel.bpGenderMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpGenderMissingText}}: {{_sectionModel.bpGenderMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlGenderMissing.dataEntryErrorMessage && _sectionModel.adlGenderMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlGenderMissingText}}:
                    {{_sectionModel.adlGenderMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlGenderMissing.dataEntryErrorMessage && _sectionModel.iadlGenderMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlGenderMissingText}}:
                    {{_sectionModel.iadlGenderMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.allGenderTotal.dataEntryErrorMessage && _sectionModel.allGenderTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{allGenderTotalText}}: {{_sectionModel.allGenderTotal.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpGenderTotal.dataEntryErrorMessage && _sectionModel.bpGenderTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpGenderTotalText}}: {{_sectionModel.bpGenderTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlGenderTotal.dataEntryErrorMessage && _sectionModel.adlGenderTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlGenderTotalText}}: {{_sectionModel.adlGenderTotal.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlGenderTotal.dataEntryErrorMessage && _sectionModel.iadlGenderTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlGenderTotalText}}: {{_sectionModel.iadlGenderTotal.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr *ngIf="genderError()">
                <td class="has-error" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                  <p *ngIf="_sectionModel.totalFemale.dataEntryErrorMessage" class="help-block">{{totalFemaleText}}:
                    {{_sectionModel.totalFemale.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpFemale.dataEntryErrorMessage" class="help-block">{{bpFemaleText}}:
                    {{_sectionModel.bpFemale.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlFemale.dataEntryErrorMessage" class="help-block">{{adlFemaleText}}:
                    {{_sectionModel.adlFemale.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlFemale.dataEntryErrorMessage" class="help-block">{{iadlFemaleText}}:
                    {{_sectionModel.iadlFemale.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalMale.dataEntryErrorMessage" class="help-block">{{totalMaleText}}:
                    {{_sectionModel.totalMale.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpMale.dataEntryErrorMessage" class="help-block">{{bpMaleText}}:
                    {{_sectionModel.bpMale.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlMale.dataEntryErrorMessage" class="help-block">{{adlMaleText}}:
                    {{_sectionModel.adlMale.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlMale.dataEntryErrorMessage" class="help-block">{{iadlMaleText}}:
                    {{_sectionModel.iadlMale.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalOther.dataEntryErrorMessage" class="help-block">{{totalOtherText}}:
                    {{_sectionModel.totalOther.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpOther.dataEntryErrorMessage" class="help-block">{{bpOtherText}}:
                    {{_sectionModel.bpOther.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlOther.dataEntryErrorMessage" class="help-block">{{adlOtherText}}:
                    {{_sectionModel.adlOther.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlOther.dataEntryErrorMessage" class="help-block">{{iadlOtherText}}:
                    {{_sectionModel.iadlOther.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalGenderMissing.dataEntryErrorMessage" class="help-block">{{totalGenderMissingText}}:
                    {{_sectionModel.totalGenderMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpGenderMissing.dataEntryErrorMessage" class="help-block">{{bpGenderMissingText}}:
                    {{_sectionModel.bpGenderMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlGenderMissing.dataEntryErrorMessage" class="help-block">{{adlGenderMissingText}}:
                    {{_sectionModel.adlGenderMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlGenderMissing.dataEntryErrorMessage" class="help-block">{{iadlGenderMissingText}}:
                    {{_sectionModel.iadlGenderMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalFemale.dataEntryErrorMessage && _sectionModel.totalFemale.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalFemaleText}}: {{_sectionModel.totalFemale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpFemale.dataEntryErrorMessage && _sectionModel.bpFemale.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpFemaleText}}: {{_sectionModel.bpFemale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlFemale.dataEntryErrorMessage && _sectionModel.adlFemale.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlFemaleText}}: {{_sectionModel.adlFemale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlFemale.dataEntryErrorMessage && _sectionModel.iadlFemale.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlFemaleText}}: {{_sectionModel.iadlFemale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalMale.dataEntryErrorMessage && _sectionModel.totalMale.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalMaleText}}: {{_sectionModel.totalMale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpMale.dataEntryErrorMessage && _sectionModel.bpMale.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpMaleText}}: {{_sectionModel.bpMale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlMale.dataEntryErrorMessage && _sectionModel.adlMale.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlMaleText}}: {{_sectionModel.adlMale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlMale.dataEntryErrorMessage && _sectionModel.iadlMale.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlMaleText}}: {{_sectionModel.iadlMale.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalOther.dataEntryErrorMessage && _sectionModel.totalOther.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalOtherText}}: {{_sectionModel.totalOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpOther.dataEntryErrorMessage && _sectionModel.bpOther.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpOtherText}}: {{_sectionModel.bpOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlOther.dataEntryErrorMessage && _sectionModel.adlOther.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlOtherText}}: {{_sectionModel.adlOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlOther.dataEntryErrorMessage && _sectionModel.iadlOther.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlOtherText}}: {{_sectionModel.iadlOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalGenderMissing.dataEntryErrorMessage && _sectionModel.totalGenderMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalGenderMissingText}}:
                    {{_sectionModel.totalGenderMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpGenderMissing.dataEntryErrorMessage && _sectionModel.bpGenderMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpGenderMissingText}}: {{_sectionModel.bpGenderMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlGenderMissing.dataEntryErrorMessage && _sectionModel.adlGenderMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlGenderMissingText}}:
                    {{_sectionModel.adlGenderMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlGenderMissing.dataEntryErrorMessage && _sectionModel.iadlGenderMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlGenderMissingText}}:
                    {{_sectionModel.iadlGenderMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.allGenderTotal.dataEntryErrorMessage && _sectionModel.allGenderTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{allGenderTotalText}}: {{_sectionModel.allGenderTotal.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpGenderTotal.dataEntryErrorMessage && _sectionModel.bpGenderTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpGenderTotalText}}: {{_sectionModel.bpGenderTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlGenderTotal.dataEntryErrorMessage && _sectionModel.adlGenderTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlGenderTotalText}}: {{_sectionModel.adlGenderTotal.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlGenderTotal.dataEntryErrorMessage && _sectionModel.iadlGenderTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlGenderTotalText}}: {{_sectionModel.iadlGenderTotal.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr>
                <td width="20%"></td>
                <th width="20%" scope="col">Total</th>
                <th width="20%" scope="col">At or Below Poverty</th>
                <th width="20%" scope="col">ADL 3+</th>
                <th width="20%" scope="col">IADL 3+</th>
              </tr>
              <tr>
                <th class="normal" scope="row">Female</th>
                <td>
                  <label for="totalFemale" class="sr-only">{{totalFemaleText}}</label>
                  <div [ngClass]="(_sectionModel.totalFemale.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalFemale.elementValue" [disabled]="!_sectionModel.totalFemale.isRequired"
                      type="text" class="form-control" id="totalFemale" (change)="unsavedChanges = true; _sectionModel.allGenderTotal.elementValue = updateTotal(allGenderTotalArr); _sectionModel.totalFemale = validate(_sectionModel.totalFemale); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpFemale" class="sr-only">{{bpFemaleText}}</label>
                  <div [ngClass]="(_sectionModel.bpFemale.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpFemale.elementValue" [disabled]="!_sectionModel.bpFemale.isRequired"
                      type="text" class="form-control" id="bpFemale" (change)="unsavedChanges = true; _sectionModel.bpGenderTotal.elementValue = updateTotal(bpGenderTotalArr); _sectionModel.bpFemale = validate(_sectionModel.bpFemale); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlFemale" class="sr-only">{{adlFemaleText}}</label>
                  <div [ngClass]="(_sectionModel.adlFemale.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlFemale.elementValue" [disabled]="!_sectionModel.adlFemale.isRequired"
                      type="text" class="form-control" id="adlFemale" (change)="unsavedChanges = true; _sectionModel.adlGenderTotal.elementValue = updateTotal(adlGenderTotalArr); _sectionModel.adlFemale = validate(_sectionModel.adlFemale); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlFemale" class="sr-only">{{iadlFemaleText}}</label>
                  <div [ngClass]="(_sectionModel.iadlFemale.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlFemale.elementValue" [disabled]="!_sectionModel.iadlFemale.isRequired"
                      type="text" class="form-control" id="iadlFemale" (change)="unsavedChanges = true; _sectionModel.iadlGenderTotal.elementValue = updateTotal(iadlGenderTotalArr); _sectionModel.iadlFemale = validate(_sectionModel.iadlFemale); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Male</th>
                <td>
                  <label for="totalMale" class="sr-only">{{totalMaleText}}</label>
                  <div [ngClass]="(_sectionModel.totalMale.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalMale.elementValue" [disabled]="!_sectionModel.totalMale.isRequired"
                      type="text" class="form-control" id="totalMale" (change)="unsavedChanges = true; _sectionModel.allGenderTotal.elementValue = updateTotal(allGenderTotalArr); _sectionModel.totalMale = validate(_sectionModel.totalMale); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpMale" class="sr-only">{{bpMaleText}}</label>
                  <div [ngClass]="(_sectionModel.bpMale.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpMale.elementValue" [disabled]="!_sectionModel.bpMale.isRequired"
                      type="text" class="form-control" id="bpMale" (change)="unsavedChanges = true; _sectionModel.bpGenderTotal.elementValue = updateTotal(bpGenderTotalArr); _sectionModel.bpMale = validate(_sectionModel.bpMale); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlMale" class="sr-only">{{adlMaleText}}</label>
                  <div [ngClass]="(_sectionModel.adlMale.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlMale.elementValue" [disabled]="!_sectionModel.adlMale.isRequired"
                      type="text" class="form-control" id="adlMale" (change)="unsavedChanges = true; _sectionModel.adlGenderTotal.elementValue = updateTotal(adlGenderTotalArr); _sectionModel.adlMale = validate(_sectionModel.adlMale); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlMale" class="sr-only">{{iadlMaleText}}</label>
                  <div [ngClass]="(_sectionModel.iadlMale.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlMale.elementValue" [disabled]="!_sectionModel.iadlMale.isRequired"
                      type="text" class="form-control" id="iadlMale" (change)="unsavedChanges = true; _sectionModel.iadlGenderTotal.elementValue = updateTotal(iadlGenderTotalArr); _sectionModel.iadlMale = validate(_sectionModel.iadlMale); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Other</th>
                <td>
                  <label for="totalOther" class="sr-only">{{totalOtherText}}</label>
                  <div [ngClass]="(_sectionModel.totalOther.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalOther.elementValue" [disabled]="!_sectionModel.totalOther.isRequired"
                      type="text" class="form-control" id="totalOther" (change)="unsavedChanges = true; _sectionModel.allGenderTotal.elementValue = updateTotal(allGenderTotalArr); _sectionModel.totalOther = validate(_sectionModel.totalOther); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpOther" class="sr-only">{{bpOtherText}}</label>
                  <div [ngClass]="(_sectionModel.bpOther.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpOther.elementValue" [disabled]="!_sectionModel.bpOther.isRequired"
                      type="text" class="form-control" id="bpOther" (change)="unsavedChanges = true; _sectionModel.bpGenderTotal.elementValue = updateTotal(bpGenderTotalArr); _sectionModel.bpOther = validate(_sectionModel.bpOther); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlOther" class="sr-only">{{adlOtherText}}</label>
                  <div [ngClass]="(_sectionModel.adlOther.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlOther.elementValue" [disabled]="!_sectionModel.adlOther.isRequired"
                      type="text" class="form-control" id="adlOther" (change)="unsavedChanges = true; _sectionModel.adlGenderTotal.elementValue = updateTotal(adlGenderTotalArr); _sectionModel.adlOther = validate(_sectionModel.adlOther); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlOther" class="sr-only">{{iadlOtherText}}</label>
                  <div [ngClass]="(_sectionModel.iadlOther.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlOther.elementValue" [disabled]="!_sectionModel.iadlOther.isRequired"
                      type="text" class="form-control" id="iadlOther" (change)="unsavedChanges = true; _sectionModel.iadlGenderTotal.elementValue = updateTotal(iadlGenderTotalArr); _sectionModel.iadlOther = validate(_sectionModel.iadlOther); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Gender missing</th>
                <td>
                  <label for="totalGenderMissing" class="sr-only">{{totalGenderMissingText}}</label>
                  <div [ngClass]="(_sectionModel.totalGenderMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalGenderMissing.elementValue" [disabled]="!_sectionModel.totalGenderMissing.isRequired"
                      type="text" class="form-control" id="totalGenderMissing" (change)="unsavedChanges = true; _sectionModel.allGenderTotal.elementValue = updateTotal(allGenderTotalArr); _sectionModel.totalGenderMissing = validate(_sectionModel.totalGenderMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpGenderMissing" class="sr-only">{{bpGenderMissingText}}</label>
                  <div [ngClass]="(_sectionModel.bpGenderMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpGenderMissing.elementValue" [disabled]="!_sectionModel.bpGenderMissing.isRequired"
                      type="text" class="form-control" id="bpGenderMissing" (change)="unsavedChanges = true; _sectionModel.bpGenderTotal.elementValue = updateTotal(bpGenderTotalArr); _sectionModel.bpGenderMissing = validate(_sectionModel.bpGenderMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlGenderMissing" class="sr-only">{{adlGenderMissingText}}</label>
                  <div [ngClass]="(_sectionModel.adlGenderMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlGenderMissing.elementValue" [disabled]="!_sectionModel.adlGenderMissing.isRequired"
                      type="text" class="form-control" id="adlGenderMissing" (change)="unsavedChanges = true; _sectionModel.adlGenderTotal.elementValue = updateTotal(adlGenderTotalArr); _sectionModel.adlGenderMissing = validate(_sectionModel.adlGenderMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlGenderMissing" class="sr-only">{{iadlGenderMissingText}}</label>
                  <div [ngClass]="(_sectionModel.iadlGenderMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlGenderMissing.elementValue" [disabled]="!_sectionModel.iadlGenderMissing.isRequired"
                      type="text" class="form-control" id="iadlGenderMissing" (change)="unsavedChanges = true; _sectionModel.iadlGenderTotal.elementValue = updateTotal(iadlGenderTotalArr); _sectionModel.iadlGenderMissing = validate(_sectionModel.iadlGenderMissing); checkValidForm()">
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Total Persons Served</th>
                <td>
                  <label for="allGenderTotal" class="sr-only">{{allGenderTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.allGenderTotal.elementValue" [disabled]="!_sectionModel.allGenderTotal.isRequired"
                    type="text" class="form-control" id="allGenderTotal" readonly>
                </td>
                <td>
                  <label for="bpGenderTotal" class="sr-only">{{bpGenderTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.bpGenderTotal.elementValue" [disabled]="!_sectionModel.bpGenderTotal.isRequired"
                    type="text" class="form-control" id="bpGenderTotal" readonly>
                </td>
                <td>
                  <label for="adlGenderTotal" class="sr-only">{{adlGenderTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.adlGenderTotal.elementValue" [disabled]="!_sectionModel.adlGenderTotal.isRequired"
                    type="text" class="form-control" id="adlGenderTotal" readonly>
                </td>
                <td>
                  <label for="iadlGenderTotal" class="sr-only">{{iadlGenderTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.iadlGenderTotal.elementValue" [disabled]="!_sectionModel.iadlGenderTotal.isRequired"
                    type="text" class="form-control" id="iadlGenderTotal" readonly>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  end table responsive  -->
        <div class="pull-left table-responsive">
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>Geographic Distribution</h2>
          </div>
          <table class="table table-no-border">
            <tbody>
              <tr *ngIf="geoWarning()">
                <td class="has-warning" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                  <p *ngIf="!_sectionModel.totalRural.dataEntryErrorMessage && _sectionModel.totalRural.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalRuralText}}: {{_sectionModel.totalRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRural.dataEntryErrorMessage && _sectionModel.bpRural.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpRuralText}}: {{_sectionModel.bpRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRural.dataEntryErrorMessage && _sectionModel.adlRural.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlRuralText}}: {{_sectionModel.adlRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlRural.dataEntryErrorMessage && _sectionModel.iadlRural.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlRuralText}}: {{_sectionModel.iadlRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalNonRural.dataEntryErrorMessage && _sectionModel.totalNonRural.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalNonRuralText}}: {{_sectionModel.totalNonRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpNonRural.dataEntryErrorMessage && _sectionModel.bpNonRural.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpNonRuralText}}: {{_sectionModel.bpNonRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlNonRural.dataEntryErrorMessage && _sectionModel.adlNonRural.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlNonRuralText}}: {{_sectionModel.adlNonRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlNonRural.dataEntryErrorMessage && _sectionModel.iadlNonRural.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlNonRuralText}}: {{_sectionModel.iadlNonRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalGeoMissing.dataEntryErrorMessage && _sectionModel.totalGeoMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalGeoMissingText}}: {{_sectionModel.totalGeoMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpGeoMissing.dataEntryErrorMessage && _sectionModel.bpGeoMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpGeoMissingText}}: {{_sectionModel.bpGeoMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlGeoMissing.dataEntryErrorMessage && _sectionModel.adlGeoMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlGeoMissingText}}: {{_sectionModel.adlGeoMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlGeoMissing.dataEntryErrorMessage && _sectionModel.iadlGeoMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlGeoMissingText}}: {{_sectionModel.iadlGeoMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allGeoTotal.dataEntryErrorMessage && _sectionModel.allGeoTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{allGeoTotalText}}: {{_sectionModel.allGeoTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpGeoTotal.dataEntryErrorMessage && _sectionModel.bpGeoTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpGeoTotalText}}: {{_sectionModel.bpGeoTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlGeoTotal.dataEntryErrorMessage && _sectionModel.adlGeoTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlGeoTotalText}}: {{_sectionModel.adlGeoTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlGeoTotal.dataEntryErrorMessage && _sectionModel.iadlGeoTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlGeoTotalText}}: {{_sectionModel.iadlGeoTotal.businessErrorMessage}} </p>
                </td>
              </tr>
              <tr *ngIf="geoError()">
                <td class="has-error" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                  <p *ngIf="_sectionModel.totalRural.dataEntryErrorMessage" class="help-block">{{totalRuralText}}:
                    {{_sectionModel.totalRural.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpRural.dataEntryErrorMessage" class="help-block">{{bpRuralText}}:
                    {{_sectionModel.bpRural.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlRural.dataEntryErrorMessage" class="help-block">{{adlRuralText}}:
                    {{_sectionModel.adlRural.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlRural.dataEntryErrorMessage" class="help-block">{{iadlRuralText}}:
                    {{_sectionModel.iadlRural.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalNonRural.dataEntryErrorMessage" class="help-block">{{totalNonRuralText}}:
                    {{_sectionModel.totalNonRural.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpNonRural.dataEntryErrorMessage" class="help-block">{{bpNonRuralText}}:
                    {{_sectionModel.bpNonRural.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlNonRural.dataEntryErrorMessage" class="help-block">{{adlNonRuralText}}:
                    {{_sectionModel.adlNonRural.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlNonRural.dataEntryErrorMessage" class="help-block">{{iadlNonRuralText}}:
                    {{_sectionModel.iadlNonRural.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalGeoMissing.dataEntryErrorMessage" class="help-block">{{totalGeoMissingText}}:
                    {{_sectionModel.totalGeoMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpGeoMissing.dataEntryErrorMessage" class="help-block">{{bpGeoMissingText}}:
                    {{_sectionModel.bpGeoMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlGeoMissing.dataEntryErrorMessage" class="help-block">{{adlGeoMissingText}}:
                    {{_sectionModel.adlGeoMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlGeoMissing.dataEntryErrorMessage" class="help-block">{{iadlGeoMissingText}}:
                    {{_sectionModel.iadlGeoMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalRural.dataEntryErrorMessage && _sectionModel.totalRural.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalRuralText}}: {{_sectionModel.totalRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRural.dataEntryErrorMessage && _sectionModel.bpRural.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpRuralText}}: {{_sectionModel.bpRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRural.dataEntryErrorMessage && _sectionModel.adlRural.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlRuralText}}: {{_sectionModel.adlRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlRural.dataEntryErrorMessage && _sectionModel.iadlRural.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlRuralText}}: {{_sectionModel.iadlRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalNonRural.dataEntryErrorMessage && _sectionModel.totalNonRural.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalNonRuralText}}: {{_sectionModel.totalNonRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpNonRural.dataEntryErrorMessage && _sectionModel.bpNonRural.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpNonRuralText}}: {{_sectionModel.bpNonRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlNonRural.dataEntryErrorMessage && _sectionModel.adlNonRural.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlNonRuralText}}: {{_sectionModel.adlNonRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlNonRural.dataEntryErrorMessage && _sectionModel.iadlNonRural.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlNonRuralText}}: {{_sectionModel.iadlNonRural.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalGeoMissing.dataEntryErrorMessage && _sectionModel.totalGeoMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalGeoMissingText}}: {{_sectionModel.totalGeoMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpGeoMissing.dataEntryErrorMessage && _sectionModel.bpGeoMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpGeoMissingText}}: {{_sectionModel.bpGeoMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlGeoMissing.dataEntryErrorMessage && _sectionModel.adlGeoMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlGeoMissingText}}: {{_sectionModel.adlGeoMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlGeoMissing.dataEntryErrorMessage && _sectionModel.iadlGeoMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlGeoMissingText}}: {{_sectionModel.iadlGeoMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allGeoTotal.dataEntryErrorMessage && _sectionModel.allGeoTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{allGeoTotalText}}: {{_sectionModel.allGeoTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpGeoTotal.dataEntryErrorMessage && _sectionModel.bpGeoTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpGeoTotalText}}: {{_sectionModel.bpGeoTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlGeoTotal.dataEntryErrorMessage && _sectionModel.adlGeoTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlGeoTotalText}}: {{_sectionModel.adlGeoTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlGeoTotal.dataEntryErrorMessage && _sectionModel.iadlGeoTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlGeoTotalText}}: {{_sectionModel.iadlGeoTotal.businessErrorMessage}} </p>
                </td>
              </tr>
              <tr>
                <td width="20%"></td>
                <th width="20%" scope="col">Total</th>
                <th width="20%" scope="col">At or Below Poverty</th>
                <th width="20%" scope="col">ADL 3+</th>
                <th width="20%" scope="col">IADL 3+</th>
              </tr>
              <tr>
                <th class="normal" scope="row">Rural</th>
                <td>
                  <label for="totalRural" class="sr-only">{{totalRuralText}}</label>
                  <div [ngClass]="(_sectionModel.totalRural.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalRural.elementValue" [disabled]="!_sectionModel.totalRural.isRequired"
                      type="text" class="form-control" id="totalRural" (change)="unsavedChanges = true; _sectionModel.allGeoTotal.elementValue = updateTotal(allGeoTotalArr); _sectionModel.totalRural = validate(_sectionModel.totalRural); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpRural" class="sr-only">{{bpRuralText}}</label>
                  <div [ngClass]="(_sectionModel.bpRural.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpRural.elementValue" [disabled]="!_sectionModel.bpRural.isRequired"
                      type="text" class="form-control" id="bpRural" (change)="unsavedChanges = true; _sectionModel.bpGeoTotal.elementValue = updateTotal(bpGeoTotalArr); _sectionModel.bpRural = validate(_sectionModel.bpRural); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlRural" class="sr-only">{{adlRuralText}}</label>
                  <div [ngClass]="(_sectionModel.adlRural.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlRural.elementValue" [disabled]="!_sectionModel.adlRural.isRequired"
                      type="text" class="form-control" id="adlRural" (change)="unsavedChanges = true; _sectionModel.adlGeoTotal.elementValue = updateTotal(adlGeoTotalArr); _sectionModel.adlRural = validate(_sectionModel.adlRural); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlRural" class="sr-only">{{iadlRuralText}}</label>
                  <div [ngClass]="(_sectionModel.iadlRural.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlRural.elementValue" [disabled]="!_sectionModel.iadlRural.isRequired"
                      type="text" class="form-control" id="iadlRural" (change)="unsavedChanges = true; _sectionModel.iadlGeoTotal.elementValue = updateTotal(iadlGeoTotalArr); _sectionModel.iadlRural = validate(_sectionModel.iadlRural); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Non-rural</th>
                <td>
                  <label for="totalNonRural" class="sr-only">{{totalNonRuralText}}</label>
                  <div [ngClass]="(_sectionModel.totalNonRural.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalNonRural.elementValue" [disabled]="!_sectionModel.totalNonRural.isRequired"
                      type="text" class="form-control" id="totalNonRural" (change)="unsavedChanges = true; _sectionModel.allGeoTotal.elementValue = updateTotal(allGeoTotalArr); _sectionModel.totalNonRural = validate(_sectionModel.totalNonRural); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpNonRural" class="sr-only">{{bpNonRuralText}}</label>
                  <div [ngClass]="(_sectionModel.bpNonRural.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpNonRural.elementValue" [disabled]="!_sectionModel.bpNonRural.isRequired"
                      type="text" class="form-control" id="bpNonRural" (change)="unsavedChanges = true; _sectionModel.bpGeoTotal.elementValue = updateTotal(bpGeoTotalArr); _sectionModel.bpNonRural = validate(_sectionModel.bpNonRural); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlNonRural" class="sr-only">{{adlNonRuralText}}</label>
                  <div [ngClass]="(_sectionModel.adlNonRural.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlNonRural.elementValue" [disabled]="!_sectionModel.adlNonRural.isRequired"
                      type="text" class="form-control" id="adlNonRural" (change)="unsavedChanges = true; _sectionModel.adlGeoTotal.elementValue = updateTotal(adlGeoTotalArr); _sectionModel.adlNonRural = validate(_sectionModel.adlNonRural); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlNonRural" class="sr-only">{{iadlNonRuralText}}</label>
                  <div [ngClass]="(_sectionModel.iadlNonRural.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlNonRural.elementValue" [disabled]="!_sectionModel.iadlNonRural.isRequired"
                      type="text" class="form-control" id="iadlNonRural" (change)="unsavedChanges = true; _sectionModel.iadlGeoTotal.elementValue = updateTotal(iadlGeoTotalArr); _sectionModel.iadlNonRural = validate(_sectionModel.iadlNonRural); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Geographic distribution missing</th>
                <td>
                  <label for="totalGeoMissing" class="sr-only">{{totalGeoMissingText}}</label>
                  <div [ngClass]="(_sectionModel.totalGeoMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalGeoMissing.elementValue" [disabled]="!_sectionModel.totalGeoMissing.isRequired"
                      type="text" class="form-control" id="totalGeoMissing" (change)="unsavedChanges = true; _sectionModel.allGeoTotal.elementValue = updateTotal(allGeoTotalArr); _sectionModel.totalGeoMissing = validate(_sectionModel.totalGeoMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpGeoMissing" class="sr-only">{{bpGeoMissingText}}</label>
                  <div [ngClass]="(_sectionModel.bpGeoMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpGeoMissing.elementValue" [disabled]="!_sectionModel.bpGeoMissing.isRequired"
                      type="text" class="form-control" id="bpGeoMissing" (change)="unsavedChanges = true; _sectionModel.bpGeoTotal.elementValue = updateTotal(bpGeoTotalArr); _sectionModel.bpGeoMissing = validate(_sectionModel.bpGeoMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlGeoMissing" class="sr-only">{{adlGeoMissingText}}</label>
                  <div [ngClass]="(_sectionModel.adlGeoMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlGeoMissing.elementValue" [disabled]="!_sectionModel.adlGeoMissing.isRequired"
                      type="text" class="form-control" id="adlGeoMissing" (change)="unsavedChanges = true; _sectionModel.adlGeoTotal.elementValue = updateTotal(adlGeoTotalArr); _sectionModel.adlGeoMissing = validate(_sectionModel.adlGeoMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlGeoMissing" class="sr-only">{{iadlGeoMissingText}}</label>
                  <div [ngClass]="(_sectionModel.iadlGeoMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlGeoMissing.elementValue" [disabled]="!_sectionModel.iadlGeoMissing.isRequired"
                      type="text" class="form-control" id="iadlGeoMissing" (change)="unsavedChanges = true; _sectionModel.iadlGeoTotal.elementValue = updateTotal(iadlGeoTotalArr); _sectionModel.iadlGeoMissing = validate(_sectionModel.iadlGeoMissing); checkValidForm()">
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Total Persons Served</th>
                <td>
                  <label for="allGeoTotal" class="sr-only">{{allGeoTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.allGeoTotal.elementValue" [disabled]="!_sectionModel.allGeoTotal.isRequired"
                    type="text" class="form-control" id="allGeoTotal" readonly>
                </td>
                <td>
                  <label for="bpGeoTotal" class="sr-only">{{bpGeoTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.bpGeoTotal.elementValue" [disabled]="!_sectionModel.bpGeoTotal.isRequired"
                    type="text" class="form-control" id="bpGeoTotal" readonly>
                </td>
                <td>
                  <label for="adlGeoTotal" class="sr-only">{{adlGeoTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.adlGeoTotal.elementValue" [disabled]="!_sectionModel.adlGeoTotal.isRequired"
                    type="text" class="form-control" id="adlGeoTotal" readonly>
                </td>
                <td>
                  <label for="iadlGeoTotal" class="sr-only">{{iadlGeoTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.iadlGeoTotal.elementValue" [disabled]="!_sectionModel.iadlGeoTotal.isRequired"
                    type="text" class="form-control" id="iadlGeoTotal" readonly>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  end table responsive  -->

        <div class="pull-left table-responsive">
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>Poverty Status Distribution</h2>
          </div>
          <table class="table table-no-border">
            <tbody>
              <tr *ngIf="povertyWarning()">
                <td class="has-warning" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                  <p *ngIf="!_sectionModel.totalBelowPoverty.dataEntryErrorMessage && _sectionModel.totalBelowPoverty.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalBelowPovertyText}}:
                    {{_sectionModel.totalBelowPoverty.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlBelowPoverty.dataEntryErrorMessage && _sectionModel.adlBelowPoverty.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlBelowPovertyText}}: {{_sectionModel.adlBelowPoverty.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlBelowPoverty.dataEntryErrorMessage && _sectionModel.iadlBelowPoverty.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlBelowPovertyText}}:
                    {{_sectionModel.iadlBelowPoverty.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalAbovePoverty.dataEntryErrorMessage && _sectionModel.totalAbovePoverty.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalAbovePovertyText}}:
                    {{_sectionModel.totalAbovePoverty.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAbovePoverty.dataEntryErrorMessage && _sectionModel.adlAbovePoverty.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlAbovePovertyText}}: {{_sectionModel.adlAbovePoverty.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlAbovePoverty.dataEntryErrorMessage && _sectionModel.iadlAbovePoverty.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlAbovePovertyText}}:
                    {{_sectionModel.iadlAbovePoverty.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalMissingPoverty.dataEntryErrorMessage && _sectionModel.totalMissingPoverty.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalMissingPovertyText}}:
                    {{_sectionModel.totalMissingPoverty.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlMissingPoverty.dataEntryErrorMessage && _sectionModel.adlMissingPoverty.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlMissingPovertyText}}:
                    {{_sectionModel.adlMissingPoverty.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlMissingPoverty.dataEntryErrorMessage && _sectionModel.iadlMissingPoverty.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlMissingPovertyText}}:
                    {{_sectionModel.iadlMissingPoverty.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allPovertyTotal.dataEntryErrorMessage && _sectionModel.allPovertyTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{allPovertyTotalText}}: {{_sectionModel.allPovertyTotal.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlPovertyTotal.dataEntryErrorMessage && _sectionModel.adlPovertyTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlPovertyTotalText}}: {{_sectionModel.adlPovertyTotal.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlPovertyTotal.dataEntryErrorMessage && _sectionModel.iadlPovertyTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlPovertyTotalText}}:
                    {{_sectionModel.iadlPovertyTotal.businessErrorMessage}} </p>
                </td>
              </tr>
              <tr *ngIf="povertyError()">
                <td class="has-error" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                  <p *ngIf="_sectionModel.totalBelowPoverty.dataEntryErrorMessage" class="help-block">{{totalBelowPovertyText}}:
                    {{_sectionModel.totalBelowPoverty.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.adlBelowPoverty.dataEntryErrorMessage" class="help-block">{{adlBelowPovertyText}}:
                    {{_sectionModel.adlBelowPoverty.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlBelowPoverty.dataEntryErrorMessage" class="help-block">{{iadlBelowPovertyText}}:
                    {{_sectionModel.iadlBelowPoverty.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalAbovePoverty.dataEntryErrorMessage" class="help-block">{{totalAbovePovertyText}}:
                    {{_sectionModel.totalAbovePoverty.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.adlAbovePoverty.dataEntryErrorMessage" class="help-block">{{adlAbovePovertyText}}:
                    {{_sectionModel.adlAbovePoverty.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlAbovePoverty.dataEntryErrorMessage" class="help-block">{{iadlAbovePovertyText}}:
                    {{_sectionModel.iadlAbovePoverty.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalMissingPoverty.dataEntryErrorMessage" class="help-block">{{totalMissingPovertyText}}:
                    {{_sectionModel.totalMissingPoverty.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.adlMissingPoverty.dataEntryErrorMessage" class="help-block">{{adlMissingPovertyText}}:
                    {{_sectionModel.adlMissingPoverty.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.iadlMissingPoverty.dataEntryErrorMessage" class="help-block">{{iadlMissingPovertyText}}:
                    {{_sectionModel.iadlMissingPoverty.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalBelowPoverty.dataEntryErrorMessage && _sectionModel.totalBelowPoverty.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalBelowPovertyText}}:
                    {{_sectionModel.totalBelowPoverty.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlBelowPoverty.dataEntryErrorMessage && _sectionModel.adlBelowPoverty.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlBelowPovertyText}}: {{_sectionModel.adlBelowPoverty.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlBelowPoverty.dataEntryErrorMessage && _sectionModel.iadlBelowPoverty.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlBelowPovertyText}}:
                    {{_sectionModel.iadlBelowPoverty.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalAbovePoverty.dataEntryErrorMessage && _sectionModel.totalAbovePoverty.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalAbovePovertyText}}:
                    {{_sectionModel.totalAbovePoverty.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAbovePoverty.dataEntryErrorMessage && _sectionModel.adlAbovePoverty.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlAbovePovertyText}}: {{_sectionModel.adlAbovePoverty.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlAbovePoverty.dataEntryErrorMessage && _sectionModel.iadlAbovePoverty.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlAbovePovertyText}}:
                    {{_sectionModel.iadlAbovePoverty.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalMissingPoverty.dataEntryErrorMessage && _sectionModel.totalMissingPoverty.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalMissingPovertyText}}:
                    {{_sectionModel.totalMissingPoverty.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlMissingPoverty.dataEntryErrorMessage && _sectionModel.adlMissingPoverty.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlMissingPovertyText}}:
                    {{_sectionModel.adlMissingPoverty.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlMissingPoverty.dataEntryErrorMessage && _sectionModel.iadlMissingPoverty.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlMissingPovertyText}}:
                    {{_sectionModel.iadlMissingPoverty.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allPovertyTotal.dataEntryErrorMessage && _sectionModel.allPovertyTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{allPovertyTotalText}}: {{_sectionModel.allPovertyTotal.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlPovertyTotal.dataEntryErrorMessage && _sectionModel.adlPovertyTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlPovertyTotalText}}: {{_sectionModel.adlPovertyTotal.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlPovertyTotal.dataEntryErrorMessage && _sectionModel.iadlPovertyTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlPovertyTotalText}}:
                    {{_sectionModel.iadlPovertyTotal.businessErrorMessage}} </p>
                </td>
              </tr>
              <tr>
                <td width="20%"></td>
                <th width="20%" scope="col">Total</th>
                <td width="20%"></td>
                <th width="20%" scope="col">ADL 3+</th>
                <th width="20%" scope="col">IADL 3+</th>
              </tr>
              <tr>
                <th class="normal" scope="row">At or below poverty</th>
                <td>
                  <label for="totalBelowPoverty" class="sr-only">{{totalBelowPovertyText}}</label>
                  <div [ngClass]="(_sectionModel.totalBelowPoverty.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalBelowPoverty.elementValue" [disabled]="!_sectionModel.totalBelowPoverty.isRequired"
                      type="text" class="form-control" id="totalBelowPoverty" (change)="unsavedChanges = true; _sectionModel.allPovertyTotal.elementValue = updateTotal(allPovertyTotalArr); _sectionModel.totalBelowPoverty = validate(_sectionModel.totalBelowPoverty); checkValidForm()">
                  </div>
                </td>
                <td></td>
                <td>
                  <label for="adlBelowPoverty" class="sr-only">{{adlBelowPovertyText}}</label>
                  <div [ngClass]="(_sectionModel.adlBelowPoverty.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlBelowPoverty.elementValue" [disabled]="!_sectionModel.adlBelowPoverty.isRequired"
                      type="text" class="form-control" id="adlBelowPoverty" (change)="unsavedChanges = true; _sectionModel.adlPovertyTotal.elementValue = updateTotal(adlPovertyTotalArr); _sectionModel.adlBelowPoverty = validate(_sectionModel.adlBelowPoverty); checkValidForm(); _sectionModel.bpHigherLimitAdl = _sectionModel.adlBelowPoverty; _sectionModel.bpLimitTotalAdl.elementValue = updateTotal(bpLimitTotalAdlArr);">
                  </div>
                </td>
                <td>
                  <label for="iadlBelowPoverty" class="sr-only">{{iadlBelowPovertyText}}</label>
                  <div [ngClass]="(_sectionModel.iadlBelowPoverty.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlBelowPoverty.elementValue" [disabled]="!_sectionModel.iadlBelowPoverty.isRequired"
                      type="text" class="form-control" id="iadlBelowPoverty" (change)="unsavedChanges = true; _sectionModel.iadlPovertyTotal.elementValue = updateTotal(iadlPovertyTotalArr); _sectionModel.iadlBelowPoverty = validate(_sectionModel.iadlBelowPoverty); checkValidForm(); _sectionModel.bpHigherLimitIadl = _sectionModel.iadlBelowPoverty; _sectionModel.bpLimitTotalIadl.elementValue = updateTotal(bpLimitTotalIadlArr);">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Above poverty</th>
                <td>
                  <label for="totalAbovePoverty" class="sr-only">{{totalAbovePovertyText}}</label>
                  <div [ngClass]="(_sectionModel.totalAbovePoverty.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalAbovePoverty.elementValue" [disabled]="!_sectionModel.totalAbovePoverty.isRequired"
                      type="text" class="form-control" id="totalAbovePoverty" (change)="unsavedChanges = true; _sectionModel.allPovertyTotal.elementValue = updateTotal(allPovertyTotalArr); _sectionModel.totalAbovePoverty = validate(_sectionModel.totalAbovePoverty); checkValidForm()">
                  </div>
                </td>
                <td></td>
                <td>
                  <label for="adlAbovePoverty" class="sr-only">{{adlAbovePovertyText}}</label>
                  <div [ngClass]="(_sectionModel.adlAbovePoverty.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlAbovePoverty.elementValue" [disabled]="!_sectionModel.adlAbovePoverty.isRequired"
                      type="text" class="form-control" id="adlAbovePoverty" (change)="unsavedChanges = true; _sectionModel.adlPovertyTotal.elementValue = updateTotal(adlPovertyTotalArr); _sectionModel.adlAbovePoverty = validate(_sectionModel.adlAbovePoverty); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlAbovePoverty" class="sr-only">{{iadlAbovePovertyText}}</label>
                  <div [ngClass]="(_sectionModel.iadlAbovePoverty.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlAbovePoverty.elementValue" [disabled]="!_sectionModel.iadlAbovePoverty.isRequired"
                      type="text" class="form-control" id="iadlAbovePoverty" (change)="unsavedChanges = true; _sectionModel.iadlPovertyTotal.elementValue = updateTotal(iadlPovertyTotalArr); _sectionModel.iadlAbovePoverty = validate(_sectionModel.iadlAbovePoverty); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Poverty status missing</th>
                <td>
                  <label for="totalMissingPoverty" class="sr-only">{{totalMissingPovertyText}}</label>
                  <div [ngClass]="(_sectionModel.totalMissingPoverty.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalMissingPoverty.elementValue" [disabled]="!_sectionModel.totalMissingPoverty.isRequired"
                      type="text" class="form-control" id="totalMissingPoverty" (change)="unsavedChanges = true; _sectionModel.allPovertyTotal.elementValue = updateTotal(allPovertyTotalArr); _sectionModel.totalMissingPoverty = validate(_sectionModel.totalMissingPoverty); checkValidForm()">
                  </div>
                </td>
                <td></td>
                <td>
                  <label for="adlMissingPoverty" class="sr-only">{{adlMissingPovertyText}}</label>
                  <div [ngClass]="(_sectionModel.adlMissingPoverty.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlMissingPoverty.elementValue" [disabled]="!_sectionModel.adlMissingPoverty.isRequired"
                      type="text" class="form-control" id="adlMissingPoverty" (change)="unsavedChanges = true; _sectionModel.adlPovertyTotal.elementValue = updateTotal(adlPovertyTotalArr); _sectionModel.adlMissingPoverty = validate(_sectionModel.adlMissingPoverty); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlMissingPoverty" class="sr-only">{{iadlMissingPovertyText}}</label>
                  <div [ngClass]="(_sectionModel.iadlMissingPoverty.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlMissingPoverty.elementValue" [disabled]="!_sectionModel.iadlMissingPoverty.isRequired"
                      type="text" class="form-control" id="iadlMissingPoverty" (change)="unsavedChanges = true; _sectionModel.iadlPovertyTotal.elementValue = updateTotal(iadlPovertyTotalArr); _sectionModel.iadlMissingPoverty = validate(_sectionModel.iadlMissingPoverty); checkValidForm()">
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Total Persons Served</th>
                <td>
                  <label for="allPovertyTotal" class="sr-only">{{allPovertyTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.allPovertyTotal.elementValue" [disabled]="!_sectionModel.allPovertyTotal.isRequired"
                    type="text" class="form-control" id="allPovertyTotal" readonly>
                </td>
                <td></td>
                <td>
                  <label for="adlPovertyTotal" class="sr-only">{{adlPovertyTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.adlPovertyTotal.elementValue" [disabled]="!_sectionModel.adlPovertyTotal.isRequired"
                    type="text" class="form-control" id="adlPovertyTotal" readonly>
                </td>
                <td>
                  <label for="iadlPovertyTotal" class="sr-only">{{iadlPovertyTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.iadlPovertyTotal.elementValue" [disabled]="!_sectionModel.iadlPovertyTotal.isRequired"
                    type="text" class="form-control" id="iadlPovertyTotal" readonly>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  end table responsive  -->
        <div class="pull-left table-responsive">
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>Household Status Distribution</h2>
          </div>
          <table class="table table-no-border">
            <tbody>
              <tr *ngIf="householdWarning()">
                <td class="has-warning" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                  <p *ngIf="!_sectionModel.totalAlone.dataEntryErrorMessage && _sectionModel.totalAlone.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalAloneText}}: {{_sectionModel.totalAlone.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpAlone.dataEntryErrorMessage && _sectionModel.bpAlone.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpAloneText}}: {{_sectionModel.bpAlone.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAlone.dataEntryErrorMessage && _sectionModel.adlAlone.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlAloneText}}: {{_sectionModel.adlAlone.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlAlone.dataEntryErrorMessage && _sectionModel.iadlAlone.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlAloneText}}: {{_sectionModel.iadlAlone.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalWithOthers.dataEntryErrorMessage && _sectionModel.totalWithOthers.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalWithOthersText}}: {{_sectionModel.totalWithOthers.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpWithOthers.dataEntryErrorMessage && _sectionModel.bpWithOthers.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpWithOthersText}}: {{_sectionModel.bpWithOthers.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlWithOthers.dataEntryErrorMessage && _sectionModel.adlWithOthers.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlWithOthersText}}: {{_sectionModel.adlWithOthers.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlWithOthers.dataEntryErrorMessage && _sectionModel.iadlWithOthers.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlWithOthersText}}: {{_sectionModel.iadlWithOthers.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalHouseholdMissing.dataEntryErrorMessage && _sectionModel.totalHouseholdMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalHouseholdMissingText}}:
                    {{_sectionModel.totalHouseholdMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpHouseholdMissing.dataEntryErrorMessage && _sectionModel.bpHouseholdMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpHouseholdMissingText}}:
                    {{_sectionModel.bpHouseholdMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlHouseholdMissing.dataEntryErrorMessage && _sectionModel.adlHouseholdMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlHouseholdMissingText}}:
                    {{_sectionModel.adlHouseholdMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlHouseholdMissing.dataEntryErrorMessage && _sectionModel.iadlHouseholdMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlHouseholdMissingText}}:
                    {{_sectionModel.iadlHouseholdMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allHouseholdTotal.dataEntryErrorMessage && _sectionModel.allHouseholdTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{allHouseholdTotalText}}:
                    {{_sectionModel.allHouseholdTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpHouseholdTotal.dataEntryErrorMessage && _sectionModel.bpHouseholdTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpHouseholdTotalText}}:
                    {{_sectionModel.bpHouseholdTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlHouseholdTotal.dataEntryErrorMessage && _sectionModel.adlHouseholdTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlHouseholdTotalText}}:
                    {{_sectionModel.adlHouseholdTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlHouseholdTotal.dataEntryErrorMessage && _sectionModel.iadlHouseholdTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlHouseholdTotalText}}:
                    {{_sectionModel.iadlHouseholdTotal.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr *ngIf="householdError()">
                <td class="has-error" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                  <p *ngIf="_sectionModel.totalAlone.dataEntryErrorMessage" class="help-block">{{totalAloneText}}:
                    {{_sectionModel.totalAlone.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpAlone.dataEntryErrorMessage" class="help-block">{{bpAloneText}}:
                    {{_sectionModel.bpAlone.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlAlone.dataEntryErrorMessage" class="help-block">{{adlAloneText}}:
                    {{_sectionModel.adlAlone.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlAlone.dataEntryErrorMessage" class="help-block">{{iadlAloneText}}:
                    {{_sectionModel.iadlAlone.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalWithOthers.dataEntryErrorMessage" class="help-block">{{totalWithOthersText}}:
                    {{_sectionModel.totalWithOthers.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpWithOthers.dataEntryErrorMessage" class="help-block">{{bpWithOthersText}}:
                    {{_sectionModel.bpWithOthers.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlWithOthers.dataEntryErrorMessage" class="help-block">{{adlWithOthersText}}:
                    {{_sectionModel.adlWithOthers.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlWithOthers.dataEntryErrorMessage" class="help-block">{{iadlWithOthersText}}:
                    {{_sectionModel.iadlWithOthers.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalHouseholdMissing.dataEntryErrorMessage" class="help-block">{{totalHouseholdMissingText}}:
                    {{_sectionModel.totalHouseholdMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpHouseholdMissing.dataEntryErrorMessage" class="help-block">{{bpHouseholdMissingText}}:
                    {{_sectionModel.bpHouseholdMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.adlHouseholdMissing.dataEntryErrorMessage" class="help-block">{{adlHouseholdMissingText}}:
                    {{_sectionModel.adlHouseholdMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.iadlHouseholdMissing.dataEntryErrorMessage" class="help-block">{{iadlHouseholdMissingText}}:
                    {{_sectionModel.iadlHouseholdMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalAlone.dataEntryErrorMessage && _sectionModel.totalAlone.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalAloneText}}: {{_sectionModel.totalAlone.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpAlone.dataEntryErrorMessage && _sectionModel.bpAlone.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpAloneText}}: {{_sectionModel.bpAlone.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAlone.dataEntryErrorMessage && _sectionModel.adlAlone.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlAloneText}}: {{_sectionModel.adlAlone.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlAlone.dataEntryErrorMessage && _sectionModel.iadlAlone.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlAloneText}}: {{_sectionModel.iadlAlone.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalWithOthers.dataEntryErrorMessage && _sectionModel.totalWithOthers.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalWithOthersText}}: {{_sectionModel.totalWithOthers.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpWithOthers.dataEntryErrorMessage && _sectionModel.bpWithOthers.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpWithOthersText}}: {{_sectionModel.bpWithOthers.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlWithOthers.dataEntryErrorMessage && _sectionModel.adlWithOthers.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlWithOthersText}}: {{_sectionModel.adlWithOthers.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlWithOthers.dataEntryErrorMessage && _sectionModel.iadlWithOthers.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlWithOthersText}}: {{_sectionModel.iadlWithOthers.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalHouseholdMissing.dataEntryErrorMessage && _sectionModel.totalHouseholdMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalHouseholdMissingText}}:
                    {{_sectionModel.totalHouseholdMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpHouseholdMissing.dataEntryErrorMessage && _sectionModel.bpHouseholdMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpHouseholdMissingText}}:
                    {{_sectionModel.bpHouseholdMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlHouseholdMissing.dataEntryErrorMessage && _sectionModel.adlHouseholdMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlHouseholdMissingText}}:
                    {{_sectionModel.adlHouseholdMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlHouseholdMissing.dataEntryErrorMessage && _sectionModel.iadlHouseholdMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlHouseholdMissingText}}:
                    {{_sectionModel.iadlHouseholdMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allHouseholdTotal.dataEntryErrorMessage && _sectionModel.allHouseholdTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{allHouseholdTotalText}}:
                    {{_sectionModel.allHouseholdTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpHouseholdTotal.dataEntryErrorMessage && _sectionModel.bpHouseholdTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpHouseholdTotalText}}:
                    {{_sectionModel.bpHouseholdTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlHouseholdTotal.dataEntryErrorMessage && _sectionModel.adlHouseholdTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlHouseholdTotalText}}:
                    {{_sectionModel.adlHouseholdTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlHouseholdTotal.dataEntryErrorMessage && _sectionModel.iadlHouseholdTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlHouseholdTotalText}}:
                    {{_sectionModel.iadlHouseholdTotal.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr>
                <td width="20%"></td>
                <th width="20%" scope="col">Total</th>
                <th width="20%" scope="col">At or Below Poverty</th>
                <th width="20%" scope="col">ADL 3+</th>
                <th width="20%" scope="col">IADL 3+</th>
              </tr>
              <tr>
                <th class="normal" scope="row">Lives alone</th>
                <td>
                  <label for="totalAlone" class="sr-only">{{totalAloneText}}</label>
                  <div [ngClass]="(_sectionModel.totalAlone.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalAlone.elementValue" [disabled]="!_sectionModel.totalAlone.isRequired"
                      type="text" class="form-control" id="totalAlone" (change)="unsavedChanges = true; _sectionModel.allHouseholdTotal.elementValue = updateTotal(allHouseholdTotalArr); _sectionModel.totalAlone = validate(_sectionModel.totalAlone); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpAlone" class="sr-only">{{bpAloneText}}</label>
                  <div [ngClass]="(_sectionModel.bpAlone.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpAlone.elementValue" [disabled]="!_sectionModel.bpAlone.isRequired"
                      type="text" class="form-control" id="bpAlone" (change)="unsavedChanges = true; _sectionModel.bpHouseholdTotal.elementValue = updateTotal(bpHouseholdTotalArr); _sectionModel.bpAlone = validate(_sectionModel.bpAlone); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlAlone" class="sr-only">{{adlAloneText}}</label>
                  <div [ngClass]="(_sectionModel.adlAlone.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlAlone.elementValue" [disabled]="!_sectionModel.adlAlone.isRequired"
                      type="text" class="form-control" id="adlAlone" (change)="unsavedChanges = true; _sectionModel.adlHouseholdTotal.elementValue = updateTotal(adlHouseholdTotalArr); _sectionModel.adlAlone = validate(_sectionModel.adlAlone); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlAlone" class="sr-only">{{iadlAloneText}}</label>
                  <div [ngClass]="(_sectionModel.iadlAlone.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlAlone.elementValue" [disabled]="!_sectionModel.iadlAlone.isRequired"
                      type="text" class="form-control" id="iadlAlone" (change)="unsavedChanges = true; _sectionModel.iadlHouseholdTotal.elementValue = updateTotal(iadlHouseholdTotalArr); _sectionModel.iadlAlone = validate(_sectionModel.iadlAlone); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Lives with others</th>
                <td>
                  <label for="totalWithOthers" class="sr-only">{{totalWithOthersText}}</label>
                  <div [ngClass]="(_sectionModel.totalWithOthers.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalWithOthers.elementValue" [disabled]="!_sectionModel.totalWithOthers.isRequired"
                      type="text" class="form-control" id="totalWithOthers" (change)="unsavedChanges = true; _sectionModel.allHouseholdTotal.elementValue = updateTotal(allHouseholdTotalArr); _sectionModel.totalWithOthers = validate(_sectionModel.totalWithOthers); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpWithOthers" class="sr-only">{{bpWithOthersText}}</label>
                  <div [ngClass]="(_sectionModel.bpWithOthers.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpWithOthers.elementValue" [disabled]="!_sectionModel.bpWithOthers.isRequired"
                      type="text" class="form-control" id="bpWithOthers" (change)="unsavedChanges = true; _sectionModel.bpHouseholdTotal.elementValue = updateTotal(bpHouseholdTotalArr); _sectionModel.bpWithOthers = validate(_sectionModel.bpWithOthers); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlWithOthers" class="sr-only">{{adlWithOthersText}}</label>
                  <div [ngClass]="(_sectionModel.adlWithOthers.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlWithOthers.elementValue" [disabled]="!_sectionModel.adlWithOthers.isRequired"
                      type="text" class="form-control" id="adlWithOthers" (change)="unsavedChanges = true; _sectionModel.adlHouseholdTotal.elementValue = updateTotal(adlHouseholdTotalArr); _sectionModel.adlWithOthers = validate(_sectionModel.adlWithOthers); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlWithOthers" class="sr-only">{{iadlWithOthersText}}</label>
                  <div [ngClass]="(_sectionModel.iadlWithOthers.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlWithOthers.elementValue" [disabled]="!_sectionModel.iadlWithOthers.isRequired"
                      type="text" class="form-control" id="iadlWithOthers" (change)="unsavedChanges = true; _sectionModel.iadlHouseholdTotal.elementValue = updateTotal(iadlHouseholdTotalArr); _sectionModel.iadlWithOthers = validate(_sectionModel.iadlWithOthers); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Household status missing</th>
                <td>
                  <label for="totalHouseholdMissing" class="sr-only">{{totalHouseholdMissingText}}</label>
                  <div [ngClass]="(_sectionModel.totalHouseholdMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalHouseholdMissing.elementValue" [disabled]="!_sectionModel.totalHouseholdMissing.isRequired"
                      type="text" class="form-control" id="totalHouseholdMissing" (change)="unsavedChanges = true; _sectionModel.allHouseholdTotal.elementValue = updateTotal(allHouseholdTotalArr); _sectionModel.totalHouseholdMissing = validate(_sectionModel.totalHouseholdMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpHouseholdMissing" class="sr-only">{{bpHouseholdMissingText}}</label>
                  <div [ngClass]="(_sectionModel.bpHouseholdMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpHouseholdMissing.elementValue" [disabled]="!_sectionModel.bpHouseholdMissing.isRequired"
                      type="text" class="form-control" id="bpHouseholdMissing" (change)="unsavedChanges = true; _sectionModel.bpHouseholdTotal.elementValue = updateTotal(bpHouseholdTotalArr); _sectionModel.bpHouseholdMissing = validate(_sectionModel.bpHouseholdMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlHouseholdMissing" class="sr-only">{{adlHouseholdMissingText}}</label>
                  <div [ngClass]="(_sectionModel.adlHouseholdMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlHouseholdMissing.elementValue" [disabled]="!_sectionModel.adlHouseholdMissing.isRequired"
                      type="text" class="form-control" id="adlHouseholdMissing" (change)="unsavedChanges = true; _sectionModel.adlHouseholdTotal.elementValue = updateTotal(adlHouseholdTotalArr); _sectionModel.adlHouseholdMissing = validate(_sectionModel.adlHouseholdMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlHouseholdMissing" class="sr-only">{{iadlHouseholdMissingText}}</label>
                  <div [ngClass]="(_sectionModel.iadlHouseholdMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlHouseholdMissing.elementValue" [disabled]="!_sectionModel.iadlHouseholdMissing.isRequired"
                      type="text" class="form-control" id="iadlHouseholdMissing" (change)="unsavedChanges = true; _sectionModel.iadlHouseholdTotal.elementValue = updateTotal(iadlHouseholdTotalArr); _sectionModel.iadlHouseholdMissing = validate(_sectionModel.iadlHouseholdMissing); checkValidForm()">
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Total Persons Served</th>
                <td>
                  <label for="allHouseholdTotal" class="sr-only">{{allHouseholdTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.allHouseholdTotal.elementValue" [disabled]="!_sectionModel.allHouseholdTotal.isRequired"
                    type="text" class="form-control" id="allHouseholdTotal" readonly>
                </td>
                <td>
                  <label for="bpHouseholdTotal" class="sr-only">{{bpHouseholdTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.bpHouseholdTotal.elementValue" [disabled]="!_sectionModel.bpHouseholdTotal.isRequired"
                    type="text" class="form-control" id="bpHouseholdTotal" readonly>
                </td>
                <td>
                  <label for="adlHouseholdTotal" class="sr-only">{{adlHouseholdTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.adlHouseholdTotal.elementValue" [disabled]="!_sectionModel.adlHouseholdTotal.isRequired"
                    type="text" class="form-control" id="adlHouseholdTotal" readonly>
                </td>
                <td>
                  <label for="iadlHouseholdTotal" class="sr-only">{{iadlHouseholdTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.iadlHouseholdTotal.elementValue" [disabled]="!_sectionModel.iadlHouseholdTotal.isRequired"
                    type="text" class="form-control" id="iadlHouseholdTotal" readonly>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  end table responsive  -->
        <div class="pull-left table-responsive">
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>Ethnicity Distribution</h2>
          </div>
          <table class="table table-no-border">
            <tbody>
              <tr *ngIf="ethnicityWarning()">
                <td class="has-warning" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                  <p *ngIf="!_sectionModel.totalHispanic.dataEntryErrorMessage && _sectionModel.totalHispanic.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalHispanicText}}: {{_sectionModel.totalHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpHispanic.dataEntryErrorMessage && _sectionModel.bpHispanic.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpHispanicText}}: {{_sectionModel.bpHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlHispanic.dataEntryErrorMessage && _sectionModel.adlHispanic.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlHispanicText}}: {{_sectionModel.adlHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlHispanic.dataEntryErrorMessage && _sectionModel.iadlHispanic.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlHispanicText}}: {{_sectionModel.iadlHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalNonHispanic.dataEntryErrorMessage && _sectionModel.totalNonHispanic.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalNonHispanicText}}:
                    {{_sectionModel.totalNonHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpNonHispanic.dataEntryErrorMessage && _sectionModel.bpNonHispanic.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpNonHispanicText}}: {{_sectionModel.bpNonHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlNonHispanic.dataEntryErrorMessage && _sectionModel.adlNonHispanic.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlNonHispanicText}}: {{_sectionModel.adlNonHispanic.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlNonHispanic.dataEntryErrorMessage && _sectionModel.iadlNonHispanic.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlNonHispanicText}}: {{_sectionModel.iadlNonHispanic.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalEthnicityMissing.dataEntryErrorMessage && _sectionModel.totalEthnicityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalEthnicityMissingText}}:
                    {{_sectionModel.totalEthnicityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpEthnicityMissing.dataEntryErrorMessage && _sectionModel.bpEthnicityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpEthnicityMissingText}}:
                    {{_sectionModel.bpEthnicityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlEthnicityMissing.dataEntryErrorMessage && _sectionModel.adlEthnicityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlEthnicityMissingText}}:
                    {{_sectionModel.adlEthnicityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlEthnicityMissing.dataEntryErrorMessage && _sectionModel.iadlEthnicityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlEthnicityMissingText}}:
                    {{_sectionModel.iadlEthnicityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allEthnicityTotal.dataEntryErrorMessage && _sectionModel.allEthnicityTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{allEthnicityTotalText}}:
                    {{_sectionModel.allEthnicityTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpEthnicityTotal.dataEntryErrorMessage && _sectionModel.bpEthnicityTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpEthnicityTotalText}}:
                    {{_sectionModel.bpEthnicityTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlEthnicityTotal.dataEntryErrorMessage && _sectionModel.adlEthnicityTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlEthnicityTotalText}}:
                    {{_sectionModel.adlEthnicityTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlEthnicityTotal.dataEntryErrorMessage && _sectionModel.iadlEthnicityTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlEthnicityTotalText}}:
                    {{_sectionModel.iadlEthnicityTotal.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr *ngIf="ethnicityError()">
                <td class="has-error" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                  <p *ngIf="_sectionModel.totalHispanic.dataEntryErrorMessage" class="help-block">{{totalHispanicText}}:
                    {{_sectionModel.totalHispanic.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpHispanic.dataEntryErrorMessage" class="help-block">{{bpHispanicText}}:
                    {{_sectionModel.bpHispanic.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlHispanic.dataEntryErrorMessage" class="help-block">{{adlHispanicText}}:
                    {{_sectionModel.adlHispanic.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlHispanic.dataEntryErrorMessage" class="help-block">{{iadlHispanicText}}:
                    {{_sectionModel.iadlHispanic.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalNonHispanic.dataEntryErrorMessage" class="help-block">{{totalNonHispanicText}}:
                    {{_sectionModel.totalNonHispanic.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpNonHispanic.dataEntryErrorMessage" class="help-block">{{bpNonHispanicText}}:
                    {{_sectionModel.bpNonHispanic.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlNonHispanic.dataEntryErrorMessage" class="help-block">{{adlNonHispanicText}}:
                    {{_sectionModel.adlNonHispanic.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlNonHispanic.dataEntryErrorMessage" class="help-block">{{iadlNonHispanicText}}:
                    {{_sectionModel.iadlNonHispanic.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalEthnicityMissing.dataEntryErrorMessage" class="help-block">{{totalEthnicityMissingText}}:
                    {{_sectionModel.totalEthnicityMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpEthnicityMissing.dataEntryErrorMessage" class="help-block">{{bpEthnicityMissingText}}:
                    {{_sectionModel.bpEthnicityMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.adlEthnicityMissing.dataEntryErrorMessage" class="help-block">{{adlEthnicityMissingText}}:
                    {{_sectionModel.adlEthnicityMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.iadlEthnicityMissing.dataEntryErrorMessage" class="help-block">{{iadlEthnicityMissingText}}:
                    {{_sectionModel.iadlEthnicityMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalHispanic.dataEntryErrorMessage && _sectionModel.totalHispanic.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalHispanicText}}: {{_sectionModel.totalHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpHispanic.dataEntryErrorMessage && _sectionModel.bpHispanic.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpHispanicText}}: {{_sectionModel.bpHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlHispanic.dataEntryErrorMessage && _sectionModel.adlHispanic.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlHispanicText}}: {{_sectionModel.adlHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlHispanic.dataEntryErrorMessage && _sectionModel.iadlHispanic.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlHispanicText}}: {{_sectionModel.iadlHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalNonHispanic.dataEntryErrorMessage && _sectionModel.totalNonHispanic.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalNonHispanicText}}:
                    {{_sectionModel.totalNonHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpNonHispanic.dataEntryErrorMessage && _sectionModel.bpNonHispanic.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpNonHispanicText}}: {{_sectionModel.bpNonHispanic.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlNonHispanic.dataEntryErrorMessage && _sectionModel.adlNonHispanic.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlNonHispanicText}}: {{_sectionModel.adlNonHispanic.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlNonHispanic.dataEntryErrorMessage && _sectionModel.iadlNonHispanic.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlNonHispanicText}}: {{_sectionModel.iadlNonHispanic.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalEthnicityMissing.dataEntryErrorMessage && _sectionModel.totalEthnicityMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalEthnicityMissingText}}:
                    {{_sectionModel.totalEthnicityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpEthnicityMissing.dataEntryErrorMessage && _sectionModel.bpEthnicityMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpEthnicityMissingText}}:
                    {{_sectionModel.bpEthnicityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlEthnicityMissing.dataEntryErrorMessage && _sectionModel.adlEthnicityMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlEthnicityMissingText}}:
                    {{_sectionModel.adlEthnicityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlEthnicityMissing.dataEntryErrorMessage && _sectionModel.iadlEthnicityMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlEthnicityMissingText}}:
                    {{_sectionModel.iadlEthnicityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allEthnicityTotal.dataEntryErrorMessage && _sectionModel.allEthnicityTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{allEthnicityTotalText}}:
                    {{_sectionModel.allEthnicityTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpEthnicityTotal.dataEntryErrorMessage && _sectionModel.bpEthnicityTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpEthnicityTotalText}}:
                    {{_sectionModel.bpEthnicityTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlEthnicityTotal.dataEntryErrorMessage && _sectionModel.adlEthnicityTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlEthnicityTotalText}}:
                    {{_sectionModel.adlEthnicityTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlEthnicityTotal.dataEntryErrorMessage && _sectionModel.iadlEthnicityTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlEthnicityTotalText}}:
                    {{_sectionModel.iadlEthnicityTotal.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr>
                <td width="20%"></td>
                <th width="20%" scope="col">Total</th>
                <th width="20%" scope="col">At or Below Poverty</th>
                <th width="20%" scope="col">ADL 3+</th>
                <th width="20%" scope="col">IADL 3+</th>
              </tr>
              <tr>
                <th class="normal" scope="row">Hispanic or Latino</th>
                <td>
                  <label for="totalHispanic" class="sr-only">{{totalHispanicText}}</label>
                  <div [ngClass]="(_sectionModel.totalHispanic.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalHispanic.elementValue" [disabled]="!_sectionModel.totalHispanic.isRequired"
                      type="text" class="form-control" id="totalHispanic" (change)="unsavedChanges = true; _sectionModel.allEthnicityTotal.elementValue = updateTotal(allEthnicityTotalArr); _sectionModel.totalHispanic = validate(_sectionModel.totalHispanic); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpHispanic" class="sr-only">{{bpHispanicText}}</label>
                  <div [ngClass]="(_sectionModel.bpHispanic.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpHispanic.elementValue" [disabled]="!_sectionModel.bpHispanic.isRequired"
                      type="text" class="form-control" id="bpHispanic" (change)="unsavedChanges = true; _sectionModel.bpEthnicityTotal.elementValue = updateTotal(bpEthnicityTotalArr); _sectionModel.bpHispanic = validate(_sectionModel.bpHispanic); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlHispanic" class="sr-only">{{adlHispanicText}}</label>
                  <div [ngClass]="(_sectionModel.adlHispanic.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlHispanic.elementValue" [disabled]="!_sectionModel.adlHispanic.isRequired"
                      type="text" class="form-control" id="adlHispanic" (change)="unsavedChanges = true; _sectionModel.adlEthnicityTotal.elementValue = updateTotal(adlEthnicityTotalArr); _sectionModel.adlHispanic = validate(_sectionModel.adlHispanic); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlHispanic" class="sr-only">{{iadlHispanicText}}</label>
                  <div [ngClass]="(_sectionModel.iadlHispanic.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlHispanic.elementValue" [disabled]="!_sectionModel.iadlHispanic.isRequired"
                      type="text" class="form-control" id="iadlHispanic" (change)="unsavedChanges = true; _sectionModel.iadlEthnicityTotal.elementValue = updateTotal(iadlEthnicityTotalArr); _sectionModel.iadlHispanic = validate(_sectionModel.iadlHispanic); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Not Hispanic or Latino</th>
                <td>
                  <label for="totalNonHispanic" class="sr-only">{{totalNonHispanicText}}</label>
                  <div [ngClass]="(_sectionModel.totalNonHispanic.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalNonHispanic.elementValue" [disabled]="!_sectionModel.totalNonHispanic.isRequired"
                      type="text" class="form-control" id="totalNonHispanic" (change)="unsavedChanges = true; _sectionModel.allEthnicityTotal.elementValue = updateTotal(allEthnicityTotalArr); _sectionModel.totalNonHispanic = validate(_sectionModel.totalNonHispanic); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpNonHispanic" class="sr-only">{{bpNonHispanicText}}</label>
                  <div [ngClass]="(_sectionModel.bpNonHispanic.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpNonHispanic.elementValue" [disabled]="!_sectionModel.bpNonHispanic.isRequired"
                      type="text" class="form-control" id="bpNonHispanic" (change)="unsavedChanges = true; _sectionModel.bpEthnicityTotal.elementValue = updateTotal(bpEthnicityTotalArr); _sectionModel.bpNonHispanic = validate(_sectionModel.bpNonHispanic); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlNonHispanic" class="sr-only">{{adlNonHispanicText}}</label>
                  <div [ngClass]="(_sectionModel.adlNonHispanic.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlNonHispanic.elementValue" [disabled]="!_sectionModel.adlNonHispanic.isRequired"
                      type="text" class="form-control" id="adlNonHispanic" (change)="unsavedChanges = true; _sectionModel.adlEthnicityTotal.elementValue = updateTotal(adlEthnicityTotalArr); _sectionModel.adlNonHispanic = validate(_sectionModel.adlNonHispanic); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlNonHispanic" class="sr-only">{{iadlNonHispanicText}}</label>
                  <div [ngClass]="(_sectionModel.iadlNonHispanic.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlNonHispanic.elementValue" [disabled]="!_sectionModel.iadlNonHispanic.isRequired"
                      type="text" class="form-control" id="iadlNonHispanic" (change)="unsavedChanges = true; _sectionModel.iadlEthnicityTotal.elementValue = updateTotal(iadlEthnicityTotalArr); _sectionModel.iadlNonHispanic = validate(_sectionModel.iadlNonHispanic); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Ethnicity missing</th>
                <td>
                  <label for="totalEthnicityMissing" class="sr-only">{{totalEthnicityMissingText}}</label>
                  <div [ngClass]="(_sectionModel.totalEthnicityMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalEthnicityMissing.elementValue" [disabled]="!_sectionModel.totalEthnicityMissing.isRequired"
                      type="text" class="form-control" id="totalEthnicityMissing" (change)="unsavedChanges = true; _sectionModel.allEthnicityTotal.elementValue = updateTotal(allEthnicityTotalArr); _sectionModel.totalEthnicityMissing = validate(_sectionModel.totalEthnicityMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpEthnicityMissing" class="sr-only">{{bpEthnicityMissingText}}</label>
                  <div [ngClass]="(_sectionModel.bpEthnicityMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpEthnicityMissing.elementValue" [disabled]="!_sectionModel.bpEthnicityMissing.isRequired"
                      type="text" class="form-control" id="bpEthnicityMissing" (change)="unsavedChanges = true; _sectionModel.bpEthnicityTotal.elementValue = updateTotal(bpEthnicityTotalArr); _sectionModel.bpEthnicityMissing = validate(_sectionModel.bpEthnicityMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlEthnicityMissing" class="sr-only">{{adlEthnicityMissingText}}</label>
                  <div [ngClass]="(_sectionModel.adlEthnicityMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlEthnicityMissing.elementValue" [disabled]="!_sectionModel.adlEthnicityMissing.isRequired"
                      type="text" class="form-control" id="adlEthnicityMissing" (change)="unsavedChanges = true; _sectionModel.adlEthnicityTotal.elementValue = updateTotal(adlEthnicityTotalArr); _sectionModel.adlEthnicityMissing = validate(_sectionModel.adlEthnicityMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlEthnicityMissing" class="sr-only">{{iadlEthnicityMissingText}}</label>
                  <div [ngClass]="(_sectionModel.iadlEthnicityMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlEthnicityMissing.elementValue" [disabled]="!_sectionModel.iadlEthnicityMissing.isRequired"
                      type="text" class="form-control" id="iadlEthnicityMissing" (change)="unsavedChanges = true; _sectionModel.iadlEthnicityTotal.elementValue = updateTotal(iadlEthnicityTotalArr); _sectionModel.iadlEthnicityMissing = validate(_sectionModel.iadlEthnicityMissing); checkValidForm()">
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Total Persons Served</th>
                <td>
                  <label for="allEthnicityTotal" class="sr-only">{{allEthnicityTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.allEthnicityTotal.elementValue" [disabled]="!_sectionModel.allEthnicityTotal.isRequired"
                    type="text" class="form-control" id="allEthnicityTotal" readonly>
                </td>
                <td>
                  <label for="bpEthnicityTotal" class="sr-only">{{bpEthnicityTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.bpEthnicityTotal.elementValue" [disabled]="!_sectionModel.bpEthnicityTotal.isRequired"
                    type="text" class="form-control" id="bpEthnicityTotal" readonly>
                </td>
                <td>
                  <label for="adlEthnicityTotal" class="sr-only">{{adlEthnicityTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.adlEthnicityTotal.elementValue" [disabled]="!_sectionModel.adlEthnicityTotal.isRequired"
                    type="text" class="form-control" id="adlEthnicityTotal" readonly>
                </td>
                <td>
                  <label for="iadlEthnicityTotal" class="sr-only">{{iadlEthnicityTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.iadlEthnicityTotal.elementValue" [disabled]="!_sectionModel.iadlEthnicityTotal.isRequired"
                    type="text" class="form-control" id="iadlEthnicityTotal" readonly>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  end table responsive  -->
        <div class="pull-left table-responsive">
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>Race Distribution</h2>
          </div>
          <table class="table table-no-border">

            <tbody>
              <tr *ngIf="raceWarning()">
                <td class="has-warning" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                  <p *ngIf="!_sectionModel.totalAmericanIndian.dataEntryErrorMessage && _sectionModel.totalAmericanIndian.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalAmericanIndianText}}:
                    {{_sectionModel.totalAmericanIndian.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpAmericanIndian.dataEntryErrorMessage && _sectionModel.bpAmericanIndian.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpAmericanIndianText}}:
                    {{_sectionModel.bpAmericanIndian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAmericanIndian.dataEntryErrorMessage && _sectionModel.adlAmericanIndian.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlAmericanIndianText}}:
                    {{_sectionModel.adlAmericanIndian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlAmericanIndian.dataEntryErrorMessage && _sectionModel.iadlAmericanIndian.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlAmericanIndianText}}:
                    {{_sectionModel.iadlAmericanIndian.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalAsian.dataEntryErrorMessage && _sectionModel.totalAsian.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalAsianText}}: {{_sectionModel.totalAsian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpAsian.dataEntryErrorMessage && _sectionModel.bpAsian.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpAsianText}}: {{_sectionModel.bpAsian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAsian.dataEntryErrorMessage && _sectionModel.adlAsian.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlAsianText}}: {{_sectionModel.adlAsian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlAsian.dataEntryErrorMessage && _sectionModel.iadlAsian.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlAsianText}}: {{_sectionModel.iadlAsian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalBlack.dataEntryErrorMessage && _sectionModel.totalBlack.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalBlackText}}: {{_sectionModel.totalBlack.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpBlack.dataEntryErrorMessage && _sectionModel.bpBlack.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpBlackText}}: {{_sectionModel.bpBlack.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlBlack.dataEntryErrorMessage && _sectionModel.adlBlack.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlBlackText}}: {{_sectionModel.adlBlack.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlBlack.dataEntryErrorMessage && _sectionModel.iadlBlack.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlBlackText}}: {{_sectionModel.iadlBlack.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalIslander.dataEntryErrorMessage && _sectionModel.totalIslander.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalIslanderText}}: {{_sectionModel.totalIslander.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpIslander.dataEntryErrorMessage && _sectionModel.bpIslander.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpIslanderText}}: {{_sectionModel.bpIslander.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlIslander.dataEntryErrorMessage && _sectionModel.adlIslander.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlIslanderText}}: {{_sectionModel.adlIslander.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlIslander.dataEntryErrorMessage && _sectionModel.iadlIslander.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlIslanderText}}: {{_sectionModel.iadlIslander.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalWhite.dataEntryErrorMessage && _sectionModel.totalWhite.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalWhiteText}}: {{_sectionModel.totalWhite.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpWhite.dataEntryErrorMessage && _sectionModel.bpWhite.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpWhiteText}}: {{_sectionModel.bpWhite.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlWhite.dataEntryErrorMessage && _sectionModel.adlWhite.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlWhiteText}}: {{_sectionModel.adlWhite.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlWhite.dataEntryErrorMessage && _sectionModel.iadlWhite.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlWhiteText}}: {{_sectionModel.iadlWhite.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalRaceOther.dataEntryErrorMessage && _sectionModel.totalRaceOther.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalRaceOtherText}}:
                    {{_sectionModel.totalRaceOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRaceOther.dataEntryErrorMessage && _sectionModel.bpRaceOther.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpRaceOtherText}}: {{_sectionModel.bpRaceOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRaceOther.dataEntryErrorMessage && _sectionModel.adlRaceOther.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlRaceOtherText}}: {{_sectionModel.adlRaceOther.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlRaceOther.dataEntryErrorMessage && _sectionModel.iadlRaceOther.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlRaceOtherText}}: {{_sectionModel.iadlRaceOther.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalRaceMultiple.dataEntryErrorMessage && _sectionModel.totalRaceMultiple.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalRaceMultipleText}}:
                    {{_sectionModel.totalRaceMultiple.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRaceMultiple.dataEntryErrorMessage && _sectionModel.bpRaceMultiple.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpRaceMultipleText}}: {{_sectionModel.bpRaceMultiple.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRaceMultiple.dataEntryErrorMessage && _sectionModel.adlRaceMultiple.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlRaceMultipleText}}: {{_sectionModel.adlRaceMultiple.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlRaceMultiple.dataEntryErrorMessage && _sectionModel.iadlRaceMultiple.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlRaceMultipleText}}: {{_sectionModel.iadlRaceMultiple.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalRaceMissing.dataEntryErrorMessage && _sectionModel.totalRaceMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalRaceMissingText}}:
                    {{_sectionModel.totalRaceMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRaceMissing.dataEntryErrorMessage && _sectionModel.bpRaceMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpRaceMissingText}}: {{_sectionModel.bpRaceMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRaceMissing.dataEntryErrorMessage && _sectionModel.adlRaceMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlRaceMissingText}}: {{_sectionModel.adlRaceMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlRaceMissing.dataEntryErrorMessage && _sectionModel.iadlRaceMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlRaceMissingText}}: {{_sectionModel.iadlRaceMissing.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr *ngIf="raceError()">
                <td class="has-error" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                  <p *ngIf="_sectionModel.totalAmericanIndian.dataEntryErrorMessage" class="help-block">{{totalAmericanIndianText}}:
                    {{_sectionModel.totalAmericanIndian.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpAmericanIndian.dataEntryErrorMessage" class="help-block">{{bpAmericanIndianText}}:
                    {{_sectionModel.bpAmericanIndian.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlAmericanIndian.dataEntryErrorMessage" class="help-block">{{adlAmericanIndianText}}:
                    {{_sectionModel.adlAmericanIndian.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.iadlAmericanIndian.dataEntryErrorMessage" class="help-block">{{iadlAmericanIndianText}}:
                    {{_sectionModel.iadlAmericanIndian.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.totalAsian.dataEntryErrorMessage" class="help-block">{{totalAsianText}}:
                    {{_sectionModel.totalAsian.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpAsian.dataEntryErrorMessage" class="help-block">{{bpAsianText}}:
                    {{_sectionModel.bpAsian.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlAsian.dataEntryErrorMessage" class="help-block">{{adlAsianText}}:
                    {{_sectionModel.adlAsian.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlAsian.dataEntryErrorMessage" class="help-block">{{iadlAsianText}}:
                    {{_sectionModel.iadlAsian.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalBlack.dataEntryErrorMessage" class="help-block">{{totalBlackText}}:
                    {{_sectionModel.totalBlack.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpBlack.dataEntryErrorMessage" class="help-block">{{bpBlackText}}:
                    {{_sectionModel.bpBlack.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlBlack.dataEntryErrorMessage" class="help-block">{{adlBlackText}}:
                    {{_sectionModel.adlBlack.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlBlack.dataEntryErrorMessage" class="help-block">{{iadlBlackText}}:
                    {{_sectionModel.iadlBlack.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalIslander.dataEntryErrorMessage" class="help-block">{{totalIslanderText}}:
                    {{_sectionModel.totalIslander.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpIslander.dataEntryErrorMessage" class="help-block">{{bpIslanderText}}:
                    {{_sectionModel.bpIslander.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlIslander.dataEntryErrorMessage" class="help-block">{{adlIslanderText}}:
                    {{_sectionModel.adlIslander.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlIslander.dataEntryErrorMessage" class="help-block">{{iadlIslanderText}}:
                    {{_sectionModel.iadlIslander.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalWhite.dataEntryErrorMessage" class="help-block">{{totalWhiteText}}:
                    {{_sectionModel.totalWhite.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpWhite.dataEntryErrorMessage" class="help-block">{{bpWhiteText}}:
                    {{_sectionModel.bpWhite.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlWhite.dataEntryErrorMessage" class="help-block">{{adlWhiteText}}:
                    {{_sectionModel.adlWhite.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlWhite.dataEntryErrorMessage" class="help-block">{{iadlWhiteText}}:
                    {{_sectionModel.iadlWhite.dataEntryErrorMessage}} </p>
                    <p *ngIf="_sectionModel.totalRaceOther.dataEntryErrorMessage" class="help-block">{{totalRaceOtherText}}:
                      {{_sectionModel.totalRaceOther.dataEntryErrorMessage}} </p>
                    <p *ngIf="_sectionModel.bpRaceOther.dataEntryErrorMessage" class="help-block">{{bpRaceOtherText}}:
                      {{_sectionModel.bpRaceOther.dataEntryErrorMessage}} </p>
                    <p *ngIf="_sectionModel.adlRaceOther.dataEntryErrorMessage" class="help-block">{{adlRaceOtherText}}:
                      {{_sectionModel.adlRaceOther.dataEntryErrorMessage}} </p>
                    <p *ngIf="_sectionModel.iadlRaceOther.dataEntryErrorMessage" class="help-block">{{iadlRaceOtherText}}:
                      {{_sectionModel.iadlRaceOther.dataEntryErrorMessage}} </p>
                      <p *ngIf="_sectionModel.totalRaceMultiple.dataEntryErrorMessage" class="help-block">{{totalRaceMultipleText}}:
                        {{_sectionModel.totalRaceMultiple.dataEntryErrorMessage}} </p>
                      <p *ngIf="_sectionModel.bpRaceMultiple.dataEntryErrorMessage" class="help-block">{{bpRaceMultipleText}}:
                        {{_sectionModel.bpRaceMultiple.dataEntryErrorMessage}} </p>
                      <p *ngIf="_sectionModel.adlRaceMultiple.dataEntryErrorMessage" class="help-block">{{adlRaceMultipleText}}:
                        {{_sectionModel.adlRaceMultiple.dataEntryErrorMessage}} </p>
                      <p *ngIf="_sectionModel.iadlRaceMultiple.dataEntryErrorMessage" class="help-block">{{iadlRaceMultipleText}}:
                        {{_sectionModel.iadlRaceMultiple.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalRaceMissing.dataEntryErrorMessage" class="help-block">{{totalRaceMissingText}}:
                    {{_sectionModel.totalRaceMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpRaceMissing.dataEntryErrorMessage" class="help-block">{{bpRaceMissingText}}:
                    {{_sectionModel.bpRaceMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlRaceMissing.dataEntryErrorMessage" class="help-block">{{adlRaceMissingText}}:
                    {{_sectionModel.adlRaceMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlRaceMissing.dataEntryErrorMessage" class="help-block">{{iadlRaceMissingText}}:
                    {{_sectionModel.iadlRaceMissing.dataEntryErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalAmericanIndian.dataEntryErrorMessage && _sectionModel.totalAmericanIndian.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalAmericanIndianText}}:
                    {{_sectionModel.totalAmericanIndian.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpAmericanIndian.dataEntryErrorMessage && _sectionModel.bpAmericanIndian.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpAmericanIndianText}}:
                    {{_sectionModel.bpAmericanIndian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAmericanIndian.dataEntryErrorMessage && _sectionModel.adlAmericanIndian.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlAmericanIndianText}}:
                    {{_sectionModel.adlAmericanIndian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlAmericanIndian.dataEntryErrorMessage && _sectionModel.iadlAmericanIndian.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlAmericanIndianText}}:
                    {{_sectionModel.iadlAmericanIndian.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalAsian.dataEntryErrorMessage && _sectionModel.totalAsian.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalAsianText}}: {{_sectionModel.totalAsian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpAsian.dataEntryErrorMessage && _sectionModel.bpAsian.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpAsianText}}: {{_sectionModel.bpAsian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlAsian.dataEntryErrorMessage && _sectionModel.adlAsian.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlAsianText}}: {{_sectionModel.adlAsian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlAsian.dataEntryErrorMessage && _sectionModel.iadlAsian.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlAsianText}}: {{_sectionModel.iadlAsian.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalBlack.dataEntryErrorMessage && _sectionModel.totalBlack.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalBlackText}}: {{_sectionModel.totalBlack.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpBlack.dataEntryErrorMessage && _sectionModel.bpBlack.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpBlackText}}: {{_sectionModel.bpBlack.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlBlack.dataEntryErrorMessage && _sectionModel.adlBlack.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlBlackText}}: {{_sectionModel.adlBlack.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlBlack.dataEntryErrorMessage && _sectionModel.iadlBlack.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlBlackText}}: {{_sectionModel.iadlBlack.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalIslander.dataEntryErrorMessage && _sectionModel.totalIslander.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalIslanderText}}: {{_sectionModel.totalIslander.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpIslander.dataEntryErrorMessage && _sectionModel.bpIslander.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpIslanderText}}: {{_sectionModel.bpIslander.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlIslander.dataEntryErrorMessage && _sectionModel.adlIslander.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlIslanderText}}: {{_sectionModel.adlIslander.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlIslander.dataEntryErrorMessage && _sectionModel.iadlIslander.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlIslanderText}}: {{_sectionModel.iadlIslander.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalWhite.dataEntryErrorMessage && _sectionModel.totalWhite.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalWhiteText}}: {{_sectionModel.totalWhite.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpWhite.dataEntryErrorMessage && _sectionModel.bpWhite.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpWhiteText}}: {{_sectionModel.bpWhite.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlWhite.dataEntryErrorMessage && _sectionModel.adlWhite.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlWhiteText}}: {{_sectionModel.adlWhite.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlWhite.dataEntryErrorMessage && _sectionModel.iadlWhite.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlWhiteText}}: {{_sectionModel.iadlWhite.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalRaceOther.dataEntryErrorMessage && _sectionModel.totalRaceOther.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalRaceOtherText}}:
                    {{_sectionModel.totalRaceOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRaceOther.dataEntryErrorMessage && _sectionModel.bpRaceOther.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpRaceOtherText}}: {{_sectionModel.bpRaceOther.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRaceOther.dataEntryErrorMessage && _sectionModel.adlRaceOther.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlRaceOtherText}}: {{_sectionModel.adlRaceOther.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlRaceOther.dataEntryErrorMessage && _sectionModel.iadlRaceOther.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlRaceOtherText}}: {{_sectionModel.iadlRaceOther.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalRaceMultiple.dataEntryErrorMessage && _sectionModel.totalRaceMultiple.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalRaceMultipleText}}:
                    {{_sectionModel.totalRaceMultiple.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRaceMultiple.dataEntryErrorMessage && _sectionModel.bpRaceMultiple.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpRaceMultipleText}}: {{_sectionModel.bpRaceMultiple.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRaceMultiple.dataEntryErrorMessage && _sectionModel.adlRaceMultiple.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlRaceMultipleText}}: {{_sectionModel.adlRaceMultiple.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlRaceMultiple.dataEntryErrorMessage && _sectionModel.iadlRaceMultiple.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlRaceMultipleText}}: {{_sectionModel.iadlRaceMultiple.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalRaceMissing.dataEntryErrorMessage && _sectionModel.totalRaceMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalRaceMissingText}}:
                    {{_sectionModel.totalRaceMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpRaceMissing.dataEntryErrorMessage && _sectionModel.bpRaceMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpRaceMissingText}}: {{_sectionModel.bpRaceMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlRaceMissing.dataEntryErrorMessage && _sectionModel.adlRaceMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlRaceMissingText}}: {{_sectionModel.adlRaceMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlRaceMissing.dataEntryErrorMessage && _sectionModel.iadlRaceMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlRaceMissingText}}: {{_sectionModel.iadlRaceMissing.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr>
                <td width="20%"></td>
                <th width="20%" scope="col">Total</th>
                <th width="20%" scope="col">At or Below Poverty</th>
                <th width="20%" scope="col">ADL 3+</th>
                <th width="20%" scope="col">IADL 3+</th>
              </tr>
              <tr>
                <th class="normal" scope="row">American Indian or Alaska Native</th>
                <td>
                  <label for="totalAmericanIndian" class="sr-only">{{totalAmericanIndianText}}</label>
                  <div [ngClass]="(_sectionModel.totalAmericanIndian.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalAmericanIndian.elementValue" [disabled]="!_sectionModel.totalAmericanIndian.isRequired"
                      type="text" class="form-control" id="totalAmericanIndian" (change)="unsavedChanges = true; _sectionModel.totalAmericanIndian.elementValue = addComma(_sectionModel.totalAmericanIndian.elementValue); _sectionModel.totalAmericanIndian = validate(_sectionModel.totalAmericanIndian); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpAmericanIndian" class="sr-only">{{bpAmericanIndianText}}</label>
                  <div [ngClass]="(_sectionModel.bpAmericanIndian.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpAmericanIndian.elementValue" [disabled]="!_sectionModel.bpAmericanIndian.isRequired"
                      type="text" class="form-control" id="bpAmericanIndian" (change)="unsavedChanges = true; _sectionModel.bpAmericanIndian.elementValue = addComma(_sectionModel.bpAmericanIndian.elementValue); _sectionModel.bpAmericanIndian = validate(_sectionModel.bpAmericanIndian); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlAmericanIndian" class="sr-only">{{adlAmericanIndianText}}</label>
                  <div [ngClass]="(_sectionModel.adlAmericanIndian.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlAmericanIndian.elementValue" [disabled]="!_sectionModel.adlAmericanIndian.isRequired"
                      type="text" class="form-control" id="adlAmericanIndian" (change)="unsavedChanges = true; _sectionModel.adlAmericanIndian.elementValue = addComma(_sectionModel.adlAmericanIndian.elementValue); _sectionModel.adlAmericanIndian = validate(_sectionModel.adlAmericanIndian); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlAmericanIndian" class="sr-only">{{iadlAmericanIndianText}}</label>
                  <div [ngClass]="(_sectionModel.iadlAmericanIndian.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlAmericanIndian.elementValue" [disabled]="!_sectionModel.iadlAmericanIndian.isRequired"
                      type="text" class="form-control" id="iadlAmericanIndian" (change)="unsavedChanges = true; _sectionModel.iadlAmericanIndian.elementValue = addComma(_sectionModel.iadlAmericanIndian.elementValue); _sectionModel.iadlAmericanIndian = validate(_sectionModel.iadlAmericanIndian); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Asian or Asian American</th>
                <td>
                  <label for="totalAsian" class="sr-only">{{totalAsianText}}</label>
                  <div [ngClass]="(_sectionModel.totalAsian.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalAsian.elementValue" [disabled]="!_sectionModel.totalAsian.isRequired"
                      type="text" class="form-control" id="totalAsian" (change)="unsavedChanges = true; _sectionModel.totalAsian.elementValue = addComma(_sectionModel.totalAsian.elementValue); _sectionModel.totalAsian = validate(_sectionModel.totalAsian); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpAsian" class="sr-only">{{bpAsianText}}</label>
                  <div [ngClass]="(_sectionModel.bpAsian.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpAsian.elementValue" [disabled]="!_sectionModel.bpAsian.isRequired"
                      type="text" class="form-control" id="bpAsian" (change)="unsavedChanges = true; _sectionModel.bpAsian.elementValue = addComma(_sectionModel.bpAsian.elementValue); _sectionModel.bpAsian = validate(_sectionModel.bpAsian); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlAsian" class="sr-only">{{adlAsianText}}</label>
                  <div [ngClass]="(_sectionModel.adlAsian.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlAsian.elementValue" [disabled]="!_sectionModel.adlAsian.isRequired"
                      type="text" class="form-control" id="adlAsian" (change)="unsavedChanges = true; _sectionModel.adlAsian.elementValue = addComma(_sectionModel.adlAsian.elementValue); _sectionModel.adlAsian = validate(_sectionModel.adlAsian); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlAsian" class="sr-only">{{iadlAsianText}}</label>
                  <div [ngClass]="(_sectionModel.iadlAsian.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlAsian.elementValue" [disabled]="!_sectionModel.iadlAsian.isRequired"
                      type="text" class="form-control" id="iadlAsian" (change)="unsavedChanges = true; _sectionModel.iadlAsian.elementValue = addComma(_sectionModel.iadlAsian.elementValue); _sectionModel.iadlAsian = validate(_sectionModel.iadlAsian); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Black or African American</th>
                <td>
                  <label for="totalBlack" class="sr-only">{{totalBlackText}}</label>
                  <div [ngClass]="(_sectionModel.totalBlack.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalBlack.elementValue" [disabled]="!_sectionModel.totalBlack.isRequired"
                      type="text" class="form-control" id="totalBlack" (change)="unsavedChanges = true; _sectionModel.totalBlack.elementValue = addComma(_sectionModel.totalBlack.elementValue); _sectionModel.totalBlack = validate(_sectionModel.totalBlack); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpBlack" class="sr-only">{{bpBlackText}}</label>
                  <div [ngClass]="(_sectionModel.bpBlack.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpBlack.elementValue" [disabled]="!_sectionModel.bpBlack.isRequired"
                      type="text" class="form-control" id="bpBlack" (change)="unsavedChanges = true; _sectionModel.bpBlack.elementValue = addComma(_sectionModel.bpBlack.elementValue); _sectionModel.bpBlack = validate(_sectionModel.bpBlack); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlBlack" class="sr-only">{{adlBlackText}}</label>
                  <div [ngClass]="(_sectionModel.adlBlack.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlBlack.elementValue" [disabled]="!_sectionModel.adlBlack.isRequired"
                      type="text" class="form-control" id="adlBlack" (change)="unsavedChanges = true; _sectionModel.adlBlack.elementValue = addComma(_sectionModel.adlBlack.elementValue); _sectionModel.adlBlack = validate(_sectionModel.adlBlack); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlBlack" class="sr-only">{{iadlBlackText}}</label>
                  <div [ngClass]="(_sectionModel.iadlBlack.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlBlack.elementValue" [disabled]="!_sectionModel.iadlBlack.isRequired"
                      type="text" class="form-control" id="iadlBlack" (change)="unsavedChanges = true; _sectionModel.iadlBlack.elementValue = addComma(_sectionModel.iadlBlack.elementValue); _sectionModel.iadlBlack = validate(_sectionModel.iadlBlack); checkValidForm()">
                  </div>
                </td>
              </tr>

              <tr>
                <th class="normal" scope="row">Native Hawaiian or Pacific Islander</th>
                <td>
                  <label for="totalIslander" class="sr-only">{{totalIslanderText}}</label>
                  <div [ngClass]="(_sectionModel.totalIslander.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalIslander.elementValue" [disabled]="!_sectionModel.totalIslander.isRequired"
                      type="text" class="form-control" id="totalIslander" (change)="unsavedChanges = true; _sectionModel.totalIslander.elementValue = addComma(_sectionModel.totalIslander.elementValue); _sectionModel.totalIslander = validate(_sectionModel.totalIslander); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpIslander" class="sr-only">{{bpIslanderText}}</label>
                  <div [ngClass]="(_sectionModel.bpIslander.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpIslander.elementValue" [disabled]="!_sectionModel.bpIslander.isRequired"
                      type="text" class="form-control" id="bpIslander" (change)="unsavedChanges = true; _sectionModel.bpIslander.elementValue = addComma(_sectionModel.bpIslander.elementValue); _sectionModel.bpIslander = validate(_sectionModel.bpIslander); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlIslander" class="sr-only">{{adlIslanderText}}</label>
                  <div [ngClass]="(_sectionModel.adlIslander.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlIslander.elementValue" [disabled]="!_sectionModel.adlIslander.isRequired"
                      type="text" class="form-control" id="adlIslander" (change)="unsavedChanges = true; _sectionModel.adlIslander.elementValue = addComma(_sectionModel.adlIslander.elementValue); _sectionModel.adlIslander = validate(_sectionModel.adlIslander); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlIslander" class="sr-only">{{iadlIslanderText}}</label>
                  <div [ngClass]="(_sectionModel.iadlIslander.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlIslander.elementValue" [disabled]="!_sectionModel.iadlIslander.isRequired"
                      type="text" class="form-control" id="iadlIslander" (change)="unsavedChanges = true; _sectionModel.iadlIslander.elementValue = addComma(_sectionModel.iadlIslander.elementValue); _sectionModel.iadlIslander = validate(_sectionModel.iadlIslander); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">White</th>
                <td>
                  <label for="totalWhite" class="sr-only">{{totalWhiteText}}</label>
                  <div [ngClass]="(_sectionModel.totalWhite.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalWhite.elementValue" [disabled]="!_sectionModel.totalWhite.isRequired"
                      type="text" class="form-control" id="totalWhite" (change)="unsavedChanges = true; _sectionModel.totalWhite.elementValue = addComma(_sectionModel.totalWhite.elementValue); _sectionModel.totalWhite = validate(_sectionModel.totalWhite); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpWhite" class="sr-only">{{bpWhiteText}}</label>
                  <div [ngClass]="(_sectionModel.bpWhite.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpWhite.elementValue" [disabled]="!_sectionModel.bpWhite.isRequired"
                      type="text" class="form-control" id="bpWhite" (change)="unsavedChanges = true; _sectionModel.bpWhite.elementValue = addComma(_sectionModel.bpWhite.elementValue); _sectionModel.bpWhite = validate(_sectionModel.bpWhite); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlWhite" class="sr-only">{{adlWhiteText}}</label>
                  <div [ngClass]="(_sectionModel.adlWhite.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlWhite.elementValue" [disabled]="!_sectionModel.adlWhite.isRequired"
                      type="text" class="form-control" id="adlWhite" (change)="unsavedChanges = true; _sectionModel.adlWhite.elementValue = addComma(_sectionModel.adlWhite.elementValue); _sectionModel.adlWhite = validate(_sectionModel.adlWhite); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlWhite" class="sr-only">{{iadlWhiteText}}</label>
                  <div [ngClass]="(_sectionModel.iadlWhite.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlWhite.elementValue" [disabled]="!_sectionModel.iadlWhite.isRequired"
                      type="text" class="form-control" id="iadlWhite" (change)="unsavedChanges = true; _sectionModel.iadlWhite.elementValue = addComma(_sectionModel.iadlWhite.elementValue); _sectionModel.iadlWhite = validate(_sectionModel.iadlWhite); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Persons reporting some other race</th>
                <td>
                  <label for="totalRaceOther" class="sr-only">{{totalRaceOtherText}}</label>
                  <div [ngClass]="(_sectionModel.totalRaceOther.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalRaceOther.elementValue" [disabled]="!_sectionModel.totalRaceOther.isRequired"
                      type="text" class="form-control" id="totalRaceOther" (change)="unsavedChanges = true; _sectionModel.totalRaceOther.elementValue = addComma(_sectionModel.totalRaceOther.elementValue); _sectionModel.totalRaceOther = validate(_sectionModel.totalRaceOther); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpRaceOther" class="sr-only">{{bpRaceOtherText}}</label>
                  <div [ngClass]="(_sectionModel.bpRaceOther.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpRaceOther.elementValue" [disabled]="!_sectionModel.bpRaceOther.isRequired"
                      type="text" class="form-control" id="bpRaceOther" (change)="unsavedChanges = true; _sectionModel.bpRaceOther.elementValue = addComma(_sectionModel.bpRaceOther.elementValue); _sectionModel.bpRaceOther = validate(_sectionModel.bpRaceOther); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlRaceOther" class="sr-only">{{adlRaceOtherText}}</label>
                  <div [ngClass]="(_sectionModel.adlRaceOther.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlRaceOther.elementValue" [disabled]="!_sectionModel.adlRaceOther.isRequired"
                      type="text" class="form-control" id="adlRaceOther" (change)="unsavedChanges = true; _sectionModel.adlRaceOther.elementValue = addComma(_sectionModel.adlRaceOther.elementValue); _sectionModel.adlRaceOther = validate(_sectionModel.adlRaceOther); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlRaceOther" class="sr-only">{{iadlRaceOtherText}}</label>
                  <div [ngClass]="(_sectionModel.iadlRaceOther.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlRaceOther.elementValue" [disabled]="!_sectionModel.iadlRaceOther.isRequired"
                      type="text" class="form-control" id="iadlRaceOther" (change)="unsavedChanges = true; _sectionModel.iadlRaceOther.elementValue = addComma(_sectionModel.iadlRaceOther.elementValue); _sectionModel.iadlRaceOther = validate(_sectionModel.iadlRaceOther); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Persons reporting 2 or more races (unduplicated count)</th>
                <td>
                  <label for="totalRaceMultiple" class="sr-only">{{totalRaceMultipleText}}</label>
                  <div [ngClass]="(_sectionModel.totalRaceMultiple.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalRaceMultiple.elementValue" [disabled]="!_sectionModel.totalRaceMultiple.isRequired"
                      type="text" class="form-control" id="totalRaceMultiple" (change)="unsavedChanges = true; _sectionModel.totalRaceMultiple.elementValue = addComma(_sectionModel.totalRaceMultiple.elementValue); _sectionModel.totalRaceMultiple = validate(_sectionModel.totalRaceMultiple); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpRaceMultiple" class="sr-only">{{bpRaceMultipleText}}</label>
                  <div [ngClass]="(_sectionModel.bpRaceMultiple.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpRaceMultiple.elementValue" [disabled]="!_sectionModel.bpRaceMultiple.isRequired"
                      type="text" class="form-control" id="bpRaceMultiple" (change)="unsavedChanges = true; _sectionModel.bpRaceMultiple.elementValue = addComma(_sectionModel.bpRaceMultiple.elementValue); _sectionModel.bpRaceMultiple = validate(_sectionModel.bpRaceMultiple); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlRaceMultiple" class="sr-only">{{adlRaceMultipleText}}</label>
                  <div [ngClass]="(_sectionModel.adlRaceMultiple.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlRaceMultiple.elementValue" [disabled]="!_sectionModel.adlRaceMultiple.isRequired"
                      type="text" class="form-control" id="adlRaceMultiple" (change)="unsavedChanges = true; _sectionModel.adlRaceMultiple.elementValue = addComma(_sectionModel.adlRaceMultiple.elementValue); _sectionModel.adlRaceMultiple = validate(_sectionModel.adlRaceMultiple); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlRaceMultiple" class="sr-only">{{iadlRaceMultipleText}}</label>
                  <div [ngClass]="(_sectionModel.iadlRaceMultiple.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlRaceMultiple.elementValue" [disabled]="!_sectionModel.iadlRaceMultiple.isRequired"
                      type="text" class="form-control" id="iadlRaceMultiple" (change)="unsavedChanges = true; _sectionModel.iadlRaceMultiple.elementValue = addComma(_sectionModel.iadlRaceMultiple.elementValue); _sectionModel.iadlRaceMultiple = validate(_sectionModel.iadlRaceMultiple); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Race missing</th>
                <td>
                  <label for="totalRaceMissing" class="sr-only">{{totalRaceMissingText}}</label>
                  <div [ngClass]="(_sectionModel.totalRaceMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalRaceMissing.elementValue" [disabled]="!_sectionModel.totalRaceMissing.isRequired"
                      type="text" class="form-control" id="totalRaceMissing" (change)="unsavedChanges = true; _sectionModel.totalRaceMissing.elementValue = addComma(_sectionModel.totalRaceMissing.elementValue); _sectionModel.totalRaceMissing = validate(_sectionModel.totalRaceMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpRaceMissing" class="sr-only">{{bpRaceMissingText}}</label>
                  <div [ngClass]="(_sectionModel.bpRaceMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpRaceMissing.elementValue" [disabled]="!_sectionModel.bpRaceMissing.isRequired"
                      type="text" class="form-control" id="bpRaceMissing" (change)="unsavedChanges = true; _sectionModel.bpRaceMissing.elementValue = addComma(_sectionModel.bpRaceMissing.elementValue); _sectionModel.bpRaceMissing = validate(_sectionModel.bpRaceMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlRaceMissing" class="sr-only">{{adlRaceMissingText}}</label>
                  <div [ngClass]="(_sectionModel.adlRaceMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlRaceMissing.elementValue" [disabled]="!_sectionModel.adlRaceMissing.isRequired"
                      type="text" class="form-control" id="adlRaceMissing" (change)="unsavedChanges = true; _sectionModel.adlRaceMissing.elementValue = addComma(_sectionModel.adlRaceMissing.elementValue); _sectionModel.adlRaceMissing = validate(_sectionModel.adlRaceMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlRaceMissing" class="sr-only">{{iadlRaceMissingText}}</label>
                  <div [ngClass]="(_sectionModel.iadlRaceMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlRaceMissing.elementValue" [disabled]="!_sectionModel.iadlRaceMissing.isRequired"
                      type="text" class="form-control" id="iadlRaceMissing" (change)="unsavedChanges = true; _sectionModel.iadlRaceMissing.elementValue = addComma(_sectionModel.iadlRaceMissing.elementValue); _sectionModel.iadlRaceMissing = validate(_sectionModel.iadlRaceMissing); checkValidForm()">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  end table responsive  -->
        <div class="pull-left table-responsive">
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>Minority Status Distribution</h2>
          </div>
          <table class="table table-no-border">
            <tbody>
              <tr *ngIf="minorityWarning()">
                <td class="has-warning" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                  <p *ngIf="!_sectionModel.totalMinority.dataEntryErrorMessage && _sectionModel.totalMinority.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalMinorityText}}: {{_sectionModel.totalMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpMinority.dataEntryErrorMessage && _sectionModel.bpMinority.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpMinorityText}}: {{_sectionModel.bpMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlMinority.dataEntryErrorMessage && _sectionModel.adlMinority.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlMinorityText}}: {{_sectionModel.adlMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlMinority.dataEntryErrorMessage && _sectionModel.iadlMinority.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlMinorityText}}: {{_sectionModel.iadlMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalNotMinority.dataEntryErrorMessage && _sectionModel.totalNotMinority.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalNotMinorityText}}:
                    {{_sectionModel.totalNotMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpNotMinority.dataEntryErrorMessage && _sectionModel.bpNotMinority.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpNotMinorityText}}: {{_sectionModel.bpNotMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlNotMinority.dataEntryErrorMessage && _sectionModel.adlNotMinority.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlNotMinorityText}}: {{_sectionModel.adlNotMinority.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlNotMinority.dataEntryErrorMessage && _sectionModel.iadlNotMinority.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlNotMinorityText}}: {{_sectionModel.iadlNotMinority.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalMinorityMissing.dataEntryErrorMessage && _sectionModel.totalMinorityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalMinorityMissingText}}:
                    {{_sectionModel.totalMinorityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpMinorityMissing.dataEntryErrorMessage && _sectionModel.bpMinorityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpMinorityMissingText}}:
                    {{_sectionModel.bpMinorityMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlMinorityMissing.dataEntryErrorMessage && _sectionModel.adlMinorityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlMinorityMissingText}}:
                    {{_sectionModel.adlMinorityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlMinorityMissing.dataEntryErrorMessage && _sectionModel.iadlMinorityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlMinorityMissingText}}:
                    {{_sectionModel.iadlMinorityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allMinorityTotal.dataEntryErrorMessage && _sectionModel.allMinorityTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{allMinorityTotalText}}:
                    {{_sectionModel.allMinorityTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpMinorityTotal.dataEntryErrorMessage && _sectionModel.bpMinorityTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpMinorityTotalText}}: {{_sectionModel.bpMinorityTotal.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlMinorityTotal.dataEntryErrorMessage && _sectionModel.adlMinorityTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlMinorityTotalText}}:
                    {{_sectionModel.adlMinorityTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlMinorityTotal.dataEntryErrorMessage && _sectionModel.iadlMinorityTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlMinorityTotalText}}:
                    {{_sectionModel.iadlMinorityTotal.businessErrorMessage}} </p>
                </td>
              </tr>
              <tr *ngIf="minorityError()">
                <td class="has-error" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                  <p *ngIf="_sectionModel.totalMinority.dataEntryErrorMessage" class="help-block">{{totalMinorityText}}:
                    {{_sectionModel.totalMinority.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpMinority.dataEntryErrorMessage" class="help-block">{{bpMinorityText}}:
                    {{_sectionModel.bpMinority.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlMinority.dataEntryErrorMessage" class="help-block">{{adlMinorityText}}:
                    {{_sectionModel.adlMinority.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlMinority.dataEntryErrorMessage" class="help-block">{{iadlMinorityText}}:
                    {{_sectionModel.iadlMinority.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalNotMinority.dataEntryErrorMessage" class="help-block">{{totalNotMinorityText}}:
                    {{_sectionModel.totalNotMinority.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpNotMinority.dataEntryErrorMessage" class="help-block">{{bpNotMinorityText}}:
                    {{_sectionModel.bpNotMinority.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlNotMinority.dataEntryErrorMessage" class="help-block">{{adlNotMinorityText}}:
                    {{_sectionModel.adlNotMinority.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlNotMinority.dataEntryErrorMessage" class="help-block">{{iadlNotMinorityText}}:
                    {{_sectionModel.iadlNotMinority.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalMinorityMissing.dataEntryErrorMessage" class="help-block">{{totalMinorityMissingText}}:
                    {{_sectionModel.totalMinorityMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpMinorityMissing.dataEntryErrorMessage" class="help-block">{{bpMinorityMissingText}}:
                    {{_sectionModel.bpMinorityMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.adlMinorityMissing.dataEntryErrorMessage" class="help-block">{{adlMinorityMissingText}}:
                    {{_sectionModel.adlMinorityMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.iadlMinorityMissing.dataEntryErrorMessage" class="help-block">{{iadlMinorityMissingText}}:
                    {{_sectionModel.iadlMinorityMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalMinority.dataEntryErrorMessage && _sectionModel.totalMinority.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalMinorityText}}: {{_sectionModel.totalMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpMinority.dataEntryErrorMessage && _sectionModel.bpMinority.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpMinorityText}}: {{_sectionModel.bpMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlMinority.dataEntryErrorMessage && _sectionModel.adlMinority.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlMinorityText}}: {{_sectionModel.adlMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlMinority.dataEntryErrorMessage && _sectionModel.iadlMinority.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlMinorityText}}: {{_sectionModel.iadlMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalNotMinority.dataEntryErrorMessage && _sectionModel.totalNotMinority.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalNotMinorityText}}:
                    {{_sectionModel.totalNotMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpNotMinority.dataEntryErrorMessage && _sectionModel.bpNotMinority.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpNotMinorityText}}: {{_sectionModel.bpNotMinority.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlNotMinority.dataEntryErrorMessage && _sectionModel.adlNotMinority.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlNotMinorityText}}: {{_sectionModel.adlNotMinority.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlNotMinority.dataEntryErrorMessage && _sectionModel.iadlNotMinority.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlNotMinorityText}}: {{_sectionModel.iadlNotMinority.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalMinorityMissing.dataEntryErrorMessage && _sectionModel.totalMinorityMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalMinorityMissingText}}:
                    {{_sectionModel.totalMinorityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpMinorityMissing.dataEntryErrorMessage && _sectionModel.bpMinorityMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpMinorityMissingText}}:
                    {{_sectionModel.bpMinorityMissing.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlMinorityMissing.dataEntryErrorMessage && _sectionModel.adlMinorityMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlMinorityMissingText}}:
                    {{_sectionModel.adlMinorityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlMinorityMissing.dataEntryErrorMessage && _sectionModel.iadlMinorityMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlMinorityMissingText}}:
                    {{_sectionModel.iadlMinorityMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allMinorityTotal.dataEntryErrorMessage && _sectionModel.allMinorityTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{allMinorityTotalText}}:
                    {{_sectionModel.allMinorityTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpMinorityTotal.dataEntryErrorMessage && _sectionModel.bpMinorityTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpMinorityTotalText}}: {{_sectionModel.bpMinorityTotal.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlMinorityTotal.dataEntryErrorMessage && _sectionModel.adlMinorityTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlMinorityTotalText}}:
                    {{_sectionModel.adlMinorityTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlMinorityTotal.dataEntryErrorMessage && _sectionModel.iadlMinorityTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlMinorityTotalText}}:
                    {{_sectionModel.iadlMinorityTotal.businessErrorMessage}} </p>
                </td>
              </tr>
              <tr>
                <td width="20%"></td>
                <th width="20%" scope="col">Total</th>
                <th width="20%" scope="col">At or Below Poverty</th>
                <th width="20%" scope="col">ADL 3+</th>
                <th width="20%" scope="col">IADL 3+</th>
              </tr>
              <tr>
                <th class="normal" scope="row">Minority</th>
                <td>
                  <label for="totalMinority" class="sr-only">{{totalMinorityText}}</label>
                  <div [ngClass]="(_sectionModel.totalMinority.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalMinority.elementValue" [disabled]="!_sectionModel.totalMinority.isRequired"
                      type="text" class="form-control" id="totalMinority" (change)="unsavedChanges = true; _sectionModel.allMinorityTotal.elementValue = updateTotal(allMinorityTotalArr); _sectionModel.totalMinority = validate(_sectionModel.totalMinority); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpMinority" class="sr-only">{{bpMinorityText}}</label>
                  <div [ngClass]="(_sectionModel.bpMinority.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpMinority.elementValue" [disabled]="!_sectionModel.bpMinority.isRequired"
                      type="text" class="form-control" id="bpMinority" (change)="unsavedChanges = true; _sectionModel.bpMinorityTotal.elementValue = updateTotal(bpMinorityTotalArr); _sectionModel.bpMinority = validate(_sectionModel.bpMinority); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlMinority" class="sr-only">{{adlMinorityText}}</label>
                  <div [ngClass]="(_sectionModel.adlMinority.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlMinority.elementValue" [disabled]="!_sectionModel.adlMinority.isRequired"
                      type="text" class="form-control" id="adlMinority" (change)="unsavedChanges = true; _sectionModel.adlMinorityTotal.elementValue = updateTotal(adlMinorityTotalArr); _sectionModel.adlMinority = validate(_sectionModel.adlMinority); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlMinority" class="sr-only">{{iadlMinorityText}}</label>
                  <div [ngClass]="(_sectionModel.iadlMinority.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlMinority.elementValue" [disabled]="!_sectionModel.iadlMinority.isRequired"
                      type="text" class="form-control" id="iadlMinority" (change)="unsavedChanges = true; _sectionModel.iadlMinorityTotal.elementValue = updateTotal(iadlMinorityTotalArr); _sectionModel.iadlMinority = validate(_sectionModel.iadlMinority); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Not minority</th>
                <td>
                  <label for="totalNotMinority" class="sr-only">{{totalNotMinorityText}}</label>
                  <div [ngClass]="(_sectionModel.totalNotMinority.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalNotMinority.elementValue" [disabled]="!_sectionModel.totalNotMinority.isRequired"
                      type="text" class="form-control" id="totalNotMinority" (change)="unsavedChanges = true; _sectionModel.allMinorityTotal.elementValue = updateTotal(allMinorityTotalArr); _sectionModel.totalNotMinority = validate(_sectionModel.totalNotMinority); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpNotMinority" class="sr-only">{{bpNotMinorityText}}</label>
                  <div [ngClass]="(_sectionModel.bpNotMinority.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpNotMinority.elementValue" [disabled]="!_sectionModel.bpNotMinority.isRequired"
                      type="text" class="form-control" id="bpNotMinority" (change)="unsavedChanges = true; _sectionModel.bpMinorityTotal.elementValue = updateTotal(bpMinorityTotalArr); _sectionModel.bpNotMinority = validate(_sectionModel.bpNotMinority); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlNotMinority" class="sr-only">{{adlNotMinorityText}}</label>
                  <div [ngClass]="(_sectionModel.adlNotMinority.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlNotMinority.elementValue" [disabled]="!_sectionModel.adlNotMinority.isRequired"
                      type="text" class="form-control" id="adlNotMinority" (change)="unsavedChanges = true; _sectionModel.adlMinorityTotal.elementValue = updateTotal(adlMinorityTotalArr); _sectionModel.adlNotMinority = validate(_sectionModel.adlNotMinority); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlNotMinority" class="sr-only">{{iadlNotMinorityText}}</label>
                  <div [ngClass]="(_sectionModel.iadlNotMinority.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlNotMinority.elementValue" [disabled]="!_sectionModel.iadlNotMinority.isRequired"
                      type="text" class="form-control" id="iadlNotMinority" (change)="unsavedChanges = true; _sectionModel.iadlMinorityTotal.elementValue = updateTotal(iadlMinorityTotalArr); _sectionModel.iadlNotMinority = validate(_sectionModel.iadlNotMinority); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Minority status missing</th>
                <td>
                  <label for="totalMinorityMissing" class="sr-only">{{totalMinorityMissingText}}</label>
                  <div [ngClass]="(_sectionModel.totalMinorityMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalMinorityMissing.elementValue" [disabled]="!_sectionModel.totalMinorityMissing.isRequired"
                      type="text" class="form-control" id="totalMinorityMissing" (change)="unsavedChanges = true; _sectionModel.allMinorityTotal.elementValue = updateTotal(allMinorityTotalArr); _sectionModel.totalMinorityMissing = validate(_sectionModel.totalMinorityMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpMinorityMissing" class="sr-only">{{bpMinorityMissingText}}</label>
                  <div [ngClass]="(_sectionModel.bpMinorityMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpMinorityMissing.elementValue" [disabled]="!_sectionModel.bpMinorityMissing.isRequired"
                      type="text" class="form-control" id="bpMinorityMissing" (change)="unsavedChanges = true; _sectionModel.bpMinorityTotal.elementValue = updateTotal(bpMinorityTotalArr); _sectionModel.bpMinorityMissing = validate(_sectionModel.bpMinorityMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlMinorityMissing" class="sr-only">{{adlMinorityMissingText}}</label>
                  <div [ngClass]="(_sectionModel.adlMinorityMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlMinorityMissing.elementValue" [disabled]="!_sectionModel.adlMinorityMissing.isRequired"
                      type="text" class="form-control" id="adlMinorityMissing" (change)="unsavedChanges = true; _sectionModel.adlMinorityTotal.elementValue = updateTotal(adlMinorityTotalArr); _sectionModel.adlMinorityMissing = validate(_sectionModel.adlMinorityMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlMinorityMissing" class="sr-only">{{iadlMinorityMissingText}}</label>
                  <div [ngClass]="(_sectionModel.iadlMinorityMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlMinorityMissing.elementValue" [disabled]="!_sectionModel.iadlMinorityMissing.isRequired"
                      type="text" class="form-control" id="iadlMinorityMissing" (change)="unsavedChanges = true; _sectionModel.iadlMinorityTotal.elementValue = updateTotal(iadlMinorityTotalArr); _sectionModel.iadlMinorityMissing = validate(_sectionModel.iadlMinorityMissing); checkValidForm()">
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Total Persons Served</th>
                <td>
                  <label for="allMinorityTotal" class="sr-only">{{allMinorityTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.allMinorityTotal.elementValue" [disabled]="!_sectionModel.allMinorityTotal.isRequired"
                    type="text" class="form-control" id="allMinorityTotal" readonly>
                </td>
                <td>
                  <label for="bpMinorityTotal" class="sr-only">{{bpMinorityTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.bpMinorityTotal.elementValue" [disabled]="!_sectionModel.bpMinorityTotal.isRequired"
                    type="text" class="form-control" id="bpMinorityTotal" readonly>
                </td>
                <td>
                  <label for="adlMinorityTotal" class="sr-only">{{adlMinorityTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.adlMinorityTotal.elementValue" [disabled]="!_sectionModel.adlMinorityTotal.isRequired"
                    type="text" class="form-control" id="adlMinorityTotal" readonly>
                </td>
                <td>
                  <label for="iadlMinorityTotal" class="sr-only">{{iadlMinorityTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.iadlMinorityTotal.elementValue" [disabled]="!_sectionModel.iadlMinorityTotal.isRequired"
                    type="text" class="form-control" id="iadlMinorityTotal" readonly>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  end table responsive  -->
        <div class="pull-left table-responsive">
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>ADL Limitations Score Distribution</h2>
          </div>
          <table class="table table-no-border">
            <tbody>
              <tr *ngIf="adlLimitWarning()">
                <td class="has-warning" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                  <p *ngIf="!_sectionModel.totalZeroLimitAdl.dataEntryErrorMessage && _sectionModel.totalZeroLimitAdl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalZeroLimitAdlText}}:
                    {{_sectionModel.totalZeroLimitAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpZeroAdl.dataEntryErrorMessage && _sectionModel.bpZeroAdl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpZeroAdlText}}: {{_sectionModel.bpZeroAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalTwoLimitAdl.dataEntryErrorMessage && _sectionModel.totalTwoLimitAdl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalTwoLimitAdlText}}:
                    {{_sectionModel.totalTwoLimitAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpTwoLimitAdl.dataEntryErrorMessage && _sectionModel.bpTwoLimitAdl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpTwoLimitAdlText}}: {{_sectionModel.bpTwoLimitAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalHigherLimitAdl.dataEntryErrorMessage && _sectionModel.totalHigherLimitAdl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalHigherLimitAdlText}}:
                    {{_sectionModel.totalHigherLimitAdl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpHigherLimitAdl.dataEntryErrorMessage && _sectionModel.bpHigherLimitAdl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpHigherLimitAdlText}}:
                    {{_sectionModel.bpHigherLimitAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalLimitMissingAdl.dataEntryErrorMessage && _sectionModel.totalLimitMissingAdl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalLimitMissingAdlText}}:
                    {{_sectionModel.totalLimitMissingAdl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpLimitMissingAdl.dataEntryErrorMessage && _sectionModel.bpLimitMissingAdl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpLimitMissingAdlText}}:
                    {{_sectionModel.bpLimitMissingAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.allLimitTotalAdl.dataEntryErrorMessage && _sectionModel.allLimitTotalAdl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{allLimitTotalAdlText}}:
                    {{_sectionModel.allLimitTotalAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpLimitTotalAdl.dataEntryErrorMessage && _sectionModel.bpLimitTotalAdl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpLimitTotalAdlText}}: {{_sectionModel.bpLimitTotalAdl.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr *ngIf="adlLimitError()">
                <td class="has-error" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                  <p *ngIf="_sectionModel.totalZeroLimitAdl.dataEntryErrorMessage" class="help-block">{{totalZeroLimitAdlText}}:
                    {{_sectionModel.totalZeroLimitAdl.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpZeroAdl.dataEntryErrorMessage" class="help-block">{{bpZeroAdlText}}:
                    {{_sectionModel.bpZeroAdl.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalTwoLimitAdl.dataEntryErrorMessage" class="help-block">{{totalTwoLimitAdlText}}:
                    {{_sectionModel.totalTwoLimitAdl.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.bpTwoLimitAdl.dataEntryErrorMessage" class="help-block">{{bpTwoLimitAdlText}}:
                    {{_sectionModel.bpTwoLimitAdl.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalHigherLimitAdl.dataEntryErrorMessage" class="help-block">{{totalHigherLimitAdlText}}:
                    {{_sectionModel.totalHigherLimitAdl.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpHigherLimitAdl.dataEntryErrorMessage" class="help-block">{{bpHigherLimitAdlText}}:
                    {{_sectionModel.bpHigherLimitAdl.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalLimitMissingAdl.dataEntryErrorMessage" class="help-block">{{totalLimitMissingAdlText}}:
                    {{_sectionModel.totalLimitMissingAdl.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpLimitMissingAdl.dataEntryErrorMessage" class="help-block">{{bpLimitMissingAdlText}}:
                    {{_sectionModel.bpLimitMissingAdl.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalZeroLimitAdl.dataEntryErrorMessage && _sectionModel.totalZeroLimitAdl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalZeroLimitAdlText}}:
                    {{_sectionModel.totalZeroLimitAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpZeroAdl.dataEntryErrorMessage && _sectionModel.bpZeroAdl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpZeroAdlText}}: {{_sectionModel.bpZeroAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalTwoLimitAdl.dataEntryErrorMessage && _sectionModel.totalTwoLimitAdl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalTwoLimitAdlText}}:
                    {{_sectionModel.totalTwoLimitAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpTwoLimitAdl.dataEntryErrorMessage && _sectionModel.bpTwoLimitAdl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpTwoLimitAdlText}}: {{_sectionModel.bpTwoLimitAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalHigherLimitAdl.dataEntryErrorMessage && _sectionModel.totalHigherLimitAdl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalHigherLimitAdlText}}:
                    {{_sectionModel.totalHigherLimitAdl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpHigherLimitAdl.dataEntryErrorMessage && _sectionModel.bpHigherLimitAdl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpHigherLimitAdlText}}:
                    {{_sectionModel.bpHigherLimitAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalLimitMissingAdl.dataEntryErrorMessage && _sectionModel.totalLimitMissingAdl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalLimitMissingAdlText}}:
                    {{_sectionModel.totalLimitMissingAdl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpLimitMissingAdl.dataEntryErrorMessage && _sectionModel.bpLimitMissingAdl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpLimitMissingAdlText}}:
                    {{_sectionModel.bpLimitMissingAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.allLimitTotalAdl.dataEntryErrorMessage && _sectionModel.allLimitTotalAdl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{allLimitTotalAdlText}}:
                    {{_sectionModel.allLimitTotalAdl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpLimitTotalAdl.dataEntryErrorMessage && _sectionModel.bpLimitTotalAdl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpLimitTotalAdlText}}: {{_sectionModel.bpLimitTotalAdl.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr>
                <td width="20%"></td>
                <th width="20%" scope="col">Total</th>
                <th width="20%" scope="col">At or Below Poverty</th>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>
              <tr>
                <th class="normal" scope="row">0 - 1</th>
                <td>
                  <label for="totalZeroLimitAdl" class="sr-only">{{totalZeroLimitAdlText}}</label>
                  <div [ngClass]="(_sectionModel.totalZeroLimitAdl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalZeroLimitAdl.elementValue" [disabled]="!_sectionModel.totalZeroLimitAdl.isRequired"
                      type="text" class="form-control" id="totalZeroLimitAdl" (change)="unsavedChanges = true; _sectionModel.allLimitTotalAdl.elementValue = updateTotal(allLimitTotalAdlArr); _sectionModel.totalZeroLimitAdl = validate(_sectionModel.totalZeroLimitAdl); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpZeroAdl" class="sr-only">{{bpZeroAdlText}}</label>
                  <div [ngClass]="(_sectionModel.bpZeroAdl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpZeroAdl.elementValue" [disabled]="!_sectionModel.bpZeroAdl.isRequired"
                      type="text" class="form-control" id="bpZeroAdl" (change)="unsavedChanges = true; _sectionModel.bpLimitTotalAdl.elementValue = updateTotal(bpLimitTotalAdlArr); _sectionModel.bpZeroAdl = validate(_sectionModel.bpZeroAdl); checkValidForm()">
                  </div>
                </td>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>
              <tr>
                <th class="normal" scope="row">2</th>
                <td>
                  <label for="totalTwoLimitAdl" class="sr-only">{{totalTwoLimitAdlText}}</label>
                  <div [ngClass]="(_sectionModel.totalTwoLimitAdl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalTwoLimitAdl.elementValue" [disabled]="!_sectionModel.totalTwoLimitAdl.isRequired"
                      type="text" class="form-control" id="totalTwoLimitAdl" (change)="unsavedChanges = true; _sectionModel.allLimitTotalAdl.elementValue = updateTotal(allLimitTotalAdlArr); _sectionModel.totalTwoLimitAdl = validate(_sectionModel.totalTwoLimitAdl); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpTwoLimitAdl" class="sr-only">{{bpTwoLimitAdlText}}</label>
                  <div [ngClass]="(_sectionModel.bpTwoLimitAdl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpTwoLimitAdl.elementValue" [disabled]="!_sectionModel.bpTwoLimitAdl.isRequired"
                      type="text" class="form-control" id="bpTwoLimitAdl" (change)="unsavedChanges = true; _sectionModel.bpLimitTotalAdl.elementValue = updateTotal(bpLimitTotalAdlArr); _sectionModel.bpTwoLimitAdl = validate(_sectionModel.bpTwoLimitAdl); checkValidForm()">
                  </div>
                </td>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>

              <tr>
                <th class="normal" scope="row">3+</th>
                <td>
                  <label for="totalHigherLimitAdl" class="sr-only">{{totalHigherLimitAdlText}}</label>
                  <div [ngClass]="(_sectionModel.totalHigherLimitAdl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalHigherLimitAdl.elementValue" [disabled]="!_sectionModel.totalHigherLimitAdl.isRequired"
                      type="text" class="form-control" id="totalHigherLimitAdl" (change)="unsavedChanges = true; _sectionModel.allLimitTotalAdl.elementValue = updateTotal(allLimitTotalAdlArr); _sectionModel.totalHigherLimitAdl = validate(_sectionModel.totalHigherLimitAdl); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpHigherLimitAdl" class="sr-only">{{bpHigherLimitAdlText}}</label>
                  <div [ngClass]="(_sectionModel.bpHigherLimitAdl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpHigherLimitAdl.elementValue" [disabled]="true" type="text"
                      class="form-control" id="bpHigherLimitAdl" (change)="unsavedChanges = true; _sectionModel.bpLimitTotalAdl.elementValue = updateTotal(bpLimitTotalAdlArr); _sectionModel.bpHigherLimitAdl = validate(_sectionModel.bpHigherLimitAdl); checkValidForm()">
                  </div>
                </td>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>
              <tr>
                <th class="normal" scope="row">ADL limitations missing</th>
                <td>
                  <label for="totalLimitMissingAdl" class="sr-only">{{totalLimitMissingAdlText}}</label>
                  <div [ngClass]="(_sectionModel.totalLimitMissingAdl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalLimitMissingAdl.elementValue" [disabled]="!_sectionModel.totalLimitMissingAdl.isRequired"
                      type="text" class="form-control" id="totalLimitMissingAdl" (change)="unsavedChanges = true; _sectionModel.allLimitTotalAdl.elementValue = updateTotal(allLimitTotalAdlArr); _sectionModel.totalLimitMissingAdl = validate(_sectionModel.totalLimitMissingAdl); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpLimitMissingAdl" class="sr-only">{{bpLimitMissingAdlText}}</label>
                  <div [ngClass]="(_sectionModel.bpLimitMissingAdl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpLimitMissingAdl.elementValue" [disabled]="!_sectionModel.bpLimitMissingAdl.isRequired"
                      type="text" class="form-control" id="bpLimitMissingAdl" (change)="unsavedChanges = true; _sectionModel.bpLimitTotalAdl.elementValue = updateTotal(bpLimitTotalAdlArr); _sectionModel.bpLimitMissingAdl = validate(_sectionModel.bpLimitMissingAdl); checkValidForm()">
                  </div>
                </td>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>

              <tr>
                <th scope="row">Total Persons Served</th>
                <td>
                  <label for="allLimitTotalAdl" class="sr-only">{{allLimitTotalAdlText}}</label>
                  <input [(ngModel)]="_sectionModel.allLimitTotalAdl.elementValue" [disabled]="!_sectionModel.allLimitTotalAdl.isRequired"
                    type="text" class="form-control" id="allLimitTotalAdl" readonly>
                </td>
                <td>
                  <label for="bpLimitTotalAdl" class="sr-only">{{bpLimitTotalAdlText}}</label>
                  <input [(ngModel)]="_sectionModel.bpLimitTotalAdl.elementValue" [disabled]="!_sectionModel.bpLimitTotalAdl.isRequired"
                    type="text" class="form-control" id="bpLimitTotalAdl" readonly>
                </td>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  end table responsive  -->
        <div class="pull-left table-responsive">
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>IADL Limitations Score Distribution</h2>
          </div>
          <table class="table table-no-border">
            <tbody>
              <tr *ngIf="iadlLimitWarning()">
                <td class="has-warning" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                  <p *ngIf="!_sectionModel.totalZeroLimitIadl.dataEntryErrorMessage && _sectionModel.totalZeroLimitIadl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalZeroLimitIadlText}}:
                    {{_sectionModel.totalZeroLimitIadl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpZeroIadl.dataEntryErrorMessage && _sectionModel.bpZeroIadl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpZeroIadlText}}: {{_sectionModel.bpZeroIadl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalTwoLimitIadl.dataEntryErrorMessage && _sectionModel.totalTwoLimitIadl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalTwoLimitIadlText}}:
                    {{_sectionModel.totalTwoLimitIadl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpTwoLimitIadl.dataEntryErrorMessage && _sectionModel.bpTwoLimitIadl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpTwoLimitIadlText}}: {{_sectionModel.bpTwoLimitIadl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalHigherLimitIadl.dataEntryErrorMessage && _sectionModel.totalHigherLimitIadl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalHigherLimitIadlText}}:
                    {{_sectionModel.totalHigherLimitIadl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpHigherLimitIadl.dataEntryErrorMessage && _sectionModel.bpHigherLimitIadl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpHigherLimitIadlText}}:
                    {{_sectionModel.bpHigherLimitIadl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalLimitMissingIadl.dataEntryErrorMessage && _sectionModel.totalLimitMissingIadl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalLimitMissingIadlText}}:
                    {{_sectionModel.totalLimitMissingIadl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpLimitMissingIadl.dataEntryErrorMessage && _sectionModel.bpLimitMissingIadl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpLimitMissingIadlText}}:
                    {{_sectionModel.bpLimitMissingIadl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allLimitTotalIadl.dataEntryErrorMessage && _sectionModel.allLimitTotalIadl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{allLimitTotalIadlText}}:
                    {{_sectionModel.allLimitTotalIadl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpLimitTotalIadl.dataEntryErrorMessage && _sectionModel.bpLimitTotalIadl.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpLimitTotalIadlText}}:
                    {{_sectionModel.bpLimitTotalIadl.businessErrorMessage}} </p>
                </td>
              </tr>
              <tr *ngIf="iadlLimitError()">
                <td class="has-error" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                  <p *ngIf="_sectionModel.totalZeroLimitIadl.dataEntryErrorMessage" class="help-block">{{totalZeroLimitIadlText}}:
                    {{_sectionModel.totalZeroLimitIadl.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpZeroIadl.dataEntryErrorMessage" class="help-block">{{bpZeroIadlText}}:
                    {{_sectionModel.bpZeroIadl.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalTwoLimitIadl.dataEntryErrorMessage" class="help-block">{{totalTwoLimitIadlText}}:
                    {{_sectionModel.totalTwoLimitIadl.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpTwoLimitIadl.dataEntryErrorMessage" class="help-block">{{bpTwoLimitIadlText}}:
                    {{_sectionModel.bpTwoLimitIadl.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalHigherLimitIadl.dataEntryErrorMessage" class="help-block">{{totalHigherLimitIadlText}}:
                    {{_sectionModel.totalHigherLimitIadl.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpHigherLimitIadl.dataEntryErrorMessage" class="help-block">{{bpHigherLimitIadlText}}:
                    {{_sectionModel.bpHigherLimitIadl.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.totalLimitMissingIadl.dataEntryErrorMessage" class="help-block">{{totalLimitMissingIadlText}}:
                    {{_sectionModel.totalLimitMissingIadl.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpLimitMissingIadl.dataEntryErrorMessage" class="help-block">{{bpLimitMissingIadlText}}:
                    {{_sectionModel.bpLimitMissingIadl.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalZeroLimitIadl.dataEntryErrorMessage && _sectionModel.totalZeroLimitIadl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalZeroLimitIadlText}}:
                    {{_sectionModel.totalZeroLimitIadl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpZeroIadl.dataEntryErrorMessage && _sectionModel.bpZeroIadl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpZeroIadlText}}: {{_sectionModel.bpZeroIadl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalTwoLimitIadl.dataEntryErrorMessage && _sectionModel.totalTwoLimitIadl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalTwoLimitIadlText}}:
                    {{_sectionModel.totalTwoLimitIadl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpTwoLimitIadl.dataEntryErrorMessage && _sectionModel.bpTwoLimitIadl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpTwoLimitIadlText}}: {{_sectionModel.bpTwoLimitIadl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalHigherLimitIadl.dataEntryErrorMessage && _sectionModel.totalHigherLimitIadl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalHigherLimitIadlText}}:
                    {{_sectionModel.totalHigherLimitIadl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpHigherLimitIadl.dataEntryErrorMessage && _sectionModel.bpHigherLimitIadl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpHigherLimitIadlText}}:
                    {{_sectionModel.bpHigherLimitIadl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalLimitMissingIadl.dataEntryErrorMessage && _sectionModel.totalLimitMissingIadl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalLimitMissingIadlText}}:
                    {{_sectionModel.totalLimitMissingIadl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpLimitMissingIadl.dataEntryErrorMessage && _sectionModel.bpLimitMissingIadl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpLimitMissingIadlText}}:
                    {{_sectionModel.bpLimitMissingIadl.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allLimitTotalIadl.dataEntryErrorMessage && _sectionModel.allLimitTotalIadl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{allLimitTotalIadlText}}:
                    {{_sectionModel.allLimitTotalIadl.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpLimitTotalIadl.dataEntryErrorMessage && _sectionModel.bpLimitTotalIadl.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpLimitTotalIadlText}}:
                    {{_sectionModel.bpLimitTotalIadl.businessErrorMessage}} </p>
                </td>
              </tr>
              <tr>
                <td width="20%"></td>
                <th width="20%" scope="col">Total</th>
                <th width="20%" scope="col">At or Below Poverty</th>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>
              <tr>
                <th class="normal" scope="row">0 - 1</th>
                <td>
                  <label for="totalZeroLimitIadl" class="sr-only">{{totalZeroLimitIadlText}}</label>
                  <div [ngClass]="(_sectionModel.totalZeroLimitIadl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalZeroLimitIadl.elementValue" [disabled]="!_sectionModel.totalZeroLimitIadl.isRequired"
                      type="text" class="form-control" id="totalZeroLimitIadl" (change)="unsavedChanges = true; _sectionModel.allLimitTotalIadl.elementValue = updateTotal(allLimitTotalIadlArr); _sectionModel.totalZeroLimitIadl = validate(_sectionModel.totalZeroLimitIadl); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpZeroIadl" class="sr-only">{{bpZeroIadlText}}</label>
                  <div [ngClass]="(_sectionModel.bpZeroIadl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpZeroIadl.elementValue" [disabled]="!_sectionModel.bpZeroIadl.isRequired"
                      type="text" class="form-control" id="bpZeroIadl" (change)="unsavedChanges = true; _sectionModel.bpLimitTotalIadl.elementValue = updateTotal(bpLimitTotalIadlArr); _sectionModel.bpZeroIadl = validate(_sectionModel.bpZeroIadl); checkValidForm()">
                  </div>
                </td>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>
              <tr>
                <th class="normal" scope="row">2</th>
                <td>
                  <label for="totalTwoLimitIadl" class="sr-only">{{totalTwoLimitIadlText}}</label>
                  <div [ngClass]="(_sectionModel.totalTwoLimitIadl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalTwoLimitIadl.elementValue" [disabled]="!_sectionModel.totalTwoLimitIadl.isRequired"
                      type="text" class="form-control" id="totalTwoLimitIadl" (change)="unsavedChanges = true; _sectionModel.allLimitTotalIadl.elementValue = updateTotal(allLimitTotalIadlArr); _sectionModel.totalTwoLimitIadl = validate(_sectionModel.totalTwoLimitIadl); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpTwoLimitIadl" class="sr-only">{{bpTwoLimitIadlText}}</label>
                  <div [ngClass]="(_sectionModel.bpTwoLimitIadl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpTwoLimitIadl.elementValue" [disabled]="!_sectionModel.bpTwoLimitIadl.isRequired"
                      type="text" class="form-control" id="bpTwoLimitIadl" (change)="unsavedChanges = true; _sectionModel.bpLimitTotalIadl.elementValue = updateTotal(bpLimitTotalIadlArr); _sectionModel.bpTwoLimitIadl = validate(_sectionModel.bpTwoLimitIadl); checkValidForm()">
                  </div>
                </td>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>

              <tr>
                <th class="normal" scope="row">3+</th>
                <td>
                  <label for="totalHigherLimitIadl" class="sr-only">{{totalHigherLimitIadlText}}</label>
                  <div [ngClass]="(_sectionModel.totalHigherLimitIadl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalHigherLimitIadl.elementValue" [disabled]="!_sectionModel.totalHigherLimitIadl.isRequired"
                      type="text" class="form-control" id="totalHigherLimitIadl" (change)="unsavedChanges = true; _sectionModel.allLimitTotalIadl.elementValue = updateTotal(allLimitTotalIadlArr); _sectionModel.totalHigherLimitIadl = validate(_sectionModel.totalHigherLimitIadl); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpHigherLimitIadl" class="sr-only">{{bpHigherLimitIadlText}}</label>
                  <div [ngClass]="(_sectionModel.bpHigherLimitIadl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpHigherLimitIadl.elementValue" [disabled]="true" type="text"
                      class="form-control" id="bpHigherLimitIadl" (change)="unsavedChanges = true; _sectionModel.bpLimitTotalIadl.elementValue = updateTotal(bpLimitTotalIadlArr); _sectionModel.bpHigherLimitIadl = validate(_sectionModel.bpHigherLimitIadl); checkValidForm()">
                  </div>
                </td>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>
              <tr>
                <th class="normal" scope="row">IADL limitations missing</th>
                <td>
                  <label for="totalLimitMissingIadl" class="sr-only">{{totalLimitMissingIadlText}}</label>
                  <div [ngClass]="(_sectionModel.totalLimitMissingIadl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalLimitMissingIadl.elementValue" [disabled]="!_sectionModel.totalLimitMissingIadl.isRequired"
                      type="text" class="form-control" id="totalLimitMissingIadl" (change)="unsavedChanges = true; _sectionModel.allLimitTotalIadl.elementValue = updateTotal(allLimitTotalIadlArr); _sectionModel.totalLimitMissingIadl = validate(_sectionModel.totalLimitMissingIadl); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpLimitMissingIadl" class="sr-only">{{bpLimitMissingIadlText}}</label>
                  <div [ngClass]="(_sectionModel.bpLimitMissingIadl.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpLimitMissingIadl.elementValue" [disabled]="!_sectionModel.bpLimitMissingIadl.isRequired"
                      type="text" class="form-control" id="bpLimitMissingIadl" (change)="unsavedChanges = true; _sectionModel.bpLimitTotalIadl.elementValue = updateTotal(bpLimitTotalIadlArr); _sectionModel.bpLimitMissingIadl = validate(_sectionModel.bpLimitMissingIadl); checkValidForm()">
                  </div>
                </td>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>

              <tr>
                <th scope="row">Total Persons Served</th>
                <td>
                  <label for="allLimitTotalIadl" class="sr-only">{{allLimitTotalIadlText}}</label>
                  <input [(ngModel)]="_sectionModel.allLimitTotalIadl.elementValue" [disabled]="!_sectionModel.allLimitTotalIadl.isRequired"
                    type="text" class="form-control" id="allLimitTotalIadl" readonly>
                </td>
                <td>
                  <label for="bpLimitTotalIadl" class="sr-only">{{bpLimitTotalIadlText}}</label>
                  <input [(ngModel)]="_sectionModel.bpLimitTotalIadl.elementValue" [disabled]="!_sectionModel.bpLimitTotalIadl.isRequired"
                    type="text" class="form-control" id="bpLimitTotalIadl" readonly>
                </td>
                <div class="col-md-12"></div>
                <div class="col-md-12"></div>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  end table responsive  -->
        <div class="pull-left table-responsive">
          <div class="col-md-12 background-gray-lighter mb-1">
            <h2>Nutrition Risk Score Distribution</h2>
          </div>
          <table class="table table-no-border">
            <tbody>
              <tr *ngIf="nutritionWarning()">
                <td class="has-warning" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                  <p *ngIf="!_sectionModel.totalLowNutrition.dataEntryErrorMessage && _sectionModel.totalLowNutrition.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalLowNutritionText}}:
                    {{_sectionModel.totalLowNutrition.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpLowNutrition.dataEntryErrorMessage && _sectionModel.bpLowNutrition.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpLowNutritionText}}: {{_sectionModel.bpLowNutrition.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlLowNutrition.dataEntryErrorMessage && _sectionModel.adlLowNutrition.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlLowNutritionText}}: {{_sectionModel.adlLowNutrition.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlLowNutrition.dataEntryErrorMessage && _sectionModel.iadlLowNutrition.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlLowNutritionText}}:
                    {{_sectionModel.iadlLowNutrition.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalHighNutrition.dataEntryErrorMessage && _sectionModel.totalHighNutrition.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalHighNutritionText}}:
                    {{_sectionModel.totalHighNutrition.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpHighNutrition.dataEntryErrorMessage && _sectionModel.bpHighNutrition.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpHighNutritionText}}: {{_sectionModel.bpHighNutrition.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlHighNutrition.dataEntryErrorMessage && _sectionModel.adlHighNutrition.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlHighNutritionText}}:
                    {{_sectionModel.adlHighNutrition.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlHighNutrition.dataEntryErrorMessage && _sectionModel.iadlHighNutrition.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlHighNutritionText}}:
                    {{_sectionModel.iadlHighNutrition.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalNutritionMissing.dataEntryErrorMessage && _sectionModel.totalNutritionMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{totalNutritionMissingText}}:
                    {{_sectionModel.totalNutritionMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpNutritionMissing.dataEntryErrorMessage && _sectionModel.bpNutritionMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpNutritionMissingText}}:
                    {{_sectionModel.bpNutritionMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlNutritionMissing.dataEntryErrorMessage && _sectionModel.adlNutritionMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlNutritionMissingText}}:
                    {{_sectionModel.adlNutritionMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlNutritionMissing.dataEntryErrorMessage && _sectionModel.iadlNutritionMissing.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlNutritionMissingText}}:
                    {{_sectionModel.iadlNutritionMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allNutritionTotal.dataEntryErrorMessage && _sectionModel.allNutritionTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{allNutritionTotalText}}:
                    {{_sectionModel.allNutritionTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpNutritionTotal.dataEntryErrorMessage && _sectionModel.bpNutritionTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{bpNutritionTotalText}}:
                    {{_sectionModel.bpNutritionTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlNutritionTotal.dataEntryErrorMessage && _sectionModel.adlNutritionTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{adlNutritionTotalText}}:
                    {{_sectionModel.adlNutritionTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlNutritionTotal.dataEntryErrorMessage && _sectionModel.iadlNutritionTotal.questionStatus == statusEnum.L2ValidatedWarning"
                    class="help-block">{{iadlNutritionTotalText}}:
                    {{_sectionModel.iadlNutritionTotal.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr *ngIf="nutritionError()">
                <td class="has-error" colspan="5">
                  <span class="help-block">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                  <p *ngIf="_sectionModel.totalLowNutrition.dataEntryErrorMessage" class="help-block">{{totalLowNutritionText}}:
                    {{_sectionModel.totalLowNutrition.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpLowNutrition.dataEntryErrorMessage" class="help-block">{{bpLowNutritionText}}:
                    {{_sectionModel.bpLowNutrition.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlLowNutrition.dataEntryErrorMessage" class="help-block">{{adlLowNutritionText}}:
                    {{_sectionModel.adlLowNutrition.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlLowNutrition.dataEntryErrorMessage" class="help-block">{{iadlLowNutritionText}}:
                    {{_sectionModel.iadlLowNutrition.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.totalHighNutrition.dataEntryErrorMessage" class="help-block">{{totalHighNutritionText}}:
                    {{_sectionModel.totalHighNutrition.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpHighNutrition.dataEntryErrorMessage" class="help-block">{{bpHighNutritionText}}:
                    {{_sectionModel.bpHighNutrition.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.adlHighNutrition.dataEntryErrorMessage" class="help-block">{{adlHighNutritionText}}:
                    {{_sectionModel.adlHighNutrition.dataEntryErrorMessage}} </p>
                  <p *ngIf="_sectionModel.iadlHighNutrition.dataEntryErrorMessage" class="help-block">{{iadlHighNutritionText}}:
                    {{_sectionModel.iadlHighNutrition.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.totalNutritionMissing.dataEntryErrorMessage" class="help-block">{{totalNutritionMissingText}}:
                    {{_sectionModel.totalNutritionMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.bpNutritionMissing.dataEntryErrorMessage" class="help-block">{{bpNutritionMissingText}}:
                    {{_sectionModel.bpNutritionMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.adlNutritionMissing.dataEntryErrorMessage" class="help-block">{{adlNutritionMissingText}}:
                    {{_sectionModel.adlNutritionMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="_sectionModel.iadlNutritionMissing.dataEntryErrorMessage" class="help-block">{{iadlNutritionMissingText}}:
                    {{_sectionModel.iadlNutritionMissing.dataEntryErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.totalLowNutrition.dataEntryErrorMessage && _sectionModel.totalLowNutrition.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalLowNutritionText}}:
                    {{_sectionModel.totalLowNutrition.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpLowNutrition.dataEntryErrorMessage && _sectionModel.bpLowNutrition.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpLowNutritionText}}: {{_sectionModel.bpLowNutrition.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlLowNutrition.dataEntryErrorMessage && _sectionModel.adlLowNutrition.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlLowNutritionText}}: {{_sectionModel.adlLowNutrition.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlLowNutrition.dataEntryErrorMessage && _sectionModel.iadlLowNutrition.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlLowNutritionText}}:
                    {{_sectionModel.iadlLowNutrition.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalHighNutrition.dataEntryErrorMessage && _sectionModel.totalHighNutrition.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalHighNutritionText}}:
                    {{_sectionModel.totalHighNutrition.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpHighNutrition.dataEntryErrorMessage && _sectionModel.bpHighNutrition.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpHighNutritionText}}: {{_sectionModel.bpHighNutrition.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlHighNutrition.dataEntryErrorMessage && _sectionModel.adlHighNutrition.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlHighNutritionText}}:
                    {{_sectionModel.adlHighNutrition.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlHighNutrition.dataEntryErrorMessage && _sectionModel.iadlHighNutrition.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlHighNutritionText}}:
                    {{_sectionModel.iadlHighNutrition.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.totalNutritionMissing.dataEntryErrorMessage && _sectionModel.totalNutritionMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{totalNutritionMissingText}}:
                    {{_sectionModel.totalNutritionMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.bpNutritionMissing.dataEntryErrorMessage && _sectionModel.bpNutritionMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpNutritionMissingText}}:
                    {{_sectionModel.bpNutritionMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.adlNutritionMissing.dataEntryErrorMessage && _sectionModel.adlNutritionMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlNutritionMissingText}}:
                    {{_sectionModel.adlNutritionMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.iadlNutritionMissing.dataEntryErrorMessage && _sectionModel.iadlNutritionMissing.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlNutritionMissingText}}:
                    {{_sectionModel.iadlNutritionMissing.businessErrorMessage}}
                  </p>
                  <p *ngIf="!_sectionModel.allNutritionTotal.dataEntryErrorMessage && _sectionModel.allNutritionTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{allNutritionTotalText}}:
                    {{_sectionModel.allNutritionTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.bpNutritionTotal.dataEntryErrorMessage && _sectionModel.bpNutritionTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{bpNutritionTotalText}}:
                    {{_sectionModel.bpNutritionTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.adlNutritionTotal.dataEntryErrorMessage && _sectionModel.adlNutritionTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{adlNutritionTotalText}}:
                    {{_sectionModel.adlNutritionTotal.businessErrorMessage}} </p>
                  <p *ngIf="!_sectionModel.iadlNutritionTotal.dataEntryErrorMessage && _sectionModel.iadlNutritionTotal.questionStatus == statusEnum.L2InProgressError"
                    class="help-block">{{iadlNutritionTotalText}}:
                    {{_sectionModel.iadlNutritionTotal.businessErrorMessage}}
                  </p>
                </td>
              </tr>
              <tr>
                <td width="20%"></td>
                <th width="20%" scope="col">Total</th>
                <th width="20%" scope="col">At or Below Poverty</th>
                <th width="20%" scope="col">ADL 3+</th>
                <th width="20%" scope="col">IADL 3+</th>
              </tr>
              <tr>
                <th class="normal" scope="row">0-5</th>
                <td>
                  <label for="totalLowNutrition" class="sr-only">{{totalLowNutritionText}}</label>
                  <div [ngClass]="(_sectionModel.totalLowNutrition.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalLowNutrition.elementValue" [disabled]="!_sectionModel.totalLowNutrition.isRequired"
                      type="text" class="form-control" id="totalLowNutrition" (change)="unsavedChanges = true; _sectionModel.allNutritionTotal.elementValue = updateTotal(allNutritionTotalArr); _sectionModel.totalLowNutrition = validate(_sectionModel.totalLowNutrition); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpLowNutrition" class="sr-only">{{bpLowNutritionText}}</label>
                  <div [ngClass]="(_sectionModel.bpLowNutrition.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpLowNutrition.elementValue" [disabled]="!_sectionModel.bpLowNutrition.isRequired"
                      type="text" class="form-control" id="bpLowNutrition" (change)="unsavedChanges = true; _sectionModel.bpNutritionTotal.elementValue = updateTotal(bpNutritionTotalArr); _sectionModel.bpLowNutrition = validate(_sectionModel.bpLowNutrition); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlLowNutrition" class="sr-only">{{adlLowNutritionText}}</label>
                  <div [ngClass]="(_sectionModel.adlLowNutrition.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlLowNutrition.elementValue" [disabled]="!_sectionModel.adlLowNutrition.isRequired"
                      type="text" class="form-control" id="adlLowNutrition" (change)="unsavedChanges = true; _sectionModel.adlNutritionTotal.elementValue = updateTotal(adlNutritionTotalArr); _sectionModel.adlLowNutrition = validate(_sectionModel.adlLowNutrition); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlLowNutrition" class="sr-only">{{iadlLowNutritionText}}</label>
                  <div [ngClass]="(_sectionModel.iadlLowNutrition.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlLowNutrition.elementValue" [disabled]="!_sectionModel.iadlLowNutrition.isRequired"
                      type="text" class="form-control" id="iadlLowNutrition" (change)="unsavedChanges = true; _sectionModel.iadlNutritionTotal.elementValue = updateTotal(iadlNutritionTotalArr); _sectionModel.iadlLowNutrition = validate(_sectionModel.iadlLowNutrition); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">6+</th>
                <td>
                  <label for="totalHighNutrition" class="sr-only">{{totalHighNutritionText}}</label>
                  <div [ngClass]="(_sectionModel.totalHighNutrition.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalHighNutrition.elementValue" [disabled]="!_sectionModel.totalHighNutrition.isRequired"
                      type="text" class="form-control" id="totalHighNutrition" (change)="unsavedChanges = true; _sectionModel.allNutritionTotal.elementValue = updateTotal(allNutritionTotalArr); _sectionModel.totalHighNutrition = validate(_sectionModel.totalHighNutrition); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpHighNutrition" class="sr-only">{{bpHighNutritionText}}</label>
                  <div [ngClass]="(_sectionModel.bpHighNutrition.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpHighNutrition.elementValue" [disabled]="!_sectionModel.bpHighNutrition.isRequired"
                      type="text" class="form-control" id="bpHighNutrition" (change)="unsavedChanges = true; _sectionModel.bpNutritionTotal.elementValue = updateTotal(bpNutritionTotalArr); _sectionModel.bpHighNutrition = validate(_sectionModel.bpHighNutrition); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlHighNutrition" class="sr-only">{{adlHighNutritionText}}</label>
                  <div [ngClass]="(_sectionModel.adlHighNutrition.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlHighNutrition.elementValue" [disabled]="!_sectionModel.adlHighNutrition.isRequired"
                      type="text" class="form-control" id="adlHighNutrition" (change)="unsavedChanges = true; _sectionModel.adlNutritionTotal.elementValue = updateTotal(adlNutritionTotalArr); _sectionModel.adlHighNutrition = validate(_sectionModel.adlHighNutrition); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlHighNutrition" class="sr-only">{{iadlHighNutritionText}}</label>
                  <div [ngClass]="(_sectionModel.iadlHighNutrition.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlHighNutrition.elementValue" [disabled]="!_sectionModel.iadlHighNutrition.isRequired"
                      type="text" class="form-control" id="iadlHighNutrition" (change)="unsavedChanges = true; _sectionModel.iadlNutritionTotal.elementValue = updateTotal(iadlNutritionTotalArr); _sectionModel.iadlHighNutrition = validate(_sectionModel.iadlHighNutrition); checkValidForm()">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="normal" scope="row">Nutrition risk score missing</th>
                <td>
                  <label for="totalNutritionMissing" class="sr-only">{{totalNutritionMissingText}}</label>
                  <div [ngClass]="(_sectionModel.totalNutritionMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.totalNutritionMissing.elementValue" [disabled]="!_sectionModel.totalNutritionMissing.isRequired"
                      type="text" class="form-control" id="totalNutritionMissing" (change)="unsavedChanges = true; _sectionModel.allNutritionTotal.elementValue = updateTotal(allNutritionTotalArr); _sectionModel.totalNutritionMissing = validate(_sectionModel.totalNutritionMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="bpNutritionMissing" class="sr-only">{{bpNutritionMissingText}}</label>
                  <div [ngClass]="(_sectionModel.bpNutritionMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.bpNutritionMissing.elementValue" [disabled]="!_sectionModel.bpNutritionMissing.isRequired"
                      type="text" class="form-control" id="bpNutritionMissing" (change)="unsavedChanges = true; _sectionModel.bpNutritionTotal.elementValue = updateTotal(bpNutritionTotalArr); _sectionModel.bpNutritionMissing = validate(_sectionModel.bpNutritionMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="adlNutritionMissing" class="sr-only">{{adlNutritionMissingText}}</label>
                  <div [ngClass]="(_sectionModel.adlNutritionMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.adlNutritionMissing.elementValue" [disabled]="!_sectionModel.adlNutritionMissing.isRequired"
                      type="text" class="form-control" id="adlNutritionMissing" (change)="unsavedChanges = true; _sectionModel.adlNutritionTotal.elementValue = updateTotal(adlNutritionTotalArr); _sectionModel.adlNutritionMissing = validate(_sectionModel.adlNutritionMissing); checkValidForm()">
                  </div>
                </td>
                <td>
                  <label for="iadlNutritionMissing" class="sr-only">{{iadlNutritionMissingText}}</label>
                  <div [ngClass]="(_sectionModel.iadlNutritionMissing.dataEntryErrorMessage)?'has-error':''">
                    <input [(ngModel)]="_sectionModel.iadlNutritionMissing.elementValue" [disabled]="!_sectionModel.iadlNutritionMissing.isRequired"
                      type="text" class="form-control" id="iadlNutritionMissing" (change)="unsavedChanges = true; _sectionModel.iadlNutritionTotal.elementValue = updateTotal(iadlNutritionTotalArr); _sectionModel.iadlNutritionMissing = validate(_sectionModel.iadlNutritionMissing); checkValidForm()">
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Total Persons Served</th>
                <td>
                  <label for="allNutritionTotal" class="sr-only">{{allNutritionTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.allNutritionTotal.elementValue" [disabled]="!_sectionModel.allNutritionTotal.isRequired"
                    type="text" class="form-control" id="allNutritionTotal" readonly>
                </td>
                <td>
                  <label for="bpNutritionTotal" class="sr-only">{{bpNutritionTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.bpNutritionTotal.elementValue" [disabled]="!_sectionModel.bpNutritionTotal.isRequired"
                    type="text" class="form-control" id="bpNutritionTotal" readonly>
                </td>
                <td>
                  <label for="adlNutritionTotal" class="sr-only">{{adlNutritionTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.adlNutritionTotal.elementValue" [disabled]="!_sectionModel.adlNutritionTotal.isRequired"
                    type="text" class="form-control" id="adlNutritionTotal" readonly>
                </td>
                <td>
                  <label for="iadlNutritionTotal" class="sr-only">{{iadlNutritionTotalText}}</label>
                  <input [(ngModel)]="_sectionModel.iadlNutritionTotal.elementValue" [disabled]="!_sectionModel.iadlNutritionTotal.isRequired"
                    type="text" class="form-control" id="iadlNutritionTotal" readonly>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  end table responsive  -->

        <div class="col-md-12 row">
          <div *ngIf="!validForm" class="has-error">
            <p class="help-block">
              There are errors on the page. You must fix the errors or clear the data in those fields in order to Save this form.
            </p>
          </div>
          <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
          <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>
          <button class="btn btn-default mr-1" (click)="save()" [disabled]="!validForm || !_sectionModel.isRequired">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
        </div>
        <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1"
             style="display: none;">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
                <h3 class="modal-title" id="nextModalLabel">
                  Recent changes on this page will not be saved
                </h3>
              </div>
              <div class="modal-body">
                <p>
                  There are errors on the page. You must fix the errors or clear the data in those fields in order to save this form.
                  <br />
                  <br />
                  You may select "Go back to form" to remove the errors or you may select "Proceed to next section" and all of new the data entered in the current section will be lost.
                </p>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go back to form</button>
                <button class="btn btn-primary pull-right" data-dismiss="modal" type="button" (click)="next()">Proceed to next section</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
