import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class ANSeniorCentersSection extends Section {

    totalCenters: IDataElement;
    oaaFunded: IDataElement;
    focalPoints: IDataElement;
    focalPointCenters: IDataElement;

    constructor() {
        super();

        this.totalCenters = new DataElement();
        this.oaaFunded = new DataElement();
        this.focalPoints = new DataElement();
        this.focalPointCenters = new DataElement();

    }
}
