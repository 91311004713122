import { Component, Input, EventEmitter, OnInit } from '@angular/core';

export interface PageEvent {
    activePage: number;
    dataLength: number;
}

@Component({
    selector: 'page-view',
    template: ``
})
export class PageViewComponent implements OnInit {

    @Input('pvActivePage') public activePage = 1;
    @Input('pvData') public inputData: any[] = [];


    public pageData: any = {};
    public dataLength: number = 0;
    public lastPage: number = 0;

    public onPageChange = new EventEmitter<PageEvent>();
    public onInit = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        if (this.inputData && this.inputData.length > 0) {
            this.pageData = this.inputData[0];
            this.lastPage = this.dataLength = this.inputData.length;
            this.onInit.emit();
        }
    }

    public getPage(): PageEvent {
        return {activePage: this.activePage, dataLength: this.inputData.length};
    }

    public setPage(activePage: number): void {
        if (this.activePage !== activePage) {
            if (this.inputData && this.inputData.length > 0) {
                this.pageData = this.inputData[activePage - 1];
            }

            this.activePage = activePage;

            this.onPageChange.emit({
                activePage: this.activePage,
                dataLength: this.inputData ? this.inputData.length : 0
            });
        }
    }

}
