import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SideNavComponent } from '../StateSubmissions/sideNav.component';
import { OaapsUserManagementSideNavComponent } from '../UserManagement/sidenav/oaapsUserManagementSideNav.component';
import { RouterModule } from '@angular/router';
import { AaastateReviewNavComponent } from './Component/aaastate-review-nav/aaastate-review-nav.component';

@NgModule({
    imports: [
        SharedModule,
        RouterModule
    ],
    declarations: [
        SideNavComponent,
        OaapsUserManagementSideNavComponent,
        AaastateReviewNavComponent
    ],
    exports: [
        SideNavComponent,
        OaapsUserManagementSideNavComponent,
    ]
})
export class SideNavModule { }
