import { MultipleUnitsSection } from './MultipleUnitsSection';
import { AssistedTransportationUnitsSection } from './AssistedTransportationUnitsSection';
import { CongregateMealsUnitsSection } from './CongregateMealsUnitsSection';
import { InfoAndAssistanceSection } from './InfoAndAssistanceSection';
import { HealthPromoEvidenceSection } from './HealthPromoEvidenceSection';
import { HealthPromoNonEvidenceSection } from './HealthPromoNonEvidenceSection';
import { TitleIIIOtherExpend } from './CGSuppExpendSection';
import { HomeDeliveredMealsUnitsSection } from './HomeDeliveredMealsUnitsSection';
import { NutritionCounselingUnitsSection } from './NutritionCounselingUnitsSection';
import { FourColNoNutritionCharsSection } from './FourColNoNutritionCharsSection';
import { HomeDeliveredMealsCharsSection } from './HomeDeliveredMealsCharsSection';
import { AssistedTransportationCharsSection } from './AssistedTransportationCharsSection';
import { TwoColCharsSection } from './TwoColCharsSection';
import { NutritionEduInfoSection } from './NutritionEduInfoSection';

export class OAExpenditureModelDto {
    personalCareUnits: MultipleUnitsSection;
    homemakerUnits: MultipleUnitsSection;
    choreUnits: MultipleUnitsSection;
    homeDeliveredMealsUnits: HomeDeliveredMealsUnitsSection;
    adultDayCareUnits: MultipleUnitsSection;
    caseMgmtUnits: MultipleUnitsSection;
    assistedTransportationUnits: AssistedTransportationUnitsSection;
    legalUnits: AssistedTransportationUnitsSection;
    congregateMealsUnits: CongregateMealsUnitsSection;
    nutritionCounselingUnits: NutritionCounselingUnitsSection;
    transportation: InfoAndAssistanceSection;
    nutritionEdu: NutritionEduInfoSection;
    infoAndAssist: InfoAndAssistanceSection;
    healthPromo: HealthPromoEvidenceSection;
    healthNonPromo: HealthPromoNonEvidenceSection;
    otherServices: TitleIIIOtherExpend;

    personalCareChars: FourColNoNutritionCharsSection;
    homemakerChars: FourColNoNutritionCharsSection;
    choreChars: FourColNoNutritionCharsSection;
    homeDeliveredMealsChars: HomeDeliveredMealsCharsSection;
    adultDayCareChars: FourColNoNutritionCharsSection;
    caseMgmtChars: FourColNoNutritionCharsSection;
    assistedTransportationChars: AssistedTransportationCharsSection;
    legalChars: AssistedTransportationCharsSection;
    congregateMealsChars: TwoColCharsSection;
    nutritionCounselingChars: TwoColCharsSection;

}
