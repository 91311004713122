<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
    <div class="row">
        <div class="col-md-12 acl-container">
            <!-- left navigation -->
            <sideNav></sideNav>
            <!-- .end left navigation -->
            <div class="col-md-9">
                <h2>Older Relative Caregivers - Consumer Summary - Persons Receiving Registered Services</h2>
                <p class="info mt-1">All fields are required</p>
                <div class="table-responsive">
                    <table class="table table-no-border">
                        <tr>
                            <th scope="col" class="col-md-9">
                                <label for="totalPersonsServed">Total unduplicated persons served</label>
                            </th>
                            <td class="col-md-3">
                                <div [ngClass]="calculateOutlineColor(_sectionModel.totalPersonsServed.questionStatus)">
                                    <input [(ngModel)]="_sectionModel.totalPersonsServed.elementValue" [disabled]="router.url.includes('/titleIIIORCConsumerSummary') || !_sectionModel.totalPersonsServed.isRequired"
                                        type="text" class="form-control" id="totalPersonsServed" (change)="unsavedChanges = true; _sectionModel.totalPersonsServed.elementValue = addComma(_sectionModel.totalPersonsServed.elementValue); _sectionModel.totalPersonsServed = validate(_sectionModel.totalPersonsServed); checkValidForm()">
                                </div>
                                <div *ngIf="!!_sectionModel.totalPersonsServed.dataEntryErrorMessage" class="alert alert-danger" role="alert">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                    <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.totalPersonsServed.dataEntryErrorMessage}}
                                </div>
                                <div *ngIf="!_sectionModel.totalPersonsServed.dataEntryErrorMessage && (_sectionModel.totalPersonsServed.questionStatus == statusEnum.L2InProgressError || _sectionModel.totalPersonsServed.questionStatus == statusEnum.L2ValidatedWarning)"
                                    [ngClass]="setErrorDisplayType(_sectionModel.totalPersonsServed.questionStatus)" role="alert">
                                    <i *ngIf="_sectionModel.totalPersonsServed.questionStatus == statusEnum.L2ValidatedWarning" class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    <span class="sr-only">Warning:</span>
                                    <i *ngIf="_sectionModel.totalPersonsServed.questionStatus != statusEnum.L2ValidatedWarning" class="fa fa-times" aria-hidden="true"></i>
                                    <span class="sr-only">Error:</span>
                                    &nbsp;{{_sectionModel.totalPersonsServed.businessErrorMessage}}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- end table-responsive -->

                <div class="table-responsive">
                    <div class="col-md-12 background-gray-lighter mb-1">
                        <h2>Age Distribution</h2>
                    </div>
                    <table class="table table-no-border">
                        <tbody>
                            <tr *ngIf="ageWarning()">
                                <td class="has-warning" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                                    <p *ngIf="!_sectionModel.totalRange1.dataEntryErrorMessage && _sectionModel.totalRange1.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalRange1Text}}: {{_sectionModel.totalRange1.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRange1.dataEntryErrorMessage && _sectionModel.bpRange1.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpRange1Text}}: {{_sectionModel.bpRange1.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRange2.dataEntryErrorMessage && _sectionModel.totalRange2.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalRange2Text}}: {{_sectionModel.totalRange2.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRange2.dataEntryErrorMessage && _sectionModel.bpRange2.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpRange2Text}}: {{_sectionModel.bpRange2.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRange3.dataEntryErrorMessage && _sectionModel.totalRange3.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalRange3Text}}: {{_sectionModel.totalRange3.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRange3.dataEntryErrorMessage && _sectionModel.bpRange3.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpRange3Text}}: {{_sectionModel.bpRange3.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRange4.dataEntryErrorMessage && _sectionModel.totalRange4.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalRange4Text}}: {{_sectionModel.totalRange4.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRange4.dataEntryErrorMessage && _sectionModel.bpRange4.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpRange4Text}}: {{_sectionModel.bpRange4.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRange5.dataEntryErrorMessage && _sectionModel.totalRange5.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalRange5Text}}: {{_sectionModel.totalRange5.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRange5.dataEntryErrorMessage && _sectionModel.bpRange5.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpRange5Text}}: {{_sectionModel.bpRange5.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalAgeMissing.dataEntryErrorMessage && _sectionModel.totalAgeMissing.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalAgeMissingText}}: {{_sectionModel.totalAgeMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpAgeMissing.dataEntryErrorMessage && _sectionModel.bpAgeMissing.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpAgeMissingText}}: {{_sectionModel.bpAgeMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.allAgeTotal.dataEntryErrorMessage && _sectionModel.allAgeTotal.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{allAgeTotalText}}: {{_sectionModel.allAgeTotal.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpAgeTotal.dataEntryErrorMessage && _sectionModel.bpAgeTotal.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpAgeTotalText}}: {{_sectionModel.bpAgeTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr *ngIf="ageError()">
                                <td class="has-error" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                                    <p *ngIf="_sectionModel.totalRange1.dataEntryErrorMessage" class="help-block">{{totalRange1Text}}: {{_sectionModel.totalRange1.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpRange1.dataEntryErrorMessage" class="help-block">{{bpRange1Text}}: {{_sectionModel.bpRange1.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalRange2.dataEntryErrorMessage" class="help-block">{{totalRange2Text}}: {{_sectionModel.totalRange2.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpRange2.dataEntryErrorMessage" class="help-block">{{bpRange2Text}}: {{_sectionModel.bpRange2.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalRange3.dataEntryErrorMessage" class="help-block">{{totalRange3Text}}: {{_sectionModel.totalRange3.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpRange3.dataEntryErrorMessage" class="help-block">{{bpRange3Text}}: {{_sectionModel.bpRange3.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalRange4.dataEntryErrorMessage" class="help-block">{{totalRange4Text}}: {{_sectionModel.totalRange4.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpRange4.dataEntryErrorMessage" class="help-block">{{bpRange4Text}}: {{_sectionModel.bpRange4.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalAgeMissing.dataEntryErrorMessage" class="help-block">{{totalAgeMissingText}}: {{_sectionModel.totalAgeMissing.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpAgeMissing.dataEntryErrorMessage" class="help-block">{{bpAgeMissingText}}: {{_sectionModel.bpAgeMissing.dataEntryErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRange1.dataEntryErrorMessage && _sectionModel.totalRange1.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalRange1Text}}: {{_sectionModel.totalRange1.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRange1.dataEntryErrorMessage && _sectionModel.bpRange1.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpRange1Text}}: {{_sectionModel.bpRange1.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRange2.dataEntryErrorMessage && _sectionModel.totalRange2.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalRange2Text}}: {{_sectionModel.totalRange2.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRange2.dataEntryErrorMessage && _sectionModel.bpRange2.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpRange2Text}}: {{_sectionModel.bpRange2.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRange3.dataEntryErrorMessage && _sectionModel.totalRange3.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalRange3Text}}: {{_sectionModel.totalRange3.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRange3.dataEntryErrorMessage && _sectionModel.bpRange3.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpRange3Text}}: {{_sectionModel.bpRange3.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRange4.dataEntryErrorMessage && _sectionModel.totalRange4.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalRange4Text}}: {{_sectionModel.totalRange4.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRange4.dataEntryErrorMessage && _sectionModel.bpRange4.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpRange4Text}}: {{_sectionModel.bpRange4.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRange5.dataEntryErrorMessage && _sectionModel.totalRange5.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalRange5Text}}: {{_sectionModel.totalRange5.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRange5.dataEntryErrorMessage && _sectionModel.bpRange5.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpRange5Text}}: {{_sectionModel.bpRange5.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalAgeMissing.dataEntryErrorMessage && _sectionModel.totalAgeMissing.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalAgeMissingText}}: {{_sectionModel.totalAgeMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpAgeMissing.dataEntryErrorMessage && _sectionModel.bpAgeMissing.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpAgeMissingText}}: {{_sectionModel.bpAgeMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.allAgeTotal.dataEntryErrorMessage && _sectionModel.allAgeTotal.questionStatus == statusEnum.L2InProgressError" class="help-block">{{allAgeTotalText}}: {{_sectionModel.allAgeTotal.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpAgeTotal.dataEntryErrorMessage && _sectionModel.bpAgeTotal.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpAgeTotalText}}: {{_sectionModel.bpAgeTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="30%"></td>
                                <th width="15%" scope="col">Total</th>
                                <th width="15%" scope="col">At or Below Poverty</th>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">55-59</th>
                                <td>
                                    <label for="totalRange1" class="sr-only">{{totalRange1Text}}</label>
                                    <div [ngClass]="(_sectionModel.totalRange1.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalRange1.elementValue" [disabled]="!_sectionModel.totalRange1.isRequired"
                                            type="text" class="form-control" id="totalRange1" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalRange1 = validate(_sectionModel.totalRange1); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpRange1" class="sr-only">{{bpRange1Text}}</label>
                                    <div [ngClass]="(_sectionModel.bpRange1.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpRange1.elementValue" [disabled]="!_sectionModel.bpRange1.isRequired"
                                            type="text" class="form-control" id="bpRange1" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpRange1 = validate(_sectionModel.bpRange1); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">60-64</th>
                                <td>
                                    <label for="totalRange2" class="sr-only">{{totalRange2Text}}</label>
                                    <div [ngClass]="(_sectionModel.totalRange2.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalRange2.elementValue" [disabled]="!_sectionModel.totalRange2.isRequired"
                                            type="text" class="form-control" id="totalRange2" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalRange2 = validate(_sectionModel.totalRange2); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpRange2" class="sr-only">{{bpRange2Text}}</label>
                                    <div [ngClass]="(_sectionModel.bpRange2.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpRange2.elementValue" [disabled]="!_sectionModel.bpRange2.isRequired"
                                            type="text" class="form-control" id="bpRange2" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpRange2 = validate(_sectionModel.bpRange2); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">65-74</th>
                                <td>
                                    <label for="totalRange3" class="sr-only">{{totalRange3Text}}</label>
                                    <div [ngClass]="(_sectionModel.totalRange3.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalRange3.elementValue" [disabled]="!_sectionModel.totalRange3.isRequired"
                                            type="text" class="form-control" id="totalRange3" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalRange3 = validate(_sectionModel.totalRange3); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpRange3" class="sr-only">{{bpRange3Text}}</label>
                                    <div [ngClass]="(_sectionModel.bpRange3.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpRange3.elementValue" [disabled]="!_sectionModel.bpRange3.isRequired"
                                            type="text" class="form-control" id="bpRange3" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpRange3 = validate(_sectionModel.bpRange3); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">75-84</th>
                                <td>
                                    <label for="totalRange4" class="sr-only">{{totalRange4Text}}</label>
                                    <div [ngClass]="(_sectionModel.totalRange4.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalRange4.elementValue" [disabled]="!_sectionModel.totalRange4.isRequired"
                                            type="text" class="form-control" id="totalRange4" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalRange4 = validate(_sectionModel.totalRange4); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpRange4" class="sr-only">{{bpRange4Text}}</label>
                                    <div [ngClass]="(_sectionModel.bpRange4.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpRange4.elementValue" [disabled]="!_sectionModel.bpRange4.isRequired"
                                            type="text" class="form-control" id="bpRange4" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpRange4 = validate(_sectionModel.bpRange4); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">85 and above</th>
                                <td>
                                    <label for="totalRange5" class="sr-only">{{totalRange5Text}}</label>
                                    <div [ngClass]="(_sectionModel.totalRange5.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalRange5.elementValue" [disabled]="!_sectionModel.totalRange5.isRequired"
                                            type="text" class="form-control" id="totalRange5" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalRange5 = validate(_sectionModel.totalRange5); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpRange5" class="sr-only">{{bpRange5Text}}</label>
                                    <div [ngClass]="(_sectionModel.bpRange5.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpRange5.elementValue" [disabled]="!_sectionModel.bpRange5.isRequired"
                                            type="text" class="form-control" id="bpRange5" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpRange5 = validate(_sectionModel.bpRange5); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Age missing</th>
                                <td>
                                    <label for="totalAgeMissing" class="sr-only">{{totalAgeMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.totalAgeMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalAgeMissing.elementValue" [disabled]="!_sectionModel.totalAgeMissing.isRequired"
                                            type="text" class="form-control" id="totalAgeMissing" (change)="unsavedChanges = true; _sectionModel.allAgeTotal.elementValue = updateTotal(allAgeTotalArr); _sectionModel.totalAgeMissing = validate(_sectionModel.totalAgeMissing); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpAgeMissing" class="sr-only">{{bpAgeMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.bpAgeMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpAgeMissing.elementValue" [disabled]="!_sectionModel.bpAgeMissing.isRequired"
                                            type="text" class="form-control" id="bpAgeMissing" (change)="unsavedChanges = true; _sectionModel.bpAgeTotal.elementValue = updateTotal(bpAgeTotalArr); _sectionModel.bpAgeMissing = validate(_sectionModel.bpAgeMissing); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Total Persons Served</th>
                                <td>
                                    <label for="allAgeTotal" class="sr-only">{{allAgeTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.allAgeTotal.elementValue"
                                        [disabled]="!_sectionModel.allAgeTotal.isRequired" type="text" class="form-control" id="allAgeTotal"
                                        readonly>
                                </td>
                                <td>
                                    <label for="bpAgeTotal" class="sr-only">{{bpAgeTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.bpAgeTotal.elementValue"
                                        [disabled]="!_sectionModel.bpAgeTotal.isRequired" type="text" class="form-control" id="bpAgeTotal"
                                        readonly>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- end table-responsive -->
                <div class="table-responsive">
                    <div class="col-md-12 background-gray-lighter mb-1">
                        <h2>Gender Distribution</h2>
                    </div>
                    <table class="table table-no-border">
                        <tbody>
                            <tr *ngIf="genderWarning()">
                                <td class="has-warning" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                                    <p *ngIf="!_sectionModel.totalFemale.dataEntryErrorMessage && _sectionModel.totalFemale.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalFemaleText}}: {{_sectionModel.totalFemale.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpFemale.dataEntryErrorMessage && _sectionModel.bpFemale.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpFemaleText}}: {{_sectionModel.bpFemale.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalMale.dataEntryErrorMessage && _sectionModel.totalMale.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalMaleText}}: {{_sectionModel.totalMale.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpMale.dataEntryErrorMessage && _sectionModel.bpMale.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpMaleText}}: {{_sectionModel.bpMale.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalOther.dataEntryErrorMessage && _sectionModel.totalOther.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalOtherText}}: {{_sectionModel.totalOther.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpOther.dataEntryErrorMessage && _sectionModel.bpOther.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpOtherText}}: {{_sectionModel.bpOther.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalGenderMissing.dataEntryErrorMessage && _sectionModel.totalGenderMissing.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{totalGenderMissingText}}: {{_sectionModel.totalGenderMissing.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.bpGenderMissing.dataEntryErrorMessage && _sectionModel.bpGenderMissing.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpGenderMissingText}}: {{_sectionModel.bpGenderMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.allGenderTotal.dataEntryErrorMessage && _sectionModel.allGenderTotal.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{allGenderTotalText}}: {{_sectionModel.allGenderTotal.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpGenderTotal.dataEntryErrorMessage && _sectionModel.bpGenderTotal.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpGenderTotalText}}: {{_sectionModel.bpGenderTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr *ngIf="genderError()">
                                <td class="has-error" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                                    <p *ngIf="_sectionModel.totalFemale.dataEntryErrorMessage" class="help-block">{{totalFemaleText}}: {{_sectionModel.totalFemale.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpFemale.dataEntryErrorMessage" class="help-block">{{bpFemaleText}}: {{_sectionModel.bpFemale.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalMale.dataEntryErrorMessage" class="help-block">{{totalMaleText}}: {{_sectionModel.totalMale.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpMale.dataEntryErrorMessage" class="help-block">{{bpMaleText}}: {{_sectionModel.bpMale.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalOther.dataEntryErrorMessage" class="help-block">{{totalOtherText}}: {{_sectionModel.totalOther.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpOther.dataEntryErrorMessage" class="help-block">{{bpOtherText}}: {{_sectionModel.bpOther.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalGenderMissing.dataEntryErrorMessage" class="help-block">{{totalGenderMissingText}}: {{_sectionModel.totalGenderMissing.dataEntryErrorMessage}}
                                        </p>
                                    <p *ngIf="_sectionModel.bpGenderMissing.dataEntryErrorMessage" class="help-block">{{bpGenderMissingText}}: {{_sectionModel.bpGenderMissing.dataEntryErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalFemale.dataEntryErrorMessage && _sectionModel.totalFemale.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalFemaleText}}: {{_sectionModel.totalFemale.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpFemale.dataEntryErrorMessage && _sectionModel.bpFemale.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpFemaleText}}: {{_sectionModel.bpFemale.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalMale.dataEntryErrorMessage && _sectionModel.totalMale.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalMaleText}}: {{_sectionModel.totalMale.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpMale.dataEntryErrorMessage && _sectionModel.bpMale.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpMaleText}}: {{_sectionModel.bpMale.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalOther.dataEntryErrorMessage && _sectionModel.totalOther.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalOtherText}}: {{_sectionModel.totalOther.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpOther.dataEntryErrorMessage && _sectionModel.bpOther.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpOtherText}}: {{_sectionModel.bpOther.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalGenderMissing.dataEntryErrorMessage && _sectionModel.totalGenderMissing.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{totalGenderMissingText}}: {{_sectionModel.totalGenderMissing.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.bpGenderMissing.dataEntryErrorMessage && _sectionModel.bpGenderMissing.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpGenderMissingText}}: {{_sectionModel.bpGenderMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.allGenderTotal.dataEntryErrorMessage && _sectionModel.allGenderTotal.questionStatus == statusEnum.L2InProgressError" class="help-block">{{allGenderTotalText}}: {{_sectionModel.allGenderTotal.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpGenderTotal.dataEntryErrorMessage && _sectionModel.bpGenderTotal.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpGenderTotalText}}: {{_sectionModel.bpGenderTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="30%"></td>
                                <th width="15%" scope="col">Total</th>
                                <th width="15%" scope="col">At or Below Poverty</th>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Female</th>
                                <td>
                                    <label for="totalFemale" class="sr-only">{{totalFemaleText}}</label>
                                    <div [ngClass]="(_sectionModel.totalFemale.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalFemale.elementValue" [disabled]="!_sectionModel.totalFemale.isRequired"
                                            type="text" class="form-control" id="totalFemale" (change)="unsavedChanges = true; _sectionModel.allGenderTotal.elementValue = updateTotal(allGenderTotalArr); _sectionModel.totalFemale = validate(_sectionModel.totalFemale); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpFemale" class="sr-only">{{bpFemaleText}}</label>
                                    <div [ngClass]="(_sectionModel.bpFemale.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpFemale.elementValue" [disabled]="!_sectionModel.bpFemale.isRequired"
                                            type="text" class="form-control" id="bpFemale" (change)="unsavedChanges = true; _sectionModel.bpGenderTotal.elementValue = updateTotal(bpGenderTotalArr); _sectionModel.bpFemale = validate(_sectionModel.bpFemale); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Male</th>
                                <td>
                                    <label for="totalMale" class="sr-only">{{totalMaleText}}</label>
                                    <div [ngClass]="(_sectionModel.totalMale.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalMale.elementValue" [disabled]="!_sectionModel.totalMale.isRequired"
                                            type="text" class="form-control" id="totalMale" (change)="unsavedChanges = true; _sectionModel.allGenderTotal.elementValue = updateTotal(allGenderTotalArr); _sectionModel.totalMale = validate(_sectionModel.totalMale); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpMale" class="sr-only">{{bpMaleText}}</label>
                                    <div [ngClass]="(_sectionModel.bpMale.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpMale.elementValue" [disabled]="!_sectionModel.bpMale.isRequired"
                                            type="text" class="form-control" id="bpMale" (change)="unsavedChanges = true; _sectionModel.bpGenderTotal.elementValue = updateTotal(bpGenderTotalArr); _sectionModel.bpMale = validate(_sectionModel.bpMale); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Other</th>
                                <td>
                                    <label for="totalOther" class="sr-only">{{totalOtherText}}</label>
                                    <div [ngClass]="(_sectionModel.totalOther.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalOther.elementValue" [disabled]="!_sectionModel.totalOther.isRequired"
                                            type="text" class="form-control" id="totalOther" (change)="unsavedChanges = true; _sectionModel.allGenderTotal.elementValue = updateTotal(allGenderTotalArr); _sectionModel.totalOther = validate(_sectionModel.totalOther); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpOther" class="sr-only">{{bpOtherText}}</label>
                                    <div [ngClass]="(_sectionModel.bpOther.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpOther.elementValue" [disabled]="!_sectionModel.bpOther.isRequired"
                                            type="text" class="form-control" id="bpOther" (change)="unsavedChanges = true; _sectionModel.bpGenderTotal.elementValue = updateTotal(bpGenderTotalArr); _sectionModel.bpOther = validate(_sectionModel.bpOther); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Gender missing</th>
                                <td>
                                    <label for="totalGenderMissing" class="sr-only">{{totalGenderMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.totalGenderMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalGenderMissing.elementValue" [disabled]="!_sectionModel.totalGenderMissing.isRequired"
                                            type="text" class="form-control" id="totalGenderMissing" (change)="unsavedChanges = true; _sectionModel.allGenderTotal.elementValue = updateTotal(allGenderTotalArr); _sectionModel.totalGenderMissing = validate(_sectionModel.totalGenderMissing); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpGenderMissing" class="sr-only">{{bpGenderMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.bpGenderMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpGenderMissing.elementValue" [disabled]="!_sectionModel.bpGenderMissing.isRequired"
                                            type="text" class="form-control" id="bpGenderMissing" (change)="unsavedChanges = true; _sectionModel.bpGenderTotal.elementValue = updateTotal(bpGenderTotalArr); _sectionModel.bpGenderMissing = validate(_sectionModel.bpGenderMissing); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Total Persons Served</th>
                                <td>
                                    <label for="allGenderTotal" class="sr-only">{{allGenderTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.allGenderTotal.elementValue"
                                        [disabled]="!_sectionModel.allGenderTotal.isRequired" type="text" class="form-control"
                                        id="allGenderTotal" readonly>
                                </td>
                                <td>
                                    <label for="bpGenderTotal" class="sr-only">{{bpGenderTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.bpGenderTotal.elementValue"
                                        [disabled]="!_sectionModel.bpGenderTotal.isRequired" type="text" class="form-control"
                                        id="bpGenderTotal" readonly>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--  end table responsive  -->
                <div class="table-responsive">
                    <div class="col-md-12 background-gray-lighter mb-1">
                      <h2>Geographic Distribution</h2>
                    </div>
                    <table class="table table-no-border">
                        <tbody>
                            <tr *ngIf="geoWarning()">
                                <td class="has-warning" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                                    <p *ngIf="!_sectionModel.totalRural.dataEntryErrorMessage && _sectionModel.totalRural.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalRuralText}}: {{_sectionModel.totalRural.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRural.dataEntryErrorMessage && _sectionModel.bpRural.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpRuralText}}: {{_sectionModel.bpRural.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalNonRural.dataEntryErrorMessage && _sectionModel.totalNonRural.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalNonRuralText}}: {{_sectionModel.totalNonRural.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpNonRural.dataEntryErrorMessage && _sectionModel.bpNonRural.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpNonRuralText}}: {{_sectionModel.bpNonRural.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalGeoMissing.dataEntryErrorMessage && _sectionModel.totalGeoMissing.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalGeoMissingText}}: {{_sectionModel.totalGeoMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpGeoMissing.dataEntryErrorMessage && _sectionModel.bpGeoMissing.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpGeoMissingText}}: {{_sectionModel.bpGeoMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.allGeoTotal.dataEntryErrorMessage && _sectionModel.allGeoTotal.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{allGeoTotalText}}: {{_sectionModel.allGeoTotal.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpGeoTotal.dataEntryErrorMessage && _sectionModel.bpGeoTotal.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpGeoTotalText}}: {{_sectionModel.bpGeoTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr *ngIf="geoError()">
                                <td class="has-error" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                                    <p *ngIf="_sectionModel.totalRural.dataEntryErrorMessage" class="help-block">{{totalRuralText}}: {{_sectionModel.totalRural.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpRural.dataEntryErrorMessage" class="help-block">{{bpRuralText}}: {{_sectionModel.bpRural.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalNonRural.dataEntryErrorMessage" class="help-block">{{totalNonRuralText}}: {{_sectionModel.totalNonRural.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpNonRural.dataEntryErrorMessage" class="help-block">{{bpNonRuralText}}: {{_sectionModel.bpNonRural.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalGeoMissing.dataEntryErrorMessage" class="help-block">{{totalGeoMissingText}}: {{_sectionModel.totalGeoMissing.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpGeoMissing.dataEntryErrorMessage" class="help-block">{{bpGeoMissingText}}: {{_sectionModel.bpGeoMissing.dataEntryErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRural.dataEntryErrorMessage && _sectionModel.totalRural.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalRuralText}}: {{_sectionModel.totalRural.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRural.dataEntryErrorMessage && _sectionModel.bpRural.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpRuralText}}: {{_sectionModel.bpRural.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalNonRural.dataEntryErrorMessage && _sectionModel.totalNonRural.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalNonRuralText}}: {{_sectionModel.totalNonRural.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpNonRural.dataEntryErrorMessage && _sectionModel.bpNonRural.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpNonRuralText}}: {{_sectionModel.bpNonRural.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalGeoMissing.dataEntryErrorMessage && _sectionModel.totalGeoMissing.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalGeoMissingText}}: {{_sectionModel.totalGeoMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpGeoMissing.dataEntryErrorMessage && _sectionModel.bpGeoMissing.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpGeoMissingText}}: {{_sectionModel.bpGeoMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.allGeoTotal.dataEntryErrorMessage && _sectionModel.allGeoTotal.questionStatus == statusEnum.L2InProgressError" class="help-block">{{allGeoTotalText}}: {{_sectionModel.allGeoTotal.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpGeoTotal.dataEntryErrorMessage && _sectionModel.bpGeoTotal.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpGeoTotalText}}: {{_sectionModel.bpGeoTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="30%"></td>
                                <th width="15%" scope="col">Total</th>
                                <th width="15%" scope="col">At or Below Poverty</th>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Rural</th>
                                <td>
                                    <label for="totalRural" class="sr-only">{{totalRuralText}}</label>
                                    <div [ngClass]="(_sectionModel.totalRural.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalRural.elementValue" [disabled]="!_sectionModel.totalRural.isRequired"
                                            type="text" class="form-control" id="totalRural" (change)="unsavedChanges = true; _sectionModel.allGeoTotal.elementValue = updateTotal(allGeoTotalArr); _sectionModel.totalRural = validate(_sectionModel.totalRural); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpRural" class="sr-only">{{bpRuralText}}</label>
                                    <div [ngClass]="(_sectionModel.bpRural.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpRural.elementValue" [disabled]="!_sectionModel.bpRural.isRequired"
                                            type="text" class="form-control" id="bpRural" (change)="unsavedChanges = true; _sectionModel.bpGeoTotal.elementValue = updateTotal(bpGeoTotalArr); _sectionModel.bpRural = validate(_sectionModel.bpRural); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Non-rural</th>
                                <td>
                                    <label for="totalNonRural" class="sr-only">{{totalNonRuralText}}</label>
                                    <div [ngClass]="(_sectionModel.totalNonRural.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalNonRural.elementValue" [disabled]="!_sectionModel.totalNonRural.isRequired"
                                            type="text" class="form-control" id="totalNonRural" (change)="unsavedChanges = true; _sectionModel.allGeoTotal.elementValue = updateTotal(allGeoTotalArr); _sectionModel.totalNonRural = validate(_sectionModel.totalNonRural); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpNonRural" class="sr-only">{{bpNonRuralText}}</label>
                                    <div [ngClass]="(_sectionModel.bpNonRural.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpNonRural.elementValue" [disabled]="!_sectionModel.bpNonRural.isRequired"
                                            type="text" class="form-control" id="bpNonRural" (change)="unsavedChanges = true; _sectionModel.bpGeoTotal.elementValue = updateTotal(bpGeoTotalArr); _sectionModel.bpNonRural = validate(_sectionModel.bpNonRural); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Geographic distribution missing</th>
                                <td>
                                    <label for="totalGeoMissing" class="sr-only">{{totalGeoMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.totalGeoMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalGeoMissing.elementValue" [disabled]="!_sectionModel.totalGeoMissing.isRequired"
                                            type="text" class="form-control" id="totalGeoMissing" (change)="unsavedChanges = true; _sectionModel.allGeoTotal.elementValue = updateTotal(allGeoTotalArr); _sectionModel.totalGeoMissing = validate(_sectionModel.totalGeoMissing); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpGeoMissing" class="sr-only">{{bpGeoMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.bpGeoMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpGeoMissing.elementValue" [disabled]="!_sectionModel.bpGeoMissing.isRequired"
                                            type="text" class="form-control" id="bpGeoMissing" (change)="unsavedChanges = true; _sectionModel.bpGeoTotal.elementValue = updateTotal(bpGeoTotalArr); _sectionModel.bpGeoMissing = validate(_sectionModel.bpGeoMissing); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Total Persons Served</th>
                                <td>
                                    <label for="allGeoTotal" class="sr-only">{{allGeoTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.allGeoTotal.elementValue"
                                        [disabled]="!_sectionModel.allGeoTotal.isRequired" type="text" class="form-control" id="allGeoTotal"
                                        readonly>
                                </td>
                                <td>
                                    <label for="bpGeoTotal" class="sr-only">{{bpGeoTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.bpGeoTotal.elementValue"
                                        [disabled]="!_sectionModel.bpGeoTotal.isRequired" type="text" class="form-control" id="bpGeoTotal"
                                        readonly>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--  end table responsive  -->
                <div class="table-responsive">
                    <div class="col-md-12 background-gray-lighter mb-1">
                        <h2>Poverty Status Distribution</h2>
                    </div>
                    <table class="table table-no-border">
                        <tbody>
                            <tr *ngIf="povertyWarning()">
                                <td class="has-warning" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                                    <p *ngIf="!_sectionModel.totalBelowPoverty.dataEntryErrorMessage && _sectionModel.totalBelowPoverty.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{totalBelowPovertyText}}: {{_sectionModel.totalBelowPoverty.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalAbovePoverty.dataEntryErrorMessage && _sectionModel.totalAbovePoverty.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{totalAbovePovertyText}}: {{_sectionModel.totalAbovePoverty.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalMissingPoverty.dataEntryErrorMessage && _sectionModel.totalMissingPoverty.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{totalMissingPovertyText}}: {{_sectionModel.totalMissingPoverty.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.allPovertyTotal.dataEntryErrorMessage && _sectionModel.allPovertyTotal.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{allPovertyTotalText}}: {{_sectionModel.allPovertyTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr *ngIf="povertyError()">
                                <td class="has-error" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                                    <p *ngIf="_sectionModel.totalBelowPoverty.dataEntryErrorMessage" class="help-block">{{totalBelowPovertyText}}: {{_sectionModel.totalBelowPoverty.dataEntryErrorMessage}}
                                        </p>
                                    <p *ngIf="_sectionModel.totalAbovePoverty.dataEntryErrorMessage" class="help-block">{{totalAbovePovertyText}}: {{_sectionModel.totalAbovePoverty.dataEntryErrorMessage}}
                                        </p>
                                    <p *ngIf="_sectionModel.totalMissingPoverty.dataEntryErrorMessage" class="help-block">{{totalMissingPovertyText}}: {{_sectionModel.totalMissingPoverty.dataEntryErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.totalBelowPoverty.dataEntryErrorMessage && _sectionModel.totalBelowPoverty.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{totalBelowPovertyText}}: {{_sectionModel.totalBelowPoverty.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalAbovePoverty.dataEntryErrorMessage && _sectionModel.totalAbovePoverty.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{totalAbovePovertyText}}: {{_sectionModel.totalAbovePoverty.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalMissingPoverty.dataEntryErrorMessage && _sectionModel.totalMissingPoverty.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{totalMissingPovertyText}}: {{_sectionModel.totalMissingPoverty.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.allPovertyTotal.dataEntryErrorMessage && _sectionModel.allPovertyTotal.questionStatus == statusEnum.L2InProgressError" class="help-block">{{allPovertyTotalText}}: {{_sectionModel.allPovertyTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr>
                              <td width="30%"></td>
                              <th width="15%" scope="col">Total</th>
                              <th width="15%" scope="col"></th>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">At or below poverty</th>
                                <td>
                                    <label for="totalBelowPoverty" class="sr-only">{{totalBelowPovertyText}}</label>
                                    <div [ngClass]="(_sectionModel.totalBelowPoverty.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalBelowPoverty.elementValue" [disabled]="!_sectionModel.totalBelowPoverty.isRequired"
                                            type="text" class="form-control" id="totalBelowPoverty" (change)="unsavedChanges = true; _sectionModel.allPovertyTotal.elementValue = updateTotal(allPovertyTotalArr); _sectionModel.totalBelowPoverty = validate(_sectionModel.totalBelowPoverty); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Above poverty</th>
                                <td>
                                    <label for="totalAbovePoverty" class="sr-only">{{totalAbovePovertyText}}</label>
                                    <div [ngClass]="(_sectionModel.totalAbovePoverty.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalAbovePoverty.elementValue" [disabled]="!_sectionModel.totalAbovePoverty.isRequired"
                                            type="text" class="form-control" id="totalAbovePoverty" (change)="unsavedChanges = true; _sectionModel.allPovertyTotal.elementValue = updateTotal(allPovertyTotalArr); _sectionModel.totalAbovePoverty = validate(_sectionModel.totalAbovePoverty); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Poverty status missing</th>
                                <td>
                                    <label for="totalMissingPoverty" class="sr-only">{{totalMissingPovertyText}}</label>
                                    <div [ngClass]="(_sectionModel.totalMissingPoverty.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalMissingPoverty.elementValue" [disabled]="!_sectionModel.totalMissingPoverty.isRequired"
                                            type="text" class="form-control" id="totalMissingPoverty" (change)="unsavedChanges = true; _sectionModel.allPovertyTotal.elementValue = updateTotal(allPovertyTotalArr); _sectionModel.totalMissingPoverty = validate(_sectionModel.totalMissingPoverty); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Total Persons Served</th>
                                <td>
                                    <label for="allPovertyTotal" class="sr-only">{{allPovertyTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.allPovertyTotal.elementValue"
                                        [disabled]="!_sectionModel.allPovertyTotal.isRequired" type="text" class="form-control"
                                        id="allPovertyTotal" readonly>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--  end table responsive  -->
                <div class="table-responsive">
                    <div class="col-md-12 background-gray-lighter mb-1">
                        <h2>Ethnicity Distribution</h2>
                    </div>
                    <table class="table table-no-border">
                        <tbody>
                            <tr *ngIf="ethnicityWarning()">
                                <td class="has-warning" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                                    <p *ngIf="!_sectionModel.totalHispanic.dataEntryErrorMessage && _sectionModel.totalHispanic.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalHispanicText}}: {{_sectionModel.totalHispanic.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpHispanic.dataEntryErrorMessage && _sectionModel.bpHispanic.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpHispanicText}}: {{_sectionModel.bpHispanic.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalNonHispanic.dataEntryErrorMessage && _sectionModel.totalNonHispanic.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalNonHispanicText}}: {{_sectionModel.totalNonHispanic.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpNonHispanic.dataEntryErrorMessage && _sectionModel.bpNonHispanic.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpNonHispanicText}}: {{_sectionModel.bpNonHispanic.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalEthnicityMissing.dataEntryErrorMessage && _sectionModel.totalEthnicityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{totalEthnicityMissingText}}: {{_sectionModel.totalEthnicityMissing.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.bpEthnicityMissing.dataEntryErrorMessage && _sectionModel.bpEthnicityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{bpEthnicityMissingText}}: {{_sectionModel.bpEthnicityMissing.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.allEthnicityTotal.dataEntryErrorMessage && _sectionModel.allEthnicityTotal.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{allEthnicityTotalText}}: {{_sectionModel.allEthnicityTotal.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpEthnicityTotal.dataEntryErrorMessage && _sectionModel.bpEthnicityTotal.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpEthnicityTotalText}}: {{_sectionModel.bpEthnicityTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr *ngIf="ethnicityError()">
                                <td class="has-error" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                                    <p *ngIf="_sectionModel.totalHispanic.dataEntryErrorMessage" class="help-block">{{totalHispanicText}}: {{_sectionModel.totalHispanic.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpHispanic.dataEntryErrorMessage" class="help-block">{{bpHispanicText}}: {{_sectionModel.bpHispanic.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalNonHispanic.dataEntryErrorMessage" class="help-block">{{totalNonHispanicText}}: {{_sectionModel.totalNonHispanic.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpNonHispanic.dataEntryErrorMessage" class="help-block">{{bpNonHispanicText}}: {{_sectionModel.bpNonHispanic.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalEthnicityMissing.dataEntryErrorMessage" class="help-block">{{totalEthnicityMissingText}}: {{_sectionModel.totalEthnicityMissing.dataEntryErrorMessage}}
                                        </p>
                                    <p *ngIf="_sectionModel.bpEthnicityMissing.dataEntryErrorMessage" class="help-block">{{bpEthnicityMissingText}}: {{_sectionModel.bpEthnicityMissing.dataEntryErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.totalHispanic.dataEntryErrorMessage && _sectionModel.totalHispanic.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalHispanicText}}: {{_sectionModel.totalHispanic.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpHispanic.dataEntryErrorMessage && _sectionModel.bpHispanic.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpHispanicText}}: {{_sectionModel.bpHispanic.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalNonHispanic.dataEntryErrorMessage && _sectionModel.totalNonHispanic.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalNonHispanicText}}: {{_sectionModel.totalNonHispanic.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpNonHispanic.dataEntryErrorMessage && _sectionModel.bpNonHispanic.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpNonHispanicText}}: {{_sectionModel.bpNonHispanic.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalEthnicityMissing.dataEntryErrorMessage && _sectionModel.totalEthnicityMissing.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{totalEthnicityMissingText}}: {{_sectionModel.totalEthnicityMissing.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.bpEthnicityMissing.dataEntryErrorMessage && _sectionModel.bpEthnicityMissing.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{bpEthnicityMissingText}}: {{_sectionModel.bpEthnicityMissing.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.allEthnicityTotal.dataEntryErrorMessage && _sectionModel.allEthnicityTotal.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{allEthnicityTotalText}}: {{_sectionModel.allEthnicityTotal.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpEthnicityTotal.dataEntryErrorMessage && _sectionModel.bpEthnicityTotal.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpEthnicityTotalText}}: {{_sectionModel.bpEthnicityTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="30%"></td>
                                <th width="15%" scope="col">Total</th>
                                <th width="15%" scope="col">At or Below Poverty</th>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Hispanic or Latino</th>
                                <td>
                                    <label for="totalHispanic" class="sr-only">{{totalHispanicText}}</label>
                                    <div [ngClass]="(_sectionModel.totalHispanic.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalHispanic.elementValue" [disabled]="!_sectionModel.totalHispanic.isRequired"
                                            type="text" class="form-control" id="totalHispanic" (change)="unsavedChanges = true; _sectionModel.allEthnicityTotal.elementValue = updateTotal(allEthnicityTotalArr); _sectionModel.totalHispanic = validate(_sectionModel.totalHispanic); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpHispanic" class="sr-only">{{bpHispanicText}}</label>
                                    <div [ngClass]="(_sectionModel.bpHispanic.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpHispanic.elementValue" [disabled]="!_sectionModel.bpHispanic.isRequired"
                                            type="text" class="form-control" id="bpHispanic" (change)="unsavedChanges = true; _sectionModel.bpEthnicityTotal.elementValue = updateTotal(bpEthnicityTotalArr); _sectionModel.bpHispanic = validate(_sectionModel.bpHispanic); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Not Hispanic or Latino</th>
                                <td>
                                    <label for="totalNonHispanic" class="sr-only">{{totalNonHispanicText}}</label>
                                    <div [ngClass]="(_sectionModel.totalNonHispanic.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalNonHispanic.elementValue" [disabled]="!_sectionModel.totalNonHispanic.isRequired"
                                            type="text" class="form-control" id="totalNonHispanic" (change)="unsavedChanges = true; _sectionModel.allEthnicityTotal.elementValue = updateTotal(allEthnicityTotalArr); _sectionModel.totalNonHispanic = validate(_sectionModel.totalNonHispanic); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpNonHispanic" class="sr-only">{{bpNonHispanicText}}</label>
                                    <div [ngClass]="(_sectionModel.bpNonHispanic.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpNonHispanic.elementValue" [disabled]="!_sectionModel.bpNonHispanic.isRequired"
                                            type="text" class="form-control" id="bpNonHispanic" (change)="unsavedChanges = true; _sectionModel.bpEthnicityTotal.elementValue = updateTotal(bpEthnicityTotalArr); _sectionModel.bpNonHispanic = validate(_sectionModel.bpNonHispanic); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Ethnicity missing</th>
                                <td>
                                    <label for="totalEthnicityMissing" class="sr-only">{{totalEthnicityMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.totalEthnicityMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalEthnicityMissing.elementValue" [disabled]="!_sectionModel.totalEthnicityMissing.isRequired"
                                            type="text" class="form-control" id="totalEthnicityMissing" (change)="unsavedChanges = true; _sectionModel.allEthnicityTotal.elementValue = updateTotal(allEthnicityTotalArr); _sectionModel.totalEthnicityMissing = validate(_sectionModel.totalEthnicityMissing); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpEthnicityMissing" class="sr-only">{{bpEthnicityMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.bpEthnicityMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpEthnicityMissing.elementValue" [disabled]="!_sectionModel.bpEthnicityMissing.isRequired"
                                            type="text" class="form-control" id="bpEthnicityMissing" (change)="unsavedChanges = true; _sectionModel.bpEthnicityTotal.elementValue = updateTotal(bpEthnicityTotalArr); _sectionModel.bpEthnicityMissing = validate(_sectionModel.bpEthnicityMissing); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Total Persons Served</th>
                                <td>
                                    <label for="allEthnicityTotal" class="sr-only">{{allEthnicityTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.allEthnicityTotal.elementValue"
                                        [disabled]="!_sectionModel.allEthnicityTotal.isRequired" type="text" class="form-control"
                                        id="allEthnicityTotal" readonly>
                                </td>
                                <td>
                                    <label for="bpEthnicityTotal" class="sr-only">{{bpEthnicityTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.bpEthnicityTotal.elementValue"
                                        [disabled]="!_sectionModel.bpEthnicityTotal.isRequired" type="text" class="form-control"
                                        id="bpEthnicityTotal" readonly>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--  end table responsive  -->
                <div class="table-responsive">
                    <div class="col-md-12 background-gray-lighter mb-1">
                        <h2>Race Distribution</h2>
                    </div>
                    <table class="table table-no-border">
                        <tbody>
                            <tr *ngIf="raceWarning()">
                                <td class="has-warning" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                                    <p *ngIf="!_sectionModel.totalAmericanIndian.dataEntryErrorMessage && _sectionModel.totalAmericanIndian.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{totalAmericanIndianText}}: {{_sectionModel.totalAmericanIndian.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.bpAmericanIndian.dataEntryErrorMessage && _sectionModel.bpAmericanIndian.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpAmericanIndianText}}: {{_sectionModel.bpAmericanIndian.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalAsian.dataEntryErrorMessage && _sectionModel.totalAsian.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalAsianText}}: {{_sectionModel.totalAsian.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpAsian.dataEntryErrorMessage && _sectionModel.bpAsian.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpAsianText}}: {{_sectionModel.bpAsian.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalBlack.dataEntryErrorMessage && _sectionModel.totalBlack.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalBlackText}}: {{_sectionModel.totalBlack.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpBlack.dataEntryErrorMessage && _sectionModel.bpBlack.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpBlackText}}: {{_sectionModel.bpBlack.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalIslander.dataEntryErrorMessage && _sectionModel.totalIslander.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalIslanderText}}: {{_sectionModel.totalIslander.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpIslander.dataEntryErrorMessage && _sectionModel.bpIslander.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpIslanderText}}: {{_sectionModel.bpIslander.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalWhite.dataEntryErrorMessage && _sectionModel.totalWhite.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalWhiteText}}: {{_sectionModel.totalWhite.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpWhite.dataEntryErrorMessage && _sectionModel.bpWhite.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpWhiteText}}: {{_sectionModel.bpWhite.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRaceOther.dataEntryErrorMessage && _sectionModel.totalRaceOther.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalRaceOtherText}}: {{_sectionModel.totalRaceOther.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRaceOther.dataEntryErrorMessage && _sectionModel.bpRaceOther.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpRaceOtherText}}: {{_sectionModel.bpRaceOther.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRaceMultiple.dataEntryErrorMessage && _sectionModel.totalRaceMultiple.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalRaceMultipleText}}: {{_sectionModel.totalRaceMultiple.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRaceMultiple.dataEntryErrorMessage && _sectionModel.bpRaceMultiple.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpRaceMultipleText}}: {{_sectionModel.bpRaceMultiple.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRaceMissing.dataEntryErrorMessage && _sectionModel.totalRaceMissing.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalRaceMissingText}}: {{_sectionModel.totalRaceMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRaceMissing.dataEntryErrorMessage && _sectionModel.bpRaceMissing.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpRaceMissingText}}: {{_sectionModel.bpRaceMissing.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr *ngIf="raceError()">
                                <td class="has-error" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                                    <p *ngIf="_sectionModel.totalAmericanIndian.dataEntryErrorMessage" class="help-block">{{totalAmericanIndianText}}: {{_sectionModel.totalAmericanIndian.dataEntryErrorMessage}}
                                        </p>
                                    <p *ngIf="_sectionModel.bpAmericanIndian.dataEntryErrorMessage" class="help-block">{{bpAmericanIndianText}}: {{_sectionModel.bpAmericanIndian.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalAsian.dataEntryErrorMessage" class="help-block">{{totalAsianText}}: {{_sectionModel.totalAsian.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpAsian.dataEntryErrorMessage" class="help-block">{{bpAsianText}}: {{_sectionModel.bpAsian.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalBlack.dataEntryErrorMessage" class="help-block">{{totalBlackText}}: {{_sectionModel.totalBlack.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpBlack.dataEntryErrorMessage" class="help-block">{{bpBlackText}}: {{_sectionModel.bpBlack.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalIslander.dataEntryErrorMessage" class="help-block">{{totalIslanderText}}: {{_sectionModel.totalIslander.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpIslander.dataEntryErrorMessage" class="help-block">{{bpIslanderText}}: {{_sectionModel.bpIslander.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalWhite.dataEntryErrorMessage" class="help-block">{{totalWhiteText}}: {{_sectionModel.totalWhite.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpWhite.dataEntryErrorMessage" class="help-block">{{bpWhiteText}}: {{_sectionModel.bpWhite.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalRaceOther.dataEntryErrorMessage" class="help-block">{{totalRaceOtherText}}: {{_sectionModel.totalRaceOther.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpRaceOther.dataEntryErrorMessage" class="help-block">{{bpRaceOtherText}}: {{_sectionModel.bpRaceOther.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalRaceMultiple.dataEntryErrorMessage" class="help-block">{{totalRaceMultipleText}}: {{_sectionModel.totalRaceMultiple.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpRaceMultiple.dataEntryErrorMessage" class="help-block">{{bpRaceMultipleText}}: {{_sectionModel.bpRaceMultiple.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalRaceMissing.dataEntryErrorMessage" class="help-block">{{totalRaceMissingText}}: {{_sectionModel.totalRaceMissing.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpRaceMissing.dataEntryErrorMessage" class="help-block">{{bpRaceMissingText}}: {{_sectionModel.bpRaceMissing.dataEntryErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalAmericanIndian.dataEntryErrorMessage && _sectionModel.totalAmericanIndian.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{totalAmericanIndianText}}: {{_sectionModel.totalAmericanIndian.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.bpAmericanIndian.dataEntryErrorMessage && _sectionModel.bpAmericanIndian.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpAmericanIndianText}}: {{_sectionModel.bpAmericanIndian.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalAsian.dataEntryErrorMessage && _sectionModel.totalAsian.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalAsianText}}: {{_sectionModel.totalAsian.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpAsian.dataEntryErrorMessage && _sectionModel.bpAsian.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpAsianText}}: {{_sectionModel.bpAsian.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalBlack.dataEntryErrorMessage && _sectionModel.totalBlack.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalBlackText}}: {{_sectionModel.totalBlack.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpBlack.dataEntryErrorMessage && _sectionModel.bpBlack.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpBlackText}}: {{_sectionModel.bpBlack.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalIslander.dataEntryErrorMessage && _sectionModel.totalIslander.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalIslanderText}}: {{_sectionModel.totalIslander.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpIslander.dataEntryErrorMessage && _sectionModel.bpIslander.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpIslanderText}}: {{_sectionModel.bpIslander.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalWhite.dataEntryErrorMessage && _sectionModel.totalWhite.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalWhiteText}}: {{_sectionModel.totalWhite.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpWhite.dataEntryErrorMessage && _sectionModel.bpWhite.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpWhiteText}}: {{_sectionModel.bpWhite.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRaceOther.dataEntryErrorMessage && _sectionModel.totalRaceOther.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalRaceOtherText}}: {{_sectionModel.totalRaceOther.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRaceOther.dataEntryErrorMessage && _sectionModel.bpRaceOther.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpRaceOtherText}}: {{_sectionModel.bpRaceOther.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRaceMultiple.dataEntryErrorMessage && _sectionModel.totalRaceMultiple.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalRaceMultipleText}}: {{_sectionModel.totalRaceMultiple.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRaceMultiple.dataEntryErrorMessage && _sectionModel.bpRaceMultiple.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpRaceMultipleText}}: {{_sectionModel.bpRaceMultiple.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRaceMissing.dataEntryErrorMessage && _sectionModel.totalRaceMissing.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalRaceMissingText}}: {{_sectionModel.totalRaceMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRaceMissing.dataEntryErrorMessage && _sectionModel.bpRaceMissing.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpRaceMissingText}}: {{_sectionModel.bpRaceMissing.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="30%"></td>
                                <th width="15%" scope="col">Total</th>
                                <th width="15%" scope="col">At or Below Poverty</th>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">American Indian or Alaska Native</th>
                                <td>
                                    <label for="totalAmericanIndian" class="sr-only">{{totalAmericanIndianText}}</label>
                                    <div [ngClass]="(_sectionModel.totalAmericanIndian.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalAmericanIndian.elementValue" [disabled]="!_sectionModel.totalAmericanIndian.isRequired"
                                            type="text" class="form-control" id="totalAmericanIndian" (change)="unsavedChanges = true; _sectionModel.totalAmericanIndian.elementValue = addComma(_sectionModel.totalAmericanIndian.elementValue); _sectionModel.totalAmericanIndian = validate(_sectionModel.totalAmericanIndian); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpAmericanIndian" class="sr-only">{{bpAmericanIndianText}}</label>
                                    <div [ngClass]="(_sectionModel.bpAmericanIndian.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpAmericanIndian.elementValue" [disabled]="!_sectionModel.bpAmericanIndian.isRequired"
                                            type="text" class="form-control" id="bpAmericanIndian" (change)="unsavedChanges = true; _sectionModel.bpAmericanIndian.elementValue = addComma(_sectionModel.bpAmericanIndian.elementValue); _sectionModel.bpAmericanIndian = validate(_sectionModel.bpAmericanIndian); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Asian or Asian American</th>
                                <td>
                                    <label for="totalAsian" class="sr-only">{{totalAsianText}}</label>
                                    <div [ngClass]="(_sectionModel.totalAsian.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalAsian.elementValue" [disabled]="!_sectionModel.totalAsian.isRequired"
                                            type="text" class="form-control" id="totalAsian" (change)="unsavedChanges = true; _sectionModel.totalAsian.elementValue = addComma(_sectionModel.totalAsian.elementValue); _sectionModel.totalAsian = validate(_sectionModel.totalAsian); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpAsian" class="sr-only">{{bpAsianText}}</label>
                                    <div [ngClass]="(_sectionModel.bpAsian.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpAsian.elementValue" [disabled]="!_sectionModel.bpAsian.isRequired"
                                            type="text" class="form-control" id="bpAsian" (change)="unsavedChanges = true; _sectionModel.bpAsian.elementValue = addComma(_sectionModel.bpAsian.elementValue); _sectionModel.bpAsian = validate(_sectionModel.bpAsian); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Black or African American</th>
                                <td>
                                    <label for="totalBlack" class="sr-only">{{totalBlackText}}</label>
                                    <div [ngClass]="(_sectionModel.totalBlack.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalBlack.elementValue" [disabled]="!_sectionModel.totalBlack.isRequired"
                                            type="text" class="form-control" id="totalBlack" (change)="unsavedChanges = true; _sectionModel.totalBlack.elementValue = addComma(_sectionModel.totalBlack.elementValue); _sectionModel.totalBlack = validate(_sectionModel.totalBlack); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpBlack" class="sr-only">{{bpBlackText}}</label>
                                    <div [ngClass]="(_sectionModel.bpBlack.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpBlack.elementValue" [disabled]="!_sectionModel.bpBlack.isRequired"
                                            type="text" class="form-control" id="bpBlack" (change)="unsavedChanges = true; _sectionModel.bpBlack.elementValue = addComma(_sectionModel.bpBlack.elementValue); _sectionModel.bpBlack = validate(_sectionModel.bpBlack); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Native Hawaiian or Pacific Islander</th>
                                <td>
                                    <label for="totalIslander" class="sr-only">{{totalIslanderText}}</label>
                                    <div [ngClass]="(_sectionModel.totalIslander.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalIslander.elementValue" [disabled]="!_sectionModel.totalIslander.isRequired"
                                            type="text" class="form-control" id="totalIslander" (change)="unsavedChanges = true; _sectionModel.totalIslander.elementValue = addComma(_sectionModel.totalIslander.elementValue); _sectionModel.totalIslander = validate(_sectionModel.totalIslander); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpIslander" class="sr-only">{{bpIslanderText}}</label>
                                    <div [ngClass]="(_sectionModel.bpIslander.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpIslander.elementValue" [disabled]="!_sectionModel.bpIslander.isRequired"
                                            type="text" class="form-control" id="bpIslander" (change)="unsavedChanges = true; _sectionModel.bpIslander.elementValue = addComma(_sectionModel.bpIslander.elementValue); _sectionModel.bpIslander = validate(_sectionModel.bpIslander); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">White</th>
                                <td>
                                    <label for="totalWhite" class="sr-only">{{totalWhiteText}}</label>
                                    <div [ngClass]="(_sectionModel.totalWhite.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalWhite.elementValue" [disabled]="!_sectionModel.totalWhite.isRequired"
                                            type="text" class="form-control" id="totalWhite" (change)="unsavedChanges = true; _sectionModel.totalWhite.elementValue = addComma(_sectionModel.totalWhite.elementValue); _sectionModel.totalWhite = validate(_sectionModel.totalWhite); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpWhite" class="sr-only">{{bpWhiteText}}</label>
                                    <div [ngClass]="(_sectionModel.bpWhite.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpWhite.elementValue" [disabled]="!_sectionModel.bpWhite.isRequired"
                                            type="text" class="form-control" id="bpWhite" (change)="unsavedChanges = true; _sectionModel.bpWhite.elementValue = addComma(_sectionModel.bpWhite.elementValue); _sectionModel.bpWhite = validate(_sectionModel.bpWhite); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Persons reporting some other race</th>
                                <td>
                                    <label for="totalRaceOther" class="sr-only">{{totalRaceOtherText}}</label>
                                    <div [ngClass]="(_sectionModel.totalRaceOther.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalRaceOther.elementValue" [disabled]="!_sectionModel.totalRaceOther.isRequired"
                                            type="text" class="form-control" id="totalRaceOther" (change)="unsavedChanges = true; _sectionModel.totalRaceOther.elementValue = addComma(_sectionModel.totalRaceOther.elementValue); _sectionModel.totalRaceOther = validate(_sectionModel.totalRaceOther); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpRaceOther" class="sr-only">{{bpRaceOtherText}}</label>
                                    <div [ngClass]="(_sectionModel.bpRaceOther.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpRaceOther.elementValue" [disabled]="!_sectionModel.bpRaceOther.isRequired"
                                            type="text" class="form-control" id="bpRaceOther" (change)="unsavedChanges = true; _sectionModel.bpRaceOther.elementValue = addComma(_sectionModel.bpRaceOther.elementValue); _sectionModel.bpRaceOther = validate(_sectionModel.bpRaceOther); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Persons reporting 2 or more races (unduplicated count)</th>
                                <td>
                                    <label for="totalRaceMultiple" class="sr-only">{{totalRaceMultipleText}}</label>
                                    <div [ngClass]="(_sectionModel.totalRaceMultiple.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalRaceMultiple.elementValue" [disabled]="!_sectionModel.totalRaceMultiple.isRequired"
                                            type="text" class="form-control" id="totalRaceMultiple" (change)="unsavedChanges = true; _sectionModel.totalRaceMultiple.elementValue = addComma(_sectionModel.totalRaceMultiple.elementValue); _sectionModel.totalRaceMultiple = validate(_sectionModel.totalRaceMultiple); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpRaceMultiple" class="sr-only">{{bpRaceMultipleText}}</label>
                                    <div [ngClass]="(_sectionModel.bpRaceMultiple.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpRaceMultiple.elementValue" [disabled]="!_sectionModel.bpRaceMultiple.isRequired"
                                            type="text" class="form-control" id="bpRaceMultiple" (change)="unsavedChanges = true; _sectionModel.bpRaceMultiple.elementValue = addComma(_sectionModel.bpRaceMultiple.elementValue); _sectionModel.bpRaceMultiple = validate(_sectionModel.bpRaceMultiple); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Race missing</th>
                                <td>
                                    <label for="totalRaceMissing" class="sr-only">{{totalRaceMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.totalRaceMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalRaceMissing.elementValue" [disabled]="!_sectionModel.totalRaceMissing.isRequired"
                                            type="text" class="form-control" id="totalRaceMissing" (change)="unsavedChanges = true; _sectionModel.totalRaceMissing.elementValue = addComma(_sectionModel.totalRaceMissing.elementValue); _sectionModel.totalRaceMissing = validate(_sectionModel.totalRaceMissing); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpRaceMissing" class="sr-only">{{bpRaceMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.bpRaceMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpRaceMissing.elementValue" [disabled]="!_sectionModel.bpRaceMissing.isRequired"
                                            type="text" class="form-control" id="bpRaceMissing" (change)="unsavedChanges = true; _sectionModel.bpRaceMissing.elementValue = addComma(_sectionModel.bpRaceMissing.elementValue); _sectionModel.bpRaceMissing = validate(_sectionModel.bpRaceMissing); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--  end table responsive  -->
                <div class="table-responsive">
                    <div class="col-md-12 background-gray-lighter mb-1">
                        <h2>Minority Status Distribution</h2>
                    </div>
                    <table class="table table-no-border">
                        <tbody>
                            <tr *ngIf="minorityWarning()">
                                <td class="has-warning" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                                    <p *ngIf="!_sectionModel.totalMinority.dataEntryErrorMessage && _sectionModel.totalMinority.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalMinorityText}}: {{_sectionModel.totalMinority.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpMinority.dataEntryErrorMessage && _sectionModel.bpMinority.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpMinorityText}}: {{_sectionModel.bpMinority.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalNotMinority.dataEntryErrorMessage && _sectionModel.totalNotMinority.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalNotMinorityText}}: {{_sectionModel.totalNotMinority.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpNotMinority.dataEntryErrorMessage && _sectionModel.bpNotMinority.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpNotMinorityText}}: {{_sectionModel.bpNotMinority.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalMinorityMissing.dataEntryErrorMessage && _sectionModel.totalMinorityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{totalMinorityMissingText}}: {{_sectionModel.totalMinorityMissing.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.bpMinorityMissing.dataEntryErrorMessage && _sectionModel.bpMinorityMissing.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{bpMinorityMissingText}}: {{_sectionModel.bpMinorityMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.allMinorityTotal.dataEntryErrorMessage && _sectionModel.allMinorityTotal.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{allMinorityTotalText}}: {{_sectionModel.allMinorityTotal.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpMinorityTotal.dataEntryErrorMessage && _sectionModel.bpMinorityTotal.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpMinorityTotalText}}: {{_sectionModel.bpMinorityTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr *ngIf="minorityError()">
                                <td class="has-error" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                                    <p *ngIf="_sectionModel.totalMinority.dataEntryErrorMessage" class="help-block">{{totalMinorityText}}: {{_sectionModel.totalMinority.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpMinority.dataEntryErrorMessage" class="help-block">{{bpMinorityText}}: {{_sectionModel.bpMinority.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalNotMinority.dataEntryErrorMessage" class="help-block">{{totalNotMinorityText}}: {{_sectionModel.totalNotMinority.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpNotMinority.dataEntryErrorMessage" class="help-block">{{bpNotMinorityText}}: {{_sectionModel.bpNotMinority.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalMinorityMissing.dataEntryErrorMessage" class="help-block">{{totalMinorityMissingText}}: {{_sectionModel.totalMinorityMissing.dataEntryErrorMessage}}
                                        </p>
                                    <p *ngIf="_sectionModel.bpMinorityMissing.dataEntryErrorMessage" class="help-block">{{bpMinorityMissingText}}: {{_sectionModel.bpMinorityMissing.dataEntryErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.totalMinority.dataEntryErrorMessage && _sectionModel.totalMinority.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalMinorityText}}: {{_sectionModel.totalMinority.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpMinority.dataEntryErrorMessage && _sectionModel.bpMinority.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpMinorityText}}: {{_sectionModel.bpMinority.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalNotMinority.dataEntryErrorMessage && _sectionModel.totalNotMinority.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalNotMinorityText}}: {{_sectionModel.totalNotMinority.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpNotMinority.dataEntryErrorMessage && _sectionModel.bpNotMinority.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpNotMinorityText}}: {{_sectionModel.bpNotMinority.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalMinorityMissing.dataEntryErrorMessage && _sectionModel.totalMinorityMissing.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{totalMinorityMissingText}}: {{_sectionModel.totalMinorityMissing.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.bpMinorityMissing.dataEntryErrorMessage && _sectionModel.bpMinorityMissing.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{bpMinorityMissingText}}: {{_sectionModel.bpMinorityMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.allMinorityTotal.dataEntryErrorMessage && _sectionModel.allMinorityTotal.questionStatus == statusEnum.L2InProgressError" class="help-block">{{allMinorityTotalText}}: {{_sectionModel.allMinorityTotal.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpMinorityTotal.dataEntryErrorMessage && _sectionModel.bpMinorityTotal.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpMinorityTotalText}}: {{_sectionModel.bpMinorityTotal.businessErrorMessage}} </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="30%"></td>
                                <th width="15%" scope="col">Total</th>
                                <th width="15%" scope="col">At or Below Poverty</th>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Minority</th>
                                <td>
                                    <label for="totalMinority" class="sr-only">{{totalMinorityText}}</label>
                                    <div [ngClass]="(_sectionModel.totalMinority.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalMinority.elementValue" [disabled]="!_sectionModel.totalMinority.isRequired"
                                            type="text" class="form-control" id="totalMinority" (change)="unsavedChanges = true; _sectionModel.allMinorityTotal.elementValue = updateTotal(allMinorityTotalArr); _sectionModel.totalMinority = validate(_sectionModel.totalMinority); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpMinority" class="sr-only">{{bpMinorityText}}</label>
                                    <div [ngClass]="(_sectionModel.bpMinority.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpMinority.elementValue" [disabled]="!_sectionModel.bpMinority.isRequired"
                                            type="text" class="form-control" id="bpMinority" (change)="unsavedChanges = true; _sectionModel.bpMinorityTotal.elementValue = updateTotal(bpMinorityTotalArr); _sectionModel.bpMinority = validate(_sectionModel.bpMinority); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Not minority</th>
                                <td>
                                    <label for="totalNotMinority" class="sr-only">{{totalNotMinorityText}}</label>
                                    <div [ngClass]="(_sectionModel.totalNotMinority.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalNotMinority.elementValue" [disabled]="!_sectionModel.totalNotMinority.isRequired"
                                            type="text" class="form-control" id="totalNotMinority" (change)="unsavedChanges = true; _sectionModel.allMinorityTotal.elementValue = updateTotal(allMinorityTotalArr); _sectionModel.totalNotMinority = validate(_sectionModel.totalNotMinority); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpNotMinority" class="sr-only">{{bpNotMinorityText}}</label>
                                    <div [ngClass]="(_sectionModel.bpNotMinority.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpNotMinority.elementValue" [disabled]="!_sectionModel.bpNotMinority.isRequired"
                                            type="text" class="form-control" id="bpNotMinority" (change)="unsavedChanges = true; _sectionModel.bpMinorityTotal.elementValue = updateTotal(bpMinorityTotalArr); _sectionModel.bpNotMinority = validate(_sectionModel.bpNotMinority); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Minority status missing</th>
                                <td>
                                    <label for="totalMinorityMissing" class="sr-only">{{totalMinorityMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.totalMinorityMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalMinorityMissing.elementValue" [disabled]="!_sectionModel.totalMinorityMissing.isRequired"
                                            type="text" class="form-control" id="totalMinorityMissing" (change)="unsavedChanges = true; _sectionModel.allMinorityTotal.elementValue = updateTotal(allMinorityTotalArr); _sectionModel.totalMinorityMissing = validate(_sectionModel.totalMinorityMissing); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpMinorityMissing" class="sr-only">{{bpMinorityMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.bpMinorityMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpMinorityMissing.elementValue" [disabled]="!_sectionModel.bpMinorityMissing.isRequired"
                                            type="text" class="form-control" id="bpMinorityMissing" (change)="unsavedChanges = true; _sectionModel.bpMinorityTotal.elementValue = updateTotal(bpMinorityTotalArr); _sectionModel.bpMinorityMissing = validate(_sectionModel.bpMinorityMissing); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Total Persons Served</th>
                                <td>
                                    <label for="allMinorityTotal" class="sr-only">{{allMinorityTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.allMinorityTotal.elementValue"
                                        [disabled]="!_sectionModel.allMinorityTotal.isRequired" type="text" class="form-control"
                                        id="allMinorityTotal" readonly>
                                </td>
                                <td>
                                    <label for="bpMinorityTotal" class="sr-only">{{bpMinorityTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.bpMinorityTotal.elementValue"
                                        [disabled]="!_sectionModel.bpMinorityTotal.isRequired" type="text" class="form-control"
                                        id="bpMinorityTotal" readonly>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--  end table responsive  -->
                <div class="table-responsive">
                    <div class="col-md-12 background-gray-lighter mb-1">
                        <h2>Relationship Distribution</h2>
                    </div>
                    <table class="table table-no-border">
                        <tbody>
                            <tr *ngIf="relationshipWarning()">
                                <td class="has-warning" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):</span>
                                    <p *ngIf="!_sectionModel.totalGrandparents.dataEntryErrorMessage && _sectionModel.totalGrandparents.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{totalGrandparentsText}}: {{_sectionModel.totalGrandparents.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpGrandparents.dataEntryErrorMessage && _sectionModel.bpGrandparents.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpGrandparentsText}}: {{_sectionModel.bpGrandparents.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalParents.dataEntryErrorMessage && _sectionModel.totalParents.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalParentsText}}: {{_sectionModel.totalParents.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpParents.dataEntryErrorMessage && _sectionModel.bpParents.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpParentsText}}: {{_sectionModel.bpParents.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalOtherRel.dataEntryErrorMessage && _sectionModel.totalOtherRel.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalOtherRelText}}: {{_sectionModel.totalOtherRel.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpOtherRel.dataEntryErrorMessage && _sectionModel.bpOtherRel.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpOtherRelText}}: {{_sectionModel.bpOtherRel.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalNon.dataEntryErrorMessage && _sectionModel.totalNon.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalNonText}}: {{_sectionModel.totalNon.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpNon.dataEntryErrorMessage && _sectionModel.bpNon.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpNonText}}: {{_sectionModel.bpNon.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRelMissing.dataEntryErrorMessage && _sectionModel.totalRelMissing.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{totalRelMissingText}}: {{_sectionModel.totalRelMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRelMissing.dataEntryErrorMessage && _sectionModel.bpRelMissing.questionStatus == statusEnum.L2ValidatedWarning" class="help-block">{{bpRelMissingText}}: {{_sectionModel.bpRelMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.allRelationshipTotal.dataEntryErrorMessage && _sectionModel.allRelationshipTotal.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{allRelationshipTotalText}}: {{_sectionModel.allRelationshipTotal.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.bpRelationshipTotal.dataEntryErrorMessage && _sectionModel.bpRelationshipTotal.questionStatus == statusEnum.L2ValidatedWarning"
                                        class="help-block">{{bpRelationshipTotalText}}: {{_sectionModel.bpRelationshipTotal.businessErrorMessage}}
                                        </p>

                                </td>
                            </tr>
                            <tr *ngIf="relationshipError()">
                                <td class="has-error" colspan="3">
                                    <span class="help-block">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i> Error(s):</span>
                                    <p *ngIf="_sectionModel.totalGrandparents.dataEntryErrorMessage" class="help-block">{{totalGrandparentsText}}: {{_sectionModel.totalGrandparents.dataEntryErrorMessage}}
                                        </p>
                                    <p *ngIf="_sectionModel.bpGrandparents.dataEntryErrorMessage" class="help-block">{{bpGrandparentsText}}: {{_sectionModel.bpGrandparents.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalParents.dataEntryErrorMessage" class="help-block">{{totalParentsText}}: {{_sectionModel.totalParents.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpParents.dataEntryErrorMessage" class="help-block">{{bpParentsText}}: {{_sectionModel.bpParents.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalOtherRel.dataEntryErrorMessage" class="help-block">{{totalOtherRelText}}: {{_sectionModel.totalOtherRel.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpOtherRel.dataEntryErrorMessage" class="help-block">{{bpOtherRelText}}: {{_sectionModel.bpOtherRel.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalNon.dataEntryErrorMessage" class="help-block">{{totalNonText}}: {{_sectionModel.totalNon.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpNon.dataEntryErrorMessage" class="help-block">{{bpNonText}}: {{_sectionModel.bpNon.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.totalRelMissing.dataEntryErrorMessage" class="help-block">{{totalRelMissingText}}: {{_sectionModel.totalRelMissing.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpRelMissing.dataEntryErrorMessage" class="help-block">{{bpRelMissingText}}: {{_sectionModel.bpRelMissing.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.allRelationshipTotal.dataEntryErrorMessage" class="help-block">{{bpRelMissingText}}: {{_sectionModel.allRelationshipTotal.dataEntryErrorMessage}} </p>
                                    <p *ngIf="_sectionModel.bpRelationshipTotal.dataEntryErrorMessage" class="help-block">{{totalRelMissingText}}: {{_sectionModel.bpRelationshipTotal.dataEntryErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.totalGrandparents.dataEntryErrorMessage && _sectionModel.totalGrandparents.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{totalGrandparentsText}}: {{_sectionModel.totalGrandparents.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpGrandparents.dataEntryErrorMessage && _sectionModel.bpGrandparents.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpGrandparentsText}}: {{_sectionModel.bpGrandparents.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalParents.dataEntryErrorMessage && _sectionModel.totalParents.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalParentsText}}: {{_sectionModel.totalParents.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpParents.dataEntryErrorMessage && _sectionModel.bpParents.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpParentsText}}: {{_sectionModel.bpParents.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalOtherRel.dataEntryErrorMessage && _sectionModel.totalOtherRel.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalOtherRelText}}: {{_sectionModel.totalOtherRel.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpOtherRel.dataEntryErrorMessage && _sectionModel.bpOtherRel.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpOtherRelText}}: {{_sectionModel.bpOtherRel.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalNon.dataEntryErrorMessage && _sectionModel.totalNon.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalNonText}}: {{_sectionModel.totalNon.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpNon.dataEntryErrorMessage && _sectionModel.bpNon.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpNonText}}: {{_sectionModel.bpNon.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.totalRelMissing.dataEntryErrorMessage && _sectionModel.totalRelMissing.questionStatus == statusEnum.L2InProgressError" class="help-block">{{totalRelMissingText}}: {{_sectionModel.totalRelMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.bpRelMissing.dataEntryErrorMessage && _sectionModel.bpRelMissing.questionStatus == statusEnum.L2InProgressError" class="help-block">{{bpRelMissingText}}: {{_sectionModel.bpRelMissing.businessErrorMessage}} </p>
                                    <p *ngIf="!_sectionModel.allRelationshipTotal.dataEntryErrorMessage && _sectionModel.allRelationshipTotal.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{allRelationshipTotalText}}: {{_sectionModel.allRelationshipTotal.businessErrorMessage}}
                                        </p>
                                    <p *ngIf="!_sectionModel.bpRelationshipTotal.dataEntryErrorMessage && _sectionModel.bpRelationshipTotal.questionStatus == statusEnum.L2InProgressError"
                                        class="help-block">{{bpRelationshipTotalText}}: {{_sectionModel.bpRelationshipTotal.businessErrorMessage}}
                                        </p>

                                </td>
                            </tr>
                            <tr>
                                <td width="30%"></td>
                                <th width="15%" scope="col">Total</th>
                                <th width="15%" scope="col">At or Below Poverty</th>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Grandparents</th>
                                <td>
                                    <label for="totalGrandparents" class="sr-only">{{totalGrandparentsText}}</label>
                                    <div [ngClass]="(_sectionModel.totalGrandparents.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalGrandparents.elementValue" [disabled]="!_sectionModel.totalGrandparents.isRequired"
                                            type="text" class="form-control" id="totalGrandparents" (change)="unsavedChanges = true; _sectionModel.allRelationshipTotal.elementValue = updateTotal(allRelationshipTotalArr); _sectionModel.totalGrandparents = validate(_sectionModel.totalGrandparents); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpGrandparents" class="sr-only">{{bpGrandparentsText}}</label>
                                    <div [ngClass]="(_sectionModel.bpGrandparents.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpGrandparents.elementValue" [disabled]="!_sectionModel.bpGrandparents.isRequired"
                                            type="text" class="form-control" id="bpGrandparents" (change)="unsavedChanges = true; _sectionModel.bpRelationshipTotal.elementValue = updateTotal(bpRelationshipTotalArr); _sectionModel.bpGrandparents = validate(_sectionModel.bpGrandparents); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Parents</th>
                                <td>
                                    <label for="totalParents" class="sr-only">{{totalParentsText}}</label>
                                    <div [ngClass]="(_sectionModel.totalParents.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalParents.elementValue" [disabled]="!_sectionModel.totalParents.isRequired"
                                            type="text" class="form-control" id="totalParents" (change)="unsavedChanges = true; _sectionModel.allRelationshipTotal.elementValue = updateTotal(allRelationshipTotalArr); _sectionModel.totalParents = validate(_sectionModel.totalParents); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpParents" class="sr-only">{{bpParentsText}}</label>
                                    <div [ngClass]="(_sectionModel.bpParents.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpParents.elementValue" [disabled]="!_sectionModel.bpParents.isRequired"
                                            type="text" class="form-control" id="bpParents" (change)="unsavedChanges = true; _sectionModel.bpRelationshipTotal.elementValue = updateTotal(bpRelationshipTotalArr); _sectionModel.bpParents = validate(_sectionModel.bpParents); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Other relative</th>
                                <td>
                                    <label for="totalOtherRel" class="sr-only">{{totalOtherRelText}}</label>
                                    <div [ngClass]="(_sectionModel.totalOtherRel.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalOtherRel.elementValue" [disabled]="!_sectionModel.totalOtherRel.isRequired"
                                            type="text" class="form-control" id="totalOtherRel" (change)="unsavedChanges = true; _sectionModel.allRelationshipTotal.elementValue = updateTotal(allRelationshipTotalArr); _sectionModel.totalOtherRel = validate(_sectionModel.totalOtherRel); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpOtherRel" class="sr-only">{{bpOtherRelText}}</label>
                                    <div [ngClass]="(_sectionModel.bpOtherRel.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpOtherRel.elementValue" [disabled]="!_sectionModel.bpOtherRel.isRequired"
                                            type="text" class="form-control" id="bpOtherRel" (change)="unsavedChanges = true; _sectionModel.bpRelationshipTotal.elementValue = updateTotal(bpRelationshipTotalArr); _sectionModel.bpOtherRel = validate(_sectionModel.bpOtherRel); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Non-relative</th>
                                <td>
                                    <label for="totalNon" class="sr-only">{{totalNonText}}</label>
                                    <div [ngClass]="(_sectionModel.totalNon.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalNon.elementValue" [disabled]="!_sectionModel.totalNon.isRequired"
                                            type="text" class="form-control" id="totalNon" (change)="unsavedChanges = true; _sectionModel.allRelationshipTotal.elementValue = updateTotal(allRelationshipTotalArr); _sectionModel.totalNon = validate(_sectionModel.totalNon); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpNon" class="sr-only">{{bpNonText}}</label>
                                    <div [ngClass]="(_sectionModel.bpNon.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpNon.elementValue" [disabled]="!_sectionModel.bpNon.isRequired"
                                            type="text" class="form-control" id="bpNon" (change)="unsavedChanges = true; _sectionModel.bpRelationshipTotal.elementValue = updateTotal(bpRelationshipTotalArr); _sectionModel.bpNon = validate(_sectionModel.bpNon); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="normal" scope="row">Relationship missing</th>
                                <td>
                                    <label for="totalRelMissing" class="sr-only">{{totalRelMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.totalRelMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.totalRelMissing.elementValue" [disabled]="!_sectionModel.totalRelMissing.isRequired"
                                            type="text" class="form-control" id="totalRelMissing" (change)="unsavedChanges = true; _sectionModel.allRelationshipTotal.elementValue = updateTotal(allRelationshipTotalArr); _sectionModel.totalRelMissing = validate(_sectionModel.totalRelMissing); checkValidForm()">
                                    </div>
                                </td>
                                <td>
                                    <label for="bpRelMissing" class="sr-only">{{bpRelMissingText}}</label>
                                    <div [ngClass]="(_sectionModel.bpRelMissing.dataEntryErrorMessage)?'has-error':''">
                                        <input [(ngModel)]="_sectionModel.bpRelMissing.elementValue" [disabled]="!_sectionModel.bpRelMissing.isRequired"
                                            type="text" class="form-control" id="bpRelMissing" (change)="unsavedChanges = true; _sectionModel.bpRelationshipTotal.elementValue = updateTotal(bpRelationshipTotalArr); _sectionModel.bpRelMissing = validate(_sectionModel.bpRelMissing); checkValidForm()">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Total Persons Served</th>
                                <td>
                                    <label for="allRelationshipTotal" class="sr-only">{{allRelationshipTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.allRelationshipTotal.elementValue"
                                        [disabled]="!_sectionModel.allRelationshipTotal.isRequired" type="text" class="form-control"
                                        id="allRelationshipTotal" readonly>
                                </td>
                                <td>
                                    <label for="bpRelationshipTotal" class="sr-only">{{bpRelationshipTotalText}}</label>
                                    <input [(ngModel)]="_sectionModel.bpRelationshipTotal.elementValue"
                                        [disabled]="!_sectionModel.bpRelationshipTotal.isRequired" type="text" class="form-control"
                                        id="bpRelationshipTotal" readonly>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--  end table responsive  -->

                <div class="col-md-12 background-gray-lighter mb-1">
                    <h2>Care Recipients of Older Relative Caregivers</h2>
                </div>
                <div class="col-md-9">
                    <label for="totalChildren" class="normal">Total children receiving care</label>
                </div>
                <div class="col-md-3 mb-1">
                    <div class="form-group">
                        <input [(ngModel)]="_sectionModel.totalChildren.elementValue" [disabled]="!_sectionModel.totalChildren.isRequired" type="text"
                            class="form-control" id="totalChildren" (change)="unsavedChanges = true; _sectionModel.totalChildren.elementValue = addComma(_sectionModel.totalChildren.elementValue); _sectionModel.totalChildren = validate(_sectionModel.totalChildren); checkValidForm()">
                        <div *ngIf="_sectionModel.totalChildren.dataEntryErrorMessage" class="has-error" role="alert">
                            <p class="help-block normal">
                                <i class="fa fa-times fa-2x help-block" aria-hidden="true"></i> {{_sectionModel.totalChildren.dataEntryErrorMessage}}</p>
                        </div>
                        <div *ngIf="!_sectionModel.totalChildren.dataEntryErrorMessage && _sectionModel.totalChildren.questionStatus == statusEnum.L2ValidatedWarning" class="has-warning"
                            role="alert">
                            <p class="help-block normal">
                                <i class="fa fa-warning fa-2x help-block" aria-hidden="true"></i> {{_sectionModel.totalChildren.businessErrorMessage}}</p>
                        </div>
                        <div *ngIf="!_sectionModel.totalChildren.dataEntryErrorMessage && _sectionModel.totalChildren.questionStatus == statusEnum.L2InProgressError" class="has-error"
                            role="alert">
                            <p class="help-block normal">
                                <i class="fa fa-times fa-2x help-block" aria-hidden="true"></i> {{_sectionModel.totalChildren.businessErrorMessage}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <label for="totalDisabled" class="normal">Total adults with disabilities receiving care</label>
                </div>
                <div class="col-md-3 mb-1">
                    <div class="form-group">
                        <input [(ngModel)]="_sectionModel.totalDisabled.elementValue" [disabled]="!_sectionModel.totalDisabled.isRequired" type="text"
                            class="form-control" id="totalDisabled" (change)="unsavedChanges = true; _sectionModel.totalDisabled.elementValue = addComma(_sectionModel.totalDisabled.elementValue); _sectionModel.totalDisabled = validate(_sectionModel.totalDisabled); checkValidForm()">
                        <div *ngIf="_sectionModel.totalDisabled.dataEntryErrorMessage" class="has-error" role="alert">
                            <p class="help-block normal">
                                <i class="fa fa-times fa-2x help-block" aria-hidden="true"></i> {{_sectionModel.totalDisabled.dataEntryErrorMessage}}</p>
                        </div>
                        <div *ngIf="!_sectionModel.totalDisabled.dataEntryErrorMessage && _sectionModel.totalDisabled.questionStatus == statusEnum.L2ValidatedWarning" class="has-warning"
                            role="alert">
                            <p class="help-block normal">
                                <i class="fa fa-warning fa-2x help-block" aria-hidden="true"></i> {{_sectionModel.totalDisabled.businessErrorMessage}}</p>
                        </div>
                        <div *ngIf="!_sectionModel.totalDisabled.dataEntryErrorMessage && _sectionModel.totalDisabled.questionStatus == statusEnum.L2InProgressError" class="has-error"
                            role="alert">
                            <p class="help-block normal">
                                <i class="fa fa-times fa-2x help-block" aria-hidden="true"></i> {{_sectionModel.totalDisabled.businessErrorMessage}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 row">
                    <div *ngIf="!validForm" class="has-error">
                      <p class="help-block">
                        There are errors on the page. You must fix the errors or clear the data in those fields in order to Save this form.
                      </p>
                    </div>
                    <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
                    <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>
                    <button class="btn btn-default mr-1" (click)="save()" [disabled]="!validForm || !_sectionModel.isRequired">Save</button>
                    <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
                  </div>
                  <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1"
                       style="display: none;">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">×</span>
                          </button>
                          <h3 class="modal-title" id="nextModalLabel">
                            Recent changes on this page will not be saved
                          </h3>
                        </div>
                        <div class="modal-body">
                          <p>
                            There are errors on the page. You must fix the errors or clear the data in those fields in order to save this form.
                            <br />
                            <br />
                            You may select "Go back to form" to remove the errors or you may select "Proceed to next section" and all of new the data entered in the current section will be lost.
                          </p>
                        </div>
                        <div class="modal-footer">
                          <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go back to form</button>
                          <button class="btn btn-primary pull-right" data-dismiss="modal" type="button" (click)="next()">Proceed to next section</button>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
            <!-- .end col-md-9 -->
        </div>
        <!-- .end acl-container (col-md-12) -->
    </div>
    <!-- .end row -->
</div>
<!-- ./container -->
