<footer class="footer no-print">
    <div class="footer-container">
        <div class="row">
            <div class="col-md-2">
                <p class="footerTitle">Site Support</p>
                <ul class="list-unstyled  m-0 p-0">
                    <li>
                        <a [routerLink]="['../about']" tabindex="0">About OAAPS</a>
                    </li>
                    <li>
                        <oaaps-tech-support-email></oaaps-tech-support-email>
                    </li>
                    <li>
                        <a href="http://www.hhs.gov/plugins.html" target="_blank" aria-label="Viewers &amp; Players: open in new window">Viewers &amp; Players</a>
                    </li>
                    <li>
                        <a href="https://www.acl.gov/node/444" target="_blank" aria-label="Accessibility: open in new window">Accessibility</a>
                    </li>
                    <li>
                        <a href="https://www.acl.gov/node/445" target="_blank" aria-label="Privacy Policy: open in new window">Privacy Policy</a>
                    </li>
                </ul>
            </div>
            <!-- end col.md.2 -->
            <div class="col-md-2">
                <p class="footerTitle">Partner Sites</p>
                <ul class="list-unstyled  m-0 p-0">
                    <li>
                        <a href="http://www.whitehouse.gov" target="_blank" aria-label="The White House: open in new window">The White House</a>
                    </li>
                    <li>
                        <a href="https://www.hhs.gov" target="_blank" aria-label="HHS: open in new window">HHS</a>
                    </li>
                    <li>
                        <a href="https://www.alzheimers.gov" target="_blank" aria-label="Alzheimers.gov: open in new window">Alzheimers.gov</a>
                    </li>
                    <li>
                        <a href="http://longtermcare.gov/" target="_blank" aria-label="LongTermCare.gov: open in new window">LongTermCare.gov</a>
                    </li>
                    <li>
                        <a href="https://www.dol.gov/odep/topics/disability.htm" target="_blank" aria-label="Disability.gov: open in new window">Disability.gov</a>
                    </li>
                    <li>
                        <a href="https://www.usa.gov/" target="_blank" aria-label="USA.gov: open in new window">USA.gov</a>
                    </li>
                    <li>
                        <a href="http://www.grants.gov/" target="_blank" aria-label="Grants.gov: open in new window">Grants.gov</a>
                    </li>
                </ul>
            </div>
            <!-- end col.md.2 -->
            <div class="col-md-2">
                <p class="footerTitle">Points of Interest</p>
                <ul class="list-unstyled  m-0 p-0">
                    <li>
                        <a href="https://www.acl.gov/node/447" target="_blank" aria-label="FOIA: Open in new window">FOIA</a>
                    </li>
                    <li>
                        <a href="https://www.hhs.gov/open/plain-writing/index.html" target="_blank" aria-label="Plain Writing: open in new window">Plain Writing</a>
                    </li>
                    <li>
                        <a href="https://www.hhs.gov/about/agencies/asa/eeo/resources/no-fear-act/index.html" target="_blank" aria-label="No Fear Act: open in new window">No Fear Act</a>
                    </li>
                    <li>
                      <a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html" target="_blank" aria-label="Vulnerability Disclosure Policy: open in new window">Vulnerability Disclosure Policy</a>
                  </li>
                </ul>
            </div>
            <!-- end col.md.2 -->
            <div class="col-md-5 col-md-push-1">
                <div class="row pb-1">
                    <a href="https://www.acl.gov/"><img src="/assets/images/acl-logo-basesm.png" alt="Administration for Community Living" class="mr-1 align-middle"
                        width="126px" height="57px"></a>
                    <p class="inline-block align-middle">Administration for Community Living</p>
                </div>
                <div class="row">
                    <p class="small">
                        <strong>U.S. Department of Health and Human Services</strong>
                        <br>Administration for Community Living</p>
                    <p class="mt-1">&copy; Copyright 2018 Administration for Community Living. All Rights Reserved</p>
                </div>
            </div>
            <!-- end col-md-5 col-md-push-1 -->
        </div>
        <!-- end row -->
    </div>
</footer>
