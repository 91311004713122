<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <h2>Aging Network Profile - Network Funding</h2>
        <p class="info mt-1">All fields are required unless identified as optional</p>
        <div class="background-gray-lighter mb-1 pl-1">
          <h2>Other Federal Funds</h2>
        </div>
        <fieldset>
          <div class="checkbox">
            <label for="noFederalFunds">
              <input [disabled]="!_sectionModel.noFederalFunds.isRequired" [(ngModel)]="_sectionModel.noFederalFunds.elementValue" type="checkbox"
                name="noFederalFunds" id="noFederalFunds" (change)="noFederalFundsSelected(); unsavedChanges = true;"> There are no other federal funds used for OAA services
            </label>
          </div>
          <legend class="background-grey-lighter">Select all that apply</legend>
          <button class="btn btn-link" (click)="selectDeselectAllFunds(); unsavedChanges = true;">Select/Deselect all</button>

          <div class="checkbox">
            <label for="medicaid">
              <input [disabled]="noFederalFundsChecked || !_sectionModel.medicaid.isRequired" [(ngModel)]="_sectionModel.medicaid.elementValue"
                type="checkbox" name="medicaid" id="medicaid" (change)="unsavedChanges = true;"> Medicaid
            </label>
          </div>

          <div class="checkbox">
            <label for="medicaidWaiver">
              <input [disabled]="noFederalFundsChecked || !_sectionModel.medicaidWaiver.isRequired" [(ngModel)]="_sectionModel.medicaidWaiver.elementValue"
                type="checkbox" name="medicaidWaiver" id="medicaidWaiver" (change)="unsavedChanges = true;"> Medicaid Waiver
            </label>
          </div>

          <div class="checkbox">
            <label for="socialServicesBlockGrant">
              <input [disabled]="noFederalFundsChecked || !_sectionModel.socialServicesBlockGrant.isRequired" [(ngModel)]="_sectionModel.socialServicesBlockGrant.elementValue"
                type="checkbox" name="socialServicesBlockGrant" id="socialServicesBlockGrant" (change)="unsavedChanges = true;"> Social Services Block Grant Program (SSBG)
            </label>
          </div>

          <div class="checkbox">
            <label for="communityServicesBlockGrant">
              <input [disabled]="noFederalFundsChecked || !_sectionModel.communityServicesBlockGrant.isRequired" [(ngModel)]="_sectionModel.communityServicesBlockGrant.elementValue"
                type="checkbox" name="communityServicesBlockGrant" id="communityServicesBlockGrant" (change)="unsavedChanges = true;"> Community Services Block Grant (CSBG)
            </label>
          </div>

          <div class="checkbox">
            <label for="unitedStatesDepartmentOfAgriculture">
              <input [disabled]="noFederalFundsChecked || !_sectionModel.unitedStatesDepartmentOfAgriculture.isRequired" [(ngModel)]="_sectionModel.unitedStatesDepartmentOfAgriculture.elementValue"
                type="checkbox" name="unitedStatesDepartmentOfAgriculture" id="unitedStatesDepartmentOfAgriculture" (change)="unsavedChanges = true;"> United States Department of Agriculture (USDA)
            </label>
          </div>

          <div class="checkbox">
            <label for="departmentOfTransportation">
              <input [disabled]="noFederalFundsChecked || !_sectionModel.departmentOfTransportation.isRequired" [(ngModel)]="_sectionModel.departmentOfTransportation.elementValue"
                type="checkbox" name="departmentOfTransportation" id="departmentOfTransportation" (change)="unsavedChanges = true;"> Department of Transportation (DOT)
            </label>
          </div>

          <div class="checkbox">
            <label for="otherFederalFunds">
              <input [disabled]="noFederalFundsChecked || !_sectionModel.otherFederalFunds.isRequired" [(ngModel)]="_sectionModel.otherFederalFunds.elementValue"
                type="checkbox" name="otherFederalFunds" id="otherFederalFunds" (change)="unsavedChanges = true;"> Other Federal Funds
            </label>
          </div>

          <div class="mt-2">
            <label for="remedy">
              Comments (Optional)
            </label>
            <textarea [disabled]="!_sectionModel.comments.isRequired" [(ngModel)]="_sectionModel.comments.elementValue" class="form-control"
              id="remedy" rows="4" (change)="unsavedChanges = true; _sectionModel.comments = validate(_sectionModel.comments); checkValidForm()"></textarea>
            <small class="form-text help-block pull-right">{{_sectionModel.comments.elementValue?.length || 0}} of 700 characters</small>
            <div *ngIf="!!_sectionModel.comments.dataEntryErrorMessage" class="alert alert-danger" role="alert">
              <i class="fa fa-times" aria-hidden="true"></i>
              <span class="sr-only">Error:</span>&nbsp;{{_sectionModel.comments.dataEntryErrorMessage}}
            </div>
          </div>
        </fieldset>
        <div class="row col-md-12">
          <button *ngIf="validForm" class="btn btn-primary  mr-1" (click)="saveAndNext()">Save and next</button>
          <button *ngIf="validForm" class="btn btn-default" (click)="save()">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
        </div>
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->