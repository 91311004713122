import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of, Observable, ObservableInput } from 'rxjs';
import { BannerMessage } from './banner/bannerMessage';
import { ITechnicalAssistanceDto } from './technicalAssistanceDto.model';
import { Attachment } from '../shared/Model/attachment';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  readonly HelpApiRoot = 'api/v1/help';
  readonly UploadApiRoot = 'api/upload';

  private httpClient: HttpClient;


  // Need to bypass HttpInterceptor from root module
  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  getTechSupportEmail(): Observable<string> {
    return this.httpClient.get<string>(`${this.HelpApiRoot}/email`, { withCredentials: false }).pipe(
      catchError(this.handleError)
    );
  }

  getBannerMessage(): Observable<BannerMessage> {
    return this.httpClient.get<BannerMessage>(`${this.HelpApiRoot}/banner`, { withCredentials: false }).pipe(
      catchError(this.handleError)
    );
  }

  getUnsupportedBrowserBannerMessage(): Observable<BannerMessage> {
    return this.httpClient.get<BannerMessage>(`${this.HelpApiRoot}/unsupported-broswer-banner`, { withCredentials: false }).pipe(
      catchError(this.handleError)
    );
  }

  getEnvironment(): Observable<string> {
    return this.httpClient.get<string>(`${this.HelpApiRoot}/environment`, { withCredentials: false }).pipe(
      catchError(this.handleError)
    );
  }

  postFile(file: File, captchaToken: string) {
    const formData: FormData = new FormData();
    formData.append('attachment', file, file.name);
    formData.append('g-recaptcha-response', captchaToken)
    return this.httpClient.post<Attachment>(`${this.UploadApiRoot}/attachment`, formData, { withCredentials: true });
  }

  postTechSupportRequest(model: ITechnicalAssistanceDto) {
    return this.httpClient.post(`${this.HelpApiRoot}/technical-assistance`, model, { withCredentials: true });
  }

  handleError(err: any, caught: Observable<any>): ObservableInput<any> {
    console.log(err);
    return of(err);
  }
}
