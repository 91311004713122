import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

import { getUploadTypeFriendlyName } from '../batchSection';
import { EmptyGuid } from '../../app.constants';
import { ClaimService } from '../claim.service';
import { ToastrService } from '../toastr.service';

import * as _ from 'lodash-es';
import { AttachmentService } from './attachment.service';
import { DestructibleComponent } from '../../destructible.component';
declare var jQuery: any;

@Component({
    templateUrl: 'aclManageAttachments.component.html',
    styles: [
        `.modal-header > h1 { text-transform: none; color: black; font-size: 20px;
            font-weight: bold; border-bottom-width: 0px; padding-bottom: 0px; }`,
        '.modal-body > .table { margin-bottom: 0px; }',
        '.modal-body > .table > tbody > tr > td { border-top: 0px; width: 70%; }',
        '.modal-body > .table > tbody > tr > th { border-top: 0px; width: 30% }'
    ]
})

export class AclManageAttachmentsComponent extends DestructibleComponent implements OnInit, OnDestroy {

    _attachments: Array<any> = [];
    _activeSubs = [];
    rowsOnPage: number = 10;
    sortBy: string = 'granteeName';
    sortOrder: string = 'asc';
    currentTitle: string;

    constructor(public zone: NgZone,
        public route: ActivatedRoute,
        public router: Router,
        public _titleService: Title,
        public _claimService: ClaimService,
        private toastr: ToastrService,
        private _dataEntryService: AttachmentService,
        public _sanitizer: DomSanitizer
    ) { super(); }

    ngOnInit(): void {
        this._titleService.setTitle('Manage Attachments - State Submissions - ACL OAAPS');
        this.currentTitle = this._claimService.currentTitle;
        this._claimService.init$.subscribe(data => { if (data) {
            this.getAttachments();
         } },
            err => { console.log(err); });
    }

    ngOnDestroy(): void {
        this._activeSubs.forEach(x => x.unsubscribe());
    }

    getAttachments(): void {
        super.registerSubscription(this._dataEntryService.
            findAttachments(this._claimService.currentYear, null)
            .subscribe(data => {
                this._attachments = data
                    .filter(d => d.id !== EmptyGuid)
                    .sort((left, right) =>
                        Date.parse(left.created) > Date.parse(right.created) ? -1 : 1);
            }));
    }

    handleError(error): void {
        // Unknown error
        if (error.code === 1999) {
            console.log(error);
            alert('We\'re sorry an error occurred during merging');
        } else {
            // Other errors
            console.log(error);
            alert('We\'re sorry an error occurred during merging');
        }
    }
    // Function wrappers
    public get getUploadTypeFriendlyNameWrapper(): Function {
        return getUploadTypeFriendlyName;
    }
}
