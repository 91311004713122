import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SectionComponent } from "../section.component";
import { ClaimService } from "../shared/claim.service";
import { CommonService } from "../shared/common.service";
import { Section } from "../shared/section";
import { ValidationService } from "../shared/validation.service";
import { TitleIIIDataEntryService } from "./titleIIIDataEntry.service";

export class TitleIIISectionComponent<T extends Section> extends SectionComponent<T> {

  constructor(
    model: T,
      public _commonService: CommonService,
      public _validationService: ValidationService,
      public _router: Router,
      public _claimService: ClaimService,
      public _dataService: TitleIIIDataEntryService) {
    super(model, _commonService, _validationService, _router);
    _commonService.setSubOrg(_router);
  }

  public processSectionStatus(disableOverride: boolean = false): void {
    this.disabled().subscribe(disabled => {
      super.processSectionStatus(disabled);
    });
  }

  protected setSectionName() {
    this._sectionModel.sectionName = this.resolveSectionName();
  }

  protected resolveSectionName(): string {
    return this._router.url.split('/').pop().split('?')[0] || '';
  }

  private disabled(): Observable<boolean> {
    return this._claimService.init$.pipe(
      map(() => {
        return this.disabledForAAAStateUser();
      })
    )
  }

  private disabledForAAAStateUser(): boolean {
    const aaaStateUser = this._claimService.isAAAEnabledStateUser();
    const editModeDisabled = !this._dataService._aaaStateEditMode;
    const viewingStateData = !this._sectionModel.psa;
    //!this._sectionModel.psa
//      && !this._sectionModel.isRollup;

      return aaaStateUser && editModeDisabled && !viewingStateData;
  }

}
