import { Component, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { CaregiverPagesSection } from './CaregiverPagesSection';
import { CGSuppExpendSection } from './CGSuppExpendSection';
import { CGRespiteSection } from './CGRespiteSection';
import { CommonService } from '../shared/common.service';
import { ValidationService } from '../shared/validation.service';
import * as _ from 'lodash-es';
import { ComponentCanDeactivate } from '../shared/pending-changes.guard';
import { ToastrService } from '../shared/toastr.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { Section } from '../shared/section';
import { DestructibleComponent } from '../destructible.component';

@Component({
  templateUrl: 'titleIIIORCExpenditures.component.html',
})

export class TitleIIIORCExpendituresComponent extends DestructibleComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  // models
  _counselingSectionModel: CaregiverPagesSection;
  _trainingSectionModel: CaregiverPagesSection;
  _caseMgmtSectionModel: CaregiverPagesSection;
  _supportSectionModel: CaregiverPagesSection;
  _infoAssistSectionModel: CaregiverPagesSection;
  _infoServiceSectionModel: CaregiverPagesSection;
  _respiteSectionModel: CGRespiteSection;
  _otherServicesSectionModel: CGSuppExpendSection;

  // arrays
  counselingArr: Array<DataElement>;
  trainingArr: Array<DataElement>;
  caseMgmtArr: Array<DataElement>;
  supportArr: Array<DataElement>;
  infoAssistArr: Array<DataElement>;
  infoServiceArr: Array<DataElement>;
  otherServicesArr: Array<DataElement>;

  serviceUnitsArr: Array<DataElement>;
  title3Arr: Array<DataElement>;
  otherStateArr: Array<DataElement>;
  otherNonStateArr: Array<DataElement>;
  programArr: Array<DataElement>;
  totalExArr: Array<DataElement>;

  inHomeArr: Array<DataElement>;
  outDayArr: Array<DataElement>;
  outNightArr: Array<DataElement>;
  unknownArr: Array<DataElement>;
  respiteTotalsArr: Array<DataElement>;
  perPersonArr: Array<DataElement>;
  questionStatusEnum = QuestionStatusEnum;
  unsavedChanges: boolean = false;
  validForm: boolean = true;

  t3ExpendTotal: any = 0.00;
  otherStateTotal: any = 0.00;
  nonOtherStateTotal: any = 0.00;
  programIncomeTotal: any = 0.00;
  serviceExpendTotal: any = 0.00;

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _commonService: CommonService,
    public _validation: ValidationService,
    public _dataService: TitleIIIDataEntryService,
    public toastr: ToastrService
  ) { super(); }

  ngOnInit(): void {
    this._titleService.setTitle('Expenditures - Title III Service Expenditures');
    // initialize models
    this._counselingSectionModel = new CaregiverPagesSection();
    this._trainingSectionModel = new CaregiverPagesSection();
    this._caseMgmtSectionModel = new CaregiverPagesSection();
    this._supportSectionModel = new CaregiverPagesSection();
    this._infoAssistSectionModel = new CaregiverPagesSection();
    this._infoServiceSectionModel = new CaregiverPagesSection();
    this._respiteSectionModel = new CGRespiteSection();
    this._otherServicesSectionModel = new CGSuppExpendSection();

    this.fillModels();
  }

  ngOnDestroy(): void {
    super.destroy();
  }
  saveAllSections(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    const save$ = [];
    // assign state, year, and (where required) sectionName then Save
    this._counselingSectionModel.state = this._claimService.currentOrg;
    this._counselingSectionModel.fiscalYear = this._claimService.currentYear;
    this._counselingSectionModel.sectionName = 'titleIIIORCCounseling';
    save$.push(this._service.savecaregiverpages(this._counselingSectionModel));

    this._trainingSectionModel.state = this._claimService.currentOrg;
    this._trainingSectionModel.fiscalYear = this._claimService.currentYear;
    this._trainingSectionModel.sectionName = 'titleIIIORCTraining';
    save$.push(this._service.savecaregiverpages(this._trainingSectionModel));

    this._caseMgmtSectionModel.state = this._claimService.currentOrg;
    this._caseMgmtSectionModel.fiscalYear = this._claimService.currentYear;
    this._caseMgmtSectionModel.sectionName = 'titleIIIORCCaseMgmt';
    save$.push(this._service.savecaregiverpages(this._caseMgmtSectionModel));

    this._respiteSectionModel.state = this._claimService.currentOrg;
    this._respiteSectionModel.fiscalYear = this._claimService.currentYear;
    this._respiteSectionModel.sectionName = 'titleIIIORCRespite';
    save$.push(this._service.savecgrespite(this._respiteSectionModel));

    this._supportSectionModel.state = this._claimService.currentOrg;
    this._supportSectionModel.fiscalYear = this._claimService.currentYear;
    this._supportSectionModel.sectionName = 'titleIIIORCSupport';
    save$.push(this._service.savecaregiverpages(this._supportSectionModel));

    this._infoAssistSectionModel.state = this._claimService.currentOrg;
    this._infoAssistSectionModel.fiscalYear = this._claimService.currentYear;
    this._infoAssistSectionModel.sectionName = 'titleIIIORCInfoAndAssistance';
    save$.push(this._service.savecaregiverpages(this._infoAssistSectionModel));

    this._otherServicesSectionModel.state = this._claimService.currentOrg;
    this._otherServicesSectionModel.fiscalYear = this._claimService.currentYear;
    this._otherServicesSectionModel.sectionName = 'titleIIIORCSuppExpend';
    save$.push(this._service.savecgsuppexpend(this._otherServicesSectionModel));

    this._infoServiceSectionModel.state = this._claimService.currentOrg;
    this._infoServiceSectionModel.fiscalYear = this._claimService.currentYear;
    this._infoServiceSectionModel.sectionName = 'titleIIIORCInfoServices';
    save$.push(this._service.savecaregiverpages(this._infoServiceSectionModel));

    super.registerSubscription(forkJoin(save$)
      .subscribe(data => {
        let i = 0;
        this._counselingSectionModel = <any>data[i++];
        this._trainingSectionModel = <any>data[i++];
        this._caseMgmtSectionModel = <any>data[i++];
        this._respiteSectionModel = <any>data[i++];
        this._supportSectionModel = <any>data[i++];
        this._infoAssistSectionModel = <any>data[i++];
        this._otherServicesSectionModel = <any>data[i++];
        this._infoServiceSectionModel = <any>data[i++];
      },
        (error)=>console.log(error),
        ()=>{
          this.initArrays();

          this.toastr.success('Saved!');
          callback();
        }
      ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleVIIExpenditures']);
    if (!this._claimService.isViewOnly && this._infoServiceSectionModel.isRequired
      && !this._claimService.isRollupOverview
      && this._infoServiceSectionModel.sectionStatus <= QuestionStatusEnum.Returned) {
      this.saveAllSections(navAction);
    } else {
      navAction();
    }
  }

  fillModels(): void {

    const caregivers$: Observable<Array<CaregiverPagesSection>> = this._service.get<CaregiverPagesSection[]>(
      this._claimService, this._service._getcaregiverpagesbyffyandstate);
    const respite$: Observable<Array<CGRespiteSection>> = this._service.get<CGRespiteSection[]>(
      this._claimService, this._service._getcgrespitebyffyandstate);
    const suppServices$: Observable<Array<CGSuppExpendSection>> = this._service.get<CGSuppExpendSection[]>(
      this._claimService, this._service._getcgsuppexpendbyffyandstate);

    let caregivers = [];
    let respite = [];
    let suppServices = [];

    super.registerSubscription(forkJoin(caregivers$, respite$, suppServices$)
      .subscribe((results: [CaregiverPagesSection[], CGRespiteSection[], CGSuppExpendSection[]]) => {
        caregivers = results[0];
        respite = results[1];
        suppServices = results[2];

        // Load template models for caregiver sections
        if (caregivers.length > 0) {
          for (const item of caregivers) {
            caregivers = _.filter(caregivers, x => x.batchId === '00000000-0000-0000-0000-000000000000');
            if (item.sectionName === 'titleIIIORCCounseling') {
              this._counselingSectionModel = item;
            }
            if (item.sectionName === 'titleIIIORCTraining') {
              this._trainingSectionModel = item;
            }
            if (item.sectionName === 'titleIIIORCCaseMgmt') {
              this._caseMgmtSectionModel = item;
            }
            if (item.sectionName === 'titleIIIORCSupport') {
              this._supportSectionModel = item;
            }
            if (item.sectionName === 'titleIIIORCInfoAndAssistance') {
              this._infoAssistSectionModel = item;
            }
            if (item.sectionName === 'titleIIIORCInfoServices') {
              this._infoServiceSectionModel = item;
            }
          }
        }
        // Load template model for respite section
        if (respite.length > 0) {
          for (const item of respite) {
            if (item.sectionName === 'titleIIIORCRespite' && item.batchId === '00000000-0000-0000-0000-000000000000') {
              this._respiteSectionModel = item;
            }
          }
        }

        // Load template model for supplemental services section
        if (suppServices.length > 0) {
          for (const item of suppServices) {
            if (item.sectionName === 'titleIIIORCSuppExpend' && item.batchId === '00000000-0000-0000-0000-000000000000') {
              this._otherServicesSectionModel = item;
            }
          }
        }

        this.createMissingSections(caregivers, respite, suppServices);

        // ORC Registered Services
        this._infoAssistSectionModel.totalPersonsServed.elementValue = this.addComma(
          this._infoAssistSectionModel.totalPersonsServed.elementValue);
        this._counselingSectionModel.totalPersonsServed.elementValue = this.addComma(
          this._counselingSectionModel.totalPersonsServed.elementValue);
        this._trainingSectionModel.totalPersonsServed.elementValue = this.addComma(
          this._trainingSectionModel.totalPersonsServed.elementValue);
        this._caseMgmtSectionModel.totalPersonsServed.elementValue = this.addComma(
          this._caseMgmtSectionModel.totalPersonsServed.elementValue);

        this._counselingSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._counselingSectionModel.totalServiceUnits.elementValue);
        this._trainingSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._trainingSectionModel.totalServiceUnits.elementValue);
        this._caseMgmtSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._caseMgmtSectionModel.totalServiceUnits.elementValue);

        this._counselingSectionModel.expend.elementValue = this.addDecimalAndComma(
          this._counselingSectionModel.expend.elementValue);
        this._trainingSectionModel.expend.elementValue = this.addDecimalAndComma(
          this._trainingSectionModel.expend.elementValue);
        this._caseMgmtSectionModel.expend.elementValue = this.addDecimalAndComma(
          this._caseMgmtSectionModel.expend.elementValue);

        this._counselingSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._counselingSectionModel.expendOtherState.elementValue);
        this._trainingSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._trainingSectionModel.expendOtherState.elementValue);
        this._caseMgmtSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._caseMgmtSectionModel.expendOtherState.elementValue);

        this._counselingSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._counselingSectionModel.expendOtherNonState.elementValue);
        this._trainingSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._trainingSectionModel.expendOtherNonState.elementValue);
        this._caseMgmtSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._caseMgmtSectionModel.expendOtherNonState.elementValue);

        this._counselingSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._counselingSectionModel.programIncome.elementValue);
        this._trainingSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._trainingSectionModel.programIncome.elementValue);
        this._caseMgmtSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._caseMgmtSectionModel.programIncome.elementValue);

        this._counselingSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._counselingSectionModel.expendTotal.elementValue);
        this._trainingSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._trainingSectionModel.expendTotal.elementValue);
        this._caseMgmtSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._caseMgmtSectionModel.expendTotal.elementValue);

        // Respite Services
        this._respiteSectionModel.inhomeTotalUnduplicated.elementValue = this.addComma(
          this._respiteSectionModel.inhomeTotalUnduplicated.elementValue);
        this._respiteSectionModel.outDayTotalUnduplicated.elementValue = this.addComma(
          this._respiteSectionModel.outDayTotalUnduplicated.elementValue);
        this._respiteSectionModel.outNightTotalUnduplicated.elementValue = this.addComma(
          this._respiteSectionModel.outNightTotalUnduplicated.elementValue);
        this._respiteSectionModel.unknownRespiteTotalUnduplicated.elementValue = this.addComma(
          this._respiteSectionModel.unknownRespiteTotalUnduplicated.elementValue);
        this._respiteSectionModel.totalRespiteTotalUnduplicated.elementValue = this.addComma(
          this._respiteSectionModel.totalRespiteTotalUnduplicated.elementValue);

        this._respiteSectionModel.inhomeTotalTotalService.elementValue = this.addComma(
          this._respiteSectionModel.inhomeTotalTotalService.elementValue);
        this._respiteSectionModel.outDayTotalService.elementValue = this.addComma(
          this._respiteSectionModel.outDayTotalService.elementValue);
        this._respiteSectionModel.outNightTotalService.elementValue = this.addComma(
          this._respiteSectionModel.outNightTotalService.elementValue);
        this._respiteSectionModel.unknownRespiteTotalService.elementValue = this.addComma(
          this._respiteSectionModel.unknownRespiteTotalService.elementValue);
        this._respiteSectionModel.totalRespiteTotalService.elementValue = this.addComma(
          this._respiteSectionModel.totalRespiteTotalService.elementValue);

        this._respiteSectionModel.title3InHome.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.title3InHome.elementValue);
        this._respiteSectionModel.title3OutDay.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.title3OutDay.elementValue);
        this._respiteSectionModel.title3OutNight.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.title3OutNight.elementValue);
        this._respiteSectionModel.title3Unknown.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.title3Unknown.elementValue);
        this._respiteSectionModel.title3Total.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.title3Total.elementValue);

        this._respiteSectionModel.otherStateInHome.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.otherStateInHome.elementValue);
        this._respiteSectionModel.otherStateOutDay.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.otherStateOutDay.elementValue);
        this._respiteSectionModel.otherStateOutNight.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.otherStateOutNight.elementValue);
        this._respiteSectionModel.otherStateUnknown.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.otherStateUnknown.elementValue);
        this._respiteSectionModel.otherStateTotal.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.otherStateTotal.elementValue);

        this._respiteSectionModel.otherNonStateInHome.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.otherNonStateInHome.elementValue);
        this._respiteSectionModel.otherNonStateOutDay.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.otherNonStateOutDay.elementValue);
        this._respiteSectionModel.otherNonStateOutNight.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.otherNonStateOutNight.elementValue);
        this._respiteSectionModel.otherNonStateUnknown.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.otherNonStateUnknown.elementValue);
        this._respiteSectionModel.otherNonStateTotal.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.otherNonStateTotal.elementValue);

        this._respiteSectionModel.programInHome.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.programInHome.elementValue);
        this._respiteSectionModel.programOutDay.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.programOutDay.elementValue);
        this._respiteSectionModel.programOutNight.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.programOutNight.elementValue);
        this._respiteSectionModel.programUnknown.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.programUnknown.elementValue);
        this._respiteSectionModel.programTotal.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.programTotal.elementValue);

        this._respiteSectionModel.totalExInHome.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.totalExInHome.elementValue);
        this._respiteSectionModel.totalExOutDay.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.totalExOutDay.elementValue);
        this._respiteSectionModel.totalExOutNight.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.totalExOutNight.elementValue);
        this._respiteSectionModel.totalExUnknown.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.totalExUnknown.elementValue);
        this._respiteSectionModel.totalExTotal.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.totalExTotal.elementValue);

        this._respiteSectionModel.totalExInHome.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.totalExInHome.elementValue);
        this._respiteSectionModel.totalExOutDay.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.totalExOutDay.elementValue);
        this._respiteSectionModel.totalExOutNight.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.totalExOutNight.elementValue);
        this._respiteSectionModel.totalExUnknown.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.totalExUnknown.elementValue);
        this._respiteSectionModel.totalExTotal.elementValue = this.addDecimalAndComma(
          this._respiteSectionModel.totalExTotal.elementValue);

        // ORC Unregistered Services
        this._infoServiceSectionModel.totalPersonsServed.elementValue = this.addComma(
          this._infoServiceSectionModel.totalPersonsServed.elementValue);

        this._supportSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._supportSectionModel.totalServiceUnits.elementValue);
        this._infoAssistSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._infoAssistSectionModel.totalServiceUnits.elementValue);
        this._infoServiceSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._infoServiceSectionModel.totalServiceUnits.elementValue);

        this._supportSectionModel.expend.elementValue = this.addDecimalAndComma(
          this._supportSectionModel.expend.elementValue);
        this._infoAssistSectionModel.expend.elementValue = this.addDecimalAndComma(
          this._infoAssistSectionModel.expend.elementValue);
        this._infoServiceSectionModel.expend.elementValue = this.addDecimalAndComma(
          this._infoServiceSectionModel.expend.elementValue);

        this._supportSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._supportSectionModel.expendOtherState.elementValue);
        this._infoAssistSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._infoAssistSectionModel.expendOtherState.elementValue);
        this._infoServiceSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._infoServiceSectionModel.expendOtherState.elementValue);

        this._supportSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._supportSectionModel.expendOtherNonState.elementValue);
        this._infoAssistSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._infoAssistSectionModel.expendOtherNonState.elementValue);
        this._infoServiceSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._infoServiceSectionModel.expendOtherNonState.elementValue);

        this._supportSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._supportSectionModel.programIncome.elementValue);
        this._infoAssistSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._infoAssistSectionModel.programIncome.elementValue);
        this._infoServiceSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._infoServiceSectionModel.programIncome.elementValue);

        this._supportSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._supportSectionModel.expendTotal.elementValue);
        this._infoAssistSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._infoAssistSectionModel.expendTotal.elementValue);
        this._infoServiceSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._infoServiceSectionModel.expendTotal.elementValue);

        // Supplemental services (ACLTITLES-1873)
        this._otherServicesSectionModel.totalServiceUnits.elementValue = this.addComma(
          this._otherServicesSectionModel.totalServiceUnits.elementValue);
        this._otherServicesSectionModel.expend.elementValue = this.addDecimalAndComma(
          this._otherServicesSectionModel.expend.elementValue);
        this._otherServicesSectionModel.expendOtherState.elementValue = this.addDecimalAndComma(
          this._otherServicesSectionModel.expendOtherState.elementValue);
        this._otherServicesSectionModel.expendOtherNonState.elementValue = this.addDecimalAndComma(
          this._otherServicesSectionModel.expendOtherNonState.elementValue);
        this._otherServicesSectionModel.programIncome.elementValue = this.addDecimalAndComma(
          this._otherServicesSectionModel.programIncome.elementValue);
        this._otherServicesSectionModel.expendTotal.elementValue = this.addDecimalAndComma(
          this._otherServicesSectionModel.expendTotal.elementValue);


        this.initArrays();
        this.calcSums();

      }, err => console.log(err),
        () => {
          this._commonService.disableFormElements(_.minBy([this._counselingSectionModel,
        this._trainingSectionModel,
        this._caseMgmtSectionModel,
        this._supportSectionModel,
        this._infoAssistSectionModel,
        this._infoServiceSectionModel,
        this._respiteSectionModel,
        this._otherServicesSectionModel], item => item.sectionStatus));

        this.processSectionStatus();
        }));
  }

   // using training section for the next 2 methods, chose it randomly.
   // If conditions are true for any section, then its true for expenditures
   public processSectionStatus(disableOverride: boolean = false): void {
    if(this.disabledForAAAStateUser()){
      this._commonService.disableFormElements(this._trainingSectionModel, true);
    }
  }

  private disabledForAAAStateUser(): boolean {
    const aaaStateUser = this._claimService.isAAAEnabledStateUser();
    const editModeDisabled = !this._dataService._aaaStateEditMode;
    const viewingStateData = !this._trainingSectionModel.psa;
      //&& !this._trainingSectionModel.isRollup;

      return aaaStateUser && editModeDisabled && !viewingStateData;
  }

  initArrays(): void {
    this.counselingArr = [this._counselingSectionModel.expend,
    this._counselingSectionModel.expendOtherState,
    this._counselingSectionModel.expendOtherNonState,
    this._counselingSectionModel.programIncome];

    this.trainingArr = [this._trainingSectionModel.expend,
    this._trainingSectionModel.expendOtherState,
    this._trainingSectionModel.expendOtherNonState,
    this._trainingSectionModel.programIncome];

    this.caseMgmtArr = [this._caseMgmtSectionModel.expend,
    this._caseMgmtSectionModel.expendOtherState,
    this._caseMgmtSectionModel.expendOtherNonState,
    this._caseMgmtSectionModel.programIncome];

    this.supportArr = [this._supportSectionModel.expend,
    this._supportSectionModel.expendOtherState,
    this._supportSectionModel.expendOtherNonState,
    this._supportSectionModel.programIncome];

    this.infoAssistArr = [this._infoAssistSectionModel.expend,
    this._infoAssistSectionModel.expendOtherState,
    this._infoAssistSectionModel.expendOtherNonState,
    this._infoAssistSectionModel.programIncome];

    this.infoServiceArr = [this._infoServiceSectionModel.expend,
    this._infoServiceSectionModel.expendOtherState,
    this._infoServiceSectionModel.expendOtherNonState,
    this._infoServiceSectionModel.programIncome];

    this.inHomeArr = [this._respiteSectionModel.title3InHome,
    this._respiteSectionModel.otherStateInHome,
    this._respiteSectionModel.otherNonStateInHome,
    this._respiteSectionModel.programInHome];

    this.outDayArr = [this._respiteSectionModel.title3OutDay,
    this._respiteSectionModel.otherStateOutDay,
    this._respiteSectionModel.otherNonStateOutDay,
    this._respiteSectionModel.programOutDay];

    this.outNightArr = [this._respiteSectionModel.title3OutNight,
    this._respiteSectionModel.otherStateOutNight,
    this._respiteSectionModel.otherNonStateOutNight,
    this._respiteSectionModel.programOutNight];

    this.unknownArr = [this._respiteSectionModel.title3Unknown,
    this._respiteSectionModel.otherStateUnknown,
    this._respiteSectionModel.otherNonStateUnknown,
    this._respiteSectionModel.programUnknown];

    this.respiteTotalsArr = [this._respiteSectionModel.title3Total,
    this._respiteSectionModel.otherStateTotal,
    this._respiteSectionModel.otherNonStateTotal,
    this._respiteSectionModel.programTotal];

    // respite row sums
    this.serviceUnitsArr = [this._respiteSectionModel.inhomeTotalTotalService,
    this._respiteSectionModel.outDayTotalService,
    this._respiteSectionModel.outNightTotalService,
    this._respiteSectionModel.unknownRespiteTotalService];

    this.title3Arr = [this._respiteSectionModel.title3InHome,
    this._respiteSectionModel.title3OutDay,
    this._respiteSectionModel.title3OutNight,
    this._respiteSectionModel.title3Unknown];

    this.otherStateArr = [this._respiteSectionModel.otherStateInHome,
    this._respiteSectionModel.otherStateOutDay,
    this._respiteSectionModel.otherStateOutNight,
    this._respiteSectionModel.otherStateUnknown];

    this.otherNonStateArr = [this._respiteSectionModel.otherNonStateInHome,
    this._respiteSectionModel.otherNonStateOutDay,
    this._respiteSectionModel.otherNonStateOutNight,
    this._respiteSectionModel.otherNonStateUnknown];

    this.programArr = [this._respiteSectionModel.programInHome,
    this._respiteSectionModel.programOutDay,
    this._respiteSectionModel.programOutNight,
    this._respiteSectionModel.programUnknown];

    this.totalExArr = [this._respiteSectionModel.totalExInHome,
    this._respiteSectionModel.totalExOutDay,
    this._respiteSectionModel.totalExOutNight,
    this._respiteSectionModel.totalExUnknown];

    this.perPersonArr = [this._respiteSectionModel.perPersonInHome,
    this._respiteSectionModel.perPersonOutDay,
    this._respiteSectionModel.perPersonOutNight,
    this._respiteSectionModel.perPersonUnknown];
  }

  updateTotal(totalsArray: Array<DataElement>): string {
    let total: number = 0;

    for (let i = 0; i < totalsArray.length; i++) {
      // if not null, remove commas
      if (totalsArray[i].elementValue) {
        totalsArray[i].elementValue = totalsArray[i].elementValue.replace(/,/g, '');
      }
      // if is a number, sum it
      if (!isNaN(parseFloat(totalsArray[i].elementValue))) {
        total += parseFloat(totalsArray[i].elementValue);
      }
      // replace commas when done adding
      totalsArray[i].elementValue = this.addDecimals(totalsArray[i].elementValue);
    }
    this.calcSums();
    return this.addDecimals(total.toString());
  }

  // updates Total Expenditure row
  updatePerUnit(): void {
    const counselingUnits = parseFloat(this.removeCommas(this._counselingSectionModel.totalServiceUnits.elementValue));
    const trainingUnits = parseFloat(this.removeCommas(this._trainingSectionModel.totalServiceUnits.elementValue));
    const caseUnits = parseFloat(this.removeCommas(this._caseMgmtSectionModel.totalServiceUnits.elementValue));
    const supportUnits = parseFloat(this.removeCommas(this._supportSectionModel.totalServiceUnits.elementValue));
    const infoAssistUnits = parseFloat(this.removeCommas(this._infoAssistSectionModel.totalServiceUnits.elementValue));
    const infoServiceUnits = parseFloat(this.removeCommas(this._infoServiceSectionModel.totalServiceUnits.elementValue));
    const inHomeUnits = parseFloat(this.removeCommas(this._respiteSectionModel.inhomeTotalTotalService.elementValue));
    const outDayUnits = parseFloat(this.removeCommas(this._respiteSectionModel.outDayTotalService.elementValue));
    const outNightUnits = parseFloat(this.removeCommas(this._respiteSectionModel.outNightTotalService.elementValue));
    const unknownRespiteUnits = parseFloat(this.removeCommas(this._respiteSectionModel.unknownRespiteTotalService.elementValue));
    const totalRespiteUnits = parseFloat(this.removeCommas(this._respiteSectionModel.totalRespiteTotalService.elementValue));
    const otherUnits = parseFloat(this.removeCommas(this._otherServicesSectionModel.totalServiceUnits.elementValue));

    const counselingExpend = parseFloat(this.removeCommas(this._counselingSectionModel.expendTotal.elementValue));
    const trainingExpend = parseFloat(this.removeCommas(this._trainingSectionModel.expendTotal.elementValue));
    const caseExpend = parseFloat(this.removeCommas(this._caseMgmtSectionModel.expendTotal.elementValue));
    const supportExpend = parseFloat(this.removeCommas(this._supportSectionModel.expendTotal.elementValue));
    const infoAssistExpend = parseFloat(this.removeCommas(this._infoAssistSectionModel.expendTotal.elementValue));
    const infoServiceExpend = parseFloat(this.removeCommas(this._infoServiceSectionModel.expendTotal.elementValue));
    const inHomeExpend = parseFloat(this.removeCommas(this._respiteSectionModel.totalExInHome.elementValue));
    const outDayExpend = parseFloat(this.removeCommas(this._respiteSectionModel.totalExOutDay.elementValue));
    const outNightExpend = parseFloat(this.removeCommas(this._respiteSectionModel.totalExOutNight.elementValue));
    const unknownRespiteExpend = parseFloat(this.removeCommas(this._respiteSectionModel.totalExUnknown.elementValue));
    const totalRespiteExpend = parseFloat(this.removeCommas(this._respiteSectionModel.totalExTotal.elementValue));
    const otherExpend = parseFloat(this.removeCommas(this._otherServicesSectionModel.expendTotal.elementValue));

    this._counselingSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(counselingExpend, counselingUnits);
    this._trainingSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(trainingExpend, trainingUnits);
    this._caseMgmtSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(caseExpend, caseUnits);
    this._supportSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(supportExpend, supportUnits);
    this._infoAssistSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(infoAssistExpend, infoAssistUnits);
    this._infoServiceSectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(infoServiceExpend, infoServiceUnits);
    this._respiteSectionModel.perPersonInHome.elementValue = this.expendPerUnit(inHomeExpend, inHomeUnits);
    this._respiteSectionModel.perPersonOutDay.elementValue = this.expendPerUnit(outDayExpend, outDayUnits);
    this._respiteSectionModel.perPersonOutNight.elementValue = this.expendPerUnit(outNightExpend, outNightUnits);
    this._respiteSectionModel.perPersonUnknown.elementValue = this.expendPerUnit(unknownRespiteExpend, unknownRespiteUnits);
    this._respiteSectionModel.perPersonTotal.elementValue = this.expendPerUnit(totalRespiteExpend, totalRespiteUnits);

    if (!isNaN(otherUnits) && !isNaN(otherExpend)) {
      this._otherServicesSectionModel.totalExpendPerUnit.elementValue = this.addDecimals(
        this._commonService.calculatePerUnit(otherUnits, otherExpend));
    }
    this.calcSums();
  }

  expendPerUnit(expendValue: number, unitValue: number): string {
    if (!isNaN(expendValue) && !isNaN(unitValue) && unitValue !== 0) {
        return this.addDecimals((expendValue / unitValue).toString());
    }
    else {
        return 'N/A';
    }
  }

  removeCommas(value: string): string {
    if (value) {
      value = value.replace(/,/g, '');
    }
    return value;
  }

  addDecimals(currentValue: string): string {
    const originalvalue = currentValue;
    if (currentValue == null || currentValue === '') {
      return '';
    }
    currentValue = currentValue.replace(/,/g, '');
    const num = parseFloat(currentValue);
    if (!isNaN(num)) {
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    } else {
      return originalvalue;
    }
  }

  // adds commas to value after every 3 digits
  addComma(currentValue: string): string {
    const originalvalue = currentValue;

    if (currentValue == null || currentValue === '') {
      return '';
    }
    currentValue = currentValue.replace(/,/g, '');
    const num = parseInt(currentValue);
    if (!isNaN(num) && !currentValue.includes('.')) {
      return currentValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return originalvalue;
    }
  }

  addDecimalAndComma(currentValue: string): string {
    return this.addDecimals(this.addComma(currentValue));
  }


  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.eoLockedNav = false;
      return true;
    } else {
      this._commonService.eoLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

  calcSums(): void {

    this.t3ExpendTotal = (Number(this.removeCommas(this._counselingSectionModel.expend.elementValue)) || 0) +
      (Number(this.removeCommas(this._trainingSectionModel.expend.elementValue)) || 0) +
      (Number(this.removeCommas(this._caseMgmtSectionModel.expend.elementValue)) || 0) +
      (Number(this.removeCommas(this._supportSectionModel.expend.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoAssistSectionModel.expend.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoServiceSectionModel.expend.elementValue)) || 0) +
      (Number(this.removeCommas(this._otherServicesSectionModel.expend.elementValue)) || 0) +
      (Number(this.removeCommas(this._respiteSectionModel.title3Total.elementValue)) || 0);

    this.otherStateTotal = (Number(this.removeCommas(this._counselingSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._trainingSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._caseMgmtSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._supportSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoAssistSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoServiceSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._otherServicesSectionModel.expendOtherState.elementValue)) || 0) +
      (Number(this.removeCommas(this._respiteSectionModel.otherStateTotal.elementValue)) || 0);

    this.nonOtherStateTotal = (Number(this.removeCommas(this._counselingSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._trainingSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._caseMgmtSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._supportSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoAssistSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoServiceSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._otherServicesSectionModel.expendOtherNonState.elementValue)) || 0) +
      (Number(this.removeCommas(this._respiteSectionModel.otherNonStateTotal.elementValue)) || 0);

    this.programIncomeTotal = (Number(this.removeCommas(this._counselingSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._trainingSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._caseMgmtSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._supportSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoAssistSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoServiceSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._otherServicesSectionModel.programIncome.elementValue)) || 0) +
      (Number(this.removeCommas(this._respiteSectionModel.programTotal.elementValue)) || 0);

    this.serviceExpendTotal = (Number(this.removeCommas(this._counselingSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._trainingSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._caseMgmtSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._supportSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoAssistSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._infoServiceSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._otherServicesSectionModel.expendTotal.elementValue)) || 0) +
      (Number(this.removeCommas(this._respiteSectionModel.totalExTotal.elementValue)) || 0);

    this.t3ExpendTotal = this.addDecimals(this.t3ExpendTotal.toString());
    this.otherStateTotal = this.addDecimals(this.otherStateTotal.toString());
    this.nonOtherStateTotal = this.addDecimals(this.nonOtherStateTotal.toString());
    this.programIncomeTotal = this.addDecimals(this.programIncomeTotal.toString());
    this.serviceExpendTotal = this.addDecimals(this.serviceExpendTotal.toString());
  }

  validate(data: DataElement): DataElement {
    if (data.elementValue != null && data.elementValue !== '') {
      if (this._validation.validate(data.validationType, data.elementValue)) {
        if (!this._validation.validateMaxLength(data.elementValue, data.maxLength)) {
          data.dataEntryErrorMessage = 'Maximum allowed digits is ' + data.maxLength;
          data.questionStatus = 1;
        } else {
          data.dataEntryErrorMessage = '';
          data.questionStatus = 3;
        }
      } else {
        data.dataEntryErrorMessage = this._validation.getErrorMessage(data.validationType);
        data.questionStatus = 1;
      }
    } else {
      data.dataEntryErrorMessage = '';
      data.questionStatus = 0;
    }

    return data;
  }

  public hasError(cluster: Array<Section>): boolean {
    if (cluster) {
      return cluster.some(x => x.sectionStatus === QuestionStatusEnum.L1InProgressError
        || x.sectionStatus === QuestionStatusEnum.L2InProgressError);
    } else {
      return false;
    }
  }

  public hasWarning(cluster: Array<Section>): boolean {
    if (cluster && !this.hasError(cluster)) {
      return cluster.some(x => x.sectionStatus === QuestionStatusEnum.L2ValidatedWarning);
    } else {
      return false;
    }
  }

  public get registeredCluster() {
    return [this._counselingSectionModel, this._trainingSectionModel,
    this._caseMgmtSectionModel, this._otherServicesSectionModel];
  }

  public get unregisteredCluster() {
    return [this._supportSectionModel, this._infoAssistSectionModel,
    this._infoServiceSectionModel];
  }

  public get respiteCluster() {
    return [this._respiteSectionModel];
  }

  checkValidForm(cluster: string): void {
    let valid = true;
    let clusterSections: Array<Section>;

    switch (cluster) {
      case 'registered': {
        clusterSections = this.registeredCluster;
        clusterSections.forEach(function (currSection) {
          Object.keys(currSection).forEach(function (key) {
            if (currSection[key] != null && currSection[key].questionStatus === 1) {
              valid = false;
            }
          });
        });
        break;
      }
      case 'unregistered': {
        clusterSections = this.unregisteredCluster;

        clusterSections.forEach(function (currSection) {
          Object.keys(currSection).forEach(function (key) {
            if (currSection[key] != null && currSection[key].questionStatus === 1) {
              valid = false;
            }
          });
        });
        break;
      }
      case 'respite': {
        clusterSections = this.respiteCluster;

        clusterSections.forEach(function (currSection) {
          Object.keys(currSection).forEach(function (key) {
            if (currSection[key] != null && currSection[key].questionStatus === 1) {
              valid = false;
            }
          });
        });
        break;
      }
    }

    this.validForm = valid;
  }

  setErrorDisplayType(status: number): string {
    if (status === QuestionStatusEnum.L1InProgressError || status === QuestionStatusEnum.L2InProgressError) {
      return 'alert alert-danger';
    }
    if (status === QuestionStatusEnum.L2ValidatedWarning) {
      return 'alert alert-warning';
    } else { return ''; }
  }

  calculateOutlineColor(status: number): string {
    if (status === QuestionStatusEnum.L1InProgressError || status === QuestionStatusEnum.L2InProgressError) {
      return 'has-error';
    }
    if (status === QuestionStatusEnum.L2ValidatedWarning) {
      return 'has-warning';
    } else { return ''; }
  }

  registeredErrors(): Array<string> {
    const errors: Array<string> = [];
    this.getRegisteredDataElements().forEach((de) => {
      this.getError(de, errors);
    });

    return errors;
  }

  unregisteredErrors(): Array<string> {
    const errors: Array<string> = [];
    this.getUnegisteredDataElements().forEach((de) => {
      this.getError(de, errors);
    });

    return errors;
  }

  respiteErrors(): Array<string> {
    const errors: Array<string> = [];
    this.getRespiteDataElements().forEach((de) => {
      this.getError(de, errors);
    });

    return errors;
  }

  getError(dataElement: DataElement, errors: Array<string>){
    if (dataElement.dataEntryErrorMessage) {
      errors.push(dataElement.label + ': ' + dataElement.dataEntryErrorMessage);
    }
    else if (!dataElement.dataEntryErrorMessage &&
      dataElement.questionStatus === QuestionStatusEnum.L2InProgressError) {
      errors.push(dataElement.label + ': ' + dataElement.businessErrorMessage);
    }
  }

  registeredWarnings(): Array<string> {
    const warnings: Array<string> = [];
    this.getRegisteredDataElements().forEach((de) => {
      this.getWarning(de, warnings);
    });

    return warnings;
  }

  unregisteredWarnings(): Array<string> {
    const warnings: Array<string> = [];
    this.getUnegisteredDataElements().forEach((de) => {
      this.getWarning(de, warnings);
    });

    return warnings;
  }

  respiteWarnings(): Array<string> {
    const warnings: Array<string> = [];
    this.getRespiteDataElements().forEach((de) => {
      this.getWarning(de, warnings);
    });

    return warnings;
  }
  getWarning(dataElement: DataElement, warnings: Array<string>){
    if (dataElement.businessErrorMessage &&
      dataElement.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
        warnings.push(dataElement.label + ': ' + dataElement.businessErrorMessage);
    }
  }


  getRegisteredDataElements(): Array<DataElement> {
    const dataElements: Array<DataElement> = [
      this._counselingSectionModel.totalPersonsServed,
      this._counselingSectionModel.totalServiceUnits,
      this._counselingSectionModel.expend,
      this._counselingSectionModel.expendOtherState,
      this._counselingSectionModel.expendOtherNonState,
      this._counselingSectionModel.programIncome,
      this._trainingSectionModel.totalPersonsServed,
      this._trainingSectionModel.totalServiceUnits,
      this._trainingSectionModel.expend,
      this._trainingSectionModel.expendOtherState,
      this._trainingSectionModel.expendOtherNonState,
      this._trainingSectionModel.programIncome,
      this._caseMgmtSectionModel.totalPersonsServed,
      this._caseMgmtSectionModel.totalServiceUnits,
      this._caseMgmtSectionModel.expend,
      this._caseMgmtSectionModel.expendOtherState,
      this._caseMgmtSectionModel.expendOtherNonState,
      this._caseMgmtSectionModel.programIncome,
      this._otherServicesSectionModel.totalServiceUnits
    ];

    return dataElements;
  }

  getUnegisteredDataElements(): Array<DataElement> {
    const dataElements: Array<DataElement> = [
      this._supportSectionModel.totalServiceUnits,
      this._supportSectionModel.expend,
      this._supportSectionModel.expendOtherState,
      this._supportSectionModel.expendOtherNonState,
      this._supportSectionModel.programIncome,
      this._infoAssistSectionModel.totalPersonsServed,
      this._infoAssistSectionModel.totalServiceUnits,
      this._infoAssistSectionModel.expend,
      this._infoAssistSectionModel.expendOtherState,
      this._infoAssistSectionModel.expendOtherNonState,
      this._infoAssistSectionModel.programIncome,
      this._infoServiceSectionModel.totalPersonsServed,
      this._infoServiceSectionModel.totalServiceUnits,
      this._infoServiceSectionModel.expend,
      this._infoServiceSectionModel.expendOtherState,
      this._infoServiceSectionModel.expendOtherNonState,
      this._infoServiceSectionModel.programIncome
    ];

    return dataElements;
  }

  getRespiteDataElements(): Array<DataElement> {
    const dataElements: Array<DataElement> = [
      this._respiteSectionModel.inhomeTotalUnduplicated,
      this._respiteSectionModel.outDayTotalUnduplicated,
      this._respiteSectionModel.outNightTotalUnduplicated,
      this._respiteSectionModel.unknownRespiteTotalUnduplicated,
      this._respiteSectionModel.inhomeTotalTotalService,
      this._respiteSectionModel.outDayTotalService,
      this._respiteSectionModel.outNightTotalService,
      this._respiteSectionModel.unknownRespiteTotalService,
      this._respiteSectionModel.title3InHome,
      this._respiteSectionModel.title3OutDay,
      this._respiteSectionModel.title3OutNight,
      this._respiteSectionModel.title3Unknown,
      this._respiteSectionModel.otherStateInHome,
      this._respiteSectionModel.otherStateOutDay,
      this._respiteSectionModel.otherStateOutNight,
      this._respiteSectionModel.otherStateUnknown,
      this._respiteSectionModel.otherNonStateInHome,
      this._respiteSectionModel.otherNonStateOutDay,
      this._respiteSectionModel.otherNonStateOutNight,
      this._respiteSectionModel.otherNonStateUnknown,
      this._respiteSectionModel.programInHome,
      this._respiteSectionModel.programOutDay,
      this._respiteSectionModel.programOutNight,
      this._respiteSectionModel.programUnknown
    ];

    return dataElements;
  }

  // updates Total columns and rows
  updateTotals(totalsArray: Array<DataElement>, addDecimals: boolean = true): string {
    let total: number = 0;

    for (let i = 0; i < totalsArray.length; i++) {
      // if not null, remove commas
      if (totalsArray[i].elementValue) {
        totalsArray[i].elementValue = totalsArray[i].elementValue.replace(/,/g, '');
      }
      // if is a number, sum it
      if (!isNaN(parseFloat(totalsArray[i].elementValue))) {
        total += parseFloat(totalsArray[i].elementValue);
      }
      // replace commas when done adding
      if (totalsArray[i].elementValue.includes('.')) {
        totalsArray[i].elementValue = this.addDecimals(totalsArray[i].elementValue);
      } else {
        totalsArray[i].elementValue = this.addComma(totalsArray[i].elementValue);
      }
    }

    this.calcSums();
    this.updatePerPersonRow();

    if (addDecimals) {
      return this.addDecimals(total.toString());
    } else {
      return this.addComma(total.toString());
    }
  }

  updatePerPersonRow(): void {
    const inhomeTotalTotalService = parseFloat(this.removeCommas(this._respiteSectionModel.inhomeTotalTotalService.elementValue));
    const outDayTotalService = parseFloat(this.removeCommas(this._respiteSectionModel.outDayTotalService.elementValue));
    const outNightTotalService = parseFloat(this.removeCommas(this._respiteSectionModel.outNightTotalService.elementValue));
    const unknownRespiteTotalService = parseFloat(this.removeCommas(this._respiteSectionModel.unknownRespiteTotalService.elementValue));
    const totalRespiteTotalService = parseFloat(this.removeCommas(this._respiteSectionModel.totalRespiteTotalService.elementValue));

    const totalExInHome = parseFloat(this.removeCommas(this._respiteSectionModel.totalExInHome.elementValue));
    const totalExOutDay = parseFloat(this.removeCommas(this._respiteSectionModel.totalExOutDay.elementValue));
    const totalExOutNight = parseFloat(this.removeCommas(this._respiteSectionModel.totalExOutNight.elementValue));
    const totalExUnknown = parseFloat(this.removeCommas(this._respiteSectionModel.totalExUnknown.elementValue));
    const totalExTotal = parseFloat(this.removeCommas(this._respiteSectionModel.totalExTotal.elementValue));

    this._respiteSectionModel.perPersonInHome.elementValue = this.expendPerUnit(totalExInHome, inhomeTotalTotalService);
    this._respiteSectionModel.perPersonOutDay.elementValue = this.expendPerUnit(totalExOutDay, outDayTotalService);
    this._respiteSectionModel.perPersonOutNight.elementValue = this.expendPerUnit(totalExOutNight, outNightTotalService);
    this._respiteSectionModel.perPersonUnknown.elementValue = this.expendPerUnit(totalExUnknown, unknownRespiteTotalService);
    this._respiteSectionModel.perPersonTotal.elementValue = this.expendPerUnit(totalExTotal, totalRespiteTotalService);
  }

  private createMissingSections(
    caregivers: CaregiverPagesSection[], respite: CGRespiteSection[], suppServices: CGSuppExpendSection[]): void {

    this._commonService.copyMetadataIf(caregivers[0], this._counselingSectionModel, !this._counselingSectionModel.id);
    this._commonService.copyMetadataIf(caregivers[0], this._trainingSectionModel, !this._trainingSectionModel.id);
    this._commonService.copyMetadataIf(caregivers[0], this._caseMgmtSectionModel, !this._caseMgmtSectionModel.id);
    this._commonService.copyMetadataIf(caregivers[0], this._supportSectionModel, !this._supportSectionModel.id);
    this._commonService.copyMetadataIf(caregivers[0], this._infoAssistSectionModel, !this._infoAssistSectionModel.id);
    this._commonService.copyMetadataIf(caregivers[0], this._infoServiceSectionModel, !this._infoServiceSectionModel.id);

    this._commonService.copyMetadataIf(respite[0], this._respiteSectionModel, !this._respiteSectionModel.id);
    this._commonService.copyMetadataIf(suppServices[0], this._otherServicesSectionModel, !this._otherServicesSectionModel.id);

  }

}
