<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <h2>Title III Service Expenditures - Older Adults Expenditures</h2>
        <p class="info mt-1">All fields are required</p>
        <div class="col-md-12 background-gray-lighter mb-1">
          <h2>Total Expenditures</h2>
        </div>
        <div class="col-md-9">
          <label for="expendC" class="normal">Title III expenditure (Excluding Title III-A)</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" id="expendC" value="{{t3ExpendTotal}}" readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label for="expendA" class="normal">Title III-A expenditure (NSIP Grant)</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" id="expendA" value="{{t3aExpendTotal}}" readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label for="expendOtherState" class="normal">Other: State expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" id="expendOtherState" value="{{otherStateTotal}}" readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label for="expendOtherNonState" class="normal">Other: Non-state expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" id="expendOtherNonState" value="{{nonOtherStateTotal}}" readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label for="programIncome" class="normal">Program income expended</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" id="programIncome" value="{{programIncomeTotal}}" readonly>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <label for="expendTotal" class="normal">Total service expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <div>
            <div class="input-group">
              <div class="input-group-addon">$</div>
              <input type="text" class="form-control" id="expendTotal" value="{{serviceExpendTotal}}" readonly>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-1">
          <!-- error -->
          <i *ngIf="hasError(clusterOneSections)" aria-hidden="true" class="fa fa-exclamation-circle text-danger">
            <span class="sr-only">Error:</span>
          </i>
          <!-- warning -->
          <i *ngIf="hasWarning(clusterOneSections)" aria-hidden="true" class="fa fa-exclamation-triangle text-warning">
            <span class="sr-only">Warning:</span>
          </i>
          <a id="cluster1Lnk" class="btn btn-link" data-target="#myModal" data-toggle="modal" tabindex="0" href="#">View / Enter
            Utilization and Expenditure for Older Adult Cluster 1 Services</a>
        </div>

        <div class="col-md-12 mb-1">
          <!-- error -->
          <i *ngIf="hasError(clusterTwoSections)" aria-hidden="true" class="fa fa-exclamation-circle text-danger">
            <span class="sr-only">Error:</span>
          </i>
          <!-- warning -->
          <i *ngIf="hasWarning(clusterTwoSections)" aria-hidden="true" class="fa fa-exclamation-triangle text-warning">
            <span class="sr-only">Warning:</span>
          </i>
          <a id="cluster2Lnk" class="btn btn-link" data-target="#myModal2" data-toggle="modal" tabindex="0" href="#">View / Enter
            Utilization and Expenditure for Older Adult Cluster 2 and Restricted Services</a>
        </div>

        <div class="col-md-12 mb-1">
          <!-- error -->
          <i *ngIf="hasError(clusterThreeSections)" aria-hidden="true" class="fa fa-exclamation-circle text-danger">
            <span class="sr-only">Error:</span>
          </i>
          <!-- warning -->
          <i *ngIf="hasWarning(clusterThreeSections)" aria-hidden="true"
            class="fa fa-exclamation-triangle text-warning">
            <span class="sr-only">Warning:</span>
          </i>
          <a id="cluster3Lnk" class="btn btn-link" data-target="#myModal3" data-toggle="modal" tabindex="0" href="#">View / Enter
            Utilization and Expenditure for Older Adult Cluster 3 Services</a>
        </div>

        <!-- Button trigger modal -->
        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div class="modal-dialog modal-xlg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title" id="myModalLabel">Utilization and Expenditure for Older Adult Cluster 1
                  Services</h4>
              </div>
              <div class="modal-body">

                <table *ngIf="cluster1Errors().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-error">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Error(s):
                        </span>
                        <p *ngFor="let clusterOneErrorItem of cluster1Errors()" class="help-block">
                          {{clusterOneErrorItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table *ngIf="cluster1Warnings().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-warning">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):
                        </span>
                        <p *ngFor="let clusterOneWarnItem of cluster1Warnings()" class="help-block">
                          {{clusterOneWarnItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="table-responsive">
                  <table class="table table-no-border">
                    <caption class="sr-only">Utilization and Expenditure for Older Adult Cluster 1 Services</caption>
                    <thead>
                      <tr class="background-gray-lighter">
                        <td></td>
                        <th id="personalCareClusterOne">
                          <a id="titleIIIPersonalCareUnitsLnk" [routerLink]="['../titleIIIPersonalCareUnits']" data-dismiss="modal">Personal
                            Care</a>
                        </th>
                        <th id="homemakerClusterOne">
                          <a id="titleIIIHomemakerUnitsLnk" [routerLink]="['../titleIIIHomemakerUnits']" data-dismiss="modal">Homemaker</a>
                        </th>
                        <th id="choreClusterOne">
                          <a id="titleIIIChoreUnitsLnk" [routerLink]="['../titleIIIChoreUnits']"data-dismiss="modal">Chore</a>
                        </th>
                        <th id="homeDeliveredMealsClusterOne">
                          <a id="titleIIIHomeDeliveredMealsUnitsLnk"
                            [routerLink]="['../titleIIIHomeDeliveredMealsUnits']" data-dismiss="modal">Home Delivered Nutrition</a>
                        </th>
                        <th id="adultCareHealthClusterOne">
                          <a id="titleIIIAdultDayCareUnitsLnk" [routerLink]="['../titleIIIAdultDayCareUnits']" data-dismiss="modal">Adult Day
                            Care/Health</a>
                        </th>
                        <th id="caseMangementClusterOne">
                          <a id="titleIIICaseMgmtUnitsLnk" [routerLink]="['../titleIIICaseMgmtUnits']" data-dismiss="modal">Case
                            Management</a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th id="unduplicatedNoPerServedClusterOne" class="normal">Unduplicated number of persons served
                      </th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_personalCareCharsModel.totalPersonsServed.questionStatus)">
                          <input [(ngModel)]="_personalCareCharsModel.totalPersonsServed.elementValue"
                            [disabled]="!_personalCareCharsModel.totalPersonsServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterOnePersonalCareClusterOne"
                            aria-labelledby="unduplicatedNoPerServedClusterOne personalCareClusterOne"
                            (change)="unsavedChanges = true;
                          _personalCareCharsModel.totalPersonsServed.elementValue = addComma(_personalCareCharsModel.totalPersonsServed.elementValue); _personalCareCharsModel.totalPersonsServed = validate(_personalCareCharsModel.totalPersonsServed); checkValidForm('cluster1')">
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_homemakerCharsModel.totalPersonsServed.questionStatus)">
                          <input [(ngModel)]="_homemakerCharsModel.totalPersonsServed.elementValue"
                            [disabled]="!_homemakerCharsModel.totalPersonsServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterOneHomemakerClusterOne"
                            aria-labelledby="unduplicatedNoPerServedClusterOne homemakerClusterOne"
                            (change)="unsavedChanges = true;
                          _homemakerCharsModel.totalPersonsServed.elementValue = addComma(_homemakerCharsModel.totalPersonsServed.elementValue); _homemakerCharsModel.totalPersonsServed = validate(_homemakerCharsModel.totalPersonsServed); checkValidForm('cluster1')">
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_choreCharsModel.totalPersonsServed.questionStatus)">
                          <input [(ngModel)]="_choreCharsModel.totalPersonsServed.elementValue"
                            [disabled]="!_choreCharsModel.totalPersonsServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterOneChoreClusterOne"
                            aria-labelledby="unduplicatedNoPerServedClusterOne choreClusterOne"
                            (change)="unsavedChanges = true;
                          _choreCharsModel.totalPersonsServed.elementValue = addComma(_choreCharsModel.totalPersonsServed.elementValue); _choreCharsModel.totalPersonsServed = validate(_choreCharsModel.totalPersonsServed); checkValidForm('cluster1')">
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_homeDeliveredMealsCharsModel.totalPersonsServed.questionStatus)">
                          <input [(ngModel)]="_homeDeliveredMealsCharsModel.totalPersonsServed.elementValue"
                            [disabled]="!_homeDeliveredMealsCharsModel.totalPersonsServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterOneHomeDeliveredMealsClusterOne"
                            aria-labelledby="unduplicatedNoPerServedClusterOne homeDeliveredMealsClusterOne"
                            (change)="unsavedChanges = true;
                          _homeDeliveredMealsCharsModel.totalPersonsServed.elementValue = addComma(_homeDeliveredMealsCharsModel.totalPersonsServed.elementValue); _homeDeliveredMealsCharsModel.totalPersonsServed = validate(_homeDeliveredMealsCharsModel.totalPersonsServed); checkValidForm('cluster1')">
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_adultDayCareCharsModel.totalPersonsServed.questionStatus)">
                          <input [(ngModel)]="_adultDayCareCharsModel.totalPersonsServed.elementValue"
                            [disabled]="!_adultDayCareCharsModel.totalPersonsServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterOneAdultCareHealthClusterOne"
                            aria-labelledby="unduplicatedNoPerServedClusterOne adultCareHealthClusterOne"
                            (change)="unsavedChanges = true;
                          _adultDayCareCharsModel.totalPersonsServed.elementValue = addComma(_adultDayCareCharsModel.totalPersonsServed.elementValue); _adultDayCareCharsModel.totalPersonsServed = validate(_adultDayCareCharsModel.totalPersonsServed); checkValidForm('cluster1')">
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_caseMgmtCharsModel.totalPersonsServed.questionStatus)">
                          <input [(ngModel)]="_caseMgmtCharsModel.totalPersonsServed.elementValue"
                            [disabled]="!_caseMgmtCharsModel.totalPersonsServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterOneCaseMangementClusterOne"
                            aria-labelledby="unduplicatedNoPerServedClusterOne caseMangementClusterOne"
                            (change)="unsavedChanges = true;
                          _caseMgmtCharsModel.totalPersonsServed.elementValue = addComma(_caseMgmtCharsModel.totalPersonsServed.elementValue); _caseMgmtCharsModel.totalPersonsServed = validate(_caseMgmtCharsModel.totalPersonsServed); checkValidForm('cluster1')">
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="serviceUnitsClusterOne" class="normal">Service units</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_personalCareSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_personalCareSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_personalCareSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterOnePersonalCareClusterOne"
                            aria-labelledby="serviceUnitsClusterOne personalCareClusterOne"
                            (change)="unsavedChanges = true;
                          _personalCareSectionModel.totalServiceUnits.elementValue = addComma(_personalCareSectionModel.totalServiceUnits.elementValue);
                          updatePerUnit(); _personalCareSectionModel.totalServiceUnits = validate(_personalCareSectionModel.totalServiceUnits); checkValidForm('cluster1')">
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_homemakerSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_homemakerSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_homemakerSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterOneHomemakerClusterOne"
                            aria-labelledby="serviceUnitsClusterOne homemakerClusterOne"
                            (change)="unsavedChanges = true;
                          _homemakerSectionModel.totalServiceUnits.elementValue = addComma(_homemakerSectionModel.totalServiceUnits.elementValue);
                           updatePerUnit(); _homemakerSectionModel.totalServiceUnits = validate(_homemakerSectionModel.totalServiceUnits); checkValidForm('cluster1')">
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_choreSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_choreSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_choreSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterOneChoreClusterOne"
                            aria-labelledby="serviceUnitsClusterOne choreClusterOne"
                            (change)="unsavedChanges = true;
                          _choreSectionModel.totalServiceUnits.elementValue = addComma(_choreSectionModel.totalServiceUnits.elementValue);
                          updatePerUnit(); _choreSectionModel.totalServiceUnits = validate(_choreSectionModel.totalServiceUnits); checkValidForm('cluster1')">
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_homeDeliveredMealsSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_homeDeliveredMealsSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_homeDeliveredMealsSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterOneHomeDeliveredMealsClusterOne"
                            aria-labelledby="serviceUnitsClusterOne homeDeliveredMealsClusterOne"
                            (change)="unsavedChanges = true;
                          _homeDeliveredMealsSectionModel.totalServiceUnits.elementValue = addComma(_homeDeliveredMealsSectionModel.totalServiceUnits.elementValue);
                           updatePerUnit(); _homeDeliveredMealsSectionModel.totalServiceUnits = validate(_homeDeliveredMealsSectionModel.totalServiceUnits); checkValidForm('cluster1')">
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_adultDayCareSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_adultDayCareSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_adultDayCareSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterOneAdultCareHealthClusterOne"
                            aria-labelledby="serviceUnitsClusterOne adultCareHealthClusterOne"
                            (change)="unsavedChanges = true;
                          _adultDayCareSectionModel.totalServiceUnits.elementValue = addComma(_adultDayCareSectionModel.totalServiceUnits.elementValue);
                          updatePerUnit(); _adultDayCareSectionModel.totalServiceUnits = validate(_adultDayCareSectionModel.totalServiceUnits); checkValidForm('cluster1')">
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_caseMgmtSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_caseMgmtSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_caseMgmtSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterOneCaseMangementClusterOne"
                            aria-labelledby="serviceUnitsClusterOne caseMangementClusterOne"
                            (change)="unsavedChanges = true;
                          _caseMgmtSectionModel.totalServiceUnits.elementValue = addComma(_caseMgmtSectionModel.totalServiceUnits.elementValue);
                          updatePerUnit(); _caseMgmtSectionModel.totalServiceUnits = validate(_caseMgmtSectionModel.totalServiceUnits); checkValidForm('cluster1')">
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th id="title3AExpClusterOne" class="normal">Title III A expenditure (NSIP grant)</th>
                      <td class="background-gray-lighter"></td>
                      <td></td>
                      <td class="background-gray-lighter"></td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_homeDeliveredMealsSectionModel.expendA.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_homeDeliveredMealsSectionModel.expendA.elementValue"
                              [disabled]="!_homeDeliveredMealsSectionModel.expendA.isRequired" type="text"
                              class="form-control" id="title3AExpClusterOneHomeDeliveredMealsClusterOne"
                              aria-labelledby="title3AExpClusterOne homeDeliveredMealsClusterOne"
                              (change)="unsavedChanges = true;
                            _homeDeliveredMealsSectionModel.expendTotal.elementValue = updateTotal(homeDeliveredArr);
                            updatePerUnit(); _homeDeliveredMealsSectionModel.expendA = validate(_homeDeliveredMealsSectionModel.expendA); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter"></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th id="title3ExpClusterOne" class="normal">Title III expenditure (Excluding Title III-A)</th>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_personalCareSectionModel.expendC.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_personalCareSectionModel.expendC.elementValue"
                              [disabled]="!_personalCareSectionModel.expendC.isRequired" type="text"
                              class="form-control" id="title3ExpClusterOnePersonalCareClusterOne"
                              aria-labelledby="title3ExpClusterOne personalCareClusterOne"
                              (change)="unsavedChanges = true;
                            _personalCareSectionModel.expendTotal.elementValue = updateTotal(personalCareArr);
                            updatePerUnit(); _personalCareSectionModel.expendC = validate(_personalCareSectionModel.expendC); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_homemakerSectionModel.expendC.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_homemakerSectionModel.expendC.elementValue"
                              [disabled]="!_homemakerSectionModel.expendC.isRequired" type="text" class="form-control"
                              id="title3ExpClusterOneHomemakerClusterOne"
                              aria-labelledby="title3ExpClusterOne homemakerClusterOne"
                              (change)="unsavedChanges = true;
                            _homemakerSectionModel.expendTotal.elementValue = updateTotal(homemakerArr);
                            updatePerUnit(); _homemakerSectionModel.expendC = validate(_homemakerSectionModel.expendC); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_choreSectionModel.expendC.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_choreSectionModel.expendC.elementValue"
                              [disabled]="!_choreSectionModel.expendC.isRequired" type="text" class="form-control"
                              id="title3ExpClusterOneChoreClusterOne"
                              aria-labelledby="title3ExpClusterOne choreClusterOne"
                              (change)="unsavedChanges = true;
                            _choreSectionModel.expendTotal.elementValue = updateTotal(choreArr);
                            updatePerUnit(); _choreSectionModel.expendC = validate(_choreSectionModel.expendC); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_homeDeliveredMealsSectionModel.expendC.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_homeDeliveredMealsSectionModel.expendC.elementValue"
                              [disabled]="!_homeDeliveredMealsSectionModel.expendC.isRequired" type="text"
                              class="form-control" id="title3ExpClusterOneHomeDeliveredMealsClusterOne"
                              aria-labelledby="title3ExpClusterOne homeDeliveredMealsClusterOne"
                              (change)="unsavedChanges = true;
                            _homeDeliveredMealsSectionModel.expendTotal.elementValue = updateTotal(homeDeliveredArr);
                            updatePerUnit(); _homeDeliveredMealsSectionModel.expendC = validate(_homeDeliveredMealsSectionModel.expendC); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_adultDayCareSectionModel.expendC.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_adultDayCareSectionModel.expendC.elementValue"
                              [disabled]="!_adultDayCareSectionModel.expendC.isRequired" type="text"
                              class="form-control" id="title3ExpClusterOneAdultCareHealthClusterOne"
                              aria-labelledby="title3ExpClusterOne adultCareHealthClusterOne"
                              (change)="unsavedChanges = true;
                            _adultDayCareSectionModel.expendTotal.elementValue = updateTotal(adultDayCareArr);
                            updatePerUnit(); _adultDayCareSectionModel.expendC = validate(_adultDayCareSectionModel.expendC); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_caseMgmtSectionModel.expendC.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_caseMgmtSectionModel.expendC.elementValue"
                              [disabled]="!_caseMgmtSectionModel.expendC.isRequired" type="text" class="form-control"
                              id="title3ExpClusterOneCaseMangementClusterOne"
                              aria-labelledby="title3ExpClusterOne caseMangementClusterOne"
                              (change)="unsavedChanges = true;
                            _caseMgmtSectionModel.expendTotal.elementValue = updateTotal(caseMgmtArr);
                            updatePerUnit(); _caseMgmtSectionModel.expendC = validate(_caseMgmtSectionModel.expendC); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="otherStateExpClusterOne" class="normal">Other: State expenditure</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_personalCareSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_personalCareSectionModel.expendOtherState.elementValue"
                              [disabled]="!_personalCareSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterOnePersonalCareClusterOne"
                              aria-labelledby="otherStateExpClusterOne personalCareClusterOne"
                              (change)="unsavedChanges = true; _personalCareSectionModel.expendTotal.elementValue = updateTotal(personalCareArr); updatePerUnit(); _personalCareSectionModel.expendOtherState = validate(_personalCareSectionModel.expendOtherState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_homemakerSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_homemakerSectionModel.expendOtherState.elementValue"
                              [disabled]="!_homemakerSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterOneHomemakerClusterOne"
                              aria-labelledby="otherStateExpClusterOne homemakerClusterOne"
                              (change)="unsavedChanges = true; _homemakerSectionModel.expendTotal.elementValue = updateTotal(homemakerArr); updatePerUnit(); _homemakerSectionModel.expendOtherState = validate(_homemakerSectionModel.expendOtherState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_choreSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_choreSectionModel.expendOtherState.elementValue"
                              [disabled]="!_choreSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterOneChoreClusterOne"
                              aria-labelledby="otherStateExpClusterOne choreClusterOne"
                              (change)="unsavedChanges = true; _choreSectionModel.expendTotal.elementValue = updateTotal(choreArr); updatePerUnit(); _choreSectionModel.expendOtherState = validate(_choreSectionModel.expendOtherState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_homeDeliveredMealsSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_homeDeliveredMealsSectionModel.expendOtherState.elementValue"
                              [disabled]="!_homeDeliveredMealsSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterOneHomeDeliveredMealsClusterOne"
                              aria-labelledby="otherStateExpClusterOne homeDeliveredMealsClusterOne"
                              (change)="unsavedChanges = true; _homeDeliveredMealsSectionModel.expendTotal.elementValue = updateTotal(homeDeliveredArr); updatePerUnit(); _homeDeliveredMealsSectionModel.expendOtherState = validate(_homeDeliveredMealsSectionModel.expendOtherState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_adultDayCareSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_adultDayCareSectionModel.expendOtherState.elementValue"
                              [disabled]="!_adultDayCareSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterOneAdultCareHealthClusterOne"
                              aria-labelledby="otherStateExpClusterOne adultCareHealthClusterOne"
                              (change)="unsavedChanges = true; _adultDayCareSectionModel.expendTotal.elementValue = updateTotal(adultDayCareArr); updatePerUnit(); _adultDayCareSectionModel.expendOtherState = validate(_adultDayCareSectionModel.expendOtherState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_caseMgmtSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_caseMgmtSectionModel.expendOtherState.elementValue"
                              [disabled]="!_caseMgmtSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterOneCaseMangementClusterOne"
                              aria-labelledby="otherStateExpClusterOne caseMangementClusterOne"
                              (change)="unsavedChanges = true; _caseMgmtSectionModel.expendTotal.elementValue = updateTotal(caseMgmtArr); updatePerUnit(); _caseMgmtSectionModel.expendOtherState = validate(_caseMgmtSectionModel.expendOtherState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="otherNonStateExpClusterOne" class="normal">Other: Non-state expenditure</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_personalCareSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_personalCareSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_personalCareSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterOnePersonalCareClusterOne"
                              aria-labelledby="otherNonStateExpClusterOne personalCareClusterOne"
                              (change)="unsavedChanges = true; _personalCareSectionModel.expendTotal.elementValue = updateTotal(personalCareArr); updatePerUnit(); _personalCareSectionModel.expendOtherNonState = validate(_personalCareSectionModel.expendOtherNonState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_homemakerSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_homemakerSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_homemakerSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterOneHomemakerClusterOne"
                              aria-labelledby="otherNonStateExpClusterOne homemakerClusterOne"
                              (change)="unsavedChanges = true; _homemakerSectionModel.expendTotal.elementValue = updateTotal(homemakerArr); updatePerUnit(); _homemakerSectionModel.expendOtherNonState = validate(_homemakerSectionModel.expendOtherNonState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_choreSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_choreSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_choreSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterOneChoreClusterOne"
                              aria-labelledby="otherNonStateExpClusterOne choreClusterOne"
                              (change)="unsavedChanges = true; _choreSectionModel.expendTotal.elementValue = updateTotal(choreArr); updatePerUnit(); _choreSectionModel.expendOtherNonState = validate(_choreSectionModel.expendOtherNonState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_homeDeliveredMealsSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_homeDeliveredMealsSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_homeDeliveredMealsSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterOneHomeDeliveredMealsClusterOne"
                              aria-labelledby="otherNonStateExpClusterOne homeDeliveredMealsClusterOne"
                              (change)="unsavedChanges = true; _homeDeliveredMealsSectionModel.expendTotal.elementValue = updateTotal(homeDeliveredArr); updatePerUnit(); _homeDeliveredMealsSectionModel.expendOtherNonState = validate(_homeDeliveredMealsSectionModel.expendOtherNonState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_adultDayCareSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_adultDayCareSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_adultDayCareSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterOneAdultCareHealthClusterOne"
                              aria-labelledby="otherNonStateExpClusterOne adultCareHealthClusterOne"
                              (change)="unsavedChanges = true; _adultDayCareSectionModel.expendTotal.elementValue = updateTotal(adultDayCareArr); updatePerUnit(); _adultDayCareSectionModel.expendOtherNonState = validate(_adultDayCareSectionModel.expendOtherNonState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_caseMgmtSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_caseMgmtSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_caseMgmtSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterOneCaseMangementClusterOne"
                              aria-labelledby="otherNonStateExpClusterOne caseMangementClusterOne"
                              (change)="unsavedChanges = true; _caseMgmtSectionModel.expendTotal.elementValue = updateTotal(caseMgmtArr); updatePerUnit(); _caseMgmtSectionModel.expendOtherNonState = validate(_caseMgmtSectionModel.expendOtherNonState); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="programIncomeClusterOne" class="normal">Program income expended</th>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_personalCareSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_personalCareSectionModel.programIncome.elementValue"
                              [disabled]="!_personalCareSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterOnePersonalCareClusterOne"
                              aria-labelledby="programIncomeClusterOne personalCareClusterOne"
                              (change)="unsavedChanges = true; _personalCareSectionModel.expendTotal.elementValue = updateTotal(personalCareArr); updatePerUnit(); _personalCareSectionModel.programIncome = validate(_personalCareSectionModel.programIncome); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_homemakerSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_homemakerSectionModel.programIncome.elementValue"
                              [disabled]="!_homemakerSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterOneHomemakerClusterOne"
                              aria-labelledby="programIncomeClusterOne homemakerClusterOne"
                              (change)="unsavedChanges = true; _homemakerSectionModel.expendTotal.elementValue = updateTotal(homemakerArr); updatePerUnit(); _homemakerSectionModel.programIncome = validate(_homemakerSectionModel.programIncome); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_choreSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_choreSectionModel.programIncome.elementValue"
                              [disabled]="!_choreSectionModel.programIncome.isRequired" type="text" class="form-control"
                              id="programIncomeClusterOneChoreClusterOne"
                              aria-labelledby="programIncomeClusterOne choreClusterOne"
                              (change)="unsavedChanges = true; _choreSectionModel.expendTotal.elementValue = updateTotal(choreArr); updatePerUnit(); _choreSectionModel.programIncome = validate(_choreSectionModel.programIncome); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_homeDeliveredMealsSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_homeDeliveredMealsSectionModel.programIncome.elementValue"
                              [disabled]="!_homeDeliveredMealsSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterOneHomeDeliveredMealsClusterOne"
                              aria-labelledby="programIncomeClusterOne homeDeliveredMealsClusterOne"
                              (change)="unsavedChanges = true; _homeDeliveredMealsSectionModel.expendTotal.elementValue = updateTotal(homeDeliveredArr); updatePerUnit(); _homeDeliveredMealsSectionModel.programIncome = validate(_homeDeliveredMealsSectionModel.programIncome); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_adultDayCareSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_adultDayCareSectionModel.programIncome.elementValue"
                              [disabled]="!_adultDayCareSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterOneAdultCareHealthClusterOne"
                              aria-labelledby="programIncomeClusterOne adultCareHealthClusterOne"
                              (change)="unsavedChanges = true; _adultDayCareSectionModel.expendTotal.elementValue = updateTotal(adultDayCareArr); updatePerUnit(); _adultDayCareSectionModel.programIncome = validate(_adultDayCareSectionModel.programIncome); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_caseMgmtSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_caseMgmtSectionModel.programIncome.elementValue"
                              [disabled]="!_caseMgmtSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterOneCaseMangementClusterOne"
                              aria-labelledby="programIncomeClusterOne caseMangementClusterOne"
                              (change)="unsavedChanges = true; _caseMgmtSectionModel.expendTotal.elementValue = updateTotal(caseMgmtArr); updatePerUnit(); _caseMgmtSectionModel.programIncome = validate(_caseMgmtSectionModel.programIncome); checkValidForm('cluster1')">
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="totalServExpClusterOne" class="normal">Total service expenditure</th>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_personalCareSectionModel.expendTotal.elementValue"
                            [disabled]="!_personalCareSectionModel.expendTotal.isRequired" type="text"
                            class="form-control" id="totalServExpClusterOnePersonalCareClusterOne"
                            aria-labelledby="totalServExpClusterOne personalCareClusterOne" readonly>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_homemakerSectionModel.expendTotal.elementValue"
                            [disabled]="!_homemakerSectionModel.expendTotal.isRequired" type="text" class="form-control"
                            id="totalServExpClusterOneHomemakerClusterOne"
                            aria-labelledby="totalServExpClusterOne homemakerClusterOne" readonly>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_choreSectionModel.expendTotal.elementValue"
                            [disabled]="!_choreSectionModel.expendTotal.isRequired" type="text" class="form-control"
                            id="totalServExpClusterOneChoreClusterOne"
                            aria-labelledby="totalServExpClusterOne choreClusterOne" readonly>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_homeDeliveredMealsSectionModel.expendTotal.elementValue"
                            [disabled]="!_homeDeliveredMealsSectionModel.expendTotal.isRequired" type="text"
                            class="form-control" id="totalServExpClusterOneHomeDeliveredMealsClusterOne"
                            aria-labelledby="totalServExpClusterOne homeDeliveredMealsClusterOne" readonly>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_adultDayCareSectionModel.expendTotal.elementValue"
                            [disabled]="!_adultDayCareSectionModel.expendTotal.isRequired" type="text"
                            class="form-control" id="totalServExpClusterOneAdultCareHealthClusterOne"
                            aria-labelledby="totalServExpClusterOne adultCareHealthClusterOne" readonly>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_caseMgmtSectionModel.expendTotal.elementValue"
                            [disabled]="!_caseMgmtSectionModel.expendTotal.isRequired" type="text" class="form-control"
                            id="totalServExpClusterOneCaseMangementClusterOne"
                            aria-labelledby="totalServExpClusterOne caseMangementClusterOne" readonly>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="expPerUnitClusterOne" class="normal">Expenditure per unit</th>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <input [(ngModel)]="_personalCareSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_personalCareSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterOnePersonalCareClusterOne"
                            aria-labelledby="expPerUnitClusterOne personalCareClusterOne" readonly>
                          <div class="input-group-addon">/hour</div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <input [(ngModel)]="_homemakerSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_homemakerSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterOneHomemakerClusterOne"
                            aria-labelledby="expPerUnitClusterOne homemakerClusterOne" readonly>
                          <div class="input-group-addon">/hour</div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <input [(ngModel)]="_choreSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_choreSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterOneChoreClusterOne"
                            aria-labelledby="expPerUnitClusterOne choreClusterOne" readonly>
                          <div class="input-group-addon">/hour</div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <input [(ngModel)]="_homeDeliveredMealsSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_homeDeliveredMealsSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterOneHomeDeliveredMealsClusterOne"
                            aria-labelledby="expPerUnitClusterOne homeDeliveredMealsClusterOne" readonly>
                          <div class="input-group-addon">/meal</div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <input [(ngModel)]="_adultDayCareSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_adultDayCareSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterOneAdultCareHealthClusterOne"
                            aria-labelledby="expPerUnitClusterOne adultCareHealthClusterOne" readonly>
                          <div class="input-group-addon">/hour</div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <input [(ngModel)]="_caseMgmtSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_caseMgmtSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterOneCaseMangementClusterOne"
                            aria-labelledby="expPerUnitClusterOne caseMangementClusterOne" readonly>
                          <div class="input-group-addon">/hour</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  </table>

                </div>
                <!-- ./end table-responsive -->
              </div>
              <div class="modal-footer">
                <button id="oaSaveAllBtn1" type="button" *ngIf="validForm" class="btn btn-primary" data-dismiss="modal"
                  (click)="saveAllSections()">Save changes</button>
                <button id="oaSaveAllBtnDisabled1" type="button" *ngIf="!validForm" class="btn btn-primary"
                  data-dismiss="modal" disabled>Save changes</button>
                <button id="oaCloseBtn1" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Button trigger modal -->
        <div class="modal fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModal2Label">
          <div class="modal-dialog modal-xlg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title" id="myModal2Label">Utilization and Expenditure for Older Adult Cluster 2 and
                  Restricted Services</h4>
              </div>
              <div class="modal-body">

                <table *ngIf="cluster2Errors().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-error">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Error(s):
                        </span>
                        <p *ngFor="let clusterTwoErrorItem of cluster2Errors()" class="help-block">
                          {{clusterTwoErrorItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table *ngIf="cluster2Warnings().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-warning">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):
                        </span>
                        <p *ngFor="let clusterTwoWarnItem of cluster2Warnings()" class="help-block">
                          {{clusterTwoWarnItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="table-responsive">
                  <table class="table table-no-border">
                    <caption class="sr-only">Utilization and Expenditure for Older Adult Cluster 2 and Restricted
                      Services</caption>
                    <thead>
                      <tr class="background-gray-lighter">
                        <td></td>
                        <th id="assistedTransClusterTwo">
                          <a [routerLink]="['../titleIIIAssistedTransportationUnits']" data-dismiss="modal">Assisted Transportation</a>
                        </th>
                        <th id="congregateMealsClusterTwo">
                          <a [routerLink]="['../titleIIICongregateMealsUnits']" data-dismiss="modal">Congregate Nutrition</a>
                        </th>
                        <th id="nutritionCounselingClusterTwo">
                          <a [routerLink]="['../titleIIINutritionCounselingUnits']" data-dismiss="modal">Nutrition Counseling</a>
                        </th>
                        <th id="legalClusterTwo">
                          <a [routerLink]="['../titleIIILegalUnits']" data-dismiss="modal">Legal Assistance<br>(Restricted Services)</a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th id="unduplicatedNoPerServedClusterTwo" class="normal" width="40%">Unduplicated number of
                        persons served</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_assistedTranspCharsModel.totalPersonsServed.questionStatus)">
                          <input [(ngModel)]="_assistedTranspCharsModel.totalPersonsServed.elementValue"
                            [disabled]="!_assistedTranspCharsModel.totalPersonsServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterTwoAssistedTransClusterTwo"
                            aria-labelledby="unduplicatedNoPerServedClusterTwo assistedTransClusterTwo"
                            (change)="unsavedChanges = true;
                          _assistedTranspCharsModel.totalPersonsServed.elementValue = addComma(_assistedTranspCharsModel.totalPersonsServed.elementValue); _assistedTranspCharsModel.totalPersonsServed = validate(_assistedTranspCharsModel.totalPersonsServed); checkValidForm('cluster2')">
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_congregateMealsCharsModel.totalPersonsServed.questionStatus)">
                          <input [(ngModel)]="_congregateMealsCharsModel.totalPersonsServed.elementValue"
                            [disabled]="!_congregateMealsCharsModel.totalPersonsServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterTwoCongregateMealsClusterTwo"
                            aria-labelledby="unduplicatedNoPerServedClusterTwo congregateMealsClusterTwo"
                            (change)="unsavedChanges = true;
                          _congregateMealsCharsModel.totalPersonsServed.elementValue = addComma(_congregateMealsCharsModel.totalPersonsServed.elementValue); _congregateMealsCharsModel.totalPersonsServed = validate(_congregateMealsCharsModel.totalPersonsServed); checkValidForm('cluster2')">
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_nutritionCounselingCharsModel.totalPersonsServed.questionStatus)">
                          <input [(ngModel)]="_nutritionCounselingCharsModel.totalPersonsServed.elementValue"
                            [disabled]="!_nutritionCounselingCharsModel.totalPersonsServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterTwoNutritionCounselingClusterTwo"
                            aria-labelledby="unduplicatedNoPerServedClusterTwo nutritionCounselingClusterTwo"
                            (change)="unsavedChanges = true;
                          _nutritionCounselingCharsModel.totalPersonsServed.elementValue = addComma(_nutritionCounselingCharsModel.totalPersonsServed.elementValue); _nutritionCounselingCharsModel.totalPersonsServed = validate(_nutritionCounselingCharsModel.totalPersonsServed); checkValidForm('cluster2')">
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_legalCharsModel.totalPersonsServed.questionStatus)">
                          <input [(ngModel)]="_legalCharsModel.totalPersonsServed.elementValue"
                            [disabled]="!_legalCharsModel.totalPersonsServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterTwoLegalClusterTwo"
                            aria-labelledby="unduplicatedNoPerServedClusterTwo legalClusterTwo"
                            (change)="unsavedChanges = true;
                          _legalCharsModel.totalPersonsServed.elementValue = addComma(_legalCharsModel.totalPersonsServed.elementValue); _legalCharsModel.totalPersonsServed = validate(_legalCharsModel.totalPersonsServed); checkValidForm('cluster2')">
                        </div>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <th id="serviceUnitsClusterTwo" class="normal">Service units</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_assistedTranspSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_assistedTranspSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_assistedTranspSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterTwoAssistedTransClusterTwo"
                            aria-labelledby="serviceUnitsClusterTwo assistedTransClusterTwo"
                            (change)="unsavedChanges = true;
                          _assistedTranspSectionModel.totalServiceUnits.elementValue = addComma(_assistedTranspSectionModel.totalServiceUnits.elementValue);
                          updatePerUnit(); _assistedTranspSectionModel.totalServiceUnits = validate(_assistedTranspSectionModel.totalServiceUnits); checkValidForm('cluster2')">
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_congregateMealsSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_congregateMealsSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_congregateMealsSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterTwoCongregateMealsClusterTwo"
                            aria-labelledby="serviceUnitsClusterTwo congregateMealsClusterTwo"
                            (change)="unsavedChanges = true;
                          _congregateMealsSectionModel.totalServiceUnits.elementValue = addComma(_congregateMealsSectionModel.totalServiceUnits.elementValue);
                          updatePerUnit(); _congregateMealsSectionModel.totalServiceUnits = validate(_congregateMealsSectionModel.totalServiceUnits); checkValidForm('cluster2')">
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_nutritionCounselingSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_nutritionCounselingSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_nutritionCounselingSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterTwoNutritionCounselingClusterTwo"
                            aria-labelledby="serviceUnitsClusterTwo nutritionCounselingClusterTwo"
                            (change)="unsavedChanges = true;
                          _nutritionCounselingSectionModel.totalServiceUnits.elementValue = addComma(_nutritionCounselingSectionModel.totalServiceUnits.elementValue);
                          updatePerUnit(); _nutritionCounselingSectionModel.totalServiceUnits = validate(_nutritionCounselingSectionModel.totalServiceUnits); checkValidForm('cluster2')">
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_legalSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_legalSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_legalSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterTwoLegalClusterTwo"
                            aria-labelledby="serviceUnitsClusterTwo legalClusterTwo"
                            (change)="unsavedChanges = true;
                          _legalSectionModel.totalServiceUnits.elementValue = addComma(_legalSectionModel.totalServiceUnits.elementValue);
                          updatePerUnit(); _legalSectionModel.totalServiceUnits = validate(_legalSectionModel.totalServiceUnits); checkValidForm('cluster2')">
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th id="title3AExpClusterTwo" class="normal">Title III C1 expenditure</th>
                      <td class="background-gray-lighter"></td>
                      <td>
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_nutritionCounselingSectionModel.expendC1.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionCounselingSectionModel.expendC1.elementValue"
                              [disabled]="!_nutritionCounselingSectionModel.expendC1.isRequired" type="text"
                              class="form-control" id="title3ExpClusterTwoNutritionCounselingClusterTwo"
                              aria-labelledby="title3ExpClusterTwo nutritionCounselingClusterTwo"
                              (change)="unsavedChanges = true;
                              updateNutritionCounselingExpendC();
                              _nutritionCounselingSectionModel.expendTotal.elementValue = updateTotal(nutritionCounsArr);
                              updatePerUnit(); _nutritionCounselingSectionModel.expendC1 = validate(_nutritionCounselingSectionModel.expendC1); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <th id="title3AExpClusterTwo" class="normal">Title III C2 expenditure</th>
                      <td class="background-gray-lighter"></td>
                      <td>                    
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_nutritionCounselingSectionModel.expendC2.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionCounselingSectionModel.expendC2.elementValue"
                              [disabled]="!_nutritionCounselingSectionModel.expendC2.isRequired" type="text"
                              class="form-control" id="title3ExpClusterTwoNutritionCounselingClusterTwo"
                              aria-labelledby="title3ExpClusterTwo nutritionCounselingClusterTwo"
                              (change)="unsavedChanges = true;
                              updateNutritionCounselingExpendC();
                              _nutritionCounselingSectionModel.expendTotal.elementValue = updateTotal(nutritionCounsArr);
                              updatePerUnit(); _nutritionCounselingSectionModel.expendC2 = validate(_nutritionCounselingSectionModel.expendC2); checkValidForm('cluster2')">
                          </div>
                        </div>                          
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <th id="title3AExpClusterTwo" class="normal">Title III Part B expenditure</th>
                      <td class="background-gray-lighter"></td>
                      <td>                    
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_nutritionCounselingSectionModel.partB.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionCounselingSectionModel.partB.elementValue"
                              [disabled]="!_nutritionCounselingSectionModel.partB.isRequired" type="text"
                              class="form-control" id="title3ExpClusterTwoNutritionCounselingClusterTwo"
                              aria-labelledby="title3ExpClusterTwo nutritionCounselingClusterTwo"
                              (change)="unsavedChanges = true;
                              updateNutritionCounselingExpendC();
                              _nutritionCounselingSectionModel.expendTotal.elementValue = updateTotal(nutritionCounsArr);
                              updatePerUnit(); _nutritionCounselingSectionModel.partB = validate(_nutritionCounselingSectionModel.partB); checkValidForm('cluster2')">
                          </div>
                        </div>                          
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <th id="title3AExpClusterTwo" class="normal">Title III A expenditure (NSIP grant)</th>
                      <td class="background-gray-lighter"></td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_congregateMealsSectionModel.expendA.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_congregateMealsSectionModel.expendA.elementValue"
                              [disabled]="!_congregateMealsSectionModel.expendA.isRequired" type="text"
                              class="form-control" id="title3AExpClusterTwoCongregateMealsClusterTwo"
                              aria-labelledby="title3AExpClusterTwo congregateMealsClusterTwo"
                              (change)="unsavedChanges = true; _congregateMealsSectionModel.expendTotal.elementValue = updateTotal(congMealsArr); updatePerUnit(); _congregateMealsSectionModel.expendA = validate(_congregateMealsSectionModel.expendA); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <th id="title3ExpClusterTwo" class="normal">Title III expenditure (Excluding Title III-A)</th>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_assistedTranspSectionModel.expendC.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_assistedTranspSectionModel.expendC.elementValue"
                              [disabled]="!_assistedTranspSectionModel.expendC.isRequired" type="text"
                              class="form-control" id="title3ExpClusterTwoAssistedTransClusterTwo"
                              aria-labelledby="title3ExpClusterTwo assistedTransClusterTwo"
                              (change)="unsavedChanges = true;
                              _assistedTranspSectionModel.expendTotal.elementValue = updateTotal(asssitedTranspArr);
                              updatePerUnit(); _assistedTranspSectionModel.expendC = validate(_assistedTranspSectionModel.expendC); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_congregateMealsSectionModel.expendC.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_congregateMealsSectionModel.expendC.elementValue"
                              [disabled]="!_congregateMealsSectionModel.expendC.isRequired" type="text"
                              class="form-control" id="title3ExpClusterTwoCongregateMealsClusterTwo"
                              aria-labelledby="title3ExpClusterTwo congregateMealsClusterTwo"
                              (change)="unsavedChanges = true;
                              _congregateMealsSectionModel.expendTotal.elementValue = updateTotal(congMealsArr);
                              updatePerUnit(); _congregateMealsSectionModel.expendC = validate(_congregateMealsSectionModel.expendC); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_nutritionCounselingSectionModel.expendC.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionCounselingSectionModel.expendC.elementValue"
                              [disabled]="!_nutritionCounselingSectionModel.expendC.isRequired" type="text"
                              class="form-control" id="title3ExpClusterTwoNutritionCounselingClusterTwo"
                              aria-labelledby="title3ExpClusterTwo nutritionCounselingClusterTwo"
                              (change)="unsavedChanges = true;
                              _nutritionCounselingSectionModel.expendTotal.elementValue = updateTotal(nutritionCounsArr);
                              updatePerUnit(); _nutritionCounselingSectionModel.expendC = validate(_nutritionCounselingSectionModel.expendC); checkValidForm('cluster2')"
                              readonly>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_legalSectionModel.expendC.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_legalSectionModel.expendC.elementValue"
                              [disabled]="!_legalSectionModel.expendC.isRequired" type="text" class="form-control"
                              id="title3ExpClusterTwoLegalClusterTwo"
                              aria-labelledby="title3ExpClusterTwo legalClusterTwo"
                              (change)="unsavedChanges = true;
                              _legalSectionModel.expendTotal.elementValue = updateTotal(legalArr);
                              updatePerUnit(); _legalSectionModel.expendC = validate(_legalSectionModel.expendC); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="otherStateExpClusterTwo" class="normal">Other: State expenditure</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_assistedTranspSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_assistedTranspSectionModel.expendOtherState.elementValue"
                              [disabled]="!_assistedTranspSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterTwoAssistedTransClusterTwo"
                              aria-labelledby="otherStateExpClusterTwo assistedTransClusterTwo"
                              (change)="unsavedChanges = true; _assistedTranspSectionModel.expendTotal.elementValue = updateTotal(asssitedTranspArr); updatePerUnit(); _assistedTranspSectionModel.expendOtherState = validate(_assistedTranspSectionModel.expendOtherState); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_congregateMealsSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_congregateMealsSectionModel.expendOtherState.elementValue"
                              [disabled]="!_congregateMealsSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterTwoCongregateMealsClusterTwo"
                              aria-labelledby="otherStateExpClusterTwo congregateMealsClusterTwo"
                              (change)="unsavedChanges = true; _congregateMealsSectionModel.expendTotal.elementValue = updateTotal(congMealsArr); updatePerUnit(); _congregateMealsSectionModel.expendOtherState = validate(_congregateMealsSectionModel.expendOtherState); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_nutritionCounselingSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionCounselingSectionModel.expendOtherState.elementValue"
                              [disabled]="!_nutritionCounselingSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterTwoNutritionCounselingClusterTwo"
                              aria-labelledby="otherStateExpClusterTwo nutritionCounselingClusterTwo"
                              (change)="unsavedChanges = true; _nutritionCounselingSectionModel.expendTotal.elementValue = updateTotal(nutritionCounsArr); updatePerUnit(); _nutritionCounselingSectionModel.expendOtherState = validate(_nutritionCounselingSectionModel.expendOtherState); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_legalSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_legalSectionModel.expendOtherState.elementValue"
                              [disabled]="!_legalSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterTwoLegalClusterTwo"
                              aria-labelledby="otherStateExpClusterTwo legalClusterTwo"
                              (change)="unsavedChanges = true; _legalSectionModel.expendTotal.elementValue = updateTotal(legalArr); updatePerUnit(); _legalSectionModel.expendOtherState = validate(_legalSectionModel.expendOtherState); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="otherNonStateExpClusterTwo" class="normal">Other: Non-state expenditure</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_assistedTranspSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_assistedTranspSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_assistedTranspSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterTwoAssistedTransClusterTwo"
                              aria-labelledby="otherNonStateExpClusterTwo assistedTransClusterTwo"
                              (change)="unsavedChanges = true; _assistedTranspSectionModel.expendTotal.elementValue = updateTotal(asssitedTranspArr); updatePerUnit(); _assistedTranspSectionModel.expendOtherNonState = validate(_assistedTranspSectionModel.expendOtherNonState); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_congregateMealsSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_congregateMealsSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_congregateMealsSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterTwoCongregateMealsClusterTwo"
                              aria-labelledby="otherNonStateExpClusterTwo congregateMealsClusterTwo"
                              (change)="unsavedChanges = true; _congregateMealsSectionModel.expendTotal.elementValue = updateTotal(congMealsArr); updatePerUnit(); _congregateMealsSectionModel.expendOtherNonState = validate(_congregateMealsSectionModel.expendOtherNonState); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_nutritionCounselingSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionCounselingSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_nutritionCounselingSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterTwoNutritionCounselingClusterTwo"
                              aria-labelledby="otherNonStateExpClusterTwo nutritionCounselingClusterTwo"
                              (change)="unsavedChanges = true; _nutritionCounselingSectionModel.expendTotal.elementValue = updateTotal(nutritionCounsArr); updatePerUnit(); _nutritionCounselingSectionModel.expendOtherNonState = validate(_nutritionCounselingSectionModel.expendOtherNonState); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_legalSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_legalSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_legalSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterTwoNegalClusterTwo"
                              aria-labelledby="otherNonStateExpClusterTwo legalClusterTwo"
                              (change)="unsavedChanges = true; _legalSectionModel.expendTotal.elementValue = updateTotal(legalArr); updatePerUnit(); _legalSectionModel.expendOtherNonState = validate(_legalSectionModel.expendOtherNonState); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="programIncomeClusterTwo" class="normal">Program income expended</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_assistedTranspSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_assistedTranspSectionModel.programIncome.elementValue"
                              [disabled]="!_assistedTranspSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterTwoAssistedTransClusterTwo"
                              aria-labelledby="programIncomeClusterTwo assistedTransClusterTwo"
                              (change)="unsavedChanges = true; _assistedTranspSectionModel.expendTotal.elementValue = updateTotal(asssitedTranspArr); updatePerUnit(); _assistedTranspSectionModel.programIncome = validate(_assistedTranspSectionModel.programIncome); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_congregateMealsSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_congregateMealsSectionModel.programIncome.elementValue"
                              [disabled]="!_congregateMealsSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterTwoCongregateMealsClusterTwo"
                              aria-labelledby="programIncomeClusterTwo congregateMealsClusterTwo"
                              (change)="unsavedChanges = true; _congregateMealsSectionModel.expendTotal.elementValue = updateTotal(congMealsArr); updatePerUnit(); _congregateMealsSectionModel.programIncome = validate(_congregateMealsSectionModel.programIncome); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_nutritionCounselingSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionCounselingSectionModel.programIncome.elementValue"
                              [disabled]="!_nutritionCounselingSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterTwoNutritionCounselingClusterTwo"
                              aria-labelledby="programIncomeClusterTwo nutritionCounselingClusterTwo"
                              (change)="unsavedChanges = true; _nutritionCounselingSectionModel.expendTotal.elementValue = updateTotal(nutritionCounsArr); updatePerUnit(); _nutritionCounselingSectionModel.programIncome = validate(_nutritionCounselingSectionModel.programIncome); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_legalSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_legalSectionModel.programIncome.elementValue"
                              [disabled]="!_legalSectionModel.programIncome.isRequired" type="text" class="form-control"
                              id="programIncomeClusterTwoLegalClusterTwo"
                              aria-labelledby="programIncomeClusterTwo legalClusterTwo"
                              (change)="unsavedChanges = true; _legalSectionModel.expendTotal.elementValue = updateTotal(legalArr); updatePerUnit(); _legalSectionModel.programIncome = validate(_legalSectionModel.programIncome); checkValidForm('cluster2')">
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="totalServExpClusterTwo" class="normal">Total service expenditure</th>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_assistedTranspSectionModel.expendTotal.elementValue"
                            [disabled]="!_assistedTranspSectionModel.expendTotal.isRequired" type="text"
                            class="form-control" id="totalServExpClusterTwoAssistedTransClusterTwo"
                            aria-labelledby="totalServExpClusterTwo assistedTransClusterTwo" readonly>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_congregateMealsSectionModel.expendTotal.elementValue"
                            [disabled]="!_congregateMealsSectionModel.expendTotal.isRequired" type="text"
                            class="form-control" id="totalServExpClusterTwoCongregateMealsClusterTwo"
                            aria-labelledby="totalServExpClusterTwo congregateMealsClusterTwo" readonly>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_nutritionCounselingSectionModel.expendTotal.elementValue"
                            [disabled]="!_nutritionCounselingSectionModel.expendTotal.isRequired" type="text"
                            class="form-control" id="totalServExpClusterTwoNutritionCounselingClusterTwo"
                            aria-labelledby="totalServExpClusterTwo nutritionCounselingClusterTwo" readonly>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_legalSectionModel.expendTotal.elementValue"
                            [disabled]="!_legalSectionModel.expendTotal.isRequired" type="text" class="form-control"
                            id="totalServExpClusterTwoLegalClusterTwo"
                            aria-labelledby="totalServExpClusterTwo legalClusterTwo" readonly>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="expPerUnitClusterTwo" class="normal">Expenditure per unit</th>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <input [(ngModel)]="_assistedTranspSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_assistedTranspSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterTwoAssistedTransClusterTwo"
                            aria-labelledby="expPerUnitClusterTwo assistedTransClusterTwo" readonly>
                          <div class="input-group-addon">/hour</div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <input [(ngModel)]="_congregateMealsSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_congregateMealsSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterTwoCongregateMealsClusterTwo"
                            aria-labelledby="expPerUnitClusterTwo congregateMealsClusterTwo" readonly>
                          <div class="input-group-addon">/meal</div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <input [(ngModel)]="_nutritionCounselingSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_nutritionCounselingSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterTwoNutritionCounselingClusterTwo"
                            aria-labelledby="expPerUnitClusterTwo nutritionCounselingClusterTwo" readonly>
                          <div class="input-group-addon">/hour</div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <input [(ngModel)]="_legalSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_legalSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterTwoLegalClusterTwo"
                            aria-labelledby="expPerUnitClusterTwo legalClusterTwo" readonly>
                          <div class="input-group-addon">/hour</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  </table>

                </div>
                <!-- ./end table-responsive -->
              </div>
              <div class="modal-footer">
                <button id="oaSaveAllBtn2" type="button" *ngIf="validForm" class="btn btn-primary" data-dismiss="modal"
                  (click)="saveAllSections()">Save changes</button>
                <button id="oaSaveAllBtnDisabled2" type="button" *ngIf="!validForm" class="btn btn-primary"
                  data-dismiss="modal" disabled>Save changes</button>
                <button id="oaCloseBtn2" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>

        <div class="modal fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="myModal3Label">
          <div class="modal-dialog modal-xlg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title" id="myModal3Label">Utilization and Expenditure for Older Adult Cluster 3
                  Services</h4>
              </div>
              <div class="modal-body">

                <table *ngIf="cluster3Errors().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-error">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Error(s):
                        </span>
                        <p *ngFor="let clusterThreeErrorItem of cluster3Errors()" class="help-block">
                          {{clusterThreeErrorItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table *ngIf="cluster3Warnings().length > 0" class="table table-no-border">
                  <tbody>
                    <tr>
                      <td class="has-warning">
                        <span class="help-block">
                          <i class="fa fa-warning fa-2x" aria-hidden="true"></i> Warning(s):
                        </span>
                        <p *ngFor="let clusterThreeWarnItem of cluster3Warnings()" class="help-block">
                          {{clusterThreeWarnItem}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="table-responsive">
                  <table class="table table-no-border">
                    <caption class="sr-only">Utilization and Expenditure for Older Adult Cluster 3 Services</caption>
                    <thead>
                      <tr class="background-gray-lighter">
                        <td></td>
                        <th id="transportation">
                          <a [routerLink]="['../titleIIITransportation']" data-dismiss="modal">Transportation</a>
                        </th>
                        <th id="nutritionEducation">
                          <a [routerLink]="['../titleIIINutritionEducation']" data-dismiss="modal">Nutrition Education</a>
                        </th>
                        <th id="infoAssist">
                          <a [routerLink]="['../titleIIIInfoAndAssistance']" data-dismiss="modal">Information and Assistance</a>
                        </th>
                        <th id="healthPromoEvidenced">
                          <a [routerLink]="['../healthPromoEvidence']" data-dismiss="modal">Health Promotion: Evidenced-Based</a>
                        </th>
                        <th id="healthPromoNonEvidenced">
                          <a [routerLink]="['../healthPromoNonEvidence']" data-dismiss="modal">Health Promotion: Non Evidenced-Based</a>
                        </th>
                        <th id="otherServices">
                          <a [routerLink]="['../titleIIIOtherExpend']" data-dismiss="modal">Other Services</a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th id="unduplicatedNoPerServedClusterThree" class="normal">Unduplicated number of persons served
                      </th>
                      <td class="background-gray-lighter">
                      </td>
                      <td>
                      </td>
                      <td class="background-gray-lighter">
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_healthPromoEvidenceSectionModel.peopleServed.questionStatus)">
                          <input [(ngModel)]="_healthPromoEvidenceSectionModel.peopleServed.elementValue"
                            [disabled]="!_healthPromoEvidenceSectionModel.peopleServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterThreeHealthPromoEvidenced"
                            aria-labelledby="unduplicatedNoPerServedClusterThree healthPromoEvidenced"
                            (change)="unsavedChanges = true; _healthPromoEvidenceSectionModel.peopleServed.elementValue = addComma(_healthPromoEvidenceSectionModel.peopleServed.elementValue); updatePerUnit(); _healthPromoEvidenceSectionModel.peopleServed = validate(_healthPromoEvidenceSectionModel.peopleServed); checkValidForm('cluster3')">
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_healthPromoNonEvidenceSectionModel.peopleServed.questionStatus)">
                          <input [(ngModel)]="_healthPromoNonEvidenceSectionModel.peopleServed.elementValue"
                            [disabled]="!_healthPromoNonEvidenceSectionModel.peopleServed.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterThreeHealthPromoNonEvidenced"
                            aria-labelledby="unduplicatedNoPerServedClusterThree healthPromoNonEvidenced"
                            (change)="unsavedChanges = true; _healthPromoNonEvidenceSectionModel.peopleServed.elementValue = addComma(_healthPromoNonEvidenceSectionModel.peopleServed.elementValue); updatePerUnit(); _healthPromoNonEvidenceSectionModel.peopleServed = validate(_healthPromoNonEvidenceSectionModel.peopleServed); checkValidForm('cluster3')">
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_otherServicesSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_otherServicesSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_otherServicesSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="unduplicatedNoPerServedClusterThreeOtherServices"
                            aria-labelledby="unduplicatedNoPerServedClusterThree otherServices"
                            (change)="unsavedChanges = true; _otherServicesSectionModel.totalServiceUnits.elementValue = addComma(_otherServicesSectionModel.totalServiceUnits.elementValue); updatePerUnit(); _otherServicesSectionModel.totalServiceUnits = validate(_otherServicesSectionModel.totalServiceUnits); checkValidForm('cluster3')">
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="serviceUnitsClusterThree" class="normal">Estimated Audience size</th>
                      <td class="background-gray-lighter">
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_nutritionEduSectionModel.estimatedAudienceSize.questionStatus)">
                          <input [(ngModel)]="_nutritionEduSectionModel.estimatedAudienceSize.elementValue"
                            [disabled]="!_nutritionEduSectionModel.estimatedAudienceSize.isRequired" type="text"
                            class="form-control" id="estimatedAudienceSizeClusterThreeNutritionEducation"
                            aria-labelledby="serviceUnitsClusterThree nutritionEducation"
                            (change)="unsavedChanges = true; _nutritionEduSectionModel.estimatedAudienceSize.elementValue = addComma(_nutritionEduSectionModel.estimatedAudienceSize.elementValue); updatePerUnit(); _nutritionEduSectionModel.estimatedAudienceSize = validate(_nutritionEduSectionModel.estimatedAudienceSize); checkValidForm('cluster3')">
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                      </td>
                      <td></td>
                      <td class="background-gray-lighter"></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th id="serviceUnitsClusterThree" class="normal">Service units</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_transportationSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_transportationSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_transportationSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterThreeTransportation"
                            aria-labelledby="serviceUnitsClusterThree transportation"
                            (change)="unsavedChanges = true;
                          _transportationSectionModel.totalServiceUnits.elementValue = addComma(_transportationSectionModel.totalServiceUnits.elementValue);
                          updatePerUnit(); _transportationSectionModel.totalServiceUnits = validate(_transportationSectionModel.totalServiceUnits); checkValidForm('cluster3')">
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_nutritionEduSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_nutritionEduSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_nutritionEduSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterThreeNutritionEducation"
                            aria-labelledby="serviceUnitsClusterThree nutritionEducation"
                            (change)="unsavedChanges = true;
                          _nutritionEduSectionModel.totalServiceUnits.elementValue = addComma(_nutritionEduSectionModel.totalServiceUnits.elementValue);
                          updatePerUnit(); _nutritionEduSectionModel.totalServiceUnits = validate(_nutritionEduSectionModel.totalServiceUnits); checkValidForm('cluster3')">
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_infoAndAssistanceSectionModel.totalServiceUnits.questionStatus)">
                          <input [(ngModel)]="_infoAndAssistanceSectionModel.totalServiceUnits.elementValue"
                            [disabled]="!_infoAndAssistanceSectionModel.totalServiceUnits.isRequired" type="text"
                            class="form-control" id="serviceUnitsClusterThreeInfoAssist"
                            aria-labelledby="serviceUnitsClusterThree infoAssist"
                            (change)="unsavedChanges = true;
                          _infoAndAssistanceSectionModel.totalServiceUnits.elementValue = addComma(_infoAndAssistanceSectionModel.totalServiceUnits.elementValue);
                          updatePerUnit(); _infoAndAssistanceSectionModel.totalServiceUnits = validate(_infoAndAssistanceSectionModel.totalServiceUnits); checkValidForm('cluster3')">
                        </div>
                      </td>
                      <td></td>
                      <td class="background-gray-lighter"></td>
                      <td></td>
                    </tr>

                    <tr>
                      <th id="serviceUnitsClusterThree" class="normal">Title III C1 expenditure</th>
                      <td class="background-gray-lighter">
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_nutritionEduSectionModel.expendC1.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionEduSectionModel.expendC1.elementValue"
                              [disabled]="!_nutritionEduSectionModel.expendC1.isRequired" type="text" class="form-control"
                              id="title3ExpClusterThreeNutritionEducation"
                              aria-labelledby="title3ExpClusterThree nutritionEducation"
                              (change)="unsavedChanges = true;
                              updateNutritionEducationExpend();
                                _nutritionEduSectionModel.expendC1.elementValue = addDecimalAndComma(_nutritionEduSectionModel.expendC1.elementValue);
                                _nutritionEduSectionModel.expendTotal.elementValue = updateTotal(nutritionEduArr);
                                updatePerUnit(); _nutritionEduSectionModel.expendC1 = validate(_nutritionEduSectionModel.expendC1); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                      </td>
                      <td></td>
                      <td class="background-gray-lighter"></td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_otherServicesSectionModel.expendC1.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_otherServicesSectionModel.expendC1.elementValue"
                              [disabled]="true" type="text" class="form-control"
                              id="title3ExpClusterThreeOtherServicesC1"
                              aria-labelledby="title3ExpClusterThree OtherServices C1">
                          </div>
                        </div>                        
                      </td>
                    </tr>

                    <tr>
                      <th id="serviceUnitsClusterThree" class="normal">Title III C2 expenditure</th>
                      <td class="background-gray-lighter">
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_nutritionEduSectionModel.expendC2.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionEduSectionModel.expendC2.elementValue"
                              [disabled]="!_nutritionEduSectionModel.expendC2.isRequired" type="text" class="form-control"
                              id="title3ExpClusterThreeNutritionEducation"
                              aria-labelledby="title3ExpClusterThree nutritionEducation"
                              (change)="unsavedChanges = true;
                              updateNutritionEducationExpend();
                                _nutritionEduSectionModel.expendC2.elementValue = addDecimalAndComma(_nutritionEduSectionModel.expendC2.elementValue);
                                _nutritionEduSectionModel.expendTotal.elementValue = updateTotal(nutritionEduArr);
                                updatePerUnit(); _nutritionEduSectionModel.expendC2 = validate(_nutritionEduSectionModel.expendC2); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                      </td>
                      <td></td>
                      <td class="background-gray-lighter"></td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_otherServicesSectionModel.expendC2.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_otherServicesSectionModel.expendC2.elementValue"
                              [disabled]="true" type="text" class="form-control"
                              id="title3ExpClusterThreeOtherServicesC2"
                              aria-labelledby="title3ExpClusterThree OtherServices C2">
                          </div>
                        </div>                        
                      </td>
                    </tr>

                    <tr>
                      <th id="title3BExpClusterThree" class="normal">Title III-B expenditure</th>
                      <td class="background-gray-lighter"></td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_nutritionEduSectionModel.partB.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionEduSectionModel.partB.elementValue"
                              [disabled]="!_nutritionEduSectionModel.partB.isRequired" type="text" class="form-control"
                              id="title3ExpClusterThreeNutritionEducation"
                              aria-labelledby="title3ExpClusterThree nutritionEducation"
                              (change)="unsavedChanges = true;
                              updateNutritionEducationExpend();
                                _nutritionEduSectionModel.partB.elementValue = addDecimalAndComma(_nutritionEduSectionModel.partB.elementValue);
                                _nutritionEduSectionModel.expendTotal.elementValue = updateTotal(nutritionEduArr);
                                updatePerUnit(); _nutritionEduSectionModel.partB = validate(_nutritionEduSectionModel.partB); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_healthPromoEvidenceSectionModel.partB.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_healthPromoEvidenceSectionModel.partB.elementValue"
                              [disabled]="!_healthPromoEvidenceSectionModel.partB.isRequired" type="text"
                              class="form-control" id="title3BExpClusterThreeHealthPromoEvidenced"
                              aria-labelledby="title3BExpClusterThree healthPromoEvidenced"
                              (change)="unsavedChanges = true;
                            updateHealthPromoEvidenceBothParts();
                            _healthPromoEvidenceSectionModel.partB.elementValue = addDecimalAndComma(_healthPromoEvidenceSectionModel.partB.elementValue);
                            _healthPromoEvidenceSectionModel.totalExpend.elementValue = updateTotal(healthPromoArr);
                            updatePerUnit(); _healthPromoEvidenceSectionModel.partB = validate(_healthPromoEvidenceSectionModel.partB); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter"></td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_otherServicesSectionModel.partB.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_otherServicesSectionModel.partB.elementValue"
                              [disabled]="true" type="text" class="form-control"
                              id="title3ExpClusterThreeOtherServicesPartB"
                              aria-labelledby="title3ExpClusterThree OtherServices partB">
                          </div>
                        </div>                        
                      </td>
                    </tr>
                    <tr>
                      <th id="title3DExpClusterThree" class="normal">Title III-D expenditure</th>
                      <td class="background-gray-lighter"></td>
                      <td></td>
                      <td class="background-gray-lighter"></td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_healthPromoEvidenceSectionModel.partD.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_healthPromoEvidenceSectionModel.partD.elementValue"
                              [disabled]="!_healthPromoEvidenceSectionModel.partD.isRequired" type="text"
                              class="form-control" id="title3DExpClusterThreeHealthPromoEvidenced"
                              aria-labelledby="title3DExpClusterThree healthPromoEvidenced"
                              (change)="unsavedChanges = true;
                                updateHealthPromoEvidenceBothParts();
                                _healthPromoEvidenceSectionModel.partD.elementValue = addDecimalAndComma(_healthPromoEvidenceSectionModel.partD.elementValue);
                                _healthPromoEvidenceSectionModel.totalExpend.elementValue = updateTotal(healthPromoArr);
                                updatePerUnit(); _healthPromoEvidenceSectionModel.partD = validate(_healthPromoEvidenceSectionModel.partD); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter"></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th id="title3ExpClusterThree" class="normal">Title III expenditure (Excluding Title III-A)</th>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_transportationSectionModel.expend.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_transportationSectionModel.expend.elementValue"
                              [disabled]="!_transportationSectionModel.expend.isRequired" type="text"
                              class="form-control" id="title3ExpClusterThreeTransportation"
                              aria-labelledby="title3ExpClusterThree transportation"
                              (change)="unsavedChanges = true;
                              _transportationSectionModel.expend.elementValue = addDecimalAndComma(_transportationSectionModel.expend.elementValue);
                                _transportationSectionModel.expendTotal.elementValue = updateTotal(transpArr);
                                updatePerUnit(); _transportationSectionModel.expend = validate(_transportationSectionModel.expend); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_nutritionEduSectionModel.expend.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionEduSectionModel.expend.elementValue"
                              [disabled]="!_nutritionEduSectionModel.expend.isRequired" type="text" class="form-control"
                              id="title3ExpClusterThreeNutritionEducation"
                              aria-labelledby="title3ExpClusterThree nutritionEducation"
                              readonly>
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div [ngClass]="calculateOutlineColor(_infoAndAssistanceSectionModel.expend.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_infoAndAssistanceSectionModel.expend.elementValue"
                              [disabled]="!_infoAndAssistanceSectionModel.expend.isRequired" type="text"
                              class="form-control" id="title3ExpClusterThreeInfoAssist"
                              aria-labelledby="title3ExpClusterThree infoAssist"
                              (change)="unsavedChanges = true;
                              _infoAndAssistanceSectionModel.expend.elementValue = addDecimalAndComma(_infoAndAssistanceSectionModel.expend.elementValue);
                              _infoAndAssistanceSectionModel.expendTotal.elementValue = updateTotal(infoArr);
                              updatePerUnit(); _infoAndAssistanceSectionModel.expend = validate(_infoAndAssistanceSectionModel.expend); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_healthPromoEvidenceSectionModel.bothParts.elementValue" type="text"
                              class="form-control" id="title3ExpClusterThreeHealthPromoEvidenced"
                              aria-labelledby="title3ExpClusterThree healthPromoEvidenced" readonly>
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_healthPromoNonEvidenceSectionModel.title3Expend.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_healthPromoNonEvidenceSectionModel.title3Expend.elementValue"
                              [disabled]="!_healthPromoNonEvidenceSectionModel.title3Expend.isRequired" type="text"
                              class="form-control" id="title3ExpClusterThreeHealthPromoNonEvidenced"
                              aria-labelledby="title3ExpClusterThree healthPromoNonEvidenced"
                              (change)="unsavedChanges = true; _healthPromoNonEvidenceSectionModel.title3Expend.elementValue = addDecimalAndComma(_healthPromoNonEvidenceSectionModel.title3Expend.elementValue); _healthPromoNonEvidenceSectionModel.totalExpend.elementValue = updateTotal(healthPromoNonArr); updatePerUnit(); _healthPromoNonEvidenceSectionModel.title3Expend = validate(_healthPromoNonEvidenceSectionModel.title3Expend); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_otherServicesSectionModel.expend.elementValue"
                            [disabled]="!_otherServicesSectionModel.expend.isRequired" type="text" class="form-control"
                            id="title3ExpClusterThreeOtherServices"
                            aria-labelledby="title3ExpClusterThree otherServices" readonly>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="otherStateExpClusterThree" class="normal">Other: State expenditure</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_transportationSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_transportationSectionModel.expendOtherState.elementValue"
                              [disabled]="!_transportationSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterThreeTransportation"
                              aria-labelledby="otherStateExpClusterThree transportation"
                              (change)="unsavedChanges = true;
                                _transportationSectionModel.expendOtherState.elementValue = addDecimalAndComma(_transportationSectionModel.expendOtherState.elementValue);
                                _transportationSectionModel.expendTotal.elementValue = updateTotal(transpArr);
                                updatePerUnit(); _transportationSectionModel.expendOtherState = validate(_transportationSectionModel.expendOtherState); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_nutritionEduSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionEduSectionModel.expendOtherState.elementValue"
                              [disabled]="!_nutritionEduSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterThreeNutritionEducation"
                              aria-labelledby="otherStateExpClusterThree nutritionEducation"
                              (change)="unsavedChanges = true;
                                _nutritionEduSectionModel.expendOtherState.elementValue = addDecimalAndComma(_nutritionEduSectionModel.expendOtherState.elementValue);
                                _nutritionEduSectionModel.expendTotal.elementValue = updateTotal(nutritionEduArr);
                                updatePerUnit(); _nutritionEduSectionModel.expendOtherState = validate(_nutritionEduSectionModel.expendOtherState); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_infoAndAssistanceSectionModel.expendOtherState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_infoAndAssistanceSectionModel.expendOtherState.elementValue"
                              [disabled]="!_infoAndAssistanceSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterThreeInfoAssist"
                              aria-labelledby="otherStateExpClusterThree infoAssist"
                              (change)="unsavedChanges = true;
                                   _infoAndAssistanceSectionModel.expendOtherState.elementValue = addDecimalAndComma(_infoAndAssistanceSectionModel.expendOtherState.elementValue);
                                   _infoAndAssistanceSectionModel.expendTotal.elementValue = updateTotal(infoArr);
                                   updatePerUnit(); _infoAndAssistanceSectionModel.expendOtherState = validate(_infoAndAssistanceSectionModel.expendOtherState); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_healthPromoEvidenceSectionModel.otherStateExpend.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_healthPromoEvidenceSectionModel.otherStateExpend.elementValue"
                              [disabled]="!_healthPromoEvidenceSectionModel.otherStateExpend.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterThreeHealthPromoEvidenced"
                              aria-labelledby="otherStateExpClusterThree healthPromoEvidenced"
                              (change)="unsavedChanges = true;
                                   _healthPromoEvidenceSectionModel.otherStateExpend.elementValue = addDecimalAndComma(_healthPromoEvidenceSectionModel.otherStateExpend.elementValue);
                                   _healthPromoEvidenceSectionModel.totalExpend.elementValue = updateTotal(healthPromoArr);
                                   updatePerUnit(); _healthPromoEvidenceSectionModel.otherStateExpend = validate(_healthPromoEvidenceSectionModel.otherStateExpend); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_healthPromoNonEvidenceSectionModel.otherStateExpend.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_healthPromoNonEvidenceSectionModel.otherStateExpend.elementValue"
                              [disabled]="!_healthPromoNonEvidenceSectionModel.otherStateExpend.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterThreeHealthPromoNonEvidenced"
                              aria-labelledby="otherStateExpClusterThree healthPromoNonEvidenced"
                              (change)="unsavedChanges = true;
                              _healthPromoNonEvidenceSectionModel.otherStateExpend.elementValue = addDecimalAndComma(_healthPromoNonEvidenceSectionModel.otherStateExpend.elementValue);
                              _healthPromoNonEvidenceSectionModel.totalExpend.elementValue = updateTotal(healthPromoNonArr);
                              updatePerUnit(); _healthPromoNonEvidenceSectionModel.otherStateExpend = validate(_healthPromoNonEvidenceSectionModel.otherStateExpend); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_otherServicesSectionModel.expendOtherState.elementValue"
                              [disabled]="!_otherServicesSectionModel.expendOtherState.isRequired" type="text"
                              class="form-control" id="otherStateExpClusterThreeOtherServices"
                              aria-labelledby="otherStateExpClusterThree otherServices" readonly>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="otherNonStateExpClusterThree" class="normal">Other: Non-state expenditure</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_transportationSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_transportationSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_transportationSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterThreeTransportation"
                              aria-labelledby="otherNonStateExpClusterThree transportation"
                              (change)="unsavedChanges = true;
                              _transportationSectionModel.expendOtherNonState.elementValue = addDecimalAndComma(_transportationSectionModel.expendOtherNonState.elementValue);
                              _transportationSectionModel.expendTotal.elementValue = updateTotal(transpArr);
                              updatePerUnit(); _transportationSectionModel.expendOtherNonState = validate(_transportationSectionModel.expendOtherNonState); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_nutritionEduSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionEduSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_nutritionEduSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterThreeNutritionEducation"
                              aria-labelledby="otherNonStateExpClusterThree nutritionEducation"
                              (change)="unsavedChanges = true;
                              _nutritionEduSectionModel.expendOtherNonState.elementValue = addDecimalAndComma(_nutritionEduSectionModel.expendOtherNonState.elementValue);
                              _nutritionEduSectionModel.expendTotal.elementValue = updateTotal(nutritionEduArr);
                              updatePerUnit(); _nutritionEduSectionModel.expendOtherNonState = validate(_nutritionEduSectionModel.expendOtherNonState); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_infoAndAssistanceSectionModel.expendOtherNonState.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_infoAndAssistanceSectionModel.expendOtherNonState.elementValue"
                              [disabled]="!_infoAndAssistanceSectionModel.expendOtherNonState.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterThreeInfoAssist"
                              aria-labelledby="otherNonStateExpClusterThree infoAssist"
                              (change)="unsavedChanges = true;
                              _infoAndAssistanceSectionModel.expendOtherNonState.elementValue = addDecimalAndComma(_infoAndAssistanceSectionModel.expendOtherNonState.elementValue);
                              _infoAndAssistanceSectionModel.expendTotal.elementValue = updateTotal(infoArr);
                              updatePerUnit(); _infoAndAssistanceSectionModel.expendOtherNonState = validate(_infoAndAssistanceSectionModel.expendOtherNonState); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_healthPromoEvidenceSectionModel.nonStateExpend.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_healthPromoEvidenceSectionModel.nonStateExpend.elementValue"
                              [disabled]="!_healthPromoEvidenceSectionModel.nonStateExpend.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterThreeHealthPromoEvidenced"
                              aria-labelledby="otherNonStateExpClusterThree healthPromoEvidenced"
                              (change)="unsavedChanges = true; _healthPromoEvidenceSectionModel.nonStateExpend.elementValue = addDecimalAndComma(_healthPromoEvidenceSectionModel.nonStateExpend.elementValue); _healthPromoEvidenceSectionModel.totalExpend.elementValue = updateTotal(healthPromoArr); updatePerUnit(); _healthPromoEvidenceSectionModel.nonStateExpend = validate(_healthPromoEvidenceSectionModel.nonStateExpend); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_healthPromoNonEvidenceSectionModel.nonStateExpend.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_healthPromoNonEvidenceSectionModel.nonStateExpend.elementValue"
                              [disabled]="!_healthPromoNonEvidenceSectionModel.nonStateExpend.isRequired" type="text"
                              class="form-control" id="otherNonStateExpClusterThreeHealthPromoNonEvidenced"
                              aria-labelledby="otherNonStateExpClusterThree healthPromoNonEvidenced"
                              (change)="unsavedChanges = true; _healthPromoNonEvidenceSectionModel.nonStateExpend.elementValue = addDecimalAndComma(_healthPromoNonEvidenceSectionModel.nonStateExpend.elementValue); _healthPromoNonEvidenceSectionModel.totalExpend.elementValue = updateTotal(healthPromoNonArr); updatePerUnit(); _healthPromoNonEvidenceSectionModel.nonStateExpend = validate(_healthPromoNonEvidenceSectionModel.nonStateExpend); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_otherServicesSectionModel.expendOtherNonState.elementValue"
                            [disabled]="!_otherServicesSectionModel.expendOtherNonState.isRequired" type="text"
                            class="form-control" id="otherNonStateExpClusterThreeOtherServices"
                            aria-labelledby="otherNonStateExpClusterThree otherServices" readonly>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="programIncomeClusterThree" class="normal">Program income expended</th>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_transportationSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_transportationSectionModel.programIncome.elementValue"
                              [disabled]="!_transportationSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterThreeTransportation"
                              aria-labelledby="programIncomeClusterThree transportation"
                              (change)="unsavedChanges = true;
                              _transportationSectionModel.programIncome.elementValue = addDecimalAndComma(_transportationSectionModel.programIncome.elementValue);
                              _transportationSectionModel.expendTotal.elementValue = updateTotal(transpArr);
                              updatePerUnit(); _transportationSectionModel.programIncome = validate(_transportationSectionModel.programIncome); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="calculateOutlineColor(_nutritionEduSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_nutritionEduSectionModel.programIncome.elementValue"
                              [disabled]="!_nutritionEduSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterThreeNutritionEducation"
                              aria-labelledby="programIncomeClusterThree nutritionEducation"
                              (change)="unsavedChanges = true;
                              _nutritionEduSectionModel.programIncome.elementValue = addDecimalAndComma(_nutritionEduSectionModel.programIncome.elementValue);
                              _nutritionEduSectionModel.expendTotal.elementValue = updateTotal(nutritionEduArr);
                              updatePerUnit(); _nutritionEduSectionModel.programIncome = validate(_nutritionEduSectionModel.programIncome); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_infoAndAssistanceSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_infoAndAssistanceSectionModel.programIncome.elementValue"
                              [disabled]="!_infoAndAssistanceSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterThreeInfoAssist"
                              aria-labelledby="programIncomeClusterThree infoAssist"
                              (change)="unsavedChanges = true;
                              _infoAndAssistanceSectionModel.programIncome.elementValue = addDecimalAndComma(_infoAndAssistanceSectionModel.programIncome.elementValue);
                              _infoAndAssistanceSectionModel.expendTotal.elementValue = updateTotal(infoArr);
                              updatePerUnit(); _infoAndAssistanceSectionModel.programIncome = validate(_infoAndAssistanceSectionModel.programIncome); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          [ngClass]="calculateOutlineColor(_healthPromoEvidenceSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_healthPromoEvidenceSectionModel.programIncome.elementValue"
                              [disabled]="!_healthPromoEvidenceSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterThreeHealthPromoEvidenced"
                              aria-labelledby="programIncomeClusterThree healthPromoEvidenced"
                              (change)="unsavedChanges = true; _healthPromoEvidenceSectionModel.programIncome.elementValue = addDecimalAndComma(_healthPromoEvidenceSectionModel.programIncome.elementValue); _healthPromoEvidenceSectionModel.totalExpend.elementValue = updateTotal(healthPromoArr); updatePerUnit(); _healthPromoEvidenceSectionModel.programIncome = validate(_healthPromoEvidenceSectionModel.programIncome); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div
                          [ngClass]="calculateOutlineColor(_healthPromoNonEvidenceSectionModel.programIncome.questionStatus)">
                          <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input [(ngModel)]="_healthPromoNonEvidenceSectionModel.programIncome.elementValue"
                              [disabled]="!_healthPromoNonEvidenceSectionModel.programIncome.isRequired" type="text"
                              class="form-control" id="programIncomeClusterThreeHealthPromoNonEvidenced"
                              aria-labelledby="programIncomeClusterThree healthPromoNonEvidenced"
                              (change)="unsavedChanges = true; _healthPromoNonEvidenceSectionModel.programIncome.elementValue = addDecimalAndComma(_healthPromoNonEvidenceSectionModel.programIncome.elementValue); _healthPromoNonEvidenceSectionModel.totalExpend.elementValue = updateTotal(healthPromoNonArr); updatePerUnit(); _healthPromoNonEvidenceSectionModel.programIncome = validate(_healthPromoNonEvidenceSectionModel.programIncome); checkValidForm('cluster3')">
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [(ngModel)]="_otherServicesSectionModel.programIncome.elementValue"
                            [disabled]="!_otherServicesSectionModel.programIncome.isRequired" type="text"
                            class="form-control" id="programIncomeClusterThreeOtherServices"
                            aria-labelledby="programIncomeClusterThree otherServices" readonly>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th id="totalServExpClusterThree" class="normal">Total service expenditure</th>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [ngModel]="_transportationSectionModel.expendTotal.elementValue"
                            [disabled]="!_transportationSectionModel.expendTotal.isRequired" type="text"
                            class="form-control" id="totalServExpClusterThreeTransportation"
                            aria-labelledby="totalServExpClusterThree transportation" readonly>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [ngModel]="_nutritionEduSectionModel.expendTotal.elementValue"
                            [disabled]="!_nutritionEduSectionModel.expendTotal.isRequired" type="text"
                            class="form-control" id="totalServExpClusterThreeNutritionEducation"
                            aria-labelledby="totalServExpClusterThree nutritionEducation" readonly>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [ngModel]="_infoAndAssistanceSectionModel.expendTotal.elementValue"
                            [disabled]="!_infoAndAssistanceSectionModel.expendTotal.isRequired" type="text"
                            class="form-control" id="totalServExpClusterThreeInfoAssist"
                            aria-labelledby="totalServExpClusterThree infoAssist" readonly>
                        </div>

                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [ngModel]="_healthPromoEvidenceSectionModel.totalExpend.elementValue"
                            [disabled]="!_healthPromoEvidenceSectionModel.totalExpend.isRequired" type="text"
                            class="form-control" id="totalServExpClusterThreeHealthPromoEvidenced"
                            aria-labelledby="totalServExpClusterThree healthPromoEvidenced" readonly>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [ngModel]="_healthPromoNonEvidenceSectionModel.totalExpend.elementValue"
                            [disabled]="!_healthPromoNonEvidenceSectionModel.totalExpend.isRequired" type="text"
                            class="form-control" id="totalServExpClusterThreeHealthPromoNonEvidenced"
                            aria-labelledby="totalServExpClusterThree healthPromoNonEvidenced" readonly>
                        </div>

                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-addon">$</div>
                          <input [ngModel]="_otherServicesSectionModel.expendTotal.elementValue"
                            [disabled]="!_otherServicesSectionModel.expendTotal.isRequired" type="text"
                            class="form-control" id="totalServExpClusterThreeOtherServices"
                            aria-labelledby="totalServExpClusterThree otherServices" readonly>
                        </div>

                      </td>
                    </tr>
                    <tr>
                      <th id="expPerUnitClusterThree" class="normal">Expenditure per unit/person</th>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <input [ngModel]="_transportationSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_transportationSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterThreeTransportation"
                            aria-labelledby="expPerUnitClusterThree transportation" readonly>
                          <div class="input-group-addon">/one-way trip</div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <input [ngModel]="_nutritionEduSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_nutritionEduSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterThreeNutritionEducation"
                            aria-labelledby="expPerUnitClusterThree nutritionEducation" readonly>
                          <div class="input-group-addon">/session</div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <input [ngModel]="_infoAndAssistanceSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_infoAndAssistanceSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterThreeInfoAssist"
                            aria-labelledby="expPerUnitClusterThree infoAssist" readonly>
                          <div class="input-group-addon">/contact</div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <input type="text" [ngModel]="_healthPromoEvidenceSectionModel.expendPerUnit.elementValue"
                            [disabled]="!_healthPromoEvidenceSectionModel.expendPerUnit.isRequired" class="form-control"
                            id="expPerUnitClusterThreeHealthPromoEvidenced"
                            aria-labelledby="expPerUnitClusterThree healthPromoEvidenced" readonly>
                          <div class="input-group-addon">/person</div>
                        </div>
                      </td>
                      <td class="background-gray-lighter">
                        <div class="input-group">
                          <input [ngModel]="_healthPromoNonEvidenceSectionModel.expendPerUnit.elementValue"
                            [disabled]="!_healthPromoNonEvidenceSectionModel.expendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterThreeHealthPromoNonEvidenced"
                            aria-labelledby="expPerUnitClusterThree healthPromoNonEvidenced" readonly>
                          <div class="input-group-addon">/person</div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <input [ngModel]="_otherServicesSectionModel.totalExpendPerUnit.elementValue"
                            [disabled]="!_otherServicesSectionModel.totalExpendPerUnit.isRequired" type="text"
                            class="form-control" id="expPerUnitClusterThreeOtherServices"
                            aria-labelledby="expPerUnitClusterThree otherServices" readonly>
                          <div class="input-group-addon">/person</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                    </tr>
                  </tbody>
                  </table>

                </div>
                <!-- ./end table-responsive -->
              </div>
              <div class="modal-footer">
                <button id="oaSaveAllBtn3" type="button" *ngIf="validForm" class="btn btn-primary" data-dismiss="modal"
                  (click)="saveAllSections()">Save changes</button>
                <button id="oaSaveAllBtnDisabled3" type="button" *ngIf="!validForm" class="btn btn-primary"
                  data-dismiss="modal" disabled>Save changes</button>
                <button id="oaCloseBtn3" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 row">
          <div *ngIf="!validForm" class="has-error">
            <p class="help-block">There are errors on the page. You must fix the errors or clear the data in those
              fields in order
              to Save this form.</p>
          </div>
          <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal"
            class="btn btn-primary mr-1">Next</button>
          <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>
          <button class="btn btn-default mr-1" (click)="saveAllSections()" [disabled]="!validForm">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to
            Overview</a>
        </div>
        <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1"
          style="display: none;">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
                <h3 class="modal-title" id="nextModalLabel">Recent changes on this page will not be saved</h3>
              </div>
              <div class="modal-body">
                <p>
                  There are errors on the page. You must fix the errors or clear the data in those fields in order to
                  save this form.
                  <br />
                  <br /> You may select "Go back to form" to remove the errors or you may select "Proceed to
                  next section" and all of new the data entered in the current section will be lost.
                </p>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go back to
                  form</button>
                <button class="btn btn-primary pull-right" (click)="unsavedChanges = false;" data-dismiss="modal"
                  type="button" [routerLink]="['/data-submissions/titleIIICOAExpenditures']">Proceed to next
                  section</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
