import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { from, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { ClaimService } from '../claim.service';
import { UploadService } from './upload.service';

import { UploadFlowEnum } from './uploadDetails';
import { UploadTypeEnum, AclTitleEnum } from '../../app.constants';
import { SectionDetail } from '../batchSection';
import { DestructibleComponent } from '../../destructible.component';
import { LoadingService } from '../../shared/loading.service';
import { StatusLoaderService } from '../status-loader.service';
import { QuestionStatusEnum } from '../dataElement';
declare var jQuery: any;

@Component({
    templateUrl: 'uploadFile.component.html',
})

export class UploadFileComponent extends DestructibleComponent implements OnInit {
    uploadSectionRadio: string;
    isReadyForUpload: boolean = false;
    _isUploadDisabled: boolean = true;
    _statusEnum = QuestionStatusEnum;
    fileToUpload: File = null;
    fileName: string = '';
    comment: string = '';
    batchId: string = null;
    currentSubscription: Subscription;
    _titleEnum = AclTitleEnum;
    subOrg: string = null;
    rollup: boolean = null;

    @ViewChild('inputUpload') inputUpload: ElementRef;

    constructor(public zone: NgZone,
        public route: ActivatedRoute,
        public router: Router,
        public _titleService: Title,
        public _claimService: ClaimService,
        public _uploadService: UploadService,
        private _loadingService: LoadingService,
        private _statusLoaderService: StatusLoaderService
    ) {
        super();
        route.queryParams.subscribe(p => {
            this.subOrg = p['psa'];
            this.rollup = p['rollup'];
        });
    }

    ngOnInit(): void {
        this._titleService.setTitle('Upload File - State Submissions - ACL OAAPS');
        if (this._claimService.currentTitle === 'VII') {
            this.uploadSectionRadio = '';       // was "casecomplaints", was requested that default be no radio button selected
        } else {
            this.uploadSectionRadio = 'allt3sections';
        }
        this._statusLoaderService.statusInitialized$().subscribe(() => {
            this.canUpload();
        }, err => { console.log(err); });
    }

    canUpload() {
        const _tviiCannotUpload = this._claimService.tviiStatuses.tVIIOverallStatus >= this._statusEnum.SubmittedNoExplanation;
        const _tiiiCannotUpload = this._claimService.tiiiStatuses.sprStatus >= this._statusEnum.SubmittedNoExplanation &&
            this._claimService.tiiiStatuses.nsipStatus >= this._statusEnum.SubmittedNoExplanation;

        this._isUploadDisabled = _tviiCannotUpload || _tiiiCannotUpload;
    }

    fileChangeEvent(fileInput: any): void {
        this.fileToUpload = <File>fileInput.target.files[0];
        this.fileName = this.fileToUpload.name;
        if (this.fileToUpload != null) {
            const index = this.fileToUpload.name.lastIndexOf(".");
            const fileExt: string = this.fileToUpload.name.substring(index + 1, this.fileToUpload.name.length);
            this.isReadyForUpload = (fileExt === 'csv' || fileExt === 'xls' || fileExt === 'xlsx' || fileExt === 'xml');
        }
    }

    uploadFileClicked() {
        let e = null;
        if (e = document.getElementById('uploadFileLabel')) {
            e.click();
        }
    }

    upload(): void {
        this.doUpload();
    }

    routeToSuccessPage(batchId: string, isCaseComplaintUpload?: boolean, uploadType?: UploadTypeEnum,
        sectionsIncluded?: SectionDetail[]): void {
        // Cache upload details in UploadService
        this._uploadService.uploadDetails = {
            fileName: this.fileToUpload.name,
            fileSize: Math.floor((this.fileToUpload.size / 1024) * 100) / 100 + 'KB',
            uploadBy: this._claimService.userID,
            batchId: batchId,
            uploadedTime: new Date(),
            uploadFlow: isCaseComplaintUpload ? UploadFlowEnum.CasesAndComplaints : UploadFlowEnum.Other,
            uploadType: uploadType,
            sectionsIncluded: sectionsIncluded
        };

        this.router.navigate(['/state-submissions/titleVIIUploadConfirmation'], { queryParamsHandling: "preserve" });
    }

    doUpload(): void {
        // this.loadingComponent.setLoadingInProgress(600);
        this._loadingService.setLoading(true, 'FileUpload');
        this.makeFileRequest('/api/upload/doupload', [], this.fileToUpload).then(
            (result) => {
                this._loadingService.setLoading(false, 'FileUpload');
                if (result.isValid) {
                    if (this.uploadSectionRadio === 'casecomplaints'
                        && result.batchId) {
                        this._uploadService.initBatchSectionPoller(result.batchId);
                        this.routeToSuccessPage(result.batchId, true, result.uploadType);
                    } else {
                        this.routeToSuccessPage(result.batchId, false, result.uploadType, result.sectionsIncluded);
                    }
                } else {
                    this.handleError(result);
                }
            }, (error) => {
                this._loadingService.setLoading(false, 'FileUpload');
                this.handleError(error);
            }).catch(error => {
                this._loadingService.setLoading(false, 'FileUpload');
                console.log(error)
            });
    }

    merge(): void {
        jQuery('#mergeConfirmationModal').modal('hide');
        if (this.batchId != null) {
            // this.loadingComponent.setLoadingInProgress(600);
            this.currentSubscription = this._uploadService.mergeBatch(this.batchId, this._claimService.currentTitle,
                this._claimService.ffyStateDto)
                .subscribe(data => {

                    this.routeToSuccessPage(data.batchId, false, data.uploadType, data.sectionsIncluded);
                }, error => { this.handleError(error);  });
        }
    }

    overwrite(): void {
        jQuery('#mergeConfirmationModal').modal('hide');
        if (this.batchId != null) {
            // this.loadingComponent.setLoadingInProgress(600);
            this.currentSubscription = this._uploadService.mergeBatch(this.batchId, this._claimService.currentTitle,
                this._claimService.ffyStateDto, true)
                .subscribe(data => {

                    this.routeToSuccessPage(data.batchId, false, data.uploadType, data.sectionsIncluded);
                }, error => {  this.handleError(error); });
        }
    }

    overwriteCaseAndComplaints(): void {
        if (this.batchId != null) {
            // this.loadingComponent.setLoadingInProgress(45);
            this._uploadService.overwriteCCData(this.batchId, this._claimService.currentOrg)
                .subscribe(data => {

                    this._uploadService.initBatchSectionPoller(this.batchId);
                    this.routeToSuccessPage(data.batchId, true, UploadTypeEnum.Overwrite, data.sectionsIncluded);
                }, error => {  this.handleError(error); });
        }
    }

    handleError(error): void {
        if (error.status === 500
            && error.text().toLowerCase().includes('timed out')) {
            if (this.currentSubscription) {
                this.currentSubscription.unsubscribe();
            }
            this.router.navigate(['./manageUploads']);
        } else if (error.status === 403) {
            this.clearUploadInput();
            console.log(error);
            alert('File upload forbidden.');
        } else if (error.code === 1001) {
            this._uploadService.uploadError = error;
            this.router.navigate(['/state-submissions/errors']);
        } else if (error.code === 1003) {
            alert(error.description);
        } else if (error.code === 1006) {
            this._uploadService.uploadError = error;
            this.router.navigate(['/state-submissions/errors']);
        } else if (error.code === 1100) {
            this.batchId = error.batchId;
            jQuery('#mergeConfirmationModal').modal('show');
            jQuery('#mergeConfirmationModal').focus().on('shown.bs.modal',
            () => jQuery('#mergedata').focus());
        } else if (error.code === 1101) {
            this.batchId = error.batchId;
            jQuery('#ccOverwriteConfirmationModal').modal('show');
            jQuery('#ccOverwriteConfirmationModal').focus().on('shown.bs.modal',
            () => jQuery('#replace').focus());
        } else if (error.code === 1999) {
            console.log(error);
            alert('An error occurred during processing please check the template matches the approved format and try again.');
        } else {
            this.clearUploadInput();
            console.log(error);
            jQuery('#incorrectUploadFileModal').modal('show');
            // alert('The file uploaded does not match the approved data format. Please check data and try again.\n\nError: '
            //     + error.description);
        }
    }

    clearUploadInput() {
      this.fileToUpload = null;
      this.fileName = null;
      this.isReadyForUpload = false;
      this.inputUpload.nativeElement.value = null;
    }

    navCancel(): void {
        if (this._claimService.currentTitle === AclTitleEnum[AclTitleEnum.III]) {
            this.router.navigate(['/data-submissions/titleIIIManageUploads'], { queryParamsHandling: "preserve" });
        } else if (this._claimService.currentTitle === AclTitleEnum[AclTitleEnum.VI]) {
            this.router.navigate(['/titlevi/titleVIPPROverview']);
        } else if (this._claimService.currentTitle === AclTitleEnum[AclTitleEnum.VII]) {
            this.router.navigate(['/state-submissions/titleVIIManageUploads']);
        }
    }

    // what happens link uses a modal for presentation and functionality
    whatHappensShow(): void {
        jQuery('#whatHappensModal').modal('show');
    }

    whatHappensHide(): void {
        jQuery('#whatHappensModal').modal('hide');
    }

    // TODO early-on project pattern replace with http Observable
    //NOTE: To append more properties to the POST, API action method model addition is required, AS WELL AS custom Model Binder update
    makeFileRequest(url: string, params: Array<string>, file: File): any {
        const req = (token) => from(new Promise((resolve, reject) => {
            const formData: FormData = new FormData();
            const xhr = new XMLHttpRequest();

            formData.append('fileUpload', file, file.name);
            formData.append('comment', this.comment);
            formData.append('fileName', this.fileName);
            formData.append('sectionsIncluded', 'Cases and Complaints');
            formData.append('state', this._claimService.currentOrg);
            formData.append('psa', this.subOrg || this._claimService.currentSubOrg);
            formData.append('ffy', this._claimService.currentYear);
            formData.append('title', this._claimService.currentTitle);
            formData.append('uploadSelection', this.uploadSectionRadio);
            formData.append('isRollup', JSON.stringify(this._claimService.isRollupOverview));

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else if (xhr.status === 403) {
                        resolve({status: xhr.status});
                    } else {
                        reject(JSON.parse(xhr.response));
                    }
                }
            };
            xhr.open('POST', url, true);

            // Cookie
            xhr.withCredentials = true;
            // JWT
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);

            xhr.send(formData);
        }));

        return this._claimService.injectToken(req).toPromise();
    }

    sampleFile(): void {
        this.router.navigate(['/Resources/techDocs']);
    }

}
