import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class ANSelfDirectionSection extends Section {

    oaPersonsServed: IDataElement;
    oaExpend: IDataElement;
    oaState: IDataElement;
    oaNonState: IDataElement;
    oaProgramIncome: IDataElement;
    oaTotal: IDataElement;

    coaPersonsServed: IDataElement;
    coaExpend: IDataElement;
    coaState: IDataElement;
    coaNonState: IDataElement;
    coaProgramIncome: IDataElement;
    coaTotal: IDataElement;

    orcPersonsServed: IDataElement;
    orcExpend: IDataElement;
    orcState: IDataElement;
    orcNonState: IDataElement;
    orcProgramIncome: IDataElement;
    orcTotal: IDataElement;

    constructor() {
        super();

        this.oaPersonsServed = new DataElement();
        this.oaExpend = new DataElement();
        this.oaState = new DataElement();
        this.oaNonState = new DataElement();
        this.oaProgramIncome = new DataElement();
        this.oaTotal = new DataElement();

        this.coaPersonsServed = new DataElement();
        this.coaExpend = new DataElement();
        this.coaState = new DataElement();
        this.coaNonState = new DataElement();
        this.coaProgramIncome = new DataElement();
        this.coaTotal = new DataElement();

        this.orcPersonsServed = new DataElement();
        this.orcExpend = new DataElement();
        this.orcState = new DataElement();
        this.orcNonState = new DataElement();
        this.orcProgramIncome = new DataElement();
        this.orcTotal = new DataElement();

    }
}
