import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { CaregiverPagesSection } from './CaregiverPagesSection';
import { ValidationService } from '../shared/validation.service';
import { ToastrService } from '../shared/toastr.service';
import { CommonService } from '../shared/common.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIISectionComponent } from './title-iii-section.component';
import { AddOnOptions } from '../shared/Model/addOnOptions';

@Component({
  templateUrl: 'titleIIICaregiverPages.component.html',
})

export class TitleIIICaregiverPagesComponent extends TitleIIISectionComponent<CaregiverPagesSection> implements OnInit, OnDestroy {

  _sectionModel: CaregiverPagesSection;
  _uploadModels: Array<CaregiverPagesSection>;
  _batchIds: Array<string>;
  pageName: string;
  section: string;
  unsavedChanges: boolean = false;
  questionStatusEnum = QuestionStatusEnum;

  public get addOnOption(): AddOnOptions {
    return {
      text: '$',
      width: 20,
      isPrefix: true
    };
  }

  constructor(public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public toastr: ToastrService,
    public _commonService: CommonService
  ) {
    super(new CaregiverPagesSection(), _commonService, _validation, router, _claimService, _service);
   }

  ngOnInit(): void {
    this._sectionModel = new CaregiverPagesSection();
    this.setSectionName();

    switch (this._sectionModel.sectionName) {
      case 'titleIIICOACounseling':
      case 'titleIIIORCCounseling': {
        this.pageName = 'Counseling';
        break;
      }
      case 'titleIIICOATraining':
      case 'titleIIIORCTraining': {
        this.pageName = 'Training';
        break;
      }
      case 'titleIIICOACaseMgmt':
      case 'titleIIIORCCaseMgmt': {
        this.pageName = 'Case Management';
        break;
      }
      case 'titleIIICOASupport':
      case 'titleIIIORCSupport': {
        this.pageName = 'Support Groups';
        break;
      }
      case 'titleIIICOAInfoAndAssistance':
      case 'titleIIIORCInfoAndAssistance': {
        this.pageName = 'Information and Assistance';
        break;
      }

      case 'titleIIICOAInfoServices':
      case 'titleIIIORCInfoServices': {
        this.pageName = 'Information Services';
        break;
      }

      default: {
        this.pageName = 'Error';
        break;
      }
    }

    if (this._sectionModel.sectionName.includes('ORC')) {
      this.section = 'Older Relative Caregivers';
    } else {
      this.section = 'Caregivers of Older Adults';
    }

    this._titleService.setTitle(this.section + ' - ' + this.pageName +
      ' - Total People Served, Service Units, and Expenditures - Data Submissions - ACL OAAPS');
    this.getcaregiverpagesbyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  // return true if current page is a support or info page, otherwise false
  supportOrInfoPage(): boolean {
    return (this.pageName.includes('Support') || this.pageName.includes('Info'));
  }


  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    this.setSectionName();
    super.registerSubscription(this._service.savecaregiverpages(this._sectionModel)
      .subscribe(data => {
        this._sectionModel = data;
      },
      (error)=> console.log(error),
      ()=>{
        callback();

        this.toastr.success('Saved!');
      }
      ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => {
      switch (this._sectionModel.sectionName) {
        case 'titleIIICOACounseling': {
          this.router.navigate(['/data-submissions/titleIIICOATraining']);
          break;
        }
        case 'titleIIICOATraining': {
          this.router.navigate(['/data-submissions/titleIIICOARespite']);
          break;
        }
        case 'titleIIICOACaseMgmt': {
          this.router.navigate(['/data-submissions/titleIIICOASupport']);
          break;
        }
        case 'titleIIICOASupport': {
          this.router.navigate(['/data-submissions/titleIIICOAInfoAndAssistance']);
          break;
        }
        case 'titleIIICOAInfoAndAssistance': {
          this.router.navigate(['/data-submissions/titleIIICOAInfoServices']);
          break;
        }
        case 'titleIIICOAInfoServices': {
          this.router.navigate(['/data-submissions/ORCConsumerTps']);
          break;
        }
        case 'titleIIIORCCounseling': {
          this.router.navigate(['/data-submissions/titleIIIORCTraining']);
          break;
        }
        case 'titleIIIORCTraining': {
          this.router.navigate(['/data-submissions/titleIIIORCRespite']);
          break;
        }
        case 'titleIIIORCCaseMgmt': {
          this.router.navigate(['/data-submissions/titleIIIORCSupport']);
          break;
        }
        case 'titleIIIORCSupport': {
          this.router.navigate(['/data-submissions/titleIIIORCInfoAndAssistance']);
          break;
        }
        case 'titleIIIORCInfoAndAssistance': {
          this.router.navigate(['/data-submissions/titleIIIORCInfoServices']);
          break;
        }
        case 'titleIIIORCInfoServices': {
          this.router.navigate(['/data-submissions/titleIIIOAExpenditures']);
          break;
        }
        default: {
          this.router.navigate(['/dashboard']);
          break;
        }
      }
    };
    if (!this._claimService.isViewOnly && 
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  next(): void {
    switch (this._sectionModel.sectionName) {
      case 'titleIIICOACounseling': {
        this.router.navigate(['/data-submissions/titleIIICOATraining']);
        break;
      }
      case 'titleIIICOATraining': {
        this.router.navigate(['/data-submissions/titleIIICOARespite']);
        break;
      }
      case 'titleIIICOACaseMgmt': {
        this.router.navigate(['/data-submissions/titleIIICOASupport']);
        break;
      }
      case 'titleIIICOASupport': {
        this.router.navigate(['/data-submissions/titleIIICOAInfoAndAssistance']);
        break;
      }
      case 'titleIIICOAInfoAndAssistance': {
        this.router.navigate(['/data-submissions/titleIIICOAInfoServices']);
        break;
      }
      case 'titleIIICOAInfoServices': {
        this.router.navigate(['/data-submissions/ORCConsumerTps']);
        break;
      }
      case 'titleIIIORCCounseling': {
        this.router.navigate(['/data-submissions/titleIIIORCTraining']);
        break;
      }
      case 'titleIIIORCTraining': {
        this.router.navigate(['/data-submissions/titleIIIORCRespite']);
        break;
      }
      case 'titleIIIORCCaseMgmt': {
        this.router.navigate(['/data-submissions/titleIIIORCSupport']);
        break;
      }
      case 'titleIIIORCSupport': {
        this.router.navigate(['/data-submissions/titleIIIORCInfoAndAssistance']);
        break;
      }
      case 'titleIIIORCInfoAndAssistance': {
        this.router.navigate(['/data-submissions/titleIIIORCInfoServices']);
        break;
      }
      case 'titleIIIORCInfoServices': {
        this.router.navigate(['/data-submissions/titleIIIOAExpenditures']);
        break;
      }
      default: {
        this.router.navigate(['/dashboard']);
        break;
      }
    }
  }

  getcaregiverpagesbyffyandstate(): void {
    super.registerSubscription(this._service.get<CaregiverPagesSection[]>(this._claimService,
      this._service._getcaregiverpagesbyffyandstate, this.resolveSectionName())
      .subscribe(data => {
        if (data.length > 0) {
          const sectionData = data.find(item => this.resolveSectionName().includes(item.sectionName));
          if (sectionData) {
            this._sectionModel = sectionData;
          } else {
            // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
            const tempSectionModel: CaregiverPagesSection = new CaregiverPagesSection();
            this._sectionModel = tempSectionModel;
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
        }

      }));
  }

  checkUserResponsibility() {
    let calculateTotals = false;
    if (this._sectionModel.totalServiceUnits.isRequired &&
      this.checkUserExpenditureResponsibility()) {
      calculateTotals = true; // User is responsible for everything
    } else {
      this._sectionModel.totalExpendPerUnit.elementValue = 'N/A';
    }
    return calculateTotals;
  }

  checkUserExpenditureResponsibility() {
    return this._sectionModel.expend.isRequired &&
      this._sectionModel.expendOtherState.isRequired &&
      this._sectionModel.expendOtherNonState.isRequired &&
      this._sectionModel.programIncome.isRequired;
  }

  updateTotals(): void {
    let grandTotal = 0;
    let totalServiceUnits = 0;
    if (this.checkUserExpenditureResponsibility()) {
      const expend = parseFloat(this.removeCommas(this._sectionModel.expend.elementValue));
      const expendOtherState = parseFloat(this.removeCommas(this._sectionModel.expendOtherState.elementValue));
      const expendOtherNonState = parseFloat(this.removeCommas(this._sectionModel.expendOtherNonState.elementValue));
      const programIncome = parseFloat(this.removeCommas(this._sectionModel.programIncome.elementValue));
      totalServiceUnits = parseFloat(this.removeCommas(this._sectionModel.totalServiceUnits.elementValue));
      if (!isNaN(expend)) { grandTotal += expend; }
      if (!isNaN(expendOtherState)) { grandTotal += expendOtherState; }
      if (!isNaN(expendOtherNonState)) { grandTotal += expendOtherNonState; }
      if (!isNaN(programIncome)) { grandTotal += programIncome; }
      this._sectionModel.expendTotal.elementValue = this.addDecimals(grandTotal.toString());
    } else {
      this._sectionModel.expendTotal.elementValue = 'N/A';
    }
    if (this.checkUserResponsibility()) {
      this._sectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(grandTotal, totalServiceUnits);
      this._sectionModel.totalExpendPerUnit.questionStatus = QuestionStatusEnum.L1InProgress;
    }
  }

  formatAllNumbersAlreadySaved(): void {
    if (this.pageName !== 'Support Groups') {
      this._sectionModel.totalPersonsServed.elementValue = this.addComma(this._sectionModel.totalPersonsServed.elementValue);
    }
    this._sectionModel.totalServiceUnits.elementValue = this.addComma(this._sectionModel.totalServiceUnits.elementValue);
    this._sectionModel.expend.elementValue = this.addDecimals(this._sectionModel.expend.elementValue);
    this._sectionModel.expendOtherState.elementValue = this.addDecimals(this._sectionModel.expendOtherState.elementValue);
    this._sectionModel.expendOtherNonState.elementValue = this.addDecimals(this._sectionModel.expendOtherNonState.elementValue);
    this._sectionModel.programIncome.elementValue = this.addDecimals(this._sectionModel.programIncome.elementValue);
    this._sectionModel.expendTotal.elementValue = this.addDecimals(this._sectionModel.expendTotal.elementValue);
    this._sectionModel.totalExpendPerUnit.elementValue = this.addDecimals(this._sectionModel.totalExpendPerUnit.elementValue);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this._sectionModel.sectionName.includes('ORC')) {
      if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
        this._commonService.orcLockedNav = false;

        return true;
      } else {
        this._commonService.orcLockedNav = true;
        return false;
      }
    } else if (this._sectionModel.sectionName.includes('COA')) {
      if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
        this._commonService.coaLockedNav = false;

        return true;
      } else {
        this._commonService.coaLockedNav = true;
        return false;
      }
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

}
