<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <h2>Older Adults - {{pageName}} - Service Units and Expenditures</h2>
        <p class="info mt-1">All fields are required</p>
        <div class="col-md-12 background-gray-lighter mb-1">
          <h2>Service Units</h2>
        </div>
        <div class="col-md-8">
          <label for="totalServiceUnits" class="normal">Total service units</label>
        </div>
        <div class="col-md-4 mb-1">
          <acl-data-element-text [id]="'inputtotalServiceUnits'" [de]="_sectionModel.totalServiceUnits"
          [addOnOptions]="createAddOnOptions('Hours', addOnWidth)"
           (change)="updateTotals(); onChange()">
          </acl-data-element-text>
          <acl-input-error [de]="_sectionModel.totalServiceUnits"></acl-input-error>
        </div>
        <div class="col-md-12 background-gray-lighter mb-1">
          <h2>Expenditures</h2>
        </div>
        <div class="col-md-9">
          <label for="expendC" class="normal">Title III expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <acl-data-element-text [id]="'inputexpendC'" [de]="_sectionModel.expendC"
          [addOnOptions]="addOnOption"
           (change)="updateTotals(); onChange()">
          </acl-data-element-text>
          <acl-input-error [de]="_sectionModel.expendC"></acl-input-error>
        </div>
        <div class="col-md-9">
          <label for="expendOtherState" class="normal">Other: State expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <acl-data-element-text [id]="'inputexpendOtherState'" [de]="_sectionModel.expendOtherState"
          [addOnOptions]="addOnOption"
           (change)="updateTotals(); onChange()">
          </acl-data-element-text>
          <acl-input-error [de]="_sectionModel.expendOtherState"></acl-input-error>
        </div>
        <div class="col-md-9">
          <label for="expendOtherNonState" class="normal">Other: Non-state expenditure</label>
        </div>
        <div class="col-md-3 mb-1">
          <acl-data-element-text [id]="'inputexpendOtherNonState'" [de]="_sectionModel.expendOtherNonState"
          [addOnOptions]="addOnOption"
           (change)="updateTotals(); onChange()">
          </acl-data-element-text>
          <acl-input-error [de]="_sectionModel.expendOtherNonState"></acl-input-error>
        </div>
        <div class="col-md-9">
          <label for="programIncome" class="normal">Program income expended</label>
        </div>
        <div class="col-md-3 mb-1">
          <acl-data-element-text [id]="'inputprogramIncome'" [de]="_sectionModel.programIncome"
          [addOnOptions]="addOnOption"
           (change)="updateTotals(); onChange()">
          </acl-data-element-text>
          <acl-input-error [de]="_sectionModel.programIncome"></acl-input-error>
        </div>
        <div class="col-md-9">
          <label for="expendTotal" class="normal">Total expenditures</label>
        </div>
        <div class="col-md-3 mb-1">
          <acl-data-element-text [id]="'inputexpendTotal'" [de]="_sectionModel.expendTotal"
          [addOnOptions]="addOnOption" [disabled]=true></acl-data-element-text>
        </div>
        <div class="col-md-9">
          <label for="totalExpendPerUnit" class="normal">Total expenditure per unit</label>
        </div>
        <div class="col-md-3 mb-1">
          <acl-data-element-text [id]="'inputtotalExpendPerUnit'" [de]="_sectionModel.totalExpendPerUnit"
          [addOnOptions]="addOnOption" [disabled]=true></acl-data-element-text>
        </div>
        <!-- <div class="row col-md-12">
            <button class="btn btn-primary col-md-3 mr-1" (click)="saveAndNext()" >Save and next</button>

            <button class="btn btn-default" (click)="save()" >Save</button>

            <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
        </div> -->
        <div class="col-md-12 row">
          <div *ngIf="!validForm" class="has-error">
            <p class="help-block">
              There are errors on the page. You must fix the errors or clear the data in those fields in order to Save this form.
            </p>
          </div>
          <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
          <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>
          <button class="btn btn-default mr-1" (click)="save()" [disabled]="!validForm || !_sectionModel.isRequired">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
        </div>
        <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1"
             style="display: none;">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
                <h3 class="modal-title" id="nextModalLabel">
                  Recent changes on this page will not be saved
                </h3>
              </div>
              <div class="modal-body">
                <p>
                  There are errors on the page. You must fix the errors or clear the data in those fields in order to save this form.
                  <br />
                  <br />
                  You may select "Go back to form" to remove the errors or you may select "Proceed to next section" and all of new the data entered in the current section will be lost.
                </p>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go back to form</button>
                <button class="btn btn-primary pull-right" data-dismiss="modal" type="button" (click)="next()">Proceed to next section</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
