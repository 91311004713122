import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class OALegalCasesSection extends Section {

    openCases: IDataElement;
    closedCases: IDataElement;

    advice: IDataElement;
    limited: IDataElement;
    rep: IDataElement;
    serviceClosed: IDataElement;

    income: IDataElement;
    healthCare: IDataElement;
    longTerm: IDataElement;
    nutrition: IDataElement;
    housing: IDataElement;
    utilities: IDataElement;
    abuseNeglect: IDataElement;
    defenseGuardianship: IDataElement;
    ageDiscrimination: IDataElement;
    other: IDataElement;
    typeClosed: IDataElement;

    constructor() {
        super();

        this.openCases = new DataElement();
        this.closedCases = new DataElement();

        this.advice = new DataElement();
        this.limited = new DataElement();
        this.rep = new DataElement();
        this.serviceClosed = new DataElement();

        this.income = new DataElement();
        this.healthCare = new DataElement();
        this.longTerm = new DataElement();
        this.nutrition = new DataElement();
        this.housing = new DataElement();
        this.utilities = new DataElement();
        this.abuseNeglect = new DataElement();
        this.defenseGuardianship = new DataElement();
        this.ageDiscrimination = new DataElement();
        this.other = new DataElement();
        this.typeClosed = new DataElement();

    }
}
