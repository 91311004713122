import { VarianceExplanation } from '../shared/varianceExplanation.model';
import { DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';
import { VarianceExplanationDto } from '../DataSubmissions/Dto/VarianceExplanationDto';


export class ServiceExpenditure extends Section {
    public estimatedAudienceSize: DataElement;
    public personsServed: DataElement;
    public serviceUnits: DataElement;
    public totalExpenditure: DataElement;
    public titleIIIExpenditure: DataElement;
    public otherStateExpenditure: DataElement;
    public otherNonStateExpenditure: DataElement;
    public programIncomeExpenditure: DataElement;
    public totalExpenditurePerUnit: DataElement;
    public totalOpenCases: DataElement;
    public varianceExplanation: VarianceExplanation;
    public rollupExplanations: VarianceExplanationDto[];

    constructor() {
        super();
        this.estimatedAudienceSize = new DataElement();
        this.personsServed = new DataElement();
        this.serviceUnits = new DataElement();
        this.totalExpenditure = new DataElement();
        this.titleIIIExpenditure = new DataElement();
        this.otherStateExpenditure = new DataElement();
        this.otherNonStateExpenditure = new DataElement();
        this.programIncomeExpenditure = new DataElement();
        this.totalExpenditurePerUnit = new DataElement();
        this.totalOpenCases = new DataElement();
    }
}
