import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ITitleOrg, RegionStrings, UserManagementStrings } from '../../UserManagement/userManagerObjects';
import { groupBy, difference } from 'lodash-es';
import { removeAll } from '../../shared/Util/array.util';
import { Constants } from '../../app.constants';
import { ClaimService } from '../../shared/claim.service';
import * as _ from 'lodash-es';


@Component({
    selector: 'acl-rollup-multiselect',
    templateUrl: 'rollup-multi-select.component.html',
    styleUrls: ['rollup-multi-select.component.scss'],
})

export class RollupMultiSelectComponent implements OnInit, OnChanges {


    @Input() grantees: ITitleOrg[] = [];
    @Input() selected: ITitleOrg[] = [];
    @Input() disabled: boolean = false;
    @Output() selectedChange = new EventEmitter<ITitleOrg[]>();


    public region: string = 'All';
    public state: string = 'All';
    public filteredGrantees: ITitleOrg[] = [];
    public selectedGrantees: ITitleOrg[] = [];
    public currentlySelectedAdd: ITitleOrg[] = [];
    public currentlySelectedRemove: ITitleOrg[] = [];
    public groupedFilteredGrantees: Array<any> = [];
    public groupedSelectedGrantees: Array<any> = [];

    public regions = RegionStrings;
    public states = Constants.STATES.map(x => ({ state: x.abbr, region: x.region }))
        .sort((left, right) => left.state < right.state ? -1 : 1);
    public filteredStates = [];

    private existingOrgs: string[] = [];

    constructor(public _claimService: ClaimService) {
    }

    ngOnInit(): void {
        this.setExistingOrgs();
        this.filter();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.grantees && changes.grantees.currentValue !== changes.grantees.previousValue) {
            this.filter();
        }
        if (changes.selected && changes.selected.currentValue !== changes.selected.previousValue) {
            this.setExistingOrgs();
            this.filter();
        }
    }

    public setExistingOrgs(): void {
        if (this.selected && this.selected.length > 0) {
            this.selectedGrantees = this.selected.slice();
        }
    }

    public addGrantee(grantees: ITitleOrg[]) {
        this.selectedGrantees.push(...grantees);
        this.currentlySelectedAdd = [];
        this.filter();
        this.selectedChange.emit(this.selectedGrantees);
    }

    public removeGrantee(grantees: ITitleOrg[]) {
        this.existingOrgs = this.existingOrgs.filter(x => !grantees.find(y => x === y.org));
        removeAll(this.selectedGrantees, grantees.slice());
        this.currentlySelectedRemove = [];
        this.filter();
        this.selectedChange.emit(this.selectedGrantees);
    }

    public filter(changed?: string): void {
        // this.selectedGrantees = union(this.selectedGrantees, this.grantees.filter(g => this.existingOrgs.find(o => o === g.org)));
        this.filteredGrantees = difference(this.grantees, this.selectedGrantees);
        this.sanitize(changed);
        this.filteredStates = this.states.filter(s => this.region === 'All' || s.region === this.region);
        this.filteredGrantees = this.filteredGrantees.filter(g => (this.region === 'All' || g.region === this.region)
            && (this.state === 'All' || g.state === this.state));
        this.groupedFilteredGrantees = this.groupGrantees(this.filteredGrantees);
        this.groupedSelectedGrantees = this.groupGrantees(this.selectedGrantees);
    }

    private groupGrantees(grantees: ITitleOrg[]) {
        if (grantees && grantees.length > 0) {
            const groups = [];
            const map = groupBy(grantees, item => item.region);
            Object.keys(map).forEach(k => {
                groups.push({ key: k, value: map[k].sort((a, b) => a.displayName > b.displayName ? 1 : -1) });
            });
            return groups;
        } else {
            return [];
        }
    }

    private sanitize(changed: string): void {
        if (changed) {
            if (changed.includes('region')) {
                this.state = 'All';
            }
        }
    }

}
