import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class HealthPromoEvidenceSection extends Section {

    peopleServed: IDataElement;
    partB: IDataElement;
    partD: IDataElement;
    bothParts: IDataElement;
    otherStateExpend: IDataElement;
    nonStateExpend: IDataElement;
    programIncome: IDataElement;
    totalExpend: IDataElement;
    expendPerUnit: IDataElement;

    constructor() {
        super();

        this.peopleServed = new DataElement();
        this.partB = new DataElement();
        this.partD = new DataElement();
        this.bothParts = new DataElement();
        this.otherStateExpend = new DataElement();
        this.nonStateExpend = new DataElement();
        this.programIncome = new DataElement();
        this.totalExpend = new DataElement();
        this.expendPerUnit = new DataElement();

    }
}
