<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <loading #loadingSpinner></loading>
      <h1>Status History</h1>
      <div class="col-md-12 acl-container">
        <acl-review-header [model]="aclHeader"></acl-review-header>
      </div>
      <div class="col-md-12 pt-1 pb-1">
        <!-- status log modal -->
        <div id="submissionLogComment" class="modal fade">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-title">
                  {{status}} Comments by {{logCreatedBy}}
                  on {{logCreatedDate != '0001-01-01T00:00:00Z' ? (logCreatedDate | amDateFormat:'L') : ""}}
                  at {{logCreatedDate != '0001-01-01T00:00:00Z' ? (logCreatedDate | amDateFormat:'LT') : ""}}
                </h2>
              </div>
              <div class="modal-body">
                <p style="white-space:pre-wrap;">{{comments}}</p>
              </div>
              <div class="modal-footer">
                <button (click)="dismissComments()" type="button" class="btn btn-primary btn-small" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
          </div>
          <div class="col-md-9">
            <div class="no-print pull-right">
              <a id="dashboardReturnLnk" *ngIf="!_claimService.isViewOnly" [routerLink]="['../../dashboard']" class="btn btn-primary">
              Go back to dashboard</a>
              <button *ngIf="isPrint" class="btn btn-default ml-1" (click)='isPrint = !isPrint'>Collapse All Comments</button>
              <button id="printStatusBtn" class="btn btn-default ml-1" (click)='print()'>Print status history</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select [(ngModel)]="flowType" id="flowType" name="flowType" style="width: 20%;" class="form-control"
              (change)="updateSubmissionLogs($event.target.value)">
              <option value="SPR">SPR</option>
              <option value="NSIP">NSIP</option>
            </select>
          </div>
        </div>
        <div class="table-responsive">
          <div class="mt-1">
            <table class="table table-striped" [mfData]="_submissionLog" #mf="mfDataTable" [mfRowsOnPage]="rowsOnPage"
              [(mfSortBy)]="sortBy" [(mfSortOrder)]="sortOrder">
              <thead>
                <tr class="background-gray-lighter">
                  <th scope="col">Status</th>
                  <th scope="col">Last Update</th>
                  <th scope="col" width="60%">Comments</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let logEntry of mf.data; let i = index">
                    <td [id]="'status' + i" [ngClass]="_commonService.setColor(logEntry.status)" [innerHTML]="_commonService.overallStatusHtml(logEntry.status)"></td>
                    <td [id]="'updated' + i" *ngIf="logEntry.created != '0001-01-01T00:00:00'">
                    {{logEntry.created | amLocale:'en' | amDateFormat:'MM/DD/YYYY [at] h:mm A'}} by
                      <br />{{logEntry.createdBy}}</td>
                    <td>
                      <p *ngIf="!isPrint" class="">
                        {{ logEntry.comments | truncate : 50 }}
                        <a *ngIf="logEntry.comments != null && logEntry.comments.length > 50" (click)="showSubmissionLogCommentModal(logEntry.status, logEntry.createdBy, logEntry.created, logEntry.comments)">...More</a>
                      </p>
                      <p *ngIf="isPrint" style="white-space: pre-wrap">{{ logEntry.comments }}</p>
                    </td>
                  </tr>
                </tbody>
              <tfoot *ngIf="_submissionLog.length > rowsOnPage" class="no-print">
                <tr>
                  <td colspan="4">
                    <mfBootstrapPaginator></mfBootstrapPaginator>
                  </td>
                </tr>
              </tfoot>
            </table>
            <span class="no-results" *ngIf="_submissionLog.length <= 0">No results.</span>                                                                       
          </div>
        </div>
        <!-- end table-responsive -->

      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end row -->
  </div>
  <!-- .end acl-container (col-md-12) -->
</div>
