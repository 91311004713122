
export class OverviewAclState {
    editAlertText: string = '';
    editButtonText: string = '';
    editAlertStyle: string = '';
    editModalTitleText: string = 'Edit Data Confirmation';
    editModalBodyText: string = '';
    editModalPositiveBtnText: string = '';
    editModalNegativeBtnText: string = 'Cancel';
}
