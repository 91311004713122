import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class ANServiceProvidersSection extends Section {

    allServices: IDataElement;
    bcdServices: IDataElement;
    eServices: IDataElement;
    homeDelivered: IDataElement;
    congregate: IDataElement;
    bothMeals: IDataElement;
    totalProviders: IDataElement;
    infoAndAssist: IDataElement;

    constructor() {
        super();

        this.allServices = new DataElement();
        this.bcdServices = new DataElement();
        this.eServices = new DataElement();
        this.homeDelivered = new DataElement();
        this.congregate = new DataElement();
        this.bothMeals = new DataElement();
        this.totalProviders = new DataElement();
        this.infoAndAssist = new DataElement();

    }
}
