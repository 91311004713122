/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ProgressInterceptor } from './progress.interceptor';
import { Http401Interceptor } from './http401.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ProgressInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: Http401Interceptor, multi: true },
];
