<loading #loadingSpinner></loading>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <acl-modal #confirmModal [headerText]="'Confirm Submission'" [id]="'submissionConfirmModal'" [buttons]="modalButtons">
    You have just completed the last of the required explanations of variances for SPR. If you chose to continue, your
    explanations of variances will be submitted to ACL for review.
    <br>
    <br>
    Once submitted, you will no longer be able to update the explanations of variances unless the complete submission is
    Pulled back for corrections. You can do so from the Overview page until ACL begins their review.
    <br>
    <br>
    If you are not ready to submit the explanations of variances, you can cancel and return to the Overview page.
  </acl-modal>
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9" *ngIf="currentFfyORServiceAndExpenditureSummaryDto?.isRequired">
        <h2>Explanations of Variances for Older Relative Caregivers<span class="print-only line">&nbsp;for&nbsp;{{_claimService.currentOrg}}</span></h2>
        <span class="block print-only">
            <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
            <span class="sr-only">Variance threshold exceeded</span>
            = Variance threshold exceeded
          </span>
          <div class="row mb-1">
            <div class="col-md-12">
              <p class="pull-left pt-1">Enter explanations for each noted variance below. Once complete, mark each explanation as complete.</p>
              <button class="btn btn-default pull-right no-print" (click)='print()'>Print</button>
            </div>
          </div>
        <div class="row mb-1 no-print">
          <div class="text-right">
            <a class="btn btn-link" (click)="_commonService.expandAccordions()" (keyup.enter)="_commonService.expandAccordions()" tabindex="0">Expand all </a> |
            <a class="btn btn-link" (click)="_commonService.collapseAccordions()" (keyup.enter)="_commonService.collapseAccordions()" tabindex="0"> Collapse all</a>
          </div>
        </div>
        <div class="panel-group accordionGroup" id="ORAccordion" role="tablist" aria-multiselectable="true">
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="consumerOverviewHeading" data-toggle="collapse"
              data-target="#consumerOverviewBody">
              <p class="panel-title">
                <a role="button" data-toggle="collapse" href="#consumerOverviewBody"
                  aria-expanded="true" aria-controls="consumerOverviewBody" class="collapsed">
                  <span class="ico"></span>
                  <b>Consumer Overview</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyORServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="consumerOverviewBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="consumerOverviewHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Total registered Older Relative Caregivers served</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRegisteredClients?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRegisteredClients?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRegisteredClients?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRegisteredClients?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.totalRegisteredClients?.variance)}}
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyORServiceAndExpenditureSummaryDto.consumerSummaryTotal?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyORServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyORServiceAndExpenditureSummaryDto?.consumerSummaryTotal?.varianceExplanation">
                  <label [for]="consumerOverviewTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyORServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyORServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.explanation = currentFfyORServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyORServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.isUserFinished || !currentFfyORServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.explanation"
                    rows="4" id="consumerOverviewTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="consumerSummaryTotalUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="consumerSummaryTotalUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyORServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation)"
                        [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyORServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyORServiceAndExpenditureSummaryDto.consumerSummaryTotal?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="counselingHeading" data-toggle="collapse" data-target="#counselingBody">
              <p class="panel-title">
                <a role="button" data-toggle="collapse" href="#counselingBody" aria-expanded="true"
                  aria-controls="counselingBody" class="collapsed">
                  <span class="ico"></span>
                  <b>Counseling</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyORServiceAndExpenditureSummaryDto?.counseling?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="counselingBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="counselingHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.counseling?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.counseling?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.counseling.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.counseling.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.counseling.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.counseling?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.counseling?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.counseling.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.counseling.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.counseling.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.counseling?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.counseling?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.counseling.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.counseling.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.counseling.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure (Excluding Title III-A)</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.counseling?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.counseling?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.counseling.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.counseling.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.counseling.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other state expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.counseling?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.counseling?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.counseling.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.counseling.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.counseling.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.counseling?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.counseling?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.counseling.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.counseling.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.counseling.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.counseling?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.counseling?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.counseling.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.counseling.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.counseling.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.counseling?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.counseling?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.counseling.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.counseling.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.counseling.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.counseling?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                      <td>{{formatVariance(currentFfyORServiceAndExpenditureSummaryDto?.counseling?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyORServiceAndExpenditureSummaryDto.counseling?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation">
                  <label [for]="counselingTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation.explanation = currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation.isUserFinished || !currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation.explanation"
                    rows="4" id="counselingTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="counselingUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="counselingUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation)"
                        [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation.explanation?.length
                    || 0}}
                    of 3400 characters</small>
                    <div *ngIf="currentFfyORServiceAndExpenditureSummaryDto.counseling?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="trainingHeading" data-toggle="collapse" data-target="#trainingBody">
              <p class="panel-title">
                <a role="button" data-toggle="collapse" href="#trainingBody" aria-expanded="true"
                  aria-controls="trainingBody" class="collapsed">
                  <span class="ico"></span>
                  <b>Training</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyORServiceAndExpenditureSummaryDto?.training?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="trainingBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="trainingHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.training?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.training?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.training.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.training.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.training.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.training?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.training?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.training.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.training.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.training.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.training?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.training?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.training.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.training.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.training.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure (Excluding Title III-A)</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.training?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.training?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.training.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.training.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.training.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other state expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.training?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.training?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.training.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.training.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.training.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.training?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.training?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.training.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.training.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.training.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.training?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.training?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.training.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.training.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.training.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.training?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.training?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.training.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.training.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.training.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.training?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                      <td>{{formatVariance(currentFfyORServiceAndExpenditureSummaryDto?.training?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyORServiceAndExpenditureSummaryDto.training?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation">
                  <label [for]="trainingTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation.explanation = currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation.isUserFinished || !currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation.explanation"
                    rows="4" id="trainingTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="trainingUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="trainingUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation)"
                        [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation.explanation?.length
                    || 0}}
                    of 3400 characters</small>
                    <div *ngIf="currentFfyORServiceAndExpenditureSummaryDto.training?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="totalRespiteHeading" data-toggle="collapse" data-target="#totalRespiteBody">
              <p class="panel-title">

                <a role="button" data-toggle="collapse" href="#totalRespiteBody"
                  aria-expanded="true" aria-controls="totalRespiteBody" class="collapsed">
                  <span class="ico"></span>
                  <b>Total Respite</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyORServiceAndExpenditureSummaryDto?.totalRespite?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="totalRespiteBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="totalRespiteHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.totalRespite?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.totalRespite?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.totalRespite?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.totalRespite?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure (Excluding Title III-A)</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.totalRespite?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.totalRespite?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other state expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.totalRespite?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.totalRespite?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.totalRespite?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.totalRespite?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.totalRespite?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.totalRespite?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                      <td>{{formatVariance(currentFfyORServiceAndExpenditureSummaryDto?.totalRespite?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation">
                  <label [for]="totalRespiteTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation.explanation = currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation.isUserFinished || !currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation.explanation"
                    rows="4" id="totalRespiteTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="totalRespiteUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="totalRespiteUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation)"
                        [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                    <div *ngIf="currentFfyORServiceAndExpenditureSummaryDto.totalRespite?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="supplementalServicesHeading" data-toggle="collapse"
              data-target="#supplementalServicesBody">
              <p class="panel-title">

                <a role="button" data-toggle="collapse" href="#supplementalServicesBody"
                  aria-expanded="true" aria-controls="supplementalServicesBody" class="collapsed">
                  <span class="ico"></span>
                  <b>Supplemental Services</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="supplementalServicesBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="supplementalServicesHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <!-- <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr> -->
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure (Excluding Title III-A)</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other state expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per person</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supplementalServices.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                      <td>{{formatVariance(currentFfyORServiceAndExpenditureSummaryDto?.supplementalServices?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation">
                  <label [for]="supplementalServicesTextbox1">Please provide an explanation for each difference
                    highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation.explanation = currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation.isUserFinished || !currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation.explanation"
                    rows="4" id="supplementalServicesTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="supplementalServicesUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="supplementalServicesUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation)"
                        [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyORServiceAndExpenditureSummaryDto.supplementalServices?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="caseManagementHeading" data-toggle="collapse"
              data-target="#caseManagementBody">
              <p class="panel-title">

                <a role="button" data-toggle="collapse" href="#caseManagementBody"
                  aria-expanded="true" aria-controls="caseManagementBody" class="collapsed">
                  <span class="ico"></span>
                  <b>Case Management</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyORServiceAndExpenditureSummaryDto?.caseManagement?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="caseManagementBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="caseManagementHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.caseManagement?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.caseManagement?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.caseManagement.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.caseManagement?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.caseManagement?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.caseManagement.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.caseManagement.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure (Excluding Title III-A)</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.caseManagement?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.caseManagement?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.caseManagement.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other state expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.caseManagement?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.caseManagement?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.caseManagement.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.caseManagement?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.caseManagement?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.caseManagement.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.caseManagement?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.caseManagement?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.caseManagement.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.caseManagement.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.caseManagement.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                      <td>{{formatVariance(currentFfyORServiceAndExpenditureSummaryDto?.caseManagement?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation">
                  <label [for]="caseManagementTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation = currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.isUserFinished || !currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation"
                    rows="4" id="caseManagementTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="caseManagementUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="caseManagementUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation)"
                        [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                    <div *ngIf="currentFfyORServiceAndExpenditureSummaryDto.caseManagement?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="supportGroupsHeading" data-toggle="collapse"
              data-target="#supportGroupsBody">
              <p class="panel-title">

                <a role="button" data-toggle="collapse" href="#supportGroupsBody"
                  aria-expanded="true" aria-controls="supportGroupsBody" class="collapsed">
                  <span class="ico"></span>
                  <b>Support Groups</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyORServiceAndExpenditureSummaryDto?.supportGroups?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="supportGroupsBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="supportGroupsHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.supportGroups?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.supportGroups?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supportGroups.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supportGroups.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure (Excluding Title III-A)</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supportGroups?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supportGroups?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supportGroups.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other state expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supportGroups?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supportGroups?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supportGroups.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supportGroups?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supportGroups?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supportGroups.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supportGroups?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supportGroups?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supportGroups.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.supportGroups.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.supportGroups.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                      <td>{{formatVariance(currentFfyORServiceAndExpenditureSummaryDto?.supportGroups?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation">
                  <label [for]="supportGroupsTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation.explanation = currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation.isUserFinished || !currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation.explanation"
                    rows="4" id="supportGroupsTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="supportGroupsUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="supportGroupsUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation)"
                        [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                    <div *ngIf="currentFfyORServiceAndExpenditureSummaryDto.supportGroups?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="infoAndAssitanceHeading" data-toggle="collapse"
              data-target="#infoAndAssitanceBody">
              <p class="panel-title">
                <a role="button" data-toggle="collapse" href="#infoAndAssitanceBody"
                  aria-expanded="true" aria-controls="infoAndAssitanceBody" class="collapsed">
                  <span class="ico"></span>
                  <b>Information and Assistance</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="infoAndAssitanceBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="infoAndAssitanceHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure (Excluding Title III-A)</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other state expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                      <td>{{formatVariance(currentFfyORServiceAndExpenditureSummaryDto?.infoAndAssitance?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation">
                  <label [for]="infoAndAssitanceTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation.explanation = currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation.isUserFinished || !currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation.explanation"
                    rows="4" id="infoAndAssitanceTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="infoAndAssitanceUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="infoAndAssitanceUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation)"
                        [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation.explanation?.length
                    || 0}} of 3400 characters</small>
                    <div *ngIf="currentFfyORServiceAndExpenditureSummaryDto.infoAndAssitance?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
          <div class="panel panel-default border-none no-break">
            <div class="panel-heading collapsed" role="tab" id="infoServicesHeading" data-toggle="collapse" data-target="#infoServicesBody">
              <p class="panel-title">
                <a role="button" data-toggle="collapse" href="#infoServicesBody"
                  aria-expanded="true" aria-controls="infoServicesBody" class="collapsed">
                  <span class="ico"></span>
                  <b>Information Services</b>
                  <span class="badge pull-right" [innerHTML]="_commonService.accordionStatus(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.varianceExplanation)"></span>
                </a>
              </p>
            </div>
            <div id="infoServicesBody" class="panel-collapse collapse" role="tabpanel" aria-labelledby="infoServicesHeading">
              <div class="panel-body panel-body-plain">
                <div class="table-responsive">
                  <table class="table  td23456-align-right">
                    <thead>
                      <tr class="background-gray-lighter">
                        <th scope="col" width="25%">Data Element</th>
                        <th scope="col" width="15%" class="text-right">{{previousYear}}</th>
                        <th scope="col" width="15%" class="text-right">{{currentYear}}</th>
                        <th scope="col" width="15%" class="text-right">% Variance</th>
                        <th scope="col" width="15%" class="text-right">National {{previousYear}}</th>
                        <th scope="col" width="15" class="text-right">% Difference with National</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th scope="row" class="normal">Persons served</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.infoServices?.personsServed?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.personsServed?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoServices.personsServed?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoServices.personsServed?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoServices.personsServed?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Service units</th>
                      <td>{{formatNumber(previousFfyORServiceAndExpenditureSummaryDto?.infoServices?.serviceUnits?.elementValue)}}</td>
                      <td>{{formatNumber(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.serviceUnits?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoServices.serviceUnits?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoServices.serviceUnits?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoServices.serviceUnits?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Total expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoServices.totalExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoServices.totalExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoServices.totalExpenditure?.variance)}}
                      </td>
                      <td class="background-gray-lightest"></td>
                      <td class="background-gray-lightest"></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Title III expenditure (Excluding Title III-A)</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoServices?.titleIIIExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.titleIIIExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoServices.titleIIIExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoServices.titleIIIExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoServices.titleIIIExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other state expenditures</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoServices?.otherStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.otherStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoServices.otherStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoServices.otherStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoServices.otherStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Other non-state expenditure</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoServices?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.otherNonStateExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoServices.otherNonStateExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoServices.otherNonStateExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoServices.otherNonStateExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="background-gray-lightest">
                      <th scope="row" class="normal paddingLeft20px">Program income expended</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoServices?.programIncomeExpenditure?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.programIncomeExpenditure?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoServices.programIncomeExpenditure?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoServices.programIncomeExpenditure?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoServices.programIncomeExpenditure?.variance)}}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row" class="normal">Expenditure per service unit</th>
                      <td>{{formatCurrency(previousFfyORServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditurePerUnit?.elementValue)}}</td>
                      <td [ngClass]="(!currentFfyORServiceAndExpenditureSummaryDto.infoServices.totalExpenditurePerUnit?.isNotSigificantVariance)?'background-highlighted':''">
                        <span *ngIf="!currentFfyORServiceAndExpenditureSummaryDto.infoServices.totalExpenditurePerUnit?.isNotSigificantVariance">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
                          <span class="sr-only">Variance threshold exceeded</span>
                        </span>
                        {{formatVariance(currentFfyORServiceAndExpenditureSummaryDto.infoServices.totalExpenditurePerUnit?.variance)}}
                      </td>
                      <td>{{formatCurrency(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditurePerUnit?.nationalValue, false)}}</td>
                      <td [ngClass]="nationalVarianceThreshold(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditurePerUnit?.nationalVariance)?'background-highlighted':''">{{formatVariance(currentFfyORServiceAndExpenditureSummaryDto?.infoServices?.totalExpenditurePerUnit?.nationalVariance, false)}}</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <acl-rollup-explanation [active]="aaaExplanationsActive" [explanations]="currentFfyORServiceAndExpenditureSummaryDto.infoServices?.rollupExplanations"></acl-rollup-explanation>
                <p class="print-only"><span class="block"><strong>State Variance Explanation</strong></span>{{currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation?.explanation}}</p>
                <span class="clearfix no-print" *ngIf="currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation">
                  <label [for]="infoServicesTextbox1">Please provide an explanation for each difference highlighted</label>
                  <div [ngClass]="calculateOutlineColor(currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation?.explanation?.length)">
                  <textarea (change)="unsavedChanges = true; currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation.explanation = currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation.explanation.trim()"
                    [disabled]="disableTextAreas || _claimService.isViewOnly || currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation.isUserFinished || !currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation.isRequired"
                    class="verticalResizeOnly form-control" [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation.explanation"
                    rows="4" id="infoServicesTextbox1" (blur)="checkValid()"></textarea>
                    </div>
                    <label class="pull-left" for="infoServicesUserFinishedCheckbox">
                      <input (change)="unsavedChanges = true;" id="infoServicesUserFinishedCheckbox" type="checkbox"
                        [disabled]="markAsCompleteCheckboxDisabled(currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation)"
                        [(ngModel)]="currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation.isUserFinished" />
                      &nbsp;Mark as complete
                    </label>
                  <small class="form-text help-block pull-right">{{currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation.explanation?.length
                    ||
                    0}} of 3400 characters</small>
                    <div *ngIf="currentFfyORServiceAndExpenditureSummaryDto.infoServices?.varianceExplanation?.explanation?.length > 3400" class="alert alert-danger" role="alert">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <span class="sr-only">Error:</span>&nbsp;Character limit exceeded.
                    </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row col-md-12 no-print">
          <button [disabled]="!isValid" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and
            next</button>
          <button [disabled]="!isValid" class="btn btn-default" (click)="save()">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to
            Overview</a>
        </div>
      </div>
      <!-- .end col-md-9 -->
      <div class="col-md-9" *ngIf="!currentFfyORServiceAndExpenditureSummaryDto?.isRequired">
        <h2>Explanations of Variances for Older Relative Caregivers</h2>
        <span>Older Relative Caregivers Explanations are not required</span>
      </div>
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
