import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { OktaService } from '../service/okta.service';

@Injectable()
export class OAuthCallbackHandler implements CanActivate {

    constructor(private router: Router, private oktaService: OktaService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return from(this.oktaService.handleCallbackAsync())
            .pipe(
                switchMap(() => from(this.oktaService.isAuthenticatedAsync())),
                map(canActivate => canActivate ? this.router.parseUrl('/dashboard') : this.router.parseUrl('/welcome'))
            );
    }

}
