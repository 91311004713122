import { from, iif, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OktaService } from './okta.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthenticationGuard implements CanActivate {
    constructor(public router: Router, public oktaService: OktaService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
        return from(this.oktaService.isAuthenticatedAsync())
            .pipe(
                switchMap(authenticated =>
                    iif(() => authenticated, of(true), of(this.router.parseUrl('/welcome')))
                )
            );
    }
}
