<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <loading #loadingSpinner></loading>
      <h1>Data Submissions</h1>
      <div class="flex flex-baseline">
        <h2 class="m-0 p-0 mr-1">FFY {{filterOptions.ffy || _claimService.currentYear}} All State Submissions</h2>
        <button style="margin-bottom: 1px" (click)="toggleFilters()" class="ml-2 btn btn-export">{{state == 'inactive'
          ? 'Show' : 'Hide'}} filters</button>
      </div>
      <!-- filters -->
      <div [@fadeInFlyOut]='state' class="flyout row col-md-12">
        <button aria-label="Close" class="close" (click)="toggleFilters()" type="button">
          <span aria-hidden="true">x</span>
        </button>
        <div class="row">
          <div class="col-md-3">
            <label>
              Region / State Group
              <select class="form-control" (change)="filterOptions.state = ''; filter()" [(ngModel)]="filterOptions.region" [tabindex]="tabIndexFlag">
                <option *ngIf="myStates.length != 0" value="myStates">My states</option>
                <option value="">All states (National view)</option>
                <option *ngFor="let region of regions" [ngValue]="region">Region {{region}}</option>
              </select>
            </label>
            <label>
              State
              <select style="width: 100%;" (change)="filter()" [(ngModel)]="filterOptions.state" class="form-control" [tabindex]="tabIndexFlag">
                <option value="">All</option>
                <option *ngFor="let item of filteredStates" [ngValue]="item.abbr">
                    {{item.abbr}}
                </option>
              </select>
            </label>
            <label>
              Federal Fiscal Year
              <select style="width: 100%;" (change)="updateFfy()" [(ngModel)]="filterOptions.ffy" class="form-control" [tabindex]="tabIndexFlag">
                <option *ngFor="let year of years" [ngValue]="year">{{year}}</option>
              </select>
            </label>
          </div>
          <div class="col-md-3">
            <strong class="mb-1 text-secondary">NSIP Status</strong>
            <br />
            <a [tabindex]="tabIndexFlag" (click)="selectAllStatusNsip()">Select all</a>
            &nbsp;/&nbsp;
            <a [tabindex]="tabIndexFlag" (click)="deselectAllStatusNsip()">Deselect all</a>
            <hr style="margin-top: 10px; margin-bottom: 0;" />
            <label style="margin-left: 20px" *ngFor="let status of filterOptions.nsipStatus; let i = index;" class="checkbox">
              <input type="checkbox" (change)="filter()" [(ngModel)]="status.isChecked" [tabindex]="tabIndexFlag"/>
              <span [innerHTML]="_commonService.renderHtml(status.iconHtml)"></span> {{status.label}}
            </label>
          </div>
          <div class="col-md-3">
            <strong class="mb-1 text-secondary">SPR Status</strong>
            <br />
            <a [tabindex]="tabIndexFlag" (click)="selectAllStatusSpr()">Select all</a>
            &nbsp;/&nbsp;
            <a [tabindex]="tabIndexFlag" (click)="deselectAllStatusSpr()">Deselect all</a>
            <hr style="margin-top: 10px; margin-bottom: 0;" />
            <label style="margin-left: 20px" *ngFor="let status of filterOptions.sprStatus; let i = index;" class="checkbox">
              <input type="checkbox" (change)="filter()" [(ngModel)]="status.isChecked" [tabindex]="tabIndexFlag"/>
              <span [innerHTML]="_commonService.renderHtml(status.iconHtml)"></span> {{status.label}}
            </label>
          </div>
          <div class="col-md-2">
            <strong class="mb-1 text-secondary">Last Updated</strong>
            <label>From
              <input type="date" (change)="filter()" [(ngModel)]="filterOptions.startDate" class="form-control" [tabindex]="tabIndexFlag"/>
            </label>
            <label>To
              <input type="date" (change)="filter()" [(ngModel)]="filterOptions.endDate" class="form-control" [tabindex]="tabIndexFlag"/>
            </label>
          </div>
        </div>
        <div class="pull-right">
          <button aria-label="Close filters" (click)="toggleFilters()" class="btn btn-primary" [tabindex]="tabIndexFlag">Close filters</button>
          <button aria-label="Clear filters" (click)="clearFilters()" (keydown.tab)="toggleFilters()" class="btn btn-link" [tabindex]="tabIndexFlag">Clear filters</button>
        </div>
      </div>
      <!-- .end filters -->
      <div class="mt-1 mb-1">
        <div class="form-group">
          <button (click)="displayAttachments()" class="btn btn-export pull-right">Attachments</button>
          <label for="action">Actions on multiple states</label>
          <select id="action" name="action" style="width: 21%;" class="form-control" (change)="showSubmittedStates()"
            [(ngModel)]="action">
            <option [ngValue]="0"></option>
            <option [ngValue]="statusEnum.Submitted">Change status to In Review</option>
            <option *ngIf="isUserLocker" [ngValue]="statusEnum.Approved">Lock data</option>
            <option *ngIf="isUserLocker" [ngValue]="statusEnum.Locked">Unlock data</option>
          </select>
        </div>
      </div>

      <div id="submittedStates" class="modal fade">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title"> {{action === statusEnum.Submitted ? "Change multiple state statuses to In Review" : action === statusEnum.Approved ? "Lock multiple state submissions" : action === statusEnum.Locked ? "Unlock multiple state submissions" : "Actions on multiple states"}}</h2>
            </div>
            <div class="modal-body">
              <p *ngIf="action === statusEnum.Submitted">Only states that are in a Submitted status can be changed to
                In Review. Once set to In Review, states can no
                longer Unsubmit their reports to make corrections. ACL must then return the report to the states for
                correction.
                Select the states you would like to set to In Review below.
              </p>
              <p *ngIf="action === statusEnum.Approved">Only states submissions that are in an Approved status can be
                Locked. Once Locked, ACL Approvers can no longer
                change the review decision for that state. A user with Locking capabilities must then Unlock the state
                to
                change the review decision.
                <br /> Select the states you would like to set to Locked below.</p>
              <p *ngIf="action === statusEnum.Locked">Only states that are in a Locked status can be Unlocked. Once
                Unlocked, the state's status would revert to
                Approved.</p>
              <br />
              <div *ngIf="submittedStateOverviews.length > 0" class="table-responsive">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <th scope="col">State</th>
                    <th scope="col">
                      <input (change)="selectAllSprStates($event)" id="selectSprStates" name="selectSprStates" type="checkbox" value="">
                      <label class="blackFont" id="selectSprStatesLabel" for="selectSprStates">Select all</label>
                    </th>
                    <th scope="col">SPR Status</th>
                    <th scope="col">
                      <input (change)="selectAllNsipStates($event)" id="selectNsipStates" name="selectNsipStates" type="checkbox" value="">
                      <label class="blackFont" id="selectNsipStatesLabel" for="selectNsipStates">Select all</label>
                    </th>
                    <th scope="col">NSIP Status</th>
                  </tr>
                  <tr *ngFor="let overview of submittedStateOverviews; let i = index">
                    <td>{{overview.state}}</td>
                    <td>
                      <input *ngIf="action === overview.sprStatus" (change)="handleSprCheckEvent($event)" [checked]="sprStatesSelected"
                        name="sprValue[{{i}}]" type="checkbox" value="{{overview.state}}" aria-label="Select state">
                    </td>
                    <td [innerHTML]="_commonService.overallStatusHtml(overview.sprStatus)">{{overview.sprStatus}}</td>
                    <td>
                      <input *ngIf="action === overview.nsipStatus" (change)="handleNsipCheckEvent($event)" [checked]="nsipStatesSelected"
                        name="nsipValue[{{i}}]" type="checkbox" value="{{overview.state}}" aria-label="Select state">
                    </td>
                    <td [innerHTML]="_commonService.overallStatusHtml(overview.nsipStatus)">{{overview.nsipStatus}}</td>
                  </tr>
                </tbody>
                </table>
              </div>
              <div *ngIf="submittedStateOverviews.length == 0">
                <p>
                  <strong>There are currently no states that are in a locked status for the selected reporting period</strong>
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <button *ngIf="submittedStateOverviews.length > 0" (click)="saveStatus()" type="button" class="btn btn-primary btn-small"
                data-dismiss="modal">Save</button>
              <button (click)="dismissSubmittedStateModal()" type="button" class="btn btn-secondary btn-small"
                data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <!-- .end submitted modal -->

      <div class="table-responsive">
        <!--
                    NOTE: statusEnum is not working within the mfDataTable, have to use numbers here
                    Make sure and update this template if new states are added
                -->
        <table class="table table-striped" [mfData]="filter()" #mf="mfDataTable" [mfRowsOnPage]="rowsOnPage"
          [(mfSortBy)]="sortBy" [(mfSortOrder)]="sortOrder">
          <thead>
          <tr>
            <th scope="col">
              <mfDataTableSorter by="state">State</mfDataTableSorter>
            </th>
            <th scope="col">
              <mfDataTableSorter [by]="sortByNumber('region')">Region</mfDataTableSorter>
            </th>
            <th scope="col">
              <mfDataTableSorter by="ffy">FFY</mfDataTableSorter>
            </th>
            <th scope="col">
              <mfDataTableSorter by="sprStatus">SPR status</mfDataTableSorter>
            </th>
            <th scope="col">
              <mfDataTableSorter by="sprLastUpdatedTime">Last updated</mfDataTableSorter>
            </th>
            <th scope="col">
              <mfDataTableSorter by="nsipStatus">NSIP status</mfDataTableSorter>
            </th>
            <th scope="col">
              <mfDataTableSorter by="nsipLastUpdatedTime">Last updated</mfDataTableSorter>
            </th>
            <th scope="col" tabindex="0">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let overview of mf.data">
            <td>
              <a *ngIf="overview.nsipStatus >= statusEnum.SubmittedNoExplanation || overview.sprStatus >= statusEnum.SubmittedNoExplanation"
                (click)="routeToReviewForState(overview)">{{overview.state}}</a>
              <p *ngIf="overview.nsipStatus < statusEnum.SubmittedNoExplanation && overview.sprStatus < statusEnum.SubmittedNoExplanation">{{overview.state}}</p>
            </td>
            <td>{{overview.region}}</td>
            <td>{{overview.ffy}}</td>
            <td [ngClass]="_commonService.setColor(resolveACLStatus(overview.sprStatus))" [innerHTML]="_commonService.statusReader(_commonService.overallStatus(overview.sprStatus, overview.sprIsRequired))" id="{{overview.state+'SprStatus'}}"></td>
            <td>
              <span [innerHTML]="_commonService.updatedByReader(overview.sprLastUpdatedTime, overview.sprLastUpdatedBy, overview.sprIsRequired)"></span>
            </td>
            <td [ngClass]="_commonService.setColor(resolveACLStatus(overview.nsipStatus))" [innerHTML]="_commonService.statusReader(_commonService.overallStatus(overview.nsipStatus, overview.nsipIsRequired))" id="{{overview.state+'NsipStatus'}}"></td>
            <td>
              <span [innerHTML]="_commonService.updatedByReader(overview.nsipLastUpdatedTime, overview.nsipLastUpdatedBy, overview.nsipIsRequired)"></span>
            </td>
            <td>
              <button *ngIf="overview.nsipStatus >= statusEnum.SubmittedNoExplanation || overview.sprStatus >= statusEnum.SubmittedNoExplanation"
                class="btn btn-primary" (click)="routeToReviewForState(overview)" id="{{'viewBtn'+overview.state}}">View</button>
            </td>
          </tr>
        </tbody>
          <tfoot class="no-print">
            <tr>
              <td colspan="5">
                <mfBootstrapPaginator></mfBootstrapPaginator>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- end .table-responsive -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
