export interface IGrant {
    enrollmentYear: string;
    fiscalYear: string;
    grantNumber: string;
    isActive: boolean;
    // grantStartTime: string;
    // duration: string;
    // awardAmount: number;
    type: GrantTypes;
}

export class Grant implements IGrant {
    enrollmentYear: string;
    fiscalYear: string;
    grantNumber: string;
    isActive: boolean;
    // grantStartTime: string;
    // duration: string;
    // awardAmount: number;
    type: GrantTypes;
}

export enum GrantTypes {
    AB = 1, C, NSIP
}
