import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { ServicesProvidedSection } from './ServicesProvidedSection';
import { DataElement, ValidationTypes } from '../shared/dataElement';
import { CommonService } from '../shared/common.service';
import { ToastrService } from '../shared/toastr.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { ValidationService } from '../shared/validation.service';
import { TitleIIISectionComponent } from './title-iii-section.component';
import { DynamicFormItem } from '../shared/dynamicFormItem';
import { AddOnOptions } from '../shared/Model/addOnOptions';
import * as _ from 'lodash-es';
import { object } from 'underscore';

@Component({
  templateUrl: 'titleIIIOtherServices.component.html',
})

export class TitleIIIOtherServicesComponent extends TitleIIISectionComponent<ServicesProvidedSection> implements OnInit, OnDestroy {

  _sectionModel: ServicesProvidedSection;
  _selectedService: DynamicFormItem = new DynamicFormItem();
  isUpdate: boolean = false;
  updateIndex: number;
  section: string;
  pageName: string;
  unsavedChanges: boolean = false;
  questionStatusEnum = QuestionStatusEnum;

  public get addOnOption(): AddOnOptions {
    return {
      text: '$',
      width: 20,
      isPrefix: true
    };
  }

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _commonService: CommonService,
    public _validation: ValidationService,
    public toastr: ToastrService
  ) {
    super(new ServicesProvidedSection(), _commonService, _validation, router, _claimService, _service);
  }

  ngOnInit(): void {
    this.initNewService();
    this.get();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  public onChange(): void {
    this.updateTotals();
    this.checkValidForm();
  }

  public hasError(property: string): boolean {
    const prop = this._selectedService.properties[property];
    return prop && !this._commonService.isNullOrEmpty(prop.dataEntryErrorMessage);
  }

  public upsertService(): void {
    this.unsavedChanges = true;
    if (!this.isUpdate) {
      this._sectionModel.services.push(this._selectedService);
    } else {
      this._sectionModel.services[this.updateIndex] = this._selectedService;
    }
    this.initNewService();
  }

  public updateService(service: DynamicFormItem, index: number): void {
    this.isUpdate = true;
    this.updateIndex = index;
    this._selectedService = _.cloneDeep(service);
  }

  public initNewService(): void {
    this._selectedService = new DynamicFormItem();
    this.isUpdate = false;
    this._selectedService.properties['serviceName'] = new DataElement();
    this._selectedService.properties['serviceDomain'] = new DataElement();
    this._selectedService.properties['totalEstimatedUnduplicatedPersonServed'] = new DataElement();
    this._selectedService.properties['serviceUnit'] = new DataElement();
    this._selectedService.properties['totalEstimatedServiceUnitsProvided'] = new DataElement();
    this._selectedService.properties['expendC1'] = new DataElement();
    this._selectedService.properties['expendC2'] = new DataElement();
    this._selectedService.properties['partB'] = new DataElement();
    this._selectedService.properties['expend'] = new DataElement();
    this._selectedService.properties['expendOtherState'] = new DataElement();
    this._selectedService.properties['expendOtherNonState'] = new DataElement();
    this._selectedService.properties['programIncome'] = new DataElement();
    this._selectedService.properties['expendTotal'] = new DataElement();
    this._selectedService.properties['totalExpendPerUnit'] = new DataElement();

    this._selectedService.properties['serviceName'].maxLength = 50;
    this._selectedService.properties['serviceName'].validationType = ValidationTypes.None;
    this._selectedService.properties['serviceDomain'].validationType = ValidationTypes.None;
    this._selectedService.properties['totalEstimatedUnduplicatedPersonServed'].validationType = ValidationTypes.NonZeroInt;

    this._selectedService.properties['serviceUnit'].validationType = ValidationTypes.None;
    this._selectedService.properties['serviceUnit'].maxLength = 50;

    this._selectedService.properties['totalEstimatedServiceUnitsProvided'].validationType = ValidationTypes.Number;

    this._selectedService.properties['expendC1'].validationType = ValidationTypes.Decimal2;
    this._selectedService.properties['expendC2'].validationType = ValidationTypes.Decimal2;
    this._selectedService.properties['partB'].validationType = ValidationTypes.Decimal2;
    this._selectedService.properties['expend'].validationType = ValidationTypes.Decimal2;

    this._selectedService.properties['expendOtherState'].validationType = ValidationTypes.Decimal2;
    this._selectedService.properties['expendOtherNonState'].validationType = ValidationTypes.Decimal2;
    this._selectedService.properties['programIncome'].validationType = ValidationTypes.Decimal2;
    this._selectedService.properties['expendTotal'].validationType = ValidationTypes.Decimal2;
    this._selectedService.properties['totalExpendPerUnit'].validationType = ValidationTypes.Decimal2;

    // required first time around when some records might not have these props (avoid null object)
    this._selectedService.properties['expendC1'].questionStatus = this.questionStatusEnum.NotStarted;
    this._selectedService.properties['expendC2'].questionStatus = this.questionStatusEnum.NotStarted;
    this._selectedService.properties['partB'].questionStatus = this.questionStatusEnum.NotStarted;
  }

  public get(): void {
    super.registerSubscription(this._service.get<ServicesProvidedSection[]>(this._claimService,
      this._service._getservicesprovidedbyffyandstate, this.resolveSectionName())
      .subscribe(data => this.handleResponse(data),
        (error) => console.log(error)
      ));
  }

  public save(callback: () => void = () => null): void {
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.psa = this._claimService.currentSubOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    super.registerSubscription(this._service.saveservicesprovided(this._sectionModel)
      .subscribe(data => {
        this.handleResponse([data]);
        this.unsavedChanges = false;
        callback();
      },
      (error)=> console.log(error)
      ));
  }

  public saveAndNext(): void {
    this.unsavedChanges = false;
    if (super.isEditable(this._claimService) && this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(this.routeToNextSection.bind(this));
    } else {
      this.routeToNextSection();
    }
  }

  public delete(index: number) {
    this._sectionModel.services.splice(index, 1);
  }

  public isOtherServices(): boolean {
    return this._sectionModel.sectionName
      && this._sectionModel.sectionName.toLowerCase().indexOf('other') !== -1;
  }

  private handleResponse(data: Array<ServicesProvidedSection>) {
    if (data && data[0]) {
      this._sectionModel = data[0];
      this.addMissingProps();
      this.resolveSectionAndPageNameFromRoute();
      super.processSectionStatus();
    }
  }

  addMissingProps() {
    // These props were introduced mid-collection of 2023. Remove for future years.
    if(this._sectionModel.sectionName == 'titleIIIOtherServices')
    {
      this._sectionModel.services.forEach(element => {
        if(element.properties['expendC1'] == undefined){
          element.properties['expendC1'] = new DataElement();
          element.properties['expendC1'].validationType = ValidationTypes.Decimal2;
        }
        if(element.properties['expendC2'] == undefined){
          element.properties['expendC2'] = new DataElement();
          element.properties['expendC2'].validationType = ValidationTypes.Decimal2;
        }
        if(element.properties['partB'] == undefined){
          element.properties['partB'] = new DataElement();
          element.properties['partB'].validationType = ValidationTypes.Decimal2;
        }
      });
    }
  }

  private resolveSectionAndPageNameFromRoute(): void {
    if (this._sectionModel.sectionName.includes('ORC')) {
      this.section = 'Older Relative Caregivers';
    } else if (this._sectionModel.sectionName.includes('COA')) {
      this.section = 'Caregivers of Older Adults';
    } else {
      this.section = 'Older Adults';
    }

    if (this._sectionModel.sectionName.includes('Other')) {
      this.pageName = 'Other Services';
    } else {
      this.pageName = 'Supplemental Services';
    }
    this._titleService.setTitle(this.section + ' - ' + this.pageName + ' - Services Provided - Data Submissions - ACL OAAPS');
  }

  private updateTotals(): void {
    let grandTotal = 0;
    let expend = 0;
    const expendC1 = parseFloat(this.removeCommas(this._selectedService.properties.expendC1.elementValue));
    const expendC2 = parseFloat(this.removeCommas(this._selectedService.properties.expendC2.elementValue));
    const partB = parseFloat(this.removeCommas(this._selectedService.properties.partB.elementValue));
    const expendOtherState = parseFloat(this.removeCommas(this._selectedService.properties.expendOtherState.elementValue));
    const expendOtherNonState = parseFloat(this.removeCommas(this._selectedService.properties.expendOtherNonState.elementValue));
    const programIncome = parseFloat(this.removeCommas(this._selectedService.properties.programIncome.elementValue));
    const totalEstimatedServiceUnitsProvided =
      parseFloat(this.removeCommas(this._selectedService.properties.totalEstimatedServiceUnitsProvided.elementValue));

    if (!isNaN(expendC1)) { expend += expendC1; }
    if (!isNaN(expendC2)) { expend += expendC2; }
    if (!isNaN(partB)) { expend += partB; }

    if (!isNaN(expend)) { grandTotal += expend; }
    if (!isNaN(expendOtherState)) { grandTotal += expendOtherState; }
    if (!isNaN(expendOtherNonState)) { grandTotal += expendOtherNonState; }
    if (!isNaN(programIncome)) { grandTotal += programIncome; }
    this._selectedService.properties.expendTotal.elementValue = grandTotal.toString();
    this._selectedService.properties.expend.elementValue = expend.toString();
    this._commonService.addDecimals(this._selectedService.properties.expendTotal);
    this._commonService.addDecimals(this._selectedService.properties.expend);

    this._selectedService.properties.totalExpendPerUnit.elementValue = this.expendPerUnit(grandTotal, totalEstimatedServiceUnitsProvided);
    this._selectedService.properties.totalExpendPerUnit.questionStatus = QuestionStatusEnum.L1InProgress;
  }

  private routeToNextSection(): void {
    switch (this._sectionModel.sectionName) {
      case 'titleIIIOtherServices': {
        this.router.navigate(['/data-submissions/titleIIIOtherExpend']);
        break;
      }
      case 'titleIIICOASuppProvided': {
        this.router.navigate(['/data-submissions/titleIIICOASuppExpend']);
        break;
      }
      case 'titleIIIORCSuppProvided': {
        this.router.navigate(['/data-submissions/titleIIIORCSuppExpend']);
        break;
      }
      default: {
        this.router.navigate(['/dashboard']);
        break;
      }
    }
  }

  next(): void {
    switch (this._sectionModel.sectionName) {
      case 'titleIIIOtherServices': {
        this.router.navigate(['/data-submissions/titleIIIOtherExpend']);
        break;
      }
      case 'titleIIICOASuppProvided': {
        this.router.navigate(['/data-submissions/titleIIICOASuppExpend']);
        break;
      }
      case 'titleIIIORCSuppProvided': {
        this.router.navigate(['/data-submissions/titleIIIORCSuppExpend']);
        break;
      }
      default: {
        this.router.navigate(['/dashboard']);
        break;
      }
    }
}

  HasServiceProvidedError(props: Object): boolean {
    return Object.keys(props).some(x =>
      props[x]['questionStatus'] == this.questionStatusEnum.L1InProgressError ||
      props[x]['questionStatus'] == this.questionStatusEnum.L2InProgressError);
  }
}
