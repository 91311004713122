import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CommentTypeEnum } from '../../app.constants';
import { Comment } from './comment';

@Injectable()
export class CommentService {

    // Endpoints
    private readonly GetTVIICommentById = 'api/titlevii/comment/{id}';
    private readonly GetTVIICommentsByType = 'api/titlevii/comment/type/{ffy}/{state}/{id}';
    private readonly SaveTVIIComment = 'api/titlevii/comment';
    private readonly SaveSingleTVIIComment = 'api/titlevii/comment/single';
    private readonly DeleteTVIIComment = 'api/titlevii/comment/delete/{id}';
    private readonly DeleteTVIICommentsByType = 'api/titlevii/comment/delete/{ffy}/{state}/{id}';

    private readonly GetTIIIComments = 'api/titleiii/comment';
    private readonly GetTIIICommentById = 'api/titleiii/comment/{id}';
    // private readonly GetTIIICommentsByType = 'api/titleiii/comment/type/{ffy}/{state}/{id}';
    // private readonly GetTIIIPSACommentsByType = 'api/titleiii/comment/type/{ffy}/{state}/{psa}/{id}';
    private readonly GetTIIIPSACommentsByTypes = 'api/titleiii/comment/type/{ffy}/{state}/{psa}';
    private readonly  GetTIIICommentsByTypes = 'api/titleiii/comment/type/{ffy}/{state}';
    private readonly SaveTIIIComment = 'api/titleiii/comment';
    private readonly SaveSingleTIIIComment = 'api/titleiii/comment/single';
    private readonly DeleteTIIIComment = 'api/titleiii/comment/delete/{id}';
    private readonly DeleteTIIIPSACommentsByType = 'api/titleiii/comment/delete/{ffy}/{state}/{psa}/{id}';
    private readonly DeleteTIIINoPsaCommentsByType = 'api/titleiii/comment/delete/{ffy}/{state}/{id}';

    readonly TitleVICommentApiRoot = 'api/titlevi/comment';

    constructor(public http: HttpClient) {
    }

    getTVIICommentById(id: string): Observable<Comment> {
        const url = this.GetTVIICommentById.replace('{id}', id);
        return this.http.get<Comment>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Comment>()
            ));
    }

    getTVIICommentsByFfyStateType(ffy: string, state: string, id: CommentTypeEnum):
        Observable<Array<Comment>> {
        const url = this.GetTVIICommentsByType.replace('{ffy}', ffy).replace('{state}', state)
            .replace('{id}', id.toString());
        return this.http.get<Array<Comment>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<Comment>>()
            ));
    }

    saveTVIIComment(model: Comment): Observable<any> {
        return this.http.post(this.SaveTVIIComment, model, { withCredentials: true }).pipe(
            catchError(this.handleError<any>()
            ));
    }

    // Soft deletes the other comments of this type existing in db
    saveSingleTVIIComment(model: Comment): Observable<any> {
        return this.http.post(this.SaveSingleTVIIComment, model, { withCredentials: true }).pipe(
            catchError(this.handleError<any>()
            ));
    }

    deleteTVIICommentById(id: string): Observable<any> {
        return this.http.get(this.DeleteTVIIComment.replace('{id}', id), { withCredentials: true }).pipe(
            catchError(this.handleError<any>()
            ));
    }

    deleteTVIICommentsByFfyStateType(ffy: string, state: string, id: CommentTypeEnum): Observable<any> {
        return this.http.get(this.DeleteTVIICommentsByType.replace('{ffy}', ffy).replace('{state}', state)
            .replace('{id}', id.toString()), { withCredentials: true }).pipe(
                catchError(this.handleError<any>()
                ));
    }
    // TIII
    getTIIIComments(): Observable<Array<Comment>> {
        return this.http.post<Array<Comment>>(this.GetTIIIComments, null, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<Comment>>()
            ));
    }

    getTIIICommentById(id: string): Observable<Comment> {
        return this.http.post<Comment>(this.GetTIIICommentById, { id: id }, { withCredentials: true }).pipe(
            catchError(this.handleError<Comment>()
            ));
    }

    // getTIIICommentsByFfyStateType(ffy: string, state: string, psa: string, id: CommentTypeEnum): Observable<Array<Comment>> {
    //     const dto = new FfyStateDto();
    //     dto.ffy = ffy;
    //     dto.state = state;
    //     dto.psa = psa;
    //     return this.http.post(this.GetTIIICommentsByType, { dto, id },
    //         { withCredentials: true })
    //         .map((response: Response) => response.json())
    //         .catch(error => this.handleError(error));
    // }

    getTIIICommentsByFfyStateTypes(ffy: string, state: string, psa: string, ids: CommentTypeEnum[]): Observable<Array<Comment>> {
        let url = this.GetTIIICommentsByTypes;
        if (psa !== null) {
            url = this.GetTIIIPSACommentsByTypes.replace('{psa}', psa);
        }
        return this.http.post<Array<Comment>>(url.replace('{ffy}', ffy).replace('{state}', state), ids,
            { withCredentials: true }).pipe(
                catchError(this.handleError<Array<Comment>>()
                ));
    }

    saveTIIIComment(model: Comment): Observable<any> {
        return this.http.post(this.SaveTIIIComment, model, { withCredentials: true }).pipe(
            catchError(this.handleError<any>()
            ));
    }

    // Soft deletes the other comments of this type existing in db
    saveSingleTIIIComment(model: Comment): Observable<any> {
        return this.http.post(this.SaveSingleTIIIComment, model, { withCredentials: true }).pipe(
            catchError(this.handleError<any>()
            ));
    }

    deleteTIIICommentById(id: string): Observable<any> {
        return this.http.delete(this.DeleteTIIIComment.replace('{id}', id), { withCredentials: true }).pipe(
            catchError(this.handleError<any>()
            ));
    }

    deleteTIIICommentsByFfyStateType(ffy: string, state: string, psa: string, id: CommentTypeEnum): Observable<any> {
        let url = this.DeleteTIIIPSACommentsByType.replace('{id}', id.toString()).replace('{state}', state)
            .replace('{ffy}', ffy).replace('{psa}', psa);
        if (psa == null) {
            url = this.DeleteTIIINoPsaCommentsByType.replace('{id}', id.toString()).replace('{state}', state)
                .replace('{ffy}', ffy);
        }
        return this.http.delete(url, { withCredentials: true }).pipe(
            catchError(this.handleError<any>()
            ));
    }

    // Title VI
    saveSingleTVIComment(model: Comment): Observable<any> {
        return this.http.post(`${this.TitleVICommentApiRoot}`, model, { withCredentials: true }).pipe(
            catchError(this.handleError<any>()
            ));
    }

    getTVICommentsByFfyOrg(ffy: string, org: string, id: CommentTypeEnum): Observable<Comment> {
        const url = `${this.TitleVICommentApiRoot}/type/single/{ffy}/{org}/{id}`.replace('{ffy}', ffy)
            .replace('{org}', org).replace('{id}', id.toString());
        return this.http.get<Comment>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Comment>()
            ));
    }

    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
