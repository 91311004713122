import { UploadTypeEnum } from '../../app.constants';
import { SectionDetail } from '../batchSection';

export class UploadDetails {
    public fileName: string;
    public fileSize: string;
    public uploadedTime: Date;
    public uploadBy: string;
    public uploadFlow: UploadFlowEnum;
    public batchId: string;
    public uploadType?: UploadTypeEnum;
    public sectionsIncluded?: SectionDetail[];
}

export enum UploadFlowEnum {
    CasesAndComplaints,
    Other
}
