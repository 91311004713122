<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
    <div class="row">
        <div class="col-md-12 acl-container">
            <!-- left navigation -->
            <sideNav></sideNav>
            <!-- .end left navigation -->
            <div class="col-md-9">
                <h2>Aging Network Profile - Senior Centers and Focal Points</h2>
                <p class="info mt-1">All fields are required</p>
                <div class="col-md-9">
                    <label for="totalCenters" class="normal">Total number of Senior Centers regardless of funding</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputTotalCenters'" [de]="_sectionModel.totalCenters"
                    (change)="onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.totalCenters"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="oaaFunded" class="normal">Number of Senior Centers receiving OAA funds</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputOaaFunded'" [de]="_sectionModel.oaaFunded"
                    (change)="onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.oaaFunded"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="focalPoints" class="normal">Total number of designated Focal Points</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputFocalPoints'" [de]="_sectionModel.focalPoints"
                    (change)="onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.focalPoints"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="focalPointCenters" class="normal">Number of Focal Points that are Senior Centers</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputFocalPointCenters'" [de]="_sectionModel.focalPointCenters"
                    (change)="onChange()">
                   </acl-data-element-text>
                   <acl-input-error [de]="_sectionModel.focalPointCenters"></acl-input-error>
                </div>

                <div class="col-md-12 row">
                    <div *ngIf="!validForm" class="has-error">
                        <p class="help-block">There are errors on the page. You must fix the errors or clear the data in those fields in order
                            to Save this form.</p>
                    </div>
                    <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
                    <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()" >Save and next</button>
                    <button class="btn btn-default mr-1" (click)="save()" [disabled]="!validForm || !_sectionModel.isRequired">Save</button>
                    <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
                </div>
                <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1" style="display: none;">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h3 class="modal-title" id="nextModalLabel">Recent changes on this page will not be saved</h3>
                            </div>
                            <div class="modal-body">
                                <p>
                                    There are errors on the page. You must fix the errors or clear the data in those fields in order to save this form.
                                    <br />
                                    <br /> You may select "Go back to form" to remove the errors or you may select "Proceed to
                                    next section" and all of new the data entered in the current section will be lost.
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go back to form</button>
                                <button class="btn btn-primary pull-right" (click)="unsavedChanges = false;" data-dismiss="modal" type="button" [routerLink]="['/data-submissions/titleIIIANSelfDirection']">Proceed to next section</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- .end col-md-9 -->
        </div>
        <!-- .end acl-container (col-md-12) -->
    </div>
    <!-- .end row -->
</div>
<!-- ./container -->
