import { Injectable } from '@angular/core';
import { OktaAuth, Token, IDToken, OAuthError } from '@okta/okta-auth-js';
import { ReplaySubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthStates } from '../authStates';

const OKTA_ERROR_USER_NOT_ASSIGNED = 'User is not assigned to the client application.';

@Injectable({
  providedIn: 'root'
})
export class OktaService {
  public readonly stateChange = new ReplaySubject<AuthStates>(1);

  private readonly authClient: OktaAuth;

  constructor(authClient: OktaAuth) {
    this.authClient = authClient || new OktaAuth(environment.oktaConfig);
    this.authClient.start();
    this.isAuthenticatedAsync()
      .then(authenticated => {
        if (authenticated) {
          this.stateChange.next(AuthStates.LOGIN_COMPLETE);
        } else {
          this.stateChange.next(AuthStates.LOGIN_INITIAL_STATE);
        }
      });
  }

  public login(): void {
    this.stateChange.next(AuthStates.LOGIN_REDIRECT);
    this.authClient.token.getWithRedirect(environment.oktaConfig.implicitFlowConfig);
  }

  public async isAuthenticatedAsync(): Promise<boolean> {
    const token = await this.authClient.tokenManager.get('idToken')
    return this.validToken(token);
  }

  public logout() {
    this.stateChange.next(AuthStates.LOGOUT_REDIRECT);
    this.authClient.signOut(environment.oktaConfig.implicitFlowConfig);
  }

  public async handleCallbackAsync(): Promise<void> {
    this.stateChange.next(AuthStates.LOGIN_PROCESSING);

    try {
      const response = await this.authClient.token.parseFromUrl();
      this.authClient.tokenManager.add('idToken', response.tokens.idToken);
      this.stateChange.next(AuthStates.LOGIN_COMPLETE);
    } catch (e) {
      if (e instanceof OAuthError
        && e.errorSummary === OKTA_ERROR_USER_NOT_ASSIGNED) {
        this.stateChange.next(AuthStates.USER_NOT_ASSIGNED);
      } else {
        console.log(JSON.stringify(e));
      }
      this.stateChange.next(AuthStates.LOGIN_FAILED);
    }
  }

  public async getUpnAsync(): Promise<string> {
    return this.getClaimAsync('upn');
  }

  public async getEmailAsync(): Promise<string> {
    return this.getClaimAsync('email');
  }

  public async getIdTokenAsync(): Promise<string> {
    const token = await this.authClient.tokenManager.get('idToken');
    const wasHandled = this.handleInvalidToken(token);
    return wasHandled ? null : token?.idToken;
  }

  private handleInvalidToken(token: Token): boolean {
    if (!this.validToken(token)) {
      this.logout();
      return true;
    }
    return false;
  }

  private validToken(token: Token): boolean {
    return (!!token && !this.authClient.tokenManager.hasExpired(token));
  }

  private async getClaimAsync(key: string): Promise<string> {
    let claim = null;
    const token = await this.authClient.tokenManager.get('idToken') as IDToken;
    const claims = token?.claims;
    if (claims) {
      claim = claims[key];
    }
    return claim;
  }
}
