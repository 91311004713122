import { Component, OnInit, Input } from '@angular/core';
import { ModalButtonOption } from './modalButtonOption';
import { CommonService } from '../common.service';

declare var jQuery;


@Component({
    templateUrl: 'modal.component.html',
    selector: 'acl-modal',
    styles: [
        '.modal-content { margin: auto; }'
    ]
})
export class ModalComponent implements OnInit {

    @Input() id: string;
    @Input() width = '100%';
    @Input() buttonText: string;
    @Input() buttonClass: string;
    @Input() headerText: string;
    @Input() buttons: Array<ModalButtonOption> = [];

    constructor(private commonService: CommonService) {
        this.id = this.commonService.generateRandomString(8);
    }

    ngOnInit(): void { }

    public show(): void {
        jQuery(`#${this.id}`).modal('show');
    }

    public hide(): void {
        jQuery(`#${this.id}`).modal('hide');
    }
}
