import { Component, OnInit, NgZone, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { CGRespiteSection } from './CGRespiteSection';
import { SectionLookup } from '../shared/sectionLookup';
import { ValidationService } from '../shared/validation.service';
import { CommonService } from '../shared/common.service';
import { ToastrService } from '../shared/toastr.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIISectionComponent } from './title-iii-section.component';
import { KeyOfType } from '../shared/type.utils';

@Component({
  templateUrl: 'titleIIICGRespite.component.html',
})

export class TitleIIICGRespiteComponent extends TitleIIISectionComponent<CGRespiteSection> implements OnInit, OnDestroy {

  _sectionModel: CGRespiteSection;
  _uploadModels: Array<CGRespiteSection>;
  _batchIds: Array<string>;
  section: string;
  serviceUnitsArr: Array<DataElement>;
  title3Arr: Array<DataElement>;
  otherStateArr: Array<DataElement>;
  otherNonStateArr: Array<DataElement>;
  programArr: Array<DataElement>;
  totalExArr: Array<DataElement>;
  perPersonArr: Array<DataElement>;
  inHomeArr: Array<DataElement>;
  outDayArr: Array<DataElement>;
  outNightArr: Array<DataElement>;
  unknownArr: Array<DataElement>;
  respiteTotalsArr: Array<DataElement>;
  unsavedChanges: boolean = false;
  statusEnum = QuestionStatusEnum;

  constructor(public zone: NgZone,
    public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public _commonService: CommonService,
    public toastr: ToastrService
  ) { super(new CGRespiteSection(), _commonService, _validation, router, _claimService, _service); }

  ngOnInit(): void {
    this._sectionModel = new CGRespiteSection();
    this.setSectionName();

    if (this._sectionModel.sectionName.includes('ORC')) {
      this.section = 'Older Relative Caregivers';
    } else if (this._sectionModel.sectionName.includes('COA')) {
      this.section = 'Caregivers of Older Adults';
    } else {
      this.section = 'Error';
    }
    this._titleService.setTitle(this.section + ' - Respite - Total People Served and Expenditures - Data Submissions - ACL OAAPS');
    this.getcgrespitebyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    // Add null checking to handle sectionName DataElement being uninitialized upon first save
    // This kind of bug will be prevented in new flow where api calls to /getbyffyandstate
    // will return an initialized model if there is no persisted record for this section
    // if (!this._sectionModel.sectionName)
    //    this._sectionModel.sectionName = new DataElement();

    this.setSectionName();
    super.registerSubscription(this._service.savecgrespite(this._sectionModel)
      .subscribe(data => this.zone.run(() => {
        this._sectionModel = data;
        this.initArrays();
      }),
      (error)=>console.log(error),
      ()=>{
        callback();

        this.toastr.success('Saved!');
      }
      ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => {
      switch (this._sectionModel.sectionName) {
        case 'titleIIICOARespite': {
          this.router.navigate(['/data-submissions/titleIIICOASuppProvided']);
          break;
        }
        case 'titleIIIORCRespite': {
          this.router.navigate(['/data-submissions/titleIIIORCSuppProvided']);
          break;
        }
        default: {
          this.router.navigate(['/dashboard']);
          break;
        }
      }
    };
    if (!this._claimService.isViewOnly &&
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  getcgrespitebyffyandstate(): void {
    super.registerSubscription(this._service.get<CGRespiteSection[]>(this._claimService,
      this._service._getcgrespitebyffyandstate, this.resolveSectionName())
      .subscribe(data => this.zone.run(() => {
        if (data.length > 0) {
          const sectionData = data.find(item => this.resolveSectionName().includes(item.sectionName));
          if (sectionData) {
            this._sectionModel = sectionData;
            this.initArrays();
          } else {
            // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
            const tempSectionModel: CGRespiteSection = new CGRespiteSection();
            this._sectionModel = tempSectionModel;
            this.initArrays();
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
            this.initArrays();
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
        } else {
          this.initArrays();
        }

      })));
  }

  initArrays(): void {
    // row sums
    this.serviceUnitsArr = [this._sectionModel.inhomeTotalTotalService,
    this._sectionModel.outDayTotalService,
    this._sectionModel.outNightTotalService,
    this._sectionModel.unknownRespiteTotalService];

    this.title3Arr = [this._sectionModel.title3InHome,
    this._sectionModel.title3OutDay,
    this._sectionModel.title3OutNight,
    this._sectionModel.title3Unknown];

    this.otherStateArr = [this._sectionModel.otherStateInHome,
    this._sectionModel.otherStateOutDay,
    this._sectionModel.otherStateOutNight,
    this._sectionModel.otherStateUnknown];

    this.otherNonStateArr = [this._sectionModel.otherNonStateInHome,
    this._sectionModel.otherNonStateOutDay,
    this._sectionModel.otherNonStateOutNight,
    this._sectionModel.otherNonStateUnknown];

    this.programArr = [this._sectionModel.programInHome,
    this._sectionModel.programOutDay,
    this._sectionModel.programOutNight,
    this._sectionModel.programUnknown];

    this.totalExArr = [this._sectionModel.totalExInHome,
    this._sectionModel.totalExOutDay,
    this._sectionModel.totalExOutNight,
    this._sectionModel.totalExUnknown];

    this.perPersonArr = [this._sectionModel.perPersonInHome,
    this._sectionModel.perPersonOutDay,
    this._sectionModel.perPersonOutNight,
    this._sectionModel.perPersonUnknown];

    // column sums
    this.inHomeArr = [this._sectionModel.title3InHome,
    this._sectionModel.otherStateInHome,
    this._sectionModel.otherNonStateInHome,
    this._sectionModel.programInHome];

    this.outDayArr = [this._sectionModel.title3OutDay,
    this._sectionModel.otherStateOutDay,
    this._sectionModel.otherNonStateOutDay,
    this._sectionModel.programOutDay];

    this.outNightArr = [this._sectionModel.title3OutNight,
    this._sectionModel.otherStateOutNight,
    this._sectionModel.otherNonStateOutNight,
    this._sectionModel.programOutNight];

    this.unknownArr = [this._sectionModel.title3Unknown,
    this._sectionModel.otherStateUnknown,
    this._sectionModel.otherNonStateUnknown,
    this._sectionModel.programUnknown];

    this.respiteTotalsArr = [this._sectionModel.title3Total,
    this._sectionModel.otherStateTotal,
    this._sectionModel.otherNonStateTotal,
    this._sectionModel.programTotal];
  }

  // updates Total Expenditure row
  updatePerPersonRow(): void {
    const inhomeTotalTotalService = parseFloat(this.removeCommas(this._sectionModel.inhomeTotalTotalService.elementValue));
    const outDayTotalService = parseFloat(this.removeCommas(this._sectionModel.outDayTotalService.elementValue));
    const outNightTotalService = parseFloat(this.removeCommas(this._sectionModel.outNightTotalService.elementValue));
    const unknownRespiteTotalService = parseFloat(this.removeCommas(this._sectionModel.unknownRespiteTotalService.elementValue));
    const totalRespiteTotalService = parseFloat(this.removeCommas(this._sectionModel.totalRespiteTotalService.elementValue));

    const totalExInHome = parseFloat(this.removeCommas(this._sectionModel.totalExInHome.elementValue));
    const totalExOutDay = parseFloat(this.removeCommas(this._sectionModel.totalExOutDay.elementValue));
    const totalExOutNight = parseFloat(this.removeCommas(this._sectionModel.totalExOutNight.elementValue));
    const totalExUnknown = parseFloat(this.removeCommas(this._sectionModel.totalExUnknown.elementValue));
    const totalExTotal = parseFloat(this.removeCommas(this._sectionModel.totalExTotal.elementValue));

    this._sectionModel.perPersonInHome.elementValue = this.expendPerUnit(totalExInHome, inhomeTotalTotalService);
    this._sectionModel.perPersonOutDay.elementValue = this.expendPerUnit(totalExOutDay, outDayTotalService);
    this._sectionModel.perPersonOutNight.elementValue = this.expendPerUnit(totalExOutNight, outNightTotalService);
    this._sectionModel.perPersonUnknown.elementValue = this.expendPerUnit(totalExUnknown, unknownRespiteTotalService);
    this._sectionModel.perPersonTotal.elementValue = this.expendPerUnit(totalExTotal, totalRespiteTotalService);
  }

  // updates Total columns and rows
  updateTotals(totalsArray: Array<DataElement>): string {
    let total: number = 0;

    for (let i = 0; i < totalsArray.length; i++) {
      // if not null, remove commas
      if (totalsArray[i].elementValue) {
        totalsArray[i].elementValue = totalsArray[i].elementValue.replace(/,/g, '');
      }
      // if is a number, sum it
      if (!isNaN(parseFloat(totalsArray[i].elementValue))) {
        total += parseFloat(totalsArray[i].elementValue);
      }
      // replace commas when done adding
      if (totalsArray[i].elementValue.includes('.')) {
        totalsArray[i].elementValue = this.addDecimals(totalsArray[i].elementValue);
      } else {
        totalsArray[i].elementValue = this.addComma(totalsArray[i].elementValue);
      }
    }

    // add comma and return
    return this.addDecimals(total.toString());
  }

  decimalToInt(value: string): string {
    value = value
      .replace(/,/g, '')
      .replace(/\..*$/, '');
    return this.addComma(value);
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.inhomeTotalUnduplicated.elementValue = this.addComma(this._sectionModel.inhomeTotalUnduplicated.elementValue);
    this._sectionModel.outDayTotalUnduplicated.elementValue = this.addComma(this._sectionModel.outDayTotalUnduplicated.elementValue);
    this._sectionModel.outNightTotalUnduplicated.elementValue = this.addComma(this._sectionModel.outNightTotalUnduplicated.elementValue);
    this._sectionModel.unknownRespiteTotalUnduplicated.elementValue =
      this.addComma(this._sectionModel.unknownRespiteTotalUnduplicated.elementValue);
    this._sectionModel.totalRespiteTotalUnduplicated.elementValue =
      this.addComma(this._sectionModel.totalRespiteTotalUnduplicated.elementValue);
    this._sectionModel.inhomeTotalTotalService.elementValue = this.addComma(this._sectionModel.inhomeTotalTotalService.elementValue);
    this._sectionModel.outDayTotalService.elementValue = this.addComma(this._sectionModel.outDayTotalService.elementValue);
    this._sectionModel.outNightTotalService.elementValue = this.addComma(this._sectionModel.outNightTotalService.elementValue);
    this._sectionModel.unknownRespiteTotalService.elementValue = this.addComma(this._sectionModel.unknownRespiteTotalService.elementValue);
    this._sectionModel.totalRespiteTotalService.elementValue = this.addComma(this._sectionModel.totalRespiteTotalService.elementValue);
    this._sectionModel.title3InHome.elementValue = this.addDecimals(this._sectionModel.title3InHome.elementValue);
    this._sectionModel.title3OutDay.elementValue = this.addDecimals(this._sectionModel.title3OutDay.elementValue);
    this._sectionModel.title3OutNight.elementValue = this.addDecimals(this._sectionModel.title3OutNight.elementValue);
    this._sectionModel.title3Unknown.elementValue = this.addDecimals(this._sectionModel.title3Unknown.elementValue);
    this._sectionModel.title3Total.elementValue = this.addDecimals(this._sectionModel.title3Total.elementValue);
    this._sectionModel.otherStateInHome.elementValue = this.addDecimals(this._sectionModel.otherStateInHome.elementValue);
    this._sectionModel.otherStateOutDay.elementValue = this.addDecimals(this._sectionModel.otherStateOutDay.elementValue);
    this._sectionModel.otherStateOutNight.elementValue = this.addDecimals(this._sectionModel.otherStateOutNight.elementValue);
    this._sectionModel.otherStateUnknown.elementValue = this.addDecimals(this._sectionModel.otherStateUnknown.elementValue);
    this._sectionModel.otherStateTotal.elementValue = this.addDecimals(this._sectionModel.otherStateTotal.elementValue);
    this._sectionModel.otherNonStateInHome.elementValue = this.addDecimals(this._sectionModel.otherNonStateInHome.elementValue);
    this._sectionModel.otherNonStateOutDay.elementValue = this.addDecimals(this._sectionModel.otherNonStateOutDay.elementValue);
    this._sectionModel.otherNonStateOutNight.elementValue = this.addDecimals(this._sectionModel.otherNonStateOutNight.elementValue);
    this._sectionModel.otherNonStateUnknown.elementValue = this.addDecimals(this._sectionModel.otherNonStateUnknown.elementValue);
    this._sectionModel.otherNonStateTotal.elementValue = this.addDecimals(this._sectionModel.otherNonStateTotal.elementValue);
    this._sectionModel.programInHome.elementValue = this.addDecimals(this._sectionModel.programInHome.elementValue);
    this._sectionModel.programOutDay.elementValue = this.addDecimals(this._sectionModel.programOutDay.elementValue);
    this._sectionModel.programOutNight.elementValue = this.addDecimals(this._sectionModel.programOutNight.elementValue);
    this._sectionModel.programUnknown.elementValue = this.addDecimals(this._sectionModel.programUnknown.elementValue);
    this._sectionModel.programTotal.elementValue = this.addDecimals(this._sectionModel.programTotal.elementValue);
    this._sectionModel.totalExInHome.elementValue = this.addDecimals(this._sectionModel.totalExInHome.elementValue);
    this._sectionModel.totalExOutDay.elementValue = this.addDecimals(this._sectionModel.totalExOutDay.elementValue);
    this._sectionModel.totalExOutNight.elementValue = this.addDecimals(this._sectionModel.totalExOutNight.elementValue);
    this._sectionModel.totalExUnknown.elementValue = this.addDecimals(this._sectionModel.totalExUnknown.elementValue);
    this._sectionModel.totalExTotal.elementValue = this.addDecimals(this._sectionModel.totalExTotal.elementValue);
    this._sectionModel.perPersonInHome.elementValue = this.addDecimals(this._sectionModel.perPersonInHome.elementValue);
    this._sectionModel.perPersonOutDay.elementValue = this.addDecimals(this._sectionModel.perPersonOutDay.elementValue);
    this._sectionModel.perPersonOutNight.elementValue = this.addDecimals(this._sectionModel.perPersonOutNight.elementValue);
    this._sectionModel.perPersonUnknown.elementValue = this.addDecimals(this._sectionModel.perPersonUnknown.elementValue);
    this._sectionModel.perPersonTotal.elementValue = this.addDecimals(this._sectionModel.perPersonTotal.elementValue);
  }



  expendError(): boolean {
    if (this._sectionModel.title3InHome.dataEntryErrorMessage ||
      this._sectionModel.title3OutDay.dataEntryErrorMessage ||
      this._sectionModel.title3OutNight.dataEntryErrorMessage ||
      this._sectionModel.title3Unknown.dataEntryErrorMessage ||
      this._sectionModel.title3Total.dataEntryErrorMessage ||
      this._sectionModel.otherStateInHome.dataEntryErrorMessage ||
      this._sectionModel.otherStateOutDay.dataEntryErrorMessage ||
      this._sectionModel.otherStateOutNight.dataEntryErrorMessage ||
      this._sectionModel.otherStateUnknown.dataEntryErrorMessage ||
      this._sectionModel.otherStateTotal.dataEntryErrorMessage ||
      this._sectionModel.otherNonStateInHome.dataEntryErrorMessage ||
      this._sectionModel.otherNonStateOutDay.dataEntryErrorMessage ||
      this._sectionModel.otherNonStateOutNight.dataEntryErrorMessage ||
      this._sectionModel.otherNonStateUnknown.dataEntryErrorMessage ||
      this._sectionModel.otherNonStateTotal.dataEntryErrorMessage ||
      this._sectionModel.programInHome.dataEntryErrorMessage ||
      this._sectionModel.programOutDay.dataEntryErrorMessage ||
      this._sectionModel.programOutNight.dataEntryErrorMessage ||
      this._sectionModel.programUnknown.dataEntryErrorMessage ||
      this._sectionModel.programTotal.dataEntryErrorMessage ||
      this._sectionModel.totalExInHome.dataEntryErrorMessage ||
      this._sectionModel.totalExOutDay.dataEntryErrorMessage ||
      this._sectionModel.totalExOutNight.dataEntryErrorMessage ||
      this._sectionModel.totalExUnknown.dataEntryErrorMessage ||
      this._sectionModel.totalExTotal.dataEntryErrorMessage ||
      this._sectionModel.perPersonInHome.dataEntryErrorMessage ||
      this._sectionModel.perPersonOutDay.dataEntryErrorMessage ||
      this._sectionModel.perPersonOutNight.dataEntryErrorMessage ||
      this._sectionModel.perPersonUnknown.dataEntryErrorMessage ||
      this._sectionModel.perPersonTotal.dataEntryErrorMessage ||
      this._sectionModel.title3InHome.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.title3OutDay.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.title3OutNight.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.title3Unknown.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.title3Total.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.otherStateInHome.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.otherStateOutDay.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.otherStateOutNight.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.otherStateUnknown.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.otherStateTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.otherNonStateInHome.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.otherNonStateOutDay.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.otherNonStateOutNight.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.otherNonStateUnknown.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.otherNonStateTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.programInHome.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.programOutDay.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.programOutNight.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.programUnknown.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.programTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalExInHome.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalExOutDay.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalExOutNight.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalExUnknown.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.totalExTotal.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.perPersonInHome.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.perPersonOutDay.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.perPersonOutNight.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.perPersonUnknown.questionStatus === QuestionStatusEnum.L2InProgressError ||
      this._sectionModel.perPersonTotal.questionStatus === QuestionStatusEnum.L2InProgressError) {
      return true;
    } else {
      return false;
    }
  }

  expendWarning(): boolean {
    if (this._sectionModel.title3InHome.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.title3OutDay.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.title3OutNight.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.title3Unknown.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.title3Total.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.otherStateInHome.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.otherStateOutDay.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.otherStateOutNight.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.otherStateUnknown.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.otherStateTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.otherNonStateInHome.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.otherNonStateOutDay.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.otherNonStateOutNight.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.otherNonStateUnknown.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.otherNonStateTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.programInHome.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.programOutDay.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.programOutNight.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.programUnknown.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.programTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalExInHome.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalExOutDay.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalExOutNight.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalExUnknown.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.totalExTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.perPersonInHome.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.perPersonOutDay.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.perPersonOutNight.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.perPersonUnknown.questionStatus === QuestionStatusEnum.L2ValidatedWarning ||
      this._sectionModel.perPersonTotal.questionStatus === QuestionStatusEnum.L2ValidatedWarning) {
      return true;
    } else {
      return false;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this._sectionModel.sectionName === 'titleIIIORCRespite') {
      if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
        this._commonService.orcLockedNav = false;

        return true;
      } else {
        this._commonService.orcLockedNav = true;
        return false;
      }
    } else if (this._sectionModel.sectionName === 'titleIIICOARespite') {
      if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
        this._commonService.coaLockedNav = false;

        return true;
      } else {
        this._commonService.coaLockedNav = true;
        return false;
      }
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

  totalError(): Array<string> {
    const errors: Array<string> = [];

    if (this._sectionModel.totalRespiteTotalUnduplicated.dataEntryErrorMessage) {
      errors.push(this._sectionModel.totalRespiteTotalUnduplicated.label + ': ' +
        this._sectionModel.totalRespiteTotalUnduplicated.dataEntryErrorMessage);
    }
    if (!this._sectionModel.totalRespiteTotalUnduplicated.dataEntryErrorMessage &&
      this._sectionModel.totalRespiteTotalUnduplicated.questionStatus === this.statusEnum.L2InProgressError) {
      errors.push(this._sectionModel.totalRespiteTotalUnduplicated.label + ': '
        + this._sectionModel.totalRespiteTotalUnduplicated.businessErrorMessage);
    }

    if (this._sectionModel.totalRespiteTotalService.dataEntryErrorMessage) {
      errors.push(this._sectionModel.totalRespiteTotalService.label + ': ' +
        this._sectionModel.totalRespiteTotalService.dataEntryErrorMessage);
    }
    if (!this._sectionModel.totalRespiteTotalService.dataEntryErrorMessage &&
      this._sectionModel.totalRespiteTotalService.questionStatus === this.statusEnum.L2InProgressError) {
      errors.push(this._sectionModel.totalRespiteTotalService.label + ': ' +
        this._sectionModel.totalRespiteTotalService.businessErrorMessage);
    }

    if (this._sectionModel.title3Total.dataEntryErrorMessage) {
      errors.push(this._sectionModel.title3Total.label + ': ' + this._sectionModel.title3Total.dataEntryErrorMessage);
    }
    if (!this._sectionModel.title3Total.dataEntryErrorMessage &&
      this._sectionModel.title3Total.questionStatus === this.statusEnum.L2InProgressError) {
      errors.push(this._sectionModel.title3Total.label + ': ' + this._sectionModel.title3Total.businessErrorMessage);
    }

    if (this._sectionModel.otherStateTotal.dataEntryErrorMessage) {
      errors.push(this._sectionModel.otherStateTotal.label + ': ' + this._sectionModel.otherStateTotal.dataEntryErrorMessage);
    }
    if (!this._sectionModel.otherStateTotal.dataEntryErrorMessage &&
      this._sectionModel.otherStateTotal.questionStatus === this.statusEnum.L2InProgressError) {
      errors.push(this._sectionModel.otherStateTotal.label + ': ' + this._sectionModel.otherStateTotal.businessErrorMessage);
    }

    if (this._sectionModel.otherNonStateTotal.dataEntryErrorMessage) {
      errors.push(this._sectionModel.otherNonStateTotal.label + ': ' + this._sectionModel.otherNonStateTotal.dataEntryErrorMessage);
    }
    if (!this._sectionModel.otherNonStateTotal.dataEntryErrorMessage &&
      this._sectionModel.otherNonStateTotal.questionStatus === this.statusEnum.L2InProgressError) {
      errors.push(this._sectionModel.otherNonStateTotal.label + ': ' + this._sectionModel.otherNonStateTotal.businessErrorMessage);
    }

    if (this._sectionModel.programTotal.dataEntryErrorMessage) {
      errors.push(this._sectionModel.programTotal.label + ': ' + this._sectionModel.programTotal.dataEntryErrorMessage);
    }
    if (!this._sectionModel.programTotal.dataEntryErrorMessage &&
      this._sectionModel.programTotal.questionStatus === this.statusEnum.L2InProgressError) {
      errors.push(this._sectionModel.programTotal.label + ': ' + this._sectionModel.programTotal.businessErrorMessage);
    }

    if (this._sectionModel.totalExTotal.dataEntryErrorMessage) {
      errors.push(this._sectionModel.totalExTotal.label + ': ' + this._sectionModel.totalExTotal.dataEntryErrorMessage);
    }
    if (!this._sectionModel.totalExTotal.dataEntryErrorMessage &&
      this._sectionModel.totalExTotal.questionStatus === this.statusEnum.L2InProgressError) {
      errors.push(this._sectionModel.totalExTotal.label + ': ' + this._sectionModel.totalExTotal.businessErrorMessage);
    }

    if (this._sectionModel.perPersonTotal.dataEntryErrorMessage) {
      errors.push(this._sectionModel.perPersonTotal.label + ': ' + this._sectionModel.perPersonTotal.dataEntryErrorMessage);
    }
    if (!this._sectionModel.perPersonTotal.dataEntryErrorMessage &&
      this._sectionModel.perPersonTotal.questionStatus === this.statusEnum.L2InProgressError) {
      errors.push(this._sectionModel.perPersonTotal.label + ': ' + this._sectionModel.perPersonTotal.businessErrorMessage);
    }

    return errors;
  }

  totalWarning(): Array<string> {
    const warnings: Array<string> = [];

    if (!this._sectionModel.totalRespiteTotalUnduplicated.dataEntryErrorMessage &&
      this._sectionModel.totalRespiteTotalUnduplicated.questionStatus === this.statusEnum.L2ValidatedWarning) {
      warnings.push(this._sectionModel.totalRespiteTotalUnduplicated.label + ': ' +
        this._sectionModel.totalRespiteTotalUnduplicated.businessErrorMessage);
    }

    if (!this._sectionModel.totalRespiteTotalService.dataEntryErrorMessage &&
      this._sectionModel.totalRespiteTotalService.questionStatus === this.statusEnum.L2ValidatedWarning) {
      warnings.push(this._sectionModel.totalRespiteTotalService.label + ': ' +
        this._sectionModel.totalRespiteTotalService.businessErrorMessage);
    }

    if (!this._sectionModel.title3Total.dataEntryErrorMessage &&
      this._sectionModel.title3Total.questionStatus === this.statusEnum.L2ValidatedWarning) {
      warnings.push(this._sectionModel.title3Total.label + ': ' + this._sectionModel.title3Total.businessErrorMessage);
    }

    if (!this._sectionModel.otherStateTotal.dataEntryErrorMessage &&
      this._sectionModel.otherStateTotal.questionStatus === this.statusEnum.L2ValidatedWarning) {
      warnings.push(this._sectionModel.otherStateTotal.label + ': ' + this._sectionModel.otherStateTotal.businessErrorMessage);
    }

    if (!this._sectionModel.otherNonStateTotal.dataEntryErrorMessage &&
      this._sectionModel.otherNonStateTotal.questionStatus === this.statusEnum.L2ValidatedWarning) {
      warnings.push(this._sectionModel.otherNonStateTotal.label + ': ' + this._sectionModel.otherNonStateTotal.businessErrorMessage);
    }

    if (!this._sectionModel.programTotal.dataEntryErrorMessage &&
      this._sectionModel.programTotal.questionStatus === this.statusEnum.L2ValidatedWarning) {
      warnings.push(this._sectionModel.programTotal.label + ': ' + this._sectionModel.programTotal.businessErrorMessage);
    }

    if (!this._sectionModel.totalExTotal.dataEntryErrorMessage &&
      this._sectionModel.totalExTotal.questionStatus === this.statusEnum.L2ValidatedWarning) {
      warnings.push(this._sectionModel.totalExTotal.label + ': ' + this._sectionModel.totalExTotal.businessErrorMessage);
    }

    if (!this._sectionModel.perPersonTotal.dataEntryErrorMessage &&
      this._sectionModel.perPersonTotal.questionStatus === this.statusEnum.L2ValidatedWarning) {
      warnings.push(this._sectionModel.perPersonTotal.label + ': ' + this._sectionModel.perPersonTotal.businessErrorMessage);
    }

    return warnings;
  }

  expenditureErrors(type = 'error'): Array<string> {
    let statuses: Array<QuestionStatusEnum>;
    if (type === 'error') {
      statuses = [ this.statusEnum.L1InProgressError, this.statusEnum.L2InProgressError ];
    } else {
      statuses = [ this.statusEnum.L2ValidatedWarning ];
    }

    const modalProperties: Array<KeyOfType<CGRespiteSection, DataElement>> = [
      "inhomeTotalUnduplicated",
      "outDayTotalUnduplicated",
      "outNightTotalUnduplicated",
      "unknownRespiteTotalUnduplicated",
      "inhomeTotalTotalService",
      "outDayTotalService",
      "outNightTotalService",
      "unknownRespiteTotalService",
      "title3InHome",
      "title3OutDay",
      "title3OutNight",
      "title3Unknown",
      "otherStateInHome",
      "otherStateOutDay",
      "otherStateOutNight",
      "otherStateUnknown",
      "otherNonStateInHome",
      "otherNonStateOutDay",
      "otherNonStateOutNight",
      "otherNonStateUnknown",
      "programInHome",
      "programOutDay",
      "programOutNight",
      "programUnknown",
    ];

    const errorsOrWarnings: Array<string> = [];

    modalProperties.forEach(key => {
      if (statuses.includes(this._sectionModel[key].questionStatus)) {
        errorsOrWarnings.push(this._sectionModel[key].label + ': ' +
          (this._sectionModel[key].dataEntryErrorMessage || this._sectionModel[key].businessErrorMessage));
      }
    });

    // if (this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.inhomeTotalUnduplicated.label + ': ' +
    //     this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.inhomeTotalUnduplicated.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.inhomeTotalUnduplicated.label + ': ' +
    //     this._sectionModel.inhomeTotalUnduplicated.businessErrorMessage);
    // }

    // //

    // if (!this._sectionModel.outDayTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.outDayTotalUnduplicated.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.outDayTotalUnduplicated.label + ': ' +
    //     this._sectionModel.outDayTotalUnduplicated.businessErrorMessage);
    // }

    // if (this._sectionModel.outDayTotalUnduplicated.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.outDayTotalUnduplicated.label + ': ' +
    //     this._sectionModel.outDayTotalUnduplicated.dataEntryErrorMessage);
    // }

    // if (this._sectionModel.outNightTotalUnduplicated.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.outNightTotalUnduplicated.label + ': ' +
    //     this._sectionModel.outNightTotalUnduplicated.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.outNightTotalUnduplicated.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.outNightTotalUnduplicated.label + ': ' +
    //     this._sectionModel.outNightTotalUnduplicated.businessErrorMessage);
    // }

    // if (this._sectionModel.unknownRespiteTotalUnduplicated.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.unknownRespiteTotalUnduplicated.label + ': ' +
    //     this._sectionModel.unknownRespiteTotalUnduplicated.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.unknownRespiteTotalUnduplicated.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.unknownRespiteTotalUnduplicated.label + ': ' +
    //     this._sectionModel.unknownRespiteTotalUnduplicated.businessErrorMessage);
    // }

    // if (this._sectionModel.inhomeTotalTotalService.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.inhomeTotalTotalService.label + ': ' +
    //     this._sectionModel.inhomeTotalTotalService.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalTotalService.dataEntryErrorMessage &&
    //   this._sectionModel.inhomeTotalTotalService.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.inhomeTotalTotalService.label + ': ' +
    //     this._sectionModel.inhomeTotalTotalService.businessErrorMessage);
    // }

    // if (this._sectionModel.outDayTotalService.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.outDayTotalService.label + ': ' + this._sectionModel.outDayTotalService.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.outDayTotalService.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.outDayTotalService.label + ': ' + this._sectionModel.outDayTotalService.businessErrorMessage);
    // }

    // if (this._sectionModel.outNightTotalService.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.outNightTotalService.label + ': ' + this._sectionModel.outNightTotalService.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.outNightTotalService.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.outNightTotalService.label + ': ' + this._sectionModel.outNightTotalService.businessErrorMessage);
    // }

    // if (this._sectionModel.unknownRespiteTotalService.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.unknownRespiteTotalService.label + ': '
    //     + this._sectionModel.unknownRespiteTotalService.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.unknownRespiteTotalService.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.unknownRespiteTotalService.label + ': ' +
    //     this._sectionModel.unknownRespiteTotalService.businessErrorMessage);
    // }
    // if (this._sectionModel.title3InHome.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.title3InHome.label + ': ' + this._sectionModel.title3InHome.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.title3InHome.dataEntryErrorMessage &&
    //   this._sectionModel.title3InHome.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.title3InHome.label + ': ' + this._sectionModel.title3InHome.businessErrorMessage);
    // }

    // if (this._sectionModel.title3OutDay.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.title3OutDay.label + ': ' + this._sectionModel.title3OutDay.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.title3OutDay.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.title3OutDay.label + ': ' + this._sectionModel.title3OutDay.businessErrorMessage);
    // }

    // if (this._sectionModel.title3OutNight.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.title3OutNight.label + ': ' + this._sectionModel.title3OutNight.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.title3OutNight.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.title3OutNight.label + ': ' + this._sectionModel.title3OutNight.businessErrorMessage);
    // }

    // if (this._sectionModel.title3Unknown.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.title3Unknown.label + ': ' + this._sectionModel.title3Unknown.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.title3Unknown.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.title3Unknown.label + ': ' + this._sectionModel.title3Unknown.businessErrorMessage);
    // }
    // if (this._sectionModel.otherStateInHome.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.otherStateInHome.label + ': ' + this._sectionModel.otherStateInHome.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.otherStateInHome.dataEntryErrorMessage &&
    //   this._sectionModel.otherStateInHome.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.otherStateInHome.label + ': ' + this._sectionModel.otherStateInHome.businessErrorMessage);
    // }

    // if (this._sectionModel.otherStateOutDay.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.otherStateOutDay.label + ': ' + this._sectionModel.otherStateOutDay.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.otherStateOutDay.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.otherStateOutDay.label + ': ' + this._sectionModel.otherStateOutDay.businessErrorMessage);
    // }

    // if (this._sectionModel.otherStateOutNight.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.otherStateOutNight.label + ': ' + this._sectionModel.otherStateOutNight.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.otherStateOutNight.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.otherStateOutNight.label + ': ' + this._sectionModel.otherStateOutNight.businessErrorMessage);
    // }

    // if (this._sectionModel.otherStateUnknown.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.otherStateUnknown.label + ': ' + this._sectionModel.otherStateUnknown.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.otherStateUnknown.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.otherStateUnknown.label + ': ' + this._sectionModel.otherStateUnknown.businessErrorMessage);
    // }
    // if (this._sectionModel.otherNonStateInHome.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.otherNonStateInHome.label + ': ' + this._sectionModel.otherNonStateInHome.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.otherNonStateInHome.dataEntryErrorMessage &&
    //   this._sectionModel.otherNonStateInHome.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.otherNonStateInHome.label + ': ' + this._sectionModel.otherNonStateInHome.businessErrorMessage);
    // }

    // if (this._sectionModel.otherNonStateOutDay.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.otherNonStateOutDay.label + ': ' + this._sectionModel.otherNonStateOutDay.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.otherNonStateOutDay.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.otherNonStateOutDay.label + ': ' + this._sectionModel.otherNonStateOutDay.businessErrorMessage);
    // }

    // if (this._sectionModel.otherNonStateOutNight.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.otherNonStateOutNight.label + ': ' + this._sectionModel.otherNonStateOutNight.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.otherNonStateOutNight.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.otherNonStateOutNight.label + ': ' + this._sectionModel.otherNonStateOutNight.businessErrorMessage);
    // }

    // if (this._sectionModel.otherNonStateUnknown.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.otherNonStateUnknown.label + ': ' + this._sectionModel.otherNonStateUnknown.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.otherNonStateUnknown.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.otherNonStateUnknown.label + ': ' + this._sectionModel.otherNonStateUnknown.businessErrorMessage);
    // }
    // if (this._sectionModel.programInHome.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.programInHome.label + ': ' + this._sectionModel.programInHome.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.programInHome.dataEntryErrorMessage &&
    //   this._sectionModel.programInHome.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.programInHome.label + ': ' + this._sectionModel.programInHome.businessErrorMessage);
    // }

    // if (this._sectionModel.programOutDay.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.programOutDay.label + ': ' + this._sectionModel.programOutDay.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.programOutDay.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.programOutDay.label + ': ' + this._sectionModel.programOutDay.businessErrorMessage);
    // }

    // if (this._sectionModel.programOutNight.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.programOutNight.label + ': ' + this._sectionModel.programOutNight.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.programOutNight.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.programOutNight.label + ': ' + this._sectionModel.programOutNight.businessErrorMessage);
    // }

    // if (this._sectionModel.programUnknown.dataEntryErrorMessage) {
    //   errorsOrWarnings.push(this._sectionModel.programUnknown.label + ': ' + this._sectionModel.programUnknown.dataEntryErrorMessage);
    // }
    // if (!this._sectionModel.inhomeTotalUnduplicated.dataEntryErrorMessage &&
    //   this._sectionModel.programUnknown.questionStatus === this.statusEnum.L2InProgressError) {
    //   errorsOrWarnings.push(this._sectionModel.programUnknown.label + ': ' + this._sectionModel.programUnknown.businessErrorMessage);
    // }
    return errorsOrWarnings;
  }

  validate(data: DataElement): DataElement {
    if (data.elementValue != null && data.elementValue !== '') {
      if (this._validation.validate(data.validationType, data.elementValue)) {
        if (!this._validation.validateMaxLength(data.elementValue, data.maxLength)) {
          data.dataEntryErrorMessage = 'Maximum allowed digits is ' + data.maxLength;
          data.questionStatus = 1;
        } else {
          data.dataEntryErrorMessage = '';
          data.questionStatus = 3;
        }
      } else {
        data.dataEntryErrorMessage = this._validation.getErrorMessage(data.validationType);
        data.questionStatus = 1;
      }
    } else {
      data.dataEntryErrorMessage = '';
      data.questionStatus = 0;
    }

    return data;
  }

  public hasError(): boolean {
    return this._sectionModel.sectionStatus === QuestionStatusEnum.L1InProgressError
      || this._sectionModel.sectionStatus === QuestionStatusEnum.L2InProgressError;
  }

  public hasWarning(): boolean {
    return this._sectionModel.sectionStatus === QuestionStatusEnum.L2ValidatedWarning;
  }
}
