<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
  <div class="row">
    <div class="col-md-12 acl-container">
      <!-- left navigation -->
      <sideNav #sideNav></sideNav>
      <!-- .end left navigation -->
      <div class="col-md-9">
        <h2>{{section}} - {{pageName}} - Services Provided</h2>
        <div *ngIf="isEditable(_claimService)" class="row mb-1 ml-0">
          <button class="btn btn-secondary" data-target="#serviceModal" data-toggle="modal" data-backdrop="static"
            data-keyboard="false" tabindex="0"><i class="fa fa-plus" aria-hidden="true" id="addServicesBtn"></i>Add {{pageName}}</button>
        </div>
        <!-- services table -->
        <div *ngIf="_sectionModel.services.length > 0" class="table-responsive">
          <table class="table">
            <thead>
              <tr class="background-gray-lighter">
                <th scope="col">Service name</th>
                <th scope="col">Service domain</th>
                <th scope="col">Total estimated unduplicated person served</th>
                <th scope="col">Service unit description</th>
                <th scope="col">Total estimated service units provided</th>
                <th scope="col">Expenditures</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of _sectionModel.services; let i = index;">
              <td>{{item.properties.serviceName.elementValue}}</td>
              <td>{{item.properties.serviceDomain.elementValue}}</td>
              <td>{{item.properties.totalEstimatedUnduplicatedPersonServed.elementValue}}</td>
              <td>{{item.properties.serviceUnit.elementValue}}</td>
              <td>{{item.properties.totalEstimatedServiceUnitsProvided.elementValue}}</td>
              <td>
                <div *ngIf="item.properties.expend.elementValue">
                  <strong>Title III: </strong>${{item.properties.expend.elementValue}}
                </div>
                <div *ngIf="item.properties.expendOtherState.elementValue">
                  <strong>Other: State: </strong>${{item.properties.expendOtherState.elementValue}}
                </div>
                <div *ngIf="item.properties.expendOtherNonState.elementValue">
                  <strong>Other: Non-state: </strong>${{item.properties.expendOtherNonState.elementValue}}
                </div>
                <div *ngIf="item.properties.programIncome.elementValue">
                  <strong>Program income expended: </strong>${{item.properties.programIncome.elementValue}}
                </div>
                <div *ngIf="item.properties.expendTotal.elementValue">
                  <strong>Total: </strong>${{item.properties.expendTotal.elementValue}}
                </div>
              </td>
              <td *ngIf="!_claimService.isViewOnly">
                <a [id]="'updateLnk' + i" class="btn btn-link" data-target="#serviceModal" data-toggle="modal" data-backdrop="static" data-keyboard="false" tabindex="0" (click)="updateService(item, i)" (keyup.enter)="updateService(item, i)" href="#" attr.aria-label="{{'updateOrViewService' + i}}">{{isEditable(_claimService.isViewOnly) ? 'Update' : 'View'}}
                  <i *ngIf="HasServiceProvidedError(item.properties)" aria-hidden="true" class="fa fa-exclamation-circle text-danger">
                    <span class="sr-only">Error:</span>
                  </i>
                </a>
                <br />
                <a *ngIf="isEditable(_claimService.isViewOnly)" [id]="'removeLnk' + i" class="btn btn-link" (click)="delete(i)" tabindex="0" (keyup.enter)="delete(i)" attr.aria-label="{{'removeService' + i}}">Remove</a>
              </td>
            </tr>
          </tbody>
          </table>
        </div>
        <!-- .end services table -->
        <!-- service details modal -->
        <div aria-labelledby="serviceModalLabel" class="modal fade" id="serviceModal" role="dialog" tabindex="-1">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title" id="serviceModalLabel">Add {{pageName}} Provided</h4>
              </div>
              <div class="modal-body row">
                <!-- service name -->
                <div class="col-md-12 mb-1">
                  <label for="serviceName" class="normal">Service name</label>
                  <acl-data-element-text [textAlign]="'left'" [id]="'serviceName'" [de]="_selectedService.properties.serviceName" (change)="onChange()">
                  </acl-data-element-text><acl-input-error [de]="_selectedService.properties.serviceName"></acl-input-error>
                </div>
                <!-- service domain -->
                <div class="col-md-12">
                  <label for="serviceDomain" class="normal">Service domain</label>
                  <div *ngIf="_selectedService.properties.serviceDomain.dataEntryErrorMessage" class="has-error" role="alert">
                    <p class="help-block normal">
                      <i class="fa fa-times fa-2x help-block" aria-hidden="true"></i> {{_selectedService.properties.serviceDomain.dataEntryErrorMessage}}
                    </p>
                  </div>
                  <div *ngIf="!_selectedService.properties.serviceDomain.dataEntryErrorMessage && _selectedService.properties.serviceDomain.questionStatus == questionStatusEnum.L2ValidatedWarning" class="has-warning"
                       role="alert">
                    <p class="help-block normal">
                      <i class="fa fa-warning fa-2x help-block" aria-hidden="true"></i> {{_selectedService.properties.serviceDomain.businessErrorMessage}}
                    </p>
                  </div>
                  <div *ngIf="!_selectedService.properties.serviceDomain.dataEntryErrorMessage && _selectedService.properties.serviceDomain.questionStatus == questionStatusEnum.L2InProgressError" class="has-error"
                       role="alert">
                    <p class="help-block normal">
                      <i class="fa fa-times fa-2x help-block" aria-hidden="true"></i> {{_selectedService.properties.serviceDomain.businessErrorMessage}}
                    </p>
                  </div>
                  <div class="form-group">
                    <select [(ngModel)]="_selectedService.properties.serviceDomain.elementValue" [disabled]="!_selectedService.properties.serviceDomain.isRequired" id="serviceDomain"
                            class="form-control">
                      <option></option>
                      <option value="Assistive Technology/ Durable Equipment/ Emergency Response">Assistive Technology/ Durable Equipment/ Emergency Response</option>
                      <option value="Consumable supplies">Consumable supplies</option>
                      <option value="Home Modifications/ Repairs">Home Modifications/ Repairs</option>
                      <ng-container *ngIf = "isOtherServices()">
                        <option value="Elder abuse prevention/ Elder Rights">Elder abuse prevention/ Elder Rights</option>
                        <option value="Health">Health</option>
                        <option value="Outreach">Outreach</option>
                        <option value="Public Education">Public Education</option>
                        <option value="Socialization">Socialization</option>
                        <option value="Access not reported elsewhere">Access not reported elsewhere</option>
                      </ng-container>
                      <ng-container *ngIf = "!isOtherServices()">
                        <option value="Legal and/or Financial Consultation">Legal and/or Financial Consultation</option>
                        <option value="Homemaker/chore/personal care">Homemaker/chore/personal care</option>
                        <option value="Transportation">Transportation</option>
                        <option value="Nutrition services">Nutrition services</option>
                      </ng-container>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <!-- total estimated unduplicated persons served -->
                <div class="col-md-12 mb-1">
                  <label for="totalEstimatedUnduplicatedPersonServed" class="normal">Total estimated unduplicated persons served</label>
                  <acl-data-element-text [width]="'20%'" [id]="'totalEstimatedUnduplicatedPersonServed'" [de]="_selectedService.properties.totalEstimatedUnduplicatedPersonServed" (change)="onChange()">
                  </acl-data-element-text><acl-input-error [de]="_selectedService.properties.totalEstimatedUnduplicatedPersonServed"></acl-input-error>
                </div>
                <!-- service unit description -->
                <div class="col-md-12 mb-1">
                  <label for="serviceUnit" class="normal">Service unit description</label>
                  <acl-data-element-text [textAlign]="'left'" [id]="'serviceUnit'" [de]="_selectedService.properties.serviceUnit" (change)="onChange()">
                  </acl-data-element-text><acl-input-error [de]="_selectedService.properties.serviceUnit"></acl-input-error>
                </div>
                <!-- total estimated service units provided -->
                <div class="col-md-12 mb-1">
                  <label for="totalEstimatedServiceUnitsProvided" class="normal">Total estimated service units provided</label>
                  <acl-data-element-text [width]="'20%'" [id]="'totalEstimatedServiceUnitsProvided'" [de]="_selectedService.properties.totalEstimatedServiceUnitsProvided" (change)="onChange()">
                  </acl-data-element-text><acl-input-error [de]="_selectedService.properties.totalEstimatedServiceUnitsProvided"></acl-input-error>
                </div>

                <div class="clearBoth pl-h background-gray-lighter m-1">
                  <h2>Expenditures</h2>
                </div>

                <!-- title iii expenditure -->
                <div class="col-md-8">
                  <label for="expend" class="normal">Title III expenditure</label>
                </div>
                <div class="col-md-4 mb-1">
                  <acl-data-element-text [addOnOptions]="addOnOption" [id]="'expend'"
                    [de]="_selectedService.properties.expend" (change)="onChange()">
                  </acl-data-element-text>
                  <acl-input-error [de]="_selectedService.properties.expend"></acl-input-error>
                </div>
                <!-- other state expenditure -->
                <div class="col-md-8">
                  <label for="expendOtherState" class="normal">Other: State expenditure</label>
                </div>
                <div class="col-md-4 mb-1">
                  <acl-data-element-text [addOnOptions]="addOnOption" [id]="'expendOtherState'" [de]="_selectedService.properties.expendOtherState" (change)="onChange()">
                  </acl-data-element-text>
                  <acl-input-error [de]="_selectedService.properties.expendOtherState"></acl-input-error>
                </div>
                <!-- other non-state expenditure -->
                <div class="col-md-8">
                  <label for="expendOtherNonState" class="normal">Other: Non-state expenditure</label>
                </div>
                <div class="col-md-4 mb-1">
                  <acl-data-element-text [addOnOptions]="addOnOption" [id]="'expendOtherNonState'" [de]="_selectedService.properties.expendOtherNonState" (change)="onChange()">
                  </acl-data-element-text>
                  <acl-input-error [de]="_selectedService.properties.expendOtherNonState"></acl-input-error>
                </div>
                <!-- program income expended -->
                <div class="col-md-8">
                  <label for="programIncome" class="normal">Program income expended</label>
                </div>
                <div class="col-md-4 mb-1">
                  <acl-data-element-text [addOnOptions]="addOnOption" [id]="'programIncome'" [de]="_selectedService.properties.programIncome" (change)="onChange()">
                  </acl-data-element-text>
                  <acl-input-error [de]="_selectedService.properties.programIncome"></acl-input-error>
                </div>
                <!-- total expenditures -->
                <div class="col-md-8">
                  <label for="expendTotal">Total expenditures</label>
                </div>
                <div class="col-md-4 mb-1">
                  <acl-data-element-text [addOnOptions]="addOnOption" [id]="'expendTotal'" [de]="_selectedService.properties.expendTotal" [disabled]="true">
                  </acl-data-element-text>
                  <acl-input-error [de]="_selectedService.properties.expendTotal"></acl-input-error>
                </div>
                <!-- expenditure per unit -->
                <div class="col-md-8">
                  <label for="totalExpendPerUnit" class="normal">Expenditure per unit</label>
                </div>
                <div class="col-md-4 mb-1">
                  <acl-data-element-text [addOnOptions]="addOnOption" [id]="'totalExpendPerUnit'" [de]="_selectedService.properties.totalExpendPerUnit" [disabled]="true">
                  </acl-data-element-text>
                  <acl-input-error [de]="_selectedService.properties.totalExpendPerUnit"></acl-input-error>
                </div>
              </div>
              <div class="modal-footer">
                <button id="upsertServiceBtn" class="btn btn-primary mr-1" [disabled]="!validForm" data-dismiss="modal" (click)="upsertService()">{{isUpdate ? 'Update' : 'Add'}} service</button>
                <button id="cancelBtn" class="btn btn-link" data-dismiss="modal" (click)="initNewService()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <!-- .end service details modal -->

        <div class="col-md-12 row">
          <div *ngIf="!validForm" class="has-error">
            <p class="help-block">
              There are errors on the page. You must fix the errors or clear the data in those fields in order to Save this form.
            </p>
          </div>
          <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
          <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()">Save and next</button>
          <button class="btn btn-default mr-1" (click)="save()" [disabled]="!validForm || !_sectionModel.isRequired">Save</button>
          <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
        </div>
        <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1"
             style="display: none;">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                  <span aria-hidden="true">×</span>
                </button>
                <h3 class="modal-title" id="nextModalLabel">
                  Recent changes on this page will not be saved
                </h3>
              </div>
              <div class="modal-body">
                <p>
                  There are errors on the page. You must fix the errors or clear the data in those fields in order to save this form.
                  <br />
                  <br />
                  You may select "Go back to form" to remove the errors or you may select "Proceed to next section" and all of new the data entered in the current section will be lost.
                </p>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go back to form</button>
                <button class="btn btn-primary pull-right" data-dismiss="modal" type="button" (click)="next()">Proceed to next section</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .end col-md-9 -->
    </div>
    <!-- .end acl-container (col-md-12) -->
  </div>
  <!-- .end row -->
</div>
<!-- ./container -->
