<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
    <div class="row">
        <div class="col-md-12 acl-container">
            <h1>Profile</h1>
            <div>
                <h2>State Assignment</h2>
                <loading #loadingSpinner=""></loading>
                <p class="help-block">Please select the states to be used as the default setting for the Dashboard summary display and Data Submissions
                    filter.
                </p>
                <div class="mb-2">
                    <div class="table-responsive">
                        <fieldset class="reportRadiosFieldset">
                            <legend class="mb-1">Show me</legend>
                            <label class="radio-inline mt-1" for="national">
                                <input [(ngModel)]="radioSelection" type="radio" name="levelSelect" id="national" value="national"> All states - National level
                            </label>
                            <br />
                            <label class="radio-inline ml-0" for="state">
                                <input [(ngModel)]="radioSelection" type="radio" name="levelSelect" id="state" value="state"> Selected states and regions
                            </label>
                        </fieldset>
                        <div *ngIf="radioSelection === 'state'" style="margin: 2em 15px 15px 0;">
                            <acl-state-select [states]="titleStates" (selectedStates)="setSelectedStates($event)"></acl-state-select>
                        </div>
                    </div>
                </div>
                <div class="container-fluid col-md-8 mt-1">
                    <button [disabled]="!(!!radioSelection) || radioSelection === ''" class="btn btn-primary" (click)="save()">Save</button>
                    <a class="btn btn-link" [routerLink]="['/state-submissions/titleVIIDataSubmissions']">Cancel</a>
                </div>
                <!-- .end container-fluid .end col-md-8 -->
            </div>
            <!-- .end col-md-9 -->
        </div>
        <!-- .end acl-container (col-md-12) -->
    </div>
</div>