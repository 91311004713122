import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, finalize, catchError } from 'rxjs/operators';

import { ComponentCanDeactivate } from '../../shared/pending-changes.guard';
import { ToastrService } from '../../shared/toastr.service';
import { TitleIIIDataEntryService } from '../../DataSubmissions/titleIIIDataEntry.service';
import { ClaimService } from '../../shared/claim.service';
import {
    SubmissionResponsibility, DataSubmissionResponsibility, DataGroupEnum, UserGroupEnum,
    StateProfile, StateProfileStatus
} from './submissionResponsibility';
import { YearApprovalStatus } from '../../shared/yearApprovalStatus.model';
import { interval } from 'rxjs';
import { takeWhile, exhaustMap } from 'rxjs/operators';
import { DestructibleComponent } from '../../destructible.component';
import { LoadingService } from './../../shared/loading.service';
import { NgForm } from '@angular/forms';

@Component({
    templateUrl: 'dataSubmissionResponsibility.component.html'
})

export class DataSubmissionResponsibilityComponent extends DestructibleComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

    // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;

    _sectionModel: SubmissionResponsibility = new SubmissionResponsibility('', '');
    stateGroup: UserGroupEnum = UserGroupEnum.State;
    aaaGroup: UserGroupEnum = UserGroupEnum.AAA;
    stateAAAGroup: UserGroupEnum = UserGroupEnum.StateAAA;
    statusEnum = StateProfileStatus;
    // inLockedState: boolean = false;
    // inInProgressState: boolean = true;
    // inReadyState: boolean = false;
    hasData: boolean = false;
    otherServicesTip: string = 'The following fields are included under Other Services for Older Adults.\n\n' +
        'For each Other Service provided: \n' +
        'Service name\n' +
        'Service domain\n' +
        'Total estimated unduplicated person served\n' +
        'Service unit name\n' +
        'Total estimated service units provided\n' +
        'All expenditures\n\n' +
        'For all Other Services provided(aggregated)\n' +
        'Total estimated number of people served';

    supplementalServicesTip: string = 'The following fields are included under Supplemental Services for Caregiver programs.\n\n' +
        'For each Supplemental Service provided:\n' +
        'Service name \n' +
        'Service domain\n' +
        'Total estimated unduplicated person served\n' +
        'Service unit name\n' +
        'Total estimated service units provided\n' +
        'All expenditures\n\n' +
        'For all Supplemental Services provided (aggregated):\n' +
        'Total estimated number of people served';

    public init$: Observable<boolean>;
    public isLocked = true;
    private dsr:string = "DataSubmissionResponsibility";
    private modified = false;

    @ViewChild('form', { static: true }) ngForm: NgForm;

    constructor(public route: ActivatedRoute,
        public router: Router,
        public toastr: ToastrService,
        public _claimService: ClaimService,
        public _service: TitleIIIDataEntryService,
        private _loadingService: LoadingService
    ) { super(); }

    readonly canDeactivate: () => boolean | Observable<boolean> = () => !this.modified;

    ngOnInit(): void {
        // this.loadingComponent.setLoadingInProgress(180);
        this._loadingService.setLoading(true, this.dsr);
        this._claimService.init$.subscribe(x => {
            this._sectionModel = new SubmissionResponsibility(this._claimService.currentYear, this._claimService.currentOrg);
            this.pollStateProfileState();
        });
        super.registerSubscription(this.ngForm.form.valueChanges.subscribe(_ => this.modified = true));
    }

    ngOnDestroy(): void {
        super.destroy();
    }

    pollStateProfileState(completedCallback: () => void = () => null): void {
        super.registerSubscription(
            interval(3000).pipe(
                takeWhile(val => val < 60 && this.isLocked), // Wait for 3 minutes or unlocked
                exhaustMap(() => this.getStateProfile$()),
                finalize(() => {
                    this.modified = false;
                    this._loadingService.setLoading(false, this.dsr);
                    if (!this.isLocked) {
                        completedCallback();
                    };
                })
            ).subscribe((profile: StateProfile) => {
                this._sectionModel = this.formatData(profile);
                this.isLocked = profile.status !== StateProfileStatus.ReadyForDataEntry
                    && profile.status !== StateProfileStatus.InProgress;
            })
        );
    }

    getStateProfile$(): Observable<StateProfile> {
        return this._service.getStateProfile(this._claimService.currentYear, this._claimService.currentOrg);
    }

    setFiscalYears$(): Observable<YearApprovalStatus[]> {
        return this._service.getActiveYearApprovalStatusByState(this._claimService.currentOrg, this._claimService.currentSubOrg);
    }

    formatData(profile: StateProfile) {
        const data = profile.dataGroups;
        if (data != null) {
            data.forEach(element => {
                switch (element.dataGroup) {
                    case (DataGroupEnum.AgingNetwork):
                        this._sectionModel.agingNetworkResponsibility = element;
                        break;
                    case (DataGroupEnum.ServiceExpenditures):
                        this._sectionModel.expenditureResponsibility = element;
                        break;
                    case (DataGroupEnum.ServiceUnits):
                        this._sectionModel.serviceUnitsResponsibility = element;
                        break;
                    case (DataGroupEnum.LegalAssistance):
                        this._sectionModel.legalAssistanceResponsibility = element;
                        break;
                    case (DataGroupEnum.CaregiverSupplementalServices):
                        this._sectionModel.caregiverSupplementalServices = element;
                        break;
                }
            });
            this._sectionModel.state = profile.state;
            this._sectionModel.fiscalYear = profile.fiscalYear;
            this._sectionModel.status = profile.status;
            this._sectionModel.id = profile.id;
        }

        return this._sectionModel;
    }

    save(): void {
        this.isLocked = true;
        // this.loadingComponent.setLoadingInProgress(180);
        this._loadingService.setLoading(true, this.dsr);
        this._sectionModel.state = this._claimService.currentOrg;
        this._sectionModel.fiscalYear = this._claimService.currentYear;
        super.registerSubscription(this._service.saveSubmissionResponsibility(this.viewToModel())
            .pipe(
                tap(() => this.pollStateProfileState(() => this.toastr.success('Saved!')))
            ).subscribe());
    }


    unlock(): void {
        this.isLocked = true;
        // this.loadingComponent.setLoadingInProgress(230);
        this._loadingService.setManualLoading(true);
        super.registerSubscription(this._service.deleteStateProfile(this._claimService.currentYear, this._claimService.currentOrg)
            .pipe(
                tap(() => this.pollStateProfileState(() => this.toastr.success('Data has been cleared!'))),
                catchError(err => {
                    console.log(err);
                    if (err.status === 406) {
                        this.toastr.error('Data Submission Responsibility selections cannot be cleared when the SPR or NSIP Rollup report has been Submitted.');
                    } else {
                        this.toastr.error('We\'re sorry, an unknown error occurred.');
                    }
                    return of(null);
                }),
                finalize(() => {
                    this._loadingService.setManualLoading(false);
                    this.modified = false;
                })
            ).subscribe()
        );
    }


    viewToModel() {
        const profile = new StateProfile(this._sectionModel.fiscalYear, this._sectionModel.state);
        const list: Array<DataSubmissionResponsibility> = new Array<DataSubmissionResponsibility>();
        list.push(this._sectionModel.agingNetworkResponsibility);
        list.push(this._sectionModel.serviceUnitsResponsibility);
        list.push(this._sectionModel.expenditureResponsibility);
        list.push(this._sectionModel.legalAssistanceResponsibility);
        list.push(this._sectionModel.caregiverSupplementalServices);
        profile.dataGroups = list;
        profile.fiscalYear = this._sectionModel.fiscalYear;
        profile.state = this._sectionModel.state;
        profile.status = this._sectionModel.status;
        profile.id = this._sectionModel.id;
        return profile;
    }
}
