<!-- Fixed navbar -->
<nav class="navbar navbar-default navbar-static-top" id="header-navbar">
  <div class="navbar-header">
    <div class="region region-navigation container pl-0">
      <section id="block-headerlogo" class="pull-left">
        <a href="/" class="home-logo-link pull-left">
          <div class="subtitle pull-left">
            <img alt="ACL OAAPS | Older Americans Act Performance System"
              src="/assets/images/acl-oaaps-logo.png" class="img-responsive">
          </div>
        </a>
        <!-- .end subtitle -->
      </section>

      <section id="block-topnavigation" class="pull-right">

        <ul *ngIf="isLoggedIn" class="list-unstyled list-inline">
          <li>Welcome <a [href]="userProfileUri"
              target="_blank">{{username}}</a></li>
          <!-- TODO link to edit user page -->
          <li><button (click)="logout()" class="btn btn-link" id="logout-button">Sign out</button></li>
        </ul>
        <ul *ngIf="!isLoggedIn" class="list-unstyled list-inline">
          <li class="vmiddle">
            <a (click)="forgotPassNav()" class="">Forgot password</a>
            <a href="api/upload/download?title=III&downloadType=UserRegistrationGuide" target="_blank">User registration
              guide</a>
          </li>
          <li><button id="signinbtn" (click)="login()" class="btn btn-primary">Sign in</button></li>
        </ul>
        <!-- .begin title picker dropdown -->
        <div *ngIf="isLoggedIn && claimService.titleOrgs.length > 1" class="dropdown">
          <div class="acl-select">
            <label class="sr-only" for="switchTitle">Switch title:</label>
            <select class="form-control primary" [(ngModel)]="currentTitleOrg" (ngModelChange)="switchTitle($event)"
              aria-label="Switch title" id="switchTitle">
              <option disabled selected [ngValue]="claimService.currentTitleOrg"> Current:
                {{claimService.currentTitleOrg?.displayName ||
                                ''}}</option>
              <ng-container *ngFor="let titleOrg of claimService.titleOrgs">
                <option
                  *ngIf="titleOrg.title != claimService.currentTitleOrg?.title || titleOrg.org != claimService.currentTitleOrg?.org"
                  [ngValue]="titleOrg">Switch
                  to :
                  {{titleOrg.displayName}}</option>
              </ng-container>
            </select>
          </div>
          <!-- end acl-select -->
        </div>
        <!-- .end title picker dropdown -->
      </section>
    </div><!-- .end region-navigation -->

  </div>
  <!--.end navbar-header -->
</nav>
