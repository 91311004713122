import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageViewComponent } from './page-view.component';
import { Paginator } from './paginator.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PageViewComponent,
        Paginator
    ],
    exports: [
        PageViewComponent,
        Paginator
    ]
})
export class PageViewModule {

}
