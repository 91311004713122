import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { ClaimService } from '../shared/claim.service';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { Rollup, RollupStatus } from './StateRollup/rollup';
import { LoadingComponent } from '../shared/loading.component';
import { ToastrService } from '../shared/toastr.service';
import { DestructibleComponent } from '../destructible.component';
import { IRollupSource, ITitleOrg } from '../UserManagement/userManagerObjects';
import { TIIIOverview } from '../shared/section';
// import { title } from 'process';

import {
  SubmissionResponsibility, DataSubmissionResponsibility, DataGroupEnum, UserGroupEnum,
  StateProfile, StateProfileStatus
} from './Profile/submissionResponsibility';


declare var jQuery: any;

import { interval, Observable } from 'rxjs';
import { takeWhile, exhaustMap, finalize } from 'rxjs/operators';
import { LoadingService } from '../shared/loading.service';

@Component({
  templateUrl: './titleIIIDashboardstates-and-aaa.component.html'
})
export class TitleIIIDashboardstatesAndAaaComponent extends DestructibleComponent implements OnInit, OnDestroy {
  public readonly overviewPath = '/data-submissions/titleIIIOverview';
  public readonly aaaOverviewPath = '/data-submissions/aaaDataSubmissions';

  // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;
  public rollup: Rollup;
  lockTimeout = 10*60;

  availableGrantees: ITitleOrg[] = [];
  selectedGrantees: ITitleOrg[] = [];
  originalGrantees: ITitleOrg[] = []; // used to check for changes
  disableSave: boolean = true;

  _sectionModel: SubmissionResponsibility = new SubmissionResponsibility('', '');
    public init$: Observable<boolean>;
    public isLocked = true;
    private dsr:string = "DataSubmissionResponsibility";
    private modified = false;
    canGenerateRollup = false;

  constructor(private service: TitleIIIDataEntryService,
    private claimService: ClaimService,
    private toastrService: ToastrService,
    private router: Router,
    private _loadingService: LoadingService) {
      super();
      this.rollup = new Rollup('', '', '', '', RollupStatus.NotStarted, false);   /* Init */
  }

  ngOnInit() {
    this.init();
  }

  init(callback: () => void = () => null) {
    super.registerSubscription(this.claimService.init$.subscribe(isInit => {
      if (isInit) {
        super.registerSubscription(
          this.service.get<Rollup>(this.claimService, this.service._rollupStatusEndpoint, null)
          .pipe(
            finalize(() => this.pollStateProfileState())
          )
          .subscribe(data => { if (data) { this.rollup = data; callback(); } }));
        this.clearAAADataForState();
        this.getAvailableGrantees();
      }
    }));

    this._sectionModel = new SubmissionResponsibility(this.claimService.currentYear, this.claimService.currentOrg);
  }

  pollStateProfileState(completedCallback: () => void = () => null): void {
    super.registerSubscription(
        interval(3000).pipe(
            takeWhile(val => val < 60 && this.isLocked), // Wait for 3 minutes or unlocked
            exhaustMap(() => this.getStateProfile$()),
            finalize(() => {
                this.modified = false;
                this._loadingService.setLoading(false, this.dsr);
                if (!this.isLocked) {
                    completedCallback();
                };
            })
        ).subscribe((profile: StateProfile) => {
            this._sectionModel = this.formatData(profile);
            this.isLocked = profile.status !== StateProfileStatus.ReadyForDataEntry
                && profile.status !== StateProfileStatus.InProgress;
            this.canGenerateRollup = this._sectionModel.status == StateProfileStatus.ReadyForDataEntry
              && this.rollup.canGenerateRollup;
        })
    );
}

getStateProfile$(): Observable<StateProfile> {
  return this.service.getStateProfile(this.claimService.currentYear, this.claimService.currentOrg);
}

formatData(profile: StateProfile) {
  const data = profile.dataGroups;
  if (data != null) {
      data.forEach(element => {
          switch (element.dataGroup) {
              case (DataGroupEnum.AgingNetwork):
                  this._sectionModel.agingNetworkResponsibility = element;
                  break;
              case (DataGroupEnum.ServiceExpenditures):
                  this._sectionModel.expenditureResponsibility = element;
                  break;
              case (DataGroupEnum.ServiceUnits):
                  this._sectionModel.serviceUnitsResponsibility = element;
                  break;
              case (DataGroupEnum.LegalAssistance):
                  this._sectionModel.legalAssistanceResponsibility = element;
                  break;
              case (DataGroupEnum.CaregiverSupplementalServices):
                  this._sectionModel.caregiverSupplementalServices = element;
                  break;
          }
      });
      this._sectionModel.state = profile.state;
      this._sectionModel.fiscalYear = profile.fiscalYear;
      this._sectionModel.status = profile.status;
      this._sectionModel.id = profile.id;
  }

  return this._sectionModel;
}
  selectedChange(selected: ITitleOrg[]): void {
    this.disableSave = !(selected.length > 0);
  }

  getAvailableGrantees(): void {
    // get available grantees (left side)
    super.registerSubscription(this.service.getAAAOverviewByFfyAndState(this.claimService.currentYear, this.claimService.currentOrg)
    .subscribe(data => {
        this.availableGrantees = data.map<ITitleOrg>(x => ({title: 'III', org: x.psa, region: 'AAA', displayName: x.psaName, state: x.state}));
        this.availableGrantees.push({title: 'III', org: this.claimService.currentOrg, region: 'State', displayName: this.claimService.currentOrg, state: this.claimService.currentOrg})
    },
        err => {}));

  }
  ngOnDestroy(): void {
    super.destroy();
  }

  public uploadStateRollup(){
    this.claimService.isRollupOverview = true;
    this.router.navigate(['/data-submissions/titleIIIManageUploads']);
  }

  public generateStateRollup() {
    let rollupSource: IRollupSource = { fiscalYear: this.claimService.currentYear, org: this.claimService._currentOrg, sources: this.selectedGrantees };
    super.registerSubscription(this.service.doRollup(rollupSource)
      .subscribe(resp => this.handleRollupResponse(resp), null, null
      ));
  }

  public showRollupOptions() {
    jQuery('#rollupModal').modal('show');
  }

  public navActionState() {
    this.resetOverviewOptions();
    this.router.navigate([this.overviewPath]);
  }

  public navActionAAA() {
    this.resetOverviewOptions();
    this.router.navigate([this.aaaOverviewPath]);
  }

  public navActionRollup() {
    this.resetOverviewOptions();
    this.claimService.isRollupOverview = true;
    this.router.navigate([this.overviewPath]);
  }

  private resetOverviewOptions() {
    this.resetViewAAA();
    this.resetViewRollup();
  }

  private resetViewAAA() {
    this.claimService.isViewOnly = false;
    this.claimService.currentSubOrg = null;
  }

  private resetViewRollup() {
    this.claimService.isRollupOverview = false;
  }

  private getSuccessMessage() {
    return `${RollupStatus.getFriendlyName(this.rollup.status)} Rollup was created!`;
  }

  clearAAADataForState() {
    this.claimService.resetAAAProperties();
  }

  private pollBatchOperationStatus(id: string): void {
    super.registerSubscription(
        this.service
            .pollLock(id, () => {

              this.init(() => this.toastrService.success(this.getSuccessMessage()));
            }).subscribe()
    );
}

private handleRollupResponse(model: { id?: string, error?: string }) {
    if (model.error) {
        this.toastrService.error(model.error);

    } else {
        this.pollBatchOperationStatus(model.id);
    }
}

}
