import { Injectable, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Input } from '@angular/core';

import { from, Observable, Observer, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { TitleIIIState } from '../DataSubmissions/titleIIIState';
import { LookupService } from '../lookup.service';
import { FfyStateDto } from './genericModel';
import { AclTitleEnum, FiscalYear } from '../app.constants';
import { TVIIStatuses } from './tviiStatuses';
import { TIIIStatuses } from './tiiiStatuses';
import { ITitleUser, ITitleOrg, DataAccessEnum } from '../UserManagement/userManagerObjects';
import { UserService } from '../UserManagement/user.service';
import { GranteeService } from '../TitleVI/granteeManagement/grantee.service';
import { CacheService } from './cache-service';
import { OktaService } from '../auth/service/okta.service';
import { AuthStates } from '../auth/authStates';

export type Title = '' | 'III' | 'VI' | 'VII';

@Directive()
@Injectable()
export class ClaimService {
    public _getRole = '/api/v1/userrole';
    public _getReportingYear = '/api/v1/titlereportingyear';

    // Claim service objects
    public roles: Array<string> = [];
    public init$: Observable<boolean>;
    private _init: boolean = false;

    /**
     * The user's authentication status
     */
    private _authenticated = false;
    public get authenticated(): boolean {
        return this._authenticated;
    }

    /**
     * The user's id
     */
    private _userID = null;
    public get userID(): string {
        return this._userID
    }

    /**
     * The current reporting year
     */
    public currentYear: string = FiscalYear().toString();

    /**
     * The current user
     */
    public user: ITitleUser;
    /**
     * Is the current user deactivated
     */
    public isDeactivated = false;

    //////////////////////////////////////////////////////////////////
    // Current title claims
    //////////////////////////////////////////////////////////////////

    /**
     * The current orgLevel
     */
    public currentOrgLevel: string = '';
    /**
     * The current title
     */

    public _currentTitle: Title = '';
    public get currentTitle(): Title {
        return this._currentTitle;
    }
    public set currentTitle(title: Title) {
        this._currentTitle = title;
        localStorage.setItem('title', title);
    }
    /**
     * The current org
     */
    public _currentOrg: string;
    public get currentOrg(): string {
        return this.resolveNull(this._currentOrg);
    }
    public set currentOrg(org: string) {
        this._currentOrg = org;
        localStorage.setItem('org', org);
    }
    /**
     * The current subOrg
     */
    public _currentSubOrg: string = null;
    public get currentSubOrg(): string {
        return this.resolveNull(this._currentSubOrg);
    }
    public set currentSubOrg(subOrg: string) {
        this._currentSubOrg = subOrg;
        localStorage.setItem('subOrg', subOrg);
    }
    /**
     * The current titleOrg
     */
    public _currentTitleOrg: ITitleOrg;
    public get currentTitleOrg(): ITitleOrg {
        return this._currentTitleOrg;
    }
    public set currentTitleOrg(titleOrg: ITitleOrg) {
        this._currentTitleOrg = titleOrg;
        localStorage.setItem('titleOrg', JSON.stringify(titleOrg));
    }

    public isAAAStateUserOnAAARecord(){
        return this.currentSubOrg != null
            && this.isAAAState
            && this.isAAAEnabledStateUser()
    }

    /**
     * Title selected from resources module (unauthenticated user)
     */
    public resourceTitle: string = '';
    /**
     * Set with respect to current title/org
     */
    public currentRegion: string = '';

    //////////////////////////////////////////////////////////////////
    // Role flags
    //////////////////////////////////////////////////////////////////

    /**
     * Does the logged in user have the ViewOnly role
     */
    public isViewOnly: boolean = false;
    /**
     * Does the logged in user have the DataEntry role
     */
    public isDataEntry: boolean = false;
    /**
     * Is the logged in user able to view reports
     */
    public isNavReports: boolean = false;
    /**
     * Is the logged in user an admin
     */
    public isNavAdmin: boolean = false;
    /**
     * Is the logged in user an approver or an ACL user
     */
    public isNavApprover: boolean = false;
    /**
     * Is the logged in user an approver
     */
    public isApprover: boolean = false;
    /**
     * Is the logged in user a locker
     */
    public isNavLocker: boolean = false;
    /**
     * Is the logged in user a GrantManager
     */
    public isGrantManager: boolean = false;
    /**
     * Is the logged in user a Submitter
     */
    public isSubmitter: boolean = false;
    /**
     * Is the logged in user a Submitter
     */
    public noRoles: boolean = false;


    // Title specific
    public isNavStateSubmission: boolean;
    public isGrantee: boolean;
    public isAAAEnabledState: boolean = false;
    public isAAAState: boolean = false;
    public isNonAAAState: boolean = false;
    public isAAAUser: boolean = false;
    public aaaName: string = '';
    public titleIIIStateOfficeName: string = '';
    public legacyDataCutoffYear: number;
    public ffyStateDto: FfyStateDto = new FfyStateDto();
    // TODO ASAP this needs refactor bad, it should be set in this component not overview components,
    // what happens when pages hard refresh. Potentially many bugs could be caused by this pattern
    // UPDATE: Has been partially refactored but dependent code needs to be audited to ensure correct onRefresh
    public statusesLoaded = false;
    // Title VII statuses
    public _tviiStatuses: TVIIStatuses = new TVIIStatuses();
    public set tviiStatuses(statuses: TVIIStatuses) {
        this.statusesLoaded = true;
        this._tviiStatuses = statuses;
    }
    public get tviiStatuses() {
        return this._tviiStatuses;
    }
    // Title III statuses
    private _tiiiStatuses: TIIIStatuses = new TIIIStatuses();
    public set tiiiStatuses(statuses: TIIIStatuses) {
        this.statusesLoaded = true;
        this._tiiiStatuses = statuses;
    }
    public get tiiiStatuses() {
        return this._tiiiStatuses;
    }

    @Input() titleIIIState: TitleIIIState;


    constructor(public _http: HttpClient
        , public _oktaService: OktaService
        , public _userService: UserService
        , public _granteeService: GranteeService
        , public _cacheService: CacheService
        , public _lookupService: LookupService) {

        this.initializeService();
    }

    /**
     * Initialize claim service making necessary back-end calls and updating service state according to user roles.
     * Creates an observable init$ that can be awaited by dependent code relying on fully initialized service state
     *
     * Wait on this if you need service properties to be set at component initialization
     */
    private initializeService() {
        this.init$ = new Observable((observer) => {
            this._oktaService.stateChange.pipe(
                tap((state: AuthStates) => {
                    if (state === AuthStates.LOGIN_COMPLETE) {
                        this._authenticated = true;
                        this.initializeAuthenticatedUser(observer);
                    } else {
                        observer.next(false);
                    }
                }),
                catchError(() => {
                    observer.next(false);
                    return of(null);
                })
            ).subscribe();
        });
    }

    private initializeAuthenticatedUser(observer: Observer<boolean>) {
        // Needed for some user types
        const getReportingYear$ = this.getReportingYear();

        const getUser$ = from(this._oktaService.getUpnAsync())
            .pipe(
                switchMap(upn => {
                    this._userID = upn;
                    return this._userService.findByUpn(upn);
                })
            );

        getUser$.subscribe(results => {

            const loadingComplete = getReportingYear$.pipe(
                tap(() => {
                    this._init = true;
                    observer.next(true);
                })
            );

            this.user = results;
            this.validateSecurityStamp();
            this.isDeactivated = !this.user.isActive;
            this.setCurrentTitleOrg();
            this.rolesHandler(<string[]>results.titleRoles);

            if (this.currentOrgLevel.includes('State')) {
                this.ffyStateDto.state = this.currentOrg;
            }
            if (this.currentOrgLevel.includes('AAA')) {
                this.isAAAUser = true;
            }
            // Restore ACL admin selected state on refresh if necessary
            if (this.currentOrgLevel.includes('ACL')) {
                this.currentOrg = this.currentOrg || sessionStorage.getItem('aclSelectedState');
                this.resolveGranteeDisplayNamesACL();
                // TODO test above for race conditions
                loadingComplete.subscribe();
            }
            // Resolve Grantee display names if necessary
            else if (this.user && this.user.groupedRoles.find(x => x.orgLevel.includes('Grantee'))) {
                this.resolveGranteeDisplayNames();
                // TODO test above for race conditions
                loadingComplete.subscribe();
            }
            // Resolve Title III state and AAA details if necessary
            else if (this.isAAAUser || (this.currentTitle.includes('III') && this.currentOrgLevel.includes('State'))) {
                this.fetchTitleIIIStateDetails$().subscribe(data => {
                    this.loadTitleIIIStateDetails(data);
                    loadingComplete.subscribe();
                });
            }
            else {
                loadingComplete.subscribe();
            }
        }, error => {
            console.log(error);
            observer.next(false);
        });
    }

    public injectToken<T>(inner: (token) => Observable<T>): Observable<T> {
        return from(this._oktaService.getIdTokenAsync())
            .pipe(
                switchMap(inner)
            );
    }

    public injectUpn<T>(inner: (upn) => Observable<T>): Observable<T> {
        return from(this._oktaService.getUpnAsync())
            .pipe(
                switchMap(inner)
            )
    }

    /**
     *  Checks current security stamp against the last, if different
     *  clear the local storage data. This prevents stale cache data after
     *  the user's roles have been updated.
     */
    private validateSecurityStamp(): void {
        if (this.user && this.user.securityStamp) {
            if (localStorage.getItem('securityStamp') !== this.user.securityStamp) {
                this.clearLocalStorage();
            }
            localStorage.setItem('securityStamp', this.user.securityStamp);
        }
    }

    public clearLocalStorage(): void {
        localStorage.removeItem('securityStamp');
        localStorage.removeItem('title');
        localStorage.removeItem('org');
        localStorage.removeItem('subOrg');
        localStorage.removeItem('titleOrg');
        localStorage.removeItem('titleOrg');
    }

    /**
     * Sets the currentTitle, currentOrg, and currentTitleOrg properties
     * It looks first in local storage for the last selected values, then at the users
     * defaultTitleOrg setting, lastly selecting the first role as a fallback
     */
    setCurrentTitleOrg(): any {
        if (!this.user.isActive) {
            alert('Your account has been deactivated, please contact OAAPS support for access.');
            this._oktaService.logout();
        }
        else if (this.user.groupedRoles.length <= 0) {
            alert('No roles are assigned to your account, please contact OAAPS support for access.');
            this._oktaService.logout();
        }
        if (localStorage.getItem('title')) {
            this.currentTitle = localStorage.getItem('title') as Title;
        }
        if (localStorage.getItem('org')) {
            this.currentOrg = localStorage.getItem('org');
        }
        if (localStorage.getItem('titleOrg')) {
            this.currentTitleOrg = JSON.parse(localStorage.getItem('titleOrg'));
        } else if (this.user) {
            const firstRole = this.user.groupedRoles[0];
            if (this.user.defaultTitleOrg) {
                this.currentTitleOrg = this.user.defaultTitleOrg;
            } else if (firstRole.orgLevel === 'Grantee') {
                this.currentTitleOrg = {
                    title: firstRole.title,
                    org: firstRole.org, displayName: `Grantee ${firstRole.org}`
                };
            } else {
                this.currentTitleOrg = { title: firstRole.title, org: firstRole.org, displayName: `Title ${firstRole.title}` };
            }
            this.currentTitle = this.currentTitleOrg.title as Title;
            this.currentOrg = firstRole.org;
        }
    }

    /**
     * Sets the roles property and roll flags for the current title org
     * @param result the roles returned by the api
     */
    public rolesHandler(result: string[]): void {
        if (!result.length) {
            alert('Assign your account to a group in order to proceed.');
        }
        this.roles = result;
        this.setCurrentRoleFlags();
    }

    /**
     * Gets the users role strings
     * @param upn The users upn
     */
    // TODO do we need this?
    public getRoles(upn: string): any {
        if (!this.roles) {
            const qs = 'upn=' + upn;
            const options = { search: qs, withCredentials: true };
            return this._http.get<string[]>(this._getRole, options).pipe(
                tap(res => this.rolesHandler(res)),
                catchError(this.handleError<string[]>())
            );
        }
    }

    /**
     * Gets the reporting year from sessions storage if selected otherwise
     *  queries the API for the current reporting period
     */
    public getReportingYear(): Observable<string> {
        if (sessionStorage.getItem('fiscalYear')) {
            this.currentYear = sessionStorage.getItem('fiscalYear');
            this.ffyStateDto.ffy = this.currentYear;
            return of(this.currentYear);
        } else {
            return this._http.get<string>(this._getReportingYear).pipe(
                tap(res => {
                    this.currentYear = res[this.currentTitle];
                    this.ffyStateDto.ffy = this.currentYear;
                    localStorage.setItem('reportingYears', JSON.stringify(res));
                    sessionStorage.setItem('fiscalYear', this.currentYear);
                }),
                catchError(this.handleError<string>())
            );
        }
    }

    /**
     * Set flags for the users roles based on current titleOrg
     */
    public setCurrentRoleFlags(): void {
        if (this.user.groupedRoles) {
            const currentRole = this.user.groupedRoles
                .filter(x => x.title === this.currentTitleOrg.title
                    && (x.org === this.currentTitleOrg.org || !this.currentTitleOrg.org))[0];
            // Current role flags
            if (currentRole) {
                this.isNavApprover = currentRole.isDataReviewApprove || currentRole.orgLevel.includes('ACL');
                this.isApprover = currentRole.isDataReviewApprove;
                this.isNavLocker = currentRole.isDataReviewLock;
                this.isViewOnly = currentRole.dataAccess === DataAccessEnum.ViewOnly;
                this.isDataEntry = currentRole.dataAccess === DataAccessEnum.DataEntry;
                this.isGrantManager = currentRole.isGrantManager;
                this.isSubmitter = currentRole.isDataReviewSubmit;
                this.currentRegion = this.user.region !== 'N/A' ? this.user.region : currentRole.region.toString();
                this.isNavAdmin = this.isNavReports = (this.user.groupedRoles.filter(
                    x => x.title === this.currentTitleOrg.title && x.isAdmin)).length > 0 ? true : false;
                this.isNavStateSubmission = currentRole.orgLevel === 'State';
                this.isGrantee = currentRole.orgLevel === 'Grantee';
                this.currentOrgLevel = currentRole.orgLevel;
                if (this.isAAAUser) {
                    this.currentSubOrg = currentRole.subOrg !== 'null' ? currentRole.subOrg : null;
                }
            }
            else {
                this.noRoles = true;
            }
        }
    }

    /**
     * Returns true if the user has any ACLCO or ACLRO role
     */
    public isACLUser(): boolean {
        const filtered = this.roles.filter(item => item.match(new RegExp('^(ACLCO|ACLRO)-[IVXLCDM]{2,}.*$')));
        return filtered && filtered.length > 0;
    }

    /**
     * Returns true if the user has admin status for the selected title
     * @param title the title to check admin status for
     */
    public isAdmin(title: string): boolean {
        const titleRole = this.user.groupedRoles.find(role => role.title === title && role.isAdmin);
        return titleRole !== null && titleRole !== undefined;
    }

    /**
     * Returns true if the user has state DataEntry or ViewOnly roles
     * @param title the title to check role access for
     */
    public hasStateTitleAccess(title: string): boolean {
        const filtered = this.roles.filter(item => item.match(new RegExp('^State-' + title + '-(DataEntry|ViewOnly).*$')));
        return filtered && filtered.length > 0;
    }

    /**
     * Returns true if the user has the specified title access
     * @param title the title to check role access for
     */
    public hasTitleAccess(title: string): boolean {
        const filtered = this.roles.filter(item => item.match(new RegExp('^(ACLCO|ACLRO|State|AAA|Grantee)-' + title + '-.*$')));
        return filtered && filtered.length > 0;
    }

    /**
     * Resolves current title string to enum
     */
    public get title(): AclTitleEnum {
        switch (this.currentTitle) {
            case ('III'):
                return AclTitleEnum.III;
            case ('VI'):
                return AclTitleEnum.VI;
            case ('VII'):
                return AclTitleEnum.VII;
        }
    }

    /**
     * Returns a list of ITitleOrg (available options for title dropdown)
     */
    public get titleOrgs(): ITitleOrg[] {
        const titleOrgMap = {};
        if (this.user) {
            this.user.groupedRoles.forEach((val) => {
                // Granular down to the org for TVI grantees
                if (val.orgLevel === 'Grantee') {
                    let displayName = this._cacheService.getCacheItem(`${this._cacheService.cacheGDNPre}${val.org}`)
                        || val.org;
                    if (displayName.length > 90) {
                        displayName = `${displayName.substring(0, 90)}...`;
                    }
                    titleOrgMap[`${val.title}.${val.org}`] = {
                        title: val.title,
                        org: val.org,
                        displayName: `Grantee ${displayName}`,
                    };
                } else {
                    titleOrgMap[`${val.title}`] = { title: val.title, org: val.org, displayName: `Title ${val.title}` };
                }
            });
        }
        return Object.keys(titleOrgMap).map((key) => titleOrgMap[key]);
    }

    /**
     * Sets the FFyStateDto object
     */
    getFFyStateDto(psaOverride: string = null, rollupOverride: boolean = null): any {
        this.ffyStateDto.ffy = this.currentYear;
        this.ffyStateDto.state = this.currentOrg;
        this.ffyStateDto.psa = psaOverride || this.currentSubOrg;
        this.ffyStateDto.isRollup = rollupOverride || this.isRollupOverview;
        return this.ffyStateDto;
    }

    //////////////////////////////////////////////////////////////////
    // Title III specific
    //////////////////////////////////////////////////////////////////

    /**
     * Should display rollup data
     */
    public get isRollupOverview() {
        if (sessionStorage.getItem('isViewRollupOverview')) {
            return sessionStorage.getItem('isViewRollupOverview').toUpperCase() === 'TRUE';
        } else {
            return false;
        }
    }

    /**
     * Set rollup data display flag
     */
    public set isRollupOverview(isViewOverview: boolean) {
        sessionStorage.setItem('isViewRollupOverview', isViewOverview.toString());
    }

    /**
     * Fetch title III state for currentOrg
     */
    private fetchTitleIIIStateDetails$(): Observable<any> {
        return this._lookupService.getTitleIIIState(this.currentOrg);
    }

    /**
     * Looks up AAA name
     */
    public loadTitleIIIStateDetails(titleIIIState: TitleIIIState): void {
        this.titleIIIState = titleIIIState;
        this.titleIIIStateOfficeName = this.titleIIIState.name;
        this.isAAAState = titleIIIState.isAAAState;
        this.isAAAEnabledState = titleIIIState.isEnabled;
        this.isNonAAAState = !titleIIIState.isAAAState;
        this._cacheService.putCacheItems(
            this.titleIIIState.aaa.map(item => ({ key: this._cacheService.cacheAAAPre + item.psa, value: item.name })));
        this.titleIIIState.aaa.forEach(aaa => {
            if (aaa.psa === this.currentSubOrg) {
                this.aaaName = aaa.name;
                this.ffyStateDto.psa = this.currentSubOrg;
            }
        });
    }

    /**
     * Is the current org enabled for AAA
     */
    public isAAAEnabledStateUser() {
        if (this.currentTitle === 'III' && this.isAAAEnabledState && (this.isDataEntry || this.isNavApprover) && !this.isAAAUser) {
            return true;
        }
        return false;
    }

    /**
     * Resets AAA related properties and flags
     */
    resetAAAProperties() {
        this.currentSubOrg = null;
        this.aaaName = null;
        this.isViewOnly = false;
    }

    //////////////////////////////////////////////////////////////////
    // Title VI specific
    //////////////////////////////////////////////////////////////////

    /**
     * Resolves Grantee orgIds to display names and stuffs them into the memory (runtime) cache
     * Used for ACL user pulls all Grantee names
     */
    resolveGranteeDisplayNamesACL() {
        this._granteeService.getGranteeDisplayItems(null)
            .subscribe(data => {
                if (data) {
                    this._cacheService.putCacheItems(data.map(item => ({
                        key: `${this._cacheService.cacheGDNPre}${item.org}`,
                        value: item.displayName,
                    })));
                }
            });
    }

    /**
     * Resolves Grantee orgIds to display names and stuffs them into the memory (runtime) cache
     * Used for Grantee user only
     */
    resolveGranteeDisplayNames(): any {
        this._granteeService.getGranteeDisplayItems(this.user.groupedRoles
            .filter(x => x.orgLevel === 'Grantee')
            .map(x => x.org))
            .subscribe(data => {
                if (data) {
                    this._cacheService.putCacheItems(data.map(item => ({
                        key: `${this._cacheService.cacheGDNPre}${item.org}`,
                        value: item.displayName,
                    })));
                    // Update current org if needed
                    if (this.currentTitleOrg && this.currentTitleOrg.title === 'VI' && this.currentTitleOrg.org) {
                        const currentOrg = this.currentTitleOrg.org;
                        let displayName = this._cacheService.getCacheItem(`${this._cacheService.cacheGDNPre}${currentOrg}`) || currentOrg;
                        this.currentTitleOrg.fullName = displayName;
                        if (displayName.length > 20) {
                            displayName = `${displayName.substring(0, 20)}...`;
                        }
                        this.currentTitleOrg.displayName = displayName;
                    }
                }
            });
    }

    // Helpers

    public distinctArray(arr: Array<string>): Array<string> {
        const n: Array<string> = [];
        for (let i = 0; i < arr.length; i++) {
            if (n.indexOf(arr[i]) === -1) { n.push(arr[i]); }
        }
        return n.sort();
    }

    private resolveNull(s: string): string {
        if (s == null || s.toLowerCase() === 'null') {
            return null;
        }
        return s;
    }

    // Global
    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
