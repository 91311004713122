////////////////////////////////////
// Ng libraries
////////////////////////////////////
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

////////////////////////////////////
// App TIII Data Entry
////////////////////////////////////
import { TitleIIIANStaffAndVolunteersComponent } from './titleIIIANStaffAndVolunteers.component';
import { TitleIIIANServiceProvidersComponent } from './titleIIIANServiceProviders.component';
import { TitleIIIANSeniorCentersComponent } from './titleIIIANSeniorCenters.component';
import { TitleIIIANSelfDirectionComponent } from './titleIIIANSelfDirection.component';
import { TitleIIIANRespiteComponent } from './titleIIIANRespite.component';
import { TitleIIIOAConsumerTpsComponent } from './titleIIIOAConsumerTps.component';
import { TitleIIIHomeDeliveredMealsCharsComponent } from './titleIIIHomeDeliveredMealsChars.component';
import { TitleIIIHomeDeliveredMealsUnitsComponent } from './titleIIIHomeDeliveredMealsUnits.component';
import { TitleIIIAssistedTransportationCharsComponent } from './assistedTransportationChars.component';
import { TitleIIIAssistedTransportationUnitsComponent } from './assistedTransportationUnits.component';
import { TitleIIICongregateMealsUnitsComponent } from './titleIIICongregateMealsUnits.component';
import { TitleIIINutritionCounselingUnitsComponent } from './titleIIINutritionCounselingUnits.component';
import { TitleIIIOALegalCasesComponent } from './titleIIIOALegalCases.component';
import { TitleIIIServicesProvidedComponent } from './titleIIIServicesProvided.component';
import { TitleIIIInfoAndAssistanceComponent } from './infoAndAssistance.component';
import { TitleIIIHealthPromoEvidenceComponent } from './titleIIIHealthPromoEvidence.component';
import { TitleIIIHealthPromoNonEvidenceComponent } from './titleIIIHealthPromoNonEvidence.component';
import { TitleIIICOAConsumerSummaryComponent } from './COAConsumerSummary.component';
import { TitleIIIORCConsumerSummaryComponent } from './ORCConsumerSummary.component';
import { TitleIIICaregiverPagesComponent } from './titleIIICaregiverPages.component';
import { TitleIIICGRespiteComponent } from './titleIIICGRespite.component';
import { TitleIIICGSuppExpendComponent } from './titleIIICGSuppExpend.component';
import { TitleVIIExpendituresComponent } from './titleVIIExpenditures.component';
import { TitleIIInsipComponent } from './titleIIInsip.component';
import { TitleIIIANNetworkFundingComponent } from './titleIIIANNetworkFunding.component';
import { TitleIIIOAExpendituresComponent } from './titleIIIOAExpenditures.component';
import { TitleIIICOAExpendituresComponent } from './titleIIICOAExpenditures.component';
import { TitleIIIORCExpendituresComponent } from './titleIIIORCExpenditures.component';
import { TitleIIIFourColNoNutritionCharsComponent } from './titleIIIFourColNoNutritionChars.component';
import { TitleIIITwoColCharsComponent } from './titleIIITwoColChars.component';
import { TitleIIIMultipleUnitsComponent } from './titleIIIMultipleUnits.component';
import { TitleIIIOverviewComponent } from './titleIIIOverview.component';
import { TitleIIIDataSubmissionsComponent } from './titleIIIDataSubmissions.component';
import { TitleIIIUserDefaultStatesComponent } from './titleIIIUserDefaultStates.component';
import { TitleIIIVarianceExplanationsCGComponent } from './titleIIIVarianceExplanationsCG.component';
import { TitleIIIVarianceExplanationsNSIPComponent } from './titleIIIVarianceExplanationsNSIP.component';
import { TitleIIIVarianceExplanationsOAComponent } from './titleIIIVarianceExplanationsOA.component';
import { TitleIIIVarianceExplanationsORComponent } from './titleIIIVarianceExplanationsOR.component';
import { TitleIIIStatusLogSrpComponent } from './titleIIIstatuslogsrp.component';
import { DataSubmissionResponsibilityComponent } from './Profile/dataSubmissionResponsibility.component';
import { TitleIIIAAADataSubmissionsComponent } from './titleIIIAAADataSubmissions.component';
import { TitleIIICOAHomeComponent } from './titleIIICOAHome.component';
import { TitleIIISSReviewComponent } from './titleIIISSReview.component';
import { ManageUploadsComponent } from '../shared/Upload/manageUploads.component';
import { UploadFileComponent } from '../shared/Upload/uploadFile.component';
import { PendingChangesGuard } from '../shared/pending-changes.guard';
import { TitleIIIDashboardstatesAndAaaComponent } from './titleIIIDashboardstates-and-aaa.component';
import { TitleIIIORCHomeComponent } from './titleIIIORCHome.component';
import { ManageAttachmentsComponent } from '../shared/Attachment/manageAttachments.component';
import { AttachFileComponent } from '../shared/Attachment/attachFile.component';
import { AclManageAttachmentsComponent } from '../shared/Attachment/aclManageAttachments.component';
import { TitleRoute } from '../shared/Model/titleRoute';
import { RouteLookup } from '../shared/Routing/routeLookup';
import { TitleIIINutritionEducationComponent } from './TitleIIINutritionEducation.component';
import { TitleIIIOtherExpendComponent } from './titleIIIOtherExpend.component';
import { TitleIIIOtherServicesComponent } from './titleIIIOtherServices.component';

export const titleIIIRoutes: TitleRoute[] = [
    { path: 'titleIIIDataSubmissions', sectionName: 'titleIIIDataSubmissions', component: TitleIIIDataSubmissionsComponent },
    { path: 'titleIIISSReview', sectionName: 'titleIIISSReview', component: TitleIIISSReviewComponent },
    { path: 'titleIIIUserDefaultStates', sectionName: 'titleIIIUserDefaultStates', component: TitleIIIUserDefaultStatesComponent },
    {
        path: 'titleIIIANNetworkFunding', sectionName: 'titleIIIANNetworkFunding', component: TitleIIIANNetworkFundingComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIANStaffAndVolunteers', sectionName: 'titleIIIANStaffAndVolunteers', component: TitleIIIANStaffAndVolunteersComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIANServiceProviders', sectionName: 'titleIIIANServiceProviders', component: TitleIIIANServiceProvidersComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIANSeniorCenters', sectionName: 'titleIIIANSeniorCenters', component: TitleIIIANSeniorCentersComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIANSelfDirection', sectionName: 'titleIIIANSelfDirection', component: TitleIIIANSelfDirectionComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIANRespite', sectionName: 'titleIIIANRespite', component: TitleIIIANRespiteComponent,
        canDeactivate: [PendingChangesGuard]
    },
    { path: 'titleIIIORCHome', sectionName: 'titleIIIORCHome', component: TitleIIIORCHomeComponent },
    { path: 'titleIIICOAHome', sectionName: 'titleIIICOAHome', component: TitleIIICOAHomeComponent },
    {
        path: 'OAConsumerTps', sectionName: 'OAConsumerTps', component: TitleIIIOAConsumerTpsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'COAConsumerTps', sectionName: 'COAConsumerTps', component: TitleIIIOAConsumerTpsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'ORCConsumerTps', sectionName: 'ORCConsumerTps', component: TitleIIIOAConsumerTpsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'OAConsumerBoth', sectionName: 'OAConsumerBoth', component: TitleIIITwoColCharsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'OAConsumerOne', sectionName: 'OAConsumerOne', component: TitleIIIFourColNoNutritionCharsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIPersonalCareChars', sectionName: 'titleIIIPersonalCareChars', component: TitleIIIFourColNoNutritionCharsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIPersonalCareUnits', sectionName: 'titleIIIPersonalCareUnits', component: TitleIIIMultipleUnitsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIHomemakerChars', sectionName: 'titleIIIHomemakerChars', component: TitleIIIFourColNoNutritionCharsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIHomemakerUnits', sectionName: 'titleIIIHomemakerUnits', component: TitleIIIMultipleUnitsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIChoreChars', sectionName: 'titleIIIChoreChars', component: TitleIIIFourColNoNutritionCharsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIChoreUnits', sectionName: 'titleIIIChoreUnits', component: TitleIIIMultipleUnitsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIHomeDeliveredMealsChars', sectionName: 'titleIIIHomeDeliveredMealsChars',
        component: TitleIIIHomeDeliveredMealsCharsComponent, canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIHomeDeliveredMealsUnits', sectionName: 'titleIIIHomeDeliveredMealsUnits',
        component: TitleIIIHomeDeliveredMealsUnitsComponent, canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIAdultDayCareChars', sectionName: 'titleIIIAdultDayCareChars', component: TitleIIIFourColNoNutritionCharsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIAdultDayCareUnits', sectionName: 'titleIIIAdultDayCareUnits', component: TitleIIIMultipleUnitsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICaseMgmtChars', sectionName: 'titleIIICaseMgmtChars', component: TitleIIIFourColNoNutritionCharsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICaseMgmtUnits', sectionName: 'titleIIICaseMgmtUnits', component: TitleIIIMultipleUnitsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIAssistedTransportationChars', sectionName: 'titleIIIAssistedTransportationChars',
        component: TitleIIIAssistedTransportationCharsComponent, canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIAssistedTransportationUnits', sectionName: 'titleIIIAssistedTransportationUnits',
        component: TitleIIIAssistedTransportationUnitsComponent, canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICongregateMealsChars', sectionName: 'titleIIICongregateMealsChars', component: TitleIIITwoColCharsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICongregateMealsUnits', sectionName: 'titleIIICongregateMealsUnits', component: TitleIIICongregateMealsUnitsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIINutritionCounselingChars', sectionName: 'titleIIINutritionCounselingChars', component: TitleIIITwoColCharsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIINutritionCounselingUnits', sectionName: 'titleIIINutritionCounselingUnits',
        component: TitleIIINutritionCounselingUnitsComponent, canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIILegalChars', sectionName: 'titleIIILegalChars', component: TitleIIIAssistedTransportationCharsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIILegalUnits', sectionName: 'titleIIILegalUnits', component: TitleIIIAssistedTransportationUnitsComponent,
        canDeactivate: [PendingChangesGuard]
    },
    { path: 'OALegalCases', sectionName: 'OALegalCases', component: TitleIIIOALegalCasesComponent, canDeactivate: [PendingChangesGuard] },
    {
        path: 'titleIIIOtherServices', sectionName: 'titleIIIOtherServices', component: TitleIIIOtherServicesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICOASuppProvided', sectionName: 'titleIIICOASuppProvided', component: TitleIIIServicesProvidedComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIORCSuppProvided', sectionName: 'titleIIIORCSuppProvided', component: TitleIIIServicesProvidedComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIITransportation', sectionName: 'titleIIITransportation', component: TitleIIIInfoAndAssistanceComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIINutritionEducation', sectionName: 'titleIIINutritionEducation', component: TitleIIINutritionEducationComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIInfoAndAssistance', sectionName: 'titleIIIInfoAndAssistance', component: TitleIIIInfoAndAssistanceComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'healthPromoEvidence', sectionName: 'healthPromoEvidence', component: TitleIIIHealthPromoEvidenceComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'healthPromoNonEvidence', sectionName: 'healthPromoNonEvidence', component: TitleIIIHealthPromoNonEvidenceComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICOASuppExpend', sectionName: 'titleIIICOASuppExpend', component: TitleIIICGSuppExpendComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIORCSuppExpend', sectionName: 'titleIIIORCSuppExpend', component: TitleIIICGSuppExpendComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIOtherExpend', sectionName: 'titleIIIOtherExpend', component: TitleIIIOtherExpendComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICOAConsumerSummary', sectionName: 'titleIIICOAConsumerSummary', component: TitleIIICOAConsumerSummaryComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICOACounseling', sectionName: 'titleIIICOACounseling', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICOATraining', sectionName: 'titleIIICOATraining', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICOACaseMgmt', sectionName: 'titleIIICOACaseMgmt', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICOASupport', sectionName: 'titleIIICOASupport', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICOAInfoAndAssistance', sectionName: 'titleIIICOAInfoAndAssistance', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICOAInfoServices', sectionName: 'titleIIICOAInfoServices', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIORCCounseling', sectionName: 'titleIIIORCCounseling', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIORCTraining', sectionName: 'titleIIIORCTraining', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIORCCaseMgmt', sectionName: 'titleIIIORCCaseMgmt', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIORCSupport', sectionName: 'titleIIIORCSupport', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIORCInfoAndAssistance', sectionName: 'titleIIIORCInfoAndAssistance', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIORCInfoServices', sectionName: 'titleIIIORCInfoServices', component: TitleIIICaregiverPagesComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICOARespite', sectionName: 'titleIIICOARespite', component: TitleIIICGRespiteComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIORCRespite', sectionName: 'titleIIIORCRespite', component: TitleIIICGRespiteComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIORCConsumerSummary', sectionName: 'titleIIIORCConsumerSummary', component: TitleIIIORCConsumerSummaryComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIOAExpenditures', sectionName: 'titleIIIOAExpenditures', component: TitleIIIOAExpendituresComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIICOAExpenditures', sectionName: 'titleIIICOAExpenditures', component: TitleIIICOAExpendituresComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIORCExpenditures', sectionName: 'titleIIIORCExpenditures', component: TitleIIIORCExpendituresComponent,
        canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleVIIExpenditures', sectionName: 'titleVIIExpenditures', component: TitleVIIExpendituresComponent,
        canDeactivate: [PendingChangesGuard]
    },
    { path: 'titleIIInsip', sectionName: 'titleIIInsip', component: TitleIIInsipComponent, canDeactivate: [PendingChangesGuard] },
    {
        path: 'titleIIIVarianceExplanationsCG', sectionName: 'titleIIIVarianceExplanationsCG',
        component: TitleIIIVarianceExplanationsCGComponent, canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIVarianceExplanationsNSIP', sectionName: 'titleIIIVarianceExplanationsNSIP',
        component: TitleIIIVarianceExplanationsNSIPComponent, canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIVarianceExplanationsOA', sectionName: 'titleIIIVarianceExplanationsOA',
        component: TitleIIIVarianceExplanationsOAComponent, canDeactivate: [PendingChangesGuard]
    },
    {
        path: 'titleIIIVarianceExplanationsOR', sectionName: 'titleIIIVarianceExplanationsOR',
        component: TitleIIIVarianceExplanationsORComponent, canDeactivate: [PendingChangesGuard]
    },
    { path: 'titleIIIOverview', sectionName: 'titleIIIOverview', component: TitleIIIOverviewComponent },
    { path: 'titleIIIStatusLogSrpComponent', sectionName: 'titleIIIStatusLogSrpComponent', component: TitleIIIStatusLogSrpComponent },
    { path: '', redirectTo: 'titleIIIOverview', pathMatch: 'full' },
    { path: 'titleIIIManageUploads', sectionName: 'titleIIIManageUploads', component: ManageUploadsComponent },
    { path: 'uploadfile', sectionName: 'uploadfile', component: UploadFileComponent },
    { path: 'manageAttachments', component: ManageAttachmentsComponent },
    { path: 'attachFile', component: AttachFileComponent },
    { path: 'aclManageAttachments', component: AclManageAttachmentsComponent },
    { path: 'dataSubmissionResponsibility', sectionName: 'dataSubmissionResponsibility', component: DataSubmissionResponsibilityComponent,
        canDeactivate: [ PendingChangesGuard ] },
    { path: 'aaaDataSubmissions', sectionName: 'aaaDataSubmissions', component: TitleIIIAAADataSubmissionsComponent },
    { path: 'titleIIIDashboardAAAStates', sectionName: 'titleIIIDashboardAAAStates', component: TitleIIIDashboardstatesAndAaaComponent }
];

export const routeLookup: RouteLookup = new RouteLookup(titleIIIRoutes);
export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(<Routes>titleIIIRoutes);

