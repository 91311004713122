<div id="aclReturnCommentsModal" class="modal fade">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 id="return-comments-header" class="modal-title">Return comments</h2>
        </div>
        <div class="modal-body">
          <p>Returned on: {{model.created | amLocale:'en' | amDateFormat:'MM/DD/YYYY [at] h:mma'}}</p>
          <p class="mb-2">Returned by: {{model.createdBy}}</p>
          <p class="background-gray-lighter p-1">{{model.comments}}</p>
        </div>
        <div class="modal-footer">
          <button id="btnCloseReturnCommentsModal" type="button" class="btn btn-secondary btn-small" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>