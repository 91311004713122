import { IDataElement, DataElement, QuestionStatusEnum } from '../shared/dataElement';

export interface ISection {
    id: string;
    fiscalYear: string;
    state: string;
    granteeId: string;
    psa: string;
    type: string;
    sectionName: string;
    sectionStatus: QuestionStatusEnum;
    statusReason: string;
    errorCode: number;
    isVarianceChecked: boolean;
    isDeleted: boolean;
    checkBusinessRules: boolean;
    createdBy: string;
    updatedBy: string;
    created: string;
    lastModified: string;
    batchId: string;
    isRequired: boolean;
    childSection: Section[];
    subSection: IDataElement;
    isRollup: boolean;
    groupTitle: string;
    sectionTitle: string;
    sortOrder: number;

}

export class Section implements ISection {
    id: string;
    fiscalYear: string;
    state: string;
    granteeId: string;
    psa: string;
    type: string;
    sectionName: string;
    sectionStatus: QuestionStatusEnum = 0;
    statusReason: string;
    errorCode: number;
    isVarianceChecked: boolean;
    isDeleted: boolean;
    checkBusinessRules: boolean;
    createdBy: string;
    updatedBy: string;
    created: string;
    lastModified: string;
    batchId: string;
    isRequired: boolean = true;
    childSection: Section[] = [];
    subSection: DataElement = new DataElement();
    isRollup: boolean;
    groupTitle: string;
    sectionTitle: string;
    sortOrder: number;

    constructor() { }

}


// Title VII
export interface ITVIIOverview {
    state: string;
    ffy: string;
    overviewStatus: QuestionStatusEnum;
    region: string;
    lastUpdatedBy: string;
    LastUpdatedTime: string;
    sections: ISection[];
}

export class TVIIOverview implements ITVIIOverview {
    state: string;
    ffy: string;
    overviewStatus: QuestionStatusEnum;
    region: string;
    lastUpdatedBy: string;
    LastUpdatedTime: string;
    sections: ISection[] = [];
}

export enum TVIITopSectionTypeEnum {
    All,
    CaseAndComplaintSection,
    ComplaintExampleSection,
    SystemIssueSection,
    OrgStructureSection,
    StaffAndVolunteerSection,
    ConflictOfInterestSection,
    FundsExpendedSection,
    FacilitySection,
    ProgramActivitiesSection
}


// Title III
export interface ITIIIOverview {
    state: string;
    psa: string;
    isRollup: boolean;
    psaName: string;
    ffy: string;
    sprLastUpdatedBy: string;
    sprLastUpdatedTime: string;
    sprStatus: QuestionStatusEnum;
    sprMinStatus: QuestionStatusEnum;
    sprIsRequired: boolean;
    nsipLastUpdatedBy: string;
    nsipLastUpdatedTime: string;
    nsipStatus: QuestionStatusEnum;
    nsipIsRequired: boolean;
    region: string;
    sections: ISection[];
}

export class TIIIOverview implements ITIIIOverview {

    public static readonly ANSectionServiceName = 'ANSection';
    public static readonly OASectionServiceName = 'OASection';
    public static readonly COASectionServiceName = 'COASection';
    public static readonly ORCSectionServiceName = 'ORCSection';
    public static readonly ExpendituresSectionServiceName = 'ExpendituresSection';
    public static readonly nsipSectionServiceName = 'nsipSection';

    public static readonly ANSectionServiceDescription = 'Aging Network';
    public static readonly OASectionServiceDescription = 'Older Adults';
    public static readonly COASectionServiceDescription = 'Caregivers of Older Adults';
    public static readonly ORCSectionServiceDescription = 'Older Relative Caregivers';
    public static readonly ExpendituresSectionServiceDescription = 'Expenditures Overview';
    public static readonly nsipSectionServiceDescription = 'NSIP';

    state: string;
    psa: string;
    isRollup: boolean;
    psaName: string;
    ffy: string;
    region: string;
    sprLastUpdatedBy: string;
    sprLastUpdatedTime: string;
    sprStatus: QuestionStatusEnum;
    sprMinStatus: QuestionStatusEnum;
    sprIsRequired: boolean;
    nsipLastUpdatedBy: string;
    nsipLastUpdatedTime: string;
    nsipStatus: QuestionStatusEnum;
    nsipIsRequired: boolean;
    sections: ISection[] = [];
}

export enum TIIITopSectionTypeEnum {
    All,
    ANSection,
    OASection,
    COASection,
    ORCSection,
    ExpendituresSection,
    nsipSection,
    Spr
}
