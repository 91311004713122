import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { LoadingComponent } from '../shared/loading.component';
import { TitleIIIDataEntryService } from '../DataSubmissions/titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { UserDefaultStates } from '../shared/userDefaultStates';
import { ToastrService } from '../shared/toastr.service';
import { ITitleState } from '../shared/iTitleState';
import { LookupService } from '../lookup.service';
import { ITitleStateSelection } from '../shared/StateSelect/state-select.component';
import { DestructibleComponent } from '../destructible.component';

@Component({
    templateUrl: 'titleIIIUserDefaultStates.component.html',
})

export class TitleIIIUserDefaultStatesComponent extends DestructibleComponent implements OnInit, OnDestroy {

    _sectionModel: UserDefaultStates;
    titleStates: Array<ITitleState> = [];
    selectedStates: Array<ITitleState>;
    radioSelection: string;
    reportTypeDropdownSelection: string = '';
    // @ViewChild('loadingSpinner', { static: true }) loadingComponent: LoadingComponent;

    constructor(private _titleService: Title,
        private _service: TitleIIIDataEntryService,
        private _claimService: ClaimService,
        private _lookupService: LookupService,
        private toastr: ToastrService
    ) { super(); }

    ngOnInit(): void {
        // this.loadingComponent.setLoadingInProgress(30);
        this._titleService.setTitle('Default States - Profile - ACL OAAPS');
        this._sectionModel = new UserDefaultStates();
        // this.loadingComponent.setLoadingInProgress(25);
        const titleStates$ = this._lookupService.getTitleStates(this._claimService.currentTitle);
        const defaultStatePrefs$ = this._service.getUserDefaultStates();
        super.registerSubscription(forkJoin(titleStates$, defaultStatePrefs$)
            .subscribe(results => {
                this.titleStates = results[0];
                if (results[1].length > 0) {
                    const statePrefs = results[1][0];
                    this._sectionModel.id = statePrefs.id;
                    this._sectionModel.upn = statePrefs.upn;
                    this._sectionModel.defaultStates = statePrefs.defaultStates;
                    this.loadUsersDefaultSelections();
                }
                
            }));
    }

    ngOnDestroy(): void {
        super.destroy();
    }


    setSelectedStates(states: Array<ITitleState>) {
        this.selectedStates = states;
    }

    save(): void {
        // this.loadingComponent.setLoadingInProgress(30);
        this.addSelectedStates();
        super.registerSubscription(this._service.saveUserDefaultStates(this._sectionModel).subscribe(data => {
            
        },
            (error) => this.toastr.error('Unable to save selections at this time.'),
            () => this.toastr.success('Saved!')));
    }

    addSelectedStates(): void {
        this._sectionModel.defaultStates = [];
        switch (this.radioSelection) {
            case ('national'): {
                this._sectionModel.defaultStates.push('national');
                break;
            }
            case ('state'): {
                this._sectionModel.defaultStates = this.selectedStates.map(s => s.state);
                break;
            }
        }
    }

    loadUsersDefaultSelections(): void {
        // clear selections
        this.radioSelection = '';
        // national
        if (this._sectionModel.defaultStates.includes('national')) {
            this.radioSelection = 'national';
        }
        // state
        if (this._sectionModel.defaultStates.length > 0 && !this.radioSelection) {
            this.radioSelection = 'state';
            this.titleStates.forEach(ts => {
                if (this._sectionModel.defaultStates.includes(ts.state)) {
                    (<ITitleStateSelection>ts).isSelected = true;
                }
            });
        }
    }
}
