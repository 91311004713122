import { IDataElement, DataElement } from '../shared/dataElement';
import { Section } from '../shared/section';


export class HomeDeliveredMealsCharsSection extends Section {

    // totals
    totalPersonsServed: IDataElement;

    // age
    totalBelow60: IDataElement;
    bpBelow60: IDataElement;
    adlBelow60: IDataElement;
    iadlBelow60: IDataElement;
    totalRange1: IDataElement;
    bpRange1: IDataElement;
    adlRange1: IDataElement;
    iadlRange1: IDataElement;
    totalRange2: IDataElement;
    bpRange2: IDataElement;
    adlRange2: IDataElement;
    iadlRange2: IDataElement;
    totalRange3: IDataElement;
    bpRange3: IDataElement;
    adlRange3: IDataElement;
    iadlRange3: IDataElement;
    totalRange4: IDataElement;
    bpRange4: IDataElement;
    adlRange4: IDataElement;
    iadlRange4: IDataElement;
    totalAgeMissing: IDataElement;
    bpAgeMissing: IDataElement;
    adlAgeMissing: IDataElement;
    iadlAgeMissing: IDataElement;
    allAgeTotal: IDataElement;
    bpAgeTotal: IDataElement;
    adlAgeTotal: IDataElement;
    iadlAgeTotal: IDataElement;

    // gender
    totalFemale: IDataElement;
    bpFemale: IDataElement;
    adlFemale: IDataElement;
    iadlFemale: IDataElement;
    totalMale: IDataElement;
    bpMale: IDataElement;
    adlMale: IDataElement;
    iadlMale: IDataElement;
    totalOther: IDataElement;
    bpOther: IDataElement;
    adlOther: IDataElement;
    iadlOther: IDataElement;
    totalGenderMissing: IDataElement;
    bpGenderMissing: IDataElement;
    adlGenderMissing: IDataElement;
    iadlGenderMissing: IDataElement;
    allGenderTotal: IDataElement;
    bpGenderTotal: IDataElement;
    adlGenderTotal: IDataElement;
    iadlGenderTotal: IDataElement;

    // geographic
    totalRural: IDataElement;
    bpRural: IDataElement;
    adlRural: IDataElement;
    iadlRural: IDataElement;
    totalNonRural: IDataElement;
    bpNonRural: IDataElement;
    adlNonRural: IDataElement;
    iadlNonRural: IDataElement;
    totalGeoMissing: IDataElement;
    bpGeoMissing: IDataElement;
    adlGeoMissing: IDataElement;
    iadlGeoMissing: IDataElement;
    allGeoTotal: IDataElement;
    bpGeoTotal: IDataElement;
    adlGeoTotal: IDataElement;
    iadlGeoTotal: IDataElement;
    // poverty
    totalBelowPoverty: IDataElement;
    adlBelowPoverty: IDataElement;
    iadlBelowPoverty: IDataElement;
    totalAbovePoverty: IDataElement;
    adlAbovePoverty: IDataElement;
    iadlAbovePoverty: IDataElement;
    totalMissingPoverty: IDataElement;
    adlMissingPoverty: IDataElement;
    iadlMissingPoverty: IDataElement;
    allPovertyTotal: IDataElement;
    adlPovertyTotal: IDataElement;
    iadlPovertyTotal: IDataElement;

    // household status
    totalAlone: IDataElement;
    bpAlone: IDataElement;
    adlAlone: IDataElement;
    iadlAlone: IDataElement;
    totalWithOthers: IDataElement;
    bpWithOthers: IDataElement;
    adlWithOthers: IDataElement;
    iadlWithOthers: IDataElement;
    totalHouseholdMissing: IDataElement;
    bpHouseholdMissing: IDataElement;
    adlHouseholdMissing: IDataElement;
    iadlHouseholdMissing: IDataElement;
    allHouseholdTotal: IDataElement;
    bpHouseholdTotal: IDataElement;
    adlHouseholdTotal: IDataElement;
    iadlHouseholdTotal: IDataElement;

    // ethnicity
    totalHispanic: IDataElement;
    bpHispanic: IDataElement;
    adlHispanic: IDataElement;
    iadlHispanic: IDataElement;
    totalNonHispanic: IDataElement;
    bpNonHispanic: IDataElement;
    adlNonHispanic: IDataElement;
    iadlNonHispanic: IDataElement;
    totalEthnicityMissing: IDataElement;
    bpEthnicityMissing: IDataElement;
    adlEthnicityMissing: IDataElement;
    iadlEthnicityMissing: IDataElement;
    allEthnicityTotal: IDataElement;
    bpEthnicityTotal: IDataElement;
    adlEthnicityTotal: IDataElement;
    iadlEthnicityTotal: IDataElement;

    // race
    totalAmericanIndian: IDataElement;
    bpAmericanIndian: IDataElement;
    adlAmericanIndian: IDataElement;
    iadlAmericanIndian: IDataElement;
    totalAsian: IDataElement;
    bpAsian: IDataElement;
    adlAsian: IDataElement;
    iadlAsian: IDataElement;
    totalBlack: IDataElement;
    bpBlack: IDataElement;
    adlBlack: IDataElement;
    iadlBlack: IDataElement;
    totalIslander: IDataElement;
    bpIslander: IDataElement;
    adlIslander: IDataElement;
    iadlIslander: IDataElement;
    totalWhite: IDataElement;
    bpWhite: IDataElement;
    adlWhite: IDataElement;
    iadlWhite: IDataElement;
    totalRaceOther: IDataElement;
    bpRaceOther: IDataElement;
    adlRaceOther: IDataElement;
    iadlRaceOther: IDataElement;
    totalRaceMultiple: IDataElement;
    bpRaceMultiple: IDataElement;
    adlRaceMultiple: IDataElement;
    iadlRaceMultiple: IDataElement;
    totalRaceMissing: IDataElement;
    bpRaceMissing: IDataElement;
    adlRaceMissing: IDataElement;
    iadlRaceMissing: IDataElement;

    // minority
    totalMinority: IDataElement;
    bpMinority: IDataElement;
    adlMinority: IDataElement;
    iadlMinority: IDataElement;
    totalNotMinority: IDataElement;
    bpNotMinority: IDataElement;
    adlNotMinority: IDataElement;
    iadlNotMinority: IDataElement;
    totalMinorityMissing: IDataElement;
    bpMinorityMissing: IDataElement;
    adlMinorityMissing: IDataElement;
    iadlMinorityMissing: IDataElement;
    allMinorityTotal: IDataElement;
    bpMinorityTotal: IDataElement;
    adlMinorityTotal: IDataElement;
    iadlMinorityTotal: IDataElement;

    // ADL Limitiations
    totalZeroLimitAdl: IDataElement;
    bpZeroAdl: IDataElement;
    totalTwoLimitAdl: IDataElement;
    bpTwoLimitAdl: IDataElement;
    totalHigherLimitAdl: IDataElement;
    bpHigherLimitAdl: IDataElement;
    totalLimitMissingAdl: IDataElement;
    bpLimitMissingAdl: IDataElement;
    allLimitTotalAdl: IDataElement;
    bpLimitTotalAdl: IDataElement;

    // IADL Limitations
    totalZeroLimitIadl: IDataElement;
    bpZeroIadl: IDataElement;
    totalTwoLimitIadl: IDataElement;
    bpTwoLimitIadl: IDataElement;
    totalHigherLimitIadl: IDataElement;
    bpHigherLimitIadl: IDataElement;
    totalLimitMissingIadl: IDataElement;
    bpLimitMissingIadl: IDataElement;
    allLimitTotalIadl: IDataElement;
    bpLimitTotalIadl: IDataElement;

    // Nutrition
    totalLowNutrition: IDataElement;
    bpLowNutrition: IDataElement;
    adlLowNutrition: IDataElement;
    iadlLowNutrition: IDataElement;
    totalHighNutrition: IDataElement;
    bpHighNutrition: IDataElement;
    adlHighNutrition: IDataElement;
    iadlHighNutrition: IDataElement;
    totalNutritionMissing: IDataElement;
    bpNutritionMissing: IDataElement;
    adlNutritionMissing: IDataElement;
    iadlNutritionMissing: IDataElement;
    allNutritionTotal: IDataElement;
    bpNutritionTotal: IDataElement;
    adlNutritionTotal: IDataElement;
    iadlNutritionTotal: IDataElement;




    constructor() {
        super();

        // totals
        this.totalPersonsServed = new DataElement();

        // age
        this.totalBelow60 = new DataElement();
        this.bpBelow60 = new DataElement();
        this.adlBelow60 = new DataElement();
        this.iadlBelow60 = new DataElement();
        this.totalRange1 = new DataElement();
        this.bpRange1 = new DataElement();
        this.adlRange1 = new DataElement();
        this.iadlRange1 = new DataElement();
        this.totalRange2 = new DataElement();
        this.bpRange2 = new DataElement();
        this.adlRange2 = new DataElement();
        this.iadlRange2 = new DataElement();
        this.totalRange3 = new DataElement();
        this.bpRange3 = new DataElement();
        this.adlRange3 = new DataElement();
        this.iadlRange3 = new DataElement();
        this.totalRange4 = new DataElement();
        this.bpRange4 = new DataElement();
        this.adlRange4 = new DataElement();
        this.iadlRange4 = new DataElement();
        this.totalAgeMissing = new DataElement();
        this.bpAgeMissing = new DataElement();
        this.adlAgeMissing = new DataElement();
        this.iadlAgeMissing = new DataElement();
        this.allAgeTotal = new DataElement();
        this.bpAgeTotal = new DataElement();
        this.adlAgeTotal = new DataElement();
        this.iadlAgeTotal = new DataElement();

        // gender
        this.totalFemale = new DataElement();
        this.bpFemale = new DataElement();
        this.adlFemale = new DataElement();
        this.iadlFemale = new DataElement();
        this.totalMale = new DataElement();
        this.bpMale = new DataElement();
        this.adlMale = new DataElement();
        this.iadlMale = new DataElement();
        this.totalOther = new DataElement();
        this.bpOther = new DataElement();
        this.adlOther = new DataElement();
        this.iadlOther = new DataElement();
        this.totalGenderMissing = new DataElement();
        this.bpGenderMissing = new DataElement();
        this.adlGenderMissing = new DataElement();
        this.iadlGenderMissing = new DataElement();
        this.allGenderTotal = new DataElement();
        this.bpGenderTotal = new DataElement();
        this.adlGenderTotal = new DataElement();
        this.iadlGenderTotal = new DataElement();

        // geographic
        this.totalRural = new DataElement();
        this.bpRural = new DataElement();
        this.adlRural = new DataElement();
        this.iadlRural = new DataElement();
        this.totalNonRural = new DataElement();
        this.bpNonRural = new DataElement();
        this.adlNonRural = new DataElement();
        this.iadlNonRural = new DataElement();
        this.totalGeoMissing = new DataElement();
        this.bpGeoMissing = new DataElement();
        this.adlGeoMissing = new DataElement();
        this.iadlGeoMissing = new DataElement();
        this.allGeoTotal = new DataElement();
        this.bpGeoTotal = new DataElement();
        this.adlGeoTotal = new DataElement();
        this.iadlGeoTotal = new DataElement();

        // poverty status
        this.totalBelowPoverty = new DataElement();
        this.adlBelowPoverty = new DataElement();
        this.iadlBelowPoverty = new DataElement();
        this.totalAbovePoverty = new DataElement();
        this.adlAbovePoverty = new DataElement();
        this.iadlAbovePoverty = new DataElement();
        this.totalMissingPoverty = new DataElement();
        this.adlMissingPoverty = new DataElement();
        this.iadlMissingPoverty = new DataElement();
        this.allPovertyTotal = new DataElement();
        this.adlPovertyTotal = new DataElement();
        this.iadlPovertyTotal = new DataElement();
        this.totalAlone = new DataElement();
        this.bpAlone = new DataElement();
        this.adlAlone = new DataElement();
        this.iadlAlone = new DataElement();
        this.totalWithOthers = new DataElement();
        this.bpWithOthers = new DataElement();
        this.adlWithOthers = new DataElement();
        this.iadlWithOthers = new DataElement();
        this.totalHouseholdMissing = new DataElement();
        this.bpHouseholdMissing = new DataElement();
        this.adlHouseholdMissing = new DataElement();
        this.iadlHouseholdMissing = new DataElement();
        this.allHouseholdTotal = new DataElement();
        this.bpHouseholdTotal = new DataElement();
        this.adlHouseholdTotal = new DataElement();
        this.iadlHouseholdTotal = new DataElement();

        // ethnicity
        this.totalHispanic = new DataElement();
        this.bpHispanic = new DataElement();
        this.adlHispanic = new DataElement();
        this.iadlHispanic = new DataElement();
        this.totalNonHispanic = new DataElement();
        this.bpNonHispanic = new DataElement();
        this.adlNonHispanic = new DataElement();
        this.iadlNonHispanic = new DataElement();
        this.totalEthnicityMissing = new DataElement();
        this.bpEthnicityMissing = new DataElement();
        this.adlEthnicityMissing = new DataElement();
        this.iadlEthnicityMissing = new DataElement();
        this.allEthnicityTotal = new DataElement();
        this.bpEthnicityTotal = new DataElement();
        this.adlEthnicityTotal = new DataElement();
        this.iadlEthnicityTotal = new DataElement();

        // race
        this.totalAmericanIndian = new DataElement();
        this.bpAmericanIndian = new DataElement();
        this.adlAmericanIndian = new DataElement();
        this.iadlAmericanIndian = new DataElement();
        this.totalAsian = new DataElement();
        this.bpAsian = new DataElement();
        this.adlAsian = new DataElement();
        this.iadlAsian = new DataElement();
        this.totalBlack = new DataElement();
        this.bpBlack = new DataElement();
        this.adlBlack = new DataElement();
        this.iadlBlack = new DataElement();
        this.totalIslander = new DataElement();
        this.bpIslander = new DataElement();
        this.adlIslander = new DataElement();
        this.iadlIslander = new DataElement();
        this.totalWhite = new DataElement();
        this.bpWhite = new DataElement();
        this.adlWhite = new DataElement();
        this.iadlWhite = new DataElement();
        this.totalRaceOther = new DataElement();
        this.bpRaceOther = new DataElement();
        this.adlRaceOther = new DataElement();
        this.iadlRaceOther = new DataElement();
        this.totalRaceMultiple = new DataElement();
        this.bpRaceMultiple = new DataElement();
        this.adlRaceMultiple = new DataElement();
        this.iadlRaceMultiple = new DataElement();
        this.totalRaceMissing = new DataElement();
        this.bpRaceMissing = new DataElement();
        this.adlRaceMissing = new DataElement();
        this.iadlRaceMissing = new DataElement();

        // minority
        this.totalMinority = new DataElement();
        this.bpMinority = new DataElement();
        this.adlMinority = new DataElement();
        this.iadlMinority = new DataElement();
        this.totalNotMinority = new DataElement();
        this.bpNotMinority = new DataElement();
        this.adlNotMinority = new DataElement();
        this.iadlNotMinority = new DataElement();
        this.totalMinorityMissing = new DataElement();
        this.bpMinorityMissing = new DataElement();
        this.adlMinorityMissing = new DataElement();
        this.iadlMinorityMissing = new DataElement();
        this.allMinorityTotal = new DataElement();
        this.bpMinorityTotal = new DataElement();
        this.adlMinorityTotal = new DataElement();
        this.iadlMinorityTotal = new DataElement();

        // adl limits
        this.totalZeroLimitAdl = new DataElement();
        this.bpZeroAdl = new DataElement();
        this.totalTwoLimitAdl = new DataElement();
        this.bpTwoLimitAdl = new DataElement();
        this.totalHigherLimitAdl = new DataElement();
        this.bpHigherLimitAdl = new DataElement();
        this.totalLimitMissingAdl = new DataElement();
        this.bpLimitMissingAdl = new DataElement();
        this.allLimitTotalAdl = new DataElement();
        this.bpLimitTotalAdl = new DataElement();

        // iadl limtis
        this.totalZeroLimitIadl = new DataElement();
        this.bpZeroIadl = new DataElement();
        this.totalTwoLimitIadl = new DataElement();
        this.bpTwoLimitIadl = new DataElement();
        this.totalHigherLimitIadl = new DataElement();
        this.bpHigherLimitIadl = new DataElement();
        this.totalLimitMissingIadl = new DataElement();
        this.bpLimitMissingIadl = new DataElement();
        this.allLimitTotalIadl = new DataElement();
        this.bpLimitTotalIadl = new DataElement();

        // nutrition
        this.totalLowNutrition = new DataElement();
        this.bpLowNutrition = new DataElement();
        this.adlLowNutrition = new DataElement();
        this.iadlLowNutrition = new DataElement();
        this.totalHighNutrition = new DataElement();
        this.bpHighNutrition = new DataElement();
        this.adlHighNutrition = new DataElement();
        this.iadlHighNutrition = new DataElement();
        this.totalNutritionMissing = new DataElement();
        this.bpNutritionMissing = new DataElement();
        this.adlNutritionMissing = new DataElement();
        this.iadlNutritionMissing = new DataElement();
        this.allNutritionTotal = new DataElement();
        this.bpNutritionTotal = new DataElement();
        this.adlNutritionTotal = new DataElement();
        this.iadlNutritionTotal = new DataElement();
    }
}
