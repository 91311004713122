import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { CommonService } from '../shared/common.service';

@Component({
  templateUrl: 'titleIIIORCHome.component.html',
})

export class TitleIIIORCHomeComponent implements OnInit {

  public pageName: string;


  constructor(public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.pageName = 'Older Relative Caregivers';

  }


}
