import { QuestionStatusEnum } from '../shared/dataElement';

export interface IDataSubmissionsFilter {
    state: string;
    region: string;
    ffy: string;
    lastUpdatedBy: string;
    startDate: string;
    endDate: string;
}

export class StatusItemCheckbox {
    static AllStatus = 'allStatus';
    static AclInProgress = 'aclInProgress';

    key: string | number;
    label: string;
    isChecked: boolean;
    iconHtml: string;

    constructor(key: string | number, label: string, isChecked: boolean, iconHtml: string) {
        this.key = key;
        this.label = label;
        this.isChecked = isChecked;
        this.iconHtml = iconHtml;
    }
}

export class TIIIDataSubmissionsFilter implements IDataSubmissionsFilter {
    state: string;
    region: string = '';
    ffy: string;
    lastUpdatedBy: string;
    startDate: string;
    endDate: string;
    sprStatus: Array<StatusItemCheckbox>;
    nsipStatus: Array<StatusItemCheckbox>;

    constructor() {
        this.sprStatus = new Array<StatusItemCheckbox>();
        this.nsipStatus = new Array<StatusItemCheckbox>();
        this.populateStatuses(this.sprStatus);
        this.populateStatuses(this.nsipStatus);
    }

    populateStatuses(statuses: Array<StatusItemCheckbox>): void {
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.NotStarted, 'Not Started', true, '<i class="fa fa-power-off" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            StatusItemCheckbox.AclInProgress, 'In Progress', true, '<i class="fa fa-cogs" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.SubmittedNoExplanation,
            'Submitted - Pending Explanations', true, '<i class="fa fa-upload" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Submitted, 'Submitted - Explanations Provided', true, '<i class="fa fa-upload" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.InReview, 'In Review', true, '<i class="fa fa-file-text-o" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Returned, 'Returned', true, '<i class="fa fa-undo" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Approved, 'Approved', true, '<i class="fa fa-thumbs-up" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Locked, 'Locked', true, '<i class="fa fa-lock" aria-hidden="true"></i>'));
    }
}

export class TVIIDataSubmissionsFilter implements IDataSubmissionsFilter {
    state: string;
    region: string;
    ffy: string;
    lastUpdatedBy: string;
    startDate: string;
    endDate: string;
    status: Array<StatusItemCheckbox>;

    constructor() {
        this.status = new Array<StatusItemCheckbox>();
        this.populateStatuses(this.status);
    }

    populateStatuses(statuses: Array<StatusItemCheckbox>): void {
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.NotStarted, 'Not Started', true, '<i class="fa fa-power-off" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            StatusItemCheckbox.AclInProgress, 'In Progress', true, '<i class="fa fa-cogs" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Submitted, 'Submitted', true, '<i class="fa fa-upload" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.InReview, 'In Review', true, '<i class="fa fa-file-text-o" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Returned, 'Returned', true, '<i class="fa fa-undo" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Approved, 'Approved', true, '<i class="fa fa-thumbs-up" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Locked, 'Locked', true, '<i class="fa fa-lock" aria-hidden="true"></i>'));
    }
}

export class TVIDataSubmissionsFilter implements IDataSubmissionsFilter {
    state: string;
    region: string;
    ffy: string;
    lastUpdatedBy: string;
    startDate: string;
    endDate: string;
    pprStatus: Array<StatusItemCheckbox>;
    grantee: string;

    constructor() {
        this.pprStatus = new Array<StatusItemCheckbox>();
        this.populateStatuses(this.pprStatus);
    }

    populateStatuses(statuses: Array<StatusItemCheckbox>): void {
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.NotStarted, 'Not Started', true, '<i class="fa fa-power-off" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            StatusItemCheckbox.AclInProgress, 'In Progress', true, '<i class="fa fa-cogs" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Submitted, 'Submitted', true, '<i class="fa fa-upload" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.InReview, 'In Review', true, '<i class="fa fa-file-text-o" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Returned, 'Returned', true, '<i class="fa fa-undo" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Approved, 'Approved', true, '<i class="fa fa-thumbs-up" aria-hidden="true"></i>'));
        statuses.push(new StatusItemCheckbox(
            QuestionStatusEnum.Locked, 'Locked', true, '<i class="fa fa-lock" aria-hidden="true"></i>'));
    }
}
