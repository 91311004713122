<!-- optional banner -->
<oaaps-banner></oaaps-banner>
<!-- Begin skip nav functionality -->
<a (click)="skipThatNav()" (keyup.enter)="skipThatNav()" tabindex="0" id="skipNav"
  class="focusable skip-link sr-only sr-only-focusable">
  Skip
  to main content
</a>
<!-- End skip nav functionality -->
<!-- Session exp warning -->
<session-warning></session-warning>
<!-- Login header -->
<login-header [currentTitleOrg]="_claimService.currentTitleOrg"></login-header>

<!-- Horizontal nav -->
<horizontal-nav ></horizontal-nav>

<!-- Begin Keyboard shortcut menu -->
<div *ngIf="showKeyboardShortcutsMenu" id="keyboardShortcutsMenu" class="col-md-3" role="alert">
  <div class="row">
    <strong>OAAPS Keyboard Shortcuts Menu</strong>
    <div class="downGlyphStyling glyphicon glyphicon-chevron-down pull-right"
      (click)="showKeyboardShortcutsMenu = !showKeyboardShortcutsMenu;"
      (keyup.enter)="showKeyboardShortcutsMenu = !showKeyboardShortcutsMenu;" tabindex="0"
      aria-label="Close keyboard shortcuts menu">
    </div>
  </div>
  <div class="row">
    &bull; Open/close shortcuts menu:
    <em>Ctrl + Shift + Alt + A</em>
  </div>
  <div class="row">
    &bull; Navigate to dashboard:
    <em>Ctrl + Shift + Alt + B</em>
  </div>
</div>
<!-- End Keyboard shortcut menu -->
<!-- Skip nav anchor when logged in-->
<span tabindex="0" id="mainContent" class="sr-only">Start of main content.</span>
<div *ngIf="isLoading" class="loading">Loading&#8230;</div>
<router-outlet (deactivate)="scrollToTop()" (activate)="onRouteChange()"></router-outlet>
<!-- Footer -->
<acl-footer></acl-footer>
