import { GenericModel } from '../genericModel';
import { CommentTypeEnum } from '../../app.constants';

export class Comment extends GenericModel {
    public commentType: CommentTypeEnum;
    public value: string;
    public fiscalYear: string;
    public state: string;
    public granteeId: string;
    public psa: string;
    public extraProps: any;
}
