import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CardComponent } from '../card/card.component';
import { Router } from '@angular/router';

@Component({
  selector: 'acl-button-card',
  templateUrl: './button-card.component.html',
  styleUrls: ['./button-card.component.scss']
})
export class ButtonCardComponent extends CardComponent implements OnInit {
  @Input() id: string;
  @Input() buttonText: string;
  @Input() link: string;
  @Input() width: number;
  @Input() disabled: boolean = false;
  @Output() action: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(public router: Router) {
    super();
  }

  ngOnInit() {
  }

  public onButtonClicked() {
    if (this.link != null) {
      this.router.navigate([this.link]);
    } else {
      this.action.emit();
    }
  }

}
