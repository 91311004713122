<div class="panel flex-list-content">
  <div class="panel-body">
    <p *ngIf="title !== null && title !== ''">
      {{title}}
    </p>
    <p>
      <button [ngStyle]="{'min-width': width+'px'}" [id]="id" class='btn btn-primary' [disabled]="disabled"
        (keydown.enter)="onButtonClicked()" (click)="onButtonClicked()"><span
          class="buttonSpan">{{buttonText}}</span></button>
    </p>
    <p>
      <small [innerHTML]="description"></small>
    </p>
  </div>
</div>