import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

import { getUploadTypeFriendlyName } from '../batchSection';
import { EmptyGuid, AclTitleEnum } from '../../app.constants';
import { ClaimService } from '../claim.service';
import { QuestionStatusEnum } from '../dataElement';
import { ToastrService } from '../toastr.service';

import * as _ from 'lodash-es';
import { AttachmentService } from './attachment.service';
import { DestructibleComponent } from '../../destructible.component';
declare var jQuery: any;

@Component({
    templateUrl: 'manageAttachments.component.html',
    styles: [
        `.modal-header > h1 { text-transform: none; color: black; font-size: 20px;
            font-weight: bold; border-bottom-width: 0px; padding-bottom: 0px; }`,
        '.modal-body > .table { margin-bottom: 0px; }',
        '.modal-body > .table > tbody > tr > td { border-top: 0px; width: 70%; }',
        '.modal-body > .table > tbody > tr > th { border-top: 0px; width: 30% }'
    ]
})

export class ManageAttachmentsComponent extends DestructibleComponent implements OnInit, OnDestroy {

    _attachments: Array<any>;
    _isUploadDisabled: boolean = true;
    _showUploadDisabledMessage = false;
    _activeSubs = [];
    _statusEnum = QuestionStatusEnum;
    status: QuestionStatusEnum = QuestionStatusEnum.NotStarted;
    public fileToUpload: File;
    public fileName: string = '';
    public validExtensions: Array<string> = [
      '.png',
      '.jpg',
      '.jpeg',
      '.docx',
      '.pdf'
    ];
    public uploadedFiles: Array<{uri: string, name: string}> = [];

    constructor(public zone: NgZone,
        public route: ActivatedRoute,
        public router: Router,
        public _titleService: Title,
        public _claimService: ClaimService,
        private toastr: ToastrService,
        private _dataEntryService: AttachmentService,
        public _sanitizer: DomSanitizer
    ) { super(); }

    ngOnInit(): void {
        this._titleService.setTitle('Manage Attachments - State Submissions - ACL OAAPS');

        this._claimService.init$.subscribe(data => { if (data) {
            this.getAttachments();
            this.canUpload();
         } },
            err => { console.log(err); });
    }

    ngOnDestroy(): void {
        this._activeSubs.forEach(x => x.unsubscribe());
    }

    getAttachments(): void {
        super.registerSubscription(this._dataEntryService.
            findAttachments(this._claimService.currentYear, this._claimService.currentOrg)
            .subscribe(data => {
                if(data){
                this._attachments = data
                    .filter(d => d.id !== EmptyGuid)
                    .sort((left, right) =>
                        Date.parse(left.created) > Date.parse(right.created) ? -1 : 1);
                }
            }));
    }

    handleError(error): void {
        // Unknown error
        if (error.code === 1999) {
            console.log(error);
            alert('We\'re sorry an error occurred during merging');
        } else {
            // Other errors
            console.log(error);
            alert('We\'re sorry an error occurred during merging');
        }
    }

    canUpload() {
        this.route.queryParams.subscribe(qsMap => {
            if (qsMap['status']) {
                this.status = qsMap['status'];
                this._isUploadDisabled = this.status >= this._statusEnum.SubmittedNoExplanation;
            }
        });
    }

    deleteAttachment(id){
        super.registerSubscription(this._dataEntryService.deleteAttachment(id).subscribe(
        data => this.zone.run(() => {
            this._attachments.splice(this._attachments.findIndex(x => x.id == id), 1);
        })));
    }

    navAttachFile() {
        let url = '/titlevi/attachFile';

        if(this._claimService.currentTitle === AclTitleEnum[AclTitleEnum.III]){
            url = '/data-submissions/attachFile';
        }
        else if(this._claimService.currentTitle === AclTitleEnum[AclTitleEnum.VII]){
            url = '/state-submissions/attachFile';
        }
        this.router.navigate([url], {
            queryParams: {
              status: this.status
            }
        });
    }

    getCurrentTitle() {
      return this._claimService.currentTitle;
    }
}
