import { OnInit, Component, Input, Optional } from '@angular/core';
import { DataTable } from './DataTable';


@Component({
    selector: 'acl-dt-header',
    templateUrl: 'dataTableHeader.component.html',
    styles: ['span { padding: .4em 0; font-style: italic; line-height: 1.2em; font-size: 14pt; }']
})
export class DataTableHeader implements OnInit {

    @Input('mfTable') mfTable: DataTable;

    constructor(@Optional() private injectMfTable: DataTable) { }

    ngOnInit(): void {
        this.mfTable = this.mfTable || this.injectMfTable;
        if (!this.mfTable) {
            throw new Error('Missing required dependencies, DataTable');
        }
    }

    public get fromItem(): number {
        return this.mfTable.rowsOnPage * (this.mfTable.activePage - 1) + 1;
    }

    public get toItem(): number {
        return Math.min(this.fromItem + this.mfTable.rowsOnPage - 1, this.mfTable.inputData.length);
    }

    public get count(): number {
        return this.mfTable.inputData.length;
    }

    public get isValidState(): boolean {
        return this.mfTable
            && this.mfTable.activePage > 0
            && this.mfTable.inputData.length > 0
            && this.mfTable.rowsOnPage * (this.mfTable.activePage - 1) <= this.mfTable.inputData.length;
    }

}
