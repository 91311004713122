<nav class="navbar navbar-default navbar-static-top">
    <div class="navbar-header">
      <!-- .end region-navigation -->
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
        aria-expanded="false" aria-controls="navbar">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div id="navbar" class="navbar-collapse collapse">
      <div class="region region-navigation-collapsible">
        <ul class="nav navbar-nav">
          <li>
            <a *ngIf="currentTitle == ''" [routerLink]="['welcome']" [routerLinkActive]="['router-link-active']" id="homeTab">Home</a>
          </li>
          <li>
            <a *ngIf="currentTitle == titleEnum[titleEnum.III] || currentTitle == titleEnum[titleEnum.VII] || currentTitle == titleEnum[titleEnum.VI]"
              [routerLink]="['dashboard']" [ngClass]="getActiveClass('dashboard')" id="dashboardTab">Dashboard</a>
          </li>
          <li *ngIf="!isDeactivated && (isNavStateSubmission || isAAAUser)">
            <a *ngIf="currentTitle == titleEnum[titleEnum.VII]" [routerLink]="['state-submissions']"
              [routerLinkActive]="['router-link-active']" id="tviiDataSubmissions">Data Submissions</a>
            <a *ngIf="currentTitle == titleEnum[titleEnum.III]" tabindex="0" (keyup.enter)="navTIIIDataSubmissions()"
              (click)="navTIIIDataSubmissions()" id="tiiiDataSubmissions" [ngClass]="getActiveClass('data-submissions')">
              Data
              Submissions
            </a>
          </li>
          <li *ngIf="currentOrgLevel === 'Grantee' && currentTitle === titleEnum[titleEnum.VI]">
            <a [routerLink]="['/titlevi/titleVIPPROverview']" [routerLinkActive]="['router-link-active']"
              id="PPRSubmissions">PPR
              Submissions</a>
          </li>
          <li *ngIf="!isDeactivated && (isNavApprover || isNavLocker)">
            <a *ngIf="currentTitle == titleEnum[titleEnum.III]"
              [routerLink]="['/data-submissions/titleIIIDataSubmissions']" [ngClass]="getActiveClass('data-submissions')"
              id="tiiiDataSubmissions">
              Data
              Submissions
            </a>
            <a *ngIf="currentTitle == titleEnum[titleEnum.VII]"
              [routerLink]="['state-submissions/titleVIIDataSubmissions']"
              [routerLinkActive]="['router-link-active']" id="VIIDataSubTab">Data Submissions</a>
            <!-- Title VI PPR Submissions-->
            <a *ngIf="currentTitle == titleEnum[titleEnum.VI]" [routerLink]="['/titlevi/acl-ppr-submissions-list']"
              [ngClass]="getActiveClass('ppr-submissions')" id="PPRSubmissions">PPR Submissions</a>
          </li>
          <li
            *ngIf="!isDeactivated && (isNavReports || isNavApprover || isNavLocker || isNavStateSubmission || isNavAdmin || isViewOnly || isAAAUser || currentOrgLevel === 'Grantee')">
            <a [routerLink]="['reports']" [routerLinkActive]="['router-link-active']" id="tviiAnalysisReports">
              Analysis
              Reports
            </a>
          </li>
          <li>
            <a *ngIf="!currentTitle || isDeactivated" [routerLink]="['Resources/landingResources', 'ref']"
              [ngClass]="{ 'router-link-active': isResourcesActive }" id="tvIILandingResources">Resources</a>
          </li>
          <li>
            <a *ngIf="currentTitle && currentTitle == 'VII' && !isDeactivated" [routerLink]="['Resources']"
              [ngClass]="{ 'router-link-active': isResourcesActive }" id="tvIIResources">Resources</a>
          </li>
          <li>
            <a *ngIf="currentTitle && currentTitle == 'III' && !isDeactivated" [routerLink]="['Resources/oaapsRes']"
              [ngClass]="{ 'router-link-active': isResourcesActive }" id="tvIIResources">Resources</a>
          </li>
          <li>
            <a *ngIf="currentTitle && currentTitle == 'VI' && !isDeactivated" [routerLink]="['Resources/pprRes']"
              [ngClass]="{ 'router-link-active': isResourcesActive }" id="tvIIResources">Resources</a>
          </li>
          <!-- admin tab for ACL admin users or Users tab for tvi users  -->
          <li *ngIf="isNavAdmin || currentTitle === titleEnum[titleEnum.VI]">
            <a [routerLink]="['UserManagement/administration']"
              [ngClass]="getActiveClass('admin')" id="administrationTab">{{isNavAdmin ? 'Administration' : 'Users'}}</a>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right profileNav"
          *ngIf="!isDeactivated && (isDataEntry || isNavReports || isNavStateSubmission || isNavApprover || isNavLocker || isAAAUser)">
          <li class="dropdown">
            <a [ngClass]="inProfileNav()?'dropdown-toggle router-link-active':'dropdown-toggle'" href="#"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              Profile
              <span class="caret"></span>
            </a>
            <ul id="collapse1" class="dropdown-menu">
              <li>
                <a [routerLink]="['/profile/account']">
                  <i class="fa fa-user" aria-hidden="true"></i> Account
                </a>
              </li>
              <li *ngIf="currentTitle == titleEnum[titleEnum.VII]">
                <a [ngClass]="_router.url.includes('titleVIIUserNotification')?'router-link-active':''"
                  [routerLink]="['/profile/titleVIIUserNotification']">
                  <i class="fa fa-cog" aria-hidden="true"></i> Notifications
                </a>
              </li>
              <li *ngIf="currentTitle == titleEnum[titleEnum.VI] && (isNavApprover || isDataEntry)">
                <a [ngClass]="_router.url.includes('titleVIUserNotification')?'router-link-active':''"
                  [routerLink]="['/profile/titleVIUserNotification']">
                  <i class="fa fa-cog" aria-hidden="true"></i> Notifications
                </a>
              </li>
              <li *ngIf="currentTitle == titleEnum[titleEnum.III] && (isNavApprover || isDataEntry)">
                <a [ngClass]="_router.url.includes('titleIIIUserNotification')?'router-link-active':''"
                  [routerLink]="['/profile/titleIIIUserNotification']">
                  <i class="fa fa-cog" aria-hidden="true"></i> Notifications
                </a>
              </li>
              <li *ngIf="isNavApprover && currentTitle == titleEnum[titleEnum.VII]">
                <a [ngClass]="_router.url.includes('titleVIIUserDefaultStates')?'router-link-active':''"
                  [routerLink]="['/profile/titleVIIUserDefaultStates']">
                  <i class="fa fa-cog" aria-hidden="true"></i> Default States
                </a>
              </li>
              <li *ngIf="isNavApprover && currentTitle == titleEnum[titleEnum.III]">
                <a [ngClass]="_router.url.includes('titleIIIUserDefaultStates')?'router-link-active':''"
                  [routerLink]="['/data-submissions/titleIIIUserDefaultStates']">
                  <i class="fa fa-cog" aria-hidden="true"></i> Default States
                </a>
              </li>
              <li *ngIf="isNavApprover && currentTitle == titleEnum[titleEnum.VI]">
                <a [ngClass]="_router.url.includes('tviUserDefaultGrantees')?'router-link-active':''"
                  [routerLink]="['/profile/tviUserDefaultGrantees']">
                  <i class="fa fa-cog" aria-hidden="true"></i> Default Grantees
                </a>
              </li>
              <li>
                <a [href]="forgotPasswordUrl" target="_blank">
                  <i class="fa fa-key" aria-hidden="true"></i> Change Password
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <!--/.nav-collapse -->
  </nav>
