import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { ClaimService } from '../claim.service';
import { DestructibleComponent } from '../../destructible.component';
import { LoadingService } from '../loading.service';
import { SupportingDocument } from '../model/SupportingDocument';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AttachmentService } from './attachment.service';
import { ToastrService } from '../toastr.service';
import { AclTitleEnum } from '../../app.constants';
import { Attachment } from '../Model/attachment';
declare var jQuery: any;

@Component({
    templateUrl: 'attachFile.component.html',
})

export class AttachFileComponent extends DestructibleComponent implements OnInit, OnDestroy {

    isReadyForUpload: boolean = false;
    fileToUpload: File = null;
    fileName: string = '';
    azureUrl: string = null;
    fileExt: string = null;
    blobPath: string = null;
    public form: FormGroup;
    public validExtensions: Array<string> = [
        '.docx',
        '.pdf',
        '.csv',
        '.xls',
        '.xlsx',
        '.xml'
      ];

    constructor(public formBuilder: FormBuilder,
        public zone: NgZone,
        public route: ActivatedRoute,
        public router: Router,
        public _titleService: Title,
        public _claimService: ClaimService,
        public _dataEntryService: AttachmentService,
        private _toastr: ToastrService,
        private _loadingService: LoadingService
    ) { super(); }

    ngOnInit(): void {
        this._titleService.setTitle('Upload Attachments');
        this.buildForm();
    }

    ngOnDestroy(): void {
        super.destroy();
    }

    fileChangeEvent(fileInput: any): void {
        this.fileToUpload = <File>fileInput.target.files[0];
        this.fileName = this.fileToUpload.name;
        if (this.fileToUpload != null) {
            const index = this.fileToUpload.name.lastIndexOf(".");
            this.fileExt = this.fileToUpload.name.substring(index + 1, this.fileToUpload.name.length);
            // this.isReadyForUpload = this.validExtensions.includes('.' + this.fileExt) && this.form.get('categoryType').value;
            if(this.validExtensions.includes('.' + this.fileExt)){
              super.registerSubscription(this._dataEntryService.postFile(this.fileToUpload, this._claimService.currentTitle)
                .subscribe((attachment: Attachment) => {
                    this.azureUrl = attachment.signedUri;
                    this.blobPath = attachment.filename;
                    this.categoryChangeEvent();
                }));
            }
        }
    }
    categoryChangeEvent(): void {
        this.isReadyForUpload =
            this.validExtensions.includes('.' + this.fileExt) && this.form.get('categoryType').value &&
            (this.azureUrl != '' && this.azureUrl != null);
    }

    uploadFileClicked() {
        let e = null;
        if (e = document.getElementById('uploadFileLabel')) {
            e.click();
        }
    }

    upload(): void {
        this.doUpload();
    }


    doUpload(): void {
        //this.loadingSpinner.setLoadingInProgress(60);
        const model = this.buildModel();
        this._dataEntryService.saveAttachment(model)
        .subscribe(() => { this._toastr.success('Your request has been submitted, thank you!'); this.navCancel(); },
        err => {
            console.log(err); this._toastr.error('We\'re sorry an error has occurred.');
        },
        () => {//this.loadingSpinner.setLoadingComplete()
        }
        );
    }

    buildForm() {

        // Setup default form group
        this.form = this.formBuilder.group({
            org: ['', Validators.nullValidator],
            fiscalYear: ['', Validators.nullValidator],
            fileName: ['', Validators.compose([Validators.required])],
            categoryType: ['', Validators.compose([Validators.required])],
            comments: ['', Validators.compose([Validators.nullValidator])],
        });
      }

    buildModel(): SupportingDocument {
        return {
          org: this._claimService.currentOrg,
          fiscalYear: this._claimService.currentYear,
          blobPath: this.blobPath,
          fileName: this.fileName,
          categoryType: this.form.get('categoryType').value,
          comments: this.form.get('comments').value,
          fileType: this.fileExt,
          azureUrl: this.azureUrl,
          createdBy: this._claimService.userID
        }
      }

    navCancel(): void {
        let status;
        let url = '/titlevi/manageAttachments';
        if(this._claimService.currentTitle === AclTitleEnum[AclTitleEnum.III]){
            url = '/data-submissions/manageAttachments';
        }
        else if(this._claimService.currentTitle === AclTitleEnum[AclTitleEnum.VII]){
            url = '/state-submissions/manageAttachments';
        }
        this.route.queryParams.subscribe(qsMap => {
            if (qsMap['status']) {
                status = qsMap['status'];
            }
        });
        this.router.navigate([url], {
            queryParams: {
              status: status
            }
        });
    }


}
