import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { IUserSearch, ITitleUser, IUserTitleRolesDto, IUserContact } from './userManagerObjects';
import { OktaService } from '../auth/service/okta.service';
import { from } from 'rxjs';
import { TransactionService } from '../shared/transaction.service';

@Injectable()
export class UserService {
    public _roleApiRoot = '/api/v1/userrole';
    public _apiRoot = '/api/v1/user';
    public _findByUpn = '/api/v1/user/upn';
    public _search = '/api/v1/user/search';
    public _updateContact = `${this._apiRoot}/contact`
    public _export = '/api/v1/userrole/export';


    constructor(public _http: HttpClient,
        private _oktaService: OktaService,
        private _transactionService: TransactionService) {
    }

    public search(userSearch: IUserSearch): Observable<Array<ITitleUser>> {
        return this._http.post<Array<ITitleUser>>(
            this._search, userSearch, { withCredentials: true }).pipe(
                catchError(this.handleError<Array<ITitleUser>>()
                ));
    }

    public list(): Observable<Array<ITitleUser>> {
        return this._http.get<Array<ITitleUser>>(
            `${this._apiRoot}/grantee-list`, { withCredentials: true }).pipe(
                catchError(this.handleError<Array<ITitleUser>>()
        ));
    }

    public updateContact(contact: IUserContact): Observable<Object> {
        return this._http.post(this._updateContact, contact);
    }

    public export(userSearch: IUserSearch): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._export, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(userSearch));
        });
        return from(this._oktaService.getIdTokenAsync())
        .pipe(
            switchMap(req)
        );
    }

    public add(user: ITitleUser): Observable<any> {
        return this._http.post<any>(this._apiRoot, user, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    public addRole(dto: IUserTitleRolesDto): Observable<ITitleUser> {
        // Map region, fix bug with .NET Core deserilization of enums
        if (dto.roles) {
            dto.roles.forEach(r => {
                let region = <any> r.region;
                if (typeof region === 'string') {
                    r.region = parseInt(region, 10);
                }
            });
        }
        return this._http.post<ITitleUser>(this._roleApiRoot, dto, { withCredentials: true }).pipe(
            catchError(this.handleError<ITitleUser>())
        );
    }

    public removeRole(userId: string, title: string, org: string = null): Observable<ITitleUser> {
        const paramAndCred = { params: { userId, title, org }, withCredentials: true };
        return this._http.delete<ITitleUser>(this._roleApiRoot, paramAndCred).pipe(
            catchError(this.handleError<ITitleUser>())
        );
    }

    public find(id: string): Observable<ITitleUser> {
        return this._http.get<ITitleUser>(`${this._apiRoot}/${id}`, { withCredentials: true }).pipe(
            catchError(this.handleError<ITitleUser>())
        );
    }

    public findByUpn(upn: string): Observable<ITitleUser> {
        return this._http.get<ITitleUser>(`${this._findByUpn}`, { params: { upn }, withCredentials: true }).pipe(
            catchError((err: Response) => {
                if (err.status === 401) {
                    alert('Your account is not authorized for this application, please contact OAAPS support for access.');
                    this._oktaService.logout();
                }
                return of(null);
            })
        );
    }

    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
