import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { DataElement } from '../shared/dataElement';
import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { AssistedTransportationUnitsSection } from './AssistedTransportationUnitsSection';
import { ValidationService } from '../shared/validation.service';
import { ToastrService } from '../shared/toastr.service';
import { CommonService } from '../shared/common.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIISectionComponent } from './title-iii-section.component';
import { AddOnOptions } from '../shared/Model/addOnOptions';

@Component({
  templateUrl: 'titleIIIAssistedTransportationUnits.component.html',
})

// Template for Legal Assistance Units and Assisted Transportation
export class TitleIIIAssistedTransportationUnitsComponent extends TitleIIISectionComponent<AssistedTransportationUnitsSection> implements OnInit, OnDestroy {

  _sectionModel: AssistedTransportationUnitsSection;
  _uploadModels: Array<AssistedTransportationUnitsSection>;
  _batchIds: Array<string>;
  pageName: string;
  unsavedChanges: boolean = false;
  statusEnum = QuestionStatusEnum;

  public get addOnOption(): AddOnOptions {
    return {
      text: '$',
      width: 20,
      isPrefix: true
    };
  }

  constructor(public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public toastr: ToastrService,
    public _commonService: CommonService
  ) {
    super(new AssistedTransportationUnitsSection(), _commonService, _validation, router, _claimService, _service);
   }

  ngOnInit(): void {
    this._sectionModel = new AssistedTransportationUnitsSection();
    this.setSectionName();

    switch (this._sectionModel.sectionName) {
      case 'titleIIILegalUnits': {
        this.pageName = 'Legal Assistance';
        break;
      }
      case 'titleIIIAssistedTransportationUnits': {
        this.pageName = 'Assisted Transportation';
        break;
      }
      default: {
        this.pageName = 'Error';
        break;
      }
    }
    this._titleService.setTitle(this.pageName.concat(' - Service Units, Expenditures and Program Income - Data Submissions - ACL OAAPS'));
    this.getassistedtransportationunitsbyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    this.setSectionName();
    super.registerSubscription(this._service.saveassistedtransportationunits(this._sectionModel)
      .subscribe(data => {
        this._sectionModel = data;
      },
      (error) => console.log(error),
      () => {
        this.toastr.success('Saved!');
        callback();
    }));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => {
      switch (this._sectionModel.sectionName) {
        case 'titleIIILegalUnits': {
          this.router.navigate(['/data-submissions/OALegalCases']);
          break;
        }
        case 'titleIIIAssistedTransportationUnits': {
          this.router.navigate(['/data-submissions/titleIIICongregateMealsChars']);
          break;
        }
        default: {
          this.router.navigate(['/dashboard']);
          break;
        }
      }
    };
    if (!this._claimService.isViewOnly && 
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  next(): void {
    switch (this._sectionModel.sectionName) {
      case 'titleIIILegalUnits': {
        this.router.navigate(['/data-submissions/OALegalCases']);
        break;
      }
      case 'titleIIIAssistedTransportationUnits': {
        this.router.navigate(['/data-submissions/titleIIICongregateMealsChars']);
        break;
      }
      default: {
        this.router.navigate(['/dashboard']);
        break;
      }
    }
  }

  getassistedtransportationunitsbyffyandstate(): void {
    super.registerSubscription(this._service.get<AssistedTransportationUnitsSection[]>(this._claimService,
      this._service._getassistedtransportationunitsbyffyandstate, this.resolveSectionName())
      .subscribe(data => {
        if (data.length > 0) {
          const sectionData = data.find(item => this.resolveSectionName().includes(item.sectionName));
          if (sectionData) {
            this._sectionModel = sectionData;
          } else {
            // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
            const tempSectionModel: AssistedTransportationUnitsSection = new AssistedTransportationUnitsSection();
            this._sectionModel = tempSectionModel;
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
        }

      }));
  }
  checkUserResponsibility() {
    let calculateTotals = false;
    if (this._sectionModel.totalServiceUnits.isRequired &&
      this.checkUserExpenditureResponsibility()) {
      calculateTotals = true; // User is responsible for everything
    } else {
      this._sectionModel.totalExpendPerUnit.elementValue = 'N/A';
    }
    return calculateTotals;
  }

  checkUserExpenditureResponsibility() {
    return this._sectionModel.expendC.isRequired &&
      this._sectionModel.expendOtherState.isRequired &&
      this._sectionModel.expendOtherNonState.isRequired &&
      this._sectionModel.programIncome.isRequired;
  }

  updateTotals(): void {
    let grandTotal = 0;
    let perUnit = 0;
    let totalServiceUnits = 0;
    if (this.checkUserExpenditureResponsibility()) {
      const expendC = parseFloat(this.removeCommas(this._sectionModel.expendC.elementValue));
      const expendOtherState = parseFloat(this.removeCommas(this._sectionModel.expendOtherState.elementValue));
      const expendOtherNonState = parseFloat(this.removeCommas(this._sectionModel.expendOtherNonState.elementValue));
      const programIncome = parseFloat(this.removeCommas(this._sectionModel.programIncome.elementValue));
      totalServiceUnits = parseFloat(this.removeCommas(this._sectionModel.totalServiceUnits.elementValue));
      if (!isNaN(expendC)) { grandTotal += expendC; }
      if (!isNaN(expendOtherState)) { grandTotal += expendOtherState; }
      if (!isNaN(expendOtherNonState)) { grandTotal += expendOtherNonState; }
      if (!isNaN(programIncome)) { grandTotal += programIncome; }
      this._sectionModel.expendTotal.elementValue = this.addDecimals(grandTotal.toString());
    } else {
      this._sectionModel.expendTotal.elementValue = 'N/A';
    }
    if (this.checkUserResponsibility()) {
      this._sectionModel.totalExpendPerUnit.elementValue = this.expendPerUnit(grandTotal, totalServiceUnits);
      if (totalServiceUnits.toString() !== 'NaN') {
        this._sectionModel.totalServiceUnits.elementValue = this.addComma(totalServiceUnits.toString());
      }
    }
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.totalServiceUnits.elementValue = this.addComma(this._sectionModel.totalServiceUnits.elementValue);
    this._sectionModel.expendC.elementValue = this.addDecimals(this._sectionModel.expendC.elementValue);
    this._sectionModel.expendOtherState.elementValue = this.addDecimals(this._sectionModel.expendOtherState.elementValue);
    this._sectionModel.expendOtherNonState.elementValue = this.addDecimals(this._sectionModel.expendOtherNonState.elementValue);
    this._sectionModel.programIncome.elementValue = this.addDecimals(this._sectionModel.programIncome.elementValue);
    this._sectionModel.expendTotal.elementValue = this.addDecimals(this._sectionModel.expendTotal.elementValue);
    this._sectionModel.totalExpendPerUnit.elementValue = this.addDecimals(this._sectionModel.totalExpendPerUnit.elementValue);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.oaLockedNav = false;
      return true;
    } else {
      this._commonService.oaLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
      or press Cancel to go back and save these changes.`;
    }
  }

}
