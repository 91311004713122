export class AclHeader {
    constructor(public shouldRender: boolean = false
        , public orgLabel: string = ''
        , public orgName: string = ''
        , public fiscalYear: string = ''
        , public returnLink: string = ''
        , public returnText: string = ''
        , public title: string = ''
        , public returnParams: any = {}) {}
}
