import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from '../toastr.service';
import { UploadTypeEnum, AclTitleEnum } from '../../app.constants';
import { ClaimService } from '../claim.service';
import { SupportingDocument } from '../model/SupportingDocument';
import { Attachment } from '../Model/attachment';

/**
 * Service for attachments
 */
@Injectable()
export class AttachmentService {

     // Attachments
    public _tiiiAttachmentsApiRoot = 'api/titleiii/attachments';
    public _tviAttachmentsApiRoot = 'api/titlevi/attachments';
    public _tviiAttachmentsApiRoot = 'api/titlevii/attachments';
    public  _uploadApiRoot = 'api/upload';
    public observableTimer = timer(0, 15000);

    private toastrOptions: any = { timeOut: '-1', showDuration: '-1', closeButton: true, positionClass: 'toast-top-full-width' };
    private httpClient: HttpClient;

    constructor(public _http: HttpClient,
    public _claimService: ClaimService,
    public toastr: ToastrService,
    ) {
      this.httpClient = _http;
  }


    postFile(file: File, title: string): Observable<Attachment> {
        const formData: FormData = new FormData();
        formData.append('attachment', file, file.name);
        formData.append('title', title);
        return this.httpClient.post<Attachment>(`${this._uploadApiRoot}/attachment/supportingDocument`, formData, { withCredentials: true })
        .pipe(
            catchError(err => {
                console.log(err);
                if (err.status === 400 && err.payload && err.payload.length) {
                    err.payload.map(p => this.toastr.error(p.message));
                } else {
                    this.toastr.error(err.message);
                }
                return of(null);
            })
        );
    }

    findAttachments(ffy: string, org: string): Observable<any> {
        let url = `${this._tviAttachmentsApiRoot}/${ffy}/${org}`;
        if(this._claimService.title===AclTitleEnum.III){
            url = `${this._tiiiAttachmentsApiRoot}/${ffy}/${org}`;
        }
        else if(this._claimService.title===AclTitleEnum.VII){
            url = `${this._tviiAttachmentsApiRoot}/${ffy}/${org}`;
        }
        return this._http.get(url,
        { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    findAllAttachments(): Observable<any> {
        let url = `${this._tviAttachmentsApiRoot}`;
        if(this._claimService.title===AclTitleEnum.III){
            url = `${this._tiiiAttachmentsApiRoot}`;
        }
        else if(this._claimService.title===AclTitleEnum.VII){
            url = `${this._tviiAttachmentsApiRoot}`;
        }
        return this._http.get(url, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    saveAttachment(model: SupportingDocument): Observable<any> {
        let url = `${this._tviAttachmentsApiRoot}/upload`;
        if(this._claimService.title===AclTitleEnum.III){
            url = `${this._tiiiAttachmentsApiRoot}/upload`;
        }
        else if(this._claimService.title===AclTitleEnum.VII){
            url = `${this._tviiAttachmentsApiRoot}/upload`;
        }
        return this._http.post<SupportingDocument>(
        url, model, { withCredentials: true }).pipe (
            catchError(this.handleError<SupportingDocument>())
        );
    }

    deleteAttachment(id: string): Observable<any> {
        let url = `${this._tviAttachmentsApiRoot}/delete/${id}`;
        if(this._claimService.title===AclTitleEnum.III){
            url = `${this._tiiiAttachmentsApiRoot}/delete/${id}`;
        }
        else if(this._claimService.title===AclTitleEnum.VII){
            url = `${this._tviiAttachmentsApiRoot}/delete/${id}`;
        }
        return this._http.get<any>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<any>()
            ));
    }

      // // Global
      private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
