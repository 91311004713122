import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { combineLatest, interval, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, switchMap, takeWhile } from 'rxjs/operators';

import { ToastrService } from './toastr.service';
import { DistributedTransaction, ITransaction, ITransactionCreatedResponse, TransactionStatus } from './Sync/distributedTransaction';
import { LoadingService } from './loading.service';
import { MsgErrorGeneric } from '../app.constants';


export type TransactionHttpOptions = {
  withCredentials?: boolean
};

export type PollOptions = {
  timeout: number;
  shouldDisplayLoadingSpinner: boolean;
  shouldDisplayToast: boolean;
  toastText?: string;
  callback?: (transaction: ITransaction) => void
};

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private readonly endpoint = 'api/v1/transaction';
  public readonly defaultPollOptions: PollOptions = {
    timeout: 300,
    shouldDisplayLoadingSpinner: false,
    shouldDisplayToast: true,
    toastText: 'Background operation completed successfully.'
  };

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
    private loadingService: LoadingService) {
  }

  public getTransaction(
    uri: string,
    options: TransactionHttpOptions = { withCredentials: true }): Observable<ITransaction> {
      return this.http.get<ITransaction>(uri, options);
  }

  public createGetTransaction(
    uri: string,
    options: TransactionHttpOptions = { withCredentials: true }): Observable<ITransactionCreatedResponse> {
    return this.http.get<ITransactionCreatedResponse>(uri, options);
  }

  public createPostTransaction<TPayload>(
    uri: string,
    payload: TPayload,
    options: TransactionHttpOptions = {}): Observable<ITransactionCreatedResponse> {
    return this.http.post<ITransactionCreatedResponse>(uri, payload, options);
  }

  public pollTransaction(
    transactionResult: Observable<ITransactionCreatedResponse>,
    options: PollOptions = this.defaultPollOptions): Subscription {
      const REQUEST_INTERVAL = 3000;
      const NUM_REQUESTS = options.timeout / (REQUEST_INTERVAL / 1000);

      this.toggleLoadingAnimation(options.shouldDisplayLoadingSpinner);
      const s = transactionResult
          .pipe(
            switchMap(resp => combineLatest([of(resp.tid), interval(REQUEST_INTERVAL)])),
            takeWhile(([_, tick]) => tick < NUM_REQUESTS),
            switchMap(([tid]) => this.getTransaction(`${this.endpoint}/${tid}`)),
            takeWhile(t => t.status === TransactionStatus.InProgress, true),
            finalize(() => {
              this.toggleLoadingAnimation(false);
            }),
            catchError(_ => {
              return of({ ...new DistributedTransaction(), status: TransactionStatus.Failed, error: MsgErrorGeneric });
            })
          ).subscribe(t => {
            this.toastTransactionResults(t, options);
            if (options.callback) {
              options.callback(t);
            }
          }
        );
      return s;
  }

  public createSuccessFailedCallback(success: () => void, failed: () => void): (transaction: ITransaction) => void {
    return (t) => {
      if (t.status === TransactionStatus.Completed) {
        success();
      } else if (t.status === TransactionStatus.Failed) {
        failed();
      }
    }
  }

  private toggleLoadingAnimation(enable: boolean): void {
    if (enable) {
      this.loadingService.setManualLoading(true);
    } else {
      this.loadingService.setManualLoading(false);
    }
  }

  private toastTransactionResults(transaction: ITransaction,
    options: { shouldDisplayToast: boolean, toastText?: string }) {
      this.createSuccessFailedCallback(
        () => this.toastSuccess(options),
        () => this.toastFailure({ ...options, toastText: transaction.error })
      )(transaction);
    }

  private toastSuccess(options: { shouldDisplayToast: boolean, toastText?: string }) {
    if (options.shouldDisplayToast && options.toastText) {
      this.toastrService.success(options.toastText);
    }
  }

  private toastFailure(options: { shouldDisplayToast: boolean, toastText?: string }) {
    if (options.shouldDisplayToast && options.toastText) {
      this.toastrService.error(options.toastText);
    }
  }
}
