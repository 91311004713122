import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { from, Observable, of } from 'rxjs';
import { catchError, flatMap, switchMap } from 'rxjs/operators';

import { BasicErrorDetail } from '../shared/Upload/uploadError';

// Models
import { ITVIIOverview, TVIIOverview, TVIITopSectionTypeEnum } from '../shared/section';
import { StaffAndVolunteerSection } from './staffAndVolunteerSection';
import { NumberAndCapacitySection } from './numberAndCapacitySection';
import { ProgramActivitiesSection } from './programActivitiesSection';
import { FundsExpendedSection } from './fundsExpendedSection';
import { ConflictOfInterestSection } from './conflictOfInterestSection';
import { RccInformationSection } from './rccInformationSection';
import { OrgStructureSection } from './OrgStructureSection';
import { ComplaintExampleSection } from './ComplaintExampleSection';
import { SystemIssueSection } from './SystemIssueSection';
import { CaseAndComplaintAggregateView } from './CaseAndComplaintAggregate';
import { CaseAndComplaintSection } from './CaseAndComplaintSection';
import { YearApprovalStatus } from '../shared/yearApprovalStatus.model';
import { UserNotifications } from './userNotifications';
import { UserDefaultStates } from '../shared/userDefaultStates';
import { YearStateRegionReport } from '../reports/report.model';
import { ClaimService } from '../shared/claim.service';
import { LoadingComponent } from '../shared/loading.component';
import { FfyStatesDto } from './FfyStatesDto';
import { QuestionStatusEnum } from '../shared/dataElement';


@Injectable()
export class TitleVIIDataEntryService {


    constructor(public _http: HttpClient,
        public _claimService: ClaimService) {
    }
    public set overviews(overviews: TVIIOverview[]) {
        this._overviews = overviews;
    }
    public get overviews(): TVIIOverview[] {
        return this._overviews;
    }

    // Resources

    // Variance
    public _updateVarianceStaff = 'api/titlevii/variance/status/staffandvolunteer';
    public _updateVarianceOrgStructure = 'api/titlevii/variance/status/orgstructure';
    public _updateVarianceCaseAndComplaint = 'api/titlevii/variance/status/caseandcomplaint';
    public _updateVarianceNumberAndCapacity = 'api/titlevii/variance/status/numberandcapacity';
    public _updateVarianceProgramActivities = 'api/titlevii/variance/status/programactivities';
    public _updateVarianceFundsExpended = 'api/titlevii/variance/status/fundsexpended';

    // Overview
    public _getOverview = '/api/titlevii/overview/{ffy}/{state}';
    public _getStatesOverview = 'api/titlevii/overview/{ffy}';
    public _validateBusinessRules = 'api/titlevii/overview/{ffy}/{state}/{sectionId}';
    public _dataEntryErrors = '/api/titlevii/overview/errors';
    public _generateVarianceReport = 'api/titlevii/variance/calculate/{state}/{ffy}';
    public _submit = 'api/titlevii/overview/submit';
    public _review = 'api/titlevii/overview/review';
    public _approve = 'api/titlevii/overview/approve';
    public _return = 'api/titlevii/overview/return';
    public _lock = 'api/titlevii/overview/lock';
    public _unlock = 'api/titlevii/overview/unlock';

    // Staff and Volunteers
    public _getstaffbyffyandstate = 'api/titlevii/staff/{ffy}/{state}';
    public _savestaff = '/api/titlevii/staff';

    // Number and Capacity
    public _getcapacitybyffyandstate = 'api/titlevii/capacity/{ffy}/{state}';
    public _savecapacity = 'api/titlevii/capacity';

    // Program Activities
    public _getactivitiesbyffyandstate = 'api/titlevii/programactivities/{ffy}/{state}';
    public _saveactivities = 'api/titlevii/programactivities';

    // Funds Expended
    public _getexpendedbyffyandstate = 'api/titlevii/fundsexpended/{ffy}/{state}';
    public _saveexpended = 'api/titlevii/fundsexpended';

    // Conflict of Interest
    public _getconflictbyffyandstate = 'api/titlevii/conflictofinterest/{ffy}/{state}';
    public _saveconflict = 'api/titlevii/conflictofinterest';
    public _deleteconflict = 'api/titlevii/conflictofinterest/{id}';

    // RCC Information
    public _getrccinfobyffyandstate = '/api/titlevii/rccinfo/{ffy}/{state}';
    public _saverccinfo = 'api/titlevii/rccinfo';
    public _deleterccinfo = 'api/titlevii/rccinfo/delete/{id}';

    // Org Structure
    public _getorgstructurebyffyandstate = 'api/titlevii/orgstructure/{ffy}/{state}';
    public _saveorgstructure = '/api/titlevii/orgstructure';
    public _deleteorgstructure = 'api/titlevii/orgstructure/delete/{id}';

    // Complaint Example
    public _getcomplaintbyffyandstate = '/api/titlevii/complaintexample/{ffy}/{state}';
    public _savecomplaint = '/api/titlevii/complaintexample';
    public _deletecomplaint = '/api/titlevii/complaintexample/{id}';
    // System Issue
    public _getsystemissuebyffyandstate = 'api/titlevii/systemissue/{ffy}/{state}';
    public _savesystemissue = 'api/titlevii/systemissue';
    public _deletesystemissue = 'api/titlevii/systemissue/{id}';
    // Case and Complaint
    public _getcaseandcomplaintaggregatebyffyandstate = '/api/titlevii/case-and-complaint/aggregate/{ffy}/{state}';
    public _savecaseandcomplaintaggregatebyffyandstate = '/api/titlevii/case-and-complaint';
    public _getcaseandcomplaintbyffyandstate = '/api/titlevii/case-and-complaint/{ffy}/{state}';
    public _getcomplaintsbycasenumber = 'api/titlevii/case-and-complaint/case/{state}/{caseNumber}';
    public _getcomplaintbycomplaintid = '/api/titlevii/case-and-complaint/complaint/{state}/{id}';
    // Export
    public _exportTitleVIIData = '/api/titlevii/report/{ffy}/{state}';
    // Year Approval Status
    public _getYearApprovalStatus = 'api/titleviiapproval/{ffy}/{state}';
    public _getLatestYearApprovalStatus = '/api/titleviiapproval/latest/{ffy}/{state}';
    public _getPreviousYearApprovalStatus = '/api/titleviiapproval/previous/{ffy}/{state}';
    public _getActiveYearApprovalStatus = '/api/titleviiapproval/active/{state}';
    public _postYearApprovalStatus = 'api/titleviiapproval/';
    // user default states
    public _titleviiUserDefaultStates = 'api/titlevii/userdefaultstates';
    // user notification
    public _titleviiUserPreferences = 'api/titlevii/usernotification/save';
    public _getTitleviiUserPreferences = 'api/titlevii/usernotification/{upn}';
    // reporting period
    public _getreportingperiodbyffy = 'api/titlevii/reportingperiod/{ffy}';
    public _getallreportingperiods = 'api/titlevii/reportingperiod/all/{isAclUser}';
    public _getmaxreportingyear = 'api/titleiii/reportingperiod/maxyear';
    // reports
    public readonly _get2yrReport: string = '/api/titlevii/report/Generate2yrReport';
    public readonly _get5yrReport: string = '/api/titlevii/report/Generate5yrReport';
    // To hold selections from the data submissions screen
    public _overviews: TVIIOverview[] = [];

    // Service calls
    // Overview call
    getOverviewbyffyandstate(ffy: string, state: string): Observable<ITVIIOverview> {
        const url: string = this._getOverview.replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<ITVIIOverview>(url).pipe(
            catchError(this.handleError<TVIIOverview>())
        );
    }

    getOverviewByFfy(ffy: string): Observable<Array<ITVIIOverview>> {
        return this._http.get<Array<ITVIIOverview>>(
            this._getStatesOverview.replace('{ffy}', ffy), { withCredentials: true }).pipe(
                catchError(this.handleError<Array<TVIIOverview>>())
            );
    }

    validateBusinessRules(ffy: string, state: string, sectionId: TVIITopSectionTypeEnum): Observable<any> {
        const url: string = this._validateBusinessRules.replace('{ffy}', ffy)
            .replace('{state}', state).replace('{sectionId}', sectionId.toString());
        return this._http.get(url, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    getstaffbyffyandstate(ffy: string, state: string): Observable<Array<StaffAndVolunteerSection>> {
        const url: string = this._getstaffbyffyandstate.replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<Array<StaffAndVolunteerSection>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<StaffAndVolunteerSection>>())
        );
    }

    savestaff(model: StaffAndVolunteerSection): Observable<StaffAndVolunteerSection> {
        return this._http.post<StaffAndVolunteerSection>(
            this._savestaff, model, { withCredentials: true }).pipe(
                catchError(this.handleError<StaffAndVolunteerSection>())
            );
    }

    getcapacitybyffyandstate(ffy: string, state: string): Observable<Array<NumberAndCapacitySection>> {
        const url: string = this._getcapacitybyffyandstate.replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<Array<NumberAndCapacitySection>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<NumberAndCapacitySection>>())
        );
    }

    savecapacity(model: NumberAndCapacitySection): Observable<NumberAndCapacitySection> {
        return this._http.post<NumberAndCapacitySection>(this._savecapacity, model, { withCredentials: true }).pipe(
            catchError(this.handleError<NumberAndCapacitySection>())
        );
    }

    getactivitiesbyffyandstate(ffy: string, state: string): Observable<Array<ProgramActivitiesSection>> {
        const url: string = this._getactivitiesbyffyandstate.replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<Array<ProgramActivitiesSection>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<ProgramActivitiesSection>>())
        );
    }

    saveactivities(model: ProgramActivitiesSection): Observable<ProgramActivitiesSection> {
        return this._http.post<ProgramActivitiesSection>(
            this._saveactivities, model, { withCredentials: true }).pipe(
                catchError(this.handleError<ProgramActivitiesSection>())
            );
    }

    getexpendedbyffyandstate(ffy: string, state: string): Observable<Array<FundsExpendedSection>> {
        const url: string = this._getexpendedbyffyandstate
            .replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<Array<FundsExpendedSection>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<FundsExpendedSection>>())
        );
    }

    saveexpended(model: FundsExpendedSection): Observable<FundsExpendedSection> {
        return this._http.post<FundsExpendedSection>(
            this._saveexpended, model, { withCredentials: true }).pipe(
                catchError(this.handleError<FundsExpendedSection>())
            );
    }

    getconflictbyffyandstate(ffy: string, state: string): Observable<Array<ConflictOfInterestSection>> {
        const url: string = this._getconflictbyffyandstate.replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<Array<ConflictOfInterestSection>>(
            url, { withCredentials: true }).pipe(
                catchError(this.handleError<Array<ConflictOfInterestSection>>())
            );
    }

    saveconflict(model: ConflictOfInterestSection): Observable<ConflictOfInterestSection> {
        return this._http.post<ConflictOfInterestSection>(
            this._saveconflict, model, { withCredentials: true }).pipe(
                catchError(this.handleError<ConflictOfInterestSection>())
            );
    }

    deleteconflict(id: string): Observable<any> {
        const url: string = this._deleteconflict.replace('{id}', id);
        return this._http.delete(url, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    getrccinfobyffyandstate(ffy: string, state: string): Observable<Array<RccInformationSection>> {
        const url: string = this._getrccinfobyffyandstate.replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<Array<RccInformationSection>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<RccInformationSection>>())
        );
    }

    saverccinfo(model: RccInformationSection): Observable<RccInformationSection> {
        return this._http.post<RccInformationSection>(this._saverccinfo, model, { withCredentials: true }).pipe(
            catchError(this.handleError<RccInformationSection>())
        );
    }

    deleterccinfo(id: string): Observable<any> {
        const url: string = this._deleterccinfo.replace('{id}', id);
        return this._http.delete(url, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    getorgstructurebyffyandstate(ffy: string, state: string): Observable<Array<OrgStructureSection>> {
        const url: string = this._getorgstructurebyffyandstate.replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<Array<OrgStructureSection>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<OrgStructureSection>>())
        );
    }

    saveorgstructure(model: OrgStructureSection): Observable<OrgStructureSection> {
        return this._http.post<OrgStructureSection>(
            this._saveorgstructure, model, { withCredentials: true }).pipe(
                catchError(this.handleError<OrgStructureSection>())
            );
    }

    deleteorgstructure(id: string): Observable<any> {
        const url: string = this._deleteorgstructure.replace('{id}', id);
        return this._http.get(url, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    // Complaint Example
    getcomplaintbyffyandstate(ffy: string, state: string): Observable<Array<ComplaintExampleSection>> {
        const url: string = this._getcomplaintbyffyandstate.replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<Array<ComplaintExampleSection>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<ComplaintExampleSection>>())
        );
    }

    savecomplaint(model: ComplaintExampleSection): Observable<ComplaintExampleSection> {
        return this._http.post<ComplaintExampleSection>(
            this._savecomplaint, model, { withCredentials: true }).pipe(
                catchError(this.handleError<ComplaintExampleSection>())
            );
    }

    deletecomplaint(model: ComplaintExampleSection): Observable<ComplaintExampleSection> {
        const url: string = this._deletecomplaint.replace('{id}', model.id);
        return this._http.delete<ComplaintExampleSection>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<ComplaintExampleSection>())
        );
    }

    // System Issue
    getsystemissuebyffyandstate(ffy: string, state: string): Observable<Array<SystemIssueSection>> {
        return this._http.post<Array<SystemIssueSection>>(
            this._getsystemissuebyffyandstate, { 'ffy': ffy, 'state': state }, { withCredentials: true }).pipe(
                catchError(this.handleError<Array<SystemIssueSection>>())
            );
    }

    savesystemissue(model: SystemIssueSection): Observable<SystemIssueSection> {
        return this._http.post<SystemIssueSection>(
            this._savesystemissue, model, { withCredentials: true }).pipe(
                catchError(this.handleError<SystemIssueSection>())
            );
    }

    deletesystemissue(model: SystemIssueSection): Observable<SystemIssueSection> {
        return this._http.delete<SystemIssueSection>(
            this._deletesystemissue.replace('{id}', model.id), { withCredentials: true }).pipe(
                catchError(this.handleError<SystemIssueSection>())
            );
    }
    // Case and Complaint
    getCaseAndComplaintAggregateByFfyAndState(ffy: string, state: string): Observable<CaseAndComplaintAggregateView> {
        const url: string = this._getcaseandcomplaintaggregatebyffyandstate
            .replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<CaseAndComplaintAggregateView>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<CaseAndComplaintAggregateView>())
        );
    }

    saveCaseAndComplaintAggregateByFfyAndState(aggregateModel: CaseAndComplaintAggregateView):
        Observable<CaseAndComplaintAggregateView> {
        return this._http.post<CaseAndComplaintAggregateView>(
            this._savecaseandcomplaintaggregatebyffyandstate, aggregateModel, { withCredentials: true }).pipe(
                catchError(this.handleError<CaseAndComplaintAggregateView>())
            );
    }

    getcomplaintsbycasenumber(state: string, caseNumber: string): Observable<Array<CaseAndComplaintSection>> {
        const url = this._getcomplaintsbycasenumber.replace('{state}', state).replace('{caseNumber}', caseNumber);
        return this._http.get<Array<CaseAndComplaintSection>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<CaseAndComplaintSection>>())
        );
    }

    getcomplaintbycomplaintid(state: string, complaintId: string): Observable<CaseAndComplaintSection> {
        const url: string = this._getcomplaintbycomplaintid.replace('{id}', complaintId).replace('{state}', state);
        return this._http.get<CaseAndComplaintSection>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<CaseAndComplaintSection>()
            ));
    }

    getDataEntryErrors(ffy: string, state: string): Observable<Array<BasicErrorDetail>> {
        return this._http.post<Array<BasicErrorDetail>>(this._dataEntryErrors,
            { 'fiscalYear': ffy, 'state': state }, { withCredentials: true }).pipe(
                catchError(this.handleError<Array<BasicErrorDetail>>())
            );
    }

    generateVarianceReport(ffy: string, state: string): Observable<any> {
        const url: string = this._generateVarianceReport.replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get(url, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    submit(ffy: string, state: string): Observable<any> {
        const yas = new YearApprovalStatus();
        yas.fiscalYear = ffy;
        yas.state = state;
        yas.upn = this._claimService.userID;
        return this._http.post(this._submit, yas, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    review(ffy: string, state: string): Observable<any> {
        const yas = new YearApprovalStatus();
        yas.fiscalYear = ffy;
        yas.state = state;
        yas.upn = this._claimService.userID;
        return this._http.post(this._review, yas, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    approve(model: YearApprovalStatus): Observable<any> {
        let url = this._approve;
        model.upn = this._claimService.userID;
        if(model.status === QuestionStatusEnum.Returned){
          url = this._return;
        }
        return this._http.post(url, model, { withCredentials: true }).pipe(
          catchError(this.handleError()
          ));
    }

    lock(ffy: string, state: string): Observable<any> {
        const model = new YearApprovalStatus();
        model.fiscalYear = ffy;
        model.state = state;
        model.upn = this._claimService.userID;
        return this._http.post(this._lock, model, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    unlock(ffy: string, state: string): Observable<any> {
        const model = new YearApprovalStatus();
        model.fiscalYear = ffy;
        model.state = state;
        model.upn = this._claimService.userID;
        return this._http.post(this._unlock, model, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    batch(action: QuestionStatusEnum, payload: FfyStatesDto): Observable<any> {
        // Resolve correct uri for action
        let uri: string;
        switch (action) {
            case QuestionStatusEnum.Submitted:
                uri = this._review;
                break;
            case QuestionStatusEnum.Approved:
                uri = this._lock;
                break;
            case QuestionStatusEnum.Locked:
                uri = this._unlock;
                break;
        }
        uri += '/batch';
        return this._http.post(uri, payload, { withCredentials: true }).pipe(
            catchError(this.handleError()
            ));
    }

    exporttitleviidata(ffy: string, state: string): Observable<Object[]> {
        const req = (token) => new Observable<any>((observer) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', this._exportTitleVIIData.replace('{ffy}', ffy).replace('{state}', state), true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                        console.log('error');
                    }
                }
            };
            xhr.send();
        });

        return this._claimService.injectToken(req);
    }

    // Variance

    updateVarianceStaff(model: StaffAndVolunteerSection): Observable<StaffAndVolunteerSection> {
        return this._http.post<StaffAndVolunteerSection>(
            this._updateVarianceStaff, model, { withCredentials: true }).pipe(
                catchError(this.handleError<StaffAndVolunteerSection>())
            );
    }

    updateVarianceOrgStructure(model: OrgStructureSection): Observable<OrgStructureSection> {
        return this._http.post<OrgStructureSection>(
            this._updateVarianceOrgStructure, model, { withCredentials: true }).pipe(
                catchError(this.handleError<OrgStructureSection>())
            );
    }

    updateVarianceCaseAndComplaint(model: CaseAndComplaintAggregateView):
        Observable<CaseAndComplaintAggregateView> {
        return this._http.post<CaseAndComplaintAggregateView>(
            this._updateVarianceCaseAndComplaint, model, { withCredentials: true }).pipe(
                catchError(this.handleError<CaseAndComplaintAggregateView>())
            );
    }

    updateVarianceNumberAndCapacity(model: NumberAndCapacitySection):
        Observable<NumberAndCapacitySection> {
        return this._http.post<NumberAndCapacitySection>(
            this._updateVarianceNumberAndCapacity, model, { withCredentials: true }).pipe(
                catchError(this.handleError<NumberAndCapacitySection>())
            );
    }

    updateVarianceProgramActivities(model: ProgramActivitiesSection):
        Observable<ProgramActivitiesSection> {
        return this._http.post<ProgramActivitiesSection>(
            this._updateVarianceProgramActivities, model, { withCredentials: true }).pipe(
                catchError(this.handleError<ProgramActivitiesSection>())
            );
    }

    updateVarianceFundsExpended(model: FundsExpendedSection): Observable<FundsExpendedSection> {
        return this._http.post<FundsExpendedSection>(
            this._updateVarianceFundsExpended, model, { withCredentials: true }).pipe(
                catchError(this.handleError<FundsExpendedSection>())
            );
    }

    // Year Approval Status
    getYearApprovalStatusByFfyAndState(ffy: string, state: string): Observable<Array<YearApprovalStatus>> {
        return this._http.get<Array<YearApprovalStatus>>(
            this._getYearApprovalStatus.replace('{ffy}', ffy).replace('{state}', state),
            { withCredentials: true }).pipe(
                catchError(this.handleError<Array<YearApprovalStatus>>())
            );
    }

    getLatestYearApprovalStatusByFfyAndState(ffy: string, state: string): Observable<YearApprovalStatus> {
        const url: string = this._getLatestYearApprovalStatus.replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<YearApprovalStatus>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<YearApprovalStatus>())
        );
    }

    getPreviousYearApprovalStatusByFfyAndState(ffy: string, state: string): Observable<YearApprovalStatus> {
        const url: string = this._getPreviousYearApprovalStatus.replace('{ffy}', ffy).replace('{state}', state);
        return this._http.get<YearApprovalStatus>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<YearApprovalStatus>())
        );
    }

    getActiveYearApprovalStatusByState(state: string): Observable<Array<YearApprovalStatus>> {
        const url: string = this._getActiveYearApprovalStatus.replace('{state}', state);
        return this._http.get<Array<YearApprovalStatus>>(url, { withCredentials: true }).pipe(
            catchError(this.handleError<Array<YearApprovalStatus>>())
        );
    }

    saveYearApprovalStatus(model: YearApprovalStatus): Observable<YearApprovalStatus> {
        model.upn = this._claimService.userID;
        return this._http.post<YearApprovalStatus>(
            this._postYearApprovalStatus, model, { withCredentials: true }).pipe(
                catchError(this.handleError<YearApprovalStatus>())
            );
    }

    getUserDefaultStates(): Observable<any> {
        return this._http.get(this._titleviiUserDefaultStates, {
            params: { upn: this._claimService.userID }, withCredentials: true }).pipe(
                catchError(this.handleError<any>())
            );
    }

    saveUserDefaultStates(model: UserDefaultStates): Observable<UserDefaultStates> {
        return this._http.post<UserDefaultStates>(
            this._titleviiUserDefaultStates, model, { withCredentials: true }).pipe(
                catchError(this.handleError<UserDefaultStates>())
            );
    }

    findUserNotificationPreferences(): Observable<any> {
        const url: string = this._getTitleviiUserPreferences.replace('{upn}', this._claimService.userID);
        return this._http.get<Array<any>>(url,
            { withCredentials: true }).pipe(
                catchError(this.handleError<any>())
            );
    }

    saveUserNotifications(model: UserNotifications): Observable<UserNotifications> {
        return this._http.post<UserNotifications>(
            this._titleviiUserPreferences, model, { withCredentials: true }).pipe(
                catchError(this.handleError<UserNotifications>())
            );
    }

    findReportingPeriosByFfy(ffy: string): Observable<any> {
        const url: string = this._getreportingperiodbyffy.replace('{ffy}', ffy);
        return this._http.get(url, { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    getMaxReportingYear(): Observable<string> {
        return this._http.get<string>(this._getmaxreportingyear, { withCredentials: true }).pipe(
            catchError(this.handleError<string>())
        );
    }

    getAllReportingPeriods(isAclUser: string): Observable<any> {
        return this._http.get(this._getallreportingperiods.replace('{isAclUser}', isAclUser), { withCredentials: true }).pipe(
            catchError(this.handleError<any>())
        );
    }

    public generate2yrReport(reportDetails: YearStateRegionReport, titleNumber: string): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            const url = this._get2yrReport;
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });

        return this._claimService.injectToken(req);
    }

    public generate5yrReport(reportDetails: YearStateRegionReport): Observable<Object[]> {
        const req = (token) => new Observable<any>(observer => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._get5yrReport, true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.responseType = 'blob';

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                        const blob = new Blob([xhr.response], { type: contentType });
                        observer.next(blob);
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.send(JSON.stringify(reportDetails));
        });

        return this._claimService.injectToken(req);
    }

    // Generic get
    // uses the claim service to determine
    // whether to pull state or AAA record
    getSections<T>(claimService: ClaimService, endpoint: string, loadingComponent?: LoadingComponent): Observable<T> {

        if (loadingComponent) {
            loadingComponent.setLoadingInProgress(100);
        }
    //     return claimService.init$.flatMap(isInit => {
    //         if (isInit) {
    //             const url: string = endpoint.replace('{ffy}', claimService.currentYear).replace('{state}', claimService.currentOrg);
    //             return this._http.get<T>(url, { withCredentials: true }).pipe(
    //                 catchError(this.handleError<T>()
    //                 ));
    //         } else {
    //             return Observable.throw('ClaimService failed during initialization');
    //         }

    //     });
    // }

    // To do: Test this "flatMap" implementation works. This is an RxJs6 update
    return claimService.init$.pipe(
        flatMap(isInit => {
            if (isInit) {
                const url: string = endpoint.replace('{ffy}', claimService.currentYear).replace('{state}', claimService.currentOrg);
                return this._http.get<T>(url, { withCredentials: true }).pipe(
                    catchError(this.handleError<T>()
                    ));
            } else {
                return Observable.throw('ClaimService failed during initialization');
            }
        })
    );
}



    // // Global
    private handleError<T>(result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
