import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { TitleIIIDataEntryService } from './titleIIIDataEntry.service';
import { ClaimService } from '../shared/claim.service';
import { ANServiceProvidersSection } from './ANServiceProvidersSection';
import { DataElement } from '../shared/dataElement';
import { ValidationService } from '../shared/validation.service';
import { ToastrService } from '../shared/toastr.service';
import { CommonService } from '../shared/common.service';
import { QuestionStatusEnum } from '../shared/dataElement';
import { TitleIIISectionComponent } from './title-iii-section.component';

@Component({
  templateUrl: 'titleIIIANServiceProviders.component.html',
})

export class TitleIIIANServiceProvidersComponent extends TitleIIISectionComponent<ANServiceProvidersSection> implements OnInit, OnDestroy {

  _sectionModel: ANServiceProvidersSection;
  _uploadModels: Array<ANServiceProvidersSection>;
  _batchIds: Array<string>;
  unsavedChanges: boolean = false;
  questionStatusEnum = QuestionStatusEnum;

  constructor(public route: ActivatedRoute,
    public router: Router,
    public _titleService: Title,
    public _service: TitleIIIDataEntryService,
    public _claimService: ClaimService,
    public _validation: ValidationService,
    public toastr: ToastrService,
    public _commonService: CommonService
  ) {
    super(new ANServiceProvidersSection(), _commonService, _validation, router, _claimService, _service);
  }

  ngOnInit(): void {
    this._titleService.setTitle('AN - Service Providers - Data Submissions - ACL OAAPS');
    this._sectionModel = new ANServiceProvidersSection();
    this.getanserviceprovidersbyffyandstate();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  save(callback: () => void = () => null): void {
    this.unsavedChanges = false;
    // TODO Move this logic into Controller for security
    this._sectionModel.state = this._claimService.currentOrg;
    this._sectionModel.fiscalYear = this._claimService.currentYear;
    super.registerSubscription(this._service.saveanserviceproviders(this._sectionModel)
      .subscribe(data => {
        this._sectionModel = data;
        this._claimService.tiiiStatuses.titleIIIANServiceProviders = data.sectionStatus;

      },
        (error) => console.log(error),
        () => {
          this.toastr.success('Saved!');
          callback();
        }
      ));
  }

  saveAndNext(): void {
    this.unsavedChanges = false;
    const navAction = () => this.router.navigate(['/data-submissions/titleIIIANSeniorCenters']);
    if (!this._claimService.isViewOnly && 
      this._commonService.shouldSave(this._sectionModel, this._claimService.isAAAEnabledStateUser(), this._service.aaaStateEditMode)) {
      this.save(navAction);
    } else {
      navAction();
    }
  }

  getanserviceprovidersbyffyandstate(): void {
    super.registerSubscription(this._service.get<ANServiceProvidersSection[]>(this._claimService,
      this._service._getanserviceprovidersbyffyandstate, this.resolveSectionName())
      .subscribe(data => {
        if (data.length > 0) {
          // Adding this so if the filter returns nothing, _sectionModel won't be undefined;
          let tempSectionModel: ANServiceProvidersSection = new ANServiceProvidersSection();
          tempSectionModel = data.filter(element => element.batchId === '00000000-0000-0000-0000-000000000000')[0];
          if (tempSectionModel) {
            this._sectionModel = tempSectionModel;
          }
          this._uploadModels = data.filter(element => element.batchId !== '00000000-0000-0000-0000-000000000000');
          if (this._uploadModels != null && this._uploadModels.length > 0) {
            this._batchIds = this._uploadModels.map<string>(e => e.batchId);
          }
          this.formatAllNumbersAlreadySaved();
          super.processSectionStatus();
      }

      }));
  }

  updateTotal(): void {
    let grandTotal = 0;
    const homeDelivered = parseInt(this.removeCommas(this._sectionModel.homeDelivered.elementValue));
    const congregate = parseInt(this.removeCommas(this._sectionModel.congregate.elementValue));
    const bothMeals = parseInt(this.removeCommas(this._sectionModel.bothMeals.elementValue));
    if (!isNaN(homeDelivered)) { grandTotal += homeDelivered; }
    if (!isNaN(congregate)) { grandTotal += congregate; }
    if (!isNaN(bothMeals)) { grandTotal += bothMeals; }
    this._sectionModel.totalProviders.elementValue = this.addComma(grandTotal.toString());
  }

  formatAllNumbersAlreadySaved(): void {
    this._sectionModel.allServices.elementValue = this.addComma(this._sectionModel.allServices.elementValue);
    this._sectionModel.bcdServices.elementValue = this.addComma(this._sectionModel.bcdServices.elementValue);
    this._sectionModel.eServices.elementValue = this.addComma(this._sectionModel.eServices.elementValue);
    this._sectionModel.homeDelivered.elementValue = this.addComma(this._sectionModel.homeDelivered.elementValue);
    this._sectionModel.congregate.elementValue = this.addComma(this._sectionModel.congregate.elementValue);
    this._sectionModel.bothMeals.elementValue = this.addComma(this._sectionModel.bothMeals.elementValue);
    this._sectionModel.totalProviders.elementValue = this.addComma(this._sectionModel.totalProviders.elementValue);
    this._sectionModel.infoAndAssist.elementValue = this.addComma(this._sectionModel.infoAndAssist.elementValue);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.unsavedChanges || sessionStorage.getItem('sessionExpiredRedirect')) {
      this._commonService.agingNetworkLockedNav = false;
      return true;
    } else {
      this._commonService.agingNetworkLockedNav = true;
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  showMsg($event) {
    if (!this.canDeactivate()) {
      $event.returnValue = `You have unsaved changes. Press OK to proceed without saving these changes,
or press Cancel to go back and save these changes.`;
    }
  }

}
