export interface IUserSearch {
    firstName?: string;
    lastName?: string;
    email?: string;
    status?: UserStatusEnum;
    orgName?: string;
    state?: string;
    region?: string;
    role?: Array<IUserSearchRole>;
}

export class UserSearch implements IUserSearch {
    firstName?: string = '';
    lastName?: string = '';
    email?: string = '';
    status?: UserStatusEnum = UserStatusEnum.All;
    orgName?: string = '';
    state?: string = '';
    region?: string = '';
    role?: Array<IUserSearchRole> = new Array<UserSearchRole>();
}

export enum DataAccessEnum {
    // enums initialize at 0
    All, ViewOnly, DataEntry, None
}
export enum UserStatusEnum {
    // enums initialize at 0
    All, Active, Inactive, Pending, Declined
}
export interface IUserSearchRole {
    orgLevel: string;
    title: string;
    org: string;
    subOrg: string;
    region: RegionEnum;
    dataAccess: DataAccessEnum;
    isDataReviewApprove: boolean;
    isDataReviewLock: boolean;
    isDataReviewSubmit: boolean;
    isGrantManager: boolean;
    isAdmin: boolean;
}

export class UserSearchRole implements IUserSearchRole {
    orgLevel: string;
    title: string;
    org: string;
    subOrg: string;
    dataAccess: DataAccessEnum = DataAccessEnum.All;
    isDataReviewApprove: boolean = false;
    isDataReviewLock: boolean = false;
    isDataReviewSubmit: boolean = false;
    isGrantManager: boolean = false;
    isAdmin: boolean = false;
    region: RegionEnum;
}

export enum OrgLevelEnum {
    All, ACLCO, ACLRO, State, AAA, Grantee
}

export enum RegionEnum {
    One = 1, Two, Three, Four, Five, Six, Seven, Eight, Nine, Ten, CO
}

export enum UserTypeEnum {
    SystemAdmin = 1, CentralOffice, Regional, State, AAA
}

export interface ITitleOrg {
    title: string;
    org?: string;
    region?: string;
    displayName?: string;
    granteeId?: string;
    state?: string;
    fullName?: string;
}

export interface IRollupSource {
    fiscalYear: string;
    org: string;
    sources: ITitleOrg[];
}
export class RollupSource implements IRollupSource {
    fiscalYear: string;
    org: string;
    sources: ITitleOrg[] = [];
}
export interface IUserContact {
    upn: string;
    phoneNumber: string;
    email: string;
}

export interface ITitleUser {
    id: string;
    upn: string;
    firstName: string;
    lastName: string;
    email: string;
    mobilePhone: string;
    orgLevel: string;
    region: string;
    org: string;
    subOrg: string;
    title: string;
    role: string;
    isActive: boolean;
    activeDesc: string;
    groupedRoles: Array<IUserSearchRole>;
    titleRoles: Array<string>;
    defaultTitleOrg: ITitleOrg;
    securityStamp: string;
}

export class TitleUser implements ITitleUser {
    id: string;
    upn: string;
    firstName: string;
    lastName: string;
    email: string;
    mobilePhone: string;
    orgLevel: string;
    region: string;
    org: string;
    subOrg: string;
    title: string;
    role: string;
    isActive: boolean;
    activeDesc: string;
    groupedRoles: Array<IUserSearchRole> = [];
    titleRoles: Array<string> = [];
    defaultTitleOrg: ITitleOrg;
    securityStamp: string;
}

export class TitleOrg implements ITitleOrg {
    title: string;
    org?: string;
    region?: string;
    state?: string;
    granteeId?: string;
}

export interface IRoleDetail {
    title: string;
    region: string;
    org: string;
    permissions: string[];
    status: string;
}

export class RoleDetail implements IRoleDetail {
    title: string;
    region: string;
    org: string;
    permissions: string[] = [];
    status: string;
}

export interface IKeyValue {
    key: string;
    value: string;
}

export interface IUserTitleRolesDto {
    userId: string;
    roles: IUserSearchRole[];
}

export class UserTitleRolesDto implements IUserTitleRolesDto {
    userId: string;
    roles: IUserSearchRole[] = [];
}

export interface IEditStatus {
    key: boolean;
    value: string;
}

export const AllOrgs: Array<IKeyValue> = [
    { key: 'All', value: 'All' },
    { key: 'ACLCO', value: 'Central Office' },
    { key: 'ACLRO', value: 'Regional Office' },
    { key: 'State', value: 'State' }
];

export const ACLAddUserOptions: Array<IKeyValue> = [
    { key: 'ACLCO', value: 'ACL Central Office' },
    { key: 'ACLRO', value: 'ACL Regional Office' },
    { key: 'State', value: 'State' },
    { key: 'AAA', value: 'AAA' },
    { key: 'Grantee', value: 'Grantee' },
];

export const StateAddUserOptions: Array<IKeyValue> = [
    { key: 'State', value: 'State' },
    { key: 'AAA', value: 'AAA' },
];

export function getOrgLevelPrecedence(orgLevel: string): number {
    switch (orgLevel) {
        case ('ACLCO'):
            return 5;
        case ('ACLRO'):
            return 4;
        case ('State'):
            return 3;
        case ('AAA'):
            return 2;
        case ('Grantee'):
            return 1;
        default:
            return 0;
    }
}


export const AllStatus: Array<IKeyValue> = [
    { key: '0', value: 'All' },
    { key: '1', value: 'Active' },
    { key: '2', value: 'Inactive' },
    // { key: '3', value: 'Pending' },
    // { key: '4', value: 'Declined' },
];

export const EditStatus: Array<IEditStatus> = [
    { key: true, value: 'Active' },
    { key: false, value: 'Inactive' }
];

export const RegionStrings = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export class UserManagementStrings {
    public static readonly displayNameViewOnlyRole = 'View data';
    public static readonly displayNameDataEntryRole = 'View and Edit data';
    public static readonly displayNameApproveRole = 'Approve/Return data';
    public static readonly displayNameLockRole = 'Lock data';
    public static readonly displayNameSubmitRole = 'Submit data';
    public static readonly displayNameAdminRole = 'Manage users';
    public static readonly displayNameGrantManagerRole = 'Manage grants';

    public static readonly orgLevelACLCO = 'ACLCO';
    public static readonly orgLevelACLRO = 'ACLRO';
    public static readonly orgLevelState = 'State';
    public static readonly orgLevelAAA = 'AAA';
    public static readonly orgLevelGrantee = 'Grantee';
}
