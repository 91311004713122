import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClaimService } from './claim.service';
import { DataElement } from './dataElement';

@Injectable()
export class ValidationService {

    constructor(public _http: HttpClient,
        private claimService: ClaimService) { }

    // validations
    validateDateElement(de: DataElement): void {
        if (de.elementValue != null && de.elementValue !== '') {
            if (this.validate(de.validationType, de.elementValue, de.maxLength || 0)) {
                de.dataEntryErrorMessage = '';
                de.questionStatus = 3;
            } else {
                de.dataEntryErrorMessage = this.getErrorMessage(de.validationType);
                de.questionStatus = 1;
            }
        } else {
            de.dataEntryErrorMessage = '';
            de.questionStatus = 0;
        }
    }

    validate(valType: number, value: string, maxLength = 0): boolean {
        switch (valType) {
            case 0: {
                return this.noValidation(value);
            }
            case 1: {
                return this.validateWholeNumber(value);
            }
            case 2: {
                return this.validateDecimal2(value);
            }
            case 4: {
                return this.validateNonZero(value);
            }
            case 8: {
                return this.validateAlphaNum(value);
            }
            case 16: {
                return this.validateDate(value);
            }
            case 32: {
                return this.validateString(value);
            }
            case 64: {
                return this.validateNotNullOrEmpty(value);
            }
            case 128: {
                return this.validateNonZeroInt(value);
            }
            case 256: {
                return this.validateCharLimit3400(value);
            }
            case 512: {
                return this.validateCharLimit100(value);
            }
            case 2048: {
                return this.validateCharLimit(value, maxLength);
            }
            case 4096: {
                return this.validateCharLimit5000(value);
            }
        }
    }

    validateMaxLength(value: string, maxLength: number): boolean {
        value = this.removeCommas(value);
        value = this.toFixed(value).toString();
        const val: string[] = value.split('.');
        if (maxLength != 0 && val[0] && val[0].length > maxLength) {
            return false;
        }
        return true;
    }

    validateCharLimit(value: string, maxLength: number): boolean {
        if (value && value.length > maxLength) {
            return false;
        }
        return true;
    }

    noValidation(value: string): boolean {
        return true;
    }

    validateNotNullOrEmpty(value: string): boolean {
        return (!value || value === '') ? false : true;
    }

    validateWholeNumber(value: string): boolean {
        value = this.removeCommas(value);
        const format = /^\d+$/;
        return format.test(value);
        // var format = /[ ~!@#$%^&*()_+\=\[\]{};':"\\|<>\/?]/;    //no special chars allowed
        // return !isNaN(parseInt(value)) && value.indexOf('.') == -1 && parseInt(value) >= 0 && !format.test(value);
        // parseInt accepts float values, so the second clause ensures that there is no decimal in the value
    }

    validateDecimal2(value: string): boolean {
        value = this.removeCommas(value);
        const format = /[ !@#$%^&*()_\=\[\]{};':"\\\/?a-zA-Z]+/;
        return !isNaN(parseFloat(value)) && parseFloat(value) >= 0 && !format.test(value);
    }

    validateNonZeroInt(value: string): boolean {
        value = this.removeCommas(value);
        // var format = /[ !@#$%^&*()_+\=\[\]{};':"\\|<>\/?]/;
        // return !isNaN(parseInt(value)) && parseInt(value) > 0 && !format.test(value);
        const format = /^[1-9][0-9]*$/;
        return format.test(value);
    }

    validateNonZero(value: string): boolean {
        value = this.removeCommas(value);
        const format = /[ !@#$%^&*()_\=\[\]{};':"\\\/?a-zA-Z]+/;
        return (!isNaN(parseFloat(value)) && (parseFloat(value) > 0.0) && !format.test(value));
    }

    validateAlphaNum(value: string): boolean {
        let code, i, len;
        for (i = 0, len = value.length; i < len; i++) {
            code = value.charCodeAt(i);
            if (!(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)) { // lower alpha (a-z)
                return false;
            }
        }
        return true;
    }

    validateDate(value: string): boolean {
        // regex for mm/dd/yyyy
        const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        if (!(date_regex.test(value))) {
            return false;
        } else {
            return true;
        }
    }

    validateString(value: string): boolean {
        // This checked if the string existed. Required is now a business rule, so no validation should occur on strings.
        /*
        if (!value) {
            return false;
        } else {
            return true;
        }
        */

        return true;
    }

    getErrorMessage(validationType: number): string {
        switch (validationType) {
            case 0: {
                return ''; // no validation
            }
            case 1: {
                if (this.claimService.currentTitle == 'VI') {
                    return 'Characters, symbols, and decimals are not allowed.'; // special validation message for title 6 numbers
                } else {
                    return 'Value must be a whole number greater than or equal to zero.';
                }
            }
            case 2: {
                return 'Value must be greater than or equal to zero and rounded to two decimal places.';
            }
            case 4: {
                return 'Value must be greater than zero and rounded to two decimal places.';
            }
            case 8: {
                return 'Alphanumeric value required.';
            }
            case 16: {
                return 'Valid date value required.';
            }
            case 32: {
                return 'Required field.'; // string validation; something just needs to be entered
            }
            case 64: {
                return 'Required field.';
            }
            case 128: {
                return 'Value must be a whole number greater than zero.';
            }
            case 512:
            case 2048:
            case 4096:
            case 256: {
                return 'Character limit exceeded.';
            }
        }
    }

    removeCommas(value: string): string {
        if (value) {
            value = value.replace(/,/g, '');
        }
        return value;
    }

    validateCharLimit3400(value: string): boolean {
        if (value && value.length > 3400) {
            return false;
        }
        return true;
    }

    validateCharLimit5000(value: string): boolean {
        if (value && value.length > 5000) {
            return false;
        }
        return true;
    }

    validateCharLimit100(value: string): boolean {
        if (value && value.length > 100) {
            return false;
        }
        return true;
    }

    // Avoid Scientific Notation
    toFixed(x) {
        if (Math.abs(x) < 1.0) {
            const e = parseInt(x.toString().split('e-')[1], 10);
            if (e) {
                x *= Math.pow(10, e - 1);
                x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
            }
        } else {
            let e = parseInt(x.toString().split('+')[1], 10);
            if (e > 20) {
                e -= 20;
                x /= Math.pow(10, e);
                x += (new Array(e + 1)).join('0');
            }
        }
        return x;
    }

    // Display

    /**
     * Resolves the css class for the input based on the DataElement's status
     * @param status the DataElement status property
     */
    public calculateOutlineColor(status: number): string {
        if (status === 1 || status === 2) {
            return 'has-error';
        }
        if (status === 4) {
            return 'has-warning';
        } else {
            return '';
        }
    }

}
