<header> </header>
<!-- begin main content -->
<div class="container" role="main" id="main-content">
    <div class="row">
        <div class="col-md-12 acl-container">
            <!-- left navigation -->
            <sideNav></sideNav>
            <!-- .end left navigation -->
            <div class="col-md-9">
                <h2>{{pageName}} - Consumer Summary - Total Persons Served</h2>
                <p class="info mt-1">All fields are required</p>
                <div class="col-md-9">
                    <label for="bothClusterCount" class="normal" *ngIf="pageName == 'Older Adults'">Unduplicated count of registered persons served under Cluster 1 & 2</label>
                    <label for="bothClusterCount" class="normal" *ngIf="pageName != 'Older Adults'">Unduplicated count of registered persons served</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputbothClusterCount'" [de]="_sectionModel.bothClusterCount"
                    (change)="onChange()">
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.bothClusterCount"></acl-input-error>
                </div>
                <div *ngIf="pageName == 'Older Adults'">
                    <div class="col-md-9">
                        <label for="cluster1Count" class="normal">Unduplicated count of registered persons served under Cluster 1</label>
                    </div>
                    <div class="col-md-3 mb-1">
                        <acl-data-element-text [id]="'inputcluster1Count'" [de]="_sectionModel.cluster1Count"
                        (change)="onChange()">
                        </acl-data-element-text>
                        <acl-input-error [de]="_sectionModel.cluster1Count"></acl-input-error>
                    </div>
                </div>
                <div class="col-md-9">
                    <label for="unregisteredCount" class="normal">Estimated unduplicated count of unregistered persons served</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputunregisteredCount'" [de]="_sectionModel.unregisteredCount"
                    (change)="onChange()">
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.unregisteredCount"></acl-input-error>
                </div>
                <div class="col-md-9">
                    <label for="totalCount">Total estimated unduplicated count of persons served</label>
                </div>
                <div class="col-md-3 mb-1">
                    <acl-data-element-text [id]="'inputtotalCount'" [de]="_sectionModel.totalCount"
                    (change)="onChange()">
                    </acl-data-element-text>
                    <acl-input-error [de]="_sectionModel.totalCount"></acl-input-error>
                </div>
                <div class="row col-md-12">
                    <div *ngIf="!validForm" class="has-error">
                        <p class="help-block">There are errors on the page. You must fix the errors or clear the data in those fields in order
                            to Save this form.</p>
                    </div>
                    <button *ngIf="!validForm" data-target="#nextModal" data-toggle="modal" class="btn btn-primary mr-1">Next</button>
                    <button *ngIf="validForm" class="btn btn-primary mr-1" (click)="saveAndNext()" >Save and next</button>
                    <button *ngIf="validForm" class="btn btn-default" (click)="save()" >Save</button>
                    <a class="btn btn-link pull-right" [routerLink]="['/data-submissions/titleIIIOverview']">Return to Overview</a>
                </div>

                <div aria-labelledby="nextModalLabel" class="modal fade" id="nextModal" role="dialog" tabindex="-1" style="display: none;">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <h3 class="modal-title" id="nextModalLabel">Recent changes on this page will not be saved</h3>
                            </div>
                            <div class="modal-body">
                                <p>
                                    There are errors on the page. You must fix the errors or clear the data in those fields in order to save this form.
                                    <br />
                                    <br /> You may select "Go back to form" to remove the errors or you may select "Proceed to
                                    next section" and all of new the data entered in the current section will be lost.
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary pull-left" data-dismiss="modal" type="button" href="#">Go back to form</button>
                                <button class="btn btn-primary pull-right" (click)="unsavedChanges = false;" data-dismiss="modal" type="button" [routerLink]="['/data-submissions/OAConsumerBoth']">Proceed to next section</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- .end col-md-9 -->
        </div>
        <!-- .end acl-container (col-md-12) -->
    </div>
    <!-- .end row -->
</div>
<!-- ./container -->
