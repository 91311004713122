
export enum CardTypes { Link, Button, Default }
export class CardModel {

    constructor(public title: string = '',
        public description: string = '',
        public type: CardTypes = CardTypes.Default,
        public link?: string,
        public action?: () => void,
    ) { }
}
